import React, { useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import { Form, Button, Segment, Container, Header, Divider, Table, Modal } from 'semantic-ui-react';
import _ from 'lodash';

import { isFormError } from './utils';
import { useFlightData } from './api/tfms';

function EDCTLookupForm() {

    const [callsign, setCallsign] = useState(undefined);
    const [origin, setOrigin] = useState(undefined);
    const [destination, setDestination] = useState(undefined);

    const [formError, setFormError] = useState(true);
    const { data: flights, refetch, isFetched } = useFlightData(callsign, origin, destination);

    useEffect(() => {

        if (isFormError([callsign, origin, destination])) setFormError(true);
        else setFormError(false);

    }, [callsign, origin, destination]);

    function reset() {
        setCallsign(undefined);
        setOrigin(undefined);
        setDestination(undefined);
    }

    async function lookup(callsign, origin, destination) {

        if (!callsign || !origin || !destination) return;
        await refetch();

    }

    return (

        <>
            <Container className='borderraised'>
                <Form>

                    <Segment className='lightbordered'>

                        <Header as='h4' content='EDCT Lookup' />
                        <Form.Input maxLength={7} inline className='fixedLabelWidth' label='CALL SIGN' required onChange={(_, { value }) => setCallsign(value)} />
                        <Form.Input maxLength={4} inline className='fixedLabelWidth' label='ORIGIN' required onChange={(_, { value }) => setOrigin(value)} />
                        <Form.Input maxLength={4} inline className='fixedLabelWidth' label='DESTINATION' required onChange={(_, { value }) => setDestination(value)} />
                        <Button content='Reset' disabled onClick={() => reset()} />
                        <Button floated='right' content='Lookup EDCT' disabled={formError} onClick={() => lookup(callsign, origin, destination)} />

                    </Segment>

                </Form>
            </Container>

            {
                isFetched ?
                    flights && flights.length > 0 ?
                        <Segment className='borderraised' style={{ textAlign: 'center' }}>
                            <Container style={{ border: '1px solid #999999', padding: '10px' }}>

                            <Header as='h3' dividing content='EDCT Lookup for' style={{ textAlign: 'center' }} />
                                <div style={{ width: '50%', float: 'left', display: 'inline-block', textAlign: 'right', fontWeight: 'bold' }}>
                                    CALL SIGN:<br />
                                    ORIGIN:<br />
                                    DESTINATION:
                                </div>
                                <div style={{ width: '50%', display: 'inline-block', textAlign: 'left', paddingLeft: '5px' }}>
                                    {callsign.toUpperCase()}<br />
                                    {origin.toUpperCase()}<br />
                                    {destination.toUpperCase()}
                                </div>
                                <Divider />
                            The system found <b>{flights.length}</b> record(s) matching your criteria:

                            <Table unstackable celled className='addDarkBorder'>

                                <Table.Header>
                                    <Table.Row className='edctHeader'>
                                        <Table.HeaderCell>EDCT</Table.HeaderCell>
                                        <Table.HeaderCell>Filed Departure Time</Table.HeaderCell>
                                        <Table.HeaderCell>Control Element</Table.HeaderCell>
                                        <Table.HeaderCell>Flight Cancelled?</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>

                                <Table.Body>
                                    {
                                        _.map(flights, flight => {
                                            
                                            return (
                                                <Table.Row key={flight._id}>
                                                    <Table.Cell>
                                                        {
                                                            flight?.edct?.time ?
                                                            DateTime.fromJSDate(new Date(flight.edct.time), { zone: 'utc' }).toFormat('D T') : '--'
                                                        }
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        {
                                                            flight?.pgtd ?
                                                            DateTime.fromJSDate(new Date(flight.pgtd), { zone: 'utc' }).toFormat('D T') : '--'
                                                        }
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        {
                                                            flight?.edct?.mode ? flight.dest.substring(1) : 'None'
                                                        }
                                                    </Table.Cell>
                                                    <Table.Cell>{flight.status === 'cancelled' ? 'Yes' : 'No'}</Table.Cell>
                                                </Table.Row>
                                            )
                                            
                                        })
                                    }
                                </Table.Body>
                            </Table>

                            {
                                // _.some(flights, flight => flight?.edct?.time) ?
                                _.some(flights, 'flight.edct.time') ?
                                null :
                                <Segment textAlign='center' className="lightbordered redtext whitebg">
                                        This flight is not controlled at this time. Please check back prior to departure.
                                    </Segment>
                            }
                            </Container>
                        </Segment>
                        :
                        <Segment textAlign='center' className="lightbordered redtext whitebg">
                            No EDCT information is available for the values you entered. Please check your input and try again.
                        </Segment>
                    :
                    null
                }
        </>

)

}

export function EDCTModal({ onClose, showModal }) {
    
    return (
        <Modal closeIcon={true} onClose={() => onClose()} open={showModal}>

            <Modal.Header>EDCT Lookup</Modal.Header>

            <Modal.Content>
                <EDCTLookupForm />
            </Modal.Content>

            <Modal.Actions>
                <Button content='Close' onClick={() => onClose()} />
            </Modal.Actions>

        </Modal>
    )

}

export default function EDCTLookup() {

    return (
        <Container className='hasHeader'>
            <EDCTLookupForm />
        </Container>

    )

}
