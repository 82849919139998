export const nas_low = [
    {
        "type": "Feature",
        "id": 2,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -100.00000000059953,
                        35.82916666650851
                    ],
                    [
                        -101.47222222207785,
                        36.397222221928075
                    ],
                    [
                        -101.75000000069946,
                        36.50000000000006
                    ],
                    [
                        -102.18333333376631,
                        37.04166666678327
                    ],
                    [
                        -102.54999999999995,
                        37.499999999800195
                    ],
                    [
                        -103.61944444490518,
                        37.162499999575346
                    ],
                    [
                        -104.00000000069946,
                        37.045277777547426
                    ],
                    [
                        -104.00000000069946,
                        36.93333333306691
                    ],
                    [
                        -104.00000000069946,
                        35.34166666658342
                    ],
                    [
                        -102.98333333396613,
                        35.34999999960036
                    ],
                    [
                        -102.98333333396613,
                        35.13750000002506
                    ],
                    [
                        -102.81666666733281,
                        34.808333332817085
                    ],
                    [
                        -102.32500000044962,
                        34.549999999400484
                    ],
                    [
                        -102.0000000001998,
                        34.599999999300564
                    ],
                    [
                        -101.00000000039967,
                        34.491666666483525
                    ],
                    [
                        -100.74999999999994,
                        34.46666666653351
                    ],
                    [
                        -100.31666666693309,
                        34.86666666663342
                    ],
                    [
                        -100.14166666728283,
                        35.1291666661088
                    ],
                    [
                        -100.06111111178836,
                        35.249999999800195
                    ],
                    [
                        -100.00000000059953,
                        35.33333333266722
                    ],
                    [
                        -100.00000000059953,
                        35.82916666650851
                    ]
                ],
                [
                    [
                        -102.37250000017485,
                        35.443611110364486
                    ],
                    [
                        -102.25777777832178,
                        35.60361111076418
                    ],
                    [
                        -102.03361111126372,
                        35.748333332917014
                    ],
                    [
                        -101.7861111110389,
                        35.80999999940053
                    ],
                    [
                        -101.54638888943566,
                        35.79666666603384
                    ],
                    [
                        -101.3094444443056,
                        35.711944444480594
                    ],
                    [
                        -101.09388888926077,
                        35.52361111056433
                    ],
                    [
                        -101.01138888888607,
                        35.34194444378113
                    ],
                    [
                        -101.03111111128874,
                        35.054999999650306
                    ],
                    [
                        -101.21750000032472,
                        34.80583333264224
                    ],
                    [
                        -101.50805555611896,
                        34.667777777622405
                    ],
                    [
                        -101.8200000001998,
                        34.651388888786244
                    ],
                    [
                        -102.04333333386626,
                        34.711388888486454
                    ],
                    [
                        -102.23027777819686,
                        34.83222222217785
                    ],
                    [
                        -102.38666666693308,
                        35.047500000025025
                    ],
                    [
                        -102.42333333346653,
                        35.241111110589316
                    ],
                    [
                        -102.37250000017485,
                        35.443611110364486
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 2,
            "artcc": "zab",
            "sector": "15",
            "sectype": "low",
            "Shape_Length": 15.822036775121456,
            "Shape_Area": 6.3082519304195
        }
    },
    {
        "type": "Feature",
        "id": 4,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -104.00000000069946,
                        36.93333333306691
                    ],
                    [
                        -104.00000000069946,
                        37.045277777547426
                    ],
                    [
                        -105.0000000004996,
                        36.71666666653351
                    ],
                    [
                        -105.34166666678323,
                        36.71666666653351
                    ],
                    [
                        -106.08333333316676,
                        36.71666666653351
                    ],
                    [
                        -106.35000000049956,
                        36.626944443731134
                    ],
                    [
                        -107.466666667033,
                        36.19999999970025
                    ],
                    [
                        -106.991666667083,
                        35.42499999945045
                    ],
                    [
                        -106.64944444460542,
                        35.43805555561943
                    ],
                    [
                        -106.2850000004496,
                        35.15944444450554
                    ],
                    [
                        -106.11916666720788,
                        35.163611110564375
                    ],
                    [
                        -105.47500000044965,
                        35.20833333291705
                    ],
                    [
                        -105.06666666733281,
                        34.99999999940053
                    ],
                    [
                        -104.55000000049961,
                        34.99999999940053
                    ],
                    [
                        -104.55000000049961,
                        35.08333333316682
                    ],
                    [
                        -104.00000000069946,
                        35.34166666658342
                    ],
                    [
                        -104.00000000069946,
                        36.93333333306691
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 4,
            "artcc": "zab",
            "sector": "16",
            "sectype": "low",
            "Shape_Length": 9.504267408542209,
            "Shape_Area": 4.816399382395088
        }
    },
    {
        "type": "Feature",
        "id": 6,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -107.466666667033,
                        33.58333333256729
                    ],
                    [
                        -106.80277777787211,
                        33.58333333256729
                    ],
                    [
                        -106.7555555562439,
                        33.82916666600886
                    ],
                    [
                        -106.67499999985006,
                        34.26249999997509
                    ],
                    [
                        -106.64999999990005,
                        34.48333333256727
                    ],
                    [
                        -106.95194444508002,
                        34.48666666613377
                    ],
                    [
                        -107.34222222257745,
                        34.90833333261725
                    ],
                    [
                        -107.25166666728285,
                        35.23055555549456
                    ],
                    [
                        -107.24166666658334,
                        35.27388888826164
                    ],
                    [
                        -107.09222222217778,
                        35.41805555511985
                    ],
                    [
                        -106.991666667083,
                        35.42499999945045
                    ],
                    [
                        -107.466666667033,
                        36.19999999970025
                    ],
                    [
                        -108.21666666733279,
                        36.033333333066935
                    ],
                    [
                        -109.31666666693309,
                        35.849999999500426
                    ],
                    [
                        -109.6416666671829,
                        35.68333333286705
                    ],
                    [
                        -110.31444444465535,
                        34.79777777772233
                    ],
                    [
                        -110.0833333332667,
                        34.700000000000045
                    ],
                    [
                        -109.58333333336662,
                        34.4999999995004
                    ],
                    [
                        -108.88333333386623,
                        33.999999999600334
                    ],
                    [
                        -108.7500000001998,
                        33.87499999985016
                    ],
                    [
                        -107.99999999990001,
                        33.833333332967015
                    ],
                    [
                        -107.60000000069942,
                        33.58333333256729
                    ],
                    [
                        -107.466666667033,
                        33.58333333256729
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 6,
            "artcc": "zab",
            "sector": "17",
            "sectype": "low",
            "Shape_Length": 10.599834631376526,
            "Shape_Area": 5.877591435297904
        }
    },
    {
        "type": "Feature",
        "id": 8,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -106.66055555589418,
                        32.32499999935055
                    ],
                    [
                        -106.7830555554695,
                        32.851666665983885
                    ],
                    [
                        -106.87111111148857,
                        33.22166666668335
                    ],
                    [
                        -106.80277777787211,
                        33.58333333256729
                    ],
                    [
                        -107.466666667033,
                        33.58333333256729
                    ],
                    [
                        -107.75833333341654,
                        32.949999999900115
                    ],
                    [
                        -107.99999999990001,
                        32.41666666613378
                    ],
                    [
                        -108.20000000039965,
                        32.41666666613378
                    ],
                    [
                        -108.20000000039965,
                        31.533333333066935
                    ],
                    [
                        -106.45000000029972,
                        31.533333333066935
                    ],
                    [
                        -106.45000000029972,
                        31.566666666033882
                    ],
                    [
                        -106.7541666666583,
                        31.566666666033882
                    ],
                    [
                        -106.83833333381625,
                        31.729444443806074
                    ],
                    [
                        -106.82750000062447,
                        31.92555555544459
                    ],
                    [
                        -106.72500000064946,
                        32.083333332867085
                    ],
                    [
                        -106.56722222232764,
                        32.18694444433072
                    ],
                    [
                        -106.56722222232764,
                        32.299999999400484
                    ],
                    [
                        -106.65027777799702,
                        32.299999999400484
                    ],
                    [
                        -106.66055555589418,
                        32.32499999935055
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 8,
            "artcc": "zab",
            "sector": "19",
            "sectype": "low",
            "Shape_Length": 7.386352437996555,
            "Shape_Area": 2.400050539433139
        }
    },
    {
        "type": "Feature",
        "id": 10,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -103.93333333386624,
                        32.46666666603386
                    ],
                    [
                        -104.38333333386623,
                        32.46666666603386
                    ],
                    [
                        -105.16666666713297,
                        32.38055555489501
                    ],
                    [
                        -105.56222222217775,
                        32.38472222185311
                    ],
                    [
                        -105.70055555619393,
                        32.2499999995004
                    ],
                    [
                        -105.80222222277735,
                        32.15111111118887
                    ],
                    [
                        -105.69999999999999,
                        32.00000000000006
                    ],
                    [
                        -105.68333333396617,
                        31.666666666733363
                    ],
                    [
                        -105.83333333366636,
                        31.433333333266773
                    ],
                    [
                        -106.08333333316676,
                        31.283333332667212
                    ],
                    [
                        -106.45000000029972,
                        31.566666666033882
                    ],
                    [
                        -106.45000000029972,
                        31.533333333066935
                    ],
                    [
                        -105.80000000069941,
                        30.9999999993006
                    ],
                    [
                        -105.0000000004996,
                        30.6333333330669
                    ],
                    [
                        -104.51666666663334,
                        29.43333333276712
                    ],
                    [
                        -103.31666666723288,
                        28.949999999800184
                    ],
                    [
                        -103.30000000029975,
                        28.949999999800184
                    ],
                    [
                        -103.1500000005995,
                        28.949999999800184
                    ],
                    [
                        -102.86666666723289,
                        29.21666666623372
                    ],
                    [
                        -102.84027777769722,
                        29.361111111188904
                    ],
                    [
                        -102.67500000064945,
                        29.725000000049988
                    ],
                    [
                        -102.55833333391621,
                        29.76666666603387
                    ],
                    [
                        -102.14999999990005,
                        31.283333332667212
                    ],
                    [
                        -102.43333333326666,
                        31.37083333339166
                    ],
                    [
                        -103.11666666673324,
                        31.583333332967015
                    ],
                    [
                        -103.3333333332667,
                        31.64999999980023
                    ],
                    [
                        -103.52916666680818,
                        31.80833333341667
                    ],
                    [
                        -103.80000000019982,
                        32.033333332967004
                    ],
                    [
                        -103.93333333386624,
                        32.46666666603386
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 10,
            "artcc": "zab",
            "sector": "20",
            "sectype": "low",
            "Shape_Length": 12.951549355488723,
            "Shape_Area": 8.063102315230388
        }
    },
    {
        "type": "Feature",
        "id": 12,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -103.91666666693311,
                        34.08333333336668
                    ],
                    [
                        -103.91666666693311,
                        34.650000000099965
                    ],
                    [
                        -103.91666666693311,
                        35.026944444230764
                    ],
                    [
                        -103.49999999990001,
                        35.041666666283675
                    ],
                    [
                        -102.98333333396613,
                        35.13750000002506
                    ],
                    [
                        -102.98333333396613,
                        35.34999999960036
                    ],
                    [
                        -104.00000000069946,
                        35.34166666658342
                    ],
                    [
                        -104.55000000049961,
                        35.08333333316682
                    ],
                    [
                        -104.55000000049961,
                        34.99999999940053
                    ],
                    [
                        -104.55000000049961,
                        34.71666666603386
                    ],
                    [
                        -105.15000000019984,
                        34.4999999995004
                    ],
                    [
                        -105.15000000019984,
                        34.1458333332418
                    ],
                    [
                        -104.50000000059953,
                        33.95083333309191
                    ],
                    [
                        -104.10000000049956,
                        33.82916666600886
                    ],
                    [
                        -103.91666666693311,
                        34.08333333336668
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 12,
            "artcc": "zab",
            "sector": "21",
            "sectype": "low",
            "Shape_Length": 6.491722981490194,
            "Shape_Area": 1.4268310185516984
        }
    },
    {
        "type": "Feature",
        "id": 14,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -105.8500000005995,
                        34.283333332967004
                    ],
                    [
                        -106.06666666713295,
                        34.283333332967004
                    ],
                    [
                        -106.06666666713295,
                        33.74583333314189
                    ],
                    [
                        -105.73333333386626,
                        33.62499999945044
                    ],
                    [
                        -105.73333333386626,
                        33.26666666623373
                    ],
                    [
                        -105.45000000049959,
                        33.27499999925061
                    ],
                    [
                        -105.45000000049959,
                        32.74999999940053
                    ],
                    [
                        -105.50000000039967,
                        32.555555555444585
                    ],
                    [
                        -105.50000000039967,
                        32.44166666608385
                    ],
                    [
                        -105.56222222217775,
                        32.38472222185311
                    ],
                    [
                        -105.16666666713297,
                        32.38055555489501
                    ],
                    [
                        -104.38333333386623,
                        32.46666666603386
                    ],
                    [
                        -103.93333333386624,
                        32.46666666603386
                    ],
                    [
                        -103.84027777839674,
                        32.845833333141854
                    ],
                    [
                        -103.80000000019982,
                        32.999999999800195
                    ],
                    [
                        -103.80000000019982,
                        33.049999999700276
                    ],
                    [
                        -103.80000000019982,
                        33.38333333296697
                    ],
                    [
                        -103.69166666738278,
                        33.40277777727266
                    ],
                    [
                        -103.48749999992503,
                        33.63749999942547
                    ],
                    [
                        -104.10000000049956,
                        33.82916666600886
                    ],
                    [
                        -104.50000000059953,
                        33.95083333309191
                    ],
                    [
                        -105.15000000019984,
                        34.1458333332418
                    ],
                    [
                        -105.45000000049959,
                        33.96666666663339
                    ],
                    [
                        -105.8500000005995,
                        34.283333332967004
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 14,
            "artcc": "zab",
            "sector": "23",
            "sectype": "low",
            "Shape_Length": 8.26050553276622,
            "Shape_Area": 2.994453897325466
        }
    },
    {
        "type": "Feature",
        "id": 17,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -110.76250000067444,
                        33.491666666683386
                    ],
                    [
                        -110.50000000029974,
                        33.53333333266721
                    ],
                    [
                        -110.22499999995,
                        33.589722221603324
                    ],
                    [
                        -108.7500000001998,
                        33.87499999985016
                    ],
                    [
                        -108.88333333386623,
                        33.999999999600334
                    ],
                    [
                        -109.58333333336662,
                        34.4999999995004
                    ],
                    [
                        -111.16666666683318,
                        33.83888888861139
                    ],
                    [
                        -111.35000000039969,
                        33.80000000000007
                    ],
                    [
                        -111.32583333384127,
                        33.77583333344165
                    ],
                    [
                        -111.25916666700806,
                        33.66111111068926
                    ],
                    [
                        -111.22277777767226,
                        33.54333333336672
                    ],
                    [
                        -111.21083333389123,
                        33.44333333266724
                    ],
                    [
                        -110.76250000067444,
                        33.491666666683386
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 17,
            "artcc": "zab",
            "sector": "38",
            "sectype": "low",
            "Shape_Length": 5.8368402324275275,
            "Shape_Area": 1.3283257329345353
        }
    },
    {
        "type": "Feature",
        "id": 19,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -110.31444444465535,
                        34.79777777772233
                    ],
                    [
                        -110.90000000039964,
                        34.68333333306691
                    ],
                    [
                        -111.14855555559438,
                        34.41661111101399
                    ],
                    [
                        -111.53194444438054,
                        33.96166666628369
                    ],
                    [
                        -111.4988888886113,
                        33.943055555369654
                    ],
                    [
                        -111.41805555591912,
                        33.87805555531969
                    ],
                    [
                        -111.35000000039969,
                        33.80000000000007
                    ],
                    [
                        -111.16666666683318,
                        33.83888888861139
                    ],
                    [
                        -109.58333333336662,
                        34.50000000039972
                    ],
                    [
                        -110.0833333332667,
                        34.700000000000045
                    ],
                    [
                        -110.31444444465535,
                        34.79777777772233
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 19,
            "artcc": "zab",
            "sector": "39",
            "sectype": "low",
            "Shape_Length": 4.4940676673239155,
            "Shape_Area": 0.9005000538739181
        }
    },
    {
        "type": "Feature",
        "id": 21,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -112.65694444483023,
                        33.04333333256727
                    ],
                    [
                        -112.68666666703302,
                        33.08305555546957
                    ],
                    [
                        -112.72250000017482,
                        33.137777777622375
                    ],
                    [
                        -112.73777777842173,
                        33.16944444380607
                    ],
                    [
                        -112.77083333329165,
                        33.23777777742254
                    ],
                    [
                        -112.78694444493016,
                        33.29777777712275
                    ],
                    [
                        -112.79194444438059,
                        33.31944444440563
                    ],
                    [
                        -112.80083333359141,
                        33.39944444370616
                    ],
                    [
                        -112.8013888888861,
                        33.41194444368119
                    ],
                    [
                        -112.80027777829679,
                        33.46611111053937
                    ],
                    [
                        -112.79750000002497,
                        33.50694444403092
                    ],
                    [
                        -112.86416666685818,
                        33.54027777789719
                    ],
                    [
                        -112.93333333386624,
                        33.574999999550414
                    ],
                    [
                        -113.1508333337913,
                        33.64305555506985
                    ],
                    [
                        -113.08527777844671,
                        33.81444444395595
                    ],
                    [
                        -114.0000000004996,
                        34.01666666653347
                    ],
                    [
                        -114.0000000004996,
                        33.944444444055875
                    ],
                    [
                        -114.0000000004996,
                        33.80000000000007
                    ],
                    [
                        -114.0000000004996,
                        33.69999999930059
                    ],
                    [
                        -114.0000000004996,
                        33.609999999300555
                    ],
                    [
                        -114.0000000004996,
                        33.46666666673332
                    ],
                    [
                        -114.0000000004996,
                        33.3999999999001
                    ],
                    [
                        -113.65111111178834,
                        33.291666666183744
                    ],
                    [
                        -113.65111111178834,
                        33.03333333276714
                    ],
                    [
                        -113.62222222207788,
                        33.03333333276714
                    ],
                    [
                        -113.62222222207788,
                        32.96666666683325
                    ],
                    [
                        -113.68472222285226,
                        32.737499999425495
                    ],
                    [
                        -113.51276944465411,
                        32.11622777778979
                    ],
                    [
                        -113.5083333336164,
                        32.09999999980022
                    ],
                    [
                        -111.99999999999994,
                        31.633333332867096
                    ],
                    [
                        -111.99833333321669,
                        32.47333333316686
                    ],
                    [
                        -112.21694444463037,
                        32.598055555719384
                    ],
                    [
                        -112.30083333369134,
                        32.64166666658343
                    ],
                    [
                        -112.4277777783218,
                        32.70611111043945
                    ],
                    [
                        -112.47111111108887,
                        32.727222221528336
                    ],
                    [
                        -112.43972222210283,
                        32.80611111113893
                    ],
                    [
                        -112.34750000002492,
                        33.02249999957536
                    ],
                    [
                        -112.65694444483023,
                        33.04333333256727
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 21,
            "artcc": "zab",
            "sector": "42",
            "sectype": "low",
            "Shape_Length": 7.819512191171668,
            "Shape_Area": 2.539008235507464
        }
    },
    {
        "type": "Feature",
        "id": 22,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -112.01388888956058,
                        34.23333333306692
                    ],
                    [
                        -112.05833333381628,
                        34.34583333284206
                    ],
                    [
                        -112.07805555621889,
                        34.504999999850156
                    ],
                    [
                        -112.10000000069942,
                        34.68333333306691
                    ],
                    [
                        -112.1033333333666,
                        34.720555554894986
                    ],
                    [
                        -112.11555555614399,
                        34.88222222207793
                    ],
                    [
                        -112.1269444446304,
                        35.047500000025025
                    ],
                    [
                        -112.14777777852163,
                        35.327777777922165
                    ],
                    [
                        -112.15305555606903,
                        35.396666666833255
                    ],
                    [
                        -112.66666666653339,
                        35.383333332567304
                    ],
                    [
                        -112.92777777822187,
                        35.25555555544457
                    ],
                    [
                        -113.61666666733282,
                        34.9166666665335
                    ],
                    [
                        -113.7000000001998,
                        34.86666666663342
                    ],
                    [
                        -114.0000000004996,
                        34.66666666613378
                    ],
                    [
                        -114.0000000004996,
                        34.6166666662337
                    ],
                    [
                        -114.0000000004996,
                        34.591666666283686
                    ],
                    [
                        -114.0000000004996,
                        34.4972222221279
                    ],
                    [
                        -114.0000000004996,
                        34.3999999997003
                    ],
                    [
                        -114.0008333338912,
                        34.383333332767165
                    ],
                    [
                        -114.0000000004996,
                        34.16666666623371
                    ],
                    [
                        -114.0000000004996,
                        34.01666666653347
                    ],
                    [
                        -113.08527777844671,
                        33.81444444395595
                    ],
                    [
                        -113.01666666673327,
                        33.91333333316686
                    ],
                    [
                        -112.89888888941067,
                        34.0249999995504
                    ],
                    [
                        -112.75638888933577,
                        34.1086111105144
                    ],
                    [
                        -112.5930555562689,
                        34.16416666605886
                    ],
                    [
                        -112.44777777792206,
                        33.98361111076423
                    ],
                    [
                        -112.38416666655837,
                        33.90416666675833
                    ],
                    [
                        -112.34444444455545,
                        33.84361111086412
                    ],
                    [
                        -112.27861111111383,
                        33.75333333276717
                    ],
                    [
                        -112.25138888908594,
                        33.714444444155845
                    ],
                    [
                        -112.25000000039967,
                        33.629166666408594
                    ],
                    [
                        -111.85583333314179,
                        33.55333333316685
                    ],
                    [
                        -111.75833333351648,
                        33.80000000000007
                    ],
                    [
                        -111.99999999999994,
                        33.883333332867096
                    ],
                    [
                        -112.01666666693308,
                        34.09166666638362
                    ],
                    [
                        -112.01388888956058,
                        34.23333333306692
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 22,
            "artcc": "zab",
            "sector": "43",
            "sectype": "low",
            "Shape_Length": 7.336906748557441,
            "Shape_Area": 2.431353433881841
        }
    },
    {
        "type": "Feature",
        "id": 24,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -109.31666666693309,
                        35.849999999500426
                    ],
                    [
                        -110.23333333386626,
                        35.699999999800184
                    ],
                    [
                        -111.84166666728282,
                        35.7666666666334
                    ],
                    [
                        -111.99999999999994,
                        35.39999999950044
                    ],
                    [
                        -112.15305555606903,
                        35.396666666833255
                    ],
                    [
                        -112.14777777852163,
                        35.327777777922165
                    ],
                    [
                        -112.1269444446304,
                        35.047500000025025
                    ],
                    [
                        -112.11555555614399,
                        34.88222222207793
                    ],
                    [
                        -112.1033333333666,
                        34.720555554894986
                    ],
                    [
                        -112.10000000069942,
                        34.68333333306691
                    ],
                    [
                        -112.07805555621889,
                        34.504999999850156
                    ],
                    [
                        -112.05833333381628,
                        34.34583333284206
                    ],
                    [
                        -112.01388888956058,
                        34.23333333306692
                    ],
                    [
                        -112.01666666693308,
                        34.09166666638362
                    ],
                    [
                        -111.94500000064949,
                        34.09305555506984
                    ],
                    [
                        -111.83500000014988,
                        34.078055554919956
                    ],
                    [
                        -111.79361111136365,
                        34.070555555294675
                    ],
                    [
                        -111.64305555546946,
                        34.023888888961096
                    ],
                    [
                        -111.53194444438054,
                        33.96166666628369
                    ],
                    [
                        -111.14855555559438,
                        34.41661111101399
                    ],
                    [
                        -110.90000000039964,
                        34.68333333306691
                    ],
                    [
                        -110.31444444465535,
                        34.79777777772233
                    ],
                    [
                        -109.6416666671829,
                        35.68333333286705
                    ],
                    [
                        -109.31666666693309,
                        35.849999999500426
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 24,
            "artcc": "zab",
            "sector": "45",
            "sectype": "low",
            "Shape_Length": 7.95235461532156,
            "Shape_Area": 2.7136286501626405
        }
    },
    {
        "type": "Feature",
        "id": 26,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -110.50000000029974,
                        33.53333333266721
                    ],
                    [
                        -110.76250000067444,
                        33.491666666683386
                    ],
                    [
                        -111.21083333389123,
                        33.44333333266724
                    ],
                    [
                        -111.21527777804698,
                        33.37444444375615
                    ],
                    [
                        -111.22250000047461,
                        33.316944444230785
                    ],
                    [
                        -111.25249999987506,
                        33.215833332942
                    ],
                    [
                        -111.29361111146358,
                        33.1341666659589
                    ],
                    [
                        -111.35416666735779,
                        33.04638888893612
                    ],
                    [
                        -111.43500000004991,
                        32.9627777770728
                    ],
                    [
                        -111.70277777797202,
                        32.98055555549456
                    ],
                    [
                        -111.73194444488018,
                        32.96583333344165
                    ],
                    [
                        -111.74805555561937,
                        32.983611110964034
                    ],
                    [
                        -112.05388888876115,
                        33.0033333333667
                    ],
                    [
                        -112.1883333339162,
                        33.01249999977523
                    ],
                    [
                        -112.34750000002492,
                        33.02249999957536
                    ],
                    [
                        -112.43972222210283,
                        32.80611111113893
                    ],
                    [
                        -112.47111111108887,
                        32.727222221528336
                    ],
                    [
                        -112.4277777783218,
                        32.70611111043945
                    ],
                    [
                        -112.30083333369134,
                        32.64166666658343
                    ],
                    [
                        -112.21694444463037,
                        32.598055555719384
                    ],
                    [
                        -111.99833333321669,
                        32.47333333316686
                    ],
                    [
                        -111.99999999999994,
                        31.633333332867096
                    ],
                    [
                        -111.08333333396615,
                        31.333333332567292
                    ],
                    [
                        -110.75000000069946,
                        31.333333332567292
                    ],
                    [
                        -110.75000000069946,
                        31.349999999500426
                    ],
                    [
                        -111.08333333396615,
                        31.349999999500426
                    ],
                    [
                        -111.18611111113881,
                        31.383333333366693
                    ],
                    [
                        -111.08194444438055,
                        31.64861111111395
                    ],
                    [
                        -111.06666666703302,
                        31.816666666433548
                    ],
                    [
                        -111.53333333396614,
                        31.816666666433548
                    ],
                    [
                        -111.59999999990004,
                        31.96250000007501
                    ],
                    [
                        -111.59999999990004,
                        32.25277777777228
                    ],
                    [
                        -111.66666666673325,
                        32.28333333336667
                    ],
                    [
                        -111.52916666700804,
                        32.499999999900126
                    ],
                    [
                        -111.48888888881112,
                        32.57083333279212
                    ],
                    [
                        -111.08305555586918,
                        32.517222222127884
                    ],
                    [
                        -110.77222222237765,
                        32.474999999950114
                    ],
                    [
                        -110.50000000029974,
                        32.85000000009995
                    ],
                    [
                        -110.50000000029974,
                        33.53333333266721
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 26,
            "artcc": "zab",
            "sector": "46",
            "sectype": "low",
            "Shape_Length": 9.294334341586492,
            "Shape_Area": 1.7955772377764707
        }
    },
    {
        "type": "Feature",
        "id": 27,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -107.99999999990001,
                        33.833333332967015
                    ],
                    [
                        -108.7500000001998,
                        33.87499999985016
                    ],
                    [
                        -110.22499999995,
                        33.589722221603324
                    ],
                    [
                        -110.50000000029974,
                        33.53333333266721
                    ],
                    [
                        -110.50000000029974,
                        32.85000000009995
                    ],
                    [
                        -110.77222222237765,
                        32.474999999950114
                    ],
                    [
                        -110.90000000039964,
                        32.299999999400484
                    ],
                    [
                        -110.76666666673327,
                        32.183333332667246
                    ],
                    [
                        -110.76666666673327,
                        31.799999999500415
                    ],
                    [
                        -110.75000000069946,
                        31.349999999500426
                    ],
                    [
                        -110.75000000069946,
                        31.333333332567292
                    ],
                    [
                        -108.50000000069946,
                        31.333333332567292
                    ],
                    [
                        -108.20000000039965,
                        31.333333332567292
                    ],
                    [
                        -108.20000000039965,
                        31.533333333066935
                    ],
                    [
                        -108.20000000039965,
                        32.41666666613378
                    ],
                    [
                        -107.99999999990001,
                        32.41666666613378
                    ],
                    [
                        -107.75833333341654,
                        32.949999999900115
                    ],
                    [
                        -107.466666667033,
                        33.58333333256729
                    ],
                    [
                        -107.60000000069942,
                        33.58333333256729
                    ],
                    [
                        -107.99999999990001,
                        33.833333332967015
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 27,
            "artcc": "zab",
            "sector": "47",
            "sectype": "low",
            "Shape_Length": 10.646261438534161,
            "Shape_Area": 6.556527778788765
        }
    },
    {
        "type": "Feature",
        "id": 28,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -111.35416666735779,
                        33.04638888893612
                    ],
                    [
                        -111.43500000004991,
                        32.9627777770728
                    ],
                    [
                        -111.70277777797202,
                        32.98055555549456
                    ],
                    [
                        -111.73194444488018,
                        32.96583333344165
                    ],
                    [
                        -111.74805555561937,
                        32.983611110964034
                    ],
                    [
                        -112.05388888876115,
                        33.0033333333667
                    ],
                    [
                        -112.1883333339162,
                        33.01249999977523
                    ],
                    [
                        -112.34750000002492,
                        33.02249999957536
                    ],
                    [
                        -112.43972222210283,
                        32.80611111113893
                    ],
                    [
                        -112.47111111108887,
                        32.727222221528336
                    ],
                    [
                        -112.4277777783218,
                        32.70611111043945
                    ],
                    [
                        -112.30083333369134,
                        32.64166666658343
                    ],
                    [
                        -112.21694444463037,
                        32.598055555719384
                    ],
                    [
                        -111.99833333321669,
                        32.47333333316686
                    ],
                    [
                        -111.66666666673325,
                        32.28333333336667
                    ],
                    [
                        -111.52916666700804,
                        32.499999999900126
                    ],
                    [
                        -111.48888888881112,
                        32.57083333279212
                    ],
                    [
                        -111.08305555586918,
                        32.517222222127884
                    ],
                    [
                        -111.13333333386623,
                        32.900000000000034
                    ],
                    [
                        -111.35416666735779,
                        33.04638888893612
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 28,
            "artcc": "zab",
            "sector": "49",
            "sectype": "low",
            "Shape_Length": 3.6796653241298527,
            "Shape_Area": 0.6383113809350619
        }
    },
    {
        "type": "Feature",
        "id": 48,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -106.64999999990005,
                        34.483333333466646
                    ],
                    [
                        -106.67499999985006,
                        34.26249999997509
                    ],
                    [
                        -106.75555555534459,
                        33.82916666690818
                    ],
                    [
                        -106.27499999975015,
                        33.82499999995008
                    ],
                    [
                        -106.06666666713295,
                        33.74583333314189
                    ],
                    [
                        -106.06666666713295,
                        34.283333332967004
                    ],
                    [
                        -105.84999999970017,
                        34.283333332967004
                    ],
                    [
                        -105.44999999960027,
                        33.96666666663339
                    ],
                    [
                        -105.15000000019984,
                        34.1458333332418
                    ],
                    [
                        -105.15000000019984,
                        34.50000000039972
                    ],
                    [
                        -104.54999999960029,
                        34.71666666693318
                    ],
                    [
                        -104.54999999960029,
                        35.00000000029985
                    ],
                    [
                        -105.06666666643349,
                        35.00000000029985
                    ],
                    [
                        -105.47500000044965,
                        35.20833333291705
                    ],
                    [
                        -106.11916666630856,
                        35.163611111463695
                    ],
                    [
                        -106.11416666685818,
                        34.95694444473037
                    ],
                    [
                        -106.09194444428067,
                        34.7844444443557
                    ],
                    [
                        -106.38611111083907,
                        34.47499999955039
                    ],
                    [
                        -106.64999999990005,
                        34.483333333466646
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 48,
            "artcc": "zab",
            "sector": "94",
            "sectype": "low",
            "Shape_Length": 6.948055080953363,
            "Shape_Area": 1.66609047015353
        }
    },
    {
        "type": "Feature",
        "id": 63,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -86.21666666633354,
                        43.65111111068927
                    ],
                    [
                        -86.21666666633354,
                        43.56666666633362
                    ],
                    [
                        -86.21666666633354,
                        43.02777777782222
                    ],
                    [
                        -86.21666666633354,
                        42.805555555644446
                    ],
                    [
                        -86.21222222217779,
                        42.62416666695816
                    ],
                    [
                        -86.20027777749738,
                        42.387222221828154
                    ],
                    [
                        -86.0138888884614,
                        42.38888888861135
                    ],
                    [
                        -84.99999999999994,
                        42.373888888461465
                    ],
                    [
                        -84.99999999999994,
                        42.523611110964055
                    ],
                    [
                        -84.9999916668819,
                        42.599116666501004
                    ],
                    [
                        -84.99998333286447,
                        42.68865833331546
                    ],
                    [
                        -84.99999999999994,
                        42.743333332966984
                    ],
                    [
                        -84.99999999999994,
                        42.96666666663339
                    ],
                    [
                        -84.99999999999994,
                        43.03611111083916
                    ],
                    [
                        -84.99999999999994,
                        43.177777777522465
                    ],
                    [
                        -84.99999999999994,
                        43.50000000039978
                    ],
                    [
                        -84.9636111106642,
                        43.58666666683325
                    ],
                    [
                        -85.74166666638354,
                        43.628055555619426
                    ],
                    [
                        -86.21666666633354,
                        43.65111111068927
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 63,
            "artcc": "zau",
            "sector": "22",
            "sectype": "low",
            "Shape_Length": 4.939462405219216,
            "Shape_Area": 1.5050274297404969
        }
    },
    {
        "type": "Feature",
        "id": 67,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -87.31666666683316,
                        42.339999999300574
                    ],
                    [
                        -87.0000000004996,
                        42.35694444433068
                    ],
                    [
                        -86.31666666703302,
                        42.38333333296697
                    ],
                    [
                        -86.2002777783967,
                        42.387222221828154
                    ],
                    [
                        -86.21222222217779,
                        42.62416666605884
                    ],
                    [
                        -86.21666666723286,
                        42.805555555644446
                    ],
                    [
                        -87.0000000004996,
                        42.80000000000007
                    ],
                    [
                        -87.2191666672079,
                        42.77944444420581
                    ],
                    [
                        -87.35305555616895,
                        42.73555555524473
                    ],
                    [
                        -87.44750000032474,
                        42.70444444435566
                    ],
                    [
                        -87.42944444470533,
                        42.499999999700265
                    ],
                    [
                        -87.41388888926082,
                        42.33472222175317
                    ],
                    [
                        -87.31666666683316,
                        42.339999999300574
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 67,
            "artcc": "zau",
            "sector": "26",
            "sectype": "low",
            "Shape_Length": 3.248535612444499,
            "Shape_Area": 0.5222770451322958
        }
    },
    {
        "type": "Feature",
        "id": 68,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -87.35305555616895,
                        42.73555555524473
                    ],
                    [
                        -87.2191666672079,
                        42.77944444420581
                    ],
                    [
                        -87.0000000004996,
                        42.80000000000007
                    ],
                    [
                        -86.21666666723286,
                        42.805555555644446
                    ],
                    [
                        -86.21666666723286,
                        43.02777777782222
                    ],
                    [
                        -86.21666666723286,
                        43.56666666633362
                    ],
                    [
                        -86.21666666723286,
                        43.65111111068927
                    ],
                    [
                        -87.0000000004996,
                        43.68499999985016
                    ],
                    [
                        -87.16944444450547,
                        43.69166666608385
                    ],
                    [
                        -87.63333333316677,
                        43.522222222077914
                    ],
                    [
                        -87.64833333331666,
                        42.896111110939046
                    ],
                    [
                        -87.44750000032474,
                        42.70444444435566
                    ],
                    [
                        -87.35305555616895,
                        42.73555555524473
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 68,
            "artcc": "zau",
            "sector": "27",
            "sectype": "low",
            "Shape_Length": 4.440786717765006,
            "Shape_Area": 1.224236727422162
        }
    },
    {
        "type": "Feature",
        "id": 69,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -86.10000000049956,
                        39.9999999993006
                    ],
                    [
                        -85.95555555554444,
                        40.119444444305714
                    ],
                    [
                        -85.79750000002497,
                        40.27083333269218
                    ],
                    [
                        -85.56138888918588,
                        40.50277777747249
                    ],
                    [
                        -85.7333333333666,
                        40.88333333326676
                    ],
                    [
                        -85.83333333316676,
                        41.12222222147841
                    ],
                    [
                        -85.94555555574425,
                        41.13694444443064
                    ],
                    [
                        -86.05000000059954,
                        41.1499999997003
                    ],
                    [
                        -86.11759166692184,
                        41.15918888835654
                    ],
                    [
                        -86.63258611096023,
                        41.22412777767488
                    ],
                    [
                        -86.64638888953556,
                        41.22583333294199
                    ],
                    [
                        -87.0000000004996,
                        41.3741666667583
                    ],
                    [
                        -87.13668611107511,
                        41.431025000003785
                    ],
                    [
                        -87.16972222260245,
                        41.44472222155332
                    ],
                    [
                        -87.29083333349149,
                        41.54583333284211
                    ],
                    [
                        -87.43472222225273,
                        41.667222221828126
                    ],
                    [
                        -87.46444444445552,
                        41.508888888211686
                    ],
                    [
                        -87.4297222228023,
                        41.474999999950114
                    ],
                    [
                        -87.33055555549447,
                        41.38055555489501
                    ],
                    [
                        -87.0000000004996,
                        41.06666666683327
                    ],
                    [
                        -86.9583333336164,
                        41.01666666603387
                    ],
                    [
                        -86.9286111114136,
                        40.98638888853645
                    ],
                    [
                        -86.72166666658336,
                        40.75222222167827
                    ],
                    [
                        -86.61999999999995,
                        40.633333332867096
                    ],
                    [
                        -86.33333333396615,
                        40.31249999957538
                    ],
                    [
                        -86.34999999999997,
                        40.22499999975025
                    ],
                    [
                        -86.31666666703302,
                        39.9999999993006
                    ],
                    [
                        -86.10000000049956,
                        39.9999999993006
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 69,
            "artcc": "zau",
            "sector": "32",
            "sectype": "low",
            "Shape_Length": 5.489066772070436,
            "Shape_Area": 1.1456508711416578
        }
    },
    {
        "type": "Feature",
        "id": 74,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -84.68777777782213,
                        40.744444443955956
                    ],
                    [
                        -84.70000000059952,
                        40.90833333321683
                    ],
                    [
                        -84.70916388900224,
                        41.15869999980521
                    ],
                    [
                        -84.71666666663333,
                        41.28333333336667
                    ],
                    [
                        -85.10833333371636,
                        41.38333333316683
                    ],
                    [
                        -85.0597222225025,
                        41.589166666508504
                    ],
                    [
                        -85.04722222252752,
                        41.64027777789721
                    ],
                    [
                        -85.47083333389122,
                        41.67083333259228
                    ],
                    [
                        -86.00000000069946,
                        41.683333332567315
                    ],
                    [
                        -85.9883333332167,
                        41.599722221603315
                    ],
                    [
                        -85.9386194445317,
                        41.373255555499554
                    ],
                    [
                        -85.93388888916087,
                        41.351666666083815
                    ],
                    [
                        -85.83333333316676,
                        41.12222222147841
                    ],
                    [
                        -85.7333333333666,
                        40.88333333326676
                    ],
                    [
                        -85.56138888918588,
                        40.50277777747249
                    ],
                    [
                        -85.50416666685817,
                        40.55833333301695
                    ],
                    [
                        -85.43750000002495,
                        40.637499999825195
                    ],
                    [
                        -85.41444444495511,
                        40.66333333316686
                    ],
                    [
                        -84.99194444508004,
                        40.60694444423075
                    ],
                    [
                        -84.68777777782213,
                        40.744444443955956
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 74,
            "artcc": "zau",
            "sector": "37",
            "sectype": "low",
            "Shape_Length": 4.405498465978445,
            "Shape_Area": 1.04488234412997
        }
    },
    {
        "type": "Feature",
        "id": 75,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -88.56666666703302,
                        40.44166666628365
                    ],
                    [
                        -88.08750000012486,
                        40.508333333116866
                    ],
                    [
                        -88.00000000029974,
                        41.06666666683327
                    ],
                    [
                        -87.98805555561938,
                        41.24944444420578
                    ],
                    [
                        -87.95388888926078,
                        41.40861111121387
                    ],
                    [
                        -87.89583333354147,
                        41.96666666683325
                    ],
                    [
                        -88.15583333374133,
                        41.96444444385605
                    ],
                    [
                        -88.15250000017483,
                        41.779999999700294
                    ],
                    [
                        -88.25000000069946,
                        41.68388888876126
                    ],
                    [
                        -88.25000000069946,
                        41.40222222217784
                    ],
                    [
                        -88.61805555561938,
                        40.773888888961096
                    ],
                    [
                        -88.66249999987508,
                        40.74055555509483
                    ],
                    [
                        -88.66972222230265,
                        40.68916666650853
                    ],
                    [
                        -88.70166666658338,
                        40.37000000000006
                    ],
                    [
                        -88.56666666703302,
                        40.44166666628365
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 75,
            "artcc": "zau",
            "sector": "43",
            "sectype": "low",
            "Shape_Length": 4.128264530574754,
            "Shape_Area": 0.6213521221535542
        }
    },
    {
        "type": "Feature",
        "id": 76,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -87.29166666688315,
                        40.76666666653347
                    ],
                    [
                        -87.05000000039968,
                        40.76666666653347
                    ],
                    [
                        -86.9286111114136,
                        40.98638888853645
                    ],
                    [
                        -86.9583333336164,
                        41.01666666603387
                    ],
                    [
                        -87.0000000004996,
                        41.06666666683327
                    ],
                    [
                        -87.33055555549447,
                        41.38055555489501
                    ],
                    [
                        -87.4297222228023,
                        41.474999999950114
                    ],
                    [
                        -87.46444444445552,
                        41.508888888211686
                    ],
                    [
                        -87.43472222225273,
                        41.667222221828126
                    ],
                    [
                        -87.47999999990003,
                        41.699722222302796
                    ],
                    [
                        -87.5908333337913,
                        41.80666666643356
                    ],
                    [
                        -87.67916666700802,
                        41.96666666683325
                    ],
                    [
                        -87.89583333354147,
                        41.96666666683325
                    ],
                    [
                        -87.95388888926078,
                        41.40861111121387
                    ],
                    [
                        -87.98805555561938,
                        41.24944444420578
                    ],
                    [
                        -88.00000000029974,
                        41.06666666683327
                    ],
                    [
                        -88.08750000012486,
                        40.508333333116866
                    ],
                    [
                        -87.40416666665828,
                        40.49166666618373
                    ],
                    [
                        -87.29166666688315,
                        40.76666666653347
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 76,
            "artcc": "zau",
            "sector": "44",
            "sectype": "low",
            "Shape_Length": 4.464625823897147,
            "Shape_Area": 1.0091332564171618
        }
    },
    {
        "type": "Feature",
        "id": 81,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -88.25000000069946,
                        41.40222222217784
                    ],
                    [
                        -88.30138888928576,
                        41.40972222180312
                    ],
                    [
                        -88.47916666720789,
                        41.43444444365622
                    ],
                    [
                        -88.63888888951061,
                        41.662499999575346
                    ],
                    [
                        -88.7333333336664,
                        41.663888888261624
                    ],
                    [
                        -88.74583333364137,
                        41.68055555519476
                    ],
                    [
                        -89.15916666710797,
                        41.68805555571936
                    ],
                    [
                        -89.22361111096399,
                        41.66611111123882
                    ],
                    [
                        -89.7250000004496,
                        41.49166666598393
                    ],
                    [
                        -89.79583333334165,
                        41.14166666668336
                    ],
                    [
                        -89.64750000042466,
                        41.06583333344162
                    ],
                    [
                        -89.29166666738274,
                        40.8916666662837
                    ],
                    [
                        -89.14555555564436,
                        40.74055555509483
                    ],
                    [
                        -88.66249999987508,
                        40.74055555509483
                    ],
                    [
                        -88.52916666710797,
                        40.583333332967015
                    ],
                    [
                        -88.56666666703302,
                        40.44166666628365
                    ],
                    [
                        -88.08750000012486,
                        40.508333333116866
                    ],
                    [
                        -88.00000000029974,
                        41.06666666683327
                    ],
                    [
                        -87.98805555561938,
                        41.24944444420578
                    ],
                    [
                        -87.95388888926078,
                        41.40861111121387
                    ],
                    [
                        -88.21666666683319,
                        41.397222221828144
                    ],
                    [
                        -88.25000000069946,
                        41.40222222217784
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 81,
            "artcc": "zau",
            "sector": "50",
            "sectype": "low",
            "Shape_Length": 5.294910755089194,
            "Shape_Area": 1.4410872685297422
        }
    },
    {
        "type": "Feature",
        "id": 82,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -89.15916666710797,
                        41.68805555571936
                    ],
                    [
                        -89.22361111096399,
                        41.66611111123882
                    ],
                    [
                        -89.7250000004496,
                        41.49166666598393
                    ],
                    [
                        -90.02444444455546,
                        41.01194444378109
                    ],
                    [
                        -89.97444444465538,
                        40.96222222197804
                    ],
                    [
                        -89.8902777783967,
                        40.869444443706186
                    ],
                    [
                        -89.78916666710796,
                        40.8216666667833
                    ],
                    [
                        -89.40777777792209,
                        40.694722222152905
                    ],
                    [
                        -89.09916666740776,
                        40.58805555521974
                    ],
                    [
                        -88.66972222230265,
                        40.68916666650853
                    ],
                    [
                        -88.66249999987508,
                        40.74055555509483
                    ],
                    [
                        -88.61805555561938,
                        40.773888888961096
                    ],
                    [
                        -88.25000000069946,
                        41.40222222217784
                    ],
                    [
                        -88.25000000069946,
                        41.68388888876126
                    ],
                    [
                        -88.65222222287724,
                        41.68305555536966
                    ],
                    [
                        -88.74583333364137,
                        41.68055555519476
                    ],
                    [
                        -89.15916666710797,
                        41.68805555571936
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 82,
            "artcc": "zau",
            "sector": "51",
            "sectype": "low",
            "Shape_Length": 4.668320814997932,
            "Shape_Area": 1.3927388885320806
        }
    },
    {
        "type": "Feature",
        "id": 84,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -90.41666666693311,
                        40.81666666643355
                    ],
                    [
                        -90.2861111115385,
                        40.85916666670835
                    ],
                    [
                        -89.97444444465538,
                        40.96222222197804
                    ],
                    [
                        -90.02444444455546,
                        41.01194444378109
                    ],
                    [
                        -89.7250000004496,
                        41.49166666598393
                    ],
                    [
                        -90.04583333374131,
                        42.079166666608444
                    ],
                    [
                        -90.22916666730782,
                        42.14583333344166
                    ],
                    [
                        -90.95833333371633,
                        42.04388888876127
                    ],
                    [
                        -91.33694444463038,
                        41.98916666660841
                    ],
                    [
                        -91.65666666733279,
                        41.8338888884615
                    ],
                    [
                        -91.87083333369134,
                        41.72916666640856
                    ],
                    [
                        -91.81666666683316,
                        41.319444443706175
                    ],
                    [
                        -91.74166666698306,
                        40.76388888826165
                    ],
                    [
                        -91.65000000019984,
                        40.78333333256728
                    ],
                    [
                        -91.22500000014986,
                        40.44166666628365
                    ],
                    [
                        -90.89999999990005,
                        40.42083333329174
                    ],
                    [
                        -90.72416666685814,
                        40.40777777712276
                    ],
                    [
                        -90.5680555562189,
                        40.33888888821167
                    ],
                    [
                        -90.41666666693311,
                        40.81666666643355
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 84,
            "artcc": "zau",
            "sector": "55",
            "sectype": "low",
            "Shape_Length": 6.465401622071497,
            "Shape_Area": 2.6744035112225166
        }
    },
    {
        "type": "Feature",
        "id": 85,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -91.66666666713297,
                        42.783333333066935
                    ],
                    [
                        -91.72500000004993,
                        42.7249999992506
                    ],
                    [
                        -92.46666666733279,
                        42.756944444430644
                    ],
                    [
                        -93.0000000001998,
                        42.783333333066935
                    ],
                    [
                        -93.05000000009989,
                        42.66666666633364
                    ],
                    [
                        -93.2375000006245,
                        42.22916666630863
                    ],
                    [
                        -93.26666666663334,
                        42.158333333416635
                    ],
                    [
                        -93.35555555604407,
                        41.947222221628294
                    ],
                    [
                        -93.46666666713293,
                        41.6666666665335
                    ],
                    [
                        -93.48499999994999,
                        41.566111110539396
                    ],
                    [
                        -93.50833333401613,
                        41.43333333306691
                    ],
                    [
                        -93.59999999990004,
                        41.16666666663343
                    ],
                    [
                        -93.58333333386622,
                        41.06666666683327
                    ],
                    [
                        -93.56249999997499,
                        40.9444444444556
                    ],
                    [
                        -93.55694444433061,
                        40.91749999962536
                    ],
                    [
                        -93.491666667083,
                        40.52500000005
                    ],
                    [
                        -92.71666666683319,
                        40.63472222155332
                    ],
                    [
                        -91.74166666698306,
                        40.76388888826165
                    ],
                    [
                        -91.81666666683316,
                        41.319444443706175
                    ],
                    [
                        -91.87083333369134,
                        41.72916666640856
                    ],
                    [
                        -91.65666666733279,
                        41.8338888884615
                    ],
                    [
                        -91.72500000004993,
                        41.97499999985018
                    ],
                    [
                        -91.70416666705802,
                        42.2499999993006
                    ],
                    [
                        -91.70000000009986,
                        42.3000000001
                    ],
                    [
                        -91.66666666713297,
                        42.783333333066935
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 85,
            "artcc": "zau",
            "sector": "56",
            "sectype": "low",
            "Shape_Length": 7.684704357858538,
            "Shape_Area": 3.445252122221273
        }
    },
    {
        "type": "Feature",
        "id": 86,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -87.2686111118133,
                        39.9999999993006
                    ],
                    [
                        -86.95000000059952,
                        39.9999999993006
                    ],
                    [
                        -86.41666666683318,
                        39.9999999993006
                    ],
                    [
                        -86.31666666703302,
                        39.9999999993006
                    ],
                    [
                        -86.34999999999997,
                        40.22499999975025
                    ],
                    [
                        -86.33333333396615,
                        40.31249999957538
                    ],
                    [
                        -86.61999999999995,
                        40.633333332867096
                    ],
                    [
                        -86.72166666658336,
                        40.75222222167827
                    ],
                    [
                        -86.9286111114136,
                        40.98638888853645
                    ],
                    [
                        -86.84388888896103,
                        41.13861111121389
                    ],
                    [
                        -87.02361111176339,
                        41.22083333259229
                    ],
                    [
                        -87.10000000029976,
                        41.28333333336667
                    ],
                    [
                        -87.20194444498014,
                        41.35027777739754
                    ],
                    [
                        -87.33055555549447,
                        41.38055555489501
                    ],
                    [
                        -87.42777777792207,
                        41.40555555574434
                    ],
                    [
                        -87.52611111093898,
                        41.425000000050034
                    ],
                    [
                        -87.62222222277734,
                        41.422222221778156
                    ],
                    [
                        -87.80083333319175,
                        41.4144444440559
                    ],
                    [
                        -87.95388888926078,
                        41.40861111121387
                    ],
                    [
                        -87.98805555561938,
                        41.24944444420578
                    ],
                    [
                        -88.00000000029974,
                        41.06666666683327
                    ],
                    [
                        -88.08750000012486,
                        40.508333333116866
                    ],
                    [
                        -88.16666666693311,
                        39.9999999993006
                    ],
                    [
                        -87.2686111118133,
                        39.9999999993006
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 86,
            "artcc": "zau",
            "sector": "57",
            "sectype": "low",
            "Shape_Length": 5.844636729538498,
            "Shape_Area": 1.9417402012768539
        }
    },
    {
        "type": "Feature",
        "id": 87,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -89.40777777792209,
                        40.694722222152905
                    ],
                    [
                        -89.78916666710796,
                        40.8216666667833
                    ],
                    [
                        -89.8902777783967,
                        40.869444443706186
                    ],
                    [
                        -89.97444444465538,
                        40.96222222197804
                    ],
                    [
                        -90.2861111115385,
                        40.85916666670835
                    ],
                    [
                        -90.41666666693311,
                        40.81666666643355
                    ],
                    [
                        -91.13805555561936,
                        40.57666666673333
                    ],
                    [
                        -90.92777777812194,
                        40.48888888881123
                    ],
                    [
                        -90.72416666685814,
                        40.40777777712276
                    ],
                    [
                        -90.5680555562189,
                        40.33888888821167
                    ],
                    [
                        -90.33333333316676,
                        40.241666666683386
                    ],
                    [
                        -89.8758333335415,
                        40.05249999937553
                    ],
                    [
                        -89.75000000039967,
                        39.9999999993006
                    ],
                    [
                        -89.18472222235266,
                        39.9999999993006
                    ],
                    [
                        -88.9500000001998,
                        39.9999999993006
                    ],
                    [
                        -88.25000000069946,
                        39.9999999993006
                    ],
                    [
                        -88.16666666693311,
                        39.9999999993006
                    ],
                    [
                        -88.08750000012486,
                        40.508333333116866
                    ],
                    [
                        -88.56666666703302,
                        40.44166666628365
                    ],
                    [
                        -88.70166666658338,
                        40.37000000000006
                    ],
                    [
                        -88.66972222230265,
                        40.68916666650853
                    ],
                    [
                        -89.09916666740776,
                        40.58805555521974
                    ],
                    [
                        -89.40777777792209,
                        40.694722222152905
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 87,
            "artcc": "zau",
            "sector": "58",
            "sectype": "low",
            "Shape_Length": 7.19092112269094,
            "Shape_Area": 1.664753782005244
        }
    },
    {
        "type": "Feature",
        "id": 90,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -87.63333333316677,
                        43.522222222077914
                    ],
                    [
                        -87.96250000037469,
                        43.4999999995004
                    ],
                    [
                        -88.30000000059954,
                        43.51249999947544
                    ],
                    [
                        -88.51666666713294,
                        43.51666666643354
                    ],
                    [
                        -88.58333333396615,
                        42.999999999600334
                    ],
                    [
                        -88.54444444445551,
                        42.841666665983894
                    ],
                    [
                        -88.34361111146359,
                        42.58749999952539
                    ],
                    [
                        -88.27500000064947,
                        42.499999999700265
                    ],
                    [
                        -88.18027777839671,
                        42.262222222077924
                    ],
                    [
                        -88.16249999997495,
                        42.21666666633365
                    ],
                    [
                        -88.15583333374133,
                        41.96444444385605
                    ],
                    [
                        -87.89583333354147,
                        41.96666666683325
                    ],
                    [
                        -87.67916666700802,
                        41.96666666683325
                    ],
                    [
                        -87.67611111153855,
                        41.999999999800195
                    ],
                    [
                        -87.66833333381629,
                        42.13694444423078
                    ],
                    [
                        -87.59750000002492,
                        42.2636111107642
                    ],
                    [
                        -87.41388888926082,
                        42.33472222175317
                    ],
                    [
                        -87.42944444470533,
                        42.499999999700265
                    ],
                    [
                        -87.44750000032474,
                        42.70444444435566
                    ],
                    [
                        -87.64833333331666,
                        42.896111110939046
                    ],
                    [
                        -87.63333333316677,
                        43.522222222077914
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 90,
            "artcc": "zau",
            "sector": "62",
            "sectype": "low",
            "Shape_Length": 4.825130095823782,
            "Shape_Area": 1.2667557487739807
        }
    },
    {
        "type": "Feature",
        "id": 91,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -90.03444444435559,
                        42.233333333266785
                    ],
                    [
                        -90.07944444480523,
                        42.249722222102946
                    ],
                    [
                        -90.00416666685817,
                        42.42499999985017
                    ],
                    [
                        -89.75833333341654,
                        42.68333333326677
                    ],
                    [
                        -89.84027777849667,
                        42.77055555499493
                    ],
                    [
                        -89.93083333379127,
                        42.86666666683328
                    ],
                    [
                        -90.08055555629386,
                        43.0249999995504
                    ],
                    [
                        -90.24444444465536,
                        43.200000000099976
                    ],
                    [
                        -90.17500000044964,
                        43.41666666663343
                    ],
                    [
                        -90.76527777844666,
                        43.68888888871129
                    ],
                    [
                        -90.82916666700805,
                        43.61805555491998
                    ],
                    [
                        -90.85416666695806,
                        43.595833333241785
                    ],
                    [
                        -91.08333333346656,
                        43.36666666673335
                    ],
                    [
                        -91.11861111131373,
                        43.333333332867085
                    ],
                    [
                        -91.22083333319176,
                        43.23333333306692
                    ],
                    [
                        -91.66666666713297,
                        42.783333333066935
                    ],
                    [
                        -91.70000000009986,
                        42.3000000001
                    ],
                    [
                        -91.70416666705802,
                        42.2499999993006
                    ],
                    [
                        -91.72500000004993,
                        41.97499999985018
                    ],
                    [
                        -91.65666666733279,
                        41.8338888884615
                    ],
                    [
                        -91.33694444463038,
                        41.98916666660841
                    ],
                    [
                        -90.95833333371633,
                        42.04388888876127
                    ],
                    [
                        -90.92499999985006,
                        41.999999999800195
                    ],
                    [
                        -90.66666666733278,
                        42.00833333281713
                    ],
                    [
                        -90.22916666730782,
                        42.14583333344166
                    ],
                    [
                        -90.04583333374131,
                        42.079166666608444
                    ],
                    [
                        -90.03444444435559,
                        42.233333333266785
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 91,
            "artcc": "zau",
            "sector": "63",
            "sectype": "low",
            "Shape_Length": 6.28718330499994,
            "Shape_Area": 2.238382947203286
        }
    },
    {
        "type": "Feature",
        "id": 92,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -87.63333333316677,
                        43.522222222077914
                    ],
                    [
                        -87.16944444450547,
                        43.69166666608385
                    ],
                    [
                        -87.54166666728281,
                        43.96666666643358
                    ],
                    [
                        -88.46666666723286,
                        44.10000000009995
                    ],
                    [
                        -88.7541666666583,
                        44.15944444450554
                    ],
                    [
                        -89.07611111143859,
                        44.22499999985018
                    ],
                    [
                        -89.37083333329167,
                        44.18333333296698
                    ],
                    [
                        -89.58750000072439,
                        44.15416666605887
                    ],
                    [
                        -89.62916666670827,
                        44.15416666605887
                    ],
                    [
                        -89.97777777822188,
                        44.15416666605887
                    ],
                    [
                        -90.08333333366636,
                        44.15416666605887
                    ],
                    [
                        -90.29166666718288,
                        44.15416666605887
                    ],
                    [
                        -90.37027777779718,
                        44.085833333341725
                    ],
                    [
                        -90.42222222257749,
                        44.038888888911174
                    ],
                    [
                        -90.55000000059954,
                        43.89999999960037
                    ],
                    [
                        -90.64166666738276,
                        43.81666666673334
                    ],
                    [
                        -90.76527777844666,
                        43.68888888871129
                    ],
                    [
                        -90.17500000044964,
                        43.41666666663343
                    ],
                    [
                        -90.24444444465536,
                        43.200000000099976
                    ],
                    [
                        -90.08055555629386,
                        43.0249999995504
                    ],
                    [
                        -89.93083333379127,
                        42.86666666683328
                    ],
                    [
                        -89.84027777849667,
                        42.77055555499493
                    ],
                    [
                        -89.75833333341654,
                        42.68333333326677
                    ],
                    [
                        -89.35880555563187,
                        42.762258333355476
                    ],
                    [
                        -89.08722222272735,
                        42.81444444395595
                    ],
                    [
                        -88.81666666653342,
                        42.86666666683328
                    ],
                    [
                        -88.54444444445551,
                        42.841666665983894
                    ],
                    [
                        -88.58333333396615,
                        42.999999999600334
                    ],
                    [
                        -88.51666666713294,
                        43.51666666643354
                    ],
                    [
                        -88.30000000059954,
                        43.51249999947544
                    ],
                    [
                        -87.96250000037469,
                        43.4999999995004
                    ],
                    [
                        -87.63333333316677,
                        43.522222222077914
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 92,
            "artcc": "zau",
            "sector": "64",
            "sectype": "low",
            "Shape_Length": 8.786366920014027,
            "Shape_Area": 3.0549884847523354
        }
    },
    {
        "type": "Feature",
        "id": 94,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -89.20611111153852,
                        42.212499999375495
                    ],
                    [
                        -88.96055555619392,
                        42.214444444255776
                    ],
                    [
                        -88.81611111123874,
                        42.215833332942
                    ],
                    [
                        -88.64888888931074,
                        42.215833332942
                    ],
                    [
                        -88.5713888892858,
                        42.21611111103897
                    ],
                    [
                        -88.16249999997495,
                        42.21666666633365
                    ],
                    [
                        -88.18027777839671,
                        42.262222222077924
                    ],
                    [
                        -88.27500000064947,
                        42.499999999700265
                    ],
                    [
                        -88.34361111146359,
                        42.58749999952539
                    ],
                    [
                        -88.54444444445551,
                        42.841666665983894
                    ],
                    [
                        -88.81666666653342,
                        42.86666666683328
                    ],
                    [
                        -89.47500000004993,
                        42.86666666683328
                    ],
                    [
                        -89.65750000022479,
                        42.86666666683328
                    ],
                    [
                        -89.93083333379127,
                        42.86666666683328
                    ],
                    [
                        -90.35583333384125,
                        42.66972222180317
                    ],
                    [
                        -90.36527777834675,
                        42.48388888896113
                    ],
                    [
                        -90.37500000004991,
                        42.36305555526974
                    ],
                    [
                        -90.07944444480523,
                        42.249722222102946
                    ],
                    [
                        -90.03444444435559,
                        42.233333333266785
                    ],
                    [
                        -89.67000000019982,
                        42.20749999992512
                    ],
                    [
                        -89.20611111153852,
                        42.212499999375495
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 94,
            "artcc": "zau",
            "sector": "74",
            "sectype": "low",
            "Shape_Length": 5.1405588870857875,
            "Shape_Area": 1.2513836424296185
        }
    },
    {
        "type": "Feature",
        "id": 97,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -90.03444444435559,
                        42.233333333266785
                    ],
                    [
                        -90.04583333374131,
                        42.079166666608444
                    ],
                    [
                        -89.7250000004496,
                        41.49166666598393
                    ],
                    [
                        -89.22361111096399,
                        41.66611111123882
                    ],
                    [
                        -89.15916666710797,
                        41.68805555571936
                    ],
                    [
                        -88.74583333364137,
                        41.68055555519476
                    ],
                    [
                        -88.65222222287724,
                        41.68305555536966
                    ],
                    [
                        -88.25000000069946,
                        41.68388888876126
                    ],
                    [
                        -88.15250000017483,
                        41.779999999700294
                    ],
                    [
                        -88.15583333374133,
                        41.96444444385605
                    ],
                    [
                        -88.16249999997495,
                        42.21666666633365
                    ],
                    [
                        -88.5713888892858,
                        42.21611111103897
                    ],
                    [
                        -88.64888888931074,
                        42.215833332942
                    ],
                    [
                        -88.81611111123874,
                        42.215833332942
                    ],
                    [
                        -88.96055555619392,
                        42.214444444255776
                    ],
                    [
                        -89.20611111153852,
                        42.212499999375495
                    ],
                    [
                        -89.67000000019982,
                        42.20749999992512
                    ],
                    [
                        -90.03444444435559,
                        42.233333333266785
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 97,
            "artcc": "zau",
            "sector": "77",
            "sectype": "low",
            "Shape_Length": 4.778791402475572,
            "Shape_Area": 1.0190270833367254
        }
    },
    {
        "type": "Feature",
        "id": 98,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -84.99999999999994,
                        41.85000000009995
                    ],
                    [
                        -84.99999999999994,
                        41.999999999800195
                    ],
                    [
                        -84.99999999999994,
                        42.0416666666834
                    ],
                    [
                        -84.99921388910968,
                        42.132474999821454
                    ],
                    [
                        -84.99999999999994,
                        42.16666666643357
                    ],
                    [
                        -84.99999999999994,
                        42.373888888461465
                    ],
                    [
                        -86.01388888936071,
                        42.38888888861135
                    ],
                    [
                        -86.02083333369137,
                        42.26666666623373
                    ],
                    [
                        -86.02500000064947,
                        42.16249999947547
                    ],
                    [
                        -86.00000000069946,
                        42.049999999700276
                    ],
                    [
                        -86.00000000069946,
                        41.683333332567315
                    ],
                    [
                        -85.47083333389122,
                        41.67083333259228
                    ],
                    [
                        -85.04722222252752,
                        41.64027777789721
                    ],
                    [
                        -84.99999999999994,
                        41.85000000009995
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 98,
            "artcc": "zau",
            "sector": "80",
            "sectype": "low",
            "Shape_Length": 3.4154803286516753,
            "Shape_Area": 0.7160715090685076
        }
    },
    {
        "type": "Feature",
        "id": 99,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -86.00000000069946,
                        42.049999999700276
                    ],
                    [
                        -86.89861111111384,
                        42.032222222177836
                    ],
                    [
                        -87.0000000004996,
                        42.02916666670836
                    ],
                    [
                        -87.05833333341656,
                        42.02555555504489
                    ],
                    [
                        -87.32361111116381,
                        42.01666666673333
                    ],
                    [
                        -87.67611111153855,
                        41.999999999800195
                    ],
                    [
                        -87.67916666700802,
                        41.96666666683325
                    ],
                    [
                        -87.5908333337913,
                        41.80666666643356
                    ],
                    [
                        -87.47999999990003,
                        41.699722222302796
                    ],
                    [
                        -87.29444444425565,
                        41.69999999950045
                    ],
                    [
                        -87.13055555589415,
                        41.69999999950045
                    ],
                    [
                        -87.05833333341656,
                        41.69999999950045
                    ],
                    [
                        -87.0000000004996,
                        41.69999999950045
                    ],
                    [
                        -86.90833333371631,
                        41.69999999950045
                    ],
                    [
                        -86.70805555601908,
                        41.69999999950045
                    ],
                    [
                        -86.65000000029971,
                        41.69999999950045
                    ],
                    [
                        -86.45000000069945,
                        41.69583333344161
                    ],
                    [
                        -86.00000000069946,
                        41.683333332567315
                    ],
                    [
                        -86.00000000069946,
                        42.049999999700276
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 99,
            "artcc": "zau",
            "sector": "81",
            "sectype": "low",
            "Shape_Length": 3.8941252177730243,
            "Shape_Area": 0.5366332563442839
        }
    },
    {
        "type": "Feature",
        "id": 100,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -87.32361111116381,
                        42.01666666673333
                    ],
                    [
                        -87.05833333341656,
                        42.02555555504489
                    ],
                    [
                        -87.0000000004996,
                        42.02916666670836
                    ],
                    [
                        -86.89861111111384,
                        42.032222222177836
                    ],
                    [
                        -86.00000000069946,
                        42.049999999700276
                    ],
                    [
                        -86.02500000064947,
                        42.16249999947547
                    ],
                    [
                        -86.02083333369137,
                        42.26666666623373
                    ],
                    [
                        -86.01388888936071,
                        42.38888888861135
                    ],
                    [
                        -86.2002777783967,
                        42.387222221828154
                    ],
                    [
                        -86.31666666703302,
                        42.38333333296697
                    ],
                    [
                        -87.0000000004996,
                        42.35694444433068
                    ],
                    [
                        -87.31666666683316,
                        42.339999999300574
                    ],
                    [
                        -87.41388888926082,
                        42.33472222175317
                    ],
                    [
                        -87.59750000002492,
                        42.2636111107642
                    ],
                    [
                        -87.66833333381629,
                        42.13694444423078
                    ],
                    [
                        -87.67611111153855,
                        41.999999999800195
                    ],
                    [
                        -87.32361111116381,
                        42.01666666673333
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 100,
            "artcc": "zau",
            "sector": "82",
            "sectype": "low",
            "Shape_Length": 3.899271412906214,
            "Shape_Area": 0.5321943669142729
        }
    },
    {
        "type": "Feature",
        "id": 127,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -74.05833333331663,
                        41.33333333326675
                    ],
                    [
                        -74.08222222267739,
                        41.60055555499491
                    ],
                    [
                        -74.10416666715793,
                        41.84027777749748
                    ],
                    [
                        -74.04555555614394,
                        41.988333333216815
                    ],
                    [
                        -74.04305555596909,
                        41.999999999800195
                    ],
                    [
                        -73.92500000054957,
                        42.01666666673333
                    ],
                    [
                        -74.00000000039967,
                        42.1416666664835
                    ],
                    [
                        -74.30000000069941,
                        42.17499999945045
                    ],
                    [
                        -74.91388888906096,
                        42.11388888826161
                    ],
                    [
                        -74.97222222287724,
                        42.024999999750264
                    ],
                    [
                        -74.99166666718293,
                        41.98333333286706
                    ],
                    [
                        -75.05833333401614,
                        41.869444444405644
                    ],
                    [
                        -74.95833333331666,
                        41.741666666383594
                    ],
                    [
                        -74.85000000049956,
                        41.61666666663342
                    ],
                    [
                        -74.83583333374133,
                        41.59611111083916
                    ],
                    [
                        -74.784166667058,
                        41.52249999967529
                    ],
                    [
                        -74.85138888918584,
                        41.30277777767236
                    ],
                    [
                        -74.78361111176338,
                        41.26138888888619
                    ],
                    [
                        -74.71944444510501,
                        41.22222222217789
                    ],
                    [
                        -74.65833333391618,
                        41.241666666483525
                    ],
                    [
                        -74.51666666723287,
                        41.17499999965031
                    ],
                    [
                        -74.42222222217777,
                        41.1499999997003
                    ],
                    [
                        -74.26111111118877,
                        41.2013888882866
                    ],
                    [
                        -74.05833333331663,
                        41.33333333326675
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 127,
            "artcc": "zbw",
            "sector": "05",
            "sectype": "low",
            "Shape_Length": 3.704578913782436,
            "Shape_Area": 0.7864459488826439
        }
    },
    {
        "type": "Feature",
        "id": 128,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -73.22611111163843,
                        41.63972222170321
                    ],
                    [
                        -73.23333333316674,
                        41.669444443906
                    ],
                    [
                        -73.23750000012484,
                        41.69999999950045
                    ],
                    [
                        -73.25000000009987,
                        41.765277777647384
                    ],
                    [
                        -73.37777777812192,
                        42.05277777707283
                    ],
                    [
                        -73.3875000007244,
                        42.183333333366704
                    ],
                    [
                        -74.00000000039967,
                        42.1416666664835
                    ],
                    [
                        -74.04305555596909,
                        41.999999999800195
                    ],
                    [
                        -74.31166666728285,
                        41.999999999800195
                    ],
                    [
                        -74.08222222267739,
                        41.60055555499491
                    ],
                    [
                        -74.05833333331663,
                        41.33333333326675
                    ],
                    [
                        -74.02500000034968,
                        41.33333333326675
                    ],
                    [
                        -73.8958333336414,
                        41.25833333341666
                    ],
                    [
                        -73.78250000047461,
                        41.299999999400484
                    ],
                    [
                        -73.69166666708298,
                        41.322222221977995
                    ],
                    [
                        -73.58333333336662,
                        41.345833333241785
                    ],
                    [
                        -73.33333333386622,
                        41.54166666678327
                    ],
                    [
                        -73.29999999999995,
                        41.56249999977524
                    ],
                    [
                        -73.27527777814691,
                        41.58805555501988
                    ],
                    [
                        -73.2138888888611,
                        41.58749999972525
                    ],
                    [
                        -73.22611111163843,
                        41.63972222170321
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 128,
            "artcc": "zbw",
            "sector": "06",
            "sectype": "low",
            "Shape_Length": 3.348248227060737,
            "Shape_Area": 0.6581825233059679
        }
    },
    {
        "type": "Feature",
        "id": 129,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -72.6580555553196,
                        42.353888888861206
                    ],
                    [
                        -72.99999999970021,
                        42.58333333346661
                    ],
                    [
                        -73.3333333329669,
                        42.597222222127925
                    ],
                    [
                        -73.38749999982508,
                        42.183333333366704
                    ],
                    [
                        -73.37777777812192,
                        42.05277777797215
                    ],
                    [
                        -73.25000000009987,
                        41.765277777647384
                    ],
                    [
                        -73.23750000012484,
                        41.70000000039977
                    ],
                    [
                        -73.23333333316674,
                        41.66944444480532
                    ],
                    [
                        -73.22611111073911,
                        41.63972222260253
                    ],
                    [
                        -72.78333333316675,
                        41.82083333319184
                    ],
                    [
                        -72.71055555539454,
                        41.94361111086414
                    ],
                    [
                        -72.25000000029974,
                        42.37083333299199
                    ],
                    [
                        -72.6580555553196,
                        42.353888888861206
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 129,
            "artcc": "zbw",
            "sector": "07",
            "sectype": "low",
            "Shape_Length": 3.393969605611562,
            "Shape_Area": 0.5992175926932204
        }
    },
    {
        "type": "Feature",
        "id": 131,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -74.03527777824684,
                        43.32638888853643
                    ],
                    [
                        -74.23888888951058,
                        43.56111111068924
                    ],
                    [
                        -74.50000000029974,
                        44.07499999925062
                    ],
                    [
                        -74.50000000029974,
                        44.358333332617235
                    ],
                    [
                        -74.56666666713295,
                        44.60000000000008
                    ],
                    [
                        -74.19444444435561,
                        44.9763888888362
                    ],
                    [
                        -74.20277777827181,
                        44.99166666618373
                    ],
                    [
                        -74.21083333319172,
                        45.0166666661338
                    ],
                    [
                        -74.54222222247756,
                        45.10277777727265
                    ],
                    [
                        -74.56305555546948,
                        45.108611111014
                    ],
                    [
                        -74.6000000000999,
                        45.11666666683328
                    ],
                    [
                        -74.85000000049956,
                        45.11666666683328
                    ],
                    [
                        -74.96666666723286,
                        45.06666666603388
                    ],
                    [
                        -74.95000000029972,
                        44.98055555489498
                    ],
                    [
                        -75.03666666673325,
                        44.94527777704781
                    ],
                    [
                        -75.65500000014987,
                        44.840833333091894
                    ],
                    [
                        -75.60527777834676,
                        44.69111111058936
                    ],
                    [
                        -75.6333333337663,
                        44.60833333301696
                    ],
                    [
                        -75.74111111128872,
                        44.53388888846149
                    ],
                    [
                        -75.29972222240258,
                        44.34999999960036
                    ],
                    [
                        -75.25194444458043,
                        44.31833333341666
                    ],
                    [
                        -75.1497222227024,
                        44.249999999800195
                    ],
                    [
                        -75.0000000001998,
                        44.16666666603385
                    ],
                    [
                        -75.0000000001998,
                        43.66833333291703
                    ],
                    [
                        -74.78055555629385,
                        43.6652777774475
                    ],
                    [
                        -74.40000000049957,
                        43.26666666603387
                    ],
                    [
                        -74.03527777824684,
                        43.32638888853643
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 131,
            "artcc": "zbw",
            "sector": "08",
            "sectype": "low",
            "Shape_Length": 6.425770223323562,
            "Shape_Area": 1.3378686341446995
        }
    },
    {
        "type": "Feature",
        "id": 135,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -67.33277777797201,
                        47.386111111138916
                    ],
                    [
                        -67.52722222282728,
                        47.472222222277765
                    ],
                    [
                        -67.74944444500511,
                        47.53333333256728
                    ],
                    [
                        -68.00000000069946,
                        47.5249999995504
                    ],
                    [
                        -68.06611111133867,
                        47.51666666653347
                    ],
                    [
                        -68.1994444450051,
                        47.48888888831158
                    ],
                    [
                        -68.27166666658337,
                        47.45833333271719
                    ],
                    [
                        -68.39388888896104,
                        47.40833333281711
                    ],
                    [
                        -68.56666666653342,
                        47.287500000025034
                    ],
                    [
                        -69.0000000004996,
                        47.39305555546957
                    ],
                    [
                        -69.216666667033,
                        47.44999999970025
                    ],
                    [
                        -69.99944444500511,
                        46.69166666638358
                    ],
                    [
                        -70.03833333361644,
                        46.53888888841152
                    ],
                    [
                        -70.05861111131372,
                        46.418055555619446
                    ],
                    [
                        -70.20833333381626,
                        46.30833333321681
                    ],
                    [
                        -70.25000000069946,
                        45.9083333331169
                    ],
                    [
                        -70.50277777847168,
                        45.686111110939066
                    ],
                    [
                        -71.00000000009987,
                        45.31666666643355
                    ],
                    [
                        -71.33277777807194,
                        45.28333333256728
                    ],
                    [
                        -71.45000000009986,
                        45.06666666603388
                    ],
                    [
                        -70.98888888881112,
                        44.77888888851146
                    ],
                    [
                        -70.92888888911091,
                        44.58111111098901
                    ],
                    [
                        -70.21611111153851,
                        44.54722222182812
                    ],
                    [
                        -70.1033333336664,
                        44.80666666673335
                    ],
                    [
                        -69.67888888891105,
                        44.84805555551952
                    ],
                    [
                        -69.36666666673324,
                        45.133333332867096
                    ],
                    [
                        -69.0000000004996,
                        45.237499999625356
                    ],
                    [
                        -68.63333333336658,
                        45.28333333256728
                    ],
                    [
                        -68.50000000059953,
                        45.28333333256728
                    ],
                    [
                        -68.35833333391616,
                        45.191666666683375
                    ],
                    [
                        -68.14472222285224,
                        44.77166666608383
                    ],
                    [
                        -67.9916666667832,
                        44.46666666633365
                    ],
                    [
                        -68.00000000069946,
                        44.28333333276714
                    ],
                    [
                        -68.79999999999995,
                        43.86666666663342
                    ],
                    [
                        -69.05972222210283,
                        43.79999999980021
                    ],
                    [
                        -69.33027777829682,
                        43.65249999937555
                    ],
                    [
                        -69.50000000039967,
                        43.55833333331674
                    ],
                    [
                        -69.50000000039967,
                        43.10833333331675
                    ],
                    [
                        -68.72500000014986,
                        43.383333332767165
                    ],
                    [
                        -66.99999999999994,
                        43.79999999980021
                    ],
                    [
                        -66.99999999999994,
                        44.34166666658342
                    ],
                    [
                        -66.99999999999994,
                        44.4999999993006
                    ],
                    [
                        -66.99999999999994,
                        44.93333333326677
                    ],
                    [
                        -67.33277777797201,
                        45.249999999600334
                    ],
                    [
                        -67.75777777802199,
                        45.62499999975023
                    ],
                    [
                        -67.80638888923579,
                        45.78333333336667
                    ],
                    [
                        -67.58277777837174,
                        45.82499999935055
                    ],
                    [
                        -67.21611111123877,
                        46.14305555526971
                    ],
                    [
                        -67.21611111123877,
                        46.19999999950045
                    ],
                    [
                        -67.29944444500507,
                        46.53611111103902
                    ],
                    [
                        -67.2549999998501,
                        46.56944444400597
                    ],
                    [
                        -67.13277777837175,
                        46.69166666638358
                    ],
                    [
                        -67.0577777785216,
                        46.82777777742257
                    ],
                    [
                        -67.04111111158846,
                        46.98055555539463
                    ],
                    [
                        -67.08277777847167,
                        47.13333333336669
                    ],
                    [
                        -67.17444444435557,
                        47.26944444440562
                    ],
                    [
                        -67.33277777797201,
                        47.386111111138916
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 135,
            "artcc": "zbw",
            "sector": "15",
            "sectype": "low",
            "Shape_Length": 19.34462576288258,
            "Shape_Area": 9.63112673750976
        }
    },
    {
        "type": "Feature",
        "id": 137,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -69.50000000039967,
                        43.55833333331674
                    ],
                    [
                        -69.93333333346652,
                        43.31666666683327
                    ],
                    [
                        -70.14999999999998,
                        43.200000000099976
                    ],
                    [
                        -70.28333333366635,
                        43.0333333334666
                    ],
                    [
                        -70.41666666643346,
                        42.812499999975046
                    ],
                    [
                        -70.41666666643346,
                        42.72083333319182
                    ],
                    [
                        -70.51666666713294,
                        42.61666666643356
                    ],
                    [
                        -70.30833333361642,
                        42.53888888921091
                    ],
                    [
                        -70.3166666666333,
                        42.311111111388755
                    ],
                    [
                        -69.82499999975016,
                        42.283333333166865
                    ],
                    [
                        -69.50000000039967,
                        42.08333333356654
                    ],
                    [
                        -69.50000000039967,
                        43.10833333331675
                    ],
                    [
                        -69.50000000039967,
                        43.55833333331674
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 137,
            "artcc": "zbw",
            "sector": "16",
            "sectype": "low",
            "Shape_Length": 4.249079265997196,
            "Shape_Area": 0.9118287033049937
        }
    },
    {
        "type": "Feature",
        "id": 139,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -69.34166666678323,
                        40.924999999250645
                    ],
                    [
                        -69.0000000004996,
                        41.00000000000006
                    ],
                    [
                        -66.99999999999994,
                        41.61666666663342
                    ],
                    [
                        -66.99999999999994,
                        41.86666666613377
                    ],
                    [
                        -66.99999999999994,
                        42.54166666658347
                    ],
                    [
                        -66.99999999999994,
                        43.79999999980021
                    ],
                    [
                        -68.72500000014986,
                        43.383333332767165
                    ],
                    [
                        -69.50000000039967,
                        43.10833333331675
                    ],
                    [
                        -69.50000000039967,
                        42.08333333266722
                    ],
                    [
                        -69.50000000039967,
                        41.77499999935054
                    ],
                    [
                        -69.50000000039967,
                        41.64999999960037
                    ],
                    [
                        -69.50000000039967,
                        41.16666666663343
                    ],
                    [
                        -69.7333333338662,
                        41.01388888866131
                    ],
                    [
                        -69.93222222287727,
                        41.00000000000006
                    ],
                    [
                        -69.76694444493017,
                        40.89888888871127
                    ],
                    [
                        -69.68416666735777,
                        40.84638888863634
                    ],
                    [
                        -69.34166666678323,
                        40.924999999250645
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 139,
            "artcc": "zbw",
            "sector": "17",
            "sectype": "low",
            "Shape_Length": 10.286120658377765,
            "Shape_Area": 5.687785686996216
        }
    },
    {
        "type": "Feature",
        "id": 141,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -71.33527777824685,
                        40.44138888818668
                    ],
                    [
                        -71.31111111168843,
                        40.44777777722271
                    ],
                    [
                        -70.87944444450545,
                        40.57666666673333
                    ],
                    [
                        -69.68416666735777,
                        40.84638888863634
                    ],
                    [
                        -69.76694444493017,
                        40.89888888871127
                    ],
                    [
                        -69.93222222287727,
                        41.00000000000006
                    ],
                    [
                        -69.7333333338662,
                        41.01388888866131
                    ],
                    [
                        -69.50000000039967,
                        41.16666666663343
                    ],
                    [
                        -69.50000000039967,
                        41.64999999960037
                    ],
                    [
                        -69.50000000039967,
                        41.77499999935054
                    ],
                    [
                        -69.50000000039967,
                        42.08333333266722
                    ],
                    [
                        -69.83055555629386,
                        42.12222222217787
                    ],
                    [
                        -70.31611111133867,
                        42.1799999998002
                    ],
                    [
                        -70.35555555614394,
                        42.184444443955954
                    ],
                    [
                        -70.62333333316673,
                        42.343333332867076
                    ],
                    [
                        -70.63500000064948,
                        42.211111110689274
                    ],
                    [
                        -70.69111111148857,
                        42.142499999875156
                    ],
                    [
                        -71.03083333379129,
                        42.0888888883116
                    ],
                    [
                        -71.12111111098892,
                        42.053055555169806
                    ],
                    [
                        -70.92500000024978,
                        41.894444444355656
                    ],
                    [
                        -71.09722222252753,
                        41.77611111083917
                    ],
                    [
                        -71.16250000067447,
                        41.69583333344161
                    ],
                    [
                        -71.19527777834674,
                        41.65305555506984
                    ],
                    [
                        -71.34000000049957,
                        41.452499999275574
                    ],
                    [
                        -71.35000000029976,
                        41.44166666608385
                    ],
                    [
                        -71.7666666673328,
                        41.28333333336667
                    ],
                    [
                        -71.76194444508002,
                        41.234166665958924
                    ],
                    [
                        -71.75833333341654,
                        41.19999999960038
                    ],
                    [
                        -71.72361111176338,
                        41.113888888461474
                    ],
                    [
                        -71.70000000049959,
                        41.055555555544515
                    ],
                    [
                        -71.57555555604404,
                        40.83583333264227
                    ],
                    [
                        -71.49999999999994,
                        40.68333333276712
                    ],
                    [
                        -71.49999999999994,
                        40.38972222150335
                    ],
                    [
                        -71.33527777824685,
                        40.44138888818668
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 141,
            "artcc": "zbw",
            "sector": "18",
            "sectype": "low",
            "Shape_Length": 7.677669813184193,
            "Shape_Area": 2.762904745017923
        }
    },
    {
        "type": "Feature",
        "id": 144,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -74.44583333344156,
                        42.361111111288835
                    ],
                    [
                        -74.2999999998001,
                        42.17500000034977
                    ],
                    [
                        -74.00000000039967,
                        42.1416666664835
                    ],
                    [
                        -73.9249999996502,
                        42.01666666673333
                    ],
                    [
                        -73.8986111110139,
                        42.01750000012498
                    ],
                    [
                        -73.37777777812192,
                        42.05277777797215
                    ],
                    [
                        -73.38749999982508,
                        42.183333333366704
                    ],
                    [
                        -73.3333333329669,
                        42.597222222127925
                    ],
                    [
                        -73.95972222220274,
                        42.53333333356653
                    ],
                    [
                        -74.44583333344156,
                        42.361111111288835
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 144,
            "artcc": "zbw",
            "sector": "21",
            "sectype": "low",
            "Shape_Length": 2.9261819543131007,
            "Shape_Area": 0.44020987663460853
        }
    },
    {
        "type": "Feature",
        "id": 146,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -74.40000000049957,
                        43.26666666603387
                    ],
                    [
                        -74.85000000049956,
                        43.15833333321683
                    ],
                    [
                        -75.68333333366638,
                        43.19166666618378
                    ],
                    [
                        -76.18333333356645,
                        43.22500000004999
                    ],
                    [
                        -76.28333333336661,
                        43.236111110439424
                    ],
                    [
                        -76.29166666728281,
                        43.18333333316684
                    ],
                    [
                        -76.4347222226524,
                        43.007777777322644
                    ],
                    [
                        -76.4833333338662,
                        42.941666666683375
                    ],
                    [
                        -76.08888888941067,
                        42.937499999725276
                    ],
                    [
                        -76.01388888956058,
                        42.862499999875126
                    ],
                    [
                        -75.96944444440555,
                        42.69583333324181
                    ],
                    [
                        -76.0091666673078,
                        42.53055555529471
                    ],
                    [
                        -76.01666666693308,
                        42.283333333166865
                    ],
                    [
                        -75.61666666683317,
                        42.104166666558456
                    ],
                    [
                        -75.34166666738275,
                        41.98888888851144
                    ],
                    [
                        -75.14777777782217,
                        41.90722222152834
                    ],
                    [
                        -75.05833333401614,
                        41.869444444405644
                    ],
                    [
                        -74.99166666718293,
                        41.98333333286706
                    ],
                    [
                        -74.97222222287724,
                        42.024999999750264
                    ],
                    [
                        -74.91388888906096,
                        42.11388888826161
                    ],
                    [
                        -74.30000000069941,
                        42.17499999945045
                    ],
                    [
                        -74.44583333344156,
                        42.36111111038946
                    ],
                    [
                        -74.58333333316676,
                        42.54166666658347
                    ],
                    [
                        -74.56250000017485,
                        42.67499999935052
                    ],
                    [
                        -74.52777777852162,
                        42.93333333276712
                    ],
                    [
                        -74.51666666723287,
                        42.999999999600334
                    ],
                    [
                        -74.40000000049957,
                        43.26666666603387
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 146,
            "artcc": "zbw",
            "sector": "23",
            "sectype": "low",
            "Shape_Length": 6.513682673332806,
            "Shape_Area": 1.7803342979358412
        }
    },
    {
        "type": "Feature",
        "id": 149,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -71.66666666663332,
                        41.28333333336667
                    ],
                    [
                        -71.76194444508002,
                        41.234166665958924
                    ],
                    [
                        -71.7666666673328,
                        41.28333333336667
                    ],
                    [
                        -72.1000000005995,
                        41.27499999945047
                    ],
                    [
                        -72.65833333341658,
                        41.19999999960038
                    ],
                    [
                        -72.7500000001998,
                        41.112499999775196
                    ],
                    [
                        -72.93333333376631,
                        40.927777777522465
                    ],
                    [
                        -73.0722222221778,
                        40.955555555744354
                    ],
                    [
                        -73.10000000039963,
                        41.06111111118889
                    ],
                    [
                        -73.29999999999995,
                        40.8977777772227
                    ],
                    [
                        -73.32694444483025,
                        40.87583333274216
                    ],
                    [
                        -73.36138888928576,
                        40.84750000012497
                    ],
                    [
                        -73.38833333321674,
                        40.82555555564443
                    ],
                    [
                        -73.47972222280231,
                        40.54472222155334
                    ],
                    [
                        -73.5000000004996,
                        40.508333333116866
                    ],
                    [
                        -73.41472222275235,
                        40.47416666675832
                    ],
                    [
                        -73.25416666705797,
                        40.40972222200298
                    ],
                    [
                        -72.98888888931077,
                        40.29166666658347
                    ],
                    [
                        -72.78194444448047,
                        40.19861111111396
                    ],
                    [
                        -72.50000000069946,
                        40.07222222177819
                    ],
                    [
                        -71.49999999999994,
                        40.38972222150335
                    ],
                    [
                        -71.49999999999994,
                        40.68333333276712
                    ],
                    [
                        -71.57555555604404,
                        40.83583333264227
                    ],
                    [
                        -71.70000000049959,
                        41.055555555544515
                    ],
                    [
                        -71.72361111176338,
                        41.113888888461474
                    ],
                    [
                        -71.60000000069942,
                        41.19999999960038
                    ],
                    [
                        -71.66666666663332,
                        41.28333333336667
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 149,
            "artcc": "zbw",
            "sector": "32",
            "sectype": "low",
            "Shape_Length": 5.577394926379095,
            "Shape_Area": 1.6549985340215674
        }
    },
    {
        "type": "Feature",
        "id": 152,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -71.89166666708297,
                        42.733333333166854
                    ],
                    [
                        -71.88333333316677,
                        42.86666666683328
                    ],
                    [
                        -71.91444444405579,
                        42.94944444440563
                    ],
                    [
                        -71.96666666693307,
                        43.0750000003498
                    ],
                    [
                        -72.85833333301684,
                        43.10833333331675
                    ],
                    [
                        -73.01666666663334,
                        43.022222222177845
                    ],
                    [
                        -73.0008333330918,
                        42.63333333336669
                    ],
                    [
                        -72.85277777737247,
                        42.597222222127925
                    ],
                    [
                        -72.73333333326667,
                        42.54166666658347
                    ],
                    [
                        -72.688611110914,
                        42.53722222242766
                    ],
                    [
                        -72.2861111115385,
                        42.41666666683324
                    ],
                    [
                        -72.00277777817189,
                        42.40555555554448
                    ],
                    [
                        -71.8333333332667,
                        42.449999999800184
                    ],
                    [
                        -71.87500000014984,
                        42.549999999600345
                    ],
                    [
                        -71.90000000009991,
                        42.61666666643356
                    ],
                    [
                        -71.89166666708297,
                        42.733333333166854
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 152,
            "artcc": "zbw",
            "sector": "36",
            "sectype": "low",
            "Shape_Length": 3.324204589736249,
            "Shape_Area": 0.6690277777358529
        }
    },
    {
        "type": "Feature",
        "id": 154,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -71.99999999990001,
                        44.00000000029985
                    ],
                    [
                        -72.33333333316676,
                        44.033333333266796
                    ],
                    [
                        -72.4458333329419,
                        43.962500000374746
                    ],
                    [
                        -72.58333333356643,
                        43.68750000002501
                    ],
                    [
                        -72.81666666703302,
                        43.55833333331674
                    ],
                    [
                        -72.84166666698303,
                        43.45555555524476
                    ],
                    [
                        -73.0083333336164,
                        43.36666666673335
                    ],
                    [
                        -73.10583333324172,
                        43.211388888586384
                    ],
                    [
                        -72.85833333301684,
                        43.10833333331675
                    ],
                    [
                        -71.96666666693307,
                        43.0750000003498
                    ],
                    [
                        -71.98333333296688,
                        43.13333333326676
                    ],
                    [
                        -71.9419444441807,
                        43.33222222227778
                    ],
                    [
                        -71.90000000009991,
                        43.70833333301698
                    ],
                    [
                        -71.3833333332667,
                        43.75833333291706
                    ],
                    [
                        -71.56277777797203,
                        43.805555555444585
                    ],
                    [
                        -71.82777777762232,
                        43.86666666663342
                    ],
                    [
                        -71.99999999990001,
                        44.00000000029985
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 154,
            "artcc": "zbw",
            "sector": "37",
            "sectype": "low",
            "Shape_Length": 4.517151193357235,
            "Shape_Area": 0.8120256561224884
        }
    },
    {
        "type": "Feature",
        "id": 161,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -72.42194444448052,
                        44.99416666635864
                    ],
                    [
                        -72.72888888911092,
                        44.547499999925094
                    ],
                    [
                        -72.75833333321674,
                        44.08666666673332
                    ],
                    [
                        -73.13333333336658,
                        43.933333333466635
                    ],
                    [
                        -73.28333333306682,
                        43.883333333566554
                    ],
                    [
                        -73.55000000039968,
                        43.883333333566554
                    ],
                    [
                        -73.87499999975017,
                        44.00000000029985
                    ],
                    [
                        -73.88333333366637,
                        44.741666666683386
                    ],
                    [
                        -74.01666666643348,
                        44.77499999965033
                    ],
                    [
                        -73.97555555574428,
                        44.99777777802211
                    ],
                    [
                        -74.12166666658334,
                        44.99166666708305
                    ],
                    [
                        -74.19444444435561,
                        44.9763888888362
                    ],
                    [
                        -74.56666666713295,
                        44.60000000000008
                    ],
                    [
                        -74.50000000029974,
                        44.358333333516555
                    ],
                    [
                        -74.50000000029974,
                        44.07500000014994
                    ],
                    [
                        -74.23888888861126,
                        43.56111111068924
                    ],
                    [
                        -74.03527777824684,
                        43.32638888853643
                    ],
                    [
                        -73.82222222247754,
                        43.3711111108891
                    ],
                    [
                        -73.74999999999994,
                        43.53611111073923
                    ],
                    [
                        -73.48749999962524,
                        43.52500000034979
                    ],
                    [
                        -73.42499999975013,
                        43.36666666673335
                    ],
                    [
                        -73.4191666669081,
                        43.337777777922156
                    ],
                    [
                        -73.24055555559437,
                        43.26666666693319
                    ],
                    [
                        -73.10583333324172,
                        43.211388888586384
                    ],
                    [
                        -73.0083333336164,
                        43.36666666673335
                    ],
                    [
                        -72.84166666698303,
                        43.45555555524476
                    ],
                    [
                        -72.81666666703302,
                        43.55833333331674
                    ],
                    [
                        -72.58333333356643,
                        43.68750000002501
                    ],
                    [
                        -72.4458333329419,
                        43.962500000374746
                    ],
                    [
                        -72.33333333316676,
                        44.033333333266796
                    ],
                    [
                        -71.99999999990001,
                        44.00000000029985
                    ],
                    [
                        -71.82777777762232,
                        43.86666666663342
                    ],
                    [
                        -71.56277777797203,
                        43.805555555444585
                    ],
                    [
                        -71.3833333332667,
                        43.75833333291706
                    ],
                    [
                        -71.18333333366638,
                        43.71666666693318
                    ],
                    [
                        -71.15833333371631,
                        43.78333333286707
                    ],
                    [
                        -71.06666666693309,
                        44.011111110689285
                    ],
                    [
                        -71.00000000009987,
                        44.05000000019993
                    ],
                    [
                        -70.81666666653342,
                        44.16666666693317
                    ],
                    [
                        -70.86611111113882,
                        44.33333333356654
                    ],
                    [
                        -70.96666666713293,
                        44.583333333066946
                    ],
                    [
                        -70.92888888911091,
                        44.58111111098901
                    ],
                    [
                        -70.98888888881112,
                        44.77888888851146
                    ],
                    [
                        -71.45000000009986,
                        45.0666666669332
                    ],
                    [
                        -71.48333333306681,
                        45.01666666703312
                    ],
                    [
                        -72.42194444448052,
                        44.99416666635864
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 161,
            "artcc": "zbw",
            "sector": "52",
            "sectype": "low",
            "Shape_Length": 11.895181450923534,
            "Shape_Area": 3.3678098768737215
        }
    },
    {
        "type": "Feature",
        "id": 174,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -79.54416666725785,
                        38.4347222214534
                    ],
                    [
                        -79.58194444438055,
                        38.5580555553197
                    ],
                    [
                        -79.4427777778721,
                        38.58277777717274
                    ],
                    [
                        -79.16666666693311,
                        39.1166666662337
                    ],
                    [
                        -79.37500000044963,
                        39.14166666618377
                    ],
                    [
                        -79.73194444498012,
                        39.163611110664306
                    ],
                    [
                        -79.6983333339162,
                        39.283611110964046
                    ],
                    [
                        -79.89444444465533,
                        39.173611110464435
                    ],
                    [
                        -80.40000000019984,
                        39.16666666613378
                    ],
                    [
                        -80.51055555599407,
                        38.90361111046445
                    ],
                    [
                        -80.56666666683316,
                        38.76666666603387
                    ],
                    [
                        -80.61250000067446,
                        38.569444443806105
                    ],
                    [
                        -80.64472222215278,
                        38.38249999947544
                    ],
                    [
                        -79.54416666725785,
                        38.4347222214534
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 174,
            "artcc": "zdc",
            "sector": "01",
            "sectype": "low",
            "Shape_Length": 4.221228696950364,
            "Shape_Area": 0.9003682100750452
        }
    },
    {
        "type": "Feature",
        "id": 175,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -78.17583333354145,
                        38.78166666618375
                    ],
                    [
                        -78.21222222287724,
                        38.79833333311689
                    ],
                    [
                        -78.25472222225272,
                        38.81083333309192
                    ],
                    [
                        -78.37472222255246,
                        38.814444443856075
                    ],
                    [
                        -78.3750000006495,
                        38.94999999960038
                    ],
                    [
                        -78.56666666723288,
                        38.75000000000006
                    ],
                    [
                        -79.33333333356643,
                        38.599999999400495
                    ],
                    [
                        -79.4427777778721,
                        38.58277777717274
                    ],
                    [
                        -79.58194444438055,
                        38.5580555553197
                    ],
                    [
                        -79.54416666725785,
                        38.4347222214534
                    ],
                    [
                        -79.38500000024976,
                        38.46444444365619
                    ],
                    [
                        -79.32500000054955,
                        38.34999999990015
                    ],
                    [
                        -79.19444444425568,
                        38.08888888821167
                    ],
                    [
                        -78.38333333366637,
                        38.43666666633362
                    ],
                    [
                        -78.20000000009986,
                        38.499999999600334
                    ],
                    [
                        -78.06666666733281,
                        38.44833333291706
                    ],
                    [
                        -78.02500000044961,
                        38.441666666683375
                    ],
                    [
                        -77.51666666663334,
                        38.77499999995007
                    ],
                    [
                        -77.63230191799909,
                        38.77372763821995
                    ],
                    [
                        -77.63194444468036,
                        38.77416666655847
                    ],
                    [
                        -78.15522115822984,
                        38.76810252541128
                    ],
                    [
                        -78.17583333354145,
                        38.78166666618375
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 175,
            "artcc": "zdc",
            "sector": "02",
            "sectype": "low",
            "Shape_Length": 4.896095816229356,
            "Shape_Area": 0.66058628654108
        }
    },
    {
        "type": "Feature",
        "id": 178,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -77.23194444458039,
                        39.200000000000045
                    ],
                    [
                        -77.50000000059953,
                        39.148333333316714
                    ],
                    [
                        -77.67583333364138,
                        39.11611111093907
                    ],
                    [
                        -77.88833333321674,
                        39.073611110664274
                    ],
                    [
                        -78.17500000014985,
                        39.09638888853641
                    ],
                    [
                        -78.3750000006495,
                        39.11111111058932
                    ],
                    [
                        -79.00000000029974,
                        39.10833333321682
                    ],
                    [
                        -79.16666666693311,
                        39.1166666662337
                    ],
                    [
                        -79.4427777778721,
                        38.58277777717274
                    ],
                    [
                        -79.33333333356643,
                        38.599999999400495
                    ],
                    [
                        -78.56666666723288,
                        38.75000000000006
                    ],
                    [
                        -78.3750000006495,
                        38.765555555444564
                    ],
                    [
                        -77.51666666663334,
                        38.77499999995007
                    ],
                    [
                        -77.35833333391616,
                        38.86472222185313
                    ],
                    [
                        -77.35472222225269,
                        39.01472222155331
                    ],
                    [
                        -77.23194444458039,
                        39.200000000000045
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 178,
            "artcc": "zdc",
            "sector": "05",
            "sectype": "low",
            "Shape_Length": 5.046486229130127,
            "Shape_Area": 0.7340922451122454
        }
    },
    {
        "type": "Feature",
        "id": 185,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -77.16833333321671,
                        37.753333332867044
                    ],
                    [
                        -77.08166666678324,
                        37.92722222192805
                    ],
                    [
                        -77.01250000067444,
                        38.026388888336555
                    ],
                    [
                        -76.9369444446304,
                        38.127777777722315
                    ],
                    [
                        -76.88666666663335,
                        38.19750000002506
                    ],
                    [
                        -77.01416666655837,
                        38.33111111088908
                    ],
                    [
                        -77.20194444428063,
                        38.318055555619424
                    ],
                    [
                        -77.25277777847168,
                        38.31444444395595
                    ],
                    [
                        -77.29120277788587,
                        38.31164722234149
                    ],
                    [
                        -77.37108611173466,
                        38.30587499933182
                    ],
                    [
                        -77.51666666663334,
                        38.255555555744365
                    ],
                    [
                        -77.64166666728283,
                        38.17499999935052
                    ],
                    [
                        -77.73222222257749,
                        38.08472222215289
                    ],
                    [
                        -77.81666666693309,
                        37.999999999700265
                    ],
                    [
                        -77.8602777777972,
                        37.91916666610882
                    ],
                    [
                        -77.94777777852164,
                        37.806388888236654
                    ],
                    [
                        -78.03888888911092,
                        37.69166666638358
                    ],
                    [
                        -78.17194444468032,
                        37.53444444425577
                    ],
                    [
                        -78.29999999990002,
                        37.35000000009995
                    ],
                    [
                        -78.1261111117384,
                        37.39833333321678
                    ],
                    [
                        -78.0000000004996,
                        37.43333333296698
                    ],
                    [
                        -77.82499999995002,
                        37.46249999987515
                    ],
                    [
                        -77.34444444435559,
                        37.422222221678226
                    ],
                    [
                        -77.2500000001998,
                        37.33333333316682
                    ],
                    [
                        -77.20833333331666,
                        37.41666666603385
                    ],
                    [
                        -77.0350000004496,
                        37.41388888866135
                    ],
                    [
                        -76.96666666683319,
                        37.36666666613377
                    ],
                    [
                        -76.90694444433058,
                        37.34722222182813
                    ],
                    [
                        -76.88055555569429,
                        37.40277777737259
                    ],
                    [
                        -76.70833333341659,
                        37.3833333330669
                    ],
                    [
                        -76.44027777829677,
                        37.32499999925062
                    ],
                    [
                        -76.45277777827181,
                        37.473611111163905
                    ],
                    [
                        -76.45702777820935,
                        37.51226666617373
                    ],
                    [
                        -76.46666666693312,
                        37.59999999960036
                    ],
                    [
                        -76.63027777809697,
                        37.5922222218781
                    ],
                    [
                        -76.76666666723287,
                        37.633333332567304
                    ],
                    [
                        -76.8244444448552,
                        37.648055555519534
                    ],
                    [
                        -77.1041666665584,
                        37.6416666664835
                    ],
                    [
                        -77.16833333321671,
                        37.753333332867044
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 185,
            "artcc": "zdc",
            "sector": "14",
            "sectype": "low",
            "Shape_Length": 5.504913648464091,
            "Shape_Area": 0.9075394094138697
        }
    },
    {
        "type": "Feature",
        "id": 187,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -78.96999999999997,
                        39.73916666660841
                    ],
                    [
                        -79.20833333381626,
                        39.58333333316682
                    ],
                    [
                        -79.54444444445551,
                        39.515277777647384
                    ],
                    [
                        -79.6983333339162,
                        39.283611110964046
                    ],
                    [
                        -79.89444444465533,
                        39.173611110464435
                    ],
                    [
                        -79.73194444498012,
                        39.163611110664306
                    ],
                    [
                        -79.37500000044963,
                        39.14166666618377
                    ],
                    [
                        -79.42083333339156,
                        39.049999999400484
                    ],
                    [
                        -79.16666666693311,
                        39.1166666662337
                    ],
                    [
                        -79.00000000029974,
                        39.10833333321682
                    ],
                    [
                        -78.3750000006495,
                        39.11111111058932
                    ],
                    [
                        -78.3750000006495,
                        39.09194444438066
                    ],
                    [
                        -78.30518586313701,
                        39.10597201479669
                    ],
                    [
                        -78.17500000014985,
                        39.09638888853641
                    ],
                    [
                        -77.9583333336164,
                        39.333333332767154
                    ],
                    [
                        -77.95500000004995,
                        39.39583333264221
                    ],
                    [
                        -78.03666666703305,
                        39.491666666383594
                    ],
                    [
                        -78.06156102721957,
                        39.500273266498084
                    ],
                    [
                        -78.05750000002496,
                        39.51027777729763
                    ],
                    [
                        -78.07305555546947,
                        39.5044444444556
                    ],
                    [
                        -78.48500000024978,
                        39.64666666643359
                    ],
                    [
                        -78.55833333331663,
                        39.6716666663836
                    ],
                    [
                        -78.62472222205287,
                        39.64777777792216
                    ],
                    [
                        -78.96999999999997,
                        39.73916666660841
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 187,
            "artcc": "zdc",
            "sector": "15",
            "sectype": "low",
            "Shape_Length": 4.533464202059061,
            "Shape_Area": 0.817783260371986
        }
    },
    {
        "type": "Feature",
        "id": 189,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -76.5236111111638,
                        38.674999999250645
                    ],
                    [
                        -76.47666666673325,
                        38.67583333264224
                    ],
                    [
                        -76.33055555589414,
                        38.67805555561944
                    ],
                    [
                        -76.16666666663332,
                        38.680833332991995
                    ],
                    [
                        -75.81805555601903,
                        38.696666666533474
                    ],
                    [
                        -75.66666666673325,
                        38.700000000099976
                    ],
                    [
                        -75.59166666688316,
                        38.8649999999501
                    ],
                    [
                        -75.51666666703301,
                        39.11722222152838
                    ],
                    [
                        -75.63833333321674,
                        39.27805555531967
                    ],
                    [
                        -75.86999999990002,
                        39.334999999550405
                    ],
                    [
                        -76.01333333336657,
                        39.55777777792218
                    ],
                    [
                        -76.04027777819687,
                        39.59361111106398
                    ],
                    [
                        -76.34722222282727,
                        39.39166666658343
                    ],
                    [
                        -76.72138888958557,
                        39.38694444433071
                    ],
                    [
                        -76.81527777844667,
                        39.38555555564443
                    ],
                    [
                        -76.85555555574427,
                        39.384999999450486
                    ],
                    [
                        -76.76916666740772,
                        39.14083333279211
                    ],
                    [
                        -76.768547989598,
                        39.13956504409157
                    ],
                    [
                        -76.82500000014983,
                        39.13027777769736
                    ],
                    [
                        -76.75805555611896,
                        39.11805555491998
                    ],
                    [
                        -76.7469444448302,
                        39.08444444385606
                    ],
                    [
                        -76.75000000029974,
                        39.029722221703196
                    ],
                    [
                        -76.79083333379128,
                        38.82388888836158
                    ],
                    [
                        -76.99333333356645,
                        38.692499999575375
                    ],
                    [
                        -76.9991666673078,
                        38.585277777347585
                    ],
                    [
                        -76.5236111111638,
                        38.674999999250645
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 189,
            "artcc": "zdc",
            "sector": "17",
            "sectype": "low",
            "Shape_Length": 4.4459761020648925,
            "Shape_Area": 0.9067239446006361
        }
    },
    {
        "type": "Feature",
        "id": 193,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -78.2583333339162,
                        36.05055555529469
                    ],
                    [
                        -78.36388888936074,
                        36.145833332842074
                    ],
                    [
                        -78.28944444480527,
                        36.28555555554448
                    ],
                    [
                        -78.12916666720787,
                        36.38138888838654
                    ],
                    [
                        -77.76388888876119,
                        36.56944444420577
                    ],
                    [
                        -77.53333333356647,
                        36.88333333316683
                    ],
                    [
                        -77.30833333401614,
                        37.19166666648351
                    ],
                    [
                        -77.2500000001998,
                        37.33333333316682
                    ],
                    [
                        -77.34444444435559,
                        37.422222221678226
                    ],
                    [
                        -77.82499999995002,
                        37.46249999987515
                    ],
                    [
                        -78.0000000004996,
                        37.43333333296698
                    ],
                    [
                        -78.1261111117384,
                        37.39833333321678
                    ],
                    [
                        -78.29999999990002,
                        37.35000000009995
                    ],
                    [
                        -78.38666666723287,
                        37.229999999800214
                    ],
                    [
                        -78.45000000049959,
                        37.14999999960037
                    ],
                    [
                        -78.59166666718289,
                        36.98333333296699
                    ],
                    [
                        -78.80416666675825,
                        36.508333333016935
                    ],
                    [
                        -78.78333333376628,
                        36.43333333316684
                    ],
                    [
                        -78.69166666698305,
                        36.008333333116866
                    ],
                    [
                        -78.49166666738273,
                        35.94999999930059
                    ],
                    [
                        -78.2583333339162,
                        36.05055555529469
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 193,
            "artcc": "zdc",
            "sector": "21",
            "sectype": "low",
            "Shape_Length": 4.8873697156019515,
            "Shape_Area": 1.2576093365478576
        }
    },
    {
        "type": "Feature",
        "id": 194,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -79.03333333326668,
                        37.233333333366716
                    ],
                    [
                        -79.06666666713295,
                        37.330555554895
                    ],
                    [
                        -79.1061111110389,
                        37.458888888211675
                    ],
                    [
                        -79.24111111148858,
                        37.454722222152895
                    ],
                    [
                        -79.4077777781219,
                        37.40083333339169
                    ],
                    [
                        -80.50194444488017,
                        37.11833333341667
                    ],
                    [
                        -80.32361111166341,
                        36.80833333331674
                    ],
                    [
                        -80.2594444450051,
                        36.68472222225279
                    ],
                    [
                        -80.2500000004996,
                        36.66666666663343
                    ],
                    [
                        -80.11944444510505,
                        36.443611111064
                    ],
                    [
                        -79.97944444430561,
                        36.204722221953034
                    ],
                    [
                        -79.9500000001998,
                        36.15416666675833
                    ],
                    [
                        -79.86666666733277,
                        36.133333332867096
                    ],
                    [
                        -79.74444444495515,
                        36.08888888861139
                    ],
                    [
                        -79.49333333396618,
                        36.180000000099994
                    ],
                    [
                        -79.32277777847167,
                        36.241666666583455
                    ],
                    [
                        -78.83666666723286,
                        36.41388888886121
                    ],
                    [
                        -78.78333333376628,
                        36.43333333316684
                    ],
                    [
                        -78.80416666675825,
                        36.508333333016935
                    ],
                    [
                        -78.59166666718289,
                        36.98333333296699
                    ],
                    [
                        -78.45000000049959,
                        37.14999999960037
                    ],
                    [
                        -78.67944444510499,
                        37.14472222205296
                    ],
                    [
                        -78.85555555624387,
                        37.14166666658343
                    ],
                    [
                        -79.11666666703303,
                        37.133333332667235
                    ],
                    [
                        -79.03333333326668,
                        37.233333333366716
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 194,
            "artcc": "zdc",
            "sector": "22",
            "sectype": "low",
            "Shape_Length": 5.639373261722489,
            "Shape_Area": 1.6473379631328775
        }
    },
    {
        "type": "Feature",
        "id": 195,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -75.41719166716166,
                        37.8200555553197
                    ],
                    [
                        -75.33333333346656,
                        37.82083333309191
                    ],
                    [
                        -75.05000000009989,
                        38.2666666661338
                    ],
                    [
                        -74.98955277833801,
                        38.419000000050005
                    ],
                    [
                        -74.98916666700802,
                        38.499999999600334
                    ],
                    [
                        -74.99694444473027,
                        38.61388888896113
                    ],
                    [
                        -74.88333333346651,
                        38.75000000000006
                    ],
                    [
                        -75.25000000059953,
                        38.729166666108824
                    ],
                    [
                        -75.40833333331665,
                        38.71944444440561
                    ],
                    [
                        -75.5900000000999,
                        38.70583333294201
                    ],
                    [
                        -75.66666666673325,
                        38.700000000099976
                    ],
                    [
                        -75.81333333376631,
                        38.5249999995504
                    ],
                    [
                        -76.25694444473032,
                        37.91666666683324
                    ],
                    [
                        -76.39166666708297,
                        37.7499999993006
                    ],
                    [
                        -76.19249999997498,
                        37.7499999993006
                    ],
                    [
                        -76.16666666663332,
                        37.66666666643357
                    ],
                    [
                        -76.02499999995001,
                        37.66666666643357
                    ],
                    [
                        -75.50000000009987,
                        37.56666666663341
                    ],
                    [
                        -75.50000000009987,
                        37.713444444005916
                    ],
                    [
                        -75.41719166716166,
                        37.8200555553197
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 195,
            "artcc": "zdc",
            "sector": "23",
            "sectype": "low",
            "Shape_Length": 4.3732329259077725,
            "Shape_Area": 0.9682886624485173
        }
    },
    {
        "type": "Feature",
        "id": 196,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -76.95972222250253,
                        36.55416666595892
                    ],
                    [
                        -76.9883333332167,
                        36.57999999930058
                    ],
                    [
                        -77.03777777782216,
                        36.56611111063927
                    ],
                    [
                        -77.43138888888609,
                        36.36916666650848
                    ],
                    [
                        -77.68055555589416,
                        36.241666666583455
                    ],
                    [
                        -78.0958333333416,
                        36.12083333289206
                    ],
                    [
                        -78.2583333339162,
                        36.05055555529469
                    ],
                    [
                        -78.49166666738273,
                        35.94999999930059
                    ],
                    [
                        -78.71666666693312,
                        35.7666666666334
                    ],
                    [
                        -78.48333333346653,
                        35.67499999985017
                    ],
                    [
                        -78.4733250005483,
                        35.66455277708906
                    ],
                    [
                        -78.34898055620766,
                        35.534680555363366
                    ],
                    [
                        -78.11666666723289,
                        35.2916666666834
                    ],
                    [
                        -77.99902222249756,
                        35.165294444483095
                    ],
                    [
                        -77.4743083338625,
                        34.59559999964034
                    ],
                    [
                        -77.45000000069945,
                        34.56666666633362
                    ],
                    [
                        -77.35682499997375,
                        34.464244444225756
                    ],
                    [
                        -77.33218888910591,
                        34.47436388838281
                    ],
                    [
                        -77.31555277843796,
                        34.48194444388105
                    ],
                    [
                        -77.29869444439305,
                        34.48748611039571
                    ],
                    [
                        -77.27500000014987,
                        34.50118333284456
                    ],
                    [
                        -77.26541388903973,
                        34.50742222215786
                    ],
                    [
                        -77.31638888893605,
                        34.55027777749751
                    ],
                    [
                        -77.43527777774722,
                        34.601666666083815
                    ],
                    [
                        -77.43305555566934,
                        34.636944443930986
                    ],
                    [
                        -77.26722222242756,
                        35.02638888893614
                    ],
                    [
                        -76.84166666708296,
                        35.44583333334174
                    ],
                    [
                        -76.62444444435556,
                        35.90333333296701
                    ],
                    [
                        -76.40138888878613,
                        35.88749999942547
                    ],
                    [
                        -76.37666666693309,
                        35.9763888888362
                    ],
                    [
                        -76.34972222210286,
                        36.07305555506986
                    ],
                    [
                        -76.4050000004496,
                        36.065555555444575
                    ],
                    [
                        -76.95972222250253,
                        36.55416666595892
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 196,
            "artcc": "zdc",
            "sector": "25",
            "sectype": "low",
            "Shape_Length": 6.939797818142034,
            "Shape_Area": 2.154628793948591
        }
    },
    {
        "type": "Feature",
        "id": 197,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -77.50000000059953,
                        33.84999999990015
                    ],
                    [
                        -77.49971944449675,
                        34.391944443881016
                    ],
                    [
                        -77.46775555597407,
                        34.40914444386107
                    ],
                    [
                        -77.42909444495263,
                        34.42982777713024
                    ],
                    [
                        -77.38016666715794,
                        34.452561110506906
                    ],
                    [
                        -77.35682499997375,
                        34.464244444225756
                    ],
                    [
                        -77.45000000069945,
                        34.56666666633362
                    ],
                    [
                        -77.4743083338625,
                        34.59559999964034
                    ],
                    [
                        -77.99902222249756,
                        35.165294444483095
                    ],
                    [
                        -78.11666666723289,
                        35.2916666666834
                    ],
                    [
                        -78.34898055620766,
                        35.534680555363366
                    ],
                    [
                        -78.4733250005483,
                        35.66455277708906
                    ],
                    [
                        -78.48333333346653,
                        35.67499999985017
                    ],
                    [
                        -78.71666666693312,
                        35.7666666666334
                    ],
                    [
                        -78.86666666663331,
                        35.7666666666334
                    ],
                    [
                        -79.02713333333662,
                        35.624436110638044
                    ],
                    [
                        -79.05826111136116,
                        35.52972222150339
                    ],
                    [
                        -79.05000000019982,
                        35.11666666613377
                    ],
                    [
                        -79.17338055566807,
                        34.95063611066803
                    ],
                    [
                        -79.72805555611899,
                        34.71333333336668
                    ],
                    [
                        -79.26138888918587,
                        34.58611111063931
                    ],
                    [
                        -79.18861111141365,
                        34.56611111103899
                    ],
                    [
                        -78.74999999990001,
                        34.444444443956
                    ],
                    [
                        -78.68499999985005,
                        34.39694444423077
                    ],
                    [
                        -78.35833333371636,
                        34.157222221728205
                    ],
                    [
                        -78.11916666740774,
                        33.98027777719773
                    ],
                    [
                        -77.93277777837176,
                        33.841666665983894
                    ],
                    [
                        -77.75083333349153,
                        33.705555554944965
                    ],
                    [
                        -77.51166666718291,
                        33.52555555494496
                    ],
                    [
                        -77.50000000059953,
                        33.84999999990015
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 197,
            "artcc": "zdc",
            "sector": "26",
            "sectype": "low",
            "Shape_Length": 7.175182288156639,
            "Shape_Area": 2.169334172732979
        }
    },
    {
        "type": "Feature",
        "id": 199,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -79.4077777781219,
                        37.40083333339169
                    ],
                    [
                        -79.24111111148858,
                        37.454722222152895
                    ],
                    [
                        -79.2705555555944,
                        37.78138888828664
                    ],
                    [
                        -79.29527777834676,
                        38.05194444448057
                    ],
                    [
                        -79.19444444425568,
                        38.08888888821167
                    ],
                    [
                        -79.32500000054955,
                        38.34999999990015
                    ],
                    [
                        -79.38500000024976,
                        38.46444444365619
                    ],
                    [
                        -79.54416666725785,
                        38.4347222214534
                    ],
                    [
                        -80.64472222215278,
                        38.38249999947544
                    ],
                    [
                        -80.7166666665334,
                        38.04166666658347
                    ],
                    [
                        -80.78944444430562,
                        37.708333333316716
                    ],
                    [
                        -80.82500000024976,
                        37.52916666670836
                    ],
                    [
                        -80.84166666718289,
                        37.46666666683325
                    ],
                    [
                        -80.82222222287726,
                        37.43333333296698
                    ],
                    [
                        -80.74583333344157,
                        37.30416666625871
                    ],
                    [
                        -80.63333333366637,
                        37.3319444444806
                    ],
                    [
                        -80.58111111168841,
                        37.25555555504491
                    ],
                    [
                        -80.50194444488017,
                        37.11833333341667
                    ],
                    [
                        -79.4077777781219,
                        37.40083333339169
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 199,
            "artcc": "zdc",
            "sector": "29",
            "sectype": "low",
            "Shape_Length": 5.189495213784364,
            "Shape_Area": 1.6805637719997775
        }
    },
    {
        "type": "Feature",
        "id": 201,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -78.17194444468032,
                        37.53444444425577
                    ],
                    [
                        -78.03888888911092,
                        37.69166666638358
                    ],
                    [
                        -77.94777777852164,
                        37.806388888236654
                    ],
                    [
                        -77.8602777777972,
                        37.91916666610882
                    ],
                    [
                        -77.81666666693309,
                        37.999999999700265
                    ],
                    [
                        -77.73222222257749,
                        38.08472222215289
                    ],
                    [
                        -77.64166666728283,
                        38.17499999935052
                    ],
                    [
                        -77.73166666728281,
                        38.23083333299195
                    ],
                    [
                        -78.02500000044961,
                        38.441666666683375
                    ],
                    [
                        -78.06666666733281,
                        38.44833333291706
                    ],
                    [
                        -78.20000000009986,
                        38.499999999600334
                    ],
                    [
                        -78.38333333366637,
                        38.43666666633362
                    ],
                    [
                        -78.99111111108886,
                        38.17694444423074
                    ],
                    [
                        -79.19444444425568,
                        38.08888888821167
                    ],
                    [
                        -79.29527777834676,
                        38.05194444448057
                    ],
                    [
                        -79.2705555555944,
                        37.78138888828664
                    ],
                    [
                        -79.24111111148858,
                        37.454722222152895
                    ],
                    [
                        -79.1061111110389,
                        37.458888888211675
                    ],
                    [
                        -79.06666666713295,
                        37.330555554895
                    ],
                    [
                        -79.03333333326668,
                        37.233333333366716
                    ],
                    [
                        -79.11666666703303,
                        37.133333332667235
                    ],
                    [
                        -78.85555555624387,
                        37.14166666658343
                    ],
                    [
                        -78.67944444510499,
                        37.14472222205296
                    ],
                    [
                        -78.45000000049959,
                        37.14999999960037
                    ],
                    [
                        -78.38666666723287,
                        37.229999999800214
                    ],
                    [
                        -78.29999999990002,
                        37.35000000009995
                    ],
                    [
                        -78.17194444468032,
                        37.53444444425577
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 201,
            "artcc": "zdc",
            "sector": "31",
            "sectype": "low",
            "Shape_Length": 4.9141981062155455,
            "Shape_Area": 1.4002878855591483
        }
    },
    {
        "type": "Feature",
        "id": 203,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -76.45277777827181,
                        37.473611111163905
                    ],
                    [
                        -76.70833333341659,
                        37.3833333330669
                    ],
                    [
                        -76.90694444433058,
                        37.34722222182813
                    ],
                    [
                        -76.96666666683319,
                        37.36666666613377
                    ],
                    [
                        -77.0350000004496,
                        37.41388888866135
                    ],
                    [
                        -77.20833333331666,
                        37.41666666603385
                    ],
                    [
                        -77.2500000001998,
                        37.33333333316682
                    ],
                    [
                        -77.30833333401614,
                        37.19166666648351
                    ],
                    [
                        -77.53333333356647,
                        36.88333333316683
                    ],
                    [
                        -77.76388888876119,
                        36.56944444420577
                    ],
                    [
                        -78.12916666720787,
                        36.38138888838654
                    ],
                    [
                        -78.28944444480527,
                        36.28555555554448
                    ],
                    [
                        -78.36388888936074,
                        36.145833332842074
                    ],
                    [
                        -78.2583333339162,
                        36.05055555529469
                    ],
                    [
                        -78.0958333333416,
                        36.12083333289206
                    ],
                    [
                        -77.68055555589416,
                        36.241666666583455
                    ],
                    [
                        -77.43138888888609,
                        36.36916666650848
                    ],
                    [
                        -77.03777777782216,
                        36.56611111063927
                    ],
                    [
                        -76.9883333332167,
                        36.57999999930058
                    ],
                    [
                        -76.92777777822187,
                        36.6211111108891
                    ],
                    [
                        -76.30027777839672,
                        36.92972222230276
                    ],
                    [
                        -76.39722222272735,
                        36.97666666673331
                    ],
                    [
                        -76.42361111136364,
                        37.20277777777227
                    ],
                    [
                        -76.44027777829677,
                        37.32499999925062
                    ],
                    [
                        -76.45277777827181,
                        37.473611111163905
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 203,
            "artcc": "zdc",
            "sector": "33",
            "sectype": "low",
            "Shape_Length": 5.469018856209346,
            "Shape_Area": 1.0813001156261104
        }
    },
    {
        "type": "Feature",
        "id": 212,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -74.83666666713293,
                        39.6716666663836
                    ],
                    [
                        -74.85277777787212,
                        39.67972222220283
                    ],
                    [
                        -74.9111111116884,
                        39.70972222160327
                    ],
                    [
                        -74.9400000004996,
                        39.751666666583446
                    ],
                    [
                        -75.03000000049957,
                        39.653333332667216
                    ],
                    [
                        -75.04735285015386,
                        39.6342792238072
                    ],
                    [
                        -75.05277777837176,
                        39.63194444438068
                    ],
                    [
                        -75.0688888891109,
                        39.62194444368117
                    ],
                    [
                        -75.0699256356595,
                        39.621190445782474
                    ],
                    [
                        -75.21750000012486,
                        39.60805555501992
                    ],
                    [
                        -75.25000000059953,
                        39.47499999945046
                    ],
                    [
                        -75.2541666666583,
                        39.39444444395599
                    ],
                    [
                        -75.06666666703302,
                        39.24027777729765
                    ],
                    [
                        -75.16249999987508,
                        39.097222221928064
                    ],
                    [
                        -75.40833333331665,
                        38.71944444440561
                    ],
                    [
                        -75.25000000059953,
                        38.729166666108824
                    ],
                    [
                        -74.88333333346651,
                        38.75000000000006
                    ],
                    [
                        -74.61666666703303,
                        38.75000000000006
                    ],
                    [
                        -74.33333333366636,
                        38.75000000000006
                    ],
                    [
                        -73.98333333346653,
                        38.541666666483536
                    ],
                    [
                        -73.86111111108886,
                        38.73750000002502
                    ],
                    [
                        -74.33333333366636,
                        38.96666666653351
                    ],
                    [
                        -73.9263888892358,
                        39.314444443756145
                    ],
                    [
                        -73.99888888891104,
                        39.386111110939055
                    ],
                    [
                        -74.04277777787212,
                        39.42833333311688
                    ],
                    [
                        -74.09888888961052,
                        39.475555555644405
                    ],
                    [
                        -74.19444444435561,
                        39.57916666620872
                    ],
                    [
                        -74.49861111161346,
                        39.593055554870034
                    ],
                    [
                        -74.45555555604403,
                        39.655833332842064
                    ],
                    [
                        -74.50000000029974,
                        39.65555555564441
                    ],
                    [
                        -74.80000000059948,
                        39.65277777737259
                    ],
                    [
                        -74.83666666713293,
                        39.6716666663836
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 212,
            "artcc": "zdc",
            "sector": "51",
            "sectype": "low",
            "Shape_Length": 5.468887107016648,
            "Shape_Area": 1.0430241066719832
        }
    },
    {
        "type": "Feature",
        "id": 214,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -75.21750000012486,
                        39.60805555501992
                    ],
                    [
                        -75.19166666678319,
                        39.70833333291705
                    ],
                    [
                        -75.42500000024978,
                        39.71500000005
                    ],
                    [
                        -75.86999999990002,
                        39.334999999550405
                    ],
                    [
                        -75.83611111163844,
                        39.26666666683326
                    ],
                    [
                        -75.87583333364142,
                        38.96944444390601
                    ],
                    [
                        -75.92000000069942,
                        38.92499999965031
                    ],
                    [
                        -76.16666666663332,
                        38.680833332991995
                    ],
                    [
                        -76.06138888928575,
                        38.63444444385607
                    ],
                    [
                        -75.81333333376631,
                        38.5249999995504
                    ],
                    [
                        -75.66666666673325,
                        38.700000000099976
                    ],
                    [
                        -75.40833333331665,
                        38.71944444440561
                    ],
                    [
                        -75.16249999987508,
                        39.097222221928064
                    ],
                    [
                        -75.06666666703302,
                        39.24027777729765
                    ],
                    [
                        -75.2130555559691,
                        39.36083333289207
                    ],
                    [
                        -75.23000000009989,
                        39.3783333332168
                    ],
                    [
                        -75.23555555574427,
                        39.40194444448059
                    ],
                    [
                        -75.23583333384124,
                        39.42972222180316
                    ],
                    [
                        -75.22833333331664,
                        39.45944444400595
                    ],
                    [
                        -75.22694444463036,
                        39.4652777777473
                    ],
                    [
                        -75.21638888953561,
                        39.48694444413087
                    ],
                    [
                        -75.21083333389123,
                        39.494722221853124
                    ],
                    [
                        -75.23916666740774,
                        39.48083333319181
                    ],
                    [
                        -75.24971109429151,
                        39.47618278690612
                    ],
                    [
                        -75.21750000012486,
                        39.60805555501992
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 214,
            "artcc": "zdc",
            "sector": "53",
            "sectype": "low",
            "Shape_Length": 3.7196768124527373,
            "Shape_Area": 0.6469247000660541
        }
    },
    {
        "type": "Feature",
        "id": 247,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -105.58972222230267,
                        39.65833333301697
                    ],
                    [
                        -105.61722222242759,
                        39.75361111056435
                    ],
                    [
                        -105.62638888883612,
                        39.82666666643354
                    ],
                    [
                        -105.62472222205287,
                        39.91222222227776
                    ],
                    [
                        -105.60611111113883,
                        40.00888888851142
                    ],
                    [
                        -105.56999999990006,
                        40.10277777737258
                    ],
                    [
                        -105.51416666715795,
                        40.19638888903603
                    ],
                    [
                        -105.76249999987505,
                        40.18555555494498
                    ],
                    [
                        -106.55250000027473,
                        40.14527777764738
                    ],
                    [
                        -106.96666666713293,
                        40.11666666603384
                    ],
                    [
                        -107.22055555549446,
                        39.72083333289203
                    ],
                    [
                        -107.22055555549446,
                        39.62277777707283
                    ],
                    [
                        -107.22055555549446,
                        39.563611110764214
                    ],
                    [
                        -107.04805555601905,
                        39.53305555516977
                    ],
                    [
                        -106.99805555611897,
                        39.53305555516977
                    ],
                    [
                        -106.53944444500507,
                        39.62138888838655
                    ],
                    [
                        -106.44583333334162,
                        39.52361111066426
                    ],
                    [
                        -106.44166666728285,
                        39.433333332567315
                    ],
                    [
                        -105.72138888918585,
                        39.46305555566943
                    ],
                    [
                        -105.551666667083,
                        39.47000000000003
                    ],
                    [
                        -105.48111111138866,
                        39.47611111093909
                    ],
                    [
                        -105.54527777804697,
                        39.566944444330716
                    ],
                    [
                        -105.58972222230267,
                        39.65833333301697
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 247,
            "artcc": "zdv",
            "sector": "06",
            "sectype": "low",
            "Shape_Length": 4.741004753138306,
            "Shape_Area": 1.006892361043089
        }
    },
    {
        "type": "Feature",
        "id": 249,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -102.28444444505504,
                        39.82111111078916
                    ],
                    [
                        -102.24722222232765,
                        40.42055555519477
                    ],
                    [
                        -103.17527777774723,
                        40.251666666483516
                    ],
                    [
                        -103.48833333331663,
                        40.1830555556694
                    ],
                    [
                        -103.65111111108888,
                        40.14722222162828
                    ],
                    [
                        -103.86500000024978,
                        40.09944444380608
                    ],
                    [
                        -103.82750000032473,
                        39.99694444383107
                    ],
                    [
                        -103.81111111148857,
                        39.89999999950044
                    ],
                    [
                        -103.81222222207788,
                        39.80222222177815
                    ],
                    [
                        -103.83472222275236,
                        39.69388888896111
                    ],
                    [
                        -103.86722222232765,
                        39.61194444388104
                    ],
                    [
                        -103.92444444465536,
                        39.51027777729763
                    ],
                    [
                        -103.72222222207785,
                        39.447777777422516
                    ],
                    [
                        -103.67166666688314,
                        39.43222222197801
                    ],
                    [
                        -103.24222222267741,
                        39.3338888889611
                    ],
                    [
                        -102.92972222240263,
                        39.261388888386534
                    ],
                    [
                        -102.33305555626885,
                        39.01749999982519
                    ],
                    [
                        -102.28444444505504,
                        39.82111111078916
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 249,
            "artcc": "zdv",
            "sector": "07",
            "sectype": "low",
            "Shape_Length": 5.3465106527680915,
            "Shape_Area": 1.545334876098173
        }
    },
    {
        "type": "Feature",
        "id": 253,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -107.36833333401614,
                        39.491944444480566
                    ],
                    [
                        -107.22055555549446,
                        39.491944444480566
                    ],
                    [
                        -107.22055555549446,
                        39.563611110764214
                    ],
                    [
                        -107.22055555549446,
                        39.62277777707283
                    ],
                    [
                        -107.22055555549446,
                        39.72083333289203
                    ],
                    [
                        -106.96666666713293,
                        40.11666666603384
                    ],
                    [
                        -107.58333333376629,
                        40.2666666666334
                    ],
                    [
                        -109.15000000029971,
                        40.23333333276713
                    ],
                    [
                        -109.16666666723285,
                        39.9999999993006
                    ],
                    [
                        -109.81666666683316,
                        39.76388888846151
                    ],
                    [
                        -110.30000000069941,
                        39.58333333316682
                    ],
                    [
                        -109.98333333346653,
                        39.21666666603386
                    ],
                    [
                        -109.98333333346653,
                        38.94555555544457
                    ],
                    [
                        -110.09805555621892,
                        38.90999999950043
                    ],
                    [
                        -110.19527777774721,
                        38.520833332592304
                    ],
                    [
                        -109.98333333346653,
                        38.52777777782222
                    ],
                    [
                        -109.98333333346653,
                        38.19999999930059
                    ],
                    [
                        -109.03333333356647,
                        38.18333333326677
                    ],
                    [
                        -108.86833333371635,
                        38.753888888861184
                    ],
                    [
                        -109.21083333339158,
                        38.92166666608381
                    ],
                    [
                        -109.22083333319176,
                        39.31027777769731
                    ],
                    [
                        -109.16055555629384,
                        39.36333333306692
                    ],
                    [
                        -108.9961111117384,
                        39.44555555534464
                    ],
                    [
                        -108.82805555551943,
                        39.475555555644405
                    ],
                    [
                        -108.68777777842172,
                        39.48083333319181
                    ],
                    [
                        -108.21583333394119,
                        39.31333333316684
                    ],
                    [
                        -107.92138888928577,
                        39.09055555569438
                    ],
                    [
                        -107.41638888903594,
                        39.234999999750244
                    ],
                    [
                        -107.40277777847166,
                        39.30777777752246
                    ],
                    [
                        -107.36833333401614,
                        39.491944444480566
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 253,
            "artcc": "zdv",
            "sector": "11",
            "sectype": "low",
            "Shape_Length": 10.852811886953356,
            "Shape_Area": 3.466262654058902
        }
    },
    {
        "type": "Feature",
        "id": 255,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -107.42777777842173,
                        39.16916666630868
                    ],
                    [
                        -107.41638888903594,
                        39.234999999750244
                    ],
                    [
                        -107.92138888928577,
                        39.09055555569438
                    ],
                    [
                        -108.41972222240258,
                        38.76055555509487
                    ],
                    [
                        -108.6663888892358,
                        38.654166666258675
                    ],
                    [
                        -108.86833333371635,
                        38.753888888861184
                    ],
                    [
                        -109.03333333356647,
                        38.18333333326677
                    ],
                    [
                        -109.07611111103893,
                        37.85666666623371
                    ],
                    [
                        -107.68805555581923,
                        37.80749999972528
                    ],
                    [
                        -107.32871666724037,
                        37.791919444027144
                    ],
                    [
                        -106.88194444468036,
                        37.771388888486456
                    ],
                    [
                        -106.61166666658335,
                        37.87361111036449
                    ],
                    [
                        -106.1494444447053,
                        38.04527777734762
                    ],
                    [
                        -105.93005277841297,
                        38.12045000006748
                    ],
                    [
                        -106.24655833389994,
                        38.505324999649076
                    ],
                    [
                        -106.68861111131372,
                        39.03388888866135
                    ],
                    [
                        -107.24416666675825,
                        39.03388888866135
                    ],
                    [
                        -107.42777777842173,
                        39.16916666630868
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 255,
            "artcc": "zdv",
            "sector": "12",
            "sectype": "low",
            "Shape_Length": 7.787766019744016,
            "Shape_Area": 3.096594778424544
        }
    },
    {
        "type": "Feature",
        "id": 257,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -105.76249999987505,
                        40.18555555494498
                    ],
                    [
                        -105.51416666715795,
                        40.19638888903603
                    ],
                    [
                        -105.46722222272734,
                        40.254166666658364
                    ],
                    [
                        -105.38777777782218,
                        40.33055555519479
                    ],
                    [
                        -105.30722222232765,
                        40.38999999960038
                    ],
                    [
                        -105.20416666705802,
                        40.44777777722271
                    ],
                    [
                        -105.09111111108888,
                        40.49416666635864
                    ],
                    [
                        -105.14472222265243,
                        40.5633333333667
                    ],
                    [
                        -105.25888888921082,
                        40.61611111063928
                    ],
                    [
                        -105.27361111126373,
                        40.72916666660842
                    ],
                    [
                        -105.37527777784715,
                        40.855833333141845
                    ],
                    [
                        -105.7283333335165,
                        41.05305555536961
                    ],
                    [
                        -106.01666666723287,
                        41.11666666673335
                    ],
                    [
                        -106.55944444460539,
                        41.23083333329174
                    ],
                    [
                        -106.59805555601906,
                        40.40499999975026
                    ],
                    [
                        -106.55250000027473,
                        40.14527777764738
                    ],
                    [
                        -105.76249999987505,
                        40.18555555494498
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 257,
            "artcc": "zdv",
            "sector": "13",
            "sectype": "low",
            "Shape_Length": 4.399184941865067,
            "Shape_Area": 1.1547094140540053
        }
    },
    {
        "type": "Feature",
        "id": 260,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -103.17499999965025,
                        41.241666666483525
                    ],
                    [
                        -103.87500000004991,
                        40.737499999625356
                    ],
                    [
                        -104.02638888933575,
                        40.542222222277815
                    ],
                    [
                        -104.10472222185302,
                        40.4405555556944
                    ],
                    [
                        -104.14444444475527,
                        40.39944444410588
                    ],
                    [
                        -104.04888888911091,
                        40.33055555519479
                    ],
                    [
                        -103.96055555589413,
                        40.24388888876126
                    ],
                    [
                        -103.90916666640851,
                        40.18083333359152
                    ],
                    [
                        -103.86500000024978,
                        40.0994444447054
                    ],
                    [
                        -103.65111111108888,
                        40.1472222225276
                    ],
                    [
                        -103.48833333331663,
                        40.1830555556694
                    ],
                    [
                        -103.17527777774723,
                        40.251666666483516
                    ],
                    [
                        -102.24722222232765,
                        40.42055555519477
                    ],
                    [
                        -102.2702777773975,
                        41.471944444480584
                    ],
                    [
                        -103.03333333296689,
                        41.32916666630865
                    ],
                    [
                        -103.17499999965025,
                        41.241666666483525
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 260,
            "artcc": "zdv",
            "sector": "15",
            "sectype": "low",
            "Shape_Length": 5.354820307246156,
            "Shape_Area": 1.6067823687262008
        }
    },
    {
        "type": "Feature",
        "id": 265,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -101.1583333340161,
                        38.258333333116866
                    ],
                    [
                        -100.35284444491515,
                        38.68238888853642
                    ],
                    [
                        -100.23388888936074,
                        38.74416666625871
                    ],
                    [
                        -99.63055555609401,
                        39.05833333331674
                    ],
                    [
                        -99.21666666733279,
                        39.26666666683326
                    ],
                    [
                        -98.80000000029975,
                        39.46666666643358
                    ],
                    [
                        -99.05833333371635,
                        39.983333333266785
                    ],
                    [
                        -99.05555555544447,
                        40.23055555539463
                    ],
                    [
                        -99.05416666675825,
                        40.349999999500426
                    ],
                    [
                        -99.04722222242759,
                        40.88333333326676
                    ],
                    [
                        -99.03333333376628,
                        41.07499999985015
                    ],
                    [
                        -99.02499999985008,
                        41.71666666643358
                    ],
                    [
                        -99.01666666683315,
                        41.999999999800195
                    ],
                    [
                        -100.24138888898602,
                        41.813888888861186
                    ],
                    [
                        -101.30305555616894,
                        41.640833333191836
                    ],
                    [
                        -101.91444444435558,
                        41.5363888883366
                    ],
                    [
                        -102.27027777829682,
                        41.471944444480584
                    ],
                    [
                        -102.24722222232765,
                        40.42055555519477
                    ],
                    [
                        -102.28444444505504,
                        39.82111111078916
                    ],
                    [
                        -102.33305555626885,
                        39.01749999982519
                    ],
                    [
                        -102.2333333336664,
                        38.98333333256727
                    ],
                    [
                        -101.92500000034971,
                        38.75000000000006
                    ],
                    [
                        -101.23333333386626,
                        38.218611111113944
                    ],
                    [
                        -101.1583333340161,
                        38.258333333116866
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 265,
            "artcc": "zdv",
            "sector": "19",
            "sectype": "low",
            "Shape_Length": 12.448002835475956,
            "Shape_Area": 9.801760279706311
        }
    },
    {
        "type": "Feature",
        "id": 267,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -105.27361111126373,
                        40.72916666660842
                    ],
                    [
                        -104.96555555604402,
                        40.91944444450559
                    ],
                    [
                        -105.17083333319175,
                        41.06666666683327
                    ],
                    [
                        -105.13888888891108,
                        41.400000000099965
                    ],
                    [
                        -104.84999999990004,
                        41.499999999900126
                    ],
                    [
                        -104.18027777789712,
                        41.345833333241785
                    ],
                    [
                        -104.15638888853636,
                        41.17666666643356
                    ],
                    [
                        -104.3658333335415,
                        40.930833332991995
                    ],
                    [
                        -104.4555555554445,
                        40.800000000399734
                    ],
                    [
                        -104.10472222185302,
                        40.4405555556944
                    ],
                    [
                        -104.02638888933575,
                        40.542222222277815
                    ],
                    [
                        -103.87500000004991,
                        40.737499999625356
                    ],
                    [
                        -103.17499999965025,
                        41.241666666483525
                    ],
                    [
                        -103.38333333316677,
                        41.558333333716405
                    ],
                    [
                        -104.59166666648343,
                        41.80833333321681
                    ],
                    [
                        -105.1375000002248,
                        41.81250000017491
                    ],
                    [
                        -106.02083333329165,
                        41.52499999985014
                    ],
                    [
                        -106.01666666633355,
                        41.11666666673335
                    ],
                    [
                        -105.7283333335165,
                        41.05305555536961
                    ],
                    [
                        -105.37527777784715,
                        40.855833333141845
                    ],
                    [
                        -105.27361111126373,
                        40.72916666660842
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 267,
            "artcc": "zdv",
            "sector": "21",
            "sectype": "low",
            "Shape_Length": 8.693533529126025,
            "Shape_Area": 1.8133061346650279
        }
    },
    {
        "type": "Feature",
        "id": 269,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -104.84999999990004,
                        43.81666666673334
                    ],
                    [
                        -104.76944444440556,
                        45.14999999980023
                    ],
                    [
                        -106.00000000029974,
                        45.237499999625356
                    ],
                    [
                        -106.14166666698304,
                        44.7666666666334
                    ],
                    [
                        -106.26666666673327,
                        44.31666666663341
                    ],
                    [
                        -107.28333333346654,
                        43.883333332667235
                    ],
                    [
                        -107.1333333337663,
                        42.94999999970025
                    ],
                    [
                        -107.07361111126374,
                        42.54305555526969
                    ],
                    [
                        -107.06666666693309,
                        42.48333333276713
                    ],
                    [
                        -107.04999999999995,
                        42.41666666683324
                    ],
                    [
                        -107.99999999990001,
                        41.608333332717166
                    ],
                    [
                        -108.0722222223776,
                        41.53749999982517
                    ],
                    [
                        -106.55944444460539,
                        41.23083333329174
                    ],
                    [
                        -106.01666666723287,
                        41.11666666673335
                    ],
                    [
                        -106.02083333329165,
                        41.52499999985014
                    ],
                    [
                        -105.1375000002248,
                        41.81249999927559
                    ],
                    [
                        -104.59166666738275,
                        41.80833333321681
                    ],
                    [
                        -104.38333333386623,
                        42.283333333166865
                    ],
                    [
                        -104.72361111146358,
                        43.16666666623371
                    ],
                    [
                        -104.85833333381629,
                        43.48333333256727
                    ],
                    [
                        -104.84999999990004,
                        43.81666666673334
                    ]
                ],
                [
                    [
                        -106.96250000017483,
                        42.63611111073925
                    ],
                    [
                        -107.03888888961052,
                        43.2347222217532
                    ],
                    [
                        -106.44111111108884,
                        43.50833333341666
                    ],
                    [
                        -106.11638888893606,
                        43.366944443931004
                    ],
                    [
                        -105.8458333336414,
                        43.21250000007501
                    ],
                    [
                        -105.76666666683315,
                        42.62222222207794
                    ],
                    [
                        -106.40972222210286,
                        42.34888888851145
                    ],
                    [
                        -106.96250000017483,
                        42.63611111073925
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 269,
            "artcc": "zdv",
            "sector": "22",
            "sectype": "low",
            "Shape_Length": 17.435327948942007,
            "Shape_Area": 7.4438496133776635
        }
    },
    {
        "type": "Feature",
        "id": 274,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -105.68888888961055,
                        38.48888888831158
                    ],
                    [
                        -105.35472222205289,
                        38.97166666598389
                    ],
                    [
                        -105.15555555584422,
                        39.134166666558485
                    ],
                    [
                        -105.19500000064949,
                        39.25777777762238
                    ],
                    [
                        -105.27361111126373,
                        39.301388888486485
                    ],
                    [
                        -105.35638888883614,
                        39.35749999932557
                    ],
                    [
                        -105.44249999997498,
                        39.43083333329173
                    ],
                    [
                        -105.48111111138866,
                        39.47611111093909
                    ],
                    [
                        -105.551666667083,
                        39.47000000000003
                    ],
                    [
                        -105.72138888918585,
                        39.46305555566943
                    ],
                    [
                        -106.44166666728285,
                        39.433333332567315
                    ],
                    [
                        -106.42777777772221,
                        39.142777777672336
                    ],
                    [
                        -106.68861111131372,
                        39.03388888866135
                    ],
                    [
                        -106.24655833389994,
                        38.505324999649076
                    ],
                    [
                        -105.93005277841297,
                        38.12045000006748
                    ],
                    [
                        -105.68888888961055,
                        38.48888888831158
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 274,
            "artcc": "zdv",
            "sector": "26",
            "sectype": "low",
            "Shape_Length": 4.499271792014242,
            "Shape_Area": 1.1294534548497546
        }
    },
    {
        "type": "Feature",
        "id": 276,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -101.23333333386626,
                        38.218611111113944
                    ],
                    [
                        -101.92500000034971,
                        38.75000000000006
                    ],
                    [
                        -102.2333333336664,
                        38.98333333256727
                    ],
                    [
                        -102.33305555626885,
                        39.01749999982519
                    ],
                    [
                        -102.92972222240263,
                        39.261388888386534
                    ],
                    [
                        -103.24222222267741,
                        39.3338888889611
                    ],
                    [
                        -103.67166666688314,
                        39.43222222197801
                    ],
                    [
                        -103.72222222207785,
                        39.447777777422516
                    ],
                    [
                        -103.92444444465536,
                        39.51027777729763
                    ],
                    [
                        -103.95166666668331,
                        39.48083333319181
                    ],
                    [
                        -104.00666666693309,
                        39.42111111068925
                    ],
                    [
                        -104.09138888938571,
                        39.34916666630863
                    ],
                    [
                        -104.1630555556693,
                        39.301388888486485
                    ],
                    [
                        -104.24416666735777,
                        39.26055555499494
                    ],
                    [
                        -104.26416666695809,
                        39.25027777709778
                    ],
                    [
                        -104.26055555619394,
                        39.23333333296699
                    ],
                    [
                        -104.25250000037471,
                        39.19666666643354
                    ],
                    [
                        -104.20651944481648,
                        39.102583333354175
                    ],
                    [
                        -104.08333333356643,
                        38.84999999980022
                    ],
                    [
                        -104.05361111136364,
                        38.60083333279215
                    ],
                    [
                        -104.04555555554441,
                        38.49916666620874
                    ],
                    [
                        -103.89222222227772,
                        38.485277777547424
                    ],
                    [
                        -103.75583333314177,
                        38.38722222172822
                    ],
                    [
                        -102.54999999999995,
                        37.499999999800195
                    ],
                    [
                        -101.23333333386626,
                        38.218611111113944
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 276,
            "artcc": "zdv",
            "sector": "27",
            "sectype": "low",
            "Shape_Length": 7.579206540633366,
            "Shape_Area": 3.1199182425389846
        }
    },
    {
        "type": "Feature",
        "id": 281,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -103.16666666663332,
                        44.958333333216785
                    ],
                    [
                        -104.2500000001998,
                        45.11666666683328
                    ],
                    [
                        -104.76944444440556,
                        45.14999999980023
                    ],
                    [
                        -104.84999999990004,
                        43.81666666673334
                    ],
                    [
                        -104.85833333381629,
                        43.48333333256727
                    ],
                    [
                        -104.72361111146358,
                        43.16666666623371
                    ],
                    [
                        -104.38333333386623,
                        42.283333333166865
                    ],
                    [
                        -104.59166666738275,
                        41.80833333321681
                    ],
                    [
                        -103.38333333316677,
                        41.558333332817085
                    ],
                    [
                        -103.17500000054957,
                        41.241666666483525
                    ],
                    [
                        -103.03333333386621,
                        41.32916666630865
                    ],
                    [
                        -102.27027777829682,
                        41.471944444480584
                    ],
                    [
                        -101.91444444435558,
                        41.5363888883366
                    ],
                    [
                        -101.30305555616894,
                        41.640833333191836
                    ],
                    [
                        -100.24138888898602,
                        41.813888888861186
                    ],
                    [
                        -99.01666666683315,
                        41.999999999800195
                    ],
                    [
                        -99.96666666673326,
                        43.13333333326676
                    ],
                    [
                        -100.10000000039969,
                        43.28888888861138
                    ],
                    [
                        -101.40833333351651,
                        43.70833333301698
                    ],
                    [
                        -101.4833333333666,
                        44.699999999800184
                    ],
                    [
                        -103.16666666663332,
                        44.958333333216785
                    ]
                ],
                [
                    [
                        -103.86111111138865,
                        43.75833333291706
                    ],
                    [
                        -104.01944444500509,
                        44.21388888856143
                    ],
                    [
                        -103.66666666653339,
                        44.66666666683324
                    ],
                    [
                        -102.8833333332667,
                        44.78333333266721
                    ],
                    [
                        -102.36666666733282,
                        44.53055555489499
                    ],
                    [
                        -102.20416666675823,
                        44.02916666630864
                    ],
                    [
                        -102.50000000009987,
                        43.76388888856144
                    ],
                    [
                        -103.11666666673324,
                        43.47499999955039
                    ],
                    [
                        -103.86111111138865,
                        43.75833333291706
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 281,
            "artcc": "zdv",
            "sector": "31",
            "sectype": "low",
            "Shape_Length": 21.526639443431748,
            "Shape_Area": 12.541255787701155
        }
    },
    {
        "type": "Feature",
        "id": 287,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -106.61166666658335,
                        37.87361111036449
                    ],
                    [
                        -106.88194444468036,
                        37.771388888486456
                    ],
                    [
                        -107.32871666724037,
                        37.791919444027144
                    ],
                    [
                        -107.68805555581923,
                        37.80749999972528
                    ],
                    [
                        -109.07611111103893,
                        37.85666666623371
                    ],
                    [
                        -109.31666666693309,
                        35.849999999500426
                    ],
                    [
                        -108.21666666733279,
                        36.033333333066935
                    ],
                    [
                        -107.466666667033,
                        36.19999999970025
                    ],
                    [
                        -106.35000000049956,
                        36.626944443731134
                    ],
                    [
                        -106.43640555597159,
                        37.042888888611344
                    ],
                    [
                        -106.61166666658335,
                        37.87361111036449
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 287,
            "artcc": "zdv",
            "sector": "36",
            "sectype": "low",
            "Shape_Length": 8.858646885400141,
            "Shape_Area": 4.526260028048156
        }
    },
    {
        "type": "Feature",
        "id": 292,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -105.00444444465535,
                        38.44388888876125
                    ],
                    [
                        -104.98694444433062,
                        38.541111111188854
                    ],
                    [
                        -104.95002499995377,
                        38.7044777776274
                    ],
                    [
                        -104.86666666683317,
                        39.11305555546954
                    ],
                    [
                        -105.08500000014988,
                        39.12888888901108
                    ],
                    [
                        -105.15555555584422,
                        39.134166666558485
                    ],
                    [
                        -105.35472222205289,
                        38.97166666598389
                    ],
                    [
                        -105.68888888961055,
                        38.48888888831158
                    ],
                    [
                        -105.93005277841297,
                        38.12045000006748
                    ],
                    [
                        -106.1494444447053,
                        38.04527777734762
                    ],
                    [
                        -106.61166666658335,
                        37.87361111036449
                    ],
                    [
                        -106.43640555597159,
                        37.042888888611344
                    ],
                    [
                        -106.35000000049956,
                        36.626944443731134
                    ],
                    [
                        -106.08333333316676,
                        36.71666666653351
                    ],
                    [
                        -105.34166666678323,
                        36.71666666653351
                    ],
                    [
                        -105.0000000004996,
                        36.71666666653351
                    ],
                    [
                        -104.00000000069946,
                        37.045277777547426
                    ],
                    [
                        -103.61944444490518,
                        37.162499999575346
                    ],
                    [
                        -102.54999999999995,
                        37.499999999800195
                    ],
                    [
                        -103.75583333314177,
                        38.38722222172822
                    ],
                    [
                        -103.89222222227772,
                        38.485277777547424
                    ],
                    [
                        -104.04555555554441,
                        38.49916666620874
                    ],
                    [
                        -104.0752777777472,
                        38.49861111091411
                    ],
                    [
                        -104.2500000001998,
                        38.4833333326672
                    ],
                    [
                        -104.81666666693309,
                        38.437499999725276
                    ],
                    [
                        -104.95888888891108,
                        38.43555555574437
                    ],
                    [
                        -105.00444444465535,
                        38.44388888876125
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 292,
            "artcc": "zdv",
            "sector": "41",
            "sectype": "low",
            "Shape_Length": 10.974402884618854,
            "Shape_Area": 5.369793194446633
        }
    },
    {
        "type": "Feature",
        "id": 300,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -109.07611111103893,
                        37.85666666623371
                    ],
                    [
                        -109.03333333356647,
                        38.18333333326677
                    ],
                    [
                        -109.98333333346653,
                        38.19999999930059
                    ],
                    [
                        -111.31388888916086,
                        37.64999999950044
                    ],
                    [
                        -111.35833333341657,
                        37.66944444380607
                    ],
                    [
                        -111.50000000009987,
                        37.69999999940052
                    ],
                    [
                        -111.89583333324168,
                        37.41388888866135
                    ],
                    [
                        -111.87916666720787,
                        37.41249999997507
                    ],
                    [
                        -111.71844444465535,
                        37.00499999935056
                    ],
                    [
                        -111.60833333381629,
                        36.73333333256727
                    ],
                    [
                        -111.53555555604402,
                        36.51499999925062
                    ],
                    [
                        -111.50416666705803,
                        36.42083333319181
                    ],
                    [
                        -111.84166666728282,
                        35.7666666666334
                    ],
                    [
                        -110.23333333386626,
                        35.699999999800184
                    ],
                    [
                        -109.31666666693309,
                        35.849999999500426
                    ],
                    [
                        -109.07611111103893,
                        37.85666666623371
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 300,
            "artcc": "zdv",
            "sector": "68",
            "sectype": "low",
            "Shape_Length": 9.774213260236166,
            "Shape_Area": 5.573578047791357
        }
    },
    {
        "type": "Feature",
        "id": 302,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -109.15000000029971,
                        40.23333333366645
                    ],
                    [
                        -107.58333333286697,
                        40.2666666666334
                    ],
                    [
                        -106.96666666713293,
                        40.11666666693321
                    ],
                    [
                        -106.55250000027473,
                        40.14527777764738
                    ],
                    [
                        -106.59805555601906,
                        40.40499999975026
                    ],
                    [
                        -106.55944444460539,
                        41.23083333329174
                    ],
                    [
                        -108.0722222223776,
                        41.53749999982517
                    ],
                    [
                        -108.2750000002498,
                        41.36666666713302
                    ],
                    [
                        -109.10000000039969,
                        40.850000000299815
                    ],
                    [
                        -109.15000000029971,
                        40.23333333366645
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 302,
            "artcc": "zdv",
            "sector": "77",
            "sectype": "low",
            "Shape_Length": 7.108062605377971,
            "Shape_Area": 2.7466931325837334
        }
    },
    {
        "type": "Feature",
        "id": 316,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -94.32500000024976,
                        32.6249999996503
                    ],
                    [
                        -93.93333333316673,
                        32.65833333261725
                    ],
                    [
                        -93.81666666733281,
                        32.67222222217788
                    ],
                    [
                        -93.71111111098895,
                        32.75555555504491
                    ],
                    [
                        -93.67222222237763,
                        32.8791666661088
                    ],
                    [
                        -93.6333333337663,
                        33.01666666673333
                    ],
                    [
                        -93.491666667083,
                        33.46111111108894
                    ],
                    [
                        -92.99166666718293,
                        33.82499999995008
                    ],
                    [
                        -93.15833333381624,
                        33.94999999970025
                    ],
                    [
                        -93.54166666698308,
                        34.03333333256728
                    ],
                    [
                        -93.65833333371631,
                        34.08888888901106
                    ],
                    [
                        -93.99999999999994,
                        34.26666666603387
                    ],
                    [
                        -94.53333333376628,
                        34.53333333336667
                    ],
                    [
                        -94.78333333326668,
                        34.69166666608385
                    ],
                    [
                        -95.33055555569433,
                        34.08222222187811
                    ],
                    [
                        -95.27194444468034,
                        34.012222221478396
                    ],
                    [
                        -95.21361111176338,
                        33.93972222180315
                    ],
                    [
                        -95.16472222245255,
                        33.85416666595893
                    ],
                    [
                        -95.14166666738276,
                        33.80611111093907
                    ],
                    [
                        -95.11611111123875,
                        33.74249999957539
                    ],
                    [
                        -95.08750000052459,
                        33.654166666358606
                    ],
                    [
                        -95.34166666698303,
                        33.46666666673332
                    ],
                    [
                        -94.87500000004991,
                        33.333333333066946
                    ],
                    [
                        -94.88750000002494,
                        32.96111111118887
                    ],
                    [
                        -94.88333333396616,
                        32.783333333266796
                    ],
                    [
                        -94.97805555621892,
                        32.66361111106397
                    ],
                    [
                        -94.50416666685817,
                        32.606944444030944
                    ],
                    [
                        -94.32500000024976,
                        32.6249999996503
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 316,
            "artcc": "zfw",
            "sector": "27",
            "sectype": "low",
            "Shape_Length": 7.487713458115243,
            "Shape_Area": 2.778900539711641
        }
    },
    {
        "type": "Feature",
        "id": 318,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -95.98333333356646,
                        31.46666666623372
                    ],
                    [
                        -95.6291666673078,
                        31.487500000124953
                    ],
                    [
                        -95.291666667083,
                        31.500000000099988
                    ],
                    [
                        -94.87500000004991,
                        31.533333333066935
                    ],
                    [
                        -94.77826666707301,
                        31.537855555089834
                    ],
                    [
                        -94.60833333361643,
                        31.545833333041912
                    ],
                    [
                        -94.60833333361643,
                        31.80833333341667
                    ],
                    [
                        -94.58972222270239,
                        32.19388888866132
                    ],
                    [
                        -94.83916666690811,
                        32.332777777072806
                    ],
                    [
                        -95.24166666718293,
                        32.533611110964046
                    ],
                    [
                        -95.85722222232766,
                        32.58944444370616
                    ],
                    [
                        -96.33333333376629,
                        32.628611110414454
                    ],
                    [
                        -96.60083333359142,
                        32.55055555509483
                    ],
                    [
                        -96.68972222210283,
                        32.28333333336667
                    ],
                    [
                        -96.26666666693308,
                        31.35833333341668
                    ],
                    [
                        -95.98333333356646,
                        31.46666666623372
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 318,
            "artcc": "zfw",
            "sector": "29",
            "sectype": "low",
            "Shape_Length": 5.737866035282789,
            "Shape_Area": 1.9929019277974798
        }
    },
    {
        "type": "Feature",
        "id": 319,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -92.51666666723287,
                        33.86666666683328
                    ],
                    [
                        -92.69999999990006,
                        33.89999999980023
                    ],
                    [
                        -92.91666666733278,
                        33.7666666661338
                    ],
                    [
                        -92.99166666718293,
                        33.82499999995008
                    ],
                    [
                        -93.491666667083,
                        33.46111111108894
                    ],
                    [
                        -93.6333333337663,
                        33.01666666673333
                    ],
                    [
                        -93.67222222237763,
                        32.8791666661088
                    ],
                    [
                        -93.71111111098895,
                        32.75555555504491
                    ],
                    [
                        -93.81666666733281,
                        32.67222222217788
                    ],
                    [
                        -93.93333333316673,
                        32.65833333261725
                    ],
                    [
                        -94.32500000024976,
                        32.6249999996503
                    ],
                    [
                        -94.50416666685817,
                        32.606944444030944
                    ],
                    [
                        -94.97805555621892,
                        32.66361111106397
                    ],
                    [
                        -95.24166666718293,
                        32.533611110964046
                    ],
                    [
                        -94.83916666690811,
                        32.332777777072806
                    ],
                    [
                        -94.58972222270239,
                        32.19388888866132
                    ],
                    [
                        -94.60833333361643,
                        31.80833333341667
                    ],
                    [
                        -94.60833333361643,
                        31.545833333041912
                    ],
                    [
                        -94.54722222242759,
                        31.561111110389504
                    ],
                    [
                        -94.20000000049959,
                        31.57222222167826
                    ],
                    [
                        -93.84333333316675,
                        31.569999999600327
                    ],
                    [
                        -93.61666666683317,
                        31.566666666033882
                    ],
                    [
                        -93.56666666693309,
                        31.566666666033882
                    ],
                    [
                        -93.39166666728283,
                        31.566666666033882
                    ],
                    [
                        -93.32083333349152,
                        31.558333333016947
                    ],
                    [
                        -92.68333333386624,
                        31.995833333041958
                    ],
                    [
                        -92.52222222287725,
                        32.1083333328171
                    ],
                    [
                        -92.25833333381627,
                        32.28333333336667
                    ],
                    [
                        -92.03333333336661,
                        32.28333333336667
                    ],
                    [
                        -91.6888888888111,
                        32.28333333336667
                    ],
                    [
                        -91.78333333386621,
                        32.61666666663342
                    ],
                    [
                        -91.9000000005995,
                        33.004166666758294
                    ],
                    [
                        -92.53333333326668,
                        33.48333333276713
                    ],
                    [
                        -92.51666666723287,
                        33.86666666683328
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 319,
            "artcc": "zfw",
            "sector": "30",
            "sectype": "low",
            "Shape_Length": 9.94811914162986,
            "Shape_Area": 4.050943326128446
        }
    },
    {
        "type": "Feature",
        "id": 320,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -98.75805555621889,
                        33.5158333332418
                    ],
                    [
                        -98.99166666688313,
                        33.03888888841152
                    ],
                    [
                        -99.1086111117134,
                        32.90944444450554
                    ],
                    [
                        -99.03194444508006,
                        32.44972222190307
                    ],
                    [
                        -98.86666666713296,
                        32.46666666603386
                    ],
                    [
                        -97.7333333336664,
                        32.5899999999001
                    ],
                    [
                        -97.3947222228523,
                        32.38750000012493
                    ],
                    [
                        -97.12833333361641,
                        32.94805555501989
                    ],
                    [
                        -97.49750000002496,
                        33.25111111078917
                    ],
                    [
                        -97.7333333336664,
                        33.17499999945045
                    ],
                    [
                        -98.75805555621889,
                        33.5158333332418
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 320,
            "artcc": "zfw",
            "sector": "32",
            "sectype": "low",
            "Shape_Length": 5.298301583925657,
            "Shape_Area": 1.4130226466062927
        }
    },
    {
        "type": "Feature",
        "id": 321,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -99.16277777767226,
                        34.647222221828144
                    ],
                    [
                        -99.77222222277732,
                        34.6583333331169
                    ],
                    [
                        -100.07361111176334,
                        34.77222222157832
                    ],
                    [
                        -100.31666666693309,
                        34.86666666663342
                    ],
                    [
                        -100.74999999999994,
                        34.46666666653351
                    ],
                    [
                        -100.40833333371631,
                        33.999999999600334
                    ],
                    [
                        -100.29444444435558,
                        33.833333332967015
                    ],
                    [
                        -100.1547222225525,
                        33.62722222152837
                    ],
                    [
                        -100.03750000052457,
                        33.38333333296697
                    ],
                    [
                        -99.97500000064946,
                        33.27499999925061
                    ],
                    [
                        -99.92666666663331,
                        33.22555555554453
                    ],
                    [
                        -99.9111111111888,
                        33.204999999750214
                    ],
                    [
                        -99.89583333384127,
                        33.18638888883618
                    ],
                    [
                        -99.86000000069942,
                        33.149722222302785
                    ],
                    [
                        -99.84222222227771,
                        33.13111111048943
                    ],
                    [
                        -99.82472222285224,
                        33.11166666618374
                    ],
                    [
                        -99.81138888948561,
                        33.102499999775205
                    ],
                    [
                        -99.80638888913592,
                        33.093888888661354
                    ],
                    [
                        -99.76833333391619,
                        33.05944444420578
                    ],
                    [
                        -99.68722222222772,
                        32.994166666058845
                    ],
                    [
                        -99.66694444453043,
                        32.977777777222684
                    ],
                    [
                        -99.62416666705798,
                        32.947222221628294
                    ],
                    [
                        -99.6041666665584,
                        32.93305555487001
                    ],
                    [
                        -99.55888888891104,
                        32.904722222252815
                    ],
                    [
                        -99.51361111126374,
                        32.87611111063927
                    ],
                    [
                        -99.49805555581923,
                        32.86611111083914
                    ],
                    [
                        -99.48944444470533,
                        32.86250000007499
                    ],
                    [
                        -99.42499999994999,
                        32.922222221678226
                    ],
                    [
                        -99.1086111117134,
                        32.90944444450554
                    ],
                    [
                        -98.99166666688313,
                        33.03888888841152
                    ],
                    [
                        -98.75805555621889,
                        33.5158333332418
                    ],
                    [
                        -98.73277777817191,
                        33.561666666183726
                    ],
                    [
                        -98.70416666655836,
                        33.61416666625871
                    ],
                    [
                        -98.6841666669581,
                        33.650833332792104
                    ],
                    [
                        -98.59166666678323,
                        33.82499999995008
                    ],
                    [
                        -98.52250000067448,
                        33.95305555516978
                    ],
                    [
                        -98.37083333329167,
                        34.119444443706186
                    ],
                    [
                        -98.11944444510505,
                        34.246666666433555
                    ],
                    [
                        -98.30611111133868,
                        34.28888888861138
                    ],
                    [
                        -98.48333333396613,
                        34.32499999985015
                    ],
                    [
                        -98.4294444443056,
                        34.387499999725264
                    ],
                    [
                        -98.3141666671579,
                        34.58722222212788
                    ],
                    [
                        -98.28333333346654,
                        34.63750000012493
                    ],
                    [
                        -98.75555555604404,
                        34.63750000012493
                    ],
                    [
                        -98.80833333331663,
                        34.63750000012493
                    ],
                    [
                        -99.16277777767226,
                        34.647222221828144
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 321,
            "artcc": "zfw",
            "sector": "34",
            "sectype": "low",
            "Shape_Length": 7.586337301606506,
            "Shape_Area": 2.9200764277137248
        }
    },
    {
        "type": "Feature",
        "id": 322,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -97.88333333336658,
                        35.92499999935052
                    ],
                    [
                        -98.01666666703301,
                        35.88333333336669
                    ],
                    [
                        -98.14833333391618,
                        35.86249999947546
                    ],
                    [
                        -98.466666667033,
                        35.81666666653348
                    ],
                    [
                        -98.49999999999994,
                        35.81666666653348
                    ],
                    [
                        -98.80000000029975,
                        35.81666666653348
                    ],
                    [
                        -99.22777777772222,
                        35.81666666653348
                    ],
                    [
                        -99.38361111116382,
                        35.83333333256729
                    ],
                    [
                        -100.00000000059953,
                        35.82916666650851
                    ],
                    [
                        -100.00000000059953,
                        35.33333333266722
                    ],
                    [
                        -100.06111111178836,
                        35.249999999800195
                    ],
                    [
                        -100.14166666728283,
                        35.1291666661088
                    ],
                    [
                        -100.31666666693309,
                        34.86666666663342
                    ],
                    [
                        -100.07361111176334,
                        34.77222222157832
                    ],
                    [
                        -99.77222222277732,
                        34.6583333331169
                    ],
                    [
                        -99.16277777767226,
                        34.647222221828144
                    ],
                    [
                        -98.80833333331663,
                        34.63750000012493
                    ],
                    [
                        -98.75555555604404,
                        34.63750000012493
                    ],
                    [
                        -98.28333333346654,
                        34.63750000012493
                    ],
                    [
                        -98.3141666671579,
                        34.58722222212788
                    ],
                    [
                        -98.4294444443056,
                        34.387499999725264
                    ],
                    [
                        -98.48333333396613,
                        34.32499999985015
                    ],
                    [
                        -98.30611111133868,
                        34.28888888861138
                    ],
                    [
                        -98.11944444510505,
                        34.246666666433555
                    ],
                    [
                        -97.9058333331418,
                        34.35194444378112
                    ],
                    [
                        -97.79722222222773,
                        34.349722221703246
                    ],
                    [
                        -97.753055556069,
                        34.63611111053939
                    ],
                    [
                        -97.73472222235262,
                        34.746666666333624
                    ],
                    [
                        -97.70500000014982,
                        34.93861111101404
                    ],
                    [
                        -97.66833333361643,
                        35.17638888863638
                    ],
                    [
                        -97.40000000039964,
                        35.249999999800195
                    ],
                    [
                        -97.08333333316676,
                        35.458333333316716
                    ],
                    [
                        -97.18805555611897,
                        35.46638888823668
                    ],
                    [
                        -97.58333333396615,
                        35.49166666628366
                    ],
                    [
                        -97.88333333336658,
                        35.92499999935052
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 322,
            "artcc": "zfw",
            "sector": "35",
            "sectype": "low",
            "Shape_Length": 8.874058030267976,
            "Shape_Area": 3.1418652005900216
        }
    },
    {
        "type": "Feature",
        "id": 323,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -95.34166666698303,
                        33.46666666673332
                    ],
                    [
                        -95.08750000052459,
                        33.654166666358606
                    ],
                    [
                        -95.11611111123875,
                        33.74249999957539
                    ],
                    [
                        -95.14166666738276,
                        33.80611111093907
                    ],
                    [
                        -95.16472222245255,
                        33.85416666595893
                    ],
                    [
                        -95.21361111176338,
                        33.93972222180315
                    ],
                    [
                        -95.27194444468034,
                        34.012222221478396
                    ],
                    [
                        -95.33055555569433,
                        34.08222222187811
                    ],
                    [
                        -95.35833333391616,
                        34.10694444373115
                    ],
                    [
                        -95.39944444460542,
                        34.141111110989016
                    ],
                    [
                        -95.48277777837171,
                        34.209999999900106
                    ],
                    [
                        -95.56527777784714,
                        34.26027777789716
                    ],
                    [
                        -95.69750000002495,
                        34.328611110614304
                    ],
                    [
                        -95.79194444508005,
                        34.3649999999501
                    ],
                    [
                        -95.83416666725788,
                        34.38194444408089
                    ],
                    [
                        -96.09972222220273,
                        34.17527777734762
                    ],
                    [
                        -96.12500000024977,
                        34.15833333321683
                    ],
                    [
                        -96.71166666658337,
                        33.449999999800184
                    ],
                    [
                        -96.60055555549445,
                        33.22888888821166
                    ],
                    [
                        -96.33333333376629,
                        33.171944443980976
                    ],
                    [
                        -95.34166666698303,
                        33.46666666673332
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 323,
            "artcc": "zfw",
            "sector": "37",
            "sectype": "low",
            "Shape_Length": 4.246238315513628,
            "Shape_Area": 1.1443476848612417
        }
    },
    {
        "type": "Feature",
        "id": 324,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -94.78333333326668,
                        34.69166666608385
                    ],
                    [
                        -94.81666666713295,
                        34.755555555544504
                    ],
                    [
                        -94.92499999994999,
                        34.933333332567315
                    ],
                    [
                        -95.00000000069946,
                        35.06666666623369
                    ],
                    [
                        -95.00000000069946,
                        35.383333332567304
                    ],
                    [
                        -95.00000000069946,
                        35.65416666595894
                    ],
                    [
                        -95.3666666669331,
                        35.87083333339166
                    ],
                    [
                        -95.61250000037467,
                        36.0166666661338
                    ],
                    [
                        -95.84166666688316,
                        35.94166666628365
                    ],
                    [
                        -95.98333333356646,
                        35.88333333336669
                    ],
                    [
                        -96.05000000039968,
                        35.87083333339166
                    ],
                    [
                        -96.23333333396613,
                        35.749999999700265
                    ],
                    [
                        -96.40416666665828,
                        35.6499999999001
                    ],
                    [
                        -96.83916666740777,
                        35.52777777752249
                    ],
                    [
                        -97.08333333316676,
                        35.458333333316716
                    ],
                    [
                        -97.40000000039964,
                        35.249999999800195
                    ],
                    [
                        -97.04638888943566,
                        34.96333333286708
                    ],
                    [
                        -96.09972222220273,
                        34.17527777734762
                    ],
                    [
                        -95.83416666725788,
                        34.38194444408089
                    ],
                    [
                        -95.79194444508005,
                        34.3649999999501
                    ],
                    [
                        -95.69750000002495,
                        34.328611110614304
                    ],
                    [
                        -95.56527777784714,
                        34.26027777789716
                    ],
                    [
                        -95.48277777837171,
                        34.209999999900106
                    ],
                    [
                        -95.39944444460542,
                        34.141111110989016
                    ],
                    [
                        -95.35833333391616,
                        34.10694444373115
                    ],
                    [
                        -95.33055555569433,
                        34.08222222187811
                    ],
                    [
                        -94.78333333326668,
                        34.69166666608385
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 324,
            "artcc": "zfw",
            "sector": "38",
            "sectype": "low",
            "Shape_Length": 7.130338638145417,
            "Shape_Area": 2.9259795527867944
        }
    },
    {
        "type": "Feature",
        "id": 325,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -103.01249999997498,
                        32.93333333296698
                    ],
                    [
                        -103.14444444495513,
                        32.98333333286706
                    ],
                    [
                        -103.28750000032471,
                        33.00833333281713
                    ],
                    [
                        -103.44583333394115,
                        32.97499999985018
                    ],
                    [
                        -103.57083333369138,
                        32.891666666083836
                    ],
                    [
                        -103.80000000019982,
                        32.999999999800195
                    ],
                    [
                        -103.84027777839674,
                        32.845833333141854
                    ],
                    [
                        -103.93333333386624,
                        32.46666666603386
                    ],
                    [
                        -103.80000000019982,
                        32.033333332967004
                    ],
                    [
                        -103.52916666680818,
                        31.80833333341667
                    ],
                    [
                        -103.3333333332667,
                        31.64999999980023
                    ],
                    [
                        -103.11666666673324,
                        31.583333332967015
                    ],
                    [
                        -102.43333333326666,
                        31.37083333339166
                    ],
                    [
                        -102.14999999990005,
                        31.283333332667212
                    ],
                    [
                        -101.0833333332667,
                        31.087500000025045
                    ],
                    [
                        -100.55000000039968,
                        30.983333333266785
                    ],
                    [
                        -100.15416666725787,
                        31.12916666600887
                    ],
                    [
                        -100.10000000039969,
                        31.147222221628283
                    ],
                    [
                        -100.18307380511197,
                        31.163652374057847
                    ],
                    [
                        -100.39166666678324,
                        31.41249999937554
                    ],
                    [
                        -100.64583333324168,
                        31.91249999927561
                    ],
                    [
                        -100.67916666710795,
                        31.979166666108824
                    ],
                    [
                        -101.05000000029975,
                        32.687499999525414
                    ],
                    [
                        -101.55000000019982,
                        32.54999999980021
                    ],
                    [
                        -101.68888888951062,
                        32.450000000000045
                    ],
                    [
                        -102.77222222217779,
                        32.40944444370615
                    ],
                    [
                        -102.85833333331664,
                        32.52499999985014
                    ],
                    [
                        -102.83750000032472,
                        32.59166666668335
                    ],
                    [
                        -102.85000000029976,
                        32.71666666643358
                    ],
                    [
                        -102.90833333321672,
                        32.844444444455576
                    ],
                    [
                        -103.01249999997498,
                        32.93333333296698
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 325,
            "artcc": "zfw",
            "sector": "40",
            "sectype": "low",
            "Shape_Length": 10.039738211644702,
            "Shape_Area": 4.16996675233436
        }
    },
    {
        "type": "Feature",
        "id": 335,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -96.60055555549445,
                        33.22888888821166
                    ],
                    [
                        -96.71166666658337,
                        33.449999999800184
                    ],
                    [
                        -96.67025490174228,
                        33.499999999700265
                    ],
                    [
                        -96.66861111101394,
                        33.499999999700265
                    ],
                    [
                        -96.12500000024977,
                        34.15833333321683
                    ],
                    [
                        -96.09972222220273,
                        34.17527777734762
                    ],
                    [
                        -97.04638888943566,
                        34.96333333286708
                    ],
                    [
                        -97.40000000039964,
                        35.249999999800195
                    ],
                    [
                        -97.66833333361643,
                        35.17638888863638
                    ],
                    [
                        -97.70500000014982,
                        34.93861111101404
                    ],
                    [
                        -97.73472222235262,
                        34.746666666333624
                    ],
                    [
                        -97.753055556069,
                        34.63611111053939
                    ],
                    [
                        -97.79722222222773,
                        34.349722221703246
                    ],
                    [
                        -97.68138888888609,
                        34.34722222152834
                    ],
                    [
                        -97.6208333338912,
                        34.34583333284206
                    ],
                    [
                        -97.53861111161348,
                        34.34416666605887
                    ],
                    [
                        -97.43805555561937,
                        34.32694444383105
                    ],
                    [
                        -97.37500000044963,
                        34.28277777767238
                    ],
                    [
                        -97.34305555616896,
                        34.16972222170324
                    ],
                    [
                        -97.29972222250251,
                        33.994444443955956
                    ],
                    [
                        -97.35944444500512,
                        33.449999999800184
                    ],
                    [
                        -97.49750000002496,
                        33.25111111078917
                    ],
                    [
                        -97.12833333361641,
                        32.94805555501989
                    ],
                    [
                        -97.00000000029974,
                        32.94944444370617
                    ],
                    [
                        -96.91694444463036,
                        32.94749999972527
                    ],
                    [
                        -96.60055555549445,
                        33.22888888821166
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 335,
            "artcc": "zfw",
            "sector": "53",
            "sectype": "low",
            "Shape_Length": 6.638084937780874,
            "Shape_Area": 1.9753178176568875
        }
    },
    {
        "type": "Feature",
        "id": 338,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -97.52916666710797,
                        31.545833333041912
                    ],
                    [
                        -97.49166666718293,
                        31.641666666783294
                    ],
                    [
                        -97.33916666730784,
                        32.28333333336667
                    ],
                    [
                        -97.3947222228523,
                        32.38750000012493
                    ],
                    [
                        -97.7333333336664,
                        32.5899999999001
                    ],
                    [
                        -98.86666666713296,
                        32.46666666603386
                    ],
                    [
                        -99.03194444508006,
                        32.44972222190307
                    ],
                    [
                        -99.03333333376628,
                        32.38333333316683
                    ],
                    [
                        -99.03333333376628,
                        32.1083333328171
                    ],
                    [
                        -98.99166666688313,
                        32.037777777122756
                    ],
                    [
                        -98.55833333381628,
                        31.387499999425472
                    ],
                    [
                        -97.79444444485523,
                        31.39027777769735
                    ],
                    [
                        -97.71250000067448,
                        31.374999999450438
                    ],
                    [
                        -97.52916666710797,
                        31.545833333041912
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 338,
            "artcc": "zfw",
            "sector": "62",
            "sectype": "low",
            "Shape_Length": 4.883832569064478,
            "Shape_Area": 1.617982291611065
        }
    },
    {
        "type": "Feature",
        "id": 339,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -99.49805555581923,
                        32.86611111083914
                    ],
                    [
                        -99.51361111126374,
                        32.87611111063927
                    ],
                    [
                        -99.55888888891104,
                        32.904722222252815
                    ],
                    [
                        -99.6041666665584,
                        32.93305555487001
                    ],
                    [
                        -99.62416666705798,
                        32.947222221628294
                    ],
                    [
                        -99.66694444453043,
                        32.977777777222684
                    ],
                    [
                        -99.68722222222772,
                        32.994166666058845
                    ],
                    [
                        -99.76833333391619,
                        33.05944444420578
                    ],
                    [
                        -99.80638888913592,
                        33.093888888661354
                    ],
                    [
                        -99.81138888948561,
                        33.102499999775205
                    ],
                    [
                        -99.82472222285224,
                        33.11166666618374
                    ],
                    [
                        -99.84222222227771,
                        33.13111111048943
                    ],
                    [
                        -99.86000000069942,
                        33.149722222302785
                    ],
                    [
                        -99.89583333384127,
                        33.18638888883618
                    ],
                    [
                        -99.9111111111888,
                        33.204999999750214
                    ],
                    [
                        -99.92666666663331,
                        33.22555555554453
                    ],
                    [
                        -99.97500000064946,
                        33.27499999925061
                    ],
                    [
                        -100.03750000052457,
                        33.38333333296697
                    ],
                    [
                        -100.54583333344152,
                        33.14999999950044
                    ],
                    [
                        -100.62916666720787,
                        33.11249999957539
                    ],
                    [
                        -100.77499999995001,
                        32.949999999900115
                    ],
                    [
                        -100.98333333346653,
                        32.70555555514483
                    ],
                    [
                        -101.05000000029975,
                        32.687499999525414
                    ],
                    [
                        -100.67916666710795,
                        31.979166666108824
                    ],
                    [
                        -100.64583333324168,
                        31.91249999927561
                    ],
                    [
                        -100.39166666678324,
                        31.41249999937554
                    ],
                    [
                        -100.15416666725787,
                        31.12916666600887
                    ],
                    [
                        -100.10000000039969,
                        31.147222221628283
                    ],
                    [
                        -99.83333333396615,
                        31.249999999700265
                    ],
                    [
                        -99.46666666683319,
                        31.383333333366693
                    ],
                    [
                        -98.55833333381628,
                        31.387499999425472
                    ],
                    [
                        -98.40416666715794,
                        31.749999999600334
                    ],
                    [
                        -98.813888888961,
                        32.09999999980022
                    ],
                    [
                        -98.95416666695809,
                        32.105555555444596
                    ],
                    [
                        -98.99166666688313,
                        32.037777777122756
                    ],
                    [
                        -99.03333333376628,
                        32.1083333328171
                    ],
                    [
                        -99.03333333376628,
                        32.38333333316683
                    ],
                    [
                        -99.03194444508006,
                        32.44972222190307
                    ],
                    [
                        -99.1086111117134,
                        32.90944444450554
                    ],
                    [
                        -99.42499999994999,
                        32.922222221678226
                    ],
                    [
                        -99.48944444470533,
                        32.86250000007499
                    ],
                    [
                        -99.49805555581923,
                        32.86611111083914
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 339,
            "artcc": "zfw",
            "sector": "63",
            "sectype": "low",
            "Shape_Length": 7.916680634820899,
            "Shape_Area": 3.3582966046404357
        }
    },
    {
        "type": "Feature",
        "id": 340,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -101.68888888951062,
                        32.450000000000045
                    ],
                    [
                        -101.55000000019982,
                        32.54999999980021
                    ],
                    [
                        -101.05000000029975,
                        32.687499999525414
                    ],
                    [
                        -100.98333333346653,
                        32.70555555514483
                    ],
                    [
                        -100.77499999995001,
                        32.949999999900115
                    ],
                    [
                        -100.62916666720787,
                        33.11249999957539
                    ],
                    [
                        -100.54583333344152,
                        33.14999999950044
                    ],
                    [
                        -100.03750000052457,
                        33.38333333296697
                    ],
                    [
                        -100.1547222225525,
                        33.62722222152837
                    ],
                    [
                        -100.29444444435558,
                        33.833333332967015
                    ],
                    [
                        -100.40833333371631,
                        33.999999999600334
                    ],
                    [
                        -100.74999999999994,
                        34.46666666653351
                    ],
                    [
                        -101.00000000039967,
                        34.491666666483525
                    ],
                    [
                        -102.0000000001998,
                        34.599999999300564
                    ],
                    [
                        -102.32500000044962,
                        34.549999999400484
                    ],
                    [
                        -102.66250000067447,
                        34.38888888841154
                    ],
                    [
                        -102.80000000039968,
                        34.31666666683327
                    ],
                    [
                        -103.36666666713296,
                        33.77500000005
                    ],
                    [
                        -103.4083333340161,
                        33.71944444450554
                    ],
                    [
                        -103.48749999992503,
                        33.63749999942547
                    ],
                    [
                        -103.69166666738278,
                        33.40277777727266
                    ],
                    [
                        -103.80000000019982,
                        33.38333333296697
                    ],
                    [
                        -103.80000000019982,
                        33.049999999700276
                    ],
                    [
                        -103.80000000019982,
                        32.999999999800195
                    ],
                    [
                        -103.57083333369138,
                        32.891666666083836
                    ],
                    [
                        -102.85833333331664,
                        32.52499999985014
                    ],
                    [
                        -102.77222222217779,
                        32.40944444370615
                    ],
                    [
                        -101.68888888951062,
                        32.450000000000045
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 340,
            "artcc": "zfw",
            "sector": "64",
            "sectype": "low",
            "Shape_Length": 9.418842781156831,
            "Shape_Area": 5.671793788830566
        }
    },
    {
        "type": "Feature",
        "id": 342,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -97.37500000044963,
                        34.28277777767238
                    ],
                    [
                        -97.43805555561937,
                        34.32694444383105
                    ],
                    [
                        -97.53861111161348,
                        34.34416666605887
                    ],
                    [
                        -97.6208333338912,
                        34.34583333284206
                    ],
                    [
                        -97.68138888888609,
                        34.34722222152834
                    ],
                    [
                        -97.79722222222773,
                        34.349722221703246
                    ],
                    [
                        -97.9058333331418,
                        34.35194444378112
                    ],
                    [
                        -98.11944444510505,
                        34.246666666433555
                    ],
                    [
                        -98.30611111133868,
                        34.28888888861138
                    ],
                    [
                        -98.48333333396613,
                        34.32499999985015
                    ],
                    [
                        -98.71500000064947,
                        34.07249999927558
                    ],
                    [
                        -98.79583333334165,
                        33.96305555496991
                    ],
                    [
                        -98.81277777837175,
                        33.92944444390599
                    ],
                    [
                        -98.85666666733277,
                        33.84555555574434
                    ],
                    [
                        -98.91305555626889,
                        33.73527777714776
                    ],
                    [
                        -98.95638888903596,
                        33.58055555519479
                    ],
                    [
                        -98.75805555621889,
                        33.5158333332418
                    ],
                    [
                        -97.7333333336664,
                        33.17499999945045
                    ],
                    [
                        -97.49750000002496,
                        33.25111111078917
                    ],
                    [
                        -97.35944444500512,
                        33.449999999800184
                    ],
                    [
                        -97.35388888936075,
                        33.499999999700265
                    ],
                    [
                        -97.29972222250251,
                        33.994444443955956
                    ],
                    [
                        -97.34305555616896,
                        34.16972222170324
                    ],
                    [
                        -97.37500000044963,
                        34.28277777767238
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 342,
            "artcc": "zfw",
            "sector": "75",
            "sectype": "low",
            "Shape_Length": 4.67649589568511,
            "Shape_Area": 1.4271079089180756
        }
    },
    {
        "type": "Feature",
        "id": 344,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -94.87500000004991,
                        33.333333333066946
                    ],
                    [
                        -95.34166666698303,
                        33.46666666673332
                    ],
                    [
                        -96.33333333376629,
                        33.171944443980976
                    ],
                    [
                        -96.60055555549445,
                        33.22888888821166
                    ],
                    [
                        -96.91694444463036,
                        32.94749999972527
                    ],
                    [
                        -96.60083333359142,
                        32.55055555509483
                    ],
                    [
                        -96.33333333376629,
                        32.628611110414454
                    ],
                    [
                        -95.85722222232766,
                        32.58944444370616
                    ],
                    [
                        -95.24166666718293,
                        32.533611110964046
                    ],
                    [
                        -94.97805555621892,
                        32.66361111106397
                    ],
                    [
                        -94.88333333396616,
                        32.783333333266796
                    ],
                    [
                        -94.88750000002494,
                        32.96111111118887
                    ],
                    [
                        -94.87500000004991,
                        33.333333333066946
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 344,
            "artcc": "zfw",
            "sector": "83",
            "sectype": "low",
            "Shape_Length": 5.0952523203574085,
            "Shape_Area": 1.3595738814991496
        }
    },
    {
        "type": "Feature",
        "id": 350,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -97.12500000004991,
                        31.199999999800184
                    ],
                    [
                        -97.00000000029974,
                        31.20416666675834
                    ],
                    [
                        -96.68333333396617,
                        31.20416666675834
                    ],
                    [
                        -96.5125000003747,
                        31.266666666633398
                    ],
                    [
                        -96.26666666693308,
                        31.35833333341668
                    ],
                    [
                        -96.68972222210283,
                        32.28333333336667
                    ],
                    [
                        -96.60083333359142,
                        32.55055555509483
                    ],
                    [
                        -96.91694444463036,
                        32.94749999972527
                    ],
                    [
                        -97.00000000029974,
                        32.94944444370617
                    ],
                    [
                        -97.12833333361641,
                        32.94805555501989
                    ],
                    [
                        -97.3947222228523,
                        32.38750000012493
                    ],
                    [
                        -97.33916666730784,
                        32.28333333336667
                    ],
                    [
                        -97.49166666718293,
                        31.641666666783294
                    ],
                    [
                        -97.52916666710797,
                        31.545833333041912
                    ],
                    [
                        -97.71250000067448,
                        31.374999999450438
                    ],
                    [
                        -97.58333333396615,
                        31.266666666633398
                    ],
                    [
                        -97.51250000017484,
                        31.21249999977522
                    ],
                    [
                        -97.19999999990006,
                        31.199999999800184
                    ],
                    [
                        -97.12500000004991,
                        31.199999999800184
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 350,
            "artcc": "zfw",
            "sector": "96",
            "sectype": "low",
            "Shape_Length": 5.300868062743227,
            "Shape_Area": 1.4648421686716724
        }
    },
    {
        "type": "Feature",
        "id": 368,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -88.90000000029971,
                        31.54722222172819
                    ],
                    [
                        -89.00833333401613,
                        31.550000000000068
                    ],
                    [
                        -89.16666666673325,
                        31.57499999995008
                    ],
                    [
                        -88.97500000014986,
                        31.35833333341668
                    ],
                    [
                        -88.74166666668327,
                        31.07500000005001
                    ],
                    [
                        -88.68333333376631,
                        30.9999999993006
                    ],
                    [
                        -88.98333333316674,
                        30.650000000000034
                    ],
                    [
                        -89.61666666673324,
                        30.349999999700287
                    ],
                    [
                        -89.73333333346653,
                        30.099999999300564
                    ],
                    [
                        -90.22916666730782,
                        29.98333333256727
                    ],
                    [
                        -90.20416666735775,
                        29.941666666583444
                    ],
                    [
                        -90.0958333336414,
                        29.733333333066923
                    ],
                    [
                        -89.99999999990001,
                        29.758333333016935
                    ],
                    [
                        -89.49972222280229,
                        29.92222222227781
                    ],
                    [
                        -89.30499999985005,
                        29.999999999500403
                    ],
                    [
                        -89.03333333396614,
                        29.999999999500403
                    ],
                    [
                        -88.85000000039969,
                        30.100277777397537
                    ],
                    [
                        -88.69444444505507,
                        30.183611111163884
                    ],
                    [
                        -88.52500000014987,
                        30.169444444405656
                    ],
                    [
                        -88.5000000001998,
                        30.16555555554453
                    ],
                    [
                        -88.42500000034971,
                        30.148611110514366
                    ],
                    [
                        -88.16388888956055,
                        30.200000000000045
                    ],
                    [
                        -88.02500000024975,
                        30.154444444255773
                    ],
                    [
                        -88.02500000024975,
                        30.22083333299196
                    ],
                    [
                        -87.91666666653339,
                        30.516666666333663
                    ],
                    [
                        -87.91666666653339,
                        30.6333333330669
                    ],
                    [
                        -87.80833333371635,
                        30.68333333296698
                    ],
                    [
                        -87.71666666693312,
                        30.691666665983917
                    ],
                    [
                        -87.69166666698305,
                        30.791666666683398
                    ],
                    [
                        -87.68333333396617,
                        30.845833332642258
                    ],
                    [
                        -87.6583333340161,
                        30.958333333316716
                    ],
                    [
                        -87.58888888891107,
                        31.0500000001
                    ],
                    [
                        -87.4000000005995,
                        31.28055555529471
                    ],
                    [
                        -87.55000000029975,
                        31.35833333341668
                    ],
                    [
                        -87.81666666673323,
                        31.5208333330919
                    ],
                    [
                        -88.32500000054955,
                        31.5166666661338
                    ],
                    [
                        -88.90000000029971,
                        31.54722222172819
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 368,
            "artcc": "zhu",
            "sector": "25",
            "sectype": "low",
            "Shape_Length": 8.388258151655972,
            "Shape_Area": 2.1064729931242123
        }
    },
    {
        "type": "Feature",
        "id": 370,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -89.16666666673325,
                        31.950000000099976
                    ],
                    [
                        -89.41666666713297,
                        31.950000000099976
                    ],
                    [
                        -89.85000000019983,
                        31.63055555549454
                    ],
                    [
                        -89.93333333396617,
                        31.633333332867096
                    ],
                    [
                        -90.03333333376628,
                        31.636111111138916
                    ],
                    [
                        -90.3458333331418,
                        31.64999999980023
                    ],
                    [
                        -90.65833333341658,
                        31.72916666660842
                    ],
                    [
                        -90.80000000009989,
                        31.766666666533467
                    ],
                    [
                        -91.16666666723285,
                        31.383333333366693
                    ],
                    [
                        -91.16666666723285,
                        31.15833333291704
                    ],
                    [
                        -91.16666666723285,
                        30.868888888611366
                    ],
                    [
                        -90.61527777784715,
                        30.436388888936108
                    ],
                    [
                        -90.2333333333666,
                        30.13333333316683
                    ],
                    [
                        -90.26666666723287,
                        30.05833333331674
                    ],
                    [
                        -90.22916666730782,
                        29.98333333256727
                    ],
                    [
                        -89.73333333346653,
                        30.099999999300564
                    ],
                    [
                        -89.61666666673324,
                        30.349999999700287
                    ],
                    [
                        -88.98333333316674,
                        30.650000000000034
                    ],
                    [
                        -88.68333333376631,
                        30.9999999993006
                    ],
                    [
                        -88.74166666668327,
                        31.07500000005001
                    ],
                    [
                        -88.97500000014986,
                        31.35833333341668
                    ],
                    [
                        -88.90000000029971,
                        31.54722222172819
                    ],
                    [
                        -89.16666666673325,
                        31.950000000099976
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 370,
            "artcc": "zhu",
            "sector": "27",
            "sectype": "low",
            "Shape_Length": 7.2480473100555365,
            "Shape_Area": 3.053751351362109
        }
    },
    {
        "type": "Feature",
        "id": 371,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -93.46666666713293,
                        29.541666666483536
                    ],
                    [
                        -93.50000000009987,
                        29.541666666483536
                    ],
                    [
                        -93.76666666653341,
                        29.541666666483536
                    ],
                    [
                        -93.83333333336662,
                        29.52000000009997
                    ],
                    [
                        -94.1500000005995,
                        29.441666666683375
                    ],
                    [
                        -94.16666666663332,
                        29.426666666533492
                    ],
                    [
                        -94.45416666695809,
                        29.34694444443062
                    ],
                    [
                        -94.49972222270236,
                        29.26027777709777
                    ],
                    [
                        -94.83333333316676,
                        29.113055555669405
                    ],
                    [
                        -95.16583333394118,
                        28.964999999950066
                    ],
                    [
                        -95.28750000012485,
                        28.909722221603317
                    ],
                    [
                        -95.50000000059953,
                        28.814166665958908
                    ],
                    [
                        -95.49944444440553,
                        28.565833333241812
                    ],
                    [
                        -95.50000000059953,
                        28.499999999800195
                    ],
                    [
                        -95.50000000059953,
                        28.218611110414486
                    ],
                    [
                        -95.50000000059953,
                        28.1666666665335
                    ],
                    [
                        -95.50000000059953,
                        27.83333333326675
                    ],
                    [
                        -95.50000000059953,
                        27.633333332767165
                    ],
                    [
                        -95.58111111138862,
                        26.86361111096403
                    ],
                    [
                        -95.59722222212781,
                        26.833333332567292
                    ],
                    [
                        -95.94694444423067,
                        26.07916666620872
                    ],
                    [
                        -95.98277777827184,
                        25.999166666008875
                    ],
                    [
                        -95.91666666673325,
                        25.99999999940053
                    ],
                    [
                        -94.70000000039965,
                        25.383333332767165
                    ],
                    [
                        -93.00833333321674,
                        24.499999999700265
                    ],
                    [
                        -92.50000000029974,
                        24.499999999700265
                    ],
                    [
                        -92.50000000029974,
                        26.833333332567292
                    ],
                    [
                        -92.50000000029974,
                        27.83333333326675
                    ],
                    [
                        -92.50000000029974,
                        28.1666666665335
                    ],
                    [
                        -92.50000000029974,
                        28.499999999800195
                    ],
                    [
                        -92.50000000029974,
                        28.833333333066946
                    ],
                    [
                        -92.50000000029974,
                        29.16666666633364
                    ],
                    [
                        -92.50000000029974,
                        29.514722221653244
                    ],
                    [
                        -92.56666666713295,
                        29.516666666533467
                    ],
                    [
                        -92.83333333356643,
                        29.52333333276715
                    ],
                    [
                        -93.16666666683318,
                        29.53333333256728
                    ],
                    [
                        -93.31666666653342,
                        29.53333333256728
                    ],
                    [
                        -93.46666666713293,
                        29.541666666483536
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 371,
            "artcc": "zhu",
            "sector": "28",
            "sectype": "low",
            "Shape_Length": 14.947092281821181,
            "Shape_Area": 13.234327430837666
        }
    },
    {
        "type": "Feature",
        "id": 372,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -88.02500000024975,
                        30.154444444255773
                    ],
                    [
                        -88.16388888956055,
                        30.200000000000045
                    ],
                    [
                        -88.42500000034971,
                        30.148611110514366
                    ],
                    [
                        -88.5000000001998,
                        30.16555555554453
                    ],
                    [
                        -88.52500000014987,
                        30.169444444405656
                    ],
                    [
                        -88.69444444505507,
                        30.183611111163884
                    ],
                    [
                        -88.85000000039969,
                        30.100277777397537
                    ],
                    [
                        -89.03333333396614,
                        29.999999999500403
                    ],
                    [
                        -89.15000000069944,
                        29.937499999625345
                    ],
                    [
                        -89.20166666738277,
                        29.883611110864138
                    ],
                    [
                        -89.49972222280229,
                        29.92222222227781
                    ],
                    [
                        -89.49999999999994,
                        29.808333332917016
                    ],
                    [
                        -89.8333333332667,
                        29.491944443781108
                    ],
                    [
                        -90.01527777814692,
                        29.318333332817133
                    ],
                    [
                        -90.16722222272733,
                        29.317222222227826
                    ],
                    [
                        -90.50000000069946,
                        29.316666666033882
                    ],
                    [
                        -90.52194444428062,
                        29.316666666033882
                    ],
                    [
                        -90.54277777817191,
                        29.316666666033882
                    ],
                    [
                        -90.70833333331666,
                        29.316666666033882
                    ],
                    [
                        -90.83333333396615,
                        29.316666666033882
                    ],
                    [
                        -90.83333333396615,
                        29.16666666633364
                    ],
                    [
                        -90.83333333396615,
                        28.833333333066946
                    ],
                    [
                        -90.83333333396615,
                        28.499999999800195
                    ],
                    [
                        -90.83333333396615,
                        28.266666666333663
                    ],
                    [
                        -90.83333333396615,
                        28.1666666665335
                    ],
                    [
                        -90.83333333396615,
                        27.83333333326675
                    ],
                    [
                        -90.83333333396615,
                        26.833333332567292
                    ],
                    [
                        -90.83333333396615,
                        24.499999999700265
                    ],
                    [
                        -89.23333333356646,
                        24.499999999700265
                    ],
                    [
                        -88.00000000029974,
                        25.176111110739214
                    ],
                    [
                        -88.00000000029974,
                        26.862777777572433
                    ],
                    [
                        -88.00000000029974,
                        27.83333333326675
                    ],
                    [
                        -87.84783611124749,
                        27.83333333326675
                    ],
                    [
                        -88.00000000029974,
                        28.141666666583433
                    ],
                    [
                        -88.01111111158849,
                        28.999999999700265
                    ],
                    [
                        -88.01388888896099,
                        29.574999999450483
                    ],
                    [
                        -88.01666666723287,
                        29.599999999400495
                    ],
                    [
                        -88.02500000024975,
                        29.966666666533513
                    ],
                    [
                        -88.02500000024975,
                        30.08472222195303
                    ],
                    [
                        -88.02500000024975,
                        30.154444444255773
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 372,
            "artcc": "zhu",
            "sector": "30",
            "sectype": "low",
            "Shape_Length": 16.210012710653583,
            "Shape_Area": 14.495490468250205
        }
    },
    {
        "type": "Feature",
        "id": 373,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -92.15000000009991,
                        28.266666666333663
                    ],
                    [
                        -91.66666666713297,
                        28.266666666333663
                    ],
                    [
                        -91.40000000069944,
                        28.266666666333663
                    ],
                    [
                        -90.83333333396615,
                        28.266666666333663
                    ],
                    [
                        -90.73333333326667,
                        28.266666666333663
                    ],
                    [
                        -90.50000000069946,
                        28.266666666333663
                    ],
                    [
                        -90.50000000069946,
                        28.499999999800195
                    ],
                    [
                        -90.50000000069946,
                        28.833333333066946
                    ],
                    [
                        -90.50000000069946,
                        29.16666666633364
                    ],
                    [
                        -90.50000000069946,
                        29.316666666033882
                    ],
                    [
                        -90.48333333376632,
                        29.649999999300576
                    ],
                    [
                        -90.0958333336414,
                        29.733333333066923
                    ],
                    [
                        -90.20416666735775,
                        29.941666666583444
                    ],
                    [
                        -90.22916666730782,
                        29.98333333256727
                    ],
                    [
                        -90.26666666723287,
                        30.05833333331674
                    ],
                    [
                        -90.2333333333666,
                        30.13333333316683
                    ],
                    [
                        -90.61527777784715,
                        30.436388888936108
                    ],
                    [
                        -91.16666666723285,
                        30.868888888611366
                    ],
                    [
                        -91.80833333381628,
                        30.60833333311689
                    ],
                    [
                        -92.03333333336661,
                        30.516666666333663
                    ],
                    [
                        -92.51666666723287,
                        30.31666666673334
                    ],
                    [
                        -92.38333333356644,
                        30.183333333066912
                    ],
                    [
                        -92.38333333356644,
                        30.015555554944967
                    ],
                    [
                        -92.38333333356644,
                        29.599999999400495
                    ],
                    [
                        -92.38250000017484,
                        29.538611111014006
                    ],
                    [
                        -92.38222222207787,
                        29.513888888261647
                    ],
                    [
                        -92.37250000037466,
                        28.740833332892066
                    ],
                    [
                        -92.36666666663331,
                        28.266666666333663
                    ],
                    [
                        -92.15000000009991,
                        28.266666666333663
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 373,
            "artcc": "zhu",
            "sector": "34",
            "sectype": "low",
            "Shape_Length": 8.84837240800545,
            "Shape_Area": 4.597612731196128
        }
    },
    {
        "type": "Feature",
        "id": 374,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -92.38333333356644,
                        30.015555554944967
                    ],
                    [
                        -92.38333333356644,
                        30.183333333066912
                    ],
                    [
                        -92.51666666723287,
                        30.31666666673334
                    ],
                    [
                        -93.08888888961053,
                        30.31666666673334
                    ],
                    [
                        -93.30000000049961,
                        30.31666666673334
                    ],
                    [
                        -93.69166666668326,
                        30.183333333066912
                    ],
                    [
                        -93.94722222272736,
                        30.130833332991983
                    ],
                    [
                        -94.28055555599406,
                        30.10527777774729
                    ],
                    [
                        -94.79638888943566,
                        30.05249999957539
                    ],
                    [
                        -94.86944444440553,
                        30.04527777714776
                    ],
                    [
                        -94.96416666665829,
                        30.01027777739756
                    ],
                    [
                        -95.04805555571926,
                        29.987499999525426
                    ],
                    [
                        -95.07500000054955,
                        29.783333332967004
                    ],
                    [
                        -95.07999999999998,
                        29.60833333331675
                    ],
                    [
                        -95.08333333356643,
                        29.574999999450483
                    ],
                    [
                        -95.11194444428065,
                        29.48499999945045
                    ],
                    [
                        -94.57694444463039,
                        29.64944444400595
                    ],
                    [
                        -94.4663888888361,
                        29.664722222252806
                    ],
                    [
                        -94.33833333361639,
                        29.6477777772227
                    ],
                    [
                        -94.03805555611899,
                        29.6066666665335
                    ],
                    [
                        -93.50000000009987,
                        29.61666666633363
                    ],
                    [
                        -92.55999999999995,
                        29.603333332966997
                    ],
                    [
                        -92.38333333356644,
                        29.599999999400495
                    ],
                    [
                        -92.38333333356644,
                        30.015555554944967
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 374,
            "artcc": "zhu",
            "sector": "36",
            "sectype": "low",
            "Shape_Length": 6.611465025816871,
            "Shape_Area": 1.5575080252879991
        }
    },
    {
        "type": "Feature",
        "id": 376,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -94.86944444440553,
                        30.04527777714776
                    ],
                    [
                        -94.79638888943566,
                        30.05249999957539
                    ],
                    [
                        -94.28055555599406,
                        30.10527777774729
                    ],
                    [
                        -93.94722222272736,
                        30.130833332991983
                    ],
                    [
                        -93.69166666668326,
                        30.183333333066912
                    ],
                    [
                        -93.30000000049961,
                        30.31666666673334
                    ],
                    [
                        -93.36305555566935,
                        30.49999999940053
                    ],
                    [
                        -93.38333333336658,
                        30.558333333216808
                    ],
                    [
                        -93.48333333316674,
                        30.958333333316716
                    ],
                    [
                        -93.52500000004994,
                        31.116666666033836
                    ],
                    [
                        -93.52500000004994,
                        31.295833332642246
                    ],
                    [
                        -93.59402777845918,
                        31.500000000099988
                    ],
                    [
                        -93.61666666683317,
                        31.566666666033882
                    ],
                    [
                        -93.84333333316675,
                        31.569999999600327
                    ],
                    [
                        -94.04250000027474,
                        31.350277777597398
                    ],
                    [
                        -94.57166666708298,
                        30.757222222227824
                    ],
                    [
                        -94.75888888951062,
                        30.540555555694368
                    ],
                    [
                        -94.88333333396616,
                        30.39444444395599
                    ],
                    [
                        -94.96416666665829,
                        30.01027777739756
                    ],
                    [
                        -94.86944444440553,
                        30.04527777714776
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 376,
            "artcc": "zhu",
            "sector": "38",
            "sectype": "low",
            "Shape_Length": 5.1875375979559575,
            "Shape_Area": 1.4623712002103326
        }
    },
    {
        "type": "Feature",
        "id": 377,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -90.80000000009989,
                        31.766666666533467
                    ],
                    [
                        -90.90833333381624,
                        31.799999999500415
                    ],
                    [
                        -91.30833333391621,
                        31.91249999927561
                    ],
                    [
                        -91.55000000039968,
                        32.1499999997003
                    ],
                    [
                        -91.6888888888111,
                        32.28333333336667
                    ],
                    [
                        -92.03333333336661,
                        32.28333333336667
                    ],
                    [
                        -92.25833333381627,
                        32.28333333336667
                    ],
                    [
                        -92.52222222287725,
                        32.1083333328171
                    ],
                    [
                        -92.68333333386624,
                        31.995833333041958
                    ],
                    [
                        -93.32083333349152,
                        31.558333333016947
                    ],
                    [
                        -93.39166666728283,
                        31.566666666033882
                    ],
                    [
                        -93.56666666693309,
                        31.566666666033882
                    ],
                    [
                        -93.61666666683317,
                        31.566666666033882
                    ],
                    [
                        -93.59402777845918,
                        31.500000000099988
                    ],
                    [
                        -93.52500000004994,
                        31.295833332642246
                    ],
                    [
                        -93.52500000004994,
                        31.116666666033836
                    ],
                    [
                        -93.48333333316674,
                        30.958333333316716
                    ],
                    [
                        -93.38333333336658,
                        30.558333333216808
                    ],
                    [
                        -93.36305555566935,
                        30.49999999940053
                    ],
                    [
                        -93.30000000049961,
                        30.31666666673334
                    ],
                    [
                        -93.08888888961053,
                        30.31666666673334
                    ],
                    [
                        -92.51666666723287,
                        30.31666666673334
                    ],
                    [
                        -92.03333333336661,
                        30.516666666333663
                    ],
                    [
                        -91.80833333381628,
                        30.60833333311689
                    ],
                    [
                        -91.25000000009987,
                        30.58333333316682
                    ],
                    [
                        -91.16666666723285,
                        30.799999999700276
                    ],
                    [
                        -91.16666666723285,
                        30.868888888611366
                    ],
                    [
                        -91.16666666723285,
                        31.15833333291704
                    ],
                    [
                        -91.16666666723285,
                        31.383333333366693
                    ],
                    [
                        -90.80000000009989,
                        31.766666666533467
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 377,
            "artcc": "zhu",
            "sector": "40",
            "sectype": "low",
            "Shape_Length": 7.963277149937759,
            "Shape_Area": 3.710250867080612
        }
    },
    {
        "type": "Feature",
        "id": 380,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -94.33833333361639,
                        29.6477777772227
                    ],
                    [
                        -94.4663888888361,
                        29.664722222252806
                    ],
                    [
                        -94.57694444463039,
                        29.64944444400595
                    ],
                    [
                        -95.11194444428065,
                        29.48499999945045
                    ],
                    [
                        -95.27916666710797,
                        28.96111111108894
                    ],
                    [
                        -95.28750000012485,
                        28.909722221603317
                    ],
                    [
                        -95.35694444433062,
                        28.499999999800195
                    ],
                    [
                        -95.40555555554442,
                        28.205555555144826
                    ],
                    [
                        -94.0083333339162,
                        28.266666666333663
                    ],
                    [
                        -93.6333333337663,
                        28.266666666333663
                    ],
                    [
                        -93.46666666713293,
                        28.266666666333663
                    ],
                    [
                        -93.14055555629386,
                        28.266666666333663
                    ],
                    [
                        -92.68333333386624,
                        28.266666666333663
                    ],
                    [
                        -92.36666666663331,
                        28.266666666333663
                    ],
                    [
                        -92.37250000037466,
                        28.740833332892066
                    ],
                    [
                        -92.38222222207787,
                        29.513888888261647
                    ],
                    [
                        -92.38250000017484,
                        29.538611111014006
                    ],
                    [
                        -92.38333333356644,
                        29.599999999400495
                    ],
                    [
                        -92.55999999999995,
                        29.603333332966997
                    ],
                    [
                        -93.50000000009987,
                        29.61666666633363
                    ],
                    [
                        -94.03805555611899,
                        29.6066666665335
                    ],
                    [
                        -94.33833333361639,
                        29.6477777772227
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 380,
            "artcc": "zhu",
            "sector": "43",
            "sectype": "low",
            "Shape_Length": 8.448148752938009,
            "Shape_Area": 3.9122697143641925
        }
    },
    {
        "type": "Feature",
        "id": 382,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -94.60833333361643,
                        31.80833333341667
                    ],
                    [
                        -94.87500000004991,
                        31.80833333341667
                    ],
                    [
                        -94.87500000004991,
                        31.533333333066935
                    ],
                    [
                        -95.19722222292722,
                        30.639722222102932
                    ],
                    [
                        -95.43000000019981,
                        29.936111110939066
                    ],
                    [
                        -95.3708333338912,
                        29.796666666333635
                    ],
                    [
                        -95.30750000062449,
                        29.793611110864106
                    ],
                    [
                        -95.07500000054955,
                        29.783333332967004
                    ],
                    [
                        -95.04805555571926,
                        29.987499999525426
                    ],
                    [
                        -94.96416666665829,
                        30.01027777739756
                    ],
                    [
                        -94.88333333396616,
                        30.39444444395599
                    ],
                    [
                        -94.75888888951062,
                        30.540555555694368
                    ],
                    [
                        -94.57166666708298,
                        30.757222222227824
                    ],
                    [
                        -94.04250000027474,
                        31.350277777597398
                    ],
                    [
                        -93.84333333316675,
                        31.569999999600327
                    ],
                    [
                        -94.20000000049959,
                        31.57222222167826
                    ],
                    [
                        -94.54722222242759,
                        31.561111110389504
                    ],
                    [
                        -94.60833333361643,
                        31.545833333041912
                    ],
                    [
                        -94.60833333361643,
                        31.80833333341667
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 382,
            "artcc": "zhu",
            "sector": "49",
            "sectype": "low",
            "Shape_Length": 5.96497902138001,
            "Shape_Area": 1.1274236495452656
        }
    },
    {
        "type": "Feature",
        "id": 383,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -98.28888888911092,
                        29.549999999500415
                    ],
                    [
                        -98.16666666673325,
                        29.74722222172818
                    ],
                    [
                        -97.86666666733282,
                        29.799999999900137
                    ],
                    [
                        -97.9944444444555,
                        29.854166666758317
                    ],
                    [
                        -97.97388888956056,
                        29.923611110964032
                    ],
                    [
                        -97.9491666668082,
                        29.951944444480603
                    ],
                    [
                        -97.84638888873621,
                        30.049999999400484
                    ],
                    [
                        -97.76527777794706,
                        30.167499999525432
                    ],
                    [
                        -97.70083333319172,
                        30.336944444430628
                    ],
                    [
                        -97.66472222285228,
                        30.42333333276713
                    ],
                    [
                        -97.6166666669331,
                        30.713888888561428
                    ],
                    [
                        -97.67250000057453,
                        30.799999999700276
                    ],
                    [
                        -97.70833333371633,
                        30.86666666653349
                    ],
                    [
                        -97.58333333396615,
                        31.266666666633398
                    ],
                    [
                        -97.71250000067448,
                        31.374999999450438
                    ],
                    [
                        -97.79444444485523,
                        31.39027777769735
                    ],
                    [
                        -98.55833333381628,
                        31.387499999425472
                    ],
                    [
                        -99.46666666683319,
                        31.383333333366693
                    ],
                    [
                        -99.83333333396615,
                        31.249999999700265
                    ],
                    [
                        -100.10000000039969,
                        31.147222221628283
                    ],
                    [
                        -99.91666666683318,
                        31.033333333166865
                    ],
                    [
                        -99.38333333396616,
                        30.625000000050022
                    ],
                    [
                        -99.31666666713295,
                        30.349999999700287
                    ],
                    [
                        -98.77777777772224,
                        30.041666666383605
                    ],
                    [
                        -98.7166666665334,
                        29.999999999500403
                    ],
                    [
                        -98.54027777819687,
                        29.79583333294204
                    ],
                    [
                        -98.56555555624391,
                        29.64027777759742
                    ],
                    [
                        -98.633888888961,
                        29.449166666308656
                    ],
                    [
                        -98.57499999985009,
                        29.427777777122742
                    ],
                    [
                        -98.41666666713297,
                        29.386111111138916
                    ],
                    [
                        -98.27361111176339,
                        29.34999999990015
                    ],
                    [
                        -98.28888888911092,
                        29.549999999500415
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 383,
            "artcc": "zhu",
            "sector": "50",
            "sectype": "low",
            "Shape_Length": 7.907387912783732,
            "Shape_Area": 2.7781716820865054
        }
    },
    {
        "type": "Feature",
        "id": 384,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -91.62222222287727,
                        29.499999999600334
                    ],
                    [
                        -91.86888888881111,
                        29.504444443756142
                    ],
                    [
                        -92.16666666703304,
                        29.50833333261727
                    ],
                    [
                        -92.50000000029974,
                        29.514722221653244
                    ],
                    [
                        -92.50000000029974,
                        29.16666666633364
                    ],
                    [
                        -92.50000000029974,
                        28.833333333066946
                    ],
                    [
                        -92.50000000029974,
                        28.499999999800195
                    ],
                    [
                        -92.50000000029974,
                        28.1666666665335
                    ],
                    [
                        -92.50000000029974,
                        27.83333333326675
                    ],
                    [
                        -92.50000000029974,
                        26.833333332567292
                    ],
                    [
                        -92.50000000029974,
                        24.499999999700265
                    ],
                    [
                        -92.00833333341654,
                        24.499999999700265
                    ],
                    [
                        -90.83333333396615,
                        24.499999999700265
                    ],
                    [
                        -90.83333333396615,
                        26.833333332567292
                    ],
                    [
                        -90.83333333396615,
                        27.83333333326675
                    ],
                    [
                        -90.83333333396615,
                        28.1666666665335
                    ],
                    [
                        -90.83333333396615,
                        28.266666666333663
                    ],
                    [
                        -90.83333333396615,
                        28.499999999800195
                    ],
                    [
                        -90.83333333396615,
                        28.833333333066946
                    ],
                    [
                        -90.83333333396615,
                        29.16666666633364
                    ],
                    [
                        -90.83333333396615,
                        29.316666666033882
                    ],
                    [
                        -91.16666666723285,
                        29.316666666033882
                    ],
                    [
                        -91.48333333356646,
                        29.316666666033882
                    ],
                    [
                        -91.62222222287727,
                        29.499999999600334
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 384,
            "artcc": "zhu",
            "sector": "53",
            "sectype": "low",
            "Shape_Length": 13.255962666710465,
            "Shape_Area": 8.207728392890452
        }
    },
    {
        "type": "Feature",
        "id": 385,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -97.08333333316676,
                        29.366666666833282
                    ],
                    [
                        -97.06861111111385,
                        29.515277777847245
                    ],
                    [
                        -97.06666666713295,
                        29.53333333256728
                    ],
                    [
                        -97.04388888926081,
                        29.738055555319647
                    ],
                    [
                        -97.039444445105,
                        29.757222221528366
                    ],
                    [
                        -97.1477777779221,
                        29.764166666758342
                    ],
                    [
                        -97.80416666655839,
                        29.802777777272638
                    ],
                    [
                        -97.86666666733282,
                        29.799999999900137
                    ],
                    [
                        -98.16666666673325,
                        29.74722222172818
                    ],
                    [
                        -98.28888888911092,
                        29.549999999500415
                    ],
                    [
                        -98.27361111176339,
                        29.34999999990015
                    ],
                    [
                        -98.41666666713297,
                        29.386111111138916
                    ],
                    [
                        -98.57499999985009,
                        29.427777777122742
                    ],
                    [
                        -98.633888888961,
                        29.449166666308656
                    ],
                    [
                        -98.82916666720791,
                        29.259999999900117
                    ],
                    [
                        -98.93333333396617,
                        29.149999999400507
                    ],
                    [
                        -99.20000000039965,
                        28.88333333296697
                    ],
                    [
                        -99.05000000069941,
                        28.649999999500437
                    ],
                    [
                        -98.8333333332667,
                        28.3833333330669
                    ],
                    [
                        -98.55138888948562,
                        28.034722221553352
                    ],
                    [
                        -98.43333333316673,
                        27.866666666233698
                    ],
                    [
                        -98.43333333316673,
                        27.608333332817097
                    ],
                    [
                        -97.7333333336664,
                        27.758333333416658
                    ],
                    [
                        -97.63333333386623,
                        27.841666666283686
                    ],
                    [
                        -97.54472222255248,
                        27.93055555569441
                    ],
                    [
                        -97.54416666725785,
                        28.17388888896113
                    ],
                    [
                        -97.59972222280231,
                        28.266666666333663
                    ],
                    [
                        -97.91222222217777,
                        28.771388888486456
                    ],
                    [
                        -97.66666666683318,
                        28.90833333291704
                    ],
                    [
                        -97.49833333341655,
                        29.07861111121389
                    ],
                    [
                        -97.30000000059954,
                        28.95833333281712
                    ],
                    [
                        -97.16611111163843,
                        28.878611110714246
                    ],
                    [
                        -97.12805555551944,
                        28.929722222102896
                    ],
                    [
                        -97.08250000067443,
                        29.00083333309192
                    ],
                    [
                        -97.05166666698307,
                        29.055833333341695
                    ],
                    [
                        -97.02444444495512,
                        29.11194444418078
                    ],
                    [
                        -96.99055555579423,
                        29.19611111043946
                    ],
                    [
                        -97.12249999987506,
                        29.229444444305727
                    ],
                    [
                        -97.08333333316676,
                        29.366666666833282
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 385,
            "artcc": "zhu",
            "sector": "56",
            "sectype": "low",
            "Shape_Length": 8.134654399079498,
            "Shape_Area": 2.658683757863198
        }
    },
    {
        "type": "Feature",
        "id": 386,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -95.98277777827184,
                        25.999166666008875
                    ],
                    [
                        -95.94694444423067,
                        26.07916666620872
                    ],
                    [
                        -95.59722222212781,
                        26.833333332567292
                    ],
                    [
                        -95.58111111138862,
                        26.86361111096403
                    ],
                    [
                        -95.50000000059953,
                        27.633333332767165
                    ],
                    [
                        -95.45000000069945,
                        27.999999999900126
                    ],
                    [
                        -95.40555555554442,
                        28.205555555144826
                    ],
                    [
                        -95.50000000059953,
                        28.218611110414486
                    ],
                    [
                        -95.86166666738274,
                        28.25694444373113
                    ],
                    [
                        -95.9141666665584,
                        28.37472222195305
                    ],
                    [
                        -96.06444444435556,
                        28.710000000099967
                    ],
                    [
                        -96.36083333389121,
                        28.974999999750253
                    ],
                    [
                        -96.49194444458044,
                        29.09138888838652
                    ],
                    [
                        -96.99055555579423,
                        29.19611111043946
                    ],
                    [
                        -97.12249999987506,
                        29.229444444305727
                    ],
                    [
                        -97.35472222275234,
                        29.29611111113894
                    ],
                    [
                        -97.49833333341655,
                        29.07861111121389
                    ],
                    [
                        -97.66666666683318,
                        28.90833333291704
                    ],
                    [
                        -97.91222222217777,
                        28.771388888486456
                    ],
                    [
                        -97.59972222280231,
                        28.266666666333663
                    ],
                    [
                        -97.54416666725785,
                        28.17388888896113
                    ],
                    [
                        -97.54472222255248,
                        27.93055555569441
                    ],
                    [
                        -97.63333333386623,
                        27.841666666283686
                    ],
                    [
                        -97.7333333336664,
                        27.758333333416658
                    ],
                    [
                        -98.43333333316673,
                        27.608333332817097
                    ],
                    [
                        -98.4138888888611,
                        27.272222222177845
                    ],
                    [
                        -98.43333333316673,
                        27.12499999985016
                    ],
                    [
                        -98.40000000019984,
                        27.041666666083813
                    ],
                    [
                        -99.13333333356644,
                        26.46666666633365
                    ],
                    [
                        -99.06666666673323,
                        26.399999999500437
                    ],
                    [
                        -98.69166666658339,
                        26.249999999800195
                    ],
                    [
                        -98.58750000072439,
                        26.23888888851144
                    ],
                    [
                        -98.42500000014985,
                        26.18333333296698
                    ],
                    [
                        -98.21666666663333,
                        26.066666666233687
                    ],
                    [
                        -97.95833333321673,
                        26.058333333216808
                    ],
                    [
                        -97.75000000059953,
                        25.99999999940053
                    ],
                    [
                        -97.3583333335165,
                        25.81666666673334
                    ],
                    [
                        -96.11388888896101,
                        25.749999999900126
                    ],
                    [
                        -95.98277777827184,
                        25.999166666008875
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 386,
            "artcc": "zhu",
            "sector": "58",
            "sectype": "low",
            "Shape_Length": 12.376303543220448,
            "Shape_Area": 7.901447569043413
        }
    },
    {
        "type": "Feature",
        "id": 393,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -84.99972222280229,
                        25.033611110664253
                    ],
                    [
                        -85.09166666678323,
                        26.199999999900115
                    ],
                    [
                        -85.41388888876116,
                        26.60277777737258
                    ],
                    [
                        -86.00000000069946,
                        27.000000000099988
                    ],
                    [
                        -86.81722222222771,
                        27.241388888486483
                    ],
                    [
                        -87.68333333396617,
                        27.500000000000057
                    ],
                    [
                        -87.84783611124749,
                        27.83333333326675
                    ],
                    [
                        -88.00000000029974,
                        28.141666666583433
                    ],
                    [
                        -88.5000000001998,
                        28.198333332717198
                    ],
                    [
                        -88.58333333396615,
                        28.208333333416647
                    ],
                    [
                        -88.83333333346656,
                        28.24833333261728
                    ],
                    [
                        -89.00000000009987,
                        28.266666666333663
                    ],
                    [
                        -89.16666666673325,
                        28.266666666333663
                    ],
                    [
                        -89.49999999999994,
                        28.266666666333663
                    ],
                    [
                        -89.8333333332667,
                        28.266666666333663
                    ],
                    [
                        -89.88333333316677,
                        28.266666666333663
                    ],
                    [
                        -89.58333333376629,
                        26.91666666633364
                    ],
                    [
                        -89.57083333379126,
                        26.833333332567292
                    ],
                    [
                        -89.49999999999994,
                        26.349999999600357
                    ],
                    [
                        -89.29166666738274,
                        24.966666666633387
                    ],
                    [
                        -89.23333333356646,
                        24.499999999700265
                    ],
                    [
                        -88.00000000029974,
                        24.499999999700265
                    ],
                    [
                        -86.00000000069946,
                        23.999999999800195
                    ],
                    [
                        -84.99972222280229,
                        23.999999999800195
                    ],
                    [
                        -84.99972222280229,
                        25.033611110664253
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 393,
            "artcc": "zhu",
            "sector": "72",
            "sectype": "low",
            "Shape_Length": 15.910439961556534,
            "Shape_Area": 14.41287831392495
        }
    },
    {
        "type": "Feature",
        "id": 398,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -95.35666666713297,
                        29.648611110614297
                    ],
                    [
                        -95.3708333338912,
                        29.796666666333635
                    ],
                    [
                        -95.43000000019981,
                        29.936111110939066
                    ],
                    [
                        -95.81444444485521,
                        30.10944444380607
                    ],
                    [
                        -95.8749999998501,
                        30.116666666233698
                    ],
                    [
                        -96.24611111113882,
                        30.164722222152875
                    ],
                    [
                        -96.39500000024975,
                        30.185555555144788
                    ],
                    [
                        -96.71666666693312,
                        30.233333332966993
                    ],
                    [
                        -96.81499999994998,
                        30.248611111213847
                    ],
                    [
                        -96.82166666708298,
                        30.33722222162828
                    ],
                    [
                        -96.90805555631886,
                        30.43666666613376
                    ],
                    [
                        -97.12527777814688,
                        30.491666666383594
                    ],
                    [
                        -97.28250000027475,
                        30.42805555501991
                    ],
                    [
                        -97.38833333381626,
                        30.385277777547458
                    ],
                    [
                        -97.4802777777972,
                        30.12472222205298
                    ],
                    [
                        -97.23333333376632,
                        30.049999999400484
                    ],
                    [
                        -97.04166666718288,
                        29.993055555169803
                    ],
                    [
                        -97.039444445105,
                        29.757222221528366
                    ],
                    [
                        -96.82083333369138,
                        29.733611111163896
                    ],
                    [
                        -96.1794444443056,
                        29.67249999997506
                    ],
                    [
                        -96.04666666683318,
                        29.65944444380608
                    ],
                    [
                        -95.54861111181333,
                        29.500833332991988
                    ],
                    [
                        -95.35666666713297,
                        29.648611110614297
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 398,
            "artcc": "zhu",
            "sector": "80",
            "sectype": "low",
            "Shape_Length": 5.193218167272451,
            "Shape_Area": 1.003902546162114
        }
    },
    {
        "type": "Feature",
        "id": 401,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -95.66166666688315,
                        30.3255555550449
                    ],
                    [
                        -95.68333333326666,
                        30.433333332567315
                    ],
                    [
                        -95.7341666665584,
                        30.508333333316727
                    ],
                    [
                        -95.75222222217775,
                        30.53361111046445
                    ],
                    [
                        -95.81416666675824,
                        30.621111111188895
                    ],
                    [
                        -95.85833333381629,
                        30.68333333296698
                    ],
                    [
                        -96.03333333346654,
                        30.96666666633365
                    ],
                    [
                        -96.16972222260245,
                        31.196111110939057
                    ],
                    [
                        -96.26666666693308,
                        31.35833333341668
                    ],
                    [
                        -96.5125000003747,
                        31.266666666633398
                    ],
                    [
                        -96.68333333396617,
                        31.20416666675834
                    ],
                    [
                        -97.00000000029974,
                        31.20416666675834
                    ],
                    [
                        -97.04166666718288,
                        30.87499999955037
                    ],
                    [
                        -97.1000000000999,
                        30.799999999700276
                    ],
                    [
                        -97.11194444478025,
                        30.65361111076419
                    ],
                    [
                        -97.12527777814688,
                        30.491666666383594
                    ],
                    [
                        -96.90805555631886,
                        30.43666666613376
                    ],
                    [
                        -96.82166666708298,
                        30.33722222162828
                    ],
                    [
                        -96.81499999994998,
                        30.248611111213847
                    ],
                    [
                        -96.71666666693312,
                        30.233333332966993
                    ],
                    [
                        -96.39500000024975,
                        30.185555555144788
                    ],
                    [
                        -96.26500000014983,
                        30.04694444393101
                    ],
                    [
                        -96.24611111113882,
                        30.164722222152875
                    ],
                    [
                        -95.8749999998501,
                        30.116666666233698
                    ],
                    [
                        -95.81444444485521,
                        30.10944444380607
                    ],
                    [
                        -95.66166666688315,
                        30.3255555550449
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 401,
            "artcc": "zhu",
            "sector": "83",
            "sectype": "low",
            "Shape_Length": 4.579700604408373,
            "Shape_Area": 1.2086543986997338
        }
    },
    {
        "type": "Feature",
        "id": 403,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -98.93333333396617,
                        29.149999999400507
                    ],
                    [
                        -98.82916666720791,
                        29.259999999900117
                    ],
                    [
                        -98.633888888961,
                        29.449166666308656
                    ],
                    [
                        -98.56555555624391,
                        29.64027777759742
                    ],
                    [
                        -98.54027777819687,
                        29.79583333294204
                    ],
                    [
                        -98.7166666665334,
                        29.999999999500403
                    ],
                    [
                        -98.77777777772224,
                        30.041666666383605
                    ],
                    [
                        -99.31666666713295,
                        30.349999999700287
                    ],
                    [
                        -99.60000000049956,
                        29.87499999975023
                    ],
                    [
                        -99.6083333335165,
                        29.81666666683327
                    ],
                    [
                        -99.74333333396618,
                        29.761666666583437
                    ],
                    [
                        -99.67861111111387,
                        29.596666666733313
                    ],
                    [
                        -99.64916666700805,
                        29.426666666533492
                    ],
                    [
                        -99.83499999985008,
                        29.411944444480582
                    ],
                    [
                        -99.84000000019984,
                        29.269999999700303
                    ],
                    [
                        -99.87472222275233,
                        29.122222222077937
                    ],
                    [
                        -99.93833333321675,
                        28.977777777122753
                    ],
                    [
                        -99.7500000001998,
                        28.899999999900103
                    ],
                    [
                        -99.20000000039965,
                        28.88333333296697
                    ],
                    [
                        -98.93333333396617,
                        29.149999999400507
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 403,
            "artcc": "zhu",
            "sector": "85",
            "sectype": "low",
            "Shape_Length": 4.625338973843619,
            "Shape_Area": 1.2452535879672362
        }
    },
    {
        "type": "Feature",
        "id": 404,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -95.19722222292722,
                        30.639722222102932
                    ],
                    [
                        -94.87500000004991,
                        31.533333333066935
                    ],
                    [
                        -95.291666667083,
                        31.500000000099988
                    ],
                    [
                        -95.6291666673078,
                        31.487500000124953
                    ],
                    [
                        -95.98333333356646,
                        31.46666666623372
                    ],
                    [
                        -96.26666666693308,
                        31.35833333341668
                    ],
                    [
                        -96.16972222260245,
                        31.196111110939057
                    ],
                    [
                        -96.03333333346654,
                        30.96666666633365
                    ],
                    [
                        -95.85833333381629,
                        30.68333333296698
                    ],
                    [
                        -95.81416666675824,
                        30.621111111188895
                    ],
                    [
                        -95.75222222217775,
                        30.53361111046445
                    ],
                    [
                        -95.7341666665584,
                        30.508333333316727
                    ],
                    [
                        -95.68333333326666,
                        30.433333332567315
                    ],
                    [
                        -95.66166666688315,
                        30.3255555550449
                    ],
                    [
                        -95.81444444485521,
                        30.10944444380607
                    ],
                    [
                        -95.43000000019981,
                        29.936111110939066
                    ],
                    [
                        -95.19722222292722,
                        30.639722222102932
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 404,
            "artcc": "zhu",
            "sector": "86",
            "sectype": "low",
            "Shape_Length": 4.995303206405838,
            "Shape_Area": 1.14495887341749
        }
    },
    {
        "type": "Feature",
        "id": 405,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -95.08333333356643,
                        29.574999999450483
                    ],
                    [
                        -95.07999999999998,
                        29.60833333331675
                    ],
                    [
                        -95.07500000054955,
                        29.783333332967004
                    ],
                    [
                        -95.30750000062449,
                        29.793611110864106
                    ],
                    [
                        -95.3708333338912,
                        29.796666666333635
                    ],
                    [
                        -95.35666666713297,
                        29.648611110614297
                    ],
                    [
                        -95.54861111181333,
                        29.500833332991988
                    ],
                    [
                        -96.04666666683318,
                        29.65944444380608
                    ],
                    [
                        -96.1794444443056,
                        29.67249999997506
                    ],
                    [
                        -96.82083333369138,
                        29.733611111163896
                    ],
                    [
                        -97.039444445105,
                        29.757222221528366
                    ],
                    [
                        -97.04388888926081,
                        29.738055555319647
                    ],
                    [
                        -97.06666666713295,
                        29.53333333256728
                    ],
                    [
                        -97.06861111111385,
                        29.515277777847245
                    ],
                    [
                        -97.08333333316676,
                        29.366666666833282
                    ],
                    [
                        -97.12249999987506,
                        29.229444444305727
                    ],
                    [
                        -96.99055555579423,
                        29.19611111043946
                    ],
                    [
                        -97.02444444495512,
                        29.11194444418078
                    ],
                    [
                        -97.05166666698307,
                        29.055833333341695
                    ],
                    [
                        -97.08250000067443,
                        29.00083333309192
                    ],
                    [
                        -97.12805555551944,
                        28.929722222102896
                    ],
                    [
                        -97.16611111163843,
                        28.878611110714246
                    ],
                    [
                        -96.85361111136365,
                        28.688888889011082
                    ],
                    [
                        -96.65111111158848,
                        28.583333332667223
                    ],
                    [
                        -96.38138888878615,
                        28.431944444280703
                    ],
                    [
                        -96.11666666723289,
                        28.283333333266796
                    ],
                    [
                        -95.50000000059953,
                        28.218611110414486
                    ],
                    [
                        -95.40555555554442,
                        28.205555555144826
                    ],
                    [
                        -95.35694444433062,
                        28.499999999800195
                    ],
                    [
                        -95.28750000012485,
                        28.909722221603317
                    ],
                    [
                        -95.27916666710797,
                        28.96111111108894
                    ],
                    [
                        -95.11194444428065,
                        29.48499999945045
                    ],
                    [
                        -95.08333333356643,
                        29.574999999450483
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 405,
            "artcc": "zhu",
            "sector": "87",
            "sectype": "low",
            "Shape_Length": 6.784902349457526,
            "Shape_Area": 2.3120829094212936
        }
    },
    {
        "type": "Feature",
        "id": 407,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -98.40000000019984,
                        27.041666666083813
                    ],
                    [
                        -98.43333333316673,
                        27.12499999985016
                    ],
                    [
                        -98.4138888888611,
                        27.272222222177845
                    ],
                    [
                        -98.43333333316673,
                        27.608333332817097
                    ],
                    [
                        -98.43333333316673,
                        27.866666666233698
                    ],
                    [
                        -98.55138888948562,
                        28.034722221553352
                    ],
                    [
                        -98.8333333332667,
                        28.3833333330669
                    ],
                    [
                        -99.05000000069941,
                        28.649999999500437
                    ],
                    [
                        -99.20000000039965,
                        28.88333333296697
                    ],
                    [
                        -99.7500000001998,
                        28.899999999900103
                    ],
                    [
                        -99.81666666703302,
                        28.8000000001
                    ],
                    [
                        -99.91666666683318,
                        28.683333333366704
                    ],
                    [
                        -100.06666666653342,
                        28.56666666663341
                    ],
                    [
                        -100.33333333386622,
                        28.43333333296698
                    ],
                    [
                        -100.19166666718291,
                        28.183333332567315
                    ],
                    [
                        -99.95000000069945,
                        27.983333332966993
                    ],
                    [
                        -99.83333333396615,
                        27.774999999450472
                    ],
                    [
                        -99.76750000052459,
                        27.713888888261636
                    ],
                    [
                        -99.62583333384123,
                        27.583333332867085
                    ],
                    [
                        -99.61111111178832,
                        27.57083333289205
                    ],
                    [
                        -99.52500000064947,
                        27.491666666083802
                    ],
                    [
                        -99.47777777812195,
                        27.227777777922142
                    ],
                    [
                        -99.44999999990006,
                        27.066666666033882
                    ],
                    [
                        -99.19166666738278,
                        26.649999999900103
                    ],
                    [
                        -99.18333333346652,
                        26.533333333166865
                    ],
                    [
                        -99.13333333356644,
                        26.46666666633365
                    ],
                    [
                        -98.40000000019984,
                        27.041666666083813
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 407,
            "artcc": "zhu",
            "sector": "93",
            "sectype": "low",
            "Shape_Length": 6.732818938680453,
            "Shape_Area": 2.5152089130013233
        }
    },
    {
        "type": "Feature",
        "id": 409,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -97.51250000017484,
                        31.21249999977522
                    ],
                    [
                        -97.58333333396615,
                        31.266666666633398
                    ],
                    [
                        -97.70833333371633,
                        30.86666666653349
                    ],
                    [
                        -97.67250000057453,
                        30.799999999700276
                    ],
                    [
                        -97.6166666669331,
                        30.713888888561428
                    ],
                    [
                        -97.66472222285228,
                        30.42333333276713
                    ],
                    [
                        -97.70083333319172,
                        30.336944444430628
                    ],
                    [
                        -97.76527777794706,
                        30.167499999525432
                    ],
                    [
                        -97.84638888873621,
                        30.049999999400484
                    ],
                    [
                        -97.9491666668082,
                        29.951944444480603
                    ],
                    [
                        -97.97388888956056,
                        29.923611110964032
                    ],
                    [
                        -97.9944444444555,
                        29.854166666758317
                    ],
                    [
                        -97.86666666733282,
                        29.799999999900137
                    ],
                    [
                        -97.80416666655839,
                        29.802777777272638
                    ],
                    [
                        -97.1477777779221,
                        29.764166666758342
                    ],
                    [
                        -97.039444445105,
                        29.757222221528366
                    ],
                    [
                        -97.04166666718288,
                        29.993055555169803
                    ],
                    [
                        -97.23333333376632,
                        30.049999999400484
                    ],
                    [
                        -97.30833333361642,
                        30.173333333266783
                    ],
                    [
                        -97.28250000027475,
                        30.42805555501991
                    ],
                    [
                        -97.12527777814688,
                        30.491666666383594
                    ],
                    [
                        -97.11194444478025,
                        30.65361111076419
                    ],
                    [
                        -97.1000000000999,
                        30.799999999700276
                    ],
                    [
                        -97.04166666718288,
                        30.87499999955037
                    ],
                    [
                        -97.00000000029974,
                        31.20416666675834
                    ],
                    [
                        -97.12500000004991,
                        31.199999999800184
                    ],
                    [
                        -97.19999999990006,
                        31.199999999800184
                    ],
                    [
                        -97.51250000017484,
                        31.21249999977522
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 409,
            "artcc": "zhu",
            "sector": "96",
            "sectype": "low",
            "Shape_Length": 4.873025338134964,
            "Shape_Area": 0.854859915007853
        }
    },
    {
        "type": "Feature",
        "id": 412,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -98.7166666665334,
                        29.999999999500403
                    ],
                    [
                        -98.77777777772224,
                        30.041666666383605
                    ],
                    [
                        -99.31666666713295,
                        30.349999999700287
                    ],
                    [
                        -99.38333333396616,
                        30.625000000050022
                    ],
                    [
                        -99.91666666683318,
                        31.033333333166865
                    ],
                    [
                        -100.10000000039969,
                        31.147222221628283
                    ],
                    [
                        -100.34999999990004,
                        31.19666666623374
                    ],
                    [
                        -101.16666666703304,
                        31.299999999600345
                    ],
                    [
                        -101.40277777787213,
                        31.32888888841154
                    ],
                    [
                        -102.21666666673326,
                        31.42499999935052
                    ],
                    [
                        -102.14999999990005,
                        31.283333332667212
                    ],
                    [
                        -102.55833333391621,
                        29.76666666603387
                    ],
                    [
                        -102.4525000003747,
                        29.773611110364527
                    ],
                    [
                        -102.43333333326666,
                        29.77499999995007
                    ],
                    [
                        -102.40000000029971,
                        29.76666666603387
                    ],
                    [
                        -102.31666666653342,
                        29.87499999975023
                    ],
                    [
                        -102.14999999990005,
                        29.808333332917016
                    ],
                    [
                        -102.01111111148856,
                        29.790555555394633
                    ],
                    [
                        -101.790277777997,
                        29.778888888811196
                    ],
                    [
                        -101.61666666703303,
                        29.77083333299197
                    ],
                    [
                        -101.51666666723287,
                        29.758333333016935
                    ],
                    [
                        -100.79999999990002,
                        29.16666666633364
                    ],
                    [
                        -100.64833333341659,
                        28.999999999700265
                    ],
                    [
                        -100.33333333386622,
                        28.43333333296698
                    ],
                    [
                        -100.06666666653342,
                        28.56666666663341
                    ],
                    [
                        -99.91666666683318,
                        28.683333333366704
                    ],
                    [
                        -99.81666666703302,
                        28.8000000001
                    ],
                    [
                        -99.7500000001998,
                        28.899999999900103
                    ],
                    [
                        -99.20000000039965,
                        28.88333333296697
                    ],
                    [
                        -99.3500000000999,
                        28.999999999700265
                    ],
                    [
                        -99.48333333376632,
                        29.183333333266773
                    ],
                    [
                        -99.61666666653338,
                        29.466666666633387
                    ],
                    [
                        -99.6083333335165,
                        29.81666666683327
                    ],
                    [
                        -98.79166666728281,
                        29.66666666623371
                    ],
                    [
                        -98.7166666665334,
                        29.999999999500403
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 412,
            "artcc": "zhu",
            "sector": "98",
            "sectype": "low",
            "Shape_Length": 12.206801466549708,
            "Shape_Area": 6.068053818840742
        }
    },
    {
        "type": "Feature",
        "id": 429,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -86.75000000009987,
                        38.700000000099976
                    ],
                    [
                        -86.71250000017483,
                        38.60833333331675
                    ],
                    [
                        -86.61666666643345,
                        38.625000000249884
                    ],
                    [
                        -85.46166666658337,
                        38.833333332867085
                    ],
                    [
                        -85.40000000009991,
                        38.833333332867085
                    ],
                    [
                        -85.38055555579422,
                        38.84305555546956
                    ],
                    [
                        -85.36666666713296,
                        38.93500000034982
                    ],
                    [
                        -85.36666666713296,
                        39.08333333326675
                    ],
                    [
                        -85.49999999990001,
                        39.08333333326675
                    ],
                    [
                        -86.04999999970016,
                        39.02777777772229
                    ],
                    [
                        -86.34166666698303,
                        39.00000000039972
                    ],
                    [
                        -86.49999999970021,
                        39.00000000039972
                    ],
                    [
                        -86.75000000009987,
                        38.700000000099976
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 429,
            "artcc": "zid",
            "sector": "18",
            "sectype": "low",
            "Shape_Length": 3.2226424249366006,
            "Shape_Area": 0.38570023166144746
        }
    },
    {
        "type": "Feature",
        "id": 431,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -86.56666666653342,
                        37.658333333416635
                    ],
                    [
                        -86.92916666670828,
                        37.343055555769354
                    ],
                    [
                        -86.91666666673325,
                        37.288888888911174
                    ],
                    [
                        -86.4833333336664,
                        37.30000000019993
                    ],
                    [
                        -86.15000000039964,
                        37.30000000019993
                    ],
                    [
                        -85.99999999980014,
                        37.19444444475539
                    ],
                    [
                        -85.96361111136366,
                        37.1666666665335
                    ],
                    [
                        -85.58333333366636,
                        36.900000000099965
                    ],
                    [
                        -85.26222222227767,
                        37.32500000014994
                    ],
                    [
                        -85.3625000001748,
                        37.65000000039976
                    ],
                    [
                        -85.66666666653339,
                        37.56249999967531
                    ],
                    [
                        -85.983333332867,
                        37.51666666673333
                    ],
                    [
                        -86.56666666653342,
                        37.658333333416635
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 431,
            "artcc": "zid",
            "sector": "19",
            "sectype": "low",
            "Shape_Length": 4.106024863602606,
            "Shape_Area": 0.6043678625446036
        }
    },
    {
        "type": "Feature",
        "id": 434,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -85.58333333366636,
                        36.900000000099965
                    ],
                    [
                        -85.40000000009991,
                        36.18333333366644
                    ],
                    [
                        -84.5833333329669,
                        36.3958333332418
                    ],
                    [
                        -84.11111111128872,
                        36.51249999997509
                    ],
                    [
                        -83.5583333332167,
                        36.6499999997003
                    ],
                    [
                        -83.31944444410578,
                        36.711111110889135
                    ],
                    [
                        -83.18333333306686,
                        36.67222222227781
                    ],
                    [
                        -83.12916666710794,
                        36.76111111078916
                    ],
                    [
                        -83.11194444398086,
                        36.76472222245263
                    ],
                    [
                        -82.9750000004496,
                        36.794444444655426
                    ],
                    [
                        -83.32361111106388,
                        37.04166666678327
                    ],
                    [
                        -83.82916666660833,
                        37.59999999960036
                    ],
                    [
                        -84.69055555519469,
                        37.3422222223777
                    ],
                    [
                        -85.16750000002492,
                        37.70527777784724
                    ],
                    [
                        -85.3625000001748,
                        37.65000000039976
                    ],
                    [
                        -85.26222222227767,
                        37.32500000014994
                    ],
                    [
                        -85.58333333366636,
                        36.900000000099965
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 434,
            "artcc": "zid",
            "sector": "21",
            "sectype": "low",
            "Shape_Length": 7.044200899010915,
            "Shape_Area": 2.2299472225419326
        }
    },
    {
        "type": "Feature",
        "id": 437,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -80.77777777772224,
                        39.31666666673334
                    ],
                    [
                        -81.10277777797205,
                        39.50416666635863
                    ],
                    [
                        -81.19166666738278,
                        39.54999999930055
                    ],
                    [
                        -81.42611111143862,
                        39.68222222147841
                    ],
                    [
                        -81.55000000059948,
                        39.749999999800195
                    ],
                    [
                        -81.9833333336664,
                        39.61666666613377
                    ],
                    [
                        -82.20555555584417,
                        39.533333333266796
                    ],
                    [
                        -82.49555555544447,
                        39.47388888886121
                    ],
                    [
                        -82.4636111111638,
                        39.23888888861137
                    ],
                    [
                        -82.43888888931076,
                        39.05416666635864
                    ],
                    [
                        -82.4236111110639,
                        39.05277777767236
                    ],
                    [
                        -82.05416666655839,
                        39.20833333301698
                    ],
                    [
                        -82.0083333336164,
                        39.095833333241785
                    ],
                    [
                        -81.89999999990005,
                        38.833333332867085
                    ],
                    [
                        -81.70000000029972,
                        38.85416666675832
                    ],
                    [
                        -81.41249999997495,
                        38.87361111106395
                    ],
                    [
                        -81.05000000069941,
                        38.936111110939066
                    ],
                    [
                        -80.99166666688313,
                        38.941666666583444
                    ],
                    [
                        -80.77777777772224,
                        39.31666666673334
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 437,
            "artcc": "zid",
            "sector": "24",
            "sectype": "low",
            "Shape_Length": 4.464917791114851,
            "Shape_Area": 0.996951928658166
        }
    },
    {
        "type": "Feature",
        "id": 439,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -81.28333333326668,
                        37.6416666664835
                    ],
                    [
                        -81.95833333371633,
                        37.85000000000008
                    ],
                    [
                        -82.2086111113137,
                        37.92416666645852
                    ],
                    [
                        -82.5572222228273,
                        37.59583333264226
                    ],
                    [
                        -82.7780555563188,
                        37.62166666598392
                    ],
                    [
                        -83.11666666713296,
                        37.895833332942004
                    ],
                    [
                        -83.66666666693311,
                        37.64999999950044
                    ],
                    [
                        -83.82916666660833,
                        37.59999999960036
                    ],
                    [
                        -83.32361111106388,
                        37.04166666678327
                    ],
                    [
                        -82.9750000004496,
                        36.794444443756106
                    ],
                    [
                        -82.7583333339162,
                        36.843055554969965
                    ],
                    [
                        -82.25555555574425,
                        36.9597222217032
                    ],
                    [
                        -81.93333333376631,
                        37.03333333286707
                    ],
                    [
                        -81.16250000047461,
                        37.211111110789204
                    ],
                    [
                        -80.90000000009991,
                        37.27083333329176
                    ],
                    [
                        -80.74583333344157,
                        37.30416666625871
                    ],
                    [
                        -80.82222222287726,
                        37.43333333296698
                    ],
                    [
                        -80.84166666718289,
                        37.46666666683325
                    ],
                    [
                        -80.82500000024976,
                        37.52916666670836
                    ],
                    [
                        -81.28333333326668,
                        37.6416666664835
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 439,
            "artcc": "zid",
            "sector": "25",
            "sectype": "low",
            "Shape_Length": 7.069404893061174,
            "Shape_Area": 1.95990081006502
        }
    },
    {
        "type": "Feature",
        "id": 441,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -83.9438888884614,
                        38.768888889011066
                    ],
                    [
                        -84.01833333301687,
                        38.436944444430594
                    ],
                    [
                        -83.78555555574428,
                        38.15972222200298
                    ],
                    [
                        -83.82916666660833,
                        37.8000000001
                    ],
                    [
                        -83.82916666660833,
                        37.59999999960036
                    ],
                    [
                        -83.66666666693311,
                        37.65000000039976
                    ],
                    [
                        -83.11666666713296,
                        37.895833332942004
                    ],
                    [
                        -83.05555555594412,
                        38.03333333356653
                    ],
                    [
                        -83.21583333354147,
                        38.68027777769731
                    ],
                    [
                        -83.3869444443306,
                        38.68055555579434
                    ],
                    [
                        -83.54722222192794,
                        38.67083333319181
                    ],
                    [
                        -83.68805555521965,
                        38.71361111066426
                    ],
                    [
                        -83.9438888884614,
                        38.768888889011066
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 441,
            "artcc": "zid",
            "sector": "26",
            "sectype": "low",
            "Shape_Length": 3.5945748690163017,
            "Shape_Area": 0.7315959100316375
        }
    },
    {
        "type": "Feature",
        "id": 443,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -82.34444444425566,
                        40.5736111112638
                    ],
                    [
                        -82.36666666683317,
                        40.508333333116866
                    ],
                    [
                        -82.1250000003497,
                        40.254166666658364
                    ],
                    [
                        -82.18888888891104,
                        39.68333333296698
                    ],
                    [
                        -82.20555555584417,
                        39.533333333266796
                    ],
                    [
                        -81.9833333336664,
                        39.61666666703309
                    ],
                    [
                        -81.54999999970016,
                        39.749999999800195
                    ],
                    [
                        -81.5744444443556,
                        39.88750000042472
                    ],
                    [
                        -81.63333333346651,
                        40.21666666673332
                    ],
                    [
                        -81.69972222220275,
                        40.38722222222782
                    ],
                    [
                        -81.75444444435556,
                        40.527222222127875
                    ],
                    [
                        -81.78333333316675,
                        40.59999999990015
                    ],
                    [
                        -81.89166666688311,
                        40.59999999990015
                    ],
                    [
                        -82.16666666633353,
                        40.587499999925114
                    ],
                    [
                        -82.34444444425566,
                        40.5736111112638
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 443,
            "artcc": "zid",
            "sector": "30",
            "sectype": "low",
            "Shape_Length": 3.2833346141427526,
            "Shape_Area": 0.5345043211040138
        }
    },
    {
        "type": "Feature",
        "id": 446,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -84.51666666703301,
                        40.033333333166865
                    ],
                    [
                        -84.53861111151355,
                        39.99166666628366
                    ],
                    [
                        -84.61666666683317,
                        39.84166666658342
                    ],
                    [
                        -84.31111111088904,
                        39.838333333016976
                    ],
                    [
                        -84.20500000014982,
                        39.82749999982519
                    ],
                    [
                        -84.21499999995001,
                        39.903888889260884
                    ],
                    [
                        -84.23722222252752,
                        40.08222222247764
                    ],
                    [
                        -84.40833333331665,
                        40.041666667083064
                    ],
                    [
                        -84.51666666703301,
                        40.033333333166865
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 446,
            "artcc": "zid",
            "sector": "32",
            "sectype": "low",
            "Shape_Length": 1.1696803708233634,
            "Shape_Area": 0.07355246931659254
        }
    },
    {
        "type": "Feature",
        "id": 452,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -82.49555555544447,
                        39.47388888886121
                    ],
                    [
                        -82.82499999985009,
                        39.405555555244746
                    ],
                    [
                        -83.12277777807196,
                        39.436111110839136
                    ],
                    [
                        -83.29444444505503,
                        39.3722222222778
                    ],
                    [
                        -83.88666666703301,
                        39.148333333316714
                    ],
                    [
                        -83.91250000037468,
                        38.911111110989054
                    ],
                    [
                        -83.94388888936072,
                        38.768888889011066
                    ],
                    [
                        -83.68805555611897,
                        38.71361111066426
                    ],
                    [
                        -83.54722222282726,
                        38.67083333319181
                    ],
                    [
                        -83.3869444443306,
                        38.68055555489502
                    ],
                    [
                        -83.21583333354147,
                        38.68027777769731
                    ],
                    [
                        -83.23888888951058,
                        38.77222222167825
                    ],
                    [
                        -83.03333333336661,
                        38.779166666008905
                    ],
                    [
                        -83.03333333336661,
                        38.81666666683327
                    ],
                    [
                        -83.03333333336661,
                        39.08333333326675
                    ],
                    [
                        -82.78333333386621,
                        39.07499999935055
                    ],
                    [
                        -82.49166666658334,
                        39.05833333331674
                    ],
                    [
                        -82.43888888931076,
                        39.05416666635864
                    ],
                    [
                        -82.4636111111638,
                        39.23888888861137
                    ],
                    [
                        -82.49555555544447,
                        39.47388888886121
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 452,
            "artcc": "zid",
            "sector": "69",
            "sectype": "low",
            "Shape_Length": 4.200359342116213,
            "Shape_Area": 0.7182522382587627
        }
    },
    {
        "type": "Feature",
        "id": 453,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -82.67305555616895,
                        39.807222222127905
                    ],
                    [
                        -82.97666666723285,
                        39.93472222205298
                    ],
                    [
                        -83.12111111128871,
                        39.929166666408605
                    ],
                    [
                        -83.01638888923583,
                        39.66583333264225
                    ],
                    [
                        -82.67305555616895,
                        39.807222222127905
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 453,
            "artcc": "zid",
            "sector": "69",
            "sectype": "low",
            "Shape_Length": 1.1285462889762623,
            "Shape_Area": 0.06266053244910534
        }
    },
    {
        "type": "Feature",
        "id": 490,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -86.57916666740772,
                        31.13333333296697
                    ],
                    [
                        -86.5083333336164,
                        31.57916666600886
                    ],
                    [
                        -86.74583333314177,
                        31.52916666610878
                    ],
                    [
                        -86.95000000059952,
                        31.491666666183733
                    ],
                    [
                        -87.29999999990002,
                        31.41666666633364
                    ],
                    [
                        -87.4000000005995,
                        31.28055555529471
                    ],
                    [
                        -87.58888888891107,
                        31.0500000001
                    ],
                    [
                        -87.6583333340161,
                        30.958333333316716
                    ],
                    [
                        -87.68333333396617,
                        30.845833332642258
                    ],
                    [
                        -87.02500000044961,
                        30.891944443681155
                    ],
                    [
                        -87.00416666655838,
                        30.91805555511985
                    ],
                    [
                        -86.87222222247755,
                        31.088333333416642
                    ],
                    [
                        -86.73333333316674,
                        30.9999999993006
                    ],
                    [
                        -86.57916666740772,
                        31.13333333296697
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 490,
            "artcc": "zjx",
            "sector": "09",
            "sectype": "low",
            "Shape_Length": 3.2340444664264414,
            "Shape_Area": 0.5049307486242657
        }
    },
    {
        "type": "Feature",
        "id": 491,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -85.70000000039965,
                        30.73611111113894
                    ],
                    [
                        -85.66666666653339,
                        30.73611111113894
                    ],
                    [
                        -85.53333333376628,
                        31.216666666733317
                    ],
                    [
                        -85.89166666698304,
                        31.70694444403091
                    ],
                    [
                        -85.92499999994999,
                        31.70833333271719
                    ],
                    [
                        -86.5083333336164,
                        31.57916666600886
                    ],
                    [
                        -86.74583333314177,
                        31.52916666610878
                    ],
                    [
                        -86.95000000059952,
                        31.491666666183733
                    ],
                    [
                        -87.29999999990002,
                        31.41666666633364
                    ],
                    [
                        -87.4000000005995,
                        31.28055555529471
                    ],
                    [
                        -87.58888888891107,
                        31.0500000001
                    ],
                    [
                        -87.6583333340161,
                        30.958333333316716
                    ],
                    [
                        -87.68333333396617,
                        30.845833332642258
                    ],
                    [
                        -87.69166666698305,
                        30.791666666683398
                    ],
                    [
                        -87.71666666693312,
                        30.691666665983917
                    ],
                    [
                        -86.91666666673325,
                        30.733611110964034
                    ],
                    [
                        -86.91666666673325,
                        30.646111111138907
                    ],
                    [
                        -86.78333333396614,
                        30.704444444055923
                    ],
                    [
                        -86.76250000007491,
                        30.7127777770728
                    ],
                    [
                        -86.46027777769723,
                        30.72166666628368
                    ],
                    [
                        -86.17500000034971,
                        30.729444444005935
                    ],
                    [
                        -86.08611111093899,
                        30.683611111063954
                    ],
                    [
                        -85.93333333386624,
                        30.70388888876124
                    ],
                    [
                        -85.70000000039965,
                        30.73611111113894
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 491,
            "artcc": "zjx",
            "sector": "10",
            "sectype": "low",
            "Shape_Length": 5.5674305101809125,
            "Shape_Area": 1.6084878470188024
        }
    },
    {
        "type": "Feature",
        "id": 493,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -84.6980555557193,
                        31.07500000005001
                    ],
                    [
                        -84.13333333386623,
                        31.119444444305714
                    ],
                    [
                        -83.96666666723286,
                        31.137499999925126
                    ],
                    [
                        -83.9938888892608,
                        31.186111111138928
                    ],
                    [
                        -83.97388888876117,
                        31.200555555094866
                    ],
                    [
                        -83.75000000069946,
                        31.36249999947546
                    ],
                    [
                        -83.63333333396616,
                        31.591666665983894
                    ],
                    [
                        -83.56666666713295,
                        31.591666665983894
                    ],
                    [
                        -83.55916666660835,
                        32.0691666661088
                    ],
                    [
                        -83.5583333332167,
                        32.11666666673335
                    ],
                    [
                        -83.80000000059948,
                        32.09166666678328
                    ],
                    [
                        -83.90833333341658,
                        32.07499999985015
                    ],
                    [
                        -84.23888888931077,
                        32.01333333336669
                    ],
                    [
                        -84.57916666690812,
                        31.952777777472477
                    ],
                    [
                        -84.69444444495514,
                        31.923611110564366
                    ],
                    [
                        -85.07499999985009,
                        31.849999999400495
                    ],
                    [
                        -84.9625000000749,
                        31.80833333341667
                    ],
                    [
                        -84.75833333351648,
                        31.299999999600345
                    ],
                    [
                        -84.80000000039968,
                        31.066666666133813
                    ],
                    [
                        -84.6980555557193,
                        31.07500000005001
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 493,
            "artcc": "zjx",
            "sector": "12",
            "sectype": "low",
            "Shape_Length": 4.487747110123411,
            "Shape_Area": 1.0624428625452074
        }
    },
    {
        "type": "Feature",
        "id": 494,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -83.5583333332167,
                        32.11666666673335
                    ],
                    [
                        -83.80000000059948,
                        32.09166666678328
                    ],
                    [
                        -83.90833333341658,
                        32.07499999985015
                    ],
                    [
                        -84.23888888931077,
                        32.01333333336669
                    ],
                    [
                        -84.57916666690812,
                        31.952777777472477
                    ],
                    [
                        -84.69444444495514,
                        31.923611110564366
                    ],
                    [
                        -85.07499999985009,
                        31.849999999400495
                    ],
                    [
                        -85.23888888911091,
                        31.827777777722304
                    ],
                    [
                        -85.49444444425563,
                        31.777777777822223
                    ],
                    [
                        -85.58333333366636,
                        31.76249999957537
                    ],
                    [
                        -85.89166666698304,
                        31.70694444403091
                    ],
                    [
                        -85.53333333376628,
                        31.216666666733317
                    ],
                    [
                        -85.66666666653339,
                        30.73611111113894
                    ],
                    [
                        -85.16250000057454,
                        30.722222221578306
                    ],
                    [
                        -85.13277777837175,
                        30.688611110514387
                    ],
                    [
                        -84.90611111113884,
                        30.447777777422516
                    ],
                    [
                        -84.78333333346654,
                        30.31666666673334
                    ],
                    [
                        -84.40083333369137,
                        30.48499999925059
                    ],
                    [
                        -84.03750000012485,
                        30.643055555669434
                    ],
                    [
                        -83.73333333376632,
                        30.616666666133767
                    ],
                    [
                        -83.96666666723286,
                        31.137499999925126
                    ],
                    [
                        -83.9938888892608,
                        31.186111111138928
                    ],
                    [
                        -83.97388888876117,
                        31.200555555094866
                    ],
                    [
                        -84.0194444445055,
                        31.300277777697318
                    ],
                    [
                        -84.03333333316675,
                        31.379444444505566
                    ],
                    [
                        -84.09999999999997,
                        31.50027777729764
                    ],
                    [
                        -83.90000000039964,
                        32.062777777072824
                    ],
                    [
                        -83.73750000072442,
                        32.08361111096406
                    ],
                    [
                        -83.55916666660835,
                        32.0691666661088
                    ],
                    [
                        -83.5583333332167,
                        32.11666666673335
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 494,
            "artcc": "zjx",
            "sector": "13",
            "sectype": "low",
            "Shape_Length": 7.621596584595328,
            "Shape_Area": 2.2914445605080305
        }
    },
    {
        "type": "Feature",
        "id": 496,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -82.3833333337663,
                        27.950000000000045
                    ],
                    [
                        -82.20694444453045,
                        28.02999999930057
                    ],
                    [
                        -81.9119444445804,
                        28.162499999575346
                    ],
                    [
                        -81.86222222277735,
                        28.184999999350566
                    ],
                    [
                        -81.50000000069946,
                        28.34638888843648
                    ],
                    [
                        -81.5947222220529,
                        28.55611111063928
                    ],
                    [
                        -81.78611111143863,
                        28.589722221703198
                    ],
                    [
                        -81.95138888938573,
                        28.616111111238865
                    ],
                    [
                        -82.04166666658335,
                        28.633333332567304
                    ],
                    [
                        -82.36527777814689,
                        28.68694444413086
                    ],
                    [
                        -82.50583333334163,
                        29.262222221977993
                    ],
                    [
                        -82.54333333326667,
                        29.878611110514385
                    ],
                    [
                        -82.66666666713297,
                        29.799999999900137
                    ],
                    [
                        -82.9000000005995,
                        29.799999999900137
                    ],
                    [
                        -82.9000000005995,
                        29.66666666623371
                    ],
                    [
                        -82.9000000005995,
                        29.622222221978006
                    ],
                    [
                        -82.9000000005995,
                        29.466666666633387
                    ],
                    [
                        -82.87916666670827,
                        29.204166666258686
                    ],
                    [
                        -82.716666667033,
                        28.741666666283663
                    ],
                    [
                        -82.66083333339157,
                        28.396111111138907
                    ],
                    [
                        -82.65750000072444,
                        27.950000000000045
                    ],
                    [
                        -82.3833333337663,
                        27.950000000000045
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 496,
            "artcc": "zjx",
            "sector": "14",
            "sectype": "low",
            "Shape_Length": 5.726456567183017,
            "Shape_Area": 1.0287290898695498
        }
    },
    {
        "type": "Feature",
        "id": 498,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -81.84666666733278,
                        29.3161111107392
                    ],
                    [
                        -81.78944444500507,
                        29.3161111107392
                    ],
                    [
                        -81.80694444443054,
                        29.38361111096407
                    ],
                    [
                        -81.84166666698303,
                        29.522222222177845
                    ],
                    [
                        -81.86722222222772,
                        29.605833333141845
                    ],
                    [
                        -81.96666666673326,
                        29.93055555529469
                    ],
                    [
                        -82.28333333396614,
                        30.049999999400484
                    ],
                    [
                        -82.54333333326667,
                        29.878611110514385
                    ],
                    [
                        -82.50583333334163,
                        29.262222221977993
                    ],
                    [
                        -82.36527777814689,
                        28.68694444413086
                    ],
                    [
                        -82.36583333344157,
                        28.47750000002503
                    ],
                    [
                        -81.9630555559691,
                        28.461666666483495
                    ],
                    [
                        -81.9569444450301,
                        28.563888888361532
                    ],
                    [
                        -81.95138888938573,
                        28.616111111238865
                    ],
                    [
                        -81.78611111143863,
                        28.589722221703198
                    ],
                    [
                        -81.5947222220529,
                        28.55611111063928
                    ],
                    [
                        -81.73416666665833,
                        28.864166666758308
                    ],
                    [
                        -81.70833333331666,
                        28.999999999700265
                    ],
                    [
                        -81.78416666655835,
                        29.295277777747287
                    ],
                    [
                        -81.84194444508006,
                        29.295277777747287
                    ],
                    [
                        -81.84666666733278,
                        29.3161111107392
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 498,
            "artcc": "zjx",
            "sector": "15",
            "sectype": "low",
            "Shape_Length": 4.546023677688766,
            "Shape_Area": 0.998122453633013
        }
    },
    {
        "type": "Feature",
        "id": 501,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -83.5583333332167,
                        30.391666666583433
                    ],
                    [
                        -83.56666666713295,
                        30.612500000074988
                    ],
                    [
                        -83.73333333376632,
                        30.616666666133767
                    ],
                    [
                        -84.03750000012485,
                        30.643055555669434
                    ],
                    [
                        -84.40083333369137,
                        30.48499999925059
                    ],
                    [
                        -84.78333333346654,
                        30.31666666673334
                    ],
                    [
                        -84.52500000004994,
                        30.020833333391693
                    ],
                    [
                        -84.36999999999995,
                        29.844722222252813
                    ],
                    [
                        -84.33361111156353,
                        29.708888888411536
                    ],
                    [
                        -84.0000000001998,
                        29.708333333116855
                    ],
                    [
                        -83.75666666693309,
                        29.319722221503355
                    ],
                    [
                        -83.71305555606904,
                        29.250000000099988
                    ],
                    [
                        -82.87916666670827,
                        29.204166666258686
                    ],
                    [
                        -82.9000000005995,
                        29.466666666633387
                    ],
                    [
                        -82.9000000005995,
                        29.622222221978006
                    ],
                    [
                        -82.9000000005995,
                        29.66666666623371
                    ],
                    [
                        -82.9000000005995,
                        29.799999999900137
                    ],
                    [
                        -83.10000000019983,
                        29.799999999900137
                    ],
                    [
                        -83.5583333332167,
                        30.391666666583433
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 501,
            "artcc": "zjx",
            "sector": "28",
            "sectype": "low",
            "Shape_Length": 5.5297127937881765,
            "Shape_Area": 1.5126502313395003
        }
    },
    {
        "type": "Feature",
        "id": 502,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -82.64583333324168,
                        32.447222221728225
                    ],
                    [
                        -83.10000000019983,
                        32.27777777772229
                    ],
                    [
                        -83.49583333334164,
                        32.12499999975023
                    ],
                    [
                        -83.5583333332167,
                        32.11666666673335
                    ],
                    [
                        -83.55916666660835,
                        32.0691666661088
                    ],
                    [
                        -83.56666666713295,
                        31.591666665983894
                    ],
                    [
                        -83.0166666673328,
                        31.591666665983894
                    ],
                    [
                        -82.97916666740775,
                        31.591666665983894
                    ],
                    [
                        -82.6333333332667,
                        31.245833332742166
                    ],
                    [
                        -82.6333333332667,
                        30.79305555536962
                    ],
                    [
                        -82.3833333337663,
                        30.724999999850183
                    ],
                    [
                        -82.18333333326666,
                        30.9999999993006
                    ],
                    [
                        -81.78333333316675,
                        30.9999999993006
                    ],
                    [
                        -81.93333333376631,
                        31.249999999700265
                    ],
                    [
                        -82.00000000059953,
                        31.399999999400507
                    ],
                    [
                        -82.00000000059953,
                        31.849999999400495
                    ],
                    [
                        -82.00000000059953,
                        32.02499999995007
                    ],
                    [
                        -82.2000000001998,
                        32.291666666383605
                    ],
                    [
                        -82.06666666653342,
                        32.43333333306691
                    ],
                    [
                        -82.18333333326666,
                        32.43333333306691
                    ],
                    [
                        -82.32222222257747,
                        32.33333333326675
                    ],
                    [
                        -82.64583333324168,
                        32.447222221728225
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 502,
            "artcc": "zjx",
            "sector": "29",
            "sectype": "low",
            "Shape_Length": 6.265102578861217,
            "Shape_Area": 1.666541859722808
        }
    },
    {
        "type": "Feature",
        "id": 504,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -84.94444444445548,
                        28.04305555546955
                    ],
                    [
                        -84.99999999999994,
                        28.039722221903048
                    ],
                    [
                        -85.20361111126374,
                        28.028611111513612
                    ],
                    [
                        -86.79999999999995,
                        28.50527777824692
                    ],
                    [
                        -88.01111111068917,
                        28.999999999700265
                    ],
                    [
                        -88.00000000029974,
                        28.141666666583433
                    ],
                    [
                        -87.84783611124749,
                        27.83333333326675
                    ],
                    [
                        -87.68333333306686,
                        27.500000000000057
                    ],
                    [
                        -86.81722222222771,
                        27.241388888486483
                    ],
                    [
                        -85.99999999980014,
                        27.000000000099988
                    ],
                    [
                        -85.25000000039967,
                        27.500000000000057
                    ],
                    [
                        -84.94444444445548,
                        28.04305555546955
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 504,
            "artcc": "zjx",
            "sector": "30",
            "sectype": "low",
            "Shape_Length": 8.088345579362374,
            "Shape_Area": 3.29327380880166
        }
    },
    {
        "type": "Feature",
        "id": 514,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -77.50000000059953,
                        32.00000000000006
                    ],
                    [
                        -77.00000000069946,
                        32.00000000000006
                    ],
                    [
                        -77.00000000069946,
                        32.262222222277785
                    ],
                    [
                        -76.77944444440556,
                        32.967777777422555
                    ],
                    [
                        -77.19833333351647,
                        33.288611110714214
                    ],
                    [
                        -77.51166666718291,
                        33.52555555494496
                    ],
                    [
                        -77.75083333349153,
                        33.705555554944965
                    ],
                    [
                        -77.93277777837176,
                        33.841666665983894
                    ],
                    [
                        -78.11916666740774,
                        33.98027777719773
                    ],
                    [
                        -78.14972222210281,
                        33.841944444080866
                    ],
                    [
                        -78.09972222220273,
                        33.841666665983894
                    ],
                    [
                        -78.08944444430563,
                        33.55333333316685
                    ],
                    [
                        -78.06638888923584,
                        32.91694444413082
                    ],
                    [
                        -78.11638888913586,
                        32.70027777759742
                    ],
                    [
                        -78.21638888893602,
                        32.20861111071423
                    ],
                    [
                        -78.24138888888609,
                        32.00000000000006
                    ],
                    [
                        -77.50000000059953,
                        32.00000000000006
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 514,
            "artcc": "zjx",
            "sector": "52",
            "sectype": "low",
            "Shape_Length": 5.973335609007373,
            "Shape_Area": 1.8519663568371758
        }
    },
    {
        "type": "Feature",
        "id": 515,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -82.00000000059953,
                        31.849999999400495
                    ],
                    [
                        -82.00000000059953,
                        31.399999999400507
                    ],
                    [
                        -81.93333333376631,
                        31.249999999700265
                    ],
                    [
                        -81.78333333316675,
                        30.9999999993006
                    ],
                    [
                        -81.60000000049956,
                        30.9999999993006
                    ],
                    [
                        -81.37611111153853,
                        30.91777777792214
                    ],
                    [
                        -81.32194444468035,
                        30.896944444030908
                    ],
                    [
                        -81.21666666733279,
                        30.663888888661347
                    ],
                    [
                        -80.96083333319172,
                        30.600000000099953
                    ],
                    [
                        -80.94861111131371,
                        30.749999999800195
                    ],
                    [
                        -80.95750000052459,
                        30.86666666653349
                    ],
                    [
                        -80.95861111111384,
                        30.883333332567304
                    ],
                    [
                        -80.97666666673325,
                        31.116666666033836
                    ],
                    [
                        -80.9833333338662,
                        31.199999999800184
                    ],
                    [
                        -80.79666666673324,
                        31.459722221903064
                    ],
                    [
                        -81.19111111118877,
                        31.459722221903064
                    ],
                    [
                        -81.19527777814693,
                        31.591666665983894
                    ],
                    [
                        -81.43333333386624,
                        31.591666665983894
                    ],
                    [
                        -81.79722222272738,
                        31.591666665983894
                    ],
                    [
                        -82.00000000059953,
                        31.849999999400495
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 515,
            "artcc": "zjx",
            "sector": "53",
            "sectype": "low",
            "Shape_Length": 4.283485666296526,
            "Shape_Area": 0.7185395446813457
        }
    },
    {
        "type": "Feature",
        "id": 516,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -81.32500000014983,
                        30.31666666673334
                    ],
                    [
                        -80.99638888913591,
                        30.31666666673334
                    ],
                    [
                        -80.96333333336662,
                        30.581388888286597
                    ],
                    [
                        -80.96083333319172,
                        30.600000000099953
                    ],
                    [
                        -80.94861111131371,
                        30.749999999800195
                    ],
                    [
                        -80.95750000052459,
                        30.86666666653349
                    ],
                    [
                        -80.95861111111384,
                        30.883333332567304
                    ],
                    [
                        -80.97666666673325,
                        31.116666666033836
                    ],
                    [
                        -80.9833333338662,
                        31.199999999800184
                    ],
                    [
                        -80.79666666673324,
                        31.459722221903064
                    ],
                    [
                        -80.68333333356645,
                        31.616666666833282
                    ],
                    [
                        -80.64833333381625,
                        31.68361111086415
                    ],
                    [
                        -80.56833333361641,
                        31.83749999942546
                    ],
                    [
                        -81.15000000049957,
                        32.06249999987517
                    ],
                    [
                        -81.27500000024975,
                        32.06249999987517
                    ],
                    [
                        -82.00000000059953,
                        32.02499999995007
                    ],
                    [
                        -82.00000000059953,
                        31.849999999400495
                    ],
                    [
                        -82.00000000059953,
                        31.399999999400507
                    ],
                    [
                        -81.93333333376631,
                        31.249999999700265
                    ],
                    [
                        -81.78333333316675,
                        30.9999999993006
                    ],
                    [
                        -81.70000000029972,
                        30.799999999700276
                    ],
                    [
                        -81.91666666683318,
                        30.60416666615879
                    ],
                    [
                        -81.99166666668327,
                        30.48055555509484
                    ],
                    [
                        -81.9875000006245,
                        30.36666666663342
                    ],
                    [
                        -81.77500000014987,
                        30.31666666673334
                    ],
                    [
                        -81.32500000014983,
                        30.31666666673334
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 516,
            "artcc": "zjx",
            "sector": "54",
            "sectype": "low",
            "Shape_Length": 5.969321930712913,
            "Shape_Area": 1.8017604938318097
        }
    },
    {
        "type": "Feature",
        "id": 517,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -81.9875000006245,
                        30.36666666663342
                    ],
                    [
                        -81.97500000064946,
                        29.966666666533513
                    ],
                    [
                        -81.96666666673326,
                        29.93055555529469
                    ],
                    [
                        -81.86722222222772,
                        29.605833333141845
                    ],
                    [
                        -81.84166666698303,
                        29.522222222177845
                    ],
                    [
                        -81.80694444443054,
                        29.38361111096407
                    ],
                    [
                        -81.78944444500507,
                        29.3161111107392
                    ],
                    [
                        -81.78416666655835,
                        29.295277777747287
                    ],
                    [
                        -81.70833333331666,
                        28.999999999700265
                    ],
                    [
                        -81.73416666665833,
                        28.864166666758308
                    ],
                    [
                        -81.5947222220529,
                        28.55611111063928
                    ],
                    [
                        -81.50000000069946,
                        28.34638888843648
                    ],
                    [
                        -81.40972222260245,
                        28.38638888853643
                    ],
                    [
                        -80.96805555561934,
                        28.470833332892028
                    ],
                    [
                        -80.99999999990001,
                        28.56666666663341
                    ],
                    [
                        -80.98611111123876,
                        28.727777777622407
                    ],
                    [
                        -81.01805555551942,
                        28.756944444530518
                    ],
                    [
                        -81.21666666733279,
                        28.924999999850172
                    ],
                    [
                        -81.24583333334164,
                        28.999999999700265
                    ],
                    [
                        -81.26777777782218,
                        29.366666666833282
                    ],
                    [
                        -81.29611111133869,
                        29.839444443806087
                    ],
                    [
                        -81.30333333376632,
                        29.959722222202856
                    ],
                    [
                        -81.32500000014983,
                        30.31666666673334
                    ],
                    [
                        -81.77500000014987,
                        30.31666666673334
                    ],
                    [
                        -81.9875000006245,
                        30.36666666663342
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 517,
            "artcc": "zjx",
            "sector": "57",
            "sectype": "low",
            "Shape_Length": 5.292188183884143,
            "Shape_Area": 1.1307445605049444
        }
    },
    {
        "type": "Feature",
        "id": 519,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -80.84805555621892,
                        28.82194444368116
                    ],
                    [
                        -80.7941666665584,
                        28.85861111111393
                    ],
                    [
                        -80.73333333346653,
                        28.899999999900103
                    ],
                    [
                        -80.54083333349149,
                        28.9391666666084
                    ],
                    [
                        -80.60500000014986,
                        28.999999999700265
                    ],
                    [
                        -80.64305555626885,
                        29.054444443756154
                    ],
                    [
                        -80.65500000004994,
                        29.073611110864135
                    ],
                    [
                        -80.6911111112887,
                        29.130555555094816
                    ],
                    [
                        -80.7666666673328,
                        29.250000000099988
                    ],
                    [
                        -80.80722222272738,
                        29.337499999925114
                    ],
                    [
                        -80.87527777824681,
                        29.4833333326672
                    ],
                    [
                        -80.90000000009991,
                        29.53611111083916
                    ],
                    [
                        -80.97444444465538,
                        29.68305555506987
                    ],
                    [
                        -80.99999999990001,
                        29.733333333066923
                    ],
                    [
                        -81.03388888906096,
                        29.854166666758317
                    ],
                    [
                        -81.03083333359143,
                        29.97777777782221
                    ],
                    [
                        -81.02888888961053,
                        30.055833333141834
                    ],
                    [
                        -80.99638888913591,
                        30.31666666673334
                    ],
                    [
                        -81.32500000014983,
                        30.31666666673334
                    ],
                    [
                        -81.30333333376632,
                        29.959722222202856
                    ],
                    [
                        -81.29611111133869,
                        29.839444443806087
                    ],
                    [
                        -81.26777777782218,
                        29.366666666833282
                    ],
                    [
                        -81.24583333334164,
                        28.999999999700265
                    ],
                    [
                        -81.21666666733279,
                        28.924999999850172
                    ],
                    [
                        -81.01805555551942,
                        28.756944444530518
                    ],
                    [
                        -80.98611111123876,
                        28.727777777622407
                    ],
                    [
                        -80.84805555621892,
                        28.82194444368116
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 519,
            "artcc": "zjx",
            "sector": "58",
            "sectype": "low",
            "Shape_Length": 4.046918948522521,
            "Shape_Area": 0.6012299766505916
        }
    },
    {
        "type": "Feature",
        "id": 524,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -79.90083333369137,
                        33.66972222180317
                    ],
                    [
                        -79.68333333376631,
                        33.67499999935052
                    ],
                    [
                        -79.58388888926078,
                        33.826944443930984
                    ],
                    [
                        -79.26333333316677,
                        34.42749999982516
                    ],
                    [
                        -79.18861111141365,
                        34.56611111103899
                    ],
                    [
                        -79.26138888918587,
                        34.58611111063931
                    ],
                    [
                        -79.72805555611899,
                        34.71333333336668
                    ],
                    [
                        -79.91666666723285,
                        34.84999999970029
                    ],
                    [
                        -80.14722222242756,
                        34.855555555344665
                    ],
                    [
                        -80.46888888911093,
                        34.74749999972528
                    ],
                    [
                        -80.5177777784217,
                        34.727777777322615
                    ],
                    [
                        -80.49944444470532,
                        34.669444444405656
                    ],
                    [
                        -80.42611111163848,
                        34.15055555549452
                    ],
                    [
                        -80.35722222272739,
                        33.65749999992511
                    ],
                    [
                        -79.90083333369137,
                        33.66972222180317
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 524,
            "artcc": "zjx",
            "sector": "71",
            "sectype": "low",
            "Shape_Length": 4.191731687315754,
            "Shape_Area": 1.118445293564433
        }
    },
    {
        "type": "Feature",
        "id": 525,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -80.6250000006495,
                        33.29305555487002
                    ],
                    [
                        -80.49999999999994,
                        33.5166666666334
                    ],
                    [
                        -80.35722222272739,
                        33.65749999992511
                    ],
                    [
                        -80.42611111163848,
                        34.15055555549452
                    ],
                    [
                        -80.49944444470532,
                        34.669444444405656
                    ],
                    [
                        -80.5177777784217,
                        34.727777777322615
                    ],
                    [
                        -80.57833333341654,
                        34.70333333266723
                    ],
                    [
                        -80.724166667058,
                        34.644166666358615
                    ],
                    [
                        -80.85166666698308,
                        34.59222222157831
                    ],
                    [
                        -81.24166666728286,
                        34.43222222207794
                    ],
                    [
                        -81.33333333316676,
                        34.39444444405592
                    ],
                    [
                        -81.5744444443556,
                        34.293611110864106
                    ],
                    [
                        -81.80444444425564,
                        33.985277777547424
                    ],
                    [
                        -81.84999999999997,
                        33.888888888511474
                    ],
                    [
                        -81.84999999999997,
                        33.68333333326677
                    ],
                    [
                        -81.84999999999997,
                        33.55555555524472
                    ],
                    [
                        -81.84666666733278,
                        33.51694444383105
                    ],
                    [
                        -81.45805555571928,
                        33.356388889036054
                    ],
                    [
                        -80.8947222225525,
                        33.14138888838653
                    ],
                    [
                        -80.73055555609398,
                        33.10277777787218
                    ],
                    [
                        -80.6250000006495,
                        33.29305555487002
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 525,
            "artcc": "zjx",
            "sector": "72",
            "sectype": "low",
            "Shape_Length": 4.955204891089546,
            "Shape_Area": 1.6122055162851359
        }
    },
    {
        "type": "Feature",
        "id": 526,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -80.23222222207784,
                        32.40249999937555
                    ],
                    [
                        -80.17666666653338,
                        32.490833332592274
                    ],
                    [
                        -80.13305555566933,
                        32.50444444405588
                    ],
                    [
                        -79.75750000022481,
                        32.70277777777227
                    ],
                    [
                        -80.04999999999995,
                        32.75277777767235
                    ],
                    [
                        -80.15000000069944,
                        32.85000000009995
                    ],
                    [
                        -80.31111111168843,
                        32.999999999800195
                    ],
                    [
                        -80.73055555609398,
                        33.10277777787218
                    ],
                    [
                        -80.8947222225525,
                        33.14138888838653
                    ],
                    [
                        -81.45805555571928,
                        33.356388889036054
                    ],
                    [
                        -81.84666666733278,
                        33.51694444383105
                    ],
                    [
                        -81.84138888888606,
                        32.90027777719769
                    ],
                    [
                        -81.84361111096393,
                        32.891666666083836
                    ],
                    [
                        -81.89999999990005,
                        32.67777777782226
                    ],
                    [
                        -81.93333333376631,
                        32.56249999977524
                    ],
                    [
                        -82.06666666653342,
                        32.43333333306691
                    ],
                    [
                        -82.2000000001998,
                        32.291666666383605
                    ],
                    [
                        -82.00000000059953,
                        32.02499999995007
                    ],
                    [
                        -81.27500000024975,
                        32.06249999987517
                    ],
                    [
                        -81.15000000049957,
                        32.06249999987517
                    ],
                    [
                        -80.56833333361641,
                        31.83749999942546
                    ],
                    [
                        -80.48333333396613,
                        32.00000000000006
                    ],
                    [
                        -80.23222222207784,
                        32.40249999937555
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 526,
            "artcc": "zjx",
            "sector": "73",
            "sectype": "low",
            "Shape_Length": 6.667756241549972,
            "Shape_Area": 2.1453989969884444
        }
    },
    {
        "type": "Feature",
        "id": 527,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -79.26333333316677,
                        34.42749999982516
                    ],
                    [
                        -79.58388888926078,
                        33.8269444448303
                    ],
                    [
                        -79.683333332867,
                        33.67500000024984
                    ],
                    [
                        -79.90083333369137,
                        33.66972222180317
                    ],
                    [
                        -80.35722222182801,
                        33.65749999992511
                    ],
                    [
                        -80.49999999999994,
                        33.5166666666334
                    ],
                    [
                        -80.62499999975017,
                        33.29305555576934
                    ],
                    [
                        -80.73055555519466,
                        33.10277777787218
                    ],
                    [
                        -80.31111111078911,
                        32.999999999800195
                    ],
                    [
                        -80.14999999980012,
                        32.85000000009995
                    ],
                    [
                        -80.04999999999995,
                        32.75277777767235
                    ],
                    [
                        -79.75750000022481,
                        32.70277777777227
                    ],
                    [
                        -79.55666666633357,
                        32.62527777774727
                    ],
                    [
                        -79.28305555556938,
                        33.00027777789717
                    ],
                    [
                        -79.18583333314177,
                        33.09333333336667
                    ],
                    [
                        -79.09138888898599,
                        33.18361111146368
                    ],
                    [
                        -79.07055555599408,
                        33.28361111126384
                    ],
                    [
                        -79.03583333344153,
                        33.32805555551954
                    ],
                    [
                        -79.00194444428064,
                        33.36777777752246
                    ],
                    [
                        -78.9219444440808,
                        33.46138888918591
                    ],
                    [
                        -78.67055555589417,
                        33.66972222180317
                    ],
                    [
                        -78.5586111114136,
                        33.7400000002998
                    ],
                    [
                        -78.39555555554443,
                        33.841944444080866
                    ],
                    [
                        -78.14972222210281,
                        33.841944444080866
                    ],
                    [
                        -78.11916666650842,
                        33.98027777809705
                    ],
                    [
                        -78.35833333371636,
                        34.157222222627524
                    ],
                    [
                        -78.68499999985005,
                        34.39694444423077
                    ],
                    [
                        -78.74999999990001,
                        34.444444443956
                    ],
                    [
                        -79.18861111141365,
                        34.56611111103899
                    ],
                    [
                        -79.26333333316677,
                        34.42749999982516
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 527,
            "artcc": "zjx",
            "sector": "74",
            "sectype": "low",
            "Shape_Length": 7.011899855775687,
            "Shape_Area": 2.1234270060721934
        }
    },
    {
        "type": "Feature",
        "id": 530,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -81.91666666683318,
                        30.60416666615879
                    ],
                    [
                        -82.3833333337663,
                        30.724999999850183
                    ],
                    [
                        -82.6333333332667,
                        30.79305555536962
                    ],
                    [
                        -82.6333333332667,
                        30.574999999250622
                    ],
                    [
                        -83.04166666728281,
                        30.57916666620872
                    ],
                    [
                        -83.56666666713295,
                        30.612500000074988
                    ],
                    [
                        -83.5583333332167,
                        30.391666666583433
                    ],
                    [
                        -83.10000000019983,
                        29.799999999900137
                    ],
                    [
                        -82.9000000005995,
                        29.799999999900137
                    ],
                    [
                        -82.66666666713297,
                        29.799999999900137
                    ],
                    [
                        -82.54333333326667,
                        29.878611110514385
                    ],
                    [
                        -82.28333333396614,
                        30.049999999400484
                    ],
                    [
                        -81.96666666673326,
                        29.93055555529469
                    ],
                    [
                        -81.97500000064946,
                        29.966666666533513
                    ],
                    [
                        -81.9875000006245,
                        30.36666666663342
                    ],
                    [
                        -81.99166666668327,
                        30.48055555509484
                    ],
                    [
                        -81.91666666683318,
                        30.60416666615879
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 530,
            "artcc": "zjx",
            "sector": "77",
            "sectype": "low",
            "Shape_Length": 4.788281952435026,
            "Shape_Area": 1.0818310180714603
        }
    },
    {
        "type": "Feature",
        "id": 532,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -82.00000000059953,
                        31.849999999400495
                    ],
                    [
                        -82.00000000059953,
                        32.02499999995007
                    ],
                    [
                        -82.2000000001998,
                        32.291666666383605
                    ],
                    [
                        -82.32222222257747,
                        32.33333333326675
                    ],
                    [
                        -82.64583333324168,
                        32.447222221728225
                    ],
                    [
                        -83.10000000019983,
                        32.27777777772229
                    ],
                    [
                        -83.49583333334164,
                        32.12499999975023
                    ],
                    [
                        -83.5583333332167,
                        32.11666666673335
                    ],
                    [
                        -83.55916666660835,
                        32.0691666661088
                    ],
                    [
                        -83.4833333333666,
                        32.062777777072824
                    ],
                    [
                        -83.0166666673328,
                        31.591666665983894
                    ],
                    [
                        -82.97916666740775,
                        31.591666665983894
                    ],
                    [
                        -82.6333333332667,
                        31.245833332742166
                    ],
                    [
                        -82.6333333332667,
                        30.79305555536962
                    ],
                    [
                        -82.3833333337663,
                        30.724999999850183
                    ],
                    [
                        -81.91666666683318,
                        30.60416666615879
                    ],
                    [
                        -81.70000000029972,
                        30.799999999700276
                    ],
                    [
                        -81.78333333316675,
                        30.9999999993006
                    ],
                    [
                        -81.93333333376631,
                        31.249999999700265
                    ],
                    [
                        -82.00000000059953,
                        31.399999999400507
                    ],
                    [
                        -82.00000000059953,
                        31.849999999400495
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 532,
            "artcc": "zjx",
            "sector": "79",
            "sectype": "low",
            "Shape_Length": 5.874277320624104,
            "Shape_Area": 1.6868316744428888
        }
    },
    {
        "type": "Feature",
        "id": 537,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -82.65750000072444,
                        27.950000000000045
                    ],
                    [
                        -82.66083333339157,
                        28.396111111138907
                    ],
                    [
                        -82.716666667033,
                        28.741666666283663
                    ],
                    [
                        -82.87916666670827,
                        29.204166666258686
                    ],
                    [
                        -82.9000000005995,
                        29.466666666633387
                    ],
                    [
                        -83.75666666693309,
                        29.319722221503355
                    ],
                    [
                        -83.71305555606904,
                        29.250000000099988
                    ],
                    [
                        -83.51666666723287,
                        28.93333333286705
                    ],
                    [
                        -83.51666666723287,
                        28.400000000000034
                    ],
                    [
                        -83.20833333391619,
                        28.158333332617246
                    ],
                    [
                        -82.94166666658333,
                        27.950000000000045
                    ],
                    [
                        -82.65750000072444,
                        27.950000000000045
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 537,
            "artcc": "zjx",
            "sector": "88",
            "sectype": "low",
            "Shape_Length": 4.421394391662281,
            "Shape_Area": 1.0550252702443352
        }
    },
    {
        "type": "Feature",
        "id": 576,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -94.07249999967519,
                        40.427777777622396
                    ],
                    [
                        -94.39916666670825,
                        39.802499999875124
                    ],
                    [
                        -94.11249999977514,
                        39.77083333369143
                    ],
                    [
                        -93.90000000019984,
                        39.566666667133006
                    ],
                    [
                        -93.9625000000749,
                        39.29583333374143
                    ],
                    [
                        -93.99166666698306,
                        39.1666666670331
                    ],
                    [
                        -92.69999999990006,
                        39.07500000024987
                    ],
                    [
                        -92.64999999999998,
                        39.258333332917005
                    ],
                    [
                        -91.89277777817188,
                        39.563611110764214
                    ],
                    [
                        -92.13333333316677,
                        39.61666666703309
                    ],
                    [
                        -92.71666666683319,
                        40.63472222245264
                    ],
                    [
                        -93.491666667083,
                        40.52500000005
                    ],
                    [
                        -93.90833333321672,
                        40.458333333216785
                    ],
                    [
                        -94.07249999967519,
                        40.427777777622396
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 576,
            "artcc": "zkc",
            "sector": "40",
            "sectype": "low",
            "Shape_Length": 6.791674752796123,
            "Shape_Area": 2.436187152750206
        }
    },
    {
        "type": "Feature",
        "id": 579,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -92.80000000059954,
                        38.69166666618378
                    ],
                    [
                        -92.69999999990006,
                        39.07499999935055
                    ],
                    [
                        -93.99166666698306,
                        39.16666666613378
                    ],
                    [
                        -94.02499999995001,
                        39.00833333341666
                    ],
                    [
                        -94.24583333344157,
                        38.62916666630866
                    ],
                    [
                        -94.72611111093897,
                        38.685277777147746
                    ],
                    [
                        -94.76111111158849,
                        38.534166665958935
                    ],
                    [
                        -94.94999999990006,
                        37.708333333316716
                    ],
                    [
                        -94.70277777777221,
                        37.70277777767234
                    ],
                    [
                        -94.61666666663331,
                        37.69166666638358
                    ],
                    [
                        -94.31666666723288,
                        37.683333333366704
                    ],
                    [
                        -94.00527777844667,
                        37.6313888885864
                    ],
                    [
                        -93.99999999999994,
                        37.733333333266785
                    ],
                    [
                        -93.86666666723289,
                        37.91666666683324
                    ],
                    [
                        -93.03333333316675,
                        37.97499999975025
                    ],
                    [
                        -92.95722222272735,
                        38.09388888856142
                    ],
                    [
                        -92.80277777797204,
                        38.333333332967015
                    ],
                    [
                        -92.80000000059954,
                        38.69166666618378
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 579,
            "artcc": "zkc",
            "sector": "42",
            "sectype": "low",
            "Shape_Length": 6.675988303209837,
            "Shape_Area": 2.2687813646809376
        }
    },
    {
        "type": "Feature",
        "id": 580,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -95.4833333336664,
                        38.624999999350564
                    ],
                    [
                        -95.22027777799701,
                        38.79166666598388
                    ],
                    [
                        -95.10000000049956,
                        38.87499999975023
                    ],
                    [
                        -95.0666666666333,
                        39.06666666633362
                    ],
                    [
                        -95.3666666669331,
                        39.34722222232773
                    ],
                    [
                        -95.34166666698303,
                        39.511111110689285
                    ],
                    [
                        -95.56666666653342,
                        39.51666666633366
                    ],
                    [
                        -96.08333333336662,
                        39.533333333266796
                    ],
                    [
                        -96.34583333374133,
                        39.36666666663342
                    ],
                    [
                        -96.23333333396613,
                        38.84999999980022
                    ],
                    [
                        -96.20000000009986,
                        38.63333333326676
                    ],
                    [
                        -95.65000000029971,
                        38.51666666653347
                    ],
                    [
                        -95.4833333336664,
                        38.624999999350564
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 580,
            "artcc": "zkc",
            "sector": "44",
            "sectype": "low",
            "Shape_Length": 3.790760350604317,
            "Shape_Area": 0.9557986114316994
        }
    },
    {
        "type": "Feature",
        "id": 582,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -94.07250000057451,
                        40.427777777622396
                    ],
                    [
                        -94.49999999990001,
                        40.35055555569437
                    ],
                    [
                        -95.55000000049961,
                        40.1499999999001
                    ],
                    [
                        -95.91666666673325,
                        40.07916666610879
                    ],
                    [
                        -96.08333333336662,
                        40.0500000001
                    ],
                    [
                        -96.96666666733279,
                        39.86666666653349
                    ],
                    [
                        -96.6258333335415,
                        39.59361111106398
                    ],
                    [
                        -96.34583333374133,
                        39.36666666663342
                    ],
                    [
                        -96.23333333396613,
                        38.84999999980022
                    ],
                    [
                        -96.20000000009986,
                        38.63333333326676
                    ],
                    [
                        -95.67500000024978,
                        38.97222222217789
                    ],
                    [
                        -95.42499999985006,
                        39.041666666383605
                    ],
                    [
                        -95.3666666669331,
                        39.34722222232773
                    ],
                    [
                        -95.34166666698303,
                        39.511111110689285
                    ],
                    [
                        -95.23888888891105,
                        39.827777777922165
                    ],
                    [
                        -94.56666666673323,
                        39.82083333269219
                    ],
                    [
                        -94.39916666670825,
                        39.802499999875124
                    ],
                    [
                        -94.07250000057451,
                        40.427777777622396
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 582,
            "artcc": "zkc",
            "sector": "46",
            "sectype": "low",
            "Shape_Length": 7.733691032471663,
            "Shape_Area": 1.9090087582977442
        }
    },
    {
        "type": "Feature",
        "id": 585,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -94.76111111158849,
                        38.534166665958935
                    ],
                    [
                        -94.72611111093897,
                        38.685277777147746
                    ],
                    [
                        -95.15000000039964,
                        38.73333333306692
                    ],
                    [
                        -95.22027777799701,
                        38.79166666598388
                    ],
                    [
                        -95.44166666678319,
                        38.95416666655848
                    ],
                    [
                        -95.42499999985006,
                        39.041666666383605
                    ],
                    [
                        -95.67500000024978,
                        38.97222222217789
                    ],
                    [
                        -96.20000000009986,
                        38.63333333326676
                    ],
                    [
                        -96.33333333376629,
                        38.333333332967015
                    ],
                    [
                        -96.2583333339162,
                        38.2666666661338
                    ],
                    [
                        -95.93333333366638,
                        37.887499999925126
                    ],
                    [
                        -95.47777777802202,
                        37.80833333311688
                    ],
                    [
                        -94.94999999990006,
                        37.708333333316716
                    ],
                    [
                        -94.76111111158849,
                        38.534166665958935
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 585,
            "artcc": "zkc",
            "sector": "48",
            "sectype": "low",
            "Shape_Length": 4.695834863294342,
            "Shape_Area": 1.373548109665478
        }
    },
    {
        "type": "Feature",
        "id": 587,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -90.83333333396615,
                        39.29999999980021
                    ],
                    [
                        -90.66666666733278,
                        39.3749999996503
                    ],
                    [
                        -90.80833333401614,
                        40.12361111036449
                    ],
                    [
                        -90.89999999990005,
                        40.42083333329174
                    ],
                    [
                        -90.92777777812194,
                        40.48888888881123
                    ],
                    [
                        -91.13805555561936,
                        40.57666666673333
                    ],
                    [
                        -91.40000000069944,
                        40.68333333276712
                    ],
                    [
                        -91.65000000019984,
                        40.78333333256728
                    ],
                    [
                        -91.74166666698306,
                        40.76388888826165
                    ],
                    [
                        -92.71666666683319,
                        40.63472222155332
                    ],
                    [
                        -92.13333333316677,
                        39.61666666613377
                    ],
                    [
                        -91.89277777817188,
                        39.563611110764214
                    ],
                    [
                        -91.04527777824683,
                        39.14499999975027
                    ],
                    [
                        -90.83333333396615,
                        39.29999999980021
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 587,
            "artcc": "zkc",
            "sector": "50",
            "sectype": "low",
            "Shape_Length": 5.813874384032465,
            "Shape_Area": 2.062902738341215
        }
    },
    {
        "type": "Feature",
        "id": 589,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -92.69999999990006,
                        39.07500000024987
                    ],
                    [
                        -92.79999999970016,
                        38.6916666670831
                    ],
                    [
                        -92.80277777797204,
                        38.333333332967015
                    ],
                    [
                        -92.95722222182803,
                        38.09388888856142
                    ],
                    [
                        -93.03333333316675,
                        37.97499999975025
                    ],
                    [
                        -92.6113888885863,
                        37.890000000099974
                    ],
                    [
                        -92.24999999990001,
                        38.0583333335166
                    ],
                    [
                        -91.88333333366637,
                        38.24166666708305
                    ],
                    [
                        -91.48333333356646,
                        38.36666666683328
                    ],
                    [
                        -91.16111111068915,
                        38.499999999600334
                    ],
                    [
                        -91.21666666713293,
                        38.76666666693319
                    ],
                    [
                        -91.20416666625863,
                        38.82499999985015
                    ],
                    [
                        -91.16666666633353,
                        38.96666666653351
                    ],
                    [
                        -91.06111111088904,
                        39.13333333316683
                    ],
                    [
                        -91.04527777824683,
                        39.14499999975027
                    ],
                    [
                        -91.89277777817188,
                        39.563611110764214
                    ],
                    [
                        -92.64999999999998,
                        39.258333332917005
                    ],
                    [
                        -92.69999999990006,
                        39.07500000024987
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 589,
            "artcc": "zkc",
            "sector": "52",
            "sectype": "low",
            "Shape_Length": 5.834699663134488,
            "Shape_Area": 1.9841906240003335
        }
    },
    {
        "type": "Feature",
        "id": 591,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -91.16111111068915,
                        38.499999999600334
                    ],
                    [
                        -91.48333333356646,
                        38.36666666683328
                    ],
                    [
                        -91.88333333366637,
                        38.24166666708305
                    ],
                    [
                        -92.24999999990001,
                        38.0583333335166
                    ],
                    [
                        -92.6113888885863,
                        37.890000000099974
                    ],
                    [
                        -92.51666666633355,
                        37.62499999955037
                    ],
                    [
                        -92.51666666633355,
                        36.9166666670331
                    ],
                    [
                        -92.51488055519343,
                        36.84787777823692
                    ],
                    [
                        -90.88277777767229,
                        37.10333333326679
                    ],
                    [
                        -90.5666666666333,
                        37.14999999960037
                    ],
                    [
                        -90.41694444413076,
                        37.18416666685823
                    ],
                    [
                        -90.44999999990006,
                        38.04750000032482
                    ],
                    [
                        -90.70000000029972,
                        38.13333333336669
                    ],
                    [
                        -90.91666666683318,
                        38.233333333166854
                    ],
                    [
                        -91.08333333346656,
                        38.383333332867096
                    ],
                    [
                        -91.16111111068915,
                        38.499999999600334
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 591,
            "artcc": "zkc",
            "sector": "53",
            "sectype": "low",
            "Shape_Length": 6.49142265288911,
            "Shape_Area": 2.5399887513966606
        }
    },
    {
        "type": "Feature",
        "id": 593,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -88.23333333376632,
                        38.14999999940051
                    ],
                    [
                        -88.09444444445552,
                        38.485277777547424
                    ],
                    [
                        -88.1008333334915,
                        38.651388888886174
                    ],
                    [
                        -88.10277777837172,
                        38.70361111086413
                    ],
                    [
                        -89.10833333381629,
                        38.736111110439424
                    ],
                    [
                        -89.49722222262744,
                        38.6972222218281
                    ],
                    [
                        -89.50333333356645,
                        38.674999999250645
                    ],
                    [
                        -89.6500000005995,
                        38.388888888511474
                    ],
                    [
                        -89.84166666718289,
                        38.17499999935052
                    ],
                    [
                        -90.31666666713295,
                        38.03333333266721
                    ],
                    [
                        -90.43333333386624,
                        38.04166666658347
                    ],
                    [
                        -90.44999999990006,
                        38.0474999994255
                    ],
                    [
                        -90.41694444503008,
                        37.18416666595891
                    ],
                    [
                        -90.38194444438057,
                        37.19222222177814
                    ],
                    [
                        -90.33555555614396,
                        37.20305555496992
                    ],
                    [
                        -90.03444444435559,
                        37.27083333329176
                    ],
                    [
                        -89.32638888903597,
                        37.42722222202798
                    ],
                    [
                        -89.18333333366638,
                        37.45833333291705
                    ],
                    [
                        -89.04999999999995,
                        37.48333333286706
                    ],
                    [
                        -88.83333333346656,
                        37.53333333276714
                    ],
                    [
                        -88.3166666666333,
                        37.72499999935053
                    ],
                    [
                        -88.27000000029972,
                        37.96277777787219
                    ],
                    [
                        -88.23333333376632,
                        38.14999999940051
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 593,
            "artcc": "zkc",
            "sector": "54",
            "sectype": "low",
            "Shape_Length": 6.710233689221454,
            "Shape_Area": 2.376877469030673
        }
    },
    {
        "type": "Feature",
        "id": 595,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -89.14999999980012,
                        39.566666667133006
                    ],
                    [
                        -89.68333333356645,
                        39.19166666698317
                    ],
                    [
                        -89.59166666678323,
                        39.02083333339169
                    ],
                    [
                        -89.54166666688315,
                        38.9083333336165
                    ],
                    [
                        -89.49999999999994,
                        38.79999999990014
                    ],
                    [
                        -89.49722222262744,
                        38.6972222218281
                    ],
                    [
                        -89.10833333291697,
                        38.736111111338744
                    ],
                    [
                        -88.1027777774724,
                        38.70361111086413
                    ],
                    [
                        -88.10666666633352,
                        38.80805555571936
                    ],
                    [
                        -88.110277777997,
                        38.899166666308645
                    ],
                    [
                        -88.12916666700806,
                        39.386111110939055
                    ],
                    [
                        -88.32083333359145,
                        39.34999999970029
                    ],
                    [
                        -89.14999999980012,
                        39.566666667133006
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 595,
            "artcc": "zkc",
            "sector": "58",
            "sectype": "low",
            "Shape_Length": 4.319808097390889,
            "Shape_Area": 1.0447033952845066
        }
    },
    {
        "type": "Feature",
        "id": 597,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -90.45833333381626,
                        39.40277777787219
                    ],
                    [
                        -90.44999999990006,
                        39.94999999940052
                    ],
                    [
                        -90.33333333316676,
                        40.241666666683386
                    ],
                    [
                        -90.5680555562189,
                        40.33888888821167
                    ],
                    [
                        -90.72416666685814,
                        40.40777777712276
                    ],
                    [
                        -90.92777777812194,
                        40.48888888881123
                    ],
                    [
                        -90.89999999990005,
                        40.42083333329174
                    ],
                    [
                        -90.80833333401614,
                        40.12361111036449
                    ],
                    [
                        -90.66666666733278,
                        39.3749999996503
                    ],
                    [
                        -90.45833333381626,
                        39.40277777787219
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 597,
            "artcc": "zkc",
            "sector": "59",
            "sectype": "low",
            "Shape_Length": 2.8619057320374566,
            "Shape_Area": 0.33445910530294665
        }
    },
    {
        "type": "Feature",
        "id": 599,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -96.40416666665828,
                        35.6499999999001
                    ],
                    [
                        -96.23333333396613,
                        35.749999999700265
                    ],
                    [
                        -96.57083333329166,
                        36.1888888884115
                    ],
                    [
                        -96.19444444445548,
                        36.57361111116393
                    ],
                    [
                        -96.05000000039968,
                        36.6083333328171
                    ],
                    [
                        -96.97416666695807,
                        37.332777777872195
                    ],
                    [
                        -97.19999999990006,
                        37.183333332567315
                    ],
                    [
                        -97.59999999999997,
                        36.91666666613378
                    ],
                    [
                        -97.38333333346651,
                        36.633333332767165
                    ],
                    [
                        -97.38361111156354,
                        36.46666666613379
                    ],
                    [
                        -97.5836111111638,
                        36.033333333066935
                    ],
                    [
                        -97.26388888936071,
                        35.84500000004999
                    ],
                    [
                        -96.91250000047461,
                        35.73749999972529
                    ],
                    [
                        -96.83944444460542,
                        35.622222221678214
                    ],
                    [
                        -96.83916666740777,
                        35.52777777752249
                    ],
                    [
                        -96.40416666665828,
                        35.6499999999001
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 599,
            "artcc": "zkc",
            "sector": "62",
            "sectype": "low",
            "Shape_Length": 5.786099320240562,
            "Shape_Area": 1.5905201770756003
        }
    },
    {
        "type": "Feature",
        "id": 601,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -97.04916666680822,
                        38.78638888843648
                    ],
                    [
                        -97.08083333389123,
                        38.80333333256732
                    ],
                    [
                        -97.1061111110389,
                        38.826111110439456
                    ],
                    [
                        -97.11388888876115,
                        38.83638888833656
                    ],
                    [
                        -97.37083333349153,
                        39.33888888841153
                    ],
                    [
                        -97.37722222252751,
                        39.38249999927558
                    ],
                    [
                        -97.36972222290223,
                        39.425833332942034
                    ],
                    [
                        -97.34777777842169,
                        39.4661111111389
                    ],
                    [
                        -97.31277777777217,
                        39.500833332792126
                    ],
                    [
                        -97.26777777822184,
                        39.526944444230764
                    ],
                    [
                        -97.21500000004994,
                        39.5436111111639
                    ],
                    [
                        -97.17611111143862,
                        39.550555555494554
                    ],
                    [
                        -96.6258333335415,
                        39.59361111106398
                    ],
                    [
                        -96.96666666733279,
                        39.86666666653349
                    ],
                    [
                        -97.88333333336658,
                        39.66666666603385
                    ],
                    [
                        -98.80000000029975,
                        39.46666666643358
                    ],
                    [
                        -99.21666666733279,
                        39.26666666683326
                    ],
                    [
                        -98.63333333366637,
                        38.58333333336668
                    ],
                    [
                        -98.16666666673325,
                        38.2666666661338
                    ],
                    [
                        -97.8777777777222,
                        38.081944443881014
                    ],
                    [
                        -97.63055555559436,
                        38.11666666643356
                    ],
                    [
                        -97.28055555629385,
                        38.18333333326677
                    ],
                    [
                        -97.2491666673078,
                        38.17555555554452
                    ],
                    [
                        -96.9833333333666,
                        38.099999999500426
                    ],
                    [
                        -96.72499999995,
                        37.8000000001
                    ],
                    [
                        -96.72499999995,
                        37.74166666628366
                    ],
                    [
                        -96.3916666666833,
                        37.833333333066946
                    ],
                    [
                        -95.93333333366638,
                        37.887499999925126
                    ],
                    [
                        -96.2583333339162,
                        38.2666666661338
                    ],
                    [
                        -96.33333333376629,
                        38.333333332967015
                    ],
                    [
                        -96.20000000009986,
                        38.63333333326676
                    ],
                    [
                        -96.80638888883612,
                        38.77972222220285
                    ],
                    [
                        -97.01277777837174,
                        38.77638888863635
                    ],
                    [
                        -97.04916666680822,
                        38.78638888843648
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 601,
            "artcc": "zkc",
            "sector": "66",
            "sectype": "low",
            "Shape_Length": 10.082057525517364,
            "Shape_Area": 3.227190277453641
        }
    },
    {
        "type": "Feature",
        "id": 603,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -98.43103888923332,
                        37.68931944393722
                    ],
                    [
                        -98.466666667033,
                        37.833333333066946
                    ],
                    [
                        -97.8777777777222,
                        38.081944443881014
                    ],
                    [
                        -98.16666666673325,
                        38.2666666661338
                    ],
                    [
                        -98.63333333366637,
                        38.58333333336668
                    ],
                    [
                        -99.21666666733279,
                        39.26666666683326
                    ],
                    [
                        -99.63055555609401,
                        39.05833333331674
                    ],
                    [
                        -100.23388888936074,
                        38.74416666625871
                    ],
                    [
                        -100.35284444491515,
                        38.68238888853642
                    ],
                    [
                        -98.75000000039967,
                        37.66666666643357
                    ],
                    [
                        -98.43103888923332,
                        37.68931944393722
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 603,
            "artcc": "zkc",
            "sector": "67",
            "sectype": "low",
            "Shape_Length": 6.087866557770205,
            "Shape_Area": 1.737429351019108
        }
    },
    {
        "type": "Feature",
        "id": 605,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -100.35284444491515,
                        38.68238888853642
                    ],
                    [
                        -101.1583333340161,
                        38.258333333116866
                    ],
                    [
                        -101.23333333386626,
                        38.218611111113944
                    ],
                    [
                        -102.54999999999995,
                        37.499999999800195
                    ],
                    [
                        -102.18333333376631,
                        37.04166666678327
                    ],
                    [
                        -101.75000000069946,
                        36.50000000000006
                    ],
                    [
                        -101.47222222207785,
                        36.397222221928075
                    ],
                    [
                        -100.00000000059953,
                        35.82916666650851
                    ],
                    [
                        -99.38361111116382,
                        35.83333333256729
                    ],
                    [
                        -99.22777777772222,
                        35.81666666653348
                    ],
                    [
                        -98.80000000029975,
                        35.81666666653348
                    ],
                    [
                        -98.49999999999994,
                        35.81666666653348
                    ],
                    [
                        -98.466666667033,
                        35.81666666653348
                    ],
                    [
                        -98.69722222222777,
                        35.93527777724768
                    ],
                    [
                        -98.76500000054955,
                        35.966111110939096
                    ],
                    [
                        -98.83805555551942,
                        36.09972222180312
                    ],
                    [
                        -98.88805555631882,
                        36.36916666650848
                    ],
                    [
                        -98.84888888961052,
                        36.571388888186675
                    ],
                    [
                        -98.82111111138863,
                        36.636111111038986
                    ],
                    [
                        -98.69777777842171,
                        36.814999999550366
                    ],
                    [
                        -98.58500000054954,
                        36.91749999952543
                    ],
                    [
                        -98.45138888878614,
                        37.002222221978
                    ],
                    [
                        -98.28055555609399,
                        37.07388888826165
                    ],
                    [
                        -98.28944444440555,
                        37.111388888186696
                    ],
                    [
                        -98.43103888923332,
                        37.68931944393722
                    ],
                    [
                        -98.75000000039967,
                        37.66666666643357
                    ],
                    [
                        -100.35284444491515,
                        38.68238888853642
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 605,
            "artcc": "zkc",
            "sector": "68",
            "sectype": "low",
            "Shape_Length": 11.784749560136015,
            "Shape_Area": 7.46825869737106
        }
    },
    {
        "type": "Feature",
        "id": 607,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -94.04999999990002,
                        36.7499999995004
                    ],
                    [
                        -94.00527777844667,
                        37.6313888885864
                    ],
                    [
                        -94.31666666723288,
                        37.683333333366704
                    ],
                    [
                        -94.61666666663331,
                        37.69166666638358
                    ],
                    [
                        -94.70277777777221,
                        37.70277777767234
                    ],
                    [
                        -94.94999999990006,
                        37.708333333316716
                    ],
                    [
                        -95.47777777802202,
                        37.80833333311688
                    ],
                    [
                        -95.93333333366638,
                        37.887499999925126
                    ],
                    [
                        -96.3916666666833,
                        37.833333333066946
                    ],
                    [
                        -96.72499999995,
                        37.74166666628366
                    ],
                    [
                        -96.71666666693312,
                        37.62499999955037
                    ],
                    [
                        -96.80833333371635,
                        37.44166666598392
                    ],
                    [
                        -96.97416666695807,
                        37.332777777872195
                    ],
                    [
                        -96.05000000039968,
                        36.6083333328171
                    ],
                    [
                        -95.72083333319176,
                        36.69583333264222
                    ],
                    [
                        -95.01666666673327,
                        36.34027777769734
                    ],
                    [
                        -94.71000000019984,
                        36.42583333264224
                    ],
                    [
                        -94.70000000039965,
                        36.46666666613379
                    ],
                    [
                        -94.04999999990002,
                        36.7499999995004
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 607,
            "artcc": "zkc",
            "sector": "72",
            "sectype": "low",
            "Shape_Length": 7.532737948898083,
            "Shape_Area": 3.1180758107675444
        }
    },
    {
        "type": "Feature",
        "id": 625,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -119.71277777797206,
                        35.68444444435568
                    ],
                    [
                        -119.60833333311678,
                        35.47500000024985
                    ],
                    [
                        -119.5250000002498,
                        35.308611110814184
                    ],
                    [
                        -119.47869444409326,
                        35.215813889199694
                    ],
                    [
                        -119.45416666645843,
                        35.16666666693317
                    ],
                    [
                        -119.2999999998001,
                        35.06250000017491
                    ],
                    [
                        -119.21666666693312,
                        35.00000000029985
                    ],
                    [
                        -119.05555555594412,
                        34.856944444030944
                    ],
                    [
                        -118.72916666700803,
                        34.95833333341665
                    ],
                    [
                        -118.53333333346654,
                        34.84999999970029
                    ],
                    [
                        -118.40833333371637,
                        34.777777778122015
                    ],
                    [
                        -118.29166666698308,
                        34.89166666658343
                    ],
                    [
                        -118.34999999990004,
                        34.933333333466635
                    ],
                    [
                        -118.46666666663333,
                        35.091666667083075
                    ],
                    [
                        -118.58333333336662,
                        35.249999999800195
                    ],
                    [
                        -118.58333333336662,
                        35.66666666683324
                    ],
                    [
                        -118.58333333336662,
                        36.00000000009999
                    ],
                    [
                        -118.58333333336662,
                        36.133333332867096
                    ],
                    [
                        -119.00000000039967,
                        36.133333332867096
                    ],
                    [
                        -119.03888888901099,
                        36.133333332867096
                    ],
                    [
                        -119.16666666703304,
                        36.133333332867096
                    ],
                    [
                        -119.20833333301687,
                        36.07499999995008
                    ],
                    [
                        -119.37222222227768,
                        35.827777777822234
                    ],
                    [
                        -119.49027777769726,
                        35.64527777764738
                    ],
                    [
                        -119.5763888888361,
                        35.66083333309189
                    ],
                    [
                        -119.71277777797206,
                        35.68444444435568
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 625,
            "artcc": "zla",
            "sector": "03",
            "sectype": "low",
            "Shape_Length": 4.700705775789462,
            "Shape_Area": 1.0869341489435913
        }
    },
    {
        "type": "Feature",
        "id": 626,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -118.59166666728282,
                        34.020833332592304
                    ],
                    [
                        -118.47040277776597,
                        34.086694444293244
                    ],
                    [
                        -118.44583333364142,
                        34.099999999400495
                    ],
                    [
                        -118.40416666675821,
                        34.099999999400495
                    ],
                    [
                        -118.3749999998501,
                        34.099999999400495
                    ],
                    [
                        -118.30081944426189,
                        34.308791666102536
                    ],
                    [
                        -118.24906388948688,
                        34.44958888891614
                    ],
                    [
                        -118.33333333386622,
                        34.6208333331918
                    ],
                    [
                        -118.35416666685813,
                        34.654166666158744
                    ],
                    [
                        -118.40833333371637,
                        34.777777777222695
                    ],
                    [
                        -118.53333333346654,
                        34.84999999970029
                    ],
                    [
                        -118.61944444460539,
                        34.983333333366716
                    ],
                    [
                        -118.72916666700803,
                        34.95833333341665
                    ],
                    [
                        -119.05555555594412,
                        34.856944444030944
                    ],
                    [
                        -118.85128888927079,
                        34.534019444142075
                    ],
                    [
                        -118.83750000072445,
                        34.51249999947544
                    ],
                    [
                        -118.78129999999499,
                        34.352591666072556
                    ],
                    [
                        -118.7541944443181,
                        34.27497499969655
                    ],
                    [
                        -118.72500000004993,
                        34.208333333116855
                    ],
                    [
                        -118.70833333401612,
                        34.173611110564366
                    ],
                    [
                        -118.68333333316679,
                        34.099999999400495
                    ],
                    [
                        -118.6333333332667,
                        33.9833333326672
                    ],
                    [
                        -118.59166666728282,
                        34.020833332592304
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 626,
            "artcc": "zla",
            "sector": "04",
            "sectype": "low",
            "Shape_Length": 2.7622458350730184,
            "Shape_Area": 0.4319963131341793
        }
    },
    {
        "type": "Feature",
        "id": 627,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -114.95833333341659,
                        35.940833332892055
                    ],
                    [
                        -115.03500000004993,
                        35.91083333259229
                    ],
                    [
                        -115.27347500047085,
                        35.76944722201176
                    ],
                    [
                        -115.8791666673078,
                        35.80666666673335
                    ],
                    [
                        -116.43333333316679,
                        35.6499999999001
                    ],
                    [
                        -116.31250000037471,
                        35.47638888893613
                    ],
                    [
                        -116.6250277780095,
                        35.23543333258226
                    ],
                    [
                        -116.52916666690811,
                        35.141666666083836
                    ],
                    [
                        -116.38444444475527,
                        34.99972222220282
                    ],
                    [
                        -116.09577500021607,
                        34.716586111060224
                    ],
                    [
                        -116.00001944434183,
                        34.7163861108304
                    ],
                    [
                        -115.89089722262867,
                        34.716124999868896
                    ],
                    [
                        -115.83036111097647,
                        34.71591111050941
                    ],
                    [
                        -115.53394444498014,
                        34.714530554941234
                    ],
                    [
                        -115.44250000067444,
                        34.71388888866136
                    ],
                    [
                        -115.46145555552943,
                        34.67022222217787
                    ],
                    [
                        -115.31531111131869,
                        34.580561111006546
                    ],
                    [
                        -115.26666666673327,
                        34.549999999400484
                    ],
                    [
                        -115.00000000029974,
                        34.3999999997003
                    ],
                    [
                        -114.45000000049959,
                        34.34999999980022
                    ],
                    [
                        -114.24999999999994,
                        34.383333332767165
                    ],
                    [
                        -114.0000000004996,
                        34.3999999997003
                    ],
                    [
                        -114.0000000004996,
                        34.4972222221279
                    ],
                    [
                        -114.0000000004996,
                        34.591666666283686
                    ],
                    [
                        -114.0000000004996,
                        34.6166666662337
                    ],
                    [
                        -114.0000000004996,
                        34.66666666613378
                    ],
                    [
                        -113.7000000001998,
                        34.86666666663342
                    ],
                    [
                        -114.38333333366637,
                        35.27222222147839
                    ],
                    [
                        -114.6105555561939,
                        35.58944444400595
                    ],
                    [
                        -114.96166666698304,
                        35.73333333276713
                    ],
                    [
                        -114.95833333341659,
                        35.940833332892055
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 627,
            "artcc": "zla",
            "sector": "06",
            "sectype": "low",
            "Shape_Length": 7.529946357826739,
            "Shape_Area": 2.671275839586442
        }
    },
    {
        "type": "Feature",
        "id": 628,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -113.17611111093896,
                        36.207499999325535
                    ],
                    [
                        -111.53555555604402,
                        36.51499999925062
                    ],
                    [
                        -111.60833333381629,
                        36.73333333256727
                    ],
                    [
                        -111.71844444465535,
                        37.00499999935056
                    ],
                    [
                        -111.87916666720787,
                        37.41249999997507
                    ],
                    [
                        -111.89583333324168,
                        37.41388888866135
                    ],
                    [
                        -112.42182777822933,
                        37.456174999576604
                    ],
                    [
                        -113.00000000069946,
                        37.499999999800195
                    ],
                    [
                        -113.78333333396614,
                        37.71666666633365
                    ],
                    [
                        -113.98555555564434,
                        37.472222221578306
                    ],
                    [
                        -114.39555555554443,
                        36.97083333299196
                    ],
                    [
                        -114.48166666668328,
                        36.718611110514416
                    ],
                    [
                        -114.53333333336661,
                        36.56666666683327
                    ],
                    [
                        -114.71249999997497,
                        36.424999999250645
                    ],
                    [
                        -115.00000000029974,
                        36.19999999970025
                    ],
                    [
                        -115.20761944478153,
                        36.218055555319665
                    ],
                    [
                        -115.2416666667832,
                        36.22083333269222
                    ],
                    [
                        -114.95833333341659,
                        35.940833332892055
                    ],
                    [
                        -114.60000000019983,
                        36.082777777672334
                    ],
                    [
                        -114.15250000037469,
                        36.08416666635861
                    ],
                    [
                        -113.79916666660836,
                        36.08416666635861
                    ],
                    [
                        -113.7119444448802,
                        36.1016666666834
                    ],
                    [
                        -113.17611111093896,
                        36.207499999325535
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 628,
            "artcc": "zla",
            "sector": "07",
            "sectype": "low",
            "Shape_Length": 9.015176160643707,
            "Shape_Area": 3.6091655941179503
        }
    },
    {
        "type": "Feature",
        "id": 629,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -113.61666666733282,
                        34.9166666665335
                    ],
                    [
                        -112.92777777822187,
                        35.25555555544457
                    ],
                    [
                        -112.66666666653339,
                        35.383333332567304
                    ],
                    [
                        -112.15305555606903,
                        35.396666666833255
                    ],
                    [
                        -111.99999999999994,
                        35.39999999950044
                    ],
                    [
                        -111.84166666728282,
                        35.7666666666334
                    ],
                    [
                        -111.50416666705803,
                        36.42083333319181
                    ],
                    [
                        -111.53555555604402,
                        36.51499999925062
                    ],
                    [
                        -113.17611111093896,
                        36.207499999325535
                    ],
                    [
                        -113.7119444448802,
                        36.1016666666834
                    ],
                    [
                        -113.79916666660836,
                        36.08416666635861
                    ],
                    [
                        -114.15250000037469,
                        36.08416666635861
                    ],
                    [
                        -114.60000000019983,
                        36.082777777672334
                    ],
                    [
                        -114.95833333341659,
                        35.940833332892055
                    ],
                    [
                        -114.96166666698304,
                        35.73333333276713
                    ],
                    [
                        -114.6105555561939,
                        35.58944444400595
                    ],
                    [
                        -114.38333333366637,
                        35.27222222147839
                    ],
                    [
                        -113.7000000001998,
                        34.86666666663342
                    ],
                    [
                        -113.61666666733282,
                        34.9166666665335
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 629,
            "artcc": "zla",
            "sector": "08",
            "sectype": "low",
            "Shape_Length": 8.319572182062664,
            "Shape_Area": 2.992502854040956
        }
    },
    {
        "type": "Feature",
        "id": 630,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -115.5486111114136,
                        33.35833333301696
                    ],
                    [
                        -115.55555555574426,
                        33.394444444255726
                    ],
                    [
                        -115.70277777807195,
                        33.47499999975025
                    ],
                    [
                        -115.5638888887612,
                        33.54444444395597
                    ],
                    [
                        -115.87578611124002,
                        33.55756666596892
                    ],
                    [
                        -116.00000000009987,
                        33.55833333261728
                    ],
                    [
                        -116.08500000064947,
                        33.56249999957538
                    ],
                    [
                        -116.18333333366638,
                        33.56666666653348
                    ],
                    [
                        -116.30833333341656,
                        33.574999999550414
                    ],
                    [
                        -116.4361111114386,
                        33.43333333286705
                    ],
                    [
                        -116.4563888891359,
                        33.408055555719386
                    ],
                    [
                        -116.59583333374133,
                        33.24583333324176
                    ],
                    [
                        -116.73333333346653,
                        33.08333333266722
                    ],
                    [
                        -117.13888888921082,
                        33.133333332567304
                    ],
                    [
                        -117.2333333333666,
                        32.999999999800195
                    ],
                    [
                        -117.38750000002494,
                        32.93888888861136
                    ],
                    [
                        -117.65000000039964,
                        32.79999999930055
                    ],
                    [
                        -117.54916666720788,
                        32.613888888361544
                    ],
                    [
                        -117.50083333319174,
                        32.52499999985014
                    ],
                    [
                        -117.3141666669581,
                        32.44666666643354
                    ],
                    [
                        -117.12361111096396,
                        32.53416666625867
                    ],
                    [
                        -116.86666666713296,
                        32.56388888846146
                    ],
                    [
                        -116.75835000055207,
                        32.5714777770778
                    ],
                    [
                        -116.48333333396613,
                        32.59027777709781
                    ],
                    [
                        -116.09999999990004,
                        32.61666666663342
                    ],
                    [
                        -115.80833333351649,
                        32.64166666658343
                    ],
                    [
                        -115.09166666708296,
                        32.69166666648351
                    ],
                    [
                        -115.09166388907721,
                        32.706802777226414
                    ],
                    [
                        -115.09166666708296,
                        32.85000000009995
                    ],
                    [
                        -115.18055555559437,
                        32.949999999900115
                    ],
                    [
                        -115.45000000029972,
                        32.94166666598392
                    ],
                    [
                        -115.5486111114136,
                        33.35833333301696
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 630,
            "artcc": "zla",
            "sector": "09",
            "sectype": "low",
            "Shape_Length": 6.543006621034241,
            "Shape_Area": 1.5756800420932202
        }
    },
    {
        "type": "Feature",
        "id": 631,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -115.26666666673327,
                        34.549999999400484
                    ],
                    [
                        -115.31531111131869,
                        34.580561111006546
                    ],
                    [
                        -115.46145555552943,
                        34.67022222217787
                    ],
                    [
                        -115.44250000067444,
                        34.71388888866136
                    ],
                    [
                        -115.53394444498014,
                        34.714530554941234
                    ],
                    [
                        -115.83036111097647,
                        34.71591111050941
                    ],
                    [
                        -115.89089722262867,
                        34.716124999868896
                    ],
                    [
                        -116.00001944434183,
                        34.7163861108304
                    ],
                    [
                        -116.09577500021607,
                        34.716586111060224
                    ],
                    [
                        -116.10417499997624,
                        34.691608333357976
                    ],
                    [
                        -116.07583333334162,
                        34.68750000002501
                    ],
                    [
                        -116.05083333339161,
                        34.68333333306691
                    ],
                    [
                        -115.96750000052458,
                        34.591666666283686
                    ],
                    [
                        -115.9161111110389,
                        34.57777777762237
                    ],
                    [
                        -115.78416666695807,
                        34.549999999400484
                    ],
                    [
                        -115.78416666695807,
                        34.41666666663343
                    ],
                    [
                        -115.73416666705799,
                        34.41666666663343
                    ],
                    [
                        -115.73416666705799,
                        34.283333332967004
                    ],
                    [
                        -115.73416666705799,
                        34.23333333306692
                    ],
                    [
                        -115.95083333359145,
                        34.23333333306692
                    ],
                    [
                        -116.28416666685814,
                        34.23333333306692
                    ],
                    [
                        -116.31027777829678,
                        34.14972222210292
                    ],
                    [
                        -116.31666666733281,
                        34.109444443906
                    ],
                    [
                        -116.35407222225524,
                        34.00262500014367
                    ],
                    [
                        -116.36666666723289,
                        33.96666666663339
                    ],
                    [
                        -116.37500000024977,
                        33.7249999992506
                    ],
                    [
                        -116.30833333341656,
                        33.71666666623372
                    ],
                    [
                        -116.20000000059952,
                        33.69999999930059
                    ],
                    [
                        -116.30833333341656,
                        33.574999999550414
                    ],
                    [
                        -116.4361111114386,
                        33.43333333286705
                    ],
                    [
                        -116.4563888891359,
                        33.408055555719386
                    ],
                    [
                        -116.23361944478154,
                        33.50069444449309
                    ],
                    [
                        -116.08500000064947,
                        33.56249999957538
                    ],
                    [
                        -116.00000000009987,
                        33.55833333261728
                    ],
                    [
                        -115.87578611124002,
                        33.55756666596892
                    ],
                    [
                        -115.5638888887612,
                        33.54444444395597
                    ],
                    [
                        -115.06666666713295,
                        33.5166666666334
                    ],
                    [
                        -114.4666666665334,
                        33.46666666673332
                    ],
                    [
                        -114.216666667033,
                        33.46666666673332
                    ],
                    [
                        -114.0000000004996,
                        33.3999999999001
                    ],
                    [
                        -114.0000000004996,
                        33.46666666673332
                    ],
                    [
                        -114.0000000004996,
                        33.609999999300555
                    ],
                    [
                        -114.0000000004996,
                        33.69999999930059
                    ],
                    [
                        -114.0000000004996,
                        33.80000000000007
                    ],
                    [
                        -114.0000000004996,
                        33.944444444055875
                    ],
                    [
                        -114.0000000004996,
                        34.01666666653347
                    ],
                    [
                        -114.0000000004996,
                        34.16666666623371
                    ],
                    [
                        -114.0008333338912,
                        34.383333332767165
                    ],
                    [
                        -114.0000000004996,
                        34.3999999997003
                    ],
                    [
                        -114.24999999999994,
                        34.383333332767165
                    ],
                    [
                        -114.45000000049959,
                        34.34999999980022
                    ],
                    [
                        -115.00000000029974,
                        34.3999999997003
                    ],
                    [
                        -115.26666666673327,
                        34.549999999400484
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 631,
            "artcc": "zla",
            "sector": "10",
            "sectype": "low",
            "Shape_Length": 8.13764495507288,
            "Shape_Area": 2.156718744450778
        }
    },
    {
        "type": "Feature",
        "id": 632,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -116.56333611127246,
                        33.16519722187434
                    ],
                    [
                        -116.59583333374133,
                        33.24583333324176
                    ],
                    [
                        -116.4563888891359,
                        33.408055555719386
                    ],
                    [
                        -116.4361111114386,
                        33.43333333286705
                    ],
                    [
                        -116.30833333341656,
                        33.574999999550414
                    ],
                    [
                        -116.20000000059952,
                        33.69999999930059
                    ],
                    [
                        -116.30833333341656,
                        33.71666666623372
                    ],
                    [
                        -116.37500000024977,
                        33.7249999992506
                    ],
                    [
                        -116.4625000000749,
                        33.73750000012495
                    ],
                    [
                        -116.83476944445425,
                        33.776261110361986
                    ],
                    [
                        -117.00565833366511,
                        33.79558611121013
                    ],
                    [
                        -117.10416666665833,
                        33.7976416664298
                    ],
                    [
                        -117.17633055551067,
                        33.79914444436065
                    ],
                    [
                        -117.20452777843417,
                        33.803638889023546
                    ],
                    [
                        -117.2333333333666,
                        33.80833333301695
                    ],
                    [
                        -117.25708888924083,
                        33.808972222190334
                    ],
                    [
                        -117.31972222260248,
                        33.81222222187807
                    ],
                    [
                        -117.51388888936071,
                        33.81472222205298
                    ],
                    [
                        -117.5333333336664,
                        33.81666666603388
                    ],
                    [
                        -117.60000000049956,
                        33.81666666603388
                    ],
                    [
                        -117.60000000049956,
                        33.679166666308674
                    ],
                    [
                        -117.63333333346651,
                        33.52499999965033
                    ],
                    [
                        -117.58333333356643,
                        33.48333333276713
                    ],
                    [
                        -117.43121666701552,
                        33.33766944398968
                    ],
                    [
                        -117.3458333331418,
                        33.26666666623373
                    ],
                    [
                        -117.29286666676325,
                        33.19124722168198
                    ],
                    [
                        -117.26388888896105,
                        33.14999999950044
                    ],
                    [
                        -117.13888888921082,
                        33.133333332567304
                    ],
                    [
                        -116.73333333346653,
                        33.08333333266722
                    ],
                    [
                        -116.67045555627885,
                        33.054744444200765
                    ],
                    [
                        -116.56333611127246,
                        33.16519722187434
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 632,
            "artcc": "zla",
            "sector": "12",
            "sectype": "low",
            "Shape_Length": 3.6784286200396172,
            "Shape_Area": 0.715836583531719
        }
    },
    {
        "type": "Feature",
        "id": 633,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -118.6333333332667,
                        33.9833333326672
                    ],
                    [
                        -118.68333333316679,
                        34.099999999400495
                    ],
                    [
                        -118.70833333401612,
                        34.173611110564366
                    ],
                    [
                        -118.72500000004993,
                        34.208333333116855
                    ],
                    [
                        -118.7541944443181,
                        34.27497499969655
                    ],
                    [
                        -118.78129999999499,
                        34.352591666072556
                    ],
                    [
                        -118.83750000072445,
                        34.51249999947544
                    ],
                    [
                        -118.85128888927079,
                        34.534019444142075
                    ],
                    [
                        -119.05555555594412,
                        34.856944444030944
                    ],
                    [
                        -119.21666666693312,
                        34.99999999940053
                    ],
                    [
                        -119.30000000069941,
                        35.06249999927559
                    ],
                    [
                        -119.45416666735775,
                        35.16666666603385
                    ],
                    [
                        -119.47869444499258,
                        35.215813888300374
                    ],
                    [
                        -119.57498333391368,
                        35.28764444433568
                    ],
                    [
                        -119.95706666699306,
                        35.128172221870614
                    ],
                    [
                        -119.91873333367636,
                        35.07925833320559
                    ],
                    [
                        -119.85182222211785,
                        35.01492777769482
                    ],
                    [
                        -119.67185277838297,
                        34.84119444436817
                    ],
                    [
                        -119.52930833381254,
                        34.73565277715397
                    ],
                    [
                        -119.23277777767225,
                        34.52444444415585
                    ],
                    [
                        -119.20416666695809,
                        34.50416666645856
                    ],
                    [
                        -119.02668055626265,
                        34.29784722167199
                    ],
                    [
                        -118.95000000049959,
                        34.208333333116855
                    ],
                    [
                        -118.94536111122625,
                        34.14710555557696
                    ],
                    [
                        -118.94249999997498,
                        34.099999999400495
                    ],
                    [
                        -118.8286861113749,
                        33.982983332714696
                    ],
                    [
                        -118.71088888891109,
                        33.96872777786473
                    ],
                    [
                        -118.62500000024977,
                        33.95833333271719
                    ],
                    [
                        -118.6333333332667,
                        33.9833333326672
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 633,
            "artcc": "zla",
            "sector": "13",
            "sectype": "low",
            "Shape_Length": 3.945066548976975,
            "Shape_Area": 0.5323887042719664
        }
    },
    {
        "type": "Feature",
        "id": 634,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -118.99444444475529,
                        34.38055555539461
                    ],
                    [
                        -119.04166666728281,
                        34.441666666583444
                    ],
                    [
                        -119.20416666695809,
                        34.50416666645856
                    ],
                    [
                        -119.23277777767225,
                        34.52444444415585
                    ],
                    [
                        -119.52930833381254,
                        34.73565277715397
                    ],
                    [
                        -119.67185277838297,
                        34.84119444436817
                    ],
                    [
                        -119.85182222211785,
                        35.01492777769482
                    ],
                    [
                        -119.87500000044963,
                        34.991666666383594
                    ],
                    [
                        -119.96027777819688,
                        34.90750000012497
                    ],
                    [
                        -120.09583333394119,
                        34.77083333289204
                    ],
                    [
                        -119.93333333336659,
                        34.55833333331674
                    ],
                    [
                        -120.40000000029971,
                        34.42916666660841
                    ],
                    [
                        -120.2744444443556,
                        34.20277777747248
                    ],
                    [
                        -120.23607222255498,
                        34.133286110765425
                    ],
                    [
                        -120.21049166705677,
                        34.08707777761737
                    ],
                    [
                        -120.16249999987508,
                        33.999999999600334
                    ],
                    [
                        -120.02500000014987,
                        33.999999999600334
                    ],
                    [
                        -119.95000000029972,
                        33.999999999600334
                    ],
                    [
                        -119.25833333381627,
                        33.658333333316705
                    ],
                    [
                        -119.11666666713296,
                        33.47499999975025
                    ],
                    [
                        -118.95000000049959,
                        33.47499999975025
                    ],
                    [
                        -118.78333333386621,
                        33.47499999975025
                    ],
                    [
                        -118.7792305556456,
                        33.516730555370884
                    ],
                    [
                        -118.75029444433312,
                        33.52425555524974
                    ],
                    [
                        -118.71714166735404,
                        33.54482777728015
                    ],
                    [
                        -118.71231666698054,
                        33.58596666622873
                    ],
                    [
                        -118.76666666693308,
                        33.658333333316705
                    ],
                    [
                        -118.54115000057203,
                        33.658641666779545
                    ],
                    [
                        -118.54583333344158,
                        33.708333333216785
                    ],
                    [
                        -118.56666666733281,
                        33.75583333294202
                    ],
                    [
                        -118.61250000027474,
                        33.887499999825195
                    ],
                    [
                        -118.62500000024977,
                        33.95833333271719
                    ],
                    [
                        -118.71088888891109,
                        33.96872777786473
                    ],
                    [
                        -118.8286861113749,
                        33.982983332714696
                    ],
                    [
                        -118.94249999997498,
                        34.099999999400495
                    ],
                    [
                        -118.94536111122625,
                        34.14710555557696
                    ],
                    [
                        -118.95000000049959,
                        34.208333333116855
                    ],
                    [
                        -119.02668055626265,
                        34.29784722167199
                    ],
                    [
                        -118.90863611106766,
                        34.29588888856142
                    ],
                    [
                        -118.99444444475529,
                        34.38055555539461
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 634,
            "artcc": "zla",
            "sector": "14",
            "sectype": "low",
            "Shape_Length": 5.8292110965550465,
            "Shape_Area": 1.3082036906161085
        }
    },
    {
        "type": "Feature",
        "id": 635,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -119.63333333396616,
                        34.58333333326675
                    ],
                    [
                        -119.52930833381254,
                        34.73565277715397
                    ],
                    [
                        -119.30000000069941,
                        35.06249999927559
                    ],
                    [
                        -119.45416666735775,
                        35.16666666603385
                    ],
                    [
                        -119.47869444499258,
                        35.215813888300374
                    ],
                    [
                        -119.5250000002498,
                        35.308611110814184
                    ],
                    [
                        -119.6083333340161,
                        35.47499999935053
                    ],
                    [
                        -119.71277777797206,
                        35.68444444435568
                    ],
                    [
                        -119.91750000072443,
                        35.71972222220285
                    ],
                    [
                        -120.1166666669331,
                        35.749999999700265
                    ],
                    [
                        -120.23194444498012,
                        35.71666666673332
                    ],
                    [
                        -120.2880555558192,
                        35.69944444450556
                    ],
                    [
                        -120.47500000014986,
                        35.641666665983905
                    ],
                    [
                        -120.56249999997499,
                        35.61666666603384
                    ],
                    [
                        -120.85000000029976,
                        35.533333333166865
                    ],
                    [
                        -121.23722222232766,
                        35.37166666598392
                    ],
                    [
                        -121.04318611105015,
                        35.209697222249076
                    ],
                    [
                        -120.91472222225275,
                        35.10194444408086
                    ],
                    [
                        -121.25000000039967,
                        34.94999999950045
                    ],
                    [
                        -121.18333333356645,
                        34.84999999970029
                    ],
                    [
                        -121.04999999990002,
                        34.68333333306691
                    ],
                    [
                        -120.78361111156352,
                        34.322777777772274
                    ],
                    [
                        -120.50000000009987,
                        34.3999999997003
                    ],
                    [
                        -120.40000000029971,
                        34.42916666660841
                    ],
                    [
                        -120.2744444443556,
                        34.20277777747248
                    ],
                    [
                        -119.78194444498013,
                        34.3583333328171
                    ],
                    [
                        -119.63333333396616,
                        34.58333333326675
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 635,
            "artcc": "zla",
            "sector": "15",
            "sectype": "low",
            "Shape_Length": 5.955598402158,
            "Shape_Area": 1.9825134237097617
        }
    },
    {
        "type": "Feature",
        "id": 636,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -116.55916666720788,
                        36.849999999300564
                    ],
                    [
                        -117.00833333381627,
                        37.36666666613377
                    ],
                    [
                        -117.33333333316676,
                        37.19999999950045
                    ],
                    [
                        -116.92888888891105,
                        36.50000000000006
                    ],
                    [
                        -116.90000000009991,
                        36.450000000099976
                    ],
                    [
                        -116.75000000039967,
                        36.21666666663339
                    ],
                    [
                        -116.43333333316679,
                        35.6499999999001
                    ],
                    [
                        -115.8782962278886,
                        35.80661317865531
                    ],
                    [
                        -115.27347500047085,
                        35.76944722201176
                    ],
                    [
                        -115.03500000004993,
                        35.91083333259229
                    ],
                    [
                        -114.95833333341659,
                        35.940833332892055
                    ],
                    [
                        -115.2416666667832,
                        36.22083333269222
                    ],
                    [
                        -115.55611111103889,
                        36.258888888811214
                    ],
                    [
                        -115.30083333399114,
                        36.43333333316684
                    ],
                    [
                        -115.38416666685816,
                        36.43333333316684
                    ],
                    [
                        -115.6175000003247,
                        36.583333332867085
                    ],
                    [
                        -115.88416666675823,
                        36.583333332867085
                    ],
                    [
                        -115.93416666665831,
                        36.59999999980022
                    ],
                    [
                        -115.93416666665831,
                        36.683333332667246
                    ],
                    [
                        -116.44250000047458,
                        36.683333332667246
                    ],
                    [
                        -116.55916666720788,
                        36.849999999300564
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 636,
            "artcc": "zla",
            "sector": "16",
            "sectype": "low",
            "Shape_Length": 6.884285384552063,
            "Shape_Area": 1.6379712119957723
        }
    },
    {
        "type": "Feature",
        "id": 637,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -116.52916666690811,
                        35.141666666083836
                    ],
                    [
                        -116.6250277780095,
                        35.23543333258226
                    ],
                    [
                        -116.70499999995002,
                        35.173611110364504
                    ],
                    [
                        -116.79583333334165,
                        35.11666666613377
                    ],
                    [
                        -116.8119444449801,
                        35.147222221728214
                    ],
                    [
                        -116.97888888881113,
                        35.10805555501992
                    ],
                    [
                        -117.14583333354147,
                        34.93888888821169
                    ],
                    [
                        -117.19722222212783,
                        34.89166666658343
                    ],
                    [
                        -117.5333333336664,
                        34.83888888841153
                    ],
                    [
                        -117.5333333336664,
                        34.808333332817085
                    ],
                    [
                        -117.58333333356643,
                        34.79999999980021
                    ],
                    [
                        -118.01666666663334,
                        34.79999999980021
                    ],
                    [
                        -118.09583333344153,
                        34.827777777122776
                    ],
                    [
                        -118.09583333344153,
                        34.79999999980021
                    ],
                    [
                        -118.23333333316674,
                        34.84999999970029
                    ],
                    [
                        -118.29166666698308,
                        34.89166666658343
                    ],
                    [
                        -118.40833333371637,
                        34.777777777222695
                    ],
                    [
                        -118.35416666685813,
                        34.654166666158744
                    ],
                    [
                        -118.33333333386622,
                        34.6208333331918
                    ],
                    [
                        -118.24906388948688,
                        34.44958888891614
                    ],
                    [
                        -118.06666666653342,
                        34.44999999960038
                    ],
                    [
                        -117.84999999999997,
                        34.45833333261726
                    ],
                    [
                        -117.8308333337913,
                        34.45833333261726
                    ],
                    [
                        -117.67816944506382,
                        34.46175555517476
                    ],
                    [
                        -117.61749999992503,
                        34.46249999957536
                    ],
                    [
                        -117.31426666707301,
                        34.6322888884215
                    ],
                    [
                        -116.95416666695809,
                        34.56666666633362
                    ],
                    [
                        -116.81250000027478,
                        34.67083333309188
                    ],
                    [
                        -116.7208333334915,
                        34.69166666608385
                    ],
                    [
                        -116.4650000002498,
                        34.6974999998252
                    ],
                    [
                        -116.43972222220276,
                        34.71666666603386
                    ],
                    [
                        -116.28416666685814,
                        34.71666666603386
                    ],
                    [
                        -116.10417499997624,
                        34.691608333357976
                    ],
                    [
                        -116.09577500021607,
                        34.716586111060224
                    ],
                    [
                        -116.00001944434183,
                        34.7163861108304
                    ],
                    [
                        -115.89089722262867,
                        34.716124999868896
                    ],
                    [
                        -115.83036111097647,
                        34.71591111050941
                    ],
                    [
                        -116.1505361117521,
                        35.04385555498993
                    ],
                    [
                        -116.38444444475527,
                        34.99972222220282
                    ],
                    [
                        -116.52916666690811,
                        35.141666666083836
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 637,
            "artcc": "zla",
            "sector": "17",
            "sectype": "low",
            "Shape_Length": 5.999427941755288,
            "Shape_Area": 0.8716563805020712
        }
    },
    {
        "type": "Feature",
        "id": 638,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -117.63277777817189,
                        34.12583333274216
                    ],
                    [
                        -117.57726666712296,
                        34.150291666527266
                    ],
                    [
                        -117.52638888933575,
                        34.17249999997506
                    ],
                    [
                        -117.38750000002494,
                        34.23333333306692
                    ],
                    [
                        -117.36330555610647,
                        34.251830555635706
                    ],
                    [
                        -117.25833333331667,
                        34.331944444180806
                    ],
                    [
                        -117.2333333333666,
                        34.34999999980022
                    ],
                    [
                        -117.20088055629259,
                        34.38825833325552
                    ],
                    [
                        -117.18033055561062,
                        34.403316666131275
                    ],
                    [
                        -116.95416666695809,
                        34.56666666633362
                    ],
                    [
                        -117.31426666707301,
                        34.6322888884215
                    ],
                    [
                        -117.61749999992503,
                        34.46249999957536
                    ],
                    [
                        -117.67816944506382,
                        34.46175555517476
                    ],
                    [
                        -117.8308333337913,
                        34.45833333261726
                    ],
                    [
                        -117.84999999999997,
                        34.45833333261726
                    ],
                    [
                        -118.06666666653342,
                        34.44999999960038
                    ],
                    [
                        -118.24906388948688,
                        34.44958888891614
                    ],
                    [
                        -118.30081944426189,
                        34.308791666102536
                    ],
                    [
                        -118.3749999998501,
                        34.099999999400495
                    ],
                    [
                        -118.40416666675821,
                        34.099999999400495
                    ],
                    [
                        -118.44583333364142,
                        34.099999999400495
                    ],
                    [
                        -118.47040277776597,
                        34.086694444293244
                    ],
                    [
                        -118.59166666728282,
                        34.020833332592304
                    ],
                    [
                        -118.6333333332667,
                        33.9833333326672
                    ],
                    [
                        -118.62500000024977,
                        33.95833333271719
                    ],
                    [
                        -118.61250000027474,
                        33.887499999825195
                    ],
                    [
                        -118.56666666733281,
                        33.75583333294202
                    ],
                    [
                        -118.43333333366638,
                        33.783333333066935
                    ],
                    [
                        -118.40181666720537,
                        33.7906972220992
                    ],
                    [
                        -118.35831944468657,
                        33.80087499988139
                    ],
                    [
                        -118.34444444425566,
                        33.80416666605885
                    ],
                    [
                        -118.25000000009987,
                        33.86666666683328
                    ],
                    [
                        -118.21666666713293,
                        33.86666666683328
                    ],
                    [
                        -118.09860277769599,
                        33.91396666632863
                    ],
                    [
                        -118.05000000049961,
                        33.93333333276712
                    ],
                    [
                        -118.01605000060698,
                        33.94973333272719
                    ],
                    [
                        -117.89903888903348,
                        34.0061944444181
                    ],
                    [
                        -117.81666666703302,
                        34.045833333441635
                    ],
                    [
                        -117.73611111153855,
                        34.07972222170321
                    ],
                    [
                        -117.73255555549446,
                        34.0513833330744
                    ],
                    [
                        -117.70111111178835,
                        34.078333333016985
                    ],
                    [
                        -117.63277777817189,
                        34.12583333274216
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 638,
            "artcc": "zla",
            "sector": "18",
            "sectype": "low",
            "Shape_Length": 4.116370471853831,
            "Shape_Area": 0.5844385819070024
        }
    },
    {
        "type": "Feature",
        "id": 639,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -116.34138888918585,
                        34.32499999985015
                    ],
                    [
                        -116.43972222220276,
                        34.4999999995004
                    ],
                    [
                        -116.46750000042465,
                        34.599999999300564
                    ],
                    [
                        -116.49527777774722,
                        34.675000000050034
                    ],
                    [
                        -116.4650000002498,
                        34.6974999998252
                    ],
                    [
                        -116.7208333334915,
                        34.69166666608385
                    ],
                    [
                        -116.81250000027478,
                        34.67083333309188
                    ],
                    [
                        -116.95416666695809,
                        34.56666666633362
                    ],
                    [
                        -117.18033055561062,
                        34.403316666131275
                    ],
                    [
                        -117.20088055629259,
                        34.38825833325552
                    ],
                    [
                        -117.2333333333666,
                        34.34999999980022
                    ],
                    [
                        -117.25833333331667,
                        34.331944444180806
                    ],
                    [
                        -117.36330555610647,
                        34.251830555635706
                    ],
                    [
                        -117.24656666661832,
                        34.20034444404092
                    ],
                    [
                        -117.16754444442051,
                        34.21910555557696
                    ],
                    [
                        -116.99926666662333,
                        34.25905000005753
                    ],
                    [
                        -116.88333333316677,
                        34.16666666623371
                    ],
                    [
                        -116.85416666715793,
                        33.9413888885864
                    ],
                    [
                        -116.854452778373,
                        33.933069443799866
                    ],
                    [
                        -117.01413055618019,
                        33.90964166652975
                    ],
                    [
                        -117.00565833366511,
                        33.79558611121013
                    ],
                    [
                        -116.83476944445425,
                        33.776261110361986
                    ],
                    [
                        -116.4625000000749,
                        33.73750000012495
                    ],
                    [
                        -116.37500000024977,
                        33.7249999992506
                    ],
                    [
                        -116.30833333341656,
                        33.71666666623372
                    ],
                    [
                        -116.20000000059952,
                        33.69999999930059
                    ],
                    [
                        -116.13978888954557,
                        33.77842499971399
                    ],
                    [
                        -116.15583333354147,
                        34.03499999935053
                    ],
                    [
                        -116.24333888937821,
                        34.099999999400495
                    ],
                    [
                        -116.26123611125752,
                        34.1133666661388
                    ],
                    [
                        -116.31027777829678,
                        34.14972222210292
                    ],
                    [
                        -116.28416666685814,
                        34.23333333306692
                    ],
                    [
                        -116.34138888918585,
                        34.32499999985015
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 639,
            "artcc": "zla",
            "sector": "19",
            "sectype": "low",
            "Shape_Length": 4.06216518713394,
            "Shape_Area": 0.6690184514032208
        }
    },
    {
        "type": "Feature",
        "id": 640,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -116.28416666685814,
                        34.23333333306692
                    ],
                    [
                        -116.34138888918585,
                        34.32499999985015
                    ],
                    [
                        -116.43972222220276,
                        34.4999999995004
                    ],
                    [
                        -116.46750000042465,
                        34.599999999300564
                    ],
                    [
                        -116.49527777774722,
                        34.675000000050034
                    ],
                    [
                        -116.61666666673324,
                        34.56666666633362
                    ],
                    [
                        -116.91908055622264,
                        34.291055555069875
                    ],
                    [
                        -116.99926666662333,
                        34.25905000005753
                    ],
                    [
                        -117.16754444442051,
                        34.21910555557696
                    ],
                    [
                        -117.24656666661832,
                        34.20034444404092
                    ],
                    [
                        -117.36330555610647,
                        34.251830555635706
                    ],
                    [
                        -117.38750000002494,
                        34.23333333306692
                    ],
                    [
                        -117.52638888933575,
                        34.17249999997506
                    ],
                    [
                        -117.57726666712296,
                        34.150291666527266
                    ],
                    [
                        -117.63277777817189,
                        34.12583333274216
                    ],
                    [
                        -117.70111111178835,
                        34.078333333016985
                    ],
                    [
                        -117.73255555549446,
                        34.0513833330744
                    ],
                    [
                        -117.73251111099893,
                        34.05076944415458
                    ],
                    [
                        -117.72916666720789,
                        34.00416666655849
                    ],
                    [
                        -117.71653055574052,
                        33.92405833312563
                    ],
                    [
                        -117.73658333385373,
                        33.90297222229026
                    ],
                    [
                        -117.7500000001998,
                        33.888888888511474
                    ],
                    [
                        -117.70833333331666,
                        33.85833333291703
                    ],
                    [
                        -117.60000000049956,
                        33.81666666603388
                    ],
                    [
                        -117.5333333336664,
                        33.81666666603388
                    ],
                    [
                        -117.51388888936071,
                        33.81472222205298
                    ],
                    [
                        -117.31972222260248,
                        33.81222222187807
                    ],
                    [
                        -117.25708888924083,
                        33.808972222190334
                    ],
                    [
                        -117.2333333333666,
                        33.80833333301695
                    ],
                    [
                        -117.20452777843417,
                        33.803638889023546
                    ],
                    [
                        -117.17633055551067,
                        33.79914444436065
                    ],
                    [
                        -117.10416666665833,
                        33.7976416664298
                    ],
                    [
                        -117.00565833366511,
                        33.79558611121013
                    ],
                    [
                        -116.83476944445425,
                        33.776261110361986
                    ],
                    [
                        -116.84583333324173,
                        33.84999999990015
                    ],
                    [
                        -116.854452778373,
                        33.933069443799866
                    ],
                    [
                        -116.56233611102266,
                        33.968908332953276
                    ],
                    [
                        -116.35407222225524,
                        34.00262500014367
                    ],
                    [
                        -116.31666666733281,
                        34.109444443906
                    ],
                    [
                        -116.31027777829678,
                        34.14972222210292
                    ],
                    [
                        -116.28416666685814,
                        34.23333333306692
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 640,
            "artcc": "zla",
            "sector": "20",
            "sectype": "low",
            "Shape_Length": 3.9723558518345774,
            "Shape_Area": 0.6360157525423915
        }
    },
    {
        "type": "Feature",
        "id": 641,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -117.70833333331666,
                        33.85833333291703
                    ],
                    [
                        -117.7500000001998,
                        33.888888888511474
                    ],
                    [
                        -117.73658333385373,
                        33.90297222229026
                    ],
                    [
                        -117.71653055574052,
                        33.92405833312563
                    ],
                    [
                        -117.72916666720789,
                        34.00416666655849
                    ],
                    [
                        -117.73251111099893,
                        34.05076944415458
                    ],
                    [
                        -117.73255555549446,
                        34.0513833330744
                    ],
                    [
                        -117.73611111153855,
                        34.07972222170321
                    ],
                    [
                        -117.81666666703302,
                        34.045833333441635
                    ],
                    [
                        -117.89903888903348,
                        34.0061944444181
                    ],
                    [
                        -118.01605000060698,
                        33.94973333272719
                    ],
                    [
                        -118.05000000049961,
                        33.93333333276712
                    ],
                    [
                        -118.09860277769599,
                        33.91396666632863
                    ],
                    [
                        -118.21666666713293,
                        33.86666666683328
                    ],
                    [
                        -118.25000000009987,
                        33.86666666683328
                    ],
                    [
                        -118.34444444425566,
                        33.80416666605885
                    ],
                    [
                        -118.35831944468657,
                        33.80087499988139
                    ],
                    [
                        -118.40181666720537,
                        33.7906972220992
                    ],
                    [
                        -118.43333333366638,
                        33.783333333066935
                    ],
                    [
                        -118.56666666733281,
                        33.75583333294202
                    ],
                    [
                        -118.54583333344158,
                        33.708333333216785
                    ],
                    [
                        -118.45000000059952,
                        33.61666666643356
                    ],
                    [
                        -118.44583333364142,
                        33.53333333266721
                    ],
                    [
                        -118.22500000014986,
                        33.5166666666334
                    ],
                    [
                        -118.06666666653342,
                        33.499999999700265
                    ],
                    [
                        -118.05263611126747,
                        33.49938888878626
                    ],
                    [
                        -117.78679444510755,
                        33.487830555435835
                    ],
                    [
                        -117.68333333336659,
                        33.48333333276713
                    ],
                    [
                        -117.58333333356643,
                        33.48333333276713
                    ],
                    [
                        -117.43121666701552,
                        33.33766944398968
                    ],
                    [
                        -117.38194444438057,
                        33.37499999995009
                    ],
                    [
                        -117.2791666672079,
                        33.45833333281712
                    ],
                    [
                        -117.2333333333666,
                        33.499999999700265
                    ],
                    [
                        -117.23330833401235,
                        33.65131111111896
                    ],
                    [
                        -117.2333333333666,
                        33.80833333301695
                    ],
                    [
                        -117.25708888924083,
                        33.808972222190334
                    ],
                    [
                        -117.31972222260248,
                        33.81222222187807
                    ],
                    [
                        -117.51388888936071,
                        33.81472222205298
                    ],
                    [
                        -117.5333333336664,
                        33.81666666603388
                    ],
                    [
                        -117.60000000049956,
                        33.81666666603388
                    ],
                    [
                        -117.70833333331666,
                        33.85833333291703
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 641,
            "artcc": "zla",
            "sector": "21",
            "sectype": "low",
            "Shape_Length": 3.5474265723336234,
            "Shape_Area": 0.5028158842345014
        }
    },
    {
        "type": "Feature",
        "id": 642,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -117.78679444510755,
                        33.487830555435835
                    ],
                    [
                        -118.05263611126747,
                        33.49938888878626
                    ],
                    [
                        -118.06666666653342,
                        33.499999999700265
                    ],
                    [
                        -118.22500000014986,
                        33.5166666666334
                    ],
                    [
                        -118.21666666713293,
                        33.86666666683328
                    ],
                    [
                        -118.2527888894956,
                        33.887944443881054
                    ],
                    [
                        -118.28750000002492,
                        33.90833333281711
                    ],
                    [
                        -118.33388888916085,
                        33.975983332764656
                    ],
                    [
                        -118.35416666685813,
                        34.00555555524471
                    ],
                    [
                        -118.40289444442305,
                        34.086561110806656
                    ],
                    [
                        -118.40416666675821,
                        34.099999999400495
                    ],
                    [
                        -118.44583333364142,
                        34.099999999400495
                    ],
                    [
                        -118.47040277776597,
                        34.086694444293244
                    ],
                    [
                        -118.59166666728282,
                        34.020833332592304
                    ],
                    [
                        -118.6333333332667,
                        33.9833333326672
                    ],
                    [
                        -118.62500000024977,
                        33.95833333271719
                    ],
                    [
                        -118.61250000027474,
                        33.887499999825195
                    ],
                    [
                        -118.56666666733281,
                        33.75583333294202
                    ],
                    [
                        -118.6333333332667,
                        33.75583333294202
                    ],
                    [
                        -118.72083333399115,
                        33.75583333294202
                    ],
                    [
                        -118.83333333376629,
                        33.75583333294202
                    ],
                    [
                        -118.76666666693308,
                        33.658333333316705
                    ],
                    [
                        -118.71231666698054,
                        33.58596666622873
                    ],
                    [
                        -118.71714166735404,
                        33.54482777728015
                    ],
                    [
                        -118.75029444433312,
                        33.52425555524974
                    ],
                    [
                        -118.7792305556456,
                        33.516730555370884
                    ],
                    [
                        -118.78333333386621,
                        33.47499999975025
                    ],
                    [
                        -118.61666666723289,
                        33.32500000005001
                    ],
                    [
                        -118.49166666658334,
                        33.21666666633365
                    ],
                    [
                        -118.41750000012485,
                        33.27638888883621
                    ],
                    [
                        -118.25000000009987,
                        33.2499999993006
                    ],
                    [
                        -117.98000000009989,
                        33.208333333316716
                    ],
                    [
                        -117.86854444507003,
                        33.08151666616129
                    ],
                    [
                        -117.695277778047,
                        32.8833333330669
                    ],
                    [
                        -117.65000000039964,
                        32.79999999930055
                    ],
                    [
                        -117.38750000002494,
                        32.93888888861136
                    ],
                    [
                        -117.2333333333666,
                        32.999999999800195
                    ],
                    [
                        -117.13888888921082,
                        33.133333332567304
                    ],
                    [
                        -117.26388888896105,
                        33.14999999950044
                    ],
                    [
                        -117.29286666676325,
                        33.19124722168198
                    ],
                    [
                        -117.3458333331418,
                        33.26666666623373
                    ],
                    [
                        -117.43121666701552,
                        33.33766944398968
                    ],
                    [
                        -117.58333333356643,
                        33.48333333276713
                    ],
                    [
                        -117.68333333336659,
                        33.48333333276713
                    ],
                    [
                        -117.78679444510755,
                        33.487830555435835
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 642,
            "artcc": "zla",
            "sector": "22",
            "sectype": "low",
            "Shape_Length": 5.101091586164398,
            "Shape_Area": 0.7586524967108289
        }
    },
    {
        "type": "Feature",
        "id": 646,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -121.04999999990002,
                        34.68333333306691
                    ],
                    [
                        -121.18333333356645,
                        34.84999999970029
                    ],
                    [
                        -121.25000000039967,
                        34.94999999950045
                    ],
                    [
                        -120.91472222225275,
                        35.10194444408086
                    ],
                    [
                        -121.04318611105015,
                        35.209697222249076
                    ],
                    [
                        -121.23722222232766,
                        35.37166666598392
                    ],
                    [
                        -123.24999999999994,
                        34.4999999995004
                    ],
                    [
                        -125.00000000009987,
                        34.16666666623371
                    ],
                    [
                        -124.50777777792206,
                        33.213888888961094
                    ],
                    [
                        -124.01500000044962,
                        32.241666666483525
                    ],
                    [
                        -123.46333333386622,
                        31.131666666183776
                    ],
                    [
                        -122.44166666678319,
                        29.999999999500403
                    ],
                    [
                        -120.83333333336662,
                        30.749999999800195
                    ],
                    [
                        -120.66666666673325,
                        30.833333332667223
                    ],
                    [
                        -119.69999999990006,
                        31.83333333336668
                    ],
                    [
                        -119.69999999990006,
                        32.19999999960038
                    ],
                    [
                        -119.16666666703304,
                        32.683333332567315
                    ],
                    [
                        -119.11666666713296,
                        32.741666666383594
                    ],
                    [
                        -118.7097222227024,
                        33.055555555344654
                    ],
                    [
                        -118.60694444463036,
                        33.133333332567304
                    ],
                    [
                        -118.49166666658334,
                        33.21666666633365
                    ],
                    [
                        -118.61666666723289,
                        33.32500000005001
                    ],
                    [
                        -118.78333333386621,
                        33.47499999975025
                    ],
                    [
                        -118.95000000049959,
                        33.47499999975025
                    ],
                    [
                        -119.11666666713296,
                        33.47499999975025
                    ],
                    [
                        -119.25833333381627,
                        33.658333333316705
                    ],
                    [
                        -119.95000000029972,
                        33.999999999600334
                    ],
                    [
                        -120.02500000014987,
                        33.999999999600334
                    ],
                    [
                        -120.16249999987508,
                        33.999999999600334
                    ],
                    [
                        -120.21049166705677,
                        34.08707777761737
                    ],
                    [
                        -120.23607222255498,
                        34.133286110765425
                    ],
                    [
                        -120.2744444443556,
                        34.20277777747248
                    ],
                    [
                        -120.40000000029971,
                        34.42916666660841
                    ],
                    [
                        -120.50000000009987,
                        34.3999999997003
                    ],
                    [
                        -120.78361111156352,
                        34.322777777772274
                    ],
                    [
                        -121.04999999990002,
                        34.68333333306691
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 646,
            "artcc": "zla",
            "sector": "28",
            "sectype": "low",
            "Shape_Length": 18.599193759577556,
            "Shape_Area": 18.50611978870392
        }
    },
    {
        "type": "Feature",
        "id": 678,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -111.63333333286698,
                        41.19999999960038
                    ],
                    [
                        -111.6249999998501,
                        40.46777777772229
                    ],
                    [
                        -111.51666666703301,
                        40.400000000299826
                    ],
                    [
                        -110.58333333316676,
                        40.36388888906106
                    ],
                    [
                        -109.15000000029971,
                        40.23333333366645
                    ],
                    [
                        -109.10000000039969,
                        40.850000000299815
                    ],
                    [
                        -110.7416666667832,
                        41.32083333329177
                    ],
                    [
                        -111.63333333286698,
                        41.19999999960038
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 678,
            "artcc": "zlc",
            "sector": "03",
            "sectype": "low",
            "Shape_Length": 6.459714471860285,
            "Shape_Area": 2.0402361099525597
        }
    },
    {
        "type": "Feature",
        "id": 680,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -110.58333333316676,
                        40.36388888906106
                    ],
                    [
                        -109.81666666683316,
                        39.76388888846151
                    ],
                    [
                        -109.16666666633353,
                        40.00000000019992
                    ],
                    [
                        -109.15000000029971,
                        40.23333333366645
                    ],
                    [
                        -110.58333333316676,
                        40.36388888906106
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 680,
            "artcc": "zlc",
            "sector": "04",
            "sectype": "low",
            "Shape_Length": 3.3382886018200963,
            "Shape_Area": 0.4538194449887397
        }
    },
    {
        "type": "Feature",
        "id": 682,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -107.13333333286698,
                        42.94999999970025
                    ],
                    [
                        -109.66666666713297,
                        42.3999999999001
                    ],
                    [
                        -110.93333333336659,
                        42.09999999960036
                    ],
                    [
                        -110.7416666667832,
                        41.32083333329177
                    ],
                    [
                        -109.10000000039969,
                        40.850000000299815
                    ],
                    [
                        -108.2750000002498,
                        41.36666666713302
                    ],
                    [
                        -108.0722222223776,
                        41.53749999982517
                    ],
                    [
                        -107.99999999990001,
                        41.608333333616486
                    ],
                    [
                        -107.04999999999995,
                        42.41666666683324
                    ],
                    [
                        -107.06666666693309,
                        42.48333333366645
                    ],
                    [
                        -107.07361111126374,
                        42.54305555526969
                    ],
                    [
                        -107.13333333286698,
                        42.94999999970025
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 682,
            "artcc": "zlc",
            "sector": "05",
            "sectype": "low",
            "Shape_Length": 9.531546866543387,
            "Shape_Area": 4.504959489305479
        }
    },
    {
        "type": "Feature",
        "id": 684,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -111.31944444480524,
                        45.12722222192804
                    ],
                    [
                        -111.47888888901099,
                        45.15694444413083
                    ],
                    [
                        -111.62861111151358,
                        45.20555555534463
                    ],
                    [
                        -111.76444444445553,
                        45.27138888878625
                    ],
                    [
                        -111.8822222226774,
                        45.353055554870025
                    ],
                    [
                        -111.97777777832175,
                        45.446666666533474
                    ],
                    [
                        -112.04999999990002,
                        45.551388888586416
                    ],
                    [
                        -112.0952777784467,
                        45.66444444365618
                    ],
                    [
                        -112.10388888956055,
                        45.72749999972524
                    ],
                    [
                        -111.99638888923579,
                        46.10222222177816
                    ],
                    [
                        -111.98861111151353,
                        46.114166666458516
                    ],
                    [
                        -111.94222222237761,
                        46.16083333279215
                    ],
                    [
                        -111.9111111114886,
                        46.19222222177814
                    ],
                    [
                        -111.88972222230268,
                        46.19249999987517
                    ],
                    [
                        -111.78777777852162,
                        46.206388888536424
                    ],
                    [
                        -111.68972222270236,
                        46.23222222187809
                    ],
                    [
                        -111.60138888948563,
                        46.268611111213886
                    ],
                    [
                        -112.38694444483025,
                        46.31777777772231
                    ],
                    [
                        -112.91166666658336,
                        46.34805555521973
                    ],
                    [
                        -113.93333333366638,
                        46.3833333330669
                    ],
                    [
                        -115.00000000029974,
                        46.41666666603385
                    ],
                    [
                        -115.00000000029974,
                        45.33333333336668
                    ],
                    [
                        -114.36666666673324,
                        44.70416666675834
                    ],
                    [
                        -114.15833333321672,
                        44.494999999850165
                    ],
                    [
                        -110.62861111171338,
                        44.16722222222785
                    ],
                    [
                        -110.3655361118021,
                        44.538577777342596
                    ],
                    [
                        -110.03333333336661,
                        45.00000000009999
                    ],
                    [
                        -110.02027777809695,
                        45.47694444403095
                    ],
                    [
                        -110.33250000027476,
                        45.44777777712278
                    ],
                    [
                        -110.42888888931077,
                        45.353055554870025
                    ],
                    [
                        -110.49222222257748,
                        45.30944444400592
                    ],
                    [
                        -110.54666666663331,
                        45.27138888878625
                    ],
                    [
                        -110.68250000047459,
                        45.20555555534463
                    ],
                    [
                        -110.83222222207786,
                        45.15694444413083
                    ],
                    [
                        -110.99166666718293,
                        45.12722222192804
                    ],
                    [
                        -111.15555555554442,
                        45.11722222212791
                    ],
                    [
                        -111.31944444480524,
                        45.12722222192804
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 684,
            "artcc": "zlc",
            "sector": "06",
            "sectype": "low",
            "Shape_Length": 14.005690318201706,
            "Shape_Area": 7.274793440666647
        }
    },
    {
        "type": "Feature",
        "id": 686,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -110.7416666667832,
                        41.32083333329177
                    ],
                    [
                        -110.93333333336659,
                        42.09999999960036
                    ],
                    [
                        -111.71666666663333,
                        42.26666666623373
                    ],
                    [
                        -112.17500000054957,
                        42.35833333301696
                    ],
                    [
                        -112.13083333349152,
                        41.62555555494498
                    ],
                    [
                        -112.127777778022,
                        41.54722222152833
                    ],
                    [
                        -112.09222222207785,
                        41.545277777547426
                    ],
                    [
                        -112.0041666669581,
                        41.48166666618374
                    ],
                    [
                        -111.6333333337663,
                        41.19999999960038
                    ],
                    [
                        -110.7416666667832,
                        41.32083333329177
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 686,
            "artcc": "zlc",
            "sector": "07",
            "sectype": "low",
            "Shape_Length": 4.392901842467902,
            "Shape_Area": 1.19583398935473
        }
    },
    {
        "type": "Feature",
        "id": 688,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -114.15833333321672,
                        44.494999999850165
                    ],
                    [
                        -113.91666666673325,
                        44.249999999800195
                    ],
                    [
                        -113.56666666653342,
                        43.52500000034979
                    ],
                    [
                        -113.08333333356643,
                        42.53333333356653
                    ],
                    [
                        -112.17499999965025,
                        42.35833333301696
                    ],
                    [
                        -111.71666666663333,
                        42.26666666713305
                    ],
                    [
                        -110.93333333336659,
                        42.09999999960036
                    ],
                    [
                        -109.66666666713297,
                        42.3999999999001
                    ],
                    [
                        -110.00000000039967,
                        42.883333332867096
                    ],
                    [
                        -110.66666666693311,
                        43.08333333336668
                    ],
                    [
                        -111.13333333296691,
                        43.44999999960038
                    ],
                    [
                        -110.62861111081406,
                        44.16722222222785
                    ],
                    [
                        -114.15833333321672,
                        44.494999999850165
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 688,
            "artcc": "zlc",
            "sector": "08",
            "sectype": "low",
            "Shape_Length": 12.04595847196477,
            "Shape_Area": 6.036002082987558
        }
    },
    {
        "type": "Feature",
        "id": 690,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -111.82622222217776,
                        39.87808888929084
                    ],
                    [
                        -112.0834416669993,
                        39.99812222206293
                    ],
                    [
                        -112.19999999960027,
                        40.17416666645852
                    ],
                    [
                        -112.58333333366636,
                        40.33333333346661
                    ],
                    [
                        -112.53416666625861,
                        39.983333333266785
                    ],
                    [
                        -112.60083333309183,
                        39.78333333366646
                    ],
                    [
                        -112.89583333304188,
                        39.10527777774729
                    ],
                    [
                        -111.68333333366638,
                        39.333333333666474
                    ],
                    [
                        -111.82622222217776,
                        39.87808888929084
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 690,
            "artcc": "zlc",
            "sector": "11",
            "sectype": "low",
            "Shape_Length": 4.010694625114639,
            "Shape_Area": 0.8021513827008772
        }
    },
    {
        "type": "Feature",
        "id": 692,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -112.13083333349152,
                        41.62555555494498
                    ],
                    [
                        -112.17500000054957,
                        42.35833333301696
                    ],
                    [
                        -113.08333333356643,
                        42.53333333266721
                    ],
                    [
                        -113.54166666658335,
                        42.14999999950044
                    ],
                    [
                        -113.60000000039969,
                        42.09999999960036
                    ],
                    [
                        -113.25083333359146,
                        41.87500000005002
                    ],
                    [
                        -112.61361111116383,
                        41.458888888311606
                    ],
                    [
                        -112.53916666660837,
                        41.40972222180312
                    ],
                    [
                        -112.31694444443053,
                        41.54749999962536
                    ],
                    [
                        -112.16916666680817,
                        41.54861111111393
                    ],
                    [
                        -112.127777778022,
                        41.54722222152833
                    ],
                    [
                        -112.13083333349152,
                        41.62555555494498
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 692,
            "artcc": "zlc",
            "sector": "14",
            "sectype": "low",
            "Shape_Length": 4.128185560881507,
            "Shape_Area": 1.0273051306436507
        }
    },
    {
        "type": "Feature",
        "id": 694,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -109.56666666643349,
                        46.633333333466624
                    ],
                    [
                        -110.23333333296694,
                        46.633333333466624
                    ],
                    [
                        -110.46666666643347,
                        46.0805555553946
                    ],
                    [
                        -110.00000000039967,
                        45.88333333316683
                    ],
                    [
                        -110.02027777809695,
                        45.47694444403095
                    ],
                    [
                        -110.03333333336661,
                        45.00000000009999
                    ],
                    [
                        -110.36553611090278,
                        44.538577778241915
                    ],
                    [
                        -107.28333333346654,
                        43.883333333566554
                    ],
                    [
                        -106.26666666673327,
                        44.31666666663341
                    ],
                    [
                        -106.14166666698304,
                        44.7666666666334
                    ],
                    [
                        -106.00000000029974,
                        45.237499999625356
                    ],
                    [
                        -107.62394166677444,
                        45.629288889071006
                    ],
                    [
                        -107.64143611108761,
                        45.58539444409837
                    ],
                    [
                        -107.71285000042712,
                        45.48064166667962
                    ],
                    [
                        -107.80875277801823,
                        45.38611666665088
                    ],
                    [
                        -107.92618055538827,
                        45.30464166677956
                    ],
                    [
                        -108.06156944427443,
                        45.238627778249395
                    ],
                    [
                        -108.21087222207535,
                        45.19003333327174
                    ],
                    [
                        -108.36965833286575,
                        45.160286110815434
                    ],
                    [
                        -108.53325833290569,
                        45.15026666677329
                    ],
                    [
                        -108.69685833294568,
                        45.160269444579285
                    ],
                    [
                        -108.85566111087155,
                        45.189999999900124
                    ],
                    [
                        -109.00498055580795,
                        45.2385833328546
                    ],
                    [
                        -109.1403972220541,
                        45.30458888916593
                    ],
                    [
                        -109.25784999967772,
                        45.3860638890373
                    ],
                    [
                        -109.3537777775224,
                        45.48059444417834
                    ],
                    [
                        -109.42521111110386,
                        45.58536111072675
                    ],
                    [
                        -109.46986944471905,
                        45.69722500023363
                    ],
                    [
                        -109.4862527775436,
                        45.81281944396221
                    ],
                    [
                        -109.47368888883113,
                        45.92864444418581
                    ],
                    [
                        -109.43238888903596,
                        46.04116666710803
                    ],
                    [
                        -109.3634472225113,
                        46.14694444413084
                    ],
                    [
                        -109.26884722262122,
                        46.242711111128926
                    ],
                    [
                        -109.15139444409829,
                        46.3255055558368
                    ],
                    [
                        -109.01465277790334,
                        46.3927500002124
                    ],
                    [
                        -108.86283611079784,
                        46.44234444454054
                    ],
                    [
                        -108.75749722181928,
                        46.462094444303204
                    ],
                    [
                        -108.78333333316675,
                        46.633333333466624
                    ],
                    [
                        -109.56666666643349,
                        46.633333333466624
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 694,
            "artcc": "zlc",
            "sector": "15",
            "sectype": "low",
            "Shape_Length": 14.487579333500289,
            "Shape_Area": 5.905833837319584
        }
    },
    {
        "type": "Feature",
        "id": 696,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -107.28333333346654,
                        43.883333333566554
                    ],
                    [
                        -110.36553611090278,
                        44.538577778241915
                    ],
                    [
                        -110.62861111081406,
                        44.16722222222785
                    ],
                    [
                        -111.13333333296691,
                        43.44999999960038
                    ],
                    [
                        -110.66666666693311,
                        43.08333333336668
                    ],
                    [
                        -110.00000000039967,
                        42.883333332867096
                    ],
                    [
                        -109.66666666713297,
                        42.3999999999001
                    ],
                    [
                        -107.13333333286698,
                        42.94999999970025
                    ],
                    [
                        -107.28333333346654,
                        43.883333333566554
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 696,
            "artcc": "zlc",
            "sector": "16",
            "sectype": "low",
            "Shape_Length": 9.897486596907333,
            "Shape_Area": 5.388393187925077
        }
    },
    {
        "type": "Feature",
        "id": 698,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -103.16666666663332,
                        46.449999999900115
                    ],
                    [
                        -103.16666666663332,
                        47.25000000009999
                    ],
                    [
                        -103.16666666663332,
                        47.84611111093909
                    ],
                    [
                        -103.16666666663332,
                        49.00000000019992
                    ],
                    [
                        -103.67500000044964,
                        49.00000000019992
                    ],
                    [
                        -104.49999999970021,
                        49.00000000019992
                    ],
                    [
                        -104.99999999960028,
                        49.00000000019992
                    ],
                    [
                        -106.5000000001998,
                        49.00000000019992
                    ],
                    [
                        -107.99999999990001,
                        49.00000000019992
                    ],
                    [
                        -109.18333333326666,
                        49.00000000019992
                    ],
                    [
                        -108.86111111128872,
                        47.16666666633364
                    ],
                    [
                        -108.84143888849388,
                        47.032811110744206
                    ],
                    [
                        -108.78333333316675,
                        46.633333333466624
                    ],
                    [
                        -108.75749722181928,
                        46.462094444303204
                    ],
                    [
                        -108.70067777795703,
                        46.47273333327678
                    ],
                    [
                        -108.62418333329418,
                        46.47775277786843
                    ],
                    [
                        -108.5332555557992,
                        46.4829638886726
                    ],
                    [
                        -108.36583888875367,
                        46.47271388903482
                    ],
                    [
                        -108.20369166703676,
                        46.44230833316311
                    ],
                    [
                        -108.05189722217904,
                        46.392702777711065
                    ],
                    [
                        -107.91518333334415,
                        46.325452778223166
                    ],
                    [
                        -107.7977555559741,
                        46.24265833351535
                    ],
                    [
                        -107.70318055543822,
                        46.14689722252888
                    ],
                    [
                        -107.63426111116132,
                        46.04113333373641
                    ],
                    [
                        -107.59297222249006,
                        45.92862499994385
                    ],
                    [
                        -107.58041388888984,
                        45.81281944396221
                    ],
                    [
                        -107.59679166660209,
                        45.69724444447559
                    ],
                    [
                        -107.62394166677444,
                        45.629288889071006
                    ],
                    [
                        -106.00000000029974,
                        45.237499999625356
                    ],
                    [
                        -104.76944444440556,
                        45.14999999980023
                    ],
                    [
                        -104.2500000001998,
                        45.11666666683328
                    ],
                    [
                        -103.16666666663332,
                        44.958333333216785
                    ],
                    [
                        -103.16666666663332,
                        46.449999999900115
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 698,
            "artcc": "zlc",
            "sector": "17",
            "sectype": "low",
            "Shape_Length": 18.852518501432492,
            "Shape_Area": 20.257274813930636
        }
    },
    {
        "type": "Feature",
        "id": 701,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -110.23333333386626,
                        46.633333332567304
                    ],
                    [
                        -109.56666666733281,
                        46.633333332567304
                    ],
                    [
                        -108.78333333316675,
                        46.633333332567304
                    ],
                    [
                        -108.8414388893932,
                        47.032811110744206
                    ],
                    [
                        -108.86111111128872,
                        47.16666666633364
                    ],
                    [
                        -109.18333333326666,
                        48.9999999993006
                    ],
                    [
                        -110.00000000039967,
                        48.9999999993006
                    ],
                    [
                        -110.66666666693311,
                        48.9999999993006
                    ],
                    [
                        -111.66666666673325,
                        48.9999999993006
                    ],
                    [
                        -113.00000000069946,
                        48.9999999993006
                    ],
                    [
                        -114.66666666703304,
                        48.9999999993006
                    ],
                    [
                        -114.69999999999999,
                        48.94999999940052
                    ],
                    [
                        -114.71777777842169,
                        48.91666666643357
                    ],
                    [
                        -115.00000000029974,
                        48.4166666665335
                    ],
                    [
                        -115.00000000029974,
                        47.58333333336668
                    ],
                    [
                        -115.00000000029974,
                        47.14999999940051
                    ],
                    [
                        -115.00000000029974,
                        46.41666666603385
                    ],
                    [
                        -113.93333333366638,
                        46.3833333330669
                    ],
                    [
                        -112.91166666658336,
                        46.34805555521973
                    ],
                    [
                        -112.38694444483025,
                        46.31777777772231
                    ],
                    [
                        -111.60138888948563,
                        46.268611111213886
                    ],
                    [
                        -111.09416666715794,
                        46.237499999425495
                    ],
                    [
                        -110.98000000059949,
                        46.22916666640856
                    ],
                    [
                        -110.81250000057452,
                        46.2161111111389
                    ],
                    [
                        -110.46666666733279,
                        46.0805555553946
                    ],
                    [
                        -110.23333333386626,
                        46.633333332567304
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 701,
            "artcc": "zlc",
            "sector": "19",
            "sectype": "low",
            "Shape_Length": 17.170026181890766,
            "Shape_Area": 15.644745060372166
        }
    },
    {
        "type": "Feature",
        "id": 705,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -117.49999999980014,
                        45.33333333336668
                    ],
                    [
                        -117.7500000001998,
                        45.33333333336668
                    ],
                    [
                        -117.91666666683318,
                        45.23333333356652
                    ],
                    [
                        -118.06166666708299,
                        45.130000000199914
                    ],
                    [
                        -118.4499999997002,
                        44.850000000399746
                    ],
                    [
                        -118.54166666648342,
                        44.699999999800184
                    ],
                    [
                        -119.21666666693312,
                        43.73333333296699
                    ],
                    [
                        -119.28333333286696,
                        43.63333333316683
                    ],
                    [
                        -119.24999999990007,
                        43.53333333336667
                    ],
                    [
                        -119.00000000039967,
                        42.66666666633364
                    ],
                    [
                        -119.10000000019983,
                        42.333333333066946
                    ],
                    [
                        -116.99999999990007,
                        41.999999999800195
                    ],
                    [
                        -116.61666666673324,
                        41.999999999800195
                    ],
                    [
                        -116.51666666693308,
                        42.35833333301696
                    ],
                    [
                        -116.38888888891108,
                        42.510555555694395
                    ],
                    [
                        -116.48333333306681,
                        42.54166666658347
                    ],
                    [
                        -116.99999999990007,
                        42.75000000009999
                    ],
                    [
                        -116.66780000036971,
                        42.97164722184186
                    ],
                    [
                        -116.73424722183177,
                        43.00239166654222
                    ],
                    [
                        -116.83330555590663,
                        43.06044722226153
                    ],
                    [
                        -116.92057777814193,
                        43.12797777785221
                    ],
                    [
                        -116.99436111127625,
                        43.203694444742894
                    ],
                    [
                        -117.05319999988006,
                        43.286155555734354
                    ],
                    [
                        -117.09590000038469,
                        43.37377499991635
                    ],
                    [
                        -117.12157222198044,
                        43.464866666263674
                    ],
                    [
                        -117.12964722204163,
                        43.557674999901394
                    ],
                    [
                        -117.11988333294943,
                        43.650394444548056
                    ],
                    [
                        -117.09239444484774,
                        43.74121944482158
                    ],
                    [
                        -117.04763611111758,
                        43.82836944469415
                    ],
                    [
                        -116.9864194447016,
                        43.9101305557806
                    ],
                    [
                        -116.90988611065546,
                        43.984886110905336
                    ],
                    [
                        -116.81950000022482,
                        44.051158333290516
                    ],
                    [
                        -116.71701666648596,
                        44.10762222208791
                    ],
                    [
                        -116.60444999996747,
                        44.15315277757867
                    ],
                    [
                        -116.48403611097768,
                        44.18683888850393
                    ],
                    [
                        -116.35817222247005,
                        44.20800000009996
                    ],
                    [
                        -116.22938888908595,
                        44.216213888760024
                    ],
                    [
                        -116.10027222198545,
                        44.21131388852518
                    ],
                    [
                        -115.97341944435186,
                        44.193394444398166
                    ],
                    [
                        -115.85137777796206,
                        44.162816666555955
                    ],
                    [
                        -115.73658888846637,
                        44.120194444817855
                    ],
                    [
                        -115.63133611137243,
                        44.06637777791218
                    ],
                    [
                        -115.53769722234892,
                        44.002444443955994
                    ],
                    [
                        -115.45141944435181,
                        43.92246111089162
                    ],
                    [
                        -114.36666666673324,
                        44.70416666675834
                    ],
                    [
                        -115.00000000029974,
                        45.33333333336668
                    ],
                    [
                        -117.0099999997002,
                        45.33333333336668
                    ],
                    [
                        -117.49999999980014,
                        45.33333333336668
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 705,
            "artcc": "zlc",
            "sector": "30",
            "sectype": "low",
            "Shape_Length": 15.670595499103399,
            "Shape_Area": 9.005140919499867
        }
    },
    {
        "type": "Feature",
        "id": 707,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -113.08333333356643,
                        42.53333333266721
                    ],
                    [
                        -113.56666666653342,
                        43.52499999945047
                    ],
                    [
                        -113.91666666673325,
                        44.249999999800195
                    ],
                    [
                        -114.15833333321672,
                        44.494999999850165
                    ],
                    [
                        -114.36666666673324,
                        44.70416666675834
                    ],
                    [
                        -115.45141944435181,
                        43.92246111089162
                    ],
                    [
                        -115.39228611141488,
                        43.84949166600762
                    ],
                    [
                        -115.3432861117646,
                        43.76350555523726
                    ],
                    [
                        -115.311397222204,
                        43.67340000001002
                    ],
                    [
                        -115.29716666670828,
                        43.580949999442964
                    ],
                    [
                        -115.3007916666021,
                        43.48795555560446
                    ],
                    [
                        -115.32212222216276,
                        43.396227777190234
                    ],
                    [
                        -115.3606666668332,
                        43.307547222026756
                    ],
                    [
                        -115.41561111146359,
                        43.2236222215883
                    ],
                    [
                        -115.45944444480523,
                        43.17249999997506
                    ],
                    [
                        -115.50723333375134,
                        43.126783333384196
                    ],
                    [
                        -115.56607500036097,
                        43.079044444045905
                    ],
                    [
                        -115.63253611095274,
                        43.0350222215983
                    ],
                    [
                        -115.03333333326668,
                        42.66388888896108
                    ],
                    [
                        -115.59999999999997,
                        42.40416666595894
                    ],
                    [
                        -116.06666666693309,
                        42.391666665983905
                    ],
                    [
                        -116.38888888891108,
                        42.510555555694395
                    ],
                    [
                        -116.51666666693308,
                        42.35833333301696
                    ],
                    [
                        -116.61666666673324,
                        41.999999999800195
                    ],
                    [
                        -115.00000000029974,
                        41.999999999800195
                    ],
                    [
                        -114.5833333332667,
                        41.999999999800195
                    ],
                    [
                        -113.71666666713293,
                        41.999999999800195
                    ],
                    [
                        -113.60000000039969,
                        42.09999999960036
                    ],
                    [
                        -113.54166666658335,
                        42.14999999950044
                    ],
                    [
                        -113.08333333356643,
                        42.53333333266721
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 707,
            "artcc": "zlc",
            "sector": "31",
            "sectype": "low",
            "Shape_Length": 11.377120760035403,
            "Shape_Area": 4.933316086245873
        }
    },
    {
        "type": "Feature",
        "id": 709,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -110.30000000069941,
                        39.58333333316682
                    ],
                    [
                        -109.81666666683316,
                        39.76388888846151
                    ],
                    [
                        -110.58333333316676,
                        40.36388888816174
                    ],
                    [
                        -111.51666666703301,
                        40.39999999940051
                    ],
                    [
                        -111.6249999998501,
                        40.46777777772229
                    ],
                    [
                        -111.62361111116383,
                        40.35444444365618
                    ],
                    [
                        -111.73333333356646,
                        40.35444444365618
                    ],
                    [
                        -111.78333333346654,
                        40.13333333296697
                    ],
                    [
                        -111.87750000042462,
                        40.07333333326676
                    ],
                    [
                        -111.82622222217776,
                        39.87808888839152
                    ],
                    [
                        -111.68333333366638,
                        39.333333332767154
                    ],
                    [
                        -110.30000000069941,
                        39.58333333316682
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 709,
            "artcc": "zlc",
            "sector": "32",
            "sectype": "low",
            "Shape_Length": 5.28352286261389,
            "Shape_Area": 1.4773572031614466
        }
    },
    {
        "type": "Feature",
        "id": 714,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -112.65083333389123,
                        41.01666666603387
                    ],
                    [
                        -112.84250000047462,
                        40.91666666623371
                    ],
                    [
                        -113.67500000024978,
                        40.917777777722335
                    ],
                    [
                        -113.71055555619392,
                        40.82972222170321
                    ],
                    [
                        -113.71416666695808,
                        40.821944443980954
                    ],
                    [
                        -113.78333333396614,
                        40.64999999980023
                    ],
                    [
                        -113.71666666713293,
                        40.64999999980023
                    ],
                    [
                        -113.00083333319174,
                        40.64999999980023
                    ],
                    [
                        -112.943888888961,
                        40.57361111036448
                    ],
                    [
                        -112.61750000002496,
                        40.5166666661338
                    ],
                    [
                        -112.65194444448048,
                        40.69388888876125
                    ],
                    [
                        -112.65000000049957,
                        40.899999999300576
                    ],
                    [
                        -112.65083333389123,
                        41.01666666603387
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 714,
            "artcc": "zlc",
            "sector": "40",
            "sectype": "low",
            "Shape_Length": 3.049984666851043,
            "Shape_Area": 0.33252511583255334
        }
    },
    {
        "type": "Feature",
        "id": 715,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -113.60000000039969,
                        42.09999999960036
                    ],
                    [
                        -113.71666666713293,
                        41.999999999800195
                    ],
                    [
                        -114.5833333332667,
                        41.999999999800195
                    ],
                    [
                        -114.50111111098897,
                        41.6666666665335
                    ],
                    [
                        -113.92305555576922,
                        41.87500000005002
                    ],
                    [
                        -113.77333333326669,
                        41.900000000000034
                    ],
                    [
                        -113.57916666740772,
                        41.74444444375615
                    ],
                    [
                        -113.51083333379131,
                        41.70555555514483
                    ],
                    [
                        -113.10333333316674,
                        41.48333333296699
                    ],
                    [
                        -113.08166666678324,
                        41.417777777622405
                    ],
                    [
                        -113.04444444495516,
                        41.31972222180315
                    ],
                    [
                        -112.9472222225275,
                        41.31249999937552
                    ],
                    [
                        -112.75916666670827,
                        41.191666666583444
                    ],
                    [
                        -112.7366666669331,
                        41.17916666660841
                    ],
                    [
                        -112.70861111151356,
                        41.29416666655845
                    ],
                    [
                        -112.62361111096396,
                        41.35222222227782
                    ],
                    [
                        -112.53916666660837,
                        41.40972222180312
                    ],
                    [
                        -112.61361111116383,
                        41.458888888311606
                    ],
                    [
                        -113.25083333359146,
                        41.87500000005002
                    ],
                    [
                        -113.60000000039969,
                        42.09999999960036
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 715,
            "artcc": "zlc",
            "sector": "40",
            "sectype": "low",
            "Shape_Length": 5.031272677327466,
            "Shape_Area": 0.5208041666292583
        }
    },
    {
        "type": "Feature",
        "id": 719,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -119.10000000019983,
                        42.333333333066946
                    ],
                    [
                        -119.50000000029974,
                        41.00000000000006
                    ],
                    [
                        -117.7322222217781,
                        40.121111111088965
                    ],
                    [
                        -117.36666666703303,
                        39.933333333366704
                    ],
                    [
                        -117.33333333316676,
                        39.4166666665335
                    ],
                    [
                        -117.32500000014983,
                        39.2069444443307
                    ],
                    [
                        -117.3074999998251,
                        38.96888888861139
                    ],
                    [
                        -117.29166666628356,
                        38.777777778221946
                    ],
                    [
                        -114.86916666680821,
                        39.44138888928586
                    ],
                    [
                        -114.44999999960027,
                        39.55000000019993
                    ],
                    [
                        -114.44999999960027,
                        40.299999999600345
                    ],
                    [
                        -114.45083333299186,
                        40.59999999990015
                    ],
                    [
                        -114.4341666669581,
                        40.900000000199896
                    ],
                    [
                        -114.45833333351652,
                        41.15027777779727
                    ],
                    [
                        -114.50111111098897,
                        41.6666666665335
                    ],
                    [
                        -114.5833333332667,
                        41.999999999800195
                    ],
                    [
                        -115.00000000029974,
                        41.999999999800195
                    ],
                    [
                        -116.61666666673324,
                        41.999999999800195
                    ],
                    [
                        -116.99999999990007,
                        41.999999999800195
                    ],
                    [
                        -119.10000000019983,
                        42.333333333066946
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 719,
            "artcc": "zlc",
            "sector": "43",
            "sectype": "low",
            "Shape_Length": 14.88640545678436,
            "Shape_Area": 11.691543439904382
        }
    },
    {
        "type": "Feature",
        "id": 721,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -111.68333333366638,
                        39.333333332767154
                    ],
                    [
                        -112.8958333339412,
                        39.10527777774729
                    ],
                    [
                        -113.06750000002495,
                        38.700000000099976
                    ],
                    [
                        -113.60083333379129,
                        38.499999999600334
                    ],
                    [
                        -113.71250000017483,
                        38.57583333284208
                    ],
                    [
                        -114.20083333349152,
                        38.0166666666334
                    ],
                    [
                        -113.70083333359145,
                        37.89555555574435
                    ],
                    [
                        -113.70027777829682,
                        37.82361111046441
                    ],
                    [
                        -113.78333333396614,
                        37.71666666633365
                    ],
                    [
                        -113.00000000069946,
                        37.499999999800195
                    ],
                    [
                        -112.88333333396616,
                        37.76666666623373
                    ],
                    [
                        -111.85000000029976,
                        38.43333333276712
                    ],
                    [
                        -111.68333333366638,
                        39.333333332767154
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 721,
            "artcc": "zlc",
            "sector": "44",
            "sectype": "low",
            "Shape_Length": 7.0915211342815185,
            "Shape_Area": 2.2600560956810125
        }
    },
    {
        "type": "Feature",
        "id": 724,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -111.31388888916086,
                        37.64999999950044
                    ],
                    [
                        -109.98333333346653,
                        38.19999999930059
                    ],
                    [
                        -109.98333333346653,
                        38.52777777782222
                    ],
                    [
                        -109.98333333346653,
                        38.933333332667246
                    ],
                    [
                        -109.98333333346653,
                        38.94555555544457
                    ],
                    [
                        -109.98333333346653,
                        39.21666666603386
                    ],
                    [
                        -110.30000000069941,
                        39.58333333316682
                    ],
                    [
                        -111.68333333366638,
                        39.333333332767154
                    ],
                    [
                        -111.85000000029976,
                        38.43333333276712
                    ],
                    [
                        -112.88333333396616,
                        37.76666666623373
                    ],
                    [
                        -113.00000000069946,
                        37.499999999800195
                    ],
                    [
                        -112.42182777822933,
                        37.456174999576604
                    ],
                    [
                        -111.89583333324168,
                        37.41388888866135
                    ],
                    [
                        -111.87916666720787,
                        37.41249999997507
                    ],
                    [
                        -111.31388888916086,
                        37.64999999950044
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 724,
            "artcc": "zlc",
            "sector": "46",
            "sectype": "low",
            "Shape_Length": 8.520127253033571,
            "Shape_Area": 3.641943308714097
        }
    },
    {
        "type": "Feature",
        "id": 726,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -114.45000000049959,
                        39.54999999930055
                    ],
                    [
                        -114.86916666680821,
                        39.44138888838654
                    ],
                    [
                        -117.29166666718294,
                        38.777777777322626
                    ],
                    [
                        -117.28138888928578,
                        38.41305555506989
                    ],
                    [
                        -117.27805555571928,
                        38.29583333304191
                    ],
                    [
                        -117.01388888946065,
                        38.34583333294199
                    ],
                    [
                        -116.31444444435562,
                        38.233333333166854
                    ],
                    [
                        -116.18416666705798,
                        37.88333333296697
                    ],
                    [
                        -114.70166666678324,
                        37.99972222160329
                    ],
                    [
                        -114.50083333379126,
                        38.0166666666334
                    ],
                    [
                        -114.20083333349152,
                        38.0166666666334
                    ],
                    [
                        -113.71250000017483,
                        38.57583333284208
                    ],
                    [
                        -113.93416666705798,
                        38.71666666613379
                    ],
                    [
                        -113.98416666695806,
                        38.9999999995004
                    ],
                    [
                        -114.05083333379127,
                        39.383333332667235
                    ],
                    [
                        -114.45083333389124,
                        39.383333332667235
                    ],
                    [
                        -114.45000000049959,
                        39.54999999930055
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 726,
            "artcc": "zlc",
            "sector": "47",
            "sectype": "low",
            "Shape_Length": 9.01472978023989,
            "Shape_Area": 3.7658042818300297
        }
    },
    {
        "type": "Feature",
        "id": 739,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -79.70000000069945,
                        26.383333332567304
                    ],
                    [
                        -79.60638888903594,
                        26.433333333366704
                    ],
                    [
                        -79.53972222220273,
                        26.561944443881032
                    ],
                    [
                        -79.38194444478029,
                        26.83305555536964
                    ],
                    [
                        -79.13305555586919,
                        27.291111111188854
                    ],
                    [
                        -79.11666666703303,
                        27.475000000049988
                    ],
                    [
                        -79.03333333326668,
                        27.999999999900126
                    ],
                    [
                        -78.66916666720789,
                        30.144166666358615
                    ],
                    [
                        -79.28666666723285,
                        30.200277777197698
                    ],
                    [
                        -79.90083333369137,
                        29.82222222157833
                    ],
                    [
                        -80.11555555624386,
                        29.024999999650333
                    ],
                    [
                        -80.20166666738277,
                        29.00777777742252
                    ],
                    [
                        -80.54083333349149,
                        28.9391666666084
                    ],
                    [
                        -80.73333333346653,
                        28.899999999900103
                    ],
                    [
                        -80.7941666665584,
                        28.85861111111393
                    ],
                    [
                        -80.84805555621892,
                        28.82194444368116
                    ],
                    [
                        -80.98611111123876,
                        28.727777777622407
                    ],
                    [
                        -80.28888888911092,
                        26.940555555694402
                    ],
                    [
                        -80.1333333337663,
                        26.5500000001
                    ],
                    [
                        -80.15000000069944,
                        26.383333332567304
                    ],
                    [
                        -79.70000000069945,
                        26.383333332567304
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 739,
            "artcc": "zma",
            "sector": "02",
            "sectype": "low",
            "Shape_Length": 10.03638046296773,
            "Shape_Area": 5.025141820874766
        }
    },
    {
        "type": "Feature",
        "id": 740,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -79.7905555559941,
                        27.86166666678332
                    ],
                    [
                        -80.16666666673325,
                        28.01666666683326
                    ],
                    [
                        -80.49555555584419,
                        28.149722221503396
                    ],
                    [
                        -80.5102777778971,
                        28.106666666833235
                    ],
                    [
                        -80.7922222225775,
                        28.106666666833235
                    ],
                    [
                        -81.04166666678321,
                        27.999999999900126
                    ],
                    [
                        -81.2333333333666,
                        27.999999999900126
                    ],
                    [
                        -81.2333333333666,
                        27.749999999500403
                    ],
                    [
                        -81.20000000039965,
                        27.749999999500403
                    ],
                    [
                        -81.15000000049957,
                        27.591666666783283
                    ],
                    [
                        -81.12500000054956,
                        27.54583333294204
                    ],
                    [
                        -80.99999999990001,
                        27.349999999400495
                    ],
                    [
                        -80.83138888928579,
                        26.964166666058816
                    ],
                    [
                        -80.78305555616896,
                        26.91194444408086
                    ],
                    [
                        -80.73083333329168,
                        27.026388888736278
                    ],
                    [
                        -80.70000000049959,
                        27.09999999990015
                    ],
                    [
                        -80.56083333399113,
                        27.11472222195306
                    ],
                    [
                        -80.38249999987505,
                        27.13361111096407
                    ],
                    [
                        -80.22500000054953,
                        27.14999999980023
                    ],
                    [
                        -80.24166666658334,
                        27.28333333256728
                    ],
                    [
                        -80.06666666693309,
                        27.28333333256728
                    ],
                    [
                        -79.9500000001998,
                        27.173611111063963
                    ],
                    [
                        -79.93750000022482,
                        27.230277777197728
                    ],
                    [
                        -79.89166666728283,
                        27.43166666638359
                    ],
                    [
                        -79.86250000037467,
                        27.569999999500453
                    ],
                    [
                        -79.7905555559941,
                        27.86166666678332
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 740,
            "artcc": "zma",
            "sector": "04",
            "sectype": "low",
            "Shape_Length": 4.63722876279065,
            "Shape_Area": 1.1240966053259045
        }
    },
    {
        "type": "Feature",
        "id": 741,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -80.86388888886108,
                        24.56388888826166
                    ],
                    [
                        -80.7275000006245,
                        25.04194444368119
                    ],
                    [
                        -80.70194444448049,
                        25.130833333091914
                    ],
                    [
                        -80.64444444495513,
                        25.330277777397555
                    ],
                    [
                        -80.68333333356645,
                        25.36666666673335
                    ],
                    [
                        -80.75000000039967,
                        25.44999999960038
                    ],
                    [
                        -80.87500000014984,
                        25.63750000012493
                    ],
                    [
                        -80.89166666708297,
                        25.774999999850138
                    ],
                    [
                        -81.0047222221528,
                        25.779166666808294
                    ],
                    [
                        -81.41666666693311,
                        25.783333332867073
                    ],
                    [
                        -82.30833333391621,
                        25.783333332867073
                    ],
                    [
                        -82.49972222240262,
                        25.733611111063965
                    ],
                    [
                        -82.79194444498012,
                        25.72416666655846
                    ],
                    [
                        -82.80111111138865,
                        25.723888888461488
                    ],
                    [
                        -82.79638888913587,
                        25.71666666603386
                    ],
                    [
                        -82.10000000039969,
                        24.67499999935052
                    ],
                    [
                        -82.10000000039969,
                        24.499999999700265
                    ],
                    [
                        -81.92361111116384,
                        24.25277777757242
                    ],
                    [
                        -81.85916666730782,
                        24.251944444180822
                    ],
                    [
                        -81.82888888891108,
                        24.256944444530518
                    ],
                    [
                        -81.78083333389122,
                        24.271111110389484
                    ],
                    [
                        -81.74833333341655,
                        24.290555555594437
                    ],
                    [
                        -81.72444444495511,
                        24.3000000001
                    ],
                    [
                        -81.68777777842172,
                        24.338333333416642
                    ],
                    [
                        -81.67583333374131,
                        24.351944443980983
                    ],
                    [
                        -81.66250000037468,
                        24.357777777722333
                    ],
                    [
                        -81.61666666653338,
                        24.37166666638359
                    ],
                    [
                        -81.59138888938571,
                        24.385555555044903
                    ],
                    [
                        -81.56611111133867,
                        24.386111111238847
                    ],
                    [
                        -81.46944444510501,
                        24.416666666833237
                    ],
                    [
                        -81.25000000029974,
                        24.416666666833237
                    ],
                    [
                        -81.26944444460537,
                        24.48333333276713
                    ],
                    [
                        -81.27194444478027,
                        24.54777777752247
                    ],
                    [
                        -80.96111111128874,
                        24.48749999972523
                    ],
                    [
                        -80.86388888886108,
                        24.56388888826166
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 741,
            "artcc": "zma",
            "sector": "05",
            "sectype": "low",
            "Shape_Length": 6.268958196617284,
            "Shape_Area": 2.19318796317154
        }
    },
    {
        "type": "Feature",
        "id": 742,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -81.50000000069946,
                        23.999999999800195
                    ],
                    [
                        -81.31666666713295,
                        23.999999999800195
                    ],
                    [
                        -81.31055555619395,
                        24.03888888841152
                    ],
                    [
                        -81.25000000029974,
                        24.416666666833237
                    ],
                    [
                        -81.26944444460537,
                        24.48333333276713
                    ],
                    [
                        -81.27194444478027,
                        24.54777777752247
                    ],
                    [
                        -81.27416666685815,
                        24.663333332767138
                    ],
                    [
                        -80.7275000006245,
                        25.04194444368119
                    ],
                    [
                        -80.70194444448049,
                        25.130833333091914
                    ],
                    [
                        -80.64444444495513,
                        25.330277777397555
                    ],
                    [
                        -80.68333333356645,
                        25.36666666673335
                    ],
                    [
                        -80.75000000039967,
                        25.44999999960038
                    ],
                    [
                        -80.87500000014984,
                        25.63750000012493
                    ],
                    [
                        -80.89166666708297,
                        25.774999999850138
                    ],
                    [
                        -81.0047222221528,
                        25.779166666808294
                    ],
                    [
                        -81.41666666693311,
                        25.783333332867073
                    ],
                    [
                        -82.30833333391621,
                        25.783333332867073
                    ],
                    [
                        -82.49972222240262,
                        25.733611111063965
                    ],
                    [
                        -82.80111111138865,
                        25.723888888461488
                    ],
                    [
                        -83.63333333396616,
                        25.691666666083847
                    ],
                    [
                        -84.28333333356647,
                        25.66694444423075
                    ],
                    [
                        -84.41666666723285,
                        25.61694444433067
                    ],
                    [
                        -84.99972222280229,
                        25.033611110664253
                    ],
                    [
                        -84.99972222280229,
                        23.999999999800195
                    ],
                    [
                        -84.66944444500507,
                        23.999999999800195
                    ],
                    [
                        -84.50416666705797,
                        23.999999999800195
                    ],
                    [
                        -84.34027777779716,
                        23.999999999800195
                    ],
                    [
                        -83.50000000029974,
                        23.999999999800195
                    ],
                    [
                        -83.14722222272735,
                        23.999999999800195
                    ],
                    [
                        -82.98055555609398,
                        23.999999999800195
                    ],
                    [
                        -82.56722222262744,
                        23.998611111113917
                    ],
                    [
                        -82.40055555599406,
                        23.998611111113917
                    ],
                    [
                        -81.50000000069946,
                        23.999999999800195
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 742,
            "artcc": "zma",
            "sector": "06",
            "sectype": "low",
            "Shape_Length": 11.24354300091403,
            "Shape_Area": 6.688560570823607
        }
    },
    {
        "type": "Feature",
        "id": 743,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -83.20833333391619,
                        28.158333332617246
                    ],
                    [
                        -83.17138888928577,
                        27.77611111093904
                    ],
                    [
                        -83.14861111141363,
                        27.535277777847227
                    ],
                    [
                        -83.10138888888605,
                        27.039444444005937
                    ],
                    [
                        -83.06000000009988,
                        26.581388888186666
                    ],
                    [
                        -82.3894444447053,
                        26.392222221778184
                    ],
                    [
                        -82.04166666658335,
                        26.4999999993006
                    ],
                    [
                        -81.94166666678319,
                        26.566666666133813
                    ],
                    [
                        -81.9833333336664,
                        26.600000000000023
                    ],
                    [
                        -81.99166666668327,
                        26.81666666653348
                    ],
                    [
                        -82.00000000059953,
                        27.07916666600886
                    ],
                    [
                        -82.25000000009987,
                        27.41666666623371
                    ],
                    [
                        -82.35861111101394,
                        27.858888888511444
                    ],
                    [
                        -82.3833333337663,
                        27.950000000000045
                    ],
                    [
                        -82.65750000072444,
                        27.950000000000045
                    ],
                    [
                        -82.94166666658333,
                        27.950000000000045
                    ],
                    [
                        -83.20833333391619,
                        28.158333332617246
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 743,
            "artcc": "zma",
            "sector": "07",
            "sectype": "low",
            "Shape_Length": 5.164246243937827,
            "Shape_Area": 1.4719412427624146
        }
    },
    {
        "type": "Feature",
        "id": 744,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -84.41666666723285,
                        25.61694444433067
                    ],
                    [
                        -84.28333333356647,
                        25.66694444423075
                    ],
                    [
                        -83.63333333396616,
                        25.691666666083847
                    ],
                    [
                        -82.80111111138865,
                        25.723888888461488
                    ],
                    [
                        -82.79194444498012,
                        25.72416666655846
                    ],
                    [
                        -82.49972222240262,
                        25.733611111063965
                    ],
                    [
                        -82.30833333391621,
                        25.783333332867073
                    ],
                    [
                        -82.30833333391621,
                        25.849999999700287
                    ],
                    [
                        -82.28305555586917,
                        26.166666666033848
                    ],
                    [
                        -82.3894444447053,
                        26.392222221778184
                    ],
                    [
                        -82.87529440541243,
                        26.52928262928998
                    ],
                    [
                        -82.9194444449052,
                        27.00861111121384
                    ],
                    [
                        -83.10138888888605,
                        27.039444444005937
                    ],
                    [
                        -83.14861111141363,
                        27.535277777847227
                    ],
                    [
                        -83.17138888928577,
                        27.77611111093904
                    ],
                    [
                        -82.94166666658333,
                        27.950000000000045
                    ],
                    [
                        -83.20833333391619,
                        28.158333332617246
                    ],
                    [
                        -83.51666666723287,
                        28.400000000000034
                    ],
                    [
                        -84.00999999999993,
                        28.548055555719372
                    ],
                    [
                        -84.50000000009987,
                        28.1666666665335
                    ],
                    [
                        -84.61611111153854,
                        28.06249999977524
                    ],
                    [
                        -84.94444444445548,
                        28.04305555546955
                    ],
                    [
                        -85.25000000039967,
                        27.500000000000057
                    ],
                    [
                        -86.00000000069946,
                        27.000000000099988
                    ],
                    [
                        -85.41388888876116,
                        26.60277777737258
                    ],
                    [
                        -85.09166666678323,
                        26.199999999900115
                    ],
                    [
                        -84.99972222280229,
                        25.033611110664253
                    ],
                    [
                        -84.41666666723285,
                        25.61694444433067
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 744,
            "artcc": "zma",
            "sector": "08",
            "sectype": "low",
            "Shape_Length": 12.051707952078656,
            "Shape_Area": 6.625590922419172
        }
    },
    {
        "type": "Feature",
        "id": 747,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -78.05833333341656,
                        27.46666666703311
                    ],
                    [
                        -77.9244444444555,
                        30.072222221977995
                    ],
                    [
                        -78.57027777799703,
                        30.13472222185311
                    ],
                    [
                        -78.66916666630857,
                        30.144166666358615
                    ],
                    [
                        -79.03333333326668,
                        27.999999999900126
                    ],
                    [
                        -78.05833333341656,
                        27.46666666703311
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 747,
            "artcc": "zma",
            "sector": "18",
            "sectype": "low",
            "Shape_Length": 6.643391377391302,
            "Shape_Area": 2.117456711938698
        }
    },
    {
        "type": "Feature",
        "id": 748,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -77.50000000059953,
                        30.029444444505543
                    ],
                    [
                        -77.85666666703304,
                        30.065555555744368
                    ],
                    [
                        -77.9244444444555,
                        30.072222221977995
                    ],
                    [
                        -78.05833333341656,
                        27.46666666613379
                    ],
                    [
                        -77.00000000069946,
                        27.46666666613379
                    ],
                    [
                        -76.43111111098892,
                        28.131666666783303
                    ],
                    [
                        -76.38222222257747,
                        28.18833333291701
                    ],
                    [
                        -76.54374722225646,
                        28.66850000007497
                    ],
                    [
                        -76.57361111106388,
                        28.746683332869566
                    ],
                    [
                        -76.84882222276735,
                        29.56161388847022
                    ],
                    [
                        -76.87606944504881,
                        29.641833333141847
                    ],
                    [
                        -76.9072222224276,
                        29.733333333066923
                    ],
                    [
                        -76.91805555561933,
                        29.76666666603387
                    ],
                    [
                        -77.00000000069946,
                        29.999999999500403
                    ],
                    [
                        -77.0333333336664,
                        29.999999999500403
                    ],
                    [
                        -77.21666666723286,
                        29.999999999500403
                    ],
                    [
                        -77.50000000059953,
                        30.029444444505543
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 748,
            "artcc": "zma",
            "sector": "19",
            "sectype": "low",
            "Shape_Length": 7.459601510255515,
            "Shape_Area": 3.326033550580103
        }
    },
    {
        "type": "Feature",
        "id": 749,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -79.13305555586919,
                        27.291111111188854
                    ],
                    [
                        -79.11666666703303,
                        27.475000000049988
                    ],
                    [
                        -79.03333333326668,
                        27.999999999900126
                    ],
                    [
                        -79.64277777837174,
                        28.446944444430642
                    ],
                    [
                        -79.7905555559941,
                        27.86166666678332
                    ],
                    [
                        -79.86250000037467,
                        27.569999999500453
                    ],
                    [
                        -79.89166666728283,
                        27.43166666638359
                    ],
                    [
                        -79.93750000022482,
                        27.230277777197728
                    ],
                    [
                        -79.9500000001998,
                        27.173611111063963
                    ],
                    [
                        -80.06666666693309,
                        27.28333333256728
                    ],
                    [
                        -80.24166666658334,
                        27.28333333256728
                    ],
                    [
                        -80.43055555579423,
                        27.306111110439474
                    ],
                    [
                        -80.38249999987505,
                        27.13361111096407
                    ],
                    [
                        -80.2108333337913,
                        26.73333333276713
                    ],
                    [
                        -80.19583333364142,
                        26.691666666783306
                    ],
                    [
                        -80.18527777764729,
                        26.656666666133788
                    ],
                    [
                        -80.14166666678318,
                        26.513888888861175
                    ],
                    [
                        -80.15000000069944,
                        26.383333332567304
                    ],
                    [
                        -79.91666666723285,
                        26.383333332567304
                    ],
                    [
                        -79.70000000069945,
                        26.383333332567304
                    ],
                    [
                        -79.60638888903594,
                        26.433333333366704
                    ],
                    [
                        -79.53972222220273,
                        26.561944443881032
                    ],
                    [
                        -79.38194444478029,
                        26.83305555536964
                    ],
                    [
                        -79.13305555586919,
                        27.291111111188854
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 749,
            "artcc": "zma",
            "sector": "20",
            "sectype": "low",
            "Shape_Length": 5.818900907253306,
            "Shape_Area": 1.4849445220037953
        }
    },
    {
        "type": "Feature",
        "id": 750,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -78.77138888908593,
                        26.249999999800195
                    ],
                    [
                        -78.60000000019983,
                        26.4999999993006
                    ],
                    [
                        -78.32083333379126,
                        27.000000000099988
                    ],
                    [
                        -78.05833333341656,
                        27.46666666613379
                    ],
                    [
                        -79.03333333326668,
                        27.999999999900126
                    ],
                    [
                        -79.11666666703303,
                        27.475000000049988
                    ],
                    [
                        -79.13305555586919,
                        27.291111111188854
                    ],
                    [
                        -79.38194444478029,
                        26.83305555536964
                    ],
                    [
                        -79.53972222220273,
                        26.561944443881032
                    ],
                    [
                        -79.60638888903594,
                        26.433333333366704
                    ],
                    [
                        -79.70000000069945,
                        26.383333332567304
                    ],
                    [
                        -79.91666666723285,
                        26.383333332567304
                    ],
                    [
                        -80.15000000069944,
                        26.383333332567304
                    ],
                    [
                        -80.18333333366638,
                        26.049999999300553
                    ],
                    [
                        -80.26666666653341,
                        26.049999999300553
                    ],
                    [
                        -80.26666666653341,
                        25.86666666663342
                    ],
                    [
                        -80.26666666653341,
                        25.783333332867073
                    ],
                    [
                        -80.00000000009987,
                        25.79166666678327
                    ],
                    [
                        -79.70000000069945,
                        25.9166666665335
                    ],
                    [
                        -79.4980555562189,
                        26.060833333391656
                    ],
                    [
                        -79.05000000019982,
                        26.016944444430635
                    ],
                    [
                        -79.00000000029974,
                        25.987499999425495
                    ],
                    [
                        -78.96666666733279,
                        25.966666666433582
                    ],
                    [
                        -78.77138888908593,
                        26.249999999800195
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 750,
            "artcc": "zma",
            "sector": "21",
            "sectype": "low",
            "Shape_Length": 7.191255544591449,
            "Shape_Area": 1.9545045132821264
        }
    },
    {
        "type": "Feature",
        "id": 751,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -77.00000000069946,
                        29.733333333066923
                    ],
                    [
                        -77.00000000069946,
                        29.76666666603387
                    ],
                    [
                        -77.0333333336664,
                        29.76666666603387
                    ],
                    [
                        -77.0333333336664,
                        29.733333333066923
                    ],
                    [
                        -77.00000000069946,
                        29.733333333066923
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 751,
            "artcc": "zma",
            "sector": "21",
            "sectype": "low",
            "Shape_Length": 0.13333333186778873,
            "Shape_Area": 0.0011111110866853678
        }
    },
    {
        "type": "Feature",
        "id": 752,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -80.71333333386622,
                        27.92722222212791
                    ],
                    [
                        -80.7922222225775,
                        28.106666666833235
                    ],
                    [
                        -80.96805555561934,
                        28.470833332892028
                    ],
                    [
                        -81.40972222260245,
                        28.38638888853643
                    ],
                    [
                        -81.29722222282726,
                        28.141666666583433
                    ],
                    [
                        -81.2333333333666,
                        27.999999999900126
                    ],
                    [
                        -81.2333333333666,
                        27.749999999500403
                    ],
                    [
                        -81.20000000039965,
                        27.749999999500403
                    ],
                    [
                        -81.15000000049957,
                        27.591666666783283
                    ],
                    [
                        -81.12500000054956,
                        27.54583333294204
                    ],
                    [
                        -80.99999999990001,
                        27.349999999400495
                    ],
                    [
                        -80.60277777807198,
                        27.406944444530552
                    ],
                    [
                        -80.5083333339162,
                        27.41666666623371
                    ],
                    [
                        -80.47638888873621,
                        27.42416666675831
                    ],
                    [
                        -80.71333333386622,
                        27.92722222212791
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 752,
            "artcc": "zma",
            "sector": "22",
            "sectype": "low",
            "Shape_Length": 3.2938426329761232,
            "Shape_Area": 0.5558750001178898
        }
    },
    {
        "type": "Feature",
        "id": 753,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -80.22500000054953,
                        27.14999999980023
                    ],
                    [
                        -80.06666666693309,
                        27.14999999980023
                    ],
                    [
                        -79.9500000001998,
                        27.173611111063963
                    ],
                    [
                        -79.93750000022482,
                        27.230277777197728
                    ],
                    [
                        -79.89166666728283,
                        27.43166666638359
                    ],
                    [
                        -79.86250000037467,
                        27.569999999500453
                    ],
                    [
                        -79.7905555559941,
                        27.86166666678332
                    ],
                    [
                        -79.64277777837174,
                        28.446944444430642
                    ],
                    [
                        -80.20166666738277,
                        29.00777777742252
                    ],
                    [
                        -80.54083333349149,
                        28.9391666666084
                    ],
                    [
                        -80.73333333346653,
                        28.899999999900103
                    ],
                    [
                        -80.7941666665584,
                        28.85861111111393
                    ],
                    [
                        -80.84805555621892,
                        28.82194444368116
                    ],
                    [
                        -80.98611111123876,
                        28.727777777622407
                    ],
                    [
                        -80.99999999990001,
                        28.56666666663341
                    ],
                    [
                        -80.96805555561934,
                        28.470833332892028
                    ],
                    [
                        -80.7922222225775,
                        28.106666666833235
                    ],
                    [
                        -80.71333333386622,
                        27.92722222212791
                    ],
                    [
                        -80.47638888873621,
                        27.42416666675831
                    ],
                    [
                        -80.43055555579423,
                        27.306111110439474
                    ],
                    [
                        -80.38249999987505,
                        27.13361111096407
                    ],
                    [
                        -80.22500000054953,
                        27.14999999980023
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 753,
            "artcc": "zma",
            "sector": "23",
            "sectype": "low",
            "Shape_Length": 5.110742388326262,
            "Shape_Area": 1.6235674761402967
        }
    },
    {
        "type": "Feature",
        "id": 754,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -80.82388888876119,
                        25.783333332867073
                    ],
                    [
                        -80.82388888876119,
                        25.86666666663342
                    ],
                    [
                        -80.82388888876119,
                        26.074999999250622
                    ],
                    [
                        -80.82388888876119,
                        26.116666666133767
                    ],
                    [
                        -80.8902777783967,
                        26.18055555559448
                    ],
                    [
                        -81.08750000062452,
                        26.37083333259227
                    ],
                    [
                        -81.40833333391618,
                        26.649999999900103
                    ],
                    [
                        -81.57916666660833,
                        26.804166666558444
                    ],
                    [
                        -81.791666667083,
                        26.983333333166854
                    ],
                    [
                        -81.90833333381624,
                        27.083333332967015
                    ],
                    [
                        -82.00000000059953,
                        27.07916666600886
                    ],
                    [
                        -82.44111111138864,
                        26.4999999993006
                    ],
                    [
                        -82.3894444447053,
                        26.392222221778184
                    ],
                    [
                        -82.28305555586917,
                        26.166666666033848
                    ],
                    [
                        -82.30833333391621,
                        25.849999999700287
                    ],
                    [
                        -82.30833333391621,
                        25.783333332867073
                    ],
                    [
                        -81.41666666693311,
                        25.783333332867073
                    ],
                    [
                        -81.0047222221528,
                        25.779166666808294
                    ],
                    [
                        -80.89166666708297,
                        25.774999999850138
                    ],
                    [
                        -80.82388888876119,
                        25.783333332867073
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 754,
            "artcc": "zma",
            "sector": "24",
            "sectype": "low",
            "Shape_Length": 4.844609942880405,
            "Shape_Area": 1.3810025849640288
        }
    },
    {
        "type": "Feature",
        "id": 756,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -80.44166666708298,
                        26.10416666615879
                    ],
                    [
                        -80.82388888876119,
                        26.074999999250622
                    ],
                    [
                        -80.82388888876119,
                        25.86666666663342
                    ],
                    [
                        -80.82388888876119,
                        25.783333332867073
                    ],
                    [
                        -80.89166666708297,
                        25.774999999850138
                    ],
                    [
                        -80.87500000014984,
                        25.63750000012493
                    ],
                    [
                        -80.75000000039967,
                        25.44999999960038
                    ],
                    [
                        -80.68333333356645,
                        25.36666666673335
                    ],
                    [
                        -80.64444444495513,
                        25.330277777397555
                    ],
                    [
                        -80.70194444448049,
                        25.130833333091914
                    ],
                    [
                        -80.7275000006245,
                        25.04194444368119
                    ],
                    [
                        -81.27416666685815,
                        24.663333332767138
                    ],
                    [
                        -81.27194444478027,
                        24.54777777752247
                    ],
                    [
                        -81.26944444460537,
                        24.48333333276713
                    ],
                    [
                        -81.25000000029974,
                        24.416666666833237
                    ],
                    [
                        -81.27638888893603,
                        24.25277777757242
                    ],
                    [
                        -81.31055555619395,
                        24.03888888841152
                    ],
                    [
                        -81.31666666713295,
                        23.999999999800195
                    ],
                    [
                        -81.14138888938572,
                        23.999999999800195
                    ],
                    [
                        -81.05777777842172,
                        23.999999999800195
                    ],
                    [
                        -80.96833333371632,
                        23.999999999800195
                    ],
                    [
                        -79.83305555626885,
                        24.00055555509482
                    ],
                    [
                        -79.80750000012489,
                        24.009999999600325
                    ],
                    [
                        -79.81656388931202,
                        24.046122221963003
                    ],
                    [
                        -79.98027777769727,
                        24.69555555514478
                    ],
                    [
                        -80.05833333391621,
                        24.999999999600334
                    ],
                    [
                        -80.10833333381629,
                        25.200000000099976
                    ],
                    [
                        -80.1333333337663,
                        25.27499999995007
                    ],
                    [
                        -80.24166666658334,
                        25.458333332617258
                    ],
                    [
                        -80.26666666653341,
                        25.541666666383605
                    ],
                    [
                        -80.26666666653341,
                        25.783333332867073
                    ],
                    [
                        -80.26666666653341,
                        25.86666666663342
                    ],
                    [
                        -80.36666666723289,
                        25.86666666663342
                    ],
                    [
                        -80.44166666708298,
                        26.10416666615879
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 756,
            "artcc": "zma",
            "sector": "26",
            "sectype": "low",
            "Shape_Length": 6.6949960753288655,
            "Shape_Area": 1.8524720691796506
        }
    },
    {
        "type": "Feature",
        "id": 758,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -78.9833333333666,
                        23.999999999800195
                    ],
                    [
                        -78.5833333332667,
                        23.999999999800195
                    ],
                    [
                        -78.5833333332667,
                        24.04916666630868
                    ],
                    [
                        -78.5833333332667,
                        24.39222222217785
                    ],
                    [
                        -78.5833333332667,
                        24.949999999700253
                    ],
                    [
                        -78.67916666700802,
                        25.05833333341667
                    ],
                    [
                        -79.00000000029974,
                        25.224722221953016
                    ],
                    [
                        -79.21249999987504,
                        25.333333332867085
                    ],
                    [
                        -79.70000000069945,
                        25.516666666433537
                    ],
                    [
                        -79.82500000044962,
                        25.55833333331674
                    ],
                    [
                        -80.24166666658334,
                        25.458333332617258
                    ],
                    [
                        -80.1333333337663,
                        25.27499999995007
                    ],
                    [
                        -80.10833333381629,
                        25.200000000099976
                    ],
                    [
                        -80.05833333391621,
                        24.999999999600334
                    ],
                    [
                        -79.98027777769727,
                        24.69555555514478
                    ],
                    [
                        -79.81656388931202,
                        24.046122221963003
                    ],
                    [
                        -79.80750000012489,
                        24.009999999600325
                    ],
                    [
                        -79.83305555626885,
                        24.00055555509482
                    ],
                    [
                        -79.16527777824683,
                        23.999999999800195
                    ],
                    [
                        -78.9833333333666,
                        23.999999999800195
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 758,
            "artcc": "zma",
            "sector": "41",
            "sectype": "low",
            "Shape_Length": 5.572204426193613,
            "Shape_Area": 1.888430168518682
        }
    },
    {
        "type": "Feature",
        "id": 759,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -78.50000000039967,
                        25.66666666613378
                    ],
                    [
                        -78.96666666733279,
                        25.966666666433582
                    ],
                    [
                        -79.00000000029974,
                        25.987499999425495
                    ],
                    [
                        -79.05000000019982,
                        26.016944444430635
                    ],
                    [
                        -79.4980555562189,
                        26.060833333391656
                    ],
                    [
                        -79.70000000069945,
                        25.9166666665335
                    ],
                    [
                        -80.00000000009987,
                        25.79166666678327
                    ],
                    [
                        -80.26666666653341,
                        25.783333332867073
                    ],
                    [
                        -80.26666666653341,
                        25.541666666383605
                    ],
                    [
                        -80.24166666658334,
                        25.458333332617258
                    ],
                    [
                        -79.82500000044962,
                        25.55833333331674
                    ],
                    [
                        -79.70000000069945,
                        25.516666666433537
                    ],
                    [
                        -79.21249999987504,
                        25.333333332867085
                    ],
                    [
                        -79.00000000029974,
                        25.224722221953016
                    ],
                    [
                        -78.67916666700802,
                        25.05833333341667
                    ],
                    [
                        -78.50000000039967,
                        25.066666666433548
                    ],
                    [
                        -78.50000000039967,
                        25.349999999800218
                    ],
                    [
                        -78.50000000039967,
                        25.66666666613378
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 759,
            "artcc": "zma",
            "sector": "42",
            "sectype": "low",
            "Shape_Length": 4.731415632046496,
            "Shape_Area": 0.9806154706868389
        }
    },
    {
        "type": "Feature",
        "id": 761,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -73.25500000044963,
                        21.500833332792126
                    ],
                    [
                        -73.52916666740771,
                        21.7141666666584
                    ],
                    [
                        -73.96555555594409,
                        22.051666665983873
                    ],
                    [
                        -74.75000000069946,
                        22.633333332867096
                    ],
                    [
                        -75.17694444473028,
                        22.963888888761232
                    ],
                    [
                        -75.55333333356646,
                        22.370555555294686
                    ],
                    [
                        -75.58916666670825,
                        22.31361111106395
                    ],
                    [
                        -75.19507222220523,
                        22.02103888853395
                    ],
                    [
                        -75.16666666683318,
                        21.9999999993006
                    ],
                    [
                        -75.14207777846667,
                        21.97529166657722
                    ],
                    [
                        -75.0000000001998,
                        21.833333332667223
                    ],
                    [
                        -74.48425555574926,
                        21.274336110423235
                    ],
                    [
                        -74.39134166688439,
                        21.173005555561986
                    ],
                    [
                        -74.1383861111301,
                        20.896111110839172
                    ],
                    [
                        -74.06890000043461,
                        20.818833333291764
                    ],
                    [
                        -73.91700833346778,
                        20.651158333290482
                    ],
                    [
                        -73.7569444443306,
                        20.470833332692223
                    ],
                    [
                        -73.33333333386622,
                        19.999999999700265
                    ],
                    [
                        -73.22652777833429,
                        20.135913888515176
                    ],
                    [
                        -73.20986111140115,
                        20.135913888515176
                    ],
                    [
                        -73.20986111140115,
                        20.15472222165323
                    ],
                    [
                        -73.01487777838673,
                        20.398116666550948
                    ],
                    [
                        -73.00147222226519,
                        20.398116666550948
                    ],
                    [
                        -73.00147222226519,
                        20.41478333258476
                    ],
                    [
                        -73.00000000059953,
                        20.416666666733306
                    ],
                    [
                        -72.2571388888486,
                        20.418055555419585
                    ],
                    [
                        -72.2571388888486,
                        20.40138888848645
                    ],
                    [
                        -72.24047222281479,
                        20.40138888848645
                    ],
                    [
                        -72.24047222281479,
                        20.418055555419585
                    ],
                    [
                        -71.78410277802072,
                        20.417094443653696
                    ],
                    [
                        -71.78410277802072,
                        20.400427777619882
                    ],
                    [
                        -71.76641666659583,
                        20.400477777227707
                    ],
                    [
                        -71.76641666659583,
                        20.41714444416084
                    ],
                    [
                        -71.66666666663332,
                        20.416666666733306
                    ],
                    [
                        -71.40148888900097,
                        20.416944443931015
                    ],
                    [
                        -71.3837388888386,
                        20.416944443931015
                    ],
                    [
                        -71.1266666666333,
                        20.416666666733306
                    ],
                    [
                        -71.11000000059948,
                        20.416666666733306
                    ],
                    [
                        -70.95611111113885,
                        20.418333332617237
                    ],
                    [
                        -71.61722222292718,
                        21.08333333326675
                    ],
                    [
                        -71.74222222267741,
                        21.164999999350528
                    ],
                    [
                        -72.22138888958557,
                        21.475555555644405
                    ],
                    [
                        -72.33583333334161,
                        21.385277777547458
                    ],
                    [
                        -72.73333333326667,
                        21.066666666333617
                    ],
                    [
                        -73.25500000044963,
                        21.500833332792126
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 761,
            "artcc": "zma",
            "sector": "43",
            "sectype": "low",
            "Shape_Length": 12.080264326807916,
            "Shape_Area": 4.283110789829288
        }
    },
    {
        "type": "Feature",
        "id": 762,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -77.9583333336164,
                        26.249999999800195
                    ],
                    [
                        -77.00000000069946,
                        26.249999999800195
                    ],
                    [
                        -77.00000000069946,
                        27.000000000099988
                    ],
                    [
                        -77.00000000069946,
                        27.46666666613379
                    ],
                    [
                        -78.05833333341656,
                        27.46666666613379
                    ],
                    [
                        -78.32083333379126,
                        27.000000000099988
                    ],
                    [
                        -78.60000000019983,
                        26.4999999993006
                    ],
                    [
                        -78.77138888908593,
                        26.249999999800195
                    ],
                    [
                        -77.9583333336164,
                        26.249999999800195
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 762,
            "artcc": "zma",
            "sector": "44",
            "sectype": "low",
            "Shape_Length": 5.457580447897171,
            "Shape_Area": 1.7067708323950672
        }
    },
    {
        "type": "Feature",
        "id": 763,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -80.26666666653341,
                        26.049999999300553
                    ],
                    [
                        -80.18333333366638,
                        26.049999999300553
                    ],
                    [
                        -80.15000000069944,
                        26.383333332567304
                    ],
                    [
                        -80.14166666678318,
                        26.513888888861175
                    ],
                    [
                        -80.18527777764729,
                        26.656666666133788
                    ],
                    [
                        -80.19583333364142,
                        26.691666666783306
                    ],
                    [
                        -80.2108333337913,
                        26.73333333276713
                    ],
                    [
                        -80.04166666698308,
                        26.73333333276713
                    ],
                    [
                        -80.15000000069944,
                        26.949999999300587
                    ],
                    [
                        -80.38249999987505,
                        27.13361111096407
                    ],
                    [
                        -80.43055555579423,
                        27.306111110439474
                    ],
                    [
                        -80.47638888873621,
                        27.42416666675831
                    ],
                    [
                        -80.5083333339162,
                        27.41666666623371
                    ],
                    [
                        -80.60277777807198,
                        27.406944444530552
                    ],
                    [
                        -80.99999999990001,
                        27.349999999400495
                    ],
                    [
                        -80.83138888928579,
                        26.964166666058816
                    ],
                    [
                        -80.83021015777251,
                        26.962893094740593
                    ],
                    [
                        -80.7955750003859,
                        26.88392777714523
                    ],
                    [
                        -80.65416666665828,
                        26.513888888861175
                    ],
                    [
                        -80.61250000067446,
                        26.383333332567304
                    ],
                    [
                        -80.49999999999994,
                        26.233333332867062
                    ],
                    [
                        -80.40833333321672,
                        26.049999999300553
                    ],
                    [
                        -80.26666666653341,
                        26.049999999300553
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 763,
            "artcc": "zma",
            "sector": "46",
            "sectype": "low",
            "Shape_Length": 3.8980463560403176,
            "Shape_Area": 0.6845113240551063
        }
    },
    {
        "type": "Feature",
        "id": 764,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -76.96666666683319,
                        29.733333333066923
                    ],
                    [
                        -76.96666666683319,
                        29.76666666603387
                    ],
                    [
                        -77.00000000069946,
                        29.76666666603387
                    ],
                    [
                        -77.00000000069946,
                        29.733333333066923
                    ],
                    [
                        -76.96666666683319,
                        29.733333333066923
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 764,
            "artcc": "zma",
            "sector": "46",
            "sectype": "low",
            "Shape_Length": 0.1333333336664282,
            "Shape_Area": 0.0011111111166626918
        }
    },
    {
        "type": "Feature",
        "id": 765,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -80.49999999999994,
                        26.233333332867062
                    ],
                    [
                        -80.61250000067446,
                        26.383333332567304
                    ],
                    [
                        -80.65416666665828,
                        26.513888888861175
                    ],
                    [
                        -80.7955750003859,
                        26.88392777714523
                    ],
                    [
                        -80.78305555616896,
                        26.91194444408086
                    ],
                    [
                        -80.83021015777251,
                        26.962893094740593
                    ],
                    [
                        -80.99999999990001,
                        27.349999999400495
                    ],
                    [
                        -81.09972222250252,
                        27.279444443706154
                    ],
                    [
                        -81.08305555556939,
                        27.250277777697306
                    ],
                    [
                        -81.28333333326668,
                        27.066666666033882
                    ],
                    [
                        -81.42499999994999,
                        26.946666666633405
                    ],
                    [
                        -81.57916666660833,
                        26.804166666558444
                    ],
                    [
                        -81.40833333391618,
                        26.649999999900103
                    ],
                    [
                        -81.08750000062452,
                        26.37083333259227
                    ],
                    [
                        -80.8902777783967,
                        26.18055555559448
                    ],
                    [
                        -80.82388888876119,
                        26.116666666133767
                    ],
                    [
                        -80.82388888876119,
                        26.074999999250622
                    ],
                    [
                        -80.82388888876119,
                        25.86666666663342
                    ],
                    [
                        -80.36666666723289,
                        25.86666666663342
                    ],
                    [
                        -80.26666666653341,
                        25.86666666663342
                    ],
                    [
                        -80.26666666653341,
                        26.049999999300553
                    ],
                    [
                        -80.40833333321672,
                        26.049999999300553
                    ],
                    [
                        -80.49999999999994,
                        26.233333332867062
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 765,
            "artcc": "zma",
            "sector": "47",
            "sectype": "low",
            "Shape_Length": 4.425326424435082,
            "Shape_Area": 0.7432971359827486
        }
    },
    {
        "type": "Feature",
        "id": 766,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -76.9072222224276,
                        29.733333333066923
                    ],
                    [
                        -76.91805555561933,
                        29.76666666603387
                    ],
                    [
                        -76.96666666683319,
                        29.76666666603387
                    ],
                    [
                        -76.96666666683319,
                        29.733333333066923
                    ],
                    [
                        -76.9072222224276,
                        29.733333333066923
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 766,
            "artcc": "zma",
            "sector": "47",
            "sectype": "low",
            "Shape_Length": 0.17643845626886528,
            "Shape_Area": 0.001800925907195704
        }
    },
    {
        "type": "Feature",
        "id": 768,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -73.50472222275232,
                        25.54083333299195
                    ],
                    [
                        -73.68388888936073,
                        25.855555555344665
                    ],
                    [
                        -73.75222222207788,
                        25.97388888886121
                    ],
                    [
                        -74.03333333336661,
                        26.46666666633365
                    ],
                    [
                        -74.0969444447303,
                        26.432222221878078
                    ],
                    [
                        -75.99999999999994,
                        25.383333332767165
                    ],
                    [
                        -76.41250000007489,
                        25.096944443930965
                    ],
                    [
                        -75.99999999999994,
                        24.86444444385603
                    ],
                    [
                        -74.83555555564436,
                        24.137499999525403
                    ],
                    [
                        -74.54527777794704,
                        23.92472222185313
                    ],
                    [
                        -75.0041666671579,
                        23.230277777097797
                    ],
                    [
                        -75.17694444473028,
                        22.963888888761232
                    ],
                    [
                        -74.75000000069946,
                        22.633333332867096
                    ],
                    [
                        -73.96555555594409,
                        22.051666665983873
                    ],
                    [
                        -73.52916666740771,
                        21.7141666666584
                    ],
                    [
                        -73.25500000044963,
                        21.500833332792126
                    ],
                    [
                        -72.73333333326667,
                        21.066666666333617
                    ],
                    [
                        -72.33583333334161,
                        21.385277777547458
                    ],
                    [
                        -72.22138888958557,
                        21.475555555644405
                    ],
                    [
                        -71.74222222267741,
                        21.164999999350528
                    ],
                    [
                        -71.36694444443054,
                        21.509166666708325
                    ],
                    [
                        -71.52777777822183,
                        22.062500000074976
                    ],
                    [
                        -71.59972222260245,
                        22.279722221903057
                    ],
                    [
                        -71.93555555604405,
                        23.27333333266722
                    ],
                    [
                        -72.28694444493016,
                        23.524444443656193
                    ],
                    [
                        -72.54611111173836,
                        24.942222221978
                    ],
                    [
                        -72.5563888887362,
                        24.999999999600334
                    ],
                    [
                        -73.2000000001998,
                        24.999999999600334
                    ],
                    [
                        -73.50472222275232,
                        25.54083333299195
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 768,
            "artcc": "zma",
            "sector": "58",
            "sectype": "low",
            "Shape_Length": 17.04942306127627,
            "Shape_Area": 12.491858795823758
        }
    },
    {
        "type": "Feature",
        "id": 770,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -76.38222222257747,
                        28.18833333291701
                    ],
                    [
                        -76.43111111098892,
                        28.131666666783303
                    ],
                    [
                        -77.00000000069946,
                        27.46666666613379
                    ],
                    [
                        -77.00000000069946,
                        27.000000000099988
                    ],
                    [
                        -77.00000000069946,
                        26.249999999800195
                    ],
                    [
                        -75.99999999999994,
                        25.383333332767165
                    ],
                    [
                        -74.0969444447303,
                        26.432222221878078
                    ],
                    [
                        -74.03333333336661,
                        26.46666666633365
                    ],
                    [
                        -74.24638888913586,
                        26.83166666668336
                    ],
                    [
                        -74.31361111126375,
                        26.945833333241808
                    ],
                    [
                        -74.61527777834675,
                        27.463055555369635
                    ],
                    [
                        -74.83333333356643,
                        27.83333333326675
                    ],
                    [
                        -75.18277777847169,
                        27.83472222195303
                    ],
                    [
                        -75.70888888891108,
                        27.83500000005
                    ],
                    [
                        -76.2644444443556,
                        27.83333333326675
                    ],
                    [
                        -76.38222222257747,
                        28.18833333291701
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 770,
            "artcc": "zma",
            "sector": "59",
            "sectype": "low",
            "Shape_Length": 9.123986770821222,
            "Shape_Area": 5.242209222309133
        }
    },
    {
        "type": "Feature",
        "id": 772,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -77.00000000069946,
                        24.89999999980023
                    ],
                    [
                        -77.00000000069946,
                        24.750000000099988
                    ],
                    [
                        -77.2777777784217,
                        24.40833333291704
                    ],
                    [
                        -77.32916666700805,
                        24.350000000000023
                    ],
                    [
                        -77.70222222227773,
                        23.88777777722271
                    ],
                    [
                        -77.37833333351648,
                        23.657222222027997
                    ],
                    [
                        -77.42583333324171,
                        23.60916666610882
                    ],
                    [
                        -75.99999999999994,
                        22.606666666133776
                    ],
                    [
                        -75.58916666670825,
                        22.31361111106395
                    ],
                    [
                        -75.55333333356646,
                        22.370555555294686
                    ],
                    [
                        -75.17694444473028,
                        22.963888888761232
                    ],
                    [
                        -75.0041666671579,
                        23.230277777097797
                    ],
                    [
                        -74.54527777794704,
                        23.92472222185313
                    ],
                    [
                        -74.83555555564436,
                        24.137499999525403
                    ],
                    [
                        -75.99999999999994,
                        24.86444444385603
                    ],
                    [
                        -75.99999999999994,
                        24.808333333016947
                    ],
                    [
                        -77.00000000069946,
                        24.89999999980023
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 772,
            "artcc": "zma",
            "sector": "60",
            "sectype": "low",
            "Shape_Length": 8.687580861983324,
            "Shape_Area": 4.543054398238225
        }
    },
    {
        "type": "Feature",
        "id": 773,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -75.99999999999994,
                        25.383333332767165
                    ],
                    [
                        -77.00000000069946,
                        26.249999999800195
                    ],
                    [
                        -77.9583333336164,
                        26.249999999800195
                    ],
                    [
                        -78.77138888908593,
                        26.249999999800195
                    ],
                    [
                        -78.96666666733279,
                        25.966666666433582
                    ],
                    [
                        -78.50000000039967,
                        25.66666666613378
                    ],
                    [
                        -78.50000000039967,
                        25.349999999800218
                    ],
                    [
                        -78.50000000039967,
                        25.066666666433548
                    ],
                    [
                        -78.67916666700802,
                        25.05833333341667
                    ],
                    [
                        -78.5833333332667,
                        24.949999999700253
                    ],
                    [
                        -78.5833333332667,
                        24.39222222217785
                    ],
                    [
                        -78.5833333332667,
                        24.04916666630868
                    ],
                    [
                        -78.5833333332667,
                        23.999999999800195
                    ],
                    [
                        -78.16666666713292,
                        23.999999999800195
                    ],
                    [
                        -78.0000000004996,
                        23.999999999800195
                    ],
                    [
                        -77.75000000009987,
                        23.83333333316682
                    ],
                    [
                        -77.70222222227773,
                        23.88777777722271
                    ],
                    [
                        -77.32916666700805,
                        24.350000000000023
                    ],
                    [
                        -77.2777777784217,
                        24.40833333291704
                    ],
                    [
                        -77.00000000069946,
                        24.750000000099988
                    ],
                    [
                        -77.00000000069946,
                        24.89999999980023
                    ],
                    [
                        -75.99999999999994,
                        24.808333333016947
                    ],
                    [
                        -75.99999999999994,
                        24.86444444385603
                    ],
                    [
                        -75.99999999999994,
                        25.383333332767165
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 773,
            "artcc": "zma",
            "sector": "61",
            "sectype": "low",
            "Shape_Length": 9.665057257328797,
            "Shape_Area": 4.4089687497887855
        }
    },
    {
        "type": "Feature",
        "id": 775,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -68.34999999999997,
                        20.499999999600334
                    ],
                    [
                        -68.28972222220273,
                        20.566111111138923
                    ],
                    [
                        -71.93555555604405,
                        23.27333333266722
                    ],
                    [
                        -71.59972222260245,
                        22.279722221903057
                    ],
                    [
                        -71.52777777822183,
                        22.062500000074976
                    ],
                    [
                        -71.36694444443054,
                        21.509166666708325
                    ],
                    [
                        -71.74222222267741,
                        21.164999999350528
                    ],
                    [
                        -71.61722222292718,
                        21.08333333326675
                    ],
                    [
                        -70.95611111113885,
                        20.418333332617237
                    ],
                    [
                        -70.80399166658214,
                        20.416666666733306
                    ],
                    [
                        -70.78732500054832,
                        20.416666666733306
                    ],
                    [
                        -70.5000000001998,
                        20.416666666733306
                    ],
                    [
                        -70.47527777834676,
                        20.403611110564327
                    ],
                    [
                        -70.43277777807197,
                        20.381111110789163
                    ],
                    [
                        -70.35259999989006,
                        20.338027777859736
                    ],
                    [
                        -70.33699999995002,
                        20.329922221533366
                    ],
                    [
                        -70.25000000069946,
                        20.283333333066935
                    ],
                    [
                        -70.0676555562589,
                        20.1793055549075
                    ],
                    [
                        -70.05261111161349,
                        20.170616666825765
                    ],
                    [
                        -69.73727777834677,
                        19.989777777422546
                    ],
                    [
                        -69.6986666669331,
                        19.950375000006318
                    ],
                    [
                        -69.66594999999245,
                        19.948486110745478
                    ],
                    [
                        -69.15000000019978,
                        19.649999999500437
                    ],
                    [
                        -68.34999999999997,
                        20.499999999600334
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 775,
            "artcc": "zma",
            "sector": "62",
            "sectype": "low",
            "Shape_Length": 11.262164838889563,
            "Shape_Area": 5.154442412163623
        }
    },
    {
        "type": "Feature",
        "id": 776,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -67.75000000029974,
                        21.13333333316683
                    ],
                    [
                        -67.6505555557942,
                        21.239166666708343
                    ],
                    [
                        -67.78399166668203,
                        21.84506388888741
                    ],
                    [
                        -67.80212500016859,
                        21.92933888837905
                    ],
                    [
                        -68.027499999925,
                        22.950000000099976
                    ],
                    [
                        -68.23472222285227,
                        23.85944444370614
                    ],
                    [
                        -68.26777777772224,
                        24.007222222227824
                    ],
                    [
                        -68.49250000007493,
                        24.999999999600334
                    ],
                    [
                        -69.00361111126375,
                        24.999999999600334
                    ],
                    [
                        -69.18222222257748,
                        24.999999999600334
                    ],
                    [
                        -69.96027777829681,
                        24.999999999600334
                    ],
                    [
                        -70.14583333394114,
                        24.999999999600334
                    ],
                    [
                        -70.90361111106387,
                        24.999999999600334
                    ],
                    [
                        -71.06861111181331,
                        24.999999999600334
                    ],
                    [
                        -71.88694444483025,
                        24.999999999600334
                    ],
                    [
                        -72.06833333351648,
                        24.999999999600334
                    ],
                    [
                        -72.38694444473032,
                        24.999999999600334
                    ],
                    [
                        -72.5563888887362,
                        24.999999999600334
                    ],
                    [
                        -72.54611111173836,
                        24.942222221978
                    ],
                    [
                        -72.28694444493016,
                        23.524444443656193
                    ],
                    [
                        -71.93555555604405,
                        23.27333333266722
                    ],
                    [
                        -68.28972222220273,
                        20.566111111138923
                    ],
                    [
                        -67.75000000029974,
                        21.13333333316683
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 776,
            "artcc": "zma",
            "sector": "63",
            "sectype": "low",
            "Shape_Length": 15.318927307298962,
            "Shape_Area": 13.038764322968815
        }
    },
    {
        "type": "Feature",
        "id": 779,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -81.2333333333666,
                        27.999999999900126
                    ],
                    [
                        -81.37500000004991,
                        27.999999999900126
                    ],
                    [
                        -81.66666666733278,
                        27.950000000000045
                    ],
                    [
                        -81.83333333396615,
                        27.933333333066912
                    ],
                    [
                        -81.9833333336664,
                        27.78333333336667
                    ],
                    [
                        -82.06666666653342,
                        27.766666666433537
                    ],
                    [
                        -82.25000000009987,
                        27.41666666623371
                    ],
                    [
                        -82.12250000017485,
                        27.143333332667225
                    ],
                    [
                        -82.10111111098894,
                        27.09722222162827
                    ],
                    [
                        -81.84999999999997,
                        27.116666666833282
                    ],
                    [
                        -81.791666667083,
                        27.094722221453424
                    ],
                    [
                        -81.54303888913341,
                        27.00049722176942
                    ],
                    [
                        -81.42499999994999,
                        26.946666666633405
                    ],
                    [
                        -81.36694444423068,
                        26.923611110664297
                    ],
                    [
                        -81.28333333326668,
                        27.066666666033882
                    ],
                    [
                        -81.08305555556939,
                        27.250277777697306
                    ],
                    [
                        -81.09972222250252,
                        27.279444443706154
                    ],
                    [
                        -80.99999999990001,
                        27.349999999400495
                    ],
                    [
                        -81.12500000054956,
                        27.54583333294204
                    ],
                    [
                        -81.15000000049957,
                        27.591666666783283
                    ],
                    [
                        -81.20000000039965,
                        27.749999999500403
                    ],
                    [
                        -81.2333333333666,
                        27.749999999500403
                    ],
                    [
                        -81.2333333333666,
                        27.999999999900126
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 779,
            "artcc": "zma",
            "sector": "66",
            "sectype": "low",
            "Shape_Length": 3.74908319211198,
            "Shape_Area": 0.8789929486403195
        }
    },
    {
        "type": "Feature",
        "id": 780,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -81.2333333333666,
                        27.999999999900126
                    ],
                    [
                        -81.29722222282726,
                        28.141666666583433
                    ],
                    [
                        -81.40972222260245,
                        28.38638888853643
                    ],
                    [
                        -81.50000000069946,
                        28.34638888843648
                    ],
                    [
                        -81.86222222277735,
                        28.184999999350566
                    ],
                    [
                        -81.9119444445804,
                        28.162499999575346
                    ],
                    [
                        -82.20694444453045,
                        28.02999999930057
                    ],
                    [
                        -82.3833333337663,
                        27.950000000000045
                    ],
                    [
                        -82.35861111101394,
                        27.858888888511444
                    ],
                    [
                        -82.25000000009987,
                        27.41666666623371
                    ],
                    [
                        -82.12250000017485,
                        27.143333332667225
                    ],
                    [
                        -82.10111111098894,
                        27.09722222162827
                    ],
                    [
                        -82.01813391235015,
                        27.10364744777155
                    ],
                    [
                        -82.00000000059953,
                        27.07916666600886
                    ],
                    [
                        -81.99166666668327,
                        26.81666666653348
                    ],
                    [
                        -81.9833333336664,
                        26.600000000000023
                    ],
                    [
                        -81.94166666678319,
                        26.566666666133813
                    ],
                    [
                        -81.62361111176335,
                        26.5938888881617
                    ],
                    [
                        -81.40833333391618,
                        26.649999999900103
                    ],
                    [
                        -81.57916666660833,
                        26.804166666558444
                    ],
                    [
                        -81.42499999994999,
                        26.946666666633405
                    ],
                    [
                        -81.36694444423068,
                        26.923611110664297
                    ],
                    [
                        -81.28333333326668,
                        27.066666666033882
                    ],
                    [
                        -81.08305555556939,
                        27.250277777697306
                    ],
                    [
                        -81.09972222250252,
                        27.279444443706154
                    ],
                    [
                        -80.99999999990001,
                        27.349999999400495
                    ],
                    [
                        -81.12500000054956,
                        27.54583333294204
                    ],
                    [
                        -81.15000000049957,
                        27.591666666783283
                    ],
                    [
                        -81.20000000039965,
                        27.749999999500403
                    ],
                    [
                        -81.2333333333666,
                        27.749999999500403
                    ],
                    [
                        -81.2333333333666,
                        27.999999999900126
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 780,
            "artcc": "zma",
            "sector": "67",
            "sectype": "low",
            "Shape_Length": 5.411674596138358,
            "Shape_Area": 1.490825336430471
        }
    },
    {
        "type": "Feature",
        "id": 788,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -92.67083333389121,
                        34.26249999997509
                    ],
                    [
                        -92.26666666683315,
                        34.58333333326675
                    ],
                    [
                        -92.26666666683315,
                        34.79999999980021
                    ],
                    [
                        -92.6458333339412,
                        35.197222221628294
                    ],
                    [
                        -93.21666666673326,
                        35.66666666683324
                    ],
                    [
                        -93.78333333346654,
                        35.5500000001
                    ],
                    [
                        -94.16666666663332,
                        35.44999999940052
                    ],
                    [
                        -94.07499999985009,
                        34.81638888863637
                    ],
                    [
                        -93.99999999999994,
                        34.26666666603387
                    ],
                    [
                        -93.65833333371631,
                        34.08888888901106
                    ],
                    [
                        -93.54166666698308,
                        34.03333333256728
                    ],
                    [
                        -93.15833333381624,
                        33.94999999970025
                    ],
                    [
                        -92.67083333389121,
                        34.26249999997509
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 788,
            "artcc": "zme",
            "sector": "01",
            "sectype": "low",
            "Shape_Length": 5.676360261754109,
            "Shape_Area": 2.2505381940278766
        }
    },
    {
        "type": "Feature",
        "id": 789,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -94.07499999985009,
                        34.81638888863637
                    ],
                    [
                        -94.16666666663332,
                        35.44999999940052
                    ],
                    [
                        -93.78333333346654,
                        35.5500000001
                    ],
                    [
                        -93.21666666673326,
                        35.66666666683324
                    ],
                    [
                        -93.28694444433063,
                        35.82444444425573
                    ],
                    [
                        -93.36666666733282,
                        36.00000000009999
                    ],
                    [
                        -93.52638888873616,
                        36.38333333326676
                    ],
                    [
                        -93.58333333386622,
                        36.51944444430569
                    ],
                    [
                        -93.53333333396614,
                        36.66666666663343
                    ],
                    [
                        -93.7500000004996,
                        36.62499999975023
                    ],
                    [
                        -93.7869444442307,
                        36.61833333261728
                    ],
                    [
                        -94.4083333340161,
                        36.4916666660838
                    ],
                    [
                        -94.68333333346652,
                        36.43333333316684
                    ],
                    [
                        -94.71000000019984,
                        36.42583333264224
                    ],
                    [
                        -95.01666666673327,
                        36.34027777769734
                    ],
                    [
                        -95.19583333334162,
                        36.28749999952544
                    ],
                    [
                        -95.39999999990005,
                        36.20416666665841
                    ],
                    [
                        -95.4875000006245,
                        36.133333332867096
                    ],
                    [
                        -95.61250000037467,
                        36.0166666661338
                    ],
                    [
                        -95.3666666669331,
                        35.87083333339166
                    ],
                    [
                        -95.00000000069946,
                        35.65416666595894
                    ],
                    [
                        -95.00000000069946,
                        35.383333332567304
                    ],
                    [
                        -95.00000000069946,
                        35.06666666623369
                    ],
                    [
                        -94.92499999994999,
                        34.933333332567315
                    ],
                    [
                        -94.81666666713295,
                        34.755555555544504
                    ],
                    [
                        -94.78333333326668,
                        34.69166666608385
                    ],
                    [
                        -94.53333333376628,
                        34.53333333336667
                    ],
                    [
                        -93.99999999999994,
                        34.26666666603387
                    ],
                    [
                        -94.07499999985009,
                        34.81638888863637
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 789,
            "artcc": "zme",
            "sector": "02",
            "sectype": "low",
            "Shape_Length": 8.088637132675354,
            "Shape_Area": 2.6648568676001396
        }
    },
    {
        "type": "Feature",
        "id": 790,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -91.86666666673324,
                        34.94999999950045
                    ],
                    [
                        -91.66666666713297,
                        35.150000000000034
                    ],
                    [
                        -91.46388888926077,
                        35.36249999957539
                    ],
                    [
                        -91.41666666673325,
                        35.41666666643357
                    ],
                    [
                        -91.46666666663333,
                        35.88333333336669
                    ],
                    [
                        -91.72500000004993,
                        36.083333332967015
                    ],
                    [
                        -92.69891666667081,
                        36.68821388866007
                    ],
                    [
                        -92.80000000059954,
                        36.7499999995004
                    ],
                    [
                        -92.86583333314178,
                        36.789999999600354
                    ],
                    [
                        -93.13749999992501,
                        36.74416666665837
                    ],
                    [
                        -93.25000000059953,
                        36.72499999955039
                    ],
                    [
                        -93.53333333396614,
                        36.66666666663343
                    ],
                    [
                        -93.58333333386622,
                        36.51944444430569
                    ],
                    [
                        -93.52638888873616,
                        36.38333333326676
                    ],
                    [
                        -93.36666666733282,
                        36.00000000009999
                    ],
                    [
                        -93.28694444433063,
                        35.82444444425573
                    ],
                    [
                        -93.21666666673326,
                        35.66666666683324
                    ],
                    [
                        -92.6458333339412,
                        35.197222221628294
                    ],
                    [
                        -92.26666666683315,
                        34.79999999980021
                    ],
                    [
                        -91.86666666673324,
                        34.94999999950045
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 790,
            "artcc": "zme",
            "sector": "03",
            "sectype": "low",
            "Shape_Length": 6.264592969072552,
            "Shape_Area": 2.4872859918601247
        }
    },
    {
        "type": "Feature",
        "id": 791,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -89.9763888895356,
                        35.095833333141854
                    ],
                    [
                        -89.93333333396617,
                        35.695833332842085
                    ],
                    [
                        -89.93333333396617,
                        35.72916666670835
                    ],
                    [
                        -89.96833333371632,
                        36.251666666383585
                    ],
                    [
                        -89.9669444450301,
                        36.410277777197734
                    ],
                    [
                        -90.38138888908594,
                        36.916388888936126
                    ],
                    [
                        -90.38194444438057,
                        37.19222222177814
                    ],
                    [
                        -90.41694444503008,
                        37.18416666595891
                    ],
                    [
                        -90.5666666666333,
                        37.14999999960037
                    ],
                    [
                        -90.88277777767229,
                        37.10333333326679
                    ],
                    [
                        -92.51488055609275,
                        36.8478777773376
                    ],
                    [
                        -92.64388888906092,
                        36.826666666133804
                    ],
                    [
                        -92.86583333314178,
                        36.789999999600354
                    ],
                    [
                        -92.80000000059954,
                        36.7499999995004
                    ],
                    [
                        -92.69891666667081,
                        36.68821388866007
                    ],
                    [
                        -91.72500000004993,
                        36.083333332967015
                    ],
                    [
                        -91.46666666663333,
                        35.88333333336669
                    ],
                    [
                        -91.41666666673325,
                        35.41666666643357
                    ],
                    [
                        -91.00000000059953,
                        35.299999999700276
                    ],
                    [
                        -90.7500000001998,
                        35.26666666673333
                    ],
                    [
                        -90.30083333359141,
                        35.251388888486474
                    ],
                    [
                        -90.17750000062449,
                        35.18749999992508
                    ],
                    [
                        -90.16361111106391,
                        35.08666666673332
                    ],
                    [
                        -89.9763888895356,
                        35.095833333141854
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 791,
            "artcc": "zme",
            "sector": "04",
            "sectype": "low",
            "Shape_Length": 8.464316740143694,
            "Shape_Area": 3.3333561418217226
        }
    },
    {
        "type": "Feature",
        "id": 792,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -87.7930555554695,
                        37.46999999950043
                    ],
                    [
                        -88.3166666666333,
                        37.72499999935053
                    ],
                    [
                        -88.83333333346656,
                        37.53333333276714
                    ],
                    [
                        -89.04999999999995,
                        37.48333333286706
                    ],
                    [
                        -89.18333333366638,
                        37.45833333291705
                    ],
                    [
                        -89.45833333401612,
                        37.604166666558456
                    ],
                    [
                        -90.0916666666833,
                        37.50833333281713
                    ],
                    [
                        -90.03444444435559,
                        37.27083333329176
                    ],
                    [
                        -90.33555555614396,
                        37.20305555496992
                    ],
                    [
                        -90.38194444438057,
                        37.19222222177814
                    ],
                    [
                        -90.38138888908594,
                        36.916388888936126
                    ],
                    [
                        -89.9669444450301,
                        36.410277777197734
                    ],
                    [
                        -89.96833333371632,
                        36.251666666383585
                    ],
                    [
                        -89.02527777814691,
                        36.3202777771977
                    ],
                    [
                        -88.26666666673327,
                        36.36666666633363
                    ],
                    [
                        -88.03888888891106,
                        36.45944444370616
                    ],
                    [
                        -87.8075000003247,
                        36.618611110714255
                    ],
                    [
                        -88.07500000014983,
                        36.78333333336667
                    ],
                    [
                        -88.06972222260248,
                        37.013055555169785
                    ],
                    [
                        -87.83444444425567,
                        37.41083333319182
                    ],
                    [
                        -87.7930555554695,
                        37.46999999950043
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 792,
            "artcc": "zme",
            "sector": "05",
            "sectype": "low",
            "Shape_Length": 7.44315054326572,
            "Shape_Area": 2.7702485340356975
        }
    },
    {
        "type": "Feature",
        "id": 793,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -88.8325000000749,
                        35.371944444080896
                    ],
                    [
                        -88.7077777784217,
                        35.54611111123887
                    ],
                    [
                        -88.21055555589413,
                        35.71166666638362
                    ],
                    [
                        -88.26666666673327,
                        35.94999999930059
                    ],
                    [
                        -88.26666666673327,
                        36.21666666663339
                    ],
                    [
                        -88.26666666673327,
                        36.36666666633363
                    ],
                    [
                        -89.02527777814691,
                        36.3202777771977
                    ],
                    [
                        -89.96833333371632,
                        36.251666666383585
                    ],
                    [
                        -89.93333333396617,
                        35.72916666670835
                    ],
                    [
                        -89.93333333396617,
                        35.695833332842085
                    ],
                    [
                        -89.9763888895356,
                        35.095833333141854
                    ],
                    [
                        -89.81055555629388,
                        35.10499999955039
                    ],
                    [
                        -89.81000000009988,
                        35.17861111071426
                    ],
                    [
                        -89.70972222220274,
                        35.25555555544457
                    ],
                    [
                        -89.35000000029976,
                        35.33333333266722
                    ],
                    [
                        -89.25416666655838,
                        35.358333332617235
                    ],
                    [
                        -89.2500000004996,
                        35.34999999960036
                    ],
                    [
                        -89.21916666680818,
                        35.28583333294199
                    ],
                    [
                        -88.8325000000749,
                        35.371944444080896
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 793,
            "artcc": "zme",
            "sector": "07",
            "sectype": "low",
            "Shape_Length": 5.573735598398167,
            "Shape_Area": 1.5749560186713458
        }
    },
    {
        "type": "Feature",
        "id": 794,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -88.63333333386623,
                        34.149999999300576
                    ],
                    [
                        -88.39166666738276,
                        34.24444444435568
                    ],
                    [
                        -88.75000000059953,
                        34.383333332767165
                    ],
                    [
                        -89.32500000034969,
                        34.58333333326675
                    ],
                    [
                        -89.466666667033,
                        34.63333333316683
                    ],
                    [
                        -89.63333333366637,
                        34.69166666608385
                    ],
                    [
                        -89.81666666723288,
                        34.94999999950045
                    ],
                    [
                        -89.81055555629388,
                        35.10499999955039
                    ],
                    [
                        -89.9763888895356,
                        35.095833333141854
                    ],
                    [
                        -90.16361111106391,
                        35.08666666673332
                    ],
                    [
                        -90.15000000049957,
                        34.94999999950045
                    ],
                    [
                        -90.30000000019982,
                        34.53333333336667
                    ],
                    [
                        -90.30000000019982,
                        34.44999999960038
                    ],
                    [
                        -90.26666666723287,
                        33.84999999990015
                    ],
                    [
                        -89.98333333386626,
                        33.84999999990015
                    ],
                    [
                        -89.99166666688313,
                        33.38333333296697
                    ],
                    [
                        -89.30000000039968,
                        33.39027777729763
                    ],
                    [
                        -89.29166666738274,
                        33.55833333261728
                    ],
                    [
                        -89.21666666663333,
                        33.92499999975024
                    ],
                    [
                        -89.03055555569432,
                        34.11111111068925
                    ],
                    [
                        -88.80000000049961,
                        34.06666666643355
                    ],
                    [
                        -88.63333333386623,
                        34.149999999300576
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 794,
            "artcc": "zme",
            "sector": "12",
            "sectype": "low",
            "Shape_Length": 6.337887457349766,
            "Shape_Area": 1.6079947916213602
        }
    },
    {
        "type": "Feature",
        "id": 795,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -87.55000000029975,
                        34.01666666653347
                    ],
                    [
                        -87.24999999999994,
                        34.099999999400495
                    ],
                    [
                        -87.03333333346654,
                        34.099999999400495
                    ],
                    [
                        -86.71333333356648,
                        34.099999999400495
                    ],
                    [
                        -86.60000000039969,
                        34.149999999300576
                    ],
                    [
                        -86.45023055629508,
                        34.234022221848136
                    ],
                    [
                        -86.17500000034971,
                        34.387499999725264
                    ],
                    [
                        -86.07500000054955,
                        34.63611111053939
                    ],
                    [
                        -87.0000000004996,
                        34.933333332567315
                    ],
                    [
                        -87.36666666673324,
                        34.937499999525414
                    ],
                    [
                        -87.78333333376628,
                        34.94166666648351
                    ],
                    [
                        -88.03333333326668,
                        34.9166666665335
                    ],
                    [
                        -88.1000000000999,
                        34.650000000099965
                    ],
                    [
                        -88.2833333336664,
                        34.200000000099976
                    ],
                    [
                        -88.06666666713295,
                        34.11666666633363
                    ],
                    [
                        -87.56666666723288,
                        33.890277777197696
                    ],
                    [
                        -87.55000000029975,
                        34.01666666653347
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 795,
            "artcc": "zme",
            "sector": "13",
            "sectype": "low",
            "Shape_Length": 5.402203819262141,
            "Shape_Area": 1.5367093342341744
        }
    },
    {
        "type": "Feature",
        "id": 796,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -88.1000000000999,
                        34.650000000099965
                    ],
                    [
                        -88.03333333326668,
                        34.9166666665335
                    ],
                    [
                        -88.14999999999998,
                        35.44999999940052
                    ],
                    [
                        -88.21055555589413,
                        35.71166666638362
                    ],
                    [
                        -88.7077777784217,
                        35.54611111123887
                    ],
                    [
                        -89.25416666655838,
                        35.358333332617235
                    ],
                    [
                        -89.35000000029976,
                        35.33333333266722
                    ],
                    [
                        -89.70972222220274,
                        35.25555555544457
                    ],
                    [
                        -89.81000000009988,
                        35.17861111071426
                    ],
                    [
                        -89.81055555629388,
                        35.10499999955039
                    ],
                    [
                        -89.81666666723288,
                        34.94999999950045
                    ],
                    [
                        -89.63333333366637,
                        34.69166666608385
                    ],
                    [
                        -89.466666667033,
                        34.63333333316683
                    ],
                    [
                        -89.32500000034969,
                        34.58333333326675
                    ],
                    [
                        -88.75000000059953,
                        34.383333332767165
                    ],
                    [
                        -88.39166666738276,
                        34.24444444435568
                    ],
                    [
                        -88.2833333336664,
                        34.200000000099976
                    ],
                    [
                        -88.1000000000999,
                        34.650000000099965
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 796,
            "artcc": "zme",
            "sector": "14",
            "sectype": "low",
            "Shape_Length": 5.253115311091809,
            "Shape_Area": 1.6690678631261728
        }
    },
    {
        "type": "Feature",
        "id": 797,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -90.15000000049957,
                        34.94999999950045
                    ],
                    [
                        -90.16361111106391,
                        35.08666666673332
                    ],
                    [
                        -90.17750000062449,
                        35.18749999992508
                    ],
                    [
                        -90.30083333359141,
                        35.251388888486474
                    ],
                    [
                        -90.7500000001998,
                        35.26666666673333
                    ],
                    [
                        -91.00000000059953,
                        35.299999999700276
                    ],
                    [
                        -91.41666666673325,
                        35.41666666643357
                    ],
                    [
                        -91.46388888926077,
                        35.36249999957539
                    ],
                    [
                        -91.66666666713297,
                        35.150000000000034
                    ],
                    [
                        -91.86666666673324,
                        34.94999999950045
                    ],
                    [
                        -92.26666666683315,
                        34.79999999980021
                    ],
                    [
                        -92.26666666683315,
                        34.58333333326675
                    ],
                    [
                        -92.0833333332667,
                        34.58333333326675
                    ],
                    [
                        -91.65000000019984,
                        34.230555555694366
                    ],
                    [
                        -91.55555555604406,
                        34.15277777757245
                    ],
                    [
                        -91.36666666683317,
                        33.999999999600334
                    ],
                    [
                        -90.48333333376632,
                        33.84999999990015
                    ],
                    [
                        -90.26666666723287,
                        33.84999999990015
                    ],
                    [
                        -90.30000000019982,
                        34.44999999960038
                    ],
                    [
                        -90.30000000019982,
                        34.53333333336667
                    ],
                    [
                        -90.15000000049957,
                        34.94999999950045
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 797,
            "artcc": "zme",
            "sector": "15",
            "sectype": "low",
            "Shape_Length": 6.151800047650485,
            "Shape_Area": 2.1894113808191746
        }
    },
    {
        "type": "Feature",
        "id": 812,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -85.40000000009991,
                        36.18333333276712
                    ],
                    [
                        -85.58333333366636,
                        36.900000000099965
                    ],
                    [
                        -85.96361111136366,
                        37.1666666665335
                    ],
                    [
                        -86.00000000069946,
                        37.19444444385607
                    ],
                    [
                        -86.30833333401614,
                        36.6541666666584
                    ],
                    [
                        -86.53333333356647,
                        36.06666666603388
                    ],
                    [
                        -85.89999999999998,
                        35.89999999940051
                    ],
                    [
                        -85.32083333329166,
                        35.824999999550414
                    ],
                    [
                        -85.40000000009991,
                        36.18333333276712
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 812,
            "artcc": "zme",
            "sector": "40",
            "sectype": "low",
            "Shape_Length": 4.107036483358231,
            "Shape_Area": 0.9918271613248133
        }
    },
    {
        "type": "Feature",
        "id": 813,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -87.63333333316677,
                        37.391666666083836
                    ],
                    [
                        -87.7930555554695,
                        37.46999999950043
                    ],
                    [
                        -87.83444444425567,
                        37.41083333319182
                    ],
                    [
                        -88.06972222260248,
                        37.013055555169785
                    ],
                    [
                        -88.07500000014983,
                        36.78333333336667
                    ],
                    [
                        -87.8075000003247,
                        36.618611110714255
                    ],
                    [
                        -87.72138888918585,
                        36.56472222195305
                    ],
                    [
                        -87.55305555576922,
                        36.54000000009995
                    ],
                    [
                        -87.33472222245257,
                        36.42222222187809
                    ],
                    [
                        -86.78333333396614,
                        36.14999999980023
                    ],
                    [
                        -86.78333333396614,
                        36.06666666603388
                    ],
                    [
                        -86.53333333356647,
                        36.06666666603388
                    ],
                    [
                        -86.30833333401614,
                        36.6541666666584
                    ],
                    [
                        -86.00000000069946,
                        37.19444444385607
                    ],
                    [
                        -86.15000000039964,
                        37.29999999930055
                    ],
                    [
                        -86.4833333336664,
                        37.29999999930055
                    ],
                    [
                        -86.91666666673325,
                        37.288888888911174
                    ],
                    [
                        -87.39722222232768,
                        37.27499999935054
                    ],
                    [
                        -87.63333333316677,
                        37.391666666083836
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 813,
            "artcc": "zme",
            "sector": "41",
            "sectype": "low",
            "Shape_Length": 5.669767035617861,
            "Shape_Area": 1.713244482345591
        }
    },
    {
        "type": "Feature",
        "id": 814,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -87.0000000004996,
                        35.10833333311689
                    ],
                    [
                        -86.94194444478029,
                        35.343888888661354
                    ],
                    [
                        -86.86666666683317,
                        35.6499999999001
                    ],
                    [
                        -86.78333333396614,
                        36.06666666603388
                    ],
                    [
                        -86.78333333396614,
                        36.14999999980023
                    ],
                    [
                        -87.33472222245257,
                        36.42222222187809
                    ],
                    [
                        -87.55305555576922,
                        36.54000000009995
                    ],
                    [
                        -87.72138888918585,
                        36.56472222195305
                    ],
                    [
                        -87.8075000003247,
                        36.618611110714255
                    ],
                    [
                        -88.03888888891106,
                        36.45944444370616
                    ],
                    [
                        -88.26666666673327,
                        36.36666666633363
                    ],
                    [
                        -88.26666666673327,
                        36.21666666663339
                    ],
                    [
                        -88.26666666673327,
                        35.94999999930059
                    ],
                    [
                        -88.21055555589413,
                        35.71166666638362
                    ],
                    [
                        -88.14999999999998,
                        35.44999999940052
                    ],
                    [
                        -88.03333333326668,
                        34.9166666665335
                    ],
                    [
                        -87.78333333376628,
                        34.94166666648351
                    ],
                    [
                        -87.36666666673324,
                        34.937499999525414
                    ],
                    [
                        -87.0000000004996,
                        34.933333332567315
                    ],
                    [
                        -87.0000000004996,
                        35.10833333311689
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 814,
            "artcc": "zme",
            "sector": "42",
            "sectype": "low",
            "Shape_Length": 5.413273050420248,
            "Shape_Area": 1.9501924764190097
        }
    },
    {
        "type": "Feature",
        "id": 819,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -85.93055555559437,
                        34.81111111108896
                    ],
                    [
                        -85.48333333386626,
                        34.84999999970029
                    ],
                    [
                        -85.41658888916584,
                        35.00216111074673
                    ],
                    [
                        -85.33444444475526,
                        35.188333333316734
                    ],
                    [
                        -85.23333333346653,
                        35.41666666643357
                    ],
                    [
                        -85.25833333341654,
                        35.537500000124965
                    ],
                    [
                        -85.28333333336661,
                        35.6499999999001
                    ],
                    [
                        -85.32083333329166,
                        35.824999999550414
                    ],
                    [
                        -85.89999999999998,
                        35.89999999940051
                    ],
                    [
                        -86.53333333356647,
                        36.06666666603388
                    ],
                    [
                        -86.78333333396614,
                        36.06666666603388
                    ],
                    [
                        -86.86666666683317,
                        35.6499999999001
                    ],
                    [
                        -86.94194444478029,
                        35.343888888661354
                    ],
                    [
                        -87.0000000004996,
                        35.10833333311689
                    ],
                    [
                        -87.0000000004996,
                        34.933333332567315
                    ],
                    [
                        -86.07500000054955,
                        34.63611111053939
                    ],
                    [
                        -85.93055555559437,
                        34.81111111108896
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 819,
            "artcc": "zme",
            "sector": "60",
            "sectype": "low",
            "Shape_Length": 5.331027510850725,
            "Shape_Area": 1.8468241112945931
        }
    },
    {
        "type": "Feature",
        "id": 823,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -88.40000000039964,
                        33.058333332717154
                    ],
                    [
                        -88.43055555599409,
                        33.19999999940052
                    ],
                    [
                        -88.41777777792208,
                        33.391666665983905
                    ],
                    [
                        -89.00000000009987,
                        33.393055555569504
                    ],
                    [
                        -89.13555555584418,
                        33.3924999993755
                    ],
                    [
                        -89.30000000039968,
                        33.39027777729763
                    ],
                    [
                        -89.99166666688313,
                        33.38333333296697
                    ],
                    [
                        -90.0277777781219,
                        33.0930555552697
                    ],
                    [
                        -89.99166666688313,
                        32.783333333266796
                    ],
                    [
                        -89.93333333396617,
                        32.57499999975022
                    ],
                    [
                        -89.93333333396617,
                        31.930555554894966
                    ],
                    [
                        -89.93333333396617,
                        31.633333332867096
                    ],
                    [
                        -89.85000000019983,
                        31.63055555549454
                    ],
                    [
                        -89.58333333376629,
                        31.608333332917027
                    ],
                    [
                        -89.43333333316673,
                        31.591666665983894
                    ],
                    [
                        -89.16666666673325,
                        31.57499999995008
                    ],
                    [
                        -89.00833333401613,
                        31.550000000000068
                    ],
                    [
                        -88.90000000029971,
                        31.54722222172819
                    ],
                    [
                        -88.32500000054955,
                        31.5166666661338
                    ],
                    [
                        -88.34722222222774,
                        32.06944444420577
                    ],
                    [
                        -88.34444444485518,
                        32.272222222077914
                    ],
                    [
                        -88.34722222222774,
                        32.33055555499493
                    ],
                    [
                        -88.11111111138865,
                        32.508333332917005
                    ],
                    [
                        -87.99166666728286,
                        32.59166666668335
                    ],
                    [
                        -87.94583333344156,
                        32.62777777792218
                    ],
                    [
                        -87.8500000005995,
                        32.69722222212789
                    ],
                    [
                        -87.98611111163848,
                        33.04305555536962
                    ],
                    [
                        -88.2875000006245,
                        32.85277777747251
                    ],
                    [
                        -88.40000000039964,
                        33.058333332717154
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 823,
            "artcc": "zme",
            "sector": "65",
            "sectype": "low",
            "Shape_Length": 7.681588872566353,
            "Shape_Area": 3.118428433861714
        }
    },
    {
        "type": "Feature",
        "id": 824,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -89.93333333396617,
                        31.930555554894966
                    ],
                    [
                        -89.93333333396617,
                        32.57499999975022
                    ],
                    [
                        -89.99166666688313,
                        32.783333333266796
                    ],
                    [
                        -90.0277777781219,
                        33.0930555552697
                    ],
                    [
                        -89.99166666688313,
                        33.38333333296697
                    ],
                    [
                        -89.98333333386626,
                        33.84999999990015
                    ],
                    [
                        -90.26666666723287,
                        33.84999999990015
                    ],
                    [
                        -90.48333333376632,
                        33.84999999990015
                    ],
                    [
                        -90.58333333356643,
                        33.233333333266785
                    ],
                    [
                        -91.51666666653341,
                        32.75277777767235
                    ],
                    [
                        -91.78333333386621,
                        32.61666666663342
                    ],
                    [
                        -91.6888888888111,
                        32.28333333336667
                    ],
                    [
                        -91.55000000039968,
                        32.1499999997003
                    ],
                    [
                        -91.30833333391621,
                        31.91249999927561
                    ],
                    [
                        -90.90833333381624,
                        31.799999999500415
                    ],
                    [
                        -90.80000000009989,
                        31.766666666533467
                    ],
                    [
                        -90.65833333341658,
                        31.72916666660842
                    ],
                    [
                        -90.3458333331418,
                        31.64999999980023
                    ],
                    [
                        -90.03333333376628,
                        31.636111111138916
                    ],
                    [
                        -89.93333333396617,
                        31.633333332867096
                    ],
                    [
                        -89.93333333396617,
                        31.930555554894966
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 824,
            "artcc": "zme",
            "sector": "66",
            "sectype": "low",
            "Shape_Length": 6.991438927808721,
            "Shape_Area": 2.4855410878431643
        }
    },
    {
        "type": "Feature",
        "id": 825,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -91.51666666653341,
                        32.75277777767235
                    ],
                    [
                        -90.58333333356643,
                        33.233333333266785
                    ],
                    [
                        -90.48333333376632,
                        33.84999999990015
                    ],
                    [
                        -91.36666666683317,
                        33.999999999600334
                    ],
                    [
                        -91.55555555604406,
                        34.15277777757245
                    ],
                    [
                        -91.65000000019984,
                        34.230555555694366
                    ],
                    [
                        -92.0833333332667,
                        34.58333333326675
                    ],
                    [
                        -92.26666666683315,
                        34.58333333326675
                    ],
                    [
                        -92.67083333389121,
                        34.26249999997509
                    ],
                    [
                        -93.15833333381624,
                        33.94999999970025
                    ],
                    [
                        -92.99166666718293,
                        33.82499999995008
                    ],
                    [
                        -92.91666666733278,
                        33.7666666661338
                    ],
                    [
                        -92.68111111178837,
                        33.59388888856142
                    ],
                    [
                        -92.53333333326668,
                        33.48333333276713
                    ],
                    [
                        -91.9000000005995,
                        33.004166666758294
                    ],
                    [
                        -91.78333333386621,
                        32.61666666663342
                    ],
                    [
                        -91.51666666653341,
                        32.75277777767235
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 825,
            "artcc": "zme",
            "sector": "67",
            "sectype": "low",
            "Shape_Length": 7.051252939910569,
            "Shape_Area": 2.603983411061842
        }
    },
    {
        "type": "Feature",
        "id": 836,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -84.31583333314177,
                        45.86749999962535
                    ],
                    [
                        -84.8833333332667,
                        46.11666666663342
                    ],
                    [
                        -84.99999999999994,
                        46.3833333330669
                    ],
                    [
                        -84.99999999999994,
                        46.53333333276714
                    ],
                    [
                        -85.63333333356644,
                        46.7499999993006
                    ],
                    [
                        -85.88333333396616,
                        46.8000000001
                    ],
                    [
                        -86.4988888891109,
                        46.95833333281712
                    ],
                    [
                        -86.50249999987506,
                        45.99361111086415
                    ],
                    [
                        -86.50000000059953,
                        45.48333333306692
                    ],
                    [
                        -85.11666666673324,
                        45.033333333066935
                    ],
                    [
                        -84.71666666663333,
                        44.33333333266722
                    ],
                    [
                        -84.48972222220277,
                        44.09027777749748
                    ],
                    [
                        -84.16055555589418,
                        43.67138888838656
                    ],
                    [
                        -84.0372222229272,
                        43.683611111163884
                    ],
                    [
                        -83.29333333356647,
                        43.75583333274216
                    ],
                    [
                        -82.1694444446054,
                        43.85527777724769
                    ],
                    [
                        -82.30388888886108,
                        44.382222221978
                    ],
                    [
                        -82.34999999990004,
                        44.66666666683324
                    ],
                    [
                        -82.42138888898597,
                        44.915555555744334
                    ],
                    [
                        -82.5000000004996,
                        45.33333333336668
                    ],
                    [
                        -83.50000000029974,
                        45.78333333336667
                    ],
                    [
                        -84.13333333386623,
                        45.78333333336667
                    ],
                    [
                        -84.31583333314177,
                        45.86749999962535
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 836,
            "artcc": "zmp",
            "sector": "01",
            "sectype": "low",
            "Shape_Length": 12.668493969879197,
            "Shape_Area": 7.186563696811163
        }
    },
    {
        "type": "Feature",
        "id": 837,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -84.52249999987504,
                        43.63388888846151
                    ],
                    [
                        -84.16055555589418,
                        43.67138888838656
                    ],
                    [
                        -84.48972222220277,
                        44.09027777749748
                    ],
                    [
                        -84.71666666663333,
                        44.33333333266722
                    ],
                    [
                        -85.11666666673324,
                        45.033333333066935
                    ],
                    [
                        -86.50000000059953,
                        45.48333333306692
                    ],
                    [
                        -86.7025000003747,
                        45.235277777547424
                    ],
                    [
                        -86.93916666740773,
                        44.94258333265475
                    ],
                    [
                        -87.0000000004996,
                        44.86666666643356
                    ],
                    [
                        -87.11666666723289,
                        44.66666666683324
                    ],
                    [
                        -87.20000000009986,
                        44.533333333166865
                    ],
                    [
                        -87.54166666728281,
                        43.96666666643358
                    ],
                    [
                        -87.16944444450547,
                        43.69166666608385
                    ],
                    [
                        -87.0000000004996,
                        43.68499999985016
                    ],
                    [
                        -86.21666666723286,
                        43.65111111068927
                    ],
                    [
                        -85.74166666728286,
                        43.628055555619426
                    ],
                    [
                        -84.96361111156352,
                        43.58666666683325
                    ],
                    [
                        -84.52249999987504,
                        43.63388888846151
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 837,
            "artcc": "zmp",
            "sector": "02",
            "sectype": "low",
            "Shape_Length": 8.44923108923408,
            "Shape_Area": 3.9864858914138965
        }
    },
    {
        "type": "Feature",
        "id": 838,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -88.77500000054954,
                        47.90833333271718
                    ],
                    [
                        -89.10833333381629,
                        47.79999999990014
                    ],
                    [
                        -89.31666666733281,
                        47.79888888841151
                    ],
                    [
                        -89.41222222207784,
                        47.79833333311689
                    ],
                    [
                        -89.82500000024976,
                        47.89166666668336
                    ],
                    [
                        -91.08694444423065,
                        47.1872222221279
                    ],
                    [
                        -91.36666666683317,
                        47.02499999965033
                    ],
                    [
                        -91.896666667033,
                        46.72222222197803
                    ],
                    [
                        -92.16666666703304,
                        46.549999999700276
                    ],
                    [
                        -92.17388888946067,
                        46.21888888851146
                    ],
                    [
                        -92.17527777814689,
                        46.149166666208714
                    ],
                    [
                        -92.18333333396617,
                        45.76666666643354
                    ],
                    [
                        -91.53333333346654,
                        45.56666666683327
                    ],
                    [
                        -90.93166666698306,
                        45.374999999350564
                    ],
                    [
                        -91.00083333399112,
                        45.29055555499491
                    ],
                    [
                        -90.74166666718293,
                        45.091666665983894
                    ],
                    [
                        -90.60000000049956,
                        45.141666666783294
                    ],
                    [
                        -89.78333333336661,
                        45.43333333316684
                    ],
                    [
                        -89.6250000006495,
                        45.21666666663339
                    ],
                    [
                        -89.40000000019984,
                        45.21666666663339
                    ],
                    [
                        -88.41666666733278,
                        45.16666666673336
                    ],
                    [
                        -88.00000000029974,
                        45.16666666673336
                    ],
                    [
                        -87.61666666713296,
                        45.133333332867096
                    ],
                    [
                        -87.4000000005995,
                        45.033333333066935
                    ],
                    [
                        -87.11666666723289,
                        44.66666666683324
                    ],
                    [
                        -87.0000000004996,
                        44.86666666643356
                    ],
                    [
                        -86.93916666740773,
                        44.94258333265475
                    ],
                    [
                        -86.7025000003747,
                        45.235277777547424
                    ],
                    [
                        -86.50000000059953,
                        45.48333333306692
                    ],
                    [
                        -86.50249999987506,
                        45.99361111086415
                    ],
                    [
                        -86.4988888891109,
                        46.95833333281712
                    ],
                    [
                        -87.0000000004996,
                        47.08333333256729
                    ],
                    [
                        -87.50000000039967,
                        47.32083333299198
                    ],
                    [
                        -88.77500000054954,
                        47.90833333271718
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 838,
            "artcc": "zmp",
            "sector": "03",
            "sectype": "low",
            "Shape_Length": 15.280861642223726,
            "Shape_Area": 12.221670782152511
        }
    },
    {
        "type": "Feature",
        "id": 839,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -89.78333333336661,
                        45.43333333316684
                    ],
                    [
                        -90.60000000049956,
                        45.141666666783294
                    ],
                    [
                        -90.74166666718293,
                        45.091666665983894
                    ],
                    [
                        -90.44166666688312,
                        44.66666666683324
                    ],
                    [
                        -90.29611111133869,
                        44.54694444373115
                    ],
                    [
                        -90.08333333366636,
                        44.358333332617235
                    ],
                    [
                        -90.08277777837174,
                        44.299999999700276
                    ],
                    [
                        -90.08111111158848,
                        44.16666666603385
                    ],
                    [
                        -90.08333333366636,
                        44.15416666605887
                    ],
                    [
                        -89.97777777822188,
                        44.15416666605887
                    ],
                    [
                        -89.62916666670827,
                        44.15416666605887
                    ],
                    [
                        -89.58750000072439,
                        44.15416666605887
                    ],
                    [
                        -89.37083333329167,
                        44.18333333296698
                    ],
                    [
                        -89.07611111143859,
                        44.22499999985018
                    ],
                    [
                        -88.7541666666583,
                        44.15944444450554
                    ],
                    [
                        -88.80833333351649,
                        44.26249999977523
                    ],
                    [
                        -89.06666666693309,
                        44.749999999700265
                    ],
                    [
                        -88.41666666733278,
                        45.16666666673336
                    ],
                    [
                        -89.40000000019984,
                        45.21666666663339
                    ],
                    [
                        -89.6250000006495,
                        45.21666666663339
                    ],
                    [
                        -89.78333333336661,
                        45.43333333316684
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 839,
            "artcc": "zmp",
            "sector": "04",
            "sectype": "low",
            "Shape_Length": 6.4736561889487705,
            "Shape_Area": 1.7006307491870676
        }
    },
    {
        "type": "Feature",
        "id": 840,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -91.08333333346656,
                        43.36666666673335
                    ],
                    [
                        -90.85416666695806,
                        43.595833333241785
                    ],
                    [
                        -90.82916666700805,
                        43.61805555491998
                    ],
                    [
                        -90.76527777844666,
                        43.68888888871129
                    ],
                    [
                        -90.64166666738276,
                        43.81666666673334
                    ],
                    [
                        -90.55000000059954,
                        43.89999999960037
                    ],
                    [
                        -90.42222222257749,
                        44.038888888911174
                    ],
                    [
                        -90.37027777779718,
                        44.085833333341725
                    ],
                    [
                        -90.29166666718288,
                        44.15416666605887
                    ],
                    [
                        -90.08333333366636,
                        44.15416666605887
                    ],
                    [
                        -90.08111111158848,
                        44.16666666603385
                    ],
                    [
                        -90.08277777837174,
                        44.299999999700276
                    ],
                    [
                        -90.08333333366636,
                        44.358333332617235
                    ],
                    [
                        -90.29611111133869,
                        44.54694444373115
                    ],
                    [
                        -90.44166666688312,
                        44.66666666683324
                    ],
                    [
                        -90.74166666718293,
                        45.091666665983894
                    ],
                    [
                        -91.00083333399112,
                        45.29055555499491
                    ],
                    [
                        -90.93166666698306,
                        45.374999999350564
                    ],
                    [
                        -91.53333333346654,
                        45.56666666683327
                    ],
                    [
                        -92.18333333396617,
                        45.76666666643354
                    ],
                    [
                        -92.37416666715791,
                        45.66916666680828
                    ],
                    [
                        -92.7705555555944,
                        45.464722222152886
                    ],
                    [
                        -92.31777777822185,
                        45.037777777222686
                    ],
                    [
                        -92.36638888943565,
                        44.824999999550414
                    ],
                    [
                        -92.39944444430563,
                        44.57638888873629
                    ],
                    [
                        -92.75249999997499,
                        44.345555555444605
                    ],
                    [
                        -92.27916666680818,
                        44.10833333311689
                    ],
                    [
                        -91.78333333386621,
                        43.808333332817085
                    ],
                    [
                        -91.9666666665334,
                        43.599999999300564
                    ],
                    [
                        -91.11861111131373,
                        43.333333332867085
                    ],
                    [
                        -91.08333333346656,
                        43.36666666673335
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 840,
            "artcc": "zmp",
            "sector": "05",
            "sectype": "low",
            "Shape_Length": 8.768128478341968,
            "Shape_Area": 3.7347364582919425
        }
    },
    {
        "type": "Feature",
        "id": 841,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -90.74166666718293,
                        45.091666665983894
                    ],
                    [
                        -91.00083333399112,
                        45.29055555499491
                    ],
                    [
                        -90.93166666698306,
                        45.374999999350564
                    ],
                    [
                        -91.53333333346654,
                        45.56666666683327
                    ],
                    [
                        -92.18333333396617,
                        45.76666666643354
                    ],
                    [
                        -92.37416666715791,
                        45.66916666680828
                    ],
                    [
                        -92.7705555555944,
                        45.464722222152886
                    ],
                    [
                        -93.00138888888608,
                        45.30833333341667
                    ],
                    [
                        -93.08583333324168,
                        45.1572222222278
                    ],
                    [
                        -93.14388888896104,
                        45.0524999992756
                    ],
                    [
                        -92.36638888943565,
                        44.824999999550414
                    ],
                    [
                        -92.32805555611901,
                        44.8111111108891
                    ],
                    [
                        -91.77361111126373,
                        44.61249999997506
                    ],
                    [
                        -91.15722222272734,
                        44.384999999350555
                    ],
                    [
                        -90.83972222210281,
                        44.66972222230277
                    ],
                    [
                        -90.29611111133869,
                        44.54694444373115
                    ],
                    [
                        -90.44166666688312,
                        44.66666666683324
                    ],
                    [
                        -90.74166666718293,
                        45.091666665983894
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 841,
            "artcc": "zmp",
            "sector": "06",
            "sectype": "low",
            "Shape_Length": 6.76864377682143,
            "Shape_Area": 2.0126762349820475
        }
    },
    {
        "type": "Feature",
        "id": 842,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -92.71666666683319,
                        43.433333332667246
                    ],
                    [
                        -92.3500000005995,
                        43.461111110889135
                    ],
                    [
                        -92.05833333331663,
                        43.48888888821165
                    ],
                    [
                        -91.9666666665334,
                        43.599999999300564
                    ],
                    [
                        -91.78333333386621,
                        43.808333332817085
                    ],
                    [
                        -92.27916666680818,
                        44.10833333311689
                    ],
                    [
                        -92.75249999997499,
                        44.345555555444605
                    ],
                    [
                        -93.35750000002497,
                        44.749999999700265
                    ],
                    [
                        -93.38666666693308,
                        44.471388888586375
                    ],
                    [
                        -93.55416666695805,
                        44.31555555514478
                    ],
                    [
                        -93.78611111173836,
                        43.75833333291706
                    ],
                    [
                        -93.85833333331664,
                        43.45833333261726
                    ],
                    [
                        -92.89166666738276,
                        43.40833333271718
                    ],
                    [
                        -92.71666666683319,
                        43.433333332667246
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 842,
            "artcc": "zmp",
            "sector": "07",
            "sectype": "low",
            "Shape_Length": 5.484763519453757,
            "Shape_Area": 1.583104244734101
        }
    },
    {
        "type": "Feature",
        "id": 843,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -93.31055555559436,
                        44.27166666618376
                    ],
                    [
                        -93.46861111111383,
                        44.27333333296701
                    ],
                    [
                        -93.55416666695805,
                        44.31555555514478
                    ],
                    [
                        -93.38666666693308,
                        44.471388888586375
                    ],
                    [
                        -93.35750000002497,
                        44.749999999700265
                    ],
                    [
                        -93.62944444490518,
                        44.736944444430605
                    ],
                    [
                        -94.01944444430563,
                        44.60083333339168
                    ],
                    [
                        -94.0952777784467,
                        44.57194444368116
                    ],
                    [
                        -95.59777777832176,
                        44.02583333274214
                    ],
                    [
                        -95.12500000044963,
                        43.89999999960037
                    ],
                    [
                        -95.26666666713294,
                        43.53333333336667
                    ],
                    [
                        -93.85833333331664,
                        43.45833333261726
                    ],
                    [
                        -93.78611111173836,
                        43.75833333291706
                    ],
                    [
                        -93.38805555561936,
                        43.53333333336667
                    ],
                    [
                        -93.31055555559436,
                        44.27166666618376
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 843,
            "artcc": "zmp",
            "sector": "08",
            "sectype": "low",
            "Shape_Length": 6.928385075294872,
            "Shape_Area": 1.8053904708616983
        }
    },
    {
        "type": "Feature",
        "id": 844,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -94.0952777784467,
                        44.57194444368116
                    ],
                    [
                        -94.01944444430563,
                        44.60083333339168
                    ],
                    [
                        -93.62944444490518,
                        44.736944444430605
                    ],
                    [
                        -93.35750000002497,
                        44.749999999700265
                    ],
                    [
                        -93.23194444498012,
                        44.89027777769735
                    ],
                    [
                        -93.18999999999994,
                        44.96777777772229
                    ],
                    [
                        -93.14388888896104,
                        45.0524999992756
                    ],
                    [
                        -93.08583333324168,
                        45.1572222222278
                    ],
                    [
                        -93.23999999990002,
                        45.16555555524474
                    ],
                    [
                        -94.07777777812191,
                        45.18916666650853
                    ],
                    [
                        -95.08333333356643,
                        45.633333332767165
                    ],
                    [
                        -95.38333333386623,
                        45.51666666603387
                    ],
                    [
                        -96.0000000004996,
                        45.38333333326676
                    ],
                    [
                        -96.10833333331664,
                        45.0166666661338
                    ],
                    [
                        -96.12500000024977,
                        44.849999999500426
                    ],
                    [
                        -96.1333333332667,
                        44.533333333166865
                    ],
                    [
                        -96.20833333401612,
                        44.18333333296698
                    ],
                    [
                        -95.59777777832176,
                        44.02583333274214
                    ],
                    [
                        -94.0952777784467,
                        44.57194444368116
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 844,
            "artcc": "zmp",
            "sector": "09",
            "sectype": "low",
            "Shape_Length": 7.757412777397081,
            "Shape_Area": 2.8562511962789157
        }
    },
    {
        "type": "Feature",
        "id": 845,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -94.28666666693312,
                        46.57444444435566
                    ],
                    [
                        -94.97166666718289,
                        46.574999999650345
                    ],
                    [
                        -94.7819444445804,
                        45.99361111086415
                    ],
                    [
                        -94.68166666668327,
                        45.800555555594485
                    ],
                    [
                        -95.08333333356643,
                        45.633333332767165
                    ],
                    [
                        -94.07777777812191,
                        45.18916666650853
                    ],
                    [
                        -93.23999999990002,
                        45.16555555524474
                    ],
                    [
                        -93.08583333324168,
                        45.1572222222278
                    ],
                    [
                        -93.00138888888608,
                        45.30833333341667
                    ],
                    [
                        -92.7705555555944,
                        45.464722222152886
                    ],
                    [
                        -92.37416666715791,
                        45.66916666680828
                    ],
                    [
                        -92.18333333396617,
                        45.76666666643354
                    ],
                    [
                        -92.17527777814689,
                        46.149166666208714
                    ],
                    [
                        -92.17388888946067,
                        46.21888888851146
                    ],
                    [
                        -92.16666666703304,
                        46.549999999700276
                    ],
                    [
                        -93.03527777804698,
                        46.56555555514478
                    ],
                    [
                        -93.2819444448802,
                        46.564444443656214
                    ],
                    [
                        -94.28666666693312,
                        46.57444444435566
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 845,
            "artcc": "zmp",
            "sector": "10",
            "sectype": "low",
            "Shape_Length": 8.056912856707246,
            "Shape_Area": 3.272053008614968
        }
    },
    {
        "type": "Feature",
        "id": 860,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -91.15722222272734,
                        44.384999999350555
                    ],
                    [
                        -91.77361111126373,
                        44.61249999997506
                    ],
                    [
                        -92.32805555611901,
                        44.8111111108891
                    ],
                    [
                        -92.36638888943565,
                        44.824999999550414
                    ],
                    [
                        -93.14388888896104,
                        45.0524999992756
                    ],
                    [
                        -93.18999999999994,
                        44.96777777772229
                    ],
                    [
                        -93.23194444498012,
                        44.89027777769735
                    ],
                    [
                        -93.35750000002497,
                        44.749999999700265
                    ],
                    [
                        -92.75249999997499,
                        44.345555555444605
                    ],
                    [
                        -92.27916666680818,
                        44.10833333311689
                    ],
                    [
                        -91.78333333386621,
                        43.808333332817085
                    ],
                    [
                        -91.15722222272734,
                        44.384999999350555
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 860,
            "artcc": "zmp",
            "sector": "21",
            "sectype": "low",
            "Shape_Length": 5.157614164415402,
            "Shape_Area": 1.1731464116498356
        }
    },
    {
        "type": "Feature",
        "id": 862,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -99.58333333356643,
                        48.9999999993006
                    ],
                    [
                        -101.00000000039967,
                        48.9999999993006
                    ],
                    [
                        -103.16666666663332,
                        48.9999999993006
                    ],
                    [
                        -103.16666666663332,
                        47.84611111093909
                    ],
                    [
                        -103.16666666663332,
                        47.25000000009999
                    ],
                    [
                        -103.16666666663332,
                        46.449999999900115
                    ],
                    [
                        -103.16666666663332,
                        44.958333333216785
                    ],
                    [
                        -101.4833333333666,
                        44.699999999800184
                    ],
                    [
                        -99.94194444488016,
                        45.46861111101401
                    ],
                    [
                        -99.45416666685816,
                        45.70416666655848
                    ],
                    [
                        -98.99999999990001,
                        45.91666666613378
                    ],
                    [
                        -99.30416666715792,
                        47.63749999932554
                    ],
                    [
                        -99.36944444440553,
                        47.90555555534468
                    ],
                    [
                        -99.58333333356643,
                        48.9999999993006
                    ]
                ],
                [
                    [
                        -102.41666666723285,
                        48.13333333316683
                    ],
                    [
                        -102.2333333336664,
                        48.34999999970029
                    ],
                    [
                        -102.2333333336664,
                        48.89999999950044
                    ],
                    [
                        -102.09999999999997,
                        48.91666666643357
                    ],
                    [
                        -100.25000000009987,
                        48.91666666643357
                    ],
                    [
                        -100.25000000009987,
                        48.28333333286707
                    ],
                    [
                        -100.46666666663333,
                        48.29999999980021
                    ],
                    [
                        -100.46666666663333,
                        47.96666666653351
                    ],
                    [
                        -101.24999999990001,
                        47.649999999300576
                    ],
                    [
                        -102.41666666723285,
                        48.13333333316683
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 862,
            "artcc": "zmp",
            "sector": "23",
            "sectype": "low",
            "Shape_Length": 21.341989820068115,
            "Shape_Area": 13.222239579559748
        }
    },
    {
        "type": "Feature",
        "id": 864,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -95.50000000059953,
                        46.66666666643357
                    ],
                    [
                        -95.61250000037467,
                        47.06666666653348
                    ],
                    [
                        -95.6958333332417,
                        47.362499999875126
                    ],
                    [
                        -95.75000000009987,
                        47.58333333336668
                    ],
                    [
                        -95.61666666733282,
                        48.4166666665335
                    ],
                    [
                        -95.83194444428062,
                        48.6749999999501
                    ],
                    [
                        -96.08333333336662,
                        48.9999999993006
                    ],
                    [
                        -97.71666666673326,
                        48.9999999993006
                    ],
                    [
                        -99.58333333356643,
                        48.9999999993006
                    ],
                    [
                        -99.36944444440553,
                        47.90555555534468
                    ],
                    [
                        -99.30416666715792,
                        47.63749999932554
                    ],
                    [
                        -98.99999999990001,
                        45.91666666613378
                    ],
                    [
                        -98.00000000009987,
                        45.91666666613378
                    ],
                    [
                        -97.63333333386623,
                        45.85833333321682
                    ],
                    [
                        -96.4708333334915,
                        45.533333332967004
                    ],
                    [
                        -96.0000000004996,
                        45.38333333326676
                    ],
                    [
                        -95.38333333386623,
                        45.51666666603387
                    ],
                    [
                        -95.08333333356643,
                        45.633333332767165
                    ],
                    [
                        -94.68166666668327,
                        45.800555555594485
                    ],
                    [
                        -94.7819444445804,
                        45.99361111086415
                    ],
                    [
                        -94.97166666718289,
                        46.574999999650345
                    ],
                    [
                        -95.06777777812192,
                        46.57249999947544
                    ],
                    [
                        -95.50000000059953,
                        46.66666666643357
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 864,
            "artcc": "zmp",
            "sector": "24",
            "sectype": "low",
            "Shape_Length": 15.007881333459665,
            "Shape_Area": 12.576715469364101
        }
    },
    {
        "type": "Feature",
        "id": 866,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -95.3769444448302,
                        49.08194444438067
                    ],
                    [
                        -95.53333333356647,
                        49.16666666683324
                    ],
                    [
                        -96.0000000004996,
                        49.16666666683324
                    ],
                    [
                        -95.99861111181332,
                        49.08638888853642
                    ],
                    [
                        -96.0000000004996,
                        48.9999999993006
                    ],
                    [
                        -96.08333333336662,
                        48.9999999993006
                    ],
                    [
                        -95.83194444428062,
                        48.6749999999501
                    ],
                    [
                        -95.61666666733282,
                        48.4166666665335
                    ],
                    [
                        -95.75000000009987,
                        47.58333333336668
                    ],
                    [
                        -95.6958333332417,
                        47.362499999875126
                    ],
                    [
                        -95.61250000037467,
                        47.06666666653348
                    ],
                    [
                        -95.50000000059953,
                        46.66666666643357
                    ],
                    [
                        -95.06777777812192,
                        46.57249999947544
                    ],
                    [
                        -94.97166666718289,
                        46.574999999650345
                    ],
                    [
                        -94.7819444445804,
                        45.99361111086415
                    ],
                    [
                        -94.68166666668327,
                        45.800555555594485
                    ],
                    [
                        -94.20944444500509,
                        45.751666666283654
                    ],
                    [
                        -93.27305555566932,
                        46.236111110739216
                    ],
                    [
                        -92.99250000057452,
                        46.37805555551955
                    ],
                    [
                        -93.03527777804698,
                        46.56555555514478
                    ],
                    [
                        -92.16666666703304,
                        46.549999999700276
                    ],
                    [
                        -91.896666667033,
                        46.72222222197803
                    ],
                    [
                        -91.36666666683317,
                        47.02499999965033
                    ],
                    [
                        -91.08694444423065,
                        47.1872222221279
                    ],
                    [
                        -89.82500000024976,
                        47.89166666668336
                    ],
                    [
                        -89.99999999990001,
                        48.01666666643354
                    ],
                    [
                        -90.1000000005995,
                        48.10833333321682
                    ],
                    [
                        -91.81666666683316,
                        48.576944444130845
                    ],
                    [
                        -93.40000000029971,
                        48.97499999935053
                    ],
                    [
                        -93.50000000009987,
                        48.9999999993006
                    ],
                    [
                        -93.99999999999994,
                        48.9999999993006
                    ],
                    [
                        -94.86666666703303,
                        48.9999999993006
                    ],
                    [
                        -95.21666666723286,
                        48.9999999993006
                    ],
                    [
                        -95.3769444448302,
                        49.08194444438067
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 866,
            "artcc": "zmp",
            "sector": "25",
            "sectype": "low",
            "Shape_Length": 16.169809931005613,
            "Shape_Area": 12.15182187422496
        }
    },
    {
        "type": "Feature",
        "id": 867,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -96.35833333371636,
                        41.83333333316682
                    ],
                    [
                        -96.66666666703304,
                        41.79999999930055
                    ],
                    [
                        -96.83333333366636,
                        41.794444443656175
                    ],
                    [
                        -97.39166666738276,
                        41.72499999945046
                    ],
                    [
                        -97.76666666663334,
                        41.69999999950045
                    ],
                    [
                        -98.16666666673325,
                        41.70277777777227
                    ],
                    [
                        -99.02499999985008,
                        41.71666666643358
                    ],
                    [
                        -99.03333333376628,
                        41.07499999985015
                    ],
                    [
                        -99.16666666653339,
                        41.08888888851146
                    ],
                    [
                        -99.33333333316676,
                        40.899999999300576
                    ],
                    [
                        -99.33333333316676,
                        40.849999999400495
                    ],
                    [
                        -99.28888888891106,
                        40.36666666643356
                    ],
                    [
                        -99.05416666675825,
                        40.349999999500426
                    ],
                    [
                        -99.05555555544447,
                        40.23055555539463
                    ],
                    [
                        -99.05833333371635,
                        39.983333333266785
                    ],
                    [
                        -98.80000000029975,
                        39.46666666643358
                    ],
                    [
                        -97.88333333336658,
                        39.66666666603385
                    ],
                    [
                        -96.96666666733279,
                        39.86666666653349
                    ],
                    [
                        -96.08333333336662,
                        40.0500000001
                    ],
                    [
                        -95.91666666673325,
                        40.07916666610879
                    ],
                    [
                        -95.55000000049961,
                        40.1499999999001
                    ],
                    [
                        -95.75000000009987,
                        40.35833333341668
                    ],
                    [
                        -95.77500000004994,
                        40.483333333166854
                    ],
                    [
                        -95.80750000052456,
                        40.62222222157834
                    ],
                    [
                        -95.89166666678324,
                        41.16666666663343
                    ],
                    [
                        -96.03333333346654,
                        41.54166666678327
                    ],
                    [
                        -96.05833333341656,
                        41.608333332717166
                    ],
                    [
                        -96.35833333371636,
                        41.83333333316682
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 867,
            "artcc": "zmp",
            "sector": "26",
            "sectype": "low",
            "Shape_Length": 10.694677235362319,
            "Shape_Area": 6.411876156856826
        }
    },
    {
        "type": "Feature",
        "id": 868,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -94.08750000072439,
                        41.801666666083804
                    ],
                    [
                        -94.41944444440554,
                        41.900000000000034
                    ],
                    [
                        -94.66666666653339,
                        41.98333333286706
                    ],
                    [
                        -95.33333333396615,
                        42.183333333366704
                    ],
                    [
                        -95.46666666673326,
                        41.91666666603385
                    ],
                    [
                        -95.78333333396614,
                        41.8833333330669
                    ],
                    [
                        -96.35833333371636,
                        41.83333333316682
                    ],
                    [
                        -96.05833333341656,
                        41.608333332717166
                    ],
                    [
                        -96.03333333346654,
                        41.54166666678327
                    ],
                    [
                        -95.89166666678324,
                        41.16666666663343
                    ],
                    [
                        -95.80750000052456,
                        40.62222222157834
                    ],
                    [
                        -95.77500000004994,
                        40.483333333166854
                    ],
                    [
                        -95.75000000009987,
                        40.35833333341668
                    ],
                    [
                        -95.55000000049961,
                        40.1499999999001
                    ],
                    [
                        -94.49999999990001,
                        40.35055555569437
                    ],
                    [
                        -94.07250000057451,
                        40.427777777622396
                    ],
                    [
                        -93.90833333321672,
                        40.458333333216785
                    ],
                    [
                        -93.491666667083,
                        40.52500000005
                    ],
                    [
                        -93.55694444433061,
                        40.91749999962536
                    ],
                    [
                        -93.56249999997499,
                        40.9444444444556
                    ],
                    [
                        -93.58333333386622,
                        41.06666666683327
                    ],
                    [
                        -93.59999999990004,
                        41.16666666663343
                    ],
                    [
                        -93.50833333401613,
                        41.43333333306691
                    ],
                    [
                        -93.48499999994999,
                        41.566111110539396
                    ],
                    [
                        -93.80416666735778,
                        41.565555555244714
                    ],
                    [
                        -94.08750000072439,
                        41.801666666083804
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 868,
            "artcc": "zmp",
            "sector": "27",
            "sectype": "low",
            "Shape_Length": 8.301630649061021,
            "Shape_Area": 3.732442553310029
        }
    },
    {
        "type": "Feature",
        "id": 873,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -95.12500000044963,
                        43.89999999960037
                    ],
                    [
                        -95.59777777832176,
                        44.02583333274214
                    ],
                    [
                        -96.20833333401612,
                        44.18333333296698
                    ],
                    [
                        -96.1333333332667,
                        44.533333333166865
                    ],
                    [
                        -96.12500000024977,
                        44.849999999500426
                    ],
                    [
                        -96.10833333331664,
                        45.0166666661338
                    ],
                    [
                        -96.0000000004996,
                        45.38333333326676
                    ],
                    [
                        -96.4708333334915,
                        45.533333332967004
                    ],
                    [
                        -97.63333333386623,
                        45.85833333321682
                    ],
                    [
                        -98.00000000009987,
                        45.91666666613378
                    ],
                    [
                        -98.99999999990001,
                        45.91666666613378
                    ],
                    [
                        -99.45416666685816,
                        45.70416666655848
                    ],
                    [
                        -99.94194444488016,
                        45.46861111101401
                    ],
                    [
                        -101.4833333333666,
                        44.699999999800184
                    ],
                    [
                        -101.40833333351651,
                        43.70833333301698
                    ],
                    [
                        -100.10000000039969,
                        43.28888888861138
                    ],
                    [
                        -99.96666666673326,
                        43.13333333326676
                    ],
                    [
                        -99.00416666685817,
                        43.26944444430569
                    ],
                    [
                        -98.18083333349153,
                        43.39305555536964
                    ],
                    [
                        -97.58055555569433,
                        43.48611111083915
                    ],
                    [
                        -97.34166666658336,
                        43.50416666645856
                    ],
                    [
                        -96.74166666688313,
                        43.56666666633362
                    ],
                    [
                        -96.16666666713297,
                        43.48333333256727
                    ],
                    [
                        -95.98333333356646,
                        43.40833333271718
                    ],
                    [
                        -95.55000000049961,
                        43.23333333306692
                    ],
                    [
                        -95.49166666668327,
                        43.383333332767165
                    ],
                    [
                        -95.26666666713294,
                        43.53333333336667
                    ],
                    [
                        -95.12500000044963,
                        43.89999999960037
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 873,
            "artcc": "zmp",
            "sector": "33",
            "sectype": "low",
            "Shape_Length": 16.08140165297631,
            "Shape_Area": 12.07456230670548
        }
    },
    {
        "type": "Feature",
        "id": 875,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -93.21469563159809,
                        43.425041727293944
                    ],
                    [
                        -93.38805555561936,
                        43.53333333336667
                    ],
                    [
                        -93.78611111173836,
                        43.75833333291706
                    ],
                    [
                        -94.55833333371635,
                        44.10000000009995
                    ],
                    [
                        -95.12500000044963,
                        43.89999999960037
                    ],
                    [
                        -95.26666666713294,
                        43.53333333336667
                    ],
                    [
                        -95.49166666668327,
                        43.383333332767165
                    ],
                    [
                        -95.55000000049961,
                        43.23333333306692
                    ],
                    [
                        -95.59166666738275,
                        43.16666666623371
                    ],
                    [
                        -95.53333333356647,
                        42.999999999600334
                    ],
                    [
                        -95.39999999990005,
                        42.69999999930059
                    ],
                    [
                        -95.28333333316675,
                        42.2499999993006
                    ],
                    [
                        -95.33333333396615,
                        42.183333333366704
                    ],
                    [
                        -94.66666666653339,
                        41.98333333286706
                    ],
                    [
                        -94.41944444440554,
                        41.900000000000034
                    ],
                    [
                        -94.08750000072439,
                        41.801666666083804
                    ],
                    [
                        -93.80416666735778,
                        41.565555555244714
                    ],
                    [
                        -93.48499999994999,
                        41.566111110539396
                    ],
                    [
                        -93.46666666713293,
                        41.6666666665335
                    ],
                    [
                        -93.35555555604407,
                        41.947222221628294
                    ],
                    [
                        -93.26666666663334,
                        42.158333333416635
                    ],
                    [
                        -93.2375000006245,
                        42.22916666630863
                    ],
                    [
                        -93.05000000009989,
                        42.66666666633364
                    ],
                    [
                        -93.0000000001998,
                        42.783333333066935
                    ],
                    [
                        -92.46666666733279,
                        42.756944444430644
                    ],
                    [
                        -91.72500000004993,
                        42.7249999992506
                    ],
                    [
                        -91.66666666713297,
                        42.783333333066935
                    ],
                    [
                        -91.22083333319176,
                        43.23333333306692
                    ],
                    [
                        -91.11861111131373,
                        43.333333332867085
                    ],
                    [
                        -91.9666666665334,
                        43.599999999300564
                    ],
                    [
                        -92.05833333331663,
                        43.48888888821165
                    ],
                    [
                        -92.3500000005995,
                        43.461111110889135
                    ],
                    [
                        -92.71666666683319,
                        43.433333332667246
                    ],
                    [
                        -92.89166666738276,
                        43.40833333271718
                    ],
                    [
                        -93.21469563159809,
                        43.425041727293944
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 875,
            "artcc": "zmp",
            "sector": "36",
            "sectype": "low",
            "Shape_Length": 11.696068749044045,
            "Shape_Area": 5.613174286821468
        }
    },
    {
        "type": "Feature",
        "id": 876,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -97.39166666738276,
                        41.72499999945046
                    ],
                    [
                        -96.83333333366636,
                        41.794444443656175
                    ],
                    [
                        -96.66666666703304,
                        41.79999999930055
                    ],
                    [
                        -96.35833333371636,
                        41.83333333316682
                    ],
                    [
                        -95.78333333396614,
                        41.8833333330669
                    ],
                    [
                        -95.46666666673326,
                        41.91666666603385
                    ],
                    [
                        -95.33333333396615,
                        42.183333333366704
                    ],
                    [
                        -95.28333333316675,
                        42.2499999993006
                    ],
                    [
                        -95.39999999990005,
                        42.69999999930059
                    ],
                    [
                        -95.53333333356647,
                        42.999999999600334
                    ],
                    [
                        -95.59166666738275,
                        43.16666666623371
                    ],
                    [
                        -95.55000000049961,
                        43.23333333306692
                    ],
                    [
                        -95.98333333356646,
                        43.40833333271718
                    ],
                    [
                        -96.16666666713297,
                        43.48333333256727
                    ],
                    [
                        -96.74166666688313,
                        43.56666666633362
                    ],
                    [
                        -97.34166666658336,
                        43.50416666645856
                    ],
                    [
                        -97.58055555569433,
                        43.48611111083915
                    ],
                    [
                        -98.18083333349153,
                        43.39305555536964
                    ],
                    [
                        -99.00416666685817,
                        43.26944444430569
                    ],
                    [
                        -99.96666666673326,
                        43.13333333326676
                    ],
                    [
                        -99.01666666683315,
                        41.999999999800195
                    ],
                    [
                        -99.02499999985008,
                        41.71666666643358
                    ],
                    [
                        -98.16666666673325,
                        41.70277777777227
                    ],
                    [
                        -97.76666666663334,
                        41.69999999950045
                    ],
                    [
                        -97.39166666738276,
                        41.72499999945046
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 876,
            "artcc": "zmp",
            "sector": "37",
            "sectype": "low",
            "Shape_Length": 11.262857287376558,
            "Shape_Area": 6.548223572409908
        }
    },
    {
        "type": "Feature",
        "id": 911,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -76.09861111111383,
                        41.59027777709781
                    ],
                    [
                        -75.78833333381624,
                        41.754999999750225
                    ],
                    [
                        -75.34166666738275,
                        41.98888888851144
                    ],
                    [
                        -75.61666666683317,
                        42.104166666558456
                    ],
                    [
                        -76.01666666693308,
                        42.283333333166865
                    ],
                    [
                        -76.0091666673078,
                        42.53055555529471
                    ],
                    [
                        -75.96944444440555,
                        42.69583333324181
                    ],
                    [
                        -76.01388888956058,
                        42.862499999875126
                    ],
                    [
                        -76.08888888941067,
                        42.937499999725276
                    ],
                    [
                        -76.4833333338662,
                        42.941666666683375
                    ],
                    [
                        -76.66666666653339,
                        42.883333332867096
                    ],
                    [
                        -76.68916666720787,
                        42.768611111014025
                    ],
                    [
                        -76.69722222212783,
                        42.727777777522476
                    ],
                    [
                        -76.80277777847164,
                        42.60833333341668
                    ],
                    [
                        -77.11305555576922,
                        42.29888888861137
                    ],
                    [
                        -77.23611111153855,
                        42.17499999945045
                    ],
                    [
                        -77.25833333321674,
                        42.13888888821168
                    ],
                    [
                        -77.41666666683318,
                        41.87500000005002
                    ],
                    [
                        -77.4555555554445,
                        41.82083333319184
                    ],
                    [
                        -77.56666666653342,
                        41.637499999625334
                    ],
                    [
                        -77.63333333336658,
                        41.555555555444585
                    ],
                    [
                        -77.24944444490518,
                        41.584999999550405
                    ],
                    [
                        -76.45833333391619,
                        41.395833333141866
                    ],
                    [
                        -76.30138888898603,
                        41.370833333191854
                    ],
                    [
                        -76.11055555579424,
                        41.31277777747249
                    ],
                    [
                        -76.11250000067446,
                        41.445833333041946
                    ],
                    [
                        -76.10833333371636,
                        41.45833333301698
                    ],
                    [
                        -76.09111111148854,
                        41.559722221503364
                    ],
                    [
                        -76.09861111111383,
                        41.59027777709781
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 911,
            "artcc": "zny",
            "sector": "50",
            "sectype": "low",
            "Shape_Length": 6.379667934834913,
            "Shape_Area": 1.8652393904317135
        }
    },
    {
        "type": "Feature",
        "id": 912,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -74.80916666700801,
                        41.04444444425576
                    ],
                    [
                        -74.85611111143862,
                        41.11027777769732
                    ],
                    [
                        -74.86388888916088,
                        41.113888888461474
                    ],
                    [
                        -74.9163888892358,
                        41.15444444385605
                    ],
                    [
                        -74.83750000052459,
                        41.21666666653351
                    ],
                    [
                        -74.78361111176338,
                        41.26138888888619
                    ],
                    [
                        -74.70555555554444,
                        41.328055555719345
                    ],
                    [
                        -74.53638888873621,
                        41.458888888311606
                    ],
                    [
                        -74.51861111121377,
                        41.47333333316686
                    ],
                    [
                        -74.43333333346652,
                        41.527777777222695
                    ],
                    [
                        -74.52500000024975,
                        41.56249999977524
                    ],
                    [
                        -74.95833333331666,
                        41.741666666383594
                    ],
                    [
                        -75.05833333401614,
                        41.869444444405644
                    ],
                    [
                        -75.14777777782217,
                        41.90722222152834
                    ],
                    [
                        -75.34166666738275,
                        41.98888888851144
                    ],
                    [
                        -75.61666666683317,
                        42.104166666558456
                    ],
                    [
                        -76.04861111121375,
                        42.086111110939044
                    ],
                    [
                        -76.08805555601907,
                        41.69111111118889
                    ],
                    [
                        -76.09861111111383,
                        41.59027777709781
                    ],
                    [
                        -76.09111111148854,
                        41.559722221503364
                    ],
                    [
                        -76.10833333371636,
                        41.45833333301698
                    ],
                    [
                        -76.11250000067446,
                        41.445833333041946
                    ],
                    [
                        -76.11055555579424,
                        41.31277777747249
                    ],
                    [
                        -75.9152777784467,
                        41.25277777777228
                    ],
                    [
                        -75.60583333364139,
                        41.15916666610883
                    ],
                    [
                        -75.44000000039966,
                        40.97277777707279
                    ],
                    [
                        -75.44444444455542,
                        40.958333333116855
                    ],
                    [
                        -75.37944444450545,
                        40.94499999975022
                    ],
                    [
                        -75.36805555601904,
                        40.9444444444556
                    ],
                    [
                        -75.14472222235264,
                        40.99888888851143
                    ],
                    [
                        -75.0750000000499,
                        40.97861111081414
                    ],
                    [
                        -75.01666666713294,
                        40.960277777097815
                    ],
                    [
                        -74.98888888891105,
                        40.954166666158756
                    ],
                    [
                        -74.94250000067444,
                        40.964722222152886
                    ],
                    [
                        -74.85000000049956,
                        40.98333333306692
                    ],
                    [
                        -74.80916666700801,
                        41.04444444425576
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 912,
            "artcc": "zny",
            "sector": "51",
            "sectype": "low",
            "Shape_Length": 4.79037342204801,
            "Shape_Area": 1.2156263889746228
        }
    },
    {
        "type": "Feature",
        "id": 915,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -69.57750000042466,
                        38.24305555487001
                    ],
                    [
                        -69.39944444440556,
                        38.46444444365619
                    ],
                    [
                        -68.8833333337663,
                        38.58472222205296
                    ],
                    [
                        -68.7138888888611,
                        38.62277777727263
                    ],
                    [
                        -67.58833333401611,
                        38.878611110514385
                    ],
                    [
                        -67.56138888918582,
                        38.88416666615876
                    ],
                    [
                        -67.25000000039967,
                        38.95083333299198
                    ],
                    [
                        -66.99999999999994,
                        38.9999999995004
                    ],
                    [
                        -67.0001861111001,
                        39.11664444398593
                    ],
                    [
                        -66.99999999999994,
                        39.16083333329175
                    ],
                    [
                        -66.99999999999994,
                        39.61111111048939
                    ],
                    [
                        -66.99999999999994,
                        39.851388888286635
                    ],
                    [
                        -67.0002444447253,
                        40.116697222299024
                    ],
                    [
                        -66.99999999999994,
                        40.611111111188904
                    ],
                    [
                        -66.99999999999994,
                        41.08472222155331
                    ],
                    [
                        -67.00045000006742,
                        41.116677777857205
                    ],
                    [
                        -66.99999999999994,
                        41.15138888838652
                    ],
                    [
                        -66.99999999999994,
                        41.61666666663342
                    ],
                    [
                        -69.0000000004996,
                        41.00000000000006
                    ],
                    [
                        -69.34166666678323,
                        40.924999999250645
                    ],
                    [
                        -69.68416666735777,
                        40.84638888863634
                    ],
                    [
                        -70.87944444450545,
                        40.57666666673333
                    ],
                    [
                        -71.31111111168843,
                        40.44777777722271
                    ],
                    [
                        -71.33527777824685,
                        40.44138888818668
                    ],
                    [
                        -71.49999999999994,
                        40.38972222150335
                    ],
                    [
                        -72.50000000069946,
                        40.07222222177819
                    ],
                    [
                        -71.56277777797203,
                        39.64499999965034
                    ],
                    [
                        -71.2570222226974,
                        39.47783333334172
                    ],
                    [
                        -69.64463055555564,
                        38.17989722157955
                    ],
                    [
                        -69.57750000042466,
                        38.24305555487001
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 915,
            "artcc": "zny",
            "sector": "65",
            "sectype": "low",
            "Shape_Length": 14.616703324138028,
            "Shape_Area": 10.226220233131802
        }
    },
    {
        "type": "Feature",
        "id": 916,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -72.50000000069946,
                        40.07222222177819
                    ],
                    [
                        -72.78194444448047,
                        40.19861111111396
                    ],
                    [
                        -72.98888888931077,
                        40.29166666658347
                    ],
                    [
                        -73.25416666705797,
                        40.40972222200298
                    ],
                    [
                        -73.41472222275235,
                        40.47416666675832
                    ],
                    [
                        -73.5000000004996,
                        40.508333333116866
                    ],
                    [
                        -73.63611111153853,
                        40.331944443881014
                    ],
                    [
                        -73.69861111141364,
                        40.23888888841151
                    ],
                    [
                        -73.74999999999994,
                        40.16111111118886
                    ],
                    [
                        -73.70888888931074,
                        40.14861111121388
                    ],
                    [
                        -73.6680555558192,
                        40.13611111123885
                    ],
                    [
                        -73.68583333334163,
                        40.10888888831158
                    ],
                    [
                        -73.716666667033,
                        40.068611111014036
                    ],
                    [
                        -73.73712665367208,
                        40.040962480833855
                    ],
                    [
                        -73.79861111121375,
                        40.063888888761255
                    ],
                    [
                        -73.8958333336414,
                        40.137499999925126
                    ],
                    [
                        -74.00000000039967,
                        40.15416666595894
                    ],
                    [
                        -74.135277778047,
                        40.01583333284208
                    ],
                    [
                        -74.38333333356644,
                        39.76249999977523
                    ],
                    [
                        -74.3944444448552,
                        39.7491666664086
                    ],
                    [
                        -74.45555555604403,
                        39.655833332842064
                    ],
                    [
                        -74.49861111161346,
                        39.593055554870034
                    ],
                    [
                        -74.19444444435561,
                        39.57916666620872
                    ],
                    [
                        -74.09888888961052,
                        39.475555555644405
                    ],
                    [
                        -74.04277777787212,
                        39.42833333311688
                    ],
                    [
                        -73.99888888891104,
                        39.386111110939055
                    ],
                    [
                        -73.9263888892358,
                        39.314444443756145
                    ],
                    [
                        -73.65833333321672,
                        39.03472222205295
                    ],
                    [
                        -73.16666666723285,
                        39.68333333296698
                    ],
                    [
                        -73.15000000029971,
                        39.73333333286706
                    ],
                    [
                        -72.88361111106389,
                        39.73333333286706
                    ],
                    [
                        -72.86527777824682,
                        39.85222222167823
                    ],
                    [
                        -72.69722222292722,
                        39.9999999993006
                    ],
                    [
                        -72.50000000069946,
                        40.07222222177819
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 916,
            "artcc": "zny",
            "sector": "66",
            "sectype": "low",
            "Shape_Length": 5.524817854288333,
            "Shape_Area": 1.245417604635789
        }
    },
    {
        "type": "Feature",
        "id": 920,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -75.00472222245259,
                        40.490833332792135
                    ],
                    [
                        -74.97361111156351,
                        40.513888888761244
                    ],
                    [
                        -74.90499999985008,
                        40.57416666655848
                    ],
                    [
                        -74.8583333335165,
                        40.60833333291703
                    ],
                    [
                        -74.86138888898597,
                        40.64999999980023
                    ],
                    [
                        -74.87750000062448,
                        40.889722222302794
                    ],
                    [
                        -74.88027777799704,
                        40.929444444305716
                    ],
                    [
                        -74.94111111108884,
                        40.944166666358626
                    ],
                    [
                        -74.98888888891105,
                        40.954166666158756
                    ],
                    [
                        -75.01666666713294,
                        40.960277777097815
                    ],
                    [
                        -75.0750000000499,
                        40.97861111081414
                    ],
                    [
                        -75.14472222235264,
                        40.99888888851143
                    ],
                    [
                        -75.27833333321672,
                        41.0374999999251
                    ],
                    [
                        -75.29166666658335,
                        41.04999999990014
                    ],
                    [
                        -75.53722222282727,
                        41.136111111038986
                    ],
                    [
                        -75.60583333364139,
                        41.15916666610883
                    ],
                    [
                        -75.9152777784467,
                        41.25277777777228
                    ],
                    [
                        -76.11055555579424,
                        41.31277777747249
                    ],
                    [
                        -76.30138888898603,
                        41.370833333191854
                    ],
                    [
                        -76.33194444458042,
                        41.052500000074986
                    ],
                    [
                        -76.3333333332667,
                        41.03472222165328
                    ],
                    [
                        -76.35000000019983,
                        40.854166666358594
                    ],
                    [
                        -76.35277777847165,
                        40.768055555219746
                    ],
                    [
                        -75.72000000019983,
                        40.656388888836204
                    ],
                    [
                        -75.59999999990004,
                        40.638888888511474
                    ],
                    [
                        -75.45361111096395,
                        40.59000000009996
                    ],
                    [
                        -75.30777777822186,
                        40.54166666608381
                    ],
                    [
                        -75.06666666703302,
                        40.483333333166854
                    ],
                    [
                        -75.00472222245259,
                        40.490833332792135
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 920,
            "artcc": "zny",
            "sector": "74",
            "sectype": "low",
            "Shape_Length": 3.9877210695031406,
            "Shape_Area": 0.7694323687376915
        }
    },
    {
        "type": "Feature",
        "id": 923,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -64.99833333361642,
                        33.331666666283695
                    ],
                    [
                        -65.37277777757231,
                        33.18305555526973
                    ],
                    [
                        -65.66277777807198,
                        32.935277777847205
                    ],
                    [
                        -65.83305555546946,
                        32.61833333341667
                    ],
                    [
                        -65.8644444444555,
                        32.27138888868632
                    ],
                    [
                        -65.75444444395589,
                        31.93611111143872
                    ],
                    [
                        -65.51777777782218,
                        31.65277777807205
                    ],
                    [
                        -65.18361111116383,
                        31.45499999965034
                    ],
                    [
                        -64.79111111068914,
                        31.365833333041962
                    ],
                    [
                        -64.38694444453046,
                        31.395833333341727
                    ],
                    [
                        -64.01833333341659,
                        31.541666666983133
                    ],
                    [
                        -63.72833333291692,
                        31.785833333641506
                    ],
                    [
                        -63.552222221778095,
                        32.099722222602566
                    ],
                    [
                        -63.51222222257746,
                        32.44611111113892
                    ],
                    [
                        -63.6144444444555,
                        32.78305555516977
                    ],
                    [
                        -63.84805555601906,
                        33.07000000019991
                    ],
                    [
                        -64.18527777814688,
                        33.271388888486456
                    ],
                    [
                        -64.58527777824685,
                        33.362222221878085
                    ],
                    [
                        -64.99833333361642,
                        33.331666666283695
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 923,
            "artcc": "zny",
            "sector": "80",
            "sectype": "low",
            "Shape_Length": 6.833960379732635,
            "Shape_Area": 3.641814235315058
        }
    },
    {
        "type": "Feature",
        "id": 927,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -72.50000000069946,
                        40.07222222177819
                    ],
                    [
                        -72.78194444448047,
                        40.19861111111396
                    ],
                    [
                        -73.0083333336164,
                        39.99444444365622
                    ],
                    [
                        -73.15000000029971,
                        39.73333333286706
                    ],
                    [
                        -72.88361111106389,
                        39.73333333286706
                    ],
                    [
                        -72.50000000069946,
                        39.33722222162828
                    ],
                    [
                        -72.26999999990005,
                        39.09666666663344
                    ],
                    [
                        -72.50000000069946,
                        38.76666666603387
                    ],
                    [
                        -72.80000000009989,
                        38.333333332967015
                    ],
                    [
                        -72.83388888926078,
                        38.283333333066935
                    ],
                    [
                        -73.86111111108886,
                        38.73750000002502
                    ],
                    [
                        -73.98333333346653,
                        38.541666666483536
                    ],
                    [
                        -73.0083333336164,
                        37.949999999800184
                    ],
                    [
                        -72.66666666733278,
                        37.22749999962531
                    ],
                    [
                        -74.45611388934446,
                        37.09358611041074
                    ],
                    [
                        -74.59972222290224,
                        37.0816666659839
                    ],
                    [
                        -74.59972222290224,
                        36.78777777752248
                    ],
                    [
                        -74.49160000053956,
                        36.78383611104772
                    ],
                    [
                        -72.66611111113883,
                        36.70249999977523
                    ],
                    [
                        -72.47666666663332,
                        36.725277777647364
                    ],
                    [
                        -72.12611111113881,
                        36.808888888611364
                    ],
                    [
                        -72.05611111163847,
                        36.826666666133804
                    ],
                    [
                        -71.64750000042466,
                        36.9536111107642
                    ],
                    [
                        -71.25111111108885,
                        37.108333332717166
                    ],
                    [
                        -70.87388888886107,
                        37.288888888911174
                    ],
                    [
                        -70.51555555564431,
                        37.49527777754747
                    ],
                    [
                        -70.17638888953559,
                        37.72166666668335
                    ],
                    [
                        -69.86361111116383,
                        37.972222221478376
                    ],
                    [
                        -69.64463055555564,
                        38.17989722157955
                    ],
                    [
                        -71.2570222226974,
                        39.47783333334172
                    ],
                    [
                        -71.56277777797203,
                        39.64499999965034
                    ],
                    [
                        -72.50000000069946,
                        40.07222222177819
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 927,
            "artcc": "zny",
            "sector": "85",
            "sectype": "low",
            "Shape_Length": 17.380069126789273,
            "Shape_Area": 7.531795168423255
        }
    },
    {
        "type": "Feature",
        "id": 929,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -76.30138888898603,
                        41.370833333191854
                    ],
                    [
                        -76.30000000029975,
                        41.50111111048943
                    ],
                    [
                        -76.43222222247755,
                        41.64444444395599
                    ],
                    [
                        -76.61666666663331,
                        41.6736111108641
                    ],
                    [
                        -77.16666666733278,
                        41.73055555509484
                    ],
                    [
                        -77.37499999994998,
                        41.74999999940053
                    ],
                    [
                        -77.4555555554445,
                        41.82083333319184
                    ],
                    [
                        -77.56666666653342,
                        41.637499999625334
                    ],
                    [
                        -77.63333333336658,
                        41.555555555444585
                    ],
                    [
                        -77.64166666728283,
                        41.54166666678327
                    ],
                    [
                        -77.72500000014986,
                        41.41666666613378
                    ],
                    [
                        -77.80833333391621,
                        41.30833333331674
                    ],
                    [
                        -78.12916666720787,
                        41.28333333336667
                    ],
                    [
                        -78.32499999985009,
                        41.30694444373114
                    ],
                    [
                        -78.50000000039967,
                        41.14166666668336
                    ],
                    [
                        -78.50000000039967,
                        40.84166666638362
                    ],
                    [
                        -78.50000000039967,
                        40.65833333281711
                    ],
                    [
                        -77.9669444447303,
                        40.61138888838656
                    ],
                    [
                        -77.94138888948561,
                        40.5216666664835
                    ],
                    [
                        -77.90583333354147,
                        40.395555555244755
                    ],
                    [
                        -77.67222222287722,
                        40.50277777747249
                    ],
                    [
                        -77.30000000009989,
                        40.473055555269696
                    ],
                    [
                        -77.07472222245258,
                        40.457222221728216
                    ],
                    [
                        -76.85388888896102,
                        40.439166666108804
                    ],
                    [
                        -76.58611111103892,
                        40.42083333329174
                    ],
                    [
                        -76.3700000006994,
                        40.479166666208755
                    ],
                    [
                        -76.35277777847165,
                        40.768055555219746
                    ],
                    [
                        -76.35000000019983,
                        40.854166666358594
                    ],
                    [
                        -76.3333333332667,
                        41.03472222165328
                    ],
                    [
                        -76.33194444458042,
                        41.052500000074986
                    ],
                    [
                        -76.30138888898603,
                        41.370833333191854
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 929,
            "artcc": "zny",
            "sector": "91",
            "sectype": "low",
            "Shape_Length": 6.4717437974541365,
            "Shape_Area": 2.247289313382315
        }
    },
    {
        "type": "Feature",
        "id": 930,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -76.11250000067446,
                        41.445833333041946
                    ],
                    [
                        -76.18333333356645,
                        41.49166666598393
                    ],
                    [
                        -76.30000000029975,
                        41.50111111048943
                    ],
                    [
                        -76.30138888898603,
                        41.370833333191854
                    ],
                    [
                        -76.33194444458042,
                        41.052500000074986
                    ],
                    [
                        -76.3333333332667,
                        41.03472222165328
                    ],
                    [
                        -76.35000000019983,
                        40.854166666358594
                    ],
                    [
                        -76.35277777847165,
                        40.768055555219746
                    ],
                    [
                        -76.3700000006994,
                        40.479166666208755
                    ],
                    [
                        -76.58611111103892,
                        40.42083333329174
                    ],
                    [
                        -76.51722222212783,
                        40.408888888611386
                    ],
                    [
                        -76.29739444489769,
                        40.37348888840154
                    ],
                    [
                        -76.25647222241508,
                        40.36684166640981
                    ],
                    [
                        -76.16361111116379,
                        40.35194444438065
                    ],
                    [
                        -75.8875000002248,
                        40.306944443931
                    ],
                    [
                        -75.82255000068193,
                        40.26036388858262
                    ],
                    [
                        -75.80000000039968,
                        40.24444444405589
                    ],
                    [
                        -75.76944444480523,
                        40.219444444105875
                    ],
                    [
                        -75.68944444460539,
                        40.20222222187812
                    ],
                    [
                        -75.63194444508002,
                        40.16611111063929
                    ],
                    [
                        -75.60833333381629,
                        40.12499999995009
                    ],
                    [
                        -75.60166666668329,
                        40.074444443856066
                    ],
                    [
                        -75.42083333329168,
                        40.191666666783306
                    ],
                    [
                        -75.48333333316674,
                        40.254166666658364
                    ],
                    [
                        -75.51651225883364,
                        40.35607336589317
                    ],
                    [
                        -75.491666667083,
                        40.349999999500426
                    ],
                    [
                        -75.45388888906092,
                        40.34972222230277
                    ],
                    [
                        -75.43083333399113,
                        40.353055554969956
                    ],
                    [
                        -75.39972222220274,
                        40.361388888886154
                    ],
                    [
                        -75.37416666695805,
                        40.374722222252785
                    ],
                    [
                        -75.34777777832176,
                        40.40666666653351
                    ],
                    [
                        -75.39333333316677,
                        40.459722221903064
                    ],
                    [
                        -75.06666666703302,
                        40.483333333166854
                    ],
                    [
                        -75.10694444433062,
                        40.52500000005
                    ],
                    [
                        -75.01472222225271,
                        40.59611111103902
                    ],
                    [
                        -74.97083333329164,
                        40.629999999300594
                    ],
                    [
                        -74.97027777799701,
                        40.64249999927557
                    ],
                    [
                        -74.96999999990004,
                        40.67916666670834
                    ],
                    [
                        -75.35277777777219,
                        40.75833333261727
                    ],
                    [
                        -75.35277777777219,
                        40.83055555509486
                    ],
                    [
                        -75.40027777839674,
                        40.81666666643355
                    ],
                    [
                        -75.48333333316674,
                        40.8202777771977
                    ],
                    [
                        -75.59999999990004,
                        40.82305555546958
                    ],
                    [
                        -75.60210555562685,
                        40.956930555300914
                    ],
                    [
                        -75.60583333364139,
                        41.15916666610883
                    ],
                    [
                        -75.53722222282727,
                        41.136111111038986
                    ],
                    [
                        -75.59250000027475,
                        41.26472222155331
                    ],
                    [
                        -75.51250000007491,
                        41.345833333241785
                    ],
                    [
                        -75.69166666668326,
                        41.395833333141866
                    ],
                    [
                        -75.92000000069942,
                        41.38055555489501
                    ],
                    [
                        -75.99999999999994,
                        41.37638888883623
                    ],
                    [
                        -76.11250000067446,
                        41.445833333041946
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 930,
            "artcc": "zny",
            "sector": "92",
            "sectype": "low",
            "Shape_Length": 5.831756942979625,
            "Shape_Area": 1.0728936709582455
        }
    },
    {
        "type": "Feature",
        "id": 931,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -76.3700000006994,
                        40.479166666208755
                    ],
                    [
                        -76.35277777847165,
                        40.768055555219746
                    ],
                    [
                        -76.35000000019983,
                        40.854166666358594
                    ],
                    [
                        -76.3333333332667,
                        41.03472222165328
                    ],
                    [
                        -76.33194444458042,
                        41.052500000074986
                    ],
                    [
                        -76.30138888898603,
                        41.370833333191854
                    ],
                    [
                        -76.30000000029975,
                        41.50111111048943
                    ],
                    [
                        -76.41638888893601,
                        41.52083333289204
                    ],
                    [
                        -76.71666666733279,
                        41.55277777717271
                    ],
                    [
                        -77.18888888901097,
                        41.60277777707279
                    ],
                    [
                        -77.19666666673322,
                        41.60361111046444
                    ],
                    [
                        -77.24944444490518,
                        41.584999999550405
                    ],
                    [
                        -77.63333333336658,
                        41.555555555444585
                    ],
                    [
                        -77.64166666728283,
                        41.54166666678327
                    ],
                    [
                        -77.72500000014986,
                        41.41666666613378
                    ],
                    [
                        -77.80833333391621,
                        41.30833333331674
                    ],
                    [
                        -77.95000000059952,
                        41.11944444410585
                    ],
                    [
                        -78.12777777852165,
                        40.85833333331675
                    ],
                    [
                        -78.0972222229272,
                        40.81666666643355
                    ],
                    [
                        -77.98500000034971,
                        40.673888888261615
                    ],
                    [
                        -77.9669444447303,
                        40.61138888838656
                    ],
                    [
                        -77.94138888948561,
                        40.5216666664835
                    ],
                    [
                        -77.67222222287722,
                        40.50277777747249
                    ],
                    [
                        -77.30000000009989,
                        40.473055555269696
                    ],
                    [
                        -77.07472222245258,
                        40.457222221728216
                    ],
                    [
                        -76.85388888896102,
                        40.439166666108804
                    ],
                    [
                        -76.58611111103892,
                        40.42083333329174
                    ],
                    [
                        -76.3700000006994,
                        40.479166666208755
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 931,
            "artcc": "zny",
            "sector": "93",
            "sectype": "low",
            "Shape_Length": 5.198088355500474,
            "Shape_Area": 1.7391450617797573
        }
    },
    {
        "type": "Feature",
        "id": 944,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -120.30416666655839,
                        36.25249999977524
                    ],
                    [
                        -120.41444444425565,
                        36.40055555549452
                    ],
                    [
                        -120.60805555571926,
                        36.662222221578304
                    ],
                    [
                        -120.65222222277731,
                        36.653888888561426
                    ],
                    [
                        -120.81666666733281,
                        36.54999999990014
                    ],
                    [
                        -121.0650000000499,
                        36.43166666638359
                    ],
                    [
                        -121.11416666655839,
                        36.4080555551198
                    ],
                    [
                        -121.15388888946063,
                        36.38916666610879
                    ],
                    [
                        -121.55555555544447,
                        36.195833332742154
                    ],
                    [
                        -121.82500000014983,
                        36.15694444413083
                    ],
                    [
                        -122.04583333364138,
                        36.113888888561405
                    ],
                    [
                        -122.39277777837174,
                        36.18333333276712
                    ],
                    [
                        -122.41328888877115,
                        36.18140833302817
                    ],
                    [
                        -124.19999999990006,
                        36.00000000009999
                    ],
                    [
                        -123.91388888916089,
                        35.533333333166865
                    ],
                    [
                        -123.87500000054956,
                        35.46666666633365
                    ],
                    [
                        -123.55000000029975,
                        34.94166666648351
                    ],
                    [
                        -123.53333333336661,
                        34.9166666665335
                    ],
                    [
                        -123.24999999999994,
                        34.4999999995004
                    ],
                    [
                        -121.23722222232766,
                        35.37166666598392
                    ],
                    [
                        -120.85000000029976,
                        35.533333333166865
                    ],
                    [
                        -120.7083333336164,
                        35.40277777777226
                    ],
                    [
                        -120.52777777832176,
                        35.40277777777226
                    ],
                    [
                        -120.33333333346656,
                        35.4999999993006
                    ],
                    [
                        -120.2880555558192,
                        35.69944444450556
                    ],
                    [
                        -120.23194444498012,
                        35.71666666673332
                    ],
                    [
                        -120.1166666669331,
                        35.749999999700265
                    ],
                    [
                        -119.91750000072443,
                        35.71972222220285
                    ],
                    [
                        -119.9811111111888,
                        35.8077777773226
                    ],
                    [
                        -120.169444445105,
                        36.06666666603388
                    ],
                    [
                        -120.30416666655839,
                        36.25249999977524
                    ]
                ],
                [
                    [
                        -123.3750000006495,
                        35.513888888861175
                    ],
                    [
                        -123.35833333371636,
                        35.505555554944976
                    ],
                    [
                        -123.3750000006495,
                        35.49722222192804
                    ],
                    [
                        -123.3750000006495,
                        35.513888888861175
                    ]
                ],
                [
                    [
                        -122.00833333371634,
                        35.5083333332168
                    ],
                    [
                        -121.9916666667832,
                        35.4999999993006
                    ],
                    [
                        -122.00833333371634,
                        35.49166666628366
                    ],
                    [
                        -122.00833333371634,
                        35.5083333332168
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 944,
            "artcc": "zoa",
            "sector": "10",
            "sectype": "low",
            "Shape_Length": 10.566936965665441,
            "Shape_Area": 4.322482941236732
        }
    },
    {
        "type": "Feature",
        "id": 949,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -119.20833333391619,
                        36.07499999995008
                    ],
                    [
                        -119.16666666703304,
                        36.133333332867096
                    ],
                    [
                        -119.03888888901099,
                        36.133333332867096
                    ],
                    [
                        -119.00000000039967,
                        36.133333332867096
                    ],
                    [
                        -118.58333333336662,
                        36.133333332867096
                    ],
                    [
                        -118.58333333336662,
                        36.57083333289205
                    ],
                    [
                        -118.58333333336662,
                        36.76249999947544
                    ],
                    [
                        -118.58333333336662,
                        37.0805555553946
                    ],
                    [
                        -119.2666666668332,
                        37.0805555553946
                    ],
                    [
                        -119.31388888936073,
                        37.079166666708375
                    ],
                    [
                        -119.6000000000999,
                        36.81666666633362
                    ],
                    [
                        -120.04833333331663,
                        36.682777777372564
                    ],
                    [
                        -120.00111111168843,
                        36.66472222175321
                    ],
                    [
                        -119.76416666655837,
                        36.574444443656205
                    ],
                    [
                        -119.72805555621892,
                        36.50638888903603
                    ],
                    [
                        -119.66833333371636,
                        36.39527777704785
                    ],
                    [
                        -119.59166666708296,
                        36.247499999425486
                    ],
                    [
                        -119.54944444490519,
                        36.166944443931015
                    ],
                    [
                        -119.4969444448302,
                        36.066944444130854
                    ],
                    [
                        -119.47333333356647,
                        36.02194444368121
                    ],
                    [
                        -119.46277777847166,
                        36.00166666598392
                    ],
                    [
                        -119.37222222227768,
                        35.827777777822234
                    ],
                    [
                        -119.20833333391619,
                        36.07499999995008
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 949,
            "artcc": "zoa",
            "sector": "16",
            "sectype": "low",
            "Shape_Length": 4.633025934712308,
            "Shape_Area": 1.0645137737252086
        }
    },
    {
        "type": "Feature",
        "id": 950,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -119.6000000000999,
                        36.81666666633362
                    ],
                    [
                        -119.31388888936073,
                        37.079166666708375
                    ],
                    [
                        -119.2666666668332,
                        37.0805555553946
                    ],
                    [
                        -118.58333333336662,
                        37.0805555553946
                    ],
                    [
                        -118.58416666675822,
                        37.19999999950045
                    ],
                    [
                        -119.03333333336661,
                        37.51666666673333
                    ],
                    [
                        -119.15999999990004,
                        38.180277777797244
                    ],
                    [
                        -119.86666666653338,
                        38.08333333256729
                    ],
                    [
                        -120.17884722222152,
                        38.04095833266098
                    ],
                    [
                        -120.32888888931075,
                        38.02027777739755
                    ],
                    [
                        -120.53055555569432,
                        37.99083333329173
                    ],
                    [
                        -120.4186111112138,
                        37.789166666008896
                    ],
                    [
                        -120.41055555629384,
                        37.77472222205296
                    ],
                    [
                        -120.27611111113885,
                        37.488333333216815
                    ],
                    [
                        -120.18638888923584,
                        37.379999999500455
                    ],
                    [
                        -120.52500000004994,
                        37.337500000124976
                    ],
                    [
                        -120.88888888891108,
                        37.291666666283675
                    ],
                    [
                        -120.79388888946067,
                        37.04944444450558
                    ],
                    [
                        -120.2880555558192,
                        36.89388888826164
                    ],
                    [
                        -120.18972222280229,
                        36.73611111083915
                    ],
                    [
                        -120.04833333331663,
                        36.682777777372564
                    ],
                    [
                        -119.6000000000999,
                        36.81666666633362
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 950,
            "artcc": "zoa",
            "sector": "22",
            "sectype": "low",
            "Shape_Length": 6.853650638715479,
            "Shape_Area": 1.8892095584051203
        }
    },
    {
        "type": "Feature",
        "id": 957,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -120.53055555569432,
                        37.99083333329173
                    ],
                    [
                        -121.08333333286697,
                        37.90833333291704
                    ],
                    [
                        -121.1619444443806,
                        37.799166666708345
                    ],
                    [
                        -121.47499999995,
                        37.51249999977523
                    ],
                    [
                        -121.30916666670828,
                        37.450555555194796
                    ],
                    [
                        -121.22361111086406,
                        37.41833333371642
                    ],
                    [
                        -120.88888888891108,
                        37.291666666283675
                    ],
                    [
                        -120.52500000004994,
                        37.337500000124976
                    ],
                    [
                        -120.18638888923584,
                        37.380000000399775
                    ],
                    [
                        -120.27611111113885,
                        37.488333333216815
                    ],
                    [
                        -120.17958888861631,
                        37.54638888893612
                    ],
                    [
                        -119.9477972224638,
                        37.68284166680951
                    ],
                    [
                        -120.17884722222152,
                        38.0409583335603
                    ],
                    [
                        -120.32888888931075,
                        38.02027777739755
                    ],
                    [
                        -120.53055555569432,
                        37.99083333329173
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 957,
            "artcc": "zoa",
            "sector": "34",
            "sectype": "low",
            "Shape_Length": 3.7559910494385895,
            "Shape_Area": 0.728655609427839
        }
    },
    {
        "type": "Feature",
        "id": 958,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -125.8333333332667,
                        40.46666666623372
                    ],
                    [
                        -126.87499999994998,
                        40.97222222177817
                    ],
                    [
                        -126.89999999990005,
                        40.98333333306692
                    ],
                    [
                        -127.00000000059953,
                        40.83333333336668
                    ],
                    [
                        -127.00000000059953,
                        40.63083333269219
                    ],
                    [
                        -126.99166666668327,
                        40.62527777704781
                    ],
                    [
                        -127.00000000059953,
                        40.619722222302755
                    ],
                    [
                        -127.00000000059953,
                        40.304444443756154
                    ],
                    [
                        -126.99166666668327,
                        40.298888889011096
                    ],
                    [
                        -127.00000000059953,
                        40.29333333336672
                    ],
                    [
                        -127.00000000059953,
                        40.15694444423076
                    ],
                    [
                        -126.99166666668327,
                        40.15138888858638
                    ],
                    [
                        -127.00000000059953,
                        40.145833332942004
                    ],
                    [
                        -127.00000000059953,
                        40.01194444398095
                    ],
                    [
                        -126.99166666668327,
                        40.00638888833657
                    ],
                    [
                        -127.00000000059953,
                        39.9999999993006
                    ],
                    [
                        -127.00000000059953,
                        39.224999999950114
                    ],
                    [
                        -127.00000000059953,
                        39.06916666650852
                    ],
                    [
                        -126.99166666668327,
                        39.063611110864144
                    ],
                    [
                        -127.00000000059953,
                        39.05805555521977
                    ],
                    [
                        -127.00000000059953,
                        38.47194444418079
                    ],
                    [
                        -126.99166666668327,
                        38.466388888536414
                    ],
                    [
                        -127.00000000059953,
                        38.460555555694384
                    ],
                    [
                        -127.00000000059953,
                        38.13638888883622
                    ],
                    [
                        -126.99166666668327,
                        38.130833333191845
                    ],
                    [
                        -127.00000000059953,
                        38.12527777754747
                    ],
                    [
                        -127.00000000059953,
                        37.85416666605886
                    ],
                    [
                        -126.99166666668327,
                        37.84861111041448
                    ],
                    [
                        -127.00000000059953,
                        37.84305555566942
                    ],
                    [
                        -127.00000000059953,
                        37.511944444480605
                    ],
                    [
                        -126.99166666668327,
                        37.507222222227824
                    ],
                    [
                        -127.00000000059953,
                        37.50055555509482
                    ],
                    [
                        -126.93333333376631,
                        36.46694444423076
                    ],
                    [
                        -126.92444444455543,
                        36.464722222152886
                    ],
                    [
                        -126.92944444490519,
                        36.45916666650851
                    ],
                    [
                        -126.10833333361643,
                        35.741666666683386
                    ],
                    [
                        -126.07500000064948,
                        35.741666666683386
                    ],
                    [
                        -126.07500000064948,
                        35.70833333281712
                    ],
                    [
                        -125.95083333339159,
                        35.60416666605886
                    ],
                    [
                        -125.9330555558692,
                        35.604999999450456
                    ],
                    [
                        -125.9347222226524,
                        35.590277777397546
                    ],
                    [
                        -125.8333333332667,
                        35.4999999993006
                    ],
                    [
                        -125.08333333386622,
                        35.73333333276713
                    ],
                    [
                        -125.06666666693309,
                        35.75333333326677
                    ],
                    [
                        -125.02500000004994,
                        35.749999999700265
                    ],
                    [
                        -124.69166666678319,
                        35.851388888186705
                    ],
                    [
                        -124.19999999990006,
                        36.00000000009999
                    ],
                    [
                        -122.41328888877115,
                        36.18140833302817
                    ],
                    [
                        -122.39277777837174,
                        36.18333333276712
                    ],
                    [
                        -122.40253611145232,
                        36.19802222144841
                    ],
                    [
                        -122.5319444448802,
                        36.39222222157832
                    ],
                    [
                        -122.90638888883609,
                        36.9494444438061
                    ],
                    [
                        -122.93027777819685,
                        36.98333333296699
                    ],
                    [
                        -122.837499999925,
                        37.25555555504491
                    ],
                    [
                        -122.85277777817191,
                        37.43611111123886
                    ],
                    [
                        -122.85638888893607,
                        37.63166666668337
                    ],
                    [
                        -122.85833333381629,
                        37.73416666665838
                    ],
                    [
                        -122.99888888901097,
                        37.746666666633416
                    ],
                    [
                        -123.02555555574429,
                        37.750833332692196
                    ],
                    [
                        -123.16666666713297,
                        37.77361111056433
                    ],
                    [
                        -123.29722222252752,
                        37.93333333286705
                    ],
                    [
                        -123.29722222252752,
                        38.03333333266721
                    ],
                    [
                        -123.25388888886113,
                        38.11666666643356
                    ],
                    [
                        -123.43611111093901,
                        38.35833333291703
                    ],
                    [
                        -124.00000000029974,
                        38.84999999980022
                    ],
                    [
                        -124.00416666725783,
                        39.00416666645856
                    ],
                    [
                        -124.66666666683318,
                        40.21666666673332
                    ],
                    [
                        -125.33333333336662,
                        40.21666666673332
                    ],
                    [
                        -125.8333333332667,
                        40.46666666623372
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 958,
            "artcc": "zoa",
            "sector": "35",
            "sectype": "low",
            "Shape_Length": 17.104170559165375,
            "Shape_Area": 15.91819453501332
        }
    },
    {
        "type": "Feature",
        "id": 960,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -122.22916666720789,
                        38.62222222197801
                    ],
                    [
                        -122.16388888906096,
                        38.86666666673335
                    ],
                    [
                        -122.291666667083,
                        39.16666666613378
                    ],
                    [
                        -122.52666666733279,
                        39.22277777787218
                    ],
                    [
                        -123.28333333386621,
                        39.39861111091409
                    ],
                    [
                        -124.00416666725783,
                        39.00416666645856
                    ],
                    [
                        -124.00000000029974,
                        38.84999999980022
                    ],
                    [
                        -123.43611111093901,
                        38.35833333291703
                    ],
                    [
                        -123.25388888886113,
                        38.11666666643356
                    ],
                    [
                        -123.29722222252752,
                        38.03333333266721
                    ],
                    [
                        -123.29722222252752,
                        37.93333333286705
                    ],
                    [
                        -123.16666666713297,
                        37.77361111056433
                    ],
                    [
                        -123.02555555574429,
                        37.750833332692196
                    ],
                    [
                        -122.99888888901097,
                        37.746666666633416
                    ],
                    [
                        -122.85833333381629,
                        37.73416666665838
                    ],
                    [
                        -122.73888888881112,
                        37.81416666595891
                    ],
                    [
                        -122.66138888878618,
                        37.86583333264224
                    ],
                    [
                        -122.60000000039969,
                        37.89638888823663
                    ],
                    [
                        -122.53833333391617,
                        37.926944443831076
                    ],
                    [
                        -122.41666666683318,
                        37.999999999700265
                    ],
                    [
                        -122.28750000012485,
                        37.999999999700265
                    ],
                    [
                        -122.19333333316678,
                        38.05638888863638
                    ],
                    [
                        -122.14861111171342,
                        38.481111110589325
                    ],
                    [
                        -122.24250000057452,
                        38.481111110589325
                    ],
                    [
                        -122.22916666720789,
                        38.62222222197801
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 960,
            "artcc": "zoa",
            "sector": "40",
            "sectype": "low",
            "Shape_Length": 5.753340329688774,
            "Shape_Area": 2.010832677729219
        }
    },
    {
        "type": "Feature",
        "id": 961,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -122.291666667083,
                        39.16666666613378
                    ],
                    [
                        -122.52666666733279,
                        39.22277777787218
                    ],
                    [
                        -123.28333333386621,
                        39.39861111091409
                    ],
                    [
                        -124.00416666725783,
                        39.00416666645856
                    ],
                    [
                        -124.00000000029974,
                        38.84999999980022
                    ],
                    [
                        -123.43611111093901,
                        38.35833333291703
                    ],
                    [
                        -123.25388888886113,
                        38.11666666643356
                    ],
                    [
                        -123.29722222252752,
                        38.03333333266721
                    ],
                    [
                        -123.29722222252752,
                        37.93333333286705
                    ],
                    [
                        -123.16666666713297,
                        37.77361111056433
                    ],
                    [
                        -123.02555555574429,
                        37.750833332692196
                    ],
                    [
                        -122.99888888901097,
                        37.746666666633416
                    ],
                    [
                        -122.85833333381629,
                        37.73416666665838
                    ],
                    [
                        -122.39722222252749,
                        37.73305555516981
                    ],
                    [
                        -122.41666666683318,
                        37.999999999700265
                    ],
                    [
                        -122.2797222224026,
                        38.08277777727267
                    ],
                    [
                        -122.18277777807197,
                        38.14333333316682
                    ],
                    [
                        -122.13222222287726,
                        38.17499999935052
                    ],
                    [
                        -121.94694444443053,
                        38.290277777397534
                    ],
                    [
                        -121.80111111168844,
                        38.37777777722272
                    ],
                    [
                        -121.77222222287725,
                        38.50027777769736
                    ],
                    [
                        -121.75138888898601,
                        38.58805555561946
                    ],
                    [
                        -121.77638888893603,
                        38.64305555496992
                    ],
                    [
                        -121.87083333399113,
                        38.86666666673335
                    ],
                    [
                        -122.16388888906096,
                        38.86666666673335
                    ],
                    [
                        -122.291666667083,
                        39.16666666613378
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 961,
            "artcc": "zoa",
            "sector": "41",
            "sectype": "low",
            "Shape_Length": 6.345799717985511,
            "Shape_Area": 2.300115085170403
        }
    },
    {
        "type": "Feature",
        "id": 962,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -121.88333333396616,
                        41.182777777372564
                    ],
                    [
                        -122.00000000069946,
                        41.21666666653351
                    ],
                    [
                        -122.27500000014987,
                        41.291666666383605
                    ],
                    [
                        -122.41666666683318,
                        41.33333333326675
                    ],
                    [
                        -122.7444444444555,
                        41.33333333326675
                    ],
                    [
                        -123.53333333336661,
                        41.33333333326675
                    ],
                    [
                        -123.53333333336661,
                        40.38749999942547
                    ],
                    [
                        -123.83333333366636,
                        40.21666666673332
                    ],
                    [
                        -124.66666666683318,
                        40.21666666673332
                    ],
                    [
                        -124.00416666725783,
                        39.00416666645856
                    ],
                    [
                        -123.28333333386621,
                        39.39861111091409
                    ],
                    [
                        -122.52666666733279,
                        39.22277777787218
                    ],
                    [
                        -122.291666667083,
                        39.16666666613378
                    ],
                    [
                        -122.16388888906096,
                        38.86666666673335
                    ],
                    [
                        -121.87083333399113,
                        38.86666666673335
                    ],
                    [
                        -121.77638888893603,
                        38.64305555496992
                    ],
                    [
                        -121.75138888898601,
                        38.58805555561946
                    ],
                    [
                        -121.13916666740772,
                        38.96361111106398
                    ],
                    [
                        -120.85805555611898,
                        39.133611110364484
                    ],
                    [
                        -120.95000000009992,
                        39.28333333286707
                    ],
                    [
                        -121.08611111113885,
                        39.49999999940053
                    ],
                    [
                        -120.59861111121376,
                        39.93138888848648
                    ],
                    [
                        -120.82972222260247,
                        41.00027777719771
                    ],
                    [
                        -120.99999999999994,
                        41.00000000000006
                    ],
                    [
                        -121.02361111126373,
                        41.00000000000006
                    ],
                    [
                        -121.13333333366637,
                        41.00000000000006
                    ],
                    [
                        -121.25000000039967,
                        41.00000000000006
                    ],
                    [
                        -121.88333333396616,
                        41.182777777372564
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 962,
            "artcc": "zoa",
            "sector": "42",
            "sectype": "low",
            "Shape_Length": 12.241702619524013,
            "Shape_Area": 6.8045381171651655
        }
    },
    {
        "type": "Feature",
        "id": 964,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -119.86666666653338,
                        38.08333333256729
                    ],
                    [
                        -119.15999999990004,
                        38.180277777797244
                    ],
                    [
                        -119.18333333396617,
                        38.30000000000007
                    ],
                    [
                        -119.31250000067445,
                        38.84249999927562
                    ],
                    [
                        -119.50000000029974,
                        38.9999999995004
                    ],
                    [
                        -119.55777777792207,
                        39.31805555541962
                    ],
                    [
                        -120.18333333376631,
                        39.78333333276714
                    ],
                    [
                        -120.59861111121376,
                        39.93138888848648
                    ],
                    [
                        -121.08611111113885,
                        39.49999999940053
                    ],
                    [
                        -120.95000000009992,
                        39.28333333286707
                    ],
                    [
                        -120.85805555611898,
                        39.133611110364484
                    ],
                    [
                        -120.80250000057453,
                        39.04416666655845
                    ],
                    [
                        -120.7622222223776,
                        38.97972222180317
                    ],
                    [
                        -120.594166667058,
                        38.70777777782223
                    ],
                    [
                        -120.50000000009987,
                        38.55555555514479
                    ],
                    [
                        -120.35280555603157,
                        38.32071388863511
                    ],
                    [
                        -120.17884722222152,
                        38.04095833266098
                    ],
                    [
                        -119.86666666653338,
                        38.08333333256729
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 964,
            "artcc": "zoa",
            "sector": "44",
            "sectype": "low",
            "Shape_Length": 5.865690478421227,
            "Shape_Area": 2.085300101921139
        }
    },
    {
        "type": "Feature",
        "id": 966,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -119.20833333391619,
                        39.329166666708375
                    ],
                    [
                        -118.39944444450549,
                        39.37055555549455
                    ],
                    [
                        -117.33333333316676,
                        39.4166666665335
                    ],
                    [
                        -117.36666666703303,
                        39.933333333366704
                    ],
                    [
                        -117.73222222267742,
                        40.121111111088965
                    ],
                    [
                        -119.50000000029974,
                        41.00000000000006
                    ],
                    [
                        -120.82972222260247,
                        41.00027777719771
                    ],
                    [
                        -120.59861111121376,
                        39.93138888848648
                    ],
                    [
                        -120.18333333376631,
                        39.78333333276714
                    ],
                    [
                        -119.55777777792207,
                        39.31805555541962
                    ],
                    [
                        -119.20833333391619,
                        39.329166666708375
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 966,
            "artcc": "zoa",
            "sector": "45",
            "sectype": "low",
            "Shape_Length": 8.77339445458999,
            "Shape_Area": 3.9613319446582143
        }
    },
    {
        "type": "Feature",
        "id": 968,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -118.00000000059953,
                        37.19999999950045
                    ],
                    [
                        -117.33333333316676,
                        37.19999999950045
                    ],
                    [
                        -117.00833333381627,
                        37.36666666613377
                    ],
                    [
                        -117.07583333314176,
                        37.44166666598392
                    ],
                    [
                        -117.09472222215277,
                        37.549999999700276
                    ],
                    [
                        -117.09472222215277,
                        37.88333333296697
                    ],
                    [
                        -117.26666666723287,
                        38.08333333256729
                    ],
                    [
                        -117.27805555571928,
                        38.29583333304191
                    ],
                    [
                        -117.28138888928578,
                        38.41305555506989
                    ],
                    [
                        -117.29166666718294,
                        38.777777777322626
                    ],
                    [
                        -117.30750000072442,
                        38.96888888861139
                    ],
                    [
                        -117.32500000014983,
                        39.2069444443307
                    ],
                    [
                        -117.33333333316676,
                        39.4166666665335
                    ],
                    [
                        -118.39944444450549,
                        39.37055555549455
                    ],
                    [
                        -119.20833333391619,
                        39.329166666708375
                    ],
                    [
                        -119.55777777792207,
                        39.31805555541962
                    ],
                    [
                        -119.50000000029974,
                        38.9999999995004
                    ],
                    [
                        -119.31250000067445,
                        38.84249999927562
                    ],
                    [
                        -119.18333333396617,
                        38.30000000000007
                    ],
                    [
                        -119.15999999990004,
                        38.180277777797244
                    ],
                    [
                        -119.03333333336661,
                        37.51666666673333
                    ],
                    [
                        -118.58416666675822,
                        37.19999999950045
                    ],
                    [
                        -118.43333333366638,
                        37.19999999950045
                    ],
                    [
                        -118.33333333386622,
                        37.03333333286707
                    ],
                    [
                        -118.00000000059953,
                        37.14999999960037
                    ],
                    [
                        -118.00000000059953,
                        37.19999999950045
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 968,
            "artcc": "zoa",
            "sector": "46",
            "sectype": "low",
            "Shape_Length": 8.623128755777731,
            "Shape_Area": 4.307157523865553
        }
    },
    {
        "type": "Feature",
        "id": 987,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -83.13377777812195,
                        42.23532222174322
                    ],
                    [
                        -82.93476111163596,
                        42.35431944378735
                    ],
                    [
                        -82.86388888936074,
                        42.582500000075015
                    ],
                    [
                        -82.83055555549447,
                        42.60111111098905
                    ],
                    [
                        -82.72361111136365,
                        42.70055555549453
                    ],
                    [
                        -82.46666666663333,
                        42.87083333289206
                    ],
                    [
                        -82.46111111098895,
                        42.911111111088985
                    ],
                    [
                        -82.40416666675821,
                        42.95416666665841
                    ],
                    [
                        -82.41666666673325,
                        43.01138888898612
                    ],
                    [
                        -82.35555555554441,
                        43.13333333326676
                    ],
                    [
                        -82.13431666738023,
                        43.565352777508735
                    ],
                    [
                        -82.11666666733277,
                        43.599999999300564
                    ],
                    [
                        -82.1250000003497,
                        43.68888888871129
                    ],
                    [
                        -82.1694444446054,
                        43.85527777724769
                    ],
                    [
                        -83.29333333356647,
                        43.75583333274216
                    ],
                    [
                        -84.0372222229272,
                        43.683611111163884
                    ],
                    [
                        -84.16055555589418,
                        43.67138888838656
                    ],
                    [
                        -84.52249999987504,
                        43.63388888846151
                    ],
                    [
                        -84.12131944443678,
                        43.22617777738259
                    ],
                    [
                        -83.73801666663581,
                        42.83663888827414
                    ],
                    [
                        -83.61780833388747,
                        42.722052777913404
                    ],
                    [
                        -83.67870277771101,
                        42.4927999995204
                    ],
                    [
                        -83.74508055622266,
                        42.21869166618751
                    ],
                    [
                        -83.3885277781344,
                        42.19588888896112
                    ],
                    [
                        -83.13023055609523,
                        42.17953611060307
                    ],
                    [
                        -83.13377777812195,
                        42.23532222174322
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 987,
            "artcc": "zob",
            "sector": "16",
            "sectype": "low",
            "Shape_Length": 6.895325334315326,
            "Shape_Area": 2.261410346246074
        }
    },
    {
        "type": "Feature",
        "id": 990,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -81.82777777832177,
                        42.80833333301695
                    ],
                    [
                        -81.97083333369136,
                        42.82916666600886
                    ],
                    [
                        -82.27222222267739,
                        42.853333332567274
                    ],
                    [
                        -82.40833333371631,
                        42.86666666683328
                    ],
                    [
                        -82.46666666663333,
                        42.87083333289206
                    ],
                    [
                        -82.72361111136365,
                        42.70055555549453
                    ],
                    [
                        -82.83055555549447,
                        42.60111111098905
                    ],
                    [
                        -82.86388888936074,
                        42.582500000075015
                    ],
                    [
                        -82.93476111163596,
                        42.35431944378735
                    ],
                    [
                        -82.63291111145861,
                        42.323074999411745
                    ],
                    [
                        -82.47665833331541,
                        42.3338583329957
                    ],
                    [
                        -81.79664444428562,
                        42.381358332720936
                    ],
                    [
                        -81.5932194450213,
                        42.39482222156835
                    ],
                    [
                        -80.94861111131371,
                        42.39111111068928
                    ],
                    [
                        -80.44166666708298,
                        42.755555555744365
                    ],
                    [
                        -80.26666666653341,
                        42.813888888661324
                    ],
                    [
                        -80.89583333314175,
                        42.77916666610878
                    ],
                    [
                        -81.22083333339157,
                        42.74166666618373
                    ],
                    [
                        -81.82777777832177,
                        42.80833333301695
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 990,
            "artcc": "zob",
            "sector": "20",
            "sectype": "low",
            "Shape_Length": 5.740494298017179,
            "Shape_Area": 0.9245061784259256
        }
    },
    {
        "type": "Feature",
        "id": 991,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -80.94861111131371,
                        42.39111111068928
                    ],
                    [
                        -81.5932194450213,
                        42.39482222156835
                    ],
                    [
                        -81.79664444428562,
                        42.381358332720936
                    ],
                    [
                        -82.47665833331541,
                        42.3338583329957
                    ],
                    [
                        -82.63291111145861,
                        42.323074999411745
                    ],
                    [
                        -82.93476111163596,
                        42.35431944378735
                    ],
                    [
                        -83.13377777812195,
                        42.23532222174322
                    ],
                    [
                        -83.13023055609523,
                        42.17953611060307
                    ],
                    [
                        -83.3885277781344,
                        42.19588888896112
                    ],
                    [
                        -83.39450000047458,
                        41.96021388816047
                    ],
                    [
                        -83.19751944496636,
                        41.94413055568066
                    ],
                    [
                        -82.9174694446591,
                        41.92621666666588
                    ],
                    [
                        -82.79864166657961,
                        41.901341666184976
                    ],
                    [
                        -82.62142499996372,
                        41.86457499953667
                    ],
                    [
                        -82.39198888937568,
                        41.798622221738185
                    ],
                    [
                        -82.25119722257375,
                        41.76472499945919
                    ],
                    [
                        -82.15962499989377,
                        41.74249999977525
                    ],
                    [
                        -81.9111111111888,
                        41.61111111098904
                    ],
                    [
                        -81.65000000039964,
                        41.794444443656175
                    ],
                    [
                        -81.55595555580425,
                        41.84463333266223
                    ],
                    [
                        -81.48333333376632,
                        41.8833333330669
                    ],
                    [
                        -81.21111111168841,
                        41.999999999800195
                    ],
                    [
                        -81.06388888936073,
                        42.205555555044896
                    ],
                    [
                        -80.94861111131371,
                        42.39111111068928
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 991,
            "artcc": "zob",
            "sector": "21",
            "sectype": "low",
            "Shape_Length": 5.587515010951439,
            "Shape_Area": 1.137519157968879
        }
    },
    {
        "type": "Feature",
        "id": 997,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -76.78055555589413,
                        43.3999999997003
                    ],
                    [
                        -76.79666666663331,
                        43.631111111088956
                    ],
                    [
                        -77.17666666713296,
                        43.63222222167826
                    ],
                    [
                        -77.94194444478029,
                        43.634166666558485
                    ],
                    [
                        -78.35000000069942,
                        43.63333333316683
                    ],
                    [
                        -78.0000000004996,
                        43.3999999997003
                    ],
                    [
                        -76.78055555589413,
                        43.3999999997003
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 997,
            "artcc": "zob",
            "sector": "31",
            "sectype": "low",
            "Shape_Length": 3.425102369240221,
            "Shape_Area": 0.3229032408760901
        }
    },
    {
        "type": "Feature",
        "id": 1008,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -79.52027777789709,
                        41.51583333344166
                    ],
                    [
                        -79.77083333359144,
                        41.53888888851145
                    ],
                    [
                        -79.92222222287722,
                        41.56111111108896
                    ],
                    [
                        -79.98000000049956,
                        41.474999999950114
                    ],
                    [
                        -80.09999999990004,
                        41.19999999960038
                    ],
                    [
                        -80.53333333386621,
                        41.1499999997003
                    ],
                    [
                        -80.44749999992501,
                        40.96416666595894
                    ],
                    [
                        -80.35833333331664,
                        40.6819444440809
                    ],
                    [
                        -80.10833333381629,
                        40.68333333276712
                    ],
                    [
                        -79.99111111178831,
                        40.70722222212788
                    ],
                    [
                        -79.69166666678319,
                        40.770833332592304
                    ],
                    [
                        -79.17499999994999,
                        40.80416666645857
                    ],
                    [
                        -78.86666666663331,
                        40.82499999945048
                    ],
                    [
                        -78.50000000039967,
                        40.84166666638362
                    ],
                    [
                        -78.12777777852165,
                        40.85833333331675
                    ],
                    [
                        -77.95000000059952,
                        41.11944444410585
                    ],
                    [
                        -77.80833333391621,
                        41.30833333331674
                    ],
                    [
                        -77.89972222260246,
                        41.319444443706175
                    ],
                    [
                        -78.05000000039968,
                        41.450000000000045
                    ],
                    [
                        -79.03333333326668,
                        41.54999999980021
                    ],
                    [
                        -79.16666666693311,
                        41.47083333299196
                    ],
                    [
                        -79.52027777789709,
                        41.51583333344166
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 1008,
            "artcc": "zob",
            "sector": "50",
            "sectype": "low",
            "Shape_Length": 6.330484833908007,
            "Shape_Area": 1.6419998073045077
        }
    },
    {
        "type": "Feature",
        "id": 1011,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -81.69972222220275,
                        40.38722222222782
                    ],
                    [
                        -81.63333333346651,
                        40.21666666673332
                    ],
                    [
                        -81.5744444443556,
                        39.8874999995254
                    ],
                    [
                        -81.55000000059948,
                        39.749999999800195
                    ],
                    [
                        -81.42611111143862,
                        39.68222222147841
                    ],
                    [
                        -81.19166666738278,
                        39.54999999930055
                    ],
                    [
                        -81.10277777797205,
                        39.50416666635863
                    ],
                    [
                        -80.63333333366637,
                        39.89999999950044
                    ],
                    [
                        -80.48055555569431,
                        40.02777777752249
                    ],
                    [
                        -80.40694444453044,
                        40.33749999952539
                    ],
                    [
                        -80.84666666663333,
                        40.31861111051438
                    ],
                    [
                        -81.69972222220275,
                        40.38722222222782
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 1011,
            "artcc": "zob",
            "sector": "55",
            "sectype": "low",
            "Shape_Length": 3.5949664810406268,
            "Shape_Area": 0.7257648920583558
        }
    },
    {
        "type": "Feature",
        "id": 1021,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -77.72500000014986,
                        41.41666666613378
                    ],
                    [
                        -77.64166666728283,
                        41.54166666678327
                    ],
                    [
                        -77.63333333336658,
                        41.555555555444585
                    ],
                    [
                        -77.56666666653342,
                        41.637499999625334
                    ],
                    [
                        -77.4555555554445,
                        41.82083333319184
                    ],
                    [
                        -77.41666666683318,
                        41.87500000005002
                    ],
                    [
                        -77.25833333321674,
                        42.13888888821168
                    ],
                    [
                        -77.23611111153855,
                        42.17499999945045
                    ],
                    [
                        -77.11305555576922,
                        42.29888888861137
                    ],
                    [
                        -77.42750000002496,
                        42.416388888736265
                    ],
                    [
                        -77.70972222280233,
                        42.51972222210293
                    ],
                    [
                        -77.74305555576922,
                        42.53083333339168
                    ],
                    [
                        -78.15833333321672,
                        42.59166666648355
                    ],
                    [
                        -78.3541666667582,
                        42.69999999930059
                    ],
                    [
                        -78.54722222292719,
                        42.655555555044884
                    ],
                    [
                        -78.55972222290222,
                        42.4713888889861
                    ],
                    [
                        -78.80000000069941,
                        42.39722222162828
                    ],
                    [
                        -78.80000000069941,
                        42.2499999993006
                    ],
                    [
                        -78.8094444443056,
                        42.234444443856034
                    ],
                    [
                        -78.93055555609402,
                        42.11388888826161
                    ],
                    [
                        -79.31944444490517,
                        41.70833333341665
                    ],
                    [
                        -79.51388888886112,
                        41.57499999975022
                    ],
                    [
                        -79.52027777789709,
                        41.51583333344166
                    ],
                    [
                        -79.16666666693311,
                        41.47083333299196
                    ],
                    [
                        -78.8955555554445,
                        41.43861111061432
                    ],
                    [
                        -78.74583333384123,
                        41.419444444405656
                    ],
                    [
                        -78.38888888931075,
                        41.37916666620873
                    ],
                    [
                        -77.89972222260246,
                        41.319444443706175
                    ],
                    [
                        -77.80833333391621,
                        41.30833333331674
                    ],
                    [
                        -77.72500000014986,
                        41.41666666613378
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 1021,
            "artcc": "zob",
            "sector": "73",
            "sectype": "low",
            "Shape_Length": 6.081555140212389,
            "Shape_Area": 1.9174385423325384
        }
    },
    {
        "type": "Feature",
        "id": 1039,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -121.5166666668332,
                        47.34999999990015
                    ],
                    [
                        -121.62805555601904,
                        47.23888888881123
                    ],
                    [
                        -121.85916666650843,
                        47.00722222212789
                    ],
                    [
                        -121.04999999990002,
                        46.70000000029984
                    ],
                    [
                        -120.91250000017482,
                        46.941666666783306
                    ],
                    [
                        -120.74999999960028,
                        47.21666666713304
                    ],
                    [
                        -121.5166666668332,
                        47.34999999990015
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 1039,
            "artcc": "zse",
            "sector": "01",
            "sectype": "low",
            "Shape_Length": 2.725734281386872,
            "Shape_Area": 0.4086834105135401
        }
    },
    {
        "type": "Feature",
        "id": 1041,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -122.96666666663333,
                        47.499999999600334
                    ],
                    [
                        -123.66805555571926,
                        47.249722222003015
                    ],
                    [
                        -124.24805555581923,
                        47.09972222230277
                    ],
                    [
                        -124.50138888888608,
                        47.00805555551955
                    ],
                    [
                        -125.1130555551697,
                        46.890000000099974
                    ],
                    [
                        -125.30000000039968,
                        46.53333333366646
                    ],
                    [
                        -125.72694444443056,
                        46.34027777749748
                    ],
                    [
                        -124.76666666663334,
                        46.33333333316682
                    ],
                    [
                        -123.83333333366636,
                        46.33333333316682
                    ],
                    [
                        -123.23333333306681,
                        46.833333333066946
                    ],
                    [
                        -123.28333333296689,
                        47.25000000009999
                    ],
                    [
                        -123.16666666713297,
                        47.383333332867096
                    ],
                    [
                        -122.99999999960028,
                        47.383333332867096
                    ],
                    [
                        -122.96666666663333,
                        47.499999999600334
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 1041,
            "artcc": "zse",
            "sector": "02",
            "sectype": "low",
            "Shape_Length": 6.666892476083392,
            "Shape_Area": 1.4479819836643386
        }
    },
    {
        "type": "Feature",
        "id": 1043,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -127.96638888933575,
                        48.33518055553827
                    ],
                    [
                        -128.00000000039967,
                        48.333333333666474
                    ],
                    [
                        -127.92499999965025,
                        48.1666666670331
                    ],
                    [
                        -127.84527777754738,
                        48.00000000039978
                    ],
                    [
                        -127.37777777812192,
                        46.999999999700265
                    ],
                    [
                        -126.84694444453044,
                        45.80972222200302
                    ],
                    [
                        -126.81972222250255,
                        45.82305555536965
                    ],
                    [
                        -126.25000000029974,
                        46.09999999970029
                    ],
                    [
                        -125.72694444443056,
                        46.34027777749748
                    ],
                    [
                        -125.30000000039968,
                        46.53333333366646
                    ],
                    [
                        -125.1130555551697,
                        46.890000000099974
                    ],
                    [
                        -124.50138888888608,
                        47.00805555551955
                    ],
                    [
                        -124.24805555581923,
                        47.09972222230277
                    ],
                    [
                        -123.66805555571926,
                        47.249722222003015
                    ],
                    [
                        -122.96666666663333,
                        47.499999999600334
                    ],
                    [
                        -122.88333333286698,
                        47.91666666663343
                    ],
                    [
                        -123.33333333286697,
                        48.01666666643354
                    ],
                    [
                        -124.00000000029974,
                        48.13333333316683
                    ],
                    [
                        -124.00000000029974,
                        48.26666666683326
                    ],
                    [
                        -124.01111111068917,
                        48.29722222242765
                    ],
                    [
                        -124.74999999970021,
                        48.50000000029985
                    ],
                    [
                        -125.00000000009987,
                        48.50000000029985
                    ],
                    [
                        -127.96638888933575,
                        48.33518055553827
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 1043,
            "artcc": "zse",
            "sector": "03",
            "sectype": "low",
            "Shape_Length": 12.870844993306033,
            "Shape_Area": 7.64671547204127
        }
    },
    {
        "type": "Feature",
        "id": 1044,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -122.58333333346656,
                        46.33333333316682
                    ],
                    [
                        -122.52500000054954,
                        46.708333333316716
                    ],
                    [
                        -123.23333333396613,
                        46.833333333066946
                    ],
                    [
                        -123.83333333366636,
                        46.33333333316682
                    ],
                    [
                        -124.76666666663334,
                        46.33333333316682
                    ],
                    [
                        -125.72694444443056,
                        46.34027777749748
                    ],
                    [
                        -126.25000000029974,
                        46.09999999970029
                    ],
                    [
                        -126.81972222250255,
                        45.82305555536965
                    ],
                    [
                        -126.84694444453044,
                        45.80972222200302
                    ],
                    [
                        -126.50000000069946,
                        45.00000000009999
                    ],
                    [
                        -126.46722222212782,
                        45.00027777729764
                    ],
                    [
                        -124.00000000029974,
                        45.00000000009999
                    ],
                    [
                        -123.28083333369136,
                        45.411944443980985
                    ],
                    [
                        -122.91583333334165,
                        45.633333332767165
                    ],
                    [
                        -122.83916666670825,
                        45.633333332767165
                    ],
                    [
                        -122.75000000009987,
                        45.633333332767165
                    ],
                    [
                        -122.75000000009987,
                        45.78333333336667
                    ],
                    [
                        -122.97333333376633,
                        45.78333333336667
                    ],
                    [
                        -123.19666666653342,
                        45.93194444438063
                    ],
                    [
                        -123.07500000034969,
                        45.999999999900126
                    ],
                    [
                        -122.55000000049961,
                        45.999999999900126
                    ],
                    [
                        -122.58611111173838,
                        46.09583333274219
                    ],
                    [
                        -122.5380555558192,
                        46.094999999350534
                    ],
                    [
                        -122.58333333346656,
                        46.33333333316682
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 1044,
            "artcc": "zse",
            "sector": "04",
            "sectype": "low",
            "Shape_Length": 11.514345499329083,
            "Shape_Area": 5.031403858261288
        }
    },
    {
        "type": "Feature",
        "id": 1045,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -121.20000000049959,
                        45.16666666673336
                    ],
                    [
                        -121.83333333316676,
                        45.36666666633363
                    ],
                    [
                        -122.30000000009989,
                        45.583333332867085
                    ],
                    [
                        -122.31666666703302,
                        45.50000000000006
                    ],
                    [
                        -122.30277777837176,
                        45.07499999995008
                    ],
                    [
                        -122.26861111111384,
                        44.96944444450554
                    ],
                    [
                        -122.47777777802202,
                        44.935000000050024
                    ],
                    [
                        -122.21916666740776,
                        44.769444444005956
                    ],
                    [
                        -122.15873333387623,
                        44.52986111100154
                    ],
                    [
                        -122.10000000049956,
                        44.28333333276714
                    ],
                    [
                        -121.98311666730035,
                        43.78915555548457
                    ],
                    [
                        -121.89999999999998,
                        43.433333332667246
                    ],
                    [
                        -121.19166666658339,
                        43.433333332667246
                    ],
                    [
                        -120.30022222207788,
                        43.43691944407715
                    ],
                    [
                        -119.38333333356644,
                        43.433333332667246
                    ],
                    [
                        -119.24999999990007,
                        43.53333333336667
                    ],
                    [
                        -119.28333333376628,
                        43.63333333316683
                    ],
                    [
                        -119.21666666693312,
                        43.73333333296699
                    ],
                    [
                        -118.54166666738274,
                        44.699999999800184
                    ],
                    [
                        -118.45000000059952,
                        44.849999999500426
                    ],
                    [
                        -119.90000000039964,
                        45.26666666653347
                    ],
                    [
                        -120.40000000029971,
                        44.94999999930059
                    ],
                    [
                        -121.20000000049959,
                        45.16666666673336
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 1045,
            "artcc": "zse",
            "sector": "05",
            "sectype": "low",
            "Shape_Length": 10.885696257632533,
            "Shape_Area": 5.624260124289866
        }
    },
    {
        "type": "Feature",
        "id": 1046,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -122.30277777837176,
                        45.07499999995008
                    ],
                    [
                        -122.31666666703302,
                        45.50000000000006
                    ],
                    [
                        -122.30000000009989,
                        45.583333332867085
                    ],
                    [
                        -122.69138888908594,
                        45.633333332767165
                    ],
                    [
                        -122.75000000009987,
                        45.633333332767165
                    ],
                    [
                        -122.83916666670825,
                        45.633333332767165
                    ],
                    [
                        -122.91583333334165,
                        45.633333332767165
                    ],
                    [
                        -123.28083333369136,
                        45.411944443980985
                    ],
                    [
                        -124.00000000029974,
                        45.00000000009999
                    ],
                    [
                        -126.46722222212782,
                        45.00027777729764
                    ],
                    [
                        -126.50000000069946,
                        45.00000000009999
                    ],
                    [
                        -126.55833333361642,
                        44.4999999993006
                    ],
                    [
                        -126.60000000049956,
                        44.06666666623369
                    ],
                    [
                        -126.66666666733278,
                        43.53333333336667
                    ],
                    [
                        -126.63404444449549,
                        43.54672499945923
                    ],
                    [
                        -125.49999999999994,
                        43.99999999940053
                    ],
                    [
                        -123.9833333333666,
                        43.99999999940053
                    ],
                    [
                        -123.93333333346652,
                        43.99999999940053
                    ],
                    [
                        -123.63333333316677,
                        43.99999999940053
                    ],
                    [
                        -123.56666666723288,
                        44.01666666633366
                    ],
                    [
                        -123.28333333386621,
                        44.08333333316682
                    ],
                    [
                        -122.98333333356646,
                        44.04999999930055
                    ],
                    [
                        -122.58630833396239,
                        43.93016666675834
                    ],
                    [
                        -121.98311666730035,
                        43.78915555548457
                    ],
                    [
                        -122.10000000049956,
                        44.28333333276714
                    ],
                    [
                        -122.15873333387623,
                        44.52986111100154
                    ],
                    [
                        -122.21916666740776,
                        44.769444444005956
                    ],
                    [
                        -122.26861111111384,
                        44.96944444450554
                    ],
                    [
                        -122.30277777837176,
                        45.07499999995008
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 1046,
            "artcc": "zse",
            "sector": "06",
            "sectype": "low",
            "Shape_Length": 12.505428787779023,
            "Shape_Area": 5.425072999050019
        }
    },
    {
        "type": "Feature",
        "id": 1048,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -117.01000000059952,
                        45.33333333336668
                    ],
                    [
                        -115.00000000029974,
                        45.33333333336668
                    ],
                    [
                        -115.00000000029974,
                        46.41666666603385
                    ],
                    [
                        -115.00000000029974,
                        47.14999999940051
                    ],
                    [
                        -115.00000000029974,
                        47.58333333336668
                    ],
                    [
                        -115.00000000029974,
                        48.4166666665335
                    ],
                    [
                        -114.71777777842169,
                        48.91666666643357
                    ],
                    [
                        -114.69999999999999,
                        48.94999999940052
                    ],
                    [
                        -114.66666666703304,
                        48.9999999993006
                    ],
                    [
                        -115.5000000001998,
                        48.9999999993006
                    ],
                    [
                        -116.03333333396614,
                        48.9999999993006
                    ],
                    [
                        -116.70888888881115,
                        48.9999999993006
                    ],
                    [
                        -116.75027777849664,
                        48.950833332792115
                    ],
                    [
                        -117.3263888888361,
                        48.19166666608385
                    ],
                    [
                        -117.72861111101395,
                        47.65027777739755
                    ],
                    [
                        -117.59166666658336,
                        47.56249999947545
                    ],
                    [
                        -117.59166666658336,
                        47.4833333326672
                    ],
                    [
                        -117.91666666683318,
                        47.283333333066935
                    ],
                    [
                        -117.95750000032473,
                        47.24249999957539
                    ],
                    [
                        -118.08333333346656,
                        47.11666666643356
                    ],
                    [
                        -118.49805555561937,
                        46.60694444393101
                    ],
                    [
                        -118.70000000009992,
                        46.3833333330669
                    ],
                    [
                        -118.86666666673324,
                        46.36666666613377
                    ],
                    [
                        -118.33333333386622,
                        45.999999999900126
                    ],
                    [
                        -118.11666666733282,
                        45.91666666613378
                    ],
                    [
                        -117.50000000069946,
                        45.71666666653351
                    ],
                    [
                        -117.50000000069946,
                        45.33333333336668
                    ],
                    [
                        -117.01000000059952,
                        45.33333333336668
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 1048,
            "artcc": "zse",
            "sector": "08",
            "sectype": "low",
            "Shape_Length": 13.885352523535598,
            "Shape_Area": 10.240361841874236
        }
    },
    {
        "type": "Feature",
        "id": 1049,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -120.7500000004996,
                        47.21666666623372
                    ],
                    [
                        -120.91250000017482,
                        46.941666666783306
                    ],
                    [
                        -120.48277777787212,
                        46.922222221578295
                    ],
                    [
                        -120.53805555621892,
                        46.82333333326676
                    ],
                    [
                        -120.66666666673325,
                        46.58333333266722
                    ],
                    [
                        -120.95527777764727,
                        46.460555554994926
                    ],
                    [
                        -121.12611111123874,
                        46.38888888871128
                    ],
                    [
                        -121.398055556119,
                        46.28027777779727
                    ],
                    [
                        -120.40000000029971,
                        45.7499999995004
                    ],
                    [
                        -119.90000000039964,
                        45.26666666653347
                    ],
                    [
                        -118.45000000059952,
                        44.849999999500426
                    ],
                    [
                        -118.06166666708299,
                        45.129999999300594
                    ],
                    [
                        -117.91666666683318,
                        45.2333333326672
                    ],
                    [
                        -117.7500000001998,
                        45.33333333336668
                    ],
                    [
                        -117.50000000069946,
                        45.33333333336668
                    ],
                    [
                        -117.50000000069946,
                        45.71666666653351
                    ],
                    [
                        -118.11666666733282,
                        45.91666666613378
                    ],
                    [
                        -118.33333333386622,
                        45.999999999900126
                    ],
                    [
                        -118.86666666673324,
                        46.36666666613377
                    ],
                    [
                        -119.30000000069941,
                        46.633333332567304
                    ],
                    [
                        -119.76666666673327,
                        46.98333333276713
                    ],
                    [
                        -119.96666666723286,
                        47.14999999940051
                    ],
                    [
                        -120.36666666733282,
                        47.13333333336669
                    ],
                    [
                        -120.7500000004996,
                        47.21666666623372
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 1049,
            "artcc": "zse",
            "sector": "09",
            "sectype": "low",
            "Shape_Length": 10.41844092968762,
            "Shape_Area": 4.310899459304742
        }
    },
    {
        "type": "Feature",
        "id": 1050,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -119.24999999990007,
                        43.53333333336667
                    ],
                    [
                        -119.38333333356644,
                        43.433333332667246
                    ],
                    [
                        -120.30022222207788,
                        43.43691944407715
                    ],
                    [
                        -121.19166666658339,
                        43.433333332667246
                    ],
                    [
                        -121.89999999999998,
                        43.433333332667246
                    ],
                    [
                        -122.41666666683318,
                        42.69999999930059
                    ],
                    [
                        -122.41666666683318,
                        42.43333333286705
                    ],
                    [
                        -122.41666666683318,
                        42.38333333296697
                    ],
                    [
                        -122.41666666683318,
                        41.999999999800195
                    ],
                    [
                        -122.30000000009989,
                        41.8833333330669
                    ],
                    [
                        -122.27500000014987,
                        41.291666666383605
                    ],
                    [
                        -122.00000000069946,
                        41.21666666653351
                    ],
                    [
                        -121.88333333396616,
                        41.182777777372564
                    ],
                    [
                        -121.25000000039967,
                        41.00000000000006
                    ],
                    [
                        -121.13333333366637,
                        41.00000000000006
                    ],
                    [
                        -121.02361111126373,
                        41.00000000000006
                    ],
                    [
                        -120.99999999999994,
                        41.00000000000006
                    ],
                    [
                        -120.82972222260247,
                        41.00027777719771
                    ],
                    [
                        -119.50000000029974,
                        41.00000000000006
                    ],
                    [
                        -119.10000000019983,
                        42.333333333066946
                    ],
                    [
                        -119.00000000039967,
                        42.66666666633364
                    ],
                    [
                        -119.24999999990007,
                        43.53333333336667
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 1050,
            "artcc": "zse",
            "sector": "10",
            "sectype": "low",
            "Shape_Length": 10.495365372373321,
            "Shape_Area": 7.358520218697183
        }
    },
    {
        "type": "Feature",
        "id": 1053,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -120.39166666728283,
                        48.27499999985014
                    ],
                    [
                        -120.08527777794706,
                        48.830833333391695
                    ],
                    [
                        -121.04027777819687,
                        48.84999999960036
                    ],
                    [
                        -121.98944444470533,
                        48.860277777497515
                    ],
                    [
                        -121.96666666683319,
                        48.641666666083836
                    ],
                    [
                        -121.96666666683319,
                        48.29999999980021
                    ],
                    [
                        -121.96666666683319,
                        48.24027777729765
                    ],
                    [
                        -121.96666666683319,
                        48.16666666613378
                    ],
                    [
                        -121.66666666653339,
                        48.200000000000045
                    ],
                    [
                        -121.52194444438055,
                        48.197222221728225
                    ],
                    [
                        -120.47777777842168,
                        48.10833333321682
                    ],
                    [
                        -120.39166666728283,
                        48.27499999985014
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 1053,
            "artcc": "zse",
            "sector": "12",
            "sectype": "low",
            "Shape_Length": 4.91603012734594,
            "Shape_Area": 1.1679807096789725
        }
    },
    {
        "type": "Feature",
        "id": 1058,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -117.95750000032473,
                        47.24249999957539
                    ],
                    [
                        -117.91666666683318,
                        47.283333333066935
                    ],
                    [
                        -117.59166666658336,
                        47.4833333326672
                    ],
                    [
                        -117.59166666658336,
                        47.56249999947545
                    ],
                    [
                        -117.72861111101395,
                        47.65027777739755
                    ],
                    [
                        -117.3263888888361,
                        48.19166666608385
                    ],
                    [
                        -116.75027777849664,
                        48.950833332792115
                    ],
                    [
                        -116.70888888881115,
                        48.9999999993006
                    ],
                    [
                        -116.80111111178837,
                        48.9999999993006
                    ],
                    [
                        -117.01666666683315,
                        48.9999999993006
                    ],
                    [
                        -117.66666666733278,
                        48.9999999993006
                    ],
                    [
                        -118.78333333386621,
                        48.9999999993006
                    ],
                    [
                        -119.50000000029974,
                        48.9999999993006
                    ],
                    [
                        -120.0000000001998,
                        48.9999999993006
                    ],
                    [
                        -120.08527777794706,
                        48.830833333391695
                    ],
                    [
                        -120.39166666728283,
                        48.27499999985014
                    ],
                    [
                        -120.47777777842168,
                        48.10833333321682
                    ],
                    [
                        -120.65000000069944,
                        47.73333333306692
                    ],
                    [
                        -120.7500000004996,
                        47.21666666623372
                    ],
                    [
                        -120.36666666733282,
                        47.13333333336669
                    ],
                    [
                        -119.96666666723286,
                        47.14999999940051
                    ],
                    [
                        -119.76666666673327,
                        46.98333333276713
                    ],
                    [
                        -119.30000000069941,
                        46.633333332567304
                    ],
                    [
                        -118.86666666673324,
                        46.36666666613377
                    ],
                    [
                        -118.70000000009992,
                        46.3833333330669
                    ],
                    [
                        -118.49805555561937,
                        46.60694444393101
                    ],
                    [
                        -118.08333333346656,
                        47.11666666643356
                    ],
                    [
                        -117.95750000032473,
                        47.24249999957539
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 1058,
            "artcc": "zse",
            "sector": "18",
            "sectype": "low",
            "Shape_Length": 11.06366904280646,
            "Shape_Area": 6.530240008093321
        }
    },
    {
        "type": "Feature",
        "id": 1059,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -122.90000000069944,
                        42.58333333256729
                    ],
                    [
                        -123.33333333376629,
                        42.41666666683324
                    ],
                    [
                        -123.45277777787214,
                        42.418055555519516
                    ],
                    [
                        -123.74999999990007,
                        42.43333333286705
                    ],
                    [
                        -126.75107777831676,
                        42.41728055485379
                    ],
                    [
                        -126.78333333316675,
                        42.41666666683324
                    ],
                    [
                        -126.81666666703302,
                        41.999999999800195
                    ],
                    [
                        -126.83045833358517,
                        41.83333333316682
                    ],
                    [
                        -126.838694444493,
                        41.733333333366716
                    ],
                    [
                        -126.89999999990005,
                        40.98333333306692
                    ],
                    [
                        -126.87499999994998,
                        40.97222222177817
                    ],
                    [
                        -125.8333333332667,
                        40.46666666623372
                    ],
                    [
                        -125.33333333336662,
                        40.21666666673332
                    ],
                    [
                        -124.66666666683318,
                        40.21666666673332
                    ],
                    [
                        -123.83333333366636,
                        40.21666666673332
                    ],
                    [
                        -123.53333333336661,
                        40.38749999942547
                    ],
                    [
                        -123.53333333336661,
                        41.33333333326675
                    ],
                    [
                        -122.7444444444555,
                        41.33333333326675
                    ],
                    [
                        -122.41666666683318,
                        41.33333333326675
                    ],
                    [
                        -122.27500000014987,
                        41.291666666383605
                    ],
                    [
                        -122.30000000009989,
                        41.8833333330669
                    ],
                    [
                        -122.41666666683318,
                        41.999999999800195
                    ],
                    [
                        -122.41666666683318,
                        42.38333333296697
                    ],
                    [
                        -122.90000000069944,
                        42.58333333256729
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 1059,
            "artcc": "zse",
            "sector": "30",
            "sectype": "low",
            "Shape_Length": 12.816040381597086,
            "Shape_Area": 8.078888424137942
        }
    },
    {
        "type": "Feature",
        "id": 1061,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -121.88333333396616,
                        47.31666666603388
                    ],
                    [
                        -121.96666666683319,
                        47.61666666633363
                    ],
                    [
                        -122.16666666733278,
                        47.616847222321496
                    ],
                    [
                        -122.16666666733278,
                        47.31666666603388
                    ],
                    [
                        -121.88333333396616,
                        47.31666666603388
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 1061,
            "artcc": "zse",
            "sector": "31",
            "sectype": "low",
            "Shape_Length": 1.0948730000234557,
            "Shape_Area": 0.07251805575120046
        }
    },
    {
        "type": "Feature",
        "id": 1062,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -120.65000000069944,
                        47.73333333306692
                    ],
                    [
                        -120.47777777842168,
                        48.10833333321682
                    ],
                    [
                        -121.52194444438055,
                        48.197222221728225
                    ],
                    [
                        -121.66666666653339,
                        48.200000000000045
                    ],
                    [
                        -121.96666666683319,
                        48.16666666613378
                    ],
                    [
                        -121.96666666683319,
                        47.9999999995004
                    ],
                    [
                        -121.70000000039965,
                        47.833333332867085
                    ],
                    [
                        -121.60666666683318,
                        47.67249999997506
                    ],
                    [
                        -121.5166666668332,
                        47.51666666653347
                    ],
                    [
                        -121.5166666668332,
                        47.34999999990015
                    ],
                    [
                        -120.7500000004996,
                        47.21666666623372
                    ],
                    [
                        -120.65000000069944,
                        47.73333333306692
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 1062,
            "artcc": "zse",
            "sector": "31",
            "sectype": "low",
            "Shape_Length": 4.225332516688109,
            "Shape_Area": 0.9406184409866194
        }
    },
    {
        "type": "Feature",
        "id": 1064,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -121.85916666650843,
                        47.00722222212789
                    ],
                    [
                        -121.96666666683319,
                        46.91666666683324
                    ],
                    [
                        -122.30000000009989,
                        46.66666666643357
                    ],
                    [
                        -122.52499999965022,
                        46.708333333316716
                    ],
                    [
                        -122.58333333346656,
                        46.33333333316682
                    ],
                    [
                        -122.5380555558192,
                        46.095000000249854
                    ],
                    [
                        -122.28333333316675,
                        46.09999999970029
                    ],
                    [
                        -122.16666666643346,
                        45.999999999900126
                    ],
                    [
                        -121.95416666685816,
                        46.06750000012494
                    ],
                    [
                        -121.39805555521968,
                        46.28027777779727
                    ],
                    [
                        -121.12611111123874,
                        46.38888888871128
                    ],
                    [
                        -121.19166666658339,
                        46.40833333301697
                    ],
                    [
                        -121.04999999990002,
                        46.70000000029984
                    ],
                    [
                        -121.85916666650843,
                        47.00722222212789
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 1064,
            "artcc": "zse",
            "sector": "32",
            "sectype": "low",
            "Shape_Length": 4.185964660482331,
            "Shape_Area": 0.9554780094125706
        }
    },
    {
        "type": "Feature",
        "id": 1065,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -120.40000000029971,
                        45.7499999995004
                    ],
                    [
                        -121.398055556119,
                        46.28027777779727
                    ],
                    [
                        -121.95416666685816,
                        46.06750000012494
                    ],
                    [
                        -122.16666666733278,
                        45.999999999900126
                    ],
                    [
                        -122.39999999990005,
                        45.999999999900126
                    ],
                    [
                        -122.41666666683318,
                        45.93333333306691
                    ],
                    [
                        -122.75000000009987,
                        45.78333333336667
                    ],
                    [
                        -122.75000000009987,
                        45.633333332767165
                    ],
                    [
                        -122.69138888908594,
                        45.633333332767165
                    ],
                    [
                        -122.30000000009989,
                        45.583333332867085
                    ],
                    [
                        -121.83333333316676,
                        45.36666666633363
                    ],
                    [
                        -121.20000000049959,
                        45.16666666673336
                    ],
                    [
                        -120.40000000029971,
                        44.94999999930059
                    ],
                    [
                        -119.90000000039964,
                        45.26666666653347
                    ],
                    [
                        -120.40000000029971,
                        45.7499999995004
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 1065,
            "artcc": "zse",
            "sector": "34",
            "sectype": "low",
            "Shape_Length": 6.514091750820125,
            "Shape_Area": 1.9087675928976586
        }
    },
    {
        "type": "Feature",
        "id": 1066,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -121.19166666658339,
                        43.433333332667246
                    ],
                    [
                        -120.30022222207788,
                        43.43691944407715
                    ],
                    [
                        -120.33333333346656,
                        44.4999999993006
                    ],
                    [
                        -121.3407722221603,
                        44.82075833273092
                    ],
                    [
                        -121.53136666663835,
                        44.78199444448808
                    ],
                    [
                        -121.70000000039965,
                        44.749999999700265
                    ],
                    [
                        -122.15873333387623,
                        44.52986111100154
                    ],
                    [
                        -122.10000000049956,
                        44.28333333276714
                    ],
                    [
                        -121.98311666730035,
                        43.78915555548457
                    ],
                    [
                        -121.89999999999998,
                        43.433333332667246
                    ],
                    [
                        -121.19166666658339,
                        43.433333332667246
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 1066,
            "artcc": "zse",
            "sector": "35",
            "sectype": "low",
            "Shape_Length": 5.722249274091698,
            "Shape_Area": 2.148852566048974
        }
    },
    {
        "type": "Feature",
        "id": 1067,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -122.41666666683318,
                        42.69999999930059
                    ],
                    [
                        -121.89999999999998,
                        43.433333332667246
                    ],
                    [
                        -121.98311666730035,
                        43.78915555548457
                    ],
                    [
                        -122.58630833396239,
                        43.93016666675834
                    ],
                    [
                        -122.98333333356646,
                        44.04999999930055
                    ],
                    [
                        -123.28333333386621,
                        44.08333333316682
                    ],
                    [
                        -123.56666666723288,
                        44.01666666633366
                    ],
                    [
                        -123.63333333316677,
                        43.99999999940053
                    ],
                    [
                        -123.93333333346652,
                        43.99999999940053
                    ],
                    [
                        -123.9833333333666,
                        43.99999999940053
                    ],
                    [
                        -125.49999999999994,
                        43.99999999940053
                    ],
                    [
                        -126.63404444449549,
                        43.54672499945923
                    ],
                    [
                        -126.66666666733278,
                        43.53333333336667
                    ],
                    [
                        -126.68666666693309,
                        43.34305555546956
                    ],
                    [
                        -126.69416666655837,
                        43.27222222167825
                    ],
                    [
                        -126.72277777817192,
                        42.999999999600334
                    ],
                    [
                        -126.78333333316675,
                        42.41666666683324
                    ],
                    [
                        -126.75107777831676,
                        42.41728055485379
                    ],
                    [
                        -123.74999999990007,
                        42.43333333286705
                    ],
                    [
                        -123.45277777787214,
                        42.418055555519516
                    ],
                    [
                        -123.33333333376629,
                        42.41666666683324
                    ],
                    [
                        -122.90000000069944,
                        42.58333333256729
                    ],
                    [
                        -122.41666666683318,
                        42.38333333296697
                    ],
                    [
                        -122.41666666683318,
                        42.43333333286705
                    ],
                    [
                        -122.41666666683318,
                        42.69999999930059
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 1067,
            "artcc": "zse",
            "sector": "36",
            "sectype": "low",
            "Shape_Length": 11.958695276803912,
            "Shape_Area": 6.821507769935795
        }
    },
    {
        "type": "Feature",
        "id": 1082,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -85.93055555559437,
                        34.81111111108896
                    ],
                    [
                        -86.07500000054955,
                        34.63611111053939
                    ],
                    [
                        -86.17500000034971,
                        34.387499999725264
                    ],
                    [
                        -86.01666666673327,
                        34.24444444435568
                    ],
                    [
                        -85.89264166697933,
                        34.016433332932024
                    ],
                    [
                        -85.82500000014983,
                        33.891666666783294
                    ],
                    [
                        -85.81666666713295,
                        33.861111111188904
                    ],
                    [
                        -85.70833333341659,
                        33.499999999700265
                    ],
                    [
                        -86.00416666675824,
                        33.26666666623373
                    ],
                    [
                        -85.78083333399115,
                        33.18583333264223
                    ],
                    [
                        -85.54444444505503,
                        33.09999999960036
                    ],
                    [
                        -85.46666666693312,
                        33.19999999940052
                    ],
                    [
                        -85.2666666673328,
                        33.21666666633365
                    ],
                    [
                        -85.2250000004496,
                        33.345833333041924
                    ],
                    [
                        -85.2250000004496,
                        33.758333333116866
                    ],
                    [
                        -85.16805555621892,
                        33.87083333289206
                    ],
                    [
                        -85.15611111153851,
                        33.89416666605882
                    ],
                    [
                        -85.11666666673324,
                        33.972222222277765
                    ],
                    [
                        -84.95000000009986,
                        34.16249999927561
                    ],
                    [
                        -84.79166666738274,
                        34.2388888887113
                    ],
                    [
                        -84.97222222267737,
                        34.53611111073923
                    ],
                    [
                        -85.3583333332167,
                        34.53611111073923
                    ],
                    [
                        -85.93055555559437,
                        34.81111111108896
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 1082,
            "artcc": "ztl",
            "sector": "01",
            "sectype": "low",
            "Shape_Length": 5.297070785612349,
            "Shape_Area": 1.2096237384337805
        }
    },
    {
        "type": "Feature",
        "id": 1085,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -85.60277777797205,
                        33.903333333366675
                    ],
                    [
                        -86.03166666688315,
                        34.070555555294675
                    ],
                    [
                        -86.45023055629508,
                        34.234022221848136
                    ],
                    [
                        -86.60000000039969,
                        34.149999999300576
                    ],
                    [
                        -86.61666666733277,
                        34.05833333341667
                    ],
                    [
                        -86.59388888946063,
                        33.64499999995007
                    ],
                    [
                        -86.5919444445804,
                        33.48472222145341
                    ],
                    [
                        -86.58805555571928,
                        33.429999999300605
                    ],
                    [
                        -86.14888888891107,
                        33.11055555559449
                    ],
                    [
                        -85.78083333399115,
                        33.18583333264223
                    ],
                    [
                        -85.17805555601905,
                        33.3088888884115
                    ],
                    [
                        -84.91166666678322,
                        33.35249999927561
                    ],
                    [
                        -84.76250000047463,
                        33.35555555564446
                    ],
                    [
                        -84.79472222285227,
                        33.49694444423079
                    ],
                    [
                        -84.52249999987504,
                        33.49777777762239
                    ],
                    [
                        -84.53333333396614,
                        33.62499999945044
                    ],
                    [
                        -84.52722222212782,
                        33.833333332967015
                    ],
                    [
                        -84.66666666673325,
                        33.833333332967015
                    ],
                    [
                        -84.73333333356646,
                        33.90555555544461
                    ],
                    [
                        -85.15611111153851,
                        33.89416666605882
                    ],
                    [
                        -85.60277777797205,
                        33.903333333366675
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 1085,
            "artcc": "ztl",
            "sector": "04",
            "sectype": "low",
            "Shape_Length": 5.617539346891974,
            "Shape_Area": 1.4530198575637039
        }
    },
    {
        "type": "Feature",
        "id": 1086,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -84.73333333356646,
                        33.90555555544461
                    ],
                    [
                        -84.79166666738274,
                        34.2388888887113
                    ],
                    [
                        -84.97222222267737,
                        34.53611111073923
                    ],
                    [
                        -85.08000000019979,
                        34.92916666650848
                    ],
                    [
                        -85.12111111178837,
                        35.031666666483545
                    ],
                    [
                        -85.33444444475526,
                        35.188333333316734
                    ],
                    [
                        -85.41658888916584,
                        35.00216111074673
                    ],
                    [
                        -85.48333333386626,
                        34.84999999970029
                    ],
                    [
                        -85.93055555559437,
                        34.81111111108896
                    ],
                    [
                        -86.07500000054955,
                        34.63611111053939
                    ],
                    [
                        -86.17500000034971,
                        34.387499999725264
                    ],
                    [
                        -86.45023055629508,
                        34.234022221848136
                    ],
                    [
                        -86.03166666688315,
                        34.070555555294675
                    ],
                    [
                        -85.60277777797205,
                        33.903333333366675
                    ],
                    [
                        -85.15611111153851,
                        33.89416666605882
                    ],
                    [
                        -84.73333333356646,
                        33.90555555544461
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 1086,
            "artcc": "ztl",
            "sector": "05",
            "sectype": "low",
            "Shape_Length": 4.876793371157878,
            "Shape_Area": 1.2241199224189954
        }
    },
    {
        "type": "Feature",
        "id": 1089,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -84.76250000047463,
                        33.35555555564446
                    ],
                    [
                        -84.91166666678322,
                        33.35249999927561
                    ],
                    [
                        -85.17805555601905,
                        33.3088888884115
                    ],
                    [
                        -85.78083333399115,
                        33.18583333264223
                    ],
                    [
                        -86.00416666675824,
                        33.26666666623373
                    ],
                    [
                        -86.14888888891107,
                        33.11055555559449
                    ],
                    [
                        -86.18055555599409,
                        33.07222222227779
                    ],
                    [
                        -86.25277777847168,
                        32.49027777729765
                    ],
                    [
                        -86.14166666738276,
                        32.237499999525426
                    ],
                    [
                        -85.9472222225275,
                        32.22499999955039
                    ],
                    [
                        -85.69583333344156,
                        32.008333333016935
                    ],
                    [
                        -85.49444444425563,
                        31.777777777822223
                    ],
                    [
                        -85.23888888911091,
                        31.827777777722304
                    ],
                    [
                        -85.07499999985009,
                        31.849999999400495
                    ],
                    [
                        -84.69444444495514,
                        31.923611110564366
                    ],
                    [
                        -84.57916666690812,
                        31.952777777472477
                    ],
                    [
                        -84.71666666663333,
                        33.00833333281713
                    ],
                    [
                        -84.76250000047463,
                        33.35555555564446
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 1089,
            "artcc": "ztl",
            "sector": "09",
            "sectype": "low",
            "Shape_Length": 5.576843006516104,
            "Shape_Area": 1.9992721066838044
        }
    },
    {
        "type": "Feature",
        "id": 1092,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -87.24999999999994,
                        34.100000000299815
                    ],
                    [
                        -87.55000000029975,
                        34.01666666653347
                    ],
                    [
                        -87.56666666633356,
                        33.890277778097015
                    ],
                    [
                        -87.59166666628357,
                        33.67083333329174
                    ],
                    [
                        -87.61388888886108,
                        33.5124999996753
                    ],
                    [
                        -87.61666666713296,
                        33.445833333741405
                    ],
                    [
                        -86.78333333306682,
                        33.554166666558444
                    ],
                    [
                        -86.5919444445804,
                        33.48472222235273
                    ],
                    [
                        -86.59388888856131,
                        33.64499999995007
                    ],
                    [
                        -86.61666666643345,
                        34.05833333341667
                    ],
                    [
                        -86.60000000039969,
                        34.150000000199896
                    ],
                    [
                        -86.71333333356648,
                        34.100000000299815
                    ],
                    [
                        -87.03333333346654,
                        34.100000000299815
                    ],
                    [
                        -87.24999999999994,
                        34.100000000299815
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 1092,
            "artcc": "ztl",
            "sector": "12",
            "sectype": "low",
            "Shape_Length": 3.258218307512966,
            "Shape_Area": 0.571229668334156
        }
    },
    {
        "type": "Feature",
        "id": 1093,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -87.02500000044961,
                        32.44305555566939
                    ],
                    [
                        -87.22500000004993,
                        32.450000000000045
                    ],
                    [
                        -87.8638888892608,
                        32.474999999950114
                    ],
                    [
                        -88.11111111138865,
                        32.508333332917005
                    ],
                    [
                        -88.34722222222774,
                        32.33055555499493
                    ],
                    [
                        -88.34444444485518,
                        32.272222222077914
                    ],
                    [
                        -88.34722222222774,
                        32.06944444420577
                    ],
                    [
                        -88.32500000054955,
                        31.5166666661338
                    ],
                    [
                        -87.81666666673323,
                        31.5208333330919
                    ],
                    [
                        -87.55000000029975,
                        31.35833333341668
                    ],
                    [
                        -87.4000000005995,
                        31.28055555529471
                    ],
                    [
                        -87.29999999990002,
                        31.41666666633364
                    ],
                    [
                        -86.95000000059952,
                        31.491666666183733
                    ],
                    [
                        -86.74583333314177,
                        31.52916666610878
                    ],
                    [
                        -86.5083333336164,
                        31.57916666600886
                    ],
                    [
                        -85.92499999994999,
                        31.70833333271719
                    ],
                    [
                        -85.89166666698304,
                        31.70694444403091
                    ],
                    [
                        -85.58333333366636,
                        31.76249999957537
                    ],
                    [
                        -85.49444444425563,
                        31.777777777822223
                    ],
                    [
                        -85.69583333344156,
                        32.008333333016935
                    ],
                    [
                        -85.9472222225275,
                        32.22499999955039
                    ],
                    [
                        -86.14166666738276,
                        32.237499999525426
                    ],
                    [
                        -86.25277777847168,
                        32.49027777729765
                    ],
                    [
                        -86.42916666680821,
                        32.404166666158744
                    ],
                    [
                        -87.02500000044961,
                        32.44305555566939
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 1093,
            "artcc": "ztl",
            "sector": "13",
            "sectype": "low",
            "Shape_Length": 7.102328324107409,
            "Shape_Area": 2.3120592212148128
        }
    },
    {
        "type": "Feature",
        "id": 1094,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -86.25277777847168,
                        32.49027777729765
                    ],
                    [
                        -86.18055555599409,
                        33.07222222227779
                    ],
                    [
                        -86.14888888891107,
                        33.11055555559449
                    ],
                    [
                        -86.58805555571928,
                        33.429999999300605
                    ],
                    [
                        -86.5919444445804,
                        33.48472222145341
                    ],
                    [
                        -86.78333333396614,
                        33.554166666558444
                    ],
                    [
                        -87.61666666713296,
                        33.445833332842085
                    ],
                    [
                        -87.63333333316677,
                        33.31666666613381
                    ],
                    [
                        -87.67416666665832,
                        33.286111110539366
                    ],
                    [
                        -87.72499999995,
                        33.24166666628366
                    ],
                    [
                        -87.80833333371635,
                        33.183333333366704
                    ],
                    [
                        -87.98611111163848,
                        33.04305555536962
                    ],
                    [
                        -87.8500000005995,
                        32.69722222212789
                    ],
                    [
                        -87.94583333344156,
                        32.62777777792218
                    ],
                    [
                        -87.99166666728286,
                        32.59166666668335
                    ],
                    [
                        -88.11111111138865,
                        32.508333332917005
                    ],
                    [
                        -87.8638888892608,
                        32.474999999950114
                    ],
                    [
                        -87.22500000004993,
                        32.450000000000045
                    ],
                    [
                        -87.02500000044961,
                        32.44305555566939
                    ],
                    [
                        -86.42916666680821,
                        32.404166666158744
                    ],
                    [
                        -86.25277777847168,
                        32.49027777729765
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 1094,
            "artcc": "ztl",
            "sector": "14",
            "sectype": "low",
            "Shape_Length": 5.431270687860647,
            "Shape_Area": 1.6537679781204977
        }
    },
    {
        "type": "Feature",
        "id": 1096,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -82.34999999990004,
                        34.054166666458514
                    ],
                    [
                        -82.45604999990752,
                        34.26186944391975
                    ],
                    [
                        -82.4554000005096,
                        34.34466666663343
                    ],
                    [
                        -82.37425277834302,
                        34.498030555265984
                    ],
                    [
                        -82.39444444505506,
                        34.83888888841153
                    ],
                    [
                        -82.97638888913588,
                        34.51833333321679
                    ],
                    [
                        -83.31722222292723,
                        34.33083333269218
                    ],
                    [
                        -83.76250000067444,
                        34.03333333256728
                    ],
                    [
                        -83.97194444478026,
                        33.8969444443307
                    ],
                    [
                        -84.02805555561935,
                        33.89416666605882
                    ],
                    [
                        -84.15833333381624,
                        33.92499999975024
                    ],
                    [
                        -84.2333333336664,
                        33.84999999990015
                    ],
                    [
                        -84.33333333346656,
                        33.84999999990015
                    ],
                    [
                        -84.34444444475531,
                        33.627777777722315
                    ],
                    [
                        -84.33333333346656,
                        33.43333333286705
                    ],
                    [
                        -84.2333333336664,
                        33.43333333286705
                    ],
                    [
                        -84.08750000002493,
                        33.36666666603384
                    ],
                    [
                        -83.88333333346651,
                        33.43666666643355
                    ],
                    [
                        -83.6391666668082,
                        33.450555555094866
                    ],
                    [
                        -83.08194444458042,
                        33.49805555571936
                    ],
                    [
                        -82.90833333361644,
                        33.5083333327172
                    ],
                    [
                        -82.72166666738275,
                        33.69194444438068
                    ],
                    [
                        -82.34999999990004,
                        34.054166666458514
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 1096,
            "artcc": "ztl",
            "sector": "16",
            "sectype": "low",
            "Shape_Length": 5.7179255918446925,
            "Shape_Area": 1.4736859498572072
        }
    },
    {
        "type": "Feature",
        "id": 1097,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -83.31722222292723,
                        34.33083333269218
                    ],
                    [
                        -82.97638888913588,
                        34.51833333321679
                    ],
                    [
                        -82.99166666738273,
                        34.539999999600354
                    ],
                    [
                        -83.01458333385375,
                        35.06097777710278
                    ],
                    [
                        -83.02777777772224,
                        35.26249999977523
                    ],
                    [
                        -82.95416666655836,
                        35.52916666620871
                    ],
                    [
                        -82.93888888921083,
                        35.61111111038946
                    ],
                    [
                        -83.00833333341654,
                        35.63055555559447
                    ],
                    [
                        -83.07083333329166,
                        35.644444444255726
                    ],
                    [
                        -83.35833333361643,
                        35.60833333301696
                    ],
                    [
                        -83.72499999985007,
                        35.31666666663341
                    ],
                    [
                        -83.92333333356646,
                        35.30777777742253
                    ],
                    [
                        -84.0000000001998,
                        34.99999999940053
                    ],
                    [
                        -84.13333333386623,
                        34.43833333301694
                    ],
                    [
                        -84.14944444460542,
                        34.37055555559448
                    ],
                    [
                        -84.15833333381624,
                        34.333333332867085
                    ],
                    [
                        -83.76250000067444,
                        34.03333333256728
                    ],
                    [
                        -83.31722222292723,
                        34.33083333269218
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 1097,
            "artcc": "ztl",
            "sector": "18",
            "sectype": "low",
            "Shape_Length": 4.626484683619825,
            "Shape_Area": 1.2896551738446635
        }
    },
    {
        "type": "Feature",
        "id": 1098,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -82.64583333324168,
                        32.447222221728225
                    ],
                    [
                        -82.66861111111388,
                        32.6208333326922
                    ],
                    [
                        -82.66250000017482,
                        32.88750000002506
                    ],
                    [
                        -82.70000000009986,
                        32.999999999800195
                    ],
                    [
                        -82.85944444430561,
                        33.3938888889611
                    ],
                    [
                        -82.90833333361644,
                        33.5083333327172
                    ],
                    [
                        -83.08194444458042,
                        33.49805555571936
                    ],
                    [
                        -83.6391666668082,
                        33.450555555094866
                    ],
                    [
                        -83.88333333346651,
                        33.43666666643355
                    ],
                    [
                        -84.08750000002493,
                        33.36666666603384
                    ],
                    [
                        -83.89444444475527,
                        33.129166666508524
                    ],
                    [
                        -83.56111111148857,
                        32.733333333366716
                    ],
                    [
                        -83.10000000019983,
                        32.27777777772229
                    ],
                    [
                        -82.64583333324168,
                        32.447222221728225
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 1098,
            "artcc": "ztl",
            "sector": "19",
            "sectype": "low",
            "Shape_Length": 4.2598547590353055,
            "Shape_Area": 1.0767909338187385
        }
    },
    {
        "type": "Feature",
        "id": 1100,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -84.26472222265244,
                        33.40416666595894
                    ],
                    [
                        -84.39083333389118,
                        33.40583333274219
                    ],
                    [
                        -84.42194444478025,
                        33.35999999980021
                    ],
                    [
                        -84.76250000047463,
                        33.35555555564446
                    ],
                    [
                        -84.71666666663333,
                        33.00833333281713
                    ],
                    [
                        -84.57916666690812,
                        31.952777777472477
                    ],
                    [
                        -84.23888888931077,
                        32.01333333336669
                    ],
                    [
                        -83.90833333341658,
                        32.07499999985015
                    ],
                    [
                        -83.80000000059948,
                        32.09166666678328
                    ],
                    [
                        -83.5583333332167,
                        32.11666666673335
                    ],
                    [
                        -83.49583333334164,
                        32.12499999975023
                    ],
                    [
                        -83.10000000019983,
                        32.27777777772229
                    ],
                    [
                        -83.56111111148857,
                        32.733333333366716
                    ],
                    [
                        -83.89444444475527,
                        33.129166666508524
                    ],
                    [
                        -84.08750000002493,
                        33.36666666603384
                    ],
                    [
                        -84.20055555599407,
                        33.363888888661336
                    ],
                    [
                        -84.26472222265244,
                        33.40416666595894
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 1100,
            "artcc": "ztl",
            "sector": "21",
            "sectype": "low",
            "Shape_Length": 5.11920171556813,
            "Shape_Area": 1.459668363901785
        }
    },
    {
        "type": "Feature",
        "id": 1103,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -82.06666666653342,
                        32.43333333306691
                    ],
                    [
                        -81.93333333376631,
                        32.56249999977524
                    ],
                    [
                        -81.89999999990005,
                        32.67777777782226
                    ],
                    [
                        -81.84361111096393,
                        32.891666666083836
                    ],
                    [
                        -81.84138888888606,
                        32.90027777719769
                    ],
                    [
                        -81.84666666733278,
                        33.51694444383105
                    ],
                    [
                        -81.84999999999997,
                        33.55555555524472
                    ],
                    [
                        -81.84999999999997,
                        33.68333333326677
                    ],
                    [
                        -81.84999999999997,
                        33.888888888511474
                    ],
                    [
                        -82.34999999990004,
                        34.054166666458514
                    ],
                    [
                        -82.72166666738275,
                        33.69194444438068
                    ],
                    [
                        -82.90833333361644,
                        33.5083333327172
                    ],
                    [
                        -82.85944444430561,
                        33.3938888889611
                    ],
                    [
                        -82.70000000009986,
                        32.999999999800195
                    ],
                    [
                        -82.66250000017482,
                        32.88750000002506
                    ],
                    [
                        -82.66861111111388,
                        32.6208333326922
                    ],
                    [
                        -82.64583333324168,
                        32.447222221728225
                    ],
                    [
                        -82.32222222257747,
                        32.33333333326675
                    ],
                    [
                        -82.2000000001998,
                        32.291666666383605
                    ],
                    [
                        -82.06666666653342,
                        32.43333333306691
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 1103,
            "artcc": "ztl",
            "sector": "24",
            "sectype": "low",
            "Shape_Length": 4.608477890306963,
            "Shape_Area": 1.3412464120472751
        }
    },
    {
        "type": "Feature",
        "id": 1104,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -84.7055555562439,
                        34.279166666008905
                    ],
                    [
                        -84.42594722288601,
                        34.307136110443196
                    ],
                    [
                        -84.42055833320046,
                        34.307672221495864
                    ],
                    [
                        -84.15833333381624,
                        34.333333332867085
                    ],
                    [
                        -84.14944444460542,
                        34.37055555559448
                    ],
                    [
                        -84.13333333386623,
                        34.43833333301694
                    ],
                    [
                        -84.0000000001998,
                        34.99999999940053
                    ],
                    [
                        -83.92333333356646,
                        35.30777777742253
                    ],
                    [
                        -84.46111111148855,
                        35.28333333276714
                    ],
                    [
                        -84.63055555549442,
                        34.63611111053939
                    ],
                    [
                        -84.94722222272736,
                        34.53611111073923
                    ],
                    [
                        -84.97222222267737,
                        34.53611111073923
                    ],
                    [
                        -84.79166666738274,
                        34.2388888887113
                    ],
                    [
                        -84.7055555562439,
                        34.279166666008905
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 1104,
            "artcc": "ztl",
            "sector": "25",
            "sectype": "low",
            "Shape_Length": 3.5595712982694927,
            "Shape_Area": 0.6044086412841818
        }
    },
    {
        "type": "Feature",
        "id": 1108,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -80.22916666660836,
                        35.4999999993006
                    ],
                    [
                        -79.78333333356647,
                        35.6499999999001
                    ],
                    [
                        -79.76002777775972,
                        35.913011111073956
                    ],
                    [
                        -79.75638888873618,
                        35.94999999930059
                    ],
                    [
                        -79.74444444495515,
                        36.08888888861139
                    ],
                    [
                        -79.86666666733277,
                        36.133333332867096
                    ],
                    [
                        -79.9500000001998,
                        36.15416666675833
                    ],
                    [
                        -79.97944444430561,
                        36.204722221953034
                    ],
                    [
                        -80.11944444510505,
                        36.443611111064
                    ],
                    [
                        -80.2500000004996,
                        36.66666666663343
                    ],
                    [
                        -80.2594444450051,
                        36.68472222225279
                    ],
                    [
                        -80.65833333361644,
                        36.613888888461474
                    ],
                    [
                        -80.63361111176334,
                        36.31138888888614
                    ],
                    [
                        -80.60833333371636,
                        36.00000000009999
                    ],
                    [
                        -80.59166666678323,
                        35.80833333261728
                    ],
                    [
                        -80.70000000049959,
                        35.66666666683324
                    ],
                    [
                        -80.9594444445055,
                        35.32416666625869
                    ],
                    [
                        -80.22916666660836,
                        35.4999999993006
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 1108,
            "artcc": "ztl",
            "sector": "29",
            "sectype": "low",
            "Shape_Length": 4.313778304205189,
            "Shape_Area": 0.8648912629336223
        }
    },
    {
        "type": "Feature",
        "id": 1109,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -80.85166666698308,
                        34.59222222157831
                    ],
                    [
                        -80.724166667058,
                        34.644166666358615
                    ],
                    [
                        -80.57833333341654,
                        34.70333333266723
                    ],
                    [
                        -80.5177777784217,
                        34.727777777322615
                    ],
                    [
                        -80.46888888911093,
                        34.74749999972528
                    ],
                    [
                        -80.14722222242756,
                        34.855555555344665
                    ],
                    [
                        -80.00000000009987,
                        35.10833333311689
                    ],
                    [
                        -79.99416666725784,
                        35.11666666613377
                    ],
                    [
                        -79.80416666655839,
                        35.383333332567304
                    ],
                    [
                        -79.78333333356647,
                        35.628611110714246
                    ],
                    [
                        -79.78333333356647,
                        35.6499999999001
                    ],
                    [
                        -80.22916666660836,
                        35.4999999993006
                    ],
                    [
                        -80.9594444445055,
                        35.32416666625869
                    ],
                    [
                        -81.22027777809694,
                        35.15249999927562
                    ],
                    [
                        -81.19638888873618,
                        35.11444444405589
                    ],
                    [
                        -81.20000000039965,
                        35.06666666623369
                    ],
                    [
                        -81.21722222262741,
                        34.77888888871132
                    ],
                    [
                        -81.2333333333666,
                        34.50833333341666
                    ],
                    [
                        -81.24166666728286,
                        34.43222222207794
                    ],
                    [
                        -80.85166666698308,
                        34.59222222157831
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 1109,
            "artcc": "ztl",
            "sector": "30",
            "sectype": "low",
            "Shape_Length": 4.334161712420118,
            "Shape_Area": 0.9294527776187894
        }
    },
    {
        "type": "Feature",
        "id": 1110,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -82.61666666723289,
                        35.33805555491995
                    ],
                    [
                        -82.44166666668326,
                        35.11666666613377
                    ],
                    [
                        -82.39444444505506,
                        34.83888888841153
                    ],
                    [
                        -82.37425277834302,
                        34.498030555265984
                    ],
                    [
                        -82.4554000005096,
                        34.34466666663343
                    ],
                    [
                        -82.45604999990752,
                        34.26186944391975
                    ],
                    [
                        -82.34999999990004,
                        34.054166666458514
                    ],
                    [
                        -81.84999999999997,
                        33.888888888511474
                    ],
                    [
                        -81.80444444425564,
                        33.985277777547424
                    ],
                    [
                        -81.5744444443556,
                        34.293611110864106
                    ],
                    [
                        -81.33333333316676,
                        34.39444444405592
                    ],
                    [
                        -81.24166666728286,
                        34.43222222207794
                    ],
                    [
                        -81.2333333333666,
                        34.50833333341666
                    ],
                    [
                        -81.21722222262741,
                        34.77888888871132
                    ],
                    [
                        -81.20000000039965,
                        35.06666666623369
                    ],
                    [
                        -81.19638888873618,
                        35.11444444405589
                    ],
                    [
                        -81.22027777809694,
                        35.15249999927562
                    ],
                    [
                        -81.31388888886107,
                        35.299999999700276
                    ],
                    [
                        -81.59611111163844,
                        35.268888888811205
                    ],
                    [
                        -81.72111111138867,
                        35.25499999925063
                    ],
                    [
                        -81.80638888913586,
                        35.2519444437811
                    ],
                    [
                        -82.19055555569429,
                        35.236944444530536
                    ],
                    [
                        -82.61666666723289,
                        35.33805555491995
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 1110,
            "artcc": "ztl",
            "sector": "31",
            "sectype": "low",
            "Shape_Length": 4.994194693286587,
            "Shape_Area": 1.368941133562222
        }
    },
    {
        "type": "Feature",
        "id": 1116,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -84.34166666738275,
                        33.919722222202836
                    ],
                    [
                        -84.15833333381624,
                        33.94527777744753
                    ],
                    [
                        -84.15833333381624,
                        34.333333332867085
                    ],
                    [
                        -84.14944444460542,
                        34.37055555559448
                    ],
                    [
                        -84.13333333386623,
                        34.43833333301694
                    ],
                    [
                        -84.0000000001998,
                        34.99999999940053
                    ],
                    [
                        -83.92333333356646,
                        35.30777777742253
                    ],
                    [
                        -83.81250000057452,
                        35.741388888586414
                    ],
                    [
                        -84.06250000007492,
                        35.68333333286705
                    ],
                    [
                        -84.52777777832176,
                        35.336111110939044
                    ],
                    [
                        -84.92916666710795,
                        35.044444443656175
                    ],
                    [
                        -85.08000000019979,
                        34.92916666650848
                    ],
                    [
                        -84.97222222267737,
                        34.53611111073923
                    ],
                    [
                        -84.79166666738274,
                        34.2388888887113
                    ],
                    [
                        -84.73333333356646,
                        33.90555555544461
                    ],
                    [
                        -84.53333333396614,
                        33.912222221678235
                    ],
                    [
                        -84.44055555569429,
                        33.856388888936124
                    ],
                    [
                        -84.34166666738275,
                        33.919722222202836
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 1116,
            "artcc": "ztl",
            "sector": "38",
            "sectype": "low",
            "Shape_Length": 5.065879961289075,
            "Shape_Area": 1.293425848674537
        }
    },
    {
        "type": "Feature",
        "id": 1119,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -84.92916666710795,
                        35.044444443656175
                    ],
                    [
                        -84.52777777832176,
                        35.336111110939044
                    ],
                    [
                        -84.06250000007492,
                        35.68333333286705
                    ],
                    [
                        -83.81250000057452,
                        35.741388888586414
                    ],
                    [
                        -83.62500000004991,
                        35.78333333266721
                    ],
                    [
                        -83.56055555619395,
                        36.062499999975046
                    ],
                    [
                        -83.37500000054956,
                        36.35833333331675
                    ],
                    [
                        -83.25166666668326,
                        36.56083333309192
                    ],
                    [
                        -83.18333333396617,
                        36.67222222227781
                    ],
                    [
                        -83.3194444450051,
                        36.711111110889135
                    ],
                    [
                        -83.5583333332167,
                        36.6499999997003
                    ],
                    [
                        -84.11111111128872,
                        36.51249999997509
                    ],
                    [
                        -84.58333333386622,
                        36.3958333332418
                    ],
                    [
                        -85.40000000009991,
                        36.18333333276712
                    ],
                    [
                        -85.32083333329166,
                        35.824999999550414
                    ],
                    [
                        -85.28333333336661,
                        35.6499999999001
                    ],
                    [
                        -85.25833333341654,
                        35.537500000124965
                    ],
                    [
                        -85.23333333346653,
                        35.41666666643357
                    ],
                    [
                        -85.33444444475526,
                        35.188333333316734
                    ],
                    [
                        -85.12111111178837,
                        35.031666666483545
                    ],
                    [
                        -85.08000000019979,
                        34.92916666650848
                    ],
                    [
                        -84.92916666710795,
                        35.044444443656175
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 1119,
            "artcc": "ztl",
            "sector": "41",
            "sectype": "low",
            "Shape_Length": 6.416315650111133,
            "Shape_Area": 1.9125159720982177
        }
    },
    {
        "type": "Feature",
        "id": 1122,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -81.80638888913586,
                        35.2519444437811
                    ],
                    [
                        -81.72111111138867,
                        35.25499999925063
                    ],
                    [
                        -81.59611111163844,
                        35.268888888811205
                    ],
                    [
                        -81.31388888886107,
                        35.299999999700276
                    ],
                    [
                        -81.43611111123874,
                        35.62916666600887
                    ],
                    [
                        -81.88444444445548,
                        36.21888888871132
                    ],
                    [
                        -82.06472222255252,
                        36.45277777747248
                    ],
                    [
                        -82.22083333319176,
                        36.32499999945048
                    ],
                    [
                        -82.27916666700804,
                        36.213888888361566
                    ],
                    [
                        -82.61111111158851,
                        35.983333333166854
                    ],
                    [
                        -82.8027777781719,
                        35.84583333344165
                    ],
                    [
                        -82.82916666680819,
                        35.583333333066946
                    ],
                    [
                        -82.61666666723289,
                        35.33805555491995
                    ],
                    [
                        -82.19055555569429,
                        35.236944444530536
                    ],
                    [
                        -81.80638888913586,
                        35.2519444437811
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 1122,
            "artcc": "ztl",
            "sector": "44",
            "sectype": "low",
            "Shape_Length": 4.260281193907402,
            "Shape_Area": 1.1126308258519342
        }
    },
    {
        "type": "Feature",
        "id": 1123,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -80.32361111166341,
                        36.80833333331674
                    ],
                    [
                        -80.50194444488017,
                        37.11833333341667
                    ],
                    [
                        -80.58111111168841,
                        37.25555555504491
                    ],
                    [
                        -80.63333333366637,
                        37.3319444444806
                    ],
                    [
                        -80.74583333344157,
                        37.30416666625871
                    ],
                    [
                        -80.90000000009991,
                        37.27083333329176
                    ],
                    [
                        -81.16250000047461,
                        37.211111110789204
                    ],
                    [
                        -81.93333333376631,
                        37.03333333286707
                    ],
                    [
                        -82.25555555574425,
                        36.9597222217032
                    ],
                    [
                        -82.7583333339162,
                        36.843055554969965
                    ],
                    [
                        -82.9750000004496,
                        36.794444443756106
                    ],
                    [
                        -83.11194444488018,
                        36.76472222155331
                    ],
                    [
                        -83.12916666710794,
                        36.76111111078916
                    ],
                    [
                        -83.3194444450051,
                        36.711111110889135
                    ],
                    [
                        -83.18333333396617,
                        36.67222222227781
                    ],
                    [
                        -83.25166666668326,
                        36.56083333309192
                    ],
                    [
                        -83.37500000054956,
                        36.35833333331675
                    ],
                    [
                        -83.56055555619395,
                        36.062499999975046
                    ],
                    [
                        -83.62500000004991,
                        35.78333333266721
                    ],
                    [
                        -83.35833333361643,
                        35.71666666673332
                    ],
                    [
                        -83.07083333329166,
                        35.644444444255726
                    ],
                    [
                        -83.00833333341654,
                        35.63055555559447
                    ],
                    [
                        -82.93888888921083,
                        35.61111111038946
                    ],
                    [
                        -82.82916666680819,
                        35.583333333066946
                    ],
                    [
                        -82.8027777781719,
                        35.84583333344165
                    ],
                    [
                        -82.61111111158851,
                        35.983333333166854
                    ],
                    [
                        -82.27916666700804,
                        36.213888888361566
                    ],
                    [
                        -82.22083333319176,
                        36.32499999945048
                    ],
                    [
                        -82.06472222255252,
                        36.45277777747248
                    ],
                    [
                        -81.76138888958553,
                        36.54638888823666
                    ],
                    [
                        -81.36666666703303,
                        36.66666666663343
                    ],
                    [
                        -81.2333333333666,
                        36.686111110939066
                    ],
                    [
                        -80.99166666688313,
                        36.71666666653351
                    ],
                    [
                        -80.65833333361644,
                        36.613888888461474
                    ],
                    [
                        -80.2594444450051,
                        36.68472222225279
                    ],
                    [
                        -80.32361111166341,
                        36.80833333331674
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 1123,
            "artcc": "ztl",
            "sector": "45",
            "sectype": "low",
            "Shape_Length": 8.564083201142681,
            "Shape_Area": 2.2042365351651325
        }
    },
    {
        "type": "Feature",
        "id": 1124,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -80.9594444445055,
                        35.32416666625869
                    ],
                    [
                        -80.70000000049959,
                        35.66666666683324
                    ],
                    [
                        -80.59166666678323,
                        35.80833333261728
                    ],
                    [
                        -80.60833333371636,
                        36.00000000009999
                    ],
                    [
                        -80.63361111176334,
                        36.31138888888614
                    ],
                    [
                        -80.65833333361644,
                        36.613888888461474
                    ],
                    [
                        -80.99166666688313,
                        36.71666666653351
                    ],
                    [
                        -81.2333333333666,
                        36.686111110939066
                    ],
                    [
                        -81.36666666703303,
                        36.66666666663343
                    ],
                    [
                        -81.76138888958553,
                        36.54638888823666
                    ],
                    [
                        -82.06472222255252,
                        36.45277777747248
                    ],
                    [
                        -81.88444444445548,
                        36.21888888871132
                    ],
                    [
                        -81.43611111123874,
                        35.62916666600887
                    ],
                    [
                        -81.31388888886107,
                        35.299999999700276
                    ],
                    [
                        -81.22027777809694,
                        35.15249999927562
                    ],
                    [
                        -80.9594444445055,
                        35.32416666625869
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 1124,
            "artcc": "ztl",
            "sector": "47",
            "sectype": "low",
            "Shape_Length": 4.747742891518104,
            "Shape_Area": 1.3555787806663944
        }
    },
    {
        "type": "Feature",
        "id": 1125,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -81.67916666730781,
                        35.43166666658345
                    ],
                    [
                        -81.30000000019982,
                        35.57500000005001
                    ],
                    [
                        -81.12777777792206,
                        35.637499999925126
                    ],
                    [
                        -80.59166666678323,
                        35.80833333261728
                    ],
                    [
                        -80.60833333371636,
                        36.00000000009999
                    ],
                    [
                        -80.63361111176334,
                        36.31138888888614
                    ],
                    [
                        -80.65833333361644,
                        36.613888888461474
                    ],
                    [
                        -80.99166666688313,
                        36.71666666653351
                    ],
                    [
                        -81.2333333333666,
                        36.686111110939066
                    ],
                    [
                        -81.36666666703303,
                        36.66666666663343
                    ],
                    [
                        -81.76138888958553,
                        36.54638888823666
                    ],
                    [
                        -81.7777777784217,
                        36.504166666058836
                    ],
                    [
                        -81.84861111131369,
                        36.31527777774727
                    ],
                    [
                        -81.88444444445548,
                        36.21888888871132
                    ],
                    [
                        -81.90416666685815,
                        36.16666666673336
                    ],
                    [
                        -82.12313333333663,
                        36.05927777764737
                    ],
                    [
                        -82.06666666653342,
                        35.5500000001
                    ],
                    [
                        -82.04638888883613,
                        35.41583333304192
                    ],
                    [
                        -81.67916666730781,
                        35.43166666658345
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 1125,
            "artcc": "ztl",
            "sector": "48",
            "sectype": "low",
            "Shape_Length": 4.764559302922434,
            "Shape_Area": 1.4481808484104228
        }
    },
    {
        "type": "Feature",
        "id": 1126,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -83.88694444423066,
                        33.98361111076423
                    ],
                    [
                        -83.76250000067444,
                        34.03333333256728
                    ],
                    [
                        -83.31722222292723,
                        34.33083333269218
                    ],
                    [
                        -82.97638888913588,
                        34.51833333321679
                    ],
                    [
                        -82.39444444505506,
                        34.83888888841153
                    ],
                    [
                        -82.44166666668326,
                        35.11666666613377
                    ],
                    [
                        -82.61666666723289,
                        35.33805555491995
                    ],
                    [
                        -82.82916666680819,
                        35.583333333066946
                    ],
                    [
                        -82.93888888921083,
                        35.61111111038946
                    ],
                    [
                        -83.00833333341654,
                        35.63055555559447
                    ],
                    [
                        -83.07083333329166,
                        35.644444444255726
                    ],
                    [
                        -83.35833333361643,
                        35.71666666673332
                    ],
                    [
                        -83.62500000004991,
                        35.78333333266721
                    ],
                    [
                        -83.81250000057452,
                        35.741388888586414
                    ],
                    [
                        -83.92333333356646,
                        35.30777777742253
                    ],
                    [
                        -84.0000000001998,
                        34.99999999940053
                    ],
                    [
                        -84.13333333386623,
                        34.43833333301694
                    ],
                    [
                        -84.14944444460542,
                        34.37055555559448
                    ],
                    [
                        -84.15833333381624,
                        34.333333332867085
                    ],
                    [
                        -84.15833333381624,
                        33.94527777744753
                    ],
                    [
                        -83.88694444423066,
                        33.98361111076423
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 1126,
            "artcc": "ztl",
            "sector": "49",
            "sectype": "low",
            "Shape_Length": 5.736261297172557,
            "Shape_Area": 1.992644174494102
        }
    },
    {
        "type": "Feature",
        "id": 1130,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -83.76250000067444,
                        34.03333333256728
                    ],
                    [
                        -83.88694444423066,
                        33.98361111076423
                    ],
                    [
                        -84.15833333381624,
                        33.94527777744753
                    ],
                    [
                        -84.34166666738275,
                        33.919722222202836
                    ],
                    [
                        -84.44055555569429,
                        33.856388888936124
                    ],
                    [
                        -84.53333333396614,
                        33.912222221678235
                    ],
                    [
                        -84.73333333356646,
                        33.90555555544461
                    ],
                    [
                        -84.66666666673325,
                        33.833333332967015
                    ],
                    [
                        -84.52722222212782,
                        33.833333332967015
                    ],
                    [
                        -84.53333333396614,
                        33.62499999945044
                    ],
                    [
                        -84.52249999987504,
                        33.49777777762239
                    ],
                    [
                        -84.79472222285227,
                        33.49694444423079
                    ],
                    [
                        -84.76250000047463,
                        33.35555555564446
                    ],
                    [
                        -84.42194444478025,
                        33.35999999980021
                    ],
                    [
                        -84.39083333389118,
                        33.40583333274219
                    ],
                    [
                        -84.26472222265244,
                        33.40416666595894
                    ],
                    [
                        -84.20055555599407,
                        33.363888888661336
                    ],
                    [
                        -84.08750000002493,
                        33.36666666603384
                    ],
                    [
                        -84.2333333336664,
                        33.43333333286705
                    ],
                    [
                        -84.33333333346656,
                        33.43333333286705
                    ],
                    [
                        -84.34444444475531,
                        33.627777777722315
                    ],
                    [
                        -84.33333333346656,
                        33.84999999990015
                    ],
                    [
                        -84.2333333336664,
                        33.84999999990015
                    ],
                    [
                        -84.15833333381624,
                        33.92499999975024
                    ],
                    [
                        -84.02805555561935,
                        33.89416666605882
                    ],
                    [
                        -83.97194444478026,
                        33.8969444443307
                    ],
                    [
                        -83.76250000067444,
                        34.03333333256728
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 1130,
            "artcc": "ztl",
            "sector": "59",
            "sectype": "low",
            "Shape_Length": 4.044721826695688,
            "Shape_Area": 0.1818304012454617
        }
    }

	]