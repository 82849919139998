export const nas_high =
    [

        {
            "type": "Feature",
            "id": 1,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -100.00000000059953,
                            35.82916666650851
                        ],
                        [
                            -101.47222222207785,
                            36.397222221928075
                        ],
                        [
                            -101.75000000069946,
                            36.50000000000006
                        ],
                        [
                            -102.18333333376631,
                            37.04166666678327
                        ],
                        [
                            -102.54999999999995,
                            37.499999999800195
                        ],
                        [
                            -103.61944444490518,
                            37.162499999575346
                        ],
                        [
                            -104.00000000069946,
                            37.045277777547426
                        ],
                        [
                            -104.00000000069946,
                            36.93333333306691
                        ],
                        [
                            -104.00000000069946,
                            35.34166666658342
                        ],
                        [
                            -102.98333333396613,
                            35.34999999960036
                        ],
                        [
                            -102.98333333396613,
                            35.13750000002506
                        ],
                        [
                            -102.81666666733281,
                            34.808333332817085
                        ],
                        [
                            -102.32500000044962,
                            34.549999999400484
                        ],
                        [
                            -102.0000000001998,
                            34.599999999300564
                        ],
                        [
                            -101.00000000039967,
                            34.491666666483525
                        ],
                        [
                            -100.74999999999994,
                            34.46666666653351
                        ],
                        [
                            -100.31666666693309,
                            34.86666666663342
                        ],
                        [
                            -100.14166666728283,
                            35.1291666661088
                        ],
                        [
                            -100.06111111178836,
                            35.249999999800195
                        ],
                        [
                            -100.00000000059953,
                            35.33333333266722
                        ],
                        [
                            -100.00000000059953,
                            35.82916666650851
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 1,
                "artcc": "zab",
                "sector": "15",
                "sectype": "high",
                "Shape_Length": 11.767156844503083,
                "Shape_Area": 7.579923226567642
            }
        },
        {
            "type": "Feature",
            "id": 3,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -104.00000000069946,
                            35.34166666658342
                        ],
                        [
                            -104.00000000069946,
                            36.93333333306691
                        ],
                        [
                            -104.00000000069946,
                            37.045277777547426
                        ],
                        [
                            -105.0000000004996,
                            36.71666666653351
                        ],
                        [
                            -105.34166666678323,
                            36.71666666653351
                        ],
                        [
                            -106.08333333316676,
                            36.71666666653351
                        ],
                        [
                            -106.35000000049956,
                            36.626944443731134
                        ],
                        [
                            -107.466666667033,
                            36.19999999970025
                        ],
                        [
                            -106.991666667083,
                            35.42499999945045
                        ],
                        [
                            -106.91666666723285,
                            35.31666666663341
                        ],
                        [
                            -106.70000000069945,
                            35.31666666663341
                        ],
                        [
                            -106.2850000004496,
                            35.15944444450554
                        ],
                        [
                            -106.11916666720788,
                            35.163611110564375
                        ],
                        [
                            -105.47500000044965,
                            35.20833333291705
                        ],
                        [
                            -105.06666666733281,
                            34.99999999940053
                        ],
                        [
                            -104.55000000049961,
                            34.99999999940053
                        ],
                        [
                            -104.55000000049961,
                            35.08333333316682
                        ],
                        [
                            -104.00000000069946,
                            35.34166666658342
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 3,
                "artcc": "zab",
                "sector": "16",
                "sectype": "high",
                "Shape_Length": 9.49526636496875,
                "Shape_Area": 4.877738888593138
            }
        },
        {
            "type": "Feature",
            "id": 5,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -106.991666667083,
                            35.42499999945045
                        ],
                        [
                            -107.466666667033,
                            36.19999999970025
                        ],
                        [
                            -108.21666666733279,
                            36.033333333066935
                        ],
                        [
                            -109.31666666693309,
                            35.849999999500426
                        ],
                        [
                            -109.6416666671829,
                            35.68333333286705
                        ],
                        [
                            -110.31444444465535,
                            34.79777777772233
                        ],
                        [
                            -110.0833333332667,
                            34.700000000000045
                        ],
                        [
                            -109.58333333336662,
                            34.4999999995004
                        ],
                        [
                            -108.88333333386623,
                            33.999999999600334
                        ],
                        [
                            -108.7500000001998,
                            33.87499999985016
                        ],
                        [
                            -107.99999999990001,
                            33.833333332967015
                        ],
                        [
                            -107.60000000069942,
                            33.58333333256729
                        ],
                        [
                            -107.466666667033,
                            33.58333333256729
                        ],
                        [
                            -106.80277777787211,
                            33.58333333256729
                        ],
                        [
                            -106.7555555562439,
                            33.82916666600886
                        ],
                        [
                            -106.67499999985006,
                            34.26249999997509
                        ],
                        [
                            -106.64999999990005,
                            34.48333333256727
                        ],
                        [
                            -106.6166666669331,
                            34.84999999970029
                        ],
                        [
                            -106.2850000004496,
                            35.15944444450554
                        ],
                        [
                            -106.70000000069945,
                            35.31666666663341
                        ],
                        [
                            -106.91666666723285,
                            35.31666666663341
                        ],
                        [
                            -106.991666667083,
                            35.42499999945045
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 5,
                "artcc": "zab",
                "sector": "17",
                "sectype": "high",
                "Shape_Length": 10.649687965773897,
                "Shape_Area": 6.458814815318888
            }
        },
        {
            "type": "Feature",
            "id": 7,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -106.35000000049956,
                            33.06666666663341
                        ],
                        [
                            -106.06666666713295,
                            33.56666666653348
                        ],
                        [
                            -106.06666666713295,
                            33.74583333314189
                        ],
                        [
                            -106.27500000064947,
                            33.82499999995008
                        ],
                        [
                            -106.7555555562439,
                            33.82916666600886
                        ],
                        [
                            -106.80277777787211,
                            33.58333333256729
                        ],
                        [
                            -107.466666667033,
                            33.58333333256729
                        ],
                        [
                            -107.75833333341654,
                            32.949999999900115
                        ],
                        [
                            -107.99999999990001,
                            32.41666666613378
                        ],
                        [
                            -108.20000000039965,
                            32.41666666613378
                        ],
                        [
                            -108.20000000039965,
                            31.533333333066935
                        ],
                        [
                            -106.45000000029972,
                            31.533333333066935
                        ],
                        [
                            -106.45000000029972,
                            31.566666666033882
                        ],
                        [
                            -106.33333333356643,
                            31.666666666733363
                        ],
                        [
                            -106.23333333376632,
                            31.749999999600334
                        ],
                        [
                            -106.23333333376632,
                            32.09999999980022
                        ],
                        [
                            -106.20833333381626,
                            32.2499999995004
                        ],
                        [
                            -106.17194444448052,
                            32.299999999400484
                        ],
                        [
                            -106.11805555571931,
                            32.388333332617265
                        ],
                        [
                            -106.1000000000999,
                            32.41666666613378
                        ],
                        [
                            -106.1000000000999,
                            32.56666666673334
                        ],
                        [
                            -106.25000000069946,
                            32.56666666673334
                        ],
                        [
                            -106.35000000049956,
                            33.06666666663341
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 7,
                "artcc": "zab",
                "sector": "19",
                "sectype": "high",
                "Shape_Length": 8.315699187281604,
                "Shape_Area": 3.6435944436145866
            }
        },
        {
            "type": "Feature",
            "id": 9,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -103.93333333386624,
                            32.46666666603386
                        ],
                        [
                            -104.38333333386623,
                            32.46666666603386
                        ],
                        [
                            -105.16666666713297,
                            32.38055555489501
                        ],
                        [
                            -105.56222222217775,
                            32.38472222185311
                        ],
                        [
                            -106.1000000000999,
                            32.41666666613378
                        ],
                        [
                            -106.11805555571931,
                            32.388333332617265
                        ],
                        [
                            -106.17194444448052,
                            32.299999999400484
                        ],
                        [
                            -106.20833333381626,
                            32.2499999995004
                        ],
                        [
                            -106.23333333376632,
                            32.09999999980022
                        ],
                        [
                            -106.23333333376632,
                            31.749999999600334
                        ],
                        [
                            -106.33333333356643,
                            31.666666666733363
                        ],
                        [
                            -106.45000000029972,
                            31.566666666033882
                        ],
                        [
                            -106.45000000029972,
                            31.533333333066935
                        ],
                        [
                            -105.80000000069941,
                            30.9999999993006
                        ],
                        [
                            -105.0000000004996,
                            30.6333333330669
                        ],
                        [
                            -104.51666666663334,
                            29.43333333276712
                        ],
                        [
                            -103.31666666723288,
                            28.949999999800184
                        ],
                        [
                            -103.30000000029975,
                            28.949999999800184
                        ],
                        [
                            -103.1500000005995,
                            28.949999999800184
                        ],
                        [
                            -102.86666666723289,
                            29.21666666623372
                        ],
                        [
                            -102.84027777769722,
                            29.361111111188904
                        ],
                        [
                            -102.67500000064945,
                            29.725000000049988
                        ],
                        [
                            -102.55833333391621,
                            29.76666666603387
                        ],
                        [
                            -102.14999999990005,
                            31.283333332667212
                        ],
                        [
                            -102.21666666673326,
                            31.42499999935052
                        ],
                        [
                            -103.11666666673324,
                            31.583333332967015
                        ],
                        [
                            -103.3333333332667,
                            31.64999999980023
                        ],
                        [
                            -103.52916666680818,
                            31.80833333341667
                        ],
                        [
                            -103.80000000019982,
                            32.033333332967004
                        ],
                        [
                            -103.93333333386624,
                            32.46666666603386
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 9,
                "artcc": "zab",
                "sector": "20",
                "sectype": "high",
                "Shape_Length": 12.64989607611556,
                "Shape_Area": 8.682978858109804
            }
        },
        {
            "type": "Feature",
            "id": 11,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -103.4083333340161,
                            33.71944444450554
                        ],
                        [
                            -103.36666666713296,
                            33.77500000005
                        ],
                        [
                            -102.80000000039968,
                            34.31666666683327
                        ],
                        [
                            -102.66250000067447,
                            34.38888888841154
                        ],
                        [
                            -102.32500000044962,
                            34.549999999400484
                        ],
                        [
                            -102.81666666733281,
                            34.808333332817085
                        ],
                        [
                            -102.98333333396613,
                            35.13750000002506
                        ],
                        [
                            -102.98333333396613,
                            35.34999999960036
                        ],
                        [
                            -104.00000000069946,
                            35.34166666658342
                        ],
                        [
                            -104.55000000049961,
                            35.08333333316682
                        ],
                        [
                            -104.55000000049961,
                            34.99999999940053
                        ],
                        [
                            -104.55000000049961,
                            34.71666666603386
                        ],
                        [
                            -105.15000000019984,
                            34.4999999995004
                        ],
                        [
                            -105.15000000019984,
                            34.1458333332418
                        ],
                        [
                            -104.50000000059953,
                            33.95083333309191
                        ],
                        [
                            -104.10000000049956,
                            33.82916666600886
                        ],
                        [
                            -103.48749999992503,
                            33.63749999942547
                        ],
                        [
                            -103.4083333340161,
                            33.71944444450554
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 11,
                "artcc": "zab",
                "sector": "21",
                "sectype": "high",
                "Shape_Length": 7.3550550962286625,
                "Shape_Area": 2.87520196721127
            }
        },
        {
            "type": "Feature",
            "id": 13,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -105.8500000005995,
                            34.283333332967004
                        ],
                        [
                            -106.06666666713295,
                            34.283333332967004
                        ],
                        [
                            -106.06666666713295,
                            33.74583333314189
                        ],
                        [
                            -106.06666666713295,
                            33.56666666653348
                        ],
                        [
                            -106.35000000049956,
                            33.06666666663341
                        ],
                        [
                            -106.25000000069946,
                            32.56666666673334
                        ],
                        [
                            -106.1000000000999,
                            32.56666666673334
                        ],
                        [
                            -106.1000000000999,
                            32.41666666613378
                        ],
                        [
                            -105.56222222217775,
                            32.38472222185311
                        ],
                        [
                            -105.16666666713297,
                            32.38055555489501
                        ],
                        [
                            -104.38333333386623,
                            32.46666666603386
                        ],
                        [
                            -103.93333333386624,
                            32.46666666603386
                        ],
                        [
                            -103.84027777839674,
                            32.845833333141854
                        ],
                        [
                            -103.80000000019982,
                            32.999999999800195
                        ],
                        [
                            -103.80000000019982,
                            33.049999999700276
                        ],
                        [
                            -103.80000000019982,
                            33.38333333296697
                        ],
                        [
                            -103.69166666738278,
                            33.40277777727266
                        ],
                        [
                            -103.48749999992503,
                            33.63749999942547
                        ],
                        [
                            -104.10000000049956,
                            33.82916666600886
                        ],
                        [
                            -104.50000000059953,
                            33.95083333309191
                        ],
                        [
                            -105.15000000019984,
                            34.1458333332418
                        ],
                        [
                            -105.45000000049959,
                            33.96666666663339
                        ],
                        [
                            -105.8500000005995,
                            34.283333332967004
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 13,
                "artcc": "zab",
                "sector": "23",
                "sectype": "high",
                "Shape_Length": 8.4426448795245,
                "Shape_Area": 3.8488844528329453
            }
        },
        {
            "type": "Feature",
            "id": 15,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -112.15305555516971,
                            35.396666666833255
                        ],
                        [
                            -112.66666666653339,
                            35.383333333466624
                        ],
                        [
                            -112.92777777822187,
                            35.25555555544457
                        ],
                        [
                            -113.6166666664335,
                            34.9166666665335
                        ],
                        [
                            -113.7000000001998,
                            34.86666666663342
                        ],
                        [
                            -113.99999999960028,
                            34.6666666670331
                        ],
                        [
                            -113.99999999960028,
                            34.61666666713302
                        ],
                        [
                            -113.99999999960028,
                            34.591666666283686
                        ],
                        [
                            -113.99999999960028,
                            34.4972222221279
                        ],
                        [
                            -113.99999999960028,
                            34.3999999997003
                        ],
                        [
                            -114.00083333299187,
                            34.383333333666485
                        ],
                        [
                            -113.99999999960028,
                            34.16666666713303
                        ],
                        [
                            -113.99999999960028,
                            34.01666666653347
                        ],
                        [
                            -113.08527777754739,
                            33.81444444395595
                        ],
                        [
                            -112.70666666663334,
                            33.73083333299195
                        ],
                        [
                            -112.25000000039967,
                            33.629166666408594
                        ],
                        [
                            -111.85583333314179,
                            33.55333333316685
                        ],
                        [
                            -111.75833333351648,
                            33.80000000000007
                        ],
                        [
                            -111.99999999999994,
                            33.883333332867096
                        ],
                        [
                            -112.01666666693308,
                            34.09166666638362
                        ],
                        [
                            -112.01388888866126,
                            34.23333333306692
                        ],
                        [
                            -112.05833333291696,
                            34.34583333374144
                        ],
                        [
                            -112.07805555531957,
                            34.504999999850156
                        ],
                        [
                            -112.0999999998001,
                            34.68333333306691
                        ],
                        [
                            -112.1033333333666,
                            34.720555555794306
                        ],
                        [
                            -112.11555555524467,
                            34.88222222207793
                        ],
                        [
                            -112.1269444446304,
                            35.047500000025025
                        ],
                        [
                            -112.14777777762231,
                            35.327777777922165
                        ],
                        [
                            -112.15305555516971,
                            35.396666666833255
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 15,
                "artcc": "zab",
                "sector": "37",
                "sectype": "high",
                "Shape_Length": 6.921655287577164,
                "Shape_Area": 2.6635831409527375
            }
        },
        {
            "type": "Feature",
            "id": 16,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -111.38888888901096,
                            33.422222221578295
                        ],
                        [
                            -111.21083333389123,
                            33.44333333266724
                        ],
                        [
                            -110.76250000067444,
                            33.491666666683386
                        ],
                        [
                            -110.50000000029974,
                            33.53333333266721
                        ],
                        [
                            -110.22499999995,
                            33.589722221603324
                        ],
                        [
                            -108.7500000001998,
                            33.87499999985016
                        ],
                        [
                            -108.88333333386623,
                            33.999999999600334
                        ],
                        [
                            -109.58333333336662,
                            34.4999999995004
                        ],
                        [
                            -111.16666666683318,
                            33.83888888861139
                        ],
                        [
                            -111.35000000039969,
                            33.80000000000007
                        ],
                        [
                            -111.48333333316674,
                            33.80000000000007
                        ],
                        [
                            -111.75833333351648,
                            33.80000000000007
                        ],
                        [
                            -111.85583333314179,
                            33.55333333316685
                        ],
                        [
                            -111.70611111153852,
                            33.3444444443557
                        ],
                        [
                            -111.38888888901096,
                            33.422222221578295
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 16,
                "artcc": "zab",
                "sector": "38",
                "sectype": "high",
                "Shape_Length": 6.882490887183219,
                "Shape_Area": 1.5498446759584983
            }
        },
        {
            "type": "Feature",
            "id": 18,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -111.48333333316674,
                            33.80000000000007
                        ],
                        [
                            -111.35000000039969,
                            33.80000000000007
                        ],
                        [
                            -111.16666666683318,
                            33.83888888861139
                        ],
                        [
                            -109.58333333336662,
                            34.4999999995004
                        ],
                        [
                            -110.0833333332667,
                            34.700000000000045
                        ],
                        [
                            -110.31444444465535,
                            34.79777777772233
                        ],
                        [
                            -110.90000000039964,
                            34.68333333306691
                        ],
                        [
                            -111.14855555559438,
                            34.41661111101399
                        ],
                        [
                            -111.53194444438054,
                            33.96166666628369
                        ],
                        [
                            -111.75833333351648,
                            33.80000000000007
                        ],
                        [
                            -111.48333333316674,
                            33.80000000000007
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 18,
                "artcc": "zab",
                "sector": "39",
                "sectype": "high",
                "Shape_Length": 4.9353697536510195,
                "Shape_Area": 0.9354288351276373
            }
        },
        {
            "type": "Feature",
            "id": 20,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -111.70611111153852,
                            33.3444444443557
                        ],
                        [
                            -111.85583333314179,
                            33.55333333316685
                        ],
                        [
                            -112.25000000039967,
                            33.629166666408594
                        ],
                        [
                            -112.70666666663334,
                            33.73083333299195
                        ],
                        [
                            -113.08527777844671,
                            33.81444444395595
                        ],
                        [
                            -114.0000000004996,
                            34.01666666653347
                        ],
                        [
                            -114.0000000004996,
                            33.944444444055875
                        ],
                        [
                            -114.0000000004996,
                            33.80000000000007
                        ],
                        [
                            -114.0000000004996,
                            33.69999999930059
                        ],
                        [
                            -114.0000000004996,
                            33.609999999300555
                        ],
                        [
                            -114.0000000004996,
                            33.46666666673332
                        ],
                        [
                            -114.0000000004996,
                            33.3999999999001
                        ],
                        [
                            -114.0000000004996,
                            33.11666666653349
                        ],
                        [
                            -114.0000000004996,
                            33.08333333266722
                        ],
                        [
                            -114.0000000004996,
                            32.683333332567315
                        ],
                        [
                            -113.68472222285226,
                            32.737499999425495
                        ],
                        [
                            -113.51276944465411,
                            32.11622777778979
                        ],
                        [
                            -113.5083333336164,
                            32.09999999980022
                        ],
                        [
                            -111.99999999999994,
                            31.633333332867096
                        ],
                        [
                            -111.99833333321669,
                            32.47333333316686
                        ],
                        [
                            -112.21694444463037,
                            32.598055555719384
                        ],
                        [
                            -112.30083333369134,
                            32.64166666658343
                        ],
                        [
                            -112.4277777783218,
                            32.70611111043945
                        ],
                        [
                            -112.47111111108887,
                            32.727222221528336
                        ],
                        [
                            -112.43972222210283,
                            32.80611111113893
                        ],
                        [
                            -112.34750000002492,
                            33.02249999957536
                        ],
                        [
                            -112.1883333339162,
                            33.01249999977523
                        ],
                        [
                            -112.05388888876115,
                            33.0033333333667
                        ],
                        [
                            -111.99999999999994,
                            33.133333332567304
                        ],
                        [
                            -112.00972222260248,
                            33.261111110589354
                        ],
                        [
                            -111.99999999999994,
                            33.2583333332168
                        ],
                        [
                            -111.70611111153852,
                            33.3444444443557
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 20,
                "artcc": "zab",
                "sector": "42",
                "sectype": "high",
                "Shape_Length": 8.920743520565924,
                "Shape_Area": 3.377076754375555
            }
        },
        {
            "type": "Feature",
            "id": 23,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -109.31666666693309,
                            35.849999999500426
                        ],
                        [
                            -110.23333333386626,
                            35.699999999800184
                        ],
                        [
                            -111.84166666728282,
                            35.7666666666334
                        ],
                        [
                            -111.99999999999994,
                            35.39999999950044
                        ],
                        [
                            -112.15305555606903,
                            35.396666666833255
                        ],
                        [
                            -112.14777777852163,
                            35.327777777922165
                        ],
                        [
                            -112.1269444446304,
                            35.047500000025025
                        ],
                        [
                            -112.11555555614399,
                            34.88222222207793
                        ],
                        [
                            -112.1033333333666,
                            34.720555554894986
                        ],
                        [
                            -112.10000000069942,
                            34.68333333306691
                        ],
                        [
                            -112.07805555621889,
                            34.504999999850156
                        ],
                        [
                            -112.05833333381628,
                            34.34583333284206
                        ],
                        [
                            -112.01388888956058,
                            34.23333333306692
                        ],
                        [
                            -112.01666666693308,
                            34.09166666638362
                        ],
                        [
                            -111.99999999999994,
                            33.883333332867096
                        ],
                        [
                            -111.75833333351648,
                            33.80000000000007
                        ],
                        [
                            -111.48333333316674,
                            33.80000000000007
                        ],
                        [
                            -111.53194444438054,
                            33.96166666628369
                        ],
                        [
                            -111.14855555559438,
                            34.41661111101399
                        ],
                        [
                            -110.90000000039964,
                            34.68333333306691
                        ],
                        [
                            -110.31444444465535,
                            34.79777777772233
                        ],
                        [
                            -109.6416666671829,
                            35.68333333286705
                        ],
                        [
                            -109.31666666693309,
                            35.849999999500426
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 23,
                "artcc": "zab",
                "sector": "45",
                "sectype": "high",
                "Shape_Length": 8.351071139767578,
                "Shape_Area": 2.8267208954949385
            }
        },
        {
            "type": "Feature",
            "id": 25,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -110.50000000029974,
                            33.53333333266721
                        ],
                        [
                            -110.76250000067444,
                            33.491666666683386
                        ],
                        [
                            -111.21083333389123,
                            33.44333333266724
                        ],
                        [
                            -111.38888888901096,
                            33.422222221578295
                        ],
                        [
                            -111.70611111153852,
                            33.3444444443557
                        ],
                        [
                            -111.99999999999994,
                            33.2583333332168
                        ],
                        [
                            -112.00972222260248,
                            33.261111110589354
                        ],
                        [
                            -111.99999999999994,
                            33.133333332567304
                        ],
                        [
                            -112.05388888876115,
                            33.0033333333667
                        ],
                        [
                            -112.1883333339162,
                            33.01249999977523
                        ],
                        [
                            -112.34750000002492,
                            33.02249999957536
                        ],
                        [
                            -112.43972222210283,
                            32.80611111113893
                        ],
                        [
                            -112.47111111108887,
                            32.727222221528336
                        ],
                        [
                            -112.4277777783218,
                            32.70611111043945
                        ],
                        [
                            -112.30083333369134,
                            32.64166666658343
                        ],
                        [
                            -112.21694444463037,
                            32.598055555719384
                        ],
                        [
                            -111.99833333321669,
                            32.47333333316686
                        ],
                        [
                            -111.99999999999994,
                            31.633333332867096
                        ],
                        [
                            -111.08333333396615,
                            31.333333332567292
                        ],
                        [
                            -110.75000000069946,
                            31.333333332567292
                        ],
                        [
                            -110.75000000069946,
                            31.349999999500426
                        ],
                        [
                            -110.76666666673327,
                            31.799999999500415
                        ],
                        [
                            -110.76666666673327,
                            32.183333332667246
                        ],
                        [
                            -110.90000000039964,
                            32.299999999400484
                        ],
                        [
                            -110.77222222237765,
                            32.474999999950114
                        ],
                        [
                            -110.50000000029974,
                            32.85000000009995
                        ],
                        [
                            -110.50000000029974,
                            33.53333333266721
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 25,
                "artcc": "zab",
                "sector": "46",
                "sectype": "high",
                "Shape_Length": 7.487762522224697,
                "Shape_Area": 2.8008759643512002
            }
        },
        {
            "type": "Feature",
            "id": 29,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -110.50000000029974,
                            33.53333333266721
                        ],
                        [
                            -110.22499999995,
                            33.589722221603324
                        ],
                        [
                            -108.7500000001998,
                            33.87499999985016
                        ],
                        [
                            -108.88333333386623,
                            33.999999999600334
                        ],
                        [
                            -109.58333333336662,
                            34.4999999995004
                        ],
                        [
                            -110.0833333332667,
                            34.700000000000045
                        ],
                        [
                            -110.31444444465535,
                            34.79777777772233
                        ],
                        [
                            -110.90000000039964,
                            34.68333333306691
                        ],
                        [
                            -111.14855555559438,
                            34.41661111101399
                        ],
                        [
                            -111.53194444438054,
                            33.96166666628369
                        ],
                        [
                            -111.75833333351648,
                            33.80000000000007
                        ],
                        [
                            -111.85583333314179,
                            33.55333333316685
                        ],
                        [
                            -110.76250000067444,
                            33.491666666683386
                        ],
                        [
                            -110.50000000029974,
                            33.53333333266721
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 29,
                "artcc": "zab",
                "sector": "50",
                "sectype": "high",
                "Shape_Length": 7.0759587078384785,
                "Shape_Area": 2.3894716589672247
            }
        },
        {
            "type": "Feature",
            "id": 31,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -104.38333333296691,
                            32.46666666693318
                        ],
                        [
                            -105.16666666713297,
                            32.38055555579433
                        ],
                        [
                            -105.56222222217775,
                            32.38472222185311
                        ],
                        [
                            -106.1000000000999,
                            32.4166666670331
                        ],
                        [
                            -106.11805555571931,
                            32.388333333516584
                        ],
                        [
                            -106.17194444448052,
                            32.300000000299804
                        ],
                        [
                            -106.20833333291694,
                            32.25000000039972
                        ],
                        [
                            -106.233333332867,
                            32.09999999980022
                        ],
                        [
                            -106.233333332867,
                            31.749999999600334
                        ],
                        [
                            -106.33333333356643,
                            31.666666666733363
                        ],
                        [
                            -106.45000000029972,
                            31.5666666669332
                        ],
                        [
                            -106.45000000029972,
                            31.533333333066935
                        ],
                        [
                            -105.7999999998001,
                            31.00000000019992
                        ],
                        [
                            -104.99999999960028,
                            30.6333333330669
                        ],
                        [
                            -104.51666666663334,
                            29.43333333366644
                        ],
                        [
                            -103.31666666633356,
                            28.949999999800184
                        ],
                        [
                            -103.30000000029975,
                            28.949999999800184
                        ],
                        [
                            -103.14999999970019,
                            28.949999999800184
                        ],
                        [
                            -102.86666666633357,
                            29.21666666713304
                        ],
                        [
                            -102.84027777769722,
                            29.361111111188904
                        ],
                        [
                            -102.67499999975013,
                            29.725000000049988
                        ],
                        [
                            -102.55833333301689,
                            29.76666666693319
                        ],
                        [
                            -102.14999999990005,
                            31.28333333356653
                        ],
                        [
                            -102.21666666673326,
                            31.425000000249838
                        ],
                        [
                            -103.11666666673324,
                            31.583333332967015
                        ],
                        [
                            -103.3333333332667,
                            31.64999999980023
                        ],
                        [
                            -103.52916666680818,
                            31.80833333341667
                        ],
                        [
                            -103.80000000019982,
                            32.033333332967004
                        ],
                        [
                            -103.93333333296692,
                            32.46666666693318
                        ],
                        [
                            -104.38333333296691,
                            32.46666666693318
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 31,
                "artcc": "zab",
                "sector": "63",
                "sectype": "high",
                "Shape_Length": 12.64989607936555,
                "Shape_Area": 8.682978857864365
            }
        },
        {
            "type": "Feature",
            "id": 32,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -113.99999999960028,
                            34.01666666653347
                        ],
                        [
                            -113.99999999960028,
                            33.944444444055875
                        ],
                        [
                            -113.99999999960028,
                            33.80000000000007
                        ],
                        [
                            -113.99999999960028,
                            33.70000000019991
                        ],
                        [
                            -113.99999999960028,
                            33.610000000199875
                        ],
                        [
                            -113.99999999960028,
                            33.46666666673332
                        ],
                        [
                            -113.99999999960028,
                            33.3999999999001
                        ],
                        [
                            -113.99999999960028,
                            33.11666666653349
                        ],
                        [
                            -113.99999999960028,
                            33.08333333356654
                        ],
                        [
                            -113.99999999960028,
                            32.683333333466635
                        ],
                        [
                            -113.68472222195294,
                            32.737500000324815
                        ],
                        [
                            -113.51276944465411,
                            32.11622777778979
                        ],
                        [
                            -113.5083333336164,
                            32.09999999980022
                        ],
                        [
                            -111.99999999999994,
                            31.633333332867096
                        ],
                        [
                            -111.08333333306683,
                            31.333333333466612
                        ],
                        [
                            -110.74999999980014,
                            31.333333333466612
                        ],
                        [
                            -110.74999999980014,
                            31.350000000399746
                        ],
                        [
                            -110.76666666673327,
                            31.800000000399734
                        ],
                        [
                            -110.76666666673327,
                            32.183333333566566
                        ],
                        [
                            -110.90000000039964,
                            32.300000000299804
                        ],
                        [
                            -110.77222222237765,
                            32.474999999950114
                        ],
                        [
                            -110.50000000029974,
                            32.85000000009995
                        ],
                        [
                            -110.50000000029974,
                            33.53333333356653
                        ],
                        [
                            -110.76249999977512,
                            33.491666666683386
                        ],
                        [
                            -111.85583333314179,
                            33.55333333316685
                        ],
                        [
                            -112.25000000039967,
                            33.629166666408594
                        ],
                        [
                            -112.70666666663334,
                            33.73083333299195
                        ],
                        [
                            -113.08527777754739,
                            33.81444444395595
                        ],
                        [
                            -113.99999999960028,
                            34.01666666653347
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 32,
                "artcc": "zab",
                "sector": "65",
                "sectype": "high",
                "Shape_Length": 11.136926939672698,
                "Shape_Area": 6.2737545688494745
            }
        },
        {
            "type": "Feature",
            "id": 33,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -112.15305555516971,
                            35.396666666833255
                        ],
                        [
                            -112.14777777762231,
                            35.327777777922165
                        ],
                        [
                            -112.1269444446304,
                            35.047500000025025
                        ],
                        [
                            -112.11555555524467,
                            34.88222222207793
                        ],
                        [
                            -112.1033333333666,
                            34.720555555794306
                        ],
                        [
                            -112.0999999998001,
                            34.68333333306691
                        ],
                        [
                            -111.42722222232766,
                            34.45583333334173
                        ],
                        [
                            -111.31666666653342,
                            34.41666666663343
                        ],
                        [
                            -111.14855555559438,
                            34.41661111101399
                        ],
                        [
                            -110.90000000039964,
                            34.68333333306691
                        ],
                        [
                            -110.31444444465535,
                            34.79777777772233
                        ],
                        [
                            -109.64166666628358,
                            35.68333333286705
                        ],
                        [
                            -109.31666666693309,
                            35.850000000399746
                        ],
                        [
                            -110.23333333296694,
                            35.699999999800184
                        ],
                        [
                            -111.8416666663835,
                            35.7666666666334
                        ],
                        [
                            -111.99999999999994,
                            35.40000000039976
                        ],
                        [
                            -112.15305555516971,
                            35.396666666833255
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 33,
                "artcc": "zab",
                "sector": "67",
                "sectype": "high",
                "Shape_Length": 7.2405614234148254,
                "Shape_Area": 2.343928549674289
            }
        },
        {
            "type": "Feature",
            "id": 34,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -109.58333333336662,
                            34.50000000039972
                        ],
                        [
                            -108.88333333296691,
                            33.999999999600334
                        ],
                        [
                            -108.7500000001998,
                            33.87499999985016
                        ],
                        [
                            -107.99999999990001,
                            33.833333332967015
                        ],
                        [
                            -107.5999999998001,
                            33.58333333346661
                        ],
                        [
                            -107.466666667033,
                            33.58333333346661
                        ],
                        [
                            -106.80277777787211,
                            33.58333333346661
                        ],
                        [
                            -106.75555555534459,
                            33.82916666690818
                        ],
                        [
                            -106.67499999985006,
                            34.26249999997509
                        ],
                        [
                            -106.64999999990005,
                            34.483333333466646
                        ],
                        [
                            -106.6166666669331,
                            34.84999999970029
                        ],
                        [
                            -106.28499999955028,
                            35.15944444450554
                        ],
                        [
                            -106.69999999980013,
                            35.31666666663341
                        ],
                        [
                            -106.91666666633353,
                            35.31666666663341
                        ],
                        [
                            -107.25166666638353,
                            35.23055555549456
                        ],
                        [
                            -107.63333333366637,
                            35.1333333330669
                        ],
                        [
                            -108.36666666703303,
                            34.89999999960037
                        ],
                        [
                            -109.58333333336662,
                            34.50000000039972
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 34,
                "artcc": "zab",
                "sector": "68",
                "sectype": "high",
                "Shape_Length": 8.248676863584729,
                "Shape_Area": 3.3110138889363103
            }
        },
        {
            "type": "Feature",
            "id": 35,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -107.466666667033,
                            36.19999999970025
                        ],
                        [
                            -106.991666667083,
                            35.42500000034977
                        ],
                        [
                            -106.91666666633353,
                            35.31666666663341
                        ],
                        [
                            -106.69999999980013,
                            35.31666666663341
                        ],
                        [
                            -106.28499999955028,
                            35.15944444450554
                        ],
                        [
                            -106.11916666630856,
                            35.163611111463695
                        ],
                        [
                            -105.47500000044965,
                            35.20833333291705
                        ],
                        [
                            -105.38333333366637,
                            36.249999999600334
                        ],
                        [
                            -105.35638888883614,
                            36.53583333314185
                        ],
                        [
                            -105.34166666678323,
                            36.71666666653351
                        ],
                        [
                            -106.08333333316676,
                            36.71666666653351
                        ],
                        [
                            -106.34999999960024,
                            36.62694444463045
                        ],
                        [
                            -107.466666667033,
                            36.19999999970025
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 35,
                "artcc": "zab",
                "sector": "70",
                "sectype": "high",
                "Shape_Length": 6.2455470557480055,
                "Shape_Area": 2.441334953330475
            }
        },
        {
            "type": "Feature",
            "id": 36,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -101.47222222207785,
                            36.397222221928075
                        ],
                        [
                            -101.75000000069946,
                            36.50000000000006
                        ],
                        [
                            -102.18333333376631,
                            37.04166666678327
                        ],
                        [
                            -102.54999999999995,
                            37.499999999800195
                        ],
                        [
                            -103.61944444490518,
                            37.162499999575346
                        ],
                        [
                            -104.00000000069946,
                            37.045277777547426
                        ],
                        [
                            -105.0000000004996,
                            36.71666666653351
                        ],
                        [
                            -105.34166666678323,
                            36.71666666653351
                        ],
                        [
                            -105.35638888883614,
                            36.53583333314185
                        ],
                        [
                            -105.38333333366637,
                            36.249999999600334
                        ],
                        [
                            -104.41666666683318,
                            36.249999999600334
                        ],
                        [
                            -103.83333333316676,
                            35.983333333166854
                        ],
                        [
                            -102.98333333396613,
                            35.849999999500426
                        ],
                        [
                            -101.47222222207785,
                            36.397222221928075
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 36,
                "artcc": "zab",
                "sector": "71",
                "sectype": "high",
                "Shape_Length": 9.034850169516904,
                "Shape_Area": 3.4883641980897147
            }
        },
        {
            "type": "Feature",
            "id": 40,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -107.75833333341654,
                            32.949999999900115
                        ],
                        [
                            -107.466666667033,
                            33.58333333346661
                        ],
                        [
                            -107.5999999998001,
                            33.58333333346661
                        ],
                        [
                            -107.99999999990001,
                            33.833333332967015
                        ],
                        [
                            -108.7500000001998,
                            33.87499999985016
                        ],
                        [
                            -110.22499999995,
                            33.589722222502644
                        ],
                        [
                            -110.50000000029974,
                            33.53333333356653
                        ],
                        [
                            -110.50000000029974,
                            32.85000000009995
                        ],
                        [
                            -110.77222222237765,
                            32.474999999950114
                        ],
                        [
                            -110.90000000039964,
                            32.300000000299804
                        ],
                        [
                            -110.76666666673327,
                            32.183333333566566
                        ],
                        [
                            -110.76666666673327,
                            31.800000000399734
                        ],
                        [
                            -110.74999999980014,
                            31.350000000399746
                        ],
                        [
                            -110.74999999980014,
                            31.333333333466612
                        ],
                        [
                            -110.00083333289194,
                            31.33750000042471
                        ],
                        [
                            -108.49999999980014,
                            31.333333333466612
                        ],
                        [
                            -108.20000000039965,
                            31.333333333466612
                        ],
                        [
                            -108.20000000039965,
                            31.533333333066935
                        ],
                        [
                            -108.20000000039965,
                            32.4166666670331
                        ],
                        [
                            -107.99999999990001,
                            32.4166666670331
                        ],
                        [
                            -107.75833333341654,
                            32.949999999900115
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 40,
                "artcc": "zab",
                "sector": "80",
                "sectype": "high",
                "Shape_Length": 10.64627880772868,
                "Shape_Area": 6.551840276775929
            }
        },
        {
            "type": "Feature",
            "id": 43,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -107.75833333341654,
                            32.949999999900115
                        ],
                        [
                            -107.466666667033,
                            33.58333333346661
                        ],
                        [
                            -107.5999999998001,
                            33.58333333346661
                        ],
                        [
                            -107.99999999990001,
                            33.833333332967015
                        ],
                        [
                            -108.7500000001998,
                            33.87499999985016
                        ],
                        [
                            -110.22499999995,
                            33.589722222502644
                        ],
                        [
                            -110.50000000029974,
                            33.53333333356653
                        ],
                        [
                            -110.50000000029974,
                            32.85000000009995
                        ],
                        [
                            -110.77222222237765,
                            32.474999999950114
                        ],
                        [
                            -110.90000000039964,
                            32.300000000299804
                        ],
                        [
                            -110.76666666673327,
                            32.183333333566566
                        ],
                        [
                            -110.76666666673327,
                            31.800000000399734
                        ],
                        [
                            -110.74999999980014,
                            31.350000000399746
                        ],
                        [
                            -110.74999999980014,
                            31.333333333466612
                        ],
                        [
                            -108.49999999980014,
                            31.333333333466612
                        ],
                        [
                            -108.20000000039965,
                            31.333333333466612
                        ],
                        [
                            -108.20000000039965,
                            31.533333333066935
                        ],
                        [
                            -108.20000000039965,
                            32.4166666670331
                        ],
                        [
                            -107.99999999990001,
                            32.4166666670331
                        ],
                        [
                            -107.75833333341654,
                            32.949999999900115
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 43,
                "artcc": "zab",
                "sector": "90",
                "sectype": "high",
                "Shape_Length": 10.646261437054736,
                "Shape_Area": 6.55652777710379
            }
        },
        {
            "type": "Feature",
            "id": 44,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -111.85583333314179,
                            33.55333333316685
                        ],
                        [
                            -112.25000000039967,
                            33.629166666408594
                        ],
                        [
                            -112.70666666663334,
                            33.73083333299195
                        ],
                        [
                            -113.08527777844671,
                            33.81444444395595
                        ],
                        [
                            -114.0000000004996,
                            34.01666666653347
                        ],
                        [
                            -114.0000000004996,
                            33.944444444055875
                        ],
                        [
                            -114.0000000004996,
                            33.80000000000007
                        ],
                        [
                            -114.0000000004996,
                            33.69999999930059
                        ],
                        [
                            -114.0000000004996,
                            33.609999999300555
                        ],
                        [
                            -114.0000000004996,
                            33.46666666673332
                        ],
                        [
                            -114.0000000004996,
                            33.3999999999001
                        ],
                        [
                            -114.0000000004996,
                            33.11666666653349
                        ],
                        [
                            -114.0000000004996,
                            33.08333333266722
                        ],
                        [
                            -114.0000000004996,
                            32.683333332567315
                        ],
                        [
                            -113.68472222285226,
                            32.737499999425495
                        ],
                        [
                            -113.51276944465411,
                            32.11622777778979
                        ],
                        [
                            -113.5083333336164,
                            32.09999999980022
                        ],
                        [
                            -111.99999999999994,
                            31.633333332867096
                        ],
                        [
                            -111.08333333396615,
                            31.333333332567292
                        ],
                        [
                            -110.75000000069946,
                            31.333333332567292
                        ],
                        [
                            -110.75000000069946,
                            31.349999999500426
                        ],
                        [
                            -110.76666666673327,
                            31.799999999500415
                        ],
                        [
                            -110.76666666673327,
                            32.183333332667246
                        ],
                        [
                            -110.90000000039964,
                            32.299999999400484
                        ],
                        [
                            -110.77222222237765,
                            32.474999999950114
                        ],
                        [
                            -110.50000000029974,
                            32.85000000009995
                        ],
                        [
                            -110.50000000029974,
                            33.53333333266721
                        ],
                        [
                            -110.76250000067444,
                            33.491666666683386
                        ],
                        [
                            -111.85583333314179,
                            33.55333333316685
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 44,
                "artcc": "zab",
                "sector": "91",
                "sectype": "high",
                "Shape_Length": 11.13692693989138,
                "Shape_Area": 6.273754570845666
            }
        },
        {
            "type": "Feature",
            "id": 45,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -112.0999999998001,
                            34.68333333306691
                        ],
                        [
                            -112.1033333333666,
                            34.720555555794306
                        ],
                        [
                            -112.11555555524467,
                            34.88222222207793
                        ],
                        [
                            -112.1269444446304,
                            35.047500000025025
                        ],
                        [
                            -112.14777777762231,
                            35.327777777922165
                        ],
                        [
                            -112.15305555516971,
                            35.396666666833255
                        ],
                        [
                            -112.66666666653339,
                            35.383333333466624
                        ],
                        [
                            -112.92777777822187,
                            35.25555555544457
                        ],
                        [
                            -113.6166666664335,
                            34.9166666665335
                        ],
                        [
                            -113.7000000001998,
                            34.86666666663342
                        ],
                        [
                            -113.99999999960028,
                            34.6666666670331
                        ],
                        [
                            -113.99999999960028,
                            34.61666666713302
                        ],
                        [
                            -113.99999999960028,
                            34.591666666283686
                        ],
                        [
                            -113.99999999960028,
                            34.4972222221279
                        ],
                        [
                            -113.99999999960028,
                            34.3999999997003
                        ],
                        [
                            -114.00083333299187,
                            34.383333333666485
                        ],
                        [
                            -113.99999999960028,
                            34.16666666713303
                        ],
                        [
                            -113.99999999960028,
                            34.01666666653347
                        ],
                        [
                            -113.08527777754739,
                            33.81444444395595
                        ],
                        [
                            -112.70666666663334,
                            33.73083333299195
                        ],
                        [
                            -112.25000000039967,
                            33.629166666408594
                        ],
                        [
                            -111.85583333314179,
                            33.55333333316685
                        ],
                        [
                            -111.75833333351648,
                            33.80000000000007
                        ],
                        [
                            -111.53194444438054,
                            33.96166666628369
                        ],
                        [
                            -111.14855555559438,
                            34.41661111101399
                        ],
                        [
                            -111.31666666653342,
                            34.41666666663343
                        ],
                        [
                            -111.42722222232766,
                            34.45583333334173
                        ],
                        [
                            -112.0999999998001,
                            34.68333333306691
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 45,
                "artcc": "zab",
                "sector": "92",
                "sectype": "high",
                "Shape_Length": 7.723042801169342,
                "Shape_Area": 3.124146319980481
            }
        },
        {
            "type": "Feature",
            "id": 46,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -109.58333333336662,
                            34.50000000039972
                        ],
                        [
                            -108.36666666703303,
                            34.89999999960037
                        ],
                        [
                            -107.63333333366637,
                            35.1333333330669
                        ],
                        [
                            -107.25166666638353,
                            35.23055555549456
                        ],
                        [
                            -106.91666666633353,
                            35.31666666663341
                        ],
                        [
                            -106.991666667083,
                            35.42500000034977
                        ],
                        [
                            -107.466666667033,
                            36.19999999970025
                        ],
                        [
                            -108.21666666643347,
                            36.033333333066935
                        ],
                        [
                            -109.31666666693309,
                            35.850000000399746
                        ],
                        [
                            -109.64166666628358,
                            35.68333333286705
                        ],
                        [
                            -110.31444444465535,
                            34.79777777772233
                        ],
                        [
                            -110.0833333332667,
                            34.700000000000045
                        ],
                        [
                            -109.58333333336662,
                            34.50000000039972
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 46,
                "artcc": "zab",
                "sector": "93",
                "sectype": "high",
                "Shape_Length": 7.981087486310978,
                "Shape_Area": 3.1478009253399897
            }
        },
        {
            "type": "Feature",
            "id": 47,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -105.47500000044965,
                            35.20833333291705
                        ],
                        [
                            -106.11916666720788,
                            35.163611110564375
                        ],
                        [
                            -106.2850000004496,
                            35.15944444450554
                        ],
                        [
                            -106.6166666669331,
                            34.84999999970029
                        ],
                        [
                            -106.64999999990005,
                            34.48333333256727
                        ],
                        [
                            -106.67499999985006,
                            34.26249999997509
                        ],
                        [
                            -106.7555555562439,
                            33.82916666600886
                        ],
                        [
                            -106.27500000064947,
                            33.82499999995008
                        ],
                        [
                            -106.06666666713295,
                            33.74583333314189
                        ],
                        [
                            -106.06666666713295,
                            34.283333332967004
                        ],
                        [
                            -105.8500000005995,
                            34.283333332967004
                        ],
                        [
                            -105.45000000049959,
                            33.96666666663339
                        ],
                        [
                            -105.15000000019984,
                            34.1458333332418
                        ],
                        [
                            -105.15000000019984,
                            34.4999999995004
                        ],
                        [
                            -104.55000000049961,
                            34.71666666603386
                        ],
                        [
                            -104.55000000049961,
                            34.99999999940053
                        ],
                        [
                            -105.06666666733281,
                            34.99999999940053
                        ],
                        [
                            -105.47500000044965,
                            35.20833333291705
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 47,
                "artcc": "zab",
                "sector": "94",
                "sectype": "high",
                "Shape_Length": 6.8640984406152885,
                "Shape_Area": 1.9271256939530514
            }
        },
        {
            "type": "Feature",
            "id": 49,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -105.38333333366637,
                            36.249999999600334
                        ],
                        [
                            -105.47500000044965,
                            35.20833333291705
                        ],
                        [
                            -105.06666666643349,
                            35.00000000029985
                        ],
                        [
                            -104.54999999960029,
                            35.00000000029985
                        ],
                        [
                            -103.91666666693311,
                            35.026944444230764
                        ],
                        [
                            -103.49999999990001,
                            35.041666666283675
                        ],
                        [
                            -102.98333333306681,
                            35.13750000002506
                        ],
                        [
                            -102.98333333306681,
                            35.34999999960036
                        ],
                        [
                            -102.98333333306681,
                            35.850000000399746
                        ],
                        [
                            -103.83333333316676,
                            35.983333333166854
                        ],
                        [
                            -104.41666666683318,
                            36.249999999600334
                        ],
                        [
                            -105.38333333366637,
                            36.249999999600334
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 49,
                "artcc": "zab",
                "sector": "95",
                "sectype": "high",
                "Shape_Length": 6.778036845386794,
                "Shape_Area": 2.5940833335690536
            }
        },
        {
            "type": "Feature",
            "id": 51,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -101.47222222207785,
                            36.397222221928075
                        ],
                        [
                            -102.98333333306681,
                            35.850000000399746
                        ],
                        [
                            -102.98333333306681,
                            35.34999999960036
                        ],
                        [
                            -102.98333333306681,
                            35.13750000002506
                        ],
                        [
                            -103.49999999990001,
                            35.041666666283675
                        ],
                        [
                            -102.3249999995503,
                            34.550000000299804
                        ],
                        [
                            -102.0000000001998,
                            34.600000000199884
                        ],
                        [
                            -101.00000000039967,
                            34.491666666483525
                        ],
                        [
                            -100.74999999999994,
                            34.46666666653351
                        ],
                        [
                            -100.31666666693309,
                            34.86666666663342
                        ],
                        [
                            -100.14166666638351,
                            35.12916666700812
                        ],
                        [
                            -100.06111111088904,
                            35.249999999800195
                        ],
                        [
                            -99.99999999970021,
                            35.33333333356654
                        ],
                        [
                            -99.99999999970021,
                            35.82916666650851
                        ],
                        [
                            -101.47222222207785,
                            36.397222221928075
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 51,
                "artcc": "zab",
                "sector": "97",
                "sectype": "high",
                "Shape_Length": 8.93238522170469,
                "Shape_Area": 4.457164351372116
            }
        },
        {
            "type": "Feature",
            "id": 64,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -84.99999999999994,
                            42.523611110964055
                        ],
                        [
                            -84.9999916668819,
                            42.599116666501004
                        ],
                        [
                            -84.9999833337638,
                            42.68865833331546
                        ],
                        [
                            -84.99999999999994,
                            42.743333332966984
                        ],
                        [
                            -84.99999999999994,
                            42.96666666663339
                        ],
                        [
                            -84.99999999999994,
                            43.03611111083916
                        ],
                        [
                            -84.99999999999994,
                            43.177777777522465
                        ],
                        [
                            -84.99999999999994,
                            43.4999999995004
                        ],
                        [
                            -84.96361111156352,
                            43.58666666683325
                        ],
                        [
                            -85.74166666728286,
                            43.628055555619426
                        ],
                        [
                            -86.21666666723286,
                            43.65111111068927
                        ],
                        [
                            -87.0000000004996,
                            43.68499999985016
                        ],
                        [
                            -87.0000000004996,
                            43.56666666633362
                        ],
                        [
                            -87.0000000004996,
                            43.31666666683327
                        ],
                        [
                            -87.0000000004996,
                            43.049999999500415
                        ],
                        [
                            -87.0000000004996,
                            42.80000000000007
                        ],
                        [
                            -87.0000000004996,
                            42.50555555534464
                        ],
                        [
                            -87.0000000004996,
                            42.35694444433068
                        ],
                        [
                            -86.31666666703302,
                            42.38333333296697
                        ],
                        [
                            -86.2002777783967,
                            42.387222221828154
                        ],
                        [
                            -86.01388888936071,
                            42.38888888861135
                        ],
                        [
                            -84.99999999999994,
                            42.373888888461465
                        ],
                        [
                            -84.99999999999994,
                            42.523611110964055
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 64,
                "artcc": "zau",
                "sector": "23",
                "sectype": "high",
                "Shape_Length": 6.587636333698083,
                "Shape_Area": 2.5231180555714525
            }
        },
        {
            "type": "Feature",
            "id": 65,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -84.99999999999994,
                            43.03611111083916
                        ],
                        [
                            -84.99999999999994,
                            43.177777777522465
                        ],
                        [
                            -84.99999999999994,
                            43.4999999995004
                        ],
                        [
                            -84.96361111156352,
                            43.58666666683325
                        ],
                        [
                            -85.74166666728286,
                            43.628055555619426
                        ],
                        [
                            -86.21666666723286,
                            43.65111111068927
                        ],
                        [
                            -87.0000000004996,
                            43.68499999985016
                        ],
                        [
                            -87.0000000004996,
                            43.56666666633362
                        ],
                        [
                            -87.0000000004996,
                            43.31666666683327
                        ],
                        [
                            -87.0000000004996,
                            43.049999999500415
                        ],
                        [
                            -86.21666666723286,
                            43.02777777782222
                        ],
                        [
                            -84.99999999999994,
                            43.03611111083916
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 65,
                "artcc": "zau",
                "sector": "24",
                "sectype": "high",
                "Shape_Length": 5.232009529312955,
                "Shape_Area": 1.2105729556037137
            }
        },
        {
            "type": "Feature",
            "id": 66,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -87.0000000004996,
                            43.049999999500415
                        ],
                        [
                            -87.0000000004996,
                            42.80000000000007
                        ],
                        [
                            -87.0000000004996,
                            42.50555555534464
                        ],
                        [
                            -87.0000000004996,
                            42.35694444433068
                        ],
                        [
                            -86.31666666703302,
                            42.38333333296697
                        ],
                        [
                            -86.2002777783967,
                            42.387222221828154
                        ],
                        [
                            -86.01388888936071,
                            42.38888888861135
                        ],
                        [
                            -84.99999999999994,
                            42.373888888461465
                        ],
                        [
                            -84.99999999999994,
                            42.523611110964055
                        ],
                        [
                            -84.9999916668819,
                            42.599116666501004
                        ],
                        [
                            -84.9999833337638,
                            42.68865833331546
                        ],
                        [
                            -84.99999999999994,
                            42.743333332966984
                        ],
                        [
                            -84.99999999999994,
                            42.96666666663339
                        ],
                        [
                            -84.99999999999994,
                            43.03611111083916
                        ],
                        [
                            -86.21666666723286,
                            43.02777777782222
                        ],
                        [
                            -87.0000000004996,
                            43.049999999500415
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 66,
                "artcc": "zau",
                "sector": "25",
                "sectype": "high",
                "Shape_Length": 5.3563141731699275,
                "Shape_Area": 1.3125450999677388
            }
        },
        {
            "type": "Feature",
            "id": 71,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -86.31666666703302,
                            39.9999999993006
                        ],
                        [
                            -86.10000000049956,
                            39.9999999993006
                        ],
                        [
                            -85.93333333386624,
                            40.10000000000008
                        ],
                        [
                            -85.52499999985008,
                            40.354166666458525
                        ],
                        [
                            -85.31666666723288,
                            40.43333333326677
                        ],
                        [
                            -85.23333333346653,
                            40.46666666623372
                        ],
                        [
                            -85.43750000002495,
                            40.637499999825195
                        ],
                        [
                            -85.7333333333666,
                            40.88333333326676
                        ],
                        [
                            -85.83333333316676,
                            41.12222222147841
                        ],
                        [
                            -85.94555555574425,
                            41.13694444443064
                        ],
                        [
                            -86.05000000059954,
                            41.1499999997003
                        ],
                        [
                            -86.2500000001998,
                            41.59999999970029
                        ],
                        [
                            -87.0000000004996,
                            41.608333332717166
                        ],
                        [
                            -87.0000000004996,
                            41.3741666667583
                        ],
                        [
                            -87.0000000004996,
                            41.06666666683327
                        ],
                        [
                            -86.9583333336164,
                            41.01666666603387
                        ],
                        [
                            -86.9286111114136,
                            40.98638888853645
                        ],
                        [
                            -86.72166666658336,
                            40.75222222167827
                        ],
                        [
                            -86.61999999999995,
                            40.633333332867096
                        ],
                        [
                            -86.41666666683318,
                            39.9999999993006
                        ],
                        [
                            -86.31666666703302,
                            39.9999999993006
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 71,
                "artcc": "zau",
                "sector": "34",
                "sectype": "high",
                "Shape_Length": 5.458678050339845,
                "Shape_Area": 1.553790123566089
            }
        },
        {
            "type": "Feature",
            "id": 72,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -85.93388888916087,
                            41.351666666083815
                        ],
                        [
                            -85.9386194445317,
                            41.373255555499554
                        ],
                        [
                            -85.9883333332167,
                            41.599722221603315
                        ],
                        [
                            -86.00000000069946,
                            41.683333332567315
                        ],
                        [
                            -86.45000000069945,
                            41.69583333344161
                        ],
                        [
                            -86.65000000029971,
                            41.69999999950045
                        ],
                        [
                            -86.70805555601908,
                            41.69999999950045
                        ],
                        [
                            -86.90833333371631,
                            41.69999999950045
                        ],
                        [
                            -87.0000000004996,
                            41.69999999950045
                        ],
                        [
                            -87.05833333341656,
                            41.69999999950045
                        ],
                        [
                            -87.13055555589415,
                            41.69999999950045
                        ],
                        [
                            -87.29444444425565,
                            41.69999999950045
                        ],
                        [
                            -87.47999999990003,
                            41.699722222302796
                        ],
                        [
                            -87.43472222225273,
                            41.667222221828126
                        ],
                        [
                            -87.29083333349149,
                            41.54583333284211
                        ],
                        [
                            -87.16972222260245,
                            41.44472222155332
                        ],
                        [
                            -87.13668611107511,
                            41.431025000003785
                        ],
                        [
                            -87.0000000004996,
                            41.3741666667583
                        ],
                        [
                            -86.64638888953556,
                            41.22583333294199
                        ],
                        [
                            -86.63258611096023,
                            41.22412777767488
                        ],
                        [
                            -86.11759166692184,
                            41.15918888835654
                        ],
                        [
                            -86.05000000059954,
                            41.1499999997003
                        ],
                        [
                            -85.94555555574425,
                            41.13694444443064
                        ],
                        [
                            -85.83333333316676,
                            41.12222222147841
                        ],
                        [
                            -85.93388888916087,
                            41.351666666083815
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 72,
                "artcc": "zau",
                "sector": "35",
                "sectype": "high",
                "Shape_Length": 3.8577688218011916,
                "Shape_Area": 0.5925153069749449
            }
        },
        {
            "type": "Feature",
            "id": 73,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -84.95833333401612,
                            40.56666666603388
                        ],
                        [
                            -84.78888888911092,
                            40.633333332867096
                        ],
                        [
                            -84.68333333366638,
                            40.66666666673336
                        ],
                        [
                            -84.68777777782213,
                            40.744444443955956
                        ],
                        [
                            -84.70000000059952,
                            40.90833333321683
                        ],
                        [
                            -84.70916388900224,
                            41.15869999980521
                        ],
                        [
                            -84.71666666663333,
                            41.28333333336667
                        ],
                        [
                            -84.7500000004996,
                            41.583333332767154
                        ],
                        [
                            -85.0597222225025,
                            41.589166666508504
                        ],
                        [
                            -85.9883333332167,
                            41.599722221603315
                        ],
                        [
                            -86.2500000001998,
                            41.59999999970029
                        ],
                        [
                            -86.05000000059954,
                            41.1499999997003
                        ],
                        [
                            -85.94555555574425,
                            41.13694444443064
                        ],
                        [
                            -85.83333333316676,
                            41.12222222147841
                        ],
                        [
                            -85.7333333333666,
                            40.88333333326676
                        ],
                        [
                            -85.43750000002495,
                            40.637499999825195
                        ],
                        [
                            -85.23333333346653,
                            40.46666666623372
                        ],
                        [
                            -84.95833333401612,
                            40.56666666603388
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 73,
                "artcc": "zau",
                "sector": "36",
                "sectype": "high",
                "Shape_Length": 4.625715541031701,
                "Shape_Area": 1.1986857906791226
            }
        },
        {
            "type": "Feature",
            "id": 77,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -88.88750000032474,
                            41.06666666683327
                        ],
                        [
                            -88.92083333329168,
                            40.90833333321683
                        ],
                        [
                            -89.1999999997002,
                            40.65416666675833
                        ],
                        [
                            -89.8758333335415,
                            40.05250000027485
                        ],
                        [
                            -89.75000000039967,
                            40.00000000019992
                        ],
                        [
                            -89.18472222235266,
                            40.00000000019992
                        ],
                        [
                            -88.9500000001998,
                            40.00000000019992
                        ],
                        [
                            -88.24999999980014,
                            40.00000000019992
                        ],
                        [
                            -88.16666666693311,
                            40.00000000019992
                        ],
                        [
                            -88.08750000012486,
                            40.508333333116866
                        ],
                        [
                            -88.00000000029974,
                            41.06666666683327
                        ],
                        [
                            -88.88750000032474,
                            41.06666666683327
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 77,
                "artcc": "zau",
                "sector": "45",
                "sectype": "high",
                "Shape_Length": 5.130980355182262,
                "Shape_Area": 1.3441458330082534
            }
        },
        {
            "type": "Feature",
            "id": 79,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -88.00000000029974,
                            41.06666666683327
                        ],
                        [
                            -88.08750000012486,
                            40.508333333116866
                        ],
                        [
                            -88.16666666693311,
                            40.00000000019992
                        ],
                        [
                            -87.26861111091398,
                            40.00000000019992
                        ],
                        [
                            -86.9499999997002,
                            40.00000000019992
                        ],
                        [
                            -86.41666666683318,
                            40.00000000019992
                        ],
                        [
                            -86.61999999999995,
                            40.633333332867096
                        ],
                        [
                            -86.72166666658336,
                            40.752222222577586
                        ],
                        [
                            -86.9286111114136,
                            40.98638888853645
                        ],
                        [
                            -86.9583333336164,
                            41.01666666693319
                        ],
                        [
                            -86.99999999960028,
                            41.06666666683327
                        ],
                        [
                            -88.00000000029974,
                            41.06666666683327
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 79,
                "artcc": "zau",
                "sector": "46",
                "sectype": "high",
                "Shape_Length": 5.071233190099735,
                "Shape_Area": 1.5435841048661425
            }
        },
        {
            "type": "Feature",
            "id": 80,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -88.00000000029974,
                            41.06666666683327
                        ],
                        [
                            -88.08750000012486,
                            40.508333333116866
                        ],
                        [
                            -88.16666666693311,
                            40.00000000019992
                        ],
                        [
                            -87.26861111091398,
                            40.00000000019992
                        ],
                        [
                            -86.9499999997002,
                            40.00000000019992
                        ],
                        [
                            -86.41666666683318,
                            40.00000000019992
                        ],
                        [
                            -86.61999999999995,
                            40.633333332867096
                        ],
                        [
                            -86.72166666658336,
                            40.752222222577586
                        ],
                        [
                            -86.9286111114136,
                            40.98638888853645
                        ],
                        [
                            -86.9583333336164,
                            41.01666666693319
                        ],
                        [
                            -86.99999999960028,
                            41.06666666683327
                        ],
                        [
                            -88.00000000029974,
                            41.06666666683327
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 80,
                "artcc": "zau",
                "sector": "47",
                "sectype": "high",
                "Shape_Length": 5.071233190099735,
                "Shape_Area": 1.5435841048661425
            }
        },
        {
            "type": "Feature",
            "id": 83,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -91.39999999980012,
                            40.683333333666496
                        ],
                        [
                            -91.13805555561936,
                            40.57666666673333
                        ],
                        [
                            -90.92777777812194,
                            40.48888888881123
                        ],
                        [
                            -90.72416666685814,
                            40.40777777802208
                        ],
                        [
                            -90.56805555531957,
                            40.33888888911099
                        ],
                        [
                            -90.33333333316676,
                            40.241666666683386
                        ],
                        [
                            -89.8758333335415,
                            40.05250000027485
                        ],
                        [
                            -89.1999999997002,
                            40.65416666675833
                        ],
                        [
                            -88.92083333329168,
                            40.90833333321683
                        ],
                        [
                            -88.88750000032474,
                            41.06666666683327
                        ],
                        [
                            -88.87916666640848,
                            41.09999999980022
                        ],
                        [
                            -89.26666666653341,
                            41.38333333316683
                        ],
                        [
                            -90.05833333371635,
                            41.30833333331674
                        ],
                        [
                            -90.49999999980014,
                            41.133333333666485
                        ],
                        [
                            -90.77500000014987,
                            41.033333332967004
                        ],
                        [
                            -91.39999999980012,
                            40.683333333666496
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 83,
                "artcc": "zau",
                "sector": "52",
                "sectype": "high",
                "Shape_Length": 5.887442632228434,
                "Shape_Area": 1.8592870367604295
            }
        },
        {
            "type": "Feature",
            "id": 88,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -90.85416666695806,
                            43.595833333241785
                        ],
                        [
                            -89.66666666663332,
                            43.041666666483536
                        ],
                        [
                            -88.74999999970021,
                            42.549999999600345
                        ],
                        [
                            -88.63611111123873,
                            42.35555555564446
                        ],
                        [
                            -86.99999999960028,
                            42.50555555534464
                        ],
                        [
                            -86.99999999960028,
                            42.80000000000007
                        ],
                        [
                            -86.99999999960028,
                            43.050000000399734
                        ],
                        [
                            -86.99999999960028,
                            43.31666666683327
                        ],
                        [
                            -86.99999999960028,
                            43.56666666633362
                        ],
                        [
                            -86.99999999960028,
                            43.68499999985016
                        ],
                        [
                            -87.16944444450547,
                            43.69166666698317
                        ],
                        [
                            -87.54166666638349,
                            43.96666666643358
                        ],
                        [
                            -88.46666666633354,
                            44.10000000009995
                        ],
                        [
                            -88.7541666666583,
                            44.15944444450554
                        ],
                        [
                            -89.07611111143859,
                            44.22499999985018
                        ],
                        [
                            -89.37083333329167,
                            44.18333333296698
                        ],
                        [
                            -89.58749999982507,
                            44.15416666695819
                        ],
                        [
                            -89.62916666670827,
                            44.15416666695819
                        ],
                        [
                            -89.97777777822188,
                            44.15416666695819
                        ],
                        [
                            -90.08333333366636,
                            44.15416666695819
                        ],
                        [
                            -90.29166666628356,
                            44.15416666695819
                        ],
                        [
                            -90.37027777779718,
                            44.085833333341725
                        ],
                        [
                            -90.42222222257749,
                            44.038888888911174
                        ],
                        [
                            -90.54999999970016,
                            43.89999999960037
                        ],
                        [
                            -90.64166666648345,
                            43.81666666673334
                        ],
                        [
                            -90.76527777754734,
                            43.68888888871129
                        ],
                        [
                            -90.82916666700805,
                            43.618055555819296
                        ],
                        [
                            -90.85416666695806,
                            43.595833333241785
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 88,
                "artcc": "zau",
                "sector": "60",
                "sectype": "high",
                "Shape_Length": 9.601303158227047,
                "Shape_Area": 4.808967053590753
            }
        },
        {
            "type": "Feature",
            "id": 89,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -90.85416666695806,
                            43.595833333241785
                        ],
                        [
                            -89.66666666663332,
                            43.041666666483536
                        ],
                        [
                            -88.74999999970021,
                            42.549999999600345
                        ],
                        [
                            -88.63611111123873,
                            42.35555555564446
                        ],
                        [
                            -86.99999999960028,
                            42.50555555534464
                        ],
                        [
                            -86.99999999960028,
                            42.80000000000007
                        ],
                        [
                            -86.99999999960028,
                            43.050000000399734
                        ],
                        [
                            -86.99999999960028,
                            43.31666666683327
                        ],
                        [
                            -86.99999999960028,
                            43.56666666633362
                        ],
                        [
                            -86.99999999960028,
                            43.68499999985016
                        ],
                        [
                            -87.16944444450547,
                            43.69166666698317
                        ],
                        [
                            -87.54166666638349,
                            43.96666666643358
                        ],
                        [
                            -88.46666666633354,
                            44.10000000009995
                        ],
                        [
                            -88.7541666666583,
                            44.15944444450554
                        ],
                        [
                            -89.07611111143859,
                            44.22499999985018
                        ],
                        [
                            -89.37083333329167,
                            44.18333333296698
                        ],
                        [
                            -89.58749999982507,
                            44.15416666695819
                        ],
                        [
                            -89.62916666670827,
                            44.15416666695819
                        ],
                        [
                            -89.97777777822188,
                            44.15416666695819
                        ],
                        [
                            -90.08333333366636,
                            44.15416666695819
                        ],
                        [
                            -90.29166666628356,
                            44.15416666695819
                        ],
                        [
                            -90.37027777779718,
                            44.085833333341725
                        ],
                        [
                            -90.42222222257749,
                            44.038888888911174
                        ],
                        [
                            -90.54999999970016,
                            43.89999999960037
                        ],
                        [
                            -90.64166666648345,
                            43.81666666673334
                        ],
                        [
                            -90.76527777754734,
                            43.68888888871129
                        ],
                        [
                            -90.82916666700805,
                            43.618055555819296
                        ],
                        [
                            -90.85416666695806,
                            43.595833333241785
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 89,
                "artcc": "zau",
                "sector": "61",
                "sectype": "high",
                "Shape_Length": 9.601303158227047,
                "Shape_Area": 4.808967053590753
            }
        },
        {
            "type": "Feature",
            "id": 95,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -89.66666666663332,
                            43.041666666483536
                        ],
                        [
                            -90.85416666695806,
                            43.595833333241785
                        ],
                        [
                            -91.08333333346656,
                            43.36666666673335
                        ],
                        [
                            -91.11861111131373,
                            43.333333332867085
                        ],
                        [
                            -91.22083333319176,
                            43.23333333306692
                        ],
                        [
                            -91.66666666713297,
                            42.783333333066935
                        ],
                        [
                            -91.72500000004993,
                            42.7249999992506
                        ],
                        [
                            -92.46666666733279,
                            42.756944444430644
                        ],
                        [
                            -93.0000000001998,
                            42.783333333066935
                        ],
                        [
                            -93.05000000009989,
                            42.66666666633364
                        ],
                        [
                            -93.2375000006245,
                            42.22916666630863
                        ],
                        [
                            -93.26666666663334,
                            42.158333333416635
                        ],
                        [
                            -93.35555555604407,
                            41.947222221628294
                        ],
                        [
                            -91.5000000004996,
                            41.949999999900115
                        ],
                        [
                            -90.81944444440558,
                            42.19138888828661
                        ],
                        [
                            -89.67000000019982,
                            42.20749999992512
                        ],
                        [
                            -89.20611111153852,
                            42.212499999375495
                        ],
                        [
                            -88.96055555619392,
                            42.214444444255776
                        ],
                        [
                            -88.81611111123874,
                            42.215833332942
                        ],
                        [
                            -88.55000000009989,
                            42.183333333366704
                        ],
                        [
                            -88.61111111128872,
                            42.3000000001
                        ],
                        [
                            -88.63611111123873,
                            42.35555555564446
                        ],
                        [
                            -88.75000000059953,
                            42.549999999600345
                        ],
                        [
                            -89.66666666663332,
                            43.041666666483536
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 95,
                "artcc": "zau",
                "sector": "75",
                "sectype": "high",
                "Shape_Length": 11.034337772631082,
                "Shape_Area": 4.056705941883723
            }
        },
        {
            "type": "Feature",
            "id": 96,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -89.66666666663332,
                            43.041666666483536
                        ],
                        [
                            -90.85416666695806,
                            43.595833333241785
                        ],
                        [
                            -91.08333333346656,
                            43.36666666673335
                        ],
                        [
                            -91.11861111131373,
                            43.333333332867085
                        ],
                        [
                            -91.22083333319176,
                            43.23333333306692
                        ],
                        [
                            -91.66666666713297,
                            42.783333333066935
                        ],
                        [
                            -91.72500000004993,
                            42.7249999992506
                        ],
                        [
                            -92.46666666733279,
                            42.756944444430644
                        ],
                        [
                            -93.0000000001998,
                            42.783333333066935
                        ],
                        [
                            -93.05000000009989,
                            42.66666666633364
                        ],
                        [
                            -93.2375000006245,
                            42.22916666630863
                        ],
                        [
                            -93.26666666663334,
                            42.158333333416635
                        ],
                        [
                            -93.35555555604407,
                            41.947222221628294
                        ],
                        [
                            -93.46666666713293,
                            41.6666666665335
                        ],
                        [
                            -91.7583333339162,
                            41.77333333256729
                        ],
                        [
                            -91.39055555619393,
                            41.80527777774728
                        ],
                        [
                            -89.71277777767227,
                            41.93638888843651
                        ],
                        [
                            -88.55000000009989,
                            41.98333333286706
                        ],
                        [
                            -88.55000000009989,
                            42.183333333366704
                        ],
                        [
                            -88.61111111128872,
                            42.3000000001
                        ],
                        [
                            -88.63611111123873,
                            42.35555555564446
                        ],
                        [
                            -88.75000000059953,
                            42.549999999600345
                        ],
                        [
                            -89.66666666663332,
                            43.041666666483536
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 96,
                "artcc": "zau",
                "sector": "76",
                "sectype": "high",
                "Shape_Length": 11.614304188572937,
                "Shape_Area": 5.270492747690628
            }
        },
        {
            "type": "Feature",
            "id": 101,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -88.63611111123873,
                            42.35555555564446
                        ],
                        [
                            -88.61111111128872,
                            42.3000000001
                        ],
                        [
                            -88.55000000009989,
                            42.183333333366704
                        ],
                        [
                            -88.55000000009989,
                            41.98333333286706
                        ],
                        [
                            -88.55000000009989,
                            41.71666666643358
                        ],
                        [
                            -89.26666666653341,
                            41.38333333316683
                        ],
                        [
                            -88.87916666640848,
                            41.09999999980022
                        ],
                        [
                            -88.88750000032474,
                            41.06666666683327
                        ],
                        [
                            -88.00000000029974,
                            41.06666666683327
                        ],
                        [
                            -86.99999999960028,
                            41.06666666683327
                        ],
                        [
                            -86.99999999960028,
                            41.3741666667583
                        ],
                        [
                            -86.99999999960028,
                            41.608333333616486
                        ],
                        [
                            -86.99999999960028,
                            41.70000000039977
                        ],
                        [
                            -86.99999999960028,
                            42.02916666670836
                        ],
                        [
                            -86.99999999960028,
                            42.35694444433068
                        ],
                        [
                            -86.99999999960028,
                            42.50555555534464
                        ],
                        [
                            -88.63611111123873,
                            42.35555555564446
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 101,
                "artcc": "zau",
                "sector": "83",
                "sectype": "high",
                "Shape_Length": 6.933441129573073,
                "Shape_Area": 2.406855709949595
            }
        },
        {
            "type": "Feature",
            "id": 103,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -84.76666666653341,
                            41.79999999930055
                        ],
                        [
                            -84.76666666653341,
                            41.85833333311689
                        ],
                        [
                            -84.99999999999994,
                            41.999999999800195
                        ],
                        [
                            -84.99999999999994,
                            42.0416666666834
                        ],
                        [
                            -84.99921388910968,
                            42.132474999821454
                        ],
                        [
                            -84.99999999999994,
                            42.16666666643357
                        ],
                        [
                            -84.99999999999994,
                            42.373888888461465
                        ],
                        [
                            -86.01388888936071,
                            42.38888888861135
                        ],
                        [
                            -86.2002777783967,
                            42.387222221828154
                        ],
                        [
                            -86.31666666703302,
                            42.38333333296697
                        ],
                        [
                            -87.0000000004996,
                            42.35694444433068
                        ],
                        [
                            -87.0000000004996,
                            42.02916666670836
                        ],
                        [
                            -87.0000000004996,
                            41.69999999950045
                        ],
                        [
                            -87.0000000004996,
                            41.608333332717166
                        ],
                        [
                            -86.2500000001998,
                            41.59999999970029
                        ],
                        [
                            -85.9883333332167,
                            41.599722221603315
                        ],
                        [
                            -85.0597222225025,
                            41.589166666508504
                        ],
                        [
                            -84.7500000004996,
                            41.583333332767154
                        ],
                        [
                            -84.76666666653341,
                            41.79999999930055
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 103,
                "artcc": "zau",
                "sector": "85",
                "sectype": "high",
                "Shape_Length": 5.921978910412439,
                "Shape_Area": 1.6414969334740366
            }
        },
        {
            "type": "Feature",
            "id": 104,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -86.99999999960028,
                            42.35694444433068
                        ],
                        [
                            -86.99999999960028,
                            42.02916666670836
                        ],
                        [
                            -86.89861111111384,
                            42.032222222177836
                        ],
                        [
                            -85.99999999980014,
                            42.049999999700276
                        ],
                        [
                            -84.99999999999994,
                            42.0416666666834
                        ],
                        [
                            -84.99921388910968,
                            42.132474999821454
                        ],
                        [
                            -84.99999999999994,
                            42.16666666643357
                        ],
                        [
                            -84.99999999999994,
                            42.373888888461465
                        ],
                        [
                            -86.0138888884614,
                            42.38888888861135
                        ],
                        [
                            -86.20027777749738,
                            42.387222221828154
                        ],
                        [
                            -86.31666666703302,
                            42.38333333296697
                        ],
                        [
                            -86.99999999960028,
                            42.35694444433068
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 104,
                "artcc": "zau",
                "sector": "88",
                "sectype": "high",
                "Shape_Length": 4.660961735094969,
                "Shape_Area": 0.6709463151133647
            }
        },
        {
            "type": "Feature",
            "id": 105,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -84.76666666653341,
                            41.79999999930055
                        ],
                        [
                            -84.76666666653341,
                            41.85833333311689
                        ],
                        [
                            -84.99999999999994,
                            41.999999999800195
                        ],
                        [
                            -84.99999999999994,
                            42.0416666666834
                        ],
                        [
                            -86.00000000069946,
                            42.049999999700276
                        ],
                        [
                            -86.89861111111384,
                            42.032222222177836
                        ],
                        [
                            -87.0000000004996,
                            42.02916666670836
                        ],
                        [
                            -87.0000000004996,
                            41.69999999950045
                        ],
                        [
                            -87.0000000004996,
                            41.608333332717166
                        ],
                        [
                            -86.2500000001998,
                            41.59999999970029
                        ],
                        [
                            -85.9883333332167,
                            41.599722221603315
                        ],
                        [
                            -85.0597222225025,
                            41.589166666508504
                        ],
                        [
                            -84.7500000004996,
                            41.583333332767154
                        ],
                        [
                            -84.76666666653341,
                            41.79999999930055
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 105,
                "artcc": "zau",
                "sector": "89",
                "sectype": "high",
                "Shape_Length": 5.261530356407296,
                "Shape_Area": 0.9705506180631469
            }
        },
        {
            "type": "Feature",
            "id": 106,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -93.35555555604407,
                            41.947222222527614
                        ],
                        [
                            -93.46666666713293,
                            41.6666666665335
                        ],
                        [
                            -93.48499999994999,
                            41.566111111438715
                        ],
                        [
                            -93.50833333311681,
                            41.43333333306691
                        ],
                        [
                            -93.59999999990004,
                            41.16666666663343
                        ],
                        [
                            -93.5833333329669,
                            41.06666666683327
                        ],
                        [
                            -93.56249999997499,
                            40.9444444444556
                        ],
                        [
                            -93.55694444433061,
                            40.91749999962536
                        ],
                        [
                            -93.491666667083,
                            40.52500000005
                        ],
                        [
                            -92.71666666683319,
                            40.63472222245264
                        ],
                        [
                            -91.74166666698306,
                            40.76388888916097
                        ],
                        [
                            -91.65000000019984,
                            40.7833333334666
                        ],
                        [
                            -91.39999999980012,
                            40.683333333666496
                        ],
                        [
                            -90.77500000014987,
                            41.033333332967004
                        ],
                        [
                            -90.49999999980014,
                            41.133333333666485
                        ],
                        [
                            -91.02083333359144,
                            41.31111111068924
                        ],
                        [
                            -91.39055555529461,
                            41.80527777774728
                        ],
                        [
                            -91.49999999960028,
                            41.949999999900115
                        ],
                        [
                            -93.35555555604407,
                            41.947222222527614
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 106,
                "artcc": "zau",
                "sector": "90",
                "sectype": "high",
                "Shape_Length": 7.814196871360373,
                "Shape_Area": 3.106840277537708
            }
        },
        {
            "type": "Feature",
            "id": 107,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -91.39999999980012,
                            40.683333333666496
                        ],
                        [
                            -91.13805555561936,
                            40.57666666673333
                        ],
                        [
                            -90.92777777812194,
                            40.48888888881123
                        ],
                        [
                            -90.72416666685814,
                            40.40777777802208
                        ],
                        [
                            -90.56805555531957,
                            40.33888888911099
                        ],
                        [
                            -90.33333333316676,
                            40.241666666683386
                        ],
                        [
                            -89.8758333335415,
                            40.05250000027485
                        ],
                        [
                            -89.1999999997002,
                            40.65416666675833
                        ],
                        [
                            -88.92083333329168,
                            40.90833333321683
                        ],
                        [
                            -88.88750000032474,
                            41.06666666683327
                        ],
                        [
                            -88.87916666640848,
                            41.09999999980022
                        ],
                        [
                            -89.26666666653341,
                            41.38333333316683
                        ],
                        [
                            -90.05833333371635,
                            41.30833333331674
                        ],
                        [
                            -90.49999999980014,
                            41.133333333666485
                        ],
                        [
                            -90.77500000014987,
                            41.033333332967004
                        ],
                        [
                            -91.39999999980012,
                            40.683333333666496
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 107,
                "artcc": "zau",
                "sector": "91",
                "sectype": "high",
                "Shape_Length": 5.887442632228434,
                "Shape_Area": 1.8592870367604295
            }
        },
        {
            "type": "Feature",
            "id": 108,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -91.49999999960028,
                            41.949999999900115
                        ],
                        [
                            -91.39055555529461,
                            41.80527777774728
                        ],
                        [
                            -91.02083333359144,
                            41.31111111068924
                        ],
                        [
                            -90.49999999980014,
                            41.133333333666485
                        ],
                        [
                            -90.05833333371635,
                            41.30833333331674
                        ],
                        [
                            -89.26666666653341,
                            41.38333333316683
                        ],
                        [
                            -88.55000000009989,
                            41.71666666643358
                        ],
                        [
                            -88.55000000009989,
                            41.98333333286706
                        ],
                        [
                            -88.55000000009989,
                            42.183333333366704
                        ],
                        [
                            -88.81611111123874,
                            42.215833332942
                        ],
                        [
                            -88.9605555552946,
                            42.214444444255776
                        ],
                        [
                            -89.20611111153852,
                            42.21250000027487
                        ],
                        [
                            -89.67000000019982,
                            42.20749999992512
                        ],
                        [
                            -90.81944444440558,
                            42.19138888918593
                        ],
                        [
                            -91.49999999960028,
                            41.949999999900115
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 108,
                "artcc": "zau",
                "sector": "92",
                "sectype": "high",
                "Shape_Length": 6.8699693494043235,
                "Shape_Area": 2.2777282795793554
            }
        },
        {
            "type": "Feature",
            "id": 109,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -90.77500000014987,
                            41.033333332967004
                        ],
                        [
                            -90.50000000069946,
                            41.133333332767165
                        ],
                        [
                            -90.05833333371635,
                            41.30833333331674
                        ],
                        [
                            -89.26666666653341,
                            41.38333333316683
                        ],
                        [
                            -88.55000000009989,
                            41.71666666643358
                        ],
                        [
                            -88.55000000009989,
                            41.98333333286706
                        ],
                        [
                            -89.71277777767227,
                            41.93638888843651
                        ],
                        [
                            -91.39055555619393,
                            41.80527777774728
                        ],
                        [
                            -91.7583333339162,
                            41.77333333256729
                        ],
                        [
                            -93.46666666713293,
                            41.6666666665335
                        ],
                        [
                            -93.48499999994999,
                            41.566111110539396
                        ],
                        [
                            -93.50833333401613,
                            41.43333333306691
                        ],
                        [
                            -93.59999999990004,
                            41.16666666663343
                        ],
                        [
                            -93.58333333386622,
                            41.06666666683327
                        ],
                        [
                            -93.56249999997499,
                            40.9444444444556
                        ],
                        [
                            -93.55694444433061,
                            40.91749999962536
                        ],
                        [
                            -93.491666667083,
                            40.52500000005
                        ],
                        [
                            -92.71666666683319,
                            40.63472222155332
                        ],
                        [
                            -91.74166666698306,
                            40.76388888826165
                        ],
                        [
                            -91.65000000019984,
                            40.78333333256728
                        ],
                        [
                            -91.40000000069944,
                            40.68333333276712
                        ],
                        [
                            -90.77500000014987,
                            41.033333332967004
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 109,
                "artcc": "zau",
                "sector": "94",
                "sectype": "high",
                "Shape_Length": 11.56271661746949,
                "Shape_Area": 4.170781751555852
            }
        },
        {
            "type": "Feature",
            "id": 125,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -67.75777777802199,
                            45.62499999975023
                        ],
                        [
                            -67.80638888923579,
                            45.78333333336667
                        ],
                        [
                            -67.58277777747242,
                            45.82500000024987
                        ],
                        [
                            -67.21611111123877,
                            46.14305555526971
                        ],
                        [
                            -67.21611111123877,
                            46.20000000039977
                        ],
                        [
                            -67.29944444410575,
                            46.53611111103902
                        ],
                        [
                            -67.2549999998501,
                            46.56944444400597
                        ],
                        [
                            -67.13277777747243,
                            46.69166666638358
                        ],
                        [
                            -67.05777777762228,
                            46.82777777742257
                        ],
                        [
                            -67.04111111068914,
                            46.98055555539463
                        ],
                        [
                            -67.08277777757235,
                            47.13333333336669
                        ],
                        [
                            -67.17444444435557,
                            47.26944444440562
                        ],
                        [
                            -67.33277777797201,
                            47.386111111138916
                        ],
                        [
                            -67.52722222192796,
                            47.472222222277765
                        ],
                        [
                            -67.74944444410579,
                            47.5333333334666
                        ],
                        [
                            -67.99999999980014,
                            47.5249999995504
                        ],
                        [
                            -68.06611111133867,
                            47.51666666653347
                        ],
                        [
                            -68.19944444410578,
                            47.4888888892109
                        ],
                        [
                            -68.27166666658337,
                            47.45833333361651
                        ],
                        [
                            -68.39388888896104,
                            47.40833333371643
                        ],
                        [
                            -68.56666666653342,
                            47.287500000025034
                        ],
                        [
                            -68.99999999960028,
                            47.39305555546957
                        ],
                        [
                            -69.216666667033,
                            47.44999999970025
                        ],
                        [
                            -69.99944444410579,
                            46.69166666638358
                        ],
                        [
                            -70.03833333361644,
                            46.53888888931084
                        ],
                        [
                            -70.05861111131372,
                            46.418055555619446
                        ],
                        [
                            -70.20833333291694,
                            46.30833333321681
                        ],
                        [
                            -70.24999999980014,
                            45.9083333331169
                        ],
                        [
                            -70.50277777757236,
                            45.686111110939066
                        ],
                        [
                            -71.00000000009987,
                            45.31666666643355
                        ],
                        [
                            -71.33277777807194,
                            45.2833333334666
                        ],
                        [
                            -71.45000000009986,
                            45.0666666669332
                        ],
                        [
                            -70.98888888881112,
                            44.77888888851146
                        ],
                        [
                            -70.48333333326667,
                            44.91666666633364
                        ],
                        [
                            -67.75777777802199,
                            45.62499999975023
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 125,
                "artcc": "zbw",
                "sector": "01",
                "sectype": "high",
                "Shape_Length": 11.704599415247378,
                "Shape_Area": 6.324369599535538
            }
        },
        {
            "type": "Feature",
            "id": 126,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -69.50000000039967,
                            43.10833333331675
                        ],
                        [
                            -68.72500000014986,
                            43.383333332767165
                        ],
                        [
                            -66.99999999999994,
                            43.79999999980021
                        ],
                        [
                            -66.99999999999994,
                            44.34166666658342
                        ],
                        [
                            -66.99999999999994,
                            44.4999999993006
                        ],
                        [
                            -66.99999999999994,
                            44.93333333326677
                        ],
                        [
                            -67.33277777797201,
                            45.249999999600334
                        ],
                        [
                            -67.75777777802199,
                            45.62499999975023
                        ],
                        [
                            -70.48333333326667,
                            44.91666666633364
                        ],
                        [
                            -70.98888888881112,
                            44.77888888851146
                        ],
                        [
                            -70.92888888911091,
                            44.58111111098901
                        ],
                        [
                            -70.86611111113882,
                            44.33333333266722
                        ],
                        [
                            -70.81666666653342,
                            44.16666666603385
                        ],
                        [
                            -71.00000000009987,
                            44.04999999930055
                        ],
                        [
                            -71.15833333371631,
                            43.78333333286707
                        ],
                        [
                            -71.18333333366638,
                            43.71666666603386
                        ],
                        [
                            -71.10833333381629,
                            43.39166666668336
                        ],
                        [
                            -71.41055555619391,
                            43.086666666033864
                        ],
                        [
                            -71.15833333371631,
                            42.94999999970025
                        ],
                        [
                            -71.01500000024976,
                            42.87222222157834
                        ],
                        [
                            -70.59999999999997,
                            42.64444444375613
                        ],
                        [
                            -69.50000000039967,
                            43.10833333331675
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 126,
                "artcc": "zbw",
                "sector": "02",
                "sectype": "high",
                "Shape_Length": 12.211376691593598,
                "Shape_Area": 8.032384722832074
            }
        },
        {
            "type": "Feature",
            "id": 130,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -74.51666666723287,
                            42.999999999600334
                        ],
                        [
                            -74.40000000049957,
                            43.26666666603387
                        ],
                        [
                            -74.03527777824684,
                            43.32638888853643
                        ],
                        [
                            -74.23888888951058,
                            43.56111111068924
                        ],
                        [
                            -74.50000000029974,
                            44.07499999925062
                        ],
                        [
                            -74.50000000029974,
                            44.358333332617235
                        ],
                        [
                            -74.56666666713295,
                            44.60000000000008
                        ],
                        [
                            -74.76972222220274,
                            44.81333333296698
                        ],
                        [
                            -75.6333333337663,
                            44.60833333301696
                        ],
                        [
                            -75.74111111128872,
                            44.53388888846149
                        ],
                        [
                            -76.00666666713295,
                            44.35388888846148
                        ],
                        [
                            -76.13861111121378,
                            44.25388888866132
                        ],
                        [
                            -76.18333333356645,
                            44.222222221578306
                        ],
                        [
                            -76.31638888913591,
                            44.19166666598392
                        ],
                        [
                            -76.34138888908592,
                            44.13888888871128
                        ],
                        [
                            -76.42472222285227,
                            44.10000000009995
                        ],
                        [
                            -76.79666666663331,
                            43.631111111088956
                        ],
                        [
                            -76.78055555589413,
                            43.3999999997003
                        ],
                        [
                            -76.77833333381625,
                            43.35444444395597
                        ],
                        [
                            -76.77500000024975,
                            43.329166666808305
                        ],
                        [
                            -76.76388888896099,
                            43.06666666643355
                        ],
                        [
                            -76.7333333333666,
                            43.01666666653347
                        ],
                        [
                            -76.4347222226524,
                            43.007777777322644
                        ],
                        [
                            -74.52777777852162,
                            42.93333333276712
                        ],
                        [
                            -74.51666666723287,
                            42.999999999600334
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 130,
                "artcc": "zbw",
                "sector": "08",
                "sectype": "high",
                "Shape_Length": 7.720325653493557,
                "Shape_Area": 3.5271388118349343
            }
        },
        {
            "type": "Feature",
            "id": 132,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -75.63333333286698,
                            44.60833333301696
                        ],
                        [
                            -75.74111111128872,
                            44.53388888846149
                        ],
                        [
                            -76.00666666713295,
                            44.35388888846148
                        ],
                        [
                            -76.13861111121378,
                            44.25388888866132
                        ],
                        [
                            -76.18333333356645,
                            44.222222222477626
                        ],
                        [
                            -76.31638888913591,
                            44.191666666883236
                        ],
                        [
                            -76.34138888908592,
                            44.13888888871128
                        ],
                        [
                            -76.42472222195295,
                            44.10000000009995
                        ],
                        [
                            -76.79666666663331,
                            43.631111111088956
                        ],
                        [
                            -76.78055555589413,
                            43.3999999997003
                        ],
                        [
                            -76.77833333291693,
                            43.35444444395597
                        ],
                        [
                            -76.77500000024975,
                            43.329166666808305
                        ],
                        [
                            -76.76388888896099,
                            43.06666666643355
                        ],
                        [
                            -76.7333333333666,
                            43.01666666653347
                        ],
                        [
                            -76.43472222175308,
                            43.007777778221964
                        ],
                        [
                            -74.5277777776223,
                            42.933333333666496
                        ],
                        [
                            -74.51666666633355,
                            42.999999999600334
                        ],
                        [
                            -74.39999999960025,
                            43.26666666693319
                        ],
                        [
                            -74.23888888861126,
                            43.56111111068924
                        ],
                        [
                            -74.50000000029974,
                            44.07500000014994
                        ],
                        [
                            -74.50000000029974,
                            44.358333333516555
                        ],
                        [
                            -74.56666666713295,
                            44.60000000000008
                        ],
                        [
                            -74.76972222220274,
                            44.81333333296698
                        ],
                        [
                            -75.63333333286698,
                            44.60833333301696
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 132,
                "artcc": "zbw",
                "sector": "09",
                "sectype": "high",
                "Shape_Length": 7.375658083427209,
                "Shape_Area": 3.47825455120974
            }
        },
        {
            "type": "Feature",
            "id": 133,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -76.7333333333666,
                            43.01666666653347
                        ],
                        [
                            -76.66666666653339,
                            42.883333332867096
                        ],
                        [
                            -76.68916666630855,
                            42.768611111014025
                        ],
                        [
                            -76.69722222212783,
                            42.727777777522476
                        ],
                        [
                            -76.80277777757232,
                            42.60833333341668
                        ],
                        [
                            -76.23333333346653,
                            42.36666666693321
                        ],
                        [
                            -76.01666666693308,
                            42.283333333166865
                        ],
                        [
                            -75.61666666683317,
                            42.104166666558456
                        ],
                        [
                            -75.34166666648343,
                            41.98888888851144
                        ],
                        [
                            -75.14777777782217,
                            41.90722222242766
                        ],
                        [
                            -75.05833333311676,
                            41.869444444405644
                        ],
                        [
                            -74.90833333341658,
                            41.80138888888615
                        ],
                        [
                            -74.80833333361642,
                            41.75833333331673
                        ],
                        [
                            -74.76666666673327,
                            41.733333333366716
                        ],
                        [
                            -74.44583333344156,
                            41.999999999800195
                        ],
                        [
                            -74.38305555546947,
                            41.99972222260254
                        ],
                        [
                            -74.3255555559441,
                            42.34222222227777
                        ],
                        [
                            -74.58333333316676,
                            42.54166666658347
                        ],
                        [
                            -74.56250000017485,
                            42.67500000024984
                        ],
                        [
                            -74.5277777776223,
                            42.933333333666496
                        ],
                        [
                            -76.43472222175308,
                            43.007777778221964
                        ],
                        [
                            -76.7333333333666,
                            43.01666666653347
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 133,
                "artcc": "zbw",
                "sector": "10",
                "sectype": "high",
                "Shape_Length": 6.439817840060389,
                "Shape_Area": 1.9301695609006568
            }
        },
        {
            "type": "Feature",
            "id": 134,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -67.33277777797201,
                            47.386111111138916
                        ],
                        [
                            -67.52722222282728,
                            47.472222222277765
                        ],
                        [
                            -67.74944444500511,
                            47.53333333256728
                        ],
                        [
                            -68.00000000069946,
                            47.5249999995504
                        ],
                        [
                            -68.06611111133867,
                            47.51666666653347
                        ],
                        [
                            -68.1994444450051,
                            47.48888888831158
                        ],
                        [
                            -68.27166666658337,
                            47.45833333271719
                        ],
                        [
                            -68.39388888896104,
                            47.40833333281711
                        ],
                        [
                            -68.56666666653342,
                            47.287500000025034
                        ],
                        [
                            -69.0000000004996,
                            47.39305555546957
                        ],
                        [
                            -69.216666667033,
                            47.44999999970025
                        ],
                        [
                            -69.99944444500511,
                            46.69166666638358
                        ],
                        [
                            -70.03833333361644,
                            46.53888888841152
                        ],
                        [
                            -70.05861111131372,
                            46.418055555619446
                        ],
                        [
                            -70.20833333381626,
                            46.30833333321681
                        ],
                        [
                            -70.25000000069946,
                            45.9083333331169
                        ],
                        [
                            -70.50277777847168,
                            45.686111110939066
                        ],
                        [
                            -71.00000000009987,
                            45.31666666643355
                        ],
                        [
                            -71.33277777807194,
                            45.28333333256728
                        ],
                        [
                            -71.45000000009986,
                            45.06666666603388
                        ],
                        [
                            -70.98888888881112,
                            44.77888888851146
                        ],
                        [
                            -70.92888888911091,
                            44.58111111098901
                        ],
                        [
                            -70.86611111113882,
                            44.33333333266722
                        ],
                        [
                            -70.81666666653342,
                            44.16666666603385
                        ],
                        [
                            -70.25000000069946,
                            44.199999999900115
                        ],
                        [
                            -70.08333333316676,
                            44.033333333266796
                        ],
                        [
                            -69.50000000039967,
                            43.833333332767154
                        ],
                        [
                            -69.50000000039967,
                            43.55833333331674
                        ],
                        [
                            -69.50000000039967,
                            43.10833333331675
                        ],
                        [
                            -68.72500000014986,
                            43.383333332767165
                        ],
                        [
                            -66.99999999999994,
                            43.79999999980021
                        ],
                        [
                            -66.99999999999994,
                            44.34166666658342
                        ],
                        [
                            -66.99999999999994,
                            44.4999999993006
                        ],
                        [
                            -66.99999999999994,
                            44.93333333326677
                        ],
                        [
                            -67.33277777797201,
                            45.249999999600334
                        ],
                        [
                            -67.75777777802199,
                            45.62499999975023
                        ],
                        [
                            -67.80638888923579,
                            45.78333333336667
                        ],
                        [
                            -67.58277777837174,
                            45.82499999935055
                        ],
                        [
                            -67.21611111123877,
                            46.14305555526971
                        ],
                        [
                            -67.21611111123877,
                            46.19999999950045
                        ],
                        [
                            -67.29944444500507,
                            46.53611111103902
                        ],
                        [
                            -67.2549999998501,
                            46.56944444400597
                        ],
                        [
                            -67.13277777837175,
                            46.69166666638358
                        ],
                        [
                            -67.0577777785216,
                            46.82777777742257
                        ],
                        [
                            -67.04111111158846,
                            46.98055555539463
                        ],
                        [
                            -67.08277777847167,
                            47.13333333336669
                        ],
                        [
                            -67.17444444435557,
                            47.26944444440562
                        ],
                        [
                            -67.33277777797201,
                            47.386111111138916
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 134,
                "artcc": "zbw",
                "sector": "15",
                "sectype": "high",
                "Shape_Length": 15.902101532721133,
                "Shape_Area": 12.31346034051166
            }
        },
        {
            "type": "Feature",
            "id": 136,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -69.50000000039967,
                            43.10833333331675
                        ],
                        [
                            -69.50000000039967,
                            43.55833333331674
                        ],
                        [
                            -69.50000000039967,
                            43.833333332767154
                        ],
                        [
                            -70.08333333316676,
                            44.033333333266796
                        ],
                        [
                            -70.25000000069946,
                            44.199999999900115
                        ],
                        [
                            -70.81666666653342,
                            44.16666666603385
                        ],
                        [
                            -71.00000000009987,
                            44.04999999930055
                        ],
                        [
                            -71.56277777797203,
                            43.805555555444585
                        ],
                        [
                            -71.41666666713292,
                            43.6166666662337
                        ],
                        [
                            -71.37861111101392,
                            42.993055555269734
                        ],
                        [
                            -71.11111111118879,
                            42.85833333291703
                        ],
                        [
                            -71.04500000054952,
                            42.79138888888616
                        ],
                        [
                            -71.18250000027473,
                            42.56805555521976
                        ],
                        [
                            -71.48333333396613,
                            42.499999999700265
                        ],
                        [
                            -71.54166666688315,
                            42.42777777722267
                        ],
                        [
                            -71.41777777772222,
                            42.43055555549455
                        ],
                        [
                            -70.83333333346656,
                            42.441666666783306
                        ],
                        [
                            -70.62333333316673,
                            42.343333332867076
                        ],
                        [
                            -70.35555555614394,
                            42.184444443955954
                        ],
                        [
                            -70.31611111133867,
                            42.1799999998002
                        ],
                        [
                            -69.83055555629386,
                            42.12222222217787
                        ],
                        [
                            -69.50000000039967,
                            42.08333333266722
                        ],
                        [
                            -69.50000000039967,
                            43.10833333331675
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 136,
                "artcc": "zbw",
                "sector": "16",
                "sectype": "high",
                "Shape_Length": 8.03483856171195,
                "Shape_Area": 3.2928915502739318
            }
        },
        {
            "type": "Feature",
            "id": 138,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -69.50000000039967,
                            43.10833333331675
                        ],
                        [
                            -70.59999999999997,
                            42.64444444465545
                        ],
                        [
                            -70.40833333341658,
                            41.976388888536405
                        ],
                        [
                            -69.50000000039967,
                            41.64999999960037
                        ],
                        [
                            -69.50000000039967,
                            41.16666666663343
                        ],
                        [
                            -69.73333333296688,
                            41.01388888866131
                        ],
                        [
                            -69.9322222219779,
                            41.00000000000006
                        ],
                        [
                            -69.76694444403086,
                            40.89888888871127
                        ],
                        [
                            -69.68416666645845,
                            40.84638888863634
                        ],
                        [
                            -69.34166666678323,
                            40.925000000149964
                        ],
                        [
                            -68.99999999960028,
                            41.00000000000006
                        ],
                        [
                            -66.99999999999994,
                            41.61666666663342
                        ],
                        [
                            -66.99999999999994,
                            41.86666666703309
                        ],
                        [
                            -66.99999999999994,
                            42.54166666658347
                        ],
                        [
                            -66.99999999999994,
                            43.79999999980021
                        ],
                        [
                            -68.72500000014986,
                            43.383333333666485
                        ],
                        [
                            -69.50000000039967,
                            43.10833333331675
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 138,
                "artcc": "zbw",
                "sector": "17",
                "sectype": "high",
                "Shape_Length": 11.681802314353549,
                "Shape_Area": 6.761998650981238
            }
        },
        {
            "type": "Feature",
            "id": 140,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -71.33527777824685,
                            40.44138888818668
                        ],
                        [
                            -71.31111111168843,
                            40.44777777722271
                        ],
                        [
                            -70.87944444450545,
                            40.57666666673333
                        ],
                        [
                            -69.68416666735777,
                            40.84638888863634
                        ],
                        [
                            -69.76694444493017,
                            40.89888888871127
                        ],
                        [
                            -69.93222222287727,
                            41.00000000000006
                        ],
                        [
                            -69.7333333338662,
                            41.01388888866131
                        ],
                        [
                            -69.50000000039967,
                            41.16666666663343
                        ],
                        [
                            -69.50000000039967,
                            41.64999999960037
                        ],
                        [
                            -70.40833333341658,
                            41.976388888536405
                        ],
                        [
                            -70.64444444425567,
                            42.05694444403093
                        ],
                        [
                            -71.19527777834674,
                            41.65305555506984
                        ],
                        [
                            -71.34000000049957,
                            41.452499999275574
                        ],
                        [
                            -71.75833333341654,
                            41.19999999960038
                        ],
                        [
                            -71.72361111176338,
                            41.113888888461474
                        ],
                        [
                            -71.70000000049959,
                            41.055555555544515
                        ],
                        [
                            -71.57555555604404,
                            40.83583333264227
                        ],
                        [
                            -71.49999999999994,
                            40.68333333276712
                        ],
                        [
                            -71.49999999999994,
                            40.38972222150335
                        ],
                        [
                            -71.33527777824685,
                            40.44138888818668
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 140,
                "artcc": "zbw",
                "sector": "18",
                "sectype": "high",
                "Shape_Length": 6.6325991420474715,
                "Shape_Area": 2.307324922191915
            }
        },
        {
            "type": "Feature",
            "id": 142,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -72.97083333369136,
                            40.90833333321683
                        ],
                        [
                            -72.93333333376631,
                            40.927777777522465
                        ],
                        [
                            -72.7500000001998,
                            41.112499999775196
                        ],
                        [
                            -72.65833333341658,
                            41.19999999960038
                        ],
                        [
                            -72.54166666668328,
                            41.299999999400484
                        ],
                        [
                            -72.51666666673327,
                            41.46499999925061
                        ],
                        [
                            -72.51666666673327,
                            41.54999999980021
                        ],
                        [
                            -72.65000000039964,
                            41.56666666673334
                        ],
                        [
                            -72.78333333316675,
                            41.82083333319184
                        ],
                        [
                            -73.22611111163843,
                            41.63972222170321
                        ],
                        [
                            -73.27527777814691,
                            41.58805555501988
                        ],
                        [
                            -73.29999999999995,
                            41.58749999972525
                        ],
                        [
                            -73.29999999999995,
                            41.56249999977524
                        ],
                        [
                            -73.33333333386622,
                            41.54166666678327
                        ],
                        [
                            -73.58333333336662,
                            41.345833333241785
                        ],
                        [
                            -73.69166666708298,
                            41.322222221977995
                        ],
                        [
                            -73.78250000047461,
                            41.299999999400484
                        ],
                        [
                            -73.8958333336414,
                            41.25833333341666
                        ],
                        [
                            -73.87833333331662,
                            41.00000000000006
                        ],
                        [
                            -73.877777778022,
                            40.96805555571939
                        ],
                        [
                            -73.86972222220277,
                            40.87222222197801
                        ],
                        [
                            -73.82472222265244,
                            40.85083333279215
                        ],
                        [
                            -73.7541666669581,
                            40.851388888986094
                        ],
                        [
                            -73.6416666671829,
                            40.853333332967
                        ],
                        [
                            -73.37777777812192,
                            40.85833333331675
                        ],
                        [
                            -73.36138888928576,
                            40.84750000012497
                        ],
                        [
                            -73.29166666698308,
                            40.80138888818669
                        ],
                        [
                            -73.44166666668326,
                            40.58888888861139
                        ],
                        [
                            -73.29583333394118,
                            40.65972222150339
                        ],
                        [
                            -73.13805555561936,
                            40.776666666333654
                        ],
                        [
                            -72.97083333369136,
                            40.90833333321683
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 142,
                "artcc": "zbw",
                "sector": "19",
                "sectype": "high",
                "Shape_Length": 4.356185420744116,
                "Shape_Area": 0.8710544754593004
            }
        },
        {
            "type": "Feature",
            "id": 143,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -73.09166666738275,
                            42.08333333266722
                        ],
                        [
                            -73.37777777812192,
                            42.05277777707283
                        ],
                        [
                            -73.8986111110139,
                            42.01750000012498
                        ],
                        [
                            -73.92500000054957,
                            42.01666666673333
                        ],
                        [
                            -74.04305555596909,
                            41.999999999800195
                        ],
                        [
                            -74.31166666728285,
                            41.999999999800195
                        ],
                        [
                            -74.38305555546947,
                            41.99972222170322
                        ],
                        [
                            -74.44583333344156,
                            41.999999999800195
                        ],
                        [
                            -74.76666666673327,
                            41.733333333366716
                        ],
                        [
                            -74.65833333391618,
                            41.6249999996503
                        ],
                        [
                            -74.43333333346652,
                            41.527777777222695
                        ],
                        [
                            -74.34444444495512,
                            41.48333333296699
                        ],
                        [
                            -74.18333333396617,
                            41.400000000099965
                        ],
                        [
                            -74.05833333331663,
                            41.33333333326675
                        ],
                        [
                            -74.02500000034968,
                            41.33333333326675
                        ],
                        [
                            -73.8958333336414,
                            41.25833333341666
                        ],
                        [
                            -73.87833333331662,
                            41.00000000000006
                        ],
                        [
                            -73.5513888890859,
                            41.14583333274214
                        ],
                        [
                            -73.49166666658334,
                            41.194444443956
                        ],
                        [
                            -73.00000000059953,
                            41.3691666664086
                        ],
                        [
                            -72.95000000069945,
                            41.56111111108896
                        ],
                        [
                            -72.78333333316675,
                            41.82083333319184
                        ],
                        [
                            -73.09166666738275,
                            42.08333333266722
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 143,
                "artcc": "zbw",
                "sector": "20",
                "sectype": "high",
                "Shape_Length": 4.906404152676968,
                "Shape_Area": 1.237219791513695
            }
        },
        {
            "type": "Feature",
            "id": 145,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -73.00000000059953,
                            42.58333333256729
                        ],
                        [
                            -73.00083333399112,
                            42.63333333336669
                        ],
                        [
                            -73.01666666663334,
                            43.022222222177845
                        ],
                        [
                            -72.85833333391616,
                            43.10833333331675
                        ],
                        [
                            -73.10583333324172,
                            43.211388888586384
                        ],
                        [
                            -73.24055555559437,
                            43.26666666603387
                        ],
                        [
                            -73.4191666669081,
                            43.337777777922156
                        ],
                        [
                            -73.6152777776473,
                            43.41166666628368
                        ],
                        [
                            -73.82222222247754,
                            43.3711111108891
                        ],
                        [
                            -74.03527777824684,
                            43.32638888853643
                        ],
                        [
                            -74.40000000049957,
                            43.26666666603387
                        ],
                        [
                            -74.51666666723287,
                            42.999999999600334
                        ],
                        [
                            -74.52777777852162,
                            42.93333333276712
                        ],
                        [
                            -74.56250000017485,
                            42.67499999935052
                        ],
                        [
                            -74.58333333316676,
                            42.54166666658347
                        ],
                        [
                            -74.44583333344156,
                            42.36111111038946
                        ],
                        [
                            -74.3800785962502,
                            42.38440707492663
                        ],
                        [
                            -74.3255555559441,
                            42.34222222227777
                        ],
                        [
                            -74.17222222267742,
                            42.22222222197803
                        ],
                        [
                            -73.8986111110139,
                            42.01750000012498
                        ],
                        [
                            -73.37777777812192,
                            42.05277777707283
                        ],
                        [
                            -73.09166666738275,
                            42.08333333266722
                        ],
                        [
                            -72.77500000014987,
                            42.27083333319183
                        ],
                        [
                            -72.65805555621893,
                            42.353888888861206
                        ],
                        [
                            -73.00000000059953,
                            42.58333333256729
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 145,
                "artcc": "zbw",
                "sector": "22",
                "sectype": "high",
                "Shape_Length": 5.6224947651643635,
                "Shape_Area": 1.8802603583277875
            }
        },
        {
            "type": "Feature",
            "id": 147,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -74.31166666728285,
                            41.999999999800195
                        ],
                        [
                            -74.04305555596909,
                            41.999999999800195
                        ],
                        [
                            -73.92500000054957,
                            42.01666666673333
                        ],
                        [
                            -73.8986111110139,
                            42.01750000012498
                        ],
                        [
                            -74.17222222267742,
                            42.22222222197803
                        ],
                        [
                            -74.3255555559441,
                            42.34222222227777
                        ],
                        [
                            -74.58333333316676,
                            42.54166666658347
                        ],
                        [
                            -74.56250000017485,
                            42.67499999935052
                        ],
                        [
                            -74.52777777852162,
                            42.93333333276712
                        ],
                        [
                            -76.4347222226524,
                            43.007777777322644
                        ],
                        [
                            -76.7333333333666,
                            43.01666666653347
                        ],
                        [
                            -76.66666666653339,
                            42.883333332867096
                        ],
                        [
                            -76.68916666720787,
                            42.768611111014025
                        ],
                        [
                            -76.69722222212783,
                            42.727777777522476
                        ],
                        [
                            -76.80277777847164,
                            42.60833333341668
                        ],
                        [
                            -76.23333333346653,
                            42.36666666603384
                        ],
                        [
                            -76.01666666693308,
                            42.283333333166865
                        ],
                        [
                            -75.61666666683317,
                            42.104166666558456
                        ],
                        [
                            -75.34166666738275,
                            41.98888888851144
                        ],
                        [
                            -75.14777777782217,
                            41.90722222152834
                        ],
                        [
                            -75.05833333401614,
                            41.869444444405644
                        ],
                        [
                            -74.90833333341658,
                            41.80138888888615
                        ],
                        [
                            -74.80833333361642,
                            41.75833333331673
                        ],
                        [
                            -74.76666666673327,
                            41.733333333366716
                        ],
                        [
                            -74.44583333344156,
                            41.999999999800195
                        ],
                        [
                            -74.38305555546947,
                            41.99972222170322
                        ],
                        [
                            -74.31166666728285,
                            41.999999999800195
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 147,
                "artcc": "zbw",
                "sector": "24",
                "sectype": "high",
                "Shape_Length": 7.114583744048496,
                "Shape_Area": 2.0146451770890823
            }
        },
        {
            "type": "Feature",
            "id": 148,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -72.21388888906097,
                            41.17499999965031
                        ],
                        [
                            -72.1000000005995,
                            41.27499999945047
                        ],
                        [
                            -72.65833333341658,
                            41.19999999960038
                        ],
                        [
                            -72.7500000001998,
                            41.112499999775196
                        ],
                        [
                            -72.93333333376631,
                            40.927777777522465
                        ],
                        [
                            -73.29166666698308,
                            40.80138888818669
                        ],
                        [
                            -73.44166666668326,
                            40.58888888861139
                        ],
                        [
                            -73.5000000004996,
                            40.508333333116866
                        ],
                        [
                            -73.63611111153853,
                            40.331944443881014
                        ],
                        [
                            -73.69861111141364,
                            40.23888888841151
                        ],
                        [
                            -73.70888888931074,
                            40.14861111121388
                        ],
                        [
                            -73.716666667033,
                            40.068611111014036
                        ],
                        [
                            -73.72916666700803,
                            39.933333333366704
                        ],
                        [
                            -73.28333333396614,
                            39.73333333286706
                        ],
                        [
                            -73.16666666723285,
                            39.68333333296698
                        ],
                        [
                            -73.15000000029971,
                            39.73333333286706
                        ],
                        [
                            -72.88361111106389,
                            39.73333333286706
                        ],
                        [
                            -72.86527777824682,
                            39.85222222167823
                        ],
                        [
                            -72.69722222292722,
                            39.9999999993006
                        ],
                        [
                            -72.50000000069946,
                            40.07222222177819
                        ],
                        [
                            -71.49999999999994,
                            40.38972222150335
                        ],
                        [
                            -71.49999999999994,
                            40.68333333276712
                        ],
                        [
                            -71.57555555604404,
                            40.83583333264227
                        ],
                        [
                            -71.70000000049959,
                            41.055555555544515
                        ],
                        [
                            -71.75833333341654,
                            41.19999999960038
                        ],
                        [
                            -71.94999999999999,
                            41.12777777712279
                        ],
                        [
                            -72.21388888906097,
                            41.17499999965031
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 148,
                "artcc": "zbw",
                "sector": "31",
                "sectype": "high",
                "Shape_Length": 6.3663788776524655,
                "Shape_Area": 2.0985286652695416
            }
        },
        {
            "type": "Feature",
            "id": 150,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -71.12111111098892,
                            42.053055555169806
                        ],
                        [
                            -71.27677883067844,
                            41.99245385775936
                        ],
                        [
                            -71.27694444443051,
                            41.992499999275594
                        ],
                        [
                            -71.3080555562189,
                            41.98027777739759
                        ],
                        [
                            -71.35555555594414,
                            41.96666666683325
                        ],
                        [
                            -71.66250000057454,
                            41.85833333311689
                        ],
                        [
                            -72.00277777817189,
                            41.77499999935054
                        ],
                        [
                            -72.45833333381626,
                            41.56666666673334
                        ],
                        [
                            -72.51666666673327,
                            41.54999999980021
                        ],
                        [
                            -72.51666666673327,
                            41.46499999925061
                        ],
                        [
                            -72.54166666668328,
                            41.299999999400484
                        ],
                        [
                            -72.65833333341658,
                            41.19999999960038
                        ],
                        [
                            -72.1000000005995,
                            41.27499999945047
                        ],
                        [
                            -71.7666666673328,
                            41.28333333336667
                        ],
                        [
                            -71.35000000029976,
                            41.44166666608385
                        ],
                        [
                            -71.34000000049957,
                            41.452499999275574
                        ],
                        [
                            -71.19527777834674,
                            41.65305555506984
                        ],
                        [
                            -71.16250000067447,
                            41.69583333344161
                        ],
                        [
                            -71.09722222252753,
                            41.77611111083917
                        ],
                        [
                            -70.92500000024978,
                            41.894444444355656
                        ],
                        [
                            -71.12111111098892,
                            42.053055555169806
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 150,
                "artcc": "zbw",
                "sector": "34",
                "sectype": "high",
                "Shape_Length": 4.116160791270506,
                "Shape_Area": 0.6869715601089568
            }
        },
        {
            "type": "Feature",
            "id": 151,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -71.48333333396613,
                            42.499999999700265
                        ],
                        [
                            -71.40000000019978,
                            42.708333333216785
                        ],
                        [
                            -71.58333333376629,
                            42.71666666623372
                        ],
                        [
                            -71.89166666708297,
                            42.733333333166854
                        ],
                        [
                            -71.88333333316677,
                            42.86666666683328
                        ],
                        [
                            -71.91444444495511,
                            42.94944444440563
                        ],
                        [
                            -71.96666666693307,
                            43.07499999945048
                        ],
                        [
                            -72.85833333391616,
                            43.10833333331675
                        ],
                        [
                            -73.01666666663334,
                            43.022222222177845
                        ],
                        [
                            -73.00083333399112,
                            42.63333333336669
                        ],
                        [
                            -73.00000000059953,
                            42.58333333256729
                        ],
                        [
                            -72.65805555621893,
                            42.353888888861206
                        ],
                        [
                            -72.25000000029974,
                            42.37083333299199
                        ],
                        [
                            -72.00277777817189,
                            42.40555555554448
                        ],
                        [
                            -71.8333333332667,
                            42.449999999800184
                        ],
                        [
                            -71.73333333346653,
                            42.43333333286705
                        ],
                        [
                            -71.54166666688315,
                            42.42777777722267
                        ],
                        [
                            -71.48333333396613,
                            42.499999999700265
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 151,
                "artcc": "zbw",
                "sector": "36",
                "sectype": "high",
                "Shape_Length": 4.21742381611146,
                "Shape_Area": 0.8764149306629758
            }
        },
        {
            "type": "Feature",
            "id": 153,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -70.62250000067445,
                            42.535277777547435
                        ],
                        [
                            -71.04500000054952,
                            42.79138888888616
                        ],
                        [
                            -71.11111111118879,
                            42.85833333291703
                        ],
                        [
                            -71.37861111101392,
                            42.993055555269734
                        ],
                        [
                            -71.41666666713292,
                            43.6166666662337
                        ],
                        [
                            -71.51629667235352,
                            43.74546591312145
                        ],
                        [
                            -71.3833333332667,
                            43.75833333291706
                        ],
                        [
                            -71.56277777797203,
                            43.805555555444585
                        ],
                        [
                            -71.82777777852164,
                            43.86666666663342
                        ],
                        [
                            -71.99999999990001,
                            43.99999999940053
                        ],
                        [
                            -72.33333333316676,
                            44.033333333266796
                        ],
                        [
                            -72.44583333384122,
                            43.962499999475426
                        ],
                        [
                            -72.58333333356643,
                            43.68750000002501
                        ],
                        [
                            -72.81666666703302,
                            43.55833333331674
                        ],
                        [
                            -72.84166666698303,
                            43.45555555524476
                        ],
                        [
                            -73.0083333336164,
                            43.36666666673335
                        ],
                        [
                            -73.10583333324172,
                            43.211388888586384
                        ],
                        [
                            -72.85833333391616,
                            43.10833333331675
                        ],
                        [
                            -71.96666666693307,
                            43.07499999945048
                        ],
                        [
                            -71.91444444495511,
                            42.94944444440563
                        ],
                        [
                            -71.88333333316677,
                            42.86666666683328
                        ],
                        [
                            -71.89166666708297,
                            42.733333333166854
                        ],
                        [
                            -71.58333333376629,
                            42.71666666623372
                        ],
                        [
                            -71.40000000019978,
                            42.708333333216785
                        ],
                        [
                            -71.53333333386621,
                            42.599999999500426
                        ],
                        [
                            -71.8333333332667,
                            42.449999999800184
                        ],
                        [
                            -71.73333333346653,
                            42.43333333286705
                        ],
                        [
                            -71.54166666688315,
                            42.42777777722267
                        ],
                        [
                            -71.41777777772222,
                            42.43055555549455
                        ],
                        [
                            -70.83333333346656,
                            42.441666666783306
                        ],
                        [
                            -70.62333333316673,
                            42.343333332867076
                        ],
                        [
                            -70.62250000067445,
                            42.535277777547435
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 153,
                "artcc": "zbw",
                "sector": "37",
                "sectype": "high",
                "Shape_Length": 7.947607416109062,
                "Shape_Area": 1.669164970474758
            }
        },
        {
            "type": "Feature",
            "id": 155,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -74.31166666728285,
                            41.999999999800195
                        ],
                        [
                            -74.04305555596909,
                            41.999999999800195
                        ],
                        [
                            -73.92500000054957,
                            42.01666666673333
                        ],
                        [
                            -73.8986111110139,
                            42.01750000012498
                        ],
                        [
                            -73.37777777812192,
                            42.05277777707283
                        ],
                        [
                            -73.09166666738275,
                            42.08333333266722
                        ],
                        [
                            -72.77500000014987,
                            42.27083333319183
                        ],
                        [
                            -72.65805555621893,
                            42.353888888861206
                        ],
                        [
                            -72.25000000029974,
                            42.37083333299199
                        ],
                        [
                            -71.8333333332667,
                            42.449999999800184
                        ],
                        [
                            -71.53333333386621,
                            42.599999999500426
                        ],
                        [
                            -72.00277777817189,
                            42.740833332792135
                        ],
                        [
                            -72.03333333376628,
                            42.75000000009999
                        ],
                        [
                            -73.33333333386622,
                            42.883333332867096
                        ],
                        [
                            -74.52777777852162,
                            42.93333333276712
                        ],
                        [
                            -74.56250000017485,
                            42.67499999935052
                        ],
                        [
                            -74.58333333316676,
                            42.54166666658347
                        ],
                        [
                            -74.3255555559441,
                            42.34222222227777
                        ],
                        [
                            -74.38305555546947,
                            41.99972222170322
                        ],
                        [
                            -74.31166666728285,
                            41.999999999800195
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 155,
                "artcc": "zbw",
                "sector": "38",
                "sectype": "high",
                "Shape_Length": 7.067934351154357,
                "Shape_Area": 1.8124505396734172
            }
        },
        {
            "type": "Feature",
            "id": 156,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -71.18333333366638,
                            43.71666666603386
                        ],
                        [
                            -71.3833333332667,
                            43.75833333291706
                        ],
                        [
                            -71.56277777797203,
                            43.805555555444585
                        ],
                        [
                            -71.82777777852164,
                            43.86666666663342
                        ],
                        [
                            -71.99999999990001,
                            43.99999999940053
                        ],
                        [
                            -72.58333333356643,
                            43.68750000002501
                        ],
                        [
                            -72.81666666703302,
                            43.55833333331674
                        ],
                        [
                            -73.20416666715795,
                            43.51249999947544
                        ],
                        [
                            -73.48750000052456,
                            43.52499999945047
                        ],
                        [
                            -73.74999999999994,
                            43.53611111073923
                        ],
                        [
                            -74.23888888951058,
                            43.56111111068924
                        ],
                        [
                            -74.40000000049957,
                            43.26666666603387
                        ],
                        [
                            -74.51666666723287,
                            42.999999999600334
                        ],
                        [
                            -74.52777777852162,
                            42.93333333276712
                        ],
                        [
                            -73.33333333386622,
                            42.883333332867096
                        ],
                        [
                            -72.03333333376628,
                            42.75000000009999
                        ],
                        [
                            -72.00277777817189,
                            42.740833332792135
                        ],
                        [
                            -71.53333333386621,
                            42.599999999500426
                        ],
                        [
                            -71.40000000019978,
                            42.708333333216785
                        ],
                        [
                            -71.15833333371631,
                            42.94999999970025
                        ],
                        [
                            -71.41055555619391,
                            43.086666666033864
                        ],
                        [
                            -71.10833333381629,
                            43.39166666668336
                        ],
                        [
                            -71.18333333366638,
                            43.71666666603386
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 156,
                "artcc": "zbw",
                "sector": "39",
                "sectype": "high",
                "Shape_Length": 8.51611749524916,
                "Shape_Area": 2.7230866127035886
            }
        },
        {
            "type": "Feature",
            "id": 157,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -71.75833333341654,
                            41.19999999960038
                        ],
                        [
                            -71.34000000049957,
                            41.452499999275574
                        ],
                        [
                            -70.40833333341658,
                            41.976388888536405
                        ],
                        [
                            -70.59999999999997,
                            42.64444444375613
                        ],
                        [
                            -71.01500000024976,
                            42.87222222157834
                        ],
                        [
                            -71.15833333371631,
                            42.94999999970025
                        ],
                        [
                            -71.41055555619391,
                            43.086666666033864
                        ],
                        [
                            -72.00277777817189,
                            42.740833332792135
                        ],
                        [
                            -72.65805555621893,
                            42.353888888861206
                        ],
                        [
                            -72.77500000014987,
                            42.27083333319183
                        ],
                        [
                            -73.09166666738275,
                            42.08333333266722
                        ],
                        [
                            -72.78333333316675,
                            41.82083333319184
                        ],
                        [
                            -72.95000000069945,
                            41.56111111108896
                        ],
                        [
                            -73.00000000059953,
                            41.3691666664086
                        ],
                        [
                            -72.84999999999997,
                            41.28888888901105
                        ],
                        [
                            -72.65833333341658,
                            41.19999999960038
                        ],
                        [
                            -72.21388888906097,
                            41.17499999965031
                        ],
                        [
                            -71.94999999999999,
                            41.12777777712279
                        ],
                        [
                            -71.75833333341654,
                            41.19999999960038
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 157,
                "artcc": "zbw",
                "sector": "46",
                "sectype": "high",
                "Shape_Length": 7.345438001907289,
                "Shape_Area": 3.2292523924388807
            }
        },
        {
            "type": "Feature",
            "id": 158,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -71.73333333346653,
                            42.43333333286705
                        ],
                        [
                            -71.8333333332667,
                            42.449999999800184
                        ],
                        [
                            -72.00277777817189,
                            42.40555555554448
                        ],
                        [
                            -72.25000000029974,
                            42.37083333299199
                        ],
                        [
                            -72.65805555621893,
                            42.353888888861206
                        ],
                        [
                            -72.77500000014987,
                            42.27083333319183
                        ],
                        [
                            -73.09166666738275,
                            42.08333333266722
                        ],
                        [
                            -72.78333333316675,
                            41.82083333319184
                        ],
                        [
                            -72.65000000039964,
                            41.56666666673334
                        ],
                        [
                            -72.51666666673327,
                            41.54999999980021
                        ],
                        [
                            -72.45833333381626,
                            41.56666666673334
                        ],
                        [
                            -72.00277777817189,
                            41.77499999935054
                        ],
                        [
                            -71.66250000057454,
                            41.85833333311689
                        ],
                        [
                            -71.35555555594414,
                            41.96666666683325
                        ],
                        [
                            -71.3080555562189,
                            41.98027777739759
                        ],
                        [
                            -71.12111111098892,
                            42.053055555169806
                        ],
                        [
                            -71.03083333379129,
                            42.0888888883116
                        ],
                        [
                            -70.69111111148857,
                            42.142499999875156
                        ],
                        [
                            -70.63500000064948,
                            42.211111110689274
                        ],
                        [
                            -70.62333333316673,
                            42.343333332867076
                        ],
                        [
                            -70.83333333346656,
                            42.441666666783306
                        ],
                        [
                            -71.41777777772222,
                            42.43055555549455
                        ],
                        [
                            -71.54166666688315,
                            42.42777777722267
                        ],
                        [
                            -71.73333333346653,
                            42.43333333286705
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 158,
                "artcc": "zbw",
                "sector": "47",
                "sectype": "high",
                "Shape_Length": 5.5543715406014105,
                "Shape_Area": 1.2448242671388658
            }
        },
        {
            "type": "Feature",
            "id": 159,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -72.21388888906097,
                            41.17499999965031
                        ],
                        [
                            -72.65833333341658,
                            41.19999999960038
                        ],
                        [
                            -72.84999999999997,
                            41.28888888901105
                        ],
                        [
                            -73.00000000059953,
                            41.3691666664086
                        ],
                        [
                            -73.49166666658334,
                            41.194444443956
                        ],
                        [
                            -73.5513888890859,
                            41.14583333274214
                        ],
                        [
                            -73.37777777812192,
                            40.85833333331675
                        ],
                        [
                            -73.36138888928576,
                            40.84750000012497
                        ],
                        [
                            -73.29166666698308,
                            40.80138888818669
                        ],
                        [
                            -73.44166666668326,
                            40.58888888861139
                        ],
                        [
                            -73.5000000004996,
                            40.508333333116866
                        ],
                        [
                            -73.63611111153853,
                            40.331944443881014
                        ],
                        [
                            -73.69861111141364,
                            40.23888888841151
                        ],
                        [
                            -73.70888888931074,
                            40.14861111121388
                        ],
                        [
                            -73.716666667033,
                            40.068611111014036
                        ],
                        [
                            -73.72916666700803,
                            39.933333333366704
                        ],
                        [
                            -73.28333333396614,
                            39.73333333286706
                        ],
                        [
                            -73.16666666723285,
                            39.68333333296698
                        ],
                        [
                            -73.15000000029971,
                            39.73333333286706
                        ],
                        [
                            -72.88361111106389,
                            39.73333333286706
                        ],
                        [
                            -72.86527777824682,
                            39.85222222167823
                        ],
                        [
                            -72.69722222292722,
                            39.9999999993006
                        ],
                        [
                            -72.50000000069946,
                            40.07222222177819
                        ],
                        [
                            -71.49999999999994,
                            40.38972222150335
                        ],
                        [
                            -71.49999999999994,
                            40.68333333276712
                        ],
                        [
                            -71.57555555604404,
                            40.83583333264227
                        ],
                        [
                            -71.70000000049959,
                            41.055555555544515
                        ],
                        [
                            -71.75833333341654,
                            41.19999999960038
                        ],
                        [
                            -71.94999999999999,
                            41.12777777712279
                        ],
                        [
                            -72.21388888906097,
                            41.17499999965031
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 159,
                "artcc": "zbw",
                "sector": "49",
                "sectype": "high",
                "Shape_Length": 6.748956703046605,
                "Shape_Area": 2.3646025465632188
            }
        },
        {
            "type": "Feature",
            "id": 160,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -71.45000000009986,
                            45.06666666603388
                        ],
                        [
                            -71.48333333396613,
                            45.0166666661338
                        ],
                        [
                            -72.42194444448052,
                            44.99416666635864
                        ],
                        [
                            -73.25000000009987,
                            45.00000000009999
                        ],
                        [
                            -73.40833333371631,
                            44.999722222003015
                        ],
                        [
                            -73.71527777834677,
                            44.999166666708334
                        ],
                        [
                            -73.97555555574428,
                            44.99777777712279
                        ],
                        [
                            -74.12166666658334,
                            44.99166666618373
                        ],
                        [
                            -74.19444444435561,
                            44.9763888888362
                        ],
                        [
                            -74.56638888903598,
                            44.849999999500426
                        ],
                        [
                            -74.68333333386624,
                            44.83333333256729
                        ],
                        [
                            -74.76972222220274,
                            44.81333333296698
                        ],
                        [
                            -74.56666666713295,
                            44.60000000000008
                        ],
                        [
                            -74.50000000029974,
                            44.358333332617235
                        ],
                        [
                            -74.50000000029974,
                            44.07499999925062
                        ],
                        [
                            -74.23888888951058,
                            43.56111111068924
                        ],
                        [
                            -74.03527777824684,
                            43.32638888853643
                        ],
                        [
                            -73.82222222247754,
                            43.3711111108891
                        ],
                        [
                            -73.6152777776473,
                            43.41166666628368
                        ],
                        [
                            -73.4191666669081,
                            43.337777777922156
                        ],
                        [
                            -73.24055555559437,
                            43.26666666603387
                        ],
                        [
                            -73.10583333324172,
                            43.211388888586384
                        ],
                        [
                            -73.0083333336164,
                            43.36666666673335
                        ],
                        [
                            -72.84166666698303,
                            43.45555555524476
                        ],
                        [
                            -72.81666666703302,
                            43.55833333331674
                        ],
                        [
                            -72.58333333356643,
                            43.68750000002501
                        ],
                        [
                            -71.99999999990001,
                            43.99999999940053
                        ],
                        [
                            -71.82777777852164,
                            43.86666666663342
                        ],
                        [
                            -71.56277777797203,
                            43.805555555444585
                        ],
                        [
                            -71.3833333332667,
                            43.75833333291706
                        ],
                        [
                            -71.18333333366638,
                            43.71666666603386
                        ],
                        [
                            -71.15833333371631,
                            43.78333333286707
                        ],
                        [
                            -71.00000000009987,
                            44.04999999930055
                        ],
                        [
                            -70.81666666653342,
                            44.16666666603385
                        ],
                        [
                            -70.86611111113882,
                            44.33333333266722
                        ],
                        [
                            -70.92888888911091,
                            44.58111111098901
                        ],
                        [
                            -70.98888888881112,
                            44.77888888851146
                        ],
                        [
                            -71.45000000009986,
                            45.06666666603388
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 160,
                "artcc": "zbw",
                "sector": "52",
                "sectype": "high",
                "Shape_Length": 10.128955214824233,
                "Shape_Area": 4.86741450656795
            }
        },
        {
            "type": "Feature",
            "id": 162,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -71.45000000009986,
                            45.06666666603388
                        ],
                        [
                            -71.48333333396613,
                            45.0166666661338
                        ],
                        [
                            -72.42194444448052,
                            44.99416666635864
                        ],
                        [
                            -73.25000000009987,
                            45.00000000009999
                        ],
                        [
                            -73.40833333371631,
                            44.999722222003015
                        ],
                        [
                            -73.71527777834677,
                            44.999166666708334
                        ],
                        [
                            -73.97555555574428,
                            44.99777777712279
                        ],
                        [
                            -74.12166666658334,
                            44.99166666618373
                        ],
                        [
                            -74.19444444435561,
                            44.9763888888362
                        ],
                        [
                            -74.56638888903598,
                            44.849999999500426
                        ],
                        [
                            -74.68333333386624,
                            44.83333333256729
                        ],
                        [
                            -74.76972222220274,
                            44.81333333296698
                        ],
                        [
                            -74.56666666713295,
                            44.60000000000008
                        ],
                        [
                            -74.50000000029974,
                            44.358333332617235
                        ],
                        [
                            -74.50000000029974,
                            44.07499999925062
                        ],
                        [
                            -74.23888888951058,
                            43.56111111068924
                        ],
                        [
                            -73.74999999999994,
                            43.53611111073923
                        ],
                        [
                            -73.48750000052456,
                            43.52499999945047
                        ],
                        [
                            -73.20416666715795,
                            43.51249999947544
                        ],
                        [
                            -72.81666666703302,
                            43.55833333331674
                        ],
                        [
                            -72.58333333356643,
                            43.68750000002501
                        ],
                        [
                            -71.99999999990001,
                            43.99999999940053
                        ],
                        [
                            -71.82777777852164,
                            43.86666666663342
                        ],
                        [
                            -71.56277777797203,
                            43.805555555444585
                        ],
                        [
                            -71.3833333332667,
                            43.75833333291706
                        ],
                        [
                            -71.18333333366638,
                            43.71666666603386
                        ],
                        [
                            -71.15833333371631,
                            43.78333333286707
                        ],
                        [
                            -71.00000000009987,
                            44.04999999930055
                        ],
                        [
                            -70.81666666653342,
                            44.16666666603385
                        ],
                        [
                            -70.86611111113882,
                            44.33333333266722
                        ],
                        [
                            -70.92888888911091,
                            44.58111111098901
                        ],
                        [
                            -70.98888888881112,
                            44.77888888851146
                        ],
                        [
                            -71.45000000009986,
                            45.06666666603388
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 162,
                "artcc": "zbw",
                "sector": "53",
                "sectype": "high",
                "Shape_Length": 9.790268847044509,
                "Shape_Area": 4.6201741132049134
            }
        },
        {
            "type": "Feature",
            "id": 176,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -80.00000000009987,
                            38.4833333326672
                        ],
                        [
                            -79.58194444438055,
                            38.5580555553197
                        ],
                        [
                            -79.4427777778721,
                            38.58277777717274
                        ],
                        [
                            -79.33333333356643,
                            38.599999999400495
                        ],
                        [
                            -78.56666666723288,
                            38.75000000000006
                        ],
                        [
                            -78.3750000006495,
                            38.765555555444564
                        ],
                        [
                            -78.3750000006495,
                            39.11111111058932
                        ],
                        [
                            -78.3750000006495,
                            39.26333333326676
                        ],
                        [
                            -78.47305555556937,
                            39.48249999997506
                        ],
                        [
                            -78.52222222207786,
                            39.591666666183755
                        ],
                        [
                            -78.55833333331663,
                            39.6716666663836
                        ],
                        [
                            -78.62472222205287,
                            39.64777777792216
                        ],
                        [
                            -79.01388888896099,
                            39.533333333266796
                        ],
                        [
                            -79.23333333376632,
                            39.46666666643358
                        ],
                        [
                            -79.3180555562189,
                            39.44111111118889
                        ],
                        [
                            -79.54305555576923,
                            39.3708333326922
                        ],
                        [
                            -79.6983333339162,
                            39.283611110964046
                        ],
                        [
                            -79.89444444465533,
                            39.173611110464435
                        ],
                        [
                            -80.40000000019984,
                            39.16666666613378
                        ],
                        [
                            -80.51055555599407,
                            38.90361111046445
                        ],
                        [
                            -80.56666666683316,
                            38.76666666603387
                        ],
                        [
                            -80.61250000067446,
                            38.569444443806105
                        ],
                        [
                            -80.64472222215278,
                            38.38249999947544
                        ],
                        [
                            -80.00000000009987,
                            38.4833333326672
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 176,
                "artcc": "zdc",
                "sector": "03",
                "sectype": "high",
                "Shape_Length": 6.01209065290185,
                "Shape_Area": 1.709203588131599
            }
        },
        {
            "type": "Feature",
            "id": 177,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -77.84027777819688,
                            39.855555555244734
                        ],
                        [
                            -77.93333333366638,
                            39.84999999960036
                        ],
                        [
                            -78.20000000009986,
                            39.77222222147839
                        ],
                        [
                            -78.44444444485521,
                            39.70555555554449
                        ],
                        [
                            -78.55833333331663,
                            39.6716666663836
                        ],
                        [
                            -78.52222222207786,
                            39.591666666183755
                        ],
                        [
                            -78.47305555556937,
                            39.48249999997506
                        ],
                        [
                            -78.3750000006495,
                            39.26333333326676
                        ],
                        [
                            -78.3750000006495,
                            39.11111111058932
                        ],
                        [
                            -78.3750000006495,
                            38.765555555444564
                        ],
                        [
                            -78.33166666698304,
                            38.58805555561946
                        ],
                        [
                            -77.7997222228023,
                            38.59027777769734
                        ],
                        [
                            -77.32083333399112,
                            38.64305555496992
                        ],
                        [
                            -77.0375000006245,
                            38.8583333328171
                        ],
                        [
                            -76.34722222282727,
                            39.39166666658343
                        ],
                        [
                            -76.72138888958557,
                            39.38694444433071
                        ],
                        [
                            -76.81527777844667,
                            39.38555555564443
                        ],
                        [
                            -76.85555555574427,
                            39.384999999450486
                        ],
                        [
                            -76.94277777837175,
                            39.406666666733315
                        ],
                        [
                            -77.10555555614394,
                            39.446388888736294
                        ],
                        [
                            -77.12333333366638,
                            39.4652777777473
                        ],
                        [
                            -77.18361111146356,
                            39.52083333329176
                        ],
                        [
                            -77.20666666653341,
                            39.54805555531965
                        ],
                        [
                            -77.2269444442307,
                            39.56888888831162
                        ],
                        [
                            -77.24027777849665,
                            39.581111111088944
                        ],
                        [
                            -77.3572222224276,
                            39.69805555501989
                        ],
                        [
                            -77.35694444433062,
                            39.722222221578306
                        ],
                        [
                            -77.35694444433062,
                            39.74638888903604
                        ],
                        [
                            -77.35666666713297,
                            39.78166666598389
                        ],
                        [
                            -77.3974944446129,
                            39.78018888830661
                        ],
                        [
                            -77.61722222262745,
                            39.77222222147839
                        ],
                        [
                            -77.84027777819688,
                            39.855555555244734
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 177,
                "artcc": "zdc",
                "sector": "04",
                "sectype": "high",
                "Shape_Length": 5.8175686132624485,
                "Shape_Area": 1.6982885089704158
            }
        },
        {
            "type": "Feature",
            "id": 179,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -77.84027777819688,
                            39.855555555244734
                        ],
                        [
                            -77.93333333366638,
                            39.84999999960036
                        ],
                        [
                            -78.20000000009986,
                            39.77222222147839
                        ],
                        [
                            -78.44444444485521,
                            39.70555555554449
                        ],
                        [
                            -78.55833333331663,
                            39.6716666663836
                        ],
                        [
                            -78.48500000024978,
                            39.64666666643359
                        ],
                        [
                            -78.03666666703305,
                            39.491666666383594
                        ],
                        [
                            -77.95500000004995,
                            39.39583333264221
                        ],
                        [
                            -77.9583333336164,
                            39.333333332767154
                        ],
                        [
                            -78.17500000014985,
                            39.09638888853641
                        ],
                        [
                            -77.88833333321674,
                            39.073611110664274
                        ],
                        [
                            -77.67583333364138,
                            39.11611111093907
                        ],
                        [
                            -77.50000000059953,
                            39.148333333316714
                        ],
                        [
                            -77.12333333366638,
                            39.4652777777473
                        ],
                        [
                            -77.18361111146356,
                            39.52083333329176
                        ],
                        [
                            -77.20666666653341,
                            39.54805555531965
                        ],
                        [
                            -77.2269444442307,
                            39.56888888831162
                        ],
                        [
                            -77.24027777849665,
                            39.581111111088944
                        ],
                        [
                            -77.3572222224276,
                            39.69805555501989
                        ],
                        [
                            -77.35694444433062,
                            39.722222221578306
                        ],
                        [
                            -77.35694444433062,
                            39.74638888903604
                        ],
                        [
                            -77.35666666713297,
                            39.78166666598389
                        ],
                        [
                            -77.3974944446129,
                            39.78018888830661
                        ],
                        [
                            -77.61722222262745,
                            39.77222222147839
                        ],
                        [
                            -77.84027777819688,
                            39.855555555244734
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 179,
                "artcc": "zdc",
                "sector": "06",
                "sectype": "high",
                "Shape_Length": 3.8925739337847833,
                "Shape_Area": 0.5987705304062323
            }
        },
        {
            "type": "Feature",
            "id": 182,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -76.02499999995001,
                            38.991666666483525
                        ],
                        [
                            -76.33055555589414,
                            38.67805555561944
                        ],
                        [
                            -76.53888888941066,
                            38.51722222182815
                        ],
                        [
                            -76.6105555556943,
                            38.451666666483504
                        ],
                        [
                            -76.70111111098896,
                            38.36888888891116
                        ],
                        [
                            -76.81194444488017,
                            38.2674999995254
                        ],
                        [
                            -76.88666666663335,
                            38.19750000002506
                        ],
                        [
                            -76.9369444446304,
                            38.127777777722315
                        ],
                        [
                            -77.01250000067444,
                            38.026388888336555
                        ],
                        [
                            -77.08166666678324,
                            37.92722222192805
                        ],
                        [
                            -77.16833333321671,
                            37.753333332867044
                        ],
                        [
                            -77.1041666665584,
                            37.6416666664835
                        ],
                        [
                            -76.8244444448552,
                            37.648055555519534
                        ],
                        [
                            -76.76666666723287,
                            37.633333332567304
                        ],
                        [
                            -76.63027777809697,
                            37.5922222218781
                        ],
                        [
                            -76.39166666708297,
                            37.7499999993006
                        ],
                        [
                            -76.25694444473032,
                            37.91666666683324
                        ],
                        [
                            -75.81333333376631,
                            38.5249999995504
                        ],
                        [
                            -75.66666666673325,
                            38.700000000099976
                        ],
                        [
                            -75.65166666658337,
                            38.981666666683395
                        ],
                        [
                            -75.92000000069942,
                            38.92499999965031
                        ],
                        [
                            -76.02499999995001,
                            38.991666666483525
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 182,
                "artcc": "zdc",
                "sector": "10",
                "sectype": "high",
                "Shape_Length": 4.474014686595673,
                "Shape_Area": 0.9519495370403319
            }
        },
        {
            "type": "Feature",
            "id": 183,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -76.70833333341659,
                            37.3833333330669
                        ],
                        [
                            -76.45277777827181,
                            37.473611111163905
                        ],
                        [
                            -76.46666666693312,
                            37.59999999960036
                        ],
                        [
                            -76.39166666708297,
                            37.7499999993006
                        ],
                        [
                            -76.19249999997498,
                            37.7499999993006
                        ],
                        [
                            -76.25694444473032,
                            37.91666666683324
                        ],
                        [
                            -75.81333333376631,
                            38.5249999995504
                        ],
                        [
                            -75.66666666673325,
                            38.700000000099976
                        ],
                        [
                            -75.81805555601903,
                            38.696666666533474
                        ],
                        [
                            -76.16666666663332,
                            38.680833332991995
                        ],
                        [
                            -76.33055555589414,
                            38.67805555561944
                        ],
                        [
                            -76.47666666673325,
                            38.67583333264224
                        ],
                        [
                            -76.5236111111638,
                            38.674999999250645
                        ],
                        [
                            -76.9991666673078,
                            38.585277777347585
                        ],
                        [
                            -77.01416666655837,
                            38.33111111088908
                        ],
                        [
                            -76.88666666663335,
                            38.19750000002506
                        ],
                        [
                            -76.9369444446304,
                            38.127777777722315
                        ],
                        [
                            -77.01250000067444,
                            38.026388888336555
                        ],
                        [
                            -77.08166666678324,
                            37.92722222192805
                        ],
                        [
                            -77.16833333321671,
                            37.753333332867044
                        ],
                        [
                            -77.1041666665584,
                            37.6416666664835
                        ],
                        [
                            -77.20833333331666,
                            37.41666666603385
                        ],
                        [
                            -77.0350000004496,
                            37.41388888866135
                        ],
                        [
                            -76.96666666683319,
                            37.36666666613377
                        ],
                        [
                            -76.90694444433058,
                            37.34722222182813
                        ],
                        [
                            -76.70833333341659,
                            37.3833333330669
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 183,
                "artcc": "zdc",
                "sector": "11",
                "sectype": "high",
                "Shape_Length": 5.131032801632734,
                "Shape_Area": 1.1522910876283363
            }
        },
        {
            "type": "Feature",
            "id": 184,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -77.08166666678324,
                            37.92722222192805
                        ],
                        [
                            -77.01250000067444,
                            38.026388888336555
                        ],
                        [
                            -76.9369444446304,
                            38.127777777722315
                        ],
                        [
                            -76.88666666663335,
                            38.19750000002506
                        ],
                        [
                            -76.81194444488017,
                            38.2674999995254
                        ],
                        [
                            -76.70111111098896,
                            38.36888888891116
                        ],
                        [
                            -76.6105555556943,
                            38.451666666483504
                        ],
                        [
                            -76.53888888941066,
                            38.51722222182815
                        ],
                        [
                            -76.33055555589414,
                            38.67805555561944
                        ],
                        [
                            -76.02499999995001,
                            38.991666666483525
                        ],
                        [
                            -76.1250000006495,
                            39.12999999960033
                        ],
                        [
                            -76.34722222282727,
                            39.39166666658343
                        ],
                        [
                            -76.7469444448302,
                            39.08444444385606
                        ],
                        [
                            -76.74703883317557,
                            39.082754034071456
                        ],
                        [
                            -77.0375000006245,
                            38.8583333328171
                        ],
                        [
                            -77.32083333399112,
                            38.64305555496992
                        ],
                        [
                            -77.56666666653342,
                            38.39722222152835
                        ],
                        [
                            -77.51666666663334,
                            38.255555555744365
                        ],
                        [
                            -77.64166666728283,
                            38.17499999935052
                        ],
                        [
                            -77.73222222257749,
                            38.08472222215289
                        ],
                        [
                            -77.81666666693309,
                            37.999999999700265
                        ],
                        [
                            -77.8602777777972,
                            37.91916666610882
                        ],
                        [
                            -77.73333333316674,
                            37.86666666603384
                        ],
                        [
                            -77.48000000009989,
                            37.76666666623373
                        ],
                        [
                            -77.16833333321671,
                            37.753333332867044
                        ],
                        [
                            -77.08166666678324,
                            37.92722222192805
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 184,
                "artcc": "zdc",
                "sector": "12",
                "sectype": "high",
                "Shape_Length": 5.151405120644869,
                "Shape_Area": 1.1600513574614126
            }
        },
        {
            "type": "Feature",
            "id": 186,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -79.89444444465533,
                            39.173611111363755
                        ],
                        [
                            -79.7319444440808,
                            39.163611110664306
                        ],
                        [
                            -79.37500000044963,
                            39.141666667083086
                        ],
                        [
                            -79.16666666693311,
                            39.11666666713302
                        ],
                        [
                            -79.00000000029974,
                            39.10833333321682
                        ],
                        [
                            -78.70277777737249,
                            39.33472222235275
                        ],
                        [
                            -78.52222222207786,
                            39.591666667083075
                        ],
                        [
                            -78.55833333331663,
                            39.6716666663836
                        ],
                        [
                            -78.62472222205287,
                            39.64777777792216
                        ],
                        [
                            -79.01388888896099,
                            39.533333333266796
                        ],
                        [
                            -79.233333332867,
                            39.46666666643358
                        ],
                        [
                            -79.31805555531957,
                            39.44111111118889
                        ],
                        [
                            -79.54305555576923,
                            39.37083333359152
                        ],
                        [
                            -79.69833333301688,
                            39.283611110964046
                        ],
                        [
                            -79.89444444465533,
                            39.173611111363755
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 186,
                "artcc": "zdc",
                "sector": "15",
                "sectype": "high",
                "Shape_Length": 3.105276358870364,
                "Shape_Area": 0.3545789737038566
            }
        },
        {
            "type": "Feature",
            "id": 188,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -77.16833333321671,
                            37.753333332867044
                        ],
                        [
                            -77.48000000009989,
                            37.76666666713305
                        ],
                        [
                            -77.73333333316674,
                            37.86666666693321
                        ],
                        [
                            -77.8602777777972,
                            37.91916666700814
                        ],
                        [
                            -78.03888888911092,
                            37.69166666638358
                        ],
                        [
                            -78.17194444468032,
                            37.53444444425577
                        ],
                        [
                            -78.29999999990002,
                            37.35000000009995
                        ],
                        [
                            -78.38666666633355,
                            37.229999999800214
                        ],
                        [
                            -78.44999999960027,
                            37.14999999960037
                        ],
                        [
                            -78.59166666628357,
                            36.98333333296699
                        ],
                        [
                            -78.82823888921331,
                            36.77838333352406
                        ],
                        [
                            -78.87499999965024,
                            36.726527777734816
                        ],
                        [
                            -78.83863611146734,
                            36.5936861115249
                        ],
                        [
                            -78.80416666675825,
                            36.508333333016935
                        ],
                        [
                            -78.78333333286696,
                            36.43333333316684
                        ],
                        [
                            -78.68888888871123,
                            36.46666666703311
                        ],
                        [
                            -78.2824999995753,
                            36.46527777744751
                        ],
                        [
                            -78.12916666630855,
                            36.38138888928586
                        ],
                        [
                            -77.76388888876119,
                            36.56944444420577
                        ],
                        [
                            -77.62722222242758,
                            36.55416666685824
                        ],
                        [
                            -77.2500000001998,
                            36.69999999960038
                        ],
                        [
                            -77.16666666643346,
                            36.78333333336667
                        ],
                        [
                            -77.04222222197791,
                            36.910555555194776
                        ],
                        [
                            -76.90694444433058,
                            37.34722222182813
                        ],
                        [
                            -76.88055555569429,
                            37.40277777737259
                        ],
                        [
                            -76.76666666633355,
                            37.633333333466624
                        ],
                        [
                            -76.82444444395588,
                            37.648055555519534
                        ],
                        [
                            -77.1041666665584,
                            37.6416666664835
                        ],
                        [
                            -77.16833333321671,
                            37.753333332867044
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 188,
                "artcc": "zdc",
                "sector": "16",
                "sectype": "high",
                "Shape_Length": 5.77461176339268,
                "Shape_Area": 1.8508385269744596
            }
        },
        {
            "type": "Feature",
            "id": 190,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -75.86999999990002,
                            39.334999999550405
                        ],
                        [
                            -75.42500000024978,
                            39.71500000005
                        ],
                        [
                            -75.16333333326668,
                            39.9224999992756
                        ],
                        [
                            -74.98333333326667,
                            40.06666666613381
                        ],
                        [
                            -74.89722222212782,
                            40.083333333066946
                        ],
                        [
                            -74.87055555629382,
                            40.10277777737258
                        ],
                        [
                            -74.8180555562189,
                            40.14861111121388
                        ],
                        [
                            -74.74722222242758,
                            40.20694444413084
                        ],
                        [
                            -74.72499999985007,
                            40.23000000009995
                        ],
                        [
                            -74.70722222232769,
                            40.24638888893611
                        ],
                        [
                            -74.6930555555694,
                            40.263333333066896
                        ],
                        [
                            -74.95694444463038,
                            40.34166666648355
                        ],
                        [
                            -75.01666666713294,
                            40.29027777789719
                        ],
                        [
                            -75.03666666673325,
                            40.25500000005002
                        ],
                        [
                            -75.05750000062449,
                            40.23944444370619
                        ],
                        [
                            -75.07583333344155,
                            40.23055555539463
                        ],
                        [
                            -75.09638888923581,
                            40.22277777767238
                        ],
                        [
                            -75.11833333371635,
                            40.21666666673332
                        ],
                        [
                            -75.15888888911093,
                            40.188611110414456
                        ],
                        [
                            -75.34166666738275,
                            40.06666666613381
                        ],
                        [
                            -75.46944444450548,
                            39.97083333329175
                        ],
                        [
                            -75.65000000069944,
                            39.84999999960036
                        ],
                        [
                            -75.67000000029975,
                            39.834999999450474
                        ],
                        [
                            -75.77333333366636,
                            39.77083333279211
                        ],
                        [
                            -75.89472222265243,
                            39.68916666670833
                        ],
                        [
                            -75.96388888876118,
                            39.64388888816171
                        ],
                        [
                            -76.04027777819687,
                            39.59361111106398
                        ],
                        [
                            -76.34722222282727,
                            39.39166666658343
                        ],
                        [
                            -76.1250000006495,
                            39.12999999960033
                        ],
                        [
                            -75.86999999990002,
                            39.334999999550405
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 190,
                "artcc": "zdc",
                "sector": "18",
                "sectype": "high",
                "Shape_Length": 4.146911542478243,
                "Shape_Area": 0.4555652782048531
            }
        },
        {
            "type": "Feature",
            "id": 191,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -74.49944444500511,
                            40.03972222220284
                        ],
                        [
                            -74.40444444465538,
                            40.09888888851145
                        ],
                        [
                            -74.36555555604406,
                            40.16249999987514
                        ],
                        [
                            -74.36583333324171,
                            40.2213888889861
                        ],
                        [
                            -74.39722222222775,
                            40.2624999996753
                        ],
                        [
                            -74.47777777772222,
                            40.29305555526969
                        ],
                        [
                            -74.62000000059953,
                            40.26416666645855
                        ],
                        [
                            -74.66500000014986,
                            40.25500000005002
                        ],
                        [
                            -74.70722222232769,
                            40.24638888893611
                        ],
                        [
                            -74.72499999985007,
                            40.23000000009995
                        ],
                        [
                            -74.74722222242758,
                            40.20694444413084
                        ],
                        [
                            -74.8180555562189,
                            40.14861111121388
                        ],
                        [
                            -74.87055555629382,
                            40.10277777737258
                        ],
                        [
                            -74.89722222212782,
                            40.083333333066946
                        ],
                        [
                            -74.98333333326667,
                            40.06666666613381
                        ],
                        [
                            -75.16333333326668,
                            39.9224999992756
                        ],
                        [
                            -75.42500000024978,
                            39.71500000005
                        ],
                        [
                            -75.86999999990002,
                            39.334999999550405
                        ],
                        [
                            -76.1250000006495,
                            39.12999999960033
                        ],
                        [
                            -76.02499999995001,
                            38.991666666483525
                        ],
                        [
                            -75.92000000069942,
                            38.92499999965031
                        ],
                        [
                            -75.65166666658337,
                            38.981666666683395
                        ],
                        [
                            -75.51666666703301,
                            39.11722222152838
                        ],
                        [
                            -75.2541666666583,
                            39.39444444395599
                        ],
                        [
                            -75.05833333401614,
                            39.622222221778145
                        ],
                        [
                            -75.03000000049957,
                            39.653333332667216
                        ],
                        [
                            -74.9400000004996,
                            39.751666666583446
                        ],
                        [
                            -74.89249999987504,
                            39.805555555344654
                        ],
                        [
                            -74.85277777787212,
                            39.84999999960036
                        ],
                        [
                            -74.83722222242756,
                            39.867222221828115
                        ],
                        [
                            -74.80416666665832,
                            39.89999999950044
                        ],
                        [
                            -74.74333333356645,
                            39.94277777787221
                        ],
                        [
                            -74.6972222225275,
                            39.97472222215288
                        ],
                        [
                            -74.68472222255247,
                            39.983333333266785
                        ],
                        [
                            -74.63138888908594,
                            40.020277777897206
                        ],
                        [
                            -74.58361111126374,
                            40.01722222152836
                        ],
                        [
                            -74.49944444500511,
                            40.03972222220284
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 191,
                "artcc": "zdc",
                "sector": "19",
                "sectype": "high",
                "Shape_Length": 4.597588379042622,
                "Shape_Area": 0.630079976794574
            }
        },
        {
            "type": "Feature",
            "id": 192,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -77.16833333321671,
                            37.753333332867044
                        ],
                        [
                            -77.48000000009989,
                            37.76666666623373
                        ],
                        [
                            -77.73333333316674,
                            37.86666666603384
                        ],
                        [
                            -77.8602777777972,
                            37.91916666610882
                        ],
                        [
                            -78.03888888911092,
                            37.69166666638358
                        ],
                        [
                            -78.17194444468032,
                            37.53444444425577
                        ],
                        [
                            -78.29999999990002,
                            37.35000000009995
                        ],
                        [
                            -78.38666666723287,
                            37.229999999800214
                        ],
                        [
                            -78.45000000049959,
                            37.14999999960037
                        ],
                        [
                            -78.59166666718289,
                            36.98333333296699
                        ],
                        [
                            -78.82823888921331,
                            36.77838333262474
                        ],
                        [
                            -78.87500000054956,
                            36.726527777734816
                        ],
                        [
                            -78.83863611146734,
                            36.59368611062558
                        ],
                        [
                            -78.80416666675825,
                            36.508333333016935
                        ],
                        [
                            -78.78333333376628,
                            36.43333333316684
                        ],
                        [
                            -78.68888888961055,
                            36.46666666613379
                        ],
                        [
                            -78.28250000047461,
                            36.46527777744751
                        ],
                        [
                            -78.12916666720787,
                            36.38138888838654
                        ],
                        [
                            -77.76388888876119,
                            36.56944444420577
                        ],
                        [
                            -77.62722222242758,
                            36.55416666595892
                        ],
                        [
                            -77.2500000001998,
                            36.69999999960038
                        ],
                        [
                            -77.16666666733278,
                            36.78333333336667
                        ],
                        [
                            -77.04222222287723,
                            36.910555555194776
                        ],
                        [
                            -76.90694444433058,
                            37.34722222182813
                        ],
                        [
                            -76.88055555569429,
                            37.40277777737259
                        ],
                        [
                            -76.76666666723287,
                            37.633333332567304
                        ],
                        [
                            -76.8244444448552,
                            37.648055555519534
                        ],
                        [
                            -77.1041666665584,
                            37.6416666664835
                        ],
                        [
                            -77.16833333321671,
                            37.753333332867044
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 192,
                "artcc": "zdc",
                "sector": "20",
                "sectype": "high",
                "Shape_Length": 5.774611762560475,
                "Shape_Area": 1.850838527015759
            }
        },
        {
            "type": "Feature",
            "id": 198,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -79.17338055566807,
                            34.95063611066803
                        ],
                        [
                            -79.05000000019982,
                            35.11666666613377
                        ],
                        [
                            -79.05826111136116,
                            35.52972222150339
                        ],
                        [
                            -79.02713333333662,
                            35.624436110638044
                        ],
                        [
                            -78.86666666663331,
                            35.7666666666334
                        ],
                        [
                            -78.71666666693312,
                            35.7666666666334
                        ],
                        [
                            -78.49166666738273,
                            35.94999999930059
                        ],
                        [
                            -78.69166666698305,
                            36.008333333116866
                        ],
                        [
                            -78.78333333376628,
                            36.43333333316684
                        ],
                        [
                            -78.83666666723286,
                            36.41388888886121
                        ],
                        [
                            -79.32277777847167,
                            36.241666666583455
                        ],
                        [
                            -79.49333333396618,
                            36.180000000099994
                        ],
                        [
                            -79.74444444495515,
                            36.08888888861139
                        ],
                        [
                            -79.75638888873618,
                            35.94999999930059
                        ],
                        [
                            -79.76002777775972,
                            35.913011111073956
                        ],
                        [
                            -79.78333333356647,
                            35.6499999999001
                        ],
                        [
                            -79.78333333356647,
                            35.628611110714246
                        ],
                        [
                            -79.80416666655839,
                            35.383333332567304
                        ],
                        [
                            -80.00000000009987,
                            35.299999999700276
                        ],
                        [
                            -80.00000000009987,
                            35.10833333311689
                        ],
                        [
                            -80.14722222242756,
                            34.855555555344665
                        ],
                        [
                            -79.91666666723285,
                            34.84999999970029
                        ],
                        [
                            -79.72805555611899,
                            34.71333333336668
                        ],
                        [
                            -79.17338055566807,
                            34.95063611066803
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 198,
                "artcc": "zdc",
                "sector": "27",
                "sectype": "high",
                "Shape_Length": 5.51046162988363,
                "Shape_Area": 1.3582087454655736
            }
        },
        {
            "type": "Feature",
            "id": 202,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -79.52784444426567,
                            36.20912222161331
                        ],
                        [
                            -78.83863611146734,
                            36.59368611062558
                        ],
                        [
                            -78.87500000054956,
                            36.726527777734816
                        ],
                        [
                            -78.82823888921331,
                            36.77838333262474
                        ],
                        [
                            -78.59166666718289,
                            36.98333333296699
                        ],
                        [
                            -78.45000000049959,
                            37.14999999960037
                        ],
                        [
                            -78.38666666723287,
                            37.229999999800214
                        ],
                        [
                            -78.29999999990002,
                            37.35000000009995
                        ],
                        [
                            -78.17194444468032,
                            37.53444444425577
                        ],
                        [
                            -78.03888888911092,
                            37.69166666638358
                        ],
                        [
                            -77.8602777777972,
                            37.91916666610882
                        ],
                        [
                            -77.81666666693309,
                            37.999999999700265
                        ],
                        [
                            -77.73222222257749,
                            38.08472222215289
                        ],
                        [
                            -77.64166666728283,
                            38.17499999935052
                        ],
                        [
                            -77.51666666663334,
                            38.255555555744365
                        ],
                        [
                            -77.56666666653342,
                            38.39722222152835
                        ],
                        [
                            -77.32083333399112,
                            38.64305555496992
                        ],
                        [
                            -77.7997222228023,
                            38.59027777769734
                        ],
                        [
                            -78.26666666693308,
                            38.283333333066935
                        ],
                        [
                            -78.74361111176336,
                            37.881388888986066
                        ],
                        [
                            -79.24111111148858,
                            37.454722222152895
                        ],
                        [
                            -79.68611111113881,
                            37.04166666678327
                        ],
                        [
                            -80.07083333389119,
                            36.792777777872175
                        ],
                        [
                            -80.2594444450051,
                            36.68472222225279
                        ],
                        [
                            -80.2500000004996,
                            36.66666666663343
                        ],
                        [
                            -80.11944444510505,
                            36.443611111064
                        ],
                        [
                            -79.97944444430561,
                            36.204722221953034
                        ],
                        [
                            -79.9500000001998,
                            36.15416666675833
                        ],
                        [
                            -79.86666666733277,
                            36.133333332867096
                        ],
                        [
                            -79.74444444495515,
                            36.08888888861139
                        ],
                        [
                            -79.52784444426567,
                            36.20912222161331
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 202,
                "artcc": "zdc",
                "sector": "32",
                "sectype": "high",
                "Shape_Length": 8.164633822892593,
                "Shape_Area": 2.2870773819529946
            }
        },
        {
            "type": "Feature",
            "id": 204,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -76.46666666693312,
                            37.59999999960036
                        ],
                        [
                            -76.39166666708297,
                            37.7499999993006
                        ],
                        [
                            -76.63027777809697,
                            37.5922222218781
                        ],
                        [
                            -76.76666666723287,
                            37.633333332567304
                        ],
                        [
                            -76.88055555569429,
                            37.40277777737259
                        ],
                        [
                            -76.90694444433058,
                            37.34722222182813
                        ],
                        [
                            -77.04222222287723,
                            36.910555555194776
                        ],
                        [
                            -77.16666666733278,
                            36.78333333336667
                        ],
                        [
                            -77.33333333396615,
                            36.133333332867096
                        ],
                        [
                            -77.39999999990005,
                            35.93333333326677
                        ],
                        [
                            -76.62444444435556,
                            35.90333333296701
                        ],
                        [
                            -75.74972222240262,
                            35.825277777647386
                        ],
                        [
                            -75.41638888913587,
                            35.50027777739757
                        ],
                        [
                            -75.18999999999994,
                            35.3080555555195
                        ],
                        [
                            -74.93694444503006,
                            35.49694444383107
                        ],
                        [
                            -75.4544444443556,
                            35.88222222187807
                        ],
                        [
                            -75.49972222290222,
                            35.91694444443061
                        ],
                        [
                            -75.50000000009987,
                            36.81666666633362
                        ],
                        [
                            -75.50000000009987,
                            36.88333333316683
                        ],
                        [
                            -75.50000000009987,
                            36.93333333306691
                        ],
                        [
                            -75.49972222290222,
                            37.00583333274216
                        ],
                        [
                            -76.42361111136364,
                            37.20277777777227
                        ],
                        [
                            -76.44027777829677,
                            37.32499999925062
                        ],
                        [
                            -76.45277777827181,
                            37.473611111163905
                        ],
                        [
                            -76.46666666693312,
                            37.59999999960036
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 204,
                "artcc": "zdc",
                "sector": "34",
                "sectype": "high",
                "Shape_Length": 8.299874556093375,
                "Shape_Area": 2.5343139657706835
            }
        },
        {
            "type": "Feature",
            "id": 205,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -78.74999999990001,
                            34.444444443956
                        ],
                        [
                            -78.68499999985005,
                            34.39694444423077
                        ],
                        [
                            -78.35833333371636,
                            34.157222222627524
                        ],
                        [
                            -78.11916666650842,
                            33.98027777809705
                        ],
                        [
                            -77.93277777747244,
                            33.841666666883214
                        ],
                        [
                            -77.75083333349153,
                            33.705555555844285
                        ],
                        [
                            -77.51166666628359,
                            33.52555555584428
                        ],
                        [
                            -77.49999999970021,
                            33.58333333346661
                        ],
                        [
                            -77.31666666703302,
                            34.483333333466646
                        ],
                        [
                            -77.14736388873246,
                            34.57097499989641
                        ],
                        [
                            -76.93333333296692,
                            34.61666666713302
                        ],
                        [
                            -76.23333333346653,
                            34.81666666673334
                        ],
                        [
                            -76.01420555524214,
                            35.00628888922091
                        ],
                        [
                            -75.46986111120128,
                            35.20065833331546
                        ],
                        [
                            -75.42021388925957,
                            35.45004444479537
                        ],
                        [
                            -75.41638888913587,
                            35.50027777739757
                        ],
                        [
                            -75.74972222240262,
                            35.825277777647386
                        ],
                        [
                            -76.62444444435556,
                            35.90333333296701
                        ],
                        [
                            -77.39999999990005,
                            35.93333333326677
                        ],
                        [
                            -77.44999999980013,
                            35.81666666653348
                        ],
                        [
                            -77.61666666643345,
                            35.6499999999001
                        ],
                        [
                            -78.74999999990001,
                            34.444444443956
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 205,
                "artcc": "zdc",
                "sector": "35",
                "sectype": "high",
                "Shape_Length": 8.966563880739299,
                "Shape_Area": 3.666935686933245
            }
        },
        {
            "type": "Feature",
            "id": 206,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -78.80416666675825,
                            36.508333333016935
                        ],
                        [
                            -78.83863611146734,
                            36.59368611062558
                        ],
                        [
                            -79.52784444426567,
                            36.20912222161331
                        ],
                        [
                            -79.74444444495515,
                            36.08888888861139
                        ],
                        [
                            -79.75638888873618,
                            35.94999999930059
                        ],
                        [
                            -79.76002777775972,
                            35.913011111073956
                        ],
                        [
                            -79.78333333356647,
                            35.6499999999001
                        ],
                        [
                            -79.78333333356647,
                            35.628611110714246
                        ],
                        [
                            -80.00000000009987,
                            35.541666666183744
                        ],
                        [
                            -80.15000000069944,
                            35.46944444370615
                        ],
                        [
                            -80.20000000059952,
                            35.383333332567304
                        ],
                        [
                            -80.12499999985005,
                            35.11666666613377
                        ],
                        [
                            -79.99416666725784,
                            35.11666666613377
                        ],
                        [
                            -80.00000000009987,
                            35.10833333311689
                        ],
                        [
                            -80.14722222242756,
                            34.855555555344665
                        ],
                        [
                            -79.91666666723285,
                            34.84999999970029
                        ],
                        [
                            -79.72805555611899,
                            34.71333333336668
                        ],
                        [
                            -79.33333333356643,
                            34.42499999965031
                        ],
                        [
                            -79.26138888918587,
                            34.58611111063931
                        ],
                        [
                            -79.00000000029974,
                            35.16666666603385
                        ],
                        [
                            -78.12916666720787,
                            36.38138888838654
                        ],
                        [
                            -78.28250000047461,
                            36.46527777744751
                        ],
                        [
                            -78.68888888961055,
                            36.46666666613379
                        ],
                        [
                            -78.78333333376628,
                            36.43333333316684
                        ],
                        [
                            -78.80416666675825,
                            36.508333333016935
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 206,
                "artcc": "zdc",
                "sector": "36",
                "sectype": "high",
                "Shape_Length": 6.820361974310814,
                "Shape_Area": 2.1104957619309244
            }
        },
        {
            "type": "Feature",
            "id": 207,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -79.71666666673326,
                            37.76666666623373
                        ],
                        [
                            -79.51666666713294,
                            37.96666666673332
                        ],
                        [
                            -79.29527777834676,
                            38.05194444448057
                        ],
                        [
                            -79.19444444425568,
                            38.08888888821167
                        ],
                        [
                            -78.80972222240263,
                            38.25527777764739
                        ],
                        [
                            -78.33166666698304,
                            38.58805555561946
                        ],
                        [
                            -78.3750000006495,
                            38.765555555444564
                        ],
                        [
                            -78.56666666723288,
                            38.75000000000006
                        ],
                        [
                            -79.33333333356643,
                            38.599999999400495
                        ],
                        [
                            -79.4427777778721,
                            38.58277777717274
                        ],
                        [
                            -79.58194444438055,
                            38.5580555553197
                        ],
                        [
                            -80.00000000009987,
                            38.4833333326672
                        ],
                        [
                            -80.64472222215278,
                            38.38249999947544
                        ],
                        [
                            -80.7166666665334,
                            38.04166666658347
                        ],
                        [
                            -80.78944444430562,
                            37.708333333316716
                        ],
                        [
                            -79.71666666673326,
                            37.76666666623373
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 207,
                "artcc": "zdc",
                "sector": "37",
                "sectype": "high",
                "Shape_Length": 5.87859577855288,
                "Shape_Area": 1.3704533557132499
            }
        },
        {
            "type": "Feature",
            "id": 208,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -77.16666666733278,
                            36.78333333336667
                        ],
                        [
                            -77.2500000001998,
                            36.69999999960038
                        ],
                        [
                            -77.62722222242758,
                            36.55416666595892
                        ],
                        [
                            -77.76388888876119,
                            36.56944444420577
                        ],
                        [
                            -78.12916666720787,
                            36.38138888838654
                        ],
                        [
                            -79.00000000029974,
                            35.16666666603385
                        ],
                        [
                            -79.26138888918587,
                            34.58611111063931
                        ],
                        [
                            -79.33333333356643,
                            34.42499999965031
                        ],
                        [
                            -79.26333333316677,
                            34.42749999982516
                        ],
                        [
                            -79.06666666713295,
                            34.43416666605884
                        ],
                        [
                            -78.74999999990001,
                            34.444444443956
                        ],
                        [
                            -77.61666666733277,
                            35.6499999999001
                        ],
                        [
                            -77.45000000069945,
                            35.81666666653348
                        ],
                        [
                            -77.39999999990005,
                            35.93333333326677
                        ],
                        [
                            -77.33333333396615,
                            36.133333332867096
                        ],
                        [
                            -77.16666666733278,
                            36.78333333336667
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 208,
                "artcc": "zdc",
                "sector": "38",
                "sectype": "high",
                "Shape_Length": 6.861166034024754,
                "Shape_Area": 1.9156773142651968
            }
        },
        {
            "type": "Feature",
            "id": 213,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -79.24111111148858,
                            37.454722222152895
                        ],
                        [
                            -78.74361111176336,
                            37.881388888986066
                        ],
                        [
                            -78.26666666693308,
                            38.283333333066935
                        ],
                        [
                            -77.7997222228023,
                            38.59027777769734
                        ],
                        [
                            -78.33166666698304,
                            38.58805555561946
                        ],
                        [
                            -78.80972222240263,
                            38.25527777764739
                        ],
                        [
                            -79.19444444425568,
                            38.08888888821167
                        ],
                        [
                            -79.29527777834676,
                            38.05194444448057
                        ],
                        [
                            -79.51666666713294,
                            37.96666666673332
                        ],
                        [
                            -79.71666666673326,
                            37.76666666623373
                        ],
                        [
                            -80.78944444430562,
                            37.708333333316716
                        ],
                        [
                            -80.82500000024976,
                            37.52916666670836
                        ],
                        [
                            -80.84166666718289,
                            37.46666666683325
                        ],
                        [
                            -80.82222222287726,
                            37.43333333296698
                        ],
                        [
                            -80.74583333344157,
                            37.30416666625871
                        ],
                        [
                            -80.63333333366637,
                            37.3319444444806
                        ],
                        [
                            -80.58111111168841,
                            37.25555555504491
                        ],
                        [
                            -80.50194444488017,
                            37.11833333341667
                        ],
                        [
                            -80.32361111166341,
                            36.80833333331674
                        ],
                        [
                            -80.2594444450051,
                            36.68472222225279
                        ],
                        [
                            -80.07083333389119,
                            36.792777777872175
                        ],
                        [
                            -79.68611111113881,
                            37.04166666678327
                        ],
                        [
                            -79.24111111148858,
                            37.454722222152895
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 213,
                "artcc": "zdc",
                "sector": "52",
                "sectype": "high",
                "Shape_Length": 7.6558281793451295,
                "Shape_Area": 1.6732449069189699
            }
        },
        {
            "type": "Feature",
            "id": 215,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -76.42361111136364,
                            37.20277777777227
                        ],
                        [
                            -75.4997222220029,
                            37.005833333641476
                        ],
                        [
                            -75.50000000009987,
                            37.50833333371645
                        ],
                        [
                            -75.50000000009987,
                            37.713444444005916
                        ],
                        [
                            -75.41719166626234,
                            37.8200555553197
                        ],
                        [
                            -75.33333333346656,
                            37.82083333309191
                        ],
                        [
                            -75.18611111113881,
                            37.991666666683386
                        ],
                        [
                            -75.05000000009989,
                            38.26666666703312
                        ],
                        [
                            -74.98955277743869,
                            38.419000000050005
                        ],
                        [
                            -74.61666666703303,
                            38.75000000000006
                        ],
                        [
                            -74.88333333346651,
                            38.75000000000006
                        ],
                        [
                            -75.24999999970021,
                            38.729166667008144
                        ],
                        [
                            -75.40833333331665,
                            38.71944444440561
                        ],
                        [
                            -75.66666666673325,
                            38.700000000099976
                        ],
                        [
                            -75.81333333286699,
                            38.5249999995504
                        ],
                        [
                            -76.25694444473032,
                            37.91666666683324
                        ],
                        [
                            -76.39166666708297,
                            37.75000000019992
                        ],
                        [
                            -76.46666666693312,
                            37.59999999960036
                        ],
                        [
                            -76.45277777737249,
                            37.473611111163905
                        ],
                        [
                            -76.44027777739745,
                            37.32500000014994
                        ],
                        [
                            -76.42361111136364,
                            37.20277777777227
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 215,
                "artcc": "zdc",
                "sector": "54",
                "sectype": "high",
                "Shape_Length": 5.880470026308951,
                "Shape_Area": 1.5556202439122722
            }
        },
        {
            "type": "Feature",
            "id": 216,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -75.40833333331665,
                            38.71944444440561
                        ],
                        [
                            -75.25000000059953,
                            38.729166666108824
                        ],
                        [
                            -74.86666666653338,
                            39.249999999900126
                        ],
                        [
                            -74.49861111161346,
                            39.593055554870034
                        ],
                        [
                            -74.45555555604403,
                            39.655833332842064
                        ],
                        [
                            -74.3944444448552,
                            39.7491666664086
                        ],
                        [
                            -74.38333333356644,
                            39.76249999977523
                        ],
                        [
                            -74.135277778047,
                            40.01583333284208
                        ],
                        [
                            -74.00000000039967,
                            40.15416666595894
                        ],
                        [
                            -74.68472222255247,
                            39.983333333266785
                        ],
                        [
                            -74.6972222225275,
                            39.97472222215288
                        ],
                        [
                            -74.74333333356645,
                            39.94277777787221
                        ],
                        [
                            -74.80416666665832,
                            39.89999999950044
                        ],
                        [
                            -74.83722222242756,
                            39.867222221828115
                        ],
                        [
                            -74.85277777787212,
                            39.84999999960036
                        ],
                        [
                            -74.89249999987504,
                            39.805555555344654
                        ],
                        [
                            -74.9400000004996,
                            39.751666666583446
                        ],
                        [
                            -75.03000000049957,
                            39.653333332667216
                        ],
                        [
                            -75.05833333401614,
                            39.622222221778145
                        ],
                        [
                            -75.2541666666583,
                            39.39444444395599
                        ],
                        [
                            -75.51666666703301,
                            39.11722222152838
                        ],
                        [
                            -75.65166666658337,
                            38.981666666683395
                        ],
                        [
                            -75.66666666673325,
                            38.700000000099976
                        ],
                        [
                            -75.40833333331665,
                            38.71944444440561
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 216,
                "artcc": "zdc",
                "sector": "58",
                "sectype": "high",
                "Shape_Length": 4.704097828485816,
                "Shape_Area": 0.7309461031965246
            }
        },
        {
            "type": "Feature",
            "id": 217,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -73.28333333396614,
                            39.73333333286706
                        ],
                        [
                            -73.72916666700803,
                            39.933333333366704
                        ],
                        [
                            -73.716666667033,
                            40.068611111014036
                        ],
                        [
                            -73.70888888931074,
                            40.14861111121388
                        ],
                        [
                            -73.69861111141364,
                            40.23888888841151
                        ],
                        [
                            -74.00000000039967,
                            40.15416666595894
                        ],
                        [
                            -74.135277778047,
                            40.01583333284208
                        ],
                        [
                            -74.38333333356644,
                            39.76249999977523
                        ],
                        [
                            -74.3944444448552,
                            39.7491666664086
                        ],
                        [
                            -74.45555555604403,
                            39.655833332842064
                        ],
                        [
                            -74.49861111161346,
                            39.593055554870034
                        ],
                        [
                            -74.86666666653338,
                            39.249999999900126
                        ],
                        [
                            -75.25000000059953,
                            38.729166666108824
                        ],
                        [
                            -74.88333333346651,
                            38.75000000000006
                        ],
                        [
                            -74.61666666703303,
                            38.75000000000006
                        ],
                        [
                            -74.33333333366636,
                            38.75000000000006
                        ],
                        [
                            -73.98333333346653,
                            38.541666666483536
                        ],
                        [
                            -73.86111111108886,
                            38.73750000002502
                        ],
                        [
                            -74.33333333366636,
                            38.96666666653351
                        ],
                        [
                            -73.9263888892358,
                            39.314444443756145
                        ],
                        [
                            -73.76305555616892,
                            39.41527777784722
                        ],
                        [
                            -73.4641666673578,
                            39.73333333286706
                        ],
                        [
                            -73.16666666723285,
                            39.68333333296698
                        ],
                        [
                            -73.28333333396614,
                            39.73333333286706
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 217,
                "artcc": "zdc",
                "sector": "59",
                "sectype": "high",
                "Shape_Length": 6.68433645539868,
                "Shape_Area": 1.2100601075451805
            }
        },
        {
            "type": "Feature",
            "id": 218,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -77.3250000000499,
                            38.56666666643355
                        ],
                        [
                            -77.32083333399112,
                            38.64305555496992
                        ],
                        [
                            -77.31388888876114,
                            38.86472222185313
                        ],
                        [
                            -77.35833333391616,
                            38.86472222185313
                        ],
                        [
                            -77.51666666663334,
                            38.77499999995007
                        ],
                        [
                            -78.02500000044961,
                            38.441666666683375
                        ],
                        [
                            -78.06666666733281,
                            38.44833333291706
                        ],
                        [
                            -78.20000000009986,
                            38.499999999600334
                        ],
                        [
                            -78.38333333366637,
                            38.43666666633362
                        ],
                        [
                            -78.99111111108886,
                            38.17694444423074
                        ],
                        [
                            -79.19444444425568,
                            38.08888888821167
                        ],
                        [
                            -79.29527777834676,
                            38.05194444448057
                        ],
                        [
                            -79.51666666713294,
                            37.96666666673332
                        ],
                        [
                            -79.71666666673326,
                            37.76666666623373
                        ],
                        [
                            -79.2705555555944,
                            37.78138888828664
                        ],
                        [
                            -79.24111111148858,
                            37.454722222152895
                        ],
                        [
                            -79.1061111110389,
                            37.458888888211675
                        ],
                        [
                            -79.06666666713295,
                            37.330555554895
                        ],
                        [
                            -79.03333333326668,
                            37.233333333366716
                        ],
                        [
                            -79.11666666703303,
                            37.133333332667235
                        ],
                        [
                            -78.85555555624387,
                            37.14166666658343
                        ],
                        [
                            -78.67944444510499,
                            37.14472222205296
                        ],
                        [
                            -78.45000000049959,
                            37.14999999960037
                        ],
                        [
                            -78.38666666723287,
                            37.229999999800214
                        ],
                        [
                            -78.29999999990002,
                            37.35000000009995
                        ],
                        [
                            -78.17194444468032,
                            37.53444444425577
                        ],
                        [
                            -78.03888888911092,
                            37.69166666638358
                        ],
                        [
                            -77.94777777852164,
                            37.806388888236654
                        ],
                        [
                            -77.8602777777972,
                            37.91916666610882
                        ],
                        [
                            -77.81666666693309,
                            37.999999999700265
                        ],
                        [
                            -77.73222222257749,
                            38.08472222215289
                        ],
                        [
                            -77.64166666728283,
                            38.17499999935052
                        ],
                        [
                            -77.51666666663334,
                            38.255555555744365
                        ],
                        [
                            -77.46444444465538,
                            38.3091666664086
                        ],
                        [
                            -77.3250000000499,
                            38.56666666643355
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 218,
                "artcc": "zdc",
                "sector": "60",
                "sectype": "high",
                "Shape_Length": 6.790773843673658,
                "Shape_Area": 1.724537731648483
            }
        },
        {
            "type": "Feature",
            "id": 243,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -109.98333333346653,
                            38.933333333566566
                        ],
                        [
                            -107.22055555549446,
                            39.491944444480566
                        ],
                        [
                            -107.22055555549446,
                            39.622777777972146
                        ],
                        [
                            -107.22055555549446,
                            39.72083333289203
                        ],
                        [
                            -106.96666666713293,
                            40.11666666693321
                        ],
                        [
                            -107.58333333286697,
                            40.2666666666334
                        ],
                        [
                            -109.15000000029971,
                            40.23333333366645
                        ],
                        [
                            -109.16666666633353,
                            40.00000000019992
                        ],
                        [
                            -109.81666666683316,
                            39.76388888846151
                        ],
                        [
                            -110.2999999998001,
                            39.58333333316682
                        ],
                        [
                            -109.98333333346653,
                            39.21666666693318
                        ],
                        [
                            -109.98333333346653,
                            38.94555555544457
                        ],
                        [
                            -109.98333333346653,
                            38.933333333566566
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 243,
                "artcc": "zdv",
                "sector": "03",
                "sectype": "high",
                "Shape_Length": 7.928756806761179,
                "Shape_Area": 2.6632470675391127
            }
        },
        {
            "type": "Feature",
            "id": 244,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -109.98333333346653,
                            38.20000000019991
                        ],
                        [
                            -109.03333333356647,
                            38.18333333326677
                        ],
                        [
                            -108.33333333316676,
                            38.499999999600334
                        ],
                        [
                            -106.72722222182801,
                            39.079999999700306
                        ],
                        [
                            -106.96445833328539,
                            39.360649999797715
                        ],
                        [
                            -107.22055555549446,
                            39.491944444480566
                        ],
                        [
                            -109.98333333346653,
                            38.933333333566566
                        ],
                        [
                            -109.98333333346653,
                            38.52777777782222
                        ],
                        [
                            -109.98333333346653,
                            38.20000000019991
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 244,
                "artcc": "zdv",
                "sector": "04",
                "sectype": "high",
                "Shape_Length": 7.633365012180837,
                "Shape_Area": 2.314949912174514
            }
        },
        {
            "type": "Feature",
            "id": 245,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -107.22055555549446,
                            39.491944444480566
                        ],
                        [
                            -106.96445833328539,
                            39.360649999797715
                        ],
                        [
                            -106.72722222182801,
                            39.079999999700306
                        ],
                        [
                            -105.551666667083,
                            39.47000000000003
                        ],
                        [
                            -105.48111111138866,
                            39.47611111093909
                        ],
                        [
                            -105.18194444448051,
                            39.5016666670831
                        ],
                        [
                            -105.08277777807194,
                            39.98555555534466
                        ],
                        [
                            -105.05000000039968,
                            40.141666666883225
                        ],
                        [
                            -105.05888888871124,
                            40.21499999995007
                        ],
                        [
                            -105.51416666625863,
                            40.19638888903603
                        ],
                        [
                            -105.76249999987505,
                            40.1855555558443
                        ],
                        [
                            -106.55250000027473,
                            40.14527777764738
                        ],
                        [
                            -106.96666666713293,
                            40.11666666693321
                        ],
                        [
                            -107.22055555549446,
                            39.72083333289203
                        ],
                        [
                            -107.22055555549446,
                            39.622777777972146
                        ],
                        [
                            -107.22055555549446,
                            39.491944444480566
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 245,
                "artcc": "zdv",
                "sector": "05",
                "sectype": "high",
                "Shape_Length": 5.601799099373559,
                "Shape_Area": 1.7045480831589046
            }
        },
        {
            "type": "Feature",
            "id": 246,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -106.44166666728285,
                            39.433333332567315
                        ],
                        [
                            -105.72138888918585,
                            39.46305555566943
                        ],
                        [
                            -105.551666667083,
                            39.47000000000003
                        ],
                        [
                            -105.48111111138866,
                            39.47611111093909
                        ],
                        [
                            -105.18194444448051,
                            39.50166666618372
                        ],
                        [
                            -105.08277777807194,
                            39.98555555534466
                        ],
                        [
                            -105.05000000039968,
                            40.141666665983905
                        ],
                        [
                            -105.05888888961056,
                            40.21499999995007
                        ],
                        [
                            -105.51416666715795,
                            40.19638888903603
                        ],
                        [
                            -105.76249999987505,
                            40.18555555494498
                        ],
                        [
                            -106.55250000027473,
                            40.14527777764738
                        ],
                        [
                            -106.96666666713293,
                            40.11666666603384
                        ],
                        [
                            -107.22055555549446,
                            39.72083333289203
                        ],
                        [
                            -107.22055555549446,
                            39.62277777707283
                        ],
                        [
                            -107.22055555549446,
                            39.563611110764214
                        ],
                        [
                            -107.22055555549446,
                            39.491944444480566
                        ],
                        [
                            -106.96445833328539,
                            39.360649999797715
                        ],
                        [
                            -106.44166666728285,
                            39.433333332567315
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 246,
                "artcc": "zdv",
                "sector": "06",
                "sectype": "high",
                "Shape_Length": 5.414328904548903,
                "Shape_Area": 1.470546342101639
            }
        },
        {
            "type": "Feature",
            "id": 248,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -102.28444444505504,
                            39.82111111078916
                        ],
                        [
                            -102.24722222232765,
                            40.42055555519477
                        ],
                        [
                            -103.17527777774723,
                            40.251666666483516
                        ],
                        [
                            -103.48833333331663,
                            40.1830555556694
                        ],
                        [
                            -103.65111111108888,
                            40.14722222162828
                        ],
                        [
                            -103.86500000024978,
                            40.09944444380608
                        ],
                        [
                            -104.42416666735778,
                            39.97388888876128
                        ],
                        [
                            -104.31916666720787,
                            39.500833332792126
                        ],
                        [
                            -103.92444444465536,
                            39.51027777729763
                        ],
                        [
                            -103.72222222207785,
                            39.447777777422516
                        ],
                        [
                            -103.67166666688314,
                            39.43222222197801
                        ],
                        [
                            -103.24222222267741,
                            39.3338888889611
                        ],
                        [
                            -102.92972222240263,
                            39.261388888386534
                        ],
                        [
                            -102.33305555626885,
                            39.01749999982519
                        ],
                        [
                            -102.28444444505504,
                            39.82111111078916
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 248,
                "artcc": "zdv",
                "sector": "07",
                "sectype": "high",
                "Shape_Length": 6.178291969638446,
                "Shape_Area": 1.8317743439323517
            }
        },
        {
            "type": "Feature",
            "id": 250,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -102.28444444505504,
                            39.82111111078916
                        ],
                        [
                            -101.32416666725783,
                            39.87249999937552
                        ],
                        [
                            -99.05833333371635,
                            39.983333333266785
                        ],
                        [
                            -99.05555555544447,
                            40.23055555539463
                        ],
                        [
                            -99.05416666675825,
                            40.349999999500426
                        ],
                        [
                            -99.04722222242759,
                            40.88333333326676
                        ],
                        [
                            -101.28944444470534,
                            40.56916666620873
                        ],
                        [
                            -102.24722222232765,
                            40.42055555519477
                        ],
                        [
                            -103.17527777774723,
                            40.251666666483516
                        ],
                        [
                            -103.20944444500509,
                            39.76805555541961
                        ],
                        [
                            -102.28444444505504,
                            39.82111111078916
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 250,
                "artcc": "zdv",
                "sector": "08",
                "sectype": "high",
                "Shape_Length": 9.718261142370144,
                "Shape_Area": 2.910113387566921
            }
        },
        {
            "type": "Feature",
            "id": 251,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -101.30305555526962,
                            41.640833333191836
                        ],
                        [
                            -101.28944444470534,
                            40.56916666710805
                        ],
                        [
                            -99.04722222242759,
                            40.88333333326676
                        ],
                        [
                            -99.03333333286696,
                            41.07499999985015
                        ],
                        [
                            -99.02499999985008,
                            41.71666666643358
                        ],
                        [
                            -99.01666666683315,
                            41.999999999800195
                        ],
                        [
                            -100.24138888898602,
                            41.813888888861186
                        ],
                        [
                            -101.30305555526962,
                            41.640833333191836
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 251,
                "artcc": "zdv",
                "sector": "09",
                "sectype": "high",
                "Shape_Length": 6.767684737259373,
                "Shape_Area": 2.485187190853476
            }
        },
        {
            "type": "Feature",
            "id": 252,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -108.86833333371635,
                            38.753888888861184
                        ],
                        [
                            -108.84999999999997,
                            38.818611110814174
                        ],
                        [
                            -107.41638888903594,
                            39.234999999750244
                        ],
                        [
                            -106.96445833328539,
                            39.360649999797715
                        ],
                        [
                            -107.22055555549446,
                            39.491944444480566
                        ],
                        [
                            -107.22055555549446,
                            39.563611110764214
                        ],
                        [
                            -107.22055555549446,
                            39.62277777707283
                        ],
                        [
                            -107.22055555549446,
                            39.72083333289203
                        ],
                        [
                            -106.96666666713293,
                            40.11666666603384
                        ],
                        [
                            -107.58333333376629,
                            40.2666666666334
                        ],
                        [
                            -109.15000000029971,
                            40.23333333276713
                        ],
                        [
                            -109.16666666723285,
                            39.9999999993006
                        ],
                        [
                            -109.81666666683316,
                            39.76388888846151
                        ],
                        [
                            -110.30000000069941,
                            39.58333333316682
                        ],
                        [
                            -109.98333333346653,
                            39.21666666603386
                        ],
                        [
                            -109.98333333346653,
                            38.94555555544457
                        ],
                        [
                            -109.98333333346653,
                            38.933333332667246
                        ],
                        [
                            -109.98333333346653,
                            38.52777777782222
                        ],
                        [
                            -109.98333333346653,
                            38.19999999930059
                        ],
                        [
                            -109.03333333356647,
                            38.18333333326677
                        ],
                        [
                            -108.86833333371635,
                            38.753888888861184
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 252,
                "artcc": "zdv",
                "sector": "11",
                "sectype": "high",
                "Shape_Length": 9.704475265091805,
                "Shape_Area": 4.046384222740555
            }
        },
        {
            "type": "Feature",
            "id": 254,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -106.61166666658335,
                            37.87361111036449
                        ],
                        [
                            -106.1494444447053,
                            38.04527777734762
                        ],
                        [
                            -105.93005277841297,
                            38.12045000006748
                        ],
                        [
                            -106.24655833389994,
                            38.505324999649076
                        ],
                        [
                            -106.68861111131372,
                            39.03388888866135
                        ],
                        [
                            -106.96445833328539,
                            39.360649999797715
                        ],
                        [
                            -107.41638888903594,
                            39.234999999750244
                        ],
                        [
                            -107.92138888928577,
                            39.09055555569438
                        ],
                        [
                            -107.92739165799856,
                            39.086580478506335
                        ],
                        [
                            -108.84999999999997,
                            38.818611110814174
                        ],
                        [
                            -108.86833333371635,
                            38.753888888861184
                        ],
                        [
                            -109.03333333356647,
                            38.18333333326677
                        ],
                        [
                            -109.07611111103893,
                            37.85666666623371
                        ],
                        [
                            -107.68805555581923,
                            37.80749999972528
                        ],
                        [
                            -107.32871666724037,
                            37.791919444027144
                        ],
                        [
                            -106.88194444468036,
                            37.771388888486456
                        ],
                        [
                            -106.61166666658335,
                            37.87361111036449
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 254,
                "artcc": "zdv",
                "sector": "12",
                "sectype": "high",
                "Shape_Length": 7.777690409208613,
                "Shape_Area": 3.3723696821480513
            }
        },
        {
            "type": "Feature",
            "id": 256,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -105.76249999987505,
                            40.18555555494498
                        ],
                        [
                            -105.51416666715795,
                            40.19638888903603
                        ],
                        [
                            -105.05888888961056,
                            40.21499999995007
                        ],
                        [
                            -105.09111111108888,
                            40.49416666635864
                        ],
                        [
                            -105.14472222265243,
                            40.5633333333667
                        ],
                        [
                            -105.25888888921082,
                            40.61611111063928
                        ],
                        [
                            -105.27361111126373,
                            40.72916666660842
                        ],
                        [
                            -105.37527777784715,
                            40.855833333141845
                        ],
                        [
                            -105.7283333335165,
                            41.05305555536961
                        ],
                        [
                            -106.01666666723287,
                            41.11666666673335
                        ],
                        [
                            -106.55944444460539,
                            41.23083333329174
                        ],
                        [
                            -106.59805555601906,
                            40.40499999975026
                        ],
                        [
                            -106.55250000027473,
                            40.14527777764738
                        ],
                        [
                            -105.76249999987505,
                            40.18555555494498
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 256,
                "artcc": "zdv",
                "sector": "13",
                "sectype": "high",
                "Shape_Length": 4.610742307610431,
                "Shape_Area": 1.2317260033604964
            }
        },
        {
            "type": "Feature",
            "id": 258,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -109.15000000029971,
                            40.23333333366645
                        ],
                        [
                            -107.58333333286697,
                            40.2666666666334
                        ],
                        [
                            -106.96666666713293,
                            40.11666666693321
                        ],
                        [
                            -106.55250000027473,
                            40.14527777764738
                        ],
                        [
                            -105.76249999987505,
                            40.1855555558443
                        ],
                        [
                            -105.51416666625863,
                            40.19638888903603
                        ],
                        [
                            -105.05888888871124,
                            40.21499999995007
                        ],
                        [
                            -105.08666666693307,
                            40.458333333216785
                        ],
                        [
                            -105.09111111108888,
                            40.49416666635864
                        ],
                        [
                            -105.27361111126373,
                            40.72916666660842
                        ],
                        [
                            -105.37527777784715,
                            40.855833333141845
                        ],
                        [
                            -105.7283333335165,
                            41.05305555536961
                        ],
                        [
                            -106.01666666633355,
                            41.11666666673335
                        ],
                        [
                            -106.55944444460539,
                            41.23083333329174
                        ],
                        [
                            -108.0722222223776,
                            41.53749999982517
                        ],
                        [
                            -108.2750000002498,
                            41.36666666713302
                        ],
                        [
                            -109.10000000039969,
                            40.850000000299815
                        ],
                        [
                            -109.15000000029971,
                            40.23333333366645
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 258,
                "artcc": "zdv",
                "sector": "14",
                "sectype": "high",
                "Shape_Length": 9.508206302112344,
                "Shape_Area": 3.9845151233665033
            }
        },
        {
            "type": "Feature",
            "id": 259,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -103.86500000024978,
                            40.09944444380608
                        ],
                        [
                            -103.65111111108888,
                            40.14722222162828
                        ],
                        [
                            -103.48833333331663,
                            40.1830555556694
                        ],
                        [
                            -103.17527777774723,
                            40.251666666483516
                        ],
                        [
                            -102.24722222232765,
                            40.42055555519477
                        ],
                        [
                            -102.27027777829682,
                            41.471944444480584
                        ],
                        [
                            -103.03333333386621,
                            41.32916666630865
                        ],
                        [
                            -103.17500000054957,
                            41.241666666483525
                        ],
                        [
                            -103.87500000004991,
                            40.737499999625356
                        ],
                        [
                            -104.02638888933575,
                            40.542222222277815
                        ],
                        [
                            -104.10472222275234,
                            40.4405555556944
                        ],
                        [
                            -104.14444444475527,
                            40.39944444410588
                        ],
                        [
                            -104.2841666665584,
                            40.25388888856139
                        ],
                        [
                            -104.33305555586918,
                            40.20277777717274
                        ],
                        [
                            -104.39166666688311,
                            40.141666665983905
                        ],
                        [
                            -104.42416666735778,
                            39.97388888876128
                        ],
                        [
                            -103.86500000024978,
                            40.09944444380608
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 259,
                "artcc": "zdv",
                "sector": "15",
                "sectype": "high",
                "Shape_Length": 6.040481006688722,
                "Shape_Area": 1.7324086036470496
            }
        },
        {
            "type": "Feature",
            "id": 261,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -104.14444444475527,
                            40.39944444410588
                        ],
                        [
                            -104.2841666665584,
                            40.25388888856139
                        ],
                        [
                            -104.33305555586918,
                            40.20277777807206
                        ],
                        [
                            -103.48833333331663,
                            40.1830555556694
                        ],
                        [
                            -103.17527777774723,
                            40.251666666483516
                        ],
                        [
                            -102.24722222232765,
                            40.42055555519477
                        ],
                        [
                            -101.28944444470534,
                            40.56916666710805
                        ],
                        [
                            -101.30305555526962,
                            41.640833333191836
                        ],
                        [
                            -101.91444444435558,
                            41.53638888923592
                        ],
                        [
                            -102.2702777773975,
                            41.471944444480584
                        ],
                        [
                            -103.03333333296689,
                            41.32916666630865
                        ],
                        [
                            -103.17499999965025,
                            41.241666666483525
                        ],
                        [
                            -103.87500000004991,
                            40.737499999625356
                        ],
                        [
                            -104.02638888933575,
                            40.542222222277815
                        ],
                        [
                            -104.10472222185302,
                            40.4405555556944
                        ],
                        [
                            -104.14444444475527,
                            40.39944444410588
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 261,
                "artcc": "zdv",
                "sector": "16",
                "sectype": "high",
                "Shape_Length": 7.642155141384325,
                "Shape_Area": 2.6569878468440904
            }
        },
        {
            "type": "Feature",
            "id": 262,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -101.1583333340161,
                            38.258333333116866
                        ],
                        [
                            -100.35284444491515,
                            38.68238888853642
                        ],
                        [
                            -100.23388888936074,
                            38.74416666625871
                        ],
                        [
                            -99.63055555609401,
                            39.05833333331674
                        ],
                        [
                            -99.21666666733279,
                            39.26666666683326
                        ],
                        [
                            -98.80000000029975,
                            39.46666666643358
                        ],
                        [
                            -99.05833333371635,
                            39.983333333266785
                        ],
                        [
                            -101.32416666725783,
                            39.87249999937552
                        ],
                        [
                            -102.28444444505504,
                            39.82111111078916
                        ],
                        [
                            -103.20944444500509,
                            39.76805555541961
                        ],
                        [
                            -103.24222222267741,
                            39.3338888889611
                        ],
                        [
                            -102.92972222240263,
                            39.261388888386534
                        ],
                        [
                            -102.33305555626885,
                            39.01749999982519
                        ],
                        [
                            -102.2333333336664,
                            38.98333333256727
                        ],
                        [
                            -101.92500000034971,
                            38.75000000000006
                        ],
                        [
                            -101.23333333386626,
                            38.218611111113944
                        ],
                        [
                            -101.1583333340161,
                            38.258333333116866
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 262,
                "artcc": "zdv",
                "sector": "17",
                "sectype": "high",
                "Shape_Length": 10.234442495970713,
                "Shape_Area": 4.434942647431371
            }
        },
        {
            "type": "Feature",
            "id": 264,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -101.1583333340161,
                            38.258333333116866
                        ],
                        [
                            -100.35284444491515,
                            38.68238888853642
                        ],
                        [
                            -100.23388888936074,
                            38.74416666625871
                        ],
                        [
                            -99.63055555609401,
                            39.05833333331674
                        ],
                        [
                            -99.21666666733279,
                            39.26666666683326
                        ],
                        [
                            -98.80000000029975,
                            39.46666666643358
                        ],
                        [
                            -99.05833333371635,
                            39.983333333266785
                        ],
                        [
                            -99.05555555544447,
                            40.23055555539463
                        ],
                        [
                            -99.05416666675825,
                            40.349999999500426
                        ],
                        [
                            -99.04722222242759,
                            40.88333333326676
                        ],
                        [
                            -99.03333333376628,
                            41.07499999985015
                        ],
                        [
                            -99.02499999985008,
                            41.71666666643358
                        ],
                        [
                            -99.01666666683315,
                            41.999999999800195
                        ],
                        [
                            -100.24138888898602,
                            41.813888888861186
                        ],
                        [
                            -101.30305555616894,
                            41.640833333191836
                        ],
                        [
                            -101.91444444435558,
                            41.5363888883366
                        ],
                        [
                            -102.27027777829682,
                            41.471944444480584
                        ],
                        [
                            -102.24722222232765,
                            40.42055555519477
                        ],
                        [
                            -102.28444444505504,
                            39.82111111078916
                        ],
                        [
                            -102.33305555626885,
                            39.01749999982519
                        ],
                        [
                            -102.2333333336664,
                            38.98333333256727
                        ],
                        [
                            -101.92500000034971,
                            38.75000000000006
                        ],
                        [
                            -101.23333333386626,
                            38.218611111113944
                        ],
                        [
                            -101.1583333340161,
                            38.258333333116866
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 264,
                "artcc": "zdv",
                "sector": "19",
                "sectype": "high",
                "Shape_Length": 12.448002835475956,
                "Shape_Area": 9.801760279706311
            }
        },
        {
            "type": "Feature",
            "id": 266,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -104.39166666688311,
                            40.141666665983905
                        ],
                        [
                            -104.33305555586918,
                            40.20277777717274
                        ],
                        [
                            -104.2841666665584,
                            40.25388888856139
                        ],
                        [
                            -104.14444444475527,
                            40.39944444410588
                        ],
                        [
                            -104.10472222275234,
                            40.4405555556944
                        ],
                        [
                            -104.02638888933575,
                            40.542222222277815
                        ],
                        [
                            -103.87500000004991,
                            40.737499999625356
                        ],
                        [
                            -103.17500000054957,
                            41.241666666483525
                        ],
                        [
                            -103.38333333316677,
                            41.558333332817085
                        ],
                        [
                            -104.59166666738275,
                            41.80833333321681
                        ],
                        [
                            -105.1375000002248,
                            41.81249999927559
                        ],
                        [
                            -106.02083333329165,
                            41.52499999985014
                        ],
                        [
                            -106.01666666723287,
                            41.11666666673335
                        ],
                        [
                            -105.7283333335165,
                            41.05305555536961
                        ],
                        [
                            -105.37527777784715,
                            40.855833333141845
                        ],
                        [
                            -105.27361111126373,
                            40.72916666660842
                        ],
                        [
                            -105.25888888921082,
                            40.61611111063928
                        ],
                        [
                            -105.14472222265243,
                            40.5633333333667
                        ],
                        [
                            -105.09111111108888,
                            40.49416666635864
                        ],
                        [
                            -105.05888888961056,
                            40.21499999995007
                        ],
                        [
                            -105.05000000039968,
                            40.141666665983905
                        ],
                        [
                            -105.08277777807194,
                            39.98555555534466
                        ],
                        [
                            -104.42416666735778,
                            39.97388888876128
                        ],
                        [
                            -104.39166666688311,
                            40.141666665983905
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 266,
                "artcc": "zdv",
                "sector": "21",
                "sectype": "high",
                "Shape_Length": 7.681955845657747,
                "Shape_Area": 3.035419058091425
            }
        },
        {
            "type": "Feature",
            "id": 268,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -104.38333333386623,
                            42.283333333166865
                        ],
                        [
                            -104.72361111146358,
                            43.16666666623371
                        ],
                        [
                            -104.85833333381629,
                            43.48333333256727
                        ],
                        [
                            -104.84999999990004,
                            43.81666666673334
                        ],
                        [
                            -104.76944444440556,
                            45.14999999980023
                        ],
                        [
                            -106.00000000029974,
                            45.237499999625356
                        ],
                        [
                            -106.14166666698304,
                            44.7666666666334
                        ],
                        [
                            -106.26666666673327,
                            44.31666666663341
                        ],
                        [
                            -107.28333333346654,
                            43.883333332667235
                        ],
                        [
                            -107.1333333337663,
                            42.94999999970025
                        ],
                        [
                            -107.07361111126374,
                            42.54305555526969
                        ],
                        [
                            -107.06666666693309,
                            42.48333333276713
                        ],
                        [
                            -107.04999999999995,
                            42.41666666683324
                        ],
                        [
                            -107.99999999990001,
                            41.608333332717166
                        ],
                        [
                            -108.0722222223776,
                            41.53749999982517
                        ],
                        [
                            -106.55944444460539,
                            41.23083333329174
                        ],
                        [
                            -106.01666666723287,
                            41.11666666673335
                        ],
                        [
                            -106.02083333329165,
                            41.52499999985014
                        ],
                        [
                            -105.1375000002248,
                            41.81249999927559
                        ],
                        [
                            -104.59166666738275,
                            41.80833333321681
                        ],
                        [
                            -104.38333333386623,
                            42.283333333166865
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 268,
                "artcc": "zdv",
                "sector": "22",
                "sectype": "high",
                "Shape_Length": 13.591496719417965,
                "Shape_Area": 8.496179976388051
            }
        },
        {
            "type": "Feature",
            "id": 270,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -111.87916666630855,
                            37.41249999997507
                        ],
                        [
                            -111.71844444465535,
                            37.00500000024988
                        ],
                        [
                            -111.60833333291697,
                            36.733333333466646
                        ],
                        [
                            -111.53555555604402,
                            36.51500000014994
                        ],
                        [
                            -111.50416666705803,
                            36.42083333319181
                        ],
                        [
                            -111.8416666663835,
                            35.7666666666334
                        ],
                        [
                            -110.23333333296694,
                            35.699999999800184
                        ],
                        [
                            -109.31666666693309,
                            35.850000000399746
                        ],
                        [
                            -109.07611111103893,
                            37.85666666713303
                        ],
                        [
                            -109.03333333356647,
                            38.18333333326677
                        ],
                        [
                            -109.98333333346653,
                            38.20000000019991
                        ],
                        [
                            -111.31388888916086,
                            37.65000000039976
                        ],
                        [
                            -111.87916666630855,
                            37.41249999997507
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 270,
                "artcc": "zdv",
                "sector": "23",
                "sectype": "high",
                "Shape_Length": 9.688787067743345,
                "Shape_Area": 5.533987963324753
            }
        },
        {
            "type": "Feature",
            "id": 272,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -108.33333333316676,
                            38.499999999600334
                        ],
                        [
                            -107.05555555564433,
                            37.84722222262752
                        ],
                        [
                            -106.1494444447053,
                            38.04527777824694
                        ],
                        [
                            -105.93005277751365,
                            38.12045000006748
                        ],
                        [
                            -105.68888888871123,
                            38.4888888892109
                        ],
                        [
                            -105.19694444463039,
                            39.20027777809702
                        ],
                        [
                            -105.04583333344152,
                            39.34583333364151
                        ],
                        [
                            -105.00916666690813,
                            39.411388888986096
                        ],
                        [
                            -105.18194444448051,
                            39.5016666670831
                        ],
                        [
                            -105.48111111138866,
                            39.47611111093909
                        ],
                        [
                            -105.551666667083,
                            39.47000000000003
                        ],
                        [
                            -106.72722222182801,
                            39.079999999700306
                        ],
                        [
                            -108.33333333316676,
                            38.499999999600334
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 272,
                "artcc": "zdv",
                "sector": "25",
                "sectype": "high",
                "Shape_Length": 7.696678183543677,
                "Shape_Area": 2.6454770667269276
            }
        },
        {
            "type": "Feature",
            "id": 273,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -105.68888888961055,
                            38.48888888831158
                        ],
                        [
                            -105.35472222205289,
                            38.97166666598389
                        ],
                        [
                            -105.15555555584422,
                            39.134166666558485
                        ],
                        [
                            -105.19500000064949,
                            39.25777777762238
                        ],
                        [
                            -105.18194444448051,
                            39.50166666618372
                        ],
                        [
                            -105.48111111138866,
                            39.47611111093909
                        ],
                        [
                            -105.551666667083,
                            39.47000000000003
                        ],
                        [
                            -105.72138888918585,
                            39.46305555566943
                        ],
                        [
                            -106.44166666728285,
                            39.433333332567315
                        ],
                        [
                            -106.96445833328539,
                            39.360649999797715
                        ],
                        [
                            -106.68861111131372,
                            39.03388888866135
                        ],
                        [
                            -106.24655833389994,
                            38.505324999649076
                        ],
                        [
                            -105.93005277841297,
                            38.12045000006748
                        ],
                        [
                            -105.68888888961055,
                            38.48888888831158
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 273,
                "artcc": "zdv",
                "sector": "26",
                "sectype": "high",
                "Shape_Length": 5.063163613719619,
                "Shape_Area": 1.3041431984281395
            }
        },
        {
            "type": "Feature",
            "id": 275,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -101.23333333386626,
                            38.218611111113944
                        ],
                        [
                            -101.92500000034971,
                            38.75000000000006
                        ],
                        [
                            -102.2333333336664,
                            38.98333333256727
                        ],
                        [
                            -102.33305555626885,
                            39.01749999982519
                        ],
                        [
                            -102.92972222240263,
                            39.261388888386534
                        ],
                        [
                            -103.24222222267741,
                            39.3338888889611
                        ],
                        [
                            -103.67166666688314,
                            39.43222222197801
                        ],
                        [
                            -103.72222222207785,
                            39.447777777422516
                        ],
                        [
                            -103.92444444465536,
                            39.51027777729763
                        ],
                        [
                            -104.31916666720787,
                            39.500833332792126
                        ],
                        [
                            -104.26416666695809,
                            39.25027777709778
                        ],
                        [
                            -104.26055555619394,
                            39.23333333296699
                        ],
                        [
                            -104.25250000037471,
                            39.19666666643354
                        ],
                        [
                            -104.20651944481648,
                            39.102583333354175
                        ],
                        [
                            -104.08333333356643,
                            38.84999999980022
                        ],
                        [
                            -104.05361111136364,
                            38.60083333279215
                        ],
                        [
                            -104.04555555554441,
                            38.49916666620874
                        ],
                        [
                            -103.89222222227772,
                            38.485277777547424
                        ],
                        [
                            -103.75583333314177,
                            38.38722222172822
                        ],
                        [
                            -102.54999999999995,
                            37.499999999800195
                        ],
                        [
                            -101.23333333386626,
                            38.218611111113944
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 275,
                "artcc": "zdv",
                "sector": "27",
                "sectype": "high",
                "Shape_Length": 7.798696771734866,
                "Shape_Area": 3.1766834045762673
            }
        },
        {
            "type": "Feature",
            "id": 277,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -102.54999999999995,
                            37.499999999800195
                        ],
                        [
                            -101.23333333296688,
                            38.218611111113944
                        ],
                        [
                            -101.92500000034971,
                            38.75000000000006
                        ],
                        [
                            -102.2333333336664,
                            38.983333333466646
                        ],
                        [
                            -102.33305555536953,
                            39.01749999982519
                        ],
                        [
                            -102.92972222240263,
                            39.261388889285854
                        ],
                        [
                            -103.24222222177809,
                            39.3338888889611
                        ],
                        [
                            -103.67166666688314,
                            39.43222222197801
                        ],
                        [
                            -104.30277777747244,
                            39.42638888913598
                        ],
                        [
                            -104.25250000037471,
                            39.19666666643354
                        ],
                        [
                            -104.20651944481648,
                            39.102583333354175
                        ],
                        [
                            -104.08333333356643,
                            38.84999999980022
                        ],
                        [
                            -104.05361111136364,
                            38.60083333369147
                        ],
                        [
                            -103.89222222227772,
                            38.485277777547424
                        ],
                        [
                            -103.75583333314177,
                            38.38722222262754
                        ],
                        [
                            -102.54999999999995,
                            37.499999999800195
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 277,
                "artcc": "zdv",
                "sector": "28",
                "sectype": "high",
                "Shape_Length": 7.636763977027007,
                "Shape_Area": 3.128813652307231
            }
        },
        {
            "type": "Feature",
            "id": 278,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -106.34999999960024,
                            36.62694444463045
                        ],
                        [
                            -106.08333333316676,
                            36.71666666653351
                        ],
                        [
                            -105.34166666678323,
                            36.71666666653351
                        ],
                        [
                            -104.99999999960028,
                            36.71666666653351
                        ],
                        [
                            -103.99999999980014,
                            37.045277777547426
                        ],
                        [
                            -103.61944444400586,
                            37.162499999575346
                        ],
                        [
                            -102.54999999999995,
                            37.499999999800195
                        ],
                        [
                            -103.75583333314177,
                            38.38722222262754
                        ],
                        [
                            -103.89222222227772,
                            38.485277777547424
                        ],
                        [
                            -104.05361111136364,
                            38.60083333369147
                        ],
                        [
                            -104.08333333356643,
                            38.84999999980022
                        ],
                        [
                            -104.20651944481648,
                            39.102583333354175
                        ],
                        [
                            -104.25250000037471,
                            39.19666666643354
                        ],
                        [
                            -104.30277777747244,
                            39.42638888913598
                        ],
                        [
                            -105.00916666690813,
                            39.411388888986096
                        ],
                        [
                            -105.04583333344152,
                            39.34583333364151
                        ],
                        [
                            -105.19694444463039,
                            39.20027777809702
                        ],
                        [
                            -105.68888888871123,
                            38.4888888892109
                        ],
                        [
                            -105.93005277751365,
                            38.12045000006748
                        ],
                        [
                            -106.1494444447053,
                            38.04527777824694
                        ],
                        [
                            -106.34999999960024,
                            36.62694444463045
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 278,
                "artcc": "zdv",
                "sector": "29",
                "sectype": "high",
                "Shape_Length": 10.633397004699814,
                "Shape_Area": 5.878682274934643
            }
        },
        {
            "type": "Feature",
            "id": 280,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -103.03333333386621,
                            41.32916666630865
                        ],
                        [
                            -102.27027777829682,
                            41.471944444480584
                        ],
                        [
                            -101.91444444435558,
                            41.5363888883366
                        ],
                        [
                            -101.30305555616894,
                            41.640833333191836
                        ],
                        [
                            -100.24138888898602,
                            41.813888888861186
                        ],
                        [
                            -99.01666666683315,
                            41.999999999800195
                        ],
                        [
                            -99.96666666673326,
                            43.13333333326676
                        ],
                        [
                            -100.10000000039969,
                            43.28888888861138
                        ],
                        [
                            -101.40833333351651,
                            43.70833333301698
                        ],
                        [
                            -101.4833333333666,
                            44.699999999800184
                        ],
                        [
                            -103.16666666663332,
                            44.958333333216785
                        ],
                        [
                            -104.2500000001998,
                            45.11666666683328
                        ],
                        [
                            -104.76944444440556,
                            45.14999999980023
                        ],
                        [
                            -104.84999999990004,
                            43.81666666673334
                        ],
                        [
                            -104.85833333381629,
                            43.48333333256727
                        ],
                        [
                            -104.72361111146358,
                            43.16666666623371
                        ],
                        [
                            -104.38333333386623,
                            42.283333333166865
                        ],
                        [
                            -104.59166666738275,
                            41.80833333321681
                        ],
                        [
                            -103.38333333316677,
                            41.558333332817085
                        ],
                        [
                            -103.17500000054957,
                            41.241666666483525
                        ],
                        [
                            -103.03333333386621,
                            41.32916666630865
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 280,
                "artcc": "zdv",
                "sector": "31",
                "sectype": "high",
                "Shape_Length": 16.70126741026665,
                "Shape_Area": 14.202247300129073
            }
        },
        {
            "type": "Feature",
            "id": 282,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -107.06666666693309,
                            42.48333333366645
                        ],
                        [
                            -104.68333333326666,
                            42.733333333166854
                        ],
                        [
                            -102.4500000001998,
                            42.85833333291703
                        ],
                        [
                            -101.40833333351651,
                            43.70833333301698
                        ],
                        [
                            -101.4833333333666,
                            44.699999999800184
                        ],
                        [
                            -103.16666666663332,
                            44.958333333216785
                        ],
                        [
                            -104.2500000001998,
                            45.11666666683328
                        ],
                        [
                            -104.76944444440556,
                            45.14999999980023
                        ],
                        [
                            -106.00000000029974,
                            45.237499999625356
                        ],
                        [
                            -106.14166666698304,
                            44.7666666666334
                        ],
                        [
                            -106.26666666673327,
                            44.31666666663341
                        ],
                        [
                            -107.28333333346654,
                            43.883333333566554
                        ],
                        [
                            -107.13333333286698,
                            42.94999999970025
                        ],
                        [
                            -107.07361111126374,
                            42.54305555526969
                        ],
                        [
                            -107.06666666693309,
                            42.48333333366645
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 282,
                "artcc": "zdv",
                "sector": "32",
                "sectype": "high",
                "Shape_Length": 15.004879939218565,
                "Shape_Area": 11.829375000041246
            }
        },
        {
            "type": "Feature",
            "id": 283,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -104.68333333326666,
                            42.733333333166854
                        ],
                        [
                            -104.54999999960029,
                            41.21249999957536
                        ],
                        [
                            -104.6538888891609,
                            40.208888889011064
                        ],
                        [
                            -104.33305555586918,
                            40.20277777807206
                        ],
                        [
                            -104.2841666665584,
                            40.25388888856139
                        ],
                        [
                            -104.14444444475527,
                            40.39944444410588
                        ],
                        [
                            -104.10472222185302,
                            40.4405555556944
                        ],
                        [
                            -104.02638888933575,
                            40.542222222277815
                        ],
                        [
                            -103.87500000004991,
                            40.737499999625356
                        ],
                        [
                            -103.17499999965025,
                            41.241666666483525
                        ],
                        [
                            -103.03333333296689,
                            41.32916666630865
                        ],
                        [
                            -102.2702777773975,
                            41.471944444480584
                        ],
                        [
                            -101.91444444435558,
                            41.53638888923592
                        ],
                        [
                            -102.4500000001998,
                            42.85833333291703
                        ],
                        [
                            -104.68333333326666,
                            42.733333333166854
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 283,
                "artcc": "zdv",
                "sector": "33",
                "sectype": "high",
                "Shape_Length": 9.391851154129087,
                "Shape_Area": 4.4779839884632855
            }
        },
        {
            "type": "Feature",
            "id": 284,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -105.09111111108888,
                            40.49416666635864
                        ],
                        [
                            -105.08666666693307,
                            40.458333333216785
                        ],
                        [
                            -105.05888888871124,
                            40.21499999995007
                        ],
                        [
                            -104.6538888891609,
                            40.208888889011064
                        ],
                        [
                            -104.54999999960029,
                            41.21249999957536
                        ],
                        [
                            -104.68333333326666,
                            42.733333333166854
                        ],
                        [
                            -107.06666666693309,
                            42.48333333366645
                        ],
                        [
                            -107.04999999999995,
                            42.41666666683324
                        ],
                        [
                            -107.99999999990001,
                            41.608333333616486
                        ],
                        [
                            -108.0722222223776,
                            41.53749999982517
                        ],
                        [
                            -106.55944444460539,
                            41.23083333329174
                        ],
                        [
                            -106.01666666633355,
                            41.11666666673335
                        ],
                        [
                            -105.7283333335165,
                            41.05305555536961
                        ],
                        [
                            -105.37527777784715,
                            40.855833333141845
                        ],
                        [
                            -105.27361111126373,
                            40.72916666660842
                        ],
                        [
                            -105.09111111108888,
                            40.49416666635864
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 284,
                "artcc": "zdv",
                "sector": "34",
                "sectype": "high",
                "Shape_Length": 10.293194148381431,
                "Shape_Area": 4.72008973883578
            }
        },
        {
            "type": "Feature",
            "id": 285,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -102.4500000001998,
                            42.85833333291703
                        ],
                        [
                            -101.91444444435558,
                            41.53638888923592
                        ],
                        [
                            -101.30305555526962,
                            41.640833333191836
                        ],
                        [
                            -100.24138888898602,
                            41.813888888861186
                        ],
                        [
                            -99.01666666683315,
                            41.999999999800195
                        ],
                        [
                            -99.96666666673326,
                            43.13333333326676
                        ],
                        [
                            -100.10000000039969,
                            43.28888888861138
                        ],
                        [
                            -101.40833333351651,
                            43.70833333301698
                        ],
                        [
                            -102.4500000001998,
                            42.85833333291703
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 285,
                "artcc": "zdv",
                "sector": "35",
                "sectype": "high",
                "Shape_Length": 8.763110341055539,
                "Shape_Area": 4.546542823296264
            }
        },
        {
            "type": "Feature",
            "id": 286,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -106.61166666658335,
                            37.87361111036449
                        ],
                        [
                            -106.88194444468036,
                            37.771388888486456
                        ],
                        [
                            -107.32871666724037,
                            37.791919444027144
                        ],
                        [
                            -107.68805555581923,
                            37.80749999972528
                        ],
                        [
                            -109.07611111103893,
                            37.85666666623371
                        ],
                        [
                            -109.31666666693309,
                            35.849999999500426
                        ],
                        [
                            -108.21666666733279,
                            36.033333333066935
                        ],
                        [
                            -107.466666667033,
                            36.19999999970025
                        ],
                        [
                            -106.35000000049956,
                            36.626944443731134
                        ],
                        [
                            -106.43640555597159,
                            37.042888888611344
                        ],
                        [
                            -106.61166666658335,
                            37.87361111036449
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 286,
                "artcc": "zdv",
                "sector": "36",
                "sectype": "high",
                "Shape_Length": 8.858646885400141,
                "Shape_Area": 4.526260028048156
            }
        },
        {
            "type": "Feature",
            "id": 288,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -109.03333333356647,
                            38.18333333326677
                        ],
                        [
                            -109.07611111103893,
                            37.85666666713303
                        ],
                        [
                            -109.31666666693309,
                            35.850000000399746
                        ],
                        [
                            -108.21666666643347,
                            36.033333333066935
                        ],
                        [
                            -107.466666667033,
                            36.19999999970025
                        ],
                        [
                            -106.34999999960024,
                            36.62694444463045
                        ],
                        [
                            -106.1494444447053,
                            38.04527777824694
                        ],
                        [
                            -107.05555555564433,
                            37.84722222262752
                        ],
                        [
                            -108.33333333316676,
                            38.499999999600334
                        ],
                        [
                            -109.03333333356647,
                            38.18333333326677
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 288,
                "artcc": "zdv",
                "sector": "37",
                "sectype": "high",
                "Shape_Length": 9.992566430447258,
                "Shape_Area": 5.718754090375498
            }
        },
        {
            "type": "Feature",
            "id": 291,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -106.08333333316676,
                            36.71666666653351
                        ],
                        [
                            -105.34166666678323,
                            36.71666666653351
                        ],
                        [
                            -105.0000000004996,
                            36.71666666653351
                        ],
                        [
                            -104.00000000069946,
                            37.045277777547426
                        ],
                        [
                            -103.61944444490518,
                            37.162499999575346
                        ],
                        [
                            -102.54999999999995,
                            37.499999999800195
                        ],
                        [
                            -103.75583333314177,
                            38.38722222172822
                        ],
                        [
                            -103.89222222227772,
                            38.485277777547424
                        ],
                        [
                            -104.04555555554441,
                            38.49916666620874
                        ],
                        [
                            -104.05361111136364,
                            38.60083333279215
                        ],
                        [
                            -104.08333333356643,
                            38.84999999980022
                        ],
                        [
                            -104.20651944481648,
                            39.102583333354175
                        ],
                        [
                            -104.25250000037471,
                            39.19666666643354
                        ],
                        [
                            -104.26055555619394,
                            39.23333333296699
                        ],
                        [
                            -104.26416666695809,
                            39.25027777709778
                        ],
                        [
                            -104.31916666720787,
                            39.500833332792126
                        ],
                        [
                            -104.42416666735778,
                            39.97388888876128
                        ],
                        [
                            -105.08277777807194,
                            39.98555555534466
                        ],
                        [
                            -105.18194444448051,
                            39.50166666618372
                        ],
                        [
                            -105.19500000064949,
                            39.25777777762238
                        ],
                        [
                            -105.15555555584422,
                            39.134166666558485
                        ],
                        [
                            -105.35472222205289,
                            38.97166666598389
                        ],
                        [
                            -105.68888888961055,
                            38.48888888831158
                        ],
                        [
                            -105.93005277841297,
                            38.12045000006748
                        ],
                        [
                            -106.1494444447053,
                            38.04527777734762
                        ],
                        [
                            -106.61166666658335,
                            37.87361111036449
                        ],
                        [
                            -106.43640555597159,
                            37.042888888611344
                        ],
                        [
                            -106.35000000049956,
                            36.626944443731134
                        ],
                        [
                            -106.08333333316676,
                            36.71666666653351
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 291,
                "artcc": "zdv",
                "sector": "41",
                "sectype": "high",
                "Shape_Length": 12.10056153101688,
                "Shape_Area": 6.628028433739317
            }
        },
        {
            "type": "Feature",
            "id": 295,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -103.17527777774723,
                            40.251666666483516
                        ],
                        [
                            -103.48833333331663,
                            40.1830555556694
                        ],
                        [
                            -104.33305555586918,
                            40.20277777717274
                        ],
                        [
                            -104.6538888891609,
                            40.208888889011064
                        ],
                        [
                            -105.05888888961056,
                            40.21499999995007
                        ],
                        [
                            -105.05000000039968,
                            40.141666665983905
                        ],
                        [
                            -105.08277777807194,
                            39.98555555534466
                        ],
                        [
                            -105.18194444448051,
                            39.50166666618372
                        ],
                        [
                            -105.00916666690813,
                            39.411388888986096
                        ],
                        [
                            -104.30277777837176,
                            39.42638888823666
                        ],
                        [
                            -103.67166666688314,
                            39.43222222197801
                        ],
                        [
                            -103.24222222267741,
                            39.3338888889611
                        ],
                        [
                            -103.20944444500509,
                            39.76805555541961
                        ],
                        [
                            -103.17527777774723,
                            40.251666666483516
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 295,
                "artcc": "zdv",
                "sector": "61",
                "sectype": "high",
                "Shape_Length": 5.512112310718025,
                "Shape_Area": 1.50184394298398
            }
        },
        {
            "type": "Feature",
            "id": 299,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -111.8416666663835,
                            35.7666666666334
                        ],
                        [
                            -110.23333333296694,
                            35.699999999800184
                        ],
                        [
                            -109.31666666693309,
                            35.850000000399746
                        ],
                        [
                            -109.07611111103893,
                            37.85666666713303
                        ],
                        [
                            -109.03333333356647,
                            38.18333333326677
                        ],
                        [
                            -109.98333333346653,
                            38.20000000019991
                        ],
                        [
                            -111.31388888916086,
                            37.65000000039976
                        ],
                        [
                            -111.87916666630855,
                            37.41249999997507
                        ],
                        [
                            -111.71844444465535,
                            37.00500000024988
                        ],
                        [
                            -111.60833333291697,
                            36.733333333466646
                        ],
                        [
                            -111.53555555604402,
                            36.51500000014994
                        ],
                        [
                            -111.50416666705803,
                            36.42083333319181
                        ],
                        [
                            -111.8416666663835,
                            35.7666666666334
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 299,
                "artcc": "zdv",
                "sector": "68",
                "sectype": "high",
                "Shape_Length": 9.688787067743343,
                "Shape_Area": 5.533987963324752
            }
        },
        {
            "type": "Feature",
            "id": 301,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -109.15000000029971,
                            40.23333333366645
                        ],
                        [
                            -107.58333333286697,
                            40.2666666666334
                        ],
                        [
                            -106.96666666713293,
                            40.11666666693321
                        ],
                        [
                            -106.55250000027473,
                            40.14527777764738
                        ],
                        [
                            -106.59805555601906,
                            40.40499999975026
                        ],
                        [
                            -106.55944444460539,
                            41.23083333329174
                        ],
                        [
                            -108.0722222223776,
                            41.53749999982517
                        ],
                        [
                            -108.2750000002498,
                            41.36666666713302
                        ],
                        [
                            -109.10000000039969,
                            40.850000000299815
                        ],
                        [
                            -109.15000000029971,
                            40.23333333366645
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 301,
                "artcc": "zdv",
                "sector": "77",
                "sectype": "high",
                "Shape_Length": 7.108062605377971,
                "Shape_Area": 2.7466931325837334
            }
        },
        {
            "type": "Feature",
            "id": 317,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -91.79999999990002,
                            32.1499999997003
                        ],
                        [
                            -91.6888888888111,
                            32.28333333336667
                        ],
                        [
                            -91.53333333346654,
                            32.43333333306691
                        ],
                        [
                            -91.9000000005995,
                            33.21666666633365
                        ],
                        [
                            -92.0833333332667,
                            33.59027777779727
                        ],
                        [
                            -92.68111111178837,
                            33.59388888856142
                        ],
                        [
                            -92.91666666733278,
                            33.7666666661338
                        ],
                        [
                            -92.99166666718293,
                            33.82499999995008
                        ],
                        [
                            -93.82500000034969,
                            33.38944444390603
                        ],
                        [
                            -94.3955555559441,
                            32.8805555556944
                        ],
                        [
                            -92.81222222247754,
                            32.79305555496995
                        ],
                        [
                            -92.95500000064948,
                            32.6666666665335
                        ],
                        [
                            -92.94444444465535,
                            32.61833333341667
                        ],
                        [
                            -92.8625000004746,
                            32.191666666583444
                        ],
                        [
                            -92.52222222287725,
                            32.1083333328171
                        ],
                        [
                            -91.79999999990002,
                            32.1499999997003
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 317,
                "artcc": "zfw",
                "sector": "28",
                "sectype": "high",
                "Shape_Length": 7.694604778880848,
                "Shape_Area": 2.5440868057085884
            }
        },
        {
            "type": "Feature",
            "id": 327,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -94.92499999994999,
                            34.933333333466635
                        ],
                        [
                            -95.63333333336658,
                            34.68333333306691
                        ],
                        [
                            -96.31888888891103,
                            33.80694444433067
                        ],
                        [
                            -96.33333333286697,
                            33.171944443980976
                        ],
                        [
                            -94.29638888863627,
                            33.841666666883214
                        ],
                        [
                            -93.65833333371631,
                            34.08888888901106
                        ],
                        [
                            -93.99999999999994,
                            34.26666666693319
                        ],
                        [
                            -94.53333333286696,
                            34.53333333336667
                        ],
                        [
                            -94.78333333326668,
                            34.69166666698317
                        ],
                        [
                            -94.81666666713295,
                            34.755555555544504
                        ],
                        [
                            -94.92499999994999,
                            34.933333333466635
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 327,
                "artcc": "zfw",
                "sector": "42",
                "sectype": "high",
                "Shape_Length": 6.885092721546607,
                "Shape_Area": 2.3028163968948916
            }
        },
        {
            "type": "Feature",
            "id": 329,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -97.51666666713294,
                            33.33194444438067
                        ],
                        [
                            -97.51666666713294,
                            33.08333333356654
                        ],
                        [
                            -97.51666666713294,
                            32.91249999997507
                        ],
                        [
                            -97.51666666713294,
                            32.608333333616486
                        ],
                        [
                            -97.51666666713294,
                            32.36666666713302
                        ],
                        [
                            -97.51666666713294,
                            31.666666666733363
                        ],
                        [
                            -97.58333333306683,
                            31.266666666633398
                        ],
                        [
                            -97.51250000017484,
                            31.21249999977522
                        ],
                        [
                            -97.19999999990006,
                            31.199999999800184
                        ],
                        [
                            -97.12500000004991,
                            31.199999999800184
                        ],
                        [
                            -97.00000000029974,
                            31.20416666675834
                        ],
                        [
                            -96.68333333306686,
                            31.20416666675834
                        ],
                        [
                            -96.5125000003747,
                            31.266666666633398
                        ],
                        [
                            -96.26666666693308,
                            31.35833333341668
                        ],
                        [
                            -96.4188888887112,
                            31.813333332867046
                        ],
                        [
                            -96.36666666673324,
                            32.36666666713302
                        ],
                        [
                            -96.35583333354145,
                            32.63055555529468
                        ],
                        [
                            -96.34361111076413,
                            32.91138888848644
                        ],
                        [
                            -96.33333333286697,
                            33.171944443980976
                        ],
                        [
                            -97.51666666713294,
                            33.33194444438067
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 329,
                "artcc": "zfw",
                "sector": "46",
                "sectype": "high",
                "Shape_Length": 6.469376872567702,
                "Shape_Area": 2.3659259651113302
            }
        },
        {
            "type": "Feature",
            "id": 330,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -100.14166666638351,
                            35.12916666700812
                        ],
                        [
                            -100.31666666693309,
                            34.86666666663342
                        ],
                        [
                            -100.74999999999994,
                            34.46666666653351
                        ],
                        [
                            -100.40833333371631,
                            33.999999999600334
                        ],
                        [
                            -97.7333333336664,
                            33.17500000034977
                        ],
                        [
                            -97.51666666713294,
                            33.08333333356654
                        ],
                        [
                            -97.51666666713294,
                            33.33194444438067
                        ],
                        [
                            -97.7541666666583,
                            33.667222222527585
                        ],
                        [
                            -97.914166667058,
                            34.070555555294675
                        ],
                        [
                            -98.45055555539454,
                            34.72222222257756
                        ],
                        [
                            -99.04166666678321,
                            34.95000000039977
                        ],
                        [
                            -100.14166666638351,
                            35.12916666700812
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 330,
                "artcc": "zfw",
                "sector": "47",
                "sectype": "high",
                "Shape_Length": 8.203571309423282,
                "Shape_Area": 3.3864882339460927
            }
        },
        {
            "type": "Feature",
            "id": 331,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -98.45055555539454,
                            34.72222222257756
                        ],
                        [
                            -97.914166667058,
                            34.070555555294675
                        ],
                        [
                            -97.7541666666583,
                            33.667222222527585
                        ],
                        [
                            -97.51666666713294,
                            33.33194444438067
                        ],
                        [
                            -96.33333333286697,
                            33.171944443980976
                        ],
                        [
                            -96.31888888891103,
                            33.80694444433067
                        ],
                        [
                            -95.63333333336658,
                            34.68333333306691
                        ],
                        [
                            -97.15972222260245,
                            35.170555555794294
                        ],
                        [
                            -98.45055555539454,
                            34.72222222257756
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 331,
                "artcc": "zfw",
                "sector": "48",
                "sectype": "high",
                "Shape_Length": 7.599489524021953,
                "Shape_Area": 3.2882336044404434
            }
        },
        {
            "type": "Feature",
            "id": 332,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -99.99999999970021,
                            35.82916666650851
                        ],
                        [
                            -99.99999999970021,
                            35.33333333356654
                        ],
                        [
                            -100.06111111088904,
                            35.249999999800195
                        ],
                        [
                            -100.14166666638351,
                            35.12916666700812
                        ],
                        [
                            -99.04166666678321,
                            34.95000000039977
                        ],
                        [
                            -98.45055555539454,
                            34.72222222257756
                        ],
                        [
                            -97.15972222260245,
                            35.170555555794294
                        ],
                        [
                            -97.18805555521965,
                            35.466388889136
                        ],
                        [
                            -97.58333333306683,
                            35.49166666628366
                        ],
                        [
                            -97.88333333336658,
                            35.92500000024984
                        ],
                        [
                            -98.01666666703301,
                            35.88333333336669
                        ],
                        [
                            -98.14833333301686,
                            35.86250000037478
                        ],
                        [
                            -98.466666667033,
                            35.81666666653348
                        ],
                        [
                            -98.49999999999994,
                            35.81666666653348
                        ],
                        [
                            -98.80000000029975,
                            35.81666666653348
                        ],
                        [
                            -99.22777777772222,
                            35.81666666653348
                        ],
                        [
                            -99.38361111116382,
                            35.83333333346661
                        ],
                        [
                            -99.99999999970021,
                            35.82916666650851
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 332,
                "artcc": "zfw",
                "sector": "49",
                "sectype": "high",
                "Shape_Length": 7.208012725643581,
                "Shape_Area": 2.3317731468668708
            }
        },
        {
            "type": "Feature",
            "id": 333,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -97.18805555521965,
                            35.466388889136
                        ],
                        [
                            -97.15972222260245,
                            35.170555555794294
                        ],
                        [
                            -95.63333333336658,
                            34.68333333306691
                        ],
                        [
                            -94.92499999994999,
                            34.933333333466635
                        ],
                        [
                            -94.99999999980014,
                            35.066666667133006
                        ],
                        [
                            -94.99999999980014,
                            35.383333333466624
                        ],
                        [
                            -94.99999999980014,
                            35.65416666685826
                        ],
                        [
                            -95.3666666669331,
                            35.87083333339166
                        ],
                        [
                            -95.61250000037467,
                            36.01666666703312
                        ],
                        [
                            -95.84166666688316,
                            35.94166666628365
                        ],
                        [
                            -95.98333333356646,
                            35.88333333336669
                        ],
                        [
                            -96.05000000039968,
                            35.87083333339166
                        ],
                        [
                            -96.23333333306681,
                            35.749999999700265
                        ],
                        [
                            -96.40416666665828,
                            35.6499999999001
                        ],
                        [
                            -96.83916666650839,
                            35.52777777752249
                        ],
                        [
                            -97.08333333316676,
                            35.458333333316716
                        ],
                        [
                            -97.18805555521965,
                            35.466388889136
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 333,
                "artcc": "zfw",
                "sector": "50",
                "sectype": "high",
                "Shape_Length": 5.793228701835764,
                "Shape_Area": 1.855109259466509
            }
        },
        {
            "type": "Feature",
            "id": 336,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -102.3249999995503,
                            34.550000000299804
                        ],
                        [
                            -102.66249999977515,
                            34.38888888931086
                        ],
                        [
                            -102.80000000039968,
                            34.31666666683327
                        ],
                        [
                            -103.36666666713296,
                            33.77500000005
                        ],
                        [
                            -103.40833333311679,
                            33.71944444450554
                        ],
                        [
                            -103.48749999992503,
                            33.63750000032479
                        ],
                        [
                            -103.69166666648346,
                            33.40277777817198
                        ],
                        [
                            -103.80000000019982,
                            33.38333333296697
                        ],
                        [
                            -103.80000000019982,
                            33.049999999700276
                        ],
                        [
                            -101.98333333326667,
                            32.95250000007502
                        ],
                        [
                            -100.65333333286696,
                            32.86444444405589
                        ],
                        [
                            -100.22916666710796,
                            32.83333333316682
                        ],
                        [
                            -100.03749999962525,
                            33.38333333296697
                        ],
                        [
                            -100.1547222225525,
                            33.62722222242769
                        ],
                        [
                            -100.29444444435558,
                            33.833333332967015
                        ],
                        [
                            -100.40833333371631,
                            33.999999999600334
                        ],
                        [
                            -100.74999999999994,
                            34.46666666653351
                        ],
                        [
                            -101.00000000039967,
                            34.491666666483525
                        ],
                        [
                            -102.0000000001998,
                            34.600000000199884
                        ],
                        [
                            -102.3249999995503,
                            34.550000000299804
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 336,
                "artcc": "zfw",
                "sector": "55",
                "sectype": "high",
                "Shape_Length": 9.296778017600039,
                "Shape_Area": 4.785552392772831
            }
        },
        {
            "type": "Feature",
            "id": 337,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -101.98333333326667,
                            32.95250000007502
                        ],
                        [
                            -101.53333333326668,
                            31.716666666633387
                        ],
                        [
                            -101.16666666703304,
                            31.299999999600345
                        ],
                        [
                            -100.34999999990004,
                            31.19666666713306
                        ],
                        [
                            -100.10000000039969,
                            31.147222222527603
                        ],
                        [
                            -99.83333333306683,
                            31.249999999700265
                        ],
                        [
                            -99.46666666683319,
                            31.383333333366693
                        ],
                        [
                            -99.70000000029972,
                            31.875000000249884
                        ],
                        [
                            -99.92083333289196,
                            32.350000000199884
                        ],
                        [
                            -100.22916666710796,
                            32.83333333316682
                        ],
                        [
                            -100.65333333286696,
                            32.86444444405589
                        ],
                        [
                            -101.98333333326667,
                            32.95250000007502
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 337,
                "artcc": "zfw",
                "sector": "61",
                "sectype": "high",
                "Shape_Length": 7.0237787676561,
                "Shape_Area": 3.003934490440674
            }
        },
        {
            "type": "Feature",
            "id": 341,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -99.92083333289196,
                            32.350000000199884
                        ],
                        [
                            -99.70000000029972,
                            31.875000000249884
                        ],
                        [
                            -99.46666666683319,
                            31.383333333366693
                        ],
                        [
                            -98.55833333291696,
                            31.387500000324792
                        ],
                        [
                            -97.79444444395591,
                            31.39027777769735
                        ],
                        [
                            -97.71249999977516,
                            31.375000000349814
                        ],
                        [
                            -97.58333333306683,
                            31.266666666633398
                        ],
                        [
                            -97.51666666713294,
                            31.666666666733363
                        ],
                        [
                            -97.51666666713294,
                            32.36666666713302
                        ],
                        [
                            -97.51666666713294,
                            32.608333333616486
                        ],
                        [
                            -99.92083333289196,
                            32.350000000199884
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 341,
                "artcc": "zfw",
                "sector": "65",
                "sectype": "high",
                "Shape_Length": 6.7574157779984585,
                "Shape_Area": 2.4126186340434943
            }
        },
        {
            "type": "Feature",
            "id": 343,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -103.80000000019982,
                            33.049999999700276
                        ],
                        [
                            -103.80000000019982,
                            32.999999999800195
                        ],
                        [
                            -103.84027777749742,
                            32.845833333141854
                        ],
                        [
                            -103.93333333296692,
                            32.46666666693318
                        ],
                        [
                            -103.80000000019982,
                            32.033333332967004
                        ],
                        [
                            -103.52916666680818,
                            31.80833333341667
                        ],
                        [
                            -103.3333333332667,
                            31.64999999980023
                        ],
                        [
                            -103.11666666673324,
                            31.583333332967015
                        ],
                        [
                            -102.21666666673326,
                            31.425000000249838
                        ],
                        [
                            -101.40277777787213,
                            31.32888888931086
                        ],
                        [
                            -101.16666666703304,
                            31.299999999600345
                        ],
                        [
                            -101.53333333326668,
                            31.716666666633387
                        ],
                        [
                            -101.98333333326667,
                            32.95250000007502
                        ],
                        [
                            -103.80000000019982,
                            33.049999999700276
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 343,
                "artcc": "zfw",
                "sector": "82",
                "sectype": "high",
                "Shape_Length": 7.544527376356426,
                "Shape_Area": 3.12687962917302
            }
        },
        {
            "type": "Feature",
            "id": 345,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -94.04250000027474,
                            31.350277777597398
                        ],
                        [
                            -93.56666666693309,
                            31.566666666033882
                        ],
                        [
                            -93.31166666708299,
                            31.794444443856037
                        ],
                        [
                            -92.8625000004746,
                            32.191666666583444
                        ],
                        [
                            -92.94444444465535,
                            32.61833333341667
                        ],
                        [
                            -92.95500000064948,
                            32.6666666665335
                        ],
                        [
                            -92.81222222247754,
                            32.79305555496995
                        ],
                        [
                            -94.3955555559441,
                            32.8805555556944
                        ],
                        [
                            -95.24166666718293,
                            32.533611110964046
                        ],
                        [
                            -94.29333333316674,
                            32.262222222277785
                        ],
                        [
                            -94.77826666707301,
                            31.537855555089834
                        ],
                        [
                            -94.94583333384122,
                            31.284444444155838
                        ],
                        [
                            -94.79750000002497,
                            31.275277777747306
                        ],
                        [
                            -94.04250000027474,
                            31.350277777597398
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 345,
                "artcc": "zfw",
                "sector": "86",
                "sectype": "high",
                "Shape_Length": 7.708344668640811,
                "Shape_Area": 2.3023988395167185
            }
        },
        {
            "type": "Feature",
            "id": 346,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -94.77826666707301,
                            31.537855555089834
                        ],
                        [
                            -94.29333333316674,
                            32.262222222277785
                        ],
                        [
                            -95.24166666718293,
                            32.533611110964046
                        ],
                        [
                            -96.35583333354145,
                            32.63055555529468
                        ],
                        [
                            -96.36666666673324,
                            32.3666666662337
                        ],
                        [
                            -96.41888888961051,
                            31.813333332867046
                        ],
                        [
                            -96.26666666693308,
                            31.35833333341668
                        ],
                        [
                            -94.94583333384122,
                            31.284444444155838
                        ],
                        [
                            -94.77826666707301,
                            31.537855555089834
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 346,
                "artcc": "zfw",
                "sector": "89",
                "sectype": "high",
                "Shape_Length": 5.9028736711833325,
                "Shape_Area": 2.142520759511188
            }
        },
        {
            "type": "Feature",
            "id": 347,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -96.35583333354145,
                            32.63055555529468
                        ],
                        [
                            -95.2416666662836,
                            32.533611110964046
                        ],
                        [
                            -94.3955555559441,
                            32.8805555556944
                        ],
                        [
                            -93.82500000034969,
                            33.38944444480535
                        ],
                        [
                            -92.9916666662836,
                            33.82499999995008
                        ],
                        [
                            -93.15833333291692,
                            33.94999999970025
                        ],
                        [
                            -93.54166666698308,
                            34.0333333334666
                        ],
                        [
                            -93.65833333371631,
                            34.08888888901106
                        ],
                        [
                            -94.29638888863627,
                            33.841666666883214
                        ],
                        [
                            -96.33333333286697,
                            33.171944443980976
                        ],
                        [
                            -96.34361111076413,
                            32.91138888848644
                        ],
                        [
                            -96.35583333354145,
                            32.63055555529468
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 347,
                "artcc": "zfw",
                "sector": "90",
                "sectype": "high",
                "Shape_Length": 7.837868244374825,
                "Shape_Area": 2.4281418202427747
            }
        },
        {
            "type": "Feature",
            "id": 348,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -100.03750000052457,
                            33.38333333296697
                        ],
                        [
                            -100.1547222225525,
                            33.62722222152837
                        ],
                        [
                            -100.29444444435558,
                            33.833333332967015
                        ],
                        [
                            -100.40833333371631,
                            33.999999999600334
                        ],
                        [
                            -100.74999999999994,
                            34.46666666653351
                        ],
                        [
                            -101.00000000039967,
                            34.491666666483525
                        ],
                        [
                            -102.0000000001998,
                            34.599999999300564
                        ],
                        [
                            -102.32500000044962,
                            34.549999999400484
                        ],
                        [
                            -102.66250000067447,
                            34.38888888841154
                        ],
                        [
                            -102.80000000039968,
                            34.31666666683327
                        ],
                        [
                            -103.36666666713296,
                            33.77500000005
                        ],
                        [
                            -103.4083333340161,
                            33.71944444450554
                        ],
                        [
                            -103.48749999992503,
                            33.63749999942547
                        ],
                        [
                            -103.69166666738278,
                            33.40277777727266
                        ],
                        [
                            -103.80000000019982,
                            33.38333333296697
                        ],
                        [
                            -103.80000000019982,
                            33.049999999700276
                        ],
                        [
                            -101.98333333326667,
                            32.95250000007502
                        ],
                        [
                            -100.65333333376628,
                            32.86444444405589
                        ],
                        [
                            -100.22916666710796,
                            32.83333333316682
                        ],
                        [
                            -100.03750000052457,
                            33.38333333296697
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 348,
                "artcc": "zfw",
                "sector": "93",
                "sectype": "high",
                "Shape_Length": 9.296778016533224,
                "Shape_Area": 4.7855523913546545
            }
        },
        {
            "type": "Feature",
            "id": 349,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -100.40833333371631,
                            33.999999999600334
                        ],
                        [
                            -100.29444444435558,
                            33.833333332967015
                        ],
                        [
                            -100.1547222225525,
                            33.62722222242769
                        ],
                        [
                            -100.03749999962525,
                            33.38333333296697
                        ],
                        [
                            -100.22916666710796,
                            32.83333333316682
                        ],
                        [
                            -99.92083333289196,
                            32.350000000199884
                        ],
                        [
                            -97.51666666713294,
                            32.608333333616486
                        ],
                        [
                            -97.51666666713294,
                            32.91249999997507
                        ],
                        [
                            -97.51666666713294,
                            33.08333333356654
                        ],
                        [
                            -97.7333333336664,
                            33.17500000034977
                        ],
                        [
                            -100.40833333371631,
                            33.999999999600334
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 349,
                "artcc": "zfw",
                "sector": "94",
                "sectype": "high",
                "Shape_Length": 7.804808615840153,
                "Shape_Area": 2.7327214495466743
            }
        },
        {
            "type": "Feature",
            "id": 351,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -97.51666666713294,
                            32.608333332717166
                        ],
                        [
                            -97.51666666713294,
                            32.91249999997507
                        ],
                        [
                            -97.51666666713294,
                            33.08333333266722
                        ],
                        [
                            -97.7333333336664,
                            33.17499999945045
                        ],
                        [
                            -100.40833333371631,
                            33.999999999600334
                        ],
                        [
                            -100.29444444435558,
                            33.833333332967015
                        ],
                        [
                            -100.1547222225525,
                            33.62722222152837
                        ],
                        [
                            -100.03750000052457,
                            33.38333333296697
                        ],
                        [
                            -100.22916666710796,
                            32.83333333316682
                        ],
                        [
                            -99.92083333379128,
                            32.349999999300564
                        ],
                        [
                            -97.51666666713294,
                            32.608333332717166
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 351,
                "artcc": "zfw",
                "sector": "97",
                "sectype": "high",
                "Shape_Length": 7.8048086165221955,
                "Shape_Area": 2.7327214510234317
            }
        },
        {
            "type": "Feature",
            "id": 367,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -88.02500000024975,
                            30.154444444255773
                        ],
                        [
                            -88.16388888956055,
                            30.200000000000045
                        ],
                        [
                            -88.42500000034971,
                            30.148611110514366
                        ],
                        [
                            -88.5000000001998,
                            30.16555555554453
                        ],
                        [
                            -88.52500000014987,
                            30.169444444405656
                        ],
                        [
                            -88.69444444505507,
                            30.183611111163884
                        ],
                        [
                            -88.85000000039969,
                            30.100277777397537
                        ],
                        [
                            -89.03333333396614,
                            29.999999999500403
                        ],
                        [
                            -89.30499999985005,
                            29.999999999500403
                        ],
                        [
                            -89.49972222280229,
                            29.92222222227781
                        ],
                        [
                            -89.99999999990001,
                            29.758333333016935
                        ],
                        [
                            -90.0958333336414,
                            29.733333333066923
                        ],
                        [
                            -90.48333333376632,
                            29.649999999300576
                        ],
                        [
                            -90.50000000069946,
                            29.316666666033882
                        ],
                        [
                            -90.50000000069946,
                            29.16666666633364
                        ],
                        [
                            -90.50000000069946,
                            28.833333333066946
                        ],
                        [
                            -90.50000000069946,
                            28.499999999800195
                        ],
                        [
                            -90.50000000069946,
                            28.266666666333663
                        ],
                        [
                            -90.16666666653339,
                            28.266666666333663
                        ],
                        [
                            -89.88333333316677,
                            28.266666666333663
                        ],
                        [
                            -89.8333333332667,
                            28.266666666333663
                        ],
                        [
                            -89.49999999999994,
                            28.266666666333663
                        ],
                        [
                            -89.16666666673325,
                            28.266666666333663
                        ],
                        [
                            -89.00000000009987,
                            28.266666666333663
                        ],
                        [
                            -88.83333333346656,
                            28.24833333261728
                        ],
                        [
                            -88.58333333396615,
                            28.208333333416647
                        ],
                        [
                            -88.5000000001998,
                            28.198333332717198
                        ],
                        [
                            -88.00000000029974,
                            28.141666666583433
                        ],
                        [
                            -88.01111111158849,
                            28.999999999700265
                        ],
                        [
                            -88.01388888896099,
                            29.574999999450483
                        ],
                        [
                            -88.01666666723287,
                            29.599999999400495
                        ],
                        [
                            -88.02500000024975,
                            29.966666666533513
                        ],
                        [
                            -88.02500000024975,
                            30.08472222195303
                        ],
                        [
                            -88.02500000024975,
                            30.154444444255773
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 367,
                "artcc": "zhu",
                "sector": "24",
                "sectype": "high",
                "Shape_Length": 8.477976908336927,
                "Shape_Area": 4.284599537643378
            }
        },
        {
            "type": "Feature",
            "id": 369,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -94.7588888886113,
                            30.540555555694368
                        ],
                        [
                            -94.88333333306684,
                            30.39444444395599
                        ],
                        [
                            -94.83333333316676,
                            30.224999999950114
                        ],
                        [
                            -94.8041666662586,
                            30.07916666630865
                        ],
                        [
                            -94.79638888853634,
                            30.05249999957539
                        ],
                        [
                            -94.28055555599406,
                            30.10527777774729
                        ],
                        [
                            -93.94722222182804,
                            30.130833332991983
                        ],
                        [
                            -93.69166666668326,
                            30.183333333066912
                        ],
                        [
                            -93.29999999960029,
                            30.31666666673334
                        ],
                        [
                            -92.5258333336414,
                            30.59972222200298
                        ],
                        [
                            -92.54166666628356,
                            30.791666666683398
                        ],
                        [
                            -92.58333333316676,
                            31.083333333066946
                        ],
                        [
                            -93.56666666693309,
                            31.5666666669332
                        ],
                        [
                            -94.04250000027474,
                            31.350277777597398
                        ],
                        [
                            -94.57166666708298,
                            30.757222222227824
                        ],
                        [
                            -94.7588888886113,
                            30.540555555694368
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 369,
                "artcc": "zhu",
                "sector": "26",
                "sectype": "high",
                "Shape_Length": 6.0836654853364,
                "Shape_Area": 2.190999267571065
            }
        },
        {
            "type": "Feature",
            "id": 378,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -91.53333333346654,
                            32.43333333306691
                        ],
                        [
                            -91.6888888888111,
                            32.28333333336667
                        ],
                        [
                            -91.79999999990002,
                            32.1499999997003
                        ],
                        [
                            -92.52222222287725,
                            32.1083333328171
                        ],
                        [
                            -92.8625000004746,
                            32.191666666583444
                        ],
                        [
                            -93.31166666708299,
                            31.794444443856037
                        ],
                        [
                            -93.56666666693309,
                            31.566666666033882
                        ],
                        [
                            -92.58333333316676,
                            31.083333333066946
                        ],
                        [
                            -92.54166666718288,
                            30.791666666683398
                        ],
                        [
                            -92.5258333336414,
                            30.59972222200298
                        ],
                        [
                            -92.18166666718292,
                            30.4166666665335
                        ],
                        [
                            -91.86666666673324,
                            30.508333333316727
                        ],
                        [
                            -91.31361111146356,
                            30.281666666083822
                        ],
                        [
                            -91.04833333371636,
                            30.230277777497463
                        ],
                        [
                            -90.71722222252754,
                            30.239722222002968
                        ],
                        [
                            -90.91666666683318,
                            30.716666666833248
                        ],
                        [
                            -90.95000000069945,
                            30.833333332667223
                        ],
                        [
                            -90.9833333336664,
                            30.91666666643357
                        ],
                        [
                            -90.97500000064946,
                            31.166666666833237
                        ],
                        [
                            -90.95000000069945,
                            31.383333333366693
                        ],
                        [
                            -90.65833333341658,
                            31.72916666660842
                        ],
                        [
                            -90.80000000009989,
                            31.766666666533467
                        ],
                        [
                            -90.90833333381624,
                            31.799999999500415
                        ],
                        [
                            -91.30833333391621,
                            31.91249999927561
                        ],
                        [
                            -91.53333333346654,
                            32.43333333306691
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 378,
                "artcc": "zhu",
                "sector": "42",
                "sectype": "high",
                "Shape_Length": 8.796380800926153,
                "Shape_Area": 3.4966090656944435
            }
        },
        {
            "type": "Feature",
            "id": 379,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -94.33833333361639,
                            29.64777777812202
                        ],
                        [
                            -94.4663888888361,
                            29.664722222252806
                        ],
                        [
                            -94.57694444463039,
                            29.64944444400595
                        ],
                        [
                            -95.11194444428065,
                            29.48500000034977
                        ],
                        [
                            -95.13333333346651,
                            29.417777778221932
                        ],
                        [
                            -95.27916666710797,
                            28.96111111108894
                        ],
                        [
                            -95.28750000012485,
                            28.909722222502637
                        ],
                        [
                            -95.35694444433062,
                            28.499999999800195
                        ],
                        [
                            -95.40555555554442,
                            28.205555556044146
                        ],
                        [
                            -94.00833333301688,
                            28.266666666333663
                        ],
                        [
                            -93.63333333286698,
                            28.266666666333663
                        ],
                        [
                            -93.46666666713293,
                            28.266666666333663
                        ],
                        [
                            -93.14055555539454,
                            28.266666666333663
                        ],
                        [
                            -92.861111110889,
                            28.499999999800195
                        ],
                        [
                            -92.47749999962525,
                            28.499999999800195
                        ],
                        [
                            -92.36944444400586,
                            28.499999999800195
                        ],
                        [
                            -92.37250000037466,
                            28.740833332892066
                        ],
                        [
                            -92.38222222207787,
                            29.513888889160967
                        ],
                        [
                            -92.38250000017484,
                            29.538611111014006
                        ],
                        [
                            -92.38333333356644,
                            29.600000000299815
                        ],
                        [
                            -92.55999999999995,
                            29.603333332966997
                        ],
                        [
                            -93.50000000009987,
                            29.61666666633363
                        ],
                        [
                            -94.03805555521967,
                            29.6066666665335
                        ],
                        [
                            -94.33833333361639,
                            29.64777777812202
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 379,
                "artcc": "zhu",
                "sector": "43",
                "sectype": "high",
                "Shape_Length": 8.296628437261402,
                "Shape_Area": 3.7646257330642987
            }
        },
        {
            "type": "Feature",
            "id": 381,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -96.26666666693308,
                            31.35833333341668
                        ],
                        [
                            -96.16972222260245,
                            31.196111110939057
                        ],
                        [
                            -96.03333333346654,
                            30.96666666633365
                        ],
                        [
                            -95.85833333291697,
                            30.68333333296698
                        ],
                        [
                            -95.68333333326666,
                            30.433333333466635
                        ],
                        [
                            -96.01666666653341,
                            30.13333333316683
                        ],
                        [
                            -95.71666666713293,
                            29.655555555844273
                        ],
                        [
                            -95.48555555574427,
                            29.564444444355672
                        ],
                        [
                            -95.2500000001998,
                            29.466666666633387
                        ],
                        [
                            -95.13333333346651,
                            29.417777778221932
                        ],
                        [
                            -95.11194444428065,
                            29.48500000034977
                        ],
                        [
                            -94.79638888853634,
                            30.05249999957539
                        ],
                        [
                            -94.8041666662586,
                            30.07916666630865
                        ],
                        [
                            -94.83333333316676,
                            30.224999999950114
                        ],
                        [
                            -94.88333333306684,
                            30.39444444395599
                        ],
                        [
                            -94.7588888886113,
                            30.540555555694368
                        ],
                        [
                            -94.57166666708298,
                            30.757222222227824
                        ],
                        [
                            -94.04250000027474,
                            31.350277777597398
                        ],
                        [
                            -94.79750000002497,
                            31.275277777747306
                        ],
                        [
                            -94.9458333329419,
                            31.284444444155838
                        ],
                        [
                            -96.26666666693308,
                            31.35833333341668
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 381,
                "artcc": "zhu",
                "sector": "46",
                "sectype": "high",
                "Shape_Length": 7.313021024299507,
                "Shape_Area": 2.1762342593105592
            }
        },
        {
            "type": "Feature",
            "id": 387,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -97.9841666665584,
                            29.011944444380674
                        ],
                        [
                            -98.06333333336659,
                            29.0152777770478
                        ],
                        [
                            -98.43333333316673,
                            29.016666666633398
                        ],
                        [
                            -99.05833333371635,
                            29.022222222277776
                        ],
                        [
                            -99.20000000039965,
                            28.88333333296697
                        ],
                        [
                            -99.7500000001998,
                            28.899999999900103
                        ],
                        [
                            -99.81666666703302,
                            28.8000000001
                        ],
                        [
                            -99.91666666683318,
                            28.683333333366704
                        ],
                        [
                            -100.06666666653342,
                            28.56666666663341
                        ],
                        [
                            -100.33333333386622,
                            28.43333333296698
                        ],
                        [
                            -100.19166666718291,
                            28.183333332567315
                        ],
                        [
                            -99.95000000069945,
                            27.983333332966993
                        ],
                        [
                            -99.83333333396615,
                            27.774999999450472
                        ],
                        [
                            -99.76750000052459,
                            27.713888888261636
                        ],
                        [
                            -99.62583333384123,
                            27.583333332867085
                        ],
                        [
                            -99.61111111178832,
                            27.57083333289205
                        ],
                        [
                            -99.52500000064947,
                            27.491666666083802
                        ],
                        [
                            -99.47777777812195,
                            27.227777777922142
                        ],
                        [
                            -99.44999999990006,
                            27.066666666033882
                        ],
                        [
                            -99.19166666738278,
                            26.649999999900103
                        ],
                        [
                            -99.18333333346652,
                            26.533333333166865
                        ],
                        [
                            -99.13333333356644,
                            26.46666666633365
                        ],
                        [
                            -99.06666666673323,
                            26.399999999500437
                        ],
                        [
                            -98.69166666658339,
                            26.249999999800195
                        ],
                        [
                            -98.58750000072439,
                            26.23888888851144
                        ],
                        [
                            -98.42500000014985,
                            26.18333333296698
                        ],
                        [
                            -98.21666666663333,
                            26.066666666233687
                        ],
                        [
                            -97.95833333321673,
                            26.058333333216808
                        ],
                        [
                            -97.75000000059953,
                            25.99999999940053
                        ],
                        [
                            -97.3583333335165,
                            25.81666666673334
                        ],
                        [
                            -96.11388888896101,
                            25.749999999900126
                        ],
                        [
                            -95.98277777827184,
                            25.999166666008875
                        ],
                        [
                            -95.94694444423067,
                            26.07916666620872
                        ],
                        [
                            -95.59722222212781,
                            26.833333332567292
                        ],
                        [
                            -95.58111111138862,
                            26.86361111096403
                        ],
                        [
                            -95.50000000059953,
                            27.633333332767165
                        ],
                        [
                            -96.94722222212783,
                            27.738888888211648
                        ],
                        [
                            -97.43333333336659,
                            28.338888888811198
                        ],
                        [
                            -97.9841666665584,
                            29.011944444380674
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 387,
                "artcc": "zhu",
                "sector": "59",
                "sectype": "high",
                "Shape_Length": 13.234353898933898,
                "Shape_Area": 9.566306598103703
            }
        },
        {
            "type": "Feature",
            "id": 388,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -89.49972222190297,
                            29.92222222227781
                        ],
                        [
                            -89.30499999985005,
                            30.000000000399723
                        ],
                        [
                            -89.03333333306682,
                            30.000000000399723
                        ],
                        [
                            -88.85000000039969,
                            30.100277777397537
                        ],
                        [
                            -88.69444444415575,
                            30.183611111163884
                        ],
                        [
                            -88.52500000014987,
                            30.169444444405656
                        ],
                        [
                            -88.5000000001998,
                            30.16555555554453
                        ],
                        [
                            -88.42500000034971,
                            30.148611111413686
                        ],
                        [
                            -88.16388888866123,
                            30.200000000000045
                        ],
                        [
                            -88.02500000024975,
                            30.154444444255773
                        ],
                        [
                            -88.02500000024975,
                            30.22083333299196
                        ],
                        [
                            -87.91666666653339,
                            30.516666666333663
                        ],
                        [
                            -87.91666666653339,
                            30.6333333330669
                        ],
                        [
                            -87.80833333371635,
                            30.68333333296698
                        ],
                        [
                            -87.71666666693312,
                            30.691666666883236
                        ],
                        [
                            -87.69166666698305,
                            30.791666666683398
                        ],
                        [
                            -87.68333333306686,
                            30.845833333541577
                        ],
                        [
                            -87.65833333311679,
                            30.958333333316716
                        ],
                        [
                            -87.58888888891107,
                            31.0500000001
                        ],
                        [
                            -87.39999999970019,
                            31.28055555529471
                        ],
                        [
                            -87.55000000029975,
                            31.35833333341668
                        ],
                        [
                            -87.81666666673323,
                            31.5208333330919
                        ],
                        [
                            -88.32499999965023,
                            31.51666666703312
                        ],
                        [
                            -88.90000000029971,
                            31.54722222262751
                        ],
                        [
                            -89.00833333311681,
                            31.550000000000068
                        ],
                        [
                            -89.2622222223776,
                            31.185277777747274
                        ],
                        [
                            -89.37444444405583,
                            30.947500000124933
                        ],
                        [
                            -89.7147222225525,
                            30.22305555596921
                        ],
                        [
                            -89.49972222190297,
                            29.92222222227781
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 388,
                "artcc": "zhu",
                "sector": "63",
                "sectype": "high",
                "Shape_Length": 6.498177652180921,
                "Shape_Area": 2.401157330378321
            }
        },
        {
            "type": "Feature",
            "id": 389,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -90.0958333336414,
                            29.733333333066923
                        ],
                        [
                            -89.99999999990001,
                            29.758333333016935
                        ],
                        [
                            -89.49972222280229,
                            29.92222222227781
                        ],
                        [
                            -89.7147222225525,
                            30.223055555069834
                        ],
                        [
                            -89.37444444495515,
                            30.947500000124933
                        ],
                        [
                            -89.2622222223776,
                            31.185277777747274
                        ],
                        [
                            -89.00833333401613,
                            31.550000000000068
                        ],
                        [
                            -89.16666666673325,
                            31.57499999995008
                        ],
                        [
                            -89.43333333316673,
                            31.591666665983894
                        ],
                        [
                            -89.58333333376629,
                            31.608333332917027
                        ],
                        [
                            -89.85000000019983,
                            31.63055555549454
                        ],
                        [
                            -89.93333333396617,
                            31.633333332867096
                        ],
                        [
                            -90.03333333376628,
                            31.636111111138916
                        ],
                        [
                            -90.3458333331418,
                            31.64999999980023
                        ],
                        [
                            -90.65833333341658,
                            31.72916666660842
                        ],
                        [
                            -90.95000000069945,
                            31.383333333366693
                        ],
                        [
                            -90.97500000064946,
                            31.166666666833237
                        ],
                        [
                            -90.9833333336664,
                            30.91666666643357
                        ],
                        [
                            -90.95000000069945,
                            30.833333332667223
                        ],
                        [
                            -90.91666666683318,
                            30.716666666833248
                        ],
                        [
                            -90.71722222252754,
                            30.239722222002968
                        ],
                        [
                            -90.62916666740773,
                            30.008333333416658
                        ],
                        [
                            -90.48333333376632,
                            29.649999999300576
                        ],
                        [
                            -90.0958333336414,
                            29.733333333066923
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 389,
                "artcc": "zhu",
                "sector": "65",
                "sectype": "high",
                "Shape_Length": 6.847048381741815,
                "Shape_Area": 2.6137693674747675
            }
        },
        {
            "type": "Feature",
            "id": 390,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -93.69166666668326,
                            30.183333333066912
                        ],
                        [
                            -93.94722222182804,
                            30.130833332991983
                        ],
                        [
                            -94.28055555599406,
                            30.10527777774729
                        ],
                        [
                            -94.79638888853634,
                            30.05249999957539
                        ],
                        [
                            -95.11194444428065,
                            29.48500000034977
                        ],
                        [
                            -94.57694444463039,
                            29.64944444400595
                        ],
                        [
                            -94.4663888888361,
                            29.664722222252806
                        ],
                        [
                            -94.33833333361639,
                            29.64777777812202
                        ],
                        [
                            -94.03805555521967,
                            29.6066666665335
                        ],
                        [
                            -93.50000000009987,
                            29.61666666633363
                        ],
                        [
                            -92.55999999999995,
                            29.603333332966997
                        ],
                        [
                            -92.38333333356644,
                            29.600000000299815
                        ],
                        [
                            -92.38333333356644,
                            30.015555555844287
                        ],
                        [
                            -92.38333333356644,
                            30.183333333066912
                        ],
                        [
                            -92.1816666662836,
                            30.4166666665335
                        ],
                        [
                            -92.5258333336414,
                            30.59972222200298
                        ],
                        [
                            -93.29999999960029,
                            30.31666666673334
                        ],
                        [
                            -93.69166666668326,
                            30.183333333066912
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 390,
                "artcc": "zhu",
                "sector": "68",
                "sectype": "high",
                "Shape_Length": 7.041151819066678,
                "Shape_Area": 1.6855763500684349
            }
        },
        {
            "type": "Feature",
            "id": 392,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -90.50000000069946,
                            28.499999999800195
                        ],
                        [
                            -90.50000000069946,
                            28.833333333066946
                        ],
                        [
                            -90.50000000069946,
                            29.16666666633364
                        ],
                        [
                            -90.50000000069946,
                            29.316666666033882
                        ],
                        [
                            -90.48333333376632,
                            29.649999999300576
                        ],
                        [
                            -90.62916666740773,
                            30.008333333416658
                        ],
                        [
                            -90.71722222252754,
                            30.239722222002968
                        ],
                        [
                            -90.91666666683318,
                            30.716666666833248
                        ],
                        [
                            -90.95000000069945,
                            30.833333332667223
                        ],
                        [
                            -90.9833333336664,
                            30.91666666643357
                        ],
                        [
                            -91.35333333346654,
                            30.601944444080857
                        ],
                        [
                            -91.86666666673324,
                            30.508333333316727
                        ],
                        [
                            -92.18166666718292,
                            30.4166666665335
                        ],
                        [
                            -92.38333333356644,
                            30.183333333066912
                        ],
                        [
                            -92.38333333356644,
                            30.015555554944967
                        ],
                        [
                            -92.38333333356644,
                            29.599999999400495
                        ],
                        [
                            -92.38250000017484,
                            29.538611111014006
                        ],
                        [
                            -92.38222222207787,
                            29.513888888261647
                        ],
                        [
                            -92.37250000037466,
                            28.740833332892066
                        ],
                        [
                            -92.36944444490518,
                            28.499999999800195
                        ],
                        [
                            -92.24833333401614,
                            28.499999999800195
                        ],
                        [
                            -92.15000000009991,
                            28.266666666333663
                        ],
                        [
                            -91.66666666713297,
                            28.266666666333663
                        ],
                        [
                            -91.40000000069944,
                            28.266666666333663
                        ],
                        [
                            -90.83333333396615,
                            28.266666666333663
                        ],
                        [
                            -90.73333333326667,
                            28.266666666333663
                        ],
                        [
                            -90.50000000069946,
                            28.266666666333663
                        ],
                        [
                            -90.50000000069946,
                            28.499999999800195
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 392,
                "artcc": "zhu",
                "sector": "70",
                "sectype": "high",
                "Shape_Length": 8.098010989049477,
                "Shape_Area": 4.130923109000128
            }
        },
        {
            "type": "Feature",
            "id": 394,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -97.86666666643345,
                            29.799999999900137
                        ],
                        [
                            -98.00416666705803,
                            29.86666666673335
                        ],
                        [
                            -98.03750000002492,
                            30.483333333366716
                        ],
                        [
                            -97.58333333306683,
                            31.266666666633398
                        ],
                        [
                            -97.71249999977516,
                            31.375000000349814
                        ],
                        [
                            -97.79444444395591,
                            31.39027777769735
                        ],
                        [
                            -98.55833333291696,
                            31.387500000324792
                        ],
                        [
                            -99.46666666683319,
                            31.383333333366693
                        ],
                        [
                            -99.83333333306683,
                            31.249999999700265
                        ],
                        [
                            -100.10000000039969,
                            31.147222222527603
                        ],
                        [
                            -99.91666666683318,
                            31.033333333166865
                        ],
                        [
                            -99.38333333306684,
                            30.625000000050022
                        ],
                        [
                            -99.31666666713295,
                            30.349999999700287
                        ],
                        [
                            -98.77777777772224,
                            30.041666666383605
                        ],
                        [
                            -98.7166666665334,
                            30.000000000399723
                        ],
                        [
                            -98.79166666638349,
                            29.66666666713303
                        ],
                        [
                            -99.6083333335165,
                            29.81666666683327
                        ],
                        [
                            -99.61666666653338,
                            29.466666666633387
                        ],
                        [
                            -99.483333332867,
                            29.183333333266773
                        ],
                        [
                            -99.3500000000999,
                            28.999999999700265
                        ],
                        [
                            -99.20000000039965,
                            28.88333333296697
                        ],
                        [
                            -99.05833333371635,
                            29.022222222277776
                        ],
                        [
                            -98.43333333316673,
                            29.016666666633398
                        ],
                        [
                            -98.06333333336659,
                            29.01527777794712
                        ],
                        [
                            -97.9841666665584,
                            29.011944444380674
                        ],
                        [
                            -97.93333333326666,
                            29.39444444415585
                        ],
                        [
                            -97.86666666643345,
                            29.799999999900137
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 394,
                "artcc": "zhu",
                "sector": "74",
                "sectype": "high",
                "Shape_Length": 10.462758413870397,
                "Shape_Area": 3.6239423618979765
            }
        },
        {
            "type": "Feature",
            "id": 396,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -97.58333333306683,
                            31.266666666633398
                        ],
                        [
                            -98.03750000002492,
                            30.483333333366716
                        ],
                        [
                            -98.00416666705803,
                            29.86666666673335
                        ],
                        [
                            -97.86666666643345,
                            29.799999999900137
                        ],
                        [
                            -97.80416666655839,
                            29.802777778171958
                        ],
                        [
                            -97.1477777779221,
                            29.764166666758342
                        ],
                        [
                            -97.03944444420569,
                            29.757222222427686
                        ],
                        [
                            -96.17694444413075,
                            29.66111111148865
                        ],
                        [
                            -96.09944444410576,
                            29.65222222227777
                        ],
                        [
                            -95.71666666713293,
                            29.655555555844273
                        ],
                        [
                            -96.01666666653341,
                            30.13333333316683
                        ],
                        [
                            -96.55000000029975,
                            30.233333332966993
                        ],
                        [
                            -97.11666666703303,
                            30.346666667033105
                        ],
                        [
                            -97.11666666703303,
                            31.00000000019992
                        ],
                        [
                            -97.00000000029974,
                            31.20416666675834
                        ],
                        [
                            -97.12500000004991,
                            31.199999999800184
                        ],
                        [
                            -97.19999999990006,
                            31.199999999800184
                        ],
                        [
                            -97.51250000017484,
                            31.21249999977522
                        ],
                        [
                            -97.58333333306683,
                            31.266666666633398
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 396,
                "artcc": "zhu",
                "sector": "78",
                "sectype": "high",
                "Shape_Length": 7.008271705579634,
                "Shape_Area": 1.8117646978978599
            }
        },
        {
            "type": "Feature",
            "id": 397,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -92.24833333401614,
                            28.499999999800195
                        ],
                        [
                            -92.36944444490518,
                            28.499999999800195
                        ],
                        [
                            -92.47750000052457,
                            28.499999999800195
                        ],
                        [
                            -92.86111111178832,
                            28.499999999800195
                        ],
                        [
                            -93.14055555629386,
                            28.266666666333663
                        ],
                        [
                            -93.46666666713293,
                            28.266666666333663
                        ],
                        [
                            -93.6333333337663,
                            28.266666666333663
                        ],
                        [
                            -94.0083333339162,
                            28.266666666333663
                        ],
                        [
                            -95.40555555554442,
                            28.205555555144826
                        ],
                        [
                            -95.45000000069945,
                            27.999999999900126
                        ],
                        [
                            -95.50000000059953,
                            27.633333332767165
                        ],
                        [
                            -95.58111111138862,
                            26.86361111096403
                        ],
                        [
                            -95.59722222212781,
                            26.833333332567292
                        ],
                        [
                            -95.94694444423067,
                            26.07916666620872
                        ],
                        [
                            -95.98277777827184,
                            25.999166666008875
                        ],
                        [
                            -95.91666666673325,
                            25.99999999940053
                        ],
                        [
                            -94.70000000039965,
                            25.383333332767165
                        ],
                        [
                            -93.00833333321674,
                            24.499999999700265
                        ],
                        [
                            -92.50000000029974,
                            24.499999999700265
                        ],
                        [
                            -92.00833333341654,
                            24.499999999700265
                        ],
                        [
                            -90.83333333396615,
                            24.499999999700265
                        ],
                        [
                            -89.23333333356646,
                            24.499999999700265
                        ],
                        [
                            -89.29166666738274,
                            24.966666666633387
                        ],
                        [
                            -89.49999999999994,
                            26.349999999600357
                        ],
                        [
                            -89.57083333379126,
                            26.833333332567292
                        ],
                        [
                            -89.58333333376629,
                            26.91666666633364
                        ],
                        [
                            -89.88333333316677,
                            28.266666666333663
                        ],
                        [
                            -90.16666666653339,
                            28.266666666333663
                        ],
                        [
                            -90.50000000069946,
                            28.266666666333663
                        ],
                        [
                            -90.73333333326667,
                            28.266666666333663
                        ],
                        [
                            -90.83333333396615,
                            28.266666666333663
                        ],
                        [
                            -91.40000000069944,
                            28.266666666333663
                        ],
                        [
                            -91.66666666713297,
                            28.266666666333663
                        ],
                        [
                            -92.15000000009991,
                            28.266666666333663
                        ],
                        [
                            -92.24833333401614,
                            28.499999999800195
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 397,
                "artcc": "zhu",
                "sector": "79",
                "sectype": "high",
                "Shape_Length": 19.00912193653416,
                "Shape_Area": 21.33488028568051
            }
        },
        {
            "type": "Feature",
            "id": 400,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -97.00000000029974,
                            31.20416666675834
                        ],
                        [
                            -97.11666666703303,
                            31.00000000019992
                        ],
                        [
                            -97.11666666703303,
                            30.346666667033105
                        ],
                        [
                            -96.55000000029975,
                            30.233333332966993
                        ],
                        [
                            -96.01666666653341,
                            30.13333333316683
                        ],
                        [
                            -95.68333333326666,
                            30.433333333466635
                        ],
                        [
                            -95.85833333291697,
                            30.68333333296698
                        ],
                        [
                            -96.03333333346654,
                            30.96666666633365
                        ],
                        [
                            -96.16972222260245,
                            31.196111110939057
                        ],
                        [
                            -96.26666666693308,
                            31.35833333341668
                        ],
                        [
                            -96.5125000003747,
                            31.266666666633398
                        ],
                        [
                            -96.68333333306686,
                            31.20416666675834
                        ],
                        [
                            -97.00000000029974,
                            31.20416666675834
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 400,
                "artcc": "zhu",
                "sector": "82",
                "sectype": "high",
                "Shape_Length": 4.312482101496353,
                "Shape_Area": 1.2004392366008856
            }
        },
        {
            "type": "Feature",
            "id": 408,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -95.71666666713293,
                            29.655555555844273
                        ],
                        [
                            -96.09944444410576,
                            29.65222222227777
                        ],
                        [
                            -96.17694444413075,
                            29.66111111148865
                        ],
                        [
                            -97.03944444420569,
                            29.757222222427686
                        ],
                        [
                            -97.1477777779221,
                            29.764166666758342
                        ],
                        [
                            -97.80416666655839,
                            29.802777778171958
                        ],
                        [
                            -97.86666666643345,
                            29.799999999900137
                        ],
                        [
                            -97.93333333326666,
                            29.39444444415585
                        ],
                        [
                            -97.9841666665584,
                            29.011944444380674
                        ],
                        [
                            -97.43333333336659,
                            28.338888888811198
                        ],
                        [
                            -96.94722222212783,
                            27.738888889110967
                        ],
                        [
                            -95.49999999970021,
                            27.633333333666485
                        ],
                        [
                            -95.44999999980013,
                            27.999999999900126
                        ],
                        [
                            -95.40555555554442,
                            28.205555556044146
                        ],
                        [
                            -95.35694444433062,
                            28.499999999800195
                        ],
                        [
                            -95.28750000012485,
                            28.909722222502637
                        ],
                        [
                            -95.27916666710797,
                            28.96111111108894
                        ],
                        [
                            -95.13333333346651,
                            29.417777778221932
                        ],
                        [
                            -95.2500000001998,
                            29.466666666633387
                        ],
                        [
                            -95.48555555574427,
                            29.564444444355672
                        ],
                        [
                            -95.71666666713293,
                            29.655555555844273
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 408,
                "artcc": "zhu",
                "sector": "95",
                "sectype": "high",
                "Shape_Length": 8.50290940977045,
                "Shape_Area": 4.595155439687305
            }
        },
        {
            "type": "Feature",
            "id": 410,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -98.7166666665334,
                            29.999999999500403
                        ],
                        [
                            -98.77777777772224,
                            30.041666666383605
                        ],
                        [
                            -99.31666666713295,
                            30.349999999700287
                        ],
                        [
                            -99.38333333396616,
                            30.625000000050022
                        ],
                        [
                            -99.91666666683318,
                            31.033333333166865
                        ],
                        [
                            -100.10000000039969,
                            31.147222221628283
                        ],
                        [
                            -100.34999999990004,
                            31.19666666623374
                        ],
                        [
                            -101.16666666703304,
                            31.299999999600345
                        ],
                        [
                            -101.40277777787213,
                            31.32888888841154
                        ],
                        [
                            -102.21666666673326,
                            31.42499999935052
                        ],
                        [
                            -102.14999999990005,
                            31.283333332667212
                        ],
                        [
                            -102.55833333391621,
                            29.76666666603387
                        ],
                        [
                            -102.67500000064945,
                            29.725000000049988
                        ],
                        [
                            -102.84027777769722,
                            29.361111111188904
                        ],
                        [
                            -100.64833333341659,
                            28.999999999700265
                        ],
                        [
                            -100.33333333386622,
                            28.43333333296698
                        ],
                        [
                            -100.06666666653342,
                            28.56666666663341
                        ],
                        [
                            -99.91666666683318,
                            28.683333333366704
                        ],
                        [
                            -99.81666666703302,
                            28.8000000001
                        ],
                        [
                            -99.7500000001998,
                            28.899999999900103
                        ],
                        [
                            -99.20000000039965,
                            28.88333333296697
                        ],
                        [
                            -99.3500000000999,
                            28.999999999700265
                        ],
                        [
                            -99.48333333376632,
                            29.183333333266773
                        ],
                        [
                            -99.61666666653338,
                            29.466666666633387
                        ],
                        [
                            -99.6083333335165,
                            29.81666666683327
                        ],
                        [
                            -98.79166666728281,
                            29.66666666623371
                        ],
                        [
                            -98.7166666665334,
                            29.999999999500403
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 410,
                "artcc": "zhu",
                "sector": "97",
                "sectype": "high",
                "Shape_Length": 12.685592523129323,
                "Shape_Area": 7.019184026791562
            }
        },
        {
            "type": "Feature",
            "id": 411,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -102.01111111148856,
                            29.790555555394633
                        ],
                        [
                            -102.14999999990005,
                            29.808333332917016
                        ],
                        [
                            -102.31666666653342,
                            29.87499999975023
                        ],
                        [
                            -102.40000000029971,
                            29.76666666693319
                        ],
                        [
                            -102.43333333326666,
                            29.77499999995007
                        ],
                        [
                            -102.4525000003747,
                            29.773611111263847
                        ],
                        [
                            -102.55833333301689,
                            29.76666666693319
                        ],
                        [
                            -102.67499999975013,
                            29.725000000049988
                        ],
                        [
                            -102.84027777769722,
                            29.361111111188904
                        ],
                        [
                            -100.64833333341659,
                            28.999999999700265
                        ],
                        [
                            -100.79999999990002,
                            29.16666666633364
                        ],
                        [
                            -101.51666666633355,
                            29.758333333016935
                        ],
                        [
                            -101.61666666703303,
                            29.77083333299197
                        ],
                        [
                            -101.790277777997,
                            29.778888888811196
                        ],
                        [
                            -102.01111111148856,
                            29.790555555394633
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 411,
                "artcc": "zhu",
                "sector": "98",
                "sectype": "high",
                "Shape_Length": 5.011287879272577,
                "Shape_Area": 0.9511302082269865
            }
        },
        {
            "type": "Feature",
            "id": 427,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -88.1008333334915,
                            38.651388888886174
                        ],
                        [
                            -88.09444444445552,
                            38.485277777547424
                        ],
                        [
                            -88.233333332867,
                            38.150000000299826
                        ],
                        [
                            -88.27000000029972,
                            37.96277777787219
                        ],
                        [
                            -88.3166666666333,
                            37.72500000024985
                        ],
                        [
                            -87.7930555554695,
                            37.47000000039975
                        ],
                        [
                            -87.63333333316677,
                            37.391666666983156
                        ],
                        [
                            -87.39722222232768,
                            37.27500000024986
                        ],
                        [
                            -86.91666666673325,
                            37.288888888911174
                        ],
                        [
                            -86.92916666670828,
                            37.343055555769354
                        ],
                        [
                            -86.56666666653342,
                            37.658333333416635
                        ],
                        [
                            -86.59444444475531,
                            37.93055555549455
                        ],
                        [
                            -85.71666666643347,
                            37.98333333366645
                        ],
                        [
                            -85.75000000029974,
                            38.158333333316705
                        ],
                        [
                            -86.61666666643345,
                            38.625000000249884
                        ],
                        [
                            -86.71250000017483,
                            38.60833333331675
                        ],
                        [
                            -86.75000000009987,
                            38.700000000099976
                        ],
                        [
                            -86.96555555604402,
                            38.69333333296697
                        ],
                        [
                            -88.1008333334915,
                            38.651388888886174
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 427,
                "artcc": "zid",
                "sector": "17",
                "sectype": "high",
                "Shape_Length": 6.866155346988677,
                "Shape_Area": 2.382855207594351
            }
        },
        {
            "type": "Feature",
            "id": 428,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -85.28083333319171,
                            38.64361111116392
                        ],
                        [
                            -84.95555555574424,
                            38.97499999955039
                        ],
                        [
                            -85.36666666713296,
                            39.08333333326675
                        ],
                        [
                            -85.49999999990001,
                            39.08333333326675
                        ],
                        [
                            -86.05000000059954,
                            39.02777777772229
                        ],
                        [
                            -86.34166666698303,
                            38.9999999995004
                        ],
                        [
                            -86.43166666698306,
                            38.9999999995004
                        ],
                        [
                            -86.50000000059953,
                            38.9999999995004
                        ],
                        [
                            -86.75000000009987,
                            38.700000000099976
                        ],
                        [
                            -86.71250000017483,
                            38.60833333331675
                        ],
                        [
                            -86.61666666733277,
                            38.624999999350564
                        ],
                        [
                            -85.75000000029974,
                            38.158333333316705
                        ],
                        [
                            -85.31361111176335,
                            38.610277777297654
                        ],
                        [
                            -85.28083333319171,
                            38.64361111116392
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 428,
                "artcc": "zid",
                "sector": "18",
                "sectype": "high",
                "Shape_Length": 4.273089048652644,
                "Shape_Area": 0.940074228630955
            }
        },
        {
            "type": "Feature",
            "id": 430,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -86.59444444475531,
                            37.93055555549455
                        ],
                        [
                            -86.56666666653342,
                            37.658333333416635
                        ],
                        [
                            -86.92916666670828,
                            37.343055555769354
                        ],
                        [
                            -86.91666666673325,
                            37.288888888911174
                        ],
                        [
                            -86.4833333336664,
                            37.30000000019993
                        ],
                        [
                            -86.15000000039964,
                            37.30000000019993
                        ],
                        [
                            -85.99999999980014,
                            37.19444444475539
                        ],
                        [
                            -85.96361111136366,
                            37.1666666665335
                        ],
                        [
                            -85.58333333366636,
                            36.900000000099965
                        ],
                        [
                            -85.26222222227767,
                            37.32500000014994
                        ],
                        [
                            -85.3625000001748,
                            37.65000000039976
                        ],
                        [
                            -85.41666666703304,
                            37.85000000000008
                        ],
                        [
                            -85.71666666643347,
                            37.85000000000008
                        ],
                        [
                            -85.71666666643347,
                            37.98333333366645
                        ],
                        [
                            -86.59444444475531,
                            37.93055555549455
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 430,
                "artcc": "zid",
                "sector": "19",
                "sectype": "high",
                "Shape_Length": 4.662804488548223,
                "Shape_Area": 1.0199320987440386
            }
        },
        {
            "type": "Feature",
            "id": 432,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -84.40888888951059,
                            39.12138888848648
                        ],
                        [
                            -84.6272222228273,
                            38.9877777776224
                        ],
                        [
                            -84.89388888926078,
                            39.002222221578336
                        ],
                        [
                            -84.91250000017482,
                            38.96249999957536
                        ],
                        [
                            -84.95555555574424,
                            38.97499999955039
                        ],
                        [
                            -85.28083333319171,
                            38.64361111116392
                        ],
                        [
                            -85.31361111176335,
                            38.610277777297654
                        ],
                        [
                            -85.75000000029974,
                            38.158333333316705
                        ],
                        [
                            -85.71666666733279,
                            37.98333333276713
                        ],
                        [
                            -85.71666666733279,
                            37.85000000000008
                        ],
                        [
                            -85.41666666703304,
                            37.85000000000008
                        ],
                        [
                            -85.12083333369134,
                            37.87083333299199
                        ],
                        [
                            -84.93750000012488,
                            37.75888888851142
                        ],
                        [
                            -84.55638888903599,
                            37.863611110564364
                        ],
                        [
                            -83.9852777781469,
                            37.76888888831161
                        ],
                        [
                            -84.22111111178833,
                            38.02249999947543
                        ],
                        [
                            -84.38777777842171,
                            38.451666666483504
                        ],
                        [
                            -84.41305555556937,
                            38.51611111123884
                        ],
                        [
                            -84.29166666658335,
                            38.84305555546956
                        ],
                        [
                            -84.323888888961,
                            38.918055555319654
                        ],
                        [
                            -84.40888888951059,
                            39.12138888848648
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 432,
                "artcc": "zid",
                "sector": "20",
                "sectype": "high",
                "Shape_Length": 5.374779328190097,
                "Shape_Area": 1.3615361497357037
            }
        },
        {
            "type": "Feature",
            "id": 433,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -85.58333333366636,
                            36.900000000099965
                        ],
                        [
                            -85.40000000009991,
                            36.18333333366644
                        ],
                        [
                            -84.5833333329669,
                            36.3958333332418
                        ],
                        [
                            -84.11111111128872,
                            36.51249999997509
                        ],
                        [
                            -83.5583333332167,
                            36.6499999997003
                        ],
                        [
                            -83.31944444410578,
                            36.711111110889135
                        ],
                        [
                            -83.12916666710794,
                            36.76111111078916
                        ],
                        [
                            -83.11194444398086,
                            36.76472222245263
                        ],
                        [
                            -82.9750000004496,
                            36.794444444655426
                        ],
                        [
                            -83.32361111106388,
                            37.04166666678327
                        ],
                        [
                            -83.82916666660833,
                            37.59999999960036
                        ],
                        [
                            -83.9852777781469,
                            37.76888888921093
                        ],
                        [
                            -84.55638888903599,
                            37.86361111146368
                        ],
                        [
                            -84.93750000012488,
                            37.75888888851142
                        ],
                        [
                            -85.12083333369134,
                            37.87083333299199
                        ],
                        [
                            -85.41666666703304,
                            37.85000000000008
                        ],
                        [
                            -85.3625000001748,
                            37.65000000039976
                        ],
                        [
                            -85.26222222227767,
                            37.32500000014994
                        ],
                        [
                            -85.58333333366636,
                            36.900000000099965
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 433,
                "artcc": "zid",
                "sector": "21",
                "sectype": "high",
                "Shape_Length": 7.216784448231249,
                "Shape_Area": 2.6805968368357638
            }
        },
        {
            "type": "Feature",
            "id": 435,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -82.67305555616895,
                            39.807222222127905
                        ],
                        [
                            -82.97666666723285,
                            39.93472222205298
                        ],
                        [
                            -82.94416666675824,
                            40.03694444393102
                        ],
                        [
                            -83.06416666705798,
                            40.04833333331675
                        ],
                        [
                            -83.38305555546947,
                            39.91472222155335
                        ],
                        [
                            -83.43972222250255,
                            39.88972222160328
                        ],
                        [
                            -83.5683333339162,
                            39.83472222225282
                        ],
                        [
                            -83.84166666658336,
                            39.79583333274218
                        ],
                        [
                            -84.20500000014982,
                            39.82749999982519
                        ],
                        [
                            -84.31111111178836,
                            39.838333333016976
                        ],
                        [
                            -84.61666666683317,
                            39.84166666658342
                        ],
                        [
                            -84.73694444433062,
                            39.82833333321679
                        ],
                        [
                            -84.85000000029976,
                            39.66249999997507
                        ],
                        [
                            -84.84694444483023,
                            39.58249999977522
                        ],
                        [
                            -84.84611111143863,
                            39.563888888861186
                        ],
                        [
                            -84.82916666730779,
                            39.13750000012493
                        ],
                        [
                            -84.89388888926078,
                            39.002222221578336
                        ],
                        [
                            -84.6272222228273,
                            38.9877777776224
                        ],
                        [
                            -84.40888888951059,
                            39.12138888848648
                        ],
                        [
                            -84.31166666708299,
                            39.176388888736255
                        ],
                        [
                            -84.08333333396615,
                            39.23444444445562
                        ],
                        [
                            -83.91388888906096,
                            39.2933333326672
                        ],
                        [
                            -83.83722222242761,
                            39.32055555559447
                        ],
                        [
                            -83.53666666683318,
                            39.448055555519545
                        ],
                        [
                            -83.01638888923583,
                            39.66583333264225
                        ],
                        [
                            -82.67305555616895,
                            39.807222222127905
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 435,
                "artcc": "zid",
                "sector": "22",
                "sectype": "high",
                "Shape_Length": 5.54766765106476,
                "Shape_Area": 1.0778757330063833
            }
        },
        {
            "type": "Feature",
            "id": 436,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -82.54083333309183,
                            39.80500000005003
                        ],
                        [
                            -82.49555555544447,
                            39.47388888886121
                        ],
                        [
                            -82.4636111111638,
                            39.23888888861137
                        ],
                        [
                            -82.43888888931076,
                            39.05416666635864
                        ],
                        [
                            -82.38833333321674,
                            38.67444444395596
                        ],
                        [
                            -82.01527777794706,
                            38.54444444475536
                        ],
                        [
                            -81.88916666670826,
                            38.499999999600334
                        ],
                        [
                            -81.89999999990005,
                            38.36666666683328
                        ],
                        [
                            -81.8250000000499,
                            38.2222222218781
                        ],
                        [
                            -81.52916666670825,
                            38.27500000005
                        ],
                        [
                            -81.0499999998001,
                            38.14166666638357
                        ],
                        [
                            -80.7166666665334,
                            38.04166666658347
                        ],
                        [
                            -80.64472222215278,
                            38.38250000037476
                        ],
                        [
                            -80.61249999977514,
                            38.569444444705425
                        ],
                        [
                            -80.56666666683316,
                            38.76666666693319
                        ],
                        [
                            -80.51055555599407,
                            38.90361111136377
                        ],
                        [
                            -80.40000000019984,
                            39.1666666670331
                        ],
                        [
                            -80.49999999999994,
                            39.1666666670331
                        ],
                        [
                            -80.77777777772224,
                            39.31666666673334
                        ],
                        [
                            -81.10277777797205,
                            39.50416666635863
                        ],
                        [
                            -81.19166666648346,
                            39.55000000019993
                        ],
                        [
                            -81.42611111143862,
                            39.68222222237773
                        ],
                        [
                            -81.54999999970016,
                            39.749999999800195
                        ],
                        [
                            -81.9833333336664,
                            39.61666666703309
                        ],
                        [
                            -82.18888888891104,
                            39.68333333296698
                        ],
                        [
                            -82.26666666703301,
                            39.699999999900115
                        ],
                        [
                            -82.28333333306682,
                            39.799999999700276
                        ],
                        [
                            -82.54083333309183,
                            39.80500000005003
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 436,
                "artcc": "zid",
                "sector": "24",
                "sectype": "high",
                "Shape_Length": 6.695075845345313,
                "Shape_Area": 2.41404336434017
            }
        },
        {
            "type": "Feature",
            "id": 438,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -83.32361111106388,
                            37.04166666678327
                        ],
                        [
                            -82.9750000004496,
                            36.794444444655426
                        ],
                        [
                            -82.75833333301688,
                            36.843055555869284
                        ],
                        [
                            -82.25555555574425,
                            36.95972222260252
                        ],
                        [
                            -81.933333332867,
                            37.03333333286707
                        ],
                        [
                            -81.16249999957529,
                            37.211111110789204
                        ],
                        [
                            -80.90000000009991,
                            37.27083333329176
                        ],
                        [
                            -80.74583333344157,
                            37.30416666625871
                        ],
                        [
                            -80.82222222197794,
                            37.43333333296698
                        ],
                        [
                            -80.84166666628357,
                            37.46666666683325
                        ],
                        [
                            -80.82500000024976,
                            37.52916666670836
                        ],
                        [
                            -80.78944444430562,
                            37.708333333316716
                        ],
                        [
                            -80.7166666665334,
                            38.04166666658347
                        ],
                        [
                            -81.0499999998001,
                            38.14166666638357
                        ],
                        [
                            -81.52916666670825,
                            38.27500000005
                        ],
                        [
                            -81.8250000000499,
                            38.2222222218781
                        ],
                        [
                            -82.26666666703301,
                            37.92499999985017
                        ],
                        [
                            -83.21666666693312,
                            37.288888888911174
                        ],
                        [
                            -83.32361111106388,
                            37.04166666678327
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 438,
                "artcc": "zid",
                "sector": "25",
                "sectype": "high",
                "Shape_Length": 6.582189233753684,
                "Shape_Area": 2.240397762632817
            }
        },
        {
            "type": "Feature",
            "id": 440,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -83.68805555611897,
                            38.71361111066426
                        ],
                        [
                            -83.94388888936072,
                            38.768888889011066
                        ],
                        [
                            -84.29166666658335,
                            38.84305555546956
                        ],
                        [
                            -84.41305555556937,
                            38.51611111123884
                        ],
                        [
                            -84.38777777842171,
                            38.451666666483504
                        ],
                        [
                            -84.22111111178833,
                            38.02249999947543
                        ],
                        [
                            -83.9852777781469,
                            37.76888888831161
                        ],
                        [
                            -83.82916666660833,
                            37.59999999960036
                        ],
                        [
                            -83.32361111106388,
                            37.04166666678327
                        ],
                        [
                            -83.21666666693312,
                            37.288888888911174
                        ],
                        [
                            -82.26666666703301,
                            37.92499999985017
                        ],
                        [
                            -81.8250000000499,
                            38.2222222218781
                        ],
                        [
                            -81.89999999990005,
                            38.36666666683328
                        ],
                        [
                            -81.88916666670826,
                            38.499999999600334
                        ],
                        [
                            -82.01527777794706,
                            38.54444444385604
                        ],
                        [
                            -82.38833333321674,
                            38.67444444395596
                        ],
                        [
                            -83.21583333354147,
                            38.68027777769731
                        ],
                        [
                            -83.3869444443306,
                            38.68055555489502
                        ],
                        [
                            -83.54722222282726,
                            38.67083333319181
                        ],
                        [
                            -83.68805555611897,
                            38.71361111066426
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 440,
                "artcc": "zid",
                "sector": "26",
                "sectype": "high",
                "Shape_Length": 6.901934414135966,
                "Shape_Area": 2.5413945600451027
            }
        },
        {
            "type": "Feature",
            "id": 442,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -82.74166666698306,
                            40.55000000000007
                        ],
                        [
                            -82.9822222219779,
                            40.17666666663342
                        ],
                        [
                            -83.06416666705798,
                            40.04833333331675
                        ],
                        [
                            -82.94416666675824,
                            40.03694444483034
                        ],
                        [
                            -82.97666666633353,
                            39.93472222205298
                        ],
                        [
                            -82.67305555526963,
                            39.807222222127905
                        ],
                        [
                            -82.56666666643349,
                            39.805555555344654
                        ],
                        [
                            -82.54083333309183,
                            39.80500000005003
                        ],
                        [
                            -82.28333333306682,
                            39.799999999700276
                        ],
                        [
                            -82.26666666703301,
                            39.699999999900115
                        ],
                        [
                            -82.18888888891104,
                            39.68333333296698
                        ],
                        [
                            -81.9833333336664,
                            39.61666666703309
                        ],
                        [
                            -81.54999999970016,
                            39.749999999800195
                        ],
                        [
                            -81.5744444443556,
                            39.88750000042472
                        ],
                        [
                            -81.63333333346651,
                            40.21666666673332
                        ],
                        [
                            -81.69972222220275,
                            40.38722222222782
                        ],
                        [
                            -81.75444444435556,
                            40.527222222127875
                        ],
                        [
                            -81.78333333316675,
                            40.59999999990015
                        ],
                        [
                            -81.89166666688311,
                            40.59999999990015
                        ],
                        [
                            -82.16666666633353,
                            40.587499999925114
                        ],
                        [
                            -82.34444444425566,
                            40.5736111112638
                        ],
                        [
                            -82.5916666663835,
                            40.55416666695817
                        ],
                        [
                            -82.74166666698306,
                            40.55000000000007
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 442,
                "artcc": "zid",
                "sector": "30",
                "sectype": "high",
                "Shape_Length": 4.239343954264344,
                "Shape_Area": 1.0577113812948054
            }
        },
        {
            "type": "Feature",
            "id": 444,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -83.5683333339162,
                            39.83472222225282
                        ],
                        [
                            -83.43972222250255,
                            39.88972222160328
                        ],
                        [
                            -83.38305555546947,
                            39.91472222155335
                        ],
                        [
                            -83.06416666705798,
                            40.04833333331675
                        ],
                        [
                            -82.98222222287723,
                            40.17666666663342
                        ],
                        [
                            -82.74166666698306,
                            40.55000000000007
                        ],
                        [
                            -83.0619444449801,
                            40.52777777742256
                        ],
                        [
                            -83.16222222287723,
                            40.52055555499493
                        ],
                        [
                            -83.33333333366636,
                            40.508333333116866
                        ],
                        [
                            -83.42500000044964,
                            40.47083333319182
                        ],
                        [
                            -83.56666666713295,
                            40.39999999940051
                        ],
                        [
                            -83.65416666695808,
                            40.29166666658347
                        ],
                        [
                            -84.23722222252752,
                            40.08222222157832
                        ],
                        [
                            -84.40833333331665,
                            40.041666666183744
                        ],
                        [
                            -84.51666666703301,
                            40.033333333166865
                        ],
                        [
                            -84.53861111151355,
                            39.99166666628366
                        ],
                        [
                            -84.61666666683317,
                            39.84166666658342
                        ],
                        [
                            -84.31111111178836,
                            39.838333333016976
                        ],
                        [
                            -84.20500000014982,
                            39.82749999982519
                        ],
                        [
                            -83.84166666658336,
                            39.79583333274218
                        ],
                        [
                            -83.5683333339162,
                            39.83472222225282
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 444,
                "artcc": "zid",
                "sector": "31",
                "sectype": "high",
                "Shape_Length": 4.30702325516192,
                "Shape_Area": 0.6855043207829985
            }
        },
        {
            "type": "Feature",
            "id": 445,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -84.40833333331665,
                            40.041666666183744
                        ],
                        [
                            -84.23722222252752,
                            40.08222222157832
                        ],
                        [
                            -83.65416666695808,
                            40.29166666658347
                        ],
                        [
                            -83.56666666713295,
                            40.39999999940051
                        ],
                        [
                            -83.42500000044964,
                            40.47083333319182
                        ],
                        [
                            -83.50000000029974,
                            40.55833333301695
                        ],
                        [
                            -83.64999999999998,
                            40.737499999625356
                        ],
                        [
                            -83.7458333337413,
                            40.78333333256728
                        ],
                        [
                            -84.34722222212781,
                            40.863055555569474
                        ],
                        [
                            -84.70000000059952,
                            40.90833333321683
                        ],
                        [
                            -84.68777777782213,
                            40.744444443955956
                        ],
                        [
                            -84.99194444508004,
                            40.60694444423075
                        ],
                        [
                            -84.95833333401612,
                            40.56666666603388
                        ],
                        [
                            -84.82500000034969,
                            40.408333333316705
                        ],
                        [
                            -84.51666666703301,
                            40.033333333166865
                        ],
                        [
                            -84.40833333331665,
                            40.041666666183744
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 445,
                "artcc": "zid",
                "sector": "32",
                "sectype": "high",
                "Shape_Length": 3.8622302955269063,
                "Shape_Area": 0.8431724538502076
            }
        },
        {
            "type": "Feature",
            "id": 447,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -85.2250000004496,
                            39.774999999750264
                        ],
                        [
                            -84.73694444433062,
                            39.82833333321679
                        ],
                        [
                            -84.61666666683317,
                            39.84166666658342
                        ],
                        [
                            -84.53861111151355,
                            39.99166666628366
                        ],
                        [
                            -84.51666666703301,
                            40.033333333166865
                        ],
                        [
                            -84.82500000034969,
                            40.408333333316705
                        ],
                        [
                            -84.95833333401612,
                            40.56666666603388
                        ],
                        [
                            -84.99194444508004,
                            40.60694444423075
                        ],
                        [
                            -85.41444444495511,
                            40.66333333316686
                        ],
                        [
                            -85.43750000002495,
                            40.637499999825195
                        ],
                        [
                            -85.50416666685817,
                            40.55833333301695
                        ],
                        [
                            -85.56138888918588,
                            40.50277777747249
                        ],
                        [
                            -85.79750000002497,
                            40.27083333269218
                        ],
                        [
                            -85.95555555554444,
                            40.119444444305714
                        ],
                        [
                            -86.10000000049956,
                            39.9999999993006
                        ],
                        [
                            -86.0888888892108,
                            39.78055555539464
                        ],
                        [
                            -85.83638888953561,
                            39.691111110689235
                        ],
                        [
                            -85.2250000004496,
                            39.774999999750264
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 447,
                "artcc": "zid",
                "sector": "33",
                "sectype": "high",
                "Shape_Length": 4.05921409022404,
                "Shape_Area": 0.9911763887013567
            }
        },
        {
            "type": "Feature",
            "id": 448,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -86.0888888892108,
                            39.78055555539464
                        ],
                        [
                            -86.10000000049956,
                            39.9999999993006
                        ],
                        [
                            -86.31666666703302,
                            39.9999999993006
                        ],
                        [
                            -86.41666666683318,
                            39.9999999993006
                        ],
                        [
                            -86.95000000059952,
                            39.9999999993006
                        ],
                        [
                            -86.54111111128873,
                            39.7033333325673
                        ],
                        [
                            -86.4050000002498,
                            39.486111110739216
                        ],
                        [
                            -86.43166666698306,
                            38.9999999995004
                        ],
                        [
                            -86.34166666698303,
                            38.9999999995004
                        ],
                        [
                            -86.05000000059954,
                            39.02777777772229
                        ],
                        [
                            -85.49999999990001,
                            39.08333333326675
                        ],
                        [
                            -85.36666666713296,
                            39.08333333326675
                        ],
                        [
                            -84.95555555574424,
                            38.97499999955039
                        ],
                        [
                            -84.91250000017482,
                            38.96249999957536
                        ],
                        [
                            -84.89388888926078,
                            39.002222221578336
                        ],
                        [
                            -84.82916666730779,
                            39.13750000012493
                        ],
                        [
                            -84.84611111143863,
                            39.563888888861186
                        ],
                        [
                            -84.84694444483023,
                            39.58249999977522
                        ],
                        [
                            -84.85000000029976,
                            39.66249999997507
                        ],
                        [
                            -84.73694444433062,
                            39.82833333321679
                        ],
                        [
                            -85.2250000004496,
                            39.774999999750264
                        ],
                        [
                            -85.83638888953561,
                            39.691111110689235
                        ],
                        [
                            -86.0888888892108,
                            39.78055555539464
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 448,
                "artcc": "zid",
                "sector": "34",
                "sectype": "high",
                "Shape_Length": 6.15308186375082,
                "Shape_Area": 1.3259183638203615
            }
        },
        {
            "type": "Feature",
            "id": 449,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -86.96555555604402,
                            38.69333333296697
                        ],
                        [
                            -86.75000000009987,
                            38.700000000099976
                        ],
                        [
                            -86.50000000059953,
                            38.9999999995004
                        ],
                        [
                            -86.43166666698306,
                            38.9999999995004
                        ],
                        [
                            -86.4050000002498,
                            39.486111110739216
                        ],
                        [
                            -86.54111111128873,
                            39.7033333325673
                        ],
                        [
                            -86.95000000059952,
                            39.9999999993006
                        ],
                        [
                            -87.2686111118133,
                            39.9999999993006
                        ],
                        [
                            -88.16666666693311,
                            39.9999999993006
                        ],
                        [
                            -88.25000000069946,
                            39.9999999993006
                        ],
                        [
                            -88.18333333386624,
                            39.904166666458536
                        ],
                        [
                            -88.14805555601907,
                            39.86833333331674
                        ],
                        [
                            -88.12916666700806,
                            39.386111110939055
                        ],
                        [
                            -88.110277777997,
                            38.899166666308645
                        ],
                        [
                            -88.10666666723284,
                            38.80805555571936
                        ],
                        [
                            -88.10277777837172,
                            38.70361111086413
                        ],
                        [
                            -88.1008333334915,
                            38.651388888886174
                        ],
                        [
                            -86.96555555604402,
                            38.69333333296697
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 449,
                "artcc": "zid",
                "sector": "35",
                "sectype": "high",
                "Shape_Length": 5.743802532772152,
                "Shape_Area": 2.090376465242527
            }
        },
        {
            "type": "Feature",
            "id": 450,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -86.49999999970021,
                            39.00000000039972
                        ],
                        [
                            -86.29083333369135,
                            38.51611111123884
                        ],
                        [
                            -86.18083333319174,
                            38.56694444453052
                        ],
                        [
                            -85.01277777807195,
                            38.57333333356655
                        ],
                        [
                            -84.77472222235264,
                            38.5683333332168
                        ],
                        [
                            -83.67472222185302,
                            39.10833333321682
                        ],
                        [
                            -83.64888888851135,
                            39.42666666633363
                        ],
                        [
                            -83.62388888856134,
                            39.61083333329174
                        ],
                        [
                            -84.53249999967522,
                            39.68444444445561
                        ],
                        [
                            -85.2922222225775,
                            39.65222222207797
                        ],
                        [
                            -85.45833333301687,
                            39.51666666633366
                        ],
                        [
                            -86.34166666698303,
                            39.00000000039972
                        ],
                        [
                            -86.49999999970021,
                            39.00000000039972
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 450,
                "artcc": "zid",
                "sector": "66",
                "sectype": "high",
                "Shape_Length": 6.8532209905208745,
                "Shape_Area": 2.2847367285396976
            }
        },
        {
            "type": "Feature",
            "id": 451,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -82.43888888931076,
                            39.05416666635864
                        ],
                        [
                            -82.4636111111638,
                            39.23888888861137
                        ],
                        [
                            -82.49555555544447,
                            39.47388888886121
                        ],
                        [
                            -82.54083333399115,
                            39.80500000005003
                        ],
                        [
                            -82.56666666733281,
                            39.805555555344654
                        ],
                        [
                            -82.67305555616895,
                            39.807222222127905
                        ],
                        [
                            -83.01638888923583,
                            39.66583333264225
                        ],
                        [
                            -83.53666666683318,
                            39.448055555519545
                        ],
                        [
                            -83.83722222242761,
                            39.32055555559447
                        ],
                        [
                            -83.91388888906096,
                            39.2933333326672
                        ],
                        [
                            -84.08333333396615,
                            39.23444444445562
                        ],
                        [
                            -84.31166666708299,
                            39.176388888736255
                        ],
                        [
                            -84.40888888951059,
                            39.12138888848648
                        ],
                        [
                            -84.323888888961,
                            38.918055555319654
                        ],
                        [
                            -84.29166666658335,
                            38.84305555546956
                        ],
                        [
                            -83.94388888936072,
                            38.768888889011066
                        ],
                        [
                            -83.68805555611897,
                            38.71361111066426
                        ],
                        [
                            -83.54722222282726,
                            38.67083333319181
                        ],
                        [
                            -83.3869444443306,
                            38.68055555489502
                        ],
                        [
                            -83.21583333354147,
                            38.68027777769731
                        ],
                        [
                            -82.38833333321674,
                            38.67444444395596
                        ],
                        [
                            -82.43888888931076,
                            39.05416666635864
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 451,
                "artcc": "zid",
                "sector": "69",
                "sectype": "high",
                "Shape_Length": 5.368624341513309,
                "Shape_Area": 1.4843047841109132
            }
        },
        {
            "type": "Feature",
            "id": 456,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -87.48333333346653,
                            38.98333333256727
                        ],
                        [
                            -86.50000000059953,
                            38.9999999995004
                        ],
                        [
                            -86.34166666698303,
                            38.9999999995004
                        ],
                        [
                            -85.45833333391619,
                            39.51666666633366
                        ],
                        [
                            -85.2922222225775,
                            39.65222222207797
                        ],
                        [
                            -85.33027777779716,
                            40.06138888858641
                        ],
                        [
                            -85.23333333346653,
                            40.46666666623372
                        ],
                        [
                            -85.31666666723288,
                            40.43333333326677
                        ],
                        [
                            -85.52499999985008,
                            40.354166666458525
                        ],
                        [
                            -85.93333333386624,
                            40.10000000000008
                        ],
                        [
                            -86.10000000049956,
                            39.9999999993006
                        ],
                        [
                            -86.31666666703302,
                            39.9999999993006
                        ],
                        [
                            -86.41666666683318,
                            39.9999999993006
                        ],
                        [
                            -86.95000000059952,
                            39.9999999993006
                        ],
                        [
                            -87.2686111118133,
                            39.9999999993006
                        ],
                        [
                            -88.16666666693311,
                            39.9999999993006
                        ],
                        [
                            -88.25000000069946,
                            39.9999999993006
                        ],
                        [
                            -88.18333333386624,
                            39.904166666458536
                        ],
                        [
                            -88.14805555601907,
                            39.86833333331674
                        ],
                        [
                            -88.12916666700806,
                            39.386111110939055
                        ],
                        [
                            -88.110277777997,
                            38.899166666308645
                        ],
                        [
                            -87.48333333346653,
                            38.98333333256727
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 456,
                "artcc": "zid",
                "sector": "75",
                "sectype": "high",
                "Shape_Length": 8.114650442897085,
                "Shape_Area": 2.757793981468378
            }
        },
        {
            "type": "Feature",
            "id": 457,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -86.49999999970021,
                            39.00000000039972
                        ],
                        [
                            -86.29083333369135,
                            38.51611111123884
                        ],
                        [
                            -86.18083333319174,
                            38.56694444453052
                        ],
                        [
                            -85.01277777807195,
                            38.57333333356655
                        ],
                        [
                            -84.77472222235264,
                            38.5683333332168
                        ],
                        [
                            -83.67472222185302,
                            39.10833333321682
                        ],
                        [
                            -83.64888888851135,
                            39.42666666633363
                        ],
                        [
                            -83.62388888856134,
                            39.61083333329174
                        ],
                        [
                            -84.53249999967522,
                            39.68444444445561
                        ],
                        [
                            -85.2922222225775,
                            39.65222222207797
                        ],
                        [
                            -85.45833333301687,
                            39.51666666633366
                        ],
                        [
                            -86.34166666698303,
                            39.00000000039972
                        ],
                        [
                            -86.49999999970021,
                            39.00000000039972
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 457,
                "artcc": "zid",
                "sector": "76",
                "sectype": "high",
                "Shape_Length": 6.8532209905208745,
                "Shape_Area": 2.2847367285396976
            }
        },
        {
            "type": "Feature",
            "id": 458,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -83.62388888856134,
                            39.61083333329174
                        ],
                        [
                            -83.64888888851135,
                            39.42666666633363
                        ],
                        [
                            -83.67472222185302,
                            39.10833333321682
                        ],
                        [
                            -83.16611111083904,
                            38.877499999925135
                        ],
                        [
                            -83.03333333336661,
                            38.86333333316685
                        ],
                        [
                            -82.72138888928578,
                            38.830277777397555
                        ],
                        [
                            -80.51055555599407,
                            38.90361111136377
                        ],
                        [
                            -80.40000000019984,
                            39.1666666670331
                        ],
                        [
                            -80.49999999999994,
                            39.1666666670331
                        ],
                        [
                            -80.77777777772224,
                            39.31666666673334
                        ],
                        [
                            -81.10277777797205,
                            39.50416666635863
                        ],
                        [
                            -81.19166666648346,
                            39.55000000019993
                        ],
                        [
                            -81.42611111143862,
                            39.68222222237773
                        ],
                        [
                            -81.54999999970016,
                            39.749999999800195
                        ],
                        [
                            -81.5744444443556,
                            39.88750000042472
                        ],
                        [
                            -83.62388888856134,
                            39.61083333329174
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 458,
                "artcc": "zid",
                "sector": "77",
                "sectype": "high",
                "Shape_Length": 7.517368202627374,
                "Shape_Area": 2.431339583649213
            }
        },
        {
            "type": "Feature",
            "id": 459,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -85.23333333346653,
                            40.46666666713304
                        ],
                        [
                            -85.33027777779716,
                            40.06138888858641
                        ],
                        [
                            -85.2922222225775,
                            39.65222222207797
                        ],
                        [
                            -84.53249999967522,
                            39.68444444445561
                        ],
                        [
                            -83.62388888856134,
                            39.61083333329174
                        ],
                        [
                            -83.57472222205286,
                            39.8916666664835
                        ],
                        [
                            -82.91666666663332,
                            40.5666666669332
                        ],
                        [
                            -83.06666666633356,
                            40.57083333299198
                        ],
                        [
                            -83.45555555604403,
                            40.70555555534463
                        ],
                        [
                            -83.64999999999998,
                            40.737499999625356
                        ],
                        [
                            -83.7458333337413,
                            40.7833333334666
                        ],
                        [
                            -84.34722222212781,
                            40.863055555569474
                        ],
                        [
                            -84.6999999997002,
                            40.90833333321683
                        ],
                        [
                            -84.68777777782213,
                            40.744444443955956
                        ],
                        [
                            -84.68333333366638,
                            40.66666666673336
                        ],
                        [
                            -84.78888888911092,
                            40.633333332867096
                        ],
                        [
                            -84.9583333331168,
                            40.5666666669332
                        ],
                        [
                            -85.23333333346653,
                            40.46666666713304
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 459,
                "artcc": "zid",
                "sector": "78",
                "sectype": "high",
                "Shape_Length": 6.382301618629825,
                "Shape_Area": 2.1179128088028136
            }
        },
        {
            "type": "Feature",
            "id": 460,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -83.03333333336661,
                            38.86333333316685
                        ],
                        [
                            -83.02916666640851,
                            37.77777777752249
                        ],
                        [
                            -82.716666667033,
                            37.838888888711324
                        ],
                        [
                            -82.30833333301689,
                            37.91249999987514
                        ],
                        [
                            -81.0499999998001,
                            38.14166666638357
                        ],
                        [
                            -80.7166666665334,
                            38.04166666658347
                        ],
                        [
                            -80.64472222215278,
                            38.38250000037476
                        ],
                        [
                            -80.61249999977514,
                            38.569444444705425
                        ],
                        [
                            -80.56666666683316,
                            38.76666666693319
                        ],
                        [
                            -80.51055555599407,
                            38.90361111136377
                        ],
                        [
                            -82.72138888928578,
                            38.830277777397555
                        ],
                        [
                            -83.03333333336661,
                            38.86333333316685
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 460,
                "artcc": "zid",
                "sector": "79",
                "sectype": "high",
                "Shape_Length": 6.860196189450895,
                "Shape_Area": 2.117433950756815
            }
        },
        {
            "type": "Feature",
            "id": 461,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -87.26861111091398,
                            40.00000000019992
                        ],
                        [
                            -88.16666666693311,
                            40.00000000019992
                        ],
                        [
                            -88.24999999980014,
                            40.00000000019992
                        ],
                        [
                            -88.18333333296692,
                            39.904166666458536
                        ],
                        [
                            -88.14805555601907,
                            39.86833333331674
                        ],
                        [
                            -88.12916666700806,
                            39.386111110939055
                        ],
                        [
                            -88.110277777997,
                            38.899166666308645
                        ],
                        [
                            -87.48333333346653,
                            38.983333333466646
                        ],
                        [
                            -86.49999999970021,
                            39.00000000039972
                        ],
                        [
                            -87.26861111091398,
                            40.00000000019992
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 461,
                "artcc": "zid",
                "sector": "80",
                "sectype": "high",
                "Shape_Length": 4.995615284912133,
                "Shape_Area": 1.3014429020207945
            }
        },
        {
            "type": "Feature",
            "id": 462,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -88.110277777997,
                            38.899166666308645
                        ],
                        [
                            -88.10666666633352,
                            38.80805555571936
                        ],
                        [
                            -88.1027777774724,
                            38.70361111086413
                        ],
                        [
                            -88.1008333334915,
                            38.651388888886174
                        ],
                        [
                            -88.09444444445552,
                            38.485277777547424
                        ],
                        [
                            -88.233333332867,
                            38.150000000299826
                        ],
                        [
                            -88.27000000029972,
                            37.96277777787219
                        ],
                        [
                            -88.3166666666333,
                            37.72500000024985
                        ],
                        [
                            -87.7930555554695,
                            37.47000000039975
                        ],
                        [
                            -87.63333333316677,
                            37.391666666983156
                        ],
                        [
                            -87.39722222232768,
                            37.27500000024986
                        ],
                        [
                            -86.91666666673325,
                            37.288888888911174
                        ],
                        [
                            -86.4833333336664,
                            37.30000000019993
                        ],
                        [
                            -86.49416666685818,
                            37.64694444403091
                        ],
                        [
                            -86.51666666663334,
                            38.36666666683328
                        ],
                        [
                            -86.52361111096394,
                            38.40833333371643
                        ],
                        [
                            -86.29083333369135,
                            38.51611111123884
                        ],
                        [
                            -86.49999999970021,
                            39.00000000039972
                        ],
                        [
                            -87.48333333346653,
                            38.983333333466646
                        ],
                        [
                            -88.110277777997,
                            38.899166666308645
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 462,
                "artcc": "zid",
                "sector": "81",
                "sectype": "high",
                "Shape_Length": 6.657237374846402,
                "Shape_Area": 2.7511597603248616
            }
        },
        {
            "type": "Feature",
            "id": 463,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -86.29083333369135,
                            38.51611111123884
                        ],
                        [
                            -86.52361111096394,
                            38.40833333371643
                        ],
                        [
                            -86.51666666663334,
                            38.36666666683328
                        ],
                        [
                            -86.49416666685818,
                            37.64694444403091
                        ],
                        [
                            -86.4833333336664,
                            37.30000000019993
                        ],
                        [
                            -86.15000000039964,
                            37.30000000019993
                        ],
                        [
                            -85.99999999980014,
                            37.19444444475539
                        ],
                        [
                            -85.96361111136366,
                            37.1666666665335
                        ],
                        [
                            -84.97777777742243,
                            37.74583333324176
                        ],
                        [
                            -85.08611111113885,
                            38.30166666678332
                        ],
                        [
                            -85.01277777807195,
                            38.57333333356655
                        ],
                        [
                            -86.18083333319174,
                            38.56694444453052
                        ],
                        [
                            -86.29083333369135,
                            38.51611111123884
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 463,
                "artcc": "zid",
                "sector": "82",
                "sectype": "high",
                "Shape_Length": 5.208783210788617,
                "Shape_Area": 1.718582099266141
            }
        },
        {
            "type": "Feature",
            "id": 464,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -85.01277777807195,
                            38.57333333356655
                        ],
                        [
                            -85.08611111113885,
                            38.30166666678332
                        ],
                        [
                            -84.97777777742243,
                            37.74583333324176
                        ],
                        [
                            -84.55833333301689,
                            37.3833333330669
                        ],
                        [
                            -84.44444444455542,
                            37.41666666693317
                        ],
                        [
                            -83.9736111106642,
                            37.55972222230275
                        ],
                        [
                            -83.66666666693311,
                            37.65000000039976
                        ],
                        [
                            -83.50833333331661,
                            37.41388888866135
                        ],
                        [
                            -83.02916666640851,
                            37.77777777752249
                        ],
                        [
                            -83.03333333336661,
                            38.86333333316685
                        ],
                        [
                            -83.16611111083904,
                            38.877499999925135
                        ],
                        [
                            -83.67472222185302,
                            39.10833333321682
                        ],
                        [
                            -84.77472222235264,
                            38.5683333332168
                        ],
                        [
                            -85.01277777807195,
                            38.57333333356655
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 464,
                "artcc": "zid",
                "sector": "83",
                "sectype": "high",
                "Shape_Length": 6.459868673481439,
                "Shape_Area": 2.590482445919216
            }
        },
        {
            "type": "Feature",
            "id": 465,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -85.96361111136366,
                            37.1666666665335
                        ],
                        [
                            -85.58333333366636,
                            36.900000000099965
                        ],
                        [
                            -85.40000000009991,
                            36.18333333366644
                        ],
                        [
                            -84.5833333329669,
                            36.3958333332418
                        ],
                        [
                            -84.11111111128872,
                            36.51249999997509
                        ],
                        [
                            -83.5583333332167,
                            36.6499999997003
                        ],
                        [
                            -83.40833333351651,
                            37.26666666633366
                        ],
                        [
                            -83.50833333331661,
                            37.41388888866135
                        ],
                        [
                            -83.66666666693311,
                            37.65000000039976
                        ],
                        [
                            -83.9736111106642,
                            37.55972222230275
                        ],
                        [
                            -84.44444444455542,
                            37.41666666693317
                        ],
                        [
                            -84.55833333301689,
                            37.3833333330669
                        ],
                        [
                            -84.97777777742243,
                            37.74583333324176
                        ],
                        [
                            -85.96361111136366,
                            37.1666666665335
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 465,
                "artcc": "zid",
                "sector": "84",
                "sectype": "high",
                "Shape_Length": 6.8294663988674875,
                "Shape_Area": 2.412928433892272
            }
        },
        {
            "type": "Feature",
            "id": 466,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -83.03333333336661,
                            38.86333333316685
                        ],
                        [
                            -83.02916666640851,
                            37.77777777752249
                        ],
                        [
                            -82.716666667033,
                            37.838888888711324
                        ],
                        [
                            -82.30833333301689,
                            37.91249999987514
                        ],
                        [
                            -81.0499999998001,
                            38.14166666638357
                        ],
                        [
                            -80.7166666665334,
                            38.04166666658347
                        ],
                        [
                            -80.64472222215278,
                            38.38250000037476
                        ],
                        [
                            -80.61249999977514,
                            38.569444444705425
                        ],
                        [
                            -80.56666666683316,
                            38.76666666693319
                        ],
                        [
                            -80.51055555599407,
                            38.90361111136377
                        ],
                        [
                            -82.72138888928578,
                            38.830277777397555
                        ],
                        [
                            -83.03333333336661,
                            38.86333333316685
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 466,
                "artcc": "zid",
                "sector": "85",
                "sectype": "high",
                "Shape_Length": 6.860196189450895,
                "Shape_Area": 2.117433950756815
            }
        },
        {
            "type": "Feature",
            "id": 467,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -83.50833333331661,
                            37.41388888866135
                        ],
                        [
                            -83.40833333351651,
                            37.26666666633366
                        ],
                        [
                            -83.5583333332167,
                            36.6499999997003
                        ],
                        [
                            -83.31944444410578,
                            36.711111110889135
                        ],
                        [
                            -83.12916666710794,
                            36.76111111078916
                        ],
                        [
                            -83.11194444398086,
                            36.76472222245263
                        ],
                        [
                            -82.9750000004496,
                            36.794444444655426
                        ],
                        [
                            -82.75833333301688,
                            36.843055555869284
                        ],
                        [
                            -82.25555555574425,
                            36.95972222260252
                        ],
                        [
                            -81.933333332867,
                            37.03333333286707
                        ],
                        [
                            -81.16249999957529,
                            37.211111110789204
                        ],
                        [
                            -80.90000000009991,
                            37.27083333329176
                        ],
                        [
                            -80.74583333344157,
                            37.30416666625871
                        ],
                        [
                            -80.82222222197794,
                            37.43333333296698
                        ],
                        [
                            -80.84166666628357,
                            37.46666666683325
                        ],
                        [
                            -80.82500000024976,
                            37.52916666670836
                        ],
                        [
                            -80.78944444430562,
                            37.708333333316716
                        ],
                        [
                            -80.7166666665334,
                            38.04166666658347
                        ],
                        [
                            -81.0499999998001,
                            38.14166666638357
                        ],
                        [
                            -82.30833333301689,
                            37.91249999987514
                        ],
                        [
                            -82.716666667033,
                            37.838888888711324
                        ],
                        [
                            -83.02916666640851,
                            37.77777777752249
                        ],
                        [
                            -83.50833333331661,
                            37.41388888866135
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 467,
                "artcc": "zid",
                "sector": "86",
                "sectype": "high",
                "Shape_Length": 7.439625309243766,
                "Shape_Area": 2.503616319201141
            }
        },
        {
            "type": "Feature",
            "id": 468,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -82.91666666663332,
                            40.5666666669332
                        ],
                        [
                            -83.57472222205286,
                            39.8916666664835
                        ],
                        [
                            -83.62388888856134,
                            39.61083333329174
                        ],
                        [
                            -81.5744444443556,
                            39.88750000042472
                        ],
                        [
                            -81.63333333346651,
                            40.21666666673332
                        ],
                        [
                            -81.69972222220275,
                            40.38722222222782
                        ],
                        [
                            -81.75444444435556,
                            40.527222222127875
                        ],
                        [
                            -81.78333333316675,
                            40.59999999990015
                        ],
                        [
                            -81.89166666688311,
                            40.59999999990015
                        ],
                        [
                            -82.16666666633353,
                            40.587499999925114
                        ],
                        [
                            -82.34444444425566,
                            40.5736111112638
                        ],
                        [
                            -82.5916666663835,
                            40.55416666695817
                        ],
                        [
                            -82.91666666663332,
                            40.5666666669332
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 468,
                "artcc": "zid",
                "sector": "87",
                "sectype": "high",
                "Shape_Length": 5.177021278016243,
                "Shape_Area": 1.370090778991921
            }
        },
        {
            "type": "Feature",
            "id": 469,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -85.23333333346653,
                            40.46666666713304
                        ],
                        [
                            -85.33027777779716,
                            40.06138888858641
                        ],
                        [
                            -85.2922222225775,
                            39.65222222207797
                        ],
                        [
                            -84.53249999967522,
                            39.68444444445561
                        ],
                        [
                            -83.62388888856134,
                            39.61083333329174
                        ],
                        [
                            -83.57472222205286,
                            39.8916666664835
                        ],
                        [
                            -82.91666666663332,
                            40.5666666669332
                        ],
                        [
                            -83.06666666633356,
                            40.57083333299198
                        ],
                        [
                            -83.45555555604403,
                            40.70555555534463
                        ],
                        [
                            -83.64999999999998,
                            40.737499999625356
                        ],
                        [
                            -83.7458333337413,
                            40.7833333334666
                        ],
                        [
                            -84.34722222212781,
                            40.863055555569474
                        ],
                        [
                            -84.6999999997002,
                            40.90833333321683
                        ],
                        [
                            -84.68777777782213,
                            40.744444443955956
                        ],
                        [
                            -84.68333333366638,
                            40.66666666673336
                        ],
                        [
                            -84.78888888911092,
                            40.633333332867096
                        ],
                        [
                            -84.9583333331168,
                            40.5666666669332
                        ],
                        [
                            -85.23333333346653,
                            40.46666666713304
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 469,
                "artcc": "zid",
                "sector": "88",
                "sectype": "high",
                "Shape_Length": 6.382301618629825,
                "Shape_Area": 2.1179128088028136
            }
        },
        {
            "type": "Feature",
            "id": 470,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -87.26861111091398,
                            40.00000000019992
                        ],
                        [
                            -86.49999999970021,
                            39.00000000039972
                        ],
                        [
                            -86.34166666698303,
                            39.00000000039972
                        ],
                        [
                            -85.45833333301687,
                            39.51666666633366
                        ],
                        [
                            -85.2922222225775,
                            39.65222222207797
                        ],
                        [
                            -85.33027777779716,
                            40.06138888858641
                        ],
                        [
                            -85.23333333346653,
                            40.46666666713304
                        ],
                        [
                            -85.31666666633356,
                            40.43333333326677
                        ],
                        [
                            -85.52499999985008,
                            40.354166666458525
                        ],
                        [
                            -85.93333333296692,
                            40.10000000000008
                        ],
                        [
                            -86.09999999960024,
                            40.00000000019992
                        ],
                        [
                            -86.31666666703302,
                            40.00000000019992
                        ],
                        [
                            -86.41666666683318,
                            40.00000000019992
                        ],
                        [
                            -86.9499999997002,
                            40.00000000019992
                        ],
                        [
                            -87.26861111091398,
                            40.00000000019992
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 470,
                "artcc": "zid",
                "sector": "89",
                "sectype": "high",
                "Shape_Length": 5.6415442578142745,
                "Shape_Area": 1.4563510796577535
            }
        },
        {
            "type": "Feature",
            "id": 473,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -85.01277777807195,
                            38.57333333356655
                        ],
                        [
                            -85.08611111113885,
                            38.30166666678332
                        ],
                        [
                            -84.97777777742243,
                            37.74583333324176
                        ],
                        [
                            -84.55833333301689,
                            37.3833333330669
                        ],
                        [
                            -84.44444444455542,
                            37.41666666693317
                        ],
                        [
                            -83.9736111106642,
                            37.55972222230275
                        ],
                        [
                            -83.66666666693311,
                            37.65000000039976
                        ],
                        [
                            -83.50833333331661,
                            37.41388888866135
                        ],
                        [
                            -83.02916666640851,
                            37.77777777752249
                        ],
                        [
                            -83.03333333336661,
                            38.86333333316685
                        ],
                        [
                            -83.16611111083904,
                            38.877499999925135
                        ],
                        [
                            -83.67472222185302,
                            39.10833333321682
                        ],
                        [
                            -84.77472222235264,
                            38.5683333332168
                        ],
                        [
                            -85.01277777807195,
                            38.57333333356655
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 473,
                "artcc": "zid",
                "sector": "93",
                "sectype": "high",
                "Shape_Length": 6.459868673481439,
                "Shape_Area": 2.590482445919216
            }
        },
        {
            "type": "Feature",
            "id": 492,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -87.58888888891107,
                            31.0500000001
                        ],
                        [
                            -87.65833333311679,
                            30.958333333316716
                        ],
                        [
                            -87.68333333306686,
                            30.845833333541577
                        ],
                        [
                            -87.69166666698305,
                            30.791666666683398
                        ],
                        [
                            -87.71666666693312,
                            30.691666666883236
                        ],
                        [
                            -87.80833333371635,
                            30.68333333296698
                        ],
                        [
                            -87.91666666653339,
                            30.6333333330669
                        ],
                        [
                            -87.91666666653339,
                            30.516666666333663
                        ],
                        [
                            -86.91666666673325,
                            30.646111111138907
                        ],
                        [
                            -86.78333333306682,
                            30.704444444055923
                        ],
                        [
                            -86.76250000007491,
                            30.71277777797212
                        ],
                        [
                            -86.46027777769723,
                            30.72166666628368
                        ],
                        [
                            -86.17500000034971,
                            30.729444444005935
                        ],
                        [
                            -86.08611111093899,
                            30.683611111063954
                        ],
                        [
                            -85.93333333296692,
                            30.70388888876124
                        ],
                        [
                            -85.70000000039965,
                            30.73611111113894
                        ],
                        [
                            -85.66666666653339,
                            30.73611111113894
                        ],
                        [
                            -85.16249999967522,
                            30.722222222477626
                        ],
                        [
                            -85.53333333286696,
                            31.216666666733317
                        ],
                        [
                            -85.89166666698304,
                            31.70694444403091
                        ],
                        [
                            -85.92499999994999,
                            31.70833333361651
                        ],
                        [
                            -86.5083333336164,
                            31.57916666690818
                        ],
                        [
                            -86.74583333314177,
                            31.529166667008155
                        ],
                        [
                            -86.9499999997002,
                            31.491666667083052
                        ],
                        [
                            -87.39999999970019,
                            31.28055555529471
                        ],
                        [
                            -87.58888888891107,
                            31.0500000001
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 492,
                "artcc": "zjx",
                "sector": "11",
                "sectype": "high",
                "Shape_Length": 6.609121676833366,
                "Shape_Area": 1.8335468757300073
            }
        },
        {
            "type": "Feature",
            "id": 495,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -82.87916666670827,
                            29.204166666258686
                        ],
                        [
                            -82.716666667033,
                            28.741666666283663
                        ],
                        [
                            -82.66083333339157,
                            28.396111111138907
                        ],
                        [
                            -82.65749999982512,
                            27.950000000000045
                        ],
                        [
                            -82.38333333286698,
                            27.950000000000045
                        ],
                        [
                            -82.20694444453045,
                            28.03000000019989
                        ],
                        [
                            -81.9119444445804,
                            28.162499999575346
                        ],
                        [
                            -81.86222222187797,
                            28.185000000249886
                        ],
                        [
                            -81.78611111143863,
                            28.589722222602518
                        ],
                        [
                            -81.95138888848635,
                            28.616111111238865
                        ],
                        [
                            -82.04166666658335,
                            28.633333333466624
                        ],
                        [
                            -82.36527777814689,
                            28.68694444413086
                        ],
                        [
                            -82.50583333334163,
                            29.262222221977993
                        ],
                        [
                            -82.54333333326667,
                            29.878611111413704
                        ],
                        [
                            -82.66666666713297,
                            29.799999999900137
                        ],
                        [
                            -82.89999999970019,
                            29.799999999900137
                        ],
                        [
                            -82.89999999970019,
                            29.66666666713303
                        ],
                        [
                            -82.89999999970019,
                            29.622222221978006
                        ],
                        [
                            -82.89999999970019,
                            29.466666666633387
                        ],
                        [
                            -82.87916666670827,
                            29.204166666258686
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 495,
                "artcc": "zjx",
                "sector": "14",
                "sectype": "high",
                "Shape_Length": 5.317285100789104,
                "Shape_Area": 0.9430937499870248
            }
        },
        {
            "type": "Feature",
            "id": 497,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -82.54333333326667,
                            29.878611111413704
                        ],
                        [
                            -82.50583333334163,
                            29.262222221977993
                        ],
                        [
                            -82.36527777814689,
                            28.68694444413086
                        ],
                        [
                            -82.04166666658335,
                            28.633333333466624
                        ],
                        [
                            -81.95138888848635,
                            28.616111111238865
                        ],
                        [
                            -81.78611111143863,
                            28.589722222602518
                        ],
                        [
                            -81.5947222220529,
                            28.5561111115386
                        ],
                        [
                            -81.73416666665833,
                            28.864166666758308
                        ],
                        [
                            -81.70833333331666,
                            28.999999999700265
                        ],
                        [
                            -81.78416666655835,
                            29.295277777747287
                        ],
                        [
                            -81.78944444410575,
                            29.3161111107392
                        ],
                        [
                            -81.80694444443054,
                            29.38361111096407
                        ],
                        [
                            -81.84166666698303,
                            29.522222222177845
                        ],
                        [
                            -81.86722222222772,
                            29.605833333141845
                        ],
                        [
                            -81.96666666673326,
                            29.93055555529469
                        ],
                        [
                            -82.28333333306682,
                            30.050000000299804
                        ],
                        [
                            -82.54333333326667,
                            29.878611111413704
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 497,
                "artcc": "zjx",
                "sector": "15",
                "sectype": "high",
                "Shape_Length": 4.083625279953603,
                "Shape_Area": 0.9245192516528827
            }
        },
        {
            "type": "Feature",
            "id": 499,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -83.51666666633355,
                            28.93333333286705
                        ],
                        [
                            -83.51666666633355,
                            28.400000000000034
                        ],
                        [
                            -83.20833333301687,
                            28.158333333516566
                        ],
                        [
                            -82.94166666658333,
                            27.950000000000045
                        ],
                        [
                            -82.65749999982512,
                            27.950000000000045
                        ],
                        [
                            -82.38333333286698,
                            27.950000000000045
                        ],
                        [
                            -82.20694444453045,
                            28.03000000019989
                        ],
                        [
                            -81.9119444445804,
                            28.162499999575346
                        ],
                        [
                            -81.86222222187797,
                            28.185000000249886
                        ],
                        [
                            -81.78611111143863,
                            28.589722222602518
                        ],
                        [
                            -81.73416666665833,
                            28.864166666758308
                        ],
                        [
                            -81.70833333331666,
                            28.999999999700265
                        ],
                        [
                            -81.78416666655835,
                            29.295277777747287
                        ],
                        [
                            -81.78944444410575,
                            29.3161111107392
                        ],
                        [
                            -81.80694444443054,
                            29.38361111096407
                        ],
                        [
                            -81.84166666698303,
                            29.522222222177845
                        ],
                        [
                            -81.8666666669331,
                            29.358333332917027
                        ],
                        [
                            -82.49999999960028,
                            29.283333333066935
                        ],
                        [
                            -82.86666666673324,
                            29.550000000399734
                        ],
                        [
                            -82.89999999970019,
                            29.183333333266773
                        ],
                        [
                            -83.51666666633355,
                            28.93333333286705
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 499,
                "artcc": "zjx",
                "sector": "16",
                "sectype": "high",
                "Shape_Length": 6.052368856540036,
                "Shape_Area": 2.0589698294485075
            }
        },
        {
            "type": "Feature",
            "id": 500,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -84.90611111113884,
                            30.447777777422516
                        ],
                        [
                            -84.78333333346654,
                            30.31666666673334
                        ],
                        [
                            -84.52500000004994,
                            30.020833333391693
                        ],
                        [
                            -84.40833333331665,
                            29.85027777789719
                        ],
                        [
                            -84.33361111156353,
                            29.708888888411536
                        ],
                        [
                            -84.0000000001998,
                            29.708333333116855
                        ],
                        [
                            -83.75666666693309,
                            29.319722221503355
                        ],
                        [
                            -83.71305555606904,
                            29.250000000099988
                        ],
                        [
                            -83.51666666723287,
                            28.93333333286705
                        ],
                        [
                            -82.9000000005995,
                            29.183333333266773
                        ],
                        [
                            -82.86666666673324,
                            29.549999999500415
                        ],
                        [
                            -82.9000000005995,
                            29.66666666623371
                        ],
                        [
                            -83.22083333389122,
                            30.404166666558467
                        ],
                        [
                            -83.57277777807195,
                            30.370555555494548
                        ],
                        [
                            -84.38249999997498,
                            30.29027777719773
                        ],
                        [
                            -84.90611111113884,
                            30.447777777422516
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 500,
                "artcc": "zjx",
                "sector": "17",
                "sectype": "high",
                "Shape_Length": 5.859129996352233,
                "Shape_Area": 1.5557199840402913
            }
        },
        {
            "type": "Feature",
            "id": 503,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -86.79999999999995,
                            28.5052777773476
                        ],
                        [
                            -86.79999999999995,
                            29.422499999575393
                        ],
                        [
                            -86.79999999999995,
                            29.499999999600334
                        ],
                        [
                            -86.79999999999995,
                            29.966666666533513
                        ],
                        [
                            -87.21972222250253,
                            29.966666666533513
                        ],
                        [
                            -87.58972222230267,
                            29.966666666533513
                        ],
                        [
                            -88.02500000024975,
                            29.966666666533513
                        ],
                        [
                            -88.01666666723287,
                            29.599999999400495
                        ],
                        [
                            -88.01388888896099,
                            29.574999999450483
                        ],
                        [
                            -88.01111111158849,
                            28.999999999700265
                        ],
                        [
                            -88.00000000029974,
                            28.141666666583433
                        ],
                        [
                            -87.84783611124749,
                            27.83333333326675
                        ],
                        [
                            -87.68333333396617,
                            27.500000000000057
                        ],
                        [
                            -86.81722222222771,
                            27.241388888486483
                        ],
                        [
                            -86.00000000069946,
                            27.000000000099988
                        ],
                        [
                            -85.25000000039967,
                            27.500000000000057
                        ],
                        [
                            -84.94444444445548,
                            28.04305555546955
                        ],
                        [
                            -84.99999999999994,
                            28.039722221903048
                        ],
                        [
                            -85.20361111126374,
                            28.028611110614293
                        ],
                        [
                            -86.79999999999995,
                            28.5052777773476
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 503,
                "artcc": "zjx",
                "sector": "30",
                "sectype": "high",
                "Shape_Length": 10.433397917749254,
                "Shape_Area": 4.768063931218434
            }
        },
        {
            "type": "Feature",
            "id": 505,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -84.16249999987508,
                            31.67499999975024
                        ],
                        [
                            -83.82499999965023,
                            30.71111111118887
                        ],
                        [
                            -83.57277777807195,
                            30.370555555494548
                        ],
                        [
                            -83.2208333329919,
                            30.404166666558467
                        ],
                        [
                            -83.15833333311679,
                            30.411111110889124
                        ],
                        [
                            -83.03416666675821,
                            30.46333333286708
                        ],
                        [
                            -82.78333333296689,
                            30.933333333366704
                        ],
                        [
                            -83.1125000001748,
                            31.68333333366644
                        ],
                        [
                            -84.09999999999997,
                            31.68333333366644
                        ],
                        [
                            -84.16249999987508,
                            31.67499999975024
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 505,
                "artcc": "zjx",
                "sector": "33",
                "sectype": "high",
                "Shape_Length": 4.398538299175152,
                "Shape_Area": 1.2675721455213176
            }
        },
        {
            "type": "Feature",
            "id": 506,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -85.89166666698304,
                            31.70694444403091
                        ],
                        [
                            -85.53333333286696,
                            31.216666666733317
                        ],
                        [
                            -85.16249999967522,
                            30.722222222477626
                        ],
                        [
                            -85.13277777747243,
                            30.688611111413707
                        ],
                        [
                            -84.90611111113884,
                            30.447777777422516
                        ],
                        [
                            -84.38249999997498,
                            30.29027777809705
                        ],
                        [
                            -83.57277777807195,
                            30.370555555494548
                        ],
                        [
                            -83.82499999965023,
                            30.71111111118887
                        ],
                        [
                            -84.16249999987508,
                            31.67499999975024
                        ],
                        [
                            -84.53750000002492,
                            31.627777778122038
                        ],
                        [
                            -85.30833333331663,
                            31.533333333066935
                        ],
                        [
                            -85.58333333366636,
                            31.76249999957537
                        ],
                        [
                            -85.89166666698304,
                            31.70694444403091
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 506,
                "artcc": "zjx",
                "sector": "34",
                "sectype": "high",
                "Shape_Length": 6.232274516036206,
                "Shape_Area": 1.8708859178425212
            }
        },
        {
            "type": "Feature",
            "id": 507,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -77.0333333336664,
                            29.999999999500403
                        ],
                        [
                            -77.00000000069946,
                            29.999999999500403
                        ],
                        [
                            -77.00000000069946,
                            31.355555555144804
                        ],
                        [
                            -77.50000000059953,
                            31.50027777729764
                        ],
                        [
                            -78.29083333349149,
                            31.724166666258668
                        ],
                        [
                            -78.75583333364142,
                            31.853333332966997
                        ],
                        [
                            -79.16333333336661,
                            30.581388888286597
                        ],
                        [
                            -79.24666666713296,
                            30.31666666673334
                        ],
                        [
                            -79.28666666723285,
                            30.200277777197698
                        ],
                        [
                            -78.66916666720789,
                            30.144166666358615
                        ],
                        [
                            -78.57027777799703,
                            30.13472222185311
                        ],
                        [
                            -77.9244444444555,
                            30.072222221977995
                        ],
                        [
                            -77.85666666703304,
                            30.065555555744368
                        ],
                        [
                            -77.50000000059953,
                            30.029444444505543
                        ],
                        [
                            -77.21666666723286,
                            29.999999999500403
                        ],
                        [
                            -77.0333333336664,
                            29.999999999500403
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 507,
                "artcc": "zjx",
                "sector": "35",
                "sectype": "high",
                "Shape_Length": 7.213180490363852,
                "Shape_Area": 3.151814042123361
            }
        },
        {
            "type": "Feature",
            "id": 508,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -79.72805555521967,
                            34.71333333336668
                        ],
                        [
                            -80.31250000037465,
                            33.733333333166854
                        ],
                        [
                            -80.86666666713296,
                            32.783333333266796
                        ],
                        [
                            -80.23222222207784,
                            32.40250000027487
                        ],
                        [
                            -80.17666666653338,
                            32.490833333491594
                        ],
                        [
                            -80.13305555566933,
                            32.50444444405588
                        ],
                        [
                            -79.75750000022481,
                            32.70277777777227
                        ],
                        [
                            -79.55666666633357,
                            32.62527777774727
                        ],
                        [
                            -79.28305555556938,
                            33.00027777789717
                        ],
                        [
                            -79.18583333314177,
                            33.09333333336667
                        ],
                        [
                            -79.09138888898599,
                            33.18361111146368
                        ],
                        [
                            -79.07055555599408,
                            33.28361111126384
                        ],
                        [
                            -79.03583333344153,
                            33.32805555551954
                        ],
                        [
                            -79.00194444428064,
                            33.36777777752246
                        ],
                        [
                            -78.9219444440808,
                            33.46138888918591
                        ],
                        [
                            -78.67055555589417,
                            33.66972222180317
                        ],
                        [
                            -78.5586111114136,
                            33.7400000002998
                        ],
                        [
                            -78.39555555554443,
                            33.841944444080866
                        ],
                        [
                            -78.14972222210281,
                            33.841944444080866
                        ],
                        [
                            -78.11916666650842,
                            33.98027777809705
                        ],
                        [
                            -78.35833333371636,
                            34.157222222627524
                        ],
                        [
                            -78.68499999985005,
                            34.39694444423077
                        ],
                        [
                            -78.74999999990001,
                            34.444444443956
                        ],
                        [
                            -79.06666666713295,
                            34.43416666695816
                        ],
                        [
                            -79.26333333316677,
                            34.42749999982516
                        ],
                        [
                            -79.33333333356643,
                            34.42499999965031
                        ],
                        [
                            -79.72805555521967,
                            34.71333333336668
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 508,
                "artcc": "zjx",
                "sector": "47",
                "sectype": "high",
                "Shape_Length": 7.7283290710657235,
                "Shape_Area": 2.9952111501364267
            }
        },
        {
            "type": "Feature",
            "id": 511,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -81.41666666693311,
                            32.38333333316683
                        ],
                        [
                            -81.89999999990005,
                            32.67777777782226
                        ],
                        [
                            -81.933333332867,
                            32.56249999977524
                        ],
                        [
                            -82.06666666653342,
                            32.43333333306691
                        ],
                        [
                            -82.2000000001998,
                            32.291666666383605
                        ],
                        [
                            -82.3333333329669,
                            32.16666666663343
                        ],
                        [
                            -82.74166666698306,
                            31.91388888886121
                        ],
                        [
                            -83.1125000001748,
                            31.68333333366644
                        ],
                        [
                            -82.78333333296689,
                            30.933333333366704
                        ],
                        [
                            -82.65000000019984,
                            30.749999999800195
                        ],
                        [
                            -82.41944444410575,
                            30.577777777522442
                        ],
                        [
                            -82.10000000039969,
                            30.35472222195301
                        ],
                        [
                            -82.05416666655839,
                            30.205555555644423
                        ],
                        [
                            -81.98749999972517,
                            30.36666666663342
                        ],
                        [
                            -81.94166666678319,
                            30.47083333339168
                        ],
                        [
                            -81.55833333361642,
                            30.829166666608444
                        ],
                        [
                            -81.54999999970016,
                            31.07222222177819
                        ],
                        [
                            -81.58749999962527,
                            31.28333333356653
                        ],
                        [
                            -81.49999999980014,
                            31.483333333166854
                        ],
                        [
                            -81.49999999980014,
                            32.033333332967004
                        ],
                        [
                            -81.41666666693311,
                            32.38333333316683
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 511,
                "artcc": "zjx",
                "sector": "50",
                "sectype": "high",
                "Shape_Length": 6.443596228224098,
                "Shape_Area": 2.3418314044230053
            }
        },
        {
            "type": "Feature",
            "id": 513,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -77.00000000069946,
                            31.583333332967015
                        ],
                        [
                            -77.00000000069946,
                            31.666666666733363
                        ],
                        [
                            -77.00000000069946,
                            32.00000000000006
                        ],
                        [
                            -77.00000000069946,
                            32.262222222277785
                        ],
                        [
                            -76.77944444440556,
                            32.967777777422555
                        ],
                        [
                            -77.19833333351647,
                            33.288611110714214
                        ],
                        [
                            -77.51166666718291,
                            33.52555555494496
                        ],
                        [
                            -77.75083333349153,
                            33.705555554944965
                        ],
                        [
                            -77.93277777837176,
                            33.841666665983894
                        ],
                        [
                            -78.11916666740774,
                            33.98027777719773
                        ],
                        [
                            -78.14972222210281,
                            33.841944444080866
                        ],
                        [
                            -78.46083333369137,
                            32.78777777742255
                        ],
                        [
                            -78.59972222210286,
                            32.3336111104644
                        ],
                        [
                            -78.75583333364142,
                            31.853333332966997
                        ],
                        [
                            -78.29083333349149,
                            31.724166666258668
                        ],
                        [
                            -77.50000000059953,
                            31.50027777729764
                        ],
                        [
                            -77.00000000069946,
                            31.355555555144804
                        ],
                        [
                            -77.00000000069946,
                            31.583333332967015
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 513,
                "artcc": "zjx",
                "sector": "52",
                "sectype": "high",
                "Shape_Length": 7.370971913646747,
                "Shape_Area": 3.093972028435931
            }
        },
        {
            "type": "Feature",
            "id": 518,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -80.84805555621892,
                            28.82194444368116
                        ],
                        [
                            -80.7941666665584,
                            28.85861111111393
                        ],
                        [
                            -80.73333333346653,
                            28.899999999900103
                        ],
                        [
                            -80.54083333349149,
                            28.9391666666084
                        ],
                        [
                            -80.60500000014986,
                            28.999999999700265
                        ],
                        [
                            -80.64305555626885,
                            29.054444443756154
                        ],
                        [
                            -80.65500000004994,
                            29.073611110864135
                        ],
                        [
                            -80.6911111112887,
                            29.130555555094816
                        ],
                        [
                            -80.7666666673328,
                            29.250000000099988
                        ],
                        [
                            -80.80722222272738,
                            29.337499999925114
                        ],
                        [
                            -80.87527777824681,
                            29.4833333326672
                        ],
                        [
                            -80.90000000009991,
                            29.53611111083916
                        ],
                        [
                            -80.97444444465538,
                            29.68305555506987
                        ],
                        [
                            -80.99999999990001,
                            29.733333333066923
                        ],
                        [
                            -81.03388888906096,
                            29.854166666758317
                        ],
                        [
                            -81.03083333359143,
                            29.97777777782221
                        ],
                        [
                            -81.02888888961053,
                            30.055833333141834
                        ],
                        [
                            -80.99638888913591,
                            30.31666666673334
                        ],
                        [
                            -80.96333333336662,
                            30.581388888286597
                        ],
                        [
                            -81.34000000029971,
                            30.56444444415581
                        ],
                        [
                            -81.32500000014983,
                            30.31666666673334
                        ],
                        [
                            -81.30333333376632,
                            29.959722222202856
                        ],
                        [
                            -81.29611111133869,
                            29.839444443806087
                        ],
                        [
                            -81.26777777782218,
                            29.366666666833282
                        ],
                        [
                            -81.10555555624387,
                            28.999999999700265
                        ],
                        [
                            -80.98611111123876,
                            28.727777777622407
                        ],
                        [
                            -80.84805555621892,
                            28.82194444368116
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 518,
                "artcc": "zjx",
                "sector": "58",
                "sectype": "high",
                "Shape_Length": 4.557366177603657,
                "Shape_Area": 0.6411487652517575
            }
        },
        {
            "type": "Feature",
            "id": 521,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -80.79388888936074,
                            34.949166666108795
                        ],
                        [
                            -81.08361111176333,
                            34.841944443881005
                        ],
                        [
                            -81.21722222262741,
                            34.77888888871132
                        ],
                        [
                            -81.2333333333666,
                            34.50833333341666
                        ],
                        [
                            -81.24166666728286,
                            34.43222222207794
                        ],
                        [
                            -81.33333333316676,
                            34.39444444405592
                        ],
                        [
                            -81.5744444443556,
                            34.293611110864106
                        ],
                        [
                            -81.80444444425564,
                            33.985277777547424
                        ],
                        [
                            -81.84999999999997,
                            33.888888888511474
                        ],
                        [
                            -81.84999999999997,
                            33.68333333326677
                        ],
                        [
                            -81.84999999999997,
                            33.55555555524472
                        ],
                        [
                            -81.84666666733278,
                            33.51694444383105
                        ],
                        [
                            -81.84138888888606,
                            32.90027777719769
                        ],
                        [
                            -81.84361111096393,
                            32.891666666083836
                        ],
                        [
                            -81.89999999990005,
                            32.67777777782226
                        ],
                        [
                            -81.41666666693311,
                            32.38333333316683
                        ],
                        [
                            -80.86666666713296,
                            32.783333333266796
                        ],
                        [
                            -80.31250000037465,
                            33.733333333166854
                        ],
                        [
                            -79.72805555611899,
                            34.71333333336668
                        ],
                        [
                            -79.91666666723285,
                            34.84999999970029
                        ],
                        [
                            -80.14722222242756,
                            34.855555555344665
                        ],
                        [
                            -80.46888888911093,
                            34.74749999972528
                        ],
                        [
                            -80.5177777784217,
                            34.727777777322615
                        ],
                        [
                            -80.57833333341654,
                            34.70333333266723
                        ],
                        [
                            -80.724166667058,
                            34.644166666358615
                        ],
                        [
                            -80.79388888936074,
                            34.949166666108795
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 521,
                "artcc": "zjx",
                "sector": "66",
                "sectype": "high",
                "Shape_Length": 7.752942071959901,
                "Shape_Area": 3.071757560389899
            }
        },
        {
            "type": "Feature",
            "id": 523,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -81.41666666693311,
                            32.38333333316683
                        ],
                        [
                            -81.49999999980014,
                            32.033333332967004
                        ],
                        [
                            -81.49999999980014,
                            31.483333333166854
                        ],
                        [
                            -81.58749999962527,
                            31.28333333356653
                        ],
                        [
                            -81.54999999970016,
                            31.07222222177819
                        ],
                        [
                            -81.55833333361642,
                            30.829166666608444
                        ],
                        [
                            -81.34000000029971,
                            30.56444444415581
                        ],
                        [
                            -80.96333333336662,
                            30.581388889185916
                        ],
                        [
                            -80.96083333319172,
                            30.600000000099953
                        ],
                        [
                            -80.94861111131371,
                            30.749999999800195
                        ],
                        [
                            -80.95749999962527,
                            30.86666666653349
                        ],
                        [
                            -80.95861111111384,
                            30.883333333466624
                        ],
                        [
                            -80.97666666673325,
                            31.116666666933156
                        ],
                        [
                            -80.98333333296688,
                            31.199999999800184
                        ],
                        [
                            -80.79666666673324,
                            31.459722221903064
                        ],
                        [
                            -80.68333333356645,
                            31.616666666833282
                        ],
                        [
                            -80.64833333291693,
                            31.68361111086415
                        ],
                        [
                            -80.56833333361641,
                            31.83750000032478
                        ],
                        [
                            -80.48333333306681,
                            32.00000000000006
                        ],
                        [
                            -80.23222222207784,
                            32.40250000027487
                        ],
                        [
                            -80.86666666713296,
                            32.783333333266796
                        ],
                        [
                            -81.41666666693311,
                            32.38333333316683
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 523,
                "artcc": "zjx",
                "sector": "68",
                "sectype": "high",
                "Shape_Length": 5.7667588329382,
                "Shape_Area": 1.6539732639770182
            }
        },
        {
            "type": "Feature",
            "id": 528,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -81.55833333361642,
                            30.829166666608444
                        ],
                        [
                            -81.94166666678319,
                            30.47083333339168
                        ],
                        [
                            -81.98749999972517,
                            30.36666666663342
                        ],
                        [
                            -82.05416666655839,
                            30.205555555644423
                        ],
                        [
                            -81.96666666673326,
                            29.93055555529469
                        ],
                        [
                            -81.86722222222772,
                            29.605833333141845
                        ],
                        [
                            -81.84166666698303,
                            29.522222222177845
                        ],
                        [
                            -81.80694444443054,
                            29.38361111096407
                        ],
                        [
                            -81.78944444410575,
                            29.3161111107392
                        ],
                        [
                            -81.78416666655835,
                            29.295277777747287
                        ],
                        [
                            -81.70833333331666,
                            28.999999999700265
                        ],
                        [
                            -81.73416666665833,
                            28.864166666758308
                        ],
                        [
                            -81.5947222220529,
                            28.5561111115386
                        ],
                        [
                            -81.49999999980014,
                            28.3463888893358
                        ],
                        [
                            -80.98611111123876,
                            28.727777777622407
                        ],
                        [
                            -81.10555555534455,
                            28.999999999700265
                        ],
                        [
                            -81.26777777782218,
                            29.366666666833282
                        ],
                        [
                            -81.29611111133869,
                            29.839444444705407
                        ],
                        [
                            -81.303333332867,
                            29.959722222202856
                        ],
                        [
                            -81.32500000014983,
                            30.31666666673334
                        ],
                        [
                            -81.34000000029971,
                            30.56444444415581
                        ],
                        [
                            -81.55833333361642,
                            30.829166666608444
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 528,
                "artcc": "zjx",
                "sector": "75",
                "sectype": "high",
                "Shape_Length": 5.655306870680591,
                "Shape_Area": 1.3466836031262013
            }
        },
        {
            "type": "Feature",
            "id": 531,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -81.8666666669331,
                            29.358333332917027
                        ],
                        [
                            -81.84166666698303,
                            29.522222222177845
                        ],
                        [
                            -81.86722222222772,
                            29.605833333141845
                        ],
                        [
                            -81.96666666673326,
                            29.93055555529469
                        ],
                        [
                            -82.05416666655839,
                            30.205555555644423
                        ],
                        [
                            -82.10000000039969,
                            30.35472222195301
                        ],
                        [
                            -82.41944444500507,
                            30.577777777522442
                        ],
                        [
                            -82.65000000019984,
                            30.749999999800195
                        ],
                        [
                            -82.78333333386621,
                            30.933333333366704
                        ],
                        [
                            -83.03416666675821,
                            30.46333333286708
                        ],
                        [
                            -83.1583333340161,
                            30.411111110889124
                        ],
                        [
                            -83.22083333389122,
                            30.404166666558467
                        ],
                        [
                            -82.9000000005995,
                            29.66666666623371
                        ],
                        [
                            -82.86666666673324,
                            29.549999999500415
                        ],
                        [
                            -82.5000000004996,
                            29.283333333066935
                        ],
                        [
                            -81.8666666669331,
                            29.358333332917027
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 531,
                "artcc": "zjx",
                "sector": "78",
                "sectype": "high",
                "Shape_Length": 4.6886106695421255,
                "Shape_Area": 1.3134724156513409
            }
        },
        {
            "type": "Feature",
            "id": 536,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -82.89999999970019,
                            29.466666666633387
                        ],
                        [
                            -83.75666666693309,
                            29.319722222402675
                        ],
                        [
                            -83.71305555516972,
                            29.250000000099988
                        ],
                        [
                            -83.51666666633355,
                            28.93333333286705
                        ],
                        [
                            -83.51666666633355,
                            28.400000000000034
                        ],
                        [
                            -83.20833333301687,
                            28.158333333516566
                        ],
                        [
                            -82.94166666658333,
                            27.950000000000045
                        ],
                        [
                            -82.65749999982512,
                            27.950000000000045
                        ],
                        [
                            -82.66083333339157,
                            28.396111111138907
                        ],
                        [
                            -82.716666667033,
                            28.741666666283663
                        ],
                        [
                            -82.87916666670827,
                            29.204166666258686
                        ],
                        [
                            -82.89999999970019,
                            29.466666666633387
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 536,
                "artcc": "zjx",
                "sector": "88",
                "sectype": "high",
                "Shape_Length": 4.421394393761068,
                "Shape_Area": 1.0550252694971505
            }
        },
        {
            "type": "Feature",
            "id": 556,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -97.44166666638353,
                            38.5249999995504
                        ],
                        [
                            -98.04999999999995,
                            37.85416666695818
                        ],
                        [
                            -97.73388888896102,
                            37.50388888866132
                        ],
                        [
                            -97.73722222252752,
                            37.13388888886118
                        ],
                        [
                            -94.88333333306684,
                            37.40833333301697
                        ],
                        [
                            -94.52916666680818,
                            38.0583333335166
                        ],
                        [
                            -94.58611111103892,
                            38.23277777787223
                        ],
                        [
                            -94.7333333333666,
                            38.84444444415584
                        ],
                        [
                            -96.38749999972515,
                            38.73333333306692
                        ],
                        [
                            -97.44166666638353,
                            38.5249999995504
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 556,
                "artcc": "zkc",
                "sector": "02",
                "sectype": "high",
                "Shape_Length": 8.899796826338157,
                "Shape_Area": 4.444919058212148
            }
        },
        {
            "type": "Feature",
            "id": 558,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -98.80000000029975,
                            35.81666666653348
                        ],
                        [
                            -98.49999999999994,
                            35.81666666653348
                        ],
                        [
                            -98.466666667033,
                            35.81666666653348
                        ],
                        [
                            -98.14833333391618,
                            35.86249999947546
                        ],
                        [
                            -98.01666666703301,
                            35.88333333336669
                        ],
                        [
                            -97.88333333336658,
                            35.92499999935052
                        ],
                        [
                            -97.74722222232765,
                            35.974444443955974
                        ],
                        [
                            -97.7386111112138,
                            36.977777777322615
                        ],
                        [
                            -97.73722222252752,
                            37.13388888886118
                        ],
                        [
                            -97.73388888896102,
                            37.50388888866132
                        ],
                        [
                            -98.04999999999995,
                            37.85416666605886
                        ],
                        [
                            -99.43333333386624,
                            37.93888888851143
                        ],
                        [
                            -99.95833333371633,
                            37.52916666670836
                        ],
                        [
                            -99.92499999985006,
                            36.7499999995004
                        ],
                        [
                            -99.77083333319172,
                            36.54166666598388
                        ],
                        [
                            -99.22777777772222,
                            35.81666666653348
                        ],
                        [
                            -98.80000000029975,
                            35.81666666653348
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 558,
                "artcc": "zkc",
                "sector": "06",
                "sectype": "high",
                "Shape_Length": 7.498633228394262,
                "Shape_Area": 4.04679868794394
            }
        },
        {
            "type": "Feature",
            "id": 560,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -89.75000000039967,
                            40.00000000019992
                        ],
                        [
                            -90.13333333356644,
                            39.4166666665335
                        ],
                        [
                            -89.7625000003747,
                            38.9041666666584
                        ],
                        [
                            -89.78333333336661,
                            38.71944444440561
                        ],
                        [
                            -89.49722222262744,
                            38.6972222218281
                        ],
                        [
                            -89.10833333291697,
                            38.736111111338744
                        ],
                        [
                            -88.1027777774724,
                            38.70361111086413
                        ],
                        [
                            -88.10666666633352,
                            38.80805555571936
                        ],
                        [
                            -88.110277777997,
                            38.899166666308645
                        ],
                        [
                            -88.12916666700806,
                            39.386111110939055
                        ],
                        [
                            -88.14805555601907,
                            39.86833333331674
                        ],
                        [
                            -88.18333333296692,
                            39.904166666458536
                        ],
                        [
                            -88.24999999980014,
                            40.00000000019992
                        ],
                        [
                            -88.9500000001998,
                            40.00000000019992
                        ],
                        [
                            -89.18472222235266,
                            40.00000000019992
                        ],
                        [
                            -89.75000000039967,
                            40.00000000019992
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 560,
                "artcc": "zkc",
                "sector": "12",
                "sectype": "high",
                "Shape_Length": 6.0330089608585045,
                "Shape_Area": 2.2912494989924066
            }
        },
        {
            "type": "Feature",
            "id": 561,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -90.50833333371634,
                            38.5249999995504
                        ],
                        [
                            -90.72805555571927,
                            38.01222222247765
                        ],
                        [
                            -90.88277777767229,
                            37.10333333326679
                        ],
                        [
                            -90.5666666666333,
                            37.14999999960037
                        ],
                        [
                            -90.41694444413076,
                            37.18416666685823
                        ],
                        [
                            -90.38194444438057,
                            37.19222222177814
                        ],
                        [
                            -90.33555555524464,
                            37.20305555586924
                        ],
                        [
                            -90.03444444435559,
                            37.27083333329176
                        ],
                        [
                            -89.32638888903597,
                            37.42722222202798
                        ],
                        [
                            -89.18333333366638,
                            37.45833333291705
                        ],
                        [
                            -89.04999999999995,
                            37.48333333286706
                        ],
                        [
                            -88.83333333346656,
                            37.53333333366646
                        ],
                        [
                            -88.3166666666333,
                            37.72500000024985
                        ],
                        [
                            -88.27000000029972,
                            37.96277777787219
                        ],
                        [
                            -88.233333332867,
                            38.150000000299826
                        ],
                        [
                            -88.09444444445552,
                            38.485277777547424
                        ],
                        [
                            -88.1008333334915,
                            38.651388888886174
                        ],
                        [
                            -88.1027777774724,
                            38.70361111086413
                        ],
                        [
                            -89.10833333291697,
                            38.736111111338744
                        ],
                        [
                            -89.49722222262744,
                            38.6972222218281
                        ],
                        [
                            -89.78333333336661,
                            38.71944444440561
                        ],
                        [
                            -90.16611111123876,
                            38.569444444705425
                        ],
                        [
                            -90.50833333371634,
                            38.5249999995504
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 561,
                "artcc": "zkc",
                "sector": "14",
                "sectype": "high",
                "Shape_Length": 7.58033902181467,
                "Shape_Area": 3.201318017203176
            }
        },
        {
            "type": "Feature",
            "id": 562,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -97.44166666638353,
                            38.5249999995504
                        ],
                        [
                            -98.66666666663332,
                            38.87499999975023
                        ],
                        [
                            -99.63055555519469,
                            39.05833333331674
                        ],
                        [
                            -100.23388888846142,
                            38.74416666625871
                        ],
                        [
                            -100.35284444401583,
                            38.68238888853642
                        ],
                        [
                            -101.15833333311679,
                            38.258333333116866
                        ],
                        [
                            -100.6333333332667,
                            38.30000000000007
                        ],
                        [
                            -99.43333333296692,
                            37.93888888851143
                        ],
                        [
                            -98.04999999999995,
                            37.85416666695818
                        ],
                        [
                            -97.44166666638353,
                            38.5249999995504
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 562,
                "artcc": "zkc",
                "sector": "20",
                "sectype": "high",
                "Shape_Length": 8.05107161236775,
                "Shape_Area": 2.5346433078852013
            }
        },
        {
            "type": "Feature",
            "id": 564,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -101.15833333311679,
                            38.258333333116866
                        ],
                        [
                            -101.23333333296688,
                            38.218611111113944
                        ],
                        [
                            -102.54999999999995,
                            37.499999999800195
                        ],
                        [
                            -102.183333332867,
                            37.04166666678327
                        ],
                        [
                            -99.95833333371633,
                            37.52916666670836
                        ],
                        [
                            -99.43333333296692,
                            37.93888888851143
                        ],
                        [
                            -100.6333333332667,
                            38.30000000000007
                        ],
                        [
                            -101.15833333311679,
                            38.258333333116866
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 564,
                "artcc": "zkc",
                "sector": "22",
                "sectype": "high",
                "Shape_Length": 6.895370212786271,
                "Shape_Area": 2.119478008604678
            }
        },
        {
            "type": "Feature",
            "id": 565,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -99.77083333319172,
                            36.54166666598388
                        ],
                        [
                            -99.92499999985006,
                            36.7499999995004
                        ],
                        [
                            -99.95833333371633,
                            37.52916666670836
                        ],
                        [
                            -102.18333333376631,
                            37.04166666678327
                        ],
                        [
                            -101.75000000069946,
                            36.50000000000006
                        ],
                        [
                            -101.47222222207785,
                            36.397222221928075
                        ],
                        [
                            -100.00000000059953,
                            35.82916666650851
                        ],
                        [
                            -99.38361111116382,
                            35.83333333256729
                        ],
                        [
                            -99.22777777772222,
                            35.81666666653348
                        ],
                        [
                            -99.77083333319172,
                            36.54166666598388
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 565,
                "artcc": "zkc",
                "sector": "23",
                "sectype": "high",
                "Shape_Length": 7.563656700751125,
                "Shape_Area": 2.600898728084104
            }
        },
        {
            "type": "Feature",
            "id": 566,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -99.63055555519469,
                            39.05833333331674
                        ],
                        [
                            -98.66666666663332,
                            38.87499999975023
                        ],
                        [
                            -97.44166666638353,
                            38.5249999995504
                        ],
                        [
                            -96.38749999972515,
                            38.73333333306692
                        ],
                        [
                            -96.08333333336662,
                            40.0500000001
                        ],
                        [
                            -96.96666666643347,
                            39.86666666653349
                        ],
                        [
                            -97.88333333336658,
                            39.66666666693317
                        ],
                        [
                            -98.80000000029975,
                            39.46666666643358
                        ],
                        [
                            -99.21666666643347,
                            39.26666666683326
                        ],
                        [
                            -99.63055555519469,
                            39.05833333331674
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 566,
                "artcc": "zkc",
                "sector": "24",
                "sectype": "high",
                "Shape_Length": 8.385253138788695,
                "Shape_Area": 2.960202547400074
            }
        },
        {
            "type": "Feature",
            "id": 567,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -93.90833333321672,
                            40.458333333216785
                        ],
                        [
                            -94.07249999967519,
                            40.427777777622396
                        ],
                        [
                            -94.49999999990001,
                            40.35055555569437
                        ],
                        [
                            -95.54999999960029,
                            40.1499999999001
                        ],
                        [
                            -95.91666666673325,
                            40.07916666700811
                        ],
                        [
                            -96.08333333336662,
                            40.0500000001
                        ],
                        [
                            -96.38749999972515,
                            38.73333333306692
                        ],
                        [
                            -94.7333333333666,
                            38.84444444415584
                        ],
                        [
                            -93.89166666628358,
                            39.31111111108896
                        ],
                        [
                            -93.90833333321672,
                            40.458333333216785
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 567,
                "artcc": "zkc",
                "sector": "26",
                "sectype": "high",
                "Shape_Length": 7.331995402361128,
                "Shape_Area": 3.1473004637410122
            }
        },
        {
            "type": "Feature",
            "id": 568,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -94.68333333346652,
                            36.43333333316684
                        ],
                        [
                            -94.4083333340161,
                            36.4916666660838
                        ],
                        [
                            -94.95638888893603,
                            37.274166665958944
                        ],
                        [
                            -94.88333333396616,
                            37.40833333301697
                        ],
                        [
                            -97.73722222252752,
                            37.13388888886118
                        ],
                        [
                            -97.7386111112138,
                            36.977777777322615
                        ],
                        [
                            -97.74722222232765,
                            35.974444443955974
                        ],
                        [
                            -97.88333333336658,
                            35.92499999935052
                        ],
                        [
                            -97.58333333396615,
                            35.49166666628366
                        ],
                        [
                            -97.18805555611897,
                            35.46638888823668
                        ],
                        [
                            -97.08333333316676,
                            35.458333333316716
                        ],
                        [
                            -96.83916666740777,
                            35.52777777752249
                        ],
                        [
                            -96.40416666665828,
                            35.6499999999001
                        ],
                        [
                            -96.23333333396613,
                            35.749999999700265
                        ],
                        [
                            -96.05000000039968,
                            35.87083333339166
                        ],
                        [
                            -95.98333333356646,
                            35.88333333336669
                        ],
                        [
                            -95.84166666688316,
                            35.94166666628365
                        ],
                        [
                            -95.61250000037467,
                            36.0166666661338
                        ],
                        [
                            -95.3666666669331,
                            35.87083333339166
                        ],
                        [
                            -94.68333333346652,
                            36.43333333316684
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 568,
                "artcc": "zkc",
                "sector": "27",
                "sectype": "high",
                "Shape_Length": 9.345026569371333,
                "Shape_Area": 4.55866670505527
            }
        },
        {
            "type": "Feature",
            "id": 569,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -94.52916666680818,
                            38.0583333335166
                        ],
                        [
                            -94.88333333306684,
                            37.40833333301697
                        ],
                        [
                            -94.95638888893603,
                            37.274166666858264
                        ],
                        [
                            -94.40833333311679,
                            36.49166666698312
                        ],
                        [
                            -93.7869444442307,
                            36.6183333335166
                        ],
                        [
                            -93.74999999960028,
                            36.62499999975023
                        ],
                        [
                            -92.46666666643347,
                            37.891666666883225
                        ],
                        [
                            -91.88333333366637,
                            38.24166666708305
                        ],
                        [
                            -94.52916666680818,
                            38.0583333335166
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 569,
                "artcc": "zkc",
                "sector": "28",
                "sectype": "high",
                "Shape_Length": 7.655655794838261,
                "Shape_Area": 2.728274306198733
            }
        },
        {
            "type": "Feature",
            "id": 570,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -91.88333333366637,
                            38.24166666708305
                        ],
                        [
                            -92.46666666643347,
                            37.891666666883225
                        ],
                        [
                            -93.74999999960028,
                            36.62499999975023
                        ],
                        [
                            -93.53333333306682,
                            36.66666666663343
                        ],
                        [
                            -93.24999999970021,
                            36.72499999955039
                        ],
                        [
                            -93.13749999992501,
                            36.74416666665837
                        ],
                        [
                            -92.86583333314178,
                            36.789999999600354
                        ],
                        [
                            -92.64388888906092,
                            36.82666666703312
                        ],
                        [
                            -92.51488055519343,
                            36.84787777823692
                        ],
                        [
                            -90.88277777767229,
                            37.10333333326679
                        ],
                        [
                            -90.72805555571927,
                            38.01222222247765
                        ],
                        [
                            -90.50833333371634,
                            38.5249999995504
                        ],
                        [
                            -91.29999999999995,
                            38.5249999995504
                        ],
                        [
                            -91.88333333366637,
                            38.24166666708305
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 570,
                "artcc": "zkc",
                "sector": "29",
                "sectype": "high",
                "Shape_Length": 8.310648362363056,
                "Shape_Area": 2.962342008473302
            }
        },
        {
            "type": "Feature",
            "id": 571,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -93.89166666628358,
                            39.31111111108896
                        ],
                        [
                            -94.7333333333666,
                            38.84444444415584
                        ],
                        [
                            -94.58611111103892,
                            38.23277777787223
                        ],
                        [
                            -94.52916666680818,
                            38.0583333335166
                        ],
                        [
                            -91.88333333366637,
                            38.24166666708305
                        ],
                        [
                            -91.29999999999995,
                            38.5249999995504
                        ],
                        [
                            -91.3333333329669,
                            39.03333333336667
                        ],
                        [
                            -92.24999999990001,
                            39.29999999980021
                        ],
                        [
                            -93.89166666628358,
                            39.31111111108896
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 571,
                "artcc": "zkc",
                "sector": "30",
                "sectype": "high",
                "Shape_Length": 8.18149717196438,
                "Shape_Area": 3.437763116090412
            }
        },
        {
            "type": "Feature",
            "id": 573,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -93.90833333321672,
                            40.458333333216785
                        ],
                        [
                            -93.89166666628358,
                            39.31111111108896
                        ],
                        [
                            -92.24999999990001,
                            39.29999999980021
                        ],
                        [
                            -92.19999999999999,
                            39.6333333330669
                        ],
                        [
                            -90.72416666685814,
                            40.40777777802208
                        ],
                        [
                            -90.92777777812194,
                            40.48888888881123
                        ],
                        [
                            -91.13805555561936,
                            40.57666666673333
                        ],
                        [
                            -91.39999999980012,
                            40.683333333666496
                        ],
                        [
                            -91.65000000019984,
                            40.7833333334666
                        ],
                        [
                            -91.74166666698306,
                            40.76388888916097
                        ],
                        [
                            -92.71666666683319,
                            40.63472222245264
                        ],
                        [
                            -93.491666667083,
                            40.52500000005
                        ],
                        [
                            -93.90833333321672,
                            40.458333333216785
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 573,
                "artcc": "zkc",
                "sector": "32",
                "sectype": "high",
                "Shape_Length": 8.073841211744483,
                "Shape_Area": 3.096963734963059
            }
        },
        {
            "type": "Feature",
            "id": 575,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -92.64999999999998,
                            39.258333332917005
                        ],
                        [
                            -91.89277777817188,
                            39.563611110764214
                        ],
                        [
                            -92.13333333316677,
                            39.61666666613377
                        ],
                        [
                            -92.71666666683319,
                            40.63472222155332
                        ],
                        [
                            -93.491666667083,
                            40.52500000005
                        ],
                        [
                            -93.90833333321672,
                            40.458333333216785
                        ],
                        [
                            -94.07250000057451,
                            40.427777777622396
                        ],
                        [
                            -94.39916666670825,
                            39.802499999875124
                        ],
                        [
                            -94.70305555586918,
                            39.21111111038948
                        ],
                        [
                            -93.99166666698306,
                            39.16666666613378
                        ],
                        [
                            -92.69999999990006,
                            39.07499999935055
                        ],
                        [
                            -92.64999999999998,
                            39.258333332917005
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 575,
                "artcc": "zkc",
                "sector": "40",
                "sectype": "high",
                "Shape_Length": 7.175881368559684,
                "Shape_Area": 2.789748843524793
            }
        },
        {
            "type": "Feature",
            "id": 578,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -93.03333333316675,
                            37.97499999975025
                        ],
                        [
                            -92.95722222272735,
                            38.09388888856142
                        ],
                        [
                            -92.80277777797204,
                            38.333333332967015
                        ],
                        [
                            -92.80000000059954,
                            38.69166666618378
                        ],
                        [
                            -92.69999999990006,
                            39.07499999935055
                        ],
                        [
                            -93.99166666698306,
                            39.16666666613378
                        ],
                        [
                            -94.70305555586918,
                            39.21111111038948
                        ],
                        [
                            -94.72611111093897,
                            38.685277777147746
                        ],
                        [
                            -94.76111111158849,
                            38.534166665958935
                        ],
                        [
                            -94.94999999990006,
                            37.708333333316716
                        ],
                        [
                            -94.70277777777221,
                            37.70277777767234
                        ],
                        [
                            -94.61666666663331,
                            37.69166666638358
                        ],
                        [
                            -94.31666666723288,
                            37.683333333366704
                        ],
                        [
                            -94.00527777844667,
                            37.6313888885864
                        ],
                        [
                            -93.33333333346656,
                            37.51666666673333
                        ],
                        [
                            -93.03333333316675,
                            37.97499999975025
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 578,
                "artcc": "zkc",
                "sector": "42",
                "sectype": "high",
                "Shape_Length": 6.896275209509343,
                "Shape_Area": 2.8960293585396855
            }
        },
        {
            "type": "Feature",
            "id": 581,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -95.67500000024978,
                            38.97222222217789
                        ],
                        [
                            -95.42499999985006,
                            39.041666666383605
                        ],
                        [
                            -95.19166666728285,
                            39.10277777757244
                        ],
                        [
                            -94.70305555586918,
                            39.21111111038948
                        ],
                        [
                            -94.39916666670825,
                            39.802499999875124
                        ],
                        [
                            -94.07250000057451,
                            40.427777777622396
                        ],
                        [
                            -94.49999999990001,
                            40.35055555569437
                        ],
                        [
                            -95.55000000049961,
                            40.1499999999001
                        ],
                        [
                            -95.91666666673325,
                            40.07916666610879
                        ],
                        [
                            -96.08333333336662,
                            40.0500000001
                        ],
                        [
                            -96.96666666733279,
                            39.86666666653349
                        ],
                        [
                            -96.6258333335415,
                            39.59361111106398
                        ],
                        [
                            -96.34583333374133,
                            39.36666666663342
                        ],
                        [
                            -96.23333333396613,
                            38.84999999980022
                        ],
                        [
                            -96.20000000009986,
                            38.63333333326676
                        ],
                        [
                            -95.67500000024978,
                            38.97222222217789
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 581,
                "artcc": "zkc",
                "sector": "46",
                "sectype": "high",
                "Shape_Length": 7.489723911193491,
                "Shape_Area": 2.4424059806257294
            }
        },
        {
            "type": "Feature",
            "id": 584,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -94.76111111158849,
                            38.534166665958935
                        ],
                        [
                            -94.72611111093897,
                            38.685277777147746
                        ],
                        [
                            -94.70305555586918,
                            39.21111111038948
                        ],
                        [
                            -95.19166666728285,
                            39.10277777757244
                        ],
                        [
                            -95.42499999985006,
                            39.041666666383605
                        ],
                        [
                            -95.67500000024978,
                            38.97222222217789
                        ],
                        [
                            -96.20000000009986,
                            38.63333333326676
                        ],
                        [
                            -96.33333333376629,
                            38.333333332967015
                        ],
                        [
                            -96.2583333339162,
                            38.2666666661338
                        ],
                        [
                            -95.93333333366638,
                            37.887499999925126
                        ],
                        [
                            -95.47777777802202,
                            37.80833333311688
                        ],
                        [
                            -94.94999999990006,
                            37.708333333316716
                        ],
                        [
                            -94.76111111158849,
                            38.534166665958935
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 584,
                "artcc": "zkc",
                "sector": "48",
                "sectype": "high",
                "Shape_Length": 5.082217143457601,
                "Shape_Area": 1.636292399905465
            }
        },
        {
            "type": "Feature",
            "id": 586,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -90.66666666733278,
                            39.3749999996503
                        ],
                        [
                            -90.80833333401614,
                            40.12361111036449
                        ],
                        [
                            -90.89999999990005,
                            40.42083333329174
                        ],
                        [
                            -90.92777777812194,
                            40.48888888881123
                        ],
                        [
                            -91.13805555561936,
                            40.57666666673333
                        ],
                        [
                            -91.40000000069944,
                            40.68333333276712
                        ],
                        [
                            -91.65000000019984,
                            40.78333333256728
                        ],
                        [
                            -91.74166666698306,
                            40.76388888826165
                        ],
                        [
                            -92.71666666683319,
                            40.63472222155332
                        ],
                        [
                            -92.13333333316677,
                            39.61666666613377
                        ],
                        [
                            -91.89277777817188,
                            39.563611110764214
                        ],
                        [
                            -91.04527777824683,
                            39.14499999975027
                        ],
                        [
                            -90.92000000039968,
                            39.08222222177818
                        ],
                        [
                            -90.60000000049956,
                            39.073611110664274
                        ],
                        [
                            -90.66666666733278,
                            39.3749999996503
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 586,
                "artcc": "zkc",
                "sector": "50",
                "sectype": "high",
                "Shape_Length": 6.137452397702561,
                "Shape_Area": 2.1420978001252124
            }
        },
        {
            "type": "Feature",
            "id": 588,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -92.71555555624388,
                            37.79833333331675
                        ],
                        [
                            -92.61138888948562,
                            37.890000000099974
                        ],
                        [
                            -92.24999999990001,
                            38.05833333261728
                        ],
                        [
                            -91.88333333366637,
                            38.24166666618373
                        ],
                        [
                            -91.48333333356646,
                            38.36666666683328
                        ],
                        [
                            -91.16111111158847,
                            38.499999999600334
                        ],
                        [
                            -91.05416666655839,
                            38.57499999945048
                        ],
                        [
                            -90.96666666673326,
                            38.6416666662837
                        ],
                        [
                            -90.3500000000999,
                            38.655555554944954
                        ],
                        [
                            -90.37638888873619,
                            38.78249999957535
                        ],
                        [
                            -90.39999999999998,
                            38.8999999997003
                        ],
                        [
                            -90.60000000049956,
                            39.073611110664274
                        ],
                        [
                            -90.92000000039968,
                            39.08222222177818
                        ],
                        [
                            -91.04527777824683,
                            39.14499999975027
                        ],
                        [
                            -91.89277777817188,
                            39.563611110764214
                        ],
                        [
                            -92.64999999999998,
                            39.258333332917005
                        ],
                        [
                            -92.69999999990006,
                            39.07499999935055
                        ],
                        [
                            -92.80000000059954,
                            38.69166666618378
                        ],
                        [
                            -92.80277777797204,
                            38.333333332967015
                        ],
                        [
                            -92.95722222272735,
                            38.09388888856142
                        ],
                        [
                            -93.03333333316675,
                            37.97499999975025
                        ],
                        [
                            -93.33333333346656,
                            37.51666666673333
                        ],
                        [
                            -93.14999999990005,
                            37.41666666603385
                        ],
                        [
                            -92.71555555624388,
                            37.79833333331675
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 588,
                "artcc": "zkc",
                "sector": "52",
                "sectype": "high",
                "Shape_Length": 8.014432803092754,
                "Shape_Area": 2.51116956000485
            }
        },
        {
            "type": "Feature",
            "id": 590,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -92.86583333314178,
                            36.789999999600354
                        ],
                        [
                            -92.64388888906092,
                            36.826666666133804
                        ],
                        [
                            -92.51488055609275,
                            36.8478777773376
                        ],
                        [
                            -90.88277777767229,
                            37.10333333326679
                        ],
                        [
                            -90.5666666666333,
                            37.14999999960037
                        ],
                        [
                            -90.41694444503008,
                            37.18416666595891
                        ],
                        [
                            -90.44999999990006,
                            38.0474999994255
                        ],
                        [
                            -90.44999999990006,
                            38.25277777747249
                        ],
                        [
                            -90.34166666708296,
                            38.549999999500415
                        ],
                        [
                            -90.3500000000999,
                            38.655555554944954
                        ],
                        [
                            -90.96666666673326,
                            38.6416666662837
                        ],
                        [
                            -91.05416666655839,
                            38.57499999945048
                        ],
                        [
                            -91.16111111158847,
                            38.499999999600334
                        ],
                        [
                            -91.48333333356646,
                            38.36666666683328
                        ],
                        [
                            -91.88333333366637,
                            38.24166666618373
                        ],
                        [
                            -92.24999999990001,
                            38.05833333261728
                        ],
                        [
                            -92.61138888948562,
                            37.890000000099974
                        ],
                        [
                            -92.71555555624388,
                            37.79833333331675
                        ],
                        [
                            -93.14999999990005,
                            37.41666666603385
                        ],
                        [
                            -93.13749999992501,
                            36.74416666665837
                        ],
                        [
                            -92.86583333314178,
                            36.789999999600354
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 590,
                "artcc": "zkc",
                "sector": "53",
                "sectype": "high",
                "Shape_Length": 8.071274532419006,
                "Shape_Area": 3.4133535445461494
            }
        },
        {
            "type": "Feature",
            "id": 592,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -90.37638888873619,
                            38.78249999957535
                        ],
                        [
                            -90.3500000000999,
                            38.655555554944954
                        ],
                        [
                            -90.34166666708296,
                            38.549999999500415
                        ],
                        [
                            -90.44999999990006,
                            38.25277777747249
                        ],
                        [
                            -90.44999999990006,
                            38.0474999994255
                        ],
                        [
                            -90.41694444503008,
                            37.18416666595891
                        ],
                        [
                            -90.38194444438057,
                            37.19222222177814
                        ],
                        [
                            -90.33555555614396,
                            37.20305555496992
                        ],
                        [
                            -90.03444444435559,
                            37.27083333329176
                        ],
                        [
                            -89.32638888903597,
                            37.42722222202798
                        ],
                        [
                            -89.18333333366638,
                            37.45833333291705
                        ],
                        [
                            -89.04999999999995,
                            37.48333333286706
                        ],
                        [
                            -88.83333333346656,
                            37.53333333276714
                        ],
                        [
                            -88.3166666666333,
                            37.72499999935053
                        ],
                        [
                            -88.27000000029972,
                            37.96277777787219
                        ],
                        [
                            -88.23333333376632,
                            38.14999999940051
                        ],
                        [
                            -88.09444444445552,
                            38.485277777547424
                        ],
                        [
                            -88.1008333334915,
                            38.651388888886174
                        ],
                        [
                            -88.10277777837172,
                            38.70361111086413
                        ],
                        [
                            -89.10833333381629,
                            38.736111110439424
                        ],
                        [
                            -89.49722222262744,
                            38.6972222218281
                        ],
                        [
                            -90.37638888873619,
                            38.78249999957535
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 592,
                "artcc": "zkc",
                "sector": "54",
                "sectype": "high",
                "Shape_Length": 7.088637123191797,
                "Shape_Area": 2.8559770059585907
            }
        },
        {
            "type": "Feature",
            "id": 594,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -88.10666666723284,
                            38.80805555571936
                        ],
                        [
                            -88.110277777997,
                            38.899166666308645
                        ],
                        [
                            -88.12916666700806,
                            39.386111110939055
                        ],
                        [
                            -88.14805555601907,
                            39.86833333331674
                        ],
                        [
                            -88.18333333386624,
                            39.904166666458536
                        ],
                        [
                            -88.25000000069946,
                            39.9999999993006
                        ],
                        [
                            -88.9500000001998,
                            39.9999999993006
                        ],
                        [
                            -88.96666666713293,
                            39.86666666653349
                        ],
                        [
                            -89.15000000069944,
                            39.56666666623369
                        ],
                        [
                            -89.68333333356645,
                            39.19166666608385
                        ],
                        [
                            -89.87472222205287,
                            39.01861111041444
                        ],
                        [
                            -89.94999999999999,
                            39.01666666643354
                        ],
                        [
                            -90.39999999999998,
                            38.8999999997003
                        ],
                        [
                            -90.37638888873619,
                            38.78249999957535
                        ],
                        [
                            -89.49722222262744,
                            38.6972222218281
                        ],
                        [
                            -89.10833333381629,
                            38.736111110439424
                        ],
                        [
                            -88.10277777837172,
                            38.70361111086413
                        ],
                        [
                            -88.10666666723284,
                            38.80805555571936
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 594,
                "artcc": "zkc",
                "sector": "58",
                "sectype": "high",
                "Shape_Length": 6.368814277854024,
                "Shape_Area": 1.7840018130687165
            }
        },
        {
            "type": "Feature",
            "id": 596,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -89.8758333335415,
                            40.05249999937553
                        ],
                        [
                            -90.33333333316676,
                            40.241666666683386
                        ],
                        [
                            -90.5680555562189,
                            40.33888888821167
                        ],
                        [
                            -90.72416666685814,
                            40.40777777712276
                        ],
                        [
                            -90.92777777812194,
                            40.48888888881123
                        ],
                        [
                            -90.89999999990005,
                            40.42083333329174
                        ],
                        [
                            -90.80833333401614,
                            40.12361111036449
                        ],
                        [
                            -90.66666666733278,
                            39.3749999996503
                        ],
                        [
                            -90.60000000049956,
                            39.073611110664274
                        ],
                        [
                            -90.39999999999998,
                            38.8999999997003
                        ],
                        [
                            -89.94999999999999,
                            39.01666666643354
                        ],
                        [
                            -89.87472222205287,
                            39.01861111041444
                        ],
                        [
                            -89.68333333356645,
                            39.19166666608385
                        ],
                        [
                            -89.15000000069944,
                            39.56666666623369
                        ],
                        [
                            -88.96666666713293,
                            39.86666666653349
                        ],
                        [
                            -88.9500000001998,
                            39.9999999993006
                        ],
                        [
                            -89.18472222235266,
                            39.9999999993006
                        ],
                        [
                            -89.75000000039967,
                            39.9999999993006
                        ],
                        [
                            -89.8758333335415,
                            40.05249999937553
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 596,
                "artcc": "zkc",
                "sector": "59",
                "sectype": "high",
                "Shape_Length": 5.731371340059248,
                "Shape_Area": 1.6387067902333983
            }
        },
        {
            "type": "Feature",
            "id": 598,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -96.83916666740777,
                            35.52777777752249
                        ],
                        [
                            -96.40416666665828,
                            35.6499999999001
                        ],
                        [
                            -96.23333333396613,
                            35.749999999700265
                        ],
                        [
                            -96.05000000039968,
                            35.87083333339166
                        ],
                        [
                            -95.98333333356646,
                            35.88333333336669
                        ],
                        [
                            -95.84166666688316,
                            35.94166666628365
                        ],
                        [
                            -95.61250000037467,
                            36.0166666661338
                        ],
                        [
                            -95.4875000006245,
                            36.133333332867096
                        ],
                        [
                            -95.837499999925,
                            36.41666666623371
                        ],
                        [
                            -96.05000000039968,
                            36.6083333328171
                        ],
                        [
                            -96.97416666695807,
                            37.332777777872195
                        ],
                        [
                            -97.30000000059954,
                            37.59999999960036
                        ],
                        [
                            -97.53333333316675,
                            37.56666666663341
                        ],
                        [
                            -97.80000000049961,
                            37.2861111106393
                        ],
                        [
                            -98.00027777819685,
                            37.083333332767154
                        ],
                        [
                            -97.7386111112138,
                            36.977777777322615
                        ],
                        [
                            -97.59999999999997,
                            36.91666666613378
                        ],
                        [
                            -97.38361111156354,
                            36.83333333326675
                        ],
                        [
                            -97.38333333346651,
                            36.633333332767165
                        ],
                        [
                            -97.38361111156354,
                            36.46666666613379
                        ],
                        [
                            -97.5836111111638,
                            36.033333333066935
                        ],
                        [
                            -97.74722222232765,
                            35.974444443955974
                        ],
                        [
                            -97.88333333336658,
                            35.92499999935052
                        ],
                        [
                            -97.58333333396615,
                            35.49166666628366
                        ],
                        [
                            -97.18805555611897,
                            35.46638888823668
                        ],
                        [
                            -97.08333333316676,
                            35.458333333316716
                        ],
                        [
                            -96.83916666740777,
                            35.52777777752249
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 598,
                "artcc": "zkc",
                "sector": "62",
                "sectype": "high",
                "Shape_Length": 7.8525959554269695,
                "Shape_Area": 2.7747824844896822
            }
        },
        {
            "type": "Feature",
            "id": 600,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -97.30000000059954,
                            37.59999999960036
                        ],
                        [
                            -97.12500000004991,
                            37.633333332567304
                        ],
                        [
                            -96.72499999995,
                            37.74166666628366
                        ],
                        [
                            -96.3916666666833,
                            37.833333333066946
                        ],
                        [
                            -95.93333333366638,
                            37.887499999925126
                        ],
                        [
                            -96.2583333339162,
                            38.2666666661338
                        ],
                        [
                            -96.33333333376629,
                            38.333333332967015
                        ],
                        [
                            -96.20000000009986,
                            38.63333333326676
                        ],
                        [
                            -96.23333333396613,
                            38.84999999980022
                        ],
                        [
                            -96.34583333374133,
                            39.36666666663342
                        ],
                        [
                            -96.6258333335415,
                            39.59361111106398
                        ],
                        [
                            -96.96666666733279,
                            39.86666666653349
                        ],
                        [
                            -97.88333333336658,
                            39.66666666603385
                        ],
                        [
                            -98.80000000029975,
                            39.46666666643358
                        ],
                        [
                            -99.21666666733279,
                            39.26666666683326
                        ],
                        [
                            -98.63333333366637,
                            38.58333333336668
                        ],
                        [
                            -98.16666666673325,
                            38.2666666661338
                        ],
                        [
                            -97.8777777777222,
                            38.081944443881014
                        ],
                        [
                            -97.70000000069945,
                            37.96666666673332
                        ],
                        [
                            -97.60691944497637,
                            37.7438138885002
                        ],
                        [
                            -97.53333333316675,
                            37.56666666663341
                        ],
                        [
                            -97.30000000059954,
                            37.59999999960036
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 600,
                "artcc": "zkc",
                "sector": "66",
                "sectype": "high",
                "Shape_Length": 8.897833003489058,
                "Shape_Area": 4.432011087859267
            }
        },
        {
            "type": "Feature",
            "id": 602,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -98.43103888923332,
                            37.68931944393722
                        ],
                        [
                            -97.60691944497637,
                            37.7438138885002
                        ],
                        [
                            -97.70000000069945,
                            37.96666666673332
                        ],
                        [
                            -97.8777777777222,
                            38.081944443881014
                        ],
                        [
                            -98.16666666673325,
                            38.2666666661338
                        ],
                        [
                            -98.63333333366637,
                            38.58333333336668
                        ],
                        [
                            -99.21666666733279,
                            39.26666666683326
                        ],
                        [
                            -99.63055555609401,
                            39.05833333331674
                        ],
                        [
                            -100.23388888936074,
                            38.74416666625871
                        ],
                        [
                            -100.35284444491515,
                            38.68238888853642
                        ],
                        [
                            -98.75000000039967,
                            37.66666666643357
                        ],
                        [
                            -98.43103888923332,
                            37.68931944393722
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 602,
                "artcc": "zkc",
                "sector": "67",
                "sectype": "high",
                "Shape_Length": 6.579606271014348,
                "Shape_Area": 1.945416367205465
            }
        },
        {
            "type": "Feature",
            "id": 604,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -100.35284444491515,
                            38.68238888853642
                        ],
                        [
                            -101.1583333340161,
                            38.258333333116866
                        ],
                        [
                            -101.23333333386626,
                            38.218611111113944
                        ],
                        [
                            -102.54999999999995,
                            37.499999999800195
                        ],
                        [
                            -102.18333333376631,
                            37.04166666678327
                        ],
                        [
                            -101.75000000069946,
                            36.50000000000006
                        ],
                        [
                            -101.47222222207785,
                            36.397222221928075
                        ],
                        [
                            -100.00000000059953,
                            35.82916666650851
                        ],
                        [
                            -99.38361111116382,
                            35.83333333256729
                        ],
                        [
                            -99.41694444503008,
                            35.88333333336669
                        ],
                        [
                            -99.52277777767227,
                            35.87499999945044
                        ],
                        [
                            -99.75083333359146,
                            36.07972222220286
                        ],
                        [
                            -99.78333333316675,
                            36.2791666665085
                        ],
                        [
                            -99.77083333319172,
                            36.54166666598388
                        ],
                        [
                            -99.70833333331666,
                            36.787499999425506
                        ],
                        [
                            -99.60000000049956,
                            37.01666666683326
                        ],
                        [
                            -99.37944444510504,
                            37.28055555499492
                        ],
                        [
                            -99.30000000019982,
                            37.29999999930055
                        ],
                        [
                            -98.95027777809696,
                            37.36666666613377
                        ],
                        [
                            -98.28944444440555,
                            37.111388888186696
                        ],
                        [
                            -98.20027777779717,
                            37.0763888884365
                        ],
                        [
                            -98.00027777819685,
                            37.083333332767154
                        ],
                        [
                            -97.80000000049961,
                            37.2861111106393
                        ],
                        [
                            -97.53333333316675,
                            37.56666666663341
                        ],
                        [
                            -97.60691944497637,
                            37.7438138885002
                        ],
                        [
                            -98.43103888923332,
                            37.68931944393722
                        ],
                        [
                            -98.75000000039967,
                            37.66666666643357
                        ],
                        [
                            -100.35284444491515,
                            38.68238888853642
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 604,
                "artcc": "zkc",
                "sector": "68",
                "sectype": "high",
                "Shape_Length": 13.404347871071847,
                "Shape_Area": 6.442363076949752
            }
        },
        {
            "type": "Feature",
            "id": 606,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -95.39999999990005,
                            36.20416666665841
                        ],
                        [
                            -95.19583333334162,
                            36.28749999952544
                        ],
                        [
                            -95.01666666673327,
                            36.34027777769734
                        ],
                        [
                            -94.71000000019984,
                            36.42583333264224
                        ],
                        [
                            -94.68333333346652,
                            36.43333333316684
                        ],
                        [
                            -94.4083333340161,
                            36.4916666660838
                        ],
                        [
                            -93.7869444442307,
                            36.61833333261728
                        ],
                        [
                            -93.7500000004996,
                            36.62499999975023
                        ],
                        [
                            -93.53333333396614,
                            36.66666666663343
                        ],
                        [
                            -93.25000000059953,
                            36.72499999955039
                        ],
                        [
                            -93.13749999992501,
                            36.74416666665837
                        ],
                        [
                            -93.14999999990005,
                            37.41666666603385
                        ],
                        [
                            -93.33333333346656,
                            37.51666666673333
                        ],
                        [
                            -94.00527777844667,
                            37.6313888885864
                        ],
                        [
                            -94.31666666723288,
                            37.683333333366704
                        ],
                        [
                            -94.61666666663331,
                            37.69166666638358
                        ],
                        [
                            -94.70277777777221,
                            37.70277777767234
                        ],
                        [
                            -94.94999999990006,
                            37.708333333316716
                        ],
                        [
                            -95.47777777802202,
                            37.80833333311688
                        ],
                        [
                            -95.93333333366638,
                            37.887499999925126
                        ],
                        [
                            -96.3916666666833,
                            37.833333333066946
                        ],
                        [
                            -96.72499999995,
                            37.74166666628366
                        ],
                        [
                            -97.12500000004991,
                            37.633333332567304
                        ],
                        [
                            -97.30000000059954,
                            37.59999999960036
                        ],
                        [
                            -96.97416666695807,
                            37.332777777872195
                        ],
                        [
                            -96.05000000039968,
                            36.6083333328171
                        ],
                        [
                            -95.837499999925,
                            36.41666666623371
                        ],
                        [
                            -95.4875000006245,
                            36.133333332867096
                        ],
                        [
                            -95.39999999990005,
                            36.20416666665841
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 606,
                "artcc": "zkc",
                "sector": "72",
                "sectype": "high",
                "Shape_Length": 9.687327287280528,
                "Shape_Area": 4.361686922101481
            }
        },
        {
            "type": "Feature",
            "id": 608,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -90.72416666685814,
                            40.40777777802208
                        ],
                        [
                            -92.19999999999999,
                            39.6333333330669
                        ],
                        [
                            -92.24999999990001,
                            39.29999999980021
                        ],
                        [
                            -91.3333333329669,
                            39.03333333336667
                        ],
                        [
                            -91.29999999999995,
                            38.5249999995504
                        ],
                        [
                            -90.50833333371634,
                            38.5249999995504
                        ],
                        [
                            -90.16611111123876,
                            38.569444444705425
                        ],
                        [
                            -89.78333333336661,
                            38.71944444440561
                        ],
                        [
                            -89.7625000003747,
                            38.9041666666584
                        ],
                        [
                            -90.13333333356644,
                            39.4166666665335
                        ],
                        [
                            -89.75000000039967,
                            40.00000000019992
                        ],
                        [
                            -89.8758333335415,
                            40.05250000027485
                        ],
                        [
                            -90.33333333316676,
                            40.241666666683386
                        ],
                        [
                            -90.56805555531957,
                            40.33888888911099
                        ],
                        [
                            -90.72416666685814,
                            40.40777777802208
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 608,
                "artcc": "zkc",
                "sector": "84",
                "sectype": "high",
                "Shape_Length": 7.588330846365405,
                "Shape_Area": 3.0142349537290487
            }
        },
        {
            "type": "Feature",
            "id": 610,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -93.90833333321672,
                            40.458333333216785
                        ],
                        [
                            -93.89166666628358,
                            39.31111111108896
                        ],
                        [
                            -92.24999999990001,
                            39.29999999980021
                        ],
                        [
                            -92.19999999999999,
                            39.6333333330669
                        ],
                        [
                            -90.72416666685814,
                            40.40777777802208
                        ],
                        [
                            -90.92777777812194,
                            40.48888888881123
                        ],
                        [
                            -91.13805555561936,
                            40.57666666673333
                        ],
                        [
                            -91.39999999980012,
                            40.683333333666496
                        ],
                        [
                            -91.65000000019984,
                            40.7833333334666
                        ],
                        [
                            -91.74166666698306,
                            40.76388888916097
                        ],
                        [
                            -92.71666666683319,
                            40.63472222245264
                        ],
                        [
                            -93.491666667083,
                            40.52500000005
                        ],
                        [
                            -93.90833333321672,
                            40.458333333216785
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 610,
                "artcc": "zkc",
                "sector": "92",
                "sectype": "high",
                "Shape_Length": 8.073841211744483,
                "Shape_Area": 3.096963734963059
            }
        },
        {
            "type": "Feature",
            "id": 643,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -119.33333333366636,
                            34.549999999400484
                        ],
                        [
                            -119.95000000029972,
                            35.05833333321681
                        ],
                        [
                            -120.56249999997499,
                            35.61666666603384
                        ],
                        [
                            -120.85000000029976,
                            35.533333333166865
                        ],
                        [
                            -121.23722222232766,
                            35.37166666598392
                        ],
                        [
                            -121.04318611105015,
                            35.209697222249076
                        ],
                        [
                            -120.91472222225275,
                            35.10194444408086
                        ],
                        [
                            -121.25000000039967,
                            34.94999999950045
                        ],
                        [
                            -121.18333333356645,
                            34.84999999970029
                        ],
                        [
                            -121.04999999990002,
                            34.68333333306691
                        ],
                        [
                            -120.78361111156352,
                            34.322777777772274
                        ],
                        [
                            -120.50000000009987,
                            34.3999999997003
                        ],
                        [
                            -120.40000000029971,
                            34.42916666660841
                        ],
                        [
                            -120.2744444443556,
                            34.20277777747248
                        ],
                        [
                            -120.23607222255498,
                            34.133286110765425
                        ],
                        [
                            -120.21049166705677,
                            34.08707777761737
                        ],
                        [
                            -120.16249999987508,
                            33.999999999600334
                        ],
                        [
                            -119.95000000029972,
                            33.999999999600334
                        ],
                        [
                            -119.25833333381627,
                            33.658333333316705
                        ],
                        [
                            -119.11666666713296,
                            33.47499999975025
                        ],
                        [
                            -118.95000000049959,
                            33.47499999975025
                        ],
                        [
                            -118.78333333386621,
                            33.47499999975025
                        ],
                        [
                            -118.76666666693308,
                            33.658333333316705
                        ],
                        [
                            -118.54115000057203,
                            33.65864444388603
                        ],
                        [
                            -118.56666666733281,
                            33.75583333294202
                        ],
                        [
                            -118.61250000027474,
                            33.887499999825195
                        ],
                        [
                            -118.6333333332667,
                            33.9833333326672
                        ],
                        [
                            -118.70722222252749,
                            33.9763888883366
                        ],
                        [
                            -119.33333333366636,
                            34.549999999400484
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 643,
                "artcc": "zla",
                "sector": "25",
                "sectype": "high",
                "Shape_Length": 8.025865336766493,
                "Shape_Area": 2.369327952375813
            }
        },
        {
            "type": "Feature",
            "id": 644,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -118.6333333332667,
                            33.9833333326672
                        ],
                        [
                            -118.69181944481147,
                            34.24654166606507
                        ],
                        [
                            -118.70000000009992,
                            34.283333332967004
                        ],
                        [
                            -118.78333333386621,
                            34.433333332667246
                        ],
                        [
                            -119.21222222277731,
                            34.962777777572455
                        ],
                        [
                            -119.49027777769726,
                            35.64527777764738
                        ],
                        [
                            -119.53249999987509,
                            35.99722222182811
                        ],
                        [
                            -119.72138888908592,
                            35.917777777822266
                        ],
                        [
                            -119.76694444483024,
                            35.898333332617256
                        ],
                        [
                            -119.9811111111888,
                            35.8077777773226
                        ],
                        [
                            -120.1166666669331,
                            35.749999999700265
                        ],
                        [
                            -120.23194444498012,
                            35.71666666673332
                        ],
                        [
                            -120.47500000014986,
                            35.641666665983905
                        ],
                        [
                            -120.56249999997499,
                            35.61666666603384
                        ],
                        [
                            -119.95000000029972,
                            35.05833333321681
                        ],
                        [
                            -119.33333333366636,
                            34.549999999400484
                        ],
                        [
                            -118.70722222252749,
                            33.9763888883366
                        ],
                        [
                            -118.6333333332667,
                            33.9833333326672
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 644,
                "artcc": "zla",
                "sector": "26",
                "sectype": "high",
                "Shape_Length": 5.902727323463219,
                "Shape_Area": 1.1443771220604624
            }
        },
        {
            "type": "Feature",
            "id": 645,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -118.09583333344153,
                            34.79999999980021
                        ],
                        [
                            -118.23333333316674,
                            34.84999999970029
                        ],
                        [
                            -118.29166666698308,
                            34.89166666658343
                        ],
                        [
                            -118.34999999990004,
                            34.933333332567315
                        ],
                        [
                            -118.46666666663333,
                            35.091666666183755
                        ],
                        [
                            -118.58333333336662,
                            35.249999999800195
                        ],
                        [
                            -118.58333333336662,
                            35.66666666683324
                        ],
                        [
                            -118.58333333336662,
                            36.00000000009999
                        ],
                        [
                            -118.58333333336662,
                            36.133333332867096
                        ],
                        [
                            -119.00000000039967,
                            36.133333332867096
                        ],
                        [
                            -119.03888888901099,
                            36.133333332867096
                        ],
                        [
                            -119.16666666703304,
                            36.133333332867096
                        ],
                        [
                            -119.22611111143863,
                            36.11194444368118
                        ],
                        [
                            -119.47333333356647,
                            36.02194444368121
                        ],
                        [
                            -119.53249999987509,
                            35.99722222182811
                        ],
                        [
                            -119.72138888908592,
                            35.917777777822266
                        ],
                        [
                            -119.76694444483024,
                            35.898333332617256
                        ],
                        [
                            -119.9811111111888,
                            35.8077777773226
                        ],
                        [
                            -119.61444444495515,
                            35.50247222211539
                        ],
                        [
                            -119.21222222277731,
                            34.962777777572455
                        ],
                        [
                            -118.78333333386621,
                            34.433333332667246
                        ],
                        [
                            -118.70000000009992,
                            34.283333332967004
                        ],
                        [
                            -118.69181944481147,
                            34.24654166606507
                        ],
                        [
                            -118.57725833380505,
                            34.19763055540585
                        ],
                        [
                            -118.14407222245507,
                            34.21999444368868
                        ],
                        [
                            -117.96416666655836,
                            34.56930555510735
                        ],
                        [
                            -117.96666666673326,
                            34.76666666683326
                        ],
                        [
                            -118.00000000059953,
                            34.76666666683326
                        ],
                        [
                            -118.09583333344153,
                            34.79999999980021
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 645,
                "artcc": "zla",
                "sector": "27",
                "sectype": "high",
                "Shape_Length": 6.351355437675373,
                "Shape_Area": 1.8075543222029724
            }
        },
        {
            "type": "Feature",
            "id": 647,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -118.69181944481147,
                            34.24654166606507
                        ],
                        [
                            -118.6333333332667,
                            33.9833333326672
                        ],
                        [
                            -118.61250000027474,
                            33.887499999825195
                        ],
                        [
                            -118.56666666733281,
                            33.75583333294202
                        ],
                        [
                            -118.83333333376629,
                            33.75583333294202
                        ],
                        [
                            -118.76666666693308,
                            33.658333333316705
                        ],
                        [
                            -118.78333333386621,
                            33.47499999975025
                        ],
                        [
                            -118.61666666723289,
                            33.32500000005001
                        ],
                        [
                            -118.49166666658334,
                            33.21666666633365
                        ],
                        [
                            -118.41750000012485,
                            33.27638888883621
                        ],
                        [
                            -118.25000000009987,
                            33.2499999993006
                        ],
                        [
                            -117.98000000009989,
                            33.208333333316716
                        ],
                        [
                            -117.86854444507003,
                            33.08151666616129
                        ],
                        [
                            -117.695277778047,
                            32.8833333330669
                        ],
                        [
                            -117.65000000039964,
                            32.79999999930055
                        ],
                        [
                            -117.54916666720788,
                            32.613888888361544
                        ],
                        [
                            -117.50083333319174,
                            32.52499999985014
                        ],
                        [
                            -117.3141666669581,
                            32.44666666643354
                        ],
                        [
                            -117.12361111096396,
                            32.53416666625867
                        ],
                        [
                            -116.86666666713296,
                            32.56388888846146
                        ],
                        [
                            -116.87146388924708,
                            32.58001388833031
                        ],
                        [
                            -117.10833333361643,
                            33.37499999995009
                        ],
                        [
                            -116.58333333376629,
                            33.333333333066946
                        ],
                        [
                            -117.11666666663331,
                            33.883333332867096
                        ],
                        [
                            -117.28028611181458,
                            33.96132777745504
                        ],
                        [
                            -117.41769444454297,
                            34.030005555012394
                        ],
                        [
                            -117.56755555564433,
                            34.09933888887866
                        ],
                        [
                            -118.09444444475531,
                            34.022222222177845
                        ],
                        [
                            -118.16666666723285,
                            34.174999999250645
                        ],
                        [
                            -118.14407222245507,
                            34.21999444368868
                        ],
                        [
                            -118.57725833380505,
                            34.19763055540585
                        ],
                        [
                            -118.69181944481147,
                            34.24654166606507
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 647,
                "artcc": "zla",
                "sector": "30",
                "sectype": "high",
                "Shape_Length": 7.463442593698817,
                "Shape_Area": 2.023365880825511
            }
        },
        {
            "type": "Feature",
            "id": 648,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -116.58333333376629,
                            33.333333333066946
                        ],
                        [
                            -117.10833333361643,
                            33.37499999995009
                        ],
                        [
                            -116.87146388924708,
                            32.58001388833031
                        ],
                        [
                            -116.86666666713296,
                            32.56388888846146
                        ],
                        [
                            -116.48333333396613,
                            32.59027777709781
                        ],
                        [
                            -116.09999999990004,
                            32.61666666663342
                        ],
                        [
                            -115.80833333351649,
                            32.64166666658343
                        ],
                        [
                            -115.09166666708296,
                            32.69166666648351
                        ],
                        [
                            -114.71527777824684,
                            32.7152777777473
                        ],
                        [
                            -114.81250000067445,
                            32.49305555556947
                        ],
                        [
                            -114.47500000044965,
                            32.395833333141866
                        ],
                        [
                            -114.0000000004996,
                            32.2499999995004
                        ],
                        [
                            -113.5083333336164,
                            32.09999999980022
                        ],
                        [
                            -113.51276944465411,
                            32.11622777778979
                        ],
                        [
                            -113.68472222285226,
                            32.737499999425495
                        ],
                        [
                            -114.0000000004996,
                            32.683333332567315
                        ],
                        [
                            -114.0000000004996,
                            33.08333333266722
                        ],
                        [
                            -114.0000000004996,
                            33.11666666653349
                        ],
                        [
                            -115.2416666667832,
                            33.129166666508524
                        ],
                        [
                            -115.89583333334161,
                            33.133333332567304
                        ],
                        [
                            -116.22500000054953,
                            33.133333332567304
                        ],
                        [
                            -116.58333333376629,
                            33.333333333066946
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 648,
                "artcc": "zla",
                "sector": "31",
                "sectype": "high",
                "Shape_Length": 9.18458599430437,
                "Shape_Area": 2.014526251562009
            }
        },
        {
            "type": "Feature",
            "id": 649,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -114.31555555624391,
                            35.783888888861156
                        ],
                        [
                            -114.2803999998601,
                            35.80287499998133
                        ],
                        [
                            -113.83888055549323,
                            36.077472221865605
                        ],
                        [
                            -114.0505555556943,
                            36.39972222210292
                        ],
                        [
                            -114.60055555549445,
                            36.71666666653351
                        ],
                        [
                            -114.88333333356644,
                            36.43333333316684
                        ],
                        [
                            -115.30083333399114,
                            36.43333333316684
                        ],
                        [
                            -115.55611111103889,
                            36.258888888811214
                        ],
                        [
                            -115.72203611127748,
                            36.1444527772739
                        ],
                        [
                            -115.85416666735779,
                            36.05333055556076
                        ],
                        [
                            -116.43333333316679,
                            35.6499999999001
                        ],
                        [
                            -115.50638611123003,
                            35.62471944384731
                        ],
                        [
                            -115.23333333376632,
                            35.62083333299199
                        ],
                        [
                            -114.96666666733279,
                            35.433333333366704
                        ],
                        [
                            -114.31555555624391,
                            35.783888888861156
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 649,
                "artcc": "zla",
                "sector": "34",
                "sectype": "high",
                "Shape_Length": 6.040905673763713,
                "Shape_Area": 1.6037760981627247
            }
        },
        {
            "type": "Feature",
            "id": 650,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -114.0000000004996,
                            34.66666666613378
                        ],
                        [
                            -113.7000000001998,
                            34.86666666663342
                        ],
                        [
                            -113.61666666733282,
                            34.9166666665335
                        ],
                        [
                            -112.92777777822187,
                            35.25555555544457
                        ],
                        [
                            -112.66666666653339,
                            35.383333332567304
                        ],
                        [
                            -112.15305555606903,
                            35.396666666833255
                        ],
                        [
                            -111.99999999999994,
                            35.39999999950044
                        ],
                        [
                            -111.84166666728282,
                            35.7666666666334
                        ],
                        [
                            -111.50416666705803,
                            36.42083333319181
                        ],
                        [
                            -111.53555555604402,
                            36.51499999925062
                        ],
                        [
                            -113.7119444448802,
                            36.1016666666834
                        ],
                        [
                            -113.83888055549323,
                            36.077472221865605
                        ],
                        [
                            -114.07764444423566,
                            36.040836110698024
                        ],
                        [
                            -114.23403055586544,
                            36.02815833274093
                        ],
                        [
                            -114.71774166704427,
                            35.99834166643478
                        ],
                        [
                            -115.21371944459668,
                            35.63626666607382
                        ],
                        [
                            -115.23333333376632,
                            35.62083333299199
                        ],
                        [
                            -114.96666666733279,
                            35.433333333366704
                        ],
                        [
                            -114.89166666658338,
                            35.37499999955037
                        ],
                        [
                            -114.86250000057453,
                            35.06666666623369
                        ],
                        [
                            -115.00555555594411,
                            34.70555555564442
                        ],
                        [
                            -115.08333333316676,
                            34.6583333331169
                        ],
                        [
                            -114.0000000004996,
                            34.6166666662337
                        ],
                        [
                            -114.0000000004996,
                            34.66666666613378
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 650,
                "artcc": "zla",
                "sector": "35",
                "sectype": "high",
                "Shape_Length": 9.628682142827028,
                "Shape_Area": 3.7421944832660867
            }
        },
        {
            "type": "Feature",
            "id": 652,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -117.29919166706173,
                            34.04944166619998
                        ],
                        [
                            -117.16981388899973,
                            34.06038055551829
                        ],
                        [
                            -117.02964444423566,
                            34.1047499999126
                        ],
                        [
                            -116.33075000021233,
                            34.30795277759864
                        ],
                        [
                            -115.78415277782841,
                            34.463461110441926
                        ],
                        [
                            -115.08333333316676,
                            34.6583333331169
                        ],
                        [
                            -115.00555555594411,
                            34.70555555564442
                        ],
                        [
                            -114.86250000057453,
                            35.06666666623369
                        ],
                        [
                            -114.89166666658338,
                            35.37499999955037
                        ],
                        [
                            -114.96666666733279,
                            35.433333333366704
                        ],
                        [
                            -115.23333333376632,
                            35.62083333299199
                        ],
                        [
                            -115.21371944459668,
                            35.63626666607382
                        ],
                        [
                            -115.32768888893105,
                            35.701802777176454
                        ],
                        [
                            -115.50638611123003,
                            35.62471944384731
                        ],
                        [
                            -116.60111111128873,
                            34.81666666673334
                        ],
                        [
                            -116.81250000027478,
                            34.67083333309188
                        ],
                        [
                            -116.90358888951562,
                            34.60385555568939
                        ],
                        [
                            -116.95416666695809,
                            34.56666666633362
                        ],
                        [
                            -117.18033055561062,
                            34.403316666131275
                        ],
                        [
                            -117.20088055629259,
                            34.38825833325552
                        ],
                        [
                            -117.2333333333666,
                            34.34999999980022
                        ],
                        [
                            -117.38750000002494,
                            34.23333333306692
                        ],
                        [
                            -117.46666666683319,
                            34.16666666623371
                        ],
                        [
                            -117.56755555564433,
                            34.09933888887866
                        ],
                        [
                            -117.41769444454297,
                            34.030005555012394
                        ],
                        [
                            -117.29919166706173,
                            34.04944166619998
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 652,
                "artcc": "zla",
                "sector": "37",
                "sectype": "high",
                "Shape_Length": 6.712822414590782,
                "Shape_Area": 1.8278448526074542
            }
        },
        {
            "type": "Feature",
            "id": 653,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -115.50638611123003,
                            35.62471944384731
                        ],
                        [
                            -116.43333333316679,
                            35.6499999999001
                        ],
                        [
                            -116.31250000037471,
                            35.47638888893613
                        ],
                        [
                            -116.6250277780095,
                            35.23543333258226
                        ],
                        [
                            -116.70499999995002,
                            35.173611110364504
                        ],
                        [
                            -116.79583333334165,
                            35.11666666613377
                        ],
                        [
                            -116.8119444449801,
                            35.147222221728214
                        ],
                        [
                            -116.97888888881113,
                            35.10805555501992
                        ],
                        [
                            -117.14583333354147,
                            34.93888888821169
                        ],
                        [
                            -117.19722222212783,
                            34.89166666658343
                        ],
                        [
                            -117.5333333336664,
                            34.83888888841153
                        ],
                        [
                            -117.5333333336664,
                            34.808333332817085
                        ],
                        [
                            -117.58333333356643,
                            34.79999999980021
                        ],
                        [
                            -118.01666666663334,
                            34.79999999980021
                        ],
                        [
                            -118.09583333344153,
                            34.827777777122776
                        ],
                        [
                            -118.09583333344153,
                            34.79999999980021
                        ],
                        [
                            -118.00000000059953,
                            34.76666666683326
                        ],
                        [
                            -117.96666666673326,
                            34.76666666683326
                        ],
                        [
                            -117.96416666655836,
                            34.56930555510735
                        ],
                        [
                            -118.14407222245507,
                            34.21999444368868
                        ],
                        [
                            -118.16666666723285,
                            34.174999999250645
                        ],
                        [
                            -118.09444444475531,
                            34.022222222177845
                        ],
                        [
                            -118.05000000049961,
                            33.93333333276712
                        ],
                        [
                            -117.78750000012485,
                            33.94055555519475
                        ],
                        [
                            -117.56755555564433,
                            34.09933888887866
                        ],
                        [
                            -117.46666666683319,
                            34.16666666623371
                        ],
                        [
                            -117.38750000002494,
                            34.23333333306692
                        ],
                        [
                            -117.2333333333666,
                            34.34999999980022
                        ],
                        [
                            -117.20088055629259,
                            34.38825833325552
                        ],
                        [
                            -117.18033055561062,
                            34.403316666131275
                        ],
                        [
                            -116.95416666695809,
                            34.56666666633362
                        ],
                        [
                            -116.90358888951562,
                            34.60385555568939
                        ],
                        [
                            -116.81250000027478,
                            34.67083333309188
                        ],
                        [
                            -116.60111111128873,
                            34.81666666673334
                        ],
                        [
                            -115.50638611123003,
                            35.62471944384731
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 653,
                "artcc": "zla",
                "sector": "38",
                "sectype": "high",
                "Shape_Length": 7.365441963919004,
                "Shape_Area": 1.313470759577706
            }
        },
        {
            "type": "Feature",
            "id": 654,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -114.0000000004996,
                            34.16666666623371
                        ],
                        [
                            -114.0008333338912,
                            34.383333332767165
                        ],
                        [
                            -114.0000000004996,
                            34.3999999997003
                        ],
                        [
                            -114.0000000004996,
                            34.4972222221279
                        ],
                        [
                            -114.0000000004996,
                            34.591666666283686
                        ],
                        [
                            -114.0000000004996,
                            34.6166666662337
                        ],
                        [
                            -115.08333333316676,
                            34.6583333331169
                        ],
                        [
                            -115.78415277782841,
                            34.463461110441926
                        ],
                        [
                            -116.33075000021233,
                            34.30795277759864
                        ],
                        [
                            -117.02964444423566,
                            34.1047499999126
                        ],
                        [
                            -117.16981388899973,
                            34.06038055551829
                        ],
                        [
                            -117.29919166706173,
                            34.04944166619998
                        ],
                        [
                            -117.41769444454297,
                            34.030005555012394
                        ],
                        [
                            -117.28028611181458,
                            33.96132777745504
                        ],
                        [
                            -117.11666666663331,
                            33.883333332867096
                        ],
                        [
                            -116.15583333354147,
                            34.03499999935053
                        ],
                        [
                            -116.1212083339974,
                            34.03594444398095
                        ],
                        [
                            -114.6448777782868,
                            33.87445277767358
                        ],
                        [
                            -114.0000000004996,
                            33.80000000000007
                        ],
                        [
                            -114.0000000004996,
                            33.944444444055875
                        ],
                        [
                            -114.0000000004996,
                            34.01666666653347
                        ],
                        [
                            -114.0000000004996,
                            34.16666666623371
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 654,
                "artcc": "zla",
                "sector": "39",
                "sectype": "high",
                "Shape_Length": 7.797844827809562,
                "Shape_Area": 1.6844646193924009
            }
        },
        {
            "type": "Feature",
            "id": 655,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -117.11666666663331,
                            33.883333332867096
                        ],
                        [
                            -116.58333333286697,
                            33.333333333066946
                        ],
                        [
                            -116.22499999965021,
                            33.133333333466624
                        ],
                        [
                            -115.89583333334161,
                            33.133333333466624
                        ],
                        [
                            -115.2416666667832,
                            33.129166666508524
                        ],
                        [
                            -115.5486111114136,
                            33.35833333301696
                        ],
                        [
                            -115.55555555574426,
                            33.394444444255726
                        ],
                        [
                            -115.10833333311678,
                            33.72500000014992
                        ],
                        [
                            -114.99486111135116,
                            33.84037777741253
                        ],
                        [
                            -114.64487777738748,
                            33.87445277767358
                        ],
                        [
                            -116.12120833309808,
                            34.03594444398095
                        ],
                        [
                            -116.15583333354147,
                            34.03500000024985
                        ],
                        [
                            -117.11666666663331,
                            33.883333332867096
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 655,
                "artcc": "zla",
                "sector": "40",
                "sectype": "high",
                "Shape_Length": 6.141762484036464,
                "Shape_Area": 1.2680544070061328
            }
        },
        {
            "type": "Feature",
            "id": 657,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -114.23403055586544,
                            36.02815833274093
                        ],
                        [
                            -114.07764444423566,
                            36.040836110698024
                        ],
                        [
                            -113.83888055549323,
                            36.077472221865605
                        ],
                        [
                            -113.7119444448802,
                            36.1016666666834
                        ],
                        [
                            -111.53555555604402,
                            36.51499999925062
                        ],
                        [
                            -111.60833333381629,
                            36.73333333256727
                        ],
                        [
                            -111.71844444465535,
                            37.00499999935056
                        ],
                        [
                            -111.87916666720787,
                            37.41249999997507
                        ],
                        [
                            -111.89583333324168,
                            37.41388888866135
                        ],
                        [
                            -112.42182777822933,
                            37.456174999576604
                        ],
                        [
                            -113.00000000069946,
                            37.499999999800195
                        ],
                        [
                            -113.78333333396614,
                            37.71666666633365
                        ],
                        [
                            -113.98555555564434,
                            37.472222221578306
                        ],
                        [
                            -114.39555555554443,
                            36.97083333299196
                        ],
                        [
                            -114.60055555549445,
                            36.71666666653351
                        ],
                        [
                            -114.88333333356644,
                            36.43333333316684
                        ],
                        [
                            -114.71774166704427,
                            35.99834166643478
                        ],
                        [
                            -114.23403055586544,
                            36.02815833274093
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 657,
                "artcc": "zla",
                "sector": "54",
                "sectype": "high",
                "Shape_Length": 8.283129593657724,
                "Shape_Area": 3.6429584661315038
            }
        },
        {
            "type": "Feature",
            "id": 659,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -114.64487777738748,
                            33.87445277767358
                        ],
                        [
                            -114.99486111135116,
                            33.84037777741253
                        ],
                        [
                            -115.10833333311678,
                            33.72500000014992
                        ],
                        [
                            -115.55555555574426,
                            33.394444444255726
                        ],
                        [
                            -115.5486111114136,
                            33.35833333301696
                        ],
                        [
                            -115.2416666667832,
                            33.129166666508524
                        ],
                        [
                            -113.99999999960028,
                            33.11666666653349
                        ],
                        [
                            -113.99999999960028,
                            33.3999999999001
                        ],
                        [
                            -113.99999999960028,
                            33.46666666673332
                        ],
                        [
                            -113.99999999960028,
                            33.610000000199875
                        ],
                        [
                            -113.99999999960028,
                            33.70000000019991
                        ],
                        [
                            -113.99999999960028,
                            33.80000000000007
                        ],
                        [
                            -114.64487777738748,
                            33.87445277767358
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 659,
                "artcc": "zla",
                "sector": "60",
                "sectype": "high",
                "Shape_Length": 4.063643524141652,
                "Shape_Area": 0.9490703663425946
            }
        },
        {
            "type": "Feature",
            "id": 677,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -109.10000000039969,
                            40.849999999400495
                        ],
                        [
                            -110.7416666667832,
                            41.32083333329177
                        ],
                        [
                            -111.6333333337663,
                            41.19999999960038
                        ],
                        [
                            -112.10000000069942,
                            41.058333332917016
                        ],
                        [
                            -112.10000000069942,
                            40.874999999350564
                        ],
                        [
                            -112.10000000069942,
                            40.76388888826165
                        ],
                        [
                            -111.6249999998501,
                            40.46777777772229
                        ],
                        [
                            -111.51666666703301,
                            40.39999999940051
                        ],
                        [
                            -110.58333333316676,
                            40.36388888816174
                        ],
                        [
                            -109.15000000029971,
                            40.23333333276713
                        ],
                        [
                            -109.10000000039969,
                            40.849999999400495
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 677,
                "artcc": "zlc",
                "sector": "03",
                "sectype": "high",
                "Shape_Length": 7.069323251995787,
                "Shape_Area": 2.2816087972389507
            }
        },
        {
            "type": "Feature",
            "id": 679,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -112.8958333339412,
                            39.10527777774729
                        ],
                        [
                            -111.68333333366638,
                            39.333333332767154
                        ],
                        [
                            -110.30000000069941,
                            39.58333333316682
                        ],
                        [
                            -109.81666666683316,
                            39.76388888846151
                        ],
                        [
                            -109.16666666723285,
                            39.9999999993006
                        ],
                        [
                            -109.15000000029971,
                            40.23333333276713
                        ],
                        [
                            -110.58333333316676,
                            40.36388888816174
                        ],
                        [
                            -111.51666666703301,
                            40.39999999940051
                        ],
                        [
                            -111.6249999998501,
                            40.46777777772229
                        ],
                        [
                            -112.10000000069942,
                            40.76388888826165
                        ],
                        [
                            -112.47694444483022,
                            40.6044444440559
                        ],
                        [
                            -112.59888888911092,
                            40.42583333274217
                        ],
                        [
                            -112.71750000072444,
                            40.2666666666334
                        ],
                        [
                            -112.71750000072444,
                            39.9999999993006
                        ],
                        [
                            -113.04361111156351,
                            39.66388888866135
                        ],
                        [
                            -113.15833333341658,
                            39.54999999930055
                        ],
                        [
                            -113.31750000042467,
                            39.383333332667235
                        ],
                        [
                            -113.31750000042467,
                            39.064999999550366
                        ],
                        [
                            -113.31666666703302,
                            39.019444443806094
                        ],
                        [
                            -112.8958333339412,
                            39.10527777774729
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 679,
                "artcc": "zlc",
                "sector": "04",
                "sectype": "high",
                "Shape_Length": 9.886306938185793,
                "Shape_Area": 3.624842283324027
            }
        },
        {
            "type": "Feature",
            "id": 681,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -107.13333333286698,
                            42.94999999970025
                        ],
                        [
                            -109.66666666713297,
                            42.3999999999001
                        ],
                        [
                            -110.93333333336659,
                            42.09999999960036
                        ],
                        [
                            -110.7416666667832,
                            41.32083333329177
                        ],
                        [
                            -109.10000000039969,
                            40.850000000299815
                        ],
                        [
                            -108.2750000002498,
                            41.36666666713302
                        ],
                        [
                            -108.0722222223776,
                            41.53749999982517
                        ],
                        [
                            -107.99999999990001,
                            41.608333333616486
                        ],
                        [
                            -107.04999999999995,
                            42.41666666683324
                        ],
                        [
                            -107.06666666693309,
                            42.48333333366645
                        ],
                        [
                            -107.07361111126374,
                            42.54305555526969
                        ],
                        [
                            -107.13333333286698,
                            42.94999999970025
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 681,
                "artcc": "zlc",
                "sector": "05",
                "sectype": "high",
                "Shape_Length": 9.531546866543387,
                "Shape_Area": 4.504959489305479
            }
        },
        {
            "type": "Feature",
            "id": 683,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -110.3655361118021,
                            44.538577777342596
                        ],
                        [
                            -110.03333333336661,
                            45.00000000009999
                        ],
                        [
                            -110.02027777809695,
                            45.47694444403095
                        ],
                        [
                            -110.00000000039967,
                            45.88333333316683
                        ],
                        [
                            -110.46666666733279,
                            46.0805555553946
                        ],
                        [
                            -110.81250000057452,
                            46.2161111111389
                        ],
                        [
                            -110.98000000059949,
                            46.22916666640856
                        ],
                        [
                            -111.09416666715794,
                            46.237499999425495
                        ],
                        [
                            -111.60138888948563,
                            46.268611111213886
                        ],
                        [
                            -112.38694444483025,
                            46.31777777772231
                        ],
                        [
                            -112.91166666658336,
                            46.34805555521973
                        ],
                        [
                            -113.93333333366638,
                            46.3833333330669
                        ],
                        [
                            -115.00000000029974,
                            46.41666666603385
                        ],
                        [
                            -115.00000000029974,
                            45.33333333336668
                        ],
                        [
                            -114.36666666673324,
                            44.70416666675834
                        ],
                        [
                            -114.15833333321672,
                            44.494999999850165
                        ],
                        [
                            -110.62861111171338,
                            44.16722222222785
                        ],
                        [
                            -110.3655361118021,
                            44.538577777342596
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 683,
                "artcc": "zlc",
                "sector": "06",
                "sectype": "high",
                "Shape_Length": 12.794755316870322,
                "Shape_Area": 9.102963502656845
            }
        },
        {
            "type": "Feature",
            "id": 685,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -111.6333333337663,
                            41.19999999960038
                        ],
                        [
                            -110.7416666667832,
                            41.32083333329177
                        ],
                        [
                            -110.93333333336659,
                            42.09999999960036
                        ],
                        [
                            -111.71666666663333,
                            42.26666666623373
                        ],
                        [
                            -112.17500000054957,
                            42.35833333301696
                        ],
                        [
                            -112.13083333349152,
                            41.62555555494498
                        ],
                        [
                            -112.127777778022,
                            41.54722222152833
                        ],
                        [
                            -112.16111111098894,
                            41.322222221977995
                        ],
                        [
                            -112.10000000069942,
                            41.058333332917016
                        ],
                        [
                            -111.6333333337663,
                            41.19999999960038
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 685,
                "artcc": "zlc",
                "sector": "07",
                "sectype": "high",
                "Shape_Length": 4.769013424777347,
                "Shape_Area": 1.3294475309077465
            }
        },
        {
            "type": "Feature",
            "id": 687,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -114.15833333321672,
                            44.494999999850165
                        ],
                        [
                            -113.91666666673325,
                            44.249999999800195
                        ],
                        [
                            -113.56666666653342,
                            43.52500000034979
                        ],
                        [
                            -113.08333333356643,
                            42.53333333356653
                        ],
                        [
                            -112.17499999965025,
                            42.35833333301696
                        ],
                        [
                            -111.71666666663333,
                            42.26666666713305
                        ],
                        [
                            -110.93333333336659,
                            42.09999999960036
                        ],
                        [
                            -109.66666666713297,
                            42.3999999999001
                        ],
                        [
                            -110.00000000039967,
                            42.883333332867096
                        ],
                        [
                            -110.66666666693311,
                            43.08333333336668
                        ],
                        [
                            -111.13333333296691,
                            43.44999999960038
                        ],
                        [
                            -110.62861111081406,
                            44.16722222222785
                        ],
                        [
                            -114.15833333321672,
                            44.494999999850165
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 687,
                "artcc": "zlc",
                "sector": "08",
                "sectype": "high",
                "Shape_Length": 12.04595847196477,
                "Shape_Area": 6.036002082987558
            }
        },
        {
            "type": "Feature",
            "id": 689,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -112.8958333339412,
                            39.10527777774729
                        ],
                        [
                            -111.68333333366638,
                            39.333333332767154
                        ],
                        [
                            -111.82622222217776,
                            39.87808888839152
                        ],
                        [
                            -111.87750000042462,
                            40.07333333326676
                        ],
                        [
                            -111.92500000014985,
                            40.21666666673332
                        ],
                        [
                            -111.99722222262744,
                            40.23055555539463
                        ],
                        [
                            -112.08611111113885,
                            40.349999999500426
                        ],
                        [
                            -112.10000000069942,
                            40.76388888826165
                        ],
                        [
                            -112.47694444483022,
                            40.6044444440559
                        ],
                        [
                            -112.59888888911092,
                            40.42583333274217
                        ],
                        [
                            -112.71750000072444,
                            40.2666666666334
                        ],
                        [
                            -112.71750000072444,
                            39.9999999993006
                        ],
                        [
                            -113.04361111156351,
                            39.66388888866135
                        ],
                        [
                            -113.15833333341658,
                            39.54999999930055
                        ],
                        [
                            -113.31750000042467,
                            39.383333332667235
                        ],
                        [
                            -113.31750000042467,
                            39.064999999550366
                        ],
                        [
                            -113.31666666703302,
                            39.019444443806094
                        ],
                        [
                            -112.8958333339412,
                            39.10527777774729
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 689,
                "artcc": "zlc",
                "sector": "11",
                "sectype": "high",
                "Shape_Length": 5.5309055135284995,
                "Shape_Area": 1.5179642470311963
            }
        },
        {
            "type": "Feature",
            "id": 691,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -112.16111111098894,
                            41.322222221977995
                        ],
                        [
                            -112.127777778022,
                            41.54722222152833
                        ],
                        [
                            -112.13083333349152,
                            41.62555555494498
                        ],
                        [
                            -112.17500000054957,
                            42.35833333301696
                        ],
                        [
                            -113.08333333356643,
                            42.53333333266721
                        ],
                        [
                            -113.54166666658335,
                            42.14999999950044
                        ],
                        [
                            -113.60000000039969,
                            42.09999999960036
                        ],
                        [
                            -113.25083333359146,
                            41.87500000005002
                        ],
                        [
                            -112.61361111116383,
                            41.458888888311606
                        ],
                        [
                            -112.53916666660837,
                            41.40972222180312
                        ],
                        [
                            -112.10000000069942,
                            41.058333332917016
                        ],
                        [
                            -112.16111111098894,
                            41.322222221977995
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 691,
                "artcc": "zlc",
                "sector": "14",
                "sectype": "high",
                "Shape_Length": 4.738294269092881,
                "Shape_Area": 1.1316913961422514
            }
        },
        {
            "type": "Feature",
            "id": 693,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -106.26666666673327,
                            44.31666666663341
                        ],
                        [
                            -106.14166666698304,
                            44.7666666666334
                        ],
                        [
                            -106.00000000029974,
                            45.237499999625356
                        ],
                        [
                            -107.62394166677444,
                            45.629288888171686
                        ],
                        [
                            -108.5288888895106,
                            45.791666666383605
                        ],
                        [
                            -108.7574972227186,
                            46.462094444303204
                        ],
                        [
                            -108.78333333316675,
                            46.633333332567304
                        ],
                        [
                            -109.56666666733281,
                            46.633333332567304
                        ],
                        [
                            -110.23333333386626,
                            46.633333332567304
                        ],
                        [
                            -110.46666666733279,
                            46.0805555553946
                        ],
                        [
                            -110.00000000039967,
                            45.88333333316683
                        ],
                        [
                            -110.02027777809695,
                            45.47694444403095
                        ],
                        [
                            -110.03333333336661,
                            45.00000000009999
                        ],
                        [
                            -110.3655361118021,
                            44.538577777342596
                        ],
                        [
                            -107.28333333346654,
                            43.883333332667235
                        ],
                        [
                            -106.26666666673327,
                            44.31666666663341
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 693,
                "artcc": "zlc",
                "sector": "15",
                "sectype": "high",
                "Shape_Length": 12.695635622260305,
                "Shape_Area": 7.215320071140897
            }
        },
        {
            "type": "Feature",
            "id": 695,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -107.28333333346654,
                            43.883333333566554
                        ],
                        [
                            -110.36553611090278,
                            44.538577778241915
                        ],
                        [
                            -110.62861111081406,
                            44.16722222222785
                        ],
                        [
                            -111.13333333296691,
                            43.44999999960038
                        ],
                        [
                            -110.66666666693311,
                            43.08333333336668
                        ],
                        [
                            -110.00000000039967,
                            42.883333332867096
                        ],
                        [
                            -109.66666666713297,
                            42.3999999999001
                        ],
                        [
                            -107.13333333286698,
                            42.94999999970025
                        ],
                        [
                            -107.28333333346654,
                            43.883333333566554
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 695,
                "artcc": "zlc",
                "sector": "16",
                "sectype": "high",
                "Shape_Length": 9.897486596907333,
                "Shape_Area": 5.388393187925077
            }
        },
        {
            "type": "Feature",
            "id": 697,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -103.16666666663332,
                            46.449999999900115
                        ],
                        [
                            -103.16666666663332,
                            47.25000000009999
                        ],
                        [
                            -103.16666666663332,
                            47.84611111093909
                        ],
                        [
                            -103.16666666663332,
                            49.00000000019992
                        ],
                        [
                            -103.67500000044964,
                            49.00000000019992
                        ],
                        [
                            -104.49999999970021,
                            49.00000000019992
                        ],
                        [
                            -104.99999999960028,
                            49.00000000019992
                        ],
                        [
                            -106.5000000001998,
                            49.00000000019992
                        ],
                        [
                            -107.99999999990001,
                            49.00000000019992
                        ],
                        [
                            -109.18333333326666,
                            49.00000000019992
                        ],
                        [
                            -108.86111111128872,
                            47.16666666633364
                        ],
                        [
                            -108.84143888849388,
                            47.032811110744206
                        ],
                        [
                            -108.78333333316675,
                            46.633333333466624
                        ],
                        [
                            -108.75749722181928,
                            46.462094444303204
                        ],
                        [
                            -108.52888888861128,
                            45.791666666383605
                        ],
                        [
                            -107.62394166677444,
                            45.629288889071006
                        ],
                        [
                            -106.00000000029974,
                            45.237499999625356
                        ],
                        [
                            -104.76944444440556,
                            45.14999999980023
                        ],
                        [
                            -104.2500000001998,
                            45.11666666683328
                        ],
                        [
                            -103.16666666663332,
                            44.958333333216785
                        ],
                        [
                            -103.16666666663332,
                            46.449999999900115
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 697,
                "artcc": "zlc",
                "sector": "17",
                "sectype": "high",
                "Shape_Length": 18.779204620877607,
                "Shape_Area": 20.932672160314464
            }
        },
        {
            "type": "Feature",
            "id": 699,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -113.08333333356643,
                            42.53333333356653
                        ],
                        [
                            -113.54166666658335,
                            42.15000000039976
                        ],
                        [
                            -113.60000000039969,
                            42.09999999960036
                        ],
                        [
                            -113.25083333359146,
                            41.87500000005002
                        ],
                        [
                            -112.61361111116383,
                            41.458888889210925
                        ],
                        [
                            -112.53916666660837,
                            41.40972222180312
                        ],
                        [
                            -112.0999999998001,
                            41.058333332917016
                        ],
                        [
                            -111.63333333286698,
                            41.19999999960038
                        ],
                        [
                            -110.7416666667832,
                            41.32083333329177
                        ],
                        [
                            -110.93333333336659,
                            42.09999999960036
                        ],
                        [
                            -111.71666666663333,
                            42.26666666713305
                        ],
                        [
                            -112.17499999965025,
                            42.35833333301696
                        ],
                        [
                            -113.08333333356643,
                            42.53333333356653
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 699,
                "artcc": "zlc",
                "sector": "18",
                "sectype": "high",
                "Shape_Length": 6.88565021078372,
                "Shape_Area": 2.4611389282788187
            }
        },
        {
            "type": "Feature",
            "id": 700,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -109.18333333326666,
                            49.00000000019992
                        ],
                        [
                            -110.00000000039967,
                            49.00000000019992
                        ],
                        [
                            -110.66666666693311,
                            49.00000000019992
                        ],
                        [
                            -111.66666666673325,
                            49.00000000019992
                        ],
                        [
                            -112.99999999980014,
                            49.00000000019992
                        ],
                        [
                            -114.66666666703304,
                            49.00000000019992
                        ],
                        [
                            -114.69999999999999,
                            48.95000000029984
                        ],
                        [
                            -114.71777777752237,
                            48.91666666643357
                        ],
                        [
                            -115.00000000029974,
                            48.4166666665335
                        ],
                        [
                            -115.00000000029974,
                            47.58333333336668
                        ],
                        [
                            -115.00000000029974,
                            47.150000000299826
                        ],
                        [
                            -115.00000000029974,
                            46.41666666693317
                        ],
                        [
                            -113.93333333366638,
                            46.3833333330669
                        ],
                        [
                            -112.91166666658336,
                            46.34805555521973
                        ],
                        [
                            -112.38694444483025,
                            46.31777777772231
                        ],
                        [
                            -111.60138888858631,
                            46.268611111213886
                        ],
                        [
                            -111.09416666625862,
                            46.237500000324815
                        ],
                        [
                            -110.97999999970017,
                            46.22916666640856
                        ],
                        [
                            -110.8124999996752,
                            46.2161111111389
                        ],
                        [
                            -110.46666666643347,
                            46.0805555553946
                        ],
                        [
                            -110.23333333296694,
                            46.633333333466624
                        ],
                        [
                            -109.56666666643349,
                            46.633333333466624
                        ],
                        [
                            -108.78333333316675,
                            46.633333333466624
                        ],
                        [
                            -108.84143888849388,
                            47.032811110744206
                        ],
                        [
                            -108.86111111128872,
                            47.16666666633364
                        ],
                        [
                            -109.18333333326666,
                            49.00000000019992
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 700,
                "artcc": "zlc",
                "sector": "19",
                "sectype": "high",
                "Shape_Length": 17.17002618266619,
                "Shape_Area": 15.644745063650335
            }
        },
        {
            "type": "Feature",
            "id": 702,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -109.18333333326666,
                            49.00000000019992
                        ],
                        [
                            -110.00000000039967,
                            49.00000000019992
                        ],
                        [
                            -110.66666666693311,
                            49.00000000019992
                        ],
                        [
                            -111.66666666673325,
                            49.00000000019992
                        ],
                        [
                            -112.99999999980014,
                            49.00000000019992
                        ],
                        [
                            -114.66666666703304,
                            49.00000000019992
                        ],
                        [
                            -114.69999999999999,
                            48.95000000029984
                        ],
                        [
                            -114.71777777752237,
                            48.91666666643357
                        ],
                        [
                            -115.00000000029974,
                            48.4166666665335
                        ],
                        [
                            -115.00000000029974,
                            47.58333333336668
                        ],
                        [
                            -115.00000000029974,
                            47.150000000299826
                        ],
                        [
                            -115.00000000029974,
                            46.41666666693317
                        ],
                        [
                            -113.93333333366638,
                            46.3833333330669
                        ],
                        [
                            -112.91166666658336,
                            46.34805555521973
                        ],
                        [
                            -112.38694444483025,
                            46.31777777772231
                        ],
                        [
                            -111.60138888858631,
                            46.268611111213886
                        ],
                        [
                            -111.09416666625862,
                            46.237500000324815
                        ],
                        [
                            -110.97999999970017,
                            46.22916666640856
                        ],
                        [
                            -110.8124999996752,
                            46.2161111111389
                        ],
                        [
                            -110.46666666643347,
                            46.0805555553946
                        ],
                        [
                            -110.23333333296694,
                            46.633333333466624
                        ],
                        [
                            -109.56666666643349,
                            46.633333333466624
                        ],
                        [
                            -108.78333333316675,
                            46.633333333466624
                        ],
                        [
                            -108.84143888849388,
                            47.032811110744206
                        ],
                        [
                            -108.86111111128872,
                            47.16666666633364
                        ],
                        [
                            -109.18333333326666,
                            49.00000000019992
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 702,
                "artcc": "zlc",
                "sector": "20",
                "sectype": "high",
                "Shape_Length": 17.17002618266619,
                "Shape_Area": 15.644745063650335
            }
        },
        {
            "type": "Feature",
            "id": 703,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -114.15833333321672,
                            44.494999999850165
                        ],
                        [
                            -113.91666666673325,
                            44.249999999800195
                        ],
                        [
                            -113.56666666653342,
                            43.52500000034979
                        ],
                        [
                            -113.08333333356643,
                            42.53333333356653
                        ],
                        [
                            -112.17499999965025,
                            42.35833333301696
                        ],
                        [
                            -111.71666666663333,
                            42.26666666713305
                        ],
                        [
                            -110.93333333336659,
                            42.09999999960036
                        ],
                        [
                            -109.66666666713297,
                            42.3999999999001
                        ],
                        [
                            -110.00000000039967,
                            42.883333332867096
                        ],
                        [
                            -110.66666666693311,
                            43.08333333336668
                        ],
                        [
                            -111.13333333296691,
                            43.44999999960038
                        ],
                        [
                            -110.62861111081406,
                            44.16722222222785
                        ],
                        [
                            -114.15833333321672,
                            44.494999999850165
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 703,
                "artcc": "zlc",
                "sector": "29",
                "sectype": "high",
                "Shape_Length": 12.04595847196477,
                "Shape_Area": 6.036002082987558
            }
        },
        {
            "type": "Feature",
            "id": 704,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -116.61666666673324,
                            41.999999999800195
                        ],
                        [
                            -116.51666666693308,
                            42.35833333301696
                        ],
                        [
                            -116.38888888891108,
                            42.510555555694395
                        ],
                        [
                            -115.830555556094,
                            43.15833333321683
                        ],
                        [
                            -115.91393888946811,
                            43.26046666610381
                        ],
                        [
                            -116.16666666673325,
                            43.56666666633362
                        ],
                        [
                            -115.62916666690813,
                            43.81944444410584
                        ],
                        [
                            -115.45141944435181,
                            43.92246111089162
                        ],
                        [
                            -114.36666666673324,
                            44.70416666675834
                        ],
                        [
                            -115.00000000029974,
                            45.33333333336668
                        ],
                        [
                            -117.01000000059952,
                            45.33333333336668
                        ],
                        [
                            -117.50000000069946,
                            45.33333333336668
                        ],
                        [
                            -117.7500000001998,
                            45.33333333336668
                        ],
                        [
                            -117.91666666683318,
                            45.2333333326672
                        ],
                        [
                            -118.06166666708299,
                            45.129999999300594
                        ],
                        [
                            -118.45000000059952,
                            44.849999999500426
                        ],
                        [
                            -118.54166666738274,
                            44.699999999800184
                        ],
                        [
                            -119.21666666693312,
                            43.73333333296699
                        ],
                        [
                            -119.28333333376628,
                            43.63333333316683
                        ],
                        [
                            -119.24999999990007,
                            43.53333333336667
                        ],
                        [
                            -119.00000000039967,
                            42.66666666633364
                        ],
                        [
                            -119.10000000019983,
                            42.333333333066946
                        ],
                        [
                            -116.99999999990007,
                            41.999999999800195
                        ],
                        [
                            -116.61666666673324,
                            41.999999999800195
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 704,
                "artcc": "zlc",
                "sector": "30",
                "sectype": "high",
                "Shape_Length": 13.925249286828265,
                "Shape_Area": 10.650108185561338
            }
        },
        {
            "type": "Feature",
            "id": 706,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -115.00000000029974,
                            41.999999999800195
                        ],
                        [
                            -114.5833333332667,
                            41.999999999800195
                        ],
                        [
                            -113.71666666713293,
                            41.999999999800195
                        ],
                        [
                            -113.60000000039969,
                            42.09999999960036
                        ],
                        [
                            -113.54166666658335,
                            42.14999999950044
                        ],
                        [
                            -113.08333333356643,
                            42.53333333266721
                        ],
                        [
                            -113.56666666653342,
                            43.52499999945047
                        ],
                        [
                            -113.91666666673325,
                            44.249999999800195
                        ],
                        [
                            -114.15833333321672,
                            44.494999999850165
                        ],
                        [
                            -114.36666666673324,
                            44.70416666675834
                        ],
                        [
                            -115.45141944435181,
                            43.92246111089162
                        ],
                        [
                            -115.62916666690813,
                            43.81944444410584
                        ],
                        [
                            -116.16666666673325,
                            43.56666666633362
                        ],
                        [
                            -115.91393888946811,
                            43.26046666610381
                        ],
                        [
                            -115.830555556094,
                            43.15833333321683
                        ],
                        [
                            -116.38888888891108,
                            42.510555555694395
                        ],
                        [
                            -116.51666666693308,
                            42.35833333301696
                        ],
                        [
                            -116.61666666673324,
                            41.999999999800195
                        ],
                        [
                            -115.00000000029974,
                            41.999999999800195
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 706,
                "artcc": "zlc",
                "sector": "31",
                "sectype": "high",
                "Shape_Length": 10.366908551302302,
                "Shape_Area": 5.936450843585798
            }
        },
        {
            "type": "Feature",
            "id": 708,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -110.30000000069941,
                            39.58333333316682
                        ],
                        [
                            -109.81666666683316,
                            39.76388888846151
                        ],
                        [
                            -110.58333333316676,
                            40.36388888816174
                        ],
                        [
                            -111.51666666703301,
                            40.39999999940051
                        ],
                        [
                            -111.6249999998501,
                            40.46777777772229
                        ],
                        [
                            -112.10000000069942,
                            40.76388888826165
                        ],
                        [
                            -112.08611111113885,
                            40.349999999500426
                        ],
                        [
                            -111.99722222262744,
                            40.23055555539463
                        ],
                        [
                            -111.92500000014985,
                            40.21666666673332
                        ],
                        [
                            -111.87750000042462,
                            40.07333333326676
                        ],
                        [
                            -111.82622222217776,
                            39.87808888839152
                        ],
                        [
                            -111.68333333366638,
                            39.333333332767154
                        ],
                        [
                            -110.30000000069941,
                            39.58333333316682
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 708,
                "artcc": "zlc",
                "sector": "32",
                "sectype": "high",
                "Shape_Length": 6.069402044980024,
                "Shape_Area": 1.6530585921521992
            }
        },
        {
            "type": "Feature",
            "id": 713,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -113.15833333341658,
                            39.54999999930055
                        ],
                        [
                            -113.04361111156351,
                            39.66388888866135
                        ],
                        [
                            -112.71750000072444,
                            39.9999999993006
                        ],
                        [
                            -112.71750000072444,
                            40.2666666666334
                        ],
                        [
                            -112.59888888911092,
                            40.42583333274217
                        ],
                        [
                            -112.47694444483022,
                            40.6044444440559
                        ],
                        [
                            -112.10000000069942,
                            40.76388888826165
                        ],
                        [
                            -112.10000000069942,
                            40.874999999350564
                        ],
                        [
                            -112.10000000069942,
                            41.058333332917016
                        ],
                        [
                            -112.53916666660837,
                            41.40972222180312
                        ],
                        [
                            -112.61361111116383,
                            41.458888888311606
                        ],
                        [
                            -113.25083333359146,
                            41.87500000005002
                        ],
                        [
                            -113.60000000039969,
                            42.09999999960036
                        ],
                        [
                            -113.71666666713293,
                            41.999999999800195
                        ],
                        [
                            -114.5833333332667,
                            41.999999999800195
                        ],
                        [
                            -114.50111111098897,
                            41.6666666665335
                        ],
                        [
                            -114.45833333351652,
                            41.15027777779727
                        ],
                        [
                            -114.4341666669581,
                            40.899999999300576
                        ],
                        [
                            -114.45083333389124,
                            40.59999999990015
                        ],
                        [
                            -114.45000000049959,
                            40.299999999600345
                        ],
                        [
                            -114.45000000049959,
                            39.54999999930055
                        ],
                        [
                            -113.15833333341658,
                            39.54999999930055
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 713,
                "artcc": "zlc",
                "sector": "40",
                "sectype": "high",
                "Shape_Length": 8.618601573280072,
                "Shape_Area": 4.473128241149916
            }
        },
        {
            "type": "Feature",
            "id": 716,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -117.49999999980014,
                            45.33333333336668
                        ],
                        [
                            -117.7500000001998,
                            45.33333333336668
                        ],
                        [
                            -117.91666666683318,
                            45.23333333356652
                        ],
                        [
                            -118.06166666708299,
                            45.130000000199914
                        ],
                        [
                            -118.4499999997002,
                            44.850000000399746
                        ],
                        [
                            -118.54166666648342,
                            44.699999999800184
                        ],
                        [
                            -119.21666666693312,
                            43.73333333296699
                        ],
                        [
                            -119.28333333286696,
                            43.63333333316683
                        ],
                        [
                            -119.24999999990007,
                            43.53333333336667
                        ],
                        [
                            -119.00000000039967,
                            42.66666666633364
                        ],
                        [
                            -119.10000000019983,
                            42.333333333066946
                        ],
                        [
                            -116.99999999990007,
                            41.999999999800195
                        ],
                        [
                            -116.61666666673324,
                            41.999999999800195
                        ],
                        [
                            -115.00000000029974,
                            41.999999999800195
                        ],
                        [
                            -114.5833333332667,
                            41.999999999800195
                        ],
                        [
                            -113.71666666713293,
                            41.999999999800195
                        ],
                        [
                            -113.60000000039969,
                            42.09999999960036
                        ],
                        [
                            -113.54166666658335,
                            42.15000000039976
                        ],
                        [
                            -113.08333333356643,
                            42.53333333356653
                        ],
                        [
                            -113.56666666653342,
                            43.52500000034979
                        ],
                        [
                            -113.91666666673325,
                            44.249999999800195
                        ],
                        [
                            -114.15833333321672,
                            44.494999999850165
                        ],
                        [
                            -114.36666666673324,
                            44.70416666675834
                        ],
                        [
                            -115.00000000029974,
                            45.33333333336668
                        ],
                        [
                            -117.0099999997002,
                            45.33333333336668
                        ],
                        [
                            -117.49999999980014,
                            45.33333333336668
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 716,
                "artcc": "zlc",
                "sector": "41",
                "sectype": "high",
                "Shape_Length": 16.109524092769114,
                "Shape_Area": 16.586559029111164
            }
        },
        {
            "type": "Feature",
            "id": 718,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -119.10000000019983,
                            42.333333333066946
                        ],
                        [
                            -119.50000000029974,
                            41.00000000000006
                        ],
                        [
                            -117.73222222267742,
                            40.121111111088965
                        ],
                        [
                            -117.36666666703303,
                            39.933333333366704
                        ],
                        [
                            -117.33333333316676,
                            39.4166666665335
                        ],
                        [
                            -117.32500000014983,
                            39.2069444443307
                        ],
                        [
                            -117.30750000072442,
                            38.96888888861139
                        ],
                        [
                            -117.29166666718294,
                            38.777777777322626
                        ],
                        [
                            -114.86916666680821,
                            39.44138888838654
                        ],
                        [
                            -114.61776666659836,
                            39.506522221923035
                        ],
                        [
                            -114.6177741345686,
                            39.50652770868686
                        ],
                        [
                            -114.45000000049959,
                            39.54999999930055
                        ],
                        [
                            -114.45000000049959,
                            40.299999999600345
                        ],
                        [
                            -114.45083333389124,
                            40.59999999990015
                        ],
                        [
                            -114.4341666669581,
                            40.899999999300576
                        ],
                        [
                            -114.45833333351652,
                            41.15027777779727
                        ],
                        [
                            -114.50111111098897,
                            41.6666666665335
                        ],
                        [
                            -114.5833333332667,
                            41.999999999800195
                        ],
                        [
                            -115.00000000029974,
                            41.999999999800195
                        ],
                        [
                            -116.61666666673324,
                            41.999999999800195
                        ],
                        [
                            -116.99999999990007,
                            41.999999999800195
                        ],
                        [
                            -119.10000000019983,
                            42.333333333066946
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 718,
                "artcc": "zlc",
                "sector": "43",
                "sectype": "high",
                "Shape_Length": 14.886420578530434,
                "Shape_Area": 11.691544374713652
            }
        },
        {
            "type": "Feature",
            "id": 720,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -112.88333333396616,
                            37.76666666623373
                        ],
                        [
                            -111.85000000029976,
                            38.43333333276712
                        ],
                        [
                            -111.68333333366638,
                            39.333333332767154
                        ],
                        [
                            -112.8958333339412,
                            39.10527777774729
                        ],
                        [
                            -113.31666666703302,
                            39.019444443806094
                        ],
                        [
                            -113.71250000017483,
                            38.57583333284208
                        ],
                        [
                            -114.20083333349152,
                            38.0166666666334
                        ],
                        [
                            -113.70083333359145,
                            37.89555555574435
                        ],
                        [
                            -113.70027777829682,
                            37.82361111046441
                        ],
                        [
                            -113.78333333396614,
                            37.71666666633365
                        ],
                        [
                            -113.00000000069946,
                            37.499999999800195
                        ],
                        [
                            -112.88333333396616,
                            37.76666666623373
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 720,
                "artcc": "zlc",
                "sector": "44",
                "sectype": "high",
                "Shape_Length": 6.9708380415981,
                "Shape_Area": 2.4878447528571304
            }
        },
        {
            "type": "Feature",
            "id": 723,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -109.98333333346653,
                            38.94555555544457
                        ],
                        [
                            -109.98333333346653,
                            39.21666666693318
                        ],
                        [
                            -110.2999999998001,
                            39.58333333316682
                        ],
                        [
                            -111.68333333366638,
                            39.333333333666474
                        ],
                        [
                            -111.85000000029976,
                            38.43333333366644
                        ],
                        [
                            -112.88333333306684,
                            37.76666666713305
                        ],
                        [
                            -112.99999999980014,
                            37.499999999800195
                        ],
                        [
                            -112.42182777822933,
                            37.456174999576604
                        ],
                        [
                            -111.89583333324168,
                            37.41388888866135
                        ],
                        [
                            -111.87916666630855,
                            37.41249999997507
                        ],
                        [
                            -111.31388888916086,
                            37.65000000039976
                        ],
                        [
                            -109.98333333346653,
                            38.20000000019991
                        ],
                        [
                            -109.98333333346653,
                            38.52777777782222
                        ],
                        [
                            -109.98333333346653,
                            38.933333333566566
                        ],
                        [
                            -109.98333333346653,
                            38.94555555544457
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 723,
                "artcc": "zlc",
                "sector": "46",
                "sectype": "high",
                "Shape_Length": 8.520127252077105,
                "Shape_Area": 3.6419433087587163
            }
        },
        {
            "type": "Feature",
            "id": 725,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -114.61776666659836,
                            39.506522221923035
                        ],
                        [
                            -114.86916666680821,
                            39.44138888838654
                        ],
                        [
                            -117.29166666718294,
                            38.777777777322626
                        ],
                        [
                            -117.28138888928578,
                            38.41305555506989
                        ],
                        [
                            -117.27805555571928,
                            38.29583333304191
                        ],
                        [
                            -117.26666666723287,
                            38.08333333256729
                        ],
                        [
                            -117.09472222215277,
                            37.88333333296697
                        ],
                        [
                            -116.91750000042464,
                            37.88333333296697
                        ],
                        [
                            -116.8333333332667,
                            37.88333333296697
                        ],
                        [
                            -116.43416666655838,
                            37.88333333296697
                        ],
                        [
                            -116.18416666705798,
                            37.88333333296697
                        ],
                        [
                            -114.70166666678324,
                            37.99972222160329
                        ],
                        [
                            -114.50083333379126,
                            38.0166666666334
                        ],
                        [
                            -114.20083333349152,
                            38.0166666666334
                        ],
                        [
                            -113.71250000017483,
                            38.57583333284208
                        ],
                        [
                            -113.31666666703302,
                            39.019444443806094
                        ],
                        [
                            -113.31750000042467,
                            39.064999999550366
                        ],
                        [
                            -113.31750000042467,
                            39.383333332667235
                        ],
                        [
                            -114.05083333379127,
                            39.383333332667235
                        ],
                        [
                            -114.45083333389124,
                            39.383333332667235
                        ],
                        [
                            -114.61776666659836,
                            39.506522221923035
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 725,
                "artcc": "zlc",
                "sector": "47",
                "sectype": "high",
                "Shape_Length": 9.670926809763403,
                "Shape_Area": 4.587646536657477
            }
        },
        {
            "type": "Feature",
            "id": 727,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -103.16666666663332,
                            46.449999999900115
                        ],
                        [
                            -103.16666666663332,
                            47.25000000009999
                        ],
                        [
                            -103.16666666663332,
                            47.84611111093909
                        ],
                        [
                            -103.16666666663332,
                            49.00000000019992
                        ],
                        [
                            -103.67500000044964,
                            49.00000000019992
                        ],
                        [
                            -104.49999999970021,
                            49.00000000019992
                        ],
                        [
                            -104.99999999960028,
                            49.00000000019992
                        ],
                        [
                            -106.5000000001998,
                            49.00000000019992
                        ],
                        [
                            -107.99999999990001,
                            49.00000000019992
                        ],
                        [
                            -109.18333333326666,
                            49.00000000019992
                        ],
                        [
                            -108.86111111128872,
                            47.16666666633364
                        ],
                        [
                            -108.84143888849388,
                            47.032811110744206
                        ],
                        [
                            -108.78333333316675,
                            46.633333333466624
                        ],
                        [
                            -108.75749722181928,
                            46.462094444303204
                        ],
                        [
                            -108.52888888861128,
                            45.791666666383605
                        ],
                        [
                            -107.62394166677444,
                            45.629288889071006
                        ],
                        [
                            -106.00000000029974,
                            45.237499999625356
                        ],
                        [
                            -104.76944444440556,
                            45.14999999980023
                        ],
                        [
                            -104.2500000001998,
                            45.11666666683328
                        ],
                        [
                            -103.16666666663332,
                            44.958333333216785
                        ],
                        [
                            -103.16666666663332,
                            46.449999999900115
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 727,
                "artcc": "zlc",
                "sector": "99",
                "sectype": "high",
                "Shape_Length": 18.779204620877607,
                "Shape_Area": 20.932672160314464
            }
        },
        {
            "type": "Feature",
            "id": 738,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -78.32083333379126,
                            27.000000000099988
                        ],
                        [
                            -78.05833333341656,
                            27.46666666613379
                        ],
                        [
                            -79.03333333326668,
                            27.999999999900126
                        ],
                        [
                            -79.11666666703303,
                            27.475000000049988
                        ],
                        [
                            -79.13305555586919,
                            27.291111111188854
                        ],
                        [
                            -79.38194444478029,
                            26.83305555536964
                        ],
                        [
                            -79.53972222220273,
                            26.561944443881032
                        ],
                        [
                            -80.28888888911092,
                            26.940555555694402
                        ],
                        [
                            -80.1333333337663,
                            26.5500000001
                        ],
                        [
                            -80.15000000069944,
                            26.383333332567304
                        ],
                        [
                            -80.05416666695805,
                            26.216666666833248
                        ],
                        [
                            -79.4980555562189,
                            26.060833333391656
                        ],
                        [
                            -78.63499999995003,
                            26.11694444423074
                        ],
                        [
                            -78.62027777789712,
                            26.248055554919972
                        ],
                        [
                            -78.60000000019983,
                            26.4999999993006
                        ],
                        [
                            -78.32083333379126,
                            27.000000000099988
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 738,
                "artcc": "zma",
                "sector": "01",
                "sectype": "high",
                "Shape_Length": 7.217251263812182,
                "Shape_Area": 1.9604835647597243
            }
        },
        {
            "type": "Feature",
            "id": 746,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -78.05833333341656,
                            27.46666666703311
                        ],
                        [
                            -77.9244444444555,
                            30.072222221977995
                        ],
                        [
                            -78.57027777799703,
                            30.13472222185311
                        ],
                        [
                            -78.66916666630857,
                            30.144166666358615
                        ],
                        [
                            -79.03333333326668,
                            27.999999999900126
                        ],
                        [
                            -78.05833333341656,
                            27.46666666703311
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 746,
                "artcc": "zma",
                "sector": "18",
                "sectype": "high",
                "Shape_Length": 6.643391377391302,
                "Shape_Area": 2.117456711938698
            }
        },
        {
            "type": "Feature",
            "id": 755,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -80.82388888876119,
                            25.783333332867073
                        ],
                        [
                            -80.82388888876119,
                            25.86666666663342
                        ],
                        [
                            -80.82388888876119,
                            26.074999999250622
                        ],
                        [
                            -80.82388888876119,
                            26.116666666133767
                        ],
                        [
                            -80.8902777783967,
                            26.18055555559448
                        ],
                        [
                            -81.08750000062452,
                            26.37083333259227
                        ],
                        [
                            -81.40833333391618,
                            26.649999999900103
                        ],
                        [
                            -81.57916666660833,
                            26.804166666558444
                        ],
                        [
                            -81.791666667083,
                            26.983333333166854
                        ],
                        [
                            -82.00916666700806,
                            27.531111110889128
                        ],
                        [
                            -82.20694444453045,
                            28.02999999930057
                        ],
                        [
                            -82.3833333337663,
                            27.950000000000045
                        ],
                        [
                            -82.65750000072444,
                            27.950000000000045
                        ],
                        [
                            -82.94166666658333,
                            27.950000000000045
                        ],
                        [
                            -83.17138888928577,
                            27.77611111093904
                        ],
                        [
                            -83.14861111141363,
                            27.535277777847227
                        ],
                        [
                            -83.10138888888605,
                            27.039444444005937
                        ],
                        [
                            -82.9194444449052,
                            27.00861111121384
                        ],
                        [
                            -82.80111111138865,
                            25.723888888461488
                        ],
                        [
                            -82.49972222240262,
                            25.733611111063965
                        ],
                        [
                            -82.30833333391621,
                            25.783333332867073
                        ],
                        [
                            -81.41666666693311,
                            25.783333332867073
                        ],
                        [
                            -81.0047222221528,
                            25.779166666808294
                        ],
                        [
                            -80.89166666708297,
                            25.774999999850138
                        ],
                        [
                            -80.82388888876119,
                            25.783333332867073
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 755,
                "artcc": "zma",
                "sector": "25",
                "sectype": "high",
                "Shape_Length": 7.998062372755116,
                "Shape_Area": 3.1587726082466205
            }
        },
        {
            "type": "Feature",
            "id": 757,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -80.05416666695805,
                            26.216666666833248
                        ],
                        [
                            -80.44166666708298,
                            26.10416666615879
                        ],
                        [
                            -80.36666666723289,
                            25.86666666663342
                        ],
                        [
                            -80.26666666653341,
                            25.541666666383605
                        ],
                        [
                            -80.24166666658334,
                            25.458333332617258
                        ],
                        [
                            -80.1333333337663,
                            25.27499999995007
                        ],
                        [
                            -80.10833333381629,
                            25.200000000099976
                        ],
                        [
                            -80.05833333391621,
                            24.999999999600334
                        ],
                        [
                            -79.98027777769727,
                            24.69555555514478
                        ],
                        [
                            -79.81656388931202,
                            24.046122221963003
                        ],
                        [
                            -79.80750000012489,
                            24.009999999600325
                        ],
                        [
                            -79.83305555626885,
                            24.00055555509482
                        ],
                        [
                            -79.16527777824683,
                            23.999999999800195
                        ],
                        [
                            -78.9833333333666,
                            23.999999999800195
                        ],
                        [
                            -78.5833333332667,
                            23.999999999800195
                        ],
                        [
                            -78.16666666713292,
                            23.999999999800195
                        ],
                        [
                            -78.14222222247753,
                            24.049722221603304
                        ],
                        [
                            -78.088888889011,
                            24.158888888711317
                        ],
                        [
                            -77.741666667083,
                            24.883333332867096
                        ],
                        [
                            -77.81388888956059,
                            25.63333333316683
                        ],
                        [
                            -78.50833333341654,
                            25.99999999940053
                        ],
                        [
                            -78.63499999995003,
                            26.11694444423074
                        ],
                        [
                            -79.4980555562189,
                            26.060833333391656
                        ],
                        [
                            -80.05416666695805,
                            26.216666666833248
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 757,
                "artcc": "zma",
                "sector": "40",
                "sectype": "high",
                "Shape_Length": 8.426517528537657,
                "Shape_Area": 4.5374465648959745
            }
        },
        {
            "type": "Feature",
            "id": 760,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -72.33583333334161,
                            21.385277777547458
                        ],
                        [
                            -72.22138888958557,
                            21.475555555644405
                        ],
                        [
                            -72.91583333344153,
                            21.919722221903044
                        ],
                        [
                            -73.21777777772223,
                            22.10999999980021
                        ],
                        [
                            -75.0041666671579,
                            23.230277777097797
                        ],
                        [
                            -75.17694444473028,
                            22.963888888761232
                        ],
                        [
                            -74.75000000069946,
                            22.633333332867096
                        ],
                        [
                            -73.96555555594409,
                            22.051666665983873
                        ],
                        [
                            -73.52916666740771,
                            21.7141666666584
                        ],
                        [
                            -73.25500000044963,
                            21.500833332792126
                        ],
                        [
                            -72.73333333326667,
                            21.066666666333617
                        ],
                        [
                            -72.33583333334161,
                            21.385277777547458
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 760,
                "artcc": "zma",
                "sector": "43",
                "sectype": "high",
                "Shape_Length": 7.356835931028611,
                "Shape_Area": 1.4767694451154099
            }
        },
        {
            "type": "Feature",
            "id": 767,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -73.57055555619394,
                            25.50999999930059
                        ],
                        [
                            -73.75138888958554,
                            25.8338888889611
                        ],
                        [
                            -73.81750000022481,
                            25.95027777759742
                        ],
                        [
                            -74.0969444447303,
                            26.432222221878078
                        ],
                        [
                            -75.99999999999994,
                            25.383333332767165
                        ],
                        [
                            -76.41250000007489,
                            25.096944443930965
                        ],
                        [
                            -75.99999999999994,
                            24.86444444385603
                        ],
                        [
                            -74.83555555564436,
                            24.137499999525403
                        ],
                        [
                            -74.54527777794704,
                            23.92472222185313
                        ],
                        [
                            -75.0041666671579,
                            23.230277777097797
                        ],
                        [
                            -73.21777777772223,
                            22.10999999980021
                        ],
                        [
                            -72.30166666698307,
                            23.265555554944967
                        ],
                        [
                            -72.28694444493016,
                            23.524444443656193
                        ],
                        [
                            -72.54611111173836,
                            24.942222221978
                        ],
                        [
                            -73.2444444444555,
                            24.931111110689244
                        ],
                        [
                            -73.57055555619394,
                            25.50999999930059
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 767,
                "artcc": "zma",
                "sector": "58",
                "sectype": "high",
                "Shape_Length": 13.422226013378033,
                "Shape_Area": 8.690437769266902
            }
        },
        {
            "type": "Feature",
            "id": 769,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -76.43111111098892,
                            28.131666666783303
                        ],
                        [
                            -77.00000000069946,
                            27.46666666613379
                        ],
                        [
                            -78.05833333341656,
                            27.46666666613379
                        ],
                        [
                            -78.32083333379126,
                            27.000000000099988
                        ],
                        [
                            -78.60000000019983,
                            26.4999999993006
                        ],
                        [
                            -78.62027777789712,
                            26.248055554919972
                        ],
                        [
                            -78.63499999995003,
                            26.11694444423074
                        ],
                        [
                            -78.50833333341654,
                            25.99999999940053
                        ],
                        [
                            -77.81388888956059,
                            25.63333333316683
                        ],
                        [
                            -77.30833333401614,
                            25.594444443656187
                        ],
                        [
                            -76.41250000007489,
                            25.096944443930965
                        ],
                        [
                            -75.99999999999994,
                            25.383333332767165
                        ],
                        [
                            -74.0969444447303,
                            26.432222221878078
                        ],
                        [
                            -74.32138888898601,
                            26.801666666383596
                        ],
                        [
                            -74.38888888921082,
                            26.919999999900142
                        ],
                        [
                            -74.68416666725784,
                            27.433333333166843
                        ],
                        [
                            -74.88222222287726,
                            27.77472222225282
                        ],
                        [
                            -75.18333333376631,
                            27.77583333284207
                        ],
                        [
                            -75.70944444510502,
                            27.77638888903607
                        ],
                        [
                            -76.30833333331663,
                            27.77472222225282
                        ],
                        [
                            -76.43111111098892,
                            28.131666666783303
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 769,
                "artcc": "zma",
                "sector": "59",
                "sectype": "high",
                "Shape_Length": 11.949811408999416,
                "Shape_Area": 8.14321196123472
            }
        },
        {
            "type": "Feature",
            "id": 771,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -74.83555555564436,
                            24.137499999525403
                        ],
                        [
                            -75.99999999999994,
                            24.86444444385603
                        ],
                        [
                            -76.41250000007489,
                            25.096944443930965
                        ],
                        [
                            -77.30833333401614,
                            25.594444443656187
                        ],
                        [
                            -77.81388888956059,
                            25.63333333316683
                        ],
                        [
                            -77.741666667083,
                            24.883333332867096
                        ],
                        [
                            -78.088888889011,
                            24.158888888711317
                        ],
                        [
                            -78.14222222247753,
                            24.049722221603304
                        ],
                        [
                            -78.16666666713292,
                            23.999999999800195
                        ],
                        [
                            -78.0000000004996,
                            23.999999999800195
                        ],
                        [
                            -77.75000000009987,
                            23.83333333316682
                        ],
                        [
                            -77.42583333324171,
                            23.60916666610882
                        ],
                        [
                            -77.37833333351648,
                            23.657222222027997
                        ],
                        [
                            -75.99999999999994,
                            22.690277777097776
                        ],
                        [
                            -75.55333333356646,
                            22.370555555294686
                        ],
                        [
                            -75.17694444473028,
                            22.963888888761232
                        ],
                        [
                            -75.0041666671579,
                            23.230277777097797
                        ],
                        [
                            -74.54527777794704,
                            23.92472222185313
                        ],
                        [
                            -74.83555555564436,
                            24.137499999525403
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 771,
                "artcc": "zma",
                "sector": "60",
                "sectype": "high",
                "Shape_Length": 10.485969512360803,
                "Shape_Area": 5.889989582876713
            }
        },
        {
            "type": "Feature",
            "id": 774,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -68.34999999999997,
                            20.499999999600334
                        ],
                        [
                            -68.28972222220273,
                            20.566111111138923
                        ],
                        [
                            -71.93555555604405,
                            23.27333333266722
                        ],
                        [
                            -72.28694444493016,
                            23.524444443656193
                        ],
                        [
                            -72.30166666698307,
                            23.265555554944967
                        ],
                        [
                            -73.21777777772223,
                            22.10999999980021
                        ],
                        [
                            -72.91583333344153,
                            21.919722221903044
                        ],
                        [
                            -72.22138888958557,
                            21.475555555644405
                        ],
                        [
                            -71.74222222267741,
                            21.164999999350528
                        ],
                        [
                            -71.61722222292718,
                            21.08333333326675
                        ],
                        [
                            -70.95611111113885,
                            20.418333332617237
                        ],
                        [
                            -70.80399166658214,
                            20.416666666733306
                        ],
                        [
                            -70.80399166658214,
                            20.43333333276712
                        ],
                        [
                            -70.78732500054832,
                            20.43333333276712
                        ],
                        [
                            -70.78732500054832,
                            20.416666666733306
                        ],
                        [
                            -70.5000000001998,
                            20.416666666733306
                        ],
                        [
                            -70.47527777834676,
                            20.403611110564327
                        ],
                        [
                            -70.46750000062451,
                            20.416666666733306
                        ],
                        [
                            -70.42527777844668,
                            20.393611110764198
                        ],
                        [
                            -70.43277777807197,
                            20.381111110789163
                        ],
                        [
                            -70.35259999989006,
                            20.338027777859736
                        ],
                        [
                            -70.34164166722911,
                            20.351413888839943
                        ],
                        [
                            -70.32605000040718,
                            20.34303055531592
                        ],
                        [
                            -70.33699999995002,
                            20.329922221533366
                        ],
                        [
                            -70.25000000069946,
                            20.283333333066935
                        ],
                        [
                            -70.0676555562589,
                            20.1793055549075
                        ],
                        [
                            -70.056141667404,
                            20.192663888527704
                        ],
                        [
                            -70.04110555587664,
                            20.183724999709
                        ],
                        [
                            -70.05261111161349,
                            20.170616666825765
                        ],
                        [
                            -69.73727777834677,
                            19.989777777422546
                        ],
                        [
                            -69.72735555551446,
                            20.003466666753297
                        ],
                        [
                            -69.6562361114074,
                            19.96209722220908
                        ],
                        [
                            -69.66594999999245,
                            19.948486110745478
                        ],
                        [
                            -69.15000000019978,
                            19.649999999500437
                        ],
                        [
                            -68.34999999999997,
                            20.499999999600334
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 774,
                "artcc": "zma",
                "sector": "62",
                "sectype": "high",
                "Shape_Length": 12.977948655972627,
                "Shape_Area": 7.31165141176292
            }
        },
        {
            "type": "Feature",
            "id": 777,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -80.44166666708298,
                            26.10416666615879
                        ],
                        [
                            -80.49999999999994,
                            26.233333332867062
                        ],
                        [
                            -80.61250000067446,
                            26.383333332567304
                        ],
                        [
                            -80.65416666665828,
                            26.513888888861175
                        ],
                        [
                            -80.7955750003859,
                            26.88392777714523
                        ],
                        [
                            -80.99999999990001,
                            27.349999999400495
                        ],
                        [
                            -81.50000000069946,
                            28.34638888843648
                        ],
                        [
                            -81.5947222220529,
                            28.55611111063928
                        ],
                        [
                            -81.73416666665833,
                            28.864166666758308
                        ],
                        [
                            -81.78611111143863,
                            28.589722221703198
                        ],
                        [
                            -81.86222222277735,
                            28.184999999350566
                        ],
                        [
                            -81.9119444445804,
                            28.162499999575346
                        ],
                        [
                            -82.20694444453045,
                            28.02999999930057
                        ],
                        [
                            -82.00916666700806,
                            27.531111110889128
                        ],
                        [
                            -81.791666667083,
                            26.983333333166854
                        ],
                        [
                            -81.57916666660833,
                            26.804166666558444
                        ],
                        [
                            -81.40833333391618,
                            26.649999999900103
                        ],
                        [
                            -81.08750000062452,
                            26.37083333259227
                        ],
                        [
                            -80.8902777783967,
                            26.18055555559448
                        ],
                        [
                            -80.82388888876119,
                            26.116666666133767
                        ],
                        [
                            -80.82388888876119,
                            26.074999999250622
                        ],
                        [
                            -80.44166666708298,
                            26.10416666615879
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 777,
                "artcc": "zma",
                "sector": "64",
                "sectype": "high",
                "Shape_Length": 6.9740892296852754,
                "Shape_Area": 1.7724501004218045
            }
        },
        {
            "type": "Feature",
            "id": 778,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -80.99999999990001,
                            27.350000000299815
                        ],
                        [
                            -80.7955750003859,
                            26.88392777804455
                        ],
                        [
                            -80.65416666665828,
                            26.513888888861175
                        ],
                        [
                            -80.61249999977514,
                            26.383333333466624
                        ],
                        [
                            -80.49999999999994,
                            26.233333332867062
                        ],
                        [
                            -80.44166666708298,
                            26.10416666705811
                        ],
                        [
                            -80.05416666695805,
                            26.216666666833248
                        ],
                        [
                            -80.14999999980012,
                            26.383333333466624
                        ],
                        [
                            -80.13333333286698,
                            26.5500000001
                        ],
                        [
                            -80.28888888911092,
                            26.940555555694402
                        ],
                        [
                            -80.98611111123876,
                            28.727777777622407
                        ],
                        [
                            -81.49999999980014,
                            28.3463888893358
                        ],
                        [
                            -80.99999999990001,
                            27.350000000299815
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 778,
                "artcc": "zma",
                "sector": "65",
                "sectype": "high",
                "Shape_Length": 6.228152852812906,
                "Shape_Area": 1.342856149152037
            }
        },
        {
            "type": "Feature",
            "id": 798,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -95.3666666669331,
                            35.87083333339166
                        ],
                        [
                            -94.99999999980014,
                            35.65416666685826
                        ],
                        [
                            -94.99999999980014,
                            35.383333333466624
                        ],
                        [
                            -94.99999999980014,
                            35.066666667133006
                        ],
                        [
                            -94.92499999994999,
                            34.933333333466635
                        ],
                        [
                            -94.81666666713295,
                            34.755555555544504
                        ],
                        [
                            -94.78333333326668,
                            34.69166666698317
                        ],
                        [
                            -94.53333333286696,
                            34.53333333336667
                        ],
                        [
                            -93.06972222250255,
                            35.41388888906101
                        ],
                        [
                            -93.10055555529459,
                            36.222777777572446
                        ],
                        [
                            -92.64388888906092,
                            36.82666666703312
                        ],
                        [
                            -92.86583333314178,
                            36.789999999600354
                        ],
                        [
                            -93.13749999992501,
                            36.74416666665837
                        ],
                        [
                            -93.24999999970021,
                            36.72499999955039
                        ],
                        [
                            -93.53333333306682,
                            36.66666666663343
                        ],
                        [
                            -93.74999999960028,
                            36.62499999975023
                        ],
                        [
                            -93.7869444442307,
                            36.6183333335166
                        ],
                        [
                            -94.40833333311679,
                            36.49166666698312
                        ],
                        [
                            -94.68333333346652,
                            36.43333333316684
                        ],
                        [
                            -95.3666666669331,
                            35.87083333339166
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 798,
                "artcc": "zme",
                "sector": "19",
                "sectype": "high",
                "Shape_Length": 7.979609484809015,
                "Shape_Area": 3.340168556762993
            }
        },
        {
            "type": "Feature",
            "id": 799,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -93.15833333291692,
                            33.94999999970025
                        ],
                        [
                            -92.90000000039964,
                            34.13333333326676
                        ],
                        [
                            -92.18333333306686,
                            34.50000000039972
                        ],
                        [
                            -91.04694444413076,
                            34.76666666683326
                        ],
                        [
                            -91.22638888883614,
                            35.53694444483034
                        ],
                        [
                            -91.88500000044962,
                            35.700555555994185
                        ],
                        [
                            -93.06972222250255,
                            35.41388888906101
                        ],
                        [
                            -94.53333333286696,
                            34.53333333336667
                        ],
                        [
                            -93.99999999999994,
                            34.26666666693319
                        ],
                        [
                            -93.65833333371631,
                            34.08888888901106
                        ],
                        [
                            -93.54166666698308,
                            34.0333333334666
                        ],
                        [
                            -93.15833333291692,
                            33.94999999970025
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 799,
                "artcc": "zme",
                "sector": "20",
                "sectype": "high",
                "Shape_Length": 8.188517233889065,
                "Shape_Area": 3.3072451007916417
            }
        },
        {
            "type": "Feature",
            "id": 800,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -92.90000000039964,
                            34.13333333326676
                        ],
                        [
                            -92.18333333396617,
                            34.4999999995004
                        ],
                        [
                            -91.04694444503008,
                            34.76666666683326
                        ],
                        [
                            -90.69388888936072,
                            34.84944444440566
                        ],
                        [
                            -90.83000000039965,
                            35.3397222217032
                        ],
                        [
                            -91.22638888883614,
                            35.53694444393102
                        ],
                        [
                            -91.88500000044962,
                            35.700555555094866
                        ],
                        [
                            -93.06972222250255,
                            35.413888888161694
                        ],
                        [
                            -94.53333333376628,
                            34.53333333336667
                        ],
                        [
                            -93.99999999999994,
                            34.26666666603387
                        ],
                        [
                            -93.65833333371631,
                            34.08888888901106
                        ],
                        [
                            -93.54166666698308,
                            34.03333333256728
                        ],
                        [
                            -93.15833333381624,
                            33.94999999970025
                        ],
                        [
                            -92.90000000039964,
                            34.13333333326676
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 800,
                "artcc": "zme",
                "sector": "21",
                "sectype": "high",
                "Shape_Length": 8.71180670990776,
                "Shape_Area": 3.5343957936904817
            }
        },
        {
            "type": "Feature",
            "id": 801,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -95.3666666669331,
                            35.87083333339166
                        ],
                        [
                            -94.99999999980014,
                            35.65416666685826
                        ],
                        [
                            -94.99999999980014,
                            35.383333333466624
                        ],
                        [
                            -94.99999999980014,
                            35.066666667133006
                        ],
                        [
                            -94.92499999994999,
                            34.933333333466635
                        ],
                        [
                            -94.81666666713295,
                            34.755555555544504
                        ],
                        [
                            -94.78333333326668,
                            34.69166666698317
                        ],
                        [
                            -94.53333333286696,
                            34.53333333336667
                        ],
                        [
                            -93.06972222250255,
                            35.41388888906101
                        ],
                        [
                            -93.10055555529459,
                            36.222777777572446
                        ],
                        [
                            -92.64388888906092,
                            36.82666666703312
                        ],
                        [
                            -92.86583333314178,
                            36.789999999600354
                        ],
                        [
                            -93.13749999992501,
                            36.74416666665837
                        ],
                        [
                            -93.24999999970021,
                            36.72499999955039
                        ],
                        [
                            -93.53333333306682,
                            36.66666666663343
                        ],
                        [
                            -93.74999999960028,
                            36.62499999975023
                        ],
                        [
                            -93.7869444442307,
                            36.6183333335166
                        ],
                        [
                            -94.40833333311679,
                            36.49166666698312
                        ],
                        [
                            -94.68333333346652,
                            36.43333333316684
                        ],
                        [
                            -95.3666666669331,
                            35.87083333339166
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 801,
                "artcc": "zme",
                "sector": "22",
                "sectype": "high",
                "Shape_Length": 7.979609484809015,
                "Shape_Area": 3.340168556762993
            }
        },
        {
            "type": "Feature",
            "id": 802,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -89.40805555601906,
                            35.631111110889094
                        ],
                        [
                            -89.05722222242758,
                            36.09000000009996
                        ],
                        [
                            -88.47111111138867,
                            36.84694444383109
                        ],
                        [
                            -88.03861111171341,
                            37.00972222160328
                        ],
                        [
                            -87.63333333316677,
                            37.391666666083836
                        ],
                        [
                            -87.7930555554695,
                            37.46999999950043
                        ],
                        [
                            -88.3166666666333,
                            37.72499999935053
                        ],
                        [
                            -88.83333333346656,
                            37.53333333276714
                        ],
                        [
                            -89.04999999999995,
                            37.48333333286706
                        ],
                        [
                            -89.18333333366638,
                            37.45833333291705
                        ],
                        [
                            -89.32638888903597,
                            37.42722222202798
                        ],
                        [
                            -90.03444444435559,
                            37.27083333329176
                        ],
                        [
                            -90.33555555614396,
                            37.20305555496992
                        ],
                        [
                            -90.38666666663335,
                            36.231111110589325
                        ],
                        [
                            -90.35388888896102,
                            35.622222221678214
                        ],
                        [
                            -89.40805555601906,
                            35.631111110889094
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 802,
                "artcc": "zme",
                "sector": "25",
                "sectype": "high",
                "Shape_Length": 7.932472055185695,
                "Shape_Area": 3.242090894860709
            }
        },
        {
            "type": "Feature",
            "id": 803,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -89.40805555601906,
                            35.631111110889094
                        ],
                        [
                            -89.28333333346654,
                            35.10000000009995
                        ],
                        [
                            -88.35472222185302,
                            35.15277777737259
                        ],
                        [
                            -87.23277777777218,
                            35.45527777784724
                        ],
                        [
                            -87.50750000002495,
                            35.810277777497504
                        ],
                        [
                            -87.46833333331665,
                            36.17916666670834
                        ],
                        [
                            -87.51666666643348,
                            36.249999999600334
                        ],
                        [
                            -88.03861111081409,
                            37.0097222225026
                        ],
                        [
                            -88.47111111138867,
                            36.84694444473041
                        ],
                        [
                            -89.05722222242758,
                            36.09000000009996
                        ],
                        [
                            -89.40805555601906,
                            35.631111110889094
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 803,
                "artcc": "zme",
                "sector": "26",
                "sectype": "high",
                "Shape_Length": 6.462105779813852,
                "Shape_Area": 2.5368223006318016
            }
        },
        {
            "type": "Feature",
            "id": 804,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -90.38666666663335,
                            36.231111110589325
                        ],
                        [
                            -90.33555555614396,
                            37.20305555496992
                        ],
                        [
                            -90.38194444438057,
                            37.19222222177814
                        ],
                        [
                            -90.41694444503008,
                            37.18416666595891
                        ],
                        [
                            -90.5666666666333,
                            37.14999999960037
                        ],
                        [
                            -90.88277777767229,
                            37.10333333326679
                        ],
                        [
                            -92.51488055609275,
                            36.8478777773376
                        ],
                        [
                            -92.64388888906092,
                            36.826666666133804
                        ],
                        [
                            -93.10055555619391,
                            36.222777777572446
                        ],
                        [
                            -93.06972222250255,
                            35.413888888161694
                        ],
                        [
                            -91.88500000044962,
                            35.700555555094866
                        ],
                        [
                            -91.22638888883614,
                            35.53694444393102
                        ],
                        [
                            -90.83000000039965,
                            35.3397222217032
                        ],
                        [
                            -90.35388888896102,
                            35.622222221678214
                        ],
                        [
                            -90.38666666663335,
                            36.231111110589325
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 804,
                "artcc": "zme",
                "sector": "27",
                "sectype": "high",
                "Shape_Length": 8.38292191734632,
                "Shape_Area": 3.7799251213361362
            }
        },
        {
            "type": "Feature",
            "id": 805,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -90.47444444455544,
                            34.69277777757242
                        ],
                        [
                            -90.28027777779715,
                            34.70749999962533
                        ],
                        [
                            -89.84166666718289,
                            34.77499999985014
                        ],
                        [
                            -89.28333333346654,
                            35.10000000009995
                        ],
                        [
                            -89.40805555601906,
                            35.631111110889094
                        ],
                        [
                            -89.05722222242758,
                            36.09000000009996
                        ],
                        [
                            -89.28083333329164,
                            36.229444443806074
                        ],
                        [
                            -89.3533333338662,
                            36.230000000000075
                        ],
                        [
                            -90.38666666663335,
                            36.231111110589325
                        ],
                        [
                            -91.22638888883614,
                            35.53694444393102
                        ],
                        [
                            -91.04694444503008,
                            34.76666666683326
                        ],
                        [
                            -90.96777777822183,
                            34.65388888896109
                        ],
                        [
                            -90.47444444455544,
                            34.69277777757242
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 805,
                "artcc": "zme",
                "sector": "28",
                "sectype": "high",
                "Shape_Length": 6.290144940282128,
                "Shape_Area": 2.469535531647771
            }
        },
        {
            "type": "Feature",
            "id": 806,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -89.84166666628357,
                            34.77499999985014
                        ],
                        [
                            -90.28027777779715,
                            34.70749999962533
                        ],
                        [
                            -90.28333333326668,
                            34.650000000099965
                        ],
                        [
                            -90.54166666668328,
                            34.16666666713303
                        ],
                        [
                            -89.99999999990001,
                            34.06666666643355
                        ],
                        [
                            -90.03333333286696,
                            33.64166666638357
                        ],
                        [
                            -90.03333333286696,
                            33.37916666690819
                        ],
                        [
                            -88.40833333341658,
                            33.38333333296697
                        ],
                        [
                            -88.03333333326668,
                            33.40833333291704
                        ],
                        [
                            -87.61388888886108,
                            33.5124999996753
                        ],
                        [
                            -87.59166666628357,
                            33.67083333329174
                        ],
                        [
                            -87.56666666633356,
                            33.890277778097015
                        ],
                        [
                            -88.74999999970021,
                            34.383333333666485
                        ],
                        [
                            -89.58333333286697,
                            34.68333333306691
                        ],
                        [
                            -89.84166666628357,
                            34.77499999985014
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 806,
                "artcc": "zme",
                "sector": "30",
                "sectype": "high",
                "Shape_Length": 7.544540751144365,
                "Shape_Area": 2.6322407415447158
            }
        },
        {
            "type": "Feature",
            "id": 807,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -87.24999999999994,
                            34.100000000299815
                        ],
                        [
                            -87.12222222197795,
                            34.24166666698312
                        ],
                        [
                            -86.99999999960028,
                            34.36666666673335
                        ],
                        [
                            -86.99999999960028,
                            34.68333333306691
                        ],
                        [
                            -86.99999999960028,
                            35.10833333311689
                        ],
                        [
                            -86.99999999960028,
                            35.33333333356654
                        ],
                        [
                            -87.23277777777218,
                            35.45527777784724
                        ],
                        [
                            -88.35472222185302,
                            35.15277777737259
                        ],
                        [
                            -89.28333333346654,
                            35.10000000009995
                        ],
                        [
                            -89.84166666628357,
                            34.77499999985014
                        ],
                        [
                            -89.58333333286697,
                            34.68333333306691
                        ],
                        [
                            -88.74999999970021,
                            34.383333333666485
                        ],
                        [
                            -87.56666666633356,
                            33.890277778097015
                        ],
                        [
                            -87.55000000029975,
                            34.01666666653347
                        ],
                        [
                            -87.24999999999994,
                            34.100000000299815
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 807,
                "artcc": "zme",
                "sector": "31",
                "sectype": "high",
                "Shape_Length": 7.213797994496459,
                "Shape_Area": 2.4865965656447924
            }
        },
        {
            "type": "Feature",
            "id": 808,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -88.40833333341658,
                            33.38333333296697
                        ],
                        [
                            -88.03333333326668,
                            33.40833333291704
                        ],
                        [
                            -87.61388888886108,
                            33.5124999996753
                        ],
                        [
                            -87.59166666718289,
                            33.67083333329174
                        ],
                        [
                            -87.56666666723288,
                            33.890277777197696
                        ],
                        [
                            -87.55000000029975,
                            34.01666666653347
                        ],
                        [
                            -87.24999999999994,
                            34.099999999400495
                        ],
                        [
                            -87.12222222287727,
                            34.2416666660838
                        ],
                        [
                            -87.0000000004996,
                            34.36666666673335
                        ],
                        [
                            -87.0000000004996,
                            34.68333333306691
                        ],
                        [
                            -87.0000000004996,
                            35.10833333311689
                        ],
                        [
                            -87.0000000004996,
                            35.33333333266722
                        ],
                        [
                            -87.23277777777218,
                            35.45527777784724
                        ],
                        [
                            -88.35472222275234,
                            35.15277777737259
                        ],
                        [
                            -89.28333333346654,
                            35.10000000009995
                        ],
                        [
                            -89.84166666718289,
                            34.77499999985014
                        ],
                        [
                            -90.28027777779715,
                            34.70749999962533
                        ],
                        [
                            -90.28333333326668,
                            34.650000000099965
                        ],
                        [
                            -90.54166666668328,
                            34.16666666623371
                        ],
                        [
                            -89.99999999990001,
                            34.06666666643355
                        ],
                        [
                            -90.03333333376628,
                            33.64166666638357
                        ],
                        [
                            -90.03333333376628,
                            33.37916666600887
                        ],
                        [
                            -88.40833333341658,
                            33.38333333296697
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 808,
                "artcc": "zme",
                "sector": "32",
                "sectype": "high",
                "Shape_Length": 9.87484301717826,
                "Shape_Area": 5.1188373076023215
            }
        },
        {
            "type": "Feature",
            "id": 809,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -89.32638888903597,
                            37.42722222202798
                        ],
                        [
                            -89.35333333296688,
                            36.230000000000075
                        ],
                        [
                            -89.40805555601906,
                            35.631111110889094
                        ],
                        [
                            -89.28333333346654,
                            35.10000000009995
                        ],
                        [
                            -88.35472222185302,
                            35.15277777737259
                        ],
                        [
                            -87.23277777777218,
                            35.45527777784724
                        ],
                        [
                            -87.50750000002495,
                            35.810277777497504
                        ],
                        [
                            -87.46833333331665,
                            36.17916666670834
                        ],
                        [
                            -87.51666666643348,
                            36.249999999600334
                        ],
                        [
                            -88.03861111081409,
                            37.0097222225026
                        ],
                        [
                            -87.63333333316677,
                            37.391666666983156
                        ],
                        [
                            -87.7930555554695,
                            37.47000000039975
                        ],
                        [
                            -88.3166666666333,
                            37.72500000024985
                        ],
                        [
                            -88.83333333346656,
                            37.53333333366646
                        ],
                        [
                            -89.04999999999995,
                            37.48333333286706
                        ],
                        [
                            -89.18333333366638,
                            37.45833333291705
                        ],
                        [
                            -89.32638888903597,
                            37.42722222202798
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 809,
                "artcc": "zme",
                "sector": "33",
                "sectype": "high",
                "Shape_Length": 8.636610297189218,
                "Shape_Area": 4.077629437595898
            }
        },
        {
            "type": "Feature",
            "id": 810,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -91.22638888883614,
                            35.53694444483034
                        ],
                        [
                            -91.04694444413076,
                            34.76666666683326
                        ],
                        [
                            -90.96777777822183,
                            34.65388888896109
                        ],
                        [
                            -90.47444444455544,
                            34.69277777757242
                        ],
                        [
                            -90.28027777779715,
                            34.70749999962533
                        ],
                        [
                            -89.84166666628357,
                            34.77499999985014
                        ],
                        [
                            -89.28333333346654,
                            35.10000000009995
                        ],
                        [
                            -89.40805555601906,
                            35.631111110889094
                        ],
                        [
                            -89.35333333296688,
                            36.230000000000075
                        ],
                        [
                            -90.38666666663335,
                            36.231111111488644
                        ],
                        [
                            -91.22638888883614,
                            35.53694444483034
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 810,
                "artcc": "zme",
                "sector": "34",
                "sectype": "high",
                "Shape_Length": 5.977863452904882,
                "Shape_Area": 2.372043403141146
            }
        },
        {
            "type": "Feature",
            "id": 811,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -92.64388888906092,
                            36.82666666703312
                        ],
                        [
                            -93.10055555529459,
                            36.222777777572446
                        ],
                        [
                            -93.06972222250255,
                            35.41388888906101
                        ],
                        [
                            -91.88500000044962,
                            35.700555555994185
                        ],
                        [
                            -91.22638888883614,
                            35.53694444483034
                        ],
                        [
                            -90.38666666663335,
                            36.231111111488644
                        ],
                        [
                            -89.35333333296688,
                            36.230000000000075
                        ],
                        [
                            -89.32638888903597,
                            37.42722222202798
                        ],
                        [
                            -90.03444444435559,
                            37.27083333329176
                        ],
                        [
                            -90.33555555524464,
                            37.20305555586924
                        ],
                        [
                            -90.38194444438057,
                            37.19222222177814
                        ],
                        [
                            -90.41694444413076,
                            37.18416666685823
                        ],
                        [
                            -90.5666666666333,
                            37.14999999960037
                        ],
                        [
                            -90.88277777767229,
                            37.10333333326679
                        ],
                        [
                            -92.51488055519343,
                            36.84787777823692
                        ],
                        [
                            -92.64388888906092,
                            36.82666666703312
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 811,
                "artcc": "zme",
                "sector": "35",
                "sectype": "high",
                "Shape_Length": 10.15762883343516,
                "Shape_Area": 4.513688005284563
            }
        },
        {
            "type": "Feature",
            "id": 815,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -90.03333333376628,
                            33.64166666638357
                        ],
                        [
                            -89.99999999990001,
                            34.06666666643355
                        ],
                        [
                            -90.54166666668328,
                            34.16666666623371
                        ],
                        [
                            -92.0833333332667,
                            33.59027777779727
                        ],
                        [
                            -91.9000000005995,
                            33.21666666633365
                        ],
                        [
                            -91.53333333346654,
                            32.43333333306691
                        ],
                        [
                            -91.30833333391621,
                            31.91249999927561
                        ],
                        [
                            -90.90833333381624,
                            31.799999999500415
                        ],
                        [
                            -90.80000000009989,
                            31.766666666533467
                        ],
                        [
                            -90.65833333341658,
                            31.72916666660842
                        ],
                        [
                            -90.3458333331418,
                            31.64999999980023
                        ],
                        [
                            -90.03333333376628,
                            31.636111111138916
                        ],
                        [
                            -89.93333333396617,
                            31.633333332867096
                        ],
                        [
                            -89.85000000019983,
                            31.63055555549454
                        ],
                        [
                            -89.58333333376629,
                            31.608333332917027
                        ],
                        [
                            -89.43333333316673,
                            31.591666665983894
                        ],
                        [
                            -89.16666666673325,
                            31.57499999995008
                        ],
                        [
                            -89.00833333401613,
                            31.550000000000068
                        ],
                        [
                            -88.90000000029971,
                            31.54722222172819
                        ],
                        [
                            -88.32500000054955,
                            31.5166666661338
                        ],
                        [
                            -88.34722222222774,
                            32.06944444420577
                        ],
                        [
                            -88.34444444485518,
                            32.272222222077914
                        ],
                        [
                            -88.34722222222774,
                            32.33055555499493
                        ],
                        [
                            -88.11111111138865,
                            32.508333332917005
                        ],
                        [
                            -87.99166666728286,
                            32.59166666668335
                        ],
                        [
                            -87.94583333344156,
                            32.62777777792218
                        ],
                        [
                            -87.8500000005995,
                            32.69722222212789
                        ],
                        [
                            -87.98611111163848,
                            33.04305555536962
                        ],
                        [
                            -87.80833333371635,
                            33.183333333366704
                        ],
                        [
                            -87.72499999995,
                            33.24166666628366
                        ],
                        [
                            -87.67416666665832,
                            33.286111110539366
                        ],
                        [
                            -87.63333333316677,
                            33.31666666613381
                        ],
                        [
                            -87.61666666713296,
                            33.445833332842085
                        ],
                        [
                            -87.61388888886108,
                            33.5124999996753
                        ],
                        [
                            -88.03333333326668,
                            33.40833333291704
                        ],
                        [
                            -88.40833333341658,
                            33.38333333296697
                        ],
                        [
                            -90.03333333376628,
                            33.37916666600887
                        ],
                        [
                            -90.03333333376628,
                            33.64166666638357
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 815,
                "artcc": "zme",
                "sector": "43",
                "sectype": "high",
                "Shape_Length": 12.63878696359091,
                "Shape_Area": 7.229426504291715
            }
        },
        {
            "type": "Feature",
            "id": 816,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -90.54166666668328,
                            34.16666666623371
                        ],
                        [
                            -90.28333333326668,
                            34.650000000099965
                        ],
                        [
                            -90.28027777779715,
                            34.70749999962533
                        ],
                        [
                            -90.69388888936072,
                            34.84944444440566
                        ],
                        [
                            -91.04694444503008,
                            34.76666666683326
                        ],
                        [
                            -92.18333333396617,
                            34.4999999995004
                        ],
                        [
                            -92.90000000039964,
                            34.13333333326676
                        ],
                        [
                            -93.15833333381624,
                            33.94999999970025
                        ],
                        [
                            -92.99166666718293,
                            33.82499999995008
                        ],
                        [
                            -92.91666666733278,
                            33.7666666661338
                        ],
                        [
                            -92.68111111178837,
                            33.59388888856142
                        ],
                        [
                            -92.0833333332667,
                            33.59027777779727
                        ],
                        [
                            -90.54166666668328,
                            34.16666666623371
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 816,
                "artcc": "zme",
                "sector": "44",
                "sectype": "high",
                "Shape_Length": 6.533749537722803,
                "Shape_Area": 1.9977081798000291
            }
        },
        {
            "type": "Feature",
            "id": 817,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -92.0833333332667,
                            33.59027777779727
                        ],
                        [
                            -91.89999999970019,
                            33.21666666633365
                        ],
                        [
                            -91.53333333346654,
                            32.43333333306691
                        ],
                        [
                            -91.30833333301689,
                            31.91250000017493
                        ],
                        [
                            -90.90833333291692,
                            31.800000000399734
                        ],
                        [
                            -90.80000000009989,
                            31.766666666533467
                        ],
                        [
                            -90.65833333341658,
                            31.72916666660842
                        ],
                        [
                            -90.3458333331418,
                            31.64999999980023
                        ],
                        [
                            -90.03333333286696,
                            31.636111111138916
                        ],
                        [
                            -90.03333333286696,
                            33.37916666690819
                        ],
                        [
                            -90.03333333286696,
                            33.64166666638357
                        ],
                        [
                            -89.99999999990001,
                            34.06666666643355
                        ],
                        [
                            -90.54166666668328,
                            34.16666666713303
                        ],
                        [
                            -92.0833333332667,
                            33.59027777779727
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 817,
                "artcc": "zme",
                "sector": "45",
                "sectype": "high",
                "Shape_Length": 7.787590153047431,
                "Shape_Area": 3.7072251170994277
            }
        },
        {
            "type": "Feature",
            "id": 818,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -90.03333333286696,
                            31.636111111138916
                        ],
                        [
                            -89.93333333306686,
                            31.633333332867096
                        ],
                        [
                            -89.85000000019983,
                            31.63055555549454
                        ],
                        [
                            -89.58333333286697,
                            31.608333332917027
                        ],
                        [
                            -89.43333333316673,
                            31.591666666883214
                        ],
                        [
                            -89.16666666673325,
                            31.57499999995008
                        ],
                        [
                            -89.00833333311681,
                            31.550000000000068
                        ],
                        [
                            -88.90000000029971,
                            31.54722222262751
                        ],
                        [
                            -88.32499999965023,
                            31.51666666703312
                        ],
                        [
                            -88.34722222222774,
                            32.06944444420577
                        ],
                        [
                            -88.34444444395587,
                            32.272222222077914
                        ],
                        [
                            -88.34722222222774,
                            32.33055555589425
                        ],
                        [
                            -88.11111111138865,
                            32.508333332917005
                        ],
                        [
                            -87.99166666638354,
                            32.59166666668335
                        ],
                        [
                            -87.94583333344156,
                            32.62777777792218
                        ],
                        [
                            -87.84999999970017,
                            32.69722222212789
                        ],
                        [
                            -87.98611111073916,
                            33.04305555536962
                        ],
                        [
                            -87.80833333371635,
                            33.183333333366704
                        ],
                        [
                            -87.72499999995,
                            33.24166666628366
                        ],
                        [
                            -87.67416666665832,
                            33.286111111438686
                        ],
                        [
                            -87.63333333316677,
                            33.31666666703313
                        ],
                        [
                            -87.61666666713296,
                            33.445833333741405
                        ],
                        [
                            -87.61388888886108,
                            33.5124999996753
                        ],
                        [
                            -88.03333333326668,
                            33.40833333291704
                        ],
                        [
                            -88.40833333341658,
                            33.38333333296697
                        ],
                        [
                            -90.03333333286696,
                            33.37916666690819
                        ],
                        [
                            -90.03333333286696,
                            31.636111111138916
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 818,
                "artcc": "zme",
                "sector": "46",
                "sectype": "high",
                "Shape_Length": 8.337307919759734,
                "Shape_Area": 3.5222013877522387
            }
        },
        {
            "type": "Feature",
            "id": 820,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -87.63333333316677,
                            37.391666666083836
                        ],
                        [
                            -88.03861111171341,
                            37.00972222160328
                        ],
                        [
                            -87.5166666673328,
                            36.249999999600334
                        ],
                        [
                            -87.46833333331665,
                            36.17916666670834
                        ],
                        [
                            -87.50750000002495,
                            35.810277777497504
                        ],
                        [
                            -87.23277777777218,
                            35.45527777784724
                        ],
                        [
                            -87.0000000004996,
                            35.33333333266722
                        ],
                        [
                            -86.94194444478029,
                            35.343888888661354
                        ],
                        [
                            -86.00000000069946,
                            35.51666666623373
                        ],
                        [
                            -85.28333333336661,
                            35.6499999999001
                        ],
                        [
                            -85.32083333329166,
                            35.824999999550414
                        ],
                        [
                            -85.40000000009991,
                            36.18333333276712
                        ],
                        [
                            -85.58333333366636,
                            36.900000000099965
                        ],
                        [
                            -85.96361111136366,
                            37.1666666665335
                        ],
                        [
                            -86.00000000069946,
                            37.19444444385607
                        ],
                        [
                            -86.15000000039964,
                            37.29999999930055
                        ],
                        [
                            -86.4833333336664,
                            37.29999999930055
                        ],
                        [
                            -86.91666666673325,
                            37.288888888911174
                        ],
                        [
                            -87.39722222232768,
                            37.27499999935054
                        ],
                        [
                            -87.63333333316677,
                            37.391666666083836
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 820,
                "artcc": "zme",
                "sector": "61",
                "sectype": "high",
                "Shape_Length": 7.8829245865879045,
                "Shape_Area": 3.9007228394467313
            }
        },
        {
            "type": "Feature",
            "id": 821,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -87.51666666643348,
                            36.249999999600334
                        ],
                        [
                            -87.46833333331665,
                            36.17916666670834
                        ],
                        [
                            -87.50750000002495,
                            35.810277777497504
                        ],
                        [
                            -87.23277777777218,
                            35.45527777784724
                        ],
                        [
                            -86.99999999960028,
                            35.33333333356654
                        ],
                        [
                            -86.94194444478029,
                            35.343888888661354
                        ],
                        [
                            -85.99999999980014,
                            35.51666666713305
                        ],
                        [
                            -85.28333333336661,
                            35.6499999999001
                        ],
                        [
                            -85.32083333329166,
                            35.824999999550414
                        ],
                        [
                            -85.40000000009991,
                            36.18333333366644
                        ],
                        [
                            -85.58333333366636,
                            36.900000000099965
                        ],
                        [
                            -86.99999999960028,
                            36.36666666633363
                        ],
                        [
                            -87.29999999990002,
                            36.249999999600334
                        ],
                        [
                            -87.51666666643348,
                            36.249999999600334
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 821,
                "artcc": "zme",
                "sector": "62",
                "sectype": "high",
                "Shape_Length": 6.251993832871252,
                "Shape_Area": 2.2386337183120433
            }
        },
        {
            "type": "Feature",
            "id": 822,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -87.63333333316677,
                            37.391666666983156
                        ],
                        [
                            -88.03861111081409,
                            37.0097222225026
                        ],
                        [
                            -87.51666666643348,
                            36.249999999600334
                        ],
                        [
                            -87.29999999990002,
                            36.249999999600334
                        ],
                        [
                            -86.99999999960028,
                            36.36666666633363
                        ],
                        [
                            -85.58333333366636,
                            36.900000000099965
                        ],
                        [
                            -85.96361111136366,
                            37.1666666665335
                        ],
                        [
                            -85.99999999980014,
                            37.19444444475539
                        ],
                        [
                            -86.15000000039964,
                            37.30000000019993
                        ],
                        [
                            -86.4833333336664,
                            37.30000000019993
                        ],
                        [
                            -86.91666666673325,
                            37.288888888911174
                        ],
                        [
                            -87.39722222232768,
                            37.27500000024986
                        ],
                        [
                            -87.63333333316677,
                            37.391666666983156
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 822,
                "artcc": "zme",
                "sector": "63",
                "sectype": "high",
                "Shape_Length": 5.735504537576286,
                "Shape_Area": 1.6620891208409454
            }
        },
        {
            "type": "Feature",
            "id": 846,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -93.2319444440808,
                            44.89027777769735
                        ],
                        [
                            -93.18999999999994,
                            44.96777777772229
                        ],
                        [
                            -93.14388888896104,
                            45.052500000174916
                        ],
                        [
                            -93.08583333324168,
                            45.1572222222278
                        ],
                        [
                            -93.00138888888608,
                            45.30833333341667
                        ],
                        [
                            -92.7705555555944,
                            45.464722222152886
                        ],
                        [
                            -92.37416666625859,
                            45.66916666680828
                        ],
                        [
                            -92.18333333306686,
                            45.76666666643354
                        ],
                        [
                            -92.17527777814689,
                            46.149166667108034
                        ],
                        [
                            -92.1738888885613,
                            46.21888888851146
                        ],
                        [
                            -92.16666666703304,
                            46.549999999700276
                        ],
                        [
                            -93.03527777804698,
                            46.5655555560441
                        ],
                        [
                            -93.28194444398088,
                            46.564444444555534
                        ],
                        [
                            -94.28666666693312,
                            46.57444444435566
                        ],
                        [
                            -94.97166666628357,
                            46.574999999650345
                        ],
                        [
                            -95.06777777812192,
                            46.57250000037476
                        ],
                        [
                            -95.49999999970021,
                            46.66666666643357
                        ],
                        [
                            -97.03333333326668,
                            46.43749999992514
                        ],
                        [
                            -97.63333333296691,
                            45.85833333321682
                        ],
                        [
                            -97.60833333301684,
                            44.96666666713304
                        ],
                        [
                            -97.67166666628361,
                            44.86666666643356
                        ],
                        [
                            -96.12500000024977,
                            44.850000000399746
                        ],
                        [
                            -94.41472222215282,
                            44.88333333336669
                        ],
                        [
                            -94.11638888863627,
                            44.88638888883622
                        ],
                        [
                            -93.7169444447303,
                            44.88611111073925
                        ],
                        [
                            -93.2319444440808,
                            44.89027777769735
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 846,
                "artcc": "zmp",
                "sector": "11",
                "sectype": "high",
                "Shape_Length": 13.378603739756608,
                "Shape_Area": 8.386581480466422
            }
        },
        {
            "type": "Feature",
            "id": 847,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -82.34999999990004,
                            44.66666666683324
                        ],
                        [
                            -82.42138888898597,
                            44.915555555744334
                        ],
                        [
                            -82.49999999960028,
                            45.33333333336668
                        ],
                        [
                            -83.50000000029974,
                            45.78333333336667
                        ],
                        [
                            -83.58333333316676,
                            45.81666666633362
                        ],
                        [
                            -84.35833333341657,
                            46.25000000029985
                        ],
                        [
                            -84.53333333306682,
                            46.3833333330669
                        ],
                        [
                            -84.99999999999994,
                            46.53333333366646
                        ],
                        [
                            -85.63333333356644,
                            46.75000000019992
                        ],
                        [
                            -85.88333333306684,
                            46.8000000001
                        ],
                        [
                            -86.4988888891109,
                            46.95833333371644
                        ],
                        [
                            -86.50249999987506,
                            45.99361111086415
                        ],
                        [
                            -86.49999999970021,
                            45.48333333306692
                        ],
                        [
                            -86.7025000003747,
                            45.235277777547424
                        ],
                        [
                            -86.93916666650841,
                            44.94258333355407
                        ],
                        [
                            -86.99999999960028,
                            44.86666666643356
                        ],
                        [
                            -87.11666666633357,
                            44.66666666683324
                        ],
                        [
                            -87.20000000009986,
                            44.533333333166865
                        ],
                        [
                            -87.54166666638349,
                            43.96666666643358
                        ],
                        [
                            -87.16944444450547,
                            43.69166666698317
                        ],
                        [
                            -86.99999999960028,
                            43.68499999985016
                        ],
                        [
                            -86.21666666633354,
                            43.65111111068927
                        ],
                        [
                            -85.74166666638354,
                            43.628055555619426
                        ],
                        [
                            -84.9636111106642,
                            43.58666666683325
                        ],
                        [
                            -84.52249999987504,
                            43.63388888846151
                        ],
                        [
                            -84.16055555589418,
                            43.67138888928588
                        ],
                        [
                            -84.03722222202788,
                            43.683611111163884
                        ],
                        [
                            -83.29333333356647,
                            43.755833333641476
                        ],
                        [
                            -82.1694444446054,
                            43.85527777814701
                        ],
                        [
                            -82.30388888886108,
                            44.382222221978
                        ],
                        [
                            -82.34999999990004,
                            44.66666666683324
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 847,
                "artcc": "zmp",
                "sector": "12",
                "sectype": "high",
                "Shape_Length": 14.658031270659176,
                "Shape_Area": 11.595931531467352
            }
        },
        {
            "type": "Feature",
            "id": 848,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -88.77500000054954,
                            47.90833333271718
                        ],
                        [
                            -89.10833333381629,
                            47.79999999990014
                        ],
                        [
                            -89.31666666733281,
                            47.79888888841151
                        ],
                        [
                            -89.41222222207784,
                            47.79833333311689
                        ],
                        [
                            -89.82500000024976,
                            47.89166666668336
                        ],
                        [
                            -91.08694444423065,
                            47.1872222221279
                        ],
                        [
                            -91.36666666683317,
                            47.02499999965033
                        ],
                        [
                            -91.41666666673325,
                            46.999999999700265
                        ],
                        [
                            -91.86777777822186,
                            46.73694444403094
                        ],
                        [
                            -91.896666667033,
                            46.72222222197803
                        ],
                        [
                            -92.16666666703304,
                            46.549999999700276
                        ],
                        [
                            -92.17388888946067,
                            46.21888888851146
                        ],
                        [
                            -92.17527777814689,
                            46.149166666208714
                        ],
                        [
                            -92.18333333396617,
                            45.76666666643354
                        ],
                        [
                            -91.53333333346654,
                            45.56666666683327
                        ],
                        [
                            -90.93166666698306,
                            45.374999999350564
                        ],
                        [
                            -90.80222222217776,
                            45.33333333336668
                        ],
                        [
                            -90.60000000049956,
                            45.141666666783294
                        ],
                        [
                            -89.58750000072439,
                            44.15416666605887
                        ],
                        [
                            -89.37083333329167,
                            44.18333333296698
                        ],
                        [
                            -89.07611111143859,
                            44.22499999985018
                        ],
                        [
                            -88.7541666666583,
                            44.15944444450554
                        ],
                        [
                            -88.46666666723286,
                            44.10000000009995
                        ],
                        [
                            -87.54166666728281,
                            43.96666666643358
                        ],
                        [
                            -87.20000000009986,
                            44.533333333166865
                        ],
                        [
                            -87.11666666723289,
                            44.66666666683324
                        ],
                        [
                            -87.0000000004996,
                            44.86666666643356
                        ],
                        [
                            -86.93916666740773,
                            44.94258333265475
                        ],
                        [
                            -86.7025000003747,
                            45.235277777547424
                        ],
                        [
                            -86.50000000059953,
                            45.48333333306692
                        ],
                        [
                            -86.50249999987506,
                            45.99361111086415
                        ],
                        [
                            -86.4988888891109,
                            46.95833333281712
                        ],
                        [
                            -87.0000000004996,
                            47.08333333256729
                        ],
                        [
                            -87.50000000039967,
                            47.32083333299198
                        ],
                        [
                            -88.77500000054954,
                            47.90833333271718
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 848,
                "artcc": "zmp",
                "sector": "13",
                "sectype": "high",
                "Shape_Length": 15.567158690955495,
                "Shape_Area": 15.262428768542879
            }
        },
        {
            "type": "Feature",
            "id": 849,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -82.34999999990004,
                            44.66666666683324
                        ],
                        [
                            -82.42138888898597,
                            44.915555555744334
                        ],
                        [
                            -82.49999999960028,
                            45.33333333336668
                        ],
                        [
                            -83.50000000029974,
                            45.78333333336667
                        ],
                        [
                            -83.58333333316676,
                            45.81666666633362
                        ],
                        [
                            -84.35833333341657,
                            46.25000000029985
                        ],
                        [
                            -84.53333333306682,
                            46.3833333330669
                        ],
                        [
                            -84.99999999999994,
                            46.53333333366646
                        ],
                        [
                            -85.63333333356644,
                            46.75000000019992
                        ],
                        [
                            -85.88333333306684,
                            46.8000000001
                        ],
                        [
                            -86.4988888891109,
                            46.95833333371644
                        ],
                        [
                            -86.50249999987506,
                            45.99361111086415
                        ],
                        [
                            -86.49999999970021,
                            45.48333333306692
                        ],
                        [
                            -86.7025000003747,
                            45.235277777547424
                        ],
                        [
                            -86.93916666650841,
                            44.94258333355407
                        ],
                        [
                            -86.99999999960028,
                            44.86666666643356
                        ],
                        [
                            -87.11666666633357,
                            44.66666666683324
                        ],
                        [
                            -87.20000000009986,
                            44.533333333166865
                        ],
                        [
                            -87.54166666638349,
                            43.96666666643358
                        ],
                        [
                            -87.16944444450547,
                            43.69166666698317
                        ],
                        [
                            -86.99999999960028,
                            43.68499999985016
                        ],
                        [
                            -86.21666666633354,
                            43.65111111068927
                        ],
                        [
                            -85.74166666638354,
                            43.628055555619426
                        ],
                        [
                            -84.9636111106642,
                            43.58666666683325
                        ],
                        [
                            -84.52249999987504,
                            43.63388888846151
                        ],
                        [
                            -84.16055555589418,
                            43.67138888928588
                        ],
                        [
                            -84.03722222202788,
                            43.683611111163884
                        ],
                        [
                            -83.29333333356647,
                            43.755833333641476
                        ],
                        [
                            -82.1694444446054,
                            43.85527777814701
                        ],
                        [
                            -82.30388888886108,
                            44.382222221978
                        ],
                        [
                            -82.34999999990004,
                            44.66666666683324
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 849,
                "artcc": "zmp",
                "sector": "14",
                "sectype": "high",
                "Shape_Length": 14.658031270659176,
                "Shape_Area": 11.595931531467352
            }
        },
        {
            "type": "Feature",
            "id": 850,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -90.76527777754734,
                            43.68888888871129
                        ],
                        [
                            -90.64166666648345,
                            43.81666666673334
                        ],
                        [
                            -90.54999999970016,
                            43.89999999960037
                        ],
                        [
                            -90.42222222257749,
                            44.038888888911174
                        ],
                        [
                            -90.37027777779718,
                            44.085833333341725
                        ],
                        [
                            -91.15722222182802,
                            44.385000000249875
                        ],
                        [
                            -91.77361111126373,
                            44.61249999997506
                        ],
                        [
                            -92.32805555521963,
                            44.8111111108891
                        ],
                        [
                            -92.36638888853633,
                            44.824999999550414
                        ],
                        [
                            -93.14388888896104,
                            45.052500000174916
                        ],
                        [
                            -93.18999999999994,
                            44.96777777772229
                        ],
                        [
                            -93.2319444440808,
                            44.89027777769735
                        ],
                        [
                            -92.873888888961,
                            44.48361111136376
                        ],
                        [
                            -92.75249999997499,
                            44.345555555444605
                        ],
                        [
                            -92.27916666680818,
                            44.10833333311689
                        ],
                        [
                            -91.98416666685813,
                            43.92972222180316
                        ],
                        [
                            -91.78333333296689,
                            43.808333333716405
                        ],
                        [
                            -91.08333333346656,
                            43.36666666673335
                        ],
                        [
                            -90.85416666695806,
                            43.595833333241785
                        ],
                        [
                            -90.82916666700805,
                            43.618055555819296
                        ],
                        [
                            -90.76527777754734,
                            43.68888888871129
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 850,
                "artcc": "zmp",
                "sector": "15",
                "sectype": "high",
                "Shape_Length": 6.79898837245485,
                "Shape_Area": 1.8349098765743899
            }
        },
        {
            "type": "Feature",
            "id": 852,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -91.77361111126373,
                            44.61249999997506
                        ],
                        [
                            -91.15722222182802,
                            44.385000000249875
                        ],
                        [
                            -90.37027777779718,
                            44.085833333341725
                        ],
                        [
                            -90.29166666628356,
                            44.15416666695819
                        ],
                        [
                            -90.08333333366636,
                            44.15416666695819
                        ],
                        [
                            -89.97777777822188,
                            44.15416666695819
                        ],
                        [
                            -89.62916666670827,
                            44.15416666695819
                        ],
                        [
                            -89.58749999982507,
                            44.15416666695819
                        ],
                        [
                            -90.59999999960024,
                            45.141666666783294
                        ],
                        [
                            -90.80222222217776,
                            45.33333333336668
                        ],
                        [
                            -90.93166666698306,
                            45.375000000249884
                        ],
                        [
                            -91.53333333346654,
                            45.56666666683327
                        ],
                        [
                            -92.18333333306686,
                            45.76666666643354
                        ],
                        [
                            -92.37416666625859,
                            45.66916666680828
                        ],
                        [
                            -92.7705555555944,
                            45.464722222152886
                        ],
                        [
                            -93.00138888888608,
                            45.30833333341667
                        ],
                        [
                            -93.08583333324168,
                            45.1572222222278
                        ],
                        [
                            -93.14388888896104,
                            45.052500000174916
                        ],
                        [
                            -92.36638888853633,
                            44.824999999550414
                        ],
                        [
                            -92.32805555521963,
                            44.8111111108891
                        ],
                        [
                            -91.77361111126373,
                            44.61249999997506
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 852,
                "artcc": "zmp",
                "sector": "16",
                "sectype": "high",
                "Shape_Length": 8.11949776545491,
                "Shape_Area": 2.720526504878658
            }
        },
        {
            "type": "Feature",
            "id": 854,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -91.08333333346656,
                            43.36666666673335
                        ],
                        [
                            -91.78333333296689,
                            43.808333333716405
                        ],
                        [
                            -91.98416666685813,
                            43.92972222180316
                        ],
                        [
                            -92.27916666680818,
                            44.10833333311689
                        ],
                        [
                            -92.75249999997499,
                            44.345555555444605
                        ],
                        [
                            -92.873888888961,
                            44.48361111136376
                        ],
                        [
                            -93.55416666695805,
                            44.3155555560441
                        ],
                        [
                            -93.78611111083904,
                            43.75833333291706
                        ],
                        [
                            -93.85833333331664,
                            43.45833333351658
                        ],
                        [
                            -94.12583333314177,
                            42.94055555519475
                        ],
                        [
                            -93.6999999997002,
                            42.8166666669332
                        ],
                        [
                            -93.24166666668327,
                            42.78888888871131
                        ],
                        [
                            -93.0000000001998,
                            42.783333333066935
                        ],
                        [
                            -92.46666666643347,
                            42.756944444430644
                        ],
                        [
                            -91.72500000004993,
                            42.72500000014992
                        ],
                        [
                            -91.66666666713297,
                            42.783333333066935
                        ],
                        [
                            -91.22083333319176,
                            43.23333333306692
                        ],
                        [
                            -91.11861111131373,
                            43.333333332867085
                        ],
                        [
                            -91.08333333346656,
                            43.36666666673335
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 854,
                "artcc": "zmp",
                "sector": "17",
                "sectype": "high",
                "Shape_Length": 7.644385615918457,
                "Shape_Area": 3.406561304296196
            }
        },
        {
            "type": "Feature",
            "id": 856,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -97.04611111133869,
                            43.327500000025054
                        ],
                        [
                            -96.18333333316673,
                            43.12222222197801
                        ],
                        [
                            -94.86666666703303,
                            43.11666666633363
                        ],
                        [
                            -94.12583333314177,
                            42.94055555519475
                        ],
                        [
                            -93.85833333331664,
                            43.45833333351658
                        ],
                        [
                            -93.78611111083904,
                            43.75833333291706
                        ],
                        [
                            -93.55416666695805,
                            44.3155555560441
                        ],
                        [
                            -92.873888888961,
                            44.48361111136376
                        ],
                        [
                            -93.2319444440808,
                            44.89027777769735
                        ],
                        [
                            -93.7169444447303,
                            44.88611111073925
                        ],
                        [
                            -94.11638888863627,
                            44.88638888883622
                        ],
                        [
                            -94.13000000009987,
                            44.69111111148868
                        ],
                        [
                            -94.01944444430563,
                            44.60083333339168
                        ],
                        [
                            -94.09527777754738,
                            44.57194444458048
                        ],
                        [
                            -95.59777777742244,
                            44.025833333641515
                        ],
                        [
                            -97.04611111133869,
                            43.327500000025054
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 856,
                "artcc": "zmp",
                "sector": "18",
                "sectype": "high",
                "Shape_Length": 10.213177981910885,
                "Shape_Area": 3.505167053285341
            }
        },
        {
            "type": "Feature",
            "id": 858,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -94.11638888863627,
                            44.88638888883622
                        ],
                        [
                            -94.41472222215282,
                            44.88333333336669
                        ],
                        [
                            -96.12500000024977,
                            44.850000000399746
                        ],
                        [
                            -97.67166666628361,
                            44.86666666643356
                        ],
                        [
                            -98.44166666708298,
                            43.600000000199884
                        ],
                        [
                            -98.14999999980012,
                            43.58333333326675
                        ],
                        [
                            -97.04611111133869,
                            43.327500000025054
                        ],
                        [
                            -95.59777777742244,
                            44.025833333641515
                        ],
                        [
                            -94.09527777754738,
                            44.57194444458048
                        ],
                        [
                            -94.01944444430563,
                            44.60083333339168
                        ],
                        [
                            -94.13000000009987,
                            44.69111111148868
                        ],
                        [
                            -94.11638888863627,
                            44.88638888883622
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 858,
                "artcc": "zmp",
                "sector": "19",
                "sectype": "high",
                "Shape_Length": 10.089543460885904,
                "Shape_Area": 4.009129359751015
            }
        },
        {
            "type": "Feature",
            "id": 859,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -101.4833333333666,
                            44.699999999800184
                        ],
                        [
                            -101.40833333351651,
                            43.70833333301698
                        ],
                        [
                            -100.10000000039969,
                            43.28888888861138
                        ],
                        [
                            -98.44166666708298,
                            43.600000000199884
                        ],
                        [
                            -97.67166666628361,
                            44.86666666643356
                        ],
                        [
                            -97.60833333301684,
                            44.96666666713304
                        ],
                        [
                            -97.63333333296691,
                            45.85833333321682
                        ],
                        [
                            -98.00000000009987,
                            45.9166666670331
                        ],
                        [
                            -98.99999999990001,
                            45.9166666670331
                        ],
                        [
                            -100.38333333286698,
                            45.999999999900126
                        ],
                        [
                            -101.82916666660833,
                            46.23888888901109
                        ],
                        [
                            -103.16666666663332,
                            46.449999999900115
                        ],
                        [
                            -103.16666666663332,
                            44.958333333216785
                        ],
                        [
                            -101.4833333333666,
                            44.699999999800184
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 859,
                "artcc": "zmp",
                "sector": "20",
                "sectype": "high",
                "Shape_Length": 15.31973779481365,
                "Shape_Area": 11.481629631480535
            }
        },
        {
            "type": "Feature",
            "id": 861,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -103.16666666663332,
                            49.00000000019992
                        ],
                        [
                            -103.16666666663332,
                            47.84611111093909
                        ],
                        [
                            -103.16666666663332,
                            47.25000000009999
                        ],
                        [
                            -103.16666666663332,
                            46.449999999900115
                        ],
                        [
                            -101.82916666660833,
                            46.23888888901109
                        ],
                        [
                            -100.38333333286698,
                            45.999999999900126
                        ],
                        [
                            -98.99999999990001,
                            45.9166666670331
                        ],
                        [
                            -99.3041666662586,
                            47.63750000022486
                        ],
                        [
                            -99.36944444440553,
                            47.90555555534468
                        ],
                        [
                            -99.58333333356643,
                            49.00000000019992
                        ],
                        [
                            -101.00000000039967,
                            49.00000000019992
                        ],
                        [
                            -103.16666666663332,
                            49.00000000019992
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 861,
                "artcc": "zmp",
                "sector": "23",
                "sectype": "high",
                "Shape_Length": 13.477215055598094,
                "Shape_Area": 11.050873843392544
            }
        },
        {
            "type": "Feature",
            "id": 863,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -99.58333333356643,
                            49.00000000019992
                        ],
                        [
                            -99.36944444440553,
                            47.90555555534468
                        ],
                        [
                            -99.3041666662586,
                            47.63750000022486
                        ],
                        [
                            -98.99999999990001,
                            45.9166666670331
                        ],
                        [
                            -98.00000000009987,
                            45.9166666670331
                        ],
                        [
                            -97.63333333296691,
                            45.85833333321682
                        ],
                        [
                            -97.03333333326668,
                            46.43749999992514
                        ],
                        [
                            -95.49999999970021,
                            46.66666666643357
                        ],
                        [
                            -95.61250000037467,
                            47.06666666653348
                        ],
                        [
                            -95.6958333332417,
                            47.362499999875126
                        ],
                        [
                            -95.75000000009987,
                            47.58333333336668
                        ],
                        [
                            -95.61666666643345,
                            48.4166666665335
                        ],
                        [
                            -95.83194444428062,
                            48.6749999999501
                        ],
                        [
                            -96.08333333336662,
                            49.00000000019992
                        ],
                        [
                            -97.71666666673326,
                            49.00000000019992
                        ],
                        [
                            -99.58333333356643,
                            49.00000000019992
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 863,
                "artcc": "zmp",
                "sector": "24",
                "sectype": "high",
                "Shape_Length": 12.935446206915996,
                "Shape_Area": 10.085888310488068
            }
        },
        {
            "type": "Feature",
            "id": 865,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -95.49999999970021,
                            46.66666666643357
                        ],
                        [
                            -95.06777777812192,
                            46.57250000037476
                        ],
                        [
                            -94.97166666628357,
                            46.574999999650345
                        ],
                        [
                            -94.28666666693312,
                            46.57444444435566
                        ],
                        [
                            -93.28194444398088,
                            46.564444444555534
                        ],
                        [
                            -93.03527777804698,
                            46.5655555560441
                        ],
                        [
                            -92.16666666703304,
                            46.549999999700276
                        ],
                        [
                            -91.896666667033,
                            46.72222222197803
                        ],
                        [
                            -91.86777777822186,
                            46.73694444403094
                        ],
                        [
                            -91.41666666673325,
                            46.999999999700265
                        ],
                        [
                            -91.36666666683317,
                            47.02499999965033
                        ],
                        [
                            -91.08694444423065,
                            47.1872222221279
                        ],
                        [
                            -89.82500000024976,
                            47.89166666668336
                        ],
                        [
                            -89.99999999990001,
                            48.01666666643354
                        ],
                        [
                            -90.09999999970017,
                            48.10833333321682
                        ],
                        [
                            -91.81666666683316,
                            48.576944444130845
                        ],
                        [
                            -93.40000000029971,
                            48.97500000024985
                        ],
                        [
                            -93.50000000009987,
                            49.00000000019992
                        ],
                        [
                            -93.99999999999994,
                            49.00000000019992
                        ],
                        [
                            -94.86666666703303,
                            49.00000000019992
                        ],
                        [
                            -95.21666666633354,
                            49.00000000019992
                        ],
                        [
                            -95.99999999960028,
                            49.00000000019992
                        ],
                        [
                            -96.08333333336662,
                            49.00000000019992
                        ],
                        [
                            -95.83194444428062,
                            48.6749999999501
                        ],
                        [
                            -95.61666666643345,
                            48.4166666665335
                        ],
                        [
                            -95.75000000009987,
                            47.58333333336668
                        ],
                        [
                            -95.6958333332417,
                            47.362499999875126
                        ],
                        [
                            -95.61250000037467,
                            47.06666666653348
                        ],
                        [
                            -95.49999999970021,
                            46.66666666643357
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 865,
                "artcc": "zmp",
                "sector": "25",
                "sectype": "high",
                "Shape_Length": 15.033624575252919,
                "Shape_Area": 10.940644868990663
            }
        },
        {
            "type": "Feature",
            "id": 870,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -96.18333333316673,
                            43.12222222197801
                        ],
                        [
                            -97.04611111133869,
                            43.327500000025054
                        ],
                        [
                            -98.14999999980012,
                            43.58333333326675
                        ],
                        [
                            -98.44166666708298,
                            43.600000000199884
                        ],
                        [
                            -100.10000000039969,
                            43.28888888861138
                        ],
                        [
                            -99.96666666673326,
                            43.13333333326676
                        ],
                        [
                            -99.01666666683315,
                            41.999999999800195
                        ],
                        [
                            -99.02499999985008,
                            41.71666666643358
                        ],
                        [
                            -96.36666666673324,
                            41.85000000009995
                        ],
                        [
                            -96.18333333316673,
                            43.12222222197801
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 870,
                "artcc": "zmp",
                "sector": "29",
                "sectype": "high",
                "Shape_Length": 9.913620923294557,
                "Shape_Area": 5.308864198061523
            }
        },
        {
            "type": "Feature",
            "id": 871,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -93.35555555604407,
                            41.947222222527614
                        ],
                        [
                            -93.26666666663334,
                            42.158333333416635
                        ],
                        [
                            -93.23749999972517,
                            42.22916666630863
                        ],
                        [
                            -93.05000000009989,
                            42.66666666633364
                        ],
                        [
                            -93.0000000001998,
                            42.783333333066935
                        ],
                        [
                            -93.24166666668327,
                            42.78888888871131
                        ],
                        [
                            -93.6999999997002,
                            42.8166666669332
                        ],
                        [
                            -94.12583333314177,
                            42.94055555519475
                        ],
                        [
                            -94.86666666703303,
                            43.11666666633363
                        ],
                        [
                            -96.18333333316673,
                            43.12222222197801
                        ],
                        [
                            -96.36666666673324,
                            41.85000000009995
                        ],
                        [
                            -96.07027777809697,
                            41.61777777812199
                        ],
                        [
                            -93.35555555604407,
                            41.947222222527614
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 871,
                "artcc": "zmp",
                "sector": "30",
                "sectype": "high",
                "Shape_Length": 8.527661704529562,
                "Shape_Area": 3.7958912019686317
            }
        },
        {
            "type": "Feature",
            "id": 877,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -93.35555555604407,
                            41.947222222527614
                        ],
                        [
                            -96.07027777809697,
                            41.61777777812199
                        ],
                        [
                            -96.05833333341656,
                            41.608333333616486
                        ],
                        [
                            -96.03333333346654,
                            41.54166666678327
                        ],
                        [
                            -95.89166666678324,
                            41.16666666663343
                        ],
                        [
                            -95.77500000004994,
                            40.483333333166854
                        ],
                        [
                            -95.75000000009987,
                            40.35833333341668
                        ],
                        [
                            -95.54999999960029,
                            40.1499999999001
                        ],
                        [
                            -94.49999999990001,
                            40.35055555569437
                        ],
                        [
                            -94.07249999967519,
                            40.427777777622396
                        ],
                        [
                            -93.90833333321672,
                            40.458333333216785
                        ],
                        [
                            -93.491666667083,
                            40.52500000005
                        ],
                        [
                            -93.55694444433061,
                            40.91749999962536
                        ],
                        [
                            -93.56249999997499,
                            40.9444444444556
                        ],
                        [
                            -93.5833333329669,
                            41.06666666683327
                        ],
                        [
                            -93.59999999990004,
                            41.16666666663343
                        ],
                        [
                            -93.50833333311681,
                            41.43333333306691
                        ],
                        [
                            -93.48499999994999,
                            41.566111111438715
                        ],
                        [
                            -93.46666666713293,
                            41.6666666665335
                        ],
                        [
                            -93.35555555604407,
                            41.947222222527614
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 877,
                "artcc": "zmp",
                "sector": "38",
                "sectype": "high",
                "Shape_Length": 7.8953095227150065,
                "Shape_Area": 3.4313976476807633
            }
        },
        {
            "type": "Feature",
            "id": 878,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -99.02499999985008,
                            41.71666666643358
                        ],
                        [
                            -99.03333333286696,
                            41.07499999985015
                        ],
                        [
                            -99.04722222242759,
                            40.88333333326676
                        ],
                        [
                            -99.05416666675825,
                            40.350000000399746
                        ],
                        [
                            -99.05555555544447,
                            40.23055555539463
                        ],
                        [
                            -99.05833333371635,
                            39.983333333266785
                        ],
                        [
                            -98.80000000029975,
                            39.46666666643358
                        ],
                        [
                            -97.88333333336658,
                            39.66666666693317
                        ],
                        [
                            -96.96666666643347,
                            39.86666666653349
                        ],
                        [
                            -96.08333333336662,
                            40.0500000001
                        ],
                        [
                            -95.91666666673325,
                            40.07916666700811
                        ],
                        [
                            -95.54999999960029,
                            40.1499999999001
                        ],
                        [
                            -95.75000000009987,
                            40.35833333341668
                        ],
                        [
                            -95.77500000004994,
                            40.483333333166854
                        ],
                        [
                            -95.89166666678324,
                            41.16666666663343
                        ],
                        [
                            -96.03333333346654,
                            41.54166666678327
                        ],
                        [
                            -96.05833333341656,
                            41.608333333616486
                        ],
                        [
                            -96.07027777809697,
                            41.61777777812199
                        ],
                        [
                            -96.36666666673324,
                            41.85000000009995
                        ],
                        [
                            -99.02499999985008,
                            41.71666666643358
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 878,
                "artcc": "zmp",
                "sector": "39",
                "sectype": "high",
                "Shape_Length": 10.267865086364667,
                "Shape_Area": 6.359081018240785
            }
        },
        {
            "type": "Feature",
            "id": 899,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -76.81527777844667,
                            39.38555555564443
                        ],
                        [
                            -76.72138888958557,
                            39.38694444433071
                        ],
                        [
                            -76.34722222282727,
                            39.39166666658343
                        ],
                        [
                            -76.04027777819687,
                            39.59361111106398
                        ],
                        [
                            -75.96388888876118,
                            39.64388888816171
                        ],
                        [
                            -75.89472222265243,
                            39.68916666670833
                        ],
                        [
                            -75.77333333366636,
                            39.77083333279211
                        ],
                        [
                            -75.67000000029975,
                            39.834999999450474
                        ],
                        [
                            -75.65000000069944,
                            39.84999999960036
                        ],
                        [
                            -75.46944444450548,
                            39.97083333329175
                        ],
                        [
                            -75.34166666738275,
                            40.06666666613381
                        ],
                        [
                            -75.0277777784217,
                            40.09305555566942
                        ],
                        [
                            -75.03750000012485,
                            40.22499999975025
                        ],
                        [
                            -75.64166666678318,
                            40.33333333256729
                        ],
                        [
                            -75.80000000039968,
                            40.24444444405589
                        ],
                        [
                            -75.95833333401612,
                            40.16111111118886
                        ],
                        [
                            -76.15750000022479,
                            40.061111110489435
                        ],
                        [
                            -76.18416666695805,
                            40.04888888861137
                        ],
                        [
                            -76.20527777804699,
                            40.03666666673331
                        ],
                        [
                            -76.25000000039967,
                            40.01611111093905
                        ],
                        [
                            -76.6000000005995,
                            39.830555555294666
                        ],
                        [
                            -76.62361111096396,
                            39.81805555531969
                        ],
                        [
                            -76.82500000014983,
                            39.71388888856143
                        ],
                        [
                            -77.00833333371634,
                            39.610555555194765
                        ],
                        [
                            -77.07472222245258,
                            39.57916666620872
                        ],
                        [
                            -77.18361111146356,
                            39.52083333329176
                        ],
                        [
                            -77.12333333366638,
                            39.4652777777473
                        ],
                        [
                            -77.10555555614394,
                            39.446388888736294
                        ],
                        [
                            -76.94277777837175,
                            39.406666666733315
                        ],
                        [
                            -76.85555555574427,
                            39.384999999450486
                        ],
                        [
                            -76.81527777844667,
                            39.38555555564443
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 899,
                "artcc": "zny",
                "sector": "09",
                "sectype": "high",
                "Shape_Length": 4.8895964935614655,
                "Shape_Area": 0.8078852619771741
            }
        },
        {
            "type": "Feature",
            "id": 901,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -76.58611111103892,
                            40.42083333329174
                        ],
                        [
                            -76.85388888896102,
                            40.439166666108804
                        ],
                        [
                            -77.07472222245258,
                            40.457222221728216
                        ],
                        [
                            -77.30000000009989,
                            40.473055555269696
                        ],
                        [
                            -77.67222222287722,
                            40.50277777747249
                        ],
                        [
                            -77.94138888948561,
                            40.5216666664835
                        ],
                        [
                            -77.90583333354147,
                            40.395555555244755
                        ],
                        [
                            -77.86416666665832,
                            40.24666666613376
                        ],
                        [
                            -77.86249999987507,
                            40.199999999800184
                        ],
                        [
                            -77.85833333381629,
                            40.15694444423076
                        ],
                        [
                            -77.84027777819688,
                            39.855555555244734
                        ],
                        [
                            -77.61722222262745,
                            39.77222222147839
                        ],
                        [
                            -77.3974944446129,
                            39.78018888830661
                        ],
                        [
                            -77.35666666713297,
                            39.78166666598389
                        ],
                        [
                            -77.35694444433062,
                            39.74638888903604
                        ],
                        [
                            -77.35694444433062,
                            39.722222221578306
                        ],
                        [
                            -77.3572222224276,
                            39.69805555501989
                        ],
                        [
                            -77.24027777849665,
                            39.581111111088944
                        ],
                        [
                            -77.2269444442307,
                            39.56888888831162
                        ],
                        [
                            -77.20666666653341,
                            39.54805555531965
                        ],
                        [
                            -77.18361111146356,
                            39.52083333329176
                        ],
                        [
                            -77.07472222245258,
                            39.57916666620872
                        ],
                        [
                            -77.00833333371634,
                            39.610555555194765
                        ],
                        [
                            -76.82500000014983,
                            39.71388888856143
                        ],
                        [
                            -76.62361111096396,
                            39.81805555531969
                        ],
                        [
                            -76.6000000005995,
                            39.830555555294666
                        ],
                        [
                            -76.25000000039967,
                            40.01611111093905
                        ],
                        [
                            -76.20527777804699,
                            40.03666666673331
                        ],
                        [
                            -76.18416666695805,
                            40.04888888861137
                        ],
                        [
                            -76.15750000022479,
                            40.061111110489435
                        ],
                        [
                            -75.95833333401612,
                            40.16111111118886
                        ],
                        [
                            -75.80000000039968,
                            40.24444444405589
                        ],
                        [
                            -75.64166666678318,
                            40.33333333256729
                        ],
                        [
                            -76.24166666738273,
                            40.4297222216033
                        ],
                        [
                            -76.40138888878613,
                            40.457222221728216
                        ],
                        [
                            -76.51722222212783,
                            40.408888888611386
                        ],
                        [
                            -76.58611111103892,
                            40.42083333329174
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 901,
                "artcc": "zny",
                "sector": "10",
                "sectype": "high",
                "Shape_Length": 5.575751701300984,
                "Shape_Area": 1.2653643839927113
            }
        },
        {
            "type": "Feature",
            "id": 902,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -75.95833333401612,
                            40.16111111118886
                        ],
                        [
                            -76.15750000022479,
                            40.061111110489435
                        ],
                        [
                            -76.18416666695805,
                            40.04888888861137
                        ],
                        [
                            -76.20527777804699,
                            40.03666666673331
                        ],
                        [
                            -76.25000000039967,
                            40.01611111093905
                        ],
                        [
                            -76.62388888906094,
                            40.032777777872184
                        ],
                        [
                            -76.74388888936073,
                            40.02999999960036
                        ],
                        [
                            -76.85999999990003,
                            39.97166666668335
                        ],
                        [
                            -77.03805555591913,
                            39.88250000007497
                        ],
                        [
                            -77.35694444433062,
                            39.722222221578306
                        ],
                        [
                            -77.3572222224276,
                            39.69805555501989
                        ],
                        [
                            -77.24027777849665,
                            39.581111111088944
                        ],
                        [
                            -77.2269444442307,
                            39.56888888831162
                        ],
                        [
                            -77.20666666653341,
                            39.54805555531965
                        ],
                        [
                            -77.18361111146356,
                            39.52083333329176
                        ],
                        [
                            -77.12333333366638,
                            39.4652777777473
                        ],
                        [
                            -77.10555555614394,
                            39.446388888736294
                        ],
                        [
                            -76.94277777837175,
                            39.406666666733315
                        ],
                        [
                            -76.85555555574427,
                            39.384999999450486
                        ],
                        [
                            -76.81527777844667,
                            39.38555555564443
                        ],
                        [
                            -76.72138888958557,
                            39.38694444433071
                        ],
                        [
                            -76.34722222282727,
                            39.39166666658343
                        ],
                        [
                            -76.04027777819687,
                            39.59361111106398
                        ],
                        [
                            -75.96388888876118,
                            39.64388888816171
                        ],
                        [
                            -75.89472222265243,
                            39.68916666670833
                        ],
                        [
                            -75.77333333366636,
                            39.77083333279211
                        ],
                        [
                            -75.67000000029975,
                            39.834999999450474
                        ],
                        [
                            -75.65000000069944,
                            39.84999999960036
                        ],
                        [
                            -75.62499999985005,
                            39.86111111088911
                        ],
                        [
                            -75.46944444450548,
                            39.97083333329175
                        ],
                        [
                            -75.55833333391621,
                            40.041666666183744
                        ],
                        [
                            -75.60166666668329,
                            40.074444443856066
                        ],
                        [
                            -75.63722222262743,
                            40.074166666658414
                        ],
                        [
                            -75.73861111111387,
                            40.04833333331675
                        ],
                        [
                            -75.83277777807194,
                            40.02194444378108
                        ],
                        [
                            -75.95833333401612,
                            40.16111111118886
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 902,
                "artcc": "zny",
                "sector": "25",
                "sectype": "high",
                "Shape_Length": 4.29778403715379,
                "Shape_Area": 0.8348749999337874
            }
        },
        {
            "type": "Feature",
            "id": 903,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -75.73861111111387,
                            40.04833333331675
                        ],
                        [
                            -75.63722222262743,
                            40.074166666658414
                        ],
                        [
                            -75.60166666668329,
                            40.074444443856066
                        ],
                        [
                            -75.60833333381629,
                            40.12499999995009
                        ],
                        [
                            -75.63194444508002,
                            40.16611111063929
                        ],
                        [
                            -75.68944444460539,
                            40.20222222187812
                        ],
                        [
                            -75.76944444480523,
                            40.219444444105875
                        ],
                        [
                            -75.80000000039968,
                            40.24444444405589
                        ],
                        [
                            -75.82255000068193,
                            40.26036388858262
                        ],
                        [
                            -75.8875000002248,
                            40.306944443931
                        ],
                        [
                            -76.16361111116379,
                            40.35194444438065
                        ],
                        [
                            -76.25647222241508,
                            40.36684166640981
                        ],
                        [
                            -76.29739444489769,
                            40.37348888840154
                        ],
                        [
                            -76.51722222212783,
                            40.408888888611386
                        ],
                        [
                            -76.58611111103892,
                            40.42083333329174
                        ],
                        [
                            -76.89999999999998,
                            40.31666666653348
                        ],
                        [
                            -76.92027777769727,
                            40.24499999935057
                        ],
                        [
                            -76.9369444446304,
                            40.185277777747274
                        ],
                        [
                            -76.91444444485523,
                            40.12249999977524
                        ],
                        [
                            -77.03805555591913,
                            39.88250000007497
                        ],
                        [
                            -77.15833333341658,
                            39.87999999990012
                        ],
                        [
                            -77.35500000034972,
                            39.87499999955037
                        ],
                        [
                            -77.35666666713297,
                            39.78166666598389
                        ],
                        [
                            -77.35694444433062,
                            39.74638888903604
                        ],
                        [
                            -77.35694444433062,
                            39.722222221578306
                        ],
                        [
                            -77.3572222224276,
                            39.69805555501989
                        ],
                        [
                            -77.24027777849665,
                            39.581111111088944
                        ],
                        [
                            -77.2269444442307,
                            39.56888888831162
                        ],
                        [
                            -77.20666666653341,
                            39.54805555531965
                        ],
                        [
                            -77.18361111146356,
                            39.52083333329176
                        ],
                        [
                            -77.07472222245258,
                            39.57916666620872
                        ],
                        [
                            -77.00833333371634,
                            39.610555555194765
                        ],
                        [
                            -76.82500000014983,
                            39.71388888856143
                        ],
                        [
                            -76.72916666730782,
                            39.73305555566941
                        ],
                        [
                            -76.70694444473031,
                            39.739722221903094
                        ],
                        [
                            -76.65000000049957,
                            39.784999999550394
                        ],
                        [
                            -76.62361111096396,
                            39.81805555531969
                        ],
                        [
                            -76.6000000005995,
                            39.830555555294666
                        ],
                        [
                            -76.24833333361642,
                            39.77444444445558
                        ],
                        [
                            -76.2166666665334,
                            39.76083333299198
                        ],
                        [
                            -76.211388888986,
                            39.820277777397564
                        ],
                        [
                            -76.15833333361644,
                            39.901388888186716
                        ],
                        [
                            -76.06249999987506,
                            40.040277777497465
                        ],
                        [
                            -75.97777777832175,
                            40.033333333166865
                        ],
                        [
                            -75.97138888928578,
                            40.027222222227806
                        ],
                        [
                            -75.94472222255251,
                            40.0136111107642
                        ],
                        [
                            -75.91138888958557,
                            40.00444444435567
                        ],
                        [
                            -75.8916666671829,
                            40.00277777757242
                        ],
                        [
                            -75.83277777807194,
                            40.02194444378108
                        ],
                        [
                            -75.73861111111387,
                            40.04833333331675
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 903,
                "artcc": "zny",
                "sector": "26",
                "sectype": "high",
                "Shape_Length": 4.5131471860271795,
                "Shape_Area": 0.7314656984412711
            }
        },
        {
            "type": "Feature",
            "id": 904,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -77.03805555591913,
                            39.88250000007497
                        ],
                        [
                            -76.85999999990003,
                            39.97166666668335
                        ],
                        [
                            -76.74388888936073,
                            40.02999999960036
                        ],
                        [
                            -76.91444444485523,
                            40.12249999977524
                        ],
                        [
                            -76.9369444446304,
                            40.185277777747274
                        ],
                        [
                            -76.92027777769727,
                            40.24499999935057
                        ],
                        [
                            -76.89999999999998,
                            40.31666666653348
                        ],
                        [
                            -76.58611111103892,
                            40.42083333329174
                        ],
                        [
                            -76.85388888896102,
                            40.439166666108804
                        ],
                        [
                            -77.07472222245258,
                            40.457222221728216
                        ],
                        [
                            -77.30000000009989,
                            40.473055555269696
                        ],
                        [
                            -77.67222222287722,
                            40.50277777747249
                        ],
                        [
                            -77.94138888948561,
                            40.5216666664835
                        ],
                        [
                            -77.90583333354147,
                            40.395555555244755
                        ],
                        [
                            -77.86416666665832,
                            40.24666666613376
                        ],
                        [
                            -77.86249999987507,
                            40.199999999800184
                        ],
                        [
                            -77.85833333381629,
                            40.15694444423076
                        ],
                        [
                            -77.84027777819688,
                            39.855555555244734
                        ],
                        [
                            -77.61722222262745,
                            39.77222222147839
                        ],
                        [
                            -77.3974944446129,
                            39.78018888830661
                        ],
                        [
                            -77.35666666713297,
                            39.78166666598389
                        ],
                        [
                            -77.35694444433062,
                            39.74638888903604
                        ],
                        [
                            -77.35694444433062,
                            39.722222221578306
                        ],
                        [
                            -77.03805555591913,
                            39.88250000007497
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 904,
                "artcc": "zny",
                "sector": "27",
                "sectype": "high",
                "Shape_Length": 4.00874486255117,
                "Shape_Area": 0.6743957114852441
            }
        },
        {
            "type": "Feature",
            "id": 905,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -74.76666666673327,
                            41.733333333366716
                        ],
                        [
                            -74.80833333361642,
                            41.75833333331673
                        ],
                        [
                            -74.90833333341658,
                            41.80138888888615
                        ],
                        [
                            -75.05833333401614,
                            41.869444444405644
                        ],
                        [
                            -75.14777777782217,
                            41.90722222152834
                        ],
                        [
                            -75.34166666738275,
                            41.98888888851144
                        ],
                        [
                            -75.61666666683317,
                            42.104166666558456
                        ],
                        [
                            -76.01666666693308,
                            42.283333333166865
                        ],
                        [
                            -76.23333333346653,
                            42.36666666603384
                        ],
                        [
                            -76.41666666703304,
                            42.333333333066946
                        ],
                        [
                            -76.8500000000999,
                            42.31250000007498
                        ],
                        [
                            -77.11305555576922,
                            42.29888888861137
                        ],
                        [
                            -77.23611111153855,
                            42.17499999945045
                        ],
                        [
                            -77.25833333321674,
                            42.13888888821168
                        ],
                        [
                            -77.41666666683318,
                            41.87500000005002
                        ],
                        [
                            -77.4555555554445,
                            41.82083333319184
                        ],
                        [
                            -77.56666666653342,
                            41.637499999625334
                        ],
                        [
                            -77.63333333336658,
                            41.555555555444585
                        ],
                        [
                            -77.24944444490518,
                            41.584999999550405
                        ],
                        [
                            -77.19666666673322,
                            41.60361111046444
                        ],
                        [
                            -76.83138888918586,
                            41.73249999997506
                        ],
                        [
                            -76.2166666665334,
                            41.59166666668335
                        ],
                        [
                            -76.09111111148854,
                            41.559722221503364
                        ],
                        [
                            -75.92500000014985,
                            41.51666666683326
                        ],
                        [
                            -75.56666666693309,
                            41.4152777774475
                        ],
                        [
                            -75.41388888896103,
                            41.3624999992756
                        ],
                        [
                            -75.17916666680821,
                            41.2499999995004
                        ],
                        [
                            -74.92916666730781,
                            41.14361111066427
                        ],
                        [
                            -74.86388888916088,
                            41.113888888461474
                        ],
                        [
                            -74.85611111143862,
                            41.11027777769732
                        ],
                        [
                            -74.66361111146358,
                            41.21749999992511
                        ],
                        [
                            -74.58194444448048,
                            41.29972222220283
                        ],
                        [
                            -74.44444444475528,
                            41.43777777722272
                        ],
                        [
                            -74.43333333346652,
                            41.527777777222695
                        ],
                        [
                            -74.65833333391618,
                            41.6249999996503
                        ],
                        [
                            -74.76666666673327,
                            41.733333333366716
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 905,
                "artcc": "zny",
                "sector": "34",
                "sectype": "high",
                "Shape_Length": 7.3198559749182115,
                "Shape_Area": 1.8801640816056828
            }
        },
        {
            "type": "Feature",
            "id": 906,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -74.51666666723287,
                            41.17499999965031
                        ],
                        [
                            -74.49583333334164,
                            41.18999999980019
                        ],
                        [
                            -74.31805555631882,
                            41.299999999400484
                        ],
                        [
                            -74.18333333396617,
                            41.400000000099965
                        ],
                        [
                            -74.23972222290223,
                            41.502500000074974
                        ],
                        [
                            -74.33805555591914,
                            41.54027777719773
                        ],
                        [
                            -74.76666666673327,
                            41.733333333366716
                        ],
                        [
                            -74.80833333361642,
                            41.75833333331673
                        ],
                        [
                            -74.90833333341658,
                            41.80138888888615
                        ],
                        [
                            -75.05833333401614,
                            41.869444444405644
                        ],
                        [
                            -75.14777777782217,
                            41.90722222152834
                        ],
                        [
                            -75.34166666738275,
                            41.98888888851144
                        ],
                        [
                            -75.78833333381624,
                            41.754999999750225
                        ],
                        [
                            -76.09861111111383,
                            41.59027777709781
                        ],
                        [
                            -76.09111111148854,
                            41.559722221503364
                        ],
                        [
                            -75.92500000014985,
                            41.51666666683326
                        ],
                        [
                            -75.56666666693309,
                            41.4152777774475
                        ],
                        [
                            -75.41388888896103,
                            41.3624999992756
                        ],
                        [
                            -75.17916666680821,
                            41.2499999995004
                        ],
                        [
                            -74.92916666730781,
                            41.14361111066427
                        ],
                        [
                            -74.86388888916088,
                            41.113888888461474
                        ],
                        [
                            -74.80916666700801,
                            41.04444444425576
                        ],
                        [
                            -74.66666666693311,
                            41.02499999995007
                        ],
                        [
                            -74.51666666723287,
                            41.17499999965031
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 906,
                "artcc": "zny",
                "sector": "35",
                "sectype": "high",
                "Shape_Length": 4.365251229242697,
                "Shape_Area": 0.8771000385754708
            }
        },
        {
            "type": "Feature",
            "id": 907,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -74.18333333396617,
                            41.400000000099965
                        ],
                        [
                            -74.31805555631882,
                            41.299999999400484
                        ],
                        [
                            -74.49583333334164,
                            41.18999999980019
                        ],
                        [
                            -74.5197222227024,
                            41.18666666623369
                        ],
                        [
                            -74.62111111118878,
                            41.1541666666584
                        ],
                        [
                            -74.70805555571928,
                            41.09944444450554
                        ],
                        [
                            -74.71666666683319,
                            41.05749999952542
                        ],
                        [
                            -74.79722222232766,
                            41.08222222227778
                        ],
                        [
                            -74.85611111143862,
                            41.11027777769732
                        ],
                        [
                            -74.86388888916088,
                            41.113888888461474
                        ],
                        [
                            -74.92916666730781,
                            41.14361111066427
                        ],
                        [
                            -75.17916666680821,
                            41.2499999995004
                        ],
                        [
                            -75.41388888896103,
                            41.3624999992756
                        ],
                        [
                            -75.51250000007491,
                            41.345833333241785
                        ],
                        [
                            -75.59250000027475,
                            41.26472222155331
                        ],
                        [
                            -75.53722222282727,
                            41.136111111038986
                        ],
                        [
                            -75.60583333364139,
                            41.15916666610883
                        ],
                        [
                            -75.44000000039966,
                            40.97277777707279
                        ],
                        [
                            -75.44444444455542,
                            40.958333333116855
                        ],
                        [
                            -75.37944444450545,
                            40.94499999975022
                        ],
                        [
                            -75.36805555601904,
                            40.9444444444556
                        ],
                        [
                            -75.14472222235264,
                            40.99888888851143
                        ],
                        [
                            -75.0750000000499,
                            40.97861111081414
                        ],
                        [
                            -75.01666666713294,
                            40.960277777097815
                        ],
                        [
                            -74.98888888891105,
                            40.954166666158756
                        ],
                        [
                            -74.94111111108884,
                            40.944166666358626
                        ],
                        [
                            -74.88027777799704,
                            40.929444444305716
                        ],
                        [
                            -74.63333333396616,
                            40.854166666358594
                        ],
                        [
                            -74.61250000007493,
                            40.834166666758335
                        ],
                        [
                            -74.58277777787214,
                            40.8202777771977
                        ],
                        [
                            -74.32083333369138,
                            40.665277777147764
                        ],
                        [
                            -74.3500000005995,
                            40.57777777732264
                        ],
                        [
                            -73.83194444508001,
                            40.70555555534463
                        ],
                        [
                            -73.7541666669581,
                            40.851388888986094
                        ],
                        [
                            -73.6416666671829,
                            40.853333332967
                        ],
                        [
                            -73.55222222247755,
                            41.0313888889861
                        ],
                        [
                            -73.74305555566934,
                            41.18694444433072
                        ],
                        [
                            -73.8958333336414,
                            41.25833333341666
                        ],
                        [
                            -74.02500000034968,
                            41.33333333326675
                        ],
                        [
                            -74.05833333331663,
                            41.33333333326675
                        ],
                        [
                            -74.18333333396617,
                            41.400000000099965
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 907,
                "artcc": "zny",
                "sector": "36",
                "sectype": "high",
                "Shape_Length": 5.1531733460361995,
                "Shape_Area": 0.799348881323022
            }
        },
        {
            "type": "Feature",
            "id": 908,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -74.98888888891105,
                            40.954166666158756
                        ],
                        [
                            -75.01666666713294,
                            40.960277777097815
                        ],
                        [
                            -75.0750000000499,
                            40.97861111081414
                        ],
                        [
                            -75.14472222235264,
                            40.99888888851143
                        ],
                        [
                            -75.36805555601904,
                            40.9444444444556
                        ],
                        [
                            -75.37944444450545,
                            40.94499999975022
                        ],
                        [
                            -75.44444444455542,
                            40.958333333116855
                        ],
                        [
                            -75.46638888903595,
                            40.920277777897184
                        ],
                        [
                            -75.62861111151352,
                            40.88027777779723
                        ],
                        [
                            -76.15055555589413,
                            40.74249999997505
                        ],
                        [
                            -76.07833333341654,
                            40.73194444398098
                        ],
                        [
                            -75.98499999985006,
                            40.71777777722269
                        ],
                        [
                            -76.00722222242757,
                            40.59444444425577
                        ],
                        [
                            -76.40138888878613,
                            40.457222221728216
                        ],
                        [
                            -76.51722222212783,
                            40.408888888611386
                        ],
                        [
                            -76.29739444489769,
                            40.37348888840154
                        ],
                        [
                            -76.25647222241508,
                            40.36684166640981
                        ],
                        [
                            -76.16361111116379,
                            40.35194444438065
                        ],
                        [
                            -75.8875000002248,
                            40.306944443931
                        ],
                        [
                            -75.82255000068193,
                            40.26036388858262
                        ],
                        [
                            -75.80000000039968,
                            40.24444444405589
                        ],
                        [
                            -75.66250000067447,
                            40.31666666653348
                        ],
                        [
                            -75.52222222267739,
                            40.373611110764216
                        ],
                        [
                            -75.43833333361641,
                            40.417777777822266
                        ],
                        [
                            -75.12499999994998,
                            40.57777777732264
                        ],
                        [
                            -74.79166666668328,
                            40.74499999925064
                        ],
                        [
                            -74.61250000007493,
                            40.834166666758335
                        ],
                        [
                            -74.63333333396616,
                            40.854166666358594
                        ],
                        [
                            -74.88027777799704,
                            40.929444444305716
                        ],
                        [
                            -74.94111111108884,
                            40.944166666358626
                        ],
                        [
                            -74.98888888891105,
                            40.954166666158756
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 908,
                "artcc": "zny",
                "sector": "39",
                "sectype": "high",
                "Shape_Length": 4.526541968229972,
                "Shape_Area": 0.6354407447827606
            }
        },
        {
            "type": "Feature",
            "id": 909,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -74.18333333396617,
                            41.400000000099965
                        ],
                        [
                            -74.34444444495512,
                            41.48333333296699
                        ],
                        [
                            -74.43333333346652,
                            41.527777777222695
                        ],
                        [
                            -74.44444444475528,
                            41.43777777722272
                        ],
                        [
                            -74.58194444448048,
                            41.29972222220283
                        ],
                        [
                            -74.66361111146358,
                            41.21749999992511
                        ],
                        [
                            -74.85611111143862,
                            41.11027777769732
                        ],
                        [
                            -74.9863888887362,
                            41.03722222182813
                        ],
                        [
                            -75.14472222235264,
                            40.99888888851143
                        ],
                        [
                            -75.36805555601904,
                            40.9444444444556
                        ],
                        [
                            -75.37944444450545,
                            40.94499999975022
                        ],
                        [
                            -75.44444444455542,
                            40.958333333116855
                        ],
                        [
                            -75.46638888903595,
                            40.920277777897184
                        ],
                        [
                            -75.62861111151352,
                            40.88027777779723
                        ],
                        [
                            -75.59333333366635,
                            40.8149999996503
                        ],
                        [
                            -75.61500000004992,
                            40.74249999997505
                        ],
                        [
                            -76.00722222242757,
                            40.59444444425577
                        ],
                        [
                            -76.40138888878613,
                            40.457222221728216
                        ],
                        [
                            -76.24166666738273,
                            40.4297222216033
                        ],
                        [
                            -75.64166666678318,
                            40.33333333256729
                        ],
                        [
                            -75.03750000012485,
                            40.22499999975025
                        ],
                        [
                            -75.0277777784217,
                            40.09305555566942
                        ],
                        [
                            -74.88944444440557,
                            40.101666666783274
                        ],
                        [
                            -74.32083333369138,
                            40.665277777147764
                        ],
                        [
                            -73.877777778022,
                            40.96805555571939
                        ],
                        [
                            -73.87833333331662,
                            41.00000000000006
                        ],
                        [
                            -73.8958333336414,
                            41.25833333341666
                        ],
                        [
                            -74.02500000034968,
                            41.33333333326675
                        ],
                        [
                            -74.05833333331663,
                            41.33333333326675
                        ],
                        [
                            -74.18333333396617,
                            41.400000000099965
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 909,
                "artcc": "zny",
                "sector": "42",
                "sectype": "high",
                "Shape_Length": 6.32686688817419,
                "Shape_Area": 1.4510552089165825
            }
        },
        {
            "type": "Feature",
            "id": 910,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -76.2166666665334,
                            41.59166666668335
                        ],
                        [
                            -76.83138888918586,
                            41.73249999997506
                        ],
                        [
                            -77.41666666683318,
                            41.87500000005002
                        ],
                        [
                            -77.4555555554445,
                            41.82083333319184
                        ],
                        [
                            -77.56666666653342,
                            41.637499999625334
                        ],
                        [
                            -77.63333333336658,
                            41.555555555444585
                        ],
                        [
                            -77.24944444490518,
                            41.584999999550405
                        ],
                        [
                            -76.45833333391619,
                            41.395833333141866
                        ],
                        [
                            -75.9152777784467,
                            41.25277777777228
                        ],
                        [
                            -75.90500000054953,
                            41.22805555501992
                        ],
                        [
                            -75.88055555589415,
                            41.18805555491997
                        ],
                        [
                            -75.85111111178833,
                            41.15166666648355
                        ],
                        [
                            -75.81750000072441,
                            41.11999999940048
                        ],
                        [
                            -75.78222222287724,
                            41.092222222077964
                        ],
                        [
                            -75.74555555544447,
                            41.0683333327172
                        ],
                        [
                            -75.69444444495514,
                            41.041944444080855
                        ],
                        [
                            -75.44444444455542,
                            40.958333333116855
                        ],
                        [
                            -75.37944444450545,
                            40.94499999975022
                        ],
                        [
                            -75.36805555601904,
                            40.9444444444556
                        ],
                        [
                            -75.14472222235264,
                            40.99888888851143
                        ],
                        [
                            -74.9863888887362,
                            41.03722222182813
                        ],
                        [
                            -74.85611111143862,
                            41.11027777769732
                        ],
                        [
                            -74.86388888916088,
                            41.113888888461474
                        ],
                        [
                            -74.92916666730781,
                            41.14361111066427
                        ],
                        [
                            -75.17916666680821,
                            41.2499999995004
                        ],
                        [
                            -75.41388888896103,
                            41.3624999992756
                        ],
                        [
                            -75.56666666693309,
                            41.4152777774475
                        ],
                        [
                            -75.92500000014985,
                            41.51666666683326
                        ],
                        [
                            -76.09111111148854,
                            41.559722221503364
                        ],
                        [
                            -76.2166666665334,
                            41.59166666668335
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 910,
                "artcc": "zny",
                "sector": "49",
                "sectype": "high",
                "Shape_Length": 6.0235465201889165,
                "Shape_Area": 0.7375613043165882
            }
        },
        {
            "type": "Feature",
            "id": 913,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -74.482499999975,
                            40.12361111036449
                        ],
                        [
                            -74.45277777777221,
                            40.144444444255726
                        ],
                        [
                            -74.32777777802198,
                            40.30833333261728
                        ],
                        [
                            -74.41527777784711,
                            40.40277777767233
                        ],
                        [
                            -74.3500000005995,
                            40.57777777732264
                        ],
                        [
                            -74.32083333369138,
                            40.665277777147764
                        ],
                        [
                            -74.58277777787214,
                            40.8202777771977
                        ],
                        [
                            -74.61250000007493,
                            40.834166666758335
                        ],
                        [
                            -74.79166666668328,
                            40.74499999925064
                        ],
                        [
                            -75.12499999994998,
                            40.57777777732264
                        ],
                        [
                            -75.43833333361641,
                            40.417777777822266
                        ],
                        [
                            -75.52222222267739,
                            40.373611110764216
                        ],
                        [
                            -75.66250000067447,
                            40.31666666653348
                        ],
                        [
                            -75.80000000039968,
                            40.24444444405589
                        ],
                        [
                            -75.95833333401612,
                            40.16111111118886
                        ],
                        [
                            -75.83277777807194,
                            40.02194444378108
                        ],
                        [
                            -75.62611111133867,
                            40.03694444393102
                        ],
                        [
                            -75.55833333391621,
                            40.041666666183744
                        ],
                        [
                            -75.46944444450548,
                            39.97083333329175
                        ],
                        [
                            -75.34166666738275,
                            40.06666666613381
                        ],
                        [
                            -75.0277777784217,
                            40.09305555566942
                        ],
                        [
                            -74.88944444440557,
                            40.101666666783274
                        ],
                        [
                            -74.87055555629382,
                            40.10277777737258
                        ],
                        [
                            -74.78333333366635,
                            40.10972222170324
                        ],
                        [
                            -74.76388888936071,
                            40.085833333241794
                        ],
                        [
                            -74.71500000004994,
                            40.021111110389484
                        ],
                        [
                            -74.68472222255247,
                            39.983333333266785
                        ],
                        [
                            -74.63138888908594,
                            40.020277777897206
                        ],
                        [
                            -74.54916666680816,
                            40.07777777742257
                        ],
                        [
                            -74.482499999975,
                            40.12361111036449
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 913,
                "artcc": "zny",
                "sector": "55",
                "sectype": "high",
                "Shape_Length": 4.19509110952567,
                "Shape_Area": 0.7496934412740107
            }
        },
        {
            "type": "Feature",
            "id": 914,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -74.00000000039967,
                            40.15416666595894
                        ],
                        [
                            -73.69861111141364,
                            40.23888888841151
                        ],
                        [
                            -73.63611111153853,
                            40.331944443881014
                        ],
                        [
                            -73.5000000004996,
                            40.508333333116866
                        ],
                        [
                            -73.44166666668326,
                            40.58888888861139
                        ],
                        [
                            -73.29166666698308,
                            40.80138888818669
                        ],
                        [
                            -73.36138888928576,
                            40.84750000012497
                        ],
                        [
                            -73.37777777812192,
                            40.85833333331675
                        ],
                        [
                            -73.5513888890859,
                            41.14583333274214
                        ],
                        [
                            -73.87833333331662,
                            41.00000000000006
                        ],
                        [
                            -73.877777778022,
                            40.96805555571939
                        ],
                        [
                            -74.32083333369138,
                            40.665277777147764
                        ],
                        [
                            -74.88944444440557,
                            40.101666666783274
                        ],
                        [
                            -74.87055555629382,
                            40.10277777737258
                        ],
                        [
                            -74.78333333366635,
                            40.10972222170324
                        ],
                        [
                            -74.76388888936071,
                            40.085833333241794
                        ],
                        [
                            -74.71500000004994,
                            40.021111110389484
                        ],
                        [
                            -74.68472222255247,
                            39.983333333266785
                        ],
                        [
                            -74.00000000039967,
                            40.15416666595894
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 914,
                "artcc": "zny",
                "sector": "56",
                "sectype": "high",
                "Shape_Length": 4.146264425349471,
                "Shape_Area": 0.8421618442810637
            }
        },
        {
            "type": "Feature",
            "id": 917,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -73.69861111141364,
                            40.23888888841151
                        ],
                        [
                            -73.63611111153853,
                            40.331944443881014
                        ],
                        [
                            -73.88472222235265,
                            40.27083333269218
                        ],
                        [
                            -74.36555555604406,
                            40.16249999987514
                        ],
                        [
                            -74.4133333338662,
                            40.1788888887113
                        ],
                        [
                            -74.45277777777221,
                            40.144444444255726
                        ],
                        [
                            -74.482499999975,
                            40.12361111036449
                        ],
                        [
                            -74.54916666680816,
                            40.07777777742257
                        ],
                        [
                            -74.63138888908594,
                            40.020277777897206
                        ],
                        [
                            -74.68472222255247,
                            39.983333333266785
                        ],
                        [
                            -74.6972222225275,
                            39.97472222215288
                        ],
                        [
                            -74.74333333356645,
                            39.94277777787221
                        ],
                        [
                            -74.80416666665832,
                            39.89999999950044
                        ],
                        [
                            -74.83722222242756,
                            39.867222221828115
                        ],
                        [
                            -74.85277777787212,
                            39.84999999960036
                        ],
                        [
                            -74.89249999987504,
                            39.805555555344654
                        ],
                        [
                            -74.9400000004996,
                            39.751666666583446
                        ],
                        [
                            -75.03000000049957,
                            39.653333332667216
                        ],
                        [
                            -75.05833333401614,
                            39.622222221778145
                        ],
                        [
                            -74.80000000059948,
                            39.65277777737259
                        ],
                        [
                            -74.50000000029974,
                            39.65555555564441
                        ],
                        [
                            -74.45555555604403,
                            39.655833332842064
                        ],
                        [
                            -74.49861111161346,
                            39.593055554870034
                        ],
                        [
                            -74.19444444435561,
                            39.57916666620872
                        ],
                        [
                            -74.06083333349153,
                            39.66416666675832
                        ],
                        [
                            -73.8097222225025,
                            39.83333333266722
                        ],
                        [
                            -73.79999999990002,
                            39.951388888986116
                        ],
                        [
                            -73.73722222282726,
                            40.04083333279215
                        ],
                        [
                            -73.716666667033,
                            40.068611111014036
                        ],
                        [
                            -73.68583333334163,
                            40.10888888831158
                        ],
                        [
                            -73.6680555558192,
                            40.13611111123885
                        ],
                        [
                            -73.70888888931074,
                            40.14861111121388
                        ],
                        [
                            -73.74999999999994,
                            40.16111111118886
                        ],
                        [
                            -73.69861111141364,
                            40.23888888841151
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 917,
                "artcc": "zny",
                "sector": "68",
                "sectype": "high",
                "Shape_Length": 3.73926335852639,
                "Shape_Area": 0.5564531636053731
            }
        },
        {
            "type": "Feature",
            "id": 919,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -77.16472222245255,
                            40.88722222212789
                        ],
                        [
                            -77.90277777807194,
                            40.9916666660838
                        ],
                        [
                            -77.95000000059952,
                            41.11944444410585
                        ],
                        [
                            -78.12777777852165,
                            40.85833333331675
                        ],
                        [
                            -78.0972222229272,
                            40.81666666643355
                        ],
                        [
                            -77.98500000034971,
                            40.673888888261615
                        ],
                        [
                            -77.9669444447303,
                            40.61138888838656
                        ],
                        [
                            -77.94138888948561,
                            40.5216666664835
                        ],
                        [
                            -77.67222222287722,
                            40.50277777747249
                        ],
                        [
                            -77.30000000009989,
                            40.473055555269696
                        ],
                        [
                            -77.07472222245258,
                            40.457222221728216
                        ],
                        [
                            -76.85388888896102,
                            40.439166666108804
                        ],
                        [
                            -76.58611111103892,
                            40.42083333329174
                        ],
                        [
                            -76.51722222212783,
                            40.408888888611386
                        ],
                        [
                            -76.29739444489769,
                            40.37348888840154
                        ],
                        [
                            -76.25647222241508,
                            40.36684166640981
                        ],
                        [
                            -76.16361111116379,
                            40.35194444438065
                        ],
                        [
                            -76.03444444445552,
                            40.435000000050024
                        ],
                        [
                            -76.01805555561936,
                            40.533333333066935
                        ],
                        [
                            -76.00722222242757,
                            40.59444444425577
                        ],
                        [
                            -75.61500000004992,
                            40.74249999997505
                        ],
                        [
                            -75.59333333366635,
                            40.8149999996503
                        ],
                        [
                            -75.62861111151352,
                            40.88027777779723
                        ],
                        [
                            -76.15055555589413,
                            40.74249999997505
                        ],
                        [
                            -77.16472222245255,
                            40.88722222212789
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 919,
                "artcc": "zny",
                "sector": "73",
                "sectype": "high",
                "Shape_Length": 5.824952250846868,
                "Shape_Area": 0.9180922169033047
            }
        },
        {
            "type": "Feature",
            "id": 921,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -75.62861111151352,
                            40.88027777779723
                        ],
                        [
                            -75.46638888903595,
                            40.920277777897184
                        ],
                        [
                            -75.44444444455542,
                            40.958333333116855
                        ],
                        [
                            -75.44000000039966,
                            40.97277777707279
                        ],
                        [
                            -75.60583333364139,
                            41.15916666610883
                        ],
                        [
                            -75.9152777784467,
                            41.25277777777228
                        ],
                        [
                            -76.45833333391619,
                            41.395833333141866
                        ],
                        [
                            -77.24944444490518,
                            41.584999999550405
                        ],
                        [
                            -77.63333333336658,
                            41.555555555444585
                        ],
                        [
                            -77.64166666728283,
                            41.54166666678327
                        ],
                        [
                            -77.72500000014986,
                            41.41666666613378
                        ],
                        [
                            -77.80833333391621,
                            41.30833333331674
                        ],
                        [
                            -77.95000000059952,
                            41.11944444410585
                        ],
                        [
                            -77.90277777807194,
                            40.9916666660838
                        ],
                        [
                            -77.16472222245255,
                            40.88722222212789
                        ],
                        [
                            -76.15055555589413,
                            40.74249999997505
                        ],
                        [
                            -75.62861111151352,
                            40.88027777779723
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 921,
                "artcc": "zny",
                "sector": "75",
                "sectype": "high",
                "Shape_Length": 5.544024023946939,
                "Shape_Area": 1.3086405088789037
            }
        },
        {
            "type": "Feature",
            "id": 924,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -63.76805555581922,
                            29.429722222003022
                        ],
                        [
                            -63.23194444468032,
                            29.598611110714216
                        ],
                        [
                            -62.713055555769245,
                            29.845277777547437
                        ],
                        [
                            -62.46833333381625,
                            29.992499999875122
                        ],
                        [
                            -62.447499999925014,
                            30.009722222102937
                        ],
                        [
                            -62.21527777794705,
                            30.190833332692193
                        ],
                        [
                            -61.81027777839671,
                            30.57916666620872
                        ],
                        [
                            -61.52500000014987,
                            30.9608333325923
                        ],
                        [
                            -61.50055555549443,
                            31.0108333333917
                        ],
                        [
                            -61.261388889185866,
                            31.522777777072804
                        ],
                        [
                            -61.13166666718291,
                            32.0308333327921
                        ],
                        [
                            -61.099722222902244,
                            32.551111110389456
                        ],
                        [
                            -61.1911111115885,
                            33.0902777778972
                        ],
                        [
                            -61.38527777834673,
                            33.589444444405615
                        ],
                        [
                            -61.636666666533415,
                            33.99694444413086
                        ],
                        [
                            -61.68000000019981,
                            34.05666666663342
                        ],
                        [
                            -62.07333333316677,
                            34.48055555519477
                        ],
                        [
                            -62.56666666683316,
                            34.8330555555695
                        ],
                        [
                            -62.92944444510499,
                            35.03083333309189
                        ],
                        [
                            -63.11027777849665,
                            35.10111111068926
                        ],
                        [
                            -63.74527777794708,
                            35.3011111111889
                        ],
                        [
                            -64.40694444503009,
                            35.39472222195303
                        ],
                        [
                            -65.00888888961055,
                            35.387777777622375
                        ],
                        [
                            -65.31916666690813,
                            35.35666666673336
                        ],
                        [
                            -65.67499999994999,
                            35.28472222145342
                        ],
                        [
                            -66.27361111096394,
                            35.09388888826163
                        ],
                        [
                            -66.80944444490518,
                            34.82499999975022
                        ],
                        [
                            -67.29805555631884,
                            34.46055555559445
                        ],
                        [
                            -67.68638888893605,
                            34.041666666483536
                        ],
                        [
                            -67.97194444438054,
                            33.5919444436812
                        ],
                        [
                            -68.14166666738276,
                            33.178888888311576
                        ],
                        [
                            -68.25194444508003,
                            32.55583333264224
                        ],
                        [
                            -68.22277777817186,
                            32.0341666663586
                        ],
                        [
                            -68.098888889011,
                            31.525277777247652
                        ],
                        [
                            -67.89277777847167,
                            31.06888888821169
                        ],
                        [
                            -67.57749999992501,
                            30.619444444405644
                        ],
                        [
                            -67.1816666667832,
                            30.223333333166863
                        ],
                        [
                            -66.67138888898597,
                            29.859722221503375
                        ],
                        [
                            -66.14055555629386,
                            29.599166666008898
                        ],
                        [
                            -65.5677777777222,
                            29.42805555521977
                        ],
                        [
                            -64.97722222252753,
                            29.34277777747252
                        ],
                        [
                            -64.39388888886111,
                            29.34222222217784
                        ],
                        [
                            -63.76805555581922,
                            29.429722222003022
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 924,
                "artcc": "zny",
                "sector": "81",
                "sectype": "high",
                "Shape_Length": 20.7895781083706,
                "Shape_Area": 33.96549382793891
            }
        },
        {
            "type": "Feature",
            "id": 925,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -72.12611111113881,
                            36.808888888611364
                        ],
                        [
                            -72.47666666663332,
                            36.725277777647364
                        ],
                        [
                            -72.66611111113883,
                            36.70249999977523
                        ],
                        [
                            -74.49160000053956,
                            36.78383611104772
                        ],
                        [
                            -74.59972222290224,
                            36.78777777752248
                        ],
                        [
                            -74.93694444503006,
                            35.49694444383107
                        ],
                        [
                            -75.18999999999994,
                            35.3080555555195
                        ],
                        [
                            -75.68388888896101,
                            34.60027777739754
                        ],
                        [
                            -74.68111111178831,
                            33.729166666208755
                        ],
                        [
                            -74.26722222212783,
                            33.35749999962536
                        ],
                        [
                            -73.68972222220276,
                            33.756944444430644
                        ],
                        [
                            -71.99999999990001,
                            34.99999999940053
                        ],
                        [
                            -71.99999999990001,
                            36.33333333336668
                        ],
                        [
                            -72.12611111113881,
                            36.808888888611364
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 925,
                "artcc": "zny",
                "sector": "82",
                "sectype": "high",
                "Shape_Length": 11.509477623211612,
                "Shape_Area": 8.305858956134152
            }
        },
        {
            "type": "Feature",
            "id": 926,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -74.26722222212783,
                            33.35749999962536
                        ],
                        [
                            -74.68111111178831,
                            33.729166666208755
                        ],
                        [
                            -75.68388888896101,
                            34.60027777739754
                        ],
                        [
                            -76.48111111178832,
                            33.41916666610882
                        ],
                        [
                            -76.77944444440556,
                            32.967777777422555
                        ],
                        [
                            -77.00000000069946,
                            32.262222222277785
                        ],
                        [
                            -77.00000000069946,
                            32.00000000000006
                        ],
                        [
                            -77.00000000069946,
                            31.666666666733363
                        ],
                        [
                            -77.00000000069946,
                            31.583333332967015
                        ],
                        [
                            -74.26722222212783,
                            33.35749999962536
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 926,
                "artcc": "zny",
                "sector": "83",
                "sectype": "high",
                "Shape_Length": 8.526928985339046,
                "Shape_Area": 3.61784907427386
            }
        },
        {
            "type": "Feature",
            "id": 928,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -72.12611111113881,
                            36.808888888611364
                        ],
                        [
                            -72.05611111073915,
                            36.82666666703312
                        ],
                        [
                            -71.64750000042466,
                            36.9536111107642
                        ],
                        [
                            -71.25111111108885,
                            37.108333333616486
                        ],
                        [
                            -70.87388888886107,
                            37.288888888911174
                        ],
                        [
                            -70.51555555564431,
                            37.49527777754747
                        ],
                        [
                            -70.17638888863627,
                            37.72166666668335
                        ],
                        [
                            -69.86361111116383,
                            37.972222222377695
                        ],
                        [
                            -69.64463055555564,
                            38.17989722247887
                        ],
                        [
                            -71.25702222179808,
                            39.47783333334172
                        ],
                        [
                            -71.56277777797203,
                            39.64499999965034
                        ],
                        [
                            -72.49999999980014,
                            40.07222222177819
                        ],
                        [
                            -72.6972222220279,
                            40.00000000019992
                        ],
                        [
                            -72.86527777824682,
                            39.85222222257755
                        ],
                        [
                            -72.88361111106389,
                            39.73333333286706
                        ],
                        [
                            -72.49999999980014,
                            39.3372222225276
                        ],
                        [
                            -72.26999999990005,
                            39.09666666663344
                        ],
                        [
                            -72.49999999980014,
                            38.76666666693319
                        ],
                        [
                            -72.80000000009989,
                            38.333333332967015
                        ],
                        [
                            -72.83388888926078,
                            38.283333333066935
                        ],
                        [
                            -73.86111111108886,
                            38.73750000002502
                        ],
                        [
                            -73.98333333346653,
                            38.541666666483536
                        ],
                        [
                            -73.0083333336164,
                            37.949999999800184
                        ],
                        [
                            -72.66666666643346,
                            37.22749999962531
                        ],
                        [
                            -74.45611388934446,
                            37.09358611131006
                        ],
                        [
                            -74.59972222200292,
                            37.08166666688322
                        ],
                        [
                            -74.59972222200292,
                            36.78777777752248
                        ],
                        [
                            -74.49159999964024,
                            36.78383611104772
                        ],
                        [
                            -72.66611111113883,
                            36.70249999977523
                        ],
                        [
                            -72.47666666663332,
                            36.725277777647364
                        ],
                        [
                            -72.12611111113881,
                            36.808888888611364
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 928,
                "artcc": "zny",
                "sector": "86",
                "sectype": "high",
                "Shape_Length": 16.75689474902945,
                "Shape_Area": 7.410913147451203
            }
        },
        {
            "type": "Feature",
            "id": 945,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -122.37166666728285,
                            37.531944444080864
                        ],
                        [
                            -122.47638888933574,
                            37.523611111063985
                        ],
                        [
                            -122.46250000067448,
                            37.34861111051441
                        ],
                        [
                            -122.4030555562689,
                            37.22888888831159
                        ],
                        [
                            -122.34055555549446,
                            37.10277777707279
                        ],
                        [
                            -121.55000000069941,
                            36.67222222227781
                        ],
                        [
                            -121.15388888946063,
                            36.38916666610879
                        ],
                        [
                            -120.23194444498012,
                            35.71666666673332
                        ],
                        [
                            -120.1166666669331,
                            35.749999999700265
                        ],
                        [
                            -119.91750000072443,
                            35.71972222220285
                        ],
                        [
                            -119.9811111111888,
                            35.8077777773226
                        ],
                        [
                            -119.76694444483024,
                            35.898333332617256
                        ],
                        [
                            -119.72138888908592,
                            35.917777777822266
                        ],
                        [
                            -119.80611111153854,
                            36.06722222222783
                        ],
                        [
                            -120.05500000044964,
                            36.501388888686336
                        ],
                        [
                            -120.14416666705802,
                            36.6569444440309
                        ],
                        [
                            -120.18972222280229,
                            36.73611111083915
                        ],
                        [
                            -120.2880555558192,
                            36.89388888826164
                        ],
                        [
                            -120.52500000004994,
                            37.337500000124976
                        ],
                        [
                            -120.88888888891108,
                            37.291666666283675
                        ],
                        [
                            -121.22361111176338,
                            37.4183333328171
                        ],
                        [
                            -121.30916666670828,
                            37.450555555194796
                        ],
                        [
                            -121.47499999995,
                            37.51249999977523
                        ],
                        [
                            -121.87916666700806,
                            37.50749999942548
                        ],
                        [
                            -122.3316666671829,
                            37.49972222170322
                        ],
                        [
                            -122.37166666728285,
                            37.531944444080864
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 945,
                "artcc": "zoa",
                "sector": "11",
                "sectype": "high",
                "Shape_Length": 7.328302826811502,
                "Shape_Area": 2.2837617672203225
            }
        },
        {
            "type": "Feature",
            "id": 946,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -122.33166666628358,
                            37.49972222260254
                        ],
                        [
                            -122.37166666638353,
                            37.531944444080864
                        ],
                        [
                            -122.47638888933574,
                            37.523611111063985
                        ],
                        [
                            -122.46249999977516,
                            37.34861111141373
                        ],
                        [
                            -122.40305555536958,
                            37.22888888921091
                        ],
                        [
                            -122.34055555549446,
                            37.10277777797211
                        ],
                        [
                            -121.5499999998001,
                            36.67222222227781
                        ],
                        [
                            -121.15388888856131,
                            36.38916666700811
                        ],
                        [
                            -120.2319444440808,
                            35.71666666673332
                        ],
                        [
                            -120.1166666669331,
                            35.749999999700265
                        ],
                        [
                            -119.9811111111888,
                            35.80777777822192
                        ],
                        [
                            -119.76694444483024,
                            35.898333333516575
                        ],
                        [
                            -119.72138888908592,
                            35.917777777822266
                        ],
                        [
                            -119.80611111153854,
                            36.06722222222783
                        ],
                        [
                            -120.05500000044964,
                            36.501388888686336
                        ],
                        [
                            -120.14416666705802,
                            36.6569444440309
                        ],
                        [
                            -120.18972222190297,
                            36.73611111083915
                        ],
                        [
                            -120.2880555558192,
                            36.89388888916096
                        ],
                        [
                            -120.52500000004994,
                            37.337500000124976
                        ],
                        [
                            -120.88888888891108,
                            37.291666666283675
                        ],
                        [
                            -121.22361111086406,
                            37.41833333371642
                        ],
                        [
                            -121.30916666670828,
                            37.450555555194796
                        ],
                        [
                            -121.47499999995,
                            37.51249999977523
                        ],
                        [
                            -121.87916666700806,
                            37.5075000003248
                        ],
                        [
                            -122.33166666628358,
                            37.49972222260254
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 946,
                "artcc": "zoa",
                "sector": "13",
                "sectype": "high",
                "Shape_Length": 7.165574627257688,
                "Shape_Area": 2.2759559015946365
            }
        },
        {
            "type": "Feature",
            "id": 947,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -120.85000000029976,
                            35.533333333166865
                        ],
                        [
                            -120.56249999997499,
                            35.61666666603384
                        ],
                        [
                            -120.47500000014986,
                            35.641666665983905
                        ],
                        [
                            -120.23194444498012,
                            35.71666666673332
                        ],
                        [
                            -121.15388888946063,
                            36.38916666610879
                        ],
                        [
                            -121.55000000069941,
                            36.67222222227781
                        ],
                        [
                            -122.34055555549446,
                            37.10277777707279
                        ],
                        [
                            -122.4030555562689,
                            37.22888888831159
                        ],
                        [
                            -122.46250000067448,
                            37.34861111051441
                        ],
                        [
                            -122.47638888933574,
                            37.523611111063985
                        ],
                        [
                            -122.37166666728285,
                            37.531944444080864
                        ],
                        [
                            -122.3761111114386,
                            37.60277777787218
                        ],
                        [
                            -122.38277777767229,
                            37.61944444390599
                        ],
                        [
                            -122.39722222252749,
                            37.73305555516981
                        ],
                        [
                            -122.85833333381629,
                            37.73416666665838
                        ],
                        [
                            -122.85638888893607,
                            37.63166666668337
                        ],
                        [
                            -122.85277777817191,
                            37.43611111123886
                        ],
                        [
                            -122.837499999925,
                            37.25555555504491
                        ],
                        [
                            -122.93027777819685,
                            36.98333333296699
                        ],
                        [
                            -122.90638888883609,
                            36.9494444438061
                        ],
                        [
                            -122.5319444448802,
                            36.39222222157832
                        ],
                        [
                            -122.40253611145232,
                            36.19802222144841
                        ],
                        [
                            -122.39277777837174,
                            36.18333333276712
                        ],
                        [
                            -122.41328888877115,
                            36.18140833302817
                        ],
                        [
                            -124.19999999990006,
                            36.00000000009999
                        ],
                        [
                            -123.91388888916089,
                            35.533333333166865
                        ],
                        [
                            -123.87500000054956,
                            35.46666666633365
                        ],
                        [
                            -123.55000000029975,
                            34.94166666648351
                        ],
                        [
                            -123.53333333336661,
                            34.9166666665335
                        ],
                        [
                            -123.24999999999994,
                            34.4999999995004
                        ],
                        [
                            -121.23722222232766,
                            35.37166666598392
                        ],
                        [
                            -120.85000000029976,
                            35.533333333166865
                        ]
                    ],
                    [
                        [
                            -123.3750000006495,
                            35.513888888861175
                        ],
                        [
                            -123.35833333371636,
                            35.505555554944976
                        ],
                        [
                            -123.3750000006495,
                            35.49722222192804
                        ],
                        [
                            -123.3750000006495,
                            35.513888888861175
                        ]
                    ],
                    [
                        [
                            -122.00833333371634,
                            35.5083333332168
                        ],
                        [
                            -121.9916666667832,
                            35.4999999993006
                        ],
                        [
                            -122.00833333371634,
                            35.49166666628366
                        ],
                        [
                            -122.00833333371634,
                            35.5083333332168
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 947,
                "artcc": "zoa",
                "sector": "14",
                "sectype": "high",
                "Shape_Length": 12.436548203497114,
                "Shape_Area": 5.0522562005563065
            }
        },
        {
            "type": "Feature",
            "id": 948,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -119.53249999987509,
                            35.99722222182811
                        ],
                        [
                            -119.47333333356647,
                            36.02194444368121
                        ],
                        [
                            -119.22611111143863,
                            36.11194444368118
                        ],
                        [
                            -119.16666666703304,
                            36.133333332867096
                        ],
                        [
                            -119.03888888901099,
                            36.133333332867096
                        ],
                        [
                            -119.00000000039967,
                            36.133333332867096
                        ],
                        [
                            -118.58333333336662,
                            36.133333332867096
                        ],
                        [
                            -118.58333333336662,
                            36.57083333289205
                        ],
                        [
                            -118.58333333336662,
                            36.76249999947544
                        ],
                        [
                            -118.58333333336662,
                            37.0805555553946
                        ],
                        [
                            -118.58416666675822,
                            37.19999999950045
                        ],
                        [
                            -119.03333333336661,
                            37.51666666673333
                        ],
                        [
                            -120.18638888923584,
                            37.379999999500455
                        ],
                        [
                            -120.52500000004994,
                            37.337500000124976
                        ],
                        [
                            -120.2880555558192,
                            36.89388888826164
                        ],
                        [
                            -120.18972222280229,
                            36.73611111083915
                        ],
                        [
                            -120.14416666705802,
                            36.6569444440309
                        ],
                        [
                            -120.05500000044964,
                            36.501388888686336
                        ],
                        [
                            -119.80611111153854,
                            36.06722222222783
                        ],
                        [
                            -119.72138888908592,
                            35.917777777822266
                        ],
                        [
                            -119.53249999987509,
                            35.99722222182811
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 948,
                "artcc": "zoa",
                "sector": "15",
                "sectype": "high",
                "Shape_Length": 5.9289878736887855,
                "Shape_Area": 2.1490101473439522
            }
        },
        {
            "type": "Feature",
            "id": 951,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -121.87916666700806,
                            37.50749999942548
                        ],
                        [
                            -121.47499999995,
                            37.51249999977523
                        ],
                        [
                            -121.1619444443806,
                            37.799166666708345
                        ],
                        [
                            -121.08333333376629,
                            37.90833333291704
                        ],
                        [
                            -120.53055555569432,
                            37.99083333329173
                        ],
                        [
                            -120.32888888931075,
                            38.02027777739755
                        ],
                        [
                            -120.17884722222152,
                            38.04095833266098
                        ],
                        [
                            -119.86666666653338,
                            38.08333333256729
                        ],
                        [
                            -119.15999999990004,
                            38.180277777797244
                        ],
                        [
                            -119.18333333396617,
                            38.30000000000007
                        ],
                        [
                            -119.31250000067445,
                            38.84249999927562
                        ],
                        [
                            -119.37205555631886,
                            38.892636110668036
                        ],
                        [
                            -120.26416666735776,
                            39.09611111043944
                        ],
                        [
                            -120.43583333344156,
                            39.22722222202799
                        ],
                        [
                            -120.85805555611898,
                            39.133611110364484
                        ],
                        [
                            -121.13916666740772,
                            38.96361111106398
                        ],
                        [
                            -121.75138888898601,
                            38.58805555561946
                        ],
                        [
                            -121.77222222287725,
                            38.50027777769736
                        ],
                        [
                            -121.80111111168844,
                            38.37777777722272
                        ],
                        [
                            -121.94694444443053,
                            38.290277777397534
                        ],
                        [
                            -122.13222222287726,
                            38.17499999935052
                        ],
                        [
                            -122.18277777807197,
                            38.14333333316682
                        ],
                        [
                            -122.2797222224026,
                            38.08277777727267
                        ],
                        [
                            -122.41666666683318,
                            37.999999999700265
                        ],
                        [
                            -122.39722222252749,
                            37.73305555516981
                        ],
                        [
                            -122.38277777767229,
                            37.61944444390599
                        ],
                        [
                            -122.3761111114386,
                            37.60277777787218
                        ],
                        [
                            -122.37166666728285,
                            37.531944444080864
                        ],
                        [
                            -122.3316666671829,
                            37.49972222170322
                        ],
                        [
                            -121.87916666700806,
                            37.50749999942548
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 951,
                "artcc": "zoa",
                "sector": "29",
                "sectype": "high",
                "Shape_Length": 8.186804633850578,
                "Shape_Area": 3.108899181684095
            }
        },
        {
            "type": "Feature",
            "id": 952,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -119.31249999977513,
                            38.84250000017494
                        ],
                        [
                            -119.18333333306686,
                            38.30000000000007
                        ],
                        [
                            -119.15999999990004,
                            38.180277777797244
                        ],
                        [
                            -117.28138888928578,
                            38.41305555596921
                        ],
                        [
                            -117.29166666628356,
                            38.777777778221946
                        ],
                        [
                            -117.3074999998251,
                            38.96888888861139
                        ],
                        [
                            -117.32500000014983,
                            39.2069444443307
                        ],
                        [
                            -117.33333333316676,
                            39.4166666665335
                        ],
                        [
                            -118.90500000004994,
                            39.057777778022114
                        ],
                        [
                            -119.37205555541954,
                            38.892636110668036
                        ],
                        [
                            -119.31249999977513,
                            38.84250000017494
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 952,
                "artcc": "zoa",
                "sector": "30",
                "sectype": "high",
                "Shape_Length": 5.763198821104533,
                "Shape_Area": 1.7615148462969596
            }
        },
        {
            "type": "Feature",
            "id": 953,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -121.88333333396616,
                            41.182777777372564
                        ],
                        [
                            -122.00000000069946,
                            41.21666666653351
                        ],
                        [
                            -122.27500000014987,
                            41.291666666383605
                        ],
                        [
                            -122.41666666683318,
                            41.33333333326675
                        ],
                        [
                            -122.7444444444555,
                            41.33333333326675
                        ],
                        [
                            -122.52666666733279,
                            39.22277777787218
                        ],
                        [
                            -122.41666666683318,
                            37.999999999700265
                        ],
                        [
                            -122.2797222224026,
                            38.08277777727267
                        ],
                        [
                            -122.18277777807197,
                            38.14333333316682
                        ],
                        [
                            -122.13222222287726,
                            38.17499999935052
                        ],
                        [
                            -121.94694444443053,
                            38.290277777397534
                        ],
                        [
                            -121.80111111168844,
                            38.37777777722272
                        ],
                        [
                            -121.77222222287725,
                            38.50027777769736
                        ],
                        [
                            -121.75138888898601,
                            38.58805555561946
                        ],
                        [
                            -121.13916666740772,
                            38.96361111106398
                        ],
                        [
                            -120.85805555611898,
                            39.133611110364484
                        ],
                        [
                            -120.43583333344156,
                            39.22722222202799
                        ],
                        [
                            -120.59861111121376,
                            39.93138888848648
                        ],
                        [
                            -120.82972222260247,
                            41.00027777719771
                        ],
                        [
                            -120.99999999999994,
                            41.00000000000006
                        ],
                        [
                            -121.02361111126373,
                            41.00000000000006
                        ],
                        [
                            -121.13333333366637,
                            41.00000000000006
                        ],
                        [
                            -121.25000000039967,
                            41.00000000000006
                        ],
                        [
                            -121.88333333396616,
                            41.182777777372564
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 953,
                "artcc": "zoa",
                "sector": "31",
                "sectype": "high",
                "Shape_Length": 9.544797040133735,
                "Shape_Area": 4.903724807440801
            }
        },
        {
            "type": "Feature",
            "id": 954,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -121.75138888898601,
                            38.58805555561946
                        ],
                        [
                            -121.77222222197793,
                            38.50027777769736
                        ],
                        [
                            -121.80111111078912,
                            38.37777777812204
                        ],
                        [
                            -121.94694444443053,
                            38.290277777397534
                        ],
                        [
                            -122.13222222197794,
                            38.17500000024984
                        ],
                        [
                            -122.18277777807197,
                            38.14333333316682
                        ],
                        [
                            -122.2797222224026,
                            38.08277777817199
                        ],
                        [
                            -122.41666666683318,
                            37.999999999700265
                        ],
                        [
                            -122.39722222252749,
                            37.73305555516981
                        ],
                        [
                            -122.38277777767229,
                            37.61944444480531
                        ],
                        [
                            -122.3761111114386,
                            37.60277777787218
                        ],
                        [
                            -122.37166666638353,
                            37.531944444080864
                        ],
                        [
                            -122.33166666628358,
                            37.49972222260254
                        ],
                        [
                            -121.87916666700806,
                            37.5075000003248
                        ],
                        [
                            -121.47499999995,
                            37.51249999977523
                        ],
                        [
                            -121.1619444443806,
                            37.799166666708345
                        ],
                        [
                            -121.08333333286697,
                            37.90833333291704
                        ],
                        [
                            -120.53055555569432,
                            37.99083333329173
                        ],
                        [
                            -120.32888888931075,
                            38.02027777739755
                        ],
                        [
                            -120.17884722222152,
                            38.0409583335603
                        ],
                        [
                            -119.86666666653338,
                            38.08333333346661
                        ],
                        [
                            -119.15999999990004,
                            38.180277777797244
                        ],
                        [
                            -119.18333333306686,
                            38.30000000000007
                        ],
                        [
                            -119.31249999977513,
                            38.84250000017494
                        ],
                        [
                            -119.37205555541954,
                            38.892636110668036
                        ],
                        [
                            -120.26416666645844,
                            39.09611111133876
                        ],
                        [
                            -120.43583333344156,
                            39.22722222202799
                        ],
                        [
                            -120.85805555521966,
                            39.133611111263804
                        ],
                        [
                            -121.1391666665084,
                            38.96361111106398
                        ],
                        [
                            -121.75138888898601,
                            38.58805555561946
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 954,
                "artcc": "zoa",
                "sector": "32",
                "sectype": "high",
                "Shape_Length": 8.186804632804186,
                "Shape_Area": 3.1088991818098934
            }
        },
        {
            "type": "Feature",
            "id": 955,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -117.28138888928578,
                            38.41305555596921
                        ],
                        [
                            -119.15999999990004,
                            38.180277777797244
                        ],
                        [
                            -119.03333333336661,
                            37.51666666673333
                        ],
                        [
                            -118.58416666675822,
                            37.20000000039977
                        ],
                        [
                            -118.43333333366638,
                            37.20000000039977
                        ],
                        [
                            -117.99999999970021,
                            37.20000000039977
                        ],
                        [
                            -117.33333333316676,
                            37.20000000039977
                        ],
                        [
                            -117.00833333291695,
                            37.36666666703309
                        ],
                        [
                            -117.07583333314176,
                            37.441666666883236
                        ],
                        [
                            -117.09472222215277,
                            37.549999999700276
                        ],
                        [
                            -117.09472222215277,
                            37.88333333296697
                        ],
                        [
                            -117.26666666633355,
                            38.08333333346661
                        ],
                        [
                            -117.27805555571928,
                            38.29583333304191
                        ],
                        [
                            -117.28138888928578,
                            38.41305555596921
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 955,
                "artcc": "zoa",
                "sector": "33",
                "sectype": "high",
                "Shape_Length": 5.8722469185685915,
                "Shape_Area": 2.0490464118735314
            }
        },
        {
            "type": "Feature",
            "id": 956,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -120.52500000004994,
                            37.337500000124976
                        ],
                        [
                            -120.18638888923584,
                            37.379999999500455
                        ],
                        [
                            -119.03333333336661,
                            37.51666666673333
                        ],
                        [
                            -119.15999999990004,
                            38.180277777797244
                        ],
                        [
                            -119.86666666653338,
                            38.08333333256729
                        ],
                        [
                            -120.17884722222152,
                            38.04095833266098
                        ],
                        [
                            -120.32888888931075,
                            38.02027777739755
                        ],
                        [
                            -120.53055555569432,
                            37.99083333329173
                        ],
                        [
                            -121.08333333376629,
                            37.90833333291704
                        ],
                        [
                            -121.1619444443806,
                            37.799166666708345
                        ],
                        [
                            -121.47499999995,
                            37.51249999977523
                        ],
                        [
                            -121.30916666670828,
                            37.450555555194796
                        ],
                        [
                            -121.22361111176338,
                            37.4183333328171
                        ],
                        [
                            -120.88888888891108,
                            37.291666666283675
                        ],
                        [
                            -120.52500000004994,
                            37.337500000124976
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 956,
                "artcc": "zoa",
                "sector": "34",
                "sectype": "high",
                "Shape_Length": 5.672582021210129,
                "Shape_Area": 1.404869203205664
            }
        },
        {
            "type": "Feature",
            "id": 959,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -122.7444444444555,
                            41.33333333326675
                        ],
                        [
                            -123.53333333336661,
                            41.33333333326675
                        ],
                        [
                            -123.53333333336661,
                            40.38749999942547
                        ],
                        [
                            -123.83333333366636,
                            40.21666666673332
                        ],
                        [
                            -124.66666666683318,
                            40.21666666673332
                        ],
                        [
                            -125.33333333336662,
                            40.21666666673332
                        ],
                        [
                            -125.8333333332667,
                            40.46666666623372
                        ],
                        [
                            -126.87499999994998,
                            40.97222222177817
                        ],
                        [
                            -126.89999999990005,
                            40.98333333306692
                        ],
                        [
                            -127.00000000059953,
                            40.83333333336668
                        ],
                        [
                            -127.00000000059953,
                            40.63083333269219
                        ],
                        [
                            -126.99166666668327,
                            40.62527777704781
                        ],
                        [
                            -127.00000000059953,
                            40.619722222302755
                        ],
                        [
                            -127.00000000059953,
                            40.304444443756154
                        ],
                        [
                            -126.99166666668327,
                            40.298888889011096
                        ],
                        [
                            -127.00000000059953,
                            40.29333333336672
                        ],
                        [
                            -127.00000000059953,
                            40.15694444423076
                        ],
                        [
                            -126.99166666668327,
                            40.15138888858638
                        ],
                        [
                            -127.00000000059953,
                            40.145833332942004
                        ],
                        [
                            -127.00000000059953,
                            40.01194444398095
                        ],
                        [
                            -126.99166666668327,
                            40.00638888833657
                        ],
                        [
                            -127.00000000059953,
                            39.9999999993006
                        ],
                        [
                            -127.00000000059953,
                            39.224999999950114
                        ],
                        [
                            -125.87611111163847,
                            38.86666666673335
                        ],
                        [
                            -124.5000000001998,
                            38.965277777847234
                        ],
                        [
                            -123.88583333374135,
                            38.43083333259227
                        ],
                        [
                            -123.87472222245259,
                            38.422777777672366
                        ],
                        [
                            -123.31388888946066,
                            37.947222221528364
                        ],
                        [
                            -123.29722222252752,
                            37.93333333286705
                        ],
                        [
                            -123.16666666713297,
                            37.77361111056433
                        ],
                        [
                            -123.02555555574429,
                            37.750833332692196
                        ],
                        [
                            -122.99888888901097,
                            37.746666666633416
                        ],
                        [
                            -122.85833333381629,
                            37.73416666665838
                        ],
                        [
                            -122.39722222252749,
                            37.73305555516981
                        ],
                        [
                            -122.41666666683318,
                            37.999999999700265
                        ],
                        [
                            -122.52666666733279,
                            39.22277777787218
                        ],
                        [
                            -122.7444444444555,
                            41.33333333326675
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 959,
                "artcc": "zoa",
                "sector": "36",
                "sectype": "high",
                "Shape_Length": 15.88785524054874,
                "Shape_Area": 8.821299383103062
            }
        },
        {
            "type": "Feature",
            "id": 963,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -120.82972222260247,
                            41.00027777809703
                        ],
                        [
                            -120.59861111121376,
                            39.93138888848648
                        ],
                        [
                            -120.43583333344156,
                            39.22722222202799
                        ],
                        [
                            -120.26416666645844,
                            39.09611111133876
                        ],
                        [
                            -119.37205555541954,
                            38.892636110668036
                        ],
                        [
                            -118.90500000004994,
                            39.057777778022114
                        ],
                        [
                            -117.33333333316676,
                            39.4166666665335
                        ],
                        [
                            -117.36666666703303,
                            39.933333333366704
                        ],
                        [
                            -117.7322222217781,
                            40.121111111088965
                        ],
                        [
                            -119.50000000029974,
                            41.00000000000006
                        ],
                        [
                            -120.82972222260247,
                            41.00027777809703
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 963,
                "artcc": "zoa",
                "sector": "43",
                "sectype": "high",
                "Shape_Length": 9.287499289358202,
                "Shape_Area": 4.999294970934638
            }
        },
        {
            "type": "Feature",
            "id": 965,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -120.82972222260247,
                            41.00027777809703
                        ],
                        [
                            -120.59861111121376,
                            39.93138888848648
                        ],
                        [
                            -120.43583333344156,
                            39.22722222202799
                        ],
                        [
                            -120.26416666645844,
                            39.09611111133876
                        ],
                        [
                            -119.37205555541954,
                            38.892636110668036
                        ],
                        [
                            -118.90500000004994,
                            39.057777778022114
                        ],
                        [
                            -117.33333333316676,
                            39.4166666665335
                        ],
                        [
                            -117.36666666703303,
                            39.933333333366704
                        ],
                        [
                            -117.7322222217781,
                            40.121111111088965
                        ],
                        [
                            -119.50000000029974,
                            41.00000000000006
                        ],
                        [
                            -120.82972222260247,
                            41.00027777809703
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 965,
                "artcc": "zoa",
                "sector": "45",
                "sectype": "high",
                "Shape_Length": 9.287499289358202,
                "Shape_Area": 4.999294970934638
            }
        },
        {
            "type": "Feature",
            "id": 967,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -118.43333333366638,
                            37.19999999950045
                        ],
                        [
                            -118.00000000059953,
                            37.19999999950045
                        ],
                        [
                            -117.33333333316676,
                            37.19999999950045
                        ],
                        [
                            -117.00833333381627,
                            37.36666666613377
                        ],
                        [
                            -117.07583333314176,
                            37.44166666598392
                        ],
                        [
                            -117.09472222215277,
                            37.549999999700276
                        ],
                        [
                            -117.09472222215277,
                            37.88333333296697
                        ],
                        [
                            -117.26666666723287,
                            38.08333333256729
                        ],
                        [
                            -117.27805555571928,
                            38.29583333304191
                        ],
                        [
                            -117.28138888928578,
                            38.41305555506989
                        ],
                        [
                            -117.29166666718294,
                            38.777777777322626
                        ],
                        [
                            -117.30750000072442,
                            38.96888888861139
                        ],
                        [
                            -117.32500000014983,
                            39.2069444443307
                        ],
                        [
                            -117.33333333316676,
                            39.4166666665335
                        ],
                        [
                            -118.90500000004994,
                            39.057777777122794
                        ],
                        [
                            -119.37205555631886,
                            38.892636110668036
                        ],
                        [
                            -119.31250000067445,
                            38.84249999927562
                        ],
                        [
                            -119.18333333396617,
                            38.30000000000007
                        ],
                        [
                            -119.15999999990004,
                            38.180277777797244
                        ],
                        [
                            -119.03333333336661,
                            37.51666666673333
                        ],
                        [
                            -118.58416666675822,
                            37.19999999950045
                        ],
                        [
                            -118.43333333366638,
                            37.19999999950045
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 967,
                "artcc": "zoa",
                "sector": "46",
                "sectype": "high",
                "Shape_Length": 7.849490010190037,
                "Shape_Area": 3.8105612587122515
            }
        },
        {
            "type": "Feature",
            "id": 978,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -83.3458333336414,
                            41.1541666666584
                        ],
                        [
                            -83.25435555596408,
                            41.38881388894987
                        ],
                        [
                            -83.24555555574426,
                            41.41138888858637
                        ],
                        [
                            -83.22187500063075,
                            41.56027777769731
                        ],
                        [
                            -83.25130833361266,
                            41.71207499966158
                        ],
                        [
                            -83.22888888881113,
                            41.80879166640233
                        ],
                        [
                            -83.19751944496636,
                            41.94413055568066
                        ],
                        [
                            -83.1253472220966,
                            42.06904722155207
                        ],
                        [
                            -83.13023055609523,
                            42.17953611060307
                        ],
                        [
                            -83.3885277781344,
                            42.19588888896112
                        ],
                        [
                            -83.74508055622266,
                            42.21869166618751
                        ],
                        [
                            -83.7831666668082,
                            42.063363888432775
                        ],
                        [
                            -83.83265833391494,
                            41.86087499978146
                        ],
                        [
                            -83.8483000003447,
                            41.793391666692116
                        ],
                        [
                            -83.89696944428437,
                            41.69645833258602
                        ],
                        [
                            -84.09722222262741,
                            41.43750000002501
                        ],
                        [
                            -84.57338055566805,
                            41.22056111050688
                        ],
                        [
                            -84.70916388900224,
                            41.15869999980521
                        ],
                        [
                            -84.70000000059952,
                            40.90833333321683
                        ],
                        [
                            -84.34722222212781,
                            40.863055555569474
                        ],
                        [
                            -83.7458333337413,
                            40.78333333256728
                        ],
                        [
                            -83.64999999999998,
                            40.737499999625356
                        ],
                        [
                            -83.3458333336414,
                            41.1541666666584
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 978,
                "artcc": "zob",
                "sector": "02",
                "sectype": "high",
                "Shape_Length": 5.071521671308194,
                "Shape_Area": 1.2264167292228367
            }
        },
        {
            "type": "Feature",
            "id": 979,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -81.79444444445551,
                            41.41749999952543
                        ],
                        [
                            -81.9111111111888,
                            41.61111111098904
                        ],
                        [
                            -82.04315833339035,
                            41.56431666628117
                        ],
                        [
                            -82.51271944494641,
                            41.37229999974522
                        ],
                        [
                            -82.54245000026725,
                            41.36961388847021
                        ],
                        [
                            -82.79858888896604,
                            41.3427722217607
                        ],
                        [
                            -82.84833333391617,
                            41.345833333241785
                        ],
                        [
                            -83.02275555602404,
                            41.36690555494744
                        ],
                        [
                            -83.25435555596408,
                            41.38881388894987
                        ],
                        [
                            -83.3458333336414,
                            41.1541666666584
                        ],
                        [
                            -83.64999999999998,
                            40.737499999625356
                        ],
                        [
                            -83.50000000029974,
                            40.55833333301695
                        ],
                        [
                            -83.42500000044964,
                            40.47083333319182
                        ],
                        [
                            -83.33333333366636,
                            40.508333333116866
                        ],
                        [
                            -83.16222222287723,
                            40.52055555499493
                        ],
                        [
                            -82.74166666698306,
                            40.90833333321683
                        ],
                        [
                            -82.61694444443054,
                            41.012777777172744
                        ],
                        [
                            -82.52792777844417,
                            41.085949999392994
                        ],
                        [
                            -82.5176277782993,
                            41.086861110651796
                        ],
                        [
                            -82.44874166739402,
                            41.108027777360064
                        ],
                        [
                            -81.95833333371633,
                            41.25694444383106
                        ],
                        [
                            -81.79444444445551,
                            41.41749999952543
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 979,
                "artcc": "zob",
                "sector": "03",
                "sectype": "high",
                "Shape_Length": 4.680534356741397,
                "Shape_Area": 0.8222752069545511
            }
        },
        {
            "type": "Feature",
            "id": 980,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -81.4894000002098,
                            40.78048611044568
                        ],
                        [
                            -81.53379444485773,
                            41.003311110419475
                        ],
                        [
                            -81.54916944431437,
                            41.08049444386353
                        ],
                        [
                            -81.55620833364765,
                            41.115819444212036
                        ],
                        [
                            -81.57850000007488,
                            41.12364444443563
                        ],
                        [
                            -81.95833333371633,
                            41.25694444383106
                        ],
                        [
                            -82.44874166739402,
                            41.108027777360064
                        ],
                        [
                            -82.5176277782993,
                            41.086861110651796
                        ],
                        [
                            -82.52792777844417,
                            41.085949999392994
                        ],
                        [
                            -82.61694444443054,
                            41.012777777172744
                        ],
                        [
                            -82.74166666698306,
                            40.90833333321683
                        ],
                        [
                            -83.16222222287723,
                            40.52055555499493
                        ],
                        [
                            -83.0619444449801,
                            40.52777777742256
                        ],
                        [
                            -82.74166666698306,
                            40.55000000000007
                        ],
                        [
                            -82.59166666728282,
                            40.55416666605885
                        ],
                        [
                            -82.34444444425566,
                            40.57361111036448
                        ],
                        [
                            -82.16666666723285,
                            40.587499999925114
                        ],
                        [
                            -81.89166666688311,
                            40.59999999990015
                        ],
                        [
                            -81.78333333316675,
                            40.59999999990015
                        ],
                        [
                            -81.75444444435556,
                            40.527222222127875
                        ],
                        [
                            -81.4894000002098,
                            40.78048611044568
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 980,
                "artcc": "zob",
                "sector": "04",
                "sectype": "high",
                "Shape_Length": 4.039436681227957,
                "Shape_Area": 0.7523369721317028
            }
        },
        {
            "type": "Feature",
            "id": 981,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -80.44749999992501,
                            40.96416666595894
                        ],
                        [
                            -80.53333333386621,
                            41.1499999997003
                        ],
                        [
                            -81.09611111173837,
                            41.30388888826161
                        ],
                        [
                            -81.26666666723287,
                            41.349999999300564
                        ],
                        [
                            -81.55277777797204,
                            41.413055555369624
                        ],
                        [
                            -81.73888888891105,
                            41.418611111014
                        ],
                        [
                            -81.79444444445551,
                            41.41749999952543
                        ],
                        [
                            -81.95833333371633,
                            41.25694444383106
                        ],
                        [
                            -81.57850000007488,
                            41.12364444443563
                        ],
                        [
                            -81.55620833364765,
                            41.115819444212036
                        ],
                        [
                            -81.54916944431437,
                            41.08049444386353
                        ],
                        [
                            -81.53379444485773,
                            41.003311110419475
                        ],
                        [
                            -81.4894000002098,
                            40.78048611044568
                        ],
                        [
                            -81.75444444435556,
                            40.527222222127875
                        ],
                        [
                            -81.69972222220275,
                            40.38722222222782
                        ],
                        [
                            -80.84666666663333,
                            40.31861111051438
                        ],
                        [
                            -80.40694444453044,
                            40.33749999952539
                        ],
                        [
                            -80.09999999990004,
                            40.349999999500426
                        ],
                        [
                            -80.10833333381629,
                            40.68333333276712
                        ],
                        [
                            -80.35833333331664,
                            40.6819444440809
                        ],
                        [
                            -80.44749999992501,
                            40.96416666595894
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 981,
                "artcc": "zob",
                "sector": "06",
                "sectype": "high",
                "Shape_Length": 5.496536174792237,
                "Shape_Area": 1.2696502930603932
            }
        },
        {
            "type": "Feature",
            "id": 982,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -84.76666666653341,
                            41.85833333311689
                        ],
                        [
                            -84.76666666653341,
                            41.80000000019993
                        ],
                        [
                            -84.74999999960028,
                            41.583333333666474
                        ],
                        [
                            -84.71666666663333,
                            41.28333333336667
                        ],
                        [
                            -84.70916388900224,
                            41.15869999980521
                        ],
                        [
                            -84.6999999997002,
                            40.90833333321683
                        ],
                        [
                            -84.34722222212781,
                            40.863055555569474
                        ],
                        [
                            -83.7458333337413,
                            40.7833333334666
                        ],
                        [
                            -83.64999999999998,
                            40.737499999625356
                        ],
                        [
                            -83.45555555604403,
                            40.70555555534463
                        ],
                        [
                            -82.70166666688311,
                            41.162499999675276
                        ],
                        [
                            -82.83166666698304,
                            41.859444444605515
                        ],
                        [
                            -84.76666666653341,
                            41.85833333311689
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 982,
                "artcc": "zob",
                "sector": "07",
                "sectype": "high",
                "Shape_Length": 5.744006302681251,
                "Shape_Area": 1.9739353121967842
            }
        },
        {
            "type": "Feature",
            "id": 983,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -82.52792777844417,
                            41.085949999392994
                        ],
                        [
                            -82.5176277782993,
                            41.086861110651796
                        ],
                        [
                            -82.44874166739402,
                            41.108027777360064
                        ],
                        [
                            -81.95833333371633,
                            41.25694444383106
                        ],
                        [
                            -81.79444444445551,
                            41.41749999952543
                        ],
                        [
                            -81.9111111111888,
                            41.61111111098904
                        ],
                        [
                            -82.15962499989377,
                            41.74249999977525
                        ],
                        [
                            -82.25119722257375,
                            41.76472499945919
                        ],
                        [
                            -82.39198888937568,
                            41.798622221738185
                        ],
                        [
                            -82.62142499996372,
                            41.86457499953667
                        ],
                        [
                            -82.79864166657961,
                            41.901341666184976
                        ],
                        [
                            -82.9174694446591,
                            41.92621666666588
                        ],
                        [
                            -83.19751944496636,
                            41.94413055568066
                        ],
                        [
                            -83.22888888881113,
                            41.80879166640233
                        ],
                        [
                            -83.25130833361266,
                            41.71207499966158
                        ],
                        [
                            -83.22187500063075,
                            41.56027777769731
                        ],
                        [
                            -83.24555555574426,
                            41.41138888858637
                        ],
                        [
                            -83.25435555596408,
                            41.38881388894987
                        ],
                        [
                            -83.02275555602404,
                            41.36690555494744
                        ],
                        [
                            -82.84833333391617,
                            41.345833333241785
                        ],
                        [
                            -82.79858888896604,
                            41.3427722217607
                        ],
                        [
                            -82.61694444443054,
                            41.012777777172744
                        ],
                        [
                            -82.52792777844417,
                            41.085949999392994
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 983,
                "artcc": "zob",
                "sector": "08",
                "sectype": "high",
                "Shape_Length": 3.91019684301403,
                "Shape_Area": 0.810009400405327
            }
        },
        {
            "type": "Feature",
            "id": 984,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -83.67870277771101,
                            42.4927999995204
                        ],
                        [
                            -83.61780833388747,
                            42.722052777913404
                        ],
                        [
                            -83.73801666663581,
                            42.83663888827414
                        ],
                        [
                            -84.12131944443678,
                            43.22617777738259
                        ],
                        [
                            -84.52249999987504,
                            43.63388888846151
                        ],
                        [
                            -84.96361111156352,
                            43.58666666683325
                        ],
                        [
                            -84.99999999999994,
                            43.4999999995004
                        ],
                        [
                            -84.99999999999994,
                            43.177777777522465
                        ],
                        [
                            -84.99999999999994,
                            43.03611111083916
                        ],
                        [
                            -84.99999999999994,
                            42.96666666663339
                        ],
                        [
                            -84.99999999999994,
                            42.743333332966984
                        ],
                        [
                            -84.9999833337638,
                            42.68865833331546
                        ],
                        [
                            -84.13408055627264,
                            42.58896666607882
                        ],
                        [
                            -83.9189138887649,
                            42.491947221886846
                        ],
                        [
                            -83.67870277771101,
                            42.4927999995204
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 984,
                "artcc": "zob",
                "sector": "12",
                "sectype": "high",
                "Shape_Length": 4.2185986141510465,
                "Shape_Area": 1.0044864396607054
            }
        },
        {
            "type": "Feature",
            "id": 985,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -84.13408055627264,
                            42.58896666607882
                        ],
                        [
                            -84.9999833337638,
                            42.68865833331546
                        ],
                        [
                            -84.9999916668819,
                            42.599116666501004
                        ],
                        [
                            -84.99999999999994,
                            42.523611110964055
                        ],
                        [
                            -84.99999999999994,
                            42.373888888461465
                        ],
                        [
                            -84.99999999999994,
                            42.16666666643357
                        ],
                        [
                            -84.99921388910968,
                            42.132474999821454
                        ],
                        [
                            -84.74440000035969,
                            42.138383333424144
                        ],
                        [
                            -84.34220000042961,
                            42.14770833267346
                        ],
                        [
                            -84.15191111140865,
                            42.15170555566692
                        ],
                        [
                            -83.7831666668082,
                            42.063363888432775
                        ],
                        [
                            -83.74508055622266,
                            42.21869166618751
                        ],
                        [
                            -83.67870277771101,
                            42.4927999995204
                        ],
                        [
                            -83.9189138887649,
                            42.491947221886846
                        ],
                        [
                            -84.13408055627264,
                            42.58896666607882
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 985,
                "artcc": "zob",
                "sector": "14",
                "sectype": "high",
                "Shape_Length": 3.5727162924190026,
                "Shape_Area": 0.5984364299702564
            }
        },
        {
            "type": "Feature",
            "id": 986,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -84.57338055566805,
                            41.22056111050688
                        ],
                        [
                            -84.09722222262741,
                            41.43750000002501
                        ],
                        [
                            -83.89696944428437,
                            41.69645833258602
                        ],
                        [
                            -83.8483000003447,
                            41.793391666692116
                        ],
                        [
                            -83.83265833391494,
                            41.86087499978146
                        ],
                        [
                            -83.7831666668082,
                            42.063363888432775
                        ],
                        [
                            -84.15191111140865,
                            42.15170555566692
                        ],
                        [
                            -84.34220000042961,
                            42.14770833267346
                        ],
                        [
                            -84.74440000035969,
                            42.138383333424144
                        ],
                        [
                            -84.99921388910968,
                            42.132474999821454
                        ],
                        [
                            -84.99999999999994,
                            42.0416666666834
                        ],
                        [
                            -84.99999999999994,
                            41.999999999800195
                        ],
                        [
                            -84.99999999999994,
                            41.85000000009995
                        ],
                        [
                            -85.04722222252752,
                            41.64027777789721
                        ],
                        [
                            -85.0597222225025,
                            41.589166666508504
                        ],
                        [
                            -85.10833333371636,
                            41.38333333316683
                        ],
                        [
                            -84.71666666663333,
                            41.28333333336667
                        ],
                        [
                            -84.70916388900224,
                            41.15869999980521
                        ],
                        [
                            -84.57338055566805,
                            41.22056111050688
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 986,
                "artcc": "zob",
                "sector": "15",
                "sectype": "high",
                "Shape_Length": 3.903355817581959,
                "Shape_Area": 0.9186107596892101
            }
        },
        {
            "type": "Feature",
            "id": 988,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -82.35555555554441,
                            43.13333333326676
                        ],
                        [
                            -82.11666666643345,
                            43.600000000199884
                        ],
                        [
                            -82.1250000003497,
                            43.68888888871129
                        ],
                        [
                            -82.1694444446054,
                            43.85527777814701
                        ],
                        [
                            -83.29333333356647,
                            43.755833333641476
                        ],
                        [
                            -84.03722222202788,
                            43.683611111163884
                        ],
                        [
                            -84.16055555589418,
                            43.67138888928588
                        ],
                        [
                            -84.52249999987504,
                            43.63388888846151
                        ],
                        [
                            -84.9636111106642,
                            43.58666666683325
                        ],
                        [
                            -84.99999999999994,
                            43.50000000039978
                        ],
                        [
                            -84.99999999999994,
                            43.177777777522465
                        ],
                        [
                            -84.99999999999994,
                            43.03611111083916
                        ],
                        [
                            -84.99999999999994,
                            42.96666666663339
                        ],
                        [
                            -84.99999999999994,
                            42.743333332966984
                        ],
                        [
                            -83.48583333354145,
                            42.700833333591504
                        ],
                        [
                            -82.46666666663333,
                            42.87083333289206
                        ],
                        [
                            -82.46111111098895,
                            42.911111111088985
                        ],
                        [
                            -82.40416666675821,
                            42.95416666665841
                        ],
                        [
                            -82.41666666673325,
                            43.01138888898612
                        ],
                        [
                            -82.35555555554441,
                            43.13333333326676
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 988,
                "artcc": "zob",
                "sector": "18",
                "sectype": "high",
                "Shape_Length": 7.29856946069806,
                "Shape_Area": 2.640477739840626
            }
        },
        {
            "type": "Feature",
            "id": 993,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -83.48583333354145,
                            42.700833333591504
                        ],
                        [
                            -82.83166666698304,
                            41.859444444605515
                        ],
                        [
                            -81.2375000003247,
                            41.7586111114137
                        ],
                        [
                            -81.21111111078909,
                            41.999999999800195
                        ],
                        [
                            -81.0638888884614,
                            42.205555555944215
                        ],
                        [
                            -80.94861111131371,
                            42.39111111068928
                        ],
                        [
                            -80.44166666708298,
                            42.755555555744365
                        ],
                        [
                            -80.26666666653341,
                            42.813888888661324
                        ],
                        [
                            -80.89583333314175,
                            42.779166667008155
                        ],
                        [
                            -81.22083333339157,
                            42.74166666708305
                        ],
                        [
                            -81.82777777742245,
                            42.80833333301695
                        ],
                        [
                            -81.84722222262741,
                            42.811111111288824
                        ],
                        [
                            -81.97083333369136,
                            42.82916666690818
                        ],
                        [
                            -82.27222222177807,
                            42.853333333466594
                        ],
                        [
                            -82.40833333371631,
                            42.86666666683328
                        ],
                        [
                            -82.46666666663333,
                            42.87083333289206
                        ],
                        [
                            -83.48583333354145,
                            42.700833333591504
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 993,
                "artcc": "zob",
                "sector": "27",
                "sectype": "high",
                "Shape_Length": 7.429342712175668,
                "Shape_Area": 2.2048322530581856
            }
        },
        {
            "type": "Feature",
            "id": 994,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -84.9999916668819,
                            42.599116666501004
                        ],
                        [
                            -84.99999999999994,
                            42.523611110964055
                        ],
                        [
                            -84.99999999999994,
                            42.373888888461465
                        ],
                        [
                            -84.99999999999994,
                            42.16666666643357
                        ],
                        [
                            -84.99921388910968,
                            42.132474999821454
                        ],
                        [
                            -84.99999999999994,
                            42.0416666666834
                        ],
                        [
                            -84.99999999999994,
                            41.999999999800195
                        ],
                        [
                            -84.76666666653341,
                            41.85833333311689
                        ],
                        [
                            -82.83166666698304,
                            41.859444444605515
                        ],
                        [
                            -83.48583333354145,
                            42.700833333591504
                        ],
                        [
                            -84.99999999999994,
                            42.743333332966984
                        ],
                        [
                            -84.99998333286447,
                            42.68865833331546
                        ],
                        [
                            -84.9999916668819,
                            42.599116666501004
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 994,
                "artcc": "zob",
                "sector": "28",
                "sectype": "high",
                "Shape_Length": 5.531853102844558,
                "Shape_Area": 1.5661387932886246
            }
        },
        {
            "type": "Feature",
            "id": 996,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -76.8500000000999,
                            42.31250000007498
                        ],
                        [
                            -76.41666666703304,
                            42.333333333066946
                        ],
                        [
                            -76.23333333346653,
                            42.36666666603384
                        ],
                        [
                            -76.80277777847164,
                            42.60833333341668
                        ],
                        [
                            -76.69722222212783,
                            42.727777777522476
                        ],
                        [
                            -76.68916666720787,
                            42.768611111014025
                        ],
                        [
                            -76.66666666653339,
                            42.883333332867096
                        ],
                        [
                            -76.4833333338662,
                            42.941666666683375
                        ],
                        [
                            -76.4347222226524,
                            43.007777777322644
                        ],
                        [
                            -76.29166666728281,
                            43.18333333316684
                        ],
                        [
                            -76.28333333336661,
                            43.236111110439424
                        ],
                        [
                            -76.77500000024975,
                            43.329166666808305
                        ],
                        [
                            -76.77833333381625,
                            43.35444444395597
                        ],
                        [
                            -76.78055555589413,
                            43.3999999997003
                        ],
                        [
                            -78.0000000004996,
                            43.3999999997003
                        ],
                        [
                            -77.78333333396614,
                            43.25000000000006
                        ],
                        [
                            -77.91250000067447,
                            43.00833333261727
                        ],
                        [
                            -78.12916666720787,
                            42.944444444055875
                        ],
                        [
                            -78.14333333396615,
                            42.773055555169776
                        ],
                        [
                            -78.15166666698303,
                            42.67583333274217
                        ],
                        [
                            -78.15833333321672,
                            42.59166666648355
                        ],
                        [
                            -77.74305555576922,
                            42.53083333339168
                        ],
                        [
                            -77.70972222280233,
                            42.51972222210293
                        ],
                        [
                            -77.42750000002496,
                            42.416388888736265
                        ],
                        [
                            -77.11305555576922,
                            42.29888888861137
                        ],
                        [
                            -76.8500000000999,
                            42.31250000007498
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 996,
                "artcc": "zob",
                "sector": "31",
                "sectype": "high",
                "Shape_Length": 6.373888239416677,
                "Shape_Area": 1.4594785105156571
            }
        },
        {
            "type": "Feature",
            "id": 998,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -78.55972222290222,
                            42.4713888889861
                        ],
                        [
                            -78.15833333321672,
                            42.59166666648355
                        ],
                        [
                            -78.15166666698303,
                            42.67583333274217
                        ],
                        [
                            -78.14333333396615,
                            42.773055555169776
                        ],
                        [
                            -78.12916666720787,
                            42.944444444055875
                        ],
                        [
                            -77.91250000067447,
                            43.00833333261727
                        ],
                        [
                            -77.78333333396614,
                            43.25000000000006
                        ],
                        [
                            -78.0000000004996,
                            43.3999999997003
                        ],
                        [
                            -78.35000000069942,
                            43.63333333316683
                        ],
                        [
                            -78.6777777783218,
                            43.63333333316683
                        ],
                        [
                            -78.74999999990001,
                            43.599999999300564
                        ],
                        [
                            -78.74999999990001,
                            43.433333332667246
                        ],
                        [
                            -78.95000000039965,
                            43.2416666660838
                        ],
                        [
                            -79.02444444495512,
                            43.19305555487
                        ],
                        [
                            -79.05000000019982,
                            42.94999999970025
                        ],
                        [
                            -79.19999999990006,
                            42.92916666670834
                        ],
                        [
                            -79.19750000062447,
                            42.89805555491995
                        ],
                        [
                            -79.18749999992502,
                            42.783333333066935
                        ],
                        [
                            -79.16944444430561,
                            42.56388888826166
                        ],
                        [
                            -79.02500000024975,
                            42.48749999972529
                        ],
                        [
                            -78.87777777792206,
                            42.41249999987514
                        ],
                        [
                            -78.80000000069941,
                            42.39722222162828
                        ],
                        [
                            -78.55972222290222,
                            42.4713888889861
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 998,
                "artcc": "zob",
                "sector": "33",
                "sectype": "high",
                "Shape_Length": 4.318738808398098,
                "Shape_Area": 1.1024342201746982
            }
        },
        {
            "type": "Feature",
            "id": 999,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -80.26666666653341,
                            42.813888888661324
                        ],
                        [
                            -80.44166666708298,
                            42.755555555744365
                        ],
                        [
                            -80.94861111131371,
                            42.39111111068928
                        ],
                        [
                            -80.26972222200288,
                            42.398888889310854
                        ],
                        [
                            -79.21666666683319,
                            42.341666666983144
                        ],
                        [
                            -78.8094444443056,
                            42.234444444755354
                        ],
                        [
                            -77.99999999960028,
                            42.01666666673333
                        ],
                        [
                            -77.41666666683318,
                            41.87500000005002
                        ],
                        [
                            -77.25833333321674,
                            42.138888889111
                        ],
                        [
                            -77.23611111153855,
                            42.17500000034977
                        ],
                        [
                            -77.11305555576922,
                            42.29888888861137
                        ],
                        [
                            -77.42750000002496,
                            42.416388888736265
                        ],
                        [
                            -77.70972222190301,
                            42.51972222210293
                        ],
                        [
                            -77.74305555576922,
                            42.53083333339168
                        ],
                        [
                            -78.15166666698303,
                            42.67583333364149
                        ],
                        [
                            -78.66666666703304,
                            42.85833333291703
                        ],
                        [
                            -79.19749999972515,
                            42.898055555819326
                        ],
                        [
                            -79.33749999962527,
                            42.90833333371643
                        ],
                        [
                            -79.74999999970021,
                            42.84999999990015
                        ],
                        [
                            -79.84444444475531,
                            42.836111111238836
                        ],
                        [
                            -80.26666666653341,
                            42.813888888661324
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 999,
                "artcc": "zob",
                "sector": "36",
                "sectype": "high",
                "Shape_Length": 8.185623848965205,
                "Shape_Area": 1.8356829080147836
            }
        },
        {
            "type": "Feature",
            "id": 1000,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -79.33749999962527,
                            42.90833333371643
                        ],
                        [
                            -79.19749999972515,
                            42.898055555819326
                        ],
                        [
                            -78.66666666703304,
                            42.85833333291703
                        ],
                        [
                            -78.15166666698303,
                            42.67583333364149
                        ],
                        [
                            -77.74305555576922,
                            42.53083333339168
                        ],
                        [
                            -77.70972222190301,
                            42.51972222210293
                        ],
                        [
                            -77.42750000002496,
                            42.416388888736265
                        ],
                        [
                            -77.11305555576922,
                            42.29888888861137
                        ],
                        [
                            -76.8500000000999,
                            42.31250000007498
                        ],
                        [
                            -76.41666666703304,
                            42.333333333066946
                        ],
                        [
                            -76.23333333346653,
                            42.36666666693321
                        ],
                        [
                            -76.80277777757232,
                            42.60833333341668
                        ],
                        [
                            -76.69722222212783,
                            42.727777777522476
                        ],
                        [
                            -76.68916666630855,
                            42.768611111014025
                        ],
                        [
                            -76.66666666653339,
                            42.883333332867096
                        ],
                        [
                            -76.7333333333666,
                            43.01666666653347
                        ],
                        [
                            -76.76388888896099,
                            43.06666666643355
                        ],
                        [
                            -76.77500000024975,
                            43.329166666808305
                        ],
                        [
                            -76.77833333291693,
                            43.35444444395597
                        ],
                        [
                            -76.78055555589413,
                            43.3999999997003
                        ],
                        [
                            -76.79666666663331,
                            43.631111111088956
                        ],
                        [
                            -77.17666666713296,
                            43.63222222257758
                        ],
                        [
                            -77.94194444478029,
                            43.634166666558485
                        ],
                        [
                            -78.3499999998001,
                            43.63333333316683
                        ],
                        [
                            -78.67777777742248,
                            43.63333333316683
                        ],
                        [
                            -78.74999999990001,
                            43.600000000199884
                        ],
                        [
                            -78.74999999990001,
                            43.433333333566566
                        ],
                        [
                            -78.95000000039965,
                            43.24166666698312
                        ],
                        [
                            -79.0244444440558,
                            43.19305555576932
                        ],
                        [
                            -79.05000000019982,
                            42.94999999970025
                        ],
                        [
                            -79.19999999990006,
                            42.92916666670834
                        ],
                        [
                            -79.33749999962527,
                            42.90833333371643
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 1000,
                "artcc": "zob",
                "sector": "37",
                "sectype": "high",
                "Shape_Length": 7.945461096669524,
                "Shape_Area": 2.383571335271581
            }
        },
        {
            "type": "Feature",
            "id": 1001,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -80.26666666653341,
                            42.813888888661324
                        ],
                        [
                            -80.44166666708298,
                            42.755555555744365
                        ],
                        [
                            -80.94861111131371,
                            42.39111111068928
                        ],
                        [
                            -80.26972222200288,
                            42.398888889310854
                        ],
                        [
                            -79.21666666683319,
                            42.341666666983144
                        ],
                        [
                            -78.8094444443056,
                            42.234444444755354
                        ],
                        [
                            -77.99999999960028,
                            42.01666666673333
                        ],
                        [
                            -77.41666666683318,
                            41.87500000005002
                        ],
                        [
                            -77.25833333321674,
                            42.138888889111
                        ],
                        [
                            -77.23611111153855,
                            42.17500000034977
                        ],
                        [
                            -77.11305555576922,
                            42.29888888861137
                        ],
                        [
                            -77.42750000002496,
                            42.416388888736265
                        ],
                        [
                            -77.70972222190301,
                            42.51972222210293
                        ],
                        [
                            -77.74305555576922,
                            42.53083333339168
                        ],
                        [
                            -78.15166666698303,
                            42.67583333364149
                        ],
                        [
                            -78.66666666703304,
                            42.85833333291703
                        ],
                        [
                            -79.19749999972515,
                            42.898055555819326
                        ],
                        [
                            -79.33749999962527,
                            42.90833333371643
                        ],
                        [
                            -79.74999999970021,
                            42.84999999990015
                        ],
                        [
                            -79.84444444475531,
                            42.836111111238836
                        ],
                        [
                            -80.26666666653341,
                            42.813888888661324
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 1001,
                "artcc": "zob",
                "sector": "38",
                "sectype": "high",
                "Shape_Length": 8.185623848965205,
                "Shape_Area": 1.8356829080147836
            }
        },
        {
            "type": "Feature",
            "id": 1002,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -79.33749999962527,
                            42.90833333371643
                        ],
                        [
                            -79.19749999972515,
                            42.898055555819326
                        ],
                        [
                            -78.66666666703304,
                            42.85833333291703
                        ],
                        [
                            -78.15166666698303,
                            42.67583333364149
                        ],
                        [
                            -77.74305555576922,
                            42.53083333339168
                        ],
                        [
                            -77.70972222190301,
                            42.51972222210293
                        ],
                        [
                            -77.42750000002496,
                            42.416388888736265
                        ],
                        [
                            -77.11305555576922,
                            42.29888888861137
                        ],
                        [
                            -76.8500000000999,
                            42.31250000007498
                        ],
                        [
                            -76.41666666703304,
                            42.333333333066946
                        ],
                        [
                            -76.23333333346653,
                            42.36666666693321
                        ],
                        [
                            -76.80277777757232,
                            42.60833333341668
                        ],
                        [
                            -76.69722222212783,
                            42.727777777522476
                        ],
                        [
                            -76.68916666630855,
                            42.768611111014025
                        ],
                        [
                            -76.66666666653339,
                            42.883333332867096
                        ],
                        [
                            -76.7333333333666,
                            43.01666666653347
                        ],
                        [
                            -76.76388888896099,
                            43.06666666643355
                        ],
                        [
                            -76.77500000024975,
                            43.329166666808305
                        ],
                        [
                            -76.77833333291693,
                            43.35444444395597
                        ],
                        [
                            -76.78055555589413,
                            43.3999999997003
                        ],
                        [
                            -76.79666666663331,
                            43.631111111088956
                        ],
                        [
                            -77.17666666713296,
                            43.63222222257758
                        ],
                        [
                            -77.94194444478029,
                            43.634166666558485
                        ],
                        [
                            -78.3499999998001,
                            43.63333333316683
                        ],
                        [
                            -78.67777777742248,
                            43.63333333316683
                        ],
                        [
                            -78.74999999990001,
                            43.600000000199884
                        ],
                        [
                            -78.74999999990001,
                            43.433333333566566
                        ],
                        [
                            -78.95000000039965,
                            43.24166666698312
                        ],
                        [
                            -79.0244444440558,
                            43.19305555576932
                        ],
                        [
                            -79.05000000019982,
                            42.94999999970025
                        ],
                        [
                            -79.19999999990006,
                            42.92916666670834
                        ],
                        [
                            -79.33749999962527,
                            42.90833333371643
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 1002,
                "artcc": "zob",
                "sector": "39",
                "sectype": "high",
                "Shape_Length": 7.945461096669524,
                "Shape_Area": 2.383571335271581
            }
        },
        {
            "type": "Feature",
            "id": 1005,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -84.76666666653341,
                            41.85833333311689
                        ],
                        [
                            -84.76666666653341,
                            41.80000000019993
                        ],
                        [
                            -84.74999999960028,
                            41.583333333666474
                        ],
                        [
                            -84.71666666663333,
                            41.28333333336667
                        ],
                        [
                            -84.70916388900224,
                            41.15869999980521
                        ],
                        [
                            -84.6999999997002,
                            40.90833333321683
                        ],
                        [
                            -84.34722222212781,
                            40.863055555569474
                        ],
                        [
                            -83.7458333337413,
                            40.7833333334666
                        ],
                        [
                            -83.64999999999998,
                            40.737499999625356
                        ],
                        [
                            -83.45555555604403,
                            40.70555555534463
                        ],
                        [
                            -82.70166666688311,
                            41.162499999675276
                        ],
                        [
                            -82.83166666698304,
                            41.859444444605515
                        ],
                        [
                            -84.76666666653341,
                            41.85833333311689
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 1005,
                "artcc": "zob",
                "sector": "47",
                "sectype": "high",
                "Shape_Length": 5.744006302681251,
                "Shape_Area": 1.9739353121967842
            }
        },
        {
            "type": "Feature",
            "id": 1006,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -82.83166666698304,
                            41.859444444605515
                        ],
                        [
                            -82.70166666688311,
                            41.162499999675276
                        ],
                        [
                            -83.45555555604403,
                            40.70555555534463
                        ],
                        [
                            -83.06666666633356,
                            40.57083333299198
                        ],
                        [
                            -82.91666666663332,
                            40.5666666669332
                        ],
                        [
                            -82.5916666663835,
                            40.55416666695817
                        ],
                        [
                            -82.34444444425566,
                            40.5736111112638
                        ],
                        [
                            -82.16666666633353,
                            40.587499999925114
                        ],
                        [
                            -81.89166666688311,
                            40.59999999990015
                        ],
                        [
                            -81.78333333316675,
                            40.59999999990015
                        ],
                        [
                            -80.89166666708297,
                            40.64999999980023
                        ],
                        [
                            -80.35833333331664,
                            40.6819444440809
                        ],
                        [
                            -80.87555555544446,
                            41.01194444468041
                        ],
                        [
                            -80.89361111106388,
                            41.50333333346663
                        ],
                        [
                            -80.61666666673324,
                            41.633333333566554
                        ],
                        [
                            -81.2375000003247,
                            41.7586111114137
                        ],
                        [
                            -82.83166666698304,
                            41.859444444605515
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 1006,
                "artcc": "zob",
                "sector": "48",
                "sectype": "high",
                "Shape_Length": 8.356553323194237,
                "Shape_Area": 2.5719508110812046
            }
        },
        {
            "type": "Feature",
            "id": 1007,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -82.83166666698304,
                            41.859444444605515
                        ],
                        [
                            -82.70166666688311,
                            41.162499999675276
                        ],
                        [
                            -83.45555555604403,
                            40.70555555534463
                        ],
                        [
                            -83.06666666633356,
                            40.57083333299198
                        ],
                        [
                            -82.91666666663332,
                            40.5666666669332
                        ],
                        [
                            -82.5916666663835,
                            40.55416666695817
                        ],
                        [
                            -82.34444444425566,
                            40.5736111112638
                        ],
                        [
                            -82.16666666633353,
                            40.587499999925114
                        ],
                        [
                            -81.89166666688311,
                            40.59999999990015
                        ],
                        [
                            -81.78333333316675,
                            40.59999999990015
                        ],
                        [
                            -80.89166666708297,
                            40.64999999980023
                        ],
                        [
                            -80.35833333331664,
                            40.6819444440809
                        ],
                        [
                            -80.87555555544446,
                            41.01194444468041
                        ],
                        [
                            -80.89361111106388,
                            41.50333333346663
                        ],
                        [
                            -80.61666666673324,
                            41.633333333566554
                        ],
                        [
                            -81.2375000003247,
                            41.7586111114137
                        ],
                        [
                            -82.83166666698304,
                            41.859444444605515
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 1007,
                "artcc": "zob",
                "sector": "49",
                "sectype": "high",
                "Shape_Length": 8.356553323194237,
                "Shape_Area": 2.5719508110812046
            }
        },
        {
            "type": "Feature",
            "id": 1009,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -78.62472222205287,
                            39.64777777792216
                        ],
                        [
                            -78.55833333331663,
                            39.6716666663836
                        ],
                        [
                            -78.44444444485521,
                            39.70555555554449
                        ],
                        [
                            -78.20000000009986,
                            39.77222222147839
                        ],
                        [
                            -77.93333333366638,
                            39.84999999960036
                        ],
                        [
                            -77.84027777819688,
                            39.855555555244734
                        ],
                        [
                            -77.85833333381629,
                            40.15694444423076
                        ],
                        [
                            -77.86249999987507,
                            40.199999999800184
                        ],
                        [
                            -77.86416666665832,
                            40.24666666613376
                        ],
                        [
                            -77.90583333354147,
                            40.395555555244755
                        ],
                        [
                            -77.94138888948561,
                            40.5216666664835
                        ],
                        [
                            -77.9669444447303,
                            40.61138888838656
                        ],
                        [
                            -77.98500000034971,
                            40.673888888261615
                        ],
                        [
                            -78.0972222229272,
                            40.81666666643355
                        ],
                        [
                            -78.12777777852165,
                            40.85833333331675
                        ],
                        [
                            -78.50000000039967,
                            40.84166666638362
                        ],
                        [
                            -78.86666666663331,
                            40.82499999945048
                        ],
                        [
                            -79.17499999994999,
                            40.80416666645857
                        ],
                        [
                            -79.69166666678319,
                            40.770833332592304
                        ],
                        [
                            -79.99111111178831,
                            40.70722222212788
                        ],
                        [
                            -80.10833333381629,
                            40.68333333276712
                        ],
                        [
                            -80.09999999990004,
                            40.349999999500426
                        ],
                        [
                            -79.81361111106389,
                            40.10722222152833
                        ],
                        [
                            -79.8708333333916,
                            39.87083333259227
                        ],
                        [
                            -79.8297222227024,
                            39.71666666683325
                        ],
                        [
                            -79.51138888958553,
                            39.64361111096406
                        ],
                        [
                            -79.20833333381626,
                            39.58333333316682
                        ],
                        [
                            -79.01388888896099,
                            39.533333333266796
                        ],
                        [
                            -78.62472222205287,
                            39.64777777792216
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 1009,
                "artcc": "zob",
                "sector": "53",
                "sectype": "high",
                "Shape_Length": 6.228734223769749,
                "Shape_Area": 2.337214505659379
            }
        },
        {
            "type": "Feature",
            "id": 1010,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -80.40000000019984,
                            39.16666666613378
                        ],
                        [
                            -79.89444444465533,
                            39.173611110464435
                        ],
                        [
                            -79.6983333339162,
                            39.283611110964046
                        ],
                        [
                            -79.54305555576923,
                            39.3708333326922
                        ],
                        [
                            -79.3180555562189,
                            39.44111111118889
                        ],
                        [
                            -79.23333333376632,
                            39.46666666643358
                        ],
                        [
                            -79.01388888896099,
                            39.533333333266796
                        ],
                        [
                            -79.20833333381626,
                            39.58333333316682
                        ],
                        [
                            -79.51138888958553,
                            39.64361111096406
                        ],
                        [
                            -79.8297222227024,
                            39.71666666683325
                        ],
                        [
                            -79.8708333333916,
                            39.87083333259227
                        ],
                        [
                            -79.81361111106389,
                            40.10722222152833
                        ],
                        [
                            -80.09999999990004,
                            40.349999999500426
                        ],
                        [
                            -80.40694444453044,
                            40.33749999952539
                        ],
                        [
                            -80.48055555569431,
                            40.02777777752249
                        ],
                        [
                            -80.63333333366637,
                            39.89999999950044
                        ],
                        [
                            -81.10277777797205,
                            39.50416666635863
                        ],
                        [
                            -80.77777777772224,
                            39.31666666673334
                        ],
                        [
                            -80.49999999999994,
                            39.16666666613378
                        ],
                        [
                            -80.40000000019984,
                            39.16666666613378
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 1010,
                "artcc": "zob",
                "sector": "55",
                "sectype": "high",
                "Shape_Length": 5.306372862302046,
                "Shape_Area": 1.1768915509101199
            }
        },
        {
            "type": "Feature",
            "id": 1012,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -80.61666666673324,
                            41.633333333566554
                        ],
                        [
                            -80.89361111106388,
                            41.50333333346663
                        ],
                        [
                            -80.87555555544446,
                            41.01194444468041
                        ],
                        [
                            -80.35833333331664,
                            40.6819444440809
                        ],
                        [
                            -79.77222222227772,
                            40.638888888511474
                        ],
                        [
                            -77.94138888858629,
                            40.5216666664835
                        ],
                        [
                            -77.9669444447303,
                            40.61138888928588
                        ],
                        [
                            -77.98500000034971,
                            40.673888889160935
                        ],
                        [
                            -78.09722222202788,
                            40.81666666643355
                        ],
                        [
                            -78.12777777762233,
                            40.85833333331675
                        ],
                        [
                            -77.9499999997002,
                            41.11944444410585
                        ],
                        [
                            -77.80833333301689,
                            41.30833333331674
                        ],
                        [
                            -77.89972222260246,
                            41.319444444605494
                        ],
                        [
                            -78.38888888931075,
                            41.37916666710805
                        ],
                        [
                            -78.74583333294191,
                            41.419444444405656
                        ],
                        [
                            -78.8955555554445,
                            41.43861111151364
                        ],
                        [
                            -79.16666666693311,
                            41.47083333299196
                        ],
                        [
                            -79.52027777789709,
                            41.51583333344166
                        ],
                        [
                            -79.77083333359144,
                            41.53888888851145
                        ],
                        [
                            -79.9222222219779,
                            41.56111111108896
                        ],
                        [
                            -80.61666666673324,
                            41.633333333566554
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 1012,
                "artcc": "zob",
                "sector": "57",
                "sectype": "high",
                "Shape_Length": 7.604388397219769,
                "Shape_Area": 2.454159993380963
            }
        },
        {
            "type": "Feature",
            "id": 1014,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -80.61666666673324,
                            41.633333333566554
                        ],
                        [
                            -80.89361111106388,
                            41.50333333346663
                        ],
                        [
                            -80.87555555544446,
                            41.01194444468041
                        ],
                        [
                            -80.35833333331664,
                            40.6819444440809
                        ],
                        [
                            -79.77222222227772,
                            40.638888888511474
                        ],
                        [
                            -77.94138888858629,
                            40.5216666664835
                        ],
                        [
                            -77.9669444447303,
                            40.61138888928588
                        ],
                        [
                            -77.98500000034971,
                            40.673888889160935
                        ],
                        [
                            -78.09722222202788,
                            40.81666666643355
                        ],
                        [
                            -78.12777777762233,
                            40.85833333331675
                        ],
                        [
                            -77.9499999997002,
                            41.11944444410585
                        ],
                        [
                            -77.80833333301689,
                            41.30833333331674
                        ],
                        [
                            -77.89972222260246,
                            41.319444444605494
                        ],
                        [
                            -78.38888888931075,
                            41.37916666710805
                        ],
                        [
                            -78.74583333294191,
                            41.419444444405656
                        ],
                        [
                            -78.8955555554445,
                            41.43861111151364
                        ],
                        [
                            -79.16666666693311,
                            41.47083333299196
                        ],
                        [
                            -79.52027777789709,
                            41.51583333344166
                        ],
                        [
                            -79.77083333359144,
                            41.53888888851145
                        ],
                        [
                            -79.9222222219779,
                            41.56111111108896
                        ],
                        [
                            -80.61666666673324,
                            41.633333333566554
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 1014,
                "artcc": "zob",
                "sector": "59",
                "sectype": "high",
                "Shape_Length": 7.604388397219769,
                "Shape_Area": 2.454159993380963
            }
        },
        {
            "type": "Feature",
            "id": 1016,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -81.78333333316675,
                            40.59999999990015
                        ],
                        [
                            -81.75444444435556,
                            40.527222222127875
                        ],
                        [
                            -81.69972222220275,
                            40.38722222222782
                        ],
                        [
                            -81.63333333346651,
                            40.21666666673332
                        ],
                        [
                            -81.5744444443556,
                            39.88750000042472
                        ],
                        [
                            -81.54999999970016,
                            39.749999999800195
                        ],
                        [
                            -81.42611111143862,
                            39.68222222237773
                        ],
                        [
                            -81.19166666648346,
                            39.55000000019993
                        ],
                        [
                            -81.10277777797205,
                            39.50416666635863
                        ],
                        [
                            -80.77777777772224,
                            39.31666666673334
                        ],
                        [
                            -80.49999999999994,
                            39.1666666670331
                        ],
                        [
                            -80.40000000019984,
                            39.1666666670331
                        ],
                        [
                            -79.89444444465533,
                            39.173611111363755
                        ],
                        [
                            -79.69833333301688,
                            39.283611110964046
                        ],
                        [
                            -79.54305555576923,
                            39.37083333359152
                        ],
                        [
                            -79.31805555531957,
                            39.44111111118889
                        ],
                        [
                            -79.233333332867,
                            39.46666666643358
                        ],
                        [
                            -79.01388888896099,
                            39.533333333266796
                        ],
                        [
                            -78.62472222205287,
                            39.64777777792216
                        ],
                        [
                            -78.96999999999997,
                            39.73916666660841
                        ],
                        [
                            -79.4433972219042,
                            39.918344444340676
                        ],
                        [
                            -80.89166666708297,
                            40.64999999980023
                        ],
                        [
                            -81.78333333316675,
                            40.59999999990015
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 1016,
                "artcc": "zob",
                "sector": "66",
                "sectype": "high",
                "Shape_Length": 7.433731455506929,
                "Shape_Area": 2.4990401054605846
            }
        },
        {
            "type": "Feature",
            "id": 1017,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -77.61722222262745,
                            39.77222222147839
                        ],
                        [
                            -77.84027777819688,
                            39.855555555244734
                        ],
                        [
                            -77.85833333381629,
                            40.15694444423076
                        ],
                        [
                            -77.86249999987507,
                            40.199999999800184
                        ],
                        [
                            -77.86416666665832,
                            40.24666666613376
                        ],
                        [
                            -77.90583333354147,
                            40.395555555244755
                        ],
                        [
                            -77.94138888948561,
                            40.5216666664835
                        ],
                        [
                            -79.77222222227772,
                            40.638888888511474
                        ],
                        [
                            -80.35833333331664,
                            40.6819444440809
                        ],
                        [
                            -80.89166666708297,
                            40.64999999980023
                        ],
                        [
                            -79.44339722280353,
                            39.918344444340676
                        ],
                        [
                            -78.96999999999997,
                            39.73916666660841
                        ],
                        [
                            -78.62472222205287,
                            39.64777777792216
                        ],
                        [
                            -78.55833333331663,
                            39.6716666663836
                        ],
                        [
                            -78.52222222207786,
                            39.591666666183755
                        ],
                        [
                            -78.47305555556937,
                            39.48249999997506
                        ],
                        [
                            -77.61722222262745,
                            39.77222222147839
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 1017,
                "artcc": "zob",
                "sector": "67",
                "sectype": "high",
                "Shape_Length": 7.53972477277459,
                "Shape_Area": 1.9469703873679947
            }
        },
        {
            "type": "Feature",
            "id": 1020,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -79.19750000062447,
                            42.89805555491995
                        ],
                        [
                            -79.19999999990006,
                            42.92916666670834
                        ],
                        [
                            -79.33750000052459,
                            42.90833333281711
                        ],
                        [
                            -79.75000000059953,
                            42.84999999990015
                        ],
                        [
                            -79.84444444475531,
                            42.836111111238836
                        ],
                        [
                            -80.26666666653341,
                            42.813888888661324
                        ],
                        [
                            -80.44166666708298,
                            42.755555555744365
                        ],
                        [
                            -80.94861111131371,
                            42.39111111068928
                        ],
                        [
                            -81.06388888936073,
                            42.205555555044896
                        ],
                        [
                            -81.21111111168841,
                            41.999999999800195
                        ],
                        [
                            -81.2375000003247,
                            41.75861111051438
                        ],
                        [
                            -80.61666666673324,
                            41.633333332667235
                        ],
                        [
                            -79.92222222287722,
                            41.56111111108896
                        ],
                        [
                            -79.77083333359144,
                            41.53888888851145
                        ],
                        [
                            -79.52027777789709,
                            41.51583333344166
                        ],
                        [
                            -79.51388888886112,
                            41.57499999975022
                        ],
                        [
                            -79.31944444490517,
                            41.70833333341665
                        ],
                        [
                            -78.93055555609402,
                            42.11388888826161
                        ],
                        [
                            -78.8094444443056,
                            42.234444443856034
                        ],
                        [
                            -78.80000000069941,
                            42.2499999993006
                        ],
                        [
                            -78.80000000069941,
                            42.39722222162828
                        ],
                        [
                            -78.87777777792206,
                            42.41249999987514
                        ],
                        [
                            -79.02500000024975,
                            42.48749999972529
                        ],
                        [
                            -78.87916666660834,
                            42.70694444453056
                        ],
                        [
                            -78.99583333334164,
                            42.813888888661324
                        ],
                        [
                            -79.18749999992502,
                            42.783333333066935
                        ],
                        [
                            -79.19750000062447,
                            42.89805555491995
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 1020,
                "artcc": "zob",
                "sector": "70",
                "sectype": "high",
                "Shape_Length": 6.53319100165411,
                "Shape_Area": 2.393140818400378
            }
        },
        {
            "type": "Feature",
            "id": 1022,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -79.98000000049956,
                            41.474999999950114
                        ],
                        [
                            -79.92222222287722,
                            41.56111111108896
                        ],
                        [
                            -79.86250000037467,
                            41.65833333261725
                        ],
                        [
                            -79.9944444444555,
                            41.79861111061433
                        ],
                        [
                            -80.05833333391621,
                            41.86666666613377
                        ],
                        [
                            -80.30000000039968,
                            41.949999999900115
                        ],
                        [
                            -81.21111111168841,
                            41.999999999800195
                        ],
                        [
                            -81.48333333376632,
                            41.8833333330669
                        ],
                        [
                            -81.55595555580425,
                            41.84463333266223
                        ],
                        [
                            -81.65000000039964,
                            41.794444443656175
                        ],
                        [
                            -81.9111111111888,
                            41.61111111098904
                        ],
                        [
                            -81.79444444445551,
                            41.41749999952543
                        ],
                        [
                            -81.73888888891105,
                            41.418611111014
                        ],
                        [
                            -81.55277777797204,
                            41.413055555369624
                        ],
                        [
                            -81.26666666723287,
                            41.349999999300564
                        ],
                        [
                            -81.09611111173837,
                            41.30388888826161
                        ],
                        [
                            -80.53333333386621,
                            41.1499999997003
                        ],
                        [
                            -80.09999999990004,
                            41.19999999960038
                        ],
                        [
                            -79.98000000049956,
                            41.474999999950114
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 1022,
                "artcc": "zob",
                "sector": "75",
                "sectype": "high",
                "Shape_Length": 4.733091630456406,
                "Shape_Area": 1.2008855634449325
            }
        },
        {
            "type": "Feature",
            "id": 1023,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -77.72500000014986,
                            41.41666666613378
                        ],
                        [
                            -77.64166666728283,
                            41.54166666678327
                        ],
                        [
                            -77.63333333336658,
                            41.555555555444585
                        ],
                        [
                            -77.56666666653342,
                            41.637499999625334
                        ],
                        [
                            -77.4555555554445,
                            41.82083333319184
                        ],
                        [
                            -77.41666666683318,
                            41.87500000005002
                        ],
                        [
                            -78.0000000004996,
                            42.01666666673333
                        ],
                        [
                            -78.8094444443056,
                            42.234444443856034
                        ],
                        [
                            -79.21666666683319,
                            42.341666666083825
                        ],
                        [
                            -80.2697222229022,
                            42.398888888411534
                        ],
                        [
                            -80.94861111131371,
                            42.39111111068928
                        ],
                        [
                            -81.06388888936073,
                            42.205555555044896
                        ],
                        [
                            -81.21111111168841,
                            41.999999999800195
                        ],
                        [
                            -81.2375000003247,
                            41.75861111051438
                        ],
                        [
                            -80.61666666673324,
                            41.633333332667235
                        ],
                        [
                            -79.92222222287722,
                            41.56111111108896
                        ],
                        [
                            -79.77083333359144,
                            41.53888888851145
                        ],
                        [
                            -79.52027777789709,
                            41.51583333344166
                        ],
                        [
                            -79.16666666693311,
                            41.47083333299196
                        ],
                        [
                            -78.8955555554445,
                            41.43861111061432
                        ],
                        [
                            -78.74583333384123,
                            41.419444444405656
                        ],
                        [
                            -78.38888888931075,
                            41.37916666620873
                        ],
                        [
                            -77.89972222260246,
                            41.319444443706175
                        ],
                        [
                            -77.80833333391621,
                            41.30833333331674
                        ],
                        [
                            -77.72500000014986,
                            41.41666666613378
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 1023,
                "artcc": "zob",
                "sector": "77",
                "sectype": "high",
                "Shape_Length": 8.457735189739706,
                "Shape_Area": 2.717852893746854
            }
        },
        {
            "type": "Feature",
            "id": 1038,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -120.53805555621892,
                            46.82333333326676
                        ],
                        [
                            -120.48277777787212,
                            46.922222221578295
                        ],
                        [
                            -120.36666666733282,
                            47.13333333336669
                        ],
                        [
                            -120.7500000004996,
                            47.21666666623372
                        ],
                        [
                            -121.5166666668332,
                            47.34999999990015
                        ],
                        [
                            -121.70000000039965,
                            47.34999999990015
                        ],
                        [
                            -121.88333333396616,
                            47.31666666603388
                        ],
                        [
                            -121.89999999999998,
                            47.16666666633364
                        ],
                        [
                            -121.85916666740775,
                            47.00722222212789
                        ],
                        [
                            -121.04999999990002,
                            46.69999999940052
                        ],
                        [
                            -120.66666666673325,
                            46.58333333266722
                        ],
                        [
                            -120.53805555621892,
                            46.82333333326676
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 1038,
                "artcc": "zse",
                "sector": "01",
                "sectype": "high",
                "Shape_Length": 3.7483803597388072,
                "Shape_Area": 0.7078368828978778
            }
        },
        {
            "type": "Feature",
            "id": 1040,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -122.96666666663333,
                            47.499999999600334
                        ],
                        [
                            -123.66805555571926,
                            47.249722222003015
                        ],
                        [
                            -124.24805555581923,
                            47.09972222230277
                        ],
                        [
                            -124.50138888888608,
                            47.00805555551955
                        ],
                        [
                            -125.11305555606901,
                            46.890000000099974
                        ],
                        [
                            -125.30000000039968,
                            46.53333333276714
                        ],
                        [
                            -125.72694444443056,
                            46.34027777749748
                        ],
                        [
                            -124.76666666663334,
                            46.33333333316682
                        ],
                        [
                            -123.83333333366636,
                            46.33333333316682
                        ],
                        [
                            -123.30861111101393,
                            45.999999999900126
                        ],
                        [
                            -123.19666666653342,
                            45.93194444438063
                        ],
                        [
                            -122.97333333376633,
                            45.78333333336667
                        ],
                        [
                            -122.75000000009987,
                            45.78333333336667
                        ],
                        [
                            -122.41666666683318,
                            45.93333333306691
                        ],
                        [
                            -122.39999999990005,
                            45.999999999900126
                        ],
                        [
                            -122.50000000059953,
                            45.999999999900126
                        ],
                        [
                            -122.5380555558192,
                            46.094999999350534
                        ],
                        [
                            -122.58333333346656,
                            46.33333333316682
                        ],
                        [
                            -122.52500000054954,
                            46.708333333316716
                        ],
                        [
                            -122.49416666685818,
                            46.89222222217785
                        ],
                        [
                            -122.4866666672329,
                            46.93611111113893
                        ],
                        [
                            -122.50000000059953,
                            47.383333332867096
                        ],
                        [
                            -122.66666666723285,
                            47.383333332867096
                        ],
                        [
                            -122.96666666663333,
                            47.499999999600334
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 1040,
                "artcc": "zse",
                "sector": "02",
                "sectype": "high",
                "Shape_Length": 8.670935124586078,
                "Shape_Area": 2.850546758771998
            }
        },
        {
            "type": "Feature",
            "id": 1042,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -122.68333333326666,
                            48.35500000005004
                        ],
                        [
                            -122.75000000009987,
                            48.54999999930055
                        ],
                        [
                            -122.75000000009987,
                            48.57499999925062
                        ],
                        [
                            -122.75000000009987,
                            48.641666666083836
                        ],
                        [
                            -122.77027777779716,
                            48.7902777770978
                        ],
                        [
                            -122.96805555621893,
                            48.90916666680829
                        ],
                        [
                            -123.37444444445549,
                            48.86944444390599
                        ],
                        [
                            -123.09361111126373,
                            48.47166666678328
                        ],
                        [
                            -123.11416666705799,
                            48.42333333276713
                        ],
                        [
                            -123.24750000072441,
                            48.2844444443557
                        ],
                        [
                            -123.54027777769727,
                            48.224999999950114
                        ],
                        [
                            -123.67805555551945,
                            48.24055555539462
                        ],
                        [
                            -124.01111111158849,
                            48.29722222152833
                        ],
                        [
                            -124.75000000059953,
                            48.49999999940053
                        ],
                        [
                            -125.00000000009987,
                            48.49999999940053
                        ],
                        [
                            -127.96638888933575,
                            48.33518055553827
                        ],
                        [
                            -128.00000000039967,
                            48.333333332767154
                        ],
                        [
                            -127.92500000054957,
                            48.16666666613378
                        ],
                        [
                            -127.8452777784467,
                            47.9999999995004
                        ],
                        [
                            -127.37777777812192,
                            46.999999999700265
                        ],
                        [
                            -126.84694444453044,
                            45.80972222200302
                        ],
                        [
                            -126.81972222250255,
                            45.82305555536965
                        ],
                        [
                            -126.25000000029974,
                            46.09999999970029
                        ],
                        [
                            -125.72694444443056,
                            46.34027777749748
                        ],
                        [
                            -125.30000000039968,
                            46.53333333276714
                        ],
                        [
                            -125.11305555606901,
                            46.890000000099974
                        ],
                        [
                            -124.50138888888608,
                            47.00805555551955
                        ],
                        [
                            -124.24805555581923,
                            47.09972222230277
                        ],
                        [
                            -123.66805555571926,
                            47.249722222003015
                        ],
                        [
                            -122.96666666663333,
                            47.499999999600334
                        ],
                        [
                            -122.66666666723285,
                            47.383333332867096
                        ],
                        [
                            -122.50000000059953,
                            47.383333332867096
                        ],
                        [
                            -122.50000000059953,
                            47.499999999600334
                        ],
                        [
                            -122.45000000069945,
                            47.549999999500415
                        ],
                        [
                            -122.38333333386623,
                            47.61666666633363
                        ],
                        [
                            -122.46666666673326,
                            47.84999999980022
                        ],
                        [
                            -122.5055555562439,
                            47.9999999995004
                        ],
                        [
                            -122.52972222280232,
                            48.085833333441656
                        ],
                        [
                            -122.53333333356647,
                            48.099999999300564
                        ],
                        [
                            -122.68333333326666,
                            48.35500000005004
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 1042,
                "artcc": "zse",
                "sector": "03",
                "sectype": "high",
                "Shape_Length": 15.308925833757764,
                "Shape_Area": 8.554859606155054
            }
        },
        {
            "type": "Feature",
            "id": 1047,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -118.45722222212783,
                            47.01833333341665
                        ],
                        [
                            -118.02027777829682,
                            47.30722222152838
                        ],
                        [
                            -117.89888888931074,
                            47.670555555094836
                        ],
                        [
                            -117.46777777832176,
                            48.36777777722267
                        ],
                        [
                            -116.85388888906095,
                            48.95055555559446
                        ],
                        [
                            -116.80111111178837,
                            48.9999999993006
                        ],
                        [
                            -117.01666666683315,
                            48.9999999993006
                        ],
                        [
                            -117.66666666733278,
                            48.9999999993006
                        ],
                        [
                            -118.78333333386621,
                            48.9999999993006
                        ],
                        [
                            -119.50000000029974,
                            48.9999999993006
                        ],
                        [
                            -120.0000000001998,
                            48.9999999993006
                        ],
                        [
                            -120.08527777794706,
                            48.830833333391695
                        ],
                        [
                            -120.39166666728283,
                            48.27499999985014
                        ],
                        [
                            -120.47777777842168,
                            48.10833333321682
                        ],
                        [
                            -120.2333333336664,
                            47.86944444410585
                        ],
                        [
                            -120.10222222207784,
                            47.604722221653276
                        ],
                        [
                            -120.13416666725783,
                            47.29833333321682
                        ],
                        [
                            -120.36666666733282,
                            47.13333333336669
                        ],
                        [
                            -118.91388888926082,
                            46.64583333344166
                        ],
                        [
                            -118.45722222212783,
                            47.01833333341665
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 1047,
                "artcc": "zse",
                "sector": "07",
                "sectype": "high",
                "Shape_Length": 10.20808406922446,
                "Shape_Area": 5.410222722092517
            }
        },
        {
            "type": "Feature",
            "id": 1051,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -115.00000000029974,
                            47.58333333336668
                        ],
                        [
                            -115.00000000029974,
                            48.4166666665335
                        ],
                        [
                            -114.71777777842169,
                            48.91666666643357
                        ],
                        [
                            -114.69999999999999,
                            48.94999999940052
                        ],
                        [
                            -114.66666666703304,
                            48.9999999993006
                        ],
                        [
                            -115.5000000001998,
                            48.9999999993006
                        ],
                        [
                            -116.03333333396614,
                            48.9999999993006
                        ],
                        [
                            -116.70888888881115,
                            48.9999999993006
                        ],
                        [
                            -116.80111111178837,
                            48.9999999993006
                        ],
                        [
                            -116.85388888906095,
                            48.95055555559446
                        ],
                        [
                            -117.46777777832176,
                            48.36777777722267
                        ],
                        [
                            -117.89888888931074,
                            47.670555555094836
                        ],
                        [
                            -118.02027777829682,
                            47.30722222152838
                        ],
                        [
                            -117.55305555606901,
                            47.286111110439435
                        ],
                        [
                            -115.00000000029974,
                            47.14999999940051
                        ],
                        [
                            -115.00000000029974,
                            47.58333333336668
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 1051,
                "artcc": "zse",
                "sector": "11",
                "sectype": "high",
                "Shape_Length": 9.21910613335009,
                "Shape_Area": 4.624512114430243
            }
        },
        {
            "type": "Feature",
            "id": 1052,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -120.39166666728283,
                            48.27499999985014
                        ],
                        [
                            -120.08527777794706,
                            48.830833333391695
                        ],
                        [
                            -121.04027777819687,
                            48.84999999960036
                        ],
                        [
                            -121.98944444470533,
                            48.860277777497515
                        ],
                        [
                            -122.34555555584421,
                            48.951388888986116
                        ],
                        [
                            -122.32611111153852,
                            49.04583333314184
                        ],
                        [
                            -122.77944444510501,
                            49.02472222205296
                        ],
                        [
                            -122.85055555609398,
                            48.97361111066425
                        ],
                        [
                            -122.89972222260246,
                            48.937777777522456
                        ],
                        [
                            -122.90750000032472,
                            48.914444444355695
                        ],
                        [
                            -122.96805555621893,
                            48.90916666680829
                        ],
                        [
                            -122.77027777779716,
                            48.7902777770978
                        ],
                        [
                            -122.75000000009987,
                            48.641666666083836
                        ],
                        [
                            -122.75000000009987,
                            48.57499999925062
                        ],
                        [
                            -122.75000000009987,
                            48.54999999930055
                        ],
                        [
                            -122.68333333326666,
                            48.35500000005004
                        ],
                        [
                            -122.53333333356647,
                            48.099999999300564
                        ],
                        [
                            -122.52972222280232,
                            48.085833333441656
                        ],
                        [
                            -122.5055555562439,
                            47.9999999995004
                        ],
                        [
                            -122.46666666673326,
                            47.84999999980022
                        ],
                        [
                            -122.38333333386623,
                            47.61666666633363
                        ],
                        [
                            -122.16666666733278,
                            47.616847222321496
                        ],
                        [
                            -121.96666666683319,
                            47.61666666633363
                        ],
                        [
                            -121.96666666683319,
                            47.783333332967004
                        ],
                        [
                            -121.96666666683319,
                            47.81666666683327
                        ],
                        [
                            -121.96666666683319,
                            47.94999999960038
                        ],
                        [
                            -121.96666666683319,
                            47.9999999995004
                        ],
                        [
                            -121.96666666683319,
                            48.16666666613378
                        ],
                        [
                            -121.66666666653339,
                            48.200000000000045
                        ],
                        [
                            -121.52194444438055,
                            48.197222221728225
                        ],
                        [
                            -120.47777777842168,
                            48.10833333321682
                        ],
                        [
                            -120.39166666728283,
                            48.27499999985014
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 1052,
                "artcc": "zse",
                "sector": "12",
                "sectype": "high",
                "Shape_Length": 7.820387753447319,
                "Shape_Area": 2.0746147761644576
            }
        },
        {
            "type": "Feature",
            "id": 1054,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -121.78333333326668,
                            43.9166666665335
                        ],
                        [
                            -121.02361111126373,
                            41.00000000000006
                        ],
                        [
                            -120.99999999999994,
                            41.00000000000006
                        ],
                        [
                            -120.82972222260247,
                            41.00027777809703
                        ],
                        [
                            -119.50000000029974,
                            41.00000000000006
                        ],
                        [
                            -119.10000000019983,
                            42.333333333066946
                        ],
                        [
                            -119.00000000039967,
                            42.66666666633364
                        ],
                        [
                            -119.24999999990007,
                            43.53333333336667
                        ],
                        [
                            -119.28333333286696,
                            43.63333333316683
                        ],
                        [
                            -119.21666666693312,
                            43.73333333296699
                        ],
                        [
                            -119.74999999980014,
                            44.16666666693317
                        ],
                        [
                            -121.26361111096395,
                            44.16666666693317
                        ],
                        [
                            -121.78333333326668,
                            43.9166666665335
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 1054,
                "artcc": "zse",
                "sector": "13",
                "sectype": "high",
                "Shape_Length": 10.182768379987849,
                "Shape_Area": 6.854675925769457
            }
        },
        {
            "type": "Feature",
            "id": 1055,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -122.54999999960029,
                            43.46666666653351
                        ],
                        [
                            -122.41666666683318,
                            41.33333333326675
                        ],
                        [
                            -122.27500000014987,
                            41.291666666383605
                        ],
                        [
                            -121.99999999980014,
                            41.21666666653351
                        ],
                        [
                            -121.88333333306684,
                            41.182777777372564
                        ],
                        [
                            -121.25000000039967,
                            41.00000000000006
                        ],
                        [
                            -121.13333333366637,
                            41.00000000000006
                        ],
                        [
                            -121.02361111126373,
                            41.00000000000006
                        ],
                        [
                            -121.78333333326668,
                            43.9166666665335
                        ],
                        [
                            -122.54999999960029,
                            43.46666666653351
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 1055,
                "artcc": "zse",
                "sector": "14",
                "sectype": "high",
                "Shape_Length": 7.480228573059077,
                "Shape_Area": 2.789525462595249
            }
        },
        {
            "type": "Feature",
            "id": 1056,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -124.60833333391622,
                            43.883333332667235
                        ],
                        [
                            -125.33333333336662,
                            44.06666666623369
                        ],
                        [
                            -126.56666666663335,
                            44.06666666623369
                        ],
                        [
                            -126.60000000049956,
                            44.06666666623369
                        ],
                        [
                            -126.66666666733278,
                            43.53333333336667
                        ],
                        [
                            -126.68666666693309,
                            43.34305555546956
                        ],
                        [
                            -126.69416666655837,
                            43.27222222167825
                        ],
                        [
                            -126.72277777817192,
                            42.999999999600334
                        ],
                        [
                            -126.78333333316675,
                            42.41666666683324
                        ],
                        [
                            -126.81666666703302,
                            41.999999999800195
                        ],
                        [
                            -126.83045833358517,
                            41.83333333316682
                        ],
                        [
                            -126.838694444493,
                            41.733333333366716
                        ],
                        [
                            -126.89999999990005,
                            40.98333333306692
                        ],
                        [
                            -126.87499999994998,
                            40.97222222177817
                        ],
                        [
                            -125.8333333332667,
                            40.46666666623372
                        ],
                        [
                            -125.33333333336662,
                            40.21666666673332
                        ],
                        [
                            -124.66666666683318,
                            40.21666666673332
                        ],
                        [
                            -123.83333333366636,
                            40.21666666673332
                        ],
                        [
                            -123.53333333336661,
                            40.38749999942547
                        ],
                        [
                            -123.53333333336661,
                            41.33333333326675
                        ],
                        [
                            -122.7444444444555,
                            41.33333333326675
                        ],
                        [
                            -122.41666666683318,
                            41.33333333326675
                        ],
                        [
                            -122.55000000049961,
                            43.46666666653351
                        ],
                        [
                            -122.96666666663333,
                            43.46666666653351
                        ],
                        [
                            -124.60833333391622,
                            43.883333332667235
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 1056,
                "artcc": "zse",
                "sector": "15",
                "sectype": "high",
                "Shape_Length": 15.012643414639054,
                "Shape_Area": 13.779985608077004
            }
        },
        {
            "type": "Feature",
            "id": 1057,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -121.95416666685816,
                            46.06750000012494
                        ],
                        [
                            -121.70472222175312,
                            45.00361111086414
                        ],
                        [
                            -121.43444444455542,
                            44.84777777742255
                        ],
                        [
                            -121.26361111096395,
                            44.16666666693317
                        ],
                        [
                            -119.74999999980014,
                            44.16666666693317
                        ],
                        [
                            -119.21666666693312,
                            43.73333333296699
                        ],
                        [
                            -118.54166666648342,
                            44.699999999800184
                        ],
                        [
                            -118.4499999997002,
                            44.850000000399746
                        ],
                        [
                            -118.06166666708299,
                            45.130000000199914
                        ],
                        [
                            -118.49999999960028,
                            45.31666666643355
                        ],
                        [
                            -119.4833333333666,
                            45.71666666653351
                        ],
                        [
                            -120.95527777764727,
                            46.460555555894246
                        ],
                        [
                            -121.12611111123874,
                            46.38888888871128
                        ],
                        [
                            -121.39805555521968,
                            46.28027777779727
                        ],
                        [
                            -121.95416666685816,
                            46.06750000012494
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 1057,
                "artcc": "zse",
                "sector": "16",
                "sectype": "high",
                "Shape_Length": 10.402040046553953,
                "Shape_Area": 5.812735879306221
            }
        },
        {
            "type": "Feature",
            "id": 1060,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -120.10222222207784,
                            47.604722221653276
                        ],
                        [
                            -120.2333333336664,
                            47.86944444410585
                        ],
                        [
                            -120.47777777842168,
                            48.10833333321682
                        ],
                        [
                            -121.52194444438055,
                            48.197222221728225
                        ],
                        [
                            -121.66666666653339,
                            48.200000000000045
                        ],
                        [
                            -121.96666666683319,
                            48.16666666613378
                        ],
                        [
                            -121.96666666683319,
                            47.9999999995004
                        ],
                        [
                            -121.96666666683319,
                            47.94999999960038
                        ],
                        [
                            -121.96666666683319,
                            47.81666666683327
                        ],
                        [
                            -121.96666666683319,
                            47.783333332967004
                        ],
                        [
                            -121.96666666683319,
                            47.61666666633363
                        ],
                        [
                            -121.88333333396616,
                            47.31666666603388
                        ],
                        [
                            -121.70000000039965,
                            47.34999999990015
                        ],
                        [
                            -121.5166666668332,
                            47.34999999990015
                        ],
                        [
                            -120.7500000004996,
                            47.21666666623372
                        ],
                        [
                            -120.36666666733282,
                            47.13333333336669
                        ],
                        [
                            -120.13416666725783,
                            47.29833333321682
                        ],
                        [
                            -120.10222222207784,
                            47.604722221653276
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 1060,
                "artcc": "zse",
                "sector": "31",
                "sectype": "high",
                "Shape_Length": 5.126381733647857,
                "Shape_Area": 1.5574487266779502
            }
        },
        {
            "type": "Feature",
            "id": 1063,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -122.39999999990005,
                            45.999999999900126
                        ],
                        [
                            -122.16666666733278,
                            45.999999999900126
                        ],
                        [
                            -121.95416666685816,
                            46.06750000012494
                        ],
                        [
                            -121.398055556119,
                            46.28027777779727
                        ],
                        [
                            -121.12611111123874,
                            46.38888888871128
                        ],
                        [
                            -120.95527777764727,
                            46.460555554994926
                        ],
                        [
                            -120.66666666673325,
                            46.58333333266722
                        ],
                        [
                            -121.04999999990002,
                            46.69999999940052
                        ],
                        [
                            -121.85916666740775,
                            47.00722222212789
                        ],
                        [
                            -121.89999999999998,
                            47.16666666633364
                        ],
                        [
                            -121.88333333396616,
                            47.31666666603388
                        ],
                        [
                            -121.96666666683319,
                            47.61666666633363
                        ],
                        [
                            -122.16666666733278,
                            47.616847222321496
                        ],
                        [
                            -122.38333333386623,
                            47.61666666633363
                        ],
                        [
                            -122.45000000069945,
                            47.549999999500415
                        ],
                        [
                            -122.50000000059953,
                            47.499999999600334
                        ],
                        [
                            -122.50000000059953,
                            47.383333332867096
                        ],
                        [
                            -122.4866666672329,
                            46.93611111113893
                        ],
                        [
                            -122.49416666685818,
                            46.89222222217785
                        ],
                        [
                            -122.52500000054954,
                            46.708333333316716
                        ],
                        [
                            -122.58333333346656,
                            46.33333333316682
                        ],
                        [
                            -122.5380555558192,
                            46.094999999350534
                        ],
                        [
                            -122.50000000059953,
                            45.999999999900126
                        ],
                        [
                            -122.39999999990005,
                            45.999999999900126
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 1063,
                "artcc": "zse",
                "sector": "32",
                "sectype": "high",
                "Shape_Length": 5.937717609459404,
                "Shape_Area": 1.554940161894812
            }
        },
        {
            "type": "Feature",
            "id": 1068,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -125.72694444443056,
                            46.34027777749748
                        ],
                        [
                            -126.25000000029974,
                            46.09999999970029
                        ],
                        [
                            -126.81972222250255,
                            45.82305555536965
                        ],
                        [
                            -126.84694444453044,
                            45.80972222200302
                        ],
                        [
                            -126.50000000069946,
                            45.00000000009999
                        ],
                        [
                            -126.55833333361642,
                            44.4999999993006
                        ],
                        [
                            -126.60000000049956,
                            44.06666666623369
                        ],
                        [
                            -126.56666666663335,
                            44.06666666623369
                        ],
                        [
                            -125.33333333336662,
                            44.06666666623369
                        ],
                        [
                            -124.60833333391622,
                            43.883333332667235
                        ],
                        [
                            -122.96666666663333,
                            43.46666666653351
                        ],
                        [
                            -122.55000000049961,
                            43.46666666653351
                        ],
                        [
                            -122.53333333356647,
                            44.86666666643356
                        ],
                        [
                            -122.78333333396614,
                            45.083333332967015
                        ],
                        [
                            -122.84999999990004,
                            45.33333333336668
                        ],
                        [
                            -122.81638888883612,
                            45.78333333336667
                        ],
                        [
                            -122.79999999999995,
                            45.999999999900126
                        ],
                        [
                            -123.07500000034969,
                            45.999999999900126
                        ],
                        [
                            -123.30861111101393,
                            45.999999999900126
                        ],
                        [
                            -123.83333333366636,
                            46.33333333316682
                        ],
                        [
                            -124.76666666663334,
                            46.33333333316682
                        ],
                        [
                            -125.72694444443056,
                            46.34027777749748
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 1068,
                "artcc": "zse",
                "sector": "42",
                "sectype": "high",
                "Shape_Length": 12.865992671010961,
                "Shape_Area": 9.399848072104822
            }
        },
        {
            "type": "Feature",
            "id": 1069,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -122.79999999999995,
                            45.999999999900126
                        ],
                        [
                            -122.81638888883612,
                            45.78333333336667
                        ],
                        [
                            -122.84999999990004,
                            45.33333333336668
                        ],
                        [
                            -122.78333333306682,
                            45.083333332967015
                        ],
                        [
                            -122.53333333356647,
                            44.86666666643356
                        ],
                        [
                            -122.54999999960029,
                            43.46666666653351
                        ],
                        [
                            -121.78333333326668,
                            43.9166666665335
                        ],
                        [
                            -121.26361111096395,
                            44.16666666693317
                        ],
                        [
                            -121.43444444455542,
                            44.84777777742255
                        ],
                        [
                            -121.70472222175312,
                            45.00361111086414
                        ],
                        [
                            -121.95416666685816,
                            46.06750000012494
                        ],
                        [
                            -122.16666666643346,
                            45.999999999900126
                        ],
                        [
                            -122.39999999990005,
                            45.999999999900126
                        ],
                        [
                            -122.49999999970021,
                            45.999999999900126
                        ],
                        [
                            -122.79999999999995,
                            45.999999999900126
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 1069,
                "artcc": "zse",
                "sector": "46",
                "sectype": "high",
                "Shape_Length": 7.0871279870437,
                "Shape_Area": 2.3953050926908537
            }
        },
        {
            "type": "Feature",
            "id": 1070,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -120.3666666664335,
                            47.13333333336669
                        ],
                        [
                            -120.48277777787212,
                            46.922222222477615
                        ],
                        [
                            -120.5380555553196,
                            46.82333333326676
                        ],
                        [
                            -120.66666666673325,
                            46.58333333356654
                        ],
                        [
                            -120.95527777764727,
                            46.460555555894246
                        ],
                        [
                            -119.4833333333666,
                            45.71666666653351
                        ],
                        [
                            -118.49999999960028,
                            45.31666666643355
                        ],
                        [
                            -118.06166666708299,
                            45.130000000199914
                        ],
                        [
                            -117.91666666683318,
                            45.23333333356652
                        ],
                        [
                            -117.7500000001998,
                            45.33333333336668
                        ],
                        [
                            -117.49999999980014,
                            45.33333333336668
                        ],
                        [
                            -117.0099999997002,
                            45.33333333336668
                        ],
                        [
                            -118.2786111108141,
                            46.42388888846148
                        ],
                        [
                            -118.91388888926082,
                            46.64583333344166
                        ],
                        [
                            -120.3666666664335,
                            47.13333333336669
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 1070,
                "artcc": "zse",
                "sector": "47",
                "sectype": "high",
                "Shape_Length": 9.118062059486203,
                "Shape_Area": 3.303556211325438
            }
        },
        {
            "type": "Feature",
            "id": 1071,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -115.00000000029974,
                            47.150000000299826
                        ],
                        [
                            -117.55305555516969,
                            47.286111111338755
                        ],
                        [
                            -118.0202777773975,
                            47.3072222224277
                        ],
                        [
                            -118.45722222212783,
                            47.01833333341665
                        ],
                        [
                            -118.91388888926082,
                            46.64583333344166
                        ],
                        [
                            -118.2786111108141,
                            46.42388888846148
                        ],
                        [
                            -117.0099999997002,
                            45.33333333336668
                        ],
                        [
                            -115.00000000029974,
                            45.33333333336668
                        ],
                        [
                            -115.00000000029974,
                            46.41666666693317
                        ],
                        [
                            -115.00000000029974,
                            47.150000000299826
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 1071,
                "artcc": "zse",
                "sector": "48",
                "sectype": "high",
                "Shape_Length": 10.310038394080841,
                "Shape_Area": 5.757858912338119
            }
        },
        {
            "type": "Feature",
            "id": 1084,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -86.48888888931077,
                            33.02222222147839
                        ],
                        [
                            -86.14888888891107,
                            33.11055555559449
                        ],
                        [
                            -85.2250000004496,
                            33.345833333041924
                        ],
                        [
                            -84.99999999999994,
                            33.36666666603384
                        ],
                        [
                            -84.99999999999994,
                            33.999999999600334
                        ],
                        [
                            -85.8500000000999,
                            34.099999999400495
                        ],
                        [
                            -86.45023055629508,
                            34.234022221848136
                        ],
                        [
                            -86.67500000024978,
                            34.283333332967004
                        ],
                        [
                            -87.0000000004996,
                            34.36666666673335
                        ],
                        [
                            -87.12222222287727,
                            34.2416666660838
                        ],
                        [
                            -87.24999999999994,
                            34.099999999400495
                        ],
                        [
                            -87.55000000029975,
                            34.01666666653347
                        ],
                        [
                            -87.56666666723288,
                            33.890277777197696
                        ],
                        [
                            -87.59166666718289,
                            33.67083333329174
                        ],
                        [
                            -87.61388888886108,
                            33.5124999996753
                        ],
                        [
                            -87.61666666713296,
                            33.445833332842085
                        ],
                        [
                            -87.63333333316677,
                            33.31666666613381
                        ],
                        [
                            -87.67416666665832,
                            33.286111110539366
                        ],
                        [
                            -87.72499999995,
                            33.24166666628366
                        ],
                        [
                            -87.80833333371635,
                            33.183333333366704
                        ],
                        [
                            -87.98611111163848,
                            33.04305555536962
                        ],
                        [
                            -87.8500000005995,
                            32.69722222212789
                        ],
                        [
                            -87.94583333344156,
                            32.62777777792218
                        ],
                        [
                            -86.48888888931077,
                            33.02222222147839
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 1084,
                "artcc": "ztl",
                "sector": "03",
                "sectype": "high",
                "Shape_Length": 8.028716878496539,
                "Shape_Area": 2.9795512268473443
            }
        },
        {
            "type": "Feature",
            "id": 1087,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -84.7033333332667,
                            34.01833333331672
                        ],
                        [
                            -85.28333333336661,
                            34.883333332667235
                        ],
                        [
                            -85.41658888916584,
                            35.00216111074673
                        ],
                        [
                            -86.00000000069946,
                            35.51666666623373
                        ],
                        [
                            -86.94194444478029,
                            35.343888888661354
                        ],
                        [
                            -87.0000000004996,
                            35.33333333266722
                        ],
                        [
                            -87.0000000004996,
                            35.10833333311689
                        ],
                        [
                            -87.0000000004996,
                            34.68333333306691
                        ],
                        [
                            -87.0000000004996,
                            34.36666666673335
                        ],
                        [
                            -86.67500000024978,
                            34.283333332967004
                        ],
                        [
                            -86.45023055629508,
                            34.234022221848136
                        ],
                        [
                            -85.8500000000999,
                            34.099999999400495
                        ],
                        [
                            -84.99999999999994,
                            33.999999999600334
                        ],
                        [
                            -84.7033333332667,
                            34.01833333331672
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 1087,
                "artcc": "ztl",
                "sector": "06",
                "sectype": "high",
                "Shape_Length": 6.314934541698234,
                "Shape_Area": 2.237589236624441
            }
        },
        {
            "type": "Feature",
            "id": 1090,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -86.48888888931077,
                            33.02222222237771
                        ],
                        [
                            -86.14166666648345,
                            32.237500000424745
                        ],
                        [
                            -85.58333333366636,
                            31.76249999957537
                        ],
                        [
                            -85.30833333331663,
                            31.533333333066935
                        ],
                        [
                            -84.53750000002492,
                            31.627777778122038
                        ],
                        [
                            -84.57916666690812,
                            31.952777777472477
                        ],
                        [
                            -84.71666666663333,
                            33.00833333371645
                        ],
                        [
                            -84.99999999999994,
                            33.366666666933156
                        ],
                        [
                            -85.2250000004496,
                            33.345833333041924
                        ],
                        [
                            -86.14888888891107,
                            33.11055555559449
                        ],
                        [
                            -86.48888888931077,
                            33.02222222237771
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 1090,
                "artcc": "ztl",
                "sector": "10",
                "sectype": "high",
                "Shape_Length": 6.105301452127892,
                "Shape_Area": 2.3976828704441466
            }
        },
        {
            "type": "Feature",
            "id": 1091,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -87.94583333344156,
                            32.62777777792218
                        ],
                        [
                            -87.99166666638354,
                            32.59166666668335
                        ],
                        [
                            -88.11111111138865,
                            32.508333332917005
                        ],
                        [
                            -88.34722222222774,
                            32.33055555589425
                        ],
                        [
                            -88.34444444395587,
                            32.272222222077914
                        ],
                        [
                            -88.34722222222774,
                            32.06944444420577
                        ],
                        [
                            -88.32499999965023,
                            31.51666666703312
                        ],
                        [
                            -87.81666666673323,
                            31.5208333330919
                        ],
                        [
                            -87.55000000029975,
                            31.35833333341668
                        ],
                        [
                            -87.39999999970019,
                            31.28055555529471
                        ],
                        [
                            -86.9499999997002,
                            31.491666667083052
                        ],
                        [
                            -86.74583333314177,
                            31.529166667008155
                        ],
                        [
                            -86.5083333336164,
                            31.57916666690818
                        ],
                        [
                            -85.92499999994999,
                            31.70833333361651
                        ],
                        [
                            -85.89166666698304,
                            31.70694444403091
                        ],
                        [
                            -85.58333333366636,
                            31.76249999957537
                        ],
                        [
                            -86.14166666648345,
                            32.237500000424745
                        ],
                        [
                            -86.48888888931077,
                            33.02222222237771
                        ],
                        [
                            -87.94583333344156,
                            32.62777777792218
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 1091,
                "artcc": "ztl",
                "sector": "11",
                "sectype": "high",
                "Shape_Length": 7.295586316125774,
                "Shape_Area": 2.8951996527713613
            }
        },
        {
            "type": "Feature",
            "id": 1099,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -83.83361111076414,
                            33.542499999975064
                        ],
                        [
                            -83.84999999960024,
                            33.43333333286705
                        ],
                        [
                            -83.89444444475527,
                            33.129166666508524
                        ],
                        [
                            -83.56111111148857,
                            32.733333333366716
                        ],
                        [
                            -83.10000000019983,
                            32.27777777772229
                        ],
                        [
                            -82.74166666698306,
                            31.91388888886121
                        ],
                        [
                            -82.3333333329669,
                            32.16666666663343
                        ],
                        [
                            -82.2000000001998,
                            32.291666666383605
                        ],
                        [
                            -82.06666666653342,
                            32.43333333306691
                        ],
                        [
                            -81.933333332867,
                            32.56249999977524
                        ],
                        [
                            -81.89999999990005,
                            32.67777777782226
                        ],
                        [
                            -81.84361111096393,
                            32.891666666983156
                        ],
                        [
                            -81.84138888888606,
                            32.900277778097006
                        ],
                        [
                            -81.84666666643346,
                            33.51694444473037
                        ],
                        [
                            -81.84999999999997,
                            33.55555555524472
                        ],
                        [
                            -81.84999999999997,
                            33.68333333326677
                        ],
                        [
                            -81.84999999999997,
                            33.888888888511474
                        ],
                        [
                            -82.44055555519469,
                            33.798055556019165
                        ],
                        [
                            -83.83361111076414,
                            33.542499999975064
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 1099,
                "artcc": "ztl",
                "sector": "20",
                "sectype": "high",
                "Shape_Length": 6.490032115793665,
                "Shape_Area": 2.701781135097997
            }
        },
        {
            "type": "Feature",
            "id": 1101,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -84.16249999987508,
                            31.67499999975024
                        ],
                        [
                            -84.09999999999997,
                            31.68333333276712
                        ],
                        [
                            -83.1125000001748,
                            31.68333333276712
                        ],
                        [
                            -82.74166666698306,
                            31.91388888886121
                        ],
                        [
                            -83.10000000019983,
                            32.27777777772229
                        ],
                        [
                            -83.56111111148857,
                            32.733333333366716
                        ],
                        [
                            -83.89444444475527,
                            33.129166666508524
                        ],
                        [
                            -83.85000000049956,
                            33.43333333286705
                        ],
                        [
                            -83.83361111166346,
                            33.542499999975064
                        ],
                        [
                            -83.75416666675824,
                            34.076388889036025
                        ],
                        [
                            -84.19166666678319,
                            34.049999999500415
                        ],
                        [
                            -84.43333333326666,
                            34.03499999935053
                        ],
                        [
                            -84.7033333332667,
                            34.01833333331672
                        ],
                        [
                            -84.99999999999994,
                            33.999999999600334
                        ],
                        [
                            -84.99999999999994,
                            33.36666666603384
                        ],
                        [
                            -84.71666666663333,
                            33.00833333281713
                        ],
                        [
                            -84.57916666690812,
                            31.952777777472477
                        ],
                        [
                            -84.53750000002492,
                            31.627777777222718
                        ],
                        [
                            -84.16249999987508,
                            31.67499999975024
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 1101,
                "artcc": "ztl",
                "sector": "22",
                "sectype": "high",
                "Shape_Length": 8.229571712237304,
                "Shape_Area": 3.0366736883396577
            }
        },
        {
            "type": "Feature",
            "id": 1107,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -80.11944444420567,
                            36.443611111064
                        ],
                        [
                            -80.41387777773724,
                            36.05114999997255
                        ],
                        [
                            -80.45280277772599,
                            35.8513472225963
                        ],
                        [
                            -80.37826111106136,
                            35.80889444482284
                        ],
                        [
                            -79.9500000001998,
                            36.15416666675833
                        ],
                        [
                            -79.97944444430561,
                            36.204722221953034
                        ],
                        [
                            -80.11944444420567,
                            36.443611111064
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 1107,
                "artcc": "ztl",
                "sector": "29",
                "sectype": "high",
                "Shape_Length": 1.6654750974333568,
                "Shape_Area": 0.14217922987866394
            }
        },
        {
            "type": "Feature",
            "id": 1111,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -82.07083333349152,
                            35.358333333516555
                        ],
                        [
                            -82.04999999960029,
                            35.23333333286706
                        ],
                        [
                            -82.10000000039969,
                            35.08333333316682
                        ],
                        [
                            -82.76583333354148,
                            34.68277777777229
                        ],
                        [
                            -82.98972222250251,
                            34.54722222202798
                        ],
                        [
                            -83.75416666675824,
                            34.076388889036025
                        ],
                        [
                            -83.83361111076414,
                            33.542499999975064
                        ],
                        [
                            -82.44055555519469,
                            33.798055556019165
                        ],
                        [
                            -81.84999999999997,
                            33.888888888511474
                        ],
                        [
                            -81.80444444425564,
                            33.985277777547424
                        ],
                        [
                            -81.5744444443556,
                            34.293611110864106
                        ],
                        [
                            -81.33333333316676,
                            34.39444444405592
                        ],
                        [
                            -81.24166666638354,
                            34.43222222207794
                        ],
                        [
                            -81.2333333333666,
                            34.50833333341666
                        ],
                        [
                            -81.21722222262741,
                            34.77888888871132
                        ],
                        [
                            -81.20000000039965,
                            35.066666667133006
                        ],
                        [
                            -81.19638888873618,
                            35.11444444405589
                        ],
                        [
                            -81.22027777809694,
                            35.15250000017494
                        ],
                        [
                            -81.31388888886107,
                            35.299999999700276
                        ],
                        [
                            -81.43611111123874,
                            35.62916666690819
                        ],
                        [
                            -81.55416666665832,
                            35.58611111133877
                        ],
                        [
                            -82.07083333349152,
                            35.358333333516555
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 1111,
                "artcc": "ztl",
                "sector": "32",
                "sectype": "high",
                "Shape_Length": 7.571620940526216,
                "Shape_Area": 2.618707793209544
            }
        },
        {
            "type": "Feature",
            "id": 1112,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -80.85166666698308,
                            34.59222222157831
                        ],
                        [
                            -80.724166667058,
                            34.644166666358615
                        ],
                        [
                            -80.57833333341654,
                            34.70333333266723
                        ],
                        [
                            -80.5177777784217,
                            34.727777777322615
                        ],
                        [
                            -80.46888888911093,
                            34.74749999972528
                        ],
                        [
                            -80.14722222242756,
                            34.855555555344665
                        ],
                        [
                            -80.00000000009987,
                            35.10833333311689
                        ],
                        [
                            -79.99416666725784,
                            35.11666666613377
                        ],
                        [
                            -79.80416666655839,
                            35.383333332567304
                        ],
                        [
                            -79.78333333356647,
                            35.628611110714246
                        ],
                        [
                            -79.78333333356647,
                            35.6499999999001
                        ],
                        [
                            -79.42295000024228,
                            35.75583888855391
                        ],
                        [
                            -79.41556666696806,
                            35.90484166601016
                        ],
                        [
                            -79.76002777775972,
                            35.913011111073956
                        ],
                        [
                            -79.75638888873618,
                            35.94999999930059
                        ],
                        [
                            -79.74444444495515,
                            36.08888888861139
                        ],
                        [
                            -79.86666666733277,
                            36.133333332867096
                        ],
                        [
                            -79.9500000001998,
                            36.15416666675833
                        ],
                        [
                            -79.97944444430561,
                            36.204722221953034
                        ],
                        [
                            -80.11944444510505,
                            36.443611111064
                        ],
                        [
                            -80.2500000004996,
                            36.66666666663343
                        ],
                        [
                            -80.2594444450051,
                            36.68472222225279
                        ],
                        [
                            -80.63361111176334,
                            36.31138888888614
                        ],
                        [
                            -81.31111111148857,
                            35.67499999985017
                        ],
                        [
                            -81.43611111123874,
                            35.62916666600887
                        ],
                        [
                            -81.31388888886107,
                            35.299999999700276
                        ],
                        [
                            -81.22027777809694,
                            35.15249999927562
                        ],
                        [
                            -81.19638888873618,
                            35.11444444405589
                        ],
                        [
                            -81.20000000039965,
                            35.06666666623369
                        ],
                        [
                            -81.21722222262741,
                            34.77888888871132
                        ],
                        [
                            -81.2333333333666,
                            34.50833333341666
                        ],
                        [
                            -81.24166666728286,
                            34.43222222207794
                        ],
                        [
                            -80.85166666698308,
                            34.59222222157831
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 1112,
                "artcc": "ztl",
                "sector": "33",
                "sectype": "high",
                "Shape_Length": 6.793497455994312,
                "Shape_Area": 2.255114951446559
            }
        },
        {
            "type": "Feature",
            "id": 1113,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -82.07083333349152,
                            35.358333333516555
                        ],
                        [
                            -82.04999999960029,
                            35.23333333286706
                        ],
                        [
                            -82.10000000039969,
                            35.08333333316682
                        ],
                        [
                            -82.76583333354148,
                            34.68277777777229
                        ],
                        [
                            -82.98972222250251,
                            34.54722222202798
                        ],
                        [
                            -83.75416666675824,
                            34.076388889036025
                        ],
                        [
                            -83.83361111076414,
                            33.542499999975064
                        ],
                        [
                            -82.44055555519469,
                            33.798055556019165
                        ],
                        [
                            -81.84999999999997,
                            33.888888888511474
                        ],
                        [
                            -81.80444444425564,
                            33.985277777547424
                        ],
                        [
                            -81.5744444443556,
                            34.293611110864106
                        ],
                        [
                            -81.33333333316676,
                            34.39444444405592
                        ],
                        [
                            -81.24166666638354,
                            34.43222222207794
                        ],
                        [
                            -81.2333333333666,
                            34.50833333341666
                        ],
                        [
                            -81.21722222262741,
                            34.77888888871132
                        ],
                        [
                            -81.20000000039965,
                            35.066666667133006
                        ],
                        [
                            -81.19638888873618,
                            35.11444444405589
                        ],
                        [
                            -81.22027777809694,
                            35.15250000017494
                        ],
                        [
                            -81.31388888886107,
                            35.299999999700276
                        ],
                        [
                            -81.43611111123874,
                            35.62916666690819
                        ],
                        [
                            -81.55416666665832,
                            35.58611111133877
                        ],
                        [
                            -82.07083333349152,
                            35.358333333516555
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 1113,
                "artcc": "ztl",
                "sector": "34",
                "sectype": "high",
                "Shape_Length": 7.571620940526216,
                "Shape_Area": 2.618707793209544
            }
        },
        {
            "type": "Feature",
            "id": 1115,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -84.43333333326666,
                            34.03499999935053
                        ],
                        [
                            -84.58333333386622,
                            36.3958333332418
                        ],
                        [
                            -85.40000000009991,
                            36.18333333276712
                        ],
                        [
                            -85.32083333329166,
                            35.824999999550414
                        ],
                        [
                            -85.28333333336661,
                            35.6499999999001
                        ],
                        [
                            -86.00000000069946,
                            35.51666666623373
                        ],
                        [
                            -85.41658888916584,
                            35.00216111074673
                        ],
                        [
                            -85.28333333336661,
                            34.883333332667235
                        ],
                        [
                            -84.7033333332667,
                            34.01833333331672
                        ],
                        [
                            -84.43333333326666,
                            34.03499999935053
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 1115,
                "artcc": "ztl",
                "sector": "37",
                "sectype": "high",
                "Shape_Length": 6.75274625681364,
                "Shape_Area": 1.833975972178551
            }
        },
        {
            "type": "Feature",
            "id": 1117,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -83.11194444398086,
                            36.76472222245263
                        ],
                        [
                            -83.12916666710794,
                            36.76111111078916
                        ],
                        [
                            -83.31944444410578,
                            36.711111110889135
                        ],
                        [
                            -83.5583333332167,
                            36.6499999997003
                        ],
                        [
                            -84.11111111128872,
                            36.51249999997509
                        ],
                        [
                            -84.5833333329669,
                            36.3958333332418
                        ],
                        [
                            -84.43333333326666,
                            34.03500000024985
                        ],
                        [
                            -84.19166666678319,
                            34.050000000399734
                        ],
                        [
                            -83.88333333346651,
                            35.40000000039976
                        ],
                        [
                            -83.38333333356644,
                            35.749999999700265
                        ],
                        [
                            -83.24944444460539,
                            36.57166666628365
                        ],
                        [
                            -83.11194444398086,
                            36.76472222245263
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 1117,
                "artcc": "ztl",
                "sector": "39",
                "sectype": "high",
                "Shape_Length": 7.18929510604012,
                "Shape_Area": 1.9345160094928284
            }
        },
        {
            "type": "Feature",
            "id": 1120,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -82.07083333349152,
                            35.358333333516555
                        ],
                        [
                            -81.55416666665832,
                            35.58611111133877
                        ],
                        [
                            -81.43611111123874,
                            35.62916666690819
                        ],
                        [
                            -81.88444444445548,
                            36.21888888871132
                        ],
                        [
                            -82.06472222255252,
                            36.45277777747248
                        ],
                        [
                            -82.2094444447053,
                            36.636111111038986
                        ],
                        [
                            -82.25555555574425,
                            36.95972222260252
                        ],
                        [
                            -82.75833333301688,
                            36.843055555869284
                        ],
                        [
                            -82.9750000004496,
                            36.794444444655426
                        ],
                        [
                            -83.11194444398086,
                            36.76472222245263
                        ],
                        [
                            -83.24944444460539,
                            36.57166666628365
                        ],
                        [
                            -83.38333333356644,
                            35.749999999700265
                        ],
                        [
                            -82.64722222192796,
                            35.65555555554448
                        ],
                        [
                            -82.09916666700803,
                            35.52972222240271
                        ],
                        [
                            -82.07083333349152,
                            35.358333333516555
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 1120,
                "artcc": "ztl",
                "sector": "42",
                "sectype": "high",
                "Shape_Length": 5.7128754389937395,
                "Shape_Area": 1.769635956818818
            }
        },
        {
            "type": "Feature",
            "id": 1121,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -80.74583333344157,
                            37.30416666625871
                        ],
                        [
                            -80.90000000009991,
                            37.27083333329176
                        ],
                        [
                            -81.16249999957529,
                            37.211111110789204
                        ],
                        [
                            -81.933333332867,
                            37.03333333286707
                        ],
                        [
                            -82.25555555574425,
                            36.95972222260252
                        ],
                        [
                            -82.2094444447053,
                            36.636111111038986
                        ],
                        [
                            -82.06472222255252,
                            36.45277777747248
                        ],
                        [
                            -81.88444444445548,
                            36.21888888871132
                        ],
                        [
                            -81.43611111123874,
                            35.62916666690819
                        ],
                        [
                            -81.31111111148857,
                            35.67499999985017
                        ],
                        [
                            -80.63361111086402,
                            36.31138888888614
                        ],
                        [
                            -80.25944444410578,
                            36.68472222225279
                        ],
                        [
                            -80.32361111076409,
                            36.80833333331674
                        ],
                        [
                            -80.50194444398085,
                            37.11833333341667
                        ],
                        [
                            -80.5811111107891,
                            37.25555555594423
                        ],
                        [
                            -80.63333333366637,
                            37.3319444444806
                        ],
                        [
                            -80.74583333344157,
                            37.30416666625871
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 1121,
                "artcc": "ztl",
                "sector": "43",
                "sectype": "high",
                "Shape_Length": 5.600033615238346,
                "Shape_Area": 1.9595774306569782
            }
        },
        {
            "type": "Feature",
            "id": 1127,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -83.38333333356644,
                            35.749999999700265
                        ],
                        [
                            -83.88333333346651,
                            35.40000000039976
                        ],
                        [
                            -84.19166666678319,
                            34.050000000399734
                        ],
                        [
                            -83.75416666675824,
                            34.076388889036025
                        ],
                        [
                            -82.98972222250251,
                            34.54722222202798
                        ],
                        [
                            -82.76583333354148,
                            34.68277777777229
                        ],
                        [
                            -82.10000000039969,
                            35.08333333316682
                        ],
                        [
                            -82.04999999960029,
                            35.23333333286706
                        ],
                        [
                            -82.07083333349152,
                            35.358333333516555
                        ],
                        [
                            -82.09916666700803,
                            35.52972222240271
                        ],
                        [
                            -82.64722222192796,
                            35.65555555554448
                        ],
                        [
                            -83.38333333356644,
                            35.749999999700265
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 1127,
                "artcc": "ztl",
                "sector": "50",
                "sectype": "high",
                "Shape_Length": 6.1329682711399744,
                "Shape_Area": 2.1556587963323395
            }
        },
        {
            "type": "Feature",
            "id": 1128,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -84.73333333356646,
                            33.53333333266721
                        ],
                        [
                            -84.73333333356646,
                            33.80000000000007
                        ],
                        [
                            -84.99999999999994,
                            33.999999999600334
                        ],
                        [
                            -84.99999999999994,
                            33.36666666603384
                        ],
                        [
                            -84.73333333356646,
                            33.53333333266721
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 1128,
                "artcc": "ztl",
                "sector": "59",
                "sectype": "high",
                "Shape_Length": 1.5477993713261309,
                "Shape_Area": 0.12000000001497976
            }
        },
        {
            "type": "Feature",
            "id": 1129,
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -83.83361111166346,
                            33.542499999975064
                        ],
                        [
                            -83.75416666675824,
                            34.076388889036025
                        ],
                        [
                            -84.19166666678319,
                            34.049999999500415
                        ],
                        [
                            -84.21416666655836,
                            33.72722222222785
                        ],
                        [
                            -84.1705555556943,
                            33.64027777769735
                        ],
                        [
                            -83.93000000069941,
                            33.53027777719774
                        ],
                        [
                            -83.83361111166346,
                            33.542499999975064
                        ]
                    ]
                ]
            },
            "properties": {
                "OBJECTID": 1129,
                "artcc": "ztl",
                "sector": "59",
                "sectype": "high",
                "Shape_Length": 1.7605659375725966,
                "Shape_Area": 0.19852260791936244
            }
        }

    ]