import { useEffect, useRef } from 'react';
import { DateTime, Interval } from 'luxon';
import { useLocation } from 'react-router-dom';
import _ from 'lodash';

export function useQueryParams() {
    return new URLSearchParams(useLocation().search);
}

export function usePrevious(value) {

    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });

    return ref.current;

}

export function useInterval(callback, delay) {

    const savedCallback = useRef();

    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {

        let isMounted = true;

        if (!isMounted) return;

        if (delay !== null) {
            // @ts-ignore
            let id = setInterval(() => { savedCallback.current() }, delay);
            return () => clearInterval(id);
        }

        return () => {
            isMounted = false;
        }

    }, [delay]);

}

export function isEmptyString(str) {
    return (!str || str.length < 1); // || !str.trim()
}

export function isBlankString(str) {
    return (!str || /^\s*$/.test(str));
}

export function isFormError(inputs = [], operator = 'or') {

    if (!Array.isArray(inputs)) return true;

    if (operator === 'or') {

        for (let input of inputs) if (isEmptyString(input) || isBlankString(input)) return true;

    } else if (operator === 'and') {

        let justOne = false;

        for (let input of inputs) {

            if (isEmptyString(input) || isBlankString(input)) justOne = false;
            else {
                justOne = true;
                break;
            }

        }

        if (!justOne) return true;

    }

    return false;

}

export function hasCommonElements(arr1, arr2) {
    if (!_.isArray(arr1) || !_.isArray(arr2)) return false;
    return _.some(arr1, item => _.includes(arr2, item));
}

export function parsePositionType(position) {

    if (position.endsWith('_DEL') || position.endsWith('_GND') || position.endsWith('_TWR')) return 'local';
    else if (position.endsWith('_DEP') || position.endsWith('_APP')) return 'terminal';
    else if (position.endsWith('_CTR')) return 'enroute';
    else return undefined;

}

export function inTimeFrame(time, now, next) {
    return Interval.fromDateTimes(now, next).contains(time);
}

export function findTimeSlot(gdp, timescale, timeslot) {

    return _.findIndex(timescale, time => {

        let range = Interval.fromDateTimes(new Date(String(timescale[0])), new Date(String(timescale[1]))).length('minutes');
        let thisTime = DateTime.fromJSDate(new Date(String(time)), { zone: 'utc' });

        if (thisTime.startOf('hour') < DateTime.utc().minus({ hours: 3 }).startOf('hour')) thisTime.plus({ day: 1});
        let nextTime = thisTime.plus({ minutes: range });

        let timeSlot = DateTime.fromJSDate(new Date(gdp[timeslot]), { zone: 'utc' });

        return Interval.fromDateTimes(thisTime, nextTime).contains(timeSlot);

    });

}

export function getHSL(color) {
    if (_.isNil(color) || _.isNil(color?.hsl)) return;
    return `${color.hsl.h}, ${color.hsl.s * 100}%, ${color.hsl.l * 100}%`;
}
