import { useEffect, useState } from 'react';
import HighCharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { Header, Container, Button } from 'semantic-ui-react';
import _ from 'lodash';
import { DateTime, Interval } from 'luxon';

import { FlightTable } from './FlightList';
import { findTimeSlot, inTimeFrame } from './utils';

function filterTraffic(timescale, aadTraffic, comp1 = undefined, comp2 = undefined) {

    let traffic = [];

    for (let i = 0; i < timescale.length - 1; i++) {

        let now = timescale[i];
        let next = timescale[i + 1];

        let t = _.filter(aadTraffic, flight => {

            let time = DateTime.fromJSDate(new Date(flight.ata ? flight.ata : flight.eta), { zone: 'utc' });
            return _.includes(['departing', 'past_deptime', 'active', 'arrived', 'cancelled'], flight.status)
                && inTimeFrame(time, now, next);

        });

        t = _.filter(t, flight => {

            if (comp1 === 'status') {

                if (comp2 === 'edct') {
                    if (flight.edct && flight.edct.program_type === 'gdp') return true;
                    else return false;
                }
                else if (comp2 === 'gs') {
                    if (flight.edct && flight.edct.program_type === 'gs') return true;
                    else return false;
                }
                else if (_.isNil(flight.edct)) return flight[comp1] === comp2;
                else return false;

            } else return flight[comp1] === comp2;

        });

        traffic.push(t.length);

    }

    return traffic;

}

function initData(timescale, aar, aadTraffic, colorSet, key) {

    let d = [];

    for (let x = 0; x <= colorSet.length - 1; x++) {

        let traffic = filterTraffic(timescale, aadTraffic, key, colorSet[x][key]);
        d.push({
            name: colorSet[x]['name'],
            type: 'column',
            color: colorSet[x]['color'],
            borderWidth: 0,
            data: traffic,
        });

    }

    d.push({
        name: 'Arrival Rate',
        type: 'line',
        color: '#ffffff',
        data: aar,
    });

    return d;

}

export const AADChart = ({ gdp, airport, timescale, aar, aad, colors, keyword, showLegend = true }) => {

    const [options, setOptions] = useState();
    const [plotlines, setPlotLines] = useState([]);

    const [showTable, setShowTable] = useState(false);
    const [tableTime, setTableTime] = useState('');
    const [tableData, setTableData] = useState([]);

    useEffect(() => {

        let opts = {
            chart: {
                type: 'column',
                marginBottom: 100,
                backgroundColor: '#cccccc'
            },
            title: false,
            xAxis: {
                categories: _.map(timescale, time => DateTime.fromJSDate(time, { zone: 'utc' }).toFormat('HHmm')),
                gridLineWidth: 1.2,
                gridLineColor: '#000000',
                tickWidth: 1,
                tickColor: '#000000',
                lineColor: '#000000',
                plotLines: plotlines,
            },
            yAxis: {
                min: 0,
                gridLineWidth: 1.2,
                gridLineColor: '#000000',
                lineColor: '#000000',
                title: false,
                stackLabels: {
                    enabled: false
                },
                lineWidth: 1,
                minorGridLineWidth: 0,
                minorTickInterval: 5,
                minorTickPosition: 'inside',
                minorTickWidth: 2,
                minorTickLength: 3,
                minorTickColor: '#000000'
            },
            legend: {
                itemHiddenStyle: {
                    color: '#A7A7A7'
                },
                align: "center",
                x: 0,
                verticalAlign: "bottom",
                y: 0,
                floating: true,
                borderColor: "#333333",
                borderWidth: 1,
                shadow: false,
            },
            tooltip: {
                formatter: function () {
                    return "<b>" + this.x + "</b><br/>" + this.series.name + ": " + this.y + (this.total > 0 ? "<br/>Total (selected): " + this.total : "")
                }
            },
            plotOptions: {
                series: {
                    cursor: 'pointer',
                    point: {
                        events: {
                            click: function (ev) {

                                let now = DateTime.utc();

                                let s = DateTime.fromJSDate(new Date(timescale[0]), { zone: 'utc' });
                                let e = DateTime.fromJSDate(new Date(timescale[1]), { zone: 'utc' });
                                let range = Interval.fromDateTimes(s, e).length('minutes');

                                let thisTime = DateTime.fromFormat(this.category, 'HHmm', { zone: 'utc' });
                                let nextTime = thisTime.plus({ minutes: range });
                                if (nextTime < now) {

                                    thisTime = thisTime.plus({ day: 1 });
                                    nextTime = nextTime.plus({ day: 1 });

                                }

                                let traffic = _.filter(aad.traffic, flight => {

                                    let now = DateTime.fromJSDate(new Date(flight.ata ? flight.ata : flight.eta), { zone: 'utc' });
                                    return inTimeFrame(now, thisTime, nextTime) && !_.includes(['cancelled', 'archived', 'arrived'], flight.status);

                                });

                                if (traffic.length > 0) {

                                    setTableTime(thisTime.toFormat('T'));
                                    setTableData(traffic);
                                    setShowTable(true);

                                }

                            }
                        },
                    },
                    states: {
                        inactive: {
                            opacity: 1
                        },
                    }
                },
                column: {
                    stacking: "normal",
                    dataLabels: {
                        enabled: false
                    }
                },
                line: {
                    marker: {
                        enabled: false
                    }
                }
            },
            pane: {
                background: [{
                    backgroundColor: '#cccccc'
                }]
            },
            series: initData(timescale, aar, aad.traffic, colors, keyword)
        }

        setOptions(opts);

    }, [timescale, aar, aad, colors, keyword, plotlines]);

    useEffect(() => {

        let lines = [];

        let curr = _.findIndex(timescale, time => {

            let range = Interval.fromDateTimes(timescale[0], timescale[1]).length('minutes');
            let thisTime = DateTime.fromJSDate(time, { zone: 'utc' });

            if (thisTime.startOf('hour') < DateTime.utc().minus({ hours: 3 }).startOf('hour')) thisTime.plus({ day: 1 });
            let nextTime = thisTime.plus({ minutes: range });

            return Interval.fromDateTimes(thisTime, nextTime).contains(DateTime.utc());

        });

        if (curr !== -1) {

            lines.push({
                name: 'currentTime',
                value: curr,
                color: 'orange',
                width: 2,
            });

        }

        let thisGDP = _.find(gdp, { airport: airport, status: 'active' });
        if (!_.isUndefined(thisGDP) && thisGDP.type === 'gs') {

            let start = findTimeSlot(thisGDP, timescale, 'start');
            let end = findTimeSlot(thisGDP, timescale, 'end');

            if (start !== -1 && end !== -1) {

                lines = lines.concat(lines, [
                    {
                        name: 'gdpStart',
                        value: start,
                        color: 'yellow',
                        width: 2
                    },
                    {
                        name: 'gdpEnd',
                        value: end,
                        color: 'yellow',
                        width: 2
                    }
                ]);

            }

        }

        setPlotLines(lines);

    }, [timescale, gdp, airport]);

    return !_.isNil(options) ? <>
        <HighchartsReact highcharts={HighCharts} options={options} />
        {
            _.isArray(tableData) && tableTime && showTable ?
                <>
                    <Header as='h3' block style={{ backgroundColor: '#cccccc', border: '0' }}>
                        {`Flight List for ${tableTime}: ${tableData.length} flights`}
                        <Button color='red' size='small' floated='right' content='Close' onClick={() => setShowTable(false)} />
                    </Header>
                    <Container className='darkbordered' style={{ padding: '0', overflowX: 'auto', overflowY: 'scroll', maxHeight: '222px' }}>
                        <FlightTable aad={tableData} onRowClick={() => { return }} />
                    </Container>
                </>
                : null
        }
    </> : null

}

export default AADChart;