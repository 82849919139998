import React, { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import { DateTime } from 'luxon';
import { Table, Container, Modal, Button, Header } from 'semantic-ui-react';

import { useADL } from './api/tfms';
import { useInterval } from './utils';
// import { ECRModal } from './ECR';

export function FlightTable({ aad, onRowClick, ...rest }) {

    const [column, setColumn] = useState('eta');
    const [direction, setDirection] = useState('ascending');
    const [data, setData] = useState([]);

    const onClick = (column) => {

        setColumn(column);
        setDirection(direction === 'ascending' ? 'descending' : 'ascending');
        handleSort(data, column);

    };

    const handleSort = useCallback((flights, clickedColumn) => {

        let dir;
        if (direction === 'ascending') dir = 'asc';
        else dir = 'desc';

        let d = _.filter(flights, flight => { return !_.includes(['cancelled', 'archived'], flight.status) });

        if (clickedColumn === 'deptime') {

            d = _.orderBy(_.map(d, flight => {

                flight.deptime = DateTime.fromJSDate(new Date(!_.isNil(flight.atd) ? flight.atd : flight.etd), { zone: 'utc' });
                return flight;

            }),
                [flight => flight.deptime],
                [dir]);
        } else if (clickedColumn === 'arrtime') {
            d = _.orderBy(d,
                [flight => flight.eta],
                [dir]);
        } else {
            d = _.orderBy(d, [clickedColumn], [dir]);
        }

        setData(d);

    }, [direction]);

    useEffect(() => {

        if (!aad) return;
        handleSort(aad, column);

    }, [aad, handleSort, column]);

    return (
        <Table unstackable sortable celled selectable {...rest}>

            <Table.Header>
                <Table.Row>

                    <Table.HeaderCell
                        sorted={column === 'callsign' ? direction : null}
                        onClick={() => onClick('callsign')}
                    >ACID</Table.HeaderCell>
                    <Table.HeaderCell
                        sorted={column === 'deptime' ? direction : null}
                        onClick={() => onClick('deptime')}
                    >ETD</Table.HeaderCell>
                    <Table.HeaderCell
                        sorted={column === 'arrtime' ? direction : null}
                        onClick={() => onClick('arrtime')}
                    >ETA</Table.HeaderCell>
                    <Table.HeaderCell
                        sorted={column === 'artcc' ? direction : null}
                        onClick={() => onClick('artcc')}
                    >DCENTR</Table.HeaderCell>
                    <Table.HeaderCell
                        sorted={column === 'dep' ? direction : null}
                        onClick={() => onClick('dep')}
                    >ORIG</Table.HeaderCell>
                    <Table.HeaderCell
                        sorted={column === 'gate' ? direction : null}
                        onClick={() => onClick('gate')}
                    >AFIX</Table.HeaderCell>
                    <Table.HeaderCell
                        sorted={column === 'dest' ? direction : null}
                        onClick={() => onClick('dest')}
                    >DEST</Table.HeaderCell>

                </Table.Row>
            </Table.Header>

            <Table.Body>
                {
                    _.map(data, flight => {

                        if (flight.status === 'archived' || flight.status === 'arrived')
                            return null;

                        return <Table.Row key={flight.callsign} onClick={() => onRowClick(flight)}>
                            <Table.Cell>{flight.callsign}</Table.Cell>
                            <Table.Cell>
                                {
                                    !_.isNil(flight.atd) ?
                                        `A${DateTime.fromJSDate(new Date(flight.atd), { zone: 'utc' }).toFormat('dd/HHmm')}`
                                        :
                                        !_.isNil(flight?.edct?.time) ?
                                            `M${DateTime.fromJSDate(new Date(flight.edct.time), { zone: 'utc' }).toFormat('dd/HHmm')}`
                                            :
                                            `E${DateTime.fromJSDate(new Date(flight.etd), { zone: 'utc' }).toFormat('dd/HHmm')}`
                                }
                            </Table.Cell>
                            <Table.Cell>
                                {
                                    !_.isNil(flight.atd) ?
                                        `E${DateTime.fromJSDate(new Date(flight.eta), { zone: 'utc' }).toFormat('dd/HHmm')}`
                                        :
                                        !_.isNil(flight?.edct?.time) ?
                                            `C${DateTime.fromJSDate(new Date(flight.eta), { zone: 'utc' }).toFormat('dd/HHmm')}`
                                            :
                                            `P${DateTime.fromJSDate(new Date(flight.eta), { zone: 'utc' }).toFormat('dd/HHmm')}`
                                }
                            </Table.Cell>
                            <Table.Cell>{flight.artcc}</Table.Cell>
                            <Table.Cell>{flight.dep}</Table.Cell>
                            <Table.Cell>{flight.gate}</Table.Cell>
                            <Table.Cell>{flight.dest}</Table.Cell>
                        </Table.Row>
                    })
                }
            </Table.Body>

        </Table>
    )
}

export function FlightList({ airport, aad }) {

    // const [showFSMModal, setShowFSMModal] = useState(false);
    // const [flight, setFlight] = useState(null);

    // const onRowClick = (flight) => {
    // setFlight(flight);
    // setShowFSMModal(true);
    // }
    const [clock, setClock] = useState(DateTime.utc());
    useInterval(() => {
        setClock(DateTime.utc());
    }, 1000);

    return (
        <>
            <Header as='h4'>{`${airport.substring(1)}\u00A0\u00A0\u00A0\u00A0${clock.toFormat('D HHmm')}Z`}</Header>
            <Container className='darkbordered' style={{ marginTop: '5px', padding: '0', overflowX: 'auto', overflowY: 'scroll', height: '80vh' }}>
                {/* <ECRModal showModal={showFSMModal} onClose={() => setShowFSMModal(false)} item={flight} /> */}
                <FlightTable aad={aad} onRowClick={() => { return }} />
            </Container>
        </>
    )

}

export function FlightListModal({ onClose, showModal, airport, filterFunc }) {

    const { data: aad } = useADL(airport);
    const [clock, setClock] = useState(DateTime.utc());
    useInterval(() => {
        setClock(DateTime.utc());
    }, 1000);

    const [flights, setFlights] = useState([]);

    useEffect(() => {

        if (!aad || !aad.traffic) return;

        let f = _.filter(aad.traffic, filterFunc);
        setFlights(f);

    }, [aad, filterFunc]);

    return (
        <Modal closeIcon={true} onClose={() => onClose()} open={showModal}>
            <Modal.Header>EDCT List: {airport}</Modal.Header>
            <Modal.Content>
                {
                    airport ?
                        <Header as='h4'>{`${airport.substring(1)}\u00A0\u00A0\u00A0\u00A0${clock.toFormat('D HH:mm')}Z`}</Header>
                        :
                        null
                }
                <Container className='darkbordered' style={{ marginTop: '5px', padding: '0', overflowX: 'auto', overflowY: 'scroll', minHeight: '200px', maxHeight: '70vh' }}>
                    <FlightTable aad={flights} onRowClick={() => { return }} />
                </Container>
            </Modal.Content>

            <Modal.Actions>
                <Button content='Close' onClick={() => onClose()} />
            </Modal.Actions>

        </Modal>
    )

}