import React, { useEffect, useState } from 'react';
import { Container, Radio, Form, Header, Button } from 'semantic-ui-react';
import { DateTime, Duration } from 'luxon';
import _ from 'lodash';

import { alertColors, arrivalTypeColors, centerColors } from './config';
import { useInterval } from './utils';
import AADChart from './AADChart';

const TOTAL_HOURS = 16;

export function generateTimeLabels(total_hours, resolution) {

    let start = DateTime.utc().startOf('hour').minus({ hour: 1 });
    let end = start.plus({ hour: total_hours-1 });

    let interval = Duration.fromObject({ hour: 1/resolution });

    let times = [];
    let i = start;
    while (i <= end) {

        times.push(i.toJSDate());
        i = i.plus(interval);

    }

    return times;

}

export const AADC = ({ airport, aar, aad, showLegends = false, view = 'arrivals', gdp }) => {

    const [delayprogram, setDelayProgram] = useState('');
    const [scale, setScale] = useState(1);
    const [timescale, setTimescale] = useState([]);
    const [chartType, setChartType] = useState();
    const [arrivalRate, setArrivalRate] = useState([]);
    const [clock, setClock] = useState(`${DateTime.utc().toFormat('D HHmm')}z`);

    const [carrierColors, setCarrierColors] = useState([]);
    const [fixColors, setFixColors] = useState([]);

    const chartTypeOptions = [
        { key: 1, text: 'Arrivals', value: 'arrivals' },
        { key: 2, text: 'Alert', value: 'alert' },
        { key: 3, text: 'Arrival Fix', value: 'arrival fix' },
        { key: 4, text: 'Carrier', value: 'carrier' },
        { key: 5, text: 'Centers', value: 'center' },
    ];

    const scaleOptions = [
        { key: 1, text: '15', value: 4 },
        { key: 1, text: '30', value: 2 },
        { key: 1, text: '60', value: 1 },
    ]

    useEffect(() => {

        if (_.filter(gdp, { airport: airport }).length === 0) setDelayProgram('No GDP');
        else setDelayProgram('Actual');

    }, [gdp, airport]);

    useEffect(() => {

        setTimescale(generateTimeLabels(TOTAL_HOURS, scale));

    }, [scale]);

    useEffect(() => {

        if (!aar) return;

        let r = [];
        for (let i = 0; i <= (scale * TOTAL_HOURS) - 1; i++) r[i] = Math.floor(aar / scale);

        setArrivalRate(r);

    }, [scale, aar]);

    useEffect(() => {

        if (!aad) return;

        let carriers = [];
        let x = 0;
        for (let i = 0; i <= aad.traffic.length - 1; i++) {

            if (aad.traffic[i].status === 'archived') continue;

            if (carriers.length >= 20) {

                if (!_.some(carriers, { carrier: 'Other' })) {

                    carriers.push({
                        carrier: 'Other',
                        name: 'Other',
                        color: centerColors[x].color
                    });
                    x++;

                }

            } else if (!_.some(carriers, { carrier: aad.traffic[i].carrier })) {

                carriers.push({
                    carrier: aad.traffic[i].carrier,
                    name: aad.traffic[i].carrier,
                    color: centerColors[x].color
                });
                x++;

            }

        }

        let fixes = [];
        if (_.isArray(aad.gates.arrival_gates)) {

            for (let i = 0; i <= aad.gates.arrival_gates.length - 1; i++) {

                fixes.push({
                    name: aad.gates.arrival_gates[i]['name'],
                    gate: aad.gates.arrival_gates[i]['name'],
                    color: centerColors[i]['color']
                });

            }

        }

        setCarrierColors(carriers);
        setFixColors(fixes);

    }, [aad]);

    useEffect(() => {
        setChartType(view);
    }, [view]);

    useInterval(() => {
        setClock(`${DateTime.utc().toFormat('D HHmm')}z`);
    }, 1000);

    useInterval(() => {

        let lastTimeSlot = DateTime.fromJSDate(timescale[0], { zone: 'utc' }).plus({ minutes: 5 });
        if (DateTime.utc().startOf('minute') > lastTimeSlot.startOf('minute') ) setTimescale(generateTimeLabels(TOTAL_HOURS, scale));

    }, 5 * 60 * 1000);

    return (

        <>
            <Form>
                <Form.Group inline>

                    {
                        _.map(scaleOptions, (option) => {

                            return <Radio key={option.value} as={Button} className={'hiddenradio rate'}
                                label={option.text}
                                value={option.value}
                                name='scaleGroup'
                                onChange={(_, { value }) => { setScale(value) }}
                                checked={scale === option.value}
                            />
                        })
                    }
                </Form.Group>
            </Form>
            <Form>
                <Form.Group inline style={{ marginLeft: '2px', marginBottom: '-1px' }}>
                    {
                        _.map(chartTypeOptions, (option) => {

                            return <Radio key={option.value} as={Button} className={'hiddenradio atype'}
                                label={option.text}
                                value={option.value}
                                name='chartTypeGroup'
                                onChange={(_, { value }) => { setChartType(value) }}
                                checked={chartType === option.value}
                            />

                        })
                    }
                </Form.Group>
            </Form>

            <Container fluid style={{ padding: '0 20px', marginTop: '0' }} className='bargraphborder'>

                <Container textAlign='center' text>
                    <Header style={{ textTransform: 'uppercase', marginTop: '8px' }} as='h2'>{`${airport.substring(1)}\u00A0\u00A0\u00A0${clock}\u00A0\u00A0\u00A0\u00A0${delayprogram}`}</Header>
                </Container>

                <Container fluid style={{ borderWidth: '2px' }} className='bargraphborder'>
                    {
                        timescale && arrivalRate && aad ?
                            {
                                'arrivals': <AADChart gdp={gdp} airport={airport} timescale={timescale} aar={arrivalRate} aad={aad} colors={arrivalTypeColors} keyword='status' showLegend={showLegends} />,
                                'alert': <AADChart gdp={gdp} airport={airport} timescale={timescale} aar={arrivalRate} aad={aad} colors={alertColors} keyword='alert' showLegend={showLegends} />,
                                'arrival fix': <AADChart gdp={gdp} airport={airport} timescale={timescale} aar={arrivalRate} aad={aad} colors={fixColors} keyword='gate' showLegend={showLegends} />,
                                'carrier': <AADChart gdp={gdp} airport={airport} timescale={timescale} aar={arrivalRate} aad={aad} colors={carrierColors} keyword='carrier' showLegend={showLegends} />,
                                'center': <AADChart gdp={gdp} airport={airport} timescale={timescale} aar={arrivalRate} aad={aad} colors={centerColors} keyword='artcc' showLegend={showLegends} />,
                            }[chartType] || null
                            : null
                    }
                </Container>
            </Container>
        </>

    )

}