export const tiers = [
 {
   "code": "ALL",
   "facility": "",
   "departureFacilitiesIncluded": "ZAB ZAU ZBW ZDC ZDV ZFW ZHU ZID ZJX ZKC ZLA ZLC ZMA ZME ZMP ZNY ZOA ZOB ZSE ZTL"
 },
 {
   "code": "ALL+Canada",
   "facility": "",
   "departureFacilitiesIncluded": "ZAB ZAU ZBW ZDC ZDV ZFW ZHU ZID ZJX ZKC ZLA ZLC ZMA ZME ZMP ZNY ZOA ZOB ZSE ZTL CZE CZM CZU CZV CZW CZY"
 },
 {
   "code": "Manual",
   "facility": "",
   "departureFacilitiesIncluded": ""
 },
 {
   "code": "ZAB1",
   "facility": "ZAB",
   "departureFacilitiesIncluded": "ZAB ZLA ZDV ZKC ZFW ZHU"
 },
 {
   "code": "ZAB10W",
   "facility": "ZAB",
   "departureFacilitiesIncluded": "ZAB ZDV ZFW ZHU ZKC ZLA ZLC ZMP ZOA ZSE"
 },
 {
   "code": "ZAB12W",
   "facility": "ZAB",
   "departureFacilitiesIncluded": "ZAB ZAU ZDV ZFW ZHU ZKC ZLA ZLC ZME ZMP ZOA ZSE"
 },
 {
   "code": "ZAB2",
   "facility": "ZAB",
   "departureFacilitiesIncluded": "ZAB ZLA ZDV ZKC ZFW ZHU ZOA ZLC ZMP ZAU ZID ZME ZTL ZJX"
 },
 {
   "code": "ZAB6W",
   "facility": "ZAB",
   "departureFacilitiesIncluded": "ZLA ZLC ZDV ZOA ZAB ZSE"
 },
 {
   "code": "ZABI",
   "facility": "ZAB",
   "departureFacilitiesIncluded": "ZAB"
 },
 {
   "code": "ZAU1",
   "facility": "ZAU",
   "departureFacilitiesIncluded": "ZAU ZMP ZKC ZID ZOB"
 },
 {
   "code": "ZAU12W",
   "facility": "ZAU",
   "departureFacilitiesIncluded": "ZAB ZAU ZDV ZFW ZHU ZKC ZLA ZLC ZME ZMP ZOA ZSE"
 },
 {
   "code": "ZAU2",
   "facility": "ZAU",
   "departureFacilitiesIncluded": "ZAU ZMP ZKC ZID ZOB ZLC ZDV ZAB ZFW ZME ZTL ZDC ZNY ZBW"
 },
 {
   "code": "ZAUI",
   "facility": "ZAU",
   "departureFacilitiesIncluded": "ZAU"
 },
 {
   "code": "ZBW1",
   "facility": "ZBW",
   "departureFacilitiesIncluded": "ZBW ZDC ZNY ZOB"
 },
 {
   "code": "ZBW1C",
   "facility": "ZBW",
   "departureFacilitiesIncluded": "ZBW ZDC ZNY ZOB CYYZ CYOW CYUL"
 },
 {
   "code": "ZBW2",
   "facility": "ZBW",
   "departureFacilitiesIncluded": "ZBW ZDC ZNY ZOB ZMP ZAU ZID ZTL ZJX"
 },
 {
   "code": "ZBWEC",
   "facility": "ZBW",
   "departureFacilitiesIncluded": "ZBW ZNY ZDC ZJX ZMA"
 },
 {
   "code": "ZBWI",
   "facility": "ZBW",
   "departureFacilitiesIncluded": "ZBW"
 },
 {
   "code": "ZDC1",
   "facility": "ZDC",
   "departureFacilitiesIncluded": "ZDC ZBW ZNY ZOB ZID ZTL ZJX"
 },
 {
   "code": "ZDC2",
   "facility": "ZDC",
   "departureFacilitiesIncluded": "ZDC ZBW ZNY ZOB ZID ZTL ZJX ZMA ZHU ZME ZKC ZAU ZMP"
 },
 {
   "code": "ZDCEC",
   "facility": "ZDC",
   "departureFacilitiesIncluded": "ZBW ZNY ZDC ZJX ZMA"
 },
 {
   "code": "ZDCI",
   "facility": "ZDC",
   "departureFacilitiesIncluded": "ZDC"
 },
 {
   "code": "ZDV1",
   "facility": "ZDV",
   "departureFacilitiesIncluded": "ZDV ZLC ZLA ZAB ZMP ZKC"
 },
 {
   "code": "ZDV10W",
   "facility": "ZDV",
   "departureFacilitiesIncluded": "ZAB ZDV ZFW ZHU ZKC ZLA ZLC ZMP ZOA ZSE"
 },
 {
   "code": "ZDV12W",
   "facility": "ZDV",
   "departureFacilitiesIncluded": "ZAB ZAU ZDV ZFW ZHU ZKC ZLA ZLC ZME ZMP ZOA ZSE"
 },
 {
   "code": "ZDV2",
   "facility": "ZDV",
   "departureFacilitiesIncluded": "ZDV ZLC ZLA ZAB ZMP ZKC ZSE ZOA ZFW ZME ZHU ZID ZOB ZAU"
 },
 {
   "code": "ZDV6W",
   "facility": "ZDV",
   "departureFacilitiesIncluded": "ZLA ZLC ZDV ZOA ZAB ZSE"
 },
 {
   "code": "ZDVI",
   "facility": "ZDV",
   "departureFacilitiesIncluded": "ZDV"
 },
 {
   "code": "ZFW1",
   "facility": "ZFW",
   "departureFacilitiesIncluded": "ZFW ZME ZKC ZAB ZHU"
 },
 {
   "code": "ZFW10W",
   "facility": "ZFW",
   "departureFacilitiesIncluded": "ZAB ZDV ZFW ZHU ZKC ZLA ZLC ZMP ZOA ZSE"
 },
 {
   "code": "ZFW12W",
   "facility": "ZFW",
   "departureFacilitiesIncluded": "ZAB ZAU ZDV ZFW ZHU ZKC ZLA ZLC ZME ZMP ZOA ZSE"
 },
 {
   "code": "ZFW2",
   "facility": "ZFW",
   "departureFacilitiesIncluded": "ZFW ZME ZKC ZAB ZHU ZLA ZDV ZMP ZAU ZID ZTL ZJX ZMA"
 },
 {
   "code": "ZFWI",
   "facility": "ZFW",
   "departureFacilitiesIncluded": "ZFW"
 },
 {
   "code": "ZHU1",
   "facility": "ZHU",
   "departureFacilitiesIncluded": "ZHU ZAB ZFW ZME ZTL ZJX ZMA"
 },
 {
   "code": "ZHU10W",
   "facility": "ZHU",
   "departureFacilitiesIncluded": "ZAB ZDV ZFW ZHU ZKC ZLA ZLC ZMP ZOA ZSE"
 },
 {
   "code": "ZHU12W",
   "facility": "ZHU",
   "departureFacilitiesIncluded": "ZAB ZAU ZDV ZFW ZHU ZKC ZLA ZLC ZME ZMP ZOA ZSE"
 },
 {
   "code": "ZHU2",
   "facility": "ZHU",
   "departureFacilitiesIncluded": "ZHU ZAB ZFW ZME ZTL ZJX ZMA ZLA ZDV ZKC ZID ZDC"
 },
 {
   "code": "ZHUGULF",
   "facility": "ZHU",
   "departureFacilitiesIncluded": "ZJX ZMA ZHU"
 },
 {
   "code": "ZHUI",
   "facility": "ZHU",
   "departureFacilitiesIncluded": "ZHU"
 },
 {
   "code": "ZID1",
   "facility": "ZID",
   "departureFacilitiesIncluded": "ZID ZAU ZOB ZDC ZME ZTL ZKC"
 },
 {
   "code": "ZID2",
   "facility": "ZID",
   "departureFacilitiesIncluded": "ZID ZAU ZOB ZDC ZME ZTL ZKC ZAB ZDV ZFW ZMP ZHU ZJX ZNY ZBW"
 },
 {
   "code": "ZIDI",
   "facility": "ZID",
   "departureFacilitiesIncluded": "ZID"
 },
 {
   "code": "ZJX1",
   "facility": "ZJX",
   "departureFacilitiesIncluded": "ZJX ZMA ZHU ZTL ZDC"
 },
 {
   "code": "ZJX2",
   "facility": "ZJX",
   "departureFacilitiesIncluded": "ZJX ZMA ZHU ZTL ZDC ZAB ZFW ZME ZID ZOB ZNY ZBW"
 },
 {
   "code": "ZJXEC",
   "facility": "ZJX",
   "departureFacilitiesIncluded": "ZBW ZNY ZDC ZJX ZMA"
 },
 {
   "code": "ZJXGULF",
   "facility": "ZJX",
   "departureFacilitiesIncluded": "ZJX ZMA ZHU"
 },
 {
   "code": "ZJXI",
   "facility": "ZJX",
   "departureFacilitiesIncluded": "ZJX"
 },
 {
   "code": "ZKC1",
   "facility": "ZKC",
   "departureFacilitiesIncluded": "ZKC ZMP ZAU ZID ZME ZFW ZAB ZDV"
 },
 {
   "code": "ZKC10W",
   "facility": "ZKC",
   "departureFacilitiesIncluded": "ZAB ZDV ZFW ZHU ZKC ZLA ZLC ZMP ZOA ZSE"
 },
 {
   "code": "ZKC12W",
   "facility": "ZKC",
   "departureFacilitiesIncluded": "ZAB ZAU ZDV ZFW ZHU ZKC ZLA ZLC ZME ZMP ZOA ZSE"
 },
 {
   "code": "ZKC2",
   "facility": "ZKC",
   "departureFacilitiesIncluded": "ZKC ZMP ZAU ZID ZME ZFW ZAB ZDV ZLC ZLA ZHU ZTL ZDC ZOB"
 },
 {
   "code": "ZKCI",
   "facility": "ZKC",
   "departureFacilitiesIncluded": "ZKC"
 },
 {
   "code": "ZLA1",
   "facility": "ZLA",
   "departureFacilitiesIncluded": "ZLA ZLC ZOA ZDV ZAB"
 },
 {
   "code": "ZLA10W",
   "facility": "ZLA",
   "departureFacilitiesIncluded": "ZAB ZDV ZFW ZHU ZKC ZLA ZLC ZMP ZOA ZSE"
 },
 {
   "code": "ZLA12W",
   "facility": "ZLA",
   "departureFacilitiesIncluded": "ZAB ZAU ZDV ZFW ZHU ZKC ZLA ZLC ZME ZMP ZOA ZSE"
 },
 {
   "code": "ZLA2",
   "facility": "ZLA",
   "departureFacilitiesIncluded": "ZLA ZLC ZOA ZDV ZAB ZSE ZMP ZKC ZFW ZHU"
 },
 {
   "code": "ZLA6W",
   "facility": "ZLA",
   "departureFacilitiesIncluded": "ZLA ZLC ZDV ZOA ZAB ZSE"
 },
 {
   "code": "ZLAI",
   "facility": "ZLA",
   "departureFacilitiesIncluded": "ZLA"
 },
 {
   "code": "ZLAWC",
   "facility": "ZLA",
   "departureFacilitiesIncluded": "ZSE ZOA ZLA"
 },
 {
   "code": "ZLC1",
   "facility": "ZLC",
   "departureFacilitiesIncluded": "ZLC ZDV ZLA ZMP ZOA ZSE"
 },
 {
   "code": "ZLC10W",
   "facility": "ZLC",
   "departureFacilitiesIncluded": "ZAB ZDV ZFW ZHU ZKC ZLA ZLC ZMP ZOA ZSE"
 },
 {
   "code": "ZLC12W",
   "facility": "ZLC",
   "departureFacilitiesIncluded": "ZAB ZAU ZDV ZFW ZHU ZKC ZLA ZLC ZME ZMP ZOA ZSE"
 },
 {
   "code": "ZLC1C",
   "facility": "ZLC",
   "departureFacilitiesIncluded": "ZLC ZDV ZLA ZMP ZOA ZSE CYYZ CYEG"
 },
 {
   "code": "ZLC2",
   "facility": "ZLC",
   "departureFacilitiesIncluded": "ZLC ZDV ZLA ZMP ZOA ZSE ZAB ZKC ZAU ZOB"
 },
 {
   "code": "ZLC6W",
   "facility": "ZLC",
   "departureFacilitiesIncluded": "ZLA ZLC ZDV ZOA ZAB ZSE"
 },
 {
   "code": "ZLCI",
   "facility": "ZLC",
   "departureFacilitiesIncluded": "ZLC"
 },
 {
   "code": "ZMA1",
   "facility": "ZMA",
   "departureFacilitiesIncluded": "ZMA ZJX ZHU"
 },
 {
   "code": "ZMA2",
   "facility": "ZMA",
   "departureFacilitiesIncluded": "ZMA ZJX ZHU ZFW ZME ZTL ZDC"
 },
 {
   "code": "ZMAEC",
   "facility": "ZMA",
   "departureFacilitiesIncluded": "ZBW ZNY ZDC ZJX ZMA"
 },
 {
   "code": "ZMAGULF",
   "facility": "ZMA",
   "departureFacilitiesIncluded": "ZJX ZMA ZHU"
 },
 {
   "code": "ZMAI",
   "facility": "ZMA",
   "departureFacilitiesIncluded": "ZMA"
 },
 {
   "code": "ZME1",
   "facility": "ZME",
   "departureFacilitiesIncluded": "ZME ZTL ZID ZKC ZFW ZHU"
 },
 {
   "code": "ZME12W",
   "facility": "ZME",
   "departureFacilitiesIncluded": "ZAB ZAU ZDV ZFW ZHU ZKC ZLA ZLC ZME ZMP ZOA ZSE"
 },
 {
   "code": "ZME2",
   "facility": "ZME",
   "departureFacilitiesIncluded": "ZME ZTL ZID ZKC ZFW ZHU ZAB ZDV ZMP ZAU ZOB ZDC ZJX ZMA"
 },
 {
   "code": "ZMEI",
   "facility": "ZME",
   "departureFacilitiesIncluded": "ZME"
 },
 {
   "code": "ZMP1",
   "facility": "ZMP",
   "departureFacilitiesIncluded": "ZMP ZAU ZOB ZKC ZDV ZLC"
 },
 {
   "code": "ZMP10W",
   "facility": "ZMP",
   "departureFacilitiesIncluded": "ZAB ZDV ZFW ZHU ZKC ZLA ZLC ZMP ZOA ZSE"
 },
 {
   "code": "ZMP12W",
   "facility": "ZMP",
   "departureFacilitiesIncluded": "ZAB ZAU ZDV ZFW ZHU ZKC ZLA ZLC ZME ZMP ZOA ZSE"
 },
 {
   "code": "ZMP1C",
   "facility": "ZMP",
   "departureFacilitiesIncluded": "ZMP ZAU ZOB ZKC ZDV ZLC CYYZ"
 },
 {
   "code": "ZMP2",
   "facility": "ZMP",
   "departureFacilitiesIncluded": "ZMP ZAU ZOB ZKC ZDV ZLC ZSE ZOA ZLA ZAB ZFW ZME ZID ZDC ZNY ZBW"
 },
 {
   "code": "ZMPI",
   "facility": "ZMP",
   "departureFacilitiesIncluded": "ZMP"
 },
 {
   "code": "ZNY1",
   "facility": "ZNY",
   "departureFacilitiesIncluded": "ZNY ZBW ZDC ZOB"
 },
 {
   "code": "ZNY2",
   "facility": "ZNY",
   "departureFacilitiesIncluded": "ZNY ZBW ZDC ZOB ZMP ZAU ZID ZTL ZJX"
 },
 {
   "code": "ZNYEC",
   "facility": "ZNY",
   "departureFacilitiesIncluded": "ZBW ZNY ZDC ZJX ZMA"
 },
 {
   "code": "ZNYI",
   "facility": "ZNY",
   "departureFacilitiesIncluded": "ZNY"
 },
 {
   "code": "ZOA1",
   "facility": "ZOA",
   "departureFacilitiesIncluded": "ZOA ZLA ZSE ZLC"
 },
 {
   "code": "ZOA10W",
   "facility": "ZOA",
   "departureFacilitiesIncluded": "ZAB ZDV ZFW ZHU ZKC ZLA ZLC ZMP ZOA ZSE"
 },
 {
   "code": "ZOA12W",
   "facility": "ZOA",
   "departureFacilitiesIncluded": "ZAB ZAU ZDV ZFW ZHU ZKC ZLA ZLC ZME ZMP ZOA ZSE"
 },
 {
   "code": "ZOA2",
   "facility": "ZOA",
   "departureFacilitiesIncluded": "ZOA ZLA ZSE ZLC ZDV ZAB ZMP"
 },
 {
   "code": "ZOA6W",
   "facility": "ZOA",
   "departureFacilitiesIncluded": "ZLA ZLC ZDV ZOA ZAB ZSE"
 },
 {
   "code": "ZOAI",
   "facility": "ZOA",
   "departureFacilitiesIncluded": "ZOA"
 },
 {
   "code": "ZOAWC",
   "facility": "ZOA",
   "departureFacilitiesIncluded": "ZSE ZOA ZLA"
 },
 {
   "code": "ZOB1",
   "facility": "ZOB",
   "departureFacilitiesIncluded": "ZOB ZAU ZMP ZID ZDC ZNY ZBW"
 },
 {
   "code": "ZOB1C",
   "facility": "ZOB",
   "departureFacilitiesIncluded": "ZOB ZAU ZMP ZID ZDC ZNY ZBW CYYZ"
 },
 {
   "code": "ZOB2",
   "facility": "ZOB",
   "departureFacilitiesIncluded": "ZOB ZAU ZMP ZID ZDC ZNY ZBW ZLC ZDV ZKC ZME ZTL ZJX"
 },
 {
   "code": "ZOBI",
   "facility": "ZOB",
   "departureFacilitiesIncluded": "ZOB"
 },
 {
   "code": "ZSE1",
   "facility": "ZSE",
   "departureFacilitiesIncluded": "ZSE ZOA ZLC"
 },
 {
   "code": "ZSE10W",
   "facility": "ZSE",
   "departureFacilitiesIncluded": "ZAB ZDV ZFW ZHU ZKC ZLA ZLC ZMP ZOA ZSE"
 },
 {
   "code": "ZSE12W",
   "facility": "ZSE",
   "departureFacilitiesIncluded": "ZAB ZAU ZDV ZFW ZHU ZKC ZLA ZLC ZME ZMP ZOA ZSE"
 },
 {
   "code": "ZSE1C",
   "facility": "ZSE",
   "departureFacilitiesIncluded": "ZSE ZOA ZLC CYVR CYYC CYEG"
 },
 {
   "code": "ZSE2",
   "facility": "ZSE",
   "departureFacilitiesIncluded": "ZSE ZOA ZLC ZMP ZDV ZLA"
 },
 {
   "code": "ZSE6W",
   "facility": "ZSE",
   "departureFacilitiesIncluded": "ZLA ZLC ZDV ZOA ZAB ZSE"
 },
 {
   "code": "ZSEI",
   "facility": "ZSE",
   "departureFacilitiesIncluded": "ZSE"
 },
 {
   "code": "ZSEWC",
   "facility": "ZSE",
   "departureFacilitiesIncluded": "ZSE ZOA ZLA"
 },
 {
   "code": "ZTL1",
   "facility": "ZTL",
   "departureFacilitiesIncluded": "ZTL ZID ZDC ZJX ZME ZHU"
 },
 {
   "code": "ZTL2",
   "facility": "ZTL",
   "departureFacilitiesIncluded": "ZTL ZID ZDC ZJX ZME ZHU ZAB ZFW ZKC ZAU ZOB ZNY ZBW ZMA"
 },
 {
   "code": "ZTLI",
   "facility": "ZTL",
   "departureFacilitiesIncluded": "ZTL"
 }
]