import React, { useEffect, useState } from 'react';
import { Modal, Form, Button, Header, Segment, Grid } from 'semantic-ui-react';
import { DateTime } from 'luxon';

import {queryClient} from './App';
// import { useFlightData} from './api/aadc';
import { isFormError } from './utils';

export const ECRModal = ({ onClose, showModal, item }) => {

    // const [assignTimeSlot, { isLoading: isSubmitting }] = useAssignTimeSlot();
    // const [applyModel, { isLoading: isApplying }] = useApplyModel();

    const adl = DateTime.utc().toFormat('dd/HHmm');

    const [flight, setFlight] = useState(undefined);

    const [callsign, setCallsign] = useState(undefined);
    const [origin, setOrigin] = useState(undefined);
    const [destination, setDestination] = useState(undefined);
    const [formError, setFormError] = useState(true);

    const [eedctDay, setEEDCTDay] = useState(undefined);
    const [eedctTime, setEEDCTTime] = useState(undefined);
    const [eedct, setEEDCT] = useState(undefined);

    const [ctd, setCTD] = useState(undefined);
    const [cta, setCTA] = useState(undefined);
    const [erta, setERTA] = useState(undefined);

    async function getFlightData(callsign, orig) {

        if (!callsign || !orig || !destination) return;

        let cacheData = queryClient.getQueryData(['getAAD', destination], {callsign: callsign, dep: orig});

        if (cacheData) setFlight(cacheData);
        else {
            
            let data = await queryClient.fetchQuery(['getAAD', destination]);
            if (data) setFlight(data);

        }

    }

    async function apply() {

        let data = {
            ...item,
            ...{
                std: eedct
            }
        };

        console.log(data);
        return;
        // let result = await applyModel(data);

        // setCTD(result.std);
        // setCTA(result.eta);
        // setERTA(result.std);

    };

    async function sendRequest() {

        // let data = {
        //     callsign,
        //     timeslot: cta
        // };
        // await assignTimeSlot(data);
        clearAll();

    }

    function clearAll() {

        setCallsign(undefined);
        setOrigin(undefined);
        setFlight(undefined);
        setCTD(undefined);
        setCTA(undefined);
        setERTA(undefined);

    }

    useEffect(() => {

        if (!flight) return;

        setCallsign(flight.callsign);
        setOrigin(flight.dep);

    }, [flight]);

    useEffect(() => {

        if (!item) return;

        setFlight(item);

    }, [item]);

    useEffect(() => {

        if (isFormError(callsign, origin)) setFormError(true);
        else setFormError(false);

    }, [callsign, origin]);

    useEffect(() => {

        if (!eedctDay && !eedctTime) return;

        setEEDCT(DateTime.fromFormat(`${eedctDay}/${eedctTime}`, 'dd/T', { zone: 'utc' }));

    }, [eedctDay, eedctTime]);

    return (
        <Modal onClose={() => onClose()} open={showModal}>

            <Modal.Header>EDCT Change Request</Modal.Header>

            <Modal.Content>
                {
                    flight ?
                    <Form>

                        <Segment>

                            <Header as='h4' content={adl.toString()} floated='right' />

                            <Form.Group>
                                <Form.Input inline label='ACID:' value={callsign} onChange={(_, { value }) => setCallsign(value)} />
                                <Form.Input inline label='ORIG:' value={origin} onChange={(_, { value }) => setOrigin(value)} />
                                <Form.Input inline label='DEST' value={destination} onChange={(_, { value }) => setDestination(value)} />
                            </Form.Group>

                            <Form.Group>
                                <label>Earliest EDCT:</label>
                                <Form.Input inline width={2} onChange={(_, { value }) => setEEDCTDay(value)} />
                                <Form.Input value={eedct} onChange={(_, { value }) => setEEDCTTime(value)} />
                            </Form.Group>

                            <Button.Group>
                                <Button content='Get Flight Data' disabled={formError} onClick={() => getFlightData(callsign, origin)} />
                                {/* <Button content='Flight Detail' /> */}
                            </Button.Group>

                        </Segment>

                        <Segment>

                            <Header as='h5' content='Flight Data' />

                            <Grid padded relaxed columns='equal'>

                                <Grid.Row stretched>
                                    <Grid.Column>CTD: {ctd}</Grid.Column>
                                    <Grid.Column>CTA: {cta}</Grid.Column>
                                </Grid.Row>
                                <Grid.Row stretched>
                                    <Grid.Column>ETD: {DateTime.fromJSDate(new Date(flight.std), { zone: 'utc' }).toFormat('dd/T')}</Grid.Column>
                                    <Grid.Column>ETA: {DateTime.fromJSDate(new Date(flight.eta), { zone: 'utc' }).toFormat('dd/T')}</Grid.Column>
                                </Grid.Row>
                                <Grid.Row stretched>
                                    <Grid.Column>ETE: {Number(flight.ete).toFixed(2)}</Grid.Column>
                                    <Grid.Column>ERTA: {erta}</Grid.Column>
                                </Grid.Row>

                            </Grid>

                        </Segment>

                    </Form>
                    : null
                }

            </Modal.Content>

            <Modal.Actions>

                <Button.Group floated='left'>

                    <Button content='Apply Model' disabled={true} onClick={() => apply()} />
                    <Button content='Send Request' disabled={true} onClick={() => sendRequest()} />

                </Button.Group>

                <Button.Group floated='right'>

                    <Button content='Clear All' onClick={() => clearAll()} />
                    <Button content='Cancel' onClick={() => onClose()} />

                </Button.Group>

            </Modal.Actions>

        </Modal>
    );

}