import React, { useEffect, useState } from 'react';
import { Confirm, Form, Modal, Button, Container, Header } from 'semantic-ui-react';

import { useSetAAR } from './api/tfms';
import { isFormError } from './utils';

export function ModifyAAR({airport, show, onClose}) {

    const {mutateAsync: submitAAR} = useSetAAR();

    const [aar, setAAR] = useState(undefined);
    const [formError, setFormError] = useState(true);

    useEffect(() => {

        if (isFormError([aar])) setFormError(true);
        else setFormError(false);

    }, [aar]);

    async function sendRequest() {
        let data = {
            airport: airport,
            aar: aar
        };
        await submitAAR(data);
        onClose();
    }

    return (
        <Modal closeIcon={true} onClose={() => onClose()} open={show}>

            <Modal.Header>Modify AAR: {airport}</Modal.Header>

                <Modal.Content>
                    <Container className='lightbordered'>

                        <Header as='h4' content='Modify AAR' />
                    
                        <Form>
                            <Form.Input inline label='AAR' value={aar} onChange={(_, { value }) => setAAR(value)} />
                        </Form>

                    </Container>

                </Modal.Content>

                <Modal.Actions>

                    <Button floated='left' content='Submit' disabled={formError} onClick={() => sendRequest()} />
                    <Button content='Close' onClick={() => onClose()} />

                </Modal.Actions>
        </Modal>
    )

}

export function ResetAAR({airport, show, onClose}) {

    const {mutateAsync: setAAR} = useSetAAR();

    async function reset() {

        let data = {
            airport: airport,
            aar: 'auto'
        };
        await setAAR(data);
        onClose();

    }

    return (
        <Confirm
            open={show}
            content={`Confirm to reset AAR to automatic mode: ${airport}`}
            confirmButton='Reset'
            onConfirm={reset}
            onCancel={onClose}
        />
    )

}