import {default as axios} from 'axios';
// import Session from 'supertokens-auth-react/recipe/session';

import {API_URL} from '../config';

const _Authapi = axios.create({
    baseURL: API_URL,
    withCredentials: true,
});

const _Publicapi = axios.create({
    baseURL: API_URL,
    withCredentials: true,
});

// Session.addAxiosInterceptors(_Authapi);

export const publicapi = _Publicapi;
export const api = _Authapi;