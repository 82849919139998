import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Container, Grid, Table } from 'semantic-ui-react';
import { Checkbox, Input } from 'formik-semantic-ui-react';
import { Field, useFormikContext } from 'formik';
import _ from 'lodash';
import { GithubPicker } from 'react-color';
import { default as SUIRNumberInput } from 'semantic-ui-react-numberinput';
import * as turf from '@turf/turf';
import { Layer, Source } from 'react-map-gl';
import { ReactSortable } from 'react-sortablejs';

import { useAirborneADL, useFix, useNavaid, useAirport, useAirway, useFacilities } from './api/tfms';
import { airportRegex, airwayRegex, defaultColorSet, fixRegex, navRegex } from './config';

export function LatLonGrid({ show, color }) {

    const latlonGrid = useMemo(() => {

        let grid = {
            type: "FeatureCollection",
            features: []
        };

        for (let lng = -170; lng <= 180; lng += 10) {
            grid.features.push({
                type: 'Feature',
                geometry: { type: 'LineString', coordinates: [[lng, -90], [lng, 90]] },
                properties: { value: lng }
            });
        }
        for (let lat = -80; lat <= 80; lat += 10) {
            grid.features.push({
                type: 'Feature',
                geometry: { type: 'LineString', coordinates: [[-180, lat], [180, lat]] },
                properties: { value: lat }
            });
        }

        return grid;

    }, []);

    return (
        show ?
            <Source id='latlongrid' type='geojson' data={latlonGrid}>
                <Layer type='line' paint={{
                    "line-color": color,
                    "line-dasharray": [4, 2]
                }} />
            </Source>
            : null
    )

}

export function NAVAID({ source, type, color, show = false, showLabel = false }) {

    const [navaidType, setNavaidType] = useState(null);
    const [textColor, setTextColor] = useState(null);
    const [iconImage, setIconImage] = useState(null);

    useEffect(() => {

        switch (type) {
            case 'high_navaid':
                setNavaidType('H');
                break;

            case 'low_navaid':
                setNavaidType('L');
                break;

            case 'terminal_navaid':
                setNavaidType('T');
                break;

            case 'other_navaid':
                setNavaidType('');
                break;

            default:
                break;
        }

    }, [type]);

    useEffect(() => {

        let colorName = _.find(defaultColorSet, { hex: color }).name;
        setIconImage(`fix_${_.toLower(colorName)}`);

        if (_.isFunction(color)) setTextColor(color(type));
        else setTextColor(color);

    }, [type, color]);

    return show ?
        <Layer type='symbol' source='composite' source-layer={source}
            filter={[
                '==',
                'type',
                navaidType
            ]}
            paint={{
                'text-color': textColor,
            }}
            layout={{
                'icon-image': iconImage,
                'icon-size': 1,
                'text-size': 10,
                'text-field': ['case',
                    ['==', showLabel, true],
                    ['get', 'fixname'],
                    ''
                ],
                "text-allow-overlap": true,
                "text-ignore-placement": true,
                "icon-allow-overlap": true,
                "icon-ignore-placement": true,
                'text-anchor': 'top',
                'text-offset': [2, -1.8],
                'text-font': ['Arial Unicode MS Regular']
            }}
        />
        : null

}

export function Fixes({ source, type, color, show = false, showLabel = false }) {

    const [textColor, setTextColor] = useState(null);
    const [iconImage, setIconImage] = useState(null);

    useEffect(() => {

        let colorName = _.find(defaultColorSet, { hex: color }).name;

        setIconImage(`fix_${_.toLower(colorName)}`);

        if (_.isFunction(color)) setTextColor(color(type));
        else setTextColor(color);

    }, [type, color]);

    return show ?
        <Layer type='symbol' source='composite' source-layer={source}
            filter={[
                '==',
                'type',
                type
            ]}
            paint={{
                'text-color': textColor,
            }}
            layout={{
                'icon-image': iconImage,
                'icon-size': 1,
                'text-size': 10,
                'text-field': ['case',
                    ['==', showLabel, true],
                    ['get', 'fixname'],
                    ''
                ],
                "text-allow-overlap": true,
                "text-ignore-placement": true,
                "icon-allow-overlap": true,
                "icon-ignore-placement": true,
                'text-anchor': 'top',
                'text-offset': [2, -1.8],
                'text-font': ['Arial Unicode MS Regular']
            }}
        />
        : null

}

export function RangeRingOption({ selectedRow, setSelectedRow, helpers, form, arrayHelpers }) {

    useEffect(() => {
        helpers({

            insert: (index, value) => {
                arrayHelpers.insert(index, value);
            },

            delete: (index) => {
                arrayHelpers.remove(index);
            },

            deleteAll: () => {
                form.setFieldValue('rangerings', []);
            },

            top: (index) => {
                arrayHelpers.move(index, 0);
            }

        });
    }, [helpers, form, arrayHelpers]);

    return (

        form.values.rangerings && form.values.rangerings.length > 0 ? (
            <ReactSortable tag='tbody' list={form.values.rangerings} setList={(newState) => form.setFieldValue('rangerings', newState)}>
                {_.map(form.values.rangerings, (rangering, index) => {

                    return (

                        <Table.Row key={rangering.id}>

                            <Table.Cell active={selectedRow === index} selectable style={{ cursor: 'pointer' }} onClick={() => setSelectedRow(index)}><h4>{index + 1}</h4></Table.Cell>
                            <Table.Cell>
                                <Checkbox name={`rangerings.${index}.show`} checked={rangering.show} />
                            </Table.Cell>
                            <Table.Cell>
                                <Checkbox name={`rangerings.${index}.showLabel`} checked={rangering.showLabel} />
                            </Table.Cell>
                            <Table.Cell>
                                <ColorPalette initialColor={rangering.color} onChange={(color) => form.setFieldValue(`rangerings.${index}.color`, color)} />
                            </Table.Cell>
                            <Table.Cell>
                                <Input name={`rangerings.${index}.location`} />
                            </Table.Cell>
                            <Table.Cell>
                                <SUIRNumberInput
                                    className='inline'
                                    buttonPlacement='right'
                                    minValue={1}
                                    maxValue={200}
                                    value={String(rangering.number)}
                                    onChange={(value) => form.setFieldValue(`rangerings.${index}.number`, value)}
                                />
                            </Table.Cell>
                            <Table.Cell>
                                <SUIRNumberInput
                                    className='inline'
                                    buttonPlacement='right'
                                    minValue={1}
                                    maxValue={3000}
                                    value={String(rangering.distance)}
                                    onChange={(value) => form.setFieldValue(`rangerings.${index}.distance`, value)}
                                />
                            </Table.Cell>

                        </Table.Row>

                    )

                })}
            </ReactSortable>
        )
            : null

    )

}

function RangeRing({ query, number, distance, color, show, showLabel }) {

    const { data: aad } = useAirborneADL();
    const { refetch: searchFix, data: fix } = useFix(query);
    const { refetch: searchNavaid, data: navaid } = useNavaid(query);
    const { refetch: searchAirway, data: airway } = useAirway(query);
    const { refetch: searchAirport, data: airport } = useAirport(query);

    const [latitude, setLatitude] = useState();
    const [longitude, setLongitude] = useState();

    const search = useCallback(async () => {

        const expressionArr = [
            {
                expression: navRegex,
                fn: async () => { await searchNavaid(); return navaid; }
            },
            {
                expression: airportRegex,
                fn: async () => { await searchAirport(); return airport; }
            },
            {
                expression: fixRegex,
                fn: async () => { await searchFix(); return fix; }
            },
            {
                expression: airwayRegex,
                fn: async () => { await searchAirway(); return airway; }
            },
        ];

        let result = null;

        for (const expression of expressionArr) {

            if (expression.expression.test(query)) result = await expression.fn();
            if (!_.isNil(result)) break;

        }

        if (_.isNil(result)) {

            result = _.find(aad, { callsign: query, status: 'active' });
            if (!_.isNil(result)) result = { latitude: result.position.geometry.coordinates[1], longitude: result.position.geometry.coordinates[0] }

        }

        return result;

    }, [searchNavaid, navaid, searchAirport, airport, searchFix, fix, searchAirway, airway, query, aad]);

    useEffect(() => {

        search().then(result => {

            if (!_.isNil(result)) {

                setLatitude(result.latitude);
                setLongitude(result.longitude);

            }

        });

    }, [query, search, aad]);

    return (

        <>
            {
                (show && _.isNumber(latitude) && _.isNumber(longitude)) ? _.map(Array.from({ length: number }, (_, k) => k + 1), num => {

                    let circle = turf.circle([longitude, latitude], distance * num, { units: 'nauticalmiles', steps: 50 });
                    let line = turf.lineString(...circle.geometry.coordinates);

                    return (
                        <React.Fragment key={num}>
                            <Source type='geojson' data={turf.point([longitude, latitude])}>
                                <Layer
                                    type='circle'
                                    paint={{
                                        "circle-opacity": 0
                                    }}
                                />
                                <Layer
                                    type='symbol'
                                    paint={{
                                        "text-color": color,
                                    }}
                                    layout={{
                                        'text-field': ['case',
                                            ['==', showLabel, true],
                                            query,
                                            ''
                                        ],
                                        "text-transform": 'uppercase',
                                        "text-size": 10,
                                        "text-letter-spacing": 0.1,
                                        "text-offset": [0, -1],
                                        'text-font': ["Arial Unicode MS Regular"],
                                        "text-allow-overlap": true,
                                        "text-ignore-placement": true,
                                        "icon-allow-overlap": true,
                                        "icon-ignore-placement": true
                                    }}
                                />
                            </Source>
                            <Source type='geojson' data={line}>
                                <Layer
                                    type='line'
                                    paint={{
                                        "line-color": color
                                    }}
                                />
                                <Layer
                                    type='symbol'
                                    paint={{
                                        "text-color": color
                                    }}
                                    layout={{
                                        'text-field': ['case',
                                            ['==', showLabel, true],
                                            String(num * distance),
                                            ''
                                        ],
                                        "text-transform": 'uppercase',
                                        "text-size": 10,
                                        "text-letter-spacing": 0.1,
                                        "text-offset": [0, -1],
                                        'text-font': ["Arial Unicode MS Regular"],
                                        "text-allow-overlap": true,
                                        "text-ignore-placement": true,
                                        "icon-allow-overlap": true,
                                        "icon-ignore-placement": true
                                    }}
                                />
                            </Source>
                        </React.Fragment>
                    )
                }) : null
            }
        </>

    )

}

export function RangeRings({ rangerings }) {

    return _.isArray(rangerings) && rangerings.length > 0 ?
        _.map(rangerings, rangering => {

            return rangering.show ?
                <RangeRing key={`${rangering.id}`}
                    show={rangering.show}
                    showLabel={rangering.showLabel}
                    query={rangering.location.toUpperCase()}
                    number={rangering.number}
                    distance={rangering.distance}
                    // color={`hsl(${getHSL(rangering.color)})`}
                    color={rangering.color}
                /> :
                null

        })
        : null

}

const Airport = ({ airport, circleColor, labelColor, showLabel }) => {

    const [color, setColor] = useState(null);
    const [textColor, setTextColor] = useState(null);

    useEffect(() => {

        if (_.isFunction(circleColor)) setColor(circleColor(airport));
        else setColor(circleColor);

        if (_.isFunction(labelColor)) setTextColor(labelColor(airport));
        else setTextColor(labelColor);

    }, [airport, circleColor, labelColor]);

    return (

        !_.isNil(color) && !_.isNil(textColor) ?
            <Source type='geojson' data={airport}>
                <Layer type='symbol' layout={{
                    'text-field': ['case',
                        ['==', showLabel, true],
                        airport.properties.iata,
                        ''
                    ],
                    "text-size": 10,
                    "text-letter-spacing": 0.1,
                    "text-offset": [0, -1],
                    'text-font': ["Arial Unicode MS Regular"],
                    "text-allow-overlap": true,
                    "text-ignore-placement": true,
                    "icon-allow-overlap": true,
                    "icon-ignore-placement": true
                }}
                    paint={{
                        "text-color": textColor
                    }}
                />
                <Layer type='circle' paint={{
                    "circle-radius": 4,
                    "circle-color": color
                }} />
            </Source>
            : null

    )

};

export function Airports({ type, circleColor, labelColor, source = null, show = false, showLabel = false }) {

    const { data: airports } = useFacilities();

    return show ?
        {

            'airports_all': <>{
                !_.isNil(source) ?
                    <>
                        <Layer type='circle' source='composite' source-layer={source}
                            paint={{
                                "circle-color": circleColor,
                                "circle-radius": 4
                            }}

                        />
                        <Layer type='symbol' source='composite' source-layer={source}
                            layout={{
                                'text-field': ['case',
                                    ['==', showLabel, true],
                                    ['get', 'iata'],
                                    ''
                                ],
                                "text-size": 10,
                                "text-letter-spacing": 0.1,
                                "text-offset": [0, -1],
                                'text-font': ["Arial Unicode MS Regular"],
                                "text-allow-overlap": true,
                                "text-ignore-placement": true,
                                "icon-allow-overlap": true,
                                "icon-ignore-placement": true
                            }}
                            paint={{
                                "text-color": labelColor,
                                "icon-opacity": 0
                            }}
                        />
                    </> : null
            }</>,

            'airports_pacing': <>{
                _.isArray(airports) ?
                    _.map(airports, a => {

                        let airport = {
                            "type": "Feature",
                            "geometry": {
                                "type": "Point",
                                "coordinates": [a.longitude, a.latitude]
                            },
                            "properties": {
                                "iata": a.iata,
                            }
                        }

                        return <Airport key={airport.properties.iata} showLabel={showLabel} circleColor={circleColor} labelColor={labelColor} airport={airport} />

                    }) : null
            }</>

        }[type] || null
        : null;

}

export function Airway({ type, textfield, color, source, show = false, showLabel = false }) {

    return show ?
        <>
            <Layer type='symbol' source='composite' source-layer={source}
                filter={[
                    '==',
                    'type',
                    type
                ]}
                paint={{
                    "text-color": _.isFunction(color) ? color() : color,
                    "icon-opacity": 0,
                }}
                layout={{
                    'text-field': ['case',
                        ['==', showLabel, true],
                        ['get', textfield],
                        ''
                    ],
                    "text-transform": 'uppercase',
                    "text-size": 10,
                    "text-letter-spacing": 0.1,
                    "text-variable-anchor": ['top'],
                    "text-font": ["Arial Unicode MS Regular"],
                }}
            />
            <Layer type='line' source='composite' source-layer={source}
                filter={[
                    '==',
                    'type',
                    type
                ]}
                paint={{
                    "line-width": 1.25,
                    "line-color": _.isFunction(color) ? color() : color,
                    "line-dasharray": [10, 8]
                }}
            />
        </>
        : null

}

export function SectorFeature({ filter, type, textfield, source, labelSource, color, fillFunc = 'transparent', show = false, showLabel = false }) {

    return show ?
        <>
            <Layer type='symbol' source='composite' source-layer={labelSource}
                filter={[
                    '==',
                    filter,
                    type
                ]}
                paint={{
                    "text-color": _.isFunction(color) ? color() : color,
                }}
                layout={{
                    'text-field': ['case',
                        ['==', showLabel, true],
                        ['get', textfield],
                        ''
                    ],
                    "text-transform": 'uppercase',
                    "text-size": 10,
                    "text-letter-spacing": 0.1,
                    "text-variable-anchor": ['top'],
                    "text-font": ["Arial Unicode MS Regular"],
                }}
            />
            <Layer type='line' source='composite' source-layer={source}
                filter={[
                    '==',
                    filter,
                    type
                ]}
                paint={{
                    "line-width": 1.25,
                    "line-color": _.isFunction(color) ? color() : color,
                }}
            />
            <Layer type='fill' source='composite' source-layer={source}
                filter={[
                    '==',
                    filter,
                    type
                ]}
                paint={{
                    "fill-antialias": false,
                    "fill-color": fillFunc,
                }}
            />
        </>
        : null

}

export function SUA({ type, textfield, source, labelSource, color, fillFunc = 'transparent', show, showLabel }) {

    return show ?
        <>
            <Layer type='symbol' source='composite' source-layer={labelSource}
                filter={[
                    '==',
                    'type',
                    type
                ]}
                paint={{
                    "text-color": _.isFunction(color) ? color() : color,
                    "icon-opacity": 0
                }}
                layout={{
                    'text-field': ['case',
                        ['==', showLabel, true],
                        ['get', textfield],
                        ''
                    ],
                    "text-transform": 'uppercase',
                    "text-size": 10,
                    "text-letter-spacing": 0.1,
                    "text-variable-anchor": ['top'],
                    "text-font": ["Arial Unicode MS Regular"],
                }}
            />
            <Layer type='line' source='composite' source-layer={source}
                filter={[
                    '==',
                    'type',
                    type
                ]}
                paint={{
                    "line-width": 1.25,
                    "line-color": _.isFunction(color) ? color() : color,
                }}
            />
            <Layer type='fill' source='composite' source-layer={source}
                filter={[
                    '==',
                    'type',
                    type
                ]}
                paint={{
                    "fill-color": fillFunc,
                }}
            />
        </>
        : null

}

export function CountryBoundaries({ source, style, show = false }) {

    return show ?
        <Layer type='fill' source='composite' source-layer={source}
            paint={style}
        />
        : null

}

export function OverlayOption({ label, showLabelHeader = true, overlay }) {

    return (

        <Container className='borderraised'>

            <Grid columns={2}>

                <Grid.Row>
                    <Grid.Column><h4>{label}</h4></Grid.Column>
                    <Grid.Column>{showLabelHeader ? <h4>Label</h4> : null}</Grid.Column>
                </Grid.Row>

                {
                    _.map(overlay.options, option => {

                        return (
                            <Grid.Row key={option.name}>

                                <Grid.Column>
                                    <label className='checkboxContainer'>
                                        {`${option.label}`}
                                        <Field key={`${option.name}_overlay`} type='checkbox' name='overlays' value={option.name} />
                                        <span className='checkmark' />
                                    </label>
                                </Grid.Column>

                                <Grid.Column>
                                    {
                                        showLabelHeader ?
                                            <label className='checkboxContainer'>
                                                <Field key={`${option.name}_label`} type='checkbox' name='labels' value={option.name} />
                                                <span className='checkmark' />
                                            </label>
                                            : null
                                    }
                                </Grid.Column>

                            </Grid.Row>
                        )

                    })
                }

            </Grid>

        </Container>

    )

}

export function ColorPalette({ onChange, initialColor, colors = defaultColorSet, label = '' }) {

    const [showColorPicker, setShowColorPicker] = useState(false);
    const [color, setColor] = useState(initialColor);
    const [textColor, setTextColor] = useState('#000000');
    const [backgroundColor, setBackgroundColor] = useState(initialColor);

    useEffect(() => {

        if (!color) return;

        if (color.hsl.l > .60) setTextColor('#000000');
        else setTextColor('#ffffff');

        setBackgroundColor(color.hex);

    }, [color]);

    function onSetColor(color) {

        setColor(color);
        onChange(color);

    }

    return (
        <div onClick={() => setShowColorPicker(prevState => !prevState)} style={{ height: '100%' }}>
            <div style={{
                width: '100%',
                height: '100%',
                boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                display: 'inline-block',
                cursor: 'pointer',
            }} >
                <div style={{
                    padding: '2px 4px',
                    minHeight: '14px',
                    height: '100%',
                    fontSize: '11px',
                    fontWeight: 'bold',
                    color: textColor,
                    borderRadius: '1px',
                    // background: `hsl(${backgroundColor})`,
                    background: `${backgroundColor}`,
                    whiteSpace: 'nowrap',
                    textTransform: 'uppercase',
                    boxShadow: 'inset 2px 2px rgba(255,255,255,.5), inset -2px -2px rgba(0,0,0,.5)'
                }}>
                    {label}
                </div>
                {
                    showColorPicker ?
                        <div style={{
                            position: 'absolute',
                            zIndex: '2'
                        }} >
                            <div style={{
                                position: 'fixed',
                                top: '0px',
                                right: '0px',
                                bottom: '0px',
                                left: '0px'
                            }} />
                            <GithubPicker triangle='hide' width={210} colors={_.map(colors, color => { return color.hex })} color={color} onChangeComplete={(color) => onSetColor(color)} />
                        </div> : null
                }
            </div>
        </div>
    )

}

export function CustomizeColorOption({ name, label }) {

    const { values, setValues } = useFormikContext();

    return (
        <div>
            <ColorPalette label={label} initialColor={values[name].defaultColor} onChange={color => {

                setValues(prevState => {

                    prevState[name] = {
                        ...prevState[name],
                        defaultColor: color,
                    };

                    return prevState;

                })

            }} />
        </div>
    )

}
