import { Suspense } from 'react';
import { Link, Routes, Route } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Container, Menu, Segment, Visibility, Loader, Dropdown } from 'semantic-ui-react';
import { ReactQueryDevtools } from 'react-query/devtools';
// import SuperTokens, { getSuperTokensRoutesForReactRouterDom } from 'supertokens-auth-react';
// import EmailPassword, { EmailPasswordAuth, signOut, redirectToAuth } from 'supertokens-auth-react/recipe/emailpassword';
// import Session, { useSessionContext } from 'supertokens-auth-react/recipe/session';

import ControlPanel from './ControlPanel';
import Home from './Home';
import EDCTLookup from './EDCTLookup';
import { Manage } from './Manage';
import { TSDMap } from './TSD';

require('dotenv').config();

export const queryClient = new QueryClient();

const DesktopContainer = ({ children }) => {

    // const { doesSessionExist, jwtPayload } = useSessionContext();

    return (

        <>
        {/* <EmailPasswordAuth requireAuth={false}> */}

            <Visibility style={{ flex: '0 1 auto', zIndex: 999 }}>
                <Segment inverted textAlign='center' vertical>
                    <Menu inverted secondary>
                        <Container>

                            <Menu.Item>Flight Schedule Monitor</Menu.Item>
                            <Menu.Item as={Link} to='/'>Home</Menu.Item>
                            <Menu.Item as={Link} to='/edctlookup' content='EDCT Lookup' />
                            <Menu.Item as={Link} to='/cp' content='Control Panel' />
                            <Menu.Item as={Link} to='/tsd' content='TSD' />

                            {
                                // hasAccess(jwtPayload.access, ACCESS.MANAGE_AIRPORTS) ?
                                <Menu.Item as={Link} to='/manage' content='Manage' />
                                // :
                                // null
                            }
                            <Dropdown disabled item text='Help'>
                                <Dropdown.Menu>

                                    <Dropdown.Item content='FSM' />
                                    <Dropdown.Item content='About FSM' />

                                </Dropdown.Menu>
                            </Dropdown>
                            {
                                // doesSessionExist ?
                                // <Menu.Item onClick={() => signOut()} content='Log Out' />
                                // :
                                // <Menu.Item onClick={() => redirectToAuth('signin')} content='Log In' />
                            }

                        </Container>
                    </Menu>
                </Segment>
            </Visibility>

            {children}

        {/* </EmailPasswordAuth> */}
        </>
    )

}

const Footer = () => {

    return (
        <div className='footer'>
            Any and all content on this site are for use on the Virtual Air Traffic Simulation Network (VATSIM)<br />
            and may not be used for real-world navigation or aviation purposes.
        </div>
    )

}

const App = () => {

    return (
        <QueryClientProvider client={queryClient}>

            <DesktopContainer>

                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', flex: '1 1' }}>
                    <Suspense fallback={<Loader active />}>
                        <Routes>
                            {/* {getSuperTokensRoutesForReactRouterDom(require('react-router-dom'))} */}
                            <Route path='/' element={<Home />} />
                            <Route path='/edctlookup' element={<EDCTLookup />} />
                            <Route path='/cp' element={<ControlPanel />} />
                            <Route path='/tsd' element={<TSDMap />} />
                            <Route path='/manage' element={<Manage />} />
                        </Routes>
                    </Suspense>
                </div>
                <Footer />

            </DesktopContainer>

            <ReactQueryDevtools />

        </QueryClientProvider>
    )

}

export default App;
