import React from 'react';
import { Header } from 'semantic-ui-react';

const PageHeader = ({label}) => {

    // document.title=`${label} | FSM`;
    return <div style={{display: 'flex', flex: 1}}><Header as='h1'>{label}</Header></div>

}

export default PageHeader;