import React, { useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import _ from 'lodash';
import { Field } from 'formik';
import { Form, Button, Header, Modal, Grid, Tab, Container, Radio as SemanticRadio, Message } from 'semantic-ui-react';
import { Form as FormikForm, Radio, Select, SubmitButton, ResetButton } from 'formik-semantic-ui-react';
import { Formik, useFormikContext } from 'formik';

import { TimeSlider, DistanceSlider, NumberInput } from './Inputs';
import { FlightListModal } from './FlightList';
import { centerColors } from './config';
import { tiers } from './tiers';
import { useADL, useAllGDP, usePurgeGDP, useSubmitGDP } from './api/tfms';

function Parameters() {

    const { values } = useFormikContext();

    return (

        <Tab.Pane className='borderraised'>

            <Container className='lightbordered'>

                <Header as='h4' content='Program Time Options' />

                <Field name='start' label='Start' showLabels={false} component={TimeSlider} />
                <Field name='end' label='End' endTime={true} showLabels={true} component={TimeSlider} />

            </Container>

            <Container className='lightbordered'>

                <Header as='h4' content='Program Rate' />
                <Field disabled={values.type === 'gs'} inline className='parameterInput' name='paar' label='PR' component={NumberInput} />

            </Container>

            <Container className='lightbordered'>

                <Header as='h4' content='Delay Options' />
                <Form.Group inline>
                    <Field name='delayCeiling' label='Delay Limit (Minutes)' component={NumberInput} />
                </Form.Group>

            </Container>

        </Tab.Pane>

    )

}

function Scope({ airports, airport, exempt, nonexempt }) {

    const scopeOptions = [
        { key: 'tier', value: 'tier', text: 'Tier' },
        { key: 'distance', value: 'distance', text: 'Distance' }
    ];

    const centerOptions = _.map(centerColors, t => {

        if (t.color === 'OTHER') return null;

        return {
            key: t.dep_fir,
            value: t.dep_fir,
            text: t.name
        };

    });

    const { values, setFieldValue } = useFormikContext();

    const [tierOptions, setTierOptions] = useState([]);
    const [totalSelected, setTotalSelected] = useState(0);

    useEffect(() => {

        if (_.isNil(airport)) return;

        let a = _.find(airports, { icao: airport });
        if (_.isNil(a)) return;

        let arr = [];
        for (let t of tiers) {

            if ((t.facility && _.includes(a.artcc, t.facility)) || _.includes(['ALL', 'ALL+Canada', 'Manual'], t.code)) {

                let obj = {
                    key: t.code,
                    value: t.code,
                    text: t.code,
                    facilities: t.departureFacilitiesIncluded,
                };
                arr.push(obj);

            }

        }

        setTierOptions(arr);

    }, [airport, airports]);

    useEffect(() => {

        if (_.isNil(values?.tier) || _.isNil(tierOptions)) return;

        if (values.tier !== 'Manual') {

            let v = _.find(tierOptions, { value: values.tier });
            if (!_.isNil(v)) setFieldValue('artcc', v.facilities.split(' '));

        }

    }, [values?.tier, setFieldValue, tierOptions]);

    useEffect(() => {

        if (_.isNil(values?.artcc) || _.isNil(tierOptions)) return;

        let v = _.find(tierOptions, { facilities: values?.artcc.join(' ') });
        if (_.isNil(v)) setFieldValue('tier', 'Manual');

        setTotalSelected(values?.artcc.length);

    }, [values?.artcc, setFieldValue, tierOptions]);

    return (

        <Tab.Pane className='borderraised'>

            <Form style={{ paddingTop: '8px' }}>

                <Select name='selectBy' inline label="Select By" options={scopeOptions} />
                {
                    values?.selectBy === 'tier' ?
                        <>
                            <Select name='tier' inline label="Scope" options={tierOptions} />
                            <Header as='h4' className='topright' content={`Total Centers Selected: ${totalSelected}`} />
                            <Container className='lightbordered'>

                                <Header as='h4' content='Centers - Origin (Non-Exempt / Exempt)' />

                                <Grid>
                                    <Grid.Row columns={5}>
                                        {
                                            _.map(centerOptions, (c, i) => {

                                                if (i >= 20) return null;

                                                let thisCenterExempt = _.filter(exempt, { dep_fir: c.value });
                                                let thisCenterNonexempt = _.filter(nonexempt, { dep_fir: c.value });

                                                thisCenterExempt = thisCenterExempt.length > 0 ? thisCenterExempt.length : '-';
                                                thisCenterNonexempt = thisCenterNonexempt.length > 0 ? thisCenterNonexempt.length : '-';

                                                return <Grid.Column key={i}>
                                                    <label className='checkboxContainer'>
                                                        {`${c.value} (${thisCenterNonexempt}/${thisCenterExempt})`}
                                                        <Field key={c.value} type='checkbox' name='artcc'
                                                            value={c.value} disabled={values.selectBy !== 'tier'} />
                                                        <span className='checkmark' />
                                                    </label>
                                                </Grid.Column>

                                            })
                                        }
                                    </Grid.Row>

                                </Grid>
                            </Container>
                        </>
                        : values?.selectBy === 'distance' ?
                            <>
                                <Container className='lightbordered'>

                                    <Header as='h4' content='Distance (nautical miles)' />
                                    <Field name='distance' component={DistanceSlider} />

                                </Container>
                            </>
                            : null
                }
                <Container className='lightbordered'>

                    <Header as='h4' content='Flights' />
                    <Form.Group inline>
                        <Radio name='exemptBy' label='Exempt Active Flights Only (by status)' value='status' />
                    </Form.Group>
                    <Form.Group inline>
                        <Radio name='exemptBy' label='Exempt All Flights Departing Within' value='time' />
                        <Field name='timePlus' disabled={values.exemptBy !== 'time'} component={NumberInput} />
                    </Form.Group>

                </Container>

            </Form>

        </Tab.Pane>

    )

}

export function GDTSetup({ airports, airport, onClose, showModal }) {

    const { data: gdps } = useAllGDP();
    const { data: traffic } = useADL(airport);
    const { mutateAsync: sendProgram, isError } = useSubmitGDP();
    const { mutateAsync: purgeProgram } = usePurgeGDP();

    const [showFlightListModal, setShowFlightListModal] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0);

    const [exempt, setExempt] = useState(null);
    const [nonexempt, setNonexempt] = useState(null);
    const [gdpID, setGDPID] = useState(null);
    const [initialValues, setInitialValues] = useState(null);

    const programOptions = [
        { key: 'gdp', value: 'gdp', text: 'GDP - DAS' },
        { key: 'gs', value: 'gs', text: 'Ground Stop' },
        // { key: 'purge', value: 'purge', text: 'Purge' },
    ];

    useEffect(() => {

        if (_.isNil(traffic)) return;

        setExempt(_.filter(traffic.traffic, { status: 'active' }));
        setNonexempt(_.filter(traffic.traffic, f => _.includes(['departing', 'past_deptime'], f.status)));

    }, [traffic]);

    useEffect(() => {

        if (_.isNil(gdps) || _.isNil(airport)) return;

        let thisGDP = _.find(gdps, g => {
            return g.airport === airport
        });

        if (thisGDP) {

            setInitialValues({
                type: thisGDP.type,
                start: DateTime.fromJSDate(new Date(String(thisGDP.start)), { zone: 'utc' }).toFormat('ddHHmm'),
                end: DateTime.fromJSDate(new Date(String(thisGDP.end)), { zone: 'utc' }).toFormat('ddHHmm'),
                paar: thisGDP.paar,
                delayCeiling: thisGDP.delay_ceiling || 180,

                selectBy: thisGDP.scope.name,
                distance: thisGDP.scope.name === 'distance' ? thisGDP.scope.parameter : undefined,
                artcc: thisGDP.scope.name === 'tier' ? thisGDP.scope.parameter : undefined,
                exemptBy: thisGDP?.timePlus === null ? 'status' : 'time',
                timePlus: thisGDP?.timePlus !== null ? thisGDP.timePlus : undefined
            });

            setGDPID(thisGDP._id);

        } else {

            setInitialValues({
                start: DateTime.utc().toFormat('ddHHmm'),
                end: DateTime.utc().toFormat('ddHHmm'),
                delayCeiling: 180,
            });

        }

    }, [gdps, airport]);

    async function sendPurge() {

        let data = {
            _id: gdpID,
            airport: airport,
        };

        await purgeProgram(data);
        onClose();

    }

    async function sendRequest(values, actions) {

        let param;
        if (values.selectBy === 'tier') param = values.artcc;
        else if (values.selectBy === 'distance') param = values.distance;

        let data = {
            _id: gdpID,
            airport: airport,
            type: values.type,
            start: DateTime.fromFormat(values.start, 'ddHHmm', { zone: 'utc' }).toJSDate(),
            end: DateTime.fromFormat(values.end, 'ddHHmm', { zone: 'utc' }).toJSDate(),
            paar: Number(values.paar),
            delayCeiling: values.delayCeiling,
            scope: {
                name: values.selectBy,
                parameter: param,
                tier: values?.tier
            },
            timePlus: values.exemptBy === 'time' ? values.timePlus : null,
        };

        await sendProgram(data);
        actions.setSubmitting(false);

        if (!isError) onClose();

    }

    function filterFlights(flight) {
        return flight.edct !== undefined;
    }

    const panes = [
        {
            key: 'parameters',
            menuItem: <SemanticRadio as={Button} label='Parameters' className='hiddenradio tabmenu' />,
            pane: {
                key: 'parameters', content: <Parameters />
            }
        },
        {
            key: 'scope',
            menuItem: <SemanticRadio as={Button} label='Scope' className='hiddenradio tabmenu' />,
            pane: {
                key: 'scope', content: <Scope exempt={exempt} nonexempt={nonexempt} airports={airports} airport={airport} />
            }
        },
    ];

    return (
        <Modal closeOnDimmerClick={false} closeIcon={true} onClose={() => onClose()} open={showModal}>

            {
                !_.isNil(initialValues) ?
                    <Formik initialValues={initialValues} onSubmit={(values, actions) => { sendRequest(values, actions); }}>
                        {
                            props => (

                                <>
                                    <Modal.Header>GDT Setup: {airport}</Modal.Header>

                                    <Modal.Content style={{ minHeight: '450px' }}>

                                        <FlightListModal airport={airport} showModal={showFlightListModal} onClose={() => setShowFlightListModal(false)} filterFunc={filterFlights} />

                                        <Container>
                                            {isError ? <Message negative content='Error submitting. Try again.' /> : null}
                                            <FormikForm>
                                                <Select name='type' inline label='Program Type' options={programOptions} />
                                            </FormikForm>
                                        </Container>

                                        <Form.Group>
                                            <Tab activeIndex={activeIndex} onTabChange={(_, { activeIndex }) => setActiveIndex(activeIndex)} renderActiveOnly={false} panes={panes} />
                                        </Form.Group>

                                    </Modal.Content>

                                    <Modal.Actions>
                                        <SubmitButton content='Send Actual Program' loading={props.isSubmitting} onClick={() => !props.isSubmitting ? props.submitForm() : null} />
                                        <Button content='Purge' onClick={() => sendPurge()} />
                                        <Button content='Flight List' disabled={airport === null} onClick={() => setShowFlightListModal(true)} />
                                        <ResetButton content='Close' onClick={() => onClose()} />
                                    </Modal.Actions>
                                </>

                            )
                        }

                    </Formik>
                    : null
            }

        </Modal>
    )

}