import _ from 'lodash';
import React, { useEffect, useState, useRef } from 'react';
import { Table, Grid } from 'semantic-ui-react';
import { DateTime } from 'luxon';

import * as serviceWorker from './serviceWorker';
import { useAllGDP, useAAR, useFacilities, useADL } from './api/tfms';
import { useMETAR } from './api/weather';
import { useInterval } from './utils';

function calculateReason(weather) {

    let reasons = [];

    if (weather) {

        if (/TS.*RMK/gm.test(weather.raw_text)) reasons.push('TSTMS');
        if (Number(weather.wind_speed_kt) > 30 || Number(weather.wind_gust_kt) > 30) reasons.push('WIND');
        if (weather.flight_category === 'IFR' || weather.flight_category === 'LIFR') reasons.push('WX');

    }

    if (reasons.length === 0) reasons.push('VOL');

    return reasons.join('/');

}

function calculateDelay(data, airport, scheduled, actual, previousDelay, showTrend, type) {

    if (!data) return null;

    let delay;
    let increment;
    let filtered;

    if (type === 'program_delay') {

        filtered = _.filter(data.traffic, (traffic) => {

            return _.get(traffic, scheduled) && _.get(traffic, actual);

        });
        if (filtered.length < 2) return 0;

        delay = _.reduce(filtered, (acc, obj) => {

            let act = DateTime.fromJSDate(new Date(_.get(obj, actual)), { zone: 'utc' });
            let sch = DateTime.fromJSDate(new Date(_.get(obj, scheduled)), { zone: 'utc' });

            let diff = act.diff(sch, 'minutes').minutes;
            return diff + acc;

        }, 0);
        delay = delay / filtered.length;

        increment = delay - 12;
        increment = increment.toFixed(0);

    } else if (type === 'departure_delay') {

        filtered = _.filter(data.traffic, (traffic) => {

            return _.get(traffic, scheduled) && !_.get(traffic, actual) && _.get(traffic, 'dep') === airport;

        });
        if (filtered.length < 2) return 0;

        let now = DateTime.utc();

        delay = _.reduce(filtered, (acc, obj) => {

            let act = DateTime.fromJSDate(new Date(_.get(obj, actual)), { zone: 'utc' });

            let diff = now.diff(act.plus({ minutes: 12 }), 'minutes').minutes;
            return diff + acc;

        }, 0);
        delay = delay / filtered.length;

        if (delay < 15) return 0;
        increment = delay;

    } else if (type === 'arrival_delay') {

        filtered = _.filter(data.traffic, (traffic) => {

            return traffic.status === 'active' && _.get(traffic, scheduled) && _.get(traffic, actual) && _.get(traffic, 'dest') === airport;

        });
        if (filtered.length < 2) return 0;

        delay = _.reduce(filtered, (acc, obj) => {

            let sch = DateTime.fromJSDate(new Date(_.get(obj, scheduled)), { zone: 'utc' });
            let atd = DateTime.fromJSDate(new Date(_.get(obj, 'atd')), { zone: 'utc' });
            let act = DateTime.fromJSDate(new Date(_.get(obj, actual)), { zone: 'utc' });

            let elapsed = DateTime.utc().diff(atd, 'minutes').minutes;
            let estimated = sch.diff(act, 'minutes').minutes;

            let diff = elapsed + estimated;

            return diff + acc;

        }, 0);
        delay = delay / filtered.length;

        if (delay < 15) return 0;
        increment = delay;

    }

    if (showTrend) {

        increment = parseInt(increment / 15);
        let prev = parseInt(Math.abs(previousDelay / 15));

        if (increment === 0 && prev === 1) return `-15`;
        else if (increment > prev) return `+${increment * 15}`;
        else if (increment < prev) return `-${increment * 15}`;
        else return Number(increment * 15);

    } else {
        return Number(increment);
    }

}

function NationalProgram({ program }) {

    const { refetch, data: metar } = useMETAR(program.airport);
    const { data: aar } = useAAR(program.airport);
    const { data } = useADL(program.airport);

    const [reason, setReason] = useState('');
    const [avg, setAvg] = useState(0);
    const avgRef = useRef(0);

    useEffect(() => {
        refetch();
    }, [program.airport, refetch]);

    useEffect(() => {

        setReason(calculateReason(metar));

    }, [metar]);

    useEffect(() => {

        let oldAvg = avgRef.current;
        let newAvg = calculateDelay(data, program.airport, 'igtd', 'edct.time', oldAvg, false, 'program_delay');

        avgRef.current = newAvg;
        setAvg(newAvg);

    }, [data, program.airport]);

    return (<Table.Row>
        <Table.Cell>{program.airport.substring(1)}</Table.Cell>
        <Table.Cell>{DateTime.fromJSDate(new Date(program.start), { zone: 'utc' }).toFormat('HHmm')}</Table.Cell>
        <Table.Cell>{DateTime.fromJSDate(new Date(program.end), { zone: 'utc' }).toFormat('HHmm')}</Table.Cell>
        <Table.Cell>{program.scope.name === 'distance' ? `${program.scope.parameter} NM` : program.scope.tier === 'Manual' ? `See Control Element...` : program.scope.tier}</Table.Cell>
        <Table.Cell>{reason}</Table.Cell>
        <Table.Cell>{avg}</Table.Cell>
        <Table.Cell>{aar?.aar ? aar?.aar : null}</Table.Cell>
        <Table.Cell>{aar?.paar ? aar?.paar : null}</Table.Cell>
        {/* <Table.Cell>ADVZY</Table.Cell> */}
        {/* <Table.Cell>DA</Table.Cell> */}
    </Table.Row>)

}
function NationalPrograms({ programs }) {

    return (

        <Table unstackable celled className='ois'>

            <Table.Header>

                <Table.Row className='oisHeader'>
                    <Table.HeaderCell colSpan='10' textAlign='center'>NATIONAL PROGRAMS</Table.HeaderCell>
                </Table.Row>

                <Table.Row className='oisSubHeader'>
                    <Table.HeaderCell>PROGRAM NAME</Table.HeaderCell>
                    <Table.HeaderCell>START</Table.HeaderCell>
                    <Table.HeaderCell>END</Table.HeaderCell>
                    <Table.HeaderCell>SCOPE</Table.HeaderCell>
                    <Table.HeaderCell>REASON</Table.HeaderCell>
                    <Table.HeaderCell>AVG</Table.HeaderCell>
                    <Table.HeaderCell>AAR</Table.HeaderCell>
                    <Table.HeaderCell>PR</Table.HeaderCell>
                    {/* <Table.HeaderCell>ADVZY</Table.HeaderCell> */}
                    {/* <Table.HeaderCell>DA</Table.HeaderCell> */}
                </Table.Row>

            </Table.Header>

            <Table.Body>
                {
                    _.map(programs, (program) => {
                        return <NationalProgram key={program._id} program={program} />
                    })
                }
            </Table.Body>

        </Table>

    )

}

function GroundStop({ program }) {

    const { refetch, data: metar } = useMETAR(program.airport);

    const [reason, setReason] = useState('');

    useEffect(() => {
        refetch();
    }, [program.airport, refetch]);

    useEffect(() => {

        if (!metar) return;
        setReason(calculateReason(metar));

    }, [metar]);

    return (
        <Table.Row>
            <Table.Cell>{program.airport}</Table.Cell>
            <Table.Cell>{DateTime.fromJSDate(new Date(program.end), { zone: 'utc' }).toFormat('HHmm')}</Table.Cell>
            <Table.Cell>POE</Table.Cell>
            <Table.Cell>{program.scope.name === 'distance' ? `${program.scope.parameter} NM` : program.scope.tier}</Table.Cell>
            <Table.Cell>{reason}</Table.Cell>
            {/* <Table.Cell>ADVZY</Table.Cell> */}
        </Table.Row>
    )
}
function GroundStops({ programs }) {

    return (

        <Table unstackable celled className='ois'>

            <Table.Header>

                <Table.Row className='oisHeader'>
                    <Table.HeaderCell colSpan='6' textAlign='center'>GROUND STOPS</Table.HeaderCell>
                </Table.Row>

                <Table.Row className='oisSubHeader'>
                    <Table.HeaderCell>ARPT</Table.HeaderCell>
                    <Table.HeaderCell>UPDATE</Table.HeaderCell>
                    <Table.HeaderCell>POE</Table.HeaderCell>
                    <Table.HeaderCell>SCOPE</Table.HeaderCell>
                    <Table.HeaderCell>REASON</Table.HeaderCell>
                    {/* <Table.HeaderCell>ADVZY</Table.HeaderCell> */}
                </Table.Row>

            </Table.Header>

            <Table.Body>
                {
                    _.map(programs, (program) => {
                        return <GroundStop key={program._id} program={program} />
                    })
                }
            </Table.Body>

        </Table>

    )

}

function Delay({ airport }) {

    const { data } = useADL(airport.icao);
    const { refetch, data: metar } = useMETAR(airport.icao);

    const [currentTime, setCurrentTime] = useState(DateTime.utc());

    const arrivalDelayRef = useRef(0);
    const departureDelayRef = useRef(0);
    const [arrivalDelay, setArrivalDelay] = useState(0);
    const [departureDelay, setDepartureDelay] = useState(0);
    const [reason, setReason] = useState('VOL');

    useEffect(() => {

        if (!_.isArray(data?.traffic)) return;

        refetch();
        setReason(calculateReason(metar));

    }, [airport, refetch, metar, data?.traffic]);

    useInterval(() => {

        if (!data) return;

        let newAvg = calculateDelay(data, airport.icao, 'atd', 'oeta', arrivalDelayRef.current, true, 'arrival_delay');
        arrivalDelayRef.current = newAvg;
        setArrivalDelay(newAvg);

        newAvg = calculateDelay(data, airport.icao, 'out', 'atd', departureDelayRef.current, true, 'departure_delay');
        departureDelayRef.current = newAvg;
        setDepartureDelay(newAvg);

        setCurrentTime(DateTime.utc());

    }, 5 * 60 * 1000);

    return (

        <>
            {(arrivalDelay < 1 && departureDelay < 1) ? null :
                <Table.Row>
                    <Table.Cell>{airport.iata}</Table.Cell>
                    <Table.Cell>{arrivalDelay}</Table.Cell>
                    <Table.Cell>{departureDelay}</Table.Cell>
                    <Table.Cell>{currentTime.toFormat('HHmm')}</Table.Cell>
                    <Table.Cell>{reason}</Table.Cell>
                </Table.Row>
            }
        </>

    )

}
function DelayInfo({ airports }) {

    return (

        <Table unstackable celled className='ois'>

            <Table.Header>

                <Table.Row className='oisHeader'>
                    <Table.HeaderCell colSpan='5' textAlign='center'>DELAY INFO</Table.HeaderCell>
                </Table.Row>

                <Table.Row className='oisSubHeader'>
                    <Table.HeaderCell>ARPT</Table.HeaderCell>
                    <Table.HeaderCell>AD</Table.HeaderCell>
                    <Table.HeaderCell>DD</Table.HeaderCell>
                    <Table.HeaderCell>TIME</Table.HeaderCell>
                    <Table.HeaderCell>REASON</Table.HeaderCell>
                </Table.Row>

            </Table.Header>

            <Table.Body>
                {
                    _.map(airports, (airport) => {
                        return <Delay key={airport.icao} airport={airport} />
                    })
                }
            </Table.Body>

        </Table>

    )

}

function Miscellaneous({ messages = [] }) {

    return (

        <Table unstackable celled className='ois'>

            <Table.Header>
                <Table.Row className='oisHeader'>
                    <Table.HeaderCell textAlign='center'>MISCELLANEOUS</Table.HeaderCell>
                </Table.Row>
            </Table.Header>

            <Table.Body>
                {
                    _.map(messages, (message) => {
                        return <Table.Row key={message}>
                            <Table.Cell>{message}</Table.Cell>
                        </Table.Row>
                    })
                }
            </Table.Body>

        </Table>

    )

}

export default function Home() {

    const { data: gdps, refetch } = useAllGDP();
    const { data: airports } = useFacilities();

    // const t = DateTime.utc().plus({ hours: 3 });

    const [np, setNP] = useState([]);
    const [gs, setGS] = useState([]);
    // const [messages, setMessages] = useState([`NEXT PLANNING WEBINAR ${t.toFormat('HHmm')}Z`]);
    const [messages, setMessages] = useState([]);

    const [newVersionAvailable, setNewVersionAvailable] = useState(false);
    const [waitingWorker, setWaitingWorker] = useState({});

    useEffect(() => {

        const onServiceWorkerUpdate = (registration) => {

            setNewVersionAvailable(true);
            setWaitingWorker(registration && registration.waiting);

        }

        if (process.env.NODE_ENV === 'production') serviceWorker.register({ onUpdate: onServiceWorkerUpdate });

        if (newVersionAvailable) {

            const { waiting } = waitingWorker;

            if (waiting) waiting.postMessage({ type: 'SKIP_WAITING' });

            setMessages([...messages, 'New version available. Reload to update.']);
            setNewVersionAvailable(false);

        }

    }, [newVersionAvailable, waitingWorker, messages]);

    useEffect(() => {

        setNP(_.filter(gdps, { type: 'gdp' }));
        setGS(_.filter(gdps, { type: 'gs' }));

    }, [gdps]);

    useInterval(() => { refetch() }, 1 * 60 * 1000);

    return (

        <div className='home'>

            <Grid>

                <Grid.Row>
                    <Grid.Column textAlign='center'>
                        <h2 style={{ fontSize: '2em' }}>VATSIM NATIONAL AIRSPACE SYSTEM STATUS</h2>
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                    <Grid.Column>
                        <NationalPrograms programs={np} />
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                    <Grid.Column>
                        <GroundStops programs={gs} />
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                    <Grid.Column>
                        <DelayInfo airports={airports} />
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                    <Grid.Column>
                        <Miscellaneous messages={messages} />
                    </Grid.Column>
                </Grid.Row>

            </Grid>

        </div>

    )

}