import { useState, useEffect, useMemo, useCallback, useContext } from 'react';
import { Modal, Container, Table, Button, Segment, Visibility, Menu, Dropdown, Grid, Form as SUIRForm, Header, Icon, TextArea } from 'semantic-ui-react';
import { Layer, Marker, Popup, Source, MapContext } from 'react-map-gl';
import { LngLat } from 'mapbox-gl';
import { Formik, useFormikContext, FieldArray, Form } from 'formik';
import { Input, Checkbox } from 'formik-semantic-ui-react';
import { featureCollection, lineString } from '@turf/helpers';
import _ from 'lodash';

import { ColorPalette } from './TSDComponents';
import { useAirborneADL, useATC } from './api/tfms';
import { defaultColorSet } from './config';
import prop from './prop.svg';
import prop_ghost from './prop_ghost.svg';
import jet from './jet.svg';
import jet_ghost from './jet_ghost.svg';
import heavy from './heavy.svg';
import heavy_ghost from './heavy_ghost.svg';
import { v4 } from 'uuid';
import { ReactSortable } from 'react-sortablejs';

function wrapAroundMeridians(segments) {

    let expanded_route = segments;

    for (let x = 0; x <= segments.length - 1; x++) {

        let coords = [];

        let curr = segments[x];
        let prev = segments[x - 1];

        if (x === 0) coords.push(curr.geometry.coordinates[0], curr.geometry.coordinates[1]);
        else {

            if ((prev.geometry.coordinates[0] < 0 && curr.geometry.coordinates[0] >= 0)
                && (curr.geometry.coordinates[0] - prev.geometry.coordinates[0]) > 180) coords.push(curr.geometry.coordinates[0] - 360);

            else if ((prev.geometry.coordinates[0] >= 0 && curr.geometry.coordinates[0] < 0)
                && (prev.geometry.coordinates[0] - curr.geometry.coordinates[0] > 180)) coords.push(curr.geometry.coordinates[0] + 360);

            else coords.push(curr.geometry.coordinates[0]);

            coords.push(curr.geometry.coordinates[1]);

        }

        segments[x].geometry.coordinates = coords;

    }

    expanded_route = _.map(expanded_route, segment => {
        return segment.geometry.coordinates;
    });

    return expanded_route;

}

function getFlightsInFlightSet(flights, flightset) {

    if (_.isObjectLike(flightset?.filter)) {

        return {
            flightsetFlights: _.remove(flights, flight => {

                let departure = flightset?.departure !== "" ? _.includes(_.split(flightset.departure, ' '), flight.dep.toLowerCase()) : true;
                let arrival = flightset?.arrival !== "" ? _.includes(_.split(flightset.arrival, ' '), flight.dest.toLowerCase()) : true;

                let cid = flightset.filter?.cid !== "" ? _.some(_.split(_.trim(flightset.filter.cid), ' '), cid => {
                    return new RegExp(wildcardToRegex(String(cid)), 'i').test(flight.cid);
                }) : true;
                let callsign = flightset.filter?.callsign !== "" ? _.some(_.split(_.trim(flightset.filter.callsign), ' '), callsign => {
                    return new RegExp(wildcardToRegex(String(callsign)), 'i').test(flight.callsign);
                }) : true;
                let beacon = flightset.filter?.beacon !== "" ? _.some(_.split(_.trim(flightset.filter.beacon), ' '), beacon => {
                    return new RegExp(wildcardToRegex(String(beacon)), 'i').test(flight.transponder);
                }) : true;
                let actype = flightset.filter?.actype !== "" ? _.some(_.split(_.trim(flightset.filter.actype), ' '), sector => {
                    return new RegExp(wildcardToRegex(String(sector)), 'i').test(flight.actype);
                }) : true;

                // let fixes = flightset.filter?.fixes !== "" ? _.some(_.split(flightset.filter.fixes, ' '), fix => flight.expanded_route.properties.label === fix) : true;
                // let sector = flightset.filter?.sector !== "" ? _.some(_.split(flightset.filter.sector, ' '), sector => flight.sector_crossings.properties.name === sector) : true;
                // let artcc = flightset.filter?.artcc !== "" ? _.some(_.split(flightset.filter.artcc, ' '), artcc => flight.artcc_crossings.properties.artcc === artcc) : true;
                let highestFL = !_.isNil(flightset?.filter?.highestFL) && flightset.filter?.highestFL !== "" ? flightlevelFilter(flightset.filter.highestFL, 'highest', flight) : true;
                let lowestFL = !_.isNil(flightset.filter?.lowestFL) && flightset.filter?.lowestFL !== "" ? flightlevelFilter(flightset.filter.lowestFL, 'lowest', flight) : true;

                return departure && arrival && cid && callsign && beacon && actype && lowestFL && highestFL
                //  && fixes && sector && artcc 

            }),
            allFlights: flights
        }

    } else {

        return {
            flightsetFlights: _.remove(flights, flight => {
                return _.some(flightset, f => {

                    // console.log(f);
                    return f?.toUpperCase() === flight.callsign;

                }) || _.some(flightset, f => Number(f) === flight.transponder)
            }
            ),
            allFlights: flights,
        };
    }

}

function flightlevelFilter(string, type, flight) {

    if (type === 'lowest') {

        if (new RegExp(/[Bb]\d{3}/).test(string)) {
            return flight.altitude >= Number(string.substr(1)) * 100 && flight.planned_altitude >= Number(string.substr(1)) * 100;
        } else if (new RegExp(/[Ff]\d{3}/).test(string)) {
            return flight.planned_altitude >= Number(string.substr(1)) * 100;
        } else if (new RegExp(/[Rr]\d{3}/).test(string)) {
            return flight.altitude >= Number(string.substr(1)) * 100;
        } else return false;

    } else if (type === 'highest') {

        if (new RegExp(/[Bb]\d{3}/).test(string)) {
            return flight.altitude <= Number(string.substr(1)) * 100 && flight.planned_altitude <= Number(string.substr(1)) * 100;
        } else if (new RegExp(/[Ff]\d{3}/).test(string)) {
            return Number(flight.planned_altitude) <= Number(string.substr(1)) * 100;
        } else if (new RegExp(/[Rr]\d{3}/).test(string)) {
            return flight.altitude <= Number(string.substr(1)) * 100;
        } else return false;

    } else return false;

}

//find any occurence of * and convert to regex wildcard
function wildcardToRegex(str) {

    if (new RegExp(/[*]/).test(str)) {
        return str.replace(/[*]/g, '.*');
    } else if (new RegExp(/[?]/).test(str)) {
        return str.replace(/[?]/g, '.');
    } else return str;

}

const HistoryTrail = ({ flight, color }) => {

    const [line, setLine] = useState(null);
    useEffect(() => {

        try {

            setLine(_.isArray(flight.historyTrail) && flight.historyTrail.length > 1 ? lineString(wrapAroundMeridians(flight.historyTrail)) : null);

            return () => {
                return null;
            }

        } catch (err) {
            return;
        }

    }, [flight.historyTrail]);

    return (
        !_.isNull(line) ?
            <>
                <Source data={line} type='geojson'>
                    <Layer type='line' paint={{
                        "line-color": color,
                        "line-width": 1,
                        "line-dasharray": [2, 4],
                    }}
                        layout={{
                            "line-join": 'round'
                        }}
                    />
                </Source>
            </>
            : null
    )

}

const FlightPath = ({ flight, color }) => {

    const [path, setPath] = useState(null);
    const [expanded, setExpanded] = useState(null);

    useEffect(() => {

        try {

            setPath(lineString(wrapAroundMeridians(flight.expanded_route)));
            setExpanded(featureCollection(flight.expanded_route));

        } catch (err) {
            return;
        }

        return () => {
            return null;
        }

    }, [flight.expanded_route]);



    return (
        !_.isNil(path) && !_.isNil(expanded) ?
            <>
                <Source data={expanded} type='geojson'>
                    <Layer type='symbol'
                        layout={{
                            "text-field": ['get', 'name'],
                            "text-transform": 'uppercase',
                            "text-size": 10,
                            "text-offset": [1, -1]
                        }}
                        paint={{
                            "text-color": color
                        }} />
                    <Layer type='circle' paint={{
                        "circle-color": color,
                        "circle-radius": 3
                    }} />
                </Source>
                <Source data={path} type='geojson'>
                    <Layer type='line' paint={{
                        "line-color": color,
                        "line-width": 1
                    }} />
                </Source>
            </>
            : null
    )

}

const Datatag = ({ flight, color }) => {

    return (

        <Popup className='datatag' anchor='top' offsetTop={-85} dynamicPosition={false} sortByDepth={true} tipSize={0} closeButton={false} latitude={flight.position.geometry.coordinates[1]} longitude={flight.position.geometry.coordinates[0]}>
            <div>
                <div style={{ color: color }}>{`${flight.callsign}`}</div>
                <div style={{ color: color }}>{`${Number(flight.altitude / 100).toFixed(0).padStart(3, '0')} ${flight.aircraft}`}</div>
                <div style={{ color: color }}>{`${Number(flight.ete).toFixed(0)}/${flight.groundspeed} ${flight.transponder}`}</div>
                <div style={{ color: color }}>{`${flight.dep} ${flight.dest}`}</div>
            </div>
        </Popup>

    )

};

const FlightMarker = ({ flightset, flight, heading }) => {

    const initialColor = flightset.color.hex;

    const [color, setColor] = useState(initialColor);
    const [showDatatag, setShowDatatag] = useState();
    const [permDatatag, setPermDatatag] = useState(flightset.showDatablock);
    const [showFlightPath, setShowFlightPath] = useState(false);
    const [showHistoryTrail, setShowHistoryTrail] = useState(false);

    let plane_icon = !_.isNil(flight.disconnected_at) ?
        {
            'jet': jet_ghost,
            'heavy': heavy_ghost,
            'prop': prop_ghost
        }[flight.aircraft_class]
        :
        {
            'jet': jet,
            'heavy': heavy,
            'prop': prop
        }[flight.aircraft_class];

    function onMouseEnter() {

        setColor('#ffffff');
        setShowDatatag(true);

    }

    function onMouseLeave() {

        setColor(initialColor);
        if (!flightset.showDatablock) setShowDatatag(false);

    }

    return (
        <>
            {
                !_.isNil(flight.position) ?
                    <>
                        {
                            showFlightPath || flightset.showRoute ? <FlightPath flight={flight} color={flightset.color.hex} /> : null
                        }
                        {
                            flightset.showDatablock || showDatatag || permDatatag ? <Datatag flight={flight} color={color} /> : null
                        }
                        {
                            showHistoryTrail ?
                                <>
                                    <HistoryTrail flight={flight} color={color} />
                                </>
                                : null
                        }

                        <Marker id='flight' offsetLeft={-7.5} offsetTop={-7.5} latitude={flight.position.geometry.coordinates[1]} longitude={flight.position.geometry.coordinates[0]}>
                            <img alt='' src={plane_icon}
                                style={{
                                    transform: `
                                rotate(${heading}deg)
                            `,
                                }}
                                className={`flight plane-${flightset.color.name}`}
                                onMouseLeave={() => onMouseLeave()}
                                onMouseOver={() => onMouseEnter()}
                                onDoubleClick={() => setShowHistoryTrail(prevState => !prevState)}
                                onClick={() => setPermDatatag(prevState => !prevState)}
                                onContextMenu={() => setShowFlightPath(prevState => !prevState)}
                            />
                        </Marker>
                    </>
                    : null
            }
        </>
    )

};

const NoRadarSymbol = () => {

    return <div style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    }}>
        <Icon.Group size='huge'>
            <Icon size='big' color='grey' name='dont' />
            <Icon size='large' color='grey' name='plane' />
        </Icon.Group>
    </div>

}

export function Flights({ show, sets, flights }) {

    const { data: aad } = useAirborneADL();
    const { data: atc } = useATC();

    let setsArr = useMemo(() => {

        let arr = [];
        if (_.isArray(sets) && sets.length > 0) arr = sets;
        return arr;

    }, [sets]);

    return useMemo(() => {

        if (_.isArray(aad)) {

            let flightsets = [];
            let allFlights = [...aad];

            if (_.isObjectLike(flights) && !_.isNull(flights?.color) && !_.isEmpty(flights?.filter)) {

                let f = getFlightsInFlightSet(allFlights, flights.filter);
                allFlights = f.allFlights;

                flightsets.push({
                    color: _.find(defaultColorSet, { hex: flights.color.hex }),
                    showFlights: true,
                    showDatablock: true,
                    showRoute: false,
                    flights: f.flightsetFlights
                });
            }

            if (_.isArray(setsArr)) {

                _.forEach(setsArr, flightset => {

                    let f = getFlightsInFlightSet(allFlights, flightset);
                    allFlights = f.allFlights;

                    flightsets.push({
                        color: _.find(defaultColorSet, { hex: flightset.color.hex }),
                        showFlights: flightset.showFlights,
                        showDatablock: flightset.showDatablock,
                        showRoute: flightset.showRoute,
                        flights: f.flightsetFlights
                    });

                });

            }

            return show ?

                _.isNil(aad) || _.isNil(atc) ?
                    <NoRadarSymbol />
                    :
                    _.map(flightsets, flightset => {
                        return (
                            flightset.showFlights ?
                                _.map(flightset.flights, marker => {
                                    return <FlightMarker key={marker._id} flightset={flightset} flight={marker} heading={marker.heading} />
                                })
                                : null
                        )
                    })
                : null

        } else return <NoRadarSymbol />

    }, [aad, atc, setsArr, flights, show]);

}

function SelectFlightNavbar({ helpers, selectedRow }) {

    const { values } = useFormikContext();

    const newFlightset = {
        id: v4(),
        showFlights: true,
        color: {
            hex: '#000000',
            hsl: { h: 0, s: 0, l: 0 },
            // hsv: { h: 0, s: 0, v: 0 },
            rgb: { r: 0, g: 0, b: 0 }
        },
        arrival: '',
        departure: '',
        showDatablock: false,
        showRoute: false,
        filter: {
            cid: '',
            callsign: '',
            beacon: '',
            actype: '',
        }
    };

    return (

        <Visibility>
            <Segment textAlign='left' horizontal='true' style={{ borderColor: '#666666', borderWidth: '0 0 1px 0', zIndex: 498 }}>
                <Menu compact>

                    <Dropdown item text='Flight Set' style={{ zIndex: '500' }}>
                        <Dropdown.Menu>
                            <Dropdown.Item content='Add' onClick={() => helpers.insert(values.flightsets.length, newFlightset)} />
                            {/* <Dropdown.Item content='Copy' onClick={() => helpers.copy(selectedRow)} /> */}
                            <Dropdown.Item content='Delete' onClick={() => helpers.delete(selectedRow)} />
                            <Dropdown.Item content='Delete All' onClick={() => helpers.deleteAll()} />
                            <Dropdown.Item content='Top' onClick={() => helpers.top(selectedRow)} />
                        </Dropdown.Menu>
                    </Dropdown>

                </Menu>
            </Segment>
        </Visibility>

    )

}

const FlightSetFilterModal = ({ flightset, onSubmit }) => {

    const [showModal, setShowModal] = useState(false);

    const [cid, setCID] = useState(flightset.filter.cid);
    const [callsign, setCallsign] = useState(flightset.filter.callsign);
    const [fixes, setFixes] = useState(flightset.filter.fixes);
    const [sectors, setSectors] = useState(flightset.filter.sectors);
    const [artcc, setArtcc] = useState(flightset.filter.artcc);
    const [beacon, setBeacon] = useState(flightset.filter.beacon);
    const [actype, setACType] = useState(flightset.filter.actype);
    const [lowestFL, setLowestFL] = useState(flightset.filter.lowestFL);
    const [highestFL, setHighestFL] = useState(flightset.filter.highestFL);

    function submit() {

        let filter = {
            cid: '',
            callsign: '',
            fixes: '',
            sectors: '',
            artcc: '',
            beacon: '',
            actype: '',
            lowestFL: '',
            highestFL: '',
        };

        if (!_.isEmpty(cid)) filter.cid = cid;
        if (!_.isEmpty(callsign)) filter.callsign = callsign;
        if (!_.isEmpty(fixes)) filter.fixes = fixes;
        if (!_.isEmpty(sectors)) filter.sectors = sectors;
        if (!_.isEmpty(artcc)) filter.artcc = artcc;
        if (!_.isEmpty(beacon)) filter.beacon = beacon;
        if (!_.isEmpty(actype)) filter.actype = actype;
        if (!_.isEmpty(lowestFL)) filter.lowestFL = lowestFL;
        if (!_.isEmpty(highestFL)) filter.highestFL = highestFL;

        onSubmit(filter);

    }

    return (

        <Modal
            size='tiny'
            open={showModal}
            onClose={() => setShowModal(false)}
            trigger={
                <Checkbox
                    onClick={() => setShowModal(true)}
                    checked={
                        !_.isEmpty(flightset.filter.cid) || !_.isEmpty(flightset.filter.acid) || !_.isEmpty(flightset.filter.fixes)
                        || !_.isEmpty(flightset.filter.sectors) || !_.isEmpty(flightset.filter.artcc)
                        || !_.isEmpty(flightset.filter.callsign) || !_.isEmpty(flightset.filter.beacon) || !_.isEmpty(flightset.filter.actype)
                        || !_.isEmpty(flightset.filter.lowestFL) || !_.isEmpty(flightset.filter.highestFL)
                    }
                />
            }
        >

            <Modal.Header>Flight Set Filter </Modal.Header>

            <Modal.Content>

                <Grid columns='two'>

                    <Grid.Row columns={2}>
                        <Grid.Column>
                            CID(s):
                        </Grid.Column>
                        <Grid.Column>
                            <SUIRForm.Input value={cid} onChange={(_, { value }) => setCID(value)} />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={2}>
                        <Grid.Column>
                            ACID(s):
                        </Grid.Column>
                        <Grid.Column>
                            <SUIRForm.Input value={callsign} onChange={(_, { value }) => setCallsign(value)} />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={2}>
                        <Grid.Column>
                            Fix(es) enroute:
                        </Grid.Column>
                        <Grid.Column>
                            <SUIRForm.Input value={fixes} onChange={(_, { value }) => setFixes(value)} />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={2}>
                        <Grid.Column>
                            Sector(s) traversed:
                        </Grid.Column>
                        <Grid.Column>
                            <SUIRForm.Input disabled value={sectors} onChange={(_, { value }) => setSectors(value)} />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={2}>
                        <Grid.Column>
                            ARTCC(s) traversed:
                        </Grid.Column>
                        <Grid.Column>
                            <SUIRForm.Input disabled value={artcc} onChange={(_, { value }) => setArtcc(value)} />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={2}>
                        <Grid.Column>
                            Beacon code(s):
                        </Grid.Column>
                        <Grid.Column>
                            <SUIRForm.Input value={beacon} onChange={(_, { value }) => setBeacon(value)} />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={2}>
                        <Grid.Column>
                            Aircraft type(s):
                        </Grid.Column>
                        <Grid.Column>
                            <SUIRForm.Input value={actype} onChange={(_, { value }) => setACType(value)} />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={2}>
                        <Grid.Column>
                            Lowest flight level:
                        </Grid.Column>
                        <Grid.Column>
                            <SUIRForm.Input maxLength={4} value={lowestFL} onChange={(_, { value }) => setLowestFL(value)} />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={2}>
                        <Grid.Column>
                            Highest flight level:
                        </Grid.Column>
                        <Grid.Column>
                            <SUIRForm.Input maxLength={4} value={highestFL} onChange={(_, { value }) => setHighestFL(value)} />
                        </Grid.Column>
                    </Grid.Row>

                </Grid>

            </Modal.Content>

            <Modal.Actions>
                <Button content='OK'
                    onClick={() => {
                        submit();
                        setShowModal(false);
                    }}
                />
                <Button content='Cancel'
                    onClick={() => {
                        setShowModal(false);
                    }}
                />
            </Modal.Actions>

        </Modal>

    )

}

const FlightSets = ({ selectedRow, setSelectedRow, helpers, form, arrayHelpers }) => {

    useEffect(() => {
        helpers({

            insert: (index, value) => {
                arrayHelpers.insert(index, value);
            },

            delete: (index) => {
                arrayHelpers.remove(index);
            },

            deleteAll: () => {
                form.setFieldValue('flightsets', []);
            },

            top: (index) => {
                arrayHelpers.move(index, 0);
            }

        });
    }, [helpers, form, arrayHelpers]);

    return (

        form.values.flightsets && form.values.flightsets.length > 0 ? (
            <ReactSortable tag='tbody' list={form.values.flightsets} setList={(newState) => form.setFieldValue('flightsets', newState)}>
                {_.map(form.values.flightsets, (flightset, index) => {

                    return (
                        <Table.Row key={flightset.id}>

                            <Table.Cell active={selectedRow === index} selectable style={{ cursor: 'pointer' }} onClick={() => setSelectedRow(index)}><h4>{index + 1}</h4></Table.Cell>
                            <Table.Cell>
                                <Checkbox name={`flightsets.${index}.showFlights`} checked={flightset.showFlights} />
                            </Table.Cell>
                            <Table.Cell>
                                <ColorPalette initialColor={flightset.color} onChange={(color) => form.setFieldValue(`flightsets.${index}.color`, color)} />
                            </Table.Cell>
                            <Table.Cell>
                                <Input name={`flightsets.${index}.arrival`} />
                            </Table.Cell>
                            <Table.Cell>
                                <Input name={`flightsets.${index}.departure`} />
                            </Table.Cell>
                            <Table.Cell>
                                <Checkbox name={`flightsets.${index}.showDatablock`} checked={flightset.showDatablock} />
                            </Table.Cell>
                            <Table.Cell>
                                <Checkbox name={`flightsets.${index}.showRoute`} checked={flightset.showRoute} />
                            </Table.Cell>
                            <Table.Cell>
                                <FlightSetFilterModal flightset={flightset} onSubmit={(value) => form.setFieldValue(`flightsets.${index}.filter`, value)} />
                            </Table.Cell>

                        </Table.Row>
                    )

                })}
            </ReactSortable>
        )
            : null

    )

};

export function SelectFlightsModal({ flightsets, onClose, onSubmit, showModal }) {

    const [selectedRowIndex, setSelectedRowIndex] = useState(null);
    const [ref1, setRef1] = useState(null);

    const onRefChange = useCallback(node => {
        if (_.isNil(ref1)) setRef1(node);
    }, [ref1]);

    return (
        <Formik
            enableReinitialize={true}
            initialValues={{ flightsets: flightsets }}
            onSubmit={values => {
                onSubmit(values.flightsets);
                onClose();
            }}
        >
            {({ resetForm, submitForm }) => (

                <Modal size='small'
                    open={showModal}
                    onClose={() => {
                        resetForm();
                        onClose();
                    }}
                >

                    <Modal.Header>Select Flights</Modal.Header>

                    <Form>
                        <Modal.Content>
                            <SelectFlightNavbar helpers={ref1} selectedRow={selectedRowIndex} />
                            <Container className='borderraised'>

                                <div>
                                    <Table unstackable celled compact textAlign='center' className='greyTable'>

                                        <Table.Header>
                                            <Table.Row>
                                                <Table.HeaderCell width={1} />
                                                <Table.HeaderCell><h4>Show</h4></Table.HeaderCell>
                                                <Table.HeaderCell><h4>Color</h4></Table.HeaderCell>
                                                <Table.HeaderCell><h4>Arrival</h4></Table.HeaderCell>
                                                <Table.HeaderCell><h4>Departure</h4></Table.HeaderCell>
                                                <Table.HeaderCell><h4>Data Block</h4></Table.HeaderCell>
                                                <Table.HeaderCell><h4>Draw Route</h4></Table.HeaderCell>
                                                <Table.HeaderCell><h4>Filter</h4></Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>

                                        <FieldArray name='flightsets'>
                                            {({ move, insert, remove, form }) => {
                                                return (
                                                    <FlightSets
                                                        arrayHelpers={{ insert, remove, move, }}
                                                        form={form}
                                                        helpers={(values) => { onRefChange(values) }}
                                                        selectedRow={selectedRowIndex}
                                                        setSelectedRow={(i) => setSelectedRowIndex(i)}
                                                    />
                                                )
                                            }}
                                        </FieldArray>

                                    </Table>
                                </div>

                            </Container>

                        </Modal.Content>

                        <Modal.Actions style={{ paddingBottom: '10px' }}>
                            <Button content='OK'
                                onClick={() => {
                                    submitForm();
                                }}
                            />
                            <Button content='Cancel'
                                onClick={() => {
                                    resetForm();
                                    onClose();
                                }}
                            />
                        </Modal.Actions>
                    </Form>

                </Modal>
            )}
        </Formik>
    )

}

export function FindFlightModal({ entries, onClose, onSubmit, showModal }) {

    const { data: aad, refetch: fetchADL } = useAirborneADL();

    const [color, setColor] = useState(entries.color);
    const [query, setQuery] = useState('');
    const [filter, setFilter] = useState([]);
    const [selectedOption, setSelectedOption] = useState(null);

    const [totalFlights, setTotalFlights] = useState(0);

    useEffect(() => {

        if (!_.isNil(entries.filter)) setFilter(entries?.filter);

    }, [entries]);

    const close = () => {
        onClose();
    };

    const submit = async (values) => {

        const flights = await findFlights(values.filter);

        onSubmit({
            query: {
                color: values.color,
                filter: values.filter,
            },
            flights,
        });
        setQuery('');
        close();

    }

    const findFlights = async (filter) => {

        if (_.isNil(filter)) return;

        await fetchADL();
        const { flightsetFlights } = getFlightsInFlightSet(aad, _.split(filter, ' '));

        setTotalFlights(flightsetFlights.length);

        return flightsetFlights;

    }

    return (

        !showModal ? null : (

            <Modal
                closeIcon
                size='mini'
                style={{ width: '225px' }}
                open={showModal}
                onClose={() => {
                    close();
                }}
            >

                <Modal.Header>Find Flight</Modal.Header>

                <Modal.Content style={{ padding: '0.5rem' }}>

                    <Header as='h4' content='Find Flight(s):' />

                    <TextArea value={query} onChange={(_, { value }) => setQuery(value)} style={{ width: '100%', padding: '0px', textTransform: 'uppercase' }} />
                    <Header as='h5' style={{ marginTop: '.5rem', marginBottom: '0' }}>
                        <div style={{ width: '20px', height: '20px', marginRight: '1rem', marginLeft: '.25rem', display: 'inline-block', verticalAlign: 'Middle' }}>
                            <ColorPalette onChange={(color) => { setColor(color) }} initialColor={color} />
                        </div>
                        Color of Found Flights
                    </Header>

                    <Header as='h5' style={{ marginTop: '1rem' }}>Number of Found Flights: {totalFlights}</Header>
                    <div className='darkbordered'>
                        <Header as='h5'> List of Flights to be Found </Header>
                        <select className='flightOption' size='10' style={{ width: '100%', backgroundColor: 'transparent' }}>
                            {
                                !_.isEmpty(filter) && _.map(filter, value => {
                                    return (
                                        <option onClick={(ev) => setSelectedOption(ev.target.value)} key={value.toUpperCase()}>{value.toUpperCase()}</option>
                                    )
                                })
                            }
                        </select>
                        <Button style={{ width: '100%' }} content='Remove Selected Item' onClick={() => setFilter(_.filter(filter, f => f.toUpperCase() !== selectedOption))} />
                    </div>

                </Modal.Content>

                <Modal.Actions style={{ paddingBottom: '10px' }}>
                    <Button content='OK'
                        onClick={() => {
                            submit({
                                filter: _.concat(filter, _.without(_.split(query, ' '), null, undefined, '')),
                                color: color,
                            });
                        }}
                    />
                    <Button content='Cancel'
                        onClick={() => {
                            close();
                        }}
                    />
                </Modal.Actions>

            </Modal>

        )

    )

}

function FlightCount({ flightset }) {

    const { data: aad } = useAirborneADL();
    const { map } = useContext(MapContext);

    const [flights, setFlights] = useState([]);
    const [arrival, setArrival] = useState('');
    const [departure, setDeparture] = useState('');
    const [visib, setVisib] = useState(0);

    useEffect(() => {

        setDeparture(_.isEmpty(flightset.departure) ? 'ALL' : flightset.departure.split(' '));
        setArrival(_.isEmpty(flightset.arrival) ? 'ALL' : flightset.arrival.split(' '));

        let allFlights = _.cloneDeep(aad);
        setFlights(getFlightsInFlightSet(allFlights, flightset));

    }, [aad, flightset]);

    useEffect(() => {

        let inBounds = [];
        const bounds = map.getBounds();

        _.forEach(flights, flight => {

            if (_.isNil(flight.position)) return;

            let latlng = new LngLat(flight.position.geometry.coordinates[0], flight.position.geometry.coordinates[1]);

            if (bounds.contains(latlng)) {
                inBounds.push(flight);
            }

        });

        setVisib(inBounds.length);

    }, [flights, map]);

    return (
        <Table.Row key={flightset.id} style={{
            color: flightset.color.hex,
        }}>
            <Table.Cell>{arrival}</Table.Cell>
            <Table.Cell>{departure}</Table.Cell>
            <Table.Cell>{flights.length}</Table.Cell>
            <Table.Cell>{visib}</Table.Cell>
        </Table.Row>
    )

}

export function FlightCountModal({ showModal, onCancel, flightsets }) {

    const { data: aad } = useAirborneADL();

    return (
        <Modal onClose={() => onCancel()} open={showModal} closeIcon>

            <Modal.Header>Flight Count</Modal.Header>

            <Modal.Content>

                <Table stackable celled compact>

                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell><h4>Arr</h4></Table.HeaderCell>
                            <Table.HeaderCell><h4>Dep</h4></Table.HeaderCell>
                            <Table.HeaderCell><h4>Actv</h4></Table.HeaderCell>
                            <Table.HeaderCell><h4>Visib</h4></Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {
                            _.isArray(aad) && aad.length > 0 && (_.isArray(flightsets) && flightsets.length > 0) ?
                                _.map(flightsets, flightset => {
                                    return <FlightCount key={flightset.id} flightset={flightset} />
                                }) : null
                        }
                    </Table.Body>

                </Table>

            </Modal.Content>

        </Modal>
    )

}