export const API_URL = process.env.REACT_APP_SITE_API;

// export const MAP_MODIFIER = 0.25;
export const MAP_MODIFIER = 0.3;

export const arrivalTypeColors = [
  {
    name: 'Cancelled',
    status: 'cancelled',
    color: '#00ffff'
  },
  {
    name: 'Past Dept Time',
    status: 'past_deptime',
    color: '#00ae00'
  },
  {
    name: 'Departing',
    status: 'departing',
    color: '#00ff00'
  },
  {
    name: 'EDCT Issued',
    status: 'edct',
    color: '#ae6640'
  },
  {
    name: 'Ground Stop',
    status: 'gs',
    color: '#ffff00'
  },
  {
    name: 'Flight Active',
    status: 'active',
    color: '#ff0000'
  },
  {
    name: 'Arrived',
    status: 'arrived',
    color: '#000000'
  },
];

export const alertColors = [
  {
    name: 'Alert',
    alert: 'alert',
    color: '#ff0000'
  },
  {
    name: 'Warning',
    alert: 'warning',
    color: '#ffff00'
  },
  {
    name: 'No Problem',
    alert: 'normal',
    color: '#008000'
  }
];

export const centerColors = [
  {
    name: 'ZAB',
    artcc: 'ZAB',
    color: '#f01338'
  },
  {
    name: 'ZAU',
    artcc: 'ZAU',
    color: '#1193d4'
  },
  {
    name: 'ZBW',
    artcc: 'ZBW',
    color: '#13f081'
  },
  {
    name: 'ZDC',
    artcc: 'ZDC',
    color: '#f0f013'
  },
  {
    name: 'ZDV',
    artcc: 'ZDV',
    color: '#f0a613'
  },
  {
    name: 'ZFW',
    artcc: 'ZFW',
    color: '#f06246'
  },
  {
    name: 'ZHU',
    artcc: 'ZHU',
    color: '#f0467e'
  },
  {
    name: 'ZID',
    artcc: 'ZID',
    color: '#f078dc'
  },
  {
    name: 'ZJX',
    artcc: 'ZJX',
    color: '#7e46f0'
  },
  {
    name: 'ZKC',
    artcc: 'ZKC',
    color: '#3d6fd4'
  },
  {
    name: 'ZLA',
    artcc: 'ZLA',
    color: '#3dbbd4'
  },
  {
    name: 'ZLC',
    artcc: 'ZLC',
    color: '#0a801e'
  },
  {
    name: 'ZMA',
    artcc: 'ZMA',
    color: '#f0dc78'
  },
  {
    name: 'ZME',
    artcc: 'ZME',
    color: '#d346f0'
  },
  {
    name: 'ZMP',
    artcc: 'ZMP',
    color: '#7f310a'
  },
  {
    name: 'ZNY',
    artcc: 'ZNY',
    color: '#f0788c'
  },
  {
    name: 'ZOA',
    artcc: 'ZOA',
    color: '#f013a6'
  },
  {
    name: 'ZOB',
    artcc: 'ZOB',
    color: '#3813f0'
  },
  {
    name: 'ZSE',
    artcc: 'ZSE',
    color: '#630fb8'
  },
  {
    name: 'ZTL',
    artcc: 'ZTL',
    color: '#406a80'
  },
  {
    name: 'OTHER',
    artcc: 'OTHER',
    color: '#13f0cb'
  }
];

export const defaultColorSet = [
  {
    name: "Black",
    rgb: "0, 0, 0",
    hex: "#000000",
    css: "filter: invert(0%) sepia(93%) saturate(48%) hue-rotate(302deg) brightness(102%) contrast(100%);"
  },
  {
    name: "DimGray",
    rgb: "102, 102, 102",
    hex: "#666666",
    css: "filter: invert(42%) sepia(1%) saturate(0%) hue-rotate(186deg) brightness(89%) contrast(81%);"
  },
  {
    name: "Nobel",
    rgb: "153, 153, 153",
    hex: "#999999",
    css: "filter: invert(68%) sepia(0%) saturate(0%) hue-rotate(155deg) brightness(91%) contrast(83%);"
  },
  {
    name: "Iron",
    rgb: "193, 205, 205",
    hex: "#c1cdcd",
    css: "filter: invert(97%) sepia(28%) saturate(245%) hue-rotate(151deg) brightness(85%) contrast(87%);"
  },
  {
    name: "White",
    rgb: "255, 255, 255",
    hex: "#ffffff",
    css: "filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(178deg) brightness(110%) contrast(101%);"
  },
  {
    name: "AntiqueWhite",
    rgb: "238, 223, 204",
    hex: "#eedfcc",
    css: "filter: invert(89%) sepia(17%) saturate(254%) hue-rotate(348deg) brightness(102%) contrast(87%);"
  },
  {
    name: "DarkRed",
    rgb: "139, 0, 0",
    hex: "#8b0000",
    css: "filter: invert(15%) sepia(30%) saturate(7420%) hue-rotate(351deg) brightness(81%) contrast(122%);"
  },
  {
    name: "Red",
    rgb: "255, 0, 0",
    hex: "#ff0000",
    css: "filter: invert(33%) sepia(98%) saturate(7500%) hue-rotate(359deg) brightness(92%) contrast(122%);"
  },
  {
    name: "OrangeRed",
    rgb: "255, 69, 0",
    hex: "#ff4500",
    css: "filter: invert(34%) sepia(27%) saturate(5142%) hue-rotate(357deg) brightness(99%) contrast(112%);"
  },
  {
    name: "Coral",
    rgb: "255, 127, 80",
    hex: "#ff7f50",
    css: "filter: invert(73%) sepia(42%) saturate(5016%) hue-rotate(326deg) brightness(102%) contrast(101%);"
  },
  {
    name: "LightPink",
    rgb: "255, 182, 193",
    hex: "#ffb6c1",
    css: "filter: invert(77%) sepia(42%) saturate(467%) hue-rotate(303deg) brightness(101%) contrast(101%);"
  },
  {
    name: "Bisque",
    rgb: "255, 228, 196",
    hex: "#ffe4c4",
    css: "filter: invert(88%) sepia(95%) saturate(266%) hue-rotate(305deg) brightness(101%) contrast(101%);"
  },
  {
    name: "Purple",
    rgb: "125, 38, 205",
    hex: "#7d26cd",
    css: "filter: invert(28%) sepia(26%) saturate(7019%) hue-rotate(259deg) brightness(78%) contrast(110%);"
  },
  {
    name: "Plum",
    rgb: "221, 160, 221",
    hex: "#dda0dd",
    css: "filter: invert(87%) sepia(22%) saturate(1072%) hue-rotate(253deg) brightness(93%) contrast(86%);"
  },
  {
    name: "DeepMagenta",
    rgb: "205, 0, 205",
    hex: "#cd00cd",
    css: "filter: invert(13%) sepia(77%) saturate(6962%) hue-rotate(296deg) brightness(95%) contrast(110%);"
  },
  {
    name: "VioletRed",
    rgb: "255, 62, 150",
    hex: "#ff3e96",
    css: "filter: invert(63%) sepia(76%) saturate(6671%) hue-rotate(312deg) brightness(105%) contrast(102%);"
  },
  {
    name: "BubbleGum",
    rgb: "255, 187, 255",
    hex: "#ffbbff",
    css: "filter: invert(89%) sepia(72%) saturate(1615%) hue-rotate(222deg) brightness(105%) contrast(106%);"
  },
  {
    name: "LightCyan",
    rgb: "224, 255, 255",
    hex: "#e0ffff",
    css: "filter: invert(89%) sepia(48%) saturate(141%) hue-rotate(103deg) brightness(105%) contrast(106%);"
  },
  {
    name: "RoyalBlue",
    rgb: "39, 64, 139",
    hex: "#27408b",
    css: "filter: invert(18%) sepia(98%) saturate(1198%) hue-rotate(208deg) brightness(97%) contrast(92%);"
  },
  {
    name: "Blue",
    rgb: "0, 0, 255",
    hex: "#0000ff",
    css: "filter: invert(9%) sepia(96%) saturate(6398%) hue-rotate(247deg) brightness(95%) contrast(147%);"
  },
  {
    name: "SteelBlue",
    rgb: "79, 148, 205",
    hex: "#4f94cd",
    css: "filter: invert(56%) sepia(42%) saturate(612%) hue-rotate(165deg) brightness(89%) contrast(89%);"
  },
  {
    name: "SkyBlue",
    rgb: "135, 206, 235",
    hex: "#87ceeb",
    css: "filter: invert(73%) sepia(58%) saturate(247%) hue-rotate(155deg) brightness(93%) contrast(98%);"
  },
  {
    name: "Cyan",
    rgb: "0, 255, 255",
    hex: "#00ffff",
    css: "filter: invert(92%) sepia(100%) saturate(5528%) hue-rotate(99deg) brightness(105%) contrast(107%);"
  },
  {
    name: "PaleTurquoise",
    rgb: "175, 238, 238",
    hex: "#afeeee",
    css: "filter: invert(95%) sepia(13%) saturate(831%) hue-rotate(134deg) brightness(98%) contrast(91%);"
  },
  {
    name: "OliveDrab",
    rgb: "105, 139, 34",
    hex: "#698b22",
    css: "filter: invert(45%) sepia(52%) saturate(619%) hue-rotate(38deg) brightness(96%) contrast(84%);"
  },
  {
    name: "LimeGreen",
    rgb: "50, 205, 50",
    hex: "#32cd32",
    css: "filter: invert(88%) sepia(36%) saturate(6417%) hue-rotate(67deg) brightness(92%) contrast(88%);"
  },
  {
    name: "PaleGreen",
    rgb: "143, 188, 143",
    hex: "#8fbc8f",
    css: "filter: invert(94%) sepia(4%) saturate(2746%) hue-rotate(59deg) brightness(79%) contrast(81%);"
  },
  {
    name: "Khaki",
    rgb: "205, 198, 115",
    hex: "#cdc673",
    css: "filter: invert(75%) sepia(23%) saturate(614%) hue-rotate(18deg) brightness(98%) contrast(95%);"
  },
  {
    name: "LightSeaGreen",
    rgb: "32, 178, 170",
    hex: "#20b2aa",
    css: "filter: invert(63%) sepia(49%) saturate(687%) hue-rotate(128deg) brightness(85%) contrast(83%);"
  },
  {
    name: "Chartreuse",
    rgb: "127, 255, 0",
    hex: "#7fff00",
    css: "filter: invert(87%) sepia(17%) saturate(2800%) hue-rotate(35deg) brightness(101%) contrast(117%);"
  },
  {
    name: "Chocolate",
    rgb: "205, 102, 29",
    hex: "#cd661d",
    css: "filter: invert(43%) sepia(18%) saturate(1907%) hue-rotate(342deg) brightness(104%) contrast(100%);"
  },
  {
    name: "Orange",
    rgb: "255, 165, 0",
    hex: "#ffa500",
    css: "filter: invert(54%) sepia(100%) saturate(526%) hue-rotate(0deg) brightness(103%) contrast(104%);"
  },
  {
    name: "Gold",
    rgb: "255, 215, 0",
    hex: "#ffd700",
    css: "filter: invert(72%) sepia(78%) saturate(862%) hue-rotate(0deg) brightness(105%) contrast(106%);"
  },
  {
    name: "LightSalmon",
    rgb: "255, 160, 122",
    hex: "#ffa07a",
    css: "filter: invert(64%) sepia(85%) saturate(371%) hue-rotate(321deg) brightness(101%) contrast(103%);"
  },
  {
    name: "Yellow",
    rgb: "255, 255, 0",
    hex: "#ffff00",
    css: "filter: invert(95%) sepia(99%) saturate(1423%) hue-rotate(1deg) brightness(105%) contrast(103%);"
  },
  {
    name: "LightGoldenrodYellow",
    rgb: "250, 250, 210",
    hex: "#fafad2",
    css: "filter: invert(86%) sepia(30%) saturate(195%) hue-rotate(21deg) brightness(110%) contrast(96%);"
  }
];

export const mapOverlayOptions = [
  {
    name: 'airports',
    label: 'Airports',
    options: [
      {
        label: 'All',
        name: 'airports_all',
      },
      {
        label: 'Pacing',
        name: 'airports_pacing',
      }
    ]
  },
  {
    name: 'artccs',
    label: 'ARTCCs',
    options: [
      {
        label: 'All',
        name: 'artcc',
      }
    ]
  },
  {
    name: 'navaids',
    label: 'NAVAIDs',
    options: [
      {
        label: 'High',
        name: 'high_navaid',
      },
      {
        label: 'Low',
        name: 'low_navaid',
      },
      {
        label: 'Terminal',
        name: 'terminal_navaid',
      },
      {
        label: 'Other',
        name: 'other_navaid',
      },
    ]
  },
  {
    name: 'sectors',
    label: 'Sectors',
    options: [
      {
        label: 'Low',
        name: 'low',
      },
      {
        label: 'High',
        name: 'high',
      },
      {
        label: 'Superhigh',
        name: 'superhigh',
      },
      {
        label: 'Oceanic',
        name: 'oceanic',
      }
    ]
  },
  {
    name: 'fixes',
    label: 'Fixes',
    options: [
      {
        label: 'Departure',
        name: 'departure',
      },
      {
        label: 'Arrival',
        name: 'arrival',
      }
    ]
  },
  {
    name: 'tracons',
    label: 'TRACONs',
    options: [
      {
        label: 'All',
        name: 'tracon',
      }
    ]
  },
  {
    name: 'airways',
    label: 'Airways',
    options: [
      {
        label: 'Jet',
        name: 'j',
      },
      {
        label: 'Victor',
        name: 'v',
      },
      {
        label: 'Q Routes',
        name: 'q',
      },
      {
        label: 'T Routes',
        name: 't',
      },
    ]
  },
  {
    name: 'sua',
    label: 'SUAs',
    options: [
      {
        label: 'Alert',
        name: 'alert',
      },
      {
        label: 'MOA',
        name: 'moa',
      },
      {
        label: 'Prohibited',
        name: 'prohibited',
      },
      {
        label: 'Restricted',
        name: 'restricted',
      },
      {
        label: 'Warning',
        name: 'warning',
      },
    ]
  },
  {
    name: 'misc',
    label: 'Misc',
    options: [
      {
        label: 'Boundaries',
        name: 'boundaries',
      },
      {
        label: 'Lat/Long Grid',
        name: 'grid',
      },
    ]
  },
];

const selectTCFOptions = [
  {
    name: 'tcf_forecast',
    label: 'Forecast',
    type: 'radio',
    options: [
      {
        value: 'off',
        label: 'Off'
      },
      {
        value: 4,
        label: '4hr'
      },
      {
        value: 6,
        label: '6hr'
      },
      {
        value: 8,
        label: '8hr'
      },
    ]
  },
  {
    name: 'overlay_labels',
    label: 'Show',
    type: 'checkbox',
    options: [
      {
        value: 'tcf_datablock',
        label: 'Data Blocks'
      }
    ]
  },
];

const selectRadarOptions = [
  {
    name: 'radar_type',
    label: 'Show',
    type: 'radio',
    options: [
      {
        value: 'off',
        label: 'Off'
      },
      {
        value: '0',
        label: 'Black and White'
      },
      {
        value: '1',
        label: 'Original'
      },
      {
        value: '2',
        label: 'Universal Blue'
      },
      {
        value: '3',
        label: 'TITAN'
      },
      {
        value: '4',
        label: 'TWC'
      },
      {
        value: '5',
        label: 'Meteored'
      },
      {
        value: '6',
        label: 'NEXRAD Level III'
      },
      {
        value: '7',
        label: 'SELEX-IS'
      },
      {
        value: '8',
        label: 'Dark Sky'
      },
    ]
  }
];

export const selectWeatherOptions = [
  {
    name: 'radar_options',
    label: 'Radar',
    inline: false,
    options: selectRadarOptions
  },
  {
    name: 'tcf_options',
    label: 'TCF',
    inline: true,
    options: selectTCFOptions
  },
];

export const selectAlertOptions = [
  {
    name: 'airports',
    label: 'Airports',
    options: [
      {
        label: 'All',
        name: 'airports_all',
        enabled: false,
      }
    ]
  },
  {
    name: 'sectors',
    label: 'Sectors',
    options: [
      {
        label: 'Low',
        name: 'low',
        enabled: true,
      },
      {
        label: 'High',
        name: 'high',
        enabled: true,
      },
      {
        label: 'Superhigh',
        name: 'superhigh',
        enabled: true,
      }
    ]
  },
  {
    name: 'fixes',
    label: 'Fixes',
    options: [
      {
        label: 'Low',
        name: 'low',
        enabled: false,
      },
      {
        label: 'High',
        name: 'high',
        enabled: false,
      },
      {
        label: 'Superhigh',
        name: 'superhigh',
        enabled: false,
      }
    ]
  },
  {
    name: 'online',
    label: 'Online',
    options: [
      {
        label: 'All',
        name: 'online_all',
        enabled: true,
      }
    ]
  },
];

export const examineAlertOptions = [
  {
    name: 'type',
    label: 'Type',
    type: 'radio',
    options: [
      {
        label: 'Airport',
        name: 'airport',
        enabled: false,
      },
      {
        label: 'Fix',
        name: 'fix',
        enabled: false,
      },
      {
        label: 'Sector',
        name: 'sector',
        enabled: true,
      }
    ]
  },
  {
    name: 'subtype',
    label: 'Subtype',
    type: 'checkbox',
    options: [
      {
        label: 'Arrival',
        name: 'arrival',
        enabled: false,
      },
      {
        label: 'Departure',
        name: 'departure',
        enabled: false,
      },
      {
        label: 'Low',
        name: 'low',
        enabled: true,
      },
      {
        label: 'High',
        name: 'high',
        enabled: true,
      },
      {
        label: 'Superhigh',
        name: 'superhigh',
        enabled: true,
      }
    ]
  }
];

export const airportRegex = /^(?!NAT)[A-Z0-9]{4}$/;
export const stardpRegex = /^[A-Z]{2}\d{1,2}[A-Z]?$|^[A-Z]{3}\d{1,2}[A-Z]?$|^[A-Z]{4}\d[A-Z]$|^[A-Z]{5}(?:\d|[A-Z])$/;
export const natRegex = /^NAT_?([A-HJ-M]{1}|[W-Z]{1})$/;
export const fixRegex = /^[A-Z0-9]{5}$/;
export const latlonRegex = /^(?<latDeg>\d{2})(?<latMin>\d{2})?(?<dirOne>N|S|E|W)?\/?(?<lonDeg>\d{2}(?!\d{3}|\d\D)|\d{3})(?<lonMin>\d{2})?(?<dirTwo>N|S|E|W)?$/;
export const airwayRegex = /^(?:A|AF|AC|AS|AD|AR|ATS|B|BF|BA|BD|BR|BRL|BRV|C|CR|G|GD|GF|GR|H|HC|HF|J|JF|JR|K|KQ|KY|KZ|L|M|MG|N|NA|ND|NB|NE|NC|NG|NF|NH|NCA|NR|OTR|P|Q|R|RD|RF|RG|RR|RTE|T|TG|TB|TK|TL|TLN|TLS|TR|TRA|UA|UB|UG|UH|UJ|UJE|UJS|UJN|UJW|UL|UM|UN|UP|UQ|UR|UT|UV|UW|UY|UZ|V|VN|VS|VW|VE|VF|W|WE|WW|WA|WG|WN|WS|WB|WD|WC|WF|X|Y|Z)\d{1,4}[A-Z]?$/;
export const navRegex = /^[A-Z]{2,3}$/;
export const frdRegex = /^([A-Z]{2,3})(\d{3})(\d{3})$/;

export const COUNTRY_SOURCE_ID = 'boundaries_50m-9ic7e5';
export const ARTCC_SOURCE_ID = 'ARTCC-aql2oq';
export const SECTOR_SOURCE_ID = 'sectors-2bx0yz';
export const LABEL_SOURCE_ID = 'labels-1b8n8n';
export const AIRPORT_SOURCE_ID = 'AIRPORTS-2kpls9';
export const FIX_SOURCE_ID = 'arrivaldeparture_fixes-0bvr77';
export const NAVAID_SOURCE_ID = 'navaid-7ui9cr';
export const AIRWAY_SOURCE_ID = 'airways-1zcocl';
export const SUA_SOURCE_ID = 'sua-bjcik8';