import React, { useEffect, useState } from 'react';
import { Container, Header, Table, Popup } from 'semantic-ui-react';
import { DateTime } from 'luxon';
import _ from 'lodash';

import { ECRModal } from './ECR';
import { useInterval, inTimeFrame } from './utils';

import prop from './prop.svg';
import jet from './jet.svg';
import heavy from './heavy.svg';
import cnx_ctrl from './cnx_ctrl.svg';
import cnx from './cnx.svg';
import dla_ctrl from './dla_ctrl.svg';
import dla from './dla.svg';

function PlaneIcon({ flight, onClick }) {

    let icon;
    let transform;

    if (flight.status === 'active' || flight.status === 'departing' || flight.status === 'arrived') {

        icon = {
            'jet': jet,
            'heavy': heavy,
            'prop': prop
        }[flight.aircraft_class];

        transform = 'rotate(-90deg)';

    }
    else if (flight.status === 'cancelled') {

        if (!_.isNil(flight?.edct)) icon = cnx_ctrl;
        else icon = cnx;

    }
    else if (flight.status === 'past_deptime') {

        if (!_.isNil(flight?.edct)) icon = dla_ctrl;
        else icon = dla;

    }

    let color = {
        'past_deptime': 'plane-Blue',
        'departing': 'plane-LimeGreen',
        'active': 'plane-Red',
        'arrived': 'plane-Black',
        'edct': 'plane-Blue',
        'cancelled': 'plane-Black'
    }[flight.status];

    let etd = DateTime.fromJSDate(new Date(flight.edct ? flight.edct.time : flight.atd ? flight.atd : flight.std), { zone: 'utc' });
    let eta = DateTime.fromJSDate(new Date(flight.ata ? flight.ata : flight.eta), { zone: 'utc' });

    if (etd.isValid) etd = etd.toFormat('dd/HHmm');
    else etd = '-';

    if (eta.isValid) eta = eta.toFormat('dd/HHmm');
    else eta = '-';

    return <Popup
        position='bottom left'
        trigger={
            <img alt='' src={icon}
                onClick={onClick}
                style={{
                    transform: transform,
                    width: 10,
                    height: 10,
                }}
                className={color}
            />
        }
        content={`${flight.callsign} ${flight.dep} ${flight.dest} ${etd} ${eta}`} />;

}

export function FSMTable({ numCols, airport, aar, aad }) {

    const [clock, setClock] = useState(DateTime.utc());
    const [headers, setHeaders] = useState(null);
    const [rows, setRows] = useState(null);

    const [showFSMModal, setShowFSMModal] = useState(false);
    const [flight, setFlight] = useState(null);

    const [arrivalRate, setArrivalRate] = useState(0);

    function onPlaneClick(flight) {

        if (_.includes(['departing', 'past_deptime', 'edct'], flight.status)) {
            setFlight(flight);
            //setShowFSMModal(true);
        }

    }

    useInterval(() => {
        setClock(DateTime.utc());
    }, 1000);

    useEffect(() => {
        setArrivalRate(Array.isArray(aar) ? aar[0] : aar);
    }, [aar]);

    useEffect(() => {

        let times = [];
        let timeslot = DateTime.utc().minus({ hour: 1 }).startOf('hour');

        function generatePlaneIcons(flights, thisTime) {

            return flights.filter(flight => {

                let time;
                if (flight.status === 'arrived') time = DateTime.fromJSDate(new Date(flight.ata), { zone: 'utc' });
                else time = DateTime.fromJSDate(new Date(flight.eta), { zone: 'utc' });

                return thisTime.hasSame(time, 'minute');

            }).map(flight => {
                return <PlaneIcon key={flight.callsign} flight={flight} onClick={() => onPlaneClick(flight)} />;
            })

        }

        for (let i = 1; i <= numCols + 1; i++) {

            times.push(timeslot);
            timeslot = timeslot.plus({ hour: 1 });

        }

        const h = times.map((time, i) => {

            let now = time;
            let next = times[i + 1];

            if (_.isNil(next)) return null;

            let demand = _.filter(aad, flight => {

                let time;
                if (flight.status === 'arrived') time = DateTime.fromJSDate(new Date(flight.ata), { zone: 'utc' });
                else time = DateTime.fromJSDate(new Date(flight.eta), { zone: 'utc' });

                return !_.includes(['archived'], flight.status) && inTimeFrame(time, now, next);

            });

            return (
                <Table.HeaderCell key={time} style={{ borderWidth: 0 }}>
                    <div><Header as='h4'>{time.toFormat('D HHmm')}</Header></div>
                    <div style={{ textAlign: 'justify' }}><Header as='h4'>{`${arrivalRate}/${demand.length}`}</Header></div>
                </Table.HeaderCell>
            );

        });

        const r = Array.from(Array(60)).map((_tr, m) => {

            return (

                <Table.Row key={m}>
                    {
                        _.map(Array.from(Array(numCols)), (_td, h) => {

                            let thisTime = times[h].plus({ minute: m });

                            let traffic = _.filter(aad, flight => !_.includes(['archived'], flight.status));
                            let planes = generatePlaneIcons(traffic, thisTime);

                            let str;
                            if (m % 5 === 0 && m !== 60)
                                str = m;
                            else
                                str = '-';

                            return (

                                <Table.Cell
                                    key={h}
                                    warning={clock.hasSame(thisTime, 'minute')}
                                    style={{ borderWidth: 0, height: 12, padding: 0 }}
                                >{str} {planes}</Table.Cell>

                            );

                        })
                    }
                </Table.Row>

            );

        });

        setHeaders(h);
        setRows(r);

    }, [numCols, arrivalRate, aad, clock]);

    return (

        <>

            <ECRModal showModal={showFSMModal} onClose={() => setShowFSMModal(false)} item={flight} />

            <Container textAlign='center' text style={{ paddingTop: '5px', paddingBottom: '10px' }}>
                <Header as='h2'>{`${airport.substring(1)}\u00A0\u00A0\u00A0\u00A0${clock.toFormat('D HH:mm')}Z\u00A0\u00A0\u00A0\u00A0ETA`}</Header>
            </Container>

            <Table unstackable basic compact>

                <Table.Header>
                    <Table.Row style={{ borderWidth: 0 }}>
                        {headers}
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {rows}
                </Table.Body>

            </Table>

        </>

    );

}

export function FSM({ airport, aar, aad }) {

    const [arrivalRate, setArrivalRate] = useState(0);

    useEffect(() => {

        if (!aar) return;
        setArrivalRate(aar);

    }, [aar]);

    return (

        <>

            <Container
                fluid
                style={{ paddingLeft: '15px', paddingRight: '15px' }}
            >

                <Container>
                    <FSMTable numCols={6} airport={airport} aar={arrivalRate} aad={aad.traffic} />
                </Container>

            </Container>

        </>

    );

}