import { useEffect, useState } from "react";
import { Field, useFormikContext } from "formik";
import { Button, Container, Grid, Modal, Form, Header, Table } from "semantic-ui-react";
import { Form as FormikForm, Input, Radio } from 'formik-semantic-ui-react';
import _ from "lodash";
import { Layer } from "react-map-gl";
import { DateTime } from "luxon";
import TreeSelect from 'react-do-tree-select';

import { nas_map } from "./resources/nas_map";
import { DisplayRangeSlider, NumberInput } from "./Inputs";
import { selectAlertOptions, examineAlertOptions, MAP_MODIFIER } from './config';
import { useActiveADL, useSectorAlerts } from "./api/tfms";

function NASMonitorFooter({ displayrange }) {

    //displayrange is the number of hours to show in the footer as a decimal

    //get the current time
    const now = DateTime.utc();

    //create 15 minute intervals from the current 15 minute interval to the end of the display range
    const intervals = _.range(Math.floor(now.minute / 15) * 15, Math.ceil(now.minute / 15) * 15 + displayrange * 60, 15);

    //create a list of labels for the footer
    const labels = intervals.map(i => {
        const dt = DateTime.utc().set({ minute: i, second: 0, millisecond: 0 });
        return dt.toFormat("HHmm");
    });

    return (

        <Table.Footer>
            <Table.Row>
                <Table.HeaderCell className='nasmonitor_blue' style={{ minWidth: '32px' }} />
                <Table.HeaderCell className='nasmonitor_blue' style={{ minWidth: '25px' }} />
                {labels.map(l => <Table.HeaderCell key={l} className='nasmonitor_blue'>{l}</Table.HeaderCell>)}
            </Table.Row>
        </Table.Footer>

    )

}

function NASMonitorCell({ congestion, capacity }) {

    let color;

    if (congestion.airborneCongestion >= capacity) {
        color = 'red';
    }
    else if (congestion.allCongestion >= capacity) {
        color = 'yellow';
    }
    else {
        color = 'green';
    }

    return (
        <Table.Cell className={`nasmonitor_${color}`}>
            {Math.max(...[congestion.airborneCongestion, congestion.allCongestion])}
        </Table.Cell>
    )

}

function NASMonitorRow({ artcc, sector, map, displayrange }) {

    const { values } = useFormikContext();
    const { data: activeTraffic } = useActiveADL();
    const { data: sectorAlerts } = useSectorAlerts();

    const [sectorLabel, setSectorLabel] = useState(sector);

    useEffect(() => {

        if ('custom' !== _.toLower(artcc)) setSectorLabel(_.replace(sector, artcc, ''));

    }, [sector, artcc]);

    const now = DateTime.utc();
    const intervals = _.range(Math.floor(now.minute / 15) * 15, Math.ceil(now.minute / 15) * 15 + displayrange * 60, 15);

    const labels = intervals.map(i => {
        return DateTime.utc().set({ minute: i, second: 0, millisecond: 0 });
    });

    const congestion = labels.map(l => {

        const thisSector = _.find(sectorAlerts, { label: sector });
        const thisTraffic = _.find(thisSector?.times, { time: l.toISO() })?.callsigns;

        const all = _.size(thisTraffic) || 0;

        return {
            label: l,
            airborneCongestion: _.size(_.filter(thisTraffic, f => {
                return _.find(activeTraffic, { callsign: f.callsign });
            })),
            allCongestion: all
        }

    });
    // const congestion = getCongestion(sectorAlerts, sector, displayrange);

    if (!values.show_in_timelimit || _.some(congestion, c => c.airborneCongestion >= map || c.allCongestion >= map)) {

        return <Table.Row>
            <Table.Cell className='nasmonitor_blue' style={{ minWidth: '32px' }}>{sectorLabel}</Table.Cell>
            <Table.Cell className='nasmonitor_blue' style={{ minWidth: '25px' }}>{`${map}/${map}`}</Table.Cell>
            {
                _.map(congestion, c => {

                    return <NASMonitorCell key={c.label.toString()}
                        congestion={{
                            airborneCongestion: c.airborneCongestion,
                            allCongestion: c.allCongestion
                        }}
                        capacity={map}
                    />

                })
            }
        </Table.Row>

    } else return null;

}

export function NASMonitorModal({ onClose, onSelect, showModal, artcc, sectors, timelimit }) {

    const { dataUpdatedAt } = useActiveADL();

    return (

        <Modal
            open={showModal}
            onClose={onClose}
            size="small"
            closeIcon
        >

            <Modal.Header>{`NAS Monitor`}</Modal.Header>

            <Modal.Content style={{ padding: '0.5em' }}>

                <div id='alerttime' style={{ position: 'relative', display: 'flex', alignItems: 'top', marginBottom: '10px' }}>

                    <div style={{ height: '34px', display: 'inline-block', padding: '3px' }}>
                        <DisplayRangeSlider value={timelimit} />
                    </div>
                    <div style={{ marginLeft: '15px', padding: '3px', display: 'inline-block', height: '34px', border: '1px solid black' }}>
                        <label className={'checkboxContainer'}>
                            Show only if alerted in next <br />{timelimit} hours (Time Limit)
                            <Field name={'show_in_timelimit'} type='checkbox' />
                            <span className='checkmark' />
                        </label>
                    </div>

                    <div style={{ display: 'inline-block', fontWeight: 'bold', position: 'absolute', top: '0', right: '0' }}>Updated: {DateTime.fromMillis(dataUpdatedAt, { zone: 'utc' }).toFormat('HHmm')}</div>

                </div>

                <Button content='Select Elements' onClick={onSelect} />

                <Table>

                    <Table.Body>
                        {
                            _.map(sectors, s => {

                                const v = _.find(nas_map, { SECTOR: s });

                                if (_.isNil(v)) return null;

                                const map = Math.floor(v.MAP * MAP_MODIFIER);
                                return <NASMonitorRow key={v.SECTOR} artcc={artcc} sector={v.SECTOR} map={map} displayrange={timelimit} />

                            })
                        }
                    </Table.Body>

                    <NASMonitorFooter displayrange={timelimit} />

                </Table>

            </Modal.Content>

        </Modal>

    )

}

export function SelectMonitoredElementsModal({ onClose, onCancel, showModal }) {

    const { values, setFieldValue, resetForm } = useFormikContext();

    const [treeData, setTreeData] = useState(null);

    const [selectedSectors, setSelectedSectors] = useState([]);

    const [monitoredElements, setMonitoredElements] = useState(values.sectors);
    const [selectedOption, setSelectedOption] = useState(null);

    useEffect(() => {

        let td = [];

        _.forEach(nas_map, (s, i) => {

            const artcc = s.SECTOR.substring(0, 3);
            // const sector_number = s.SECTOR.substring(3);

            if (_.find(td, { title: artcc })) {

                const artcc_node = _.find(td, { title: artcc });
                artcc_node.children.push({
                    title: s.SECTOR,
                    value: s.SECTOR,
                });

            } else {

                td.push({
                    title: artcc,
                    value: artcc,
                    children: [{
                        title: s.SECTOR,
                        value: s.SECTOR,
                    }]
                });

            }

        });

        setTreeData(td);

    }, []);

    const onAdd = () => {

        setMonitoredElements(prevState => {
            return _.uniq([...prevState, ...selectedSectors]);
        });
        setSelectedSectors([]);

    }

    const onRemove = () => {

        if (_.isNil(selectedOption)) return;

        setMonitoredElements(prevState => {
            return _.without(prevState, selectedOption);
        });

        setSelectedOption(null);
        setSelectedSectors([]);

    }

    const onRemoveAll = () => {

        setMonitoredElements([]);
        setSelectedOption(null);
        setSelectedSectors([]);

    }

    const moveUp = () => {

        if (_.isNil(selectedOption)) return;

        const index = _.findIndex(monitoredElements, v => { return v === selectedOption });
        if (index === 0 || index === -1) return;

        const newState = [...monitoredElements];
        const temp = newState[index];
        newState[index] = newState[index - 1];
        newState[index - 1] = temp;

        setMonitoredElements(newState);

    }

    const moveDown = () => {

        if (_.isNil(selectedOption)) return;

        const index = _.findIndex(monitoredElements, v => { return v === selectedOption });
        if (index === monitoredElements.length - 1 || index === -1) return;

        const newState = [...monitoredElements];
        const temp = newState[index];
        newState[index] = newState[index + 1];
        newState[index + 1] = temp;

        setMonitoredElements(newState);

    }

    return (

        <Modal
            open={showModal}
            onClose={onCancel}
            size='tiny'
            closeIcon
        >
            <Modal.Header>Select Monitored Elements</Modal.Header>

            <Modal.Content style={{ padding: '0.5em' }}>

                <Grid container columns='3'>

                    <Grid.Row>

                        <Grid.Column width='6'>

                            <div className='darkbordered' style={{ width: '100%' }}>

                                <Header as='h5'>Select Sectors</Header>
                                {
                                    _.isNil(treeData) ? null :
                                        <TreeSelect
                                            treeData={treeData}
                                            style={{ width: 166, height: 500 }}
                                            checkbox={{
                                                enable: true,
                                                parentChain: true,
                                                childrenChain: true,
                                                halfChain: false,
                                                initCheckedList: [],
                                            }}
                                            onChecked={(val, _e) => {
                                                setSelectedSectors(prevState => {
                                                    const newVals = _.filter([...val], v => v.length > 3);
                                                    return _.uniq([...prevState, ...newVals]);
                                                });
                                            }}
                                        />
                                }

                            </div>

                        </Grid.Column>

                        <Grid.Column width='4' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Button.Group vertical>
                                <Button onClick={onAdd}>{`Add >>`}</Button>
                                <Button onClick={onRemove}>{`<< Remove `}</Button>
                                <Button onClick={onRemoveAll}>{`Remove All`}</Button>
                            </Button.Group>
                        </Grid.Column>

                        <Grid.Column width='6'>

                            <div className='darkbordered'>

                                <Header as='h5'>Monitored Elements</Header>
                                <select size='10' className='flightOption' style={{ width: '100%', height: '475px', backgroundColor: 'transparent' }}>
                                    {
                                        !_.isEmpty(monitoredElements) && _.map(monitoredElements, e =>
                                            <option key={e}
                                                onClick={(ev) => setSelectedOption(ev.target.value)}
                                            >{e}</option>
                                        )
                                    }
                                </select>

                                <Button.Group>
                                    <Button onClick={moveUp}>Move Up</Button>
                                    <Button onClick={moveDown}>Move Down</Button>
                                </Button.Group>

                            </div>

                        </Grid.Column>

                    </Grid.Row>

                </Grid>

            </Modal.Content>

            <Modal.Actions style={{ paddingBottom: '10px' }}>
                <Button content='OK' onClick={() => {

                    if (_.isEmpty(monitoredElements)) {
                        onClose(null);
                        return;
                    }

                    setFieldValue('sectors', monitoredElements);
                    onClose(monitoredElements);

                }} />
                <Button content='Cancel' onClick={() => {
                    resetForm();
                    onCancel();
                }} />
            </Modal.Actions>

        </Modal>

    )

}

export function ExamineAlertModal({ onClose, onCancel, showModal }) {

    const { values, resetForm } = useFormikContext();

    return (

        <Modal
            open={showModal}
            onClose={() => {
                onCancel();
                resetForm();
            }}
            size='mini'
            closeIcon
        >
            <Modal.Header>Examine Alert</Modal.Header>

            <FormikForm>
                <Modal.Content style={{ padding: '0.5em' }}>

                    <Container>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column>
                                    <label style={{ display: 'inline-block', paddingRight: '1em', paddingLeft: '1em' }}>Place:</label>
                                    <div style={{ width: '150px', display: 'inline-block' }} >
                                        <Input fluid name='place' />
                                    </div>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row style={{ paddingLeft: '1em', paddingRight: '1em' }}>
                                {
                                    _.map(examineAlertOptions, (option) => (

                                        <Grid.Column key={option.name} width={(option.type === 'radio') ? '5' : '11'} stretched style={{ paddingLeft: '0.25em', paddingRight: '0.25em' }}>
                                            <Grid.Row className='gridlightbordered'>
                                                <Header as='h4' style={{ textAlign: 'left' }}>{option.label}</Header>
                                                <div>
                                                    {
                                                        _.map(option.options, (opt) => {

                                                            if (option.type === 'radio') {
                                                                return (
                                                                    <Radio key={opt.name} disabled={!opt.enabled} name={option.name} label={opt.label} value={opt.name} />
                                                                )
                                                            } else if (option.type === 'checkbox') {
                                                                return (
                                                                    <span key={opt.name} style={{ display: 'inline-block', paddingRight: '2em' }}>
                                                                        <label className={(!opt.enabled) ? 'checkboxContainer disabled' : 'checkboxContainer'}>
                                                                            {`${opt.label}`}
                                                                            <Field key={opt.name} disabled={!opt.enabled} type='checkbox' name={option.name} value={opt.name} />
                                                                            <span className='checkmark' />
                                                                        </label>
                                                                    </span>
                                                                )
                                                            }

                                                        })
                                                    }
                                                </div>
                                            </Grid.Row>
                                        </Grid.Column>

                                    ))
                                }
                            </Grid.Row>
                        </Grid>
                    </Container>

                </Modal.Content>

                <Modal.Actions style={{ paddingBottom: '10px' }}>
                    <Button content='OK' onClick={() => {
                        onClose(values);
                    }} />
                    <Button content='Cancel' onClick={() => {
                        resetForm();
                        onCancel();
                    }} />
                </Modal.Actions>
            </FormikForm>

        </Modal>

    )

}

export function SelectAlertsModal({ onClose, onCancel, showModal }) {

    const { resetForm, values } = useFormikContext();

    return (

        <Modal
            open={showModal}
            onClose={() => {
                onCancel();
                resetForm();
            }}
            size='mini'
            closeIcon
        >
            <Modal.Header>Select Alerts for Display</Modal.Header>

            <FormikForm>
                <Modal.Content style={{ padding: '0.5rem' }}>

                    <Container className='lightbordered'>

                        <Grid columns={4}>

                            {_.map(selectAlertOptions, (alertOption) => (

                                <Grid.Row key={alertOption.name}>
                                    <Grid.Column textAlign='right'>
                                        <Header size='small'>{`${alertOption.label}: `}</Header>
                                    </Grid.Column>
                                    <Grid.Column width={3}>
                                        <Form.Group inline>
                                            {
                                                _.map(alertOption.options, (option) => (
                                                    <span key={option.name} style={{ display: 'inline-block', paddingRight: '2em' }}>
                                                        <label className={(!option.enabled) ? 'checkboxContainer disabled' : 'checkboxContainer'}>
                                                            {option.label}
                                                            <Field key={option.name} disabled={!option.enabled} name={alertOption.name} type='checkbox' value={option.name} />
                                                            <span className='checkmark' />
                                                        </label>
                                                    </span>
                                                ))
                                            }
                                        </Form.Group>
                                    </Grid.Column>
                                </Grid.Row>

                            ))}

                            <Grid.Row>
                                <Grid.Column textAlign='right'>
                                    <Header size='small'>Time Limit: </Header>
                                </Grid.Column>
                                <Grid.Column width={3}>
                                    <Form.Group inline>
                                        <Field name='timelimit' component={NumberInput} maxValue={6} valueType='decimal' stepAmount={0.25} />
                                    </Form.Group>
                                </Grid.Column>
                            </Grid.Row>

                        </Grid>

                    </Container>

                </Modal.Content>

                <Modal.Actions style={{ paddingBottom: '10px' }}>
                    <Button content='OK' onClick={() => {
                        onClose(values);
                    }} />
                    <Button content='Cancel' onClick={() => {
                        resetForm();
                        onCancel();
                    }} />
                </Modal.Actions>
            </FormikForm>

        </Modal>

    )

}

export function AlertFeature({ filterText, filterPaint, paintFill, paintText, paintLine, textfield, source, labelSource, show }) {

    return show ?
        <>
            <Layer type='symbol' source='composite' source-layer={labelSource}
                filter={filterText}
                paint={{
                    'text-color': paintText
                    // 'text-color': '#0000ff'
                }}
                layout={{
                    'text-field': ['get', textfield],
                    'text-size': 11,
                    'text-letter-spacing': 0.1,
                    'text-variable-anchor': ['top'],
                    // 'text-font': ['Arial Unicode MS Bold'],
                    'text-font': ['Arial Unicode MS Bold', 'Arial Unicode MS Regular'],
                }}
            />
            <Layer type='line' source='composite' source-layer={source}
                filter={filterPaint}
                paint={{
                    'line-width': 1.25,
                    'line-color': paintLine
                }}
            />
            <Layer type='fill' source='composite' source-layer={source}
                filter={filterPaint}
                paint={{
                    'fill-antialias': false,
                    'fill-pattern': paintFill
                }}
            />
        </>
        : null

}