import React, { useState, useEffect, Suspense, useRef } from 'react';
import _ from 'lodash';
import { Visibility, Container, Menu, Segment, Loader, Dropdown, Tab, Button } from 'semantic-ui-react';
// import { useSessionContext } from 'supertokens-auth-react/recipe/session';

import { GDT } from './GDT';
import { AADC } from './AADC';
import { GDTMapContainer } from './GDTMap';
import { FSM } from './FSM';
import { FlightList, FlightListModal } from './FlightList';
import { useFacilities, useADL, useAAR, useAllGDP } from './api/tfms';
import { GDTSetup } from './GDTSetup';
import { EDCTModal } from './EDCTLookup';
import { ModifyAAR, ResetAAR } from './AAR';
import { WeatherModal } from './Weather';

function NavBar({ onDataSelect, airportOptions }) {

    function onAirportSelect(value) {

        const text = _.find(airportOptions, { value: value });
        onDataSelect({ value, text: text.text });

    }

    return (
        <Visibility>

            <Segment textAlign='left' horizontal='true' style={{ borderColor: '#666666', borderWidth: '0 0 1px 0' }}>
                <Dropdown closeOnBlur closeOnEscape closeOnChange className={'nobottom'} compact text='Open Data Set' scrolling style={{ zIndex: '999', width: '120px' }} onChange={(_, { value }) => onAirportSelect(value)} options={airportOptions} />
            </Segment>

        </Visibility>
    )

}

function DatasetNavBar({ onViewSelect, onRemove, airport, airports }) {

    // const { doesSessionExist } = useSessionContext();
    const doesSessionExist = true;

    const [showGDTModal, setShowGDTModal] = useState(false);
    const [showEDCTModal, setShowEDCTModal] = useState(false);
    const [showFlightListModal, setShowFlightListModal] = useState(false);
    const [showModifyModal, setShowModifyModal] = useState(false);
    const [showResetModal, setShowResetModal] = useState(false);
    const [showWeatherModal, setShowWeatherModal] = useState(false);

    function filterFlights(flight) {
        return flight.edct !== undefined;
    }

    return (

        <>

            <GDTSetup airports={airports} airport={airport} showModal={showGDTModal} onClose={() => setShowGDTModal(false)} />
            <EDCTModal showModal={showEDCTModal} onClose={() => setShowEDCTModal(false)} />
            <FlightListModal airport={airport} showModal={showFlightListModal} onClose={() => setShowFlightListModal(false)} filterFunc={filterFlights} />
            <ModifyAAR airport={airport} show={showModifyModal} onClose={() => setShowModifyModal(false)} />
            <ResetAAR airport={airport} show={showResetModal} onClose={() => setShowResetModal(false)} />
            <WeatherModal onClose={() => setShowWeatherModal(false)} showModal={showWeatherModal} />

            <Segment textAlign='left' horizontal='true' style={{ borderColor: '#666666', borderWidth: '0 0 1px 0' }}>

                <Menu>

                    <Dropdown disabled={!airport} item text='View' style={{ zIndex: '500' }}>
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => onViewSelect('map')} content='Map' />
                            <Dropdown.Item onClick={() => onViewSelect('gdt')} content='GDT Mode' />
                            <Dropdown.Item onClick={() => onViewSelect('graph')} content='Graph Mode' />
                            <Dropdown.Item onClick={() => onViewSelect('timeline')} content='Time Line' />
                            <Dropdown.Item onClick={() => onViewSelect('list')} content='Flight List' />
                        </Dropdown.Menu>
                    </Dropdown>

                    <Menu.Item disabled={!airport || !doesSessionExist} content='GDT Setup' onClick={() => setShowGDTModal(true)} />

                    <Dropdown disabled item text='Alerts' style={{ zIndex: '500' }}>
                        <Dropdown.Menu>

                            <Dropdown.Item content='Acknowledge All Alerts' />

                        </Dropdown.Menu>
                    </Dropdown>

                    <Dropdown item text='TFMS Tools' style={{ zIndex: '500' }}>
                        <Dropdown.Menu>

                            <Dropdown item text='EDCT'>
                                <Dropdown.Menu>

                                    <Dropdown.Item content='EDCT Check' onClick={() => setShowEDCTModal(true)} />
                                    <Dropdown.Item disabled={!airport} content='EDCT List' onClick={() => setShowFlightListModal(true)} />
                                    <Dropdown.Item disabled={true} content='EDCT Purge' />

                                </Dropdown.Menu>
                            </Dropdown>

                            <Dropdown.Item content='Weather Request' onClick={() => setShowWeatherModal(true)} />

                            <Dropdown disabled={!airport || !doesSessionExist} item text='ADL AAR'>
                                <Dropdown.Menu>

                                    <Dropdown.Item content='Modify AAR' onClick={() => setShowModifyModal(true)} />
                                    <Dropdown.Item content='Reset AAR' onClick={() => setShowResetModal(true)} />

                                </Dropdown.Menu>
                            </Dropdown>

                        </Dropdown.Menu>
                    </Dropdown>

                    <Menu.Menu position='right'>
                        <Button icon='close' color='red' floated='right' onClick={() => onRemove(airport)} />
                    </Menu.Menu>

                </Menu>

            </Segment>

        </>


    )

}

function DataSet({ dataset, onRemove }) {

    const { data: aar } = useAAR(dataset);
    const { data: aad } = useADL(dataset);
    const { data: gdp } = useAllGDP();
    const { data: airports } = useFacilities();

    const [arrivalRate, setArrivalRate] = useState(0);
    const [view, setView] = useState('graph');

    useEffect(() => {

        if (!aar) return;

        setArrivalRate(aar.paar ? aar.paar : aar.aar);

    }, [aar]);

    return (

        <>

            <DatasetNavBar airport={dataset} airports={airports} onViewSelect={setView} onRemove={(value) => onRemove(value)} />
            {
                aad && view ?
                    {
                        'gdt': <GDT airport={dataset} gdp={gdp} aad={aad} aar={arrivalRate} airports={airports} />,
                        'map': <GDTMapContainer />,
                        'graph': <>
                            <AADC airport={dataset} aar={arrivalRate} aad={aad} showLegends={false} gdp={gdp} />
                            <AADC airport={dataset} aar={arrivalRate} aad={aad} showLegends={true} gdp={gdp} view='arrival fix' />
                        </>,
                        'timeline': <FSM airport={dataset} aar={arrivalRate} aad={aad} />,
                        'list': <FlightList aad={aad.traffic} airport={dataset} />,
                    }[view] || null
                    : null
            }

        </>

    )


}

export default function ControlPanel() {

    const { data: airports } = useFacilities();

    const [airportOptions, setAirportOptions] = useState([]);
    const [panes, setPanes] = useState([]);

    let allAirports = useRef();

    const onDataRemove = (value) => {

        setPanes(prevState => _.reject(prevState, { airport: value }));

        let airport = _.find(airports, { icao: value });
        if (!_.isNil(airport)) {

            setAirportOptions(prevState => _.sortBy(_.uniq([
                ...prevState,
                {
                    key: airport.icao,
                    text: airport.iata,
                    value: airport.icao
                }
            ], 'key'), 'text'));

        }

    }

    const onDataSelect = (value) => {

        setPanes(prevState => [
            ...prevState,
            ...[{
                airport: value.value,
                menuItem: value.text,
                render: () => <Tab.Pane className='dataset'><DataSet dataset={value.value} onRemove={(value) => onDataRemove(value)} /></Tab.Pane>
            }]
        ]);

        setAirportOptions(prevState => _.reject(prevState, { value: value.value }));

    };

    useEffect(() => {

        if (!airports) return;

        let arr = [];
        for (let airport of airports) {

            arr.push({
                key: airport.icao,
                text: airport.iata,
                value: airport.icao
            });

        }

        allAirports.current = _.sortBy(arr, 'text');
        setAirportOptions(allAirports.current);

    }, [airports]);

    return (
        <Container style={{ display: 'flex', flexDirection: 'column', flex: '1 1' }}>

            <div className='borderraised'>

                <NavBar onDataSelect={onDataSelect} airportOptions={airportOptions} />

                <Suspense fallback={<Loader active />}>
                    <Tab menu={{ attached: false, tabular: false }} panes={panes} />
                </Suspense>

            </div>

        </Container>
    )

}