import { useQuery, useMutation } from 'react-query';
import { queryClient } from '../App';
import { api, publicapi } from './axios';

export const useFacilities = () => {
    return useQuery('getTMUFacilities',
    async () => {
        try {

            const { data } = await publicapi.get('/tmu/facilities');
            return data;

        } catch (err) {
            return;
        }
    },
    { refetchOnWindowFocus: false, staleTime: Infinity });
}

export const useAddFacility = () => {
    return useMutation(
        async (value) => {
            try {
                await api.post(`/tmu/facilities`, value);
            } catch (err) { return; }
        }, {
        onSuccess: () => {
            queryClient.invalidateQueries(['getTMUFacilities'], { refetchActive: true, refetchInactive: true })
        }
    }
    )
}

export const useUpdateFacility = () => {
    return useMutation(
        async (value) => {
            try {
                await api.put(`/tmu/facilities/${value.id}`, value);
            } catch (err) { return; }
        }, {
        onSuccess: () => {
            queryClient.invalidateQueries(['getTMUFacilities'], { refetchActive: true, refetchInactive: true })
        }
    }
    )
}

export const useDeleteFacility = () => {
    return useMutation(
        async (value) => {
            try {
                await api.delete(`/tmu/facilities/${value}`);
            } catch (err) { return; }
        }, {
        onSuccess: () => {
            queryClient.invalidateQueries(['getTMUFacilities'], { refetchActive: true, refetchInactive: true })
        }
    }
    )
}

export const useADL = (airport) => {
    return useQuery(['getAirportADL', airport],
        async () => {
            try {

                if (!airport) return;

                const { data } = await publicapi.get(`/aad/airport/${airport}`);
                return data;

            } catch (err) {
                return;
            }
        },
        { enabled: !!airport, refetchInterval: .25 * 60 * 1000, staleTime: 2 * 60 * 1000, refetchOnWindowFocus: true });
}

export const useAirborneADL = () => {
    return useQuery('getAirborneADL',
    async () => {
        try {

            const { data } = await publicapi.get('/aad/traffic/airborne');
            return data;

        } catch (err) {
            return;
        }
    },
    { refetchInterval: .25 * 60 * 1000, staleTime: 2 * 60 * 1000, refetchOnWindowFocus: true });
}

export const useActiveADL = () => {
    return useQuery('getActiveADL',
    async () => {
        try {

            const { data } = await publicapi.get('/aad/traffic/active');
            return data;

        } catch (err) {
            return;
        }
    },
    { refetchInterval: .25 * 60 * 1000, staleTime: 2 * 60 * 1000, refetchOnWindowFocus: true });
}

export const useSectorAlerts = () => {
    return useQuery('getSectorAlerts',
    async () => {
        try {

            const { data } = await publicapi.get(`/aad/sectoralerts`);
            return data;

        } catch (err) {
            return;
        }
    },
    { refetchInterval: 1 * 60 * 1000, staleTime: 2 * 60 * 1000, refetchOnWindowFocus: true });
}

export const useAAR = (airport) => {
    return useQuery(['getAAR', airport],
        async () => {
            try {

                if (!airport) return;

                const { data } = await publicapi.get(`/aar/${airport}`);
                return data;

            } catch (err) {
                return;
            }
        },
        { enabled: !!airport, refetchInterval: 2 * 60 * 1000, staleTime: 5 * 60 * 1000, refetchOnWindowFocus: false });
}

export const useSetAAR = () => {
    return useMutation(
        async (data) => {
            try {
                await api.post(`/aar`, data);
            } catch (err) { return; }
        }, {
        onSuccess: () => {
            queryClient.invalidateQueries(['getAAR'], { refetchActive: true, refetchInactive: true })
        }
    }
    )
}

export const useFlightData = (callsign, origin, destination) => {
    return useQuery(['getFlightData', { callsign, origin, destination }],
        async () => {
            try {

                const { data } = await publicapi.get(`/aad/flight/${callsign}/${origin}/${destination}`);
                return data;
            } catch (err) {
                return;
            }
        },
        { enabled: false, refetchOnWindowFocus: false, staleTime: Infinity });
}

export const useAllGDP = () => {
    return useQuery('getAllGDPs',
        async () => {
            try {

                const { data } = await publicapi.get('/tmu/gdp');
                return data;

            } catch (err) {
                return;
            }
        },
        { enabled: true, refetchInterval: 1 * 60 * 1000, staleTime: 3 * 60 * 1000, refetchOnWindowFocus: false })
}

export const useSubmitGDP = () => {
    return useMutation(
        async (data) => {
            try {
                await api.post(`/tmu/gdp`, data);
            } catch (err) {
                console.log(err);
                return;
            }
        }, {
        onSuccess: () => {
            queryClient.invalidateQueries(['getAllGDPs'], { refetchActive: true, refetchInactive: true })
        }
    }
    )
}

export const usePurgeGDP = () => {
    return useMutation(
        async (data) => {
            try {
                await api.delete(`/tmu/gdp`, {data: data });
            } catch (err) {
                // console.log(err);
                return;
            }
        }, {
        onSuccess: () => {
            queryClient.invalidateQueries(['getAllGDPs'], { refetchActive: true, refetchInactive: true })
        }
    }
    )    
}

export const useApplyModel = () => {
    return useMutation(
        async (data) => {
            try {
                await api.post(`/tmu/model`, data);
            } catch (err) { return; }
        }
    )
}

export const useFix = (fix) => {
    return useQuery(['getFix', fix],
        async () => {
            try {

                if (!fix) return;

                const { data } = await publicapi.get(`/airac/fix/${fix}`);
                return data;

            } catch (err) {
                return;
            }
        },
        { enabled: false, refetchOnWindowFocus: false });
}

export const useNavaid = (navaid) => {
    return useQuery(['getNavaid', navaid],
        async () => {
            try {

                if (!navaid) return;

                const { data } = await publicapi.get(`/airac/navaid/${navaid}`);
                return data;

            } catch (err) {
                return;
            }
        },
        { enabled: false, refetchOnWindowFocus: false });
}

export const useAirway = (airway) => {
    return useQuery(['getAirway', airway],
        async () => {
            try {

                if (!airway) return;

                const { data } = await publicapi.get(`/airac/airway/${airway}`);
                return data;

            } catch (err) {
                return;
            }
        },
        { enabled: false, refetchOnWindowFocus: false });
}

export const useAirport = (airport) => {
    return useQuery(['getAirport', airport],
        async () => {
            try {

                if (!airport) return;

                const { data } = await publicapi.get(`/airac/airport/${airport}`);
                return data;

            } catch (err) {
                return;
            }
        },
        { enabled: false, refetchOnWindowFocus: false });
}

export const useATC = () => {
    return useQuery(['getATC'],
    async () => {
        try {

            const { data } = await publicapi.get(`/controller`);
            return data;

        } catch (err) {
            return;
        }
    },
    { enabled: true, refetchInterval: 1 * 60 * 1000, staleTime: 3 * 60 * 1000, refetchOnWindowFocus: true });
}