import { useQuery } from 'react-query';
import { publicapi } from './axios';

export const useMETAR = (code) => {
    return useQuery(['getMETAR', code],
        async () => {
            try {
                const { data } = await publicapi.get(`/weather/metar/${code}`);
                return data;
            } catch (err) { return; }
        },
        { enabled: false });
}

export const useTAF = (code) => {
    return useQuery(['getTAF', code],
        async () => {
            try {
                const { data } = await publicapi.get(`/weather/taf/${code}`);
                return data;
            } catch (err) { return; }
        },
        { enabled: false });
}

export const useTCF = () => {
    return useQuery(['getTCF'],
        async () => {
            try {
                const { data } = await publicapi.get(`/weather/tcf`);
                return data;
            } catch (err) { return; }
        },
        { enabled: true, refetchOnWindowFocus: false });
}

export const useRVR = (code) => {
    return useQuery(['getRVR', code],
    async () => {
        try {
            const { data } = await publicapi.get(`/weather/rvr/${code}`);
            return data;
        } catch (err) { return; }
    },
    { enabled: false });
}