import React, { useState } from 'react';
import { Container, Header } from 'semantic-ui-react';
import _ from 'lodash';
import * as turf from '@turf/turf';
import ReactMapGL, { MapContext, Source, Layer } from 'react-map-gl';
import { Airports, CountryBoundaries, SectorFeature } from './TSDComponents';

import { useATC, useAllGDP, useFacilities } from './api/tfms';
import { COUNTRY_SOURCE_ID, ARTCC_SOURCE_ID, LABEL_SOURCE_ID } from './config';

const DelayPrograms = () => {

    const { data: airports } = useFacilities();
    const { data: delayprograms } = useAllGDP();

    return (

        _.isArray(delayprograms) && delayprograms.length > 0 ?
            _.map(_.filter(delayprograms, { scope: { name: 'distance' } }), dp => {

                let dpAirport = _.find(airports, { icao: dp.airport });

                if (dpAirport) {

                    let circle = turf.circle([dpAirport.longitude, dpAirport.latitude], dp.scope.parameter, { units: 'nauticalmiles' });
                    let line = turf.lineString(...circle.geometry.coordinates);

                    return <Source type='geojson' data={line}>
                        <Layer type='line' paint={{
                            "line-color": '#ff0000'
                        }} />
                    </Source>

                }

                return null;

            }) : null

    )

}

export const GDTMap = ({ height = 80 }) => {

    const { data: atc } = useATC();
    const { data: delayprograms } = useAllGDP();

    function airportColor(airport) {

        let color = '#00FE00';

        let isDPAirport, isDPScope = false;

        isDPAirport = _.find(delayprograms, { airport: airport.icao });
        if (_.isNil(isDPAirport)) {

            isDPScope = _.some(delayprograms, delayprogram => {

                let dpAirport = _.find(delayprograms, { airport: airport.icao });
                if (dpAirport) return turf.distance(airport.latitude, airport.longitude, dpAirport.latitude, dpAirport.longitude, 'N') <= delayprogram.scope.parameter;
                return false;

            });

        }

        if (isDPAirport || isDPScope) color = '#ff0000';

        return color;

    }

    // function delayColor() {

    //     const isDelayed = [
    //         'in',
    //         ['get', 'artcc'],
    //         [
    //             'literal',
    //             _.filter(delayprograms, { scope: { name: 'tier' } })
    //         ]
    //     ];

    //     const delayColor = '#FF0000';

    //     return [
    //         'case',
    //         isDelayed, delayColor,
    //         'transparent'
    //     ];

    // }

    function sectorColor() {

        const isOnline = [
            'in',
            ['get', 'callsign'],
            [
                'literal',
                _.map(_.filter(atc, c => _.endsWith(c.callsign, '_CTR')), atc => {
                    return _.split(atc.callsign, '_')[0];
                })
            ]
        ];

        const onlineColor = '#FFFFFF';
        const otherColor = '#0200EA';

        return [
            'case',
            isOnline, onlineColor,
            otherColor
        ];

    }

    const [viewport, setViewport] = useState({
        latitude: 37.5,
        longitude: -96.0,
        zoom: 3.15,
        width: '1920',
        height: '1080'
    });

    return (

        <MapContext.Provider>

            <ReactMapGL {...viewport} attributionControl={false} width='99%' height={`${height}vh`}
                onViewportChange={setViewport}
                mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
                scrollZoom={{ speed: 1 }}
                minZoom={1}
                maxZoom={22}
                mapStyle={process.env.REACT_APP_MAPBOX_URL}
            >

                <Layer type='background' paint={{ "background-color": '#160464' }} />

                <CountryBoundaries show={true} source={COUNTRY_SOURCE_ID} style={{
                    "fill-color": 'black',
                    "fill-outline-color": '#545357'
                }} />

                <DelayPrograms />
                <Airports show={true} showLabel={true} type='airports_pacing' circleColor={airportColor} labelColor='white' />

                <SectorFeature show={true} showLabel={false} filter='SECTYPE' type='artcc' textfield='LABEL' source={ARTCC_SOURCE_ID} labelSource={LABEL_SOURCE_ID} color={sectorColor} />

            </ReactMapGL>

        </MapContext.Provider>

    )

}

export const GDTMapContainer = () => {

    return (
        <Container className={'lightbordered'}>
            <Header as='h4' content='Map' />
            <GDTMap />
        </Container>
    )

}