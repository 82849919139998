import { createLocalStorageStateHook } from 'use-local-storage-state';

export const defaultCustomColorOptions = {

  'background': {
    name: 'background',
    label: 'Background',
    defaultColor: {
      hex: '#000000',
      hsl: { h: 0, s: 0, l: 0 },
      hsv: { h: 0, s: 0, v: 0 },
      rgb: { r: 0, g: 0, b: 0 }
    }
  },
  'victor_routes': {
    name: 'victor_routes',
    label: 'Victor Routes',
    defaultColor: {
      hex: "#7d26cd",
      hsl: { h: 271.2574850299401, s: 0.6872427983539094, l: 0.47647058823529415, a: 1, },
      hsv: { h: 271.2574850299401, s: 0.8146341463414634, v: 0.803921568627451, a: 1 },
      rgb: { r: 125, g: 38, b: 205, a: 1 }

    }
  },
  //NSA/SUA
  'airports_pacing': {
    name: 'airports_pacing',
    label: 'Pacing Airport',
    defaultColor: {
      hex: "#0000ff",
      hsl: { h: 240, s: 1, l: 0.5, a: 1, },
      hsv: { h: 240, s: 1, v: 1, a: 1 },
      rgb: { r: 0, g: 0, b: 255, a: 1 }
    }
  },
  'q_routes': {
    name: 'q_routes',
    label: 'Q Routes',
    defaultColor: {
      hex: "#dda0dd",
      hsl: { h: 300, s: 0.4728682170542637, l: 0.7470588235294118, a: 1 },
      hsv: { h: 300, s: 0.27601809954751133, v: 0.8666666666666667, a: 1 },
      rgb: { r: 221, g: 160, b: 221, a: 1 }
    }
  },
  'prohibited': {
    name: 'prohibited',
    label: 'Prohibited SUA',
    defaultColor: {
      hex: "#4f94cd",
      hsl: { h: 207, s: 0.56, l: 0.56, a: 1 },
      hsv: { h: 207, s: 0.615, v: 0.804, a: 1 },
      rgb: { r: 79, g: 148, b: 205, a: 1 }
    }
  },
  'airports_all': {
    name: 'airports_all',
    label: 'All Airports',
    defaultColor: {
      hex: "#0000ff",
      hsl: { h: 240, s: 1, l: 0.5, a: 1, },
      hsv: { h: 240, s: 1, v: 1, a: 1 },
      rgb: { r: 0, g: 0, b: 255, a: 1 }
    }
  },
  't_routes': {
    name: 't_routes',
    label: 'T Routes',
    defaultColor: {
      hex: "#ff3e96",
      hsl: { h: 332.64248704663214, s: 1, l: 0.6215686274509804, a: 1 },
      hsv: { h: 332.64248704663214, s: 0.7568627450980392, v: 1, a: 1 },
      rgb: { r: 255, g: 62, b: 150, a: 1 }
    }
  },
  'restricted': {
    name: 'restricted',
    label: 'Restricted SUA',
    defaultColor: {
      hex: "#4f94cd",
      hsl: { h: 207, s: 0.56, l: 0.56, a: 1 },
      hsv: { h: 207, s: 0.615, v: 0.804, a: 1 },
      rgb: { r: 79, g: 148, b: 205, a: 1 }
    }
  },
  'high_navaid': {
    name: 'high_navaid',
    label: 'High Navaid',
    defaultColor: {
      hex: "#999999",
      hsl: { h: 0, s: 0, l: 0.6, a: 1 },
      hsv: { h: 0, s: 0, v: 0.6, a: 1 },
      rgb: { r: 153, g: 153, b: 153, a: 1 }
    }
  },
  'artcc': {
    name: 'artcc',
    label: 'ARTCC',
    defaultColor: {
      hex: "#999999",
      hsl: { h: 0, s: 0, l: 0.6, a: 1 },
      hsv: { h: 0, s: 0, v: 0.6, a: 1 },
      rgb: { r: 153, g: 153, b: 153, a: 1 }
    }
  },
  'warning': {
    name: 'warning',
    label: 'Warning SUA',
    defaultColor: {
      hex: "#4f94cd",
      hsl: { h: 207, s: 0.56, l: 0.56, a: 1 },
      hsv: { h: 207, s: 0.615, v: 0.804, a: 1 },
      rgb: { r: 79, g: 148, b: 205, a: 1 }
    }
  },
  'low_navaid': {
    name: 'low_navaid',
    label: 'Low Navaid',
    defaultColor: {
      hex: "#999999",
      hsl: { h: 0, s: 0, l: 0.6, a: 1 },
      hsv: { h: 0, s: 0, v: 0.6, a: 1 },
      rgb: { r: 153, g: 153, b: 153, a: 1 }
    }
  },
  'low_sector': {
    name: 'low_sector',
    label: 'Low Sector',
    defaultColor: {
      hex: "#e0ffff",
      hsl: { h: 180, s: 1, l: 0.9392156862745098, a: 1 },
      hsv: { h: 180, s: 0.1215686274509804, v: 1, a: 1 },
      rgb: { r: 224, g: 255, b: 255, a: 1 }

    }
  },
  'grid': {
    name: 'grid',
    label: 'Lat/Lon Grid',
    defaultColor: {
      hex: "#4f94cd",
      hsl: { h: 207.14285714285717, s: 0.5575221238938054, l: 0.5568627450980392, a: 1, },
      hsv: { h: 207.14285714285717, s: 0.6146341463414634, v: 0.803921568627451, a: 1 },
      rgb: { r: 79, g: 148, b: 205, a: 1 }
    }
  },
  'terminal_navaid': {
    name: 'terminal_navaid',
    label: 'Terminal Navaid',
    defaultColor: {
      hex: "#999999",
      hsl: { h: 0, s: 0, l: 0.6, a: 1 },
      hsv: { h: 0, s: 0, v: 0.6, a: 1 },
      rgb: { r: 153, g: 153, b: 153, a: 1 }
    }
  },
  'high_sector': {
    name: 'high_sector',
    label: 'High Sector',
    defaultColor: {
      hex: "#afeeee",
      hsl: { h: 180, s: 0.6494845360824743, l: 0.8098039215686275, a: 1 },
      hsv: { h: 180, s: 0.2647058823529412, v: 0.9333333333333333, a: 1 },
      rgb: { r: 175, g: 238, b: 238, a: 1 }

    }
  },
  'other_navaid': {
    name: 'other_navaid',
    label: 'Other Navaid',
    defaultColor: {
      hex: "#999999",
      hsl: { h: 0, s: 0, l: 0.6, a: 1 },
      hsv: { h: 0, s: 0, v: 0.6, a: 1 },
      rgb: { r: 153, g: 153, b: 153, a: 1 }
    }
  },
  'superhigh_sector': {
    name: 'superhigh_sector',
    label: 'SuperHigh Sector',
    defaultColor: {
      hex: "#afeeee",
      hsl: { h: 180, s: 0.6494845360824743, l: 0.8098039215686275, a: 1 },
      hsv: { h: 180, s: 0.2647058823529412, v: 0.9333333333333333, a: 1 },
      rgb: { r: 175, g: 238, b: 238, a: 1 }

    }
  },
  'boundaries': {
    name: 'boundaries',
    label: 'Boundaries',
    defaultColor: {
      hex: "#ffffff",
      hsl: { h: 0, s: 0, l: 1, a: 1 },
      hsv: { h: 0, s: 0, v: 1, a: 1 },
      rgb: { r: 255, g: 255, b: 255, a: 1 }
    }
  },
  'departure_fix': {
    name: 'departure_fix',
    label: 'Departure Fix',
    defaultColor: {
      hex: "#00ffff",
      hsl: { h: 180, s: 1, l: 0.5, a: 1, },
      hsv: { h: 180, s: 1, v: 1, a: 1 },
      rgb: { r: 0, g: 255, b: 255, a: 1 }

    }
  },
  'oceanic_sector': {
    name: 'oceanic_sector',
    label: 'Oceanic Sector',
    defaultColor: {
      hex: "#afeeee",
      hsl: { h: 180, s: 0.6494845360824743, l: 0.8098039215686275, a: 1 },
      hsv: { h: 180, s: 0.2647058823529412, v: 0.9333333333333333, a: 1 },
      rgb: { r: 175, g: 238, b: 238, a: 1 }

    }
  },
  'arrival_fix': {
    name: 'arrival_fix',
    label: 'Arrival Fix',
    defaultColor: {
      hex: "#698b22",
      hsl: { h: 79.42857142857143, s: 0.6069364161849712, l: 0.33921568627450976, a: 1, },
      hsv: { h: 79.42857142857143, s: 0.7553956834532375, v: 0.5450980392156862, a: 1 },
      rgb: { r: 105, g: 139, b: 34, a: 1 }

    }
  },
  'tracon': {
    name: 'tracon',
    label: 'TRACON',
    defaultColor: {
      hex: "#87ceeb",
      hsl: { h: 197.4, s: 0.714285714285714, l: 0.7254901960784313, a: 1 },
      hsv: { h: 197.4, s: 0.42553191489361697, v: 0.9215686274509803, a: 1 },
      rgb: { r: 135, g: 206, b: 235, a: 1 }

    }
  },
  //Enroute Fix
  'alert': {
    name: 'alert',
    label: 'AlertAreas SUA',
    defaultColor: {
      hex: "#4f94cd",
      hsl: { h: 207, s: 0.56, l: 0.56, a: 1 },
      hsv: { h: 207, s: 0.615, v: 0.804, a: 1 },
      rgb: { r: 79, g: 148, b: 205, a: 1 }
    }
  },
  'jet_routes': {
    name: 'jet_routes',
    label: 'Jet Routes',
    defaultColor: {
      hex: "#666666",
      hsl: { h: 0, s: 0, l: 0.4, a: 1 },
      hsv: { h: 0, s: 0, v: 0.4, a: 1 },
      rgb: { r: 102, g: 102, b: 102, a: 1 }
    }
  },
  'moa': {
    name: 'moa',
    label: 'MOA/SUA',
    defaultColor: {
      hex: "#4f94cd",
      hsl: { h: 207, s: 0.56, l: 0.56, a: 1 },
      hsv: { h: 207, s: 0.615, v: 0.804, a: 1 },
      rgb: { r: 79, g: 148, b: 205, a: 1 }
    }
  }

};

export const defaultSettings = {

  'viewport': {
    latitude: 37.5,
    longitude: -96.0,
    width: '1920',
    height: '1080',
    zoom: 4,
  },

  'airports_all': {
    'overlay': false,
    'label': false,
  },
  'airports_pacing': {
    'overlay': true,
    'label': true,
  },
  'artcc': {
    'overlay': true,
    'label': true,
  },
  'high_navaid': {
    'overlay': false,
    'label': false,
  },
  'low_navaid': {
    'overlay': false,
    'label': false,
  },
  'terminal_navaid': {
    'overlay': false,
    'label': false,
  },
  'other_navaid': {
    'overlay': false,
    'label': false,
  },
  'low': {
    'overlay': false,
    'label': false,
  },
  'high': {
    'overlay': false,
    'label': false,
  },
  'superhigh': {
    'overlay': false,
    'label': false,
  },
  'oceanic': {
    'overlay': false,
    'label': false,
  },
  'departure': {
    'overlay': false,
    'label': false,
  },
  'arrival': {
    'overlay': false,
    'label': false,
  },
  'tracon': {
    'overlay': false,
    'label': false,
  },
  'j': {
    'overlay': false,
    'label': false,
  },
  'v': {
    'overlay': false,
    'label': false,
  },
  'q': {
    'overlay': false,
    'label': false,
  },
  't': {
    'overlay': false,
    'label': false,
  },
  'alert': {
    'overlay': false,
    'label': false,
  },
  'moa': {
    'overlay': false,
    'label': false,
  },
  'prohibited': {
    'overlay': false,
    'label': false,
  },
  'restricted': {
    'overlay': false,
    'label': false,
  },
  'warning': {
    'overlay': false,
    'label': false,
  },
  'boundaries': {
    'overlay': true,
    'label': false,
  },
  'grid': {
    'overlay': false,
    'label': false,
  },

  'radar_type': 'off',
  'showFCA': false,
  'showFlights': true,
  'showAlert': false,
  'showWeather': true,
  'tcf_forecast': 'off',
  'tcf_datablock': false,

  'flightsets': [],
  'flights': {
    color: { hsl: { h: 0, s: 0, l: 1 } },
    filter: [],
  },
  'rangerings': [],

  'alert_airports': [],
  'alert_sectors': [],
  'alert_fixes': [],
  'alert_online': [],

  'alert_timelimit': 2.25,

  'alert_place': '',
  'alert_type': '',
  'alert_subtype': '',

  'alert_show_in_timelimit': true,

};

export const useCustomSettings = createLocalStorageStateHook('settings', defaultSettings);
export const useCustomColors = createLocalStorageStateHook('colors', defaultCustomColorOptions);