import { useState, useEffect, useContext, useCallback } from 'react';
import _ from 'lodash';
import { Visibility, Container, Menu, Segment, Dropdown, Modal, Button, Form, Grid, Table } from 'semantic-ui-react';
import { Formik, useFormikContext, Form as FormikForm, FieldArray } from 'formik';
import ReactMapGL, { Layer, MapContext, WebMercatorViewport } from 'react-map-gl';
import { point, buffer, bbox } from '@turf/turf';
import { v4 } from 'uuid';
import { DateTime, Interval } from 'luxon';

import { nas_map } from './resources/nas_map';
import { nas_low } from "./resources/nas_low";
import { nas_high } from "./resources/nas_high";
import { nas_superhigh } from "./resources/nas_superhigh";
import { WeatherModal } from './Weather';
import { RVRModal, SelectWeatherModal, WeatherRadar, TCF } from './TSDWeatherComponents';
import { FlightCountModal, FindFlightModal, SelectFlightsModal, Flights } from './TSDFlightComponents';
import { OverlayOption, RangeRings, RangeRingOption, CustomizeColorOption, LatLonGrid, Airports, SectorFeature, CountryBoundaries, Fixes, NAVAID, Airway, SUA } from './TSDComponents';
import { mapOverlayOptions, COUNTRY_SOURCE_ID, ARTCC_SOURCE_ID, NAVAID_SOURCE_ID, AIRWAY_SOURCE_ID, SUA_SOURCE_ID, AIRPORT_SOURCE_ID, FIX_SOURCE_ID, SECTOR_SOURCE_ID, LABEL_SOURCE_ID, MAP_MODIFIER } from './config';
import { useAirborneADL, useFix, useNavaid, useAirport, useAirway, useATC, useSectorAlerts } from './api/tfms';
import { useCustomColors, useCustomSettings, defaultCustomColorOptions } from './storage';
import { AlertFeature, ExamineAlertModal, NASMonitorModal, SelectAlertsModal, SelectMonitoredElementsModal } from './TSDAlertComponents';

export function getCongestion(congestion, displayrange, sector) {

    const activeTraffic = _.filter(congestion, flight => { return flight.status === 'active' });

    const now = DateTime.utc();
    const intervals = _.range(Math.floor(now.minute / 15) * 15, Math.ceil(now.minute / 15) * 15 + displayrange * 60, 15);

    const labels = intervals.map(i => {
        return DateTime.utc().set({ minute: i, second: 0, millisecond: 0 });
    });

    return labels.map(l => {

        const thisSector = _.find(congestion, { label: sector });
        const thisTraffic = _.find(thisSector?.times, { time: l.toISO() })?.callsigns;

        const all = _.size(thisTraffic) || 0;

        return {
            label: l,
            airborneCongestion: _.size(_.filter(thisTraffic, f => {
                return _.find(activeTraffic, { callsign: f.callsign });
            })),
            allCongestion: all
        }

    });

}

function transformSettingsToArray(settings, type) {

    let mappedSettings = _.filter(_.keys(settings), (key) => {
        return settings[key][type];
    });

    return mappedSettings;

};

function transformArrayToSettings(array, type) {

    let mappedSettings = _.map(array, (value) => {
        return { [value]: { [type]: true } };
    });

    mappedSettings = _.reduce(_.filter(mappedSettings, (value) => !_.isNil(value)), (result, setting) => {
        return { ...result, ...setting };
    }, {});

    return mappedSettings;

};

function CustomizeColorsModal({ onClose, onCancel, showModal }) {

    const { values } = useFormikContext();

    return (
        <Modal closeIcon open={showModal} size='tiny'
            onClose={() => {

                onCancel();

            }}
        >

            <Modal.Header>Customize Colors</Modal.Header>

            <Modal.Content>

                <Grid columns={3}>
                    {
                        _.map(defaultCustomColorOptions, overlay => {
                            return <Grid.Column key={overlay.name}>
                                <CustomizeColorOption name={overlay.name} label={overlay.label} />
                            </Grid.Column>
                        })
                    }
                </Grid>

            </Modal.Content>

            <Modal.Actions>
                <Button content='OK' onClick={() => onClose(values)} />
                <Button content='Cancel'
                    onClick={() => {

                        onCancel();

                    }}
                />
            </Modal.Actions>

        </Modal>
    )

}

function MapOverlaysModal({ onClose, onCancel, showModal }) {

    const { values } = useFormikContext();

    return (
        <Modal onClose={() => onCancel()} open={showModal} size='mini'>

            <Modal.Header>Map Overlays</Modal.Header>

            <Modal.Content>

                <Grid columns={2}>
                    {
                        _.map(mapOverlayOptions, overlay => {
                            return <Grid.Column key={overlay.name}>
                                <OverlayOption showLabelHeader={overlay.name !== 'misc'} label={overlay.label} overlay={overlay} />
                            </Grid.Column>
                        })
                    }
                </Grid>

            </Modal.Content>

            <Modal.Actions>
                <Button content='OK' onClick={() => onClose(values)} />
                <Button content='Cancel' onClick={() => onCancel()} />
            </Modal.Actions>

        </Modal>
    )

}

function RangeRingsNavbar({ helpers, selectedRow }) {

    const { values } = useFormikContext();

    const newRangeRing = {
        id: v4(),
        show: true,
        showLabel: false,
        color: {
            hex: '#000000',
            hsl: { h: 0, s: 0, l: 0 },
            rgb: { r: 0, g: 0, b: 0 }
        },
        location: '',
        number: '5',
        distance: '20'
    };

    return (

        <Visibility>
            <Segment textAlign='left' horizontal='true' style={{ borderColor: '#666666', borderWidth: '0 0 1px 0', zIndex: '498' }}>
                <Menu compact>

                    <Dropdown item text='File' style={{ zIndex: '500' }}>
                        <Dropdown.Menu>
                            <Dropdown.Item content='Add' onClick={() => helpers.insert(values.rangerings.length, newRangeRing)} />
                            {/* <Dropdown.Item content='Copy' onClick={() => helpers.copy(selectedRow)} /> */}
                            <Dropdown.Item content='Delete' onClick={() => helpers.delete(selectedRow)} />
                            <Dropdown.Item content='Delete All' onClick={() => helpers.deleteAll()} />
                        </Dropdown.Menu>
                    </Dropdown>

                </Menu>
            </Segment>

        </Visibility>

    )

}

function RangeRingsModal({ rangerings, onClose, onSubmit, showModal }) {

    const [selectedRowIndex, setSelectedRowIndex] = useState(null);
    const [ref1, setRef1] = useState(null);

    const onRefChange = useCallback(node => {
        if (_.isNil(ref1)) setRef1(node);
    }, [ref1]);

    return (

        <Formik
            enableReinitialize={true}
            initialValues={{ rangerings: rangerings }}
            onSubmit={values => {
                onSubmit(values.rangerings);
                onClose();
            }}
        >
            {({ resetForm, submitForm }) => (

                <Modal
                    open={showModal}
                    onClose={() => {
                        resetForm();
                        onClose();
                    }}
                >

                    <Modal.Header>Select Range Ring</Modal.Header>

                    <FormikForm>
                        <Modal.Content>

                            <RangeRingsNavbar helpers={ref1} selectedRow={selectedRowIndex} />
                            <Container className='borderraised'>

                                <div>
                                    <Table unstackable celled compact textAlign='center' className='greyTable'>

                                        <Table.Header>
                                            <Table.Row>
                                                <Table.HeaderCell width={1} />
                                                <Table.HeaderCell><h4>Show</h4></Table.HeaderCell>
                                                <Table.HeaderCell><h4>Label</h4></Table.HeaderCell>
                                                <Table.HeaderCell><h4>Color</h4></Table.HeaderCell>
                                                <Table.HeaderCell><h4>Location</h4></Table.HeaderCell>
                                                <Table.HeaderCell><h4>Number</h4></Table.HeaderCell>
                                                <Table.HeaderCell><h4>Distance</h4></Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>

                                        <FieldArray name='rangerings'>
                                            {({ move, insert, remove, form }) => {
                                                return (
                                                    <RangeRingOption
                                                        arrayHelpers={{ insert, remove, move, }}
                                                        form={form}
                                                        helpers={(values) => { onRefChange(values) }}
                                                        selectedRow={selectedRowIndex}
                                                        setSelectedRow={(i) => setSelectedRowIndex(i)}
                                                    />
                                                )
                                            }}
                                        </FieldArray>

                                    </Table>
                                </div>

                            </Container>

                        </Modal.Content>

                        <Modal.Actions style={{ paddingBottom: '10px' }}>
                            <Button content='OK' onClick={() => submitForm()} />
                            <Button content='Cancel' onClick={() => {
                                resetForm();
                                onClose();
                            }} />
                        </Modal.Actions>
                    </FormikForm>

                </Modal>

            )}
        </Formik>

    )

}

function MapMenuModal({ onClick, onClose, showModal }) {

    const [query, setQuery] = useState('');
    const [zoom, setZoom] = useState(null);
    const [startSearch, setStartSearch] = useState(false);

    const { data: aad } = useAirborneADL();
    const { refetch: searchFix, data: fix } = useFix(query);
    const { refetch: searchNavaid, data: navaid } = useNavaid(query);
    const { refetch: searchAirway, data: airway } = useAirway(query);
    const { refetch: searchAirport, data: airport } = useAirport(query);

    useEffect(() => {

        async function search() {

            if (query.length === 0) return null;

            if (query.length <= 3) {

                await searchNavaid();
                if (navaid) return navaid;

            } else if (query.length === 4) {

                await searchAirport();
                if (airport) return airport;

            } else if (query.length === 5) {

                await searchFix();
                if (fix) return fix;

            }

            await searchAirway();
            if (airway) return airway;
            else return _.find(aad, { callsign: query.toUpperCase() });

        }

        let isMounted = true;

        if (isMounted && startSearch) {
            search().then(result => {

                onClick(result, zoom);

                setStartSearch(false);
                onClose();

                setQuery('');
                setZoom(null);

            });
        }

        return () => isMounted = false;

    }, [query, aad, airport, airway, fix, navaid, zoom, startSearch, searchAirport, searchAirway, searchFix, searchNavaid, onClick, onClose]);

    return (
        <Modal onClose={() => onClose()} open={showModal} size='mini'>

            <Modal.Header>Move/Zoom</Modal.Header>

            <Modal.Content>
                <Form>
                    <Form.Input inline label='Location:' onChange={(_, { value }) => setQuery(value)} />
                    <Form.Input inline label='Zoom Scale:' onChange={((_, { value }) => setZoom(value))} />
                </Form>
            </Modal.Content>

            <Modal.Actions>
                <Button content='OK' disabled={startSearch} onClick={() => setStartSearch(true)} />
                <Button content='Cancel' onClick={onClose} />
            </Modal.Actions>

        </Modal>
    )

}

function NavBar({ onMoveMap }) {

    const [showCustomizeColorsModal, setShowCustomizeColorsModal] = useState(false);
    const [showMapMenuModal, setShowMapMenuModal] = useState(false);
    const [showRangeRingsModal, setShowRangeRingsModal] = useState(false);
    const [showMapOverlaysModal, setShowMapOverlaysModal] = useState(false);

    const [showSelectFlightsModal, setShowSelectFlightsModal] = useState(false);
    const [showFlightCountModal, setShowFlightCountModal] = useState(false);
    const [showFindFlightsModal, setShowFindFlightsModal] = useState(false);

    const [showSelectAlertsModal, setShowSelectAlertsModal] = useState(false);
    const [showExamineAlertModal, setShowExamineAlertModal] = useState(false);
    const [showSelectMonitoredElementsModal, setShowSelectMonitoredElementsModal] = useState(false);
    const [showNASMonitorModal, setShowNASMonitorModal] = useState(false);

    const [showSelectWeatherModal, setSelectWeatherModal] = useState(false);
    const [showWeatherModal, setShowWeatherModal] = useState(false);
    const [showRVRModal, setShowRVRModal] = useState(false);

    const map = useContext(MapContext);
    // eslint-disable-next-line no-unused-vars
    const [colors, setColors, { removeItem: resetColors }] = useCustomColors();
    const [settings, setSettings, { removeItem: resetSettings }] = useCustomSettings();

    function moveMap(location, zoomLevel) {

        let latitude = location?.latitude ? location?.latitude : map.viewport.latitude;
        let longitude = location?.longitude ? location?.longitude : map.viewport.longitude;

        let vp;

        if (!_.isNil(zoomLevel) && _.isNumber(+zoomLevel)) {

            const box = bbox(buffer(point([longitude, latitude]), +zoomLevel, { units: 'nauticalmiles' }));

            vp = new WebMercatorViewport(map.viewport).fitBounds([
                [box[0], box[1]],
                [box[2], box[3]]
            ]);

        } else vp = new WebMercatorViewport({ ...map.viewport, latitude, longitude });

        if (!_.isNil(vp)) onMoveMap(vp.longitude, vp.latitude, vp.zoom);

    }

    function updateRangeRings(values) {

        setShowRangeRingsModal(false);
        setSettings({ ...settings, rangerings: values });

    }

    function updateFlightSets(values) {

        setShowFindFlightsModal(false);
        setShowSelectFlightsModal(false);
        setSettings({ ...settings, flightsets: values });

    }

    function updateFindFlights(values) {

        setShowFindFlightsModal(false);
        setSettings({ ...settings, flights: values.query });

        if (!_.isNil(values?.flights) && values?.flights.length === 1) moveMap(values.flights[0].position, null);

    }

    function updateAlerts(values) {

        setShowSelectAlertsModal(false);
        setSettings({
            ...settings,
            alert_airports: values.airports,
            alert_sectors: values.sectors,
            alert_fixes: values.fixes,
            alert_online: values.online,
            alert_timelimit: values.timelimit,
        });

    }

    function updateExamineAlerts(values) {

        setShowExamineAlertModal(false);
        setSettings({
            ...settings,
            alert_place: values.place,
            alert_type: values.type,
            alert_subtype: values.subtype,
        });

    }

    function updateMonitoredElements(values) {

        setShowSelectMonitoredElementsModal(false);
        setSettings({
            ...settings,
            alert_sectors: values
        });

    }

    function updateNASMonitor(values) {

        const sectors = [];

        for (const sector of values.sectors) {

            if (sector === 'high') {

                sectors.push(..._.map(nas_high, s => { return `${s.properties.artcc}${s.properties.sector}`.toUpperCase() }));

            } else if (sector === 'superhigh') {

                sectors.push(..._.map(nas_superhigh, s => { return `${s.properties.artcc}${s.properties.sector}`.toUpperCase() }));

            } else if (sector === 'low') {

                sectors.push(..._.map(nas_low, s => { return `${s.properties.artcc}${s.properties.sector}`.toUpperCase() }));

            }

        }

        if (
            !_.isNil(values.place) && !_.isNil(values.type) && !_.isNil(values.subtype)
        ) {

            sectors.push(`${values.place}`.toUpperCase());

        }

        // return sectors;
        return _.uniq(sectors);

    }

    function initializeMap() {

        resetColors();
        resetSettings();

        let vp = new WebMercatorViewport(settings.viewport);
        onMoveMap(vp.longitude, vp.latitude, vp.zoom);

    }

    useEffect(() => {

        if (_.isNil(colors?.background) || _.isNil(colors?.background?.defaultColor)) return;
        map.container.style.backgroundColor = colors.background.defaultColor.hex;

    }, [colors?.background, map]);

    return (

        <Formik
            initialValues={{
                ...colors,
                overlays: transformSettingsToArray(settings, 'overlay'),
                labels: transformSettingsToArray(settings, 'label'),
                radar_type: settings.radar_type,
                showWeather: settings.showWeather,
                tcf_forecast: settings.tcf_forecast,
                tcf_datablock: settings.tcf_datablock,
                airports: settings.alert_airports,
                sectors: settings.alert_sectors,
                fixes: settings.alert_fixes,
                online: settings.alert_online,

                place: settings.alert_place,
                type: settings.alert_type,
                subtype: settings.alert_subtype,
                timelimit: Number(settings.alert_timelimit),

                show_in_timelimit: settings.alert_show_in_timelimit
            }}
        >
            {({ resetForm, values }) => (
                <Visibility>

                    <RVRModal
                        showModal={showRVRModal}
                        onClose={() => setShowRVRModal(false)}
                    />
                    <MapMenuModal
                        showModal={showMapMenuModal}
                        onClose={() => setShowMapMenuModal(false)}
                        onClick={(location, zoom) => moveMap(location, zoom)}
                    />
                    <WeatherModal
                        showModal={showWeatherModal}
                        onClose={() => setShowWeatherModal(false)}
                    />

                    <CustomizeColorsModal
                        showModal={showCustomizeColorsModal}
                        onClose={(values) => {

                            setColors(values);
                            setShowCustomizeColorsModal(false);

                        }}
                        onCancel={() => {

                            resetForm();
                            setShowCustomizeColorsModal(false);

                        }}
                    />

                    <MapOverlaysModal
                        showModal={showMapOverlaysModal}
                        onClose={(values) => {

                            let newSettings = { ...settings };
                            const newOverlays = transformArrayToSettings(values.overlays, 'overlay');
                            const newLabels = transformArrayToSettings(values.labels, 'label');

                            _.forEach(newSettings, (_value, key) => {

                                if (_.has(newSettings[key], 'overlay') || _.has(newSettings[key], 'label')) {

                                    if (_.has(newOverlays, key)) newSettings[key] = { ...newSettings[key], overlay: true };
                                    else newSettings[key] = { ...newSettings[key], overlay: false };

                                    if (_.has(newLabels, key)) newSettings[key] = { ...newSettings[key], label: true };
                                    else newSettings[key] = { ...newSettings[key], label: false };

                                }

                            });

                            setSettings(newSettings);
                            setShowMapOverlaysModal(false);

                        }}
                        onCancel={() => {

                            resetForm();
                            setShowMapOverlaysModal(false)

                        }}
                    />
                    <SelectWeatherModal
                        showModal={showSelectWeatherModal}
                        onClose={(values) => {

                            setSelectWeatherModal(false);
                            setSettings({
                                ...settings,
                                radar_type: values.radar_type,
                                showWeather: values.showWeather,
                                tcf_forecast: values.tcf_forecast,
                                tcf_datablock: values.tcf_datablock,
                            });

                        }}
                        onCancel={() => {

                            resetForm();
                            setSelectWeatherModal(false)

                        }}
                    />

                    <RangeRingsModal rangerings={settings.rangerings} onClose={() => setShowRangeRingsModal(false)} onSubmit={(values) => updateRangeRings(values)} showModal={showRangeRingsModal} />
                    <FindFlightModal entries={settings.flights} onSubmit={(values) => updateFindFlights(values)} onClose={() => setShowFindFlightsModal(false)} showModal={showFindFlightsModal} />
                    <SelectFlightsModal flightsets={settings.flightsets} onClose={() => setShowSelectFlightsModal(false)} onSubmit={(values) => updateFlightSets(values)} showModal={showSelectFlightsModal} />
                    <FlightCountModal flightsets={settings.flightsets} onCancel={() => setShowFlightCountModal(false)} showModal={showFlightCountModal} />

                    <SelectAlertsModal
                        showModal={showSelectAlertsModal}
                        onClose={(values) => updateAlerts(values)}
                        onCancel={() => setShowSelectAlertsModal(false)}
                    />
                    <ExamineAlertModal
                        showModal={showExamineAlertModal}
                        onClose={(values) => updateExamineAlerts(values)}
                        onCancel={() => setShowExamineAlertModal(false)}
                    />
                    <SelectMonitoredElementsModal
                        showModal={showSelectMonitoredElementsModal}
                        onClose={(values) => {

                            if (!_.isNil(values)) updateMonitoredElements(values);

                        }}
                        onCancel={() => setShowSelectMonitoredElementsModal(false)}
                    />
                    <NASMonitorModal
                        showModal={showNASMonitorModal}
                        onClose={() => setShowNASMonitorModal(false)}
                        onSelect={() => setShowSelectMonitoredElementsModal(true)}
                        timelimit={values.timelimit}
                        artcc='Custom'
                        sectors={updateNASMonitor(values)}
                    />

                    <Segment textAlign='left' horizontal='true' style={{ borderColor: '#666666', borderWidth: '0 0 1px 0', zIndex: '498' }}>
                        <Menu compact>

                            <Dropdown item text='Display' style={{ zIndex: '500' }}>
                                <Dropdown.Menu>
                                    <Dropdown.Item content='Initialize' onClick={() => initializeMap()} />
                                    <Dropdown.Item content='Change Colors' onClick={() => setShowCustomizeColorsModal(true)} />
                                </Dropdown.Menu>
                            </Dropdown>

                            <Dropdown item text='Maps' style={{ zIndex: '500' }}>
                                <Dropdown.Menu>
                                    <Dropdown.Item content='Move/Zoom' onClick={() => setShowMapMenuModal(true)} />
                                    <Dropdown.Item content='Range Rings' onClick={() => setShowRangeRingsModal(true)} />
                                    <Dropdown.Item content='Overlays' onClick={() => setShowMapOverlaysModal(true)} />
                                </Dropdown.Menu>
                            </Dropdown>

                            <Dropdown item text='Flights' style={{ zIndex: '500' }}>
                                <Dropdown.Menu>
                                    <Dropdown.Item content={settings['showFlights'] ? `Hide Flights` : `Show Flights`} onClick={() => setSettings({
                                        ...settings,
                                        showFlights: !settings['showFlights']
                                    })} />
                                    <Dropdown.Item content='Select Flights' onClick={() => setShowSelectFlightsModal(true)} />
                                    <Dropdown.Item content='Hide/Show Flight Count' onClick={() => setShowFlightCountModal(true)} />
                                    <Dropdown.Item content='Find Flight' onClick={() => setShowFindFlightsModal(true)} />
                                </Dropdown.Menu>
                            </Dropdown>

                            <Dropdown item text='Alerts' style={{ zIndex: '500' }}>
                                <Dropdown.Menu>
                                    <Dropdown.Item content={settings['showAlert'] ? `Hide Alerts` : `Show Alerts`} onClick={() => setSettings({
                                        ...settings,
                                        showAlert: !settings['showAlert']
                                    })} />
                                    <Dropdown.Item content='Select Alerts' onClick={() => setShowSelectAlertsModal(true)} />
                                    <Dropdown.Item content='Examine Alerts' onClick={() => setShowExamineAlertModal(true)} />
                                    <Dropdown.Item content='Show NAS Monitor' onClick={() => setShowNASMonitorModal(true)} />
                                </Dropdown.Menu>
                            </Dropdown>

                            <Dropdown item text='Weather' style={{ zIndex: '500' }}>
                                <Dropdown.Menu>
                                    <Dropdown.Item content={settings['showWeather'] ? `Hide Weather` : `Show Weather`} onClick={() => setSettings({
                                        ...settings,
                                        showWeather: !settings['showWeather']
                                    })} />
                                    <Dropdown.Item content='Select Weather' onClick={() => setSelectWeatherModal(true)} />
                                    <Dropdown.Item content='WX Report' onClick={() => setShowWeatherModal(true)} />
                                    <Dropdown.Item content='RVR Report' onClick={() => setShowRVRModal(true)} />
                                </Dropdown.Menu>
                            </Dropdown>

                            <Dropdown disabled item text='FEA/FCA' style={{ zIndex: '500' }}>
                                <Dropdown.Menu>
                                    <Dropdown.Item content={settings['showFCA'] ? `Hide FEA/FCAs` : `Show FEA/FCAs`} onClick={() => setSettings({
                                        ...settings,
                                        showFCA: !settings['showFCA']
                                    })} />
                                    <Dropdown.Item disabled content='Select FEA/FCA' />
                                    <Dropdown.Item disabled content='Create FEA/FCA' />
                                    <Dropdown.Item disabled content='Flight Search' />
                                </Dropdown.Menu>
                            </Dropdown>

                            {/* <Dropdown item text='Tools' style={{ zIndex: '500' }}>
                                <Dropdown.Menu>
                                    <Dropdown.Item content='EDCT Commands' />
                                    <Dropdown.Item content='Report Manager' />
                                    <Dropdown.Item content='Version' />
                                </Dropdown.Menu>
                            </Dropdown> */}

                        </Menu>
                    </Segment>

                </Visibility>
            )}
        </Formik>

    )

}

export function TSDMap() {

    const [colors] = useCustomColors();
    const [settings, setSettings] = useCustomSettings();
    const { data: atc } = useATC();
    const { data: alerts } = useSectorAlerts();

    const ONLINE_ALERT = 'online_alert';
    const SUPERHIGH_ALERT = `superhigh_alert`;
    const HIGH_ALERT = `high_alert`;
    const LOW_ALERT = `low_alert`;
    const ALERT_YELLOW = `_yellow`;
    const ALERT_RED = `_red`;

    const YELLOW = '#FDFD01';
    const RED = '#FF0000';
    const BLUE = '#0000FF';

    const calculateCongestion = (congestion) => {

        const timeframe = Interval.fromDateTimes(
            DateTime.utc(),
            DateTime.utc().plus({ hours: settings.alert_timelimit })
        );

        const sectors = _.filter(congestion, (sector) => {
            return _.find(sector.times, time => {

                const thisTime = DateTime.fromISO(time.time);
                return timeframe.contains(thisTime);

            });
        });

        const c = _.map(sectors, sector => {

            const cong = getCongestion(congestion, settings.alert_timelimit, sector.label);

            const maxAirborneCongestion = _.maxBy(cong, 'airborneCongestion');
            const maxAllCongestion = _.maxBy(cong, 'allCongestion');

            return {
                sector: sector.label,
                airborneCongestion: maxAirborneCongestion.airborneCongestion,
                allCongestion: maxAllCongestion.allCongestion
            }

        });

        return c;

    };

    const filterOnline = (atc) => {

        let centers = _.filter(atc, controller => {

            let spl = _.split(controller.callsign, '_');
            return _.includes(['CTR', 'FSS'], spl[spl.length - 1]);

        });

        let suffixes = _.map(centers, controller => {

            let spl = _.split(controller.callsign, '_');
            return spl[spl.length - 1];

        });

        let prefixes = _.map(centers, controller => {

            let spl = _.split(controller.callsign, '_');
            return spl[0];

        });

        return [
            'all',
            [
                'in',
                ['string', 'online_all'],
                ['literal', settings?.alert_online]
            ],
            [
                'in',
                ['get', 'CALLSIGN'],
                ['literal', prefixes]
            ],
            [
                'in',
                ['get', 'SUFFIX'],
                ['literal', suffixes]
            ]
        ];

    }

    const findOnline = (atc) => {

        const centers = _.filter(atc, controller => {

            let spl = _.split(controller.callsign, '_');
            return spl[spl.length - 1] === 'CTR';

        });

        const suffixes = _.map(centers, controller => {

            let spl = _.split(controller.callsign, '_');
            return spl[spl.length - 1];

        });

        const prefixes = _.map(centers, controller => {

            let spl = _.split(controller.callsign, '_');

            return spl[0];

        });

        return [
            'all',
            [
                'in',
                ['get', 'CALLSIGN'],
                // ['literal', prefixes]
                _.join(prefixes, ',')
            ],
            [
                'in',
                ['get', 'SUFFIX'],
                ['literal', suffixes]
            ]
        ];

    }

    const onlineFill = (atc) => {

        return [
            'case',
            findOnline(atc), ONLINE_ALERT, ''
        ];

    }

    const onlineTextAndLine = (atc) => {

        return [
            'case',
            findOnline(atc), BLUE, 'transparent'
        ]

    }

    const filterAlerts = (congestion, property) => {

        const sectors = _.map(congestion, 'sector');

        return [
            'all',
            [
                'any',
                [
                    'in',
                    ['get', 'SECTYPE'],
                    ['literal', settings?.alert_sectors]
                ],
                [
                    'any',
                    [
                        'in',
                        ['get', property],
                        ['literal', settings?.alert_sectors]
                    ],
                    // [
                    //     'in',
                    //     ['get', 'LABEL'],
                    //     ['literal', settings?.alert_sectors]
                    // ]
                ]
            ],
            [
                'any',
                [
                    'in',
                    ['get', property],
                    ['literal', sectors]
                ],
                // [
                //     'in',
                //     ['get', 'LABEL'],
                //     ['literal', sectors]
                // ]
            ],
        ];

    }

    const findCongestion = (congestion, property, yellow, red, empty) => {

        const sectors = _.map(congestion, 'sector');
        const airborneCongestion = _.map(congestion, 'airborneCongestion');
        const allCongestion = _.map(congestion, 'allCongestion');

        const nas_map_sectors = _.map(nas_map, 'SECTOR');
        const nas_map_map = _.map(nas_map, sector => { return sector.MAP * MAP_MODIFIER });

        const getMAP =
            [
                'at',
                [
                    'index-of',
                    ['get', property],
                    ['literal', nas_map_sectors]
                ],
                ['literal', nas_map_map]
            ];

        return [

            'case',
            [
                '>=',
                [
                    'at',
                    [
                        'index-of',
                        ['get', property],
                        ['literal', sectors]
                    ],
                    ['literal', airborneCongestion],
                ],
                getMAP,
            ], red,
            [
                '>=',
                [
                    'at',
                    [
                        'index-of',
                        ['get', property],
                        ['literal', sectors]
                    ],
                    ['literal', allCongestion],
                ],
                getMAP
            ], yellow,
            empty

        ];

    }

    const alertTextAndLine = (congestion, label) => {

        return [
            'case',
            ['==', ['get', 'SECTYPE'], 'superhigh'], findCongestion(congestion, label, YELLOW, RED, 'transparent'),
            ['==', ['get', 'SECTYPE'], 'high'], findCongestion(congestion, label, YELLOW, RED, 'transparent'),
            ['==', ['get', 'SECTYPE'], 'low'], findCongestion(congestion, label, YELLOW, RED, 'transparent'),
            'transparent'
        ];

    }

    const alertFill = (congestion, label) => {

        return [
            'case',
            ['==', ['get', 'SECTYPE'], 'superhigh'], findCongestion(congestion, label, `${SUPERHIGH_ALERT}${ALERT_YELLOW}`, `${SUPERHIGH_ALERT}${ALERT_RED}`, ''),
            ['==', ['get', 'SECTYPE'], 'high'], findCongestion(congestion, label, `${HIGH_ALERT}${ALERT_YELLOW}`, `${HIGH_ALERT}${ALERT_RED}`, ''),
            ['==', ['get', 'SECTYPE'], 'low'], findCongestion(congestion, label, `${LOW_ALERT}${ALERT_YELLOW}`, `${LOW_ALERT}${ALERT_RED}`, ''),
            ''
        ];

    }

    return (

        _.isNil(settings) ? null :
            <Container
                style={{ paddingLeft: '10px', paddingRight: '10px' }}
                fluid
            >

                <MapContext.Provider>

                    <ReactMapGL {...settings.viewport} attributionControl={false} width='99vw' height='90vh'
                        onViewportChange={(viewState) => setSettings({
                            ...settings,
                            viewport: {
                                latitude: viewState.latitude,
                                longitude: viewState.longitude,
                                width: viewState.width,
                                height: viewState.height,
                                zoom: viewState.zoom,
                            }
                        })}
                        mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
                        scrollZoom={{ speed: 1 }}
                        minZoom={1}
                        maxZoom={22}
                        mapStyle={process.env.REACT_APP_MAPBOX_URL}
                        dragRotate={false}
                        touchRotate={false}
                        worldCopyJump={true}

                    >

                        <NavBar
                            onMoveMap={(longitude, latitude, zoom) => setSettings({
                                ...settings,
                                viewport: { ...settings.viewport, longitude, latitude, zoom }
                            })}
                        />

                        <Layer id='bg' type='background' paint={{ "background-color": colors['background']['defaultColor']['hex'] }} />

                        <WeatherRadar radar_type={settings['radar_type']} showWeather={settings['showWeather']} />

                        <CountryBoundaries source={COUNTRY_SOURCE_ID} show={settings['boundaries']['overlay']} style={{
                            "fill-color": 'transparent',
                            "fill-outline-color": colors['boundaries']['defaultColor']['hex'],
                        }} />

                        <LatLonGrid show={settings['grid']['overlay']} color={colors['grid']['defaultColor']['hex']} />
                        <RangeRings rangerings={settings['rangerings']} />

                        <NAVAID show={settings['high_navaid']['overlay']} showLabel={settings['high_navaid']['label']} source={NAVAID_SOURCE_ID} type='high_navaid' color={colors.high_navaid.defaultColor.hex} />
                        <NAVAID show={settings['low_navaid']['overlay']} showLabel={settings['low_navaid']['label']} source={NAVAID_SOURCE_ID} type='low_navaid' color={colors.low_navaid.defaultColor.hex} />
                        <NAVAID show={settings['terminal_navaid']['overlay']} showLabel={settings['terminal_navaid']['label']} source={NAVAID_SOURCE_ID} type='terminal_navaid' color={colors.terminal_navaid.defaultColor.hex} />
                        <NAVAID show={settings['other_navaid']['overlay']} showLabel={settings['other_navaid']['label']} source={NAVAID_SOURCE_ID} type='other_navaid' color={colors.other_navaid.defaultColor.hex} />

                        <Fixes show={settings['arrival']['overlay']} showLabel={settings['arrival']['label']} type='arrival' source={FIX_SOURCE_ID} color={colors.arrival_fix.defaultColor.hex} />
                        <Fixes show={settings['departure']['overlay']} showLabel={settings['departure']['label']} type='departure' source={FIX_SOURCE_ID} color={colors.departure_fix.defaultColor.hex} />

                        <Airway show={settings['j']['overlay']} showLabel={settings['j']['label']} type='j' textfield='id' source={AIRWAY_SOURCE_ID} color={colors.jet_routes.defaultColor.hex} />
                        <Airway show={settings['v']['overlay']} showLabel={settings['v']['label']} type='v' textfield='id' source={AIRWAY_SOURCE_ID} color={colors.victor_routes.defaultColor.hex} />
                        <Airway show={settings['t']['overlay']} showLabel={settings['t']['label']} type='t' textfield='id' source={AIRWAY_SOURCE_ID} color={colors.t_routes.defaultColor.hex} />
                        <Airway show={settings['q']['overlay']} showLabel={settings['q']['label']} type='q' textfield='id' source={AIRWAY_SOURCE_ID} color={colors.q_routes.defaultColor.hex} />

                        <SUA show={settings['alert']['overlay']} showLabel={settings['alert']['label']} type='alert' textfield='name' source={SUA_SOURCE_ID} color={colors.alert.defaultColor.hex} />
                        <SUA show={settings['moa']['overlay']} showLabel={settings['moa']['label']} type='moa' textfield='name' source={SUA_SOURCE_ID} color={colors.moa.defaultColor.hex} />
                        <SUA show={settings['prohibited']['overlay']} showLabel={settings['prohibited']['label']} type='prohibited' textfield='name' source={SUA_SOURCE_ID} color={colors.prohibited.defaultColor.hex} />
                        <SUA show={settings['restricted']['overlay']} showLabel={settings['restricted']['label']} type='restricted' textfield='name' source={SUA_SOURCE_ID} color={colors.restricted.defaultColor.hex} />
                        <SUA show={settings['warning']['overlay']} showLabel={settings['warning']['label']} type='warning' textfield='name' source={SUA_SOURCE_ID} color={colors.warning.defaultColor.hex} />

                        <SectorFeature show={settings['artcc']['overlay']} showLabel={settings['artcc']['label']} filter='SECTYPE' type='artcc' textfield='LABEL' source={ARTCC_SOURCE_ID} labelSource={LABEL_SOURCE_ID} color={colors.artcc.defaultColor.hex} />
                        <SectorFeature show={settings['tracon']['overlay']} showLabel={settings['tracon']['label']} filter='SECTYPE' type='tracon' textfield='LABEL' source={SECTOR_SOURCE_ID} labelSource={LABEL_SOURCE_ID} color={colors.tracon.defaultColor.hex} />
                        <SectorFeature show={settings['low']['overlay']} showLabel={settings['low']['label']} filter='SECTYPE' type='low' textfield='LABEL' source={SECTOR_SOURCE_ID} labelSource={LABEL_SOURCE_ID} color={colors.low_sector.defaultColor.hex} />
                        <SectorFeature show={settings['high']['overlay']} showLabel={settings['high']['label']} filter='SECTYPE' type='high' textfield='LABEL' source={SECTOR_SOURCE_ID} labelSource={LABEL_SOURCE_ID} color={colors.high_sector.defaultColor.hex} />
                        <SectorFeature show={settings['superhigh']['overlay']} showLabel={settings['superhigh']['label']} filter='SECTYPE' type='superhigh' textfield='LABEL' source={SECTOR_SOURCE_ID} labelSource={LABEL_SOURCE_ID} color={colors.superhigh_sector.defaultColor.hex} />
                        <SectorFeature show={settings['oceanic']['overlay']} showLabel={settings['oceanic']['label']} filter='SECTYPE' type='oceanic' textfield='LABEL' source={SECTOR_SOURCE_ID} labelSource={LABEL_SOURCE_ID} color={colors.oceanic_sector.defaultColor.hex} />

                        <AlertFeature
                            show={settings['showAlert']}
                            source={SECTOR_SOURCE_ID}
                            labelSource={LABEL_SOURCE_ID}
                            textfield='LABEL'
                            filterText={filterAlerts(calculateCongestion(alerts), 'LABEL')}
                            filterPaint={filterAlerts(calculateCongestion(alerts), 'label')}
                            paintText={alertTextAndLine(calculateCongestion(alerts), 'LABEL')}
                            paintLine={alertTextAndLine(calculateCongestion(alerts), 'label')}
                            paintFill={alertFill(calculateCongestion(alerts), 'label')}
                        />
                        <AlertFeature
                            show={settings['showAlert']}
                            source={ARTCC_SOURCE_ID}
                            labelSource={LABEL_SOURCE_ID}
                            textfield='CALLSIGN'
                            filterText={filterOnline(atc)}
                            filterPaint={filterOnline(atc)}
                            paintText={onlineTextAndLine(atc)}
                            paintLine={onlineTextAndLine(atc)}
                            paintFill={onlineFill(atc)}
                        />

                        <TCF show={settings['showWeather']} tcf_forecast={settings['tcf_forecast']} showLabel={settings['tcf_datablock']} />
                        <Flights show={settings['showFlights']} sets={settings['flightsets']} flights={settings['flights']} />

                        <Airports show={settings['airports_pacing']['overlay']} showLabel={settings['airports_pacing']['label']} type='airports_pacing' circleColor={colors.airports_pacing.defaultColor.hex} labelColor={colors.airports_pacing.defaultColor.hex} />
                        <Airports show={settings['airports_all']['overlay']} showLabel={settings['airports_all']['label']} type='airports_all' source={AIRPORT_SOURCE_ID} circleColor={colors.airports_all.defaultColor.hex} labelColor={colors.airports_all.defaultColor.hex} />

                    </ReactMapGL>

                </MapContext.Provider>

            </Container>

    )

}
