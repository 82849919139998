import React, { useState, useEffect } from 'react';
import { Grid } from 'semantic-ui-react';

import { generateTimeLabels } from './AADC';
import { AADChart } from './AADChart';
import { GDTMap } from './GDTMap';
import { FSMTable } from './FSM';
import { arrivalTypeColors } from './config';

export function GDT({ airport, gdp, aad, aar, airports }) {

    const TOTAL_HOURS = 13;

    const [timescale, setTimeScale] = useState([]);
    const [arrivalRate, setArrivalRate] = useState([]);

    useEffect(() => {

        setTimeScale(generateTimeLabels(TOTAL_HOURS, 1));

        let r = [];
        for (let i = 0; i <= TOTAL_HOURS -1; i++) r[i] = aar;
        setArrivalRate(r);

    }, [aar]);

    return (

        <Grid celled>

            <Grid.Row columns={2}>

                <Grid.Column>
                    <AADChart airport={airport} gdp={gdp} height={285} timescale={timescale} aar={arrivalRate} aad={aad} keyword='status' colors={arrivalTypeColors} showLegend={false} />
                </Grid.Column>

                <Grid.Column>
                    <GDTMap height={50} />
                </Grid.Column>

            </Grid.Row>

            <Grid.Row columns={1}>

                <Grid.Column>
                    <FSMTable numCols={3} airport={airport} aar={arrivalRate} aad={aad.traffic} />
                </Grid.Column>

            </Grid.Row>

        </Grid>

    )

}