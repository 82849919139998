import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Container, Header } from 'semantic-ui-react';
import { Field, Radio } from 'formik-semantic-ui-react';
import { Layer, Source } from 'react-map-gl';
import _ from 'lodash';
import axios from 'axios';
import * as turf from '@turf/turf';

import { useInterval } from './utils';
import { useTCF } from './api/weather';
import { RVRForm } from './Weather';
import { selectWeatherOptions } from './config';
import { useFormikContext } from 'formik';

export function RVRModal({ onClose, showModal }) {

    return (
        <Modal size='mini' closeIcon={true} onClose={() => onClose()} open={showModal}>

            <Modal.Header>RVR Prompts</Modal.Header>

            <Modal.Content>
                <RVRForm />
            </Modal.Content>

            <Modal.Actions>
                <Button content='Close' onClick={() => onClose()} />
            </Modal.Actions>

        </Modal>
    )

}

export function SelectWeatherModal({ onClose, onCancel, showModal }) {

    const { values } = useFormikContext();

    return (
        <Modal size='mini' closeIcon onClose={() => onCancel()} open={showModal}>

            <Modal.Header>Select Weather</Modal.Header>

            <Modal.Content>

                {
                    _.map(selectWeatherOptions, weatherOption => {

                        return (

                            <Container key={weatherOption.name} className='lightbordered'>

                                <Header as='h4' content={weatherOption.label} />

                                <Form>
                                    {
                                        _.map(weatherOption.options, option => {

                                            return <Form.Group inline={weatherOption.inline} grouped={!weatherOption.inline} key={option.name}>

                                                <label><b>{option.label}</b></label>

                                                {
                                                    _.map(option.options, opt => {

                                                        if (option.type === 'radio') {
                                                            return (
                                                                <Radio key={opt.value} name={option.name} label={opt.label} value={opt.value} />
                                                            )
                                                        } else if (option.type === 'checkbox') {
                                                            return (
                                                                <label key={opt.value} className='checkboxContainer'>
                                                                    {`${opt.label}`}
                                                                    <Field key={opt.value} type='checkbox' name={option.name}
                                                                        value={opt.value} />
                                                                    <span className='checkmark' />
                                                                </label>
                                                            )
                                                        }

                                                    })
                                                }

                                            </Form.Group>

                                        })
                                    }
                                </Form>

                            </Container>

                        )

                    })
                }

            </Modal.Content>

            <Modal.Actions>
                <Button content='OK' onClick={() => onClose(values)} />
                <Button content='Cancel' onClick={() => onCancel()} />
            </Modal.Actions>

        </Modal>
    )

}

export function TCF({ tcf_forecast, show, showLabel }) {

    const { data: tcf } = useTCF();

    function setLineColor(area) {

        let color;

        if (area.confidence === 1) {
            color = '#4f94cd';
        }
        else {
            color = '#9C9C9C';
        }

        return {
            "line-color": color
        };

    }

    function setFillPattern(area) {

        let color_name;

        if (area.confidence === 1) {
            color_name = 'blue';
        }
        else {
            color_name = 'gray';
        }

        if (area.coverage === 2) {

            return {
                "fill-pattern": `medium_coverage_${color_name}`,
            };

        } else {

            return {
                "fill-pattern": `sparse_coverage_${color_name}`,
            }

        }

    }

    return show && _.includes([4, 6, 8], tcf_forecast) && _.isArray(tcf) ?
        _.map(tcf, area => {

            if (area.forecast_length !== tcf_forecast) return null;

            if (area.forecast_type === 'AREA') {

                let data = _.map(area.boundary, b => {
                    return [b[1], b[0]]
                });

                let geo = {
                    "type": "Feature",
                    "geometry": {
                        "type": "Polygon",
                        "coordinates": [data]
                    }
                };

                return (
                    <React.Fragment key={area._id}>
                        <Source type='geojson' data={turf.centroid(geo)}>

                            <Layer type='symbol' layout={{
                                'text-field': ['case',
                                    ['in', 'tcf_datablock', ['literal', showLabel]], area.tops,
                                    ''
                                ],
                                "text-size": 10,
                                "text-letter-spacing": 0.1,
                                "text-allow-overlap": true,
                                "text-ignore-placement": true,
                            }}
                                paint={{
                                    "text-color": '#ffffff',
                                }}
                            />
                        </Source>
                        <Source type='geojson' data={geo}>
                            <Layer type='fill' paint={setFillPattern(area)} />
                            <Layer type='line' paint={setLineColor(area)} />
                        </Source>
                    </React.Fragment>
                )

            }

        })
        : null

}

export function WeatherRadar({ radar_type, showWeather }) {

    const [show, setShow] = useState(showWeather);
    const [tiles, setTiles] = useState(null);

    async function fetchWX() {

        const { data } = await axios.get('https://api.rainviewer.com/public/weather-maps.json');

        if (_.isNil(data?.radar) || _.isNil(data?.radar?.past)) return null;

        let ts = data.radar.past[data.radar.past.length - 1].path;
        return ts;

    }

    useEffect(() => {

        let isMounted = true;

        if (radar_type === 'off' || !showWeather) return;

        if (isMounted) fetchWX().then(result => {

            if (_.isNil(result)) return;

            let t = `https://tilecache.rainviewer.com${result}/1024/{z}/{x}/{y}/${radar_type}/0_1.png`;

            setShow(false);
            setTiles([t]);
            setShow(true);

        });
        return () => { isMounted = false; }

    }, [radar_type, showWeather]);

    useInterval(async () => {

        if (radar_type === 'off' || !showWeather) return;

        let result = await fetchWX();
        let t = `https://tilecache.rainviewer.com${result}/1024/{z}/{x}/{y}/${radar_type}/0_1.png`;

        setShow(false);
        setTiles([t]);
        setShow(true);

    }, 10 * 60 * 1000);

    return (showWeather && show) && radar_type !== 'off' && !_.isNil(tiles) ?
        <Source type='raster' coordinates={[
            [180, 90],
            [-180, 90],
            [-180, -90],
            [180, -90]
        ]}
            tileSize={1024}
            tiles={tiles}
        >
            <Layer type='raster' paint={{
                "raster-opacity": 0.666
            }} />
        </Source>
        : null

}