export const nas_map = [
  {
    "SECTOR": "ZAB15",
    "MAP": 15
  },
  {
    "SECTOR": "ZAB16",
    "MAP": 14
  },
  {
    "SECTOR": "ZAB17",
    "MAP": 18
  },
  {
    "SECTOR": "ZAB19",
    "MAP": 17
  },
  {
    "SECTOR": "ZAB20",
    "MAP": 16
  },
  {
    "SECTOR": "ZAB21",
    "MAP": 16
  },
  {
    "SECTOR": "ZAB23",
    "MAP": 16
  },
  {
    "SECTOR": "ZAB37",
    "MAP": 13
  },
  {
    "SECTOR": "ZAB38",
    "MAP": 13
  },
  {
    "SECTOR": "ZAB39",
    "MAP": 17
  },
  {
    "SECTOR": "ZAB42",
    "MAP": 14
  },
  {
    "SECTOR": "ZAB43",
    "MAP": 18
  },
  {
    "SECTOR": "ZAB45",
    "MAP": 16
  },
  {
    "SECTOR": "ZAB46",
    "MAP": 10
  },
  {
    "SECTOR": "ZAB47",
    "MAP": 16
  },
  {
    "SECTOR": "ZAB49",
    "MAP": 6
  },
  {
    "SECTOR": "ZAB50",
    "MAP": 17
  },
  {
    "SECTOR": "ZAB58",
    "MAP": 21
  },
  {
    "SECTOR": "ZAB63",
    "MAP": 19
  },
  {
    "SECTOR": "ZAB65",
    "MAP": 21
  },
  {
    "SECTOR": "ZAB67",
    "MAP": 18
  },
  {
    "SECTOR": "ZAB68",
    "MAP": 18
  },
  {
    "SECTOR": "ZAB70",
    "MAP": 17
  },
  {
    "SECTOR": "ZAB71",
    "MAP": 19
  },
  {
    "SECTOR": "ZAB72",
    "MAP": 19
  },
  {
    "SECTOR": "ZAB78",
    "MAP": 19
  },
  {
    "SECTOR": "ZAB79",
    "MAP": 14
  },
  {
    "SECTOR": "ZAB80",
    "MAP": 21
  },
  {
    "SECTOR": "ZAB87",
    "MAP": 16
  },
  {
    "SECTOR": "ZAB89",
    "MAP": 18
  },
  {
    "SECTOR": "ZAB90",
    "MAP": 17
  },
  {
    "SECTOR": "ZAB91",
    "MAP": 17
  },
  {
    "SECTOR": "ZAB92",
    "MAP": 19
  },
  {
    "SECTOR": "ZAB93",
    "MAP": 18
  },
  {
    "SECTOR": "ZAB94",
    "MAP": 14
  },
  {
    "SECTOR": "ZAB95",
    "MAP": 18
  },
  {
    "SECTOR": "ZAB96",
    "MAP": 18
  },
  {
    "SECTOR": "ZAB97",
    "MAP": 20
  },
  {
    "SECTOR": "ZAB98",
    "MAP": 20
  },
  {
    "SECTOR": "ZAN03",
    "MAP": 15
  },
  {
    "SECTOR": "ZAN04",
    "MAP": 21
  },
  {
    "SECTOR": "ZAN05",
    "MAP": 20
  },
  {
    "SECTOR": "ZAN06",
    "MAP": 17
  },
  {
    "SECTOR": "ZAN07",
    "MAP": 18
  },
  {
    "SECTOR": "ZAN08",
    "MAP": 15
  },
  {
    "SECTOR": "ZAN09",
    "MAP": 15
  },
  {
    "SECTOR": "ZAN10",
    "MAP": 50
  },
  {
    "SECTOR": "ZAN11",
    "MAP": 15
  },
  {
    "SECTOR": "ZAN13",
    "MAP": 18
  },
  {
    "SECTOR": "ZAN14",
    "MAP": 15
  },
  {
    "SECTOR": "ZAN15",
    "MAP": 17
  },
  {
    "SECTOR": "ZAN16",
    "MAP": 25
  },
  {
    "SECTOR": "ZAN63",
    "MAP": 25
  },
  {
    "SECTOR": "ZAN64",
    "MAP": 15
  },
  {
    "SECTOR": "ZAN68",
    "MAP": 25
  },
  {
    "SECTOR": "ZAN69",
    "MAP": 25
  },
  {
    "SECTOR": "ZAU22",
    "MAP": 10
  },
  {
    "SECTOR": "ZAU23",
    "MAP": 16
  },
  {
    "SECTOR": "ZAU24",
    "MAP": 10
  },
  {
    "SECTOR": "ZAU25",
    "MAP": 13
  },
  {
    "SECTOR": "ZAU26",
    "MAP": 11
  },
  {
    "SECTOR": "ZAU27",
    "MAP": 13
  },
  {
    "SECTOR": "ZAU32",
    "MAP": 11
  },
  {
    "SECTOR": "ZAU33",
    "MAP": 17
  },
  {
    "SECTOR": "ZAU34",
    "MAP": 11
  },
  {
    "SECTOR": "ZAU35",
    "MAP": 12
  },
  {
    "SECTOR": "ZAU36",
    "MAP": 12
  },
  {
    "SECTOR": "ZAU37",
    "MAP": 10
  },
  {
    "SECTOR": "ZAU43",
    "MAP": 10
  },
  {
    "SECTOR": "ZAU44",
    "MAP": 14
  },
  {
    "SECTOR": "ZAU45",
    "MAP": 14
  },
  {
    "SECTOR": "ZAU46",
    "MAP": 12
  },
  {
    "SECTOR": "ZAU47",
    "MAP": 14
  },
  {
    "SECTOR": "ZAU50",
    "MAP": 15
  },
  {
    "SECTOR": "ZAU51",
    "MAP": 11
  },
  {
    "SECTOR": "ZAU52",
    "MAP": 12
  },
  {
    "SECTOR": "ZAU55",
    "MAP": 12
  },
  {
    "SECTOR": "ZAU56",
    "MAP": 16
  },
  {
    "SECTOR": "ZAU57",
    "MAP": 16
  },
  {
    "SECTOR": "ZAU58",
    "MAP": 11
  },
  {
    "SECTOR": "ZAU60",
    "MAP": 16
  },
  {
    "SECTOR": "ZAU61",
    "MAP": 20
  },
  {
    "SECTOR": "ZAU62",
    "MAP": 10
  },
  {
    "SECTOR": "ZAU63",
    "MAP": 19
  },
  {
    "SECTOR": "ZAU64",
    "MAP": 11
  },
  {
    "SECTOR": "ZAU71",
    "MAP": 20
  },
  {
    "SECTOR": "ZAU74",
    "MAP": 11
  },
  {
    "SECTOR": "ZAU75",
    "MAP": 16
  },
  {
    "SECTOR": "ZAU76",
    "MAP": 18
  },
  {
    "SECTOR": "ZAU77",
    "MAP": 12
  },
  {
    "SECTOR": "ZAU80",
    "MAP": 11
  },
  {
    "SECTOR": "ZAU81",
    "MAP": 11
  },
  {
    "SECTOR": "ZAU82",
    "MAP": 9
  },
  {
    "SECTOR": "ZAU83",
    "MAP": 14
  },
  {
    "SECTOR": "ZAU84",
    "MAP": 19
  },
  {
    "SECTOR": "ZAU85",
    "MAP": 14
  },
  {
    "SECTOR": "ZAU88",
    "MAP": 12
  },
  {
    "SECTOR": "ZAU89",
    "MAP": 13
  },
  {
    "SECTOR": "ZAU90",
    "MAP": 15
  },
  {
    "SECTOR": "ZAU91",
    "MAP": 15
  },
  {
    "SECTOR": "ZAU92",
    "MAP": 14
  },
  {
    "SECTOR": "ZAU94",
    "MAP": 19
  },
  {
    "SECTOR": "ZAU95",
    "MAP": 20
  },
  {
    "SECTOR": "ZBW01",
    "MAP": 21
  },
  {
    "SECTOR": "ZBW02",
    "MAP": 23
  },
  {
    "SECTOR": "ZBW05",
    "MAP": 12
  },
  {
    "SECTOR": "ZBW06",
    "MAP": 10
  },
  {
    "SECTOR": "ZBW07",
    "MAP": 11
  },
  {
    "SECTOR": "ZBW08",
    "MAP": 13
  },
  {
    "SECTOR": "ZBW09",
    "MAP": 15
  },
  {
    "SECTOR": "ZBW10",
    "MAP": 14
  },
  {
    "SECTOR": "ZBW15",
    "MAP": 15
  },
  {
    "SECTOR": "ZBW16",
    "MAP": 15
  },
  {
    "SECTOR": "ZBW17",
    "MAP": 21
  },
  {
    "SECTOR": "ZBW18",
    "MAP": 17
  },
  {
    "SECTOR": "ZBW19",
    "MAP": 13
  },
  {
    "SECTOR": "ZBW20",
    "MAP": 13
  },
  {
    "SECTOR": "ZBW21",
    "MAP": 12
  },
  {
    "SECTOR": "ZBW22",
    "MAP": 11
  },
  {
    "SECTOR": "ZBW23",
    "MAP": 15
  },
  {
    "SECTOR": "ZBW24",
    "MAP": 14
  },
  {
    "SECTOR": "ZBW31",
    "MAP": 12
  },
  {
    "SECTOR": "ZBW32",
    "MAP": 13
  },
  {
    "SECTOR": "ZBW34",
    "MAP": 11
  },
  {
    "SECTOR": "ZBW36",
    "MAP": 13
  },
  {
    "SECTOR": "ZBW37",
    "MAP": 11
  },
  {
    "SECTOR": "ZBW38",
    "MAP": 14
  },
  {
    "SECTOR": "ZBW39",
    "MAP": 15
  },
  {
    "SECTOR": "ZBW46",
    "MAP": 18
  },
  {
    "SECTOR": "ZBW47",
    "MAP": 12
  },
  {
    "SECTOR": "ZBW49",
    "MAP": 14
  },
  {
    "SECTOR": "ZBW52",
    "MAP": 13
  },
  {
    "SECTOR": "ZBW53",
    "MAP": 14
  },
  {
    "SECTOR": "ZDC01",
    "MAP": 11
  },
  {
    "SECTOR": "ZDC02",
    "MAP": 12
  },
  {
    "SECTOR": "ZDC03",
    "MAP": 15
  },
  {
    "SECTOR": "ZDC04",
    "MAP": 21
  },
  {
    "SECTOR": "ZDC05",
    "MAP": 12
  },
  {
    "SECTOR": "ZDC06",
    "MAP": 9
  },
  {
    "SECTOR": "ZDC07",
    "MAP": 21
  },
  {
    "SECTOR": "ZDC08",
    "MAP": 20
  },
  {
    "SECTOR": "ZDC09",
    "MAP": 15
  },
  {
    "SECTOR": "ZDC10",
    "MAP": 14
  },
  {
    "SECTOR": "ZDC11",
    "MAP": 11
  },
  {
    "SECTOR": "ZDC12",
    "MAP": 16
  },
  {
    "SECTOR": "ZDC14",
    "MAP": 11
  },
  {
    "SECTOR": "ZDC15",
    "MAP": 12
  },
  {
    "SECTOR": "ZDC16",
    "MAP": 17
  },
  {
    "SECTOR": "ZDC17",
    "MAP": 10
  },
  {
    "SECTOR": "ZDC18",
    "MAP": 11
  },
  {
    "SECTOR": "ZDC19",
    "MAP": 16
  },
  {
    "SECTOR": "ZDC20",
    "MAP": 13
  },
  {
    "SECTOR": "ZDC21",
    "MAP": 12
  },
  {
    "SECTOR": "ZDC22",
    "MAP": 13
  },
  {
    "SECTOR": "ZDC23",
    "MAP": 14
  },
  {
    "SECTOR": "ZDC25",
    "MAP": 16
  },
  {
    "SECTOR": "ZDC26",
    "MAP": 14
  },
  {
    "SECTOR": "ZDC27",
    "MAP": 11
  },
  {
    "SECTOR": "ZDC29",
    "MAP": 11
  },
  {
    "SECTOR": "ZDC30",
    "MAP": 15
  },
  {
    "SECTOR": "ZDC31",
    "MAP": 15
  },
  {
    "SECTOR": "ZDC32",
    "MAP": 17
  },
  {
    "SECTOR": "ZDC33",
    "MAP": 14
  },
  {
    "SECTOR": "ZDC34",
    "MAP": 17
  },
  {
    "SECTOR": "ZDC35",
    "MAP": 19
  },
  {
    "SECTOR": "ZDC36",
    "MAP": 17
  },
  {
    "SECTOR": "ZDC37",
    "MAP": 12
  },
  {
    "SECTOR": "ZDC38",
    "MAP": 15
  },
  {
    "SECTOR": "ZDC39",
    "MAP": 18
  },
  {
    "SECTOR": "ZDC42",
    "MAP": 15
  },
  {
    "SECTOR": "ZDC50",
    "MAP": 20
  },
  {
    "SECTOR": "ZDC51",
    "MAP": 10
  },
  {
    "SECTOR": "ZDC52",
    "MAP": 15
  },
  {
    "SECTOR": "ZDC53",
    "MAP": 10
  },
  {
    "SECTOR": "ZDC54",
    "MAP": 14
  },
  {
    "SECTOR": "ZDC58",
    "MAP": 14
  },
  {
    "SECTOR": "ZDC59",
    "MAP": 16
  },
  {
    "SECTOR": "ZDC60",
    "MAP": 14
  },
  {
    "SECTOR": "ZDC72",
    "MAP": 20
  },
  {
    "SECTOR": "ZDV03",
    "MAP": 20
  },
  {
    "SECTOR": "ZDV04",
    "MAP": 20
  },
  {
    "SECTOR": "ZDV05",
    "MAP": 16
  },
  {
    "SECTOR": "ZDV06",
    "MAP": 11
  },
  {
    "SECTOR": "ZDV07",
    "MAP": 15
  },
  {
    "SECTOR": "ZDV08",
    "MAP": 18
  },
  {
    "SECTOR": "ZDV09",
    "MAP": 21
  },
  {
    "SECTOR": "ZDV11",
    "MAP": 11
  },
  {
    "SECTOR": "ZDV12",
    "MAP": 12
  },
  {
    "SECTOR": "ZDV13",
    "MAP": 9
  },
  {
    "SECTOR": "ZDV14",
    "MAP": 21
  },
  {
    "SECTOR": "ZDV15",
    "MAP": 14
  },
  {
    "SECTOR": "ZDV16",
    "MAP": 18
  },
  {
    "SECTOR": "ZDV17",
    "MAP": 20
  },
  {
    "SECTOR": "ZDV18",
    "MAP": 21
  },
  {
    "SECTOR": "ZDV19",
    "MAP": 19
  },
  {
    "SECTOR": "ZDV21",
    "MAP": 13
  },
  {
    "SECTOR": "ZDV22",
    "MAP": 18
  },
  {
    "SECTOR": "ZDV23",
    "MAP": 19
  },
  {
    "SECTOR": "ZDV24",
    "MAP": 21
  },
  {
    "SECTOR": "ZDV25",
    "MAP": 15
  },
  {
    "SECTOR": "ZDV26",
    "MAP": 9
  },
  {
    "SECTOR": "ZDV27",
    "MAP": 16
  },
  {
    "SECTOR": "ZDV28",
    "MAP": 18
  },
  {
    "SECTOR": "ZDV29",
    "MAP": 19
  },
  {
    "SECTOR": "ZDV30",
    "MAP": 20
  },
  {
    "SECTOR": "ZDV31",
    "MAP": 15
  },
  {
    "SECTOR": "ZDV32",
    "MAP": 21
  },
  {
    "SECTOR": "ZDV33",
    "MAP": 21
  },
  {
    "SECTOR": "ZDV34",
    "MAP": 21
  },
  {
    "SECTOR": "ZDV35",
    "MAP": 21
  },
  {
    "SECTOR": "ZDV36",
    "MAP": 15
  },
  {
    "SECTOR": "ZDV37",
    "MAP": 21
  },
  {
    "SECTOR": "ZDV38",
    "MAP": 18
  },
  {
    "SECTOR": "ZDV39",
    "MAP": 15
  },
  {
    "SECTOR": "ZDV41",
    "MAP": 15
  },
  {
    "SECTOR": "ZDV46",
    "MAP": 20
  },
  {
    "SECTOR": "ZDV47",
    "MAP": 19
  },
  {
    "SECTOR": "ZDV61",
    "MAP": 15
  },
  {
    "SECTOR": "ZDV64",
    "MAP": 21
  },
  {
    "SECTOR": "ZDV65",
    "MAP": 15
  },
  {
    "SECTOR": "ZDV67",
    "MAP": 18
  },
  {
    "SECTOR": "ZDV68",
    "MAP": 15
  },
  {
    "SECTOR": "ZDV77",
    "MAP": 20
  },
  {
    "SECTOR": "ZFW20",
    "MAP": 13
  },
  {
    "SECTOR": "ZFW23",
    "MAP": 12
  },
  {
    "SECTOR": "ZFW24",
    "MAP": 18
  },
  {
    "SECTOR": "ZFW25",
    "MAP": 15
  },
  {
    "SECTOR": "ZFW26",
    "MAP": 13
  },
  {
    "SECTOR": "ZFW27",
    "MAP": 15
  },
  {
    "SECTOR": "ZFW28",
    "MAP": 18
  },
  {
    "SECTOR": "ZFW29",
    "MAP": 18
  },
  {
    "SECTOR": "ZFW30",
    "MAP": 18
  },
  {
    "SECTOR": "ZFW32",
    "MAP": 16
  },
  {
    "SECTOR": "ZFW34",
    "MAP": 15
  },
  {
    "SECTOR": "ZFW35",
    "MAP": 15
  },
  {
    "SECTOR": "ZFW37",
    "MAP": 15
  },
  {
    "SECTOR": "ZFW38",
    "MAP": 18
  },
  {
    "SECTOR": "ZFW40",
    "MAP": 16
  },
  {
    "SECTOR": "ZFW41",
    "MAP": 13
  },
  {
    "SECTOR": "ZFW42",
    "MAP": 15
  },
  {
    "SECTOR": "ZFW43",
    "MAP": 16
  },
  {
    "SECTOR": "ZFW46",
    "MAP": 17
  },
  {
    "SECTOR": "ZFW47",
    "MAP": 16
  },
  {
    "SECTOR": "ZFW48",
    "MAP": 16
  },
  {
    "SECTOR": "ZFW49",
    "MAP": 16
  },
  {
    "SECTOR": "ZFW50",
    "MAP": 16
  },
  {
    "SECTOR": "ZFW51",
    "MAP": 15
  },
  {
    "SECTOR": "ZFW53",
    "MAP": 17
  },
  {
    "SECTOR": "ZFW55",
    "MAP": 20
  },
  {
    "SECTOR": "ZFW61",
    "MAP": 17
  },
  {
    "SECTOR": "ZFW62",
    "MAP": 15
  },
  {
    "SECTOR": "ZFW63",
    "MAP": 16
  },
  {
    "SECTOR": "ZFW64",
    "MAP": 18
  },
  {
    "SECTOR": "ZFW65",
    "MAP": 15
  },
  {
    "SECTOR": "ZFW75",
    "MAP": 14
  },
  {
    "SECTOR": "ZFW82",
    "MAP": 18
  },
  {
    "SECTOR": "ZFW83",
    "MAP": 15
  },
  {
    "SECTOR": "ZFW86",
    "MAP": 18
  },
  {
    "SECTOR": "ZFW89",
    "MAP": 18
  },
  {
    "SECTOR": "ZFW90",
    "MAP": 17
  },
  {
    "SECTOR": "ZFW93",
    "MAP": 19
  },
  {
    "SECTOR": "ZFW94",
    "MAP": 18
  },
  {
    "SECTOR": "ZFW96",
    "MAP": 15
  },
  {
    "SECTOR": "ZFW97",
    "MAP": 18
  },
  {
    "SECTOR": "ZHN02",
    "MAP": 15
  },
  {
    "SECTOR": "ZHN03",
    "MAP": 15
  },
  {
    "SECTOR": "ZHN04",
    "MAP": 12
  },
  {
    "SECTOR": "ZHN05",
    "MAP": 15
  },
  {
    "SECTOR": "ZHN06",
    "MAP": 15
  },
  {
    "SECTOR": "ZHN07",
    "MAP": 18
  },
  {
    "SECTOR": "ZHN62",
    "MAP": 20
  },
  {
    "SECTOR": "ZHN71",
    "MAP": 20
  },
  {
    "SECTOR": "ZHN83",
    "MAP": 20
  },
  {
    "SECTOR": "ZHN84",
    "MAP": 20
  },
  {
    "SECTOR": "ZHN85",
    "MAP": 20
  },
  {
    "SECTOR": "ZHN86",
    "MAP": 20
  },
  {
    "SECTOR": "ZHN87",
    "MAP": 15
  },
  {
    "SECTOR": "ZHN88",
    "MAP": 15
  },
  {
    "SECTOR": "ZHU23",
    "MAP": 18
  },
  {
    "SECTOR": "ZHU24",
    "MAP": 20
  },
  {
    "SECTOR": "ZHU25",
    "MAP": 11
  },
  {
    "SECTOR": "ZHU26",
    "MAP": 16
  },
  {
    "SECTOR": "ZHU27",
    "MAP": 14
  },
  {
    "SECTOR": "ZHU28",
    "MAP": 20
  },
  {
    "SECTOR": "ZHU30",
    "MAP": 20
  },
  {
    "SECTOR": "ZHU34",
    "MAP": 12
  },
  {
    "SECTOR": "ZHU36",
    "MAP": 18
  },
  {
    "SECTOR": "ZHU37",
    "MAP": 21
  },
  {
    "SECTOR": "ZHU38",
    "MAP": 16
  },
  {
    "SECTOR": "ZHU40",
    "MAP": 15
  },
  {
    "SECTOR": "ZHU42",
    "MAP": 15
  },
  {
    "SECTOR": "ZHU43",
    "MAP": 18
  },
  {
    "SECTOR": "ZHU46",
    "MAP": 16
  },
  {
    "SECTOR": "ZHU49",
    "MAP": 14
  },
  {
    "SECTOR": "ZHU50",
    "MAP": 17
  },
  {
    "SECTOR": "ZHU53",
    "MAP": 20
  },
  {
    "SECTOR": "ZHU56",
    "MAP": 18
  },
  {
    "SECTOR": "ZHU58",
    "MAP": 17
  },
  {
    "SECTOR": "ZHU59",
    "MAP": 21
  },
  {
    "SECTOR": "ZHU63",
    "MAP": 17
  },
  {
    "SECTOR": "ZHU64",
    "MAP": 17
  },
  {
    "SECTOR": "ZHU65",
    "MAP": 17
  },
  {
    "SECTOR": "ZHU68",
    "MAP": 18
  },
  {
    "SECTOR": "ZHU70",
    "MAP": 18
  },
  {
    "SECTOR": "ZHU72",
    "MAP": 19
  },
  {
    "SECTOR": "ZHU74",
    "MAP": 18
  },
  {
    "SECTOR": "ZHU76",
    "MAP": 18
  },
  {
    "SECTOR": "ZHU78",
    "MAP": 15
  },
  {
    "SECTOR": "ZHU79",
    "MAP": 21
  },
  {
    "SECTOR": "ZHU80",
    "MAP": 14
  },
  {
    "SECTOR": "ZHU81",
    "MAP": 17
  },
  {
    "SECTOR": "ZHU82",
    "MAP": 11
  },
  {
    "SECTOR": "ZHU83",
    "MAP": 14
  },
  {
    "SECTOR": "ZHU84",
    "MAP": 21
  },
  {
    "SECTOR": "ZHU85",
    "MAP": 16
  },
  {
    "SECTOR": "ZHU86",
    "MAP": 12
  },
  {
    "SECTOR": "ZHU87",
    "MAP": 17
  },
  {
    "SECTOR": "ZHU88",
    "MAP": 21
  },
  {
    "SECTOR": "ZHU93",
    "MAP": 15
  },
  {
    "SECTOR": "ZHU95",
    "MAP": 16
  },
  {
    "SECTOR": "ZHU96",
    "MAP": 10
  },
  {
    "SECTOR": "ZHU97",
    "MAP": 21
  },
  {
    "SECTOR": "ZHU98",
    "MAP": 16
  },
  {
    "SECTOR": "ZID17",
    "MAP": 15
  },
  {
    "SECTOR": "ZID18",
    "MAP": 13
  },
  {
    "SECTOR": "ZID19",
    "MAP": 15
  },
  {
    "SECTOR": "ZID20",
    "MAP": 13
  },
  {
    "SECTOR": "ZID21",
    "MAP": 15
  },
  {
    "SECTOR": "ZID22",
    "MAP": 14
  },
  {
    "SECTOR": "ZID24",
    "MAP": 12
  },
  {
    "SECTOR": "ZID25",
    "MAP": 15
  },
  {
    "SECTOR": "ZID26",
    "MAP": 14
  },
  {
    "SECTOR": "ZID30",
    "MAP": 11
  },
  {
    "SECTOR": "ZID31",
    "MAP": 14
  },
  {
    "SECTOR": "ZID32",
    "MAP": 14
  },
  {
    "SECTOR": "ZID33",
    "MAP": 15
  },
  {
    "SECTOR": "ZID34",
    "MAP": 11
  },
  {
    "SECTOR": "ZID35",
    "MAP": 12
  },
  {
    "SECTOR": "ZID66",
    "MAP": 13
  },
  {
    "SECTOR": "ZID69",
    "MAP": 13
  },
  {
    "SECTOR": "ZID70",
    "MAP": 18
  },
  {
    "SECTOR": "ZID74",
    "MAP": 17
  },
  {
    "SECTOR": "ZID75",
    "MAP": 12
  },
  {
    "SECTOR": "ZID76",
    "MAP": 15
  },
  {
    "SECTOR": "ZID77",
    "MAP": 14
  },
  {
    "SECTOR": "ZID78",
    "MAP": 15
  },
  {
    "SECTOR": "ZID79",
    "MAP": 16
  },
  {
    "SECTOR": "ZID80",
    "MAP": 12
  },
  {
    "SECTOR": "ZID81",
    "MAP": 16
  },
  {
    "SECTOR": "ZID82",
    "MAP": 14
  },
  {
    "SECTOR": "ZID83",
    "MAP": 14
  },
  {
    "SECTOR": "ZID84",
    "MAP": 14
  },
  {
    "SECTOR": "ZID85",
    "MAP": 15
  },
  {
    "SECTOR": "ZID86",
    "MAP": 15
  },
  {
    "SECTOR": "ZID87",
    "MAP": 14
  },
  {
    "SECTOR": "ZID88",
    "MAP": 15
  },
  {
    "SECTOR": "ZID89",
    "MAP": 13
  },
  {
    "SECTOR": "ZID91",
    "MAP": 18
  },
  {
    "SECTOR": "ZID92",
    "MAP": 13
  },
  {
    "SECTOR": "ZID93",
    "MAP": 17
  },
  {
    "SECTOR": "ZID94",
    "MAP": 17
  },
  {
    "SECTOR": "ZID95",
    "MAP": 17
  },
  {
    "SECTOR": "ZID96",
    "MAP": 17
  },
  {
    "SECTOR": "ZID97",
    "MAP": 17
  },
  {
    "SECTOR": "ZID98",
    "MAP": 15
  },
  {
    "SECTOR": "ZID99",
    "MAP": 17
  },
  {
    "SECTOR": "ZJX09",
    "MAP": 14
  },
  {
    "SECTOR": "ZJX10",
    "MAP": 14
  },
  {
    "SECTOR": "ZJX11",
    "MAP": 17
  },
  {
    "SECTOR": "ZJX12",
    "MAP": 16
  },
  {
    "SECTOR": "ZJX13",
    "MAP": 20
  },
  {
    "SECTOR": "ZJX14",
    "MAP": 15
  },
  {
    "SECTOR": "ZJX15",
    "MAP": 14
  },
  {
    "SECTOR": "ZJX16",
    "MAP": 16
  },
  {
    "SECTOR": "ZJX17",
    "MAP": 18
  },
  {
    "SECTOR": "ZJX28",
    "MAP": 17
  },
  {
    "SECTOR": "ZJX29",
    "MAP": 16
  },
  {
    "SECTOR": "ZJX30",
    "MAP": 21
  },
  {
    "SECTOR": "ZJX33",
    "MAP": 16
  },
  {
    "SECTOR": "ZJX34",
    "MAP": 21
  },
  {
    "SECTOR": "ZJX35",
    "MAP": 23
  },
  {
    "SECTOR": "ZJX47",
    "MAP": 19
  },
  {
    "SECTOR": "ZJX48",
    "MAP": 22
  },
  {
    "SECTOR": "ZJX49",
    "MAP": 20
  },
  {
    "SECTOR": "ZJX50",
    "MAP": 18
  },
  {
    "SECTOR": "ZJX51",
    "MAP": 23
  },
  {
    "SECTOR": "ZJX52",
    "MAP": 23
  },
  {
    "SECTOR": "ZJX53",
    "MAP": 11
  },
  {
    "SECTOR": "ZJX54",
    "MAP": 13
  },
  {
    "SECTOR": "ZJX57",
    "MAP": 12
  },
  {
    "SECTOR": "ZJX58",
    "MAP": 16
  },
  {
    "SECTOR": "ZJX65",
    "MAP": 21
  },
  {
    "SECTOR": "ZJX66",
    "MAP": 18
  },
  {
    "SECTOR": "ZJX67",
    "MAP": 21
  },
  {
    "SECTOR": "ZJX68",
    "MAP": 18
  },
  {
    "SECTOR": "ZJX71",
    "MAP": 16
  },
  {
    "SECTOR": "ZJX72",
    "MAP": 15
  },
  {
    "SECTOR": "ZJX73",
    "MAP": 16
  },
  {
    "SECTOR": "ZJX74",
    "MAP": 16
  },
  {
    "SECTOR": "ZJX75",
    "MAP": 15
  },
  {
    "SECTOR": "ZJX76",
    "MAP": 21
  },
  {
    "SECTOR": "ZJX77",
    "MAP": 14
  },
  {
    "SECTOR": "ZJX78",
    "MAP": 15
  },
  {
    "SECTOR": "ZJX79",
    "MAP": 16
  },
  {
    "SECTOR": "ZJX85",
    "MAP": 18
  },
  {
    "SECTOR": "ZJX86",
    "MAP": 20
  },
  {
    "SECTOR": "ZJX87",
    "MAP": 15
  },
  {
    "SECTOR": "ZJX88",
    "MAP": 15
  },
  {
    "SECTOR": "ZKC02",
    "MAP": 19
  },
  {
    "SECTOR": "ZKC03",
    "MAP": 19
  },
  {
    "SECTOR": "ZKC06",
    "MAP": 20
  },
  {
    "SECTOR": "ZKC07",
    "MAP": 18
  },
  {
    "SECTOR": "ZKC12",
    "MAP": 14
  },
  {
    "SECTOR": "ZKC14",
    "MAP": 15
  },
  {
    "SECTOR": "ZKC20",
    "MAP": 18
  },
  {
    "SECTOR": "ZKC21",
    "MAP": 18
  },
  {
    "SECTOR": "ZKC22",
    "MAP": 18
  },
  {
    "SECTOR": "ZKC23",
    "MAP": 18
  },
  {
    "SECTOR": "ZKC24",
    "MAP": 19
  },
  {
    "SECTOR": "ZKC26",
    "MAP": 21
  },
  {
    "SECTOR": "ZKC27",
    "MAP": 18
  },
  {
    "SECTOR": "ZKC28",
    "MAP": 16
  },
  {
    "SECTOR": "ZKC29",
    "MAP": 18
  },
  {
    "SECTOR": "ZKC30",
    "MAP": 18
  },
  {
    "SECTOR": "ZKC31",
    "MAP": 18
  },
  {
    "SECTOR": "ZKC32",
    "MAP": 15
  },
  {
    "SECTOR": "ZKC33",
    "MAP": 17
  },
  {
    "SECTOR": "ZKC40",
    "MAP": 14
  },
  {
    "SECTOR": "ZKC41",
    "MAP": 15
  },
  {
    "SECTOR": "ZKC42",
    "MAP": 17
  },
  {
    "SECTOR": "ZKC44",
    "MAP": 17
  },
  {
    "SECTOR": "ZKC46",
    "MAP": 17
  },
  {
    "SECTOR": "ZKC47",
    "MAP": 15
  },
  {
    "SECTOR": "ZKC48",
    "MAP": 18
  },
  {
    "SECTOR": "ZKC50",
    "MAP": 16
  },
  {
    "SECTOR": "ZKC52",
    "MAP": 12
  },
  {
    "SECTOR": "ZKC53",
    "MAP": 12
  },
  {
    "SECTOR": "ZKC54",
    "MAP": 14
  },
  {
    "SECTOR": "ZKC58",
    "MAP": 13
  },
  {
    "SECTOR": "ZKC59",
    "MAP": 17
  },
  {
    "SECTOR": "ZKC62",
    "MAP": 14
  },
  {
    "SECTOR": "ZKC66",
    "MAP": 14
  },
  {
    "SECTOR": "ZKC67",
    "MAP": 17
  },
  {
    "SECTOR": "ZKC68",
    "MAP": 13
  },
  {
    "SECTOR": "ZKC72",
    "MAP": 15
  },
  {
    "SECTOR": "ZKC84",
    "MAP": 17
  },
  {
    "SECTOR": "ZKC90",
    "MAP": 17
  },
  {
    "SECTOR": "ZKC92",
    "MAP": 19
  },
  {
    "SECTOR": "ZKC94",
    "MAP": 19
  },
  {
    "SECTOR": "ZKC97",
    "MAP": 18
  },
  {
    "SECTOR": "ZKC98",
    "MAP": 15
  },
  {
    "SECTOR": "ZLA03",
    "MAP": 12
  },
  {
    "SECTOR": "ZLA04",
    "MAP": 13
  },
  {
    "SECTOR": "ZLA06",
    "MAP": 14
  },
  {
    "SECTOR": "ZLA07",
    "MAP": 11
  },
  {
    "SECTOR": "ZLA08",
    "MAP": 15
  },
  {
    "SECTOR": "ZLA09",
    "MAP": 12
  },
  {
    "SECTOR": "ZLA10",
    "MAP": 14
  },
  {
    "SECTOR": "ZLA12",
    "MAP": 13
  },
  {
    "SECTOR": "ZLA13",
    "MAP": 10
  },
  {
    "SECTOR": "ZLA14",
    "MAP": 14
  },
  {
    "SECTOR": "ZLA15",
    "MAP": 14
  },
  {
    "SECTOR": "ZLA16",
    "MAP": 14
  },
  {
    "SECTOR": "ZLA17",
    "MAP": 14
  },
  {
    "SECTOR": "ZLA18",
    "MAP": 12
  },
  {
    "SECTOR": "ZLA19",
    "MAP": 12
  },
  {
    "SECTOR": "ZLA20",
    "MAP": 12
  },
  {
    "SECTOR": "ZLA21",
    "MAP": 12
  },
  {
    "SECTOR": "ZLA22",
    "MAP": 12
  },
  {
    "SECTOR": "ZLA25",
    "MAP": 15
  },
  {
    "SECTOR": "ZLA26",
    "MAP": 14
  },
  {
    "SECTOR": "ZLA27",
    "MAP": 15
  },
  {
    "SECTOR": "ZLA28",
    "MAP": 12
  },
  {
    "SECTOR": "ZLA30",
    "MAP": 13
  },
  {
    "SECTOR": "ZLA31",
    "MAP": 14
  },
  {
    "SECTOR": "ZLA34",
    "MAP": 16
  },
  {
    "SECTOR": "ZLA35",
    "MAP": 13
  },
  {
    "SECTOR": "ZLA36",
    "MAP": 14
  },
  {
    "SECTOR": "ZLA37",
    "MAP": 17
  },
  {
    "SECTOR": "ZLA38",
    "MAP": 16
  },
  {
    "SECTOR": "ZLA39",
    "MAP": 16
  },
  {
    "SECTOR": "ZLA40",
    "MAP": 15
  },
  {
    "SECTOR": "ZLA53",
    "MAP": 14
  },
  {
    "SECTOR": "ZLA54",
    "MAP": 14
  },
  {
    "SECTOR": "ZLA55",
    "MAP": 16
  },
  {
    "SECTOR": "ZLA60",
    "MAP": 13
  },
  {
    "SECTOR": "ZLC03",
    "MAP": 18
  },
  {
    "SECTOR": "ZLC04",
    "MAP": 19
  },
  {
    "SECTOR": "ZLC05",
    "MAP": 20
  },
  {
    "SECTOR": "ZLC06",
    "MAP": 19
  },
  {
    "SECTOR": "ZLC07",
    "MAP": 12
  },
  {
    "SECTOR": "ZLC08",
    "MAP": 16
  },
  {
    "SECTOR": "ZLC11",
    "MAP": 13
  },
  {
    "SECTOR": "ZLC14",
    "MAP": 12
  },
  {
    "SECTOR": "ZLC15",
    "MAP": 18
  },
  {
    "SECTOR": "ZLC16",
    "MAP": 18
  },
  {
    "SECTOR": "ZLC17",
    "MAP": 21
  },
  {
    "SECTOR": "ZLC18",
    "MAP": 19
  },
  {
    "SECTOR": "ZLC19",
    "MAP": 16
  },
  {
    "SECTOR": "ZLC20",
    "MAP": 25
  },
  {
    "SECTOR": "ZLC29",
    "MAP": 15
  },
  {
    "SECTOR": "ZLC30",
    "MAP": 18
  },
  {
    "SECTOR": "ZLC31",
    "MAP": 15
  },
  {
    "SECTOR": "ZLC32",
    "MAP": 14
  },
  {
    "SECTOR": "ZLC33",
    "MAP": 15
  },
  {
    "SECTOR": "ZLC34",
    "MAP": 17
  },
  {
    "SECTOR": "ZLC39",
    "MAP": 23
  },
  {
    "SECTOR": "ZLC40",
    "MAP": 17
  },
  {
    "SECTOR": "ZLC41",
    "MAP": 24
  },
  {
    "SECTOR": "ZLC42",
    "MAP": 21
  },
  {
    "SECTOR": "ZLC43",
    "MAP": 21
  },
  {
    "SECTOR": "ZLC44",
    "MAP": 15
  },
  {
    "SECTOR": "ZLC45",
    "MAP": 21
  },
  {
    "SECTOR": "ZLC46",
    "MAP": 17
  },
  {
    "SECTOR": "ZLC47",
    "MAP": 21
  },
  {
    "SECTOR": "ZLC99",
    "MAP": 15
  },
  {
    "SECTOR": "ZMA01",
    "MAP": 15
  },
  {
    "SECTOR": "ZMA02",
    "MAP": 19
  },
  {
    "SECTOR": "ZMA04",
    "MAP": 13
  },
  {
    "SECTOR": "ZMA05",
    "MAP": 15
  },
  {
    "SECTOR": "ZMA06",
    "MAP": 14
  },
  {
    "SECTOR": "ZMA07",
    "MAP": 12
  },
  {
    "SECTOR": "ZMA08",
    "MAP": 19
  },
  {
    "SECTOR": "ZMA17",
    "MAP": 18
  },
  {
    "SECTOR": "ZMA18",
    "MAP": 18
  },
  {
    "SECTOR": "ZMA19",
    "MAP": 20
  },
  {
    "SECTOR": "ZMA20",
    "MAP": 14
  },
  {
    "SECTOR": "ZMA21",
    "MAP": 20
  },
  {
    "SECTOR": "ZMA22",
    "MAP": 11
  },
  {
    "SECTOR": "ZMA23",
    "MAP": 11
  },
  {
    "SECTOR": "ZMA24",
    "MAP": 14
  },
  {
    "SECTOR": "ZMA25",
    "MAP": 18
  },
  {
    "SECTOR": "ZMA26",
    "MAP": 19
  },
  {
    "SECTOR": "ZMA40",
    "MAP": 21
  },
  {
    "SECTOR": "ZMA41",
    "MAP": 14
  },
  {
    "SECTOR": "ZMA42",
    "MAP": 11
  },
  {
    "SECTOR": "ZMA43",
    "MAP": 21
  },
  {
    "SECTOR": "ZMA44",
    "MAP": 11
  },
  {
    "SECTOR": "ZMA46",
    "MAP": 11
  },
  {
    "SECTOR": "ZMA47",
    "MAP": 15
  },
  {
    "SECTOR": "ZMA58",
    "MAP": 21
  },
  {
    "SECTOR": "ZMA59",
    "MAP": 20
  },
  {
    "SECTOR": "ZMA60",
    "MAP": 21
  },
  {
    "SECTOR": "ZMA61",
    "MAP": 18
  },
  {
    "SECTOR": "ZMA62",
    "MAP": 21
  },
  {
    "SECTOR": "ZMA63",
    "MAP": 21
  },
  {
    "SECTOR": "ZMA64",
    "MAP": 13
  },
  {
    "SECTOR": "ZMA65",
    "MAP": 17
  },
  {
    "SECTOR": "ZMA66",
    "MAP": 17
  },
  {
    "SECTOR": "ZMA67",
    "MAP": 14
  },
  {
    "SECTOR": "ZMA68",
    "MAP": 15
  },
  {
    "SECTOR": "ZME01",
    "MAP": 12
  },
  {
    "SECTOR": "ZME02",
    "MAP": 12
  },
  {
    "SECTOR": "ZME03",
    "MAP": 15
  },
  {
    "SECTOR": "ZME04",
    "MAP": 15
  },
  {
    "SECTOR": "ZME05",
    "MAP": 18
  },
  {
    "SECTOR": "ZME07",
    "MAP": 15
  },
  {
    "SECTOR": "ZME12",
    "MAP": 13
  },
  {
    "SECTOR": "ZME13",
    "MAP": 13
  },
  {
    "SECTOR": "ZME14",
    "MAP": 15
  },
  {
    "SECTOR": "ZME15",
    "MAP": 12
  },
  {
    "SECTOR": "ZME19",
    "MAP": 20
  },
  {
    "SECTOR": "ZME20",
    "MAP": 21
  },
  {
    "SECTOR": "ZME21",
    "MAP": 19
  },
  {
    "SECTOR": "ZME22",
    "MAP": 18
  },
  {
    "SECTOR": "ZME25",
    "MAP": 16
  },
  {
    "SECTOR": "ZME26",
    "MAP": 15
  },
  {
    "SECTOR": "ZME27",
    "MAP": 17
  },
  {
    "SECTOR": "ZME28",
    "MAP": 17
  },
  {
    "SECTOR": "ZME30",
    "MAP": 16
  },
  {
    "SECTOR": "ZME31",
    "MAP": 18
  },
  {
    "SECTOR": "ZME32",
    "MAP": 18
  },
  {
    "SECTOR": "ZME33",
    "MAP": 20
  },
  {
    "SECTOR": "ZME34",
    "MAP": 20
  },
  {
    "SECTOR": "ZME35",
    "MAP": 19
  },
  {
    "SECTOR": "ZME40",
    "MAP": 13
  },
  {
    "SECTOR": "ZME41",
    "MAP": 13
  },
  {
    "SECTOR": "ZME42",
    "MAP": 15
  },
  {
    "SECTOR": "ZME43",
    "MAP": 22
  },
  {
    "SECTOR": "ZME44",
    "MAP": 20
  },
  {
    "SECTOR": "ZME45",
    "MAP": 19
  },
  {
    "SECTOR": "ZME46",
    "MAP": 20
  },
  {
    "SECTOR": "ZME60",
    "MAP": 16
  },
  {
    "SECTOR": "ZME61",
    "MAP": 21
  },
  {
    "SECTOR": "ZME62",
    "MAP": 16
  },
  {
    "SECTOR": "ZME63",
    "MAP": 17
  },
  {
    "SECTOR": "ZME65",
    "MAP": 18
  },
  {
    "SECTOR": "ZME66",
    "MAP": 15
  },
  {
    "SECTOR": "ZME67",
    "MAP": 17
  },
  {
    "SECTOR": "ZMP01",
    "MAP": 14
  },
  {
    "SECTOR": "ZMP02",
    "MAP": 17
  },
  {
    "SECTOR": "ZMP03",
    "MAP": 14
  },
  {
    "SECTOR": "ZMP04",
    "MAP": 19
  },
  {
    "SECTOR": "ZMP05",
    "MAP": 17
  },
  {
    "SECTOR": "ZMP06",
    "MAP": 13
  },
  {
    "SECTOR": "ZMP07",
    "MAP": 16
  },
  {
    "SECTOR": "ZMP08",
    "MAP": 14
  },
  {
    "SECTOR": "ZMP09",
    "MAP": 16
  },
  {
    "SECTOR": "ZMP10",
    "MAP": 14
  },
  {
    "SECTOR": "ZMP11",
    "MAP": 21
  },
  {
    "SECTOR": "ZMP12",
    "MAP": 21
  },
  {
    "SECTOR": "ZMP13",
    "MAP": 21
  },
  {
    "SECTOR": "ZMP14",
    "MAP": 19
  },
  {
    "SECTOR": "ZMP15",
    "MAP": 16
  },
  {
    "SECTOR": "ZMP16",
    "MAP": 16
  },
  {
    "SECTOR": "ZMP17",
    "MAP": 18
  },
  {
    "SECTOR": "ZMP18",
    "MAP": 19
  },
  {
    "SECTOR": "ZMP19",
    "MAP": 18
  },
  {
    "SECTOR": "ZMP20",
    "MAP": 21
  },
  {
    "SECTOR": "ZMP21",
    "MAP": 11
  },
  {
    "SECTOR": "ZMP23",
    "MAP": 20
  },
  {
    "SECTOR": "ZMP24",
    "MAP": 19
  },
  {
    "SECTOR": "ZMP25",
    "MAP": 19
  },
  {
    "SECTOR": "ZMP26",
    "MAP": 15
  },
  {
    "SECTOR": "ZMP27",
    "MAP": 15
  },
  {
    "SECTOR": "ZMP28",
    "MAP": 21
  },
  {
    "SECTOR": "ZMP29",
    "MAP": 21
  },
  {
    "SECTOR": "ZMP30",
    "MAP": 20
  },
  {
    "SECTOR": "ZMP32",
    "MAP": 21
  },
  {
    "SECTOR": "ZMP33",
    "MAP": 20
  },
  {
    "SECTOR": "ZMP34",
    "MAP": 18
  },
  {
    "SECTOR": "ZMP36",
    "MAP": 15
  },
  {
    "SECTOR": "ZMP37",
    "MAP": 14
  },
  {
    "SECTOR": "ZMP38",
    "MAP": 18
  },
  {
    "SECTOR": "ZMP39",
    "MAP": 21
  },
  {
    "SECTOR": "ZMP40",
    "MAP": 21
  },
  {
    "SECTOR": "ZMP42",
    "MAP": 25
  },
  {
    "SECTOR": "ZMP43",
    "MAP": 19
  },
  {
    "SECTOR": "ZMP44",
    "MAP": 21
  },
  {
    "SECTOR": "ZMP46",
    "MAP": 21
  },
  {
    "SECTOR": "ZMP83",
    "MAP": 15
  },
  {
    "SECTOR": "ZNY08",
    "MAP": 12
  },
  {
    "SECTOR": "ZNY09",
    "MAP": 16
  },
  {
    "SECTOR": "ZNY10",
    "MAP": 16
  },
  {
    "SECTOR": "ZNY25",
    "MAP": 11
  },
  {
    "SECTOR": "ZNY26",
    "MAP": 11
  },
  {
    "SECTOR": "ZNY27",
    "MAP": 11
  },
  {
    "SECTOR": "ZNY2E",
    "MAP": 12
  },
  {
    "SECTOR": "ZNY2W",
    "MAP": 12
  },
  {
    "SECTOR": "ZNY34",
    "MAP": 15
  },
  {
    "SECTOR": "ZNY35",
    "MAP": 11
  },
  {
    "SECTOR": "ZNY36",
    "MAP": 12
  },
  {
    "SECTOR": "ZNY39",
    "MAP": 11
  },
  {
    "SECTOR": "ZNY42",
    "MAP": 13
  },
  {
    "SECTOR": "ZNY49",
    "MAP": 12
  },
  {
    "SECTOR": "ZNY50",
    "MAP": 15
  },
  {
    "SECTOR": "ZNY51",
    "MAP": 14
  },
  {
    "SECTOR": "ZNY55",
    "MAP": 11
  },
  {
    "SECTOR": "ZNY56",
    "MAP": 12
  },
  {
    "SECTOR": "ZNY65",
    "MAP": 18
  },
  {
    "SECTOR": "ZNY66",
    "MAP": 10
  },
  {
    "SECTOR": "ZNY68",
    "MAP": 10
  },
  {
    "SECTOR": "ZNY70",
    "MAP": 12
  },
  {
    "SECTOR": "ZNY71",
    "MAP": 12
  },
  {
    "SECTOR": "ZNY72",
    "MAP": 15
  },
  {
    "SECTOR": "ZNY73",
    "MAP": 14
  },
  {
    "SECTOR": "ZNY74",
    "MAP": 13
  },
  {
    "SECTOR": "ZNY75",
    "MAP": 15
  },
  {
    "SECTOR": "ZNY80",
    "MAP": 12
  },
  {
    "SECTOR": "ZNY81",
    "MAP": 14
  },
  {
    "SECTOR": "ZNY82",
    "MAP": 15
  },
  {
    "SECTOR": "ZNY83",
    "MAP": 15
  },
  {
    "SECTOR": "ZNY85",
    "MAP": 18
  },
  {
    "SECTOR": "ZNY86",
    "MAP": 18
  },
  {
    "SECTOR": "ZNY87",
    "MAP": 12
  },
  {
    "SECTOR": "ZNY88",
    "MAP": 12
  },
  {
    "SECTOR": "ZNY89",
    "MAP": 12
  },
  {
    "SECTOR": "ZNY90",
    "MAP": 12
  },
  {
    "SECTOR": "ZNY91",
    "MAP": 15
  },
  {
    "SECTOR": "ZNY92",
    "MAP": 13
  },
  {
    "SECTOR": "ZNY93",
    "MAP": 15
  },
  {
    "SECTOR": "ZNY98",
    "MAP": 15
  },
  {
    "SECTOR": "ZNY99",
    "MAP": 15
  },
  {
    "SECTOR": "ZOA10",
    "MAP": 15
  },
  {
    "SECTOR": "ZOA11",
    "MAP": 12
  },
  {
    "SECTOR": "ZOA13",
    "MAP": 15
  },
  {
    "SECTOR": "ZOA14",
    "MAP": 17
  },
  {
    "SECTOR": "ZOA15",
    "MAP": 15
  },
  {
    "SECTOR": "ZOA16",
    "MAP": 14
  },
  {
    "SECTOR": "ZOA22",
    "MAP": 17
  },
  {
    "SECTOR": "ZOA29",
    "MAP": 17
  },
  {
    "SECTOR": "ZOA30",
    "MAP": 15
  },
  {
    "SECTOR": "ZOA31",
    "MAP": 17
  },
  {
    "SECTOR": "ZOA32",
    "MAP": 16
  },
  {
    "SECTOR": "ZOA33",
    "MAP": 18
  },
  {
    "SECTOR": "ZOA34",
    "MAP": 16
  },
  {
    "SECTOR": "ZOA35",
    "MAP": 20
  },
  {
    "SECTOR": "ZOA36",
    "MAP": 15
  },
  {
    "SECTOR": "ZOA40",
    "MAP": 11
  },
  {
    "SECTOR": "ZOA41",
    "MAP": 12
  },
  {
    "SECTOR": "ZOA42",
    "MAP": 18
  },
  {
    "SECTOR": "ZOA43",
    "MAP": 18
  },
  {
    "SECTOR": "ZOA44",
    "MAP": 12
  },
  {
    "SECTOR": "ZOA45",
    "MAP": 12
  },
  {
    "SECTOR": "ZOA46",
    "MAP": 12
  },
  {
    "SECTOR": "ZOB02",
    "MAP": 11
  },
  {
    "SECTOR": "ZOB03",
    "MAP": 13
  },
  {
    "SECTOR": "ZOB04",
    "MAP": 13
  },
  {
    "SECTOR": "ZOB06",
    "MAP": 13
  },
  {
    "SECTOR": "ZOB07",
    "MAP": 11
  },
  {
    "SECTOR": "ZOB08",
    "MAP": 13
  },
  {
    "SECTOR": "ZOB12",
    "MAP": 14
  },
  {
    "SECTOR": "ZOB14",
    "MAP": 14
  },
  {
    "SECTOR": "ZOB15",
    "MAP": 14
  },
  {
    "SECTOR": "ZOB16",
    "MAP": 16
  },
  {
    "SECTOR": "ZOB18",
    "MAP": 17
  },
  {
    "SECTOR": "ZOB19",
    "MAP": 16
  },
  {
    "SECTOR": "ZOB20",
    "MAP": 13
  },
  {
    "SECTOR": "ZOB21",
    "MAP": 11
  },
  {
    "SECTOR": "ZOB26",
    "MAP": 20
  },
  {
    "SECTOR": "ZOB27",
    "MAP": 14
  },
  {
    "SECTOR": "ZOB28",
    "MAP": 13
  },
  {
    "SECTOR": "ZOB29",
    "MAP": 16
  },
  {
    "SECTOR": "ZOB31",
    "MAP": 16
  },
  {
    "SECTOR": "ZOB33",
    "MAP": 12
  },
  {
    "SECTOR": "ZOB36",
    "MAP": 14
  },
  {
    "SECTOR": "ZOB37",
    "MAP": 13
  },
  {
    "SECTOR": "ZOB38",
    "MAP": 19
  },
  {
    "SECTOR": "ZOB39",
    "MAP": 18
  },
  {
    "SECTOR": "ZOB45",
    "MAP": 18
  },
  {
    "SECTOR": "ZOB46",
    "MAP": 13
  },
  {
    "SECTOR": "ZOB47",
    "MAP": 13
  },
  {
    "SECTOR": "ZOB48",
    "MAP": 14
  },
  {
    "SECTOR": "ZOB49",
    "MAP": 15
  },
  {
    "SECTOR": "ZOB50",
    "MAP": 18
  },
  {
    "SECTOR": "ZOB53",
    "MAP": 15
  },
  {
    "SECTOR": "ZOB55",
    "MAP": 13
  },
  {
    "SECTOR": "ZOB57",
    "MAP": 15
  },
  {
    "SECTOR": "ZOB58",
    "MAP": 17
  },
  {
    "SECTOR": "ZOB59",
    "MAP": 14
  },
  {
    "SECTOR": "ZOB64",
    "MAP": 17
  },
  {
    "SECTOR": "ZOB66",
    "MAP": 13
  },
  {
    "SECTOR": "ZOB67",
    "MAP": 15
  },
  {
    "SECTOR": "ZOB68",
    "MAP": 13
  },
  {
    "SECTOR": "ZOB69",
    "MAP": 14
  },
  {
    "SECTOR": "ZOB70",
    "MAP": 14
  },
  {
    "SECTOR": "ZOB73",
    "MAP": 15
  },
  {
    "SECTOR": "ZOB75",
    "MAP": 16
  },
  {
    "SECTOR": "ZOB77",
    "MAP": 13
  },
  {
    "SECTOR": "ZOB79",
    "MAP": 18
  },
  {
    "SECTOR": "ZSE01",
    "MAP": 12
  },
  {
    "SECTOR": "ZSE02",
    "MAP": 14
  },
  {
    "SECTOR": "ZSE03",
    "MAP": 15
  },
  {
    "SECTOR": "ZSE04",
    "MAP": 13
  },
  {
    "SECTOR": "ZSE05",
    "MAP": 15
  },
  {
    "SECTOR": "ZSE06",
    "MAP": 15
  },
  {
    "SECTOR": "ZSE07",
    "MAP": 15
  },
  {
    "SECTOR": "ZSE08",
    "MAP": 15
  },
  {
    "SECTOR": "ZSE09",
    "MAP": 15
  },
  {
    "SECTOR": "ZSE10",
    "MAP": 12
  },
  {
    "SECTOR": "ZSE11",
    "MAP": 17
  },
  {
    "SECTOR": "ZSE12",
    "MAP": 13
  },
  {
    "SECTOR": "ZSE13",
    "MAP": 18
  },
  {
    "SECTOR": "ZSE14",
    "MAP": 18
  },
  {
    "SECTOR": "ZSE15",
    "MAP": 18
  },
  {
    "SECTOR": "ZSE16",
    "MAP": 19
  },
  {
    "SECTOR": "ZSE18",
    "MAP": 14
  },
  {
    "SECTOR": "ZSE30",
    "MAP": 11
  },
  {
    "SECTOR": "ZSE31",
    "MAP": 13
  },
  {
    "SECTOR": "ZSE32",
    "MAP": 15
  },
  {
    "SECTOR": "ZSE34",
    "MAP": 13
  },
  {
    "SECTOR": "ZSE35",
    "MAP": 8
  },
  {
    "SECTOR": "ZSE36",
    "MAP": 11
  },
  {
    "SECTOR": "ZSE42",
    "MAP": 15
  },
  {
    "SECTOR": "ZSE46",
    "MAP": 17
  },
  {
    "SECTOR": "ZSE47",
    "MAP": 13
  },
  {
    "SECTOR": "ZSE48",
    "MAP": 17
  },
  {
    "SECTOR": "ZTL01",
    "MAP": 15
  },
  {
    "SECTOR": "ZTL02",
    "MAP": 21
  },
  {
    "SECTOR": "ZTL03",
    "MAP": 19
  },
  {
    "SECTOR": "ZTL04",
    "MAP": 13
  },
  {
    "SECTOR": "ZTL05",
    "MAP": 14
  },
  {
    "SECTOR": "ZTL06",
    "MAP": 16
  },
  {
    "SECTOR": "ZTL07",
    "MAP": 20
  },
  {
    "SECTOR": "ZTL08",
    "MAP": 21
  },
  {
    "SECTOR": "ZTL09",
    "MAP": 14
  },
  {
    "SECTOR": "ZTL10",
    "MAP": 14
  },
  {
    "SECTOR": "ZTL11",
    "MAP": 15
  },
  {
    "SECTOR": "ZTL12",
    "MAP": 12
  },
  {
    "SECTOR": "ZTL13",
    "MAP": 14
  },
  {
    "SECTOR": "ZTL14",
    "MAP": 13
  },
  {
    "SECTOR": "ZTL15",
    "MAP": 20
  },
  {
    "SECTOR": "ZTL16",
    "MAP": 14
  },
  {
    "SECTOR": "ZTL18",
    "MAP": 15
  },
  {
    "SECTOR": "ZTL19",
    "MAP": 14
  },
  {
    "SECTOR": "ZTL20",
    "MAP": 15
  },
  {
    "SECTOR": "ZTL21",
    "MAP": 11
  },
  {
    "SECTOR": "ZTL22",
    "MAP": 16
  },
  {
    "SECTOR": "ZTL23",
    "MAP": 18
  },
  {
    "SECTOR": "ZTL24",
    "MAP": 12
  },
  {
    "SECTOR": "ZTL25",
    "MAP": 15
  },
  {
    "SECTOR": "ZTL27",
    "MAP": 20
  },
  {
    "SECTOR": "ZTL28",
    "MAP": 21
  },
  {
    "SECTOR": "ZTL29",
    "MAP": 12
  },
  {
    "SECTOR": "ZTL30",
    "MAP": 10
  },
  {
    "SECTOR": "ZTL31",
    "MAP": 13
  },
  {
    "SECTOR": "ZTL32",
    "MAP": 13
  },
  {
    "SECTOR": "ZTL33",
    "MAP": 16
  },
  {
    "SECTOR": "ZTL34",
    "MAP": 15
  },
  {
    "SECTOR": "ZTL36",
    "MAP": 17
  },
  {
    "SECTOR": "ZTL37",
    "MAP": 16
  },
  {
    "SECTOR": "ZTL38",
    "MAP": 12
  },
  {
    "SECTOR": "ZTL39",
    "MAP": 16
  },
  {
    "SECTOR": "ZTL40",
    "MAP": 18
  },
  {
    "SECTOR": "ZTL41",
    "MAP": 13
  },
  {
    "SECTOR": "ZTL42",
    "MAP": 15
  },
  {
    "SECTOR": "ZTL43",
    "MAP": 16
  },
  {
    "SECTOR": "ZTL44",
    "MAP": 12
  },
  {
    "SECTOR": "ZTL45",
    "MAP": 12
  },
  {
    "SECTOR": "ZTL47",
    "MAP": 12
  },
  {
    "SECTOR": "ZTL48",
    "MAP": 15
  },
  {
    "SECTOR": "ZTL49",
    "MAP": 15
  },
  {
    "SECTOR": "ZTL50",
    "MAP": 15
  },
  {
    "SECTOR": "ZTL59",
    "MAP": 20
  }
]