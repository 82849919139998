import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { Form, Button, Segment, Modal, Table, Header, Loader, Input, Popup, Divider, Message, Container } from 'semantic-ui-react';
import { ReactSortable } from 'react-sortablejs';

import PageHeader from './PageHeader';

import { isFormError } from './utils';
import { useFacilities, useAddFacility, useUpdateFacility, useDeleteFacility } from './api/tfms';

function ArrivalGateEditor({ gate, onUpdate, onDelete }) {

    const [edit, setEdit] = useState(false);

    const [name, setName] = useState(undefined);
    const [arrivals, setArrivals] = useState(undefined);

    const [formError, setFormError] = useState(true);

    function onSubmit() {

        let a = arrivals.toUpperCase().split(',');

        let data = {
            name: name.toUpperCase(), arrivals: a
        };

        onUpdate(data);
        setEdit(false);

    }

    useEffect(() => {

        if (!gate) return;

        setName(gate.name);
        setArrivals(gate.arrivals.join(','));

    }, [gate]);

    useEffect(() => {

        if (isFormError([name, arrivals])) setFormError(true);
        else setFormError(false);

    }, [name, arrivals]);

    return (

        !edit ?
            <Table.Row key={gate.name}>
                <Table.Cell className='uppercase'>{gate.name}</Table.Cell>
                <Table.Cell className='uppercase'>{gate.arrivals.join(',')}</Table.Cell>
                <Table.Cell>
                    <Button.Group>
                        <Button onClick={() => setEdit(true)} color='yellow' icon='edit' />
                        <Button onClick={() => onDelete(gate.name)} color='red' icon='delete' />
                    </Button.Group>
                </Table.Cell>
            </Table.Row>
            :
            <Table.Row key={gate.name}>
                <Table.Cell><Input className='uppercase' label='Name' value={name} onChange={(_, { value }) => setName(value)} /></Table.Cell>
                <Table.Cell><Input className='uppercase' label='Arrivals' value={arrivals} onChange={(_, { value }) => setArrivals(value)} /></Table.Cell>
                <Table.Cell>
                    <Button.Group>
                        <Button onClick={() => setEdit(false)} color='yellow' icon='undo' />
                        <Button disabled={formError} onClick={onSubmit} color='green' icon='check' />
                    </Button.Group>
                </Table.Cell>
            </Table.Row>

    )

}
function ArrivalGatesModal({ enabled = true, iata = '', gates, onAdd }) {

    const [showModal, setShowModal] = useState(false);
    const [gatesList, setGatesList] = useState([]);
    const [totalGates, setTotalGates] = useState(0);

    const [name, setName] = useState(undefined);
    const [arrivals, setArrivals] = useState(undefined);

    const [isSubmitting, setSubmitting] = useState(false);

    useEffect(() => {

        if (!_.isArray(gatesList)) setTotalGates(0);
        else setTotalGates(gatesList.length - 1 < 0 ? 0 : gatesList.length - 1);

    }, [gatesList]);

    useEffect(() => {

        if (!gates) return;

        let g = _.map(gates, gate => {

            gate.id = gate.name;
            gate.selected = false;
            gate.chosen = false;
            gate.filtered = false;

            return gate;

        });
        setGatesList(g);

    }, [gates]);

    useEffect(() => {

        if (isSubmitting) {

            let data = {
                name: name.toUpperCase(), arrivals: arrivals.toUpperCase().split(',')
            };

            setName('');
            setArrivals('');

            setGatesList(prevState => {
                return [...prevState, data];
            });

            setSubmitting(false);

        }

    }, [isSubmitting, name, arrivals]);

    function onRemove(name) {

        let t = _.filter(gatesList, f => { return f.name !== name });
        setGatesList(t);

    }

    function onUpdate(data) {

        let idx = _.findIndex(gatesList, { name: data.name });
        if (idx === -1) {

            setGatesList(prevState => {
                return [...prevState, data];
            });

        } else {

            let t = gatesList;
            t[idx] = data;
            setGatesList(t);

        }

    }

    function handleClick() {
        onAdd(gatesList);
        setShowModal(false);
    }

    return (
        <Modal trigger={<Button disabled={!enabled} color='green' onClick={() => setShowModal(true)} content={`Arrival Gates (${totalGates})`} />}
            onClose={() => { setShowModal(false) }} open={showModal}>

            <Modal.Header content={`Edit Arrival Gates (${iata.toUpperCase()})`} />

            <Modal.Content>

                <Message info>
                    <p>Enter Name of the Arrival Gate.</p>
                    Enter Arrivals in one of the following formats:
                    <Message.List>
                        <Message.Item>for STARs: (STARNAME)([0-9]) ie (ROBUC)([0-9])</Message.Item>
                        <Message.Item>for Transition Specific STARs: (TRANSITION STARNAME)([0-9]) ie (HBUDA RAVNN)([0-9])</Message.Item>
                        <Message.Item>for Fixes: (FIXNAME) ie (FREDO)</Message.Item>
                    </Message.List>
                    <p>You may enter multiple formats separated by a comma (,).</p>
                    <p>Order of Gates can be changed by dragging the row(s) accordingly. Gates will appear left-to-right with the top of the list first.</p>
                    <b>NOTE: Ensure the 'OTHER' gate is always at the bottom of the list.</b>
                </Message>
                {
                    (_.isArray(gatesList) && gatesList.length < 1) ? null :
                        <Table>
                            <Table.Body>
                                <ReactSortable list={gatesList} setList={(newState) => setGatesList(newState)}>
                                    {
                                        _.map(gatesList, g => {

                                            // if (g.name === 'OTHER') return null;
                                            return <ArrivalGateEditor key={g.id} gate={g} onUpdate={onUpdate} onDelete={onRemove} />

                                        })
                                    }
                                </ReactSortable>
                            </Table.Body>
                        </Table>
                }
                <Divider />
                <Header as='h3' content='Add New Arrival Gate' />

                <Form>
                    <Form.Input className='uppercase' label='Name' onChange={(_, { value }) => setName(value)} />
                    <Form.Input className='uppercase' label='Arrivals' onChange={(_, { value }) => setArrivals(value)} />
                    <Button loading={isSubmitting} onClick={() => !isSubmitting ? setSubmitting(true) : null}>{isSubmitting ? 'Adding' : 'Add'} </Button>
                </Form>

            </Modal.Content>

            <Modal.Actions>
                <Button color='yellow' onClick={() => setShowModal(false)} content='Cancel' />
                <Button color='green' onClick={handleClick} content='Save' />
            </Modal.Actions>

        </Modal>
    )

}

function AirportModal() {

    const [showModal, setShowModal] = useState(false);

    const [icao, setIcao] = useState(undefined);
    const [iata, setIata] = useState(undefined);
    const [latitude, setLatitude] = useState(undefined);
    const [longitude, setLongitude] = useState(undefined);
    const [artcc, setARTCC] = useState(undefined);
    const [headings, setHeadings] = useState(undefined);

    const [vfr, setVFR] = useState(undefined);
    const [mvfr, setMVFR] = useState(undefined);
    const [ifr, setIFR] = useState(undefined);
    const [lifr, setLIFR] = useState(undefined);

    const [arrivalgates, setArrivalGates] = useState([]);

    const [formError, setFormError] = useState(true);
    const [enableGatesModal, setEnableGatesModal] = useState(false);

    const { mutateAsync: addFacility } = useAddFacility();

    async function onSubmit() {
        try {

            let configs = {
                parallel: {
                    vfr: Number(vfr),
                    mvfr: Number(mvfr),
                    ifr: Number(ifr),
                    lifr: Number(lifr)
                }
            };

            let gates = arrivalgates;
            if (!_.some(gates, {name: 'OTHER'})) {
                gates.push({
                    name: 'OTHER',
                    arrivals: ["."]
                });
            }

            let heading = [];
            let spl = headings.split(',');
            for (let h of spl) {
                heading.push(Number(h));
            }

            let data = {
                icao: icao.toUpperCase(), iata: iata.toUpperCase(), latitude: latitude, longitude: longitude,
                headings: heading, configs: configs, arrival_gates: gates, artcc: artcc.toUpperCase()
            };

            await addFacility(data);
            setShowModal(false);

        } catch (err) {
            return;
        }
    }

    useEffect(() => {

        if (isFormError([icao, iata, latitude, longitude, headings, vfr, mvfr, ifr, lifr])) {

            setFormError(true);
            setEnableGatesModal(false);

        } else {

            // if (isFormError([arrivalgates]))
            //     setFormError(true);
            // else
            //     setFormError(false);

            setFormError(false);
            setEnableGatesModal(true);

        }

    }, [icao, iata, latitude, longitude, headings, vfr, mvfr, ifr, lifr, arrivalgates]);

    return (
        <Modal centered trigger={<Button primary onClick={() => setShowModal(true)} content='Add Airport' />}
            onClose={() => setShowModal(false)} open={showModal}>

            <Modal.Header content='Add Airport' />

            <Modal.Content>
                <Form>

                    <Segment>

                        <Header as='h4' content='Identification' />
                        <Form.Group>
                            <Form.Input width={2} className='uppercase' placeholder='KMCI' label='ICAO' onChange={(_, { value }) => setIcao(value)} />
                            <Form.Input width={2} className='uppercase' placeholder='MCI' label='IATA' onChange={(_, { value }) => setIata(value)} />
                            <Form.Input width={2} className='uppercase' placeholder='KZKC' label='ARTCC' onChange={(_, { value }) => setARTCC(value)} />
                        </Form.Group>

                    </Segment>

                    <Segment>

                        <Header as='h4' content='Location' />
                        <Form.Group>
                            <Form.Input width={3} placeholder='40.764389' label='Latitude' onChange={(_, { value }) => setLatitude(value)} />
                            <Form.Input width={3} placeholder='-73.999786' label='Longitude' onChange={(_, { value }) => setLongitude(value)} />
                        </Form.Group>

                    </Segment>

                    <Segment>

                        <Header as='h4' content='Runway Headings' />
                        <Form.Group>
                            <Form.Input width={6} placeholder='90,270 no dupes/leading zero' label='Headings' onChange={(_, { value }) => setHeadings(value)} />
                        </Form.Group>

                    </Segment>

                    <Segment>

                        <Header as='h4' content='Arrival Rates' />
                        <Form.Group>
                            <Form.Input width={2} label='VFR' onChange={(_, { value }) => setVFR(value)} />
                            <Form.Input width={2} label='MVFR' onChange={(_, { value }) => setMVFR(value)} />
                            <Form.Input width={2} label='IFR' onChange={(_, { value }) => setIFR(value)} />
                            <Form.Input width={2} label='LIFR' onChange={(_, { value }) => setLIFR(value)} />
                        </Form.Group>

                    </Segment>

                    <ArrivalGatesModal iata={iata} enabled={enableGatesModal} gates={[]} onAdd={setArrivalGates} />

                </Form>
            </Modal.Content>

            <Modal.Actions>
                <Button onClick={() => setShowModal(false)} content='Cancel' />
                <Button disabled={formError} onClick={() => onSubmit()} content='Submit' />
            </Modal.Actions>
        </Modal>
    )

}

function AirportEditor({ facility }) {

    const [edit, setEdit] = useState(false);

    const [icao, setIcao] = useState(undefined);
    const [iata, setIata] = useState(undefined);
    const [artcc, setARTCC] = useState(undefined);
    const [latitude, setLatitude] = useState(undefined);
    const [longitude, setLongitude] = useState(undefined);
    const [headings, setHeadings] = useState(undefined);
    const [configs, setConfigs] = useState(undefined);
    const [arrivalgates, setArrivalGates] = useState([]);

    const [formError, setFormError] = useState(true);

    const { mutateAsync: updateFacility } = useUpdateFacility();
    const { mutateAsync: deleteFacility } = useDeleteFacility();

    function refreshArrivalGates(newGates) {

        setArrivalGates(newGates);

    }

    async function onSubmit() {
        try {

            let spl = configs.split(',');
            let c = {
                parallel: {
                    vfr: Number(spl[0]),
                    mvfr: Number(spl[1]),
                    ifr: Number(spl[2]),
                    lifr: Number(spl[3])
                }
            };

            let gates = arrivalgates;
            if (!_.some(gates, {name: 'OTHER'})) {
                gates.push({
                    name: 'OTHER',
                    arrivals: ["."]
                });
            }

            let heading = [];
            spl = headings.split(',');
            for (let h of spl) {
                heading.push(Number(h));
            }

            let data = {
                id: facility._id, icao: icao.toUpperCase(), iata: iata.toUpperCase(), latitude: latitude, longitude: longitude,
                headings: heading, configs: c, arrival_gates: gates, artcc: artcc.toUpperCase()
            };

            await updateFacility(data);
            setEdit(false);

        } catch (err) {
            console.log(err);
            return;
        }
    }

    useEffect(() => {

        if (isFormError([icao, iata, latitude, longitude, headings, configs]))
            setFormError(true);
        else
            setFormError(false);

    }, [icao, iata, latitude, longitude, headings, configs]);

    useEffect(() => {

        if (!facility) return;

        setIcao(facility.icao);
        setIata(facility.iata);
        setLatitude(facility.latitude);
        setLongitude(facility.longitude);
        setHeadings(facility.headings.join(','));
        setConfigs(Object.values(facility.configs.parallel).join(','));
        setArrivalGates(facility.arrival_gates);
        setARTCC(facility.artcc);

    }, [facility]);

    return (
        <Table.Row key={facility._id}>
            {
                !edit ?
                    <>
                        <Table.Cell>{facility.icao}</Table.Cell>
                        <Table.Cell>{facility.iata}</Table.Cell>
                        <Table.Cell>{`${facility.latitude}, ${facility.longitude}`}</Table.Cell>
                        <Table.Cell>{facility.artcc}</Table.Cell>
                        <Table.Cell>{facility.headings.join(',')}</Table.Cell>
                        <Table.Cell><ArrivalGatesModal iata={facility.iata} enabled={false} gates={facility.arrival_gates} onAdd={refreshArrivalGates} /></Table.Cell>
                        <Table.Cell>{Object.values(facility.configs.parallel).join(',')}</Table.Cell>
                        <Table.Cell collapsing>
                            <Button.Group>
                                <Popup basic content='Edit Facility'
                                    trigger={<Button icon='edit' color='yellow' onClick={() => { setEdit(true) }} />}
                                />
                                <Popup basic content='Delete Facility'
                                    trigger={<Button icon='remove' color='red' onClick={() => { deleteFacility(facility.shortname); }} />}
                                />
                            </Button.Group>
                        </Table.Cell>
                    </>
                    :
                    <>
                        <Table.Cell>
                            <Form.Input width={2} className='uppercase' value={icao} onChange={(_, { value }) => setIcao(value)} />
                        </Table.Cell>
                        <Table.Cell>
                            <Form.Input width={2} className='uppercase' value={iata} onChange={(_, { value }) => setIata(value)} />
                        </Table.Cell>
                        <Table.Cell>
                            <Form.Input width={3} value={latitude} placeholder='40.764389' onChange={(_, { value }) => setLatitude(value)} />
                            <Form.Input width={3} value={longitude} placeholder='-73.999786' onChange={(_, { value }) => setLongitude(value)} />
                        </Table.Cell>
                        <Table.Cell>
                            <Input width={2} className='uppercase' value={artcc} onChange={(_, { value }) => setARTCC(value)} />
                        </Table.Cell>
                        <Table.Cell><Form.Input value={headings} onChange={(_, { value }) => setHeadings(value)} /></Table.Cell>
                        <Table.Cell><ArrivalGatesModal iata={facility.iata} enabled={true} gates={facility.arrival_gates} onAdd={refreshArrivalGates} /></Table.Cell>
                        <Table.Cell><Form.Input width={6} value={configs} onChange={(_, { value }) => setConfigs(value)} /></Table.Cell>
                        <Table.Cell>
                            <Button.Group>
                                <Button color='grey' icon='undo' onClick={() => setEdit(false)} />
                                <Button color='green' icon='check' disabled={formError} onClick={() => onSubmit()} />
                            </Button.Group>
                        </Table.Cell>
                    </>
            }
        </Table.Row>
    )

}

export function Manage() {

    const { data: facilities } = useFacilities();

    return (
        <>
            {
                (facilities && _.isArray(facilities)) ? facilities.length > 0 ?
                    <Container className='hasHeader'>
                        <div style={{ display: 'inline-block', float: 'right' }}>
                            <AirportModal />
                        </div>
                        <PageHeader label='Facilities Management' />
                        <div className='scrolldiv'>

                            <Table unstackable celled compact>

                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell content='ICAO' />
                                        <Table.HeaderCell content='IATA' />
                                        <Table.HeaderCell content='Location' />
                                        <Table.HeaderCell content='ARTCC' />
                                        <Table.HeaderCell content='Headings' />
                                        <Table.HeaderCell content='Arrival Gates' />
                                        <Table.HeaderCell content='AAR (VFR, MVFR, IFR, LIFR)' />
                                        <Table.HeaderCell />
                                    </Table.Row>
                                </Table.Header>

                                <Table.Body>
                                    {
                                        _.map(_.sortBy(facilities, ['iata']), f => (<AirportEditor key={f.iata} facility={f} />))
                                    }
                                </Table.Body>

                            </Table>

                        </div>
                    </Container>
                    :
                    <Segment placeholder>
                        <Header>No Facilities Listed.</Header>
                        <AirportModal />
                    </Segment>
                    :
                    <Loader active />
            }

        </>
    )

}