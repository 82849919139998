export const nas_superhigh = [

    {
        "type": "Feature",
        "id": 30,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -106.991666667083,
                        35.42499999945045
                    ],
                    [
                        -107.466666667033,
                        36.19999999970025
                    ],
                    [
                        -108.21666666733279,
                        36.033333333066935
                    ],
                    [
                        -109.31666666693309,
                        35.849999999500426
                    ],
                    [
                        -109.6416666671829,
                        35.68333333286705
                    ],
                    [
                        -110.31444444465535,
                        34.79777777772233
                    ],
                    [
                        -110.0833333332667,
                        34.700000000000045
                    ],
                    [
                        -109.58333333336662,
                        34.4999999995004
                    ],
                    [
                        -108.88333333386623,
                        33.999999999600334
                    ],
                    [
                        -108.7500000001998,
                        33.87499999985016
                    ],
                    [
                        -107.99999999990001,
                        33.833333332967015
                    ],
                    [
                        -107.60000000069942,
                        33.58333333256729
                    ],
                    [
                        -107.466666667033,
                        33.58333333256729
                    ],
                    [
                        -106.80277777787211,
                        33.58333333256729
                    ],
                    [
                        -106.7555555562439,
                        33.82916666600886
                    ],
                    [
                        -106.67499999985006,
                        34.26249999997509
                    ],
                    [
                        -106.64999999990005,
                        34.48333333256727
                    ],
                    [
                        -106.6166666669331,
                        34.84999999970029
                    ],
                    [
                        -106.2850000004496,
                        35.15944444450554
                    ],
                    [
                        -106.70000000069945,
                        35.31666666663341
                    ],
                    [
                        -106.91666666723285,
                        35.31666666663341
                    ],
                    [
                        -106.991666667083,
                        35.42499999945045
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 30,
            "artcc": "zab",
            "sector": "58",
            "sectype": "superhigh",
            "Shape_Length": 10.649687965773897,
            "Shape_Area": 6.458814815318888
        }
    },
    {
        "type": "Feature",
        "id": 37,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -101.47222222207785,
                        36.397222221928075
                    ],
                    [
                        -101.75000000069946,
                        36.50000000000006
                    ],
                    [
                        -102.18333333376631,
                        37.04166666678327
                    ],
                    [
                        -102.54999999999995,
                        37.499999999800195
                    ],
                    [
                        -103.61944444490518,
                        37.162499999575346
                    ],
                    [
                        -104.00000000069946,
                        37.045277777547426
                    ],
                    [
                        -105.0000000004996,
                        36.71666666653351
                    ],
                    [
                        -105.34166666678323,
                        36.71666666653351
                    ],
                    [
                        -105.35638888883614,
                        36.53583333314185
                    ],
                    [
                        -105.38333333366637,
                        36.249999999600334
                    ],
                    [
                        -104.41666666683318,
                        36.249999999600334
                    ],
                    [
                        -103.83333333316676,
                        35.983333333166854
                    ],
                    [
                        -102.98333333396613,
                        35.849999999500426
                    ],
                    [
                        -101.47222222207785,
                        36.397222221928075
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 37,
            "artcc": "zab",
            "sector": "72",
            "sectype": "superhigh",
            "Shape_Length": 9.034850169516904,
            "Shape_Area": 3.4883641980897147
        }
    },
    {
        "type": "Feature",
        "id": 38,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -103.93333333386624,
                        32.46666666603386
                    ],
                    [
                        -104.38333333386623,
                        32.46666666603386
                    ],
                    [
                        -105.16666666713297,
                        32.38055555489501
                    ],
                    [
                        -105.56222222217775,
                        32.38472222185311
                    ],
                    [
                        -106.1000000000999,
                        32.41666666613378
                    ],
                    [
                        -106.11805555571931,
                        32.388333332617265
                    ],
                    [
                        -106.17194444448052,
                        32.299999999400484
                    ],
                    [
                        -106.20833333381626,
                        32.2499999995004
                    ],
                    [
                        -106.23333333376632,
                        32.09999999980022
                    ],
                    [
                        -106.23333333376632,
                        31.749999999600334
                    ],
                    [
                        -106.33333333356643,
                        31.666666666733363
                    ],
                    [
                        -106.45000000029972,
                        31.566666666033882
                    ],
                    [
                        -106.45000000029972,
                        31.533333333066935
                    ],
                    [
                        -105.80000000069941,
                        30.9999999993006
                    ],
                    [
                        -105.0000000004996,
                        30.6333333330669
                    ],
                    [
                        -104.51666666663334,
                        29.43333333276712
                    ],
                    [
                        -103.31666666723288,
                        28.949999999800184
                    ],
                    [
                        -103.30000000029975,
                        28.949999999800184
                    ],
                    [
                        -103.1500000005995,
                        28.949999999800184
                    ],
                    [
                        -102.86666666723289,
                        29.21666666623372
                    ],
                    [
                        -102.84027777769722,
                        29.361111111188904
                    ],
                    [
                        -102.67500000064945,
                        29.725000000049988
                    ],
                    [
                        -102.55833333391621,
                        29.76666666603387
                    ],
                    [
                        -102.14999999990005,
                        31.283333332667212
                    ],
                    [
                        -102.21666666673326,
                        31.42499999935052
                    ],
                    [
                        -103.11666666673324,
                        31.583333332967015
                    ],
                    [
                        -103.3333333332667,
                        31.64999999980023
                    ],
                    [
                        -103.52916666680818,
                        31.80833333341667
                    ],
                    [
                        -103.80000000019982,
                        32.033333332967004
                    ],
                    [
                        -103.93333333386624,
                        32.46666666603386
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 38,
            "artcc": "zab",
            "sector": "78",
            "sectype": "superhigh",
            "Shape_Length": 12.64989607611556,
            "Shape_Area": 8.682978858109804
        }
    },
    {
        "type": "Feature",
        "id": 39,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -106.64999999990005,
                        34.483333333466646
                    ],
                    [
                        -106.67499999985006,
                        34.26249999997509
                    ],
                    [
                        -106.75555555534459,
                        33.82916666690818
                    ],
                    [
                        -106.27499999975015,
                        33.82499999995008
                    ],
                    [
                        -106.06666666713295,
                        33.74583333314189
                    ],
                    [
                        -106.06666666713295,
                        34.283333332967004
                    ],
                    [
                        -105.84999999970017,
                        34.283333332967004
                    ],
                    [
                        -105.44999999960027,
                        33.96666666663339
                    ],
                    [
                        -105.15000000019984,
                        34.1458333332418
                    ],
                    [
                        -105.15000000019984,
                        34.50000000039972
                    ],
                    [
                        -104.54999999960029,
                        34.71666666693318
                    ],
                    [
                        -104.54999999960029,
                        35.00000000029985
                    ],
                    [
                        -105.06666666643349,
                        35.00000000029985
                    ],
                    [
                        -105.47500000044965,
                        35.20833333291705
                    ],
                    [
                        -106.11916666630856,
                        35.163611111463695
                    ],
                    [
                        -106.28499999955028,
                        35.15944444450554
                    ],
                    [
                        -106.6166666669331,
                        34.84999999970029
                    ],
                    [
                        -106.64999999990005,
                        34.483333333466646
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 39,
            "artcc": "zab",
            "sector": "79",
            "sectype": "superhigh",
            "Shape_Length": 6.8640984407173296,
            "Shape_Area": 1.9271256941168042
        }
    },
    {
        "type": "Feature",
        "id": 41,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -104.54999999960029,
                        35.00000000029985
                    ],
                    [
                        -104.54999999960029,
                        34.71666666693318
                    ],
                    [
                        -105.15000000019984,
                        34.50000000039972
                    ],
                    [
                        -105.15000000019984,
                        34.1458333332418
                    ],
                    [
                        -104.49999999970021,
                        33.95083333309191
                    ],
                    [
                        -104.09999999960024,
                        33.82916666690818
                    ],
                    [
                        -103.48749999992503,
                        33.63750000032479
                    ],
                    [
                        -103.40833333311679,
                        33.71944444450554
                    ],
                    [
                        -103.36666666713296,
                        33.77500000005
                    ],
                    [
                        -102.80000000039968,
                        34.31666666683327
                    ],
                    [
                        -102.66249999977515,
                        34.38888888931086
                    ],
                    [
                        -102.3249999995503,
                        34.550000000299804
                    ],
                    [
                        -103.49999999990001,
                        35.041666666283675
                    ],
                    [
                        -103.91666666693311,
                        35.026944444230764
                    ],
                    [
                        -104.54999999960029,
                        35.00000000029985
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 41,
            "artcc": "zab",
            "sector": "87",
            "sectype": "superhigh",
            "Shape_Length": 6.835066563826043,
            "Shape_Area": 2.351917244909023
        }
    },
    {
        "type": "Feature",
        "id": 42,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -106.35000000049956,
                        33.06666666663341
                    ],
                    [
                        -106.06666666713295,
                        33.56666666653348
                    ],
                    [
                        -106.06666666713295,
                        33.74583333314189
                    ],
                    [
                        -106.27500000064947,
                        33.82499999995008
                    ],
                    [
                        -106.7555555562439,
                        33.82916666600886
                    ],
                    [
                        -106.80277777787211,
                        33.58333333256729
                    ],
                    [
                        -107.466666667033,
                        33.58333333256729
                    ],
                    [
                        -107.75833333341654,
                        32.949999999900115
                    ],
                    [
                        -107.99999999990001,
                        32.41666666613378
                    ],
                    [
                        -108.20000000039965,
                        32.41666666613378
                    ],
                    [
                        -108.20000000039965,
                        31.533333333066935
                    ],
                    [
                        -106.45000000029972,
                        31.533333333066935
                    ],
                    [
                        -106.45000000029972,
                        31.566666666033882
                    ],
                    [
                        -106.33333333356643,
                        31.666666666733363
                    ],
                    [
                        -106.23333333376632,
                        31.749999999600334
                    ],
                    [
                        -106.23333333376632,
                        32.09999999980022
                    ],
                    [
                        -106.20833333381626,
                        32.2499999995004
                    ],
                    [
                        -106.17194444448052,
                        32.299999999400484
                    ],
                    [
                        -106.11805555571931,
                        32.388333332617265
                    ],
                    [
                        -106.1000000000999,
                        32.41666666613378
                    ],
                    [
                        -106.1000000000999,
                        32.56666666673334
                    ],
                    [
                        -106.25000000069946,
                        32.56666666673334
                    ],
                    [
                        -106.35000000049956,
                        33.06666666663341
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 42,
            "artcc": "zab",
            "sector": "89",
            "sectype": "superhigh",
            "Shape_Length": 8.315699187281604,
            "Shape_Area": 3.6435944436145866
        }
    },
    {
        "type": "Feature",
        "id": 50,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -105.38333333366637,
                        36.249999999600334
                    ],
                    [
                        -105.47500000044965,
                        35.20833333291705
                    ],
                    [
                        -105.06666666643349,
                        35.00000000029985
                    ],
                    [
                        -104.54999999960029,
                        35.00000000029985
                    ],
                    [
                        -103.91666666693311,
                        35.026944444230764
                    ],
                    [
                        -103.49999999990001,
                        35.041666666283675
                    ],
                    [
                        -102.98333333306681,
                        35.13750000002506
                    ],
                    [
                        -102.98333333306681,
                        35.34999999960036
                    ],
                    [
                        -102.98333333306681,
                        35.850000000399746
                    ],
                    [
                        -103.83333333316676,
                        35.983333333166854
                    ],
                    [
                        -104.41666666683318,
                        36.249999999600334
                    ],
                    [
                        -105.38333333366637,
                        36.249999999600334
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 50,
            "artcc": "zab",
            "sector": "96",
            "sectype": "superhigh",
            "Shape_Length": 6.778036845386794,
            "Shape_Area": 2.5940833335690536
        }
    },
    {
        "type": "Feature",
        "id": 52,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -101.47222222207785,
                        36.397222221928075
                    ],
                    [
                        -102.98333333306681,
                        35.850000000399746
                    ],
                    [
                        -102.98333333306681,
                        35.34999999960036
                    ],
                    [
                        -102.98333333306681,
                        35.13750000002506
                    ],
                    [
                        -103.49999999990001,
                        35.041666666283675
                    ],
                    [
                        -102.3249999995503,
                        34.550000000299804
                    ],
                    [
                        -102.0000000001998,
                        34.600000000199884
                    ],
                    [
                        -101.00000000039967,
                        34.491666666483525
                    ],
                    [
                        -100.74999999999994,
                        34.46666666653351
                    ],
                    [
                        -100.31666666693309,
                        34.86666666663342
                    ],
                    [
                        -100.14166666638351,
                        35.12916666700812
                    ],
                    [
                        -100.06111111088904,
                        35.249999999800195
                    ],
                    [
                        -99.99999999970021,
                        35.33333333356654
                    ],
                    [
                        -99.99999999970021,
                        35.82916666650851
                    ],
                    [
                        -101.47222222207785,
                        36.397222221928075
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 52,
            "artcc": "zab",
            "sector": "98",
            "sectype": "superhigh",
            "Shape_Length": 8.93238522170469,
            "Shape_Area": 4.457164351372116
        }
    },
    {
        "type": "Feature",
        "id": 70,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -86.31666666703302,
                        39.9999999993006
                    ],
                    [
                        -86.10000000049956,
                        39.9999999993006
                    ],
                    [
                        -85.93333333386624,
                        40.10000000000008
                    ],
                    [
                        -85.52499999985008,
                        40.354166666458525
                    ],
                    [
                        -85.31666666723288,
                        40.43333333326677
                    ],
                    [
                        -85.23333333346653,
                        40.46666666623372
                    ],
                    [
                        -84.95833333401612,
                        40.56666666603388
                    ],
                    [
                        -84.78888888911092,
                        40.633333332867096
                    ],
                    [
                        -84.68333333366638,
                        40.66666666673336
                    ],
                    [
                        -84.68777777782213,
                        40.744444443955956
                    ],
                    [
                        -84.70000000059952,
                        40.90833333321683
                    ],
                    [
                        -84.70916388900224,
                        41.15869999980521
                    ],
                    [
                        -84.71666666663333,
                        41.28333333336667
                    ],
                    [
                        -84.7500000004996,
                        41.583333332767154
                    ],
                    [
                        -85.0597222225025,
                        41.589166666508504
                    ],
                    [
                        -85.9883333332167,
                        41.599722221603315
                    ],
                    [
                        -86.2500000001998,
                        41.59999999970029
                    ],
                    [
                        -87.0000000004996,
                        41.608333332717166
                    ],
                    [
                        -87.0000000004996,
                        41.3741666667583
                    ],
                    [
                        -87.0000000004996,
                        41.06666666683327
                    ],
                    [
                        -86.9583333336164,
                        41.01666666603387
                    ],
                    [
                        -86.9286111114136,
                        40.98638888853645
                    ],
                    [
                        -86.72166666658336,
                        40.75222222167827
                    ],
                    [
                        -86.61999999999995,
                        40.633333332867096
                    ],
                    [
                        -86.41666666683318,
                        39.9999999993006
                    ],
                    [
                        -86.31666666703302,
                        39.9999999993006
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 70,
            "artcc": "zau",
            "sector": "33",
            "sectype": "superhigh",
            "Shape_Length": 6.842966205681142,
            "Shape_Area": 2.7524759142452115
        }
    },
    {
        "type": "Feature",
        "id": 78,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -88.88750000032474,
                        41.06666666683327
                    ],
                    [
                        -88.92083333329168,
                        40.90833333321683
                    ],
                    [
                        -89.1999999997002,
                        40.65416666675833
                    ],
                    [
                        -89.8758333335415,
                        40.05250000027485
                    ],
                    [
                        -89.75000000039967,
                        40.00000000019992
                    ],
                    [
                        -89.18472222235266,
                        40.00000000019992
                    ],
                    [
                        -88.9500000001998,
                        40.00000000019992
                    ],
                    [
                        -88.24999999980014,
                        40.00000000019992
                    ],
                    [
                        -88.16666666693311,
                        40.00000000019992
                    ],
                    [
                        -88.08750000012486,
                        40.508333333116866
                    ],
                    [
                        -88.00000000029974,
                        41.06666666683327
                    ],
                    [
                        -88.88750000032474,
                        41.06666666683327
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 78,
            "artcc": "zau",
            "sector": "45",
            "sectype": "superhigh",
            "Shape_Length": 5.130980355182262,
            "Shape_Area": 1.3441458330082534
        }
    },
    {
        "type": "Feature",
        "id": 93,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -89.66666666663332,
                        43.041666666483536
                    ],
                    [
                        -90.85416666695806,
                        43.595833333241785
                    ],
                    [
                        -91.08333333346656,
                        43.36666666673335
                    ],
                    [
                        -91.11861111131373,
                        43.333333332867085
                    ],
                    [
                        -91.22083333319176,
                        43.23333333306692
                    ],
                    [
                        -91.66666666713297,
                        42.783333333066935
                    ],
                    [
                        -91.72500000004993,
                        42.7249999992506
                    ],
                    [
                        -92.46666666733279,
                        42.756944444430644
                    ],
                    [
                        -93.0000000001998,
                        42.783333333066935
                    ],
                    [
                        -93.05000000009989,
                        42.66666666633364
                    ],
                    [
                        -93.2375000006245,
                        42.22916666630863
                    ],
                    [
                        -93.26666666663334,
                        42.158333333416635
                    ],
                    [
                        -93.35555555604407,
                        41.947222221628294
                    ],
                    [
                        -93.46666666713293,
                        41.6666666665335
                    ],
                    [
                        -91.7583333339162,
                        41.77333333256729
                    ],
                    [
                        -91.39055555619393,
                        41.80527777774728
                    ],
                    [
                        -89.71277777767227,
                        41.93638888843651
                    ],
                    [
                        -88.55000000009989,
                        41.98333333286706
                    ],
                    [
                        -88.55000000009989,
                        42.183333333366704
                    ],
                    [
                        -88.61111111128872,
                        42.3000000001
                    ],
                    [
                        -88.63611111123873,
                        42.35555555564446
                    ],
                    [
                        -88.75000000059953,
                        42.549999999600345
                    ],
                    [
                        -89.66666666663332,
                        43.041666666483536
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 93,
            "artcc": "zau",
            "sector": "71",
            "sectype": "superhigh",
            "Shape_Length": 11.614304188572937,
            "Shape_Area": 5.270492747690628
        }
    },
    {
        "type": "Feature",
        "id": 102,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -88.63611111123873,
                        42.35555555564446
                    ],
                    [
                        -88.61111111128872,
                        42.3000000001
                    ],
                    [
                        -88.55000000009989,
                        42.183333333366704
                    ],
                    [
                        -88.55000000009989,
                        41.98333333286706
                    ],
                    [
                        -88.55000000009989,
                        41.71666666643358
                    ],
                    [
                        -89.26666666653341,
                        41.38333333316683
                    ],
                    [
                        -88.87916666640848,
                        41.09999999980022
                    ],
                    [
                        -88.88750000032474,
                        41.06666666683327
                    ],
                    [
                        -88.00000000029974,
                        41.06666666683327
                    ],
                    [
                        -86.99999999960028,
                        41.06666666683327
                    ],
                    [
                        -86.99999999960028,
                        41.3741666667583
                    ],
                    [
                        -86.99999999960028,
                        41.608333333616486
                    ],
                    [
                        -86.99999999960028,
                        41.70000000039977
                    ],
                    [
                        -86.99999999960028,
                        42.02916666670836
                    ],
                    [
                        -86.99999999960028,
                        42.35694444433068
                    ],
                    [
                        -86.99999999960028,
                        42.50555555534464
                    ],
                    [
                        -88.63611111123873,
                        42.35555555564446
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 102,
            "artcc": "zau",
            "sector": "84",
            "sectype": "superhigh",
            "Shape_Length": 6.933441129573073,
            "Shape_Area": 2.406855709949595
        }
    },
    {
        "type": "Feature",
        "id": 110,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -90.77500000014987,
                        41.033333332967004
                    ],
                    [
                        -90.50000000069946,
                        41.133333332767165
                    ],
                    [
                        -90.05833333371635,
                        41.30833333331674
                    ],
                    [
                        -89.26666666653341,
                        41.38333333316683
                    ],
                    [
                        -88.55000000009989,
                        41.71666666643358
                    ],
                    [
                        -88.55000000009989,
                        41.98333333286706
                    ],
                    [
                        -89.71277777767227,
                        41.93638888843651
                    ],
                    [
                        -91.39055555619393,
                        41.80527777774728
                    ],
                    [
                        -91.7583333339162,
                        41.77333333256729
                    ],
                    [
                        -93.46666666713293,
                        41.6666666665335
                    ],
                    [
                        -93.48499999994999,
                        41.566111110539396
                    ],
                    [
                        -93.50833333401613,
                        41.43333333306691
                    ],
                    [
                        -93.59999999990004,
                        41.16666666663343
                    ],
                    [
                        -93.58333333386622,
                        41.06666666683327
                    ],
                    [
                        -93.56249999997499,
                        40.9444444444556
                    ],
                    [
                        -93.55694444433061,
                        40.91749999962536
                    ],
                    [
                        -93.491666667083,
                        40.52500000005
                    ],
                    [
                        -92.71666666683319,
                        40.63472222155332
                    ],
                    [
                        -91.74166666698306,
                        40.76388888826165
                    ],
                    [
                        -91.65000000019984,
                        40.78333333256728
                    ],
                    [
                        -91.40000000069944,
                        40.68333333276712
                    ],
                    [
                        -90.77500000014987,
                        41.033333332967004
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 110,
            "artcc": "zau",
            "sector": "95",
            "sectype": "superhigh",
            "Shape_Length": 11.56271661746949,
            "Shape_Area": 4.170781751555852
        }
    },
    {
        "type": "Feature",
        "id": 180,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -79.52784444426567,
                        36.20912222161331
                    ],
                    [
                        -78.83863611146734,
                        36.59368611062558
                    ],
                    [
                        -78.87500000054956,
                        36.726527777734816
                    ],
                    [
                        -78.82823888921331,
                        36.77838333262474
                    ],
                    [
                        -78.59166666718289,
                        36.98333333296699
                    ],
                    [
                        -78.45000000049959,
                        37.14999999960037
                    ],
                    [
                        -78.38666666723287,
                        37.229999999800214
                    ],
                    [
                        -78.29999999990002,
                        37.35000000009995
                    ],
                    [
                        -78.17194444468032,
                        37.53444444425577
                    ],
                    [
                        -78.03888888911092,
                        37.69166666638358
                    ],
                    [
                        -77.8602777777972,
                        37.91916666610882
                    ],
                    [
                        -77.81666666693309,
                        37.999999999700265
                    ],
                    [
                        -77.73222222257749,
                        38.08472222215289
                    ],
                    [
                        -77.64166666728283,
                        38.17499999935052
                    ],
                    [
                        -77.51666666663334,
                        38.255555555744365
                    ],
                    [
                        -77.56666666653342,
                        38.39722222152835
                    ],
                    [
                        -77.32083333399112,
                        38.64305555496992
                    ],
                    [
                        -77.7997222228023,
                        38.59027777769734
                    ],
                    [
                        -78.26666666693308,
                        38.283333333066935
                    ],
                    [
                        -78.74361111176336,
                        37.881388888986066
                    ],
                    [
                        -79.24111111148858,
                        37.454722222152895
                    ],
                    [
                        -79.68611111113881,
                        37.04166666678327
                    ],
                    [
                        -80.07083333389119,
                        36.792777777872175
                    ],
                    [
                        -80.2594444450051,
                        36.68472222225279
                    ],
                    [
                        -80.2500000004996,
                        36.66666666663343
                    ],
                    [
                        -80.11944444510505,
                        36.443611111064
                    ],
                    [
                        -79.97944444430561,
                        36.204722221953034
                    ],
                    [
                        -79.9500000001998,
                        36.15416666675833
                    ],
                    [
                        -79.86666666733277,
                        36.133333332867096
                    ],
                    [
                        -79.74444444495515,
                        36.08888888861139
                    ],
                    [
                        -79.52784444426567,
                        36.20912222161331
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 180,
            "artcc": "zdc",
            "sector": "07",
            "sectype": "superhigh",
            "Shape_Length": 8.164633822892593,
            "Shape_Area": 2.2870773819529946
        }
    },
    {
        "type": "Feature",
        "id": 181,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -78.74999999990001,
                        34.444444443956
                    ],
                    [
                        -78.68499999985005,
                        34.39694444423077
                    ],
                    [
                        -78.35833333371636,
                        34.157222222627524
                    ],
                    [
                        -78.11916666650842,
                        33.98027777809705
                    ],
                    [
                        -77.93277777747244,
                        33.841666666883214
                    ],
                    [
                        -77.75083333349153,
                        33.705555555844285
                    ],
                    [
                        -77.51166666628359,
                        33.52555555584428
                    ],
                    [
                        -77.49999999970021,
                        33.58333333346661
                    ],
                    [
                        -77.31666666703302,
                        34.483333333466646
                    ],
                    [
                        -77.14736388873246,
                        34.57097499989641
                    ],
                    [
                        -76.93333333296692,
                        34.61666666713302
                    ],
                    [
                        -76.23333333346653,
                        34.81666666673334
                    ],
                    [
                        -76.01420555524214,
                        35.00628888922091
                    ],
                    [
                        -75.46986111120128,
                        35.20065833331546
                    ],
                    [
                        -75.42021388925957,
                        35.45004444479537
                    ],
                    [
                        -75.41638888913587,
                        35.50027777739757
                    ],
                    [
                        -75.74972222240262,
                        35.825277777647386
                    ],
                    [
                        -76.62444444435556,
                        35.90333333296701
                    ],
                    [
                        -77.39999999990005,
                        35.93333333326677
                    ],
                    [
                        -77.44999999980013,
                        35.81666666653348
                    ],
                    [
                        -77.61666666643345,
                        35.6499999999001
                    ],
                    [
                        -78.74999999990001,
                        34.444444443956
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 181,
            "artcc": "zdc",
            "sector": "09",
            "sectype": "superhigh",
            "Shape_Length": 8.966563880739299,
            "Shape_Area": 3.666935686933245
        }
    },
    {
        "type": "Feature",
        "id": 200,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -76.46666666693312,
                        37.59999999960036
                    ],
                    [
                        -76.39166666708297,
                        37.7499999993006
                    ],
                    [
                        -76.63027777809697,
                        37.5922222218781
                    ],
                    [
                        -76.76666666723287,
                        37.633333332567304
                    ],
                    [
                        -76.88055555569429,
                        37.40277777737259
                    ],
                    [
                        -76.90694444433058,
                        37.34722222182813
                    ],
                    [
                        -77.04222222287723,
                        36.910555555194776
                    ],
                    [
                        -77.16666666733278,
                        36.78333333336667
                    ],
                    [
                        -77.33333333396615,
                        36.133333332867096
                    ],
                    [
                        -77.39999999990005,
                        35.93333333326677
                    ],
                    [
                        -77.45000000069945,
                        35.81666666653348
                    ],
                    [
                        -77.61666666733277,
                        35.6499999999001
                    ],
                    [
                        -78.74999999990001,
                        34.444444443956
                    ],
                    [
                        -78.68499999985005,
                        34.39694444423077
                    ],
                    [
                        -78.35833333371636,
                        34.157222221728205
                    ],
                    [
                        -78.11916666740774,
                        33.98027777719773
                    ],
                    [
                        -77.93277777837176,
                        33.841666665983894
                    ],
                    [
                        -77.75083333349153,
                        33.705555554944965
                    ],
                    [
                        -77.51166666718291,
                        33.52555555494496
                    ],
                    [
                        -77.50000000059953,
                        33.58333333256729
                    ],
                    [
                        -77.31666666703302,
                        34.48333333256727
                    ],
                    [
                        -77.14736388873246,
                        34.57097499989641
                    ],
                    [
                        -76.93333333386624,
                        34.6166666662337
                    ],
                    [
                        -76.23333333346653,
                        34.81666666673334
                    ],
                    [
                        -76.01420555614146,
                        35.00628888832159
                    ],
                    [
                        -75.46986111120128,
                        35.20065833331546
                    ],
                    [
                        -75.42021388925957,
                        35.45004444389605
                    ],
                    [
                        -75.41638888913587,
                        35.50027777739757
                    ],
                    [
                        -75.18999999999994,
                        35.3080555555195
                    ],
                    [
                        -74.93694444503006,
                        35.49694444383107
                    ],
                    [
                        -75.4544444443556,
                        35.88222222187807
                    ],
                    [
                        -75.49972222290222,
                        35.91694444443061
                    ],
                    [
                        -75.50000000009987,
                        36.81666666633362
                    ],
                    [
                        -75.50000000009987,
                        36.88333333316683
                    ],
                    [
                        -75.50000000009987,
                        36.93333333306691
                    ],
                    [
                        -75.49972222290222,
                        37.00583333274216
                    ],
                    [
                        -76.42361111136364,
                        37.20277777777227
                    ],
                    [
                        -76.44027777829677,
                        37.32499999925062
                    ],
                    [
                        -76.45277777827181,
                        37.473611111163905
                    ],
                    [
                        -76.46666666693312,
                        37.59999999960036
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 200,
            "artcc": "zdc",
            "sector": "30",
            "sectype": "superhigh",
            "Shape_Length": 13.026672890627621,
            "Shape_Area": 6.2012496549930995
        }
    },
    {
        "type": "Feature",
        "id": 209,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -76.39166666708297,
                        37.75000000019992
                    ],
                    [
                        -76.46666666693312,
                        37.59999999960036
                    ],
                    [
                        -76.45277777737249,
                        37.473611111163905
                    ],
                    [
                        -76.44027777739745,
                        37.32500000014994
                    ],
                    [
                        -76.42361111136364,
                        37.20277777777227
                    ],
                    [
                        -75.4997222220029,
                        37.005833333641476
                    ],
                    [
                        -75.50000000009987,
                        37.50833333371645
                    ],
                    [
                        -75.50000000009987,
                        37.713444444005916
                    ],
                    [
                        -75.41719166626234,
                        37.8200555553197
                    ],
                    [
                        -75.33333333346656,
                        37.82083333309191
                    ],
                    [
                        -75.18611111113881,
                        37.991666666683386
                    ],
                    [
                        -75.05000000009989,
                        38.26666666703312
                    ],
                    [
                        -74.98955277743869,
                        38.419000000050005
                    ],
                    [
                        -74.61666666703303,
                        38.75000000000006
                    ],
                    [
                        -74.88333333346651,
                        38.75000000000006
                    ],
                    [
                        -75.24999999970021,
                        38.729166667008144
                    ],
                    [
                        -75.40833333331665,
                        38.71944444440561
                    ],
                    [
                        -75.66666666673325,
                        38.700000000099976
                    ],
                    [
                        -75.81333333286699,
                        38.5249999995504
                    ],
                    [
                        -76.25694444473032,
                        37.91666666683324
                    ],
                    [
                        -76.39166666708297,
                        37.75000000019992
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 209,
            "artcc": "zdc",
            "sector": "39",
            "sectype": "superhigh",
            "Shape_Length": 5.880470026308952,
            "Shape_Area": 1.5556202439122722
        }
    },
    {
        "type": "Feature",
        "id": 210,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -80.64472222215278,
                        38.38250000037476
                    ],
                    [
                        -80.00000000009987,
                        38.48333333356652
                    ],
                    [
                        -79.58194444438055,
                        38.5580555553197
                    ],
                    [
                        -79.4427777778721,
                        38.58277777807206
                    ],
                    [
                        -79.33333333356643,
                        38.600000000299815
                    ],
                    [
                        -78.56666666633356,
                        38.75000000000006
                    ],
                    [
                        -78.37499999975017,
                        38.765555555444564
                    ],
                    [
                        -78.37499999975017,
                        39.11111111148864
                    ],
                    [
                        -78.37499999975017,
                        39.26333333326676
                    ],
                    [
                        -78.47305555556937,
                        39.48249999997506
                    ],
                    [
                        -78.52222222207786,
                        39.591666667083075
                    ],
                    [
                        -78.55833333331663,
                        39.6716666663836
                    ],
                    [
                        -78.62472222205287,
                        39.64777777792216
                    ],
                    [
                        -79.01388888896099,
                        39.533333333266796
                    ],
                    [
                        -79.233333332867,
                        39.46666666643358
                    ],
                    [
                        -79.31805555531957,
                        39.44111111118889
                    ],
                    [
                        -79.54305555576923,
                        39.37083333359152
                    ],
                    [
                        -79.69833333301688,
                        39.283611110964046
                    ],
                    [
                        -79.89444444465533,
                        39.173611111363755
                    ],
                    [
                        -80.40000000019984,
                        39.1666666670331
                    ],
                    [
                        -80.51055555599407,
                        38.90361111136377
                    ],
                    [
                        -80.56666666683316,
                        38.76666666693319
                    ],
                    [
                        -80.61249999977514,
                        38.569444444705425
                    ],
                    [
                        -80.64472222215278,
                        38.38250000037476
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 210,
            "artcc": "zdc",
            "sector": "42",
            "sectype": "superhigh",
            "Shape_Length": 6.012090653658152,
            "Shape_Area": 1.709203588111989
        }
    },
    {
        "type": "Feature",
        "id": 211,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -75.4997222220029,
                        37.005833333641476
                    ],
                    [
                        -76.42361111136364,
                        37.20277777777227
                    ],
                    [
                        -76.44027777739745,
                        37.32500000014994
                    ],
                    [
                        -76.45277777737249,
                        37.473611111163905
                    ],
                    [
                        -76.46666666693312,
                        37.59999999960036
                    ],
                    [
                        -76.39166666708297,
                        37.75000000019992
                    ],
                    [
                        -76.63027777809697,
                        37.5922222218781
                    ],
                    [
                        -76.76666666633355,
                        37.633333333466624
                    ],
                    [
                        -76.88055555569429,
                        37.40277777737259
                    ],
                    [
                        -76.90694444433058,
                        37.34722222182813
                    ],
                    [
                        -77.04222222197791,
                        36.910555555194776
                    ],
                    [
                        -77.16666666643346,
                        36.78333333336667
                    ],
                    [
                        -77.33333333306683,
                        36.133333332867096
                    ],
                    [
                        -77.39999999990005,
                        35.93333333326677
                    ],
                    [
                        -76.62444444435556,
                        35.90333333296701
                    ],
                    [
                        -75.74972222240262,
                        35.825277777647386
                    ],
                    [
                        -75.41638888913587,
                        35.50027777739757
                    ],
                    [
                        -75.18999999999994,
                        35.3080555555195
                    ],
                    [
                        -74.93694444413075,
                        35.49694444473039
                    ],
                    [
                        -75.4544444443556,
                        35.88222222187807
                    ],
                    [
                        -75.4997222220029,
                        35.91694444443061
                    ],
                    [
                        -75.50000000009987,
                        36.81666666633362
                    ],
                    [
                        -75.50000000009987,
                        36.88333333316683
                    ],
                    [
                        -75.50000000009987,
                        36.93333333306691
                    ],
                    [
                        -75.4997222220029,
                        37.005833333641476
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 211,
            "artcc": "zdc",
            "sector": "50",
            "sectype": "superhigh",
            "Shape_Length": 8.299874560311187,
            "Shape_Area": 2.534313966478648
        }
    },
    {
        "type": "Feature",
        "id": 219,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -80.25944444410578,
                        36.68472222225279
                    ],
                    [
                        -80.07083333299187,
                        36.792777777872175
                    ],
                    [
                        -79.68611111113881,
                        37.04166666678327
                    ],
                    [
                        -79.24111111148858,
                        37.454722222152895
                    ],
                    [
                        -78.74361111086404,
                        37.881388888986066
                    ],
                    [
                        -78.26666666693308,
                        38.283333333066935
                    ],
                    [
                        -77.79972222190298,
                        38.59027777769734
                    ],
                    [
                        -78.33166666698304,
                        38.58805555561946
                    ],
                    [
                        -78.80972222240263,
                        38.25527777764739
                    ],
                    [
                        -79.19444444425568,
                        38.08888888911099
                    ],
                    [
                        -79.29527777744744,
                        38.05194444448057
                    ],
                    [
                        -79.51666666713294,
                        37.96666666673332
                    ],
                    [
                        -79.71666666673326,
                        37.76666666713305
                    ],
                    [
                        -80.78944444430562,
                        37.708333333316716
                    ],
                    [
                        -80.82500000024976,
                        37.52916666670836
                    ],
                    [
                        -80.84166666628357,
                        37.46666666683325
                    ],
                    [
                        -80.82222222197794,
                        37.43333333296698
                    ],
                    [
                        -80.74583333344157,
                        37.30416666625871
                    ],
                    [
                        -80.63333333366637,
                        37.3319444444806
                    ],
                    [
                        -80.5811111107891,
                        37.25555555594423
                    ],
                    [
                        -80.50194444398085,
                        37.11833333341667
                    ],
                    [
                        -80.32361111076409,
                        36.80833333331674
                    ],
                    [
                        -80.25944444410578,
                        36.68472222225279
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 219,
            "artcc": "zdc",
            "sector": "72",
            "sectype": "superhigh",
            "Shape_Length": 7.655828179450517,
            "Shape_Area": 1.6732449076504168
        }
    },
    {
        "type": "Feature",
        "id": 263,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -98.80000000029975,
                        39.46666666643358
                    ],
                    [
                        -99.05833333371635,
                        39.983333333266785
                    ],
                    [
                        -101.32416666635851,
                        39.87250000027484
                    ],
                    [
                        -102.28444444415572,
                        39.82111111078916
                    ],
                    [
                        -103.20944444410577,
                        39.76805555541961
                    ],
                    [
                        -103.24222222177809,
                        39.3338888889611
                    ],
                    [
                        -102.92972222240263,
                        39.261388889285854
                    ],
                    [
                        -102.33305555536953,
                        39.01749999982519
                    ],
                    [
                        -102.2333333336664,
                        38.983333333466646
                    ],
                    [
                        -101.92500000034971,
                        38.75000000000006
                    ],
                    [
                        -101.23333333296688,
                        38.218611111113944
                    ],
                    [
                        -101.15833333311679,
                        38.258333333116866
                    ],
                    [
                        -100.35284444401583,
                        38.68238888853642
                    ],
                    [
                        -100.23388888846142,
                        38.74416666625871
                    ],
                    [
                        -99.63055555519469,
                        39.05833333331674
                    ],
                    [
                        -99.21666666643347,
                        39.26666666683326
                    ],
                    [
                        -98.80000000029975,
                        39.46666666643358
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 263,
            "artcc": "zdv",
            "sector": "18",
            "sectype": "superhigh",
            "Shape_Length": 10.234442494472859,
            "Shape_Area": 4.434942648391369
        }
    },
    {
        "type": "Feature",
        "id": 271,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -111.87916666630855,
                        37.41249999997507
                    ],
                    [
                        -111.71844444465535,
                        37.00500000024988
                    ],
                    [
                        -111.60833333291697,
                        36.733333333466646
                    ],
                    [
                        -111.53555555604402,
                        36.51500000014994
                    ],
                    [
                        -111.50416666705803,
                        36.42083333319181
                    ],
                    [
                        -111.8416666663835,
                        35.7666666666334
                    ],
                    [
                        -110.23333333296694,
                        35.699999999800184
                    ],
                    [
                        -109.31666666693309,
                        35.850000000399746
                    ],
                    [
                        -109.13638888883611,
                        37.36555555554452
                    ],
                    [
                        -109.07611111103893,
                        37.85666666713303
                    ],
                    [
                        -109.03333333356647,
                        38.18333333326677
                    ],
                    [
                        -109.98333333346653,
                        38.20000000019991
                    ],
                    [
                        -111.31388888916086,
                        37.65000000039976
                    ],
                    [
                        -111.87916666630855,
                        37.41249999997507
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 271,
            "artcc": "zdv",
            "sector": "24",
            "sectype": "superhigh",
            "Shape_Length": 9.688789669098602,
            "Shape_Area": 5.532579012826559
        }
    },
    {
        "type": "Feature",
        "id": 279,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -106.34999999960024,
                        36.62694444463045
                    ],
                    [
                        -106.08333333316676,
                        36.71666666653351
                    ],
                    [
                        -105.34166666678323,
                        36.71666666653351
                    ],
                    [
                        -104.99999999960028,
                        36.71666666653351
                    ],
                    [
                        -103.99999999980014,
                        37.045277777547426
                    ],
                    [
                        -103.61944444400586,
                        37.162499999575346
                    ],
                    [
                        -102.54999999999995,
                        37.499999999800195
                    ],
                    [
                        -103.75583333314177,
                        38.38722222262754
                    ],
                    [
                        -103.89222222227772,
                        38.485277777547424
                    ],
                    [
                        -104.05361111136364,
                        38.60083333369147
                    ],
                    [
                        -104.08333333356643,
                        38.84999999980022
                    ],
                    [
                        -104.20651944481648,
                        39.102583333354175
                    ],
                    [
                        -104.25250000037471,
                        39.19666666643354
                    ],
                    [
                        -104.30277777747244,
                        39.42638888913598
                    ],
                    [
                        -105.00916666690813,
                        39.411388888986096
                    ],
                    [
                        -105.04583333344152,
                        39.34583333364151
                    ],
                    [
                        -105.19694444463039,
                        39.20027777809702
                    ],
                    [
                        -105.68888888871123,
                        38.4888888892109
                    ],
                    [
                        -105.93005277751365,
                        38.12045000006748
                    ],
                    [
                        -106.1494444447053,
                        38.04527777824694
                    ],
                    [
                        -106.34999999960024,
                        36.62694444463045
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 279,
            "artcc": "zdv",
            "sector": "30",
            "sectype": "superhigh",
            "Shape_Length": 10.633397004699814,
            "Shape_Area": 5.878682274934643
        }
    },
    {
        "type": "Feature",
        "id": 289,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -107.05555555564433,
                        37.84722222262752
                    ],
                    [
                        -109.13638888883611,
                        37.36555555554452
                    ],
                    [
                        -109.31666666693309,
                        35.850000000399746
                    ],
                    [
                        -108.21666666643347,
                        36.033333333066935
                    ],
                    [
                        -107.466666667033,
                        36.19999999970025
                    ],
                    [
                        -106.34999999960024,
                        36.62694444463045
                    ],
                    [
                        -106.1494444447053,
                        38.04527777824694
                    ],
                    [
                        -107.05555555564433,
                        37.84722222262752
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 289,
            "artcc": "zdv",
            "sector": "38",
            "sectype": "superhigh",
            "Shape_Length": 9.101010947023795,
            "Shape_Area": 4.46270663707734
        }
    },
    {
        "type": "Feature",
        "id": 290,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -102.54999999999995,
                        37.499999999800195
                    ],
                    [
                        -101.23333333296688,
                        38.218611111113944
                    ],
                    [
                        -101.92500000034971,
                        38.75000000000006
                    ],
                    [
                        -102.2333333336664,
                        38.983333333466646
                    ],
                    [
                        -102.33305555536953,
                        39.01749999982519
                    ],
                    [
                        -102.92972222240263,
                        39.261388889285854
                    ],
                    [
                        -103.24222222177809,
                        39.3338888889611
                    ],
                    [
                        -103.67166666688314,
                        39.43222222197801
                    ],
                    [
                        -104.30277777747244,
                        39.42638888913598
                    ],
                    [
                        -104.25250000037471,
                        39.19666666643354
                    ],
                    [
                        -104.20651944481648,
                        39.102583333354175
                    ],
                    [
                        -104.08333333356643,
                        38.84999999980022
                    ],
                    [
                        -104.05361111136364,
                        38.60083333369147
                    ],
                    [
                        -103.89222222227772,
                        38.485277777547424
                    ],
                    [
                        -103.75583333314177,
                        38.38722222262754
                    ],
                    [
                        -102.54999999999995,
                        37.499999999800195
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 290,
            "artcc": "zdv",
            "sector": "39",
            "sectype": "superhigh",
            "Shape_Length": 7.636763977027007,
            "Shape_Area": 3.128813652307231
        }
    },
    {
        "type": "Feature",
        "id": 293,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -106.34999999960024,
                        36.62694444463045
                    ],
                    [
                        -106.08333333316676,
                        36.71666666653351
                    ],
                    [
                        -105.34166666678323,
                        36.71666666653351
                    ],
                    [
                        -104.99999999960028,
                        36.71666666653351
                    ],
                    [
                        -103.99999999980014,
                        37.045277777547426
                    ],
                    [
                        -103.61944444400586,
                        37.162499999575346
                    ],
                    [
                        -102.54999999999995,
                        37.499999999800195
                    ],
                    [
                        -103.75583333314177,
                        38.38722222262754
                    ],
                    [
                        -103.89222222227772,
                        38.485277777547424
                    ],
                    [
                        -104.05361111136364,
                        38.60083333369147
                    ],
                    [
                        -104.08333333356643,
                        38.84999999980022
                    ],
                    [
                        -104.20651944481648,
                        39.102583333354175
                    ],
                    [
                        -104.25250000037471,
                        39.19666666643354
                    ],
                    [
                        -104.30277777747244,
                        39.42638888913598
                    ],
                    [
                        -105.00916666690813,
                        39.411388888986096
                    ],
                    [
                        -105.04583333344152,
                        39.34583333364151
                    ],
                    [
                        -105.19694444463039,
                        39.20027777809702
                    ],
                    [
                        -105.68888888871123,
                        38.4888888892109
                    ],
                    [
                        -105.93005277751365,
                        38.12045000006748
                    ],
                    [
                        -106.1494444447053,
                        38.04527777824694
                    ],
                    [
                        -106.34999999960024,
                        36.62694444463045
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 293,
            "artcc": "zdv",
            "sector": "46",
            "sectype": "superhigh",
            "Shape_Length": 10.633397004699814,
            "Shape_Area": 5.878682274934643
        }
    },
    {
        "type": "Feature",
        "id": 294,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -102.54999999999995,
                        37.499999999800195
                    ],
                    [
                        -101.23333333296688,
                        38.218611111113944
                    ],
                    [
                        -101.92500000034971,
                        38.75000000000006
                    ],
                    [
                        -102.2333333336664,
                        38.983333333466646
                    ],
                    [
                        -102.33305555536953,
                        39.01749999982519
                    ],
                    [
                        -102.92972222240263,
                        39.261388889285854
                    ],
                    [
                        -103.24222222177809,
                        39.3338888889611
                    ],
                    [
                        -103.67166666688314,
                        39.43222222197801
                    ],
                    [
                        -104.30277777747244,
                        39.42638888913598
                    ],
                    [
                        -104.25250000037471,
                        39.19666666643354
                    ],
                    [
                        -104.20651944481648,
                        39.102583333354175
                    ],
                    [
                        -104.08333333356643,
                        38.84999999980022
                    ],
                    [
                        -104.05361111136364,
                        38.60083333369147
                    ],
                    [
                        -103.89222222227772,
                        38.485277777547424
                    ],
                    [
                        -103.75583333314177,
                        38.38722222262754
                    ],
                    [
                        -102.54999999999995,
                        37.499999999800195
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 294,
            "artcc": "zdv",
            "sector": "47",
            "sectype": "superhigh",
            "Shape_Length": 7.636763977027007,
            "Shape_Area": 3.128813652307231
        }
    },
    {
        "type": "Feature",
        "id": 296,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -109.03333333356647,
                        38.18333333326677
                    ],
                    [
                        -109.07611111103893,
                        37.85666666713303
                    ],
                    [
                        -109.13638888883611,
                        37.36555555554452
                    ],
                    [
                        -107.05555555564433,
                        37.84722222262752
                    ],
                    [
                        -108.33333333316676,
                        38.499999999600334
                    ],
                    [
                        -109.03333333356647,
                        38.18333333326677
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 296,
            "artcc": "zdv",
            "sector": "64",
            "sectype": "superhigh",
            "Shape_Length": 5.163265068503541,
            "Shape_Area": 1.2574564037963516
        }
    },
    {
        "type": "Feature",
        "id": 297,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -108.33333333316676,
                        38.499999999600334
                    ],
                    [
                        -107.05555555564433,
                        37.84722222262752
                    ],
                    [
                        -106.1494444447053,
                        38.04527777824694
                    ],
                    [
                        -105.93005277751365,
                        38.12045000006748
                    ],
                    [
                        -105.68888888871123,
                        38.4888888892109
                    ],
                    [
                        -105.19694444463039,
                        39.20027777809702
                    ],
                    [
                        -105.04583333344152,
                        39.34583333364151
                    ],
                    [
                        -105.00916666690813,
                        39.411388888986096
                    ],
                    [
                        -105.18194444448051,
                        39.5016666670831
                    ],
                    [
                        -105.48111111138866,
                        39.47611111093909
                    ],
                    [
                        -105.551666667083,
                        39.47000000000003
                    ],
                    [
                        -106.72722222182801,
                        39.079999999700306
                    ],
                    [
                        -108.33333333316676,
                        38.499999999600334
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 297,
            "artcc": "zdv",
            "sector": "65",
            "sectype": "superhigh",
            "Shape_Length": 7.696678183543677,
            "Shape_Area": 2.6454770667269276
        }
    },
    {
        "type": "Feature",
        "id": 298,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -99.04722222242759,
                        40.88333333326676
                    ],
                    [
                        -101.28944444470534,
                        40.56916666710805
                    ],
                    [
                        -102.24722222232765,
                        40.42055555519477
                    ],
                    [
                        -103.17527777774723,
                        40.251666666483516
                    ],
                    [
                        -103.20944444410577,
                        39.76805555541961
                    ],
                    [
                        -102.28444444415572,
                        39.82111111078916
                    ],
                    [
                        -101.32416666635851,
                        39.87250000027484
                    ],
                    [
                        -99.05833333371635,
                        39.983333333266785
                    ],
                    [
                        -99.05555555544447,
                        40.23055555539463
                    ],
                    [
                        -99.05416666675825,
                        40.350000000399746
                    ],
                    [
                        -99.04722222242759,
                        40.88333333326676
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 298,
            "artcc": "zdv",
            "sector": "67",
            "sectype": "superhigh",
            "Shape_Length": 9.71826114142576,
            "Shape_Area": 2.9101133874777387
        }
    },
    {
        "type": "Feature",
        "id": 311,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -94.3955555559441,
                        32.8805555556944
                    ],
                    [
                        -93.82500000034969,
                        33.38944444390603
                    ],
                    [
                        -92.99166666718293,
                        33.82499999995008
                    ],
                    [
                        -93.15833333381624,
                        33.94999999970025
                    ],
                    [
                        -93.54166666698308,
                        34.03333333256728
                    ],
                    [
                        -93.65833333371631,
                        34.08888888901106
                    ],
                    [
                        -94.29638888953559,
                        33.841666665983894
                    ],
                    [
                        -96.33333333376629,
                        33.171944443980976
                    ],
                    [
                        -96.34361111166345,
                        32.91138888848644
                    ],
                    [
                        -96.35583333354145,
                        32.63055555529468
                    ],
                    [
                        -95.24166666718293,
                        32.533611110964046
                    ],
                    [
                        -94.3955555559441,
                        32.8805555556944
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 311,
            "artcc": "zfw",
            "sector": "20",
            "sectype": "superhigh",
            "Shape_Length": 7.837868243492225,
            "Shape_Area": 2.428141820062286
        }
    },
    {
        "type": "Feature",
        "id": 312,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -100.14166666638351,
                        35.12916666700812
                    ],
                    [
                        -100.31666666693309,
                        34.86666666663342
                    ],
                    [
                        -100.74999999999994,
                        34.46666666653351
                    ],
                    [
                        -100.40833333371631,
                        33.999999999600334
                    ],
                    [
                        -97.7333333336664,
                        33.17500000034977
                    ],
                    [
                        -97.51666666713294,
                        33.08333333356654
                    ],
                    [
                        -97.51666666713294,
                        33.33194444438067
                    ],
                    [
                        -97.7541666666583,
                        33.667222222527585
                    ],
                    [
                        -97.914166667058,
                        34.070555555294675
                    ],
                    [
                        -98.45055555539454,
                        34.72222222257756
                    ],
                    [
                        -99.04166666678321,
                        34.95000000039977
                    ],
                    [
                        -100.14166666638351,
                        35.12916666700812
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 312,
            "artcc": "zfw",
            "sector": "23",
            "sectype": "superhigh",
            "Shape_Length": 8.203571309423282,
            "Shape_Area": 3.3864882339460927
        }
    },
    {
        "type": "Feature",
        "id": 313,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -99.83333333396615,
                        31.249999999700265
                    ],
                    [
                        -99.46666666683319,
                        31.383333333366693
                    ],
                    [
                        -99.70000000029972,
                        31.874999999350564
                    ],
                    [
                        -99.92083333379128,
                        32.349999999300564
                    ],
                    [
                        -100.22916666710796,
                        32.83333333316682
                    ],
                    [
                        -100.65333333376628,
                        32.86444444405589
                    ],
                    [
                        -101.98333333326667,
                        32.95250000007502
                    ],
                    [
                        -103.80000000019982,
                        33.049999999700276
                    ],
                    [
                        -103.80000000019982,
                        32.999999999800195
                    ],
                    [
                        -103.84027777839674,
                        32.845833333141854
                    ],
                    [
                        -103.93333333386624,
                        32.46666666603386
                    ],
                    [
                        -103.80000000019982,
                        32.033333332967004
                    ],
                    [
                        -103.52916666680818,
                        31.80833333341667
                    ],
                    [
                        -103.3333333332667,
                        31.64999999980023
                    ],
                    [
                        -103.11666666673324,
                        31.583333332967015
                    ],
                    [
                        -102.21666666673326,
                        31.42499999935052
                    ],
                    [
                        -101.40277777787213,
                        31.32888888841154
                    ],
                    [
                        -101.16666666703304,
                        31.299999999600345
                    ],
                    [
                        -100.34999999990004,
                        31.19666666623374
                    ],
                    [
                        -100.10000000039969,
                        31.147222221628283
                    ],
                    [
                        -99.83333333396615,
                        31.249999999700265
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 313,
            "artcc": "zfw",
            "sector": "24",
            "sectype": "superhigh",
            "Shape_Length": 10.827826009148364,
            "Shape_Area": 6.1308141211589
        }
    },
    {
        "type": "Feature",
        "id": 314,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -92.9916666662836,
                        33.82499999995008
                    ],
                    [
                        -93.82500000034969,
                        33.38944444480535
                    ],
                    [
                        -94.3955555559441,
                        32.8805555556944
                    ],
                    [
                        -92.81222222247754,
                        32.79305555586927
                    ],
                    [
                        -92.95499999975016,
                        32.6666666665335
                    ],
                    [
                        -92.94444444465535,
                        32.61833333341667
                    ],
                    [
                        -92.86249999957528,
                        32.191666666583444
                    ],
                    [
                        -92.52222222197793,
                        32.108333333716416
                    ],
                    [
                        -91.79999999990002,
                        32.1499999997003
                    ],
                    [
                        -91.6888888888111,
                        32.28333333336667
                    ],
                    [
                        -91.53333333346654,
                        32.43333333306691
                    ],
                    [
                        -91.89999999970019,
                        33.21666666633365
                    ],
                    [
                        -92.0833333332667,
                        33.59027777779727
                    ],
                    [
                        -92.68111111088899,
                        33.59388888856142
                    ],
                    [
                        -92.91666666643346,
                        33.76666666703312
                    ],
                    [
                        -92.9916666662836,
                        33.82499999995008
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 314,
            "artcc": "zfw",
            "sector": "25",
            "sectype": "superhigh",
            "Shape_Length": 7.694604777642553,
            "Shape_Area": 2.544086805561205
        }
    },
    {
        "type": "Feature",
        "id": 315,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -97.18805555521965,
                        35.466388889136
                    ],
                    [
                        -97.15972222260245,
                        35.170555555794294
                    ],
                    [
                        -95.63333333336658,
                        34.68333333306691
                    ],
                    [
                        -94.92499999994999,
                        34.933333333466635
                    ],
                    [
                        -94.99999999980014,
                        35.066666667133006
                    ],
                    [
                        -94.99999999980014,
                        35.383333333466624
                    ],
                    [
                        -94.99999999980014,
                        35.65416666685826
                    ],
                    [
                        -95.3666666669331,
                        35.87083333339166
                    ],
                    [
                        -95.61250000037467,
                        36.01666666703312
                    ],
                    [
                        -95.84166666688316,
                        35.94166666628365
                    ],
                    [
                        -95.98333333356646,
                        35.88333333336669
                    ],
                    [
                        -96.05000000039968,
                        35.87083333339166
                    ],
                    [
                        -96.23333333306681,
                        35.749999999700265
                    ],
                    [
                        -96.40416666665828,
                        35.6499999999001
                    ],
                    [
                        -96.83916666650839,
                        35.52777777752249
                    ],
                    [
                        -97.08333333316676,
                        35.458333333316716
                    ],
                    [
                        -97.18805555521965,
                        35.466388889136
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 315,
            "artcc": "zfw",
            "sector": "26",
            "sectype": "superhigh",
            "Shape_Length": 5.793228701835764,
            "Shape_Area": 1.855109259466509
        }
    },
    {
        "type": "Feature",
        "id": 326,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -94.92499999994999,
                        34.933333333466635
                    ],
                    [
                        -95.63333333336658,
                        34.68333333306691
                    ],
                    [
                        -96.31888888891103,
                        33.80694444433067
                    ],
                    [
                        -96.33333333286697,
                        33.171944443980976
                    ],
                    [
                        -94.29638888863627,
                        33.841666666883214
                    ],
                    [
                        -93.65833333371631,
                        34.08888888901106
                    ],
                    [
                        -93.99999999999994,
                        34.26666666693319
                    ],
                    [
                        -94.53333333286696,
                        34.53333333336667
                    ],
                    [
                        -94.78333333326668,
                        34.69166666698317
                    ],
                    [
                        -94.81666666713295,
                        34.755555555544504
                    ],
                    [
                        -94.92499999994999,
                        34.933333333466635
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 326,
            "artcc": "zfw",
            "sector": "41",
            "sectype": "superhigh",
            "Shape_Length": 6.885092721546607,
            "Shape_Area": 2.3028163968948916
        }
    },
    {
        "type": "Feature",
        "id": 328,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -99.99999999970021,
                        35.82916666650851
                    ],
                    [
                        -99.99999999970021,
                        35.33333333356654
                    ],
                    [
                        -100.06111111088904,
                        35.249999999800195
                    ],
                    [
                        -100.14166666638351,
                        35.12916666700812
                    ],
                    [
                        -99.04166666678321,
                        34.95000000039977
                    ],
                    [
                        -98.45055555539454,
                        34.72222222257756
                    ],
                    [
                        -97.15972222260245,
                        35.170555555794294
                    ],
                    [
                        -97.18805555521965,
                        35.466388889136
                    ],
                    [
                        -97.58333333306683,
                        35.49166666628366
                    ],
                    [
                        -97.88333333336658,
                        35.92500000024984
                    ],
                    [
                        -98.01666666703301,
                        35.88333333336669
                    ],
                    [
                        -98.14833333301686,
                        35.86250000037478
                    ],
                    [
                        -98.466666667033,
                        35.81666666653348
                    ],
                    [
                        -98.49999999999994,
                        35.81666666653348
                    ],
                    [
                        -98.80000000029975,
                        35.81666666653348
                    ],
                    [
                        -99.22777777772222,
                        35.81666666653348
                    ],
                    [
                        -99.38361111116382,
                        35.83333333346661
                    ],
                    [
                        -99.99999999970021,
                        35.82916666650851
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 328,
            "artcc": "zfw",
            "sector": "43",
            "sectype": "superhigh",
            "Shape_Length": 7.208012725643581,
            "Shape_Area": 2.3317731468668708
        }
    },
    {
        "type": "Feature",
        "id": 334,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -98.45055555539454,
                        34.72222222257756
                    ],
                    [
                        -97.914166667058,
                        34.070555555294675
                    ],
                    [
                        -97.7541666666583,
                        33.667222222527585
                    ],
                    [
                        -97.51666666713294,
                        33.33194444438067
                    ],
                    [
                        -96.33333333286697,
                        33.171944443980976
                    ],
                    [
                        -96.31888888891103,
                        33.80694444433067
                    ],
                    [
                        -95.63333333336658,
                        34.68333333306691
                    ],
                    [
                        -97.15972222260245,
                        35.170555555794294
                    ],
                    [
                        -98.45055555539454,
                        34.72222222257756
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 334,
            "artcc": "zfw",
            "sector": "51",
            "sectype": "superhigh",
            "Shape_Length": 7.599489524021953,
            "Shape_Area": 3.2882336044404434
        }
    },
    {
        "type": "Feature",
        "id": 366,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -92.37250000037466,
                        28.740833332892066
                    ],
                    [
                        -92.36944444400586,
                        28.499999999800195
                    ],
                    [
                        -92.24833333311682,
                        28.499999999800195
                    ],
                    [
                        -92.15000000009991,
                        28.266666666333663
                    ],
                    [
                        -91.66666666713297,
                        28.266666666333663
                    ],
                    [
                        -91.39999999980012,
                        28.266666666333663
                    ],
                    [
                        -90.83333333306683,
                        28.266666666333663
                    ],
                    [
                        -90.73333333326667,
                        28.266666666333663
                    ],
                    [
                        -90.49999999980014,
                        28.266666666333663
                    ],
                    [
                        -90.49999999980014,
                        28.499999999800195
                    ],
                    [
                        -90.49999999980014,
                        28.833333333066946
                    ],
                    [
                        -90.49999999980014,
                        29.16666666633364
                    ],
                    [
                        -90.49999999980014,
                        29.3166666669332
                    ],
                    [
                        -90.483333332867,
                        29.650000000199896
                    ],
                    [
                        -90.62916666650841,
                        30.008333333416658
                    ],
                    [
                        -90.71722222252754,
                        30.239722222002968
                    ],
                    [
                        -90.91666666683318,
                        30.716666666833248
                    ],
                    [
                        -90.94999999980013,
                        30.833333333566543
                    ],
                    [
                        -90.9833333336664,
                        30.91666666643357
                    ],
                    [
                        -91.35333333346654,
                        30.601944444080857
                    ],
                    [
                        -91.86666666673324,
                        30.508333333316727
                    ],
                    [
                        -92.1816666662836,
                        30.4166666665335
                    ],
                    [
                        -92.38333333356644,
                        30.183333333066912
                    ],
                    [
                        -92.38333333356644,
                        30.015555555844287
                    ],
                    [
                        -92.38333333356644,
                        29.600000000299815
                    ],
                    [
                        -92.38250000017484,
                        29.538611111014006
                    ],
                    [
                        -92.38222222207787,
                        29.513888889160967
                    ],
                    [
                        -92.37250000037466,
                        28.740833332892066
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 366,
            "artcc": "zhu",
            "sector": "23",
            "sectype": "superhigh",
            "Shape_Length": 8.098010989838281,
            "Shape_Area": 4.130923110476761
        }
    },
    {
        "type": "Feature",
        "id": 375,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -88.90000000029971,
                        31.54722222172819
                    ],
                    [
                        -89.00833333401613,
                        31.550000000000068
                    ],
                    [
                        -89.16666666673325,
                        31.57499999995008
                    ],
                    [
                        -89.43333333316673,
                        31.591666665983894
                    ],
                    [
                        -89.58333333376629,
                        31.608333332917027
                    ],
                    [
                        -89.85000000019983,
                        31.63055555549454
                    ],
                    [
                        -89.93333333396617,
                        31.633333332867096
                    ],
                    [
                        -90.03333333376628,
                        31.636111111138916
                    ],
                    [
                        -90.3458333331418,
                        31.64999999980023
                    ],
                    [
                        -90.65833333341658,
                        31.72916666660842
                    ],
                    [
                        -90.95000000069945,
                        31.383333333366693
                    ],
                    [
                        -90.97500000064946,
                        31.166666666833237
                    ],
                    [
                        -90.9833333336664,
                        30.91666666643357
                    ],
                    [
                        -90.95000000069945,
                        30.833333332667223
                    ],
                    [
                        -90.91666666683318,
                        30.716666666833248
                    ],
                    [
                        -90.71722222252754,
                        30.239722222002968
                    ],
                    [
                        -90.62916666740773,
                        30.008333333416658
                    ],
                    [
                        -90.48333333376632,
                        29.649999999300576
                    ],
                    [
                        -90.0958333336414,
                        29.733333333066923
                    ],
                    [
                        -89.99999999990001,
                        29.758333333016935
                    ],
                    [
                        -89.49972222280229,
                        29.92222222227781
                    ],
                    [
                        -89.30499999985005,
                        29.999999999500403
                    ],
                    [
                        -89.03333333396614,
                        29.999999999500403
                    ],
                    [
                        -88.85000000039969,
                        30.100277777397537
                    ],
                    [
                        -88.69444444505507,
                        30.183611111163884
                    ],
                    [
                        -88.52500000014987,
                        30.169444444405656
                    ],
                    [
                        -88.5000000001998,
                        30.16555555554453
                    ],
                    [
                        -88.42500000034971,
                        30.148611110514366
                    ],
                    [
                        -88.16388888956055,
                        30.200000000000045
                    ],
                    [
                        -88.02500000024975,
                        30.154444444255773
                    ],
                    [
                        -88.02500000024975,
                        30.22083333299196
                    ],
                    [
                        -87.91666666653339,
                        30.516666666333663
                    ],
                    [
                        -87.91666666653339,
                        30.6333333330669
                    ],
                    [
                        -87.80833333371635,
                        30.68333333296698
                    ],
                    [
                        -87.71666666693312,
                        30.691666665983917
                    ],
                    [
                        -87.69166666698305,
                        30.791666666683398
                    ],
                    [
                        -87.68333333396617,
                        30.845833332642258
                    ],
                    [
                        -87.6583333340161,
                        30.958333333316716
                    ],
                    [
                        -87.58888888891107,
                        31.0500000001
                    ],
                    [
                        -87.4000000005995,
                        31.28055555529471
                    ],
                    [
                        -87.55000000029975,
                        31.35833333341668
                    ],
                    [
                        -87.81666666673323,
                        31.5208333330919
                    ],
                    [
                        -88.32500000054955,
                        31.5166666661338
                    ],
                    [
                        -88.90000000029971,
                        31.54722222172819
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 375,
            "artcc": "zhu",
            "sector": "37",
            "sectype": "superhigh",
            "Shape_Length": 9.590298252404352,
            "Shape_Area": 5.014926697934898
        }
    },
    {
        "type": "Feature",
        "id": 391,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -94.0083333339162,
                        28.266666666333663
                    ],
                    [
                        -93.6333333337663,
                        28.266666666333663
                    ],
                    [
                        -93.46666666713293,
                        28.266666666333663
                    ],
                    [
                        -93.14055555629386,
                        28.266666666333663
                    ],
                    [
                        -92.86111111178832,
                        28.499999999800195
                    ],
                    [
                        -92.47750000052457,
                        28.499999999800195
                    ],
                    [
                        -92.36944444490518,
                        28.499999999800195
                    ],
                    [
                        -92.37250000037466,
                        28.740833332892066
                    ],
                    [
                        -92.38222222207787,
                        29.513888888261647
                    ],
                    [
                        -92.38250000017484,
                        29.538611111014006
                    ],
                    [
                        -92.38333333356644,
                        29.599999999400495
                    ],
                    [
                        -92.38333333356644,
                        30.015555554944967
                    ],
                    [
                        -92.38333333356644,
                        30.183333333066912
                    ],
                    [
                        -92.18166666718292,
                        30.4166666665335
                    ],
                    [
                        -92.5258333336414,
                        30.59972222200298
                    ],
                    [
                        -93.30000000049961,
                        30.31666666673334
                    ],
                    [
                        -93.69166666668326,
                        30.183333333066912
                    ],
                    [
                        -93.94722222272736,
                        30.130833332991983
                    ],
                    [
                        -94.28055555599406,
                        30.10527777774729
                    ],
                    [
                        -94.79638888943566,
                        30.05249999957539
                    ],
                    [
                        -95.11194444428065,
                        29.48499999945045
                    ],
                    [
                        -95.13333333346651,
                        29.417777777322613
                    ],
                    [
                        -95.27916666710797,
                        28.96111111108894
                    ],
                    [
                        -95.28750000012485,
                        28.909722221603317
                    ],
                    [
                        -95.35694444433062,
                        28.499999999800195
                    ],
                    [
                        -95.40555555554442,
                        28.205555555144826
                    ],
                    [
                        -94.0083333339162,
                        28.266666666333663
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 391,
            "artcc": "zhu",
            "sector": "68",
            "sectype": "superhigh",
            "Shape_Length": 9.820778992137082,
            "Shape_Area": 5.450202083441001
        }
    },
    {
        "type": "Feature",
        "id": 395,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -97.86666666643345,
                        29.799999999900137
                    ],
                    [
                        -98.00416666705803,
                        29.86666666673335
                    ],
                    [
                        -98.03750000002492,
                        30.483333333366716
                    ],
                    [
                        -97.58333333306683,
                        31.266666666633398
                    ],
                    [
                        -97.71249999977516,
                        31.375000000349814
                    ],
                    [
                        -97.79444444395591,
                        31.39027777769735
                    ],
                    [
                        -98.55833333291696,
                        31.387500000324792
                    ],
                    [
                        -99.46666666683319,
                        31.383333333366693
                    ],
                    [
                        -99.83333333306683,
                        31.249999999700265
                    ],
                    [
                        -100.10000000039969,
                        31.147222222527603
                    ],
                    [
                        -99.91666666683318,
                        31.033333333166865
                    ],
                    [
                        -99.38333333306684,
                        30.625000000050022
                    ],
                    [
                        -99.31666666713295,
                        30.349999999700287
                    ],
                    [
                        -99.6083333335165,
                        29.81666666683327
                    ],
                    [
                        -99.61666666653338,
                        29.466666666633387
                    ],
                    [
                        -99.483333332867,
                        29.183333333266773
                    ],
                    [
                        -99.3500000000999,
                        28.999999999700265
                    ],
                    [
                        -99.20000000039965,
                        28.88333333296697
                    ],
                    [
                        -99.05833333371635,
                        29.022222222277776
                    ],
                    [
                        -98.43333333316673,
                        29.016666666633398
                    ],
                    [
                        -98.06333333336659,
                        29.01527777794712
                    ],
                    [
                        -97.9841666665584,
                        29.011944444380674
                    ],
                    [
                        -97.93333333326666,
                        29.39444444415585
                    ],
                    [
                        -97.86666666643345,
                        29.799999999900137
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 395,
            "artcc": "zhu",
            "sector": "76",
            "sectype": "superhigh",
            "Shape_Length": 9.203813484203204,
            "Shape_Area": 3.978525694943201
        }
    },
    {
        "type": "Feature",
        "id": 399,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -93.56666666693309,
                        31.5666666669332
                    ],
                    [
                        -92.58333333316676,
                        31.083333333066946
                    ],
                    [
                        -92.54166666628356,
                        30.791666666683398
                    ],
                    [
                        -92.5258333336414,
                        30.59972222200298
                    ],
                    [
                        -92.1816666662836,
                        30.4166666665335
                    ],
                    [
                        -91.86666666673324,
                        30.508333333316727
                    ],
                    [
                        -91.35333333346654,
                        30.601944444080857
                    ],
                    [
                        -90.9833333336664,
                        30.91666666643357
                    ],
                    [
                        -90.97499999975014,
                        31.166666666833237
                    ],
                    [
                        -90.94999999980013,
                        31.383333333366693
                    ],
                    [
                        -90.65833333341658,
                        31.72916666660842
                    ],
                    [
                        -90.80000000009989,
                        31.766666666533467
                    ],
                    [
                        -90.90833333291692,
                        31.800000000399734
                    ],
                    [
                        -91.30833333301689,
                        31.91250000017493
                    ],
                    [
                        -91.53333333346654,
                        32.43333333306691
                    ],
                    [
                        -91.6888888888111,
                        32.28333333336667
                    ],
                    [
                        -91.79999999990002,
                        32.1499999997003
                    ],
                    [
                        -92.52222222197793,
                        32.108333333716416
                    ],
                    [
                        -92.86249999957528,
                        32.191666666583444
                    ],
                    [
                        -93.31166666708299,
                        31.794444444755356
                    ],
                    [
                        -93.56666666693309,
                        31.5666666669332
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 399,
            "artcc": "zhu",
            "sector": "81",
            "sectype": "superhigh",
            "Shape_Length": 7.876714905673829,
            "Shape_Area": 3.1444932877159104
        }
    },
    {
        "type": "Feature",
        "id": 402,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -96.17694444503007,
                        29.66111111058933
                    ],
                    [
                        -97.039444445105,
                        29.757222221528366
                    ],
                    [
                        -97.1477777779221,
                        29.764166666758342
                    ],
                    [
                        -97.80416666655839,
                        29.802777777272638
                    ],
                    [
                        -97.86666666733282,
                        29.799999999900137
                    ],
                    [
                        -97.93333333326666,
                        29.39444444415585
                    ],
                    [
                        -97.9841666665584,
                        29.011944444380674
                    ],
                    [
                        -98.06333333336659,
                        29.0152777770478
                    ],
                    [
                        -98.43333333316673,
                        29.016666666633398
                    ],
                    [
                        -99.05833333371635,
                        29.022222222277776
                    ],
                    [
                        -99.20000000039965,
                        28.88333333296697
                    ],
                    [
                        -99.7500000001998,
                        28.899999999900103
                    ],
                    [
                        -99.81666666703302,
                        28.8000000001
                    ],
                    [
                        -99.91666666683318,
                        28.683333333366704
                    ],
                    [
                        -100.06666666653342,
                        28.56666666663341
                    ],
                    [
                        -100.33333333386622,
                        28.43333333296698
                    ],
                    [
                        -100.19166666718291,
                        28.183333332567315
                    ],
                    [
                        -99.95000000069945,
                        27.983333332966993
                    ],
                    [
                        -99.83333333396615,
                        27.774999999450472
                    ],
                    [
                        -99.76750000052459,
                        27.713888888261636
                    ],
                    [
                        -99.62583333384123,
                        27.583333332867085
                    ],
                    [
                        -99.61111111178832,
                        27.57083333289205
                    ],
                    [
                        -99.52500000064947,
                        27.491666666083802
                    ],
                    [
                        -99.47777777812195,
                        27.227777777922142
                    ],
                    [
                        -99.44999999990006,
                        27.066666666033882
                    ],
                    [
                        -99.19166666738278,
                        26.649999999900103
                    ],
                    [
                        -99.18333333346652,
                        26.533333333166865
                    ],
                    [
                        -99.13333333356644,
                        26.46666666633365
                    ],
                    [
                        -99.06666666673323,
                        26.399999999500437
                    ],
                    [
                        -98.69166666658339,
                        26.249999999800195
                    ],
                    [
                        -98.58750000072439,
                        26.23888888851144
                    ],
                    [
                        -98.42500000014985,
                        26.18333333296698
                    ],
                    [
                        -98.21666666663333,
                        26.066666666233687
                    ],
                    [
                        -97.95833333321673,
                        26.058333333216808
                    ],
                    [
                        -97.75000000059953,
                        25.99999999940053
                    ],
                    [
                        -97.3583333335165,
                        25.81666666673334
                    ],
                    [
                        -96.11388888896101,
                        25.749999999900126
                    ],
                    [
                        -95.98277777827184,
                        25.999166666008875
                    ],
                    [
                        -95.94694444423067,
                        26.07916666620872
                    ],
                    [
                        -95.59722222212781,
                        26.833333332567292
                    ],
                    [
                        -95.58111111138862,
                        26.86361111096403
                    ],
                    [
                        -95.50000000059953,
                        27.633333332767165
                    ],
                    [
                        -95.45000000069945,
                        27.999999999900126
                    ],
                    [
                        -95.40555555554442,
                        28.205555555144826
                    ],
                    [
                        -95.35694444433062,
                        28.499999999800195
                    ],
                    [
                        -95.28750000012485,
                        28.909722221603317
                    ],
                    [
                        -95.27916666710797,
                        28.96111111108894
                    ],
                    [
                        -95.13333333346651,
                        29.417777777322613
                    ],
                    [
                        -95.2500000001998,
                        29.466666666633387
                    ],
                    [
                        -95.48555555574427,
                        29.564444444355672
                    ],
                    [
                        -95.71666666713293,
                        29.655555554944954
                    ],
                    [
                        -96.09944444500508,
                        29.65222222227777
                    ],
                    [
                        -96.17694444503007,
                        29.66111111058933
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 402,
            "artcc": "zhu",
            "sector": "84",
            "sectype": "superhigh",
            "Shape_Length": 15.55126623804751,
            "Shape_Area": 14.161462037677854
        }
    },
    {
        "type": "Feature",
        "id": 406,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -99.3500000000999,
                        28.999999999700265
                    ],
                    [
                        -99.48333333376632,
                        29.183333333266773
                    ],
                    [
                        -99.61666666653338,
                        29.466666666633387
                    ],
                    [
                        -99.6083333335165,
                        29.81666666683327
                    ],
                    [
                        -99.31666666713295,
                        30.349999999700287
                    ],
                    [
                        -99.38333333396616,
                        30.625000000050022
                    ],
                    [
                        -99.91666666683318,
                        31.033333333166865
                    ],
                    [
                        -100.10000000039969,
                        31.147222221628283
                    ],
                    [
                        -100.34999999990004,
                        31.19666666623374
                    ],
                    [
                        -101.16666666703304,
                        31.299999999600345
                    ],
                    [
                        -101.40277777787213,
                        31.32888888841154
                    ],
                    [
                        -102.21666666673326,
                        31.42499999935052
                    ],
                    [
                        -102.14999999990005,
                        31.283333332667212
                    ],
                    [
                        -102.55833333391621,
                        29.76666666603387
                    ],
                    [
                        -102.67500000064945,
                        29.725000000049988
                    ],
                    [
                        -102.84027777769722,
                        29.361111111188904
                    ],
                    [
                        -100.64833333341659,
                        28.999999999700265
                    ],
                    [
                        -100.33333333386622,
                        28.43333333296698
                    ],
                    [
                        -100.06666666653342,
                        28.56666666663341
                    ],
                    [
                        -99.91666666683318,
                        28.683333333366704
                    ],
                    [
                        -99.81666666703302,
                        28.8000000001
                    ],
                    [
                        -99.7500000001998,
                        28.899999999900103
                    ],
                    [
                        -99.20000000039965,
                        28.88333333296697
                    ],
                    [
                        -99.3500000000999,
                        28.999999999700265
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 406,
            "artcc": "zhu",
            "sector": "88",
            "sectype": "superhigh",
            "Shape_Length": 11.426647593480157,
            "Shape_Area": 6.6646006934215825
        }
    },
    {
        "type": "Feature",
        "id": 454,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -83.03333333336661,
                        38.86333333316685
                    ],
                    [
                        -83.16611111173836,
                        38.877499999925135
                    ],
                    [
                        -83.67472222275234,
                        39.10833333321682
                    ],
                    [
                        -83.64888888941067,
                        39.42666666633363
                    ],
                    [
                        -83.62388888946066,
                        39.61083333329174
                    ],
                    [
                        -84.53250000057454,
                        39.68444444445561
                    ],
                    [
                        -85.2922222225775,
                        39.65222222207797
                    ],
                    [
                        -85.45833333391619,
                        39.51666666633366
                    ],
                    [
                        -86.34166666698303,
                        38.9999999995004
                    ],
                    [
                        -86.50000000059953,
                        38.9999999995004
                    ],
                    [
                        -86.29083333369135,
                        38.51611111123884
                    ],
                    [
                        -86.18083333319174,
                        38.56694444453052
                    ],
                    [
                        -85.01277777807195,
                        38.57333333266723
                    ],
                    [
                        -85.08611111113885,
                        38.30166666678332
                    ],
                    [
                        -84.97777777832175,
                        37.74583333324176
                    ],
                    [
                        -84.55833333391621,
                        37.3833333330669
                    ],
                    [
                        -84.44444444455542,
                        37.41666666603385
                    ],
                    [
                        -83.97361111156351,
                        37.55972222230275
                    ],
                    [
                        -83.66666666693311,
                        37.64999999950044
                    ],
                    [
                        -83.50833333331661,
                        37.41388888866135
                    ],
                    [
                        -83.02916666730783,
                        37.77777777752249
                    ],
                    [
                        -83.03333333336661,
                        38.86333333316685
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 454,
            "artcc": "zid",
            "sector": "70",
            "sectype": "superhigh",
            "Shape_Length": 10.386077755511781,
            "Shape_Area": 4.875219174575325
        }
    },
    {
        "type": "Feature",
        "id": 455,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -83.62388888856134,
                        39.61083333329174
                    ],
                    [
                        -83.64888888851135,
                        39.42666666633363
                    ],
                    [
                        -83.67472222185302,
                        39.10833333321682
                    ],
                    [
                        -83.16611111083904,
                        38.877499999925135
                    ],
                    [
                        -83.03333333336661,
                        38.86333333316685
                    ],
                    [
                        -82.72138888928578,
                        38.830277777397555
                    ],
                    [
                        -80.51055555599407,
                        38.90361111136377
                    ],
                    [
                        -80.40000000019984,
                        39.1666666670331
                    ],
                    [
                        -80.49999999999994,
                        39.1666666670331
                    ],
                    [
                        -80.77777777772224,
                        39.31666666673334
                    ],
                    [
                        -81.10277777797205,
                        39.50416666635863
                    ],
                    [
                        -81.19166666648346,
                        39.55000000019993
                    ],
                    [
                        -81.42611111143862,
                        39.68222222237773
                    ],
                    [
                        -81.54999999970016,
                        39.749999999800195
                    ],
                    [
                        -81.5744444443556,
                        39.88750000042472
                    ],
                    [
                        -83.62388888856134,
                        39.61083333329174
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 455,
            "artcc": "zid",
            "sector": "74",
            "sectype": "superhigh",
            "Shape_Length": 7.517368202627374,
            "Shape_Area": 2.431339583649213
        }
    },
    {
        "type": "Feature",
        "id": 471,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -88.110277777997,
                        38.899166666308645
                    ],
                    [
                        -88.10666666633352,
                        38.80805555571936
                    ],
                    [
                        -88.1027777774724,
                        38.70361111086413
                    ],
                    [
                        -88.1008333334915,
                        38.651388888886174
                    ],
                    [
                        -88.09444444445552,
                        38.485277777547424
                    ],
                    [
                        -88.233333332867,
                        38.150000000299826
                    ],
                    [
                        -88.27000000029972,
                        37.96277777787219
                    ],
                    [
                        -88.3166666666333,
                        37.72500000024985
                    ],
                    [
                        -87.7930555554695,
                        37.47000000039975
                    ],
                    [
                        -87.63333333316677,
                        37.391666666983156
                    ],
                    [
                        -87.39722222232768,
                        37.27500000024986
                    ],
                    [
                        -86.91666666673325,
                        37.288888888911174
                    ],
                    [
                        -86.4833333336664,
                        37.30000000019993
                    ],
                    [
                        -86.49416666685818,
                        37.64694444403091
                    ],
                    [
                        -86.51666666663334,
                        38.36666666683328
                    ],
                    [
                        -86.52361111096394,
                        38.40833333371643
                    ],
                    [
                        -86.29083333369135,
                        38.51611111123884
                    ],
                    [
                        -86.49999999970021,
                        39.00000000039972
                    ],
                    [
                        -87.48333333346653,
                        38.983333333466646
                    ],
                    [
                        -88.110277777997,
                        38.899166666308645
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 471,
            "artcc": "zid",
            "sector": "91",
            "sectype": "superhigh",
            "Shape_Length": 6.657237374846402,
            "Shape_Area": 2.7511597603248616
        }
    },
    {
        "type": "Feature",
        "id": 472,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -86.29083333369135,
                        38.51611111123884
                    ],
                    [
                        -86.52361111096394,
                        38.40833333371643
                    ],
                    [
                        -86.51666666663334,
                        38.36666666683328
                    ],
                    [
                        -86.49416666685818,
                        37.64694444403091
                    ],
                    [
                        -86.4833333336664,
                        37.30000000019993
                    ],
                    [
                        -86.15000000039964,
                        37.30000000019993
                    ],
                    [
                        -85.99999999980014,
                        37.19444444475539
                    ],
                    [
                        -85.96361111136366,
                        37.1666666665335
                    ],
                    [
                        -84.97777777742243,
                        37.74583333324176
                    ],
                    [
                        -85.08611111113885,
                        38.30166666678332
                    ],
                    [
                        -85.01277777807195,
                        38.57333333356655
                    ],
                    [
                        -86.18083333319174,
                        38.56694444453052
                    ],
                    [
                        -86.29083333369135,
                        38.51611111123884
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 472,
            "artcc": "zid",
            "sector": "92",
            "sectype": "superhigh",
            "Shape_Length": 5.208783210788617,
            "Shape_Area": 1.718582099266141
        }
    },
    {
        "type": "Feature",
        "id": 474,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -85.96361111136366,
                        37.1666666665335
                    ],
                    [
                        -85.58333333366636,
                        36.900000000099965
                    ],
                    [
                        -85.40000000009991,
                        36.18333333366644
                    ],
                    [
                        -84.5833333329669,
                        36.3958333332418
                    ],
                    [
                        -84.11111111128872,
                        36.51249999997509
                    ],
                    [
                        -83.5583333332167,
                        36.6499999997003
                    ],
                    [
                        -83.40833333351651,
                        37.26666666633366
                    ],
                    [
                        -83.50833333331661,
                        37.41388888866135
                    ],
                    [
                        -83.66666666693311,
                        37.65000000039976
                    ],
                    [
                        -83.9736111106642,
                        37.55972222230275
                    ],
                    [
                        -84.44444444455542,
                        37.41666666693317
                    ],
                    [
                        -84.55833333301689,
                        37.3833333330669
                    ],
                    [
                        -84.97777777742243,
                        37.74583333324176
                    ],
                    [
                        -85.96361111136366,
                        37.1666666665335
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 474,
            "artcc": "zid",
            "sector": "94",
            "sectype": "superhigh",
            "Shape_Length": 6.8294663988674875,
            "Shape_Area": 2.412928433892272
        }
    },
    {
        "type": "Feature",
        "id": 475,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -83.03333333336661,
                        38.86333333316685
                    ],
                    [
                        -83.02916666640851,
                        37.77777777752249
                    ],
                    [
                        -82.716666667033,
                        37.838888888711324
                    ],
                    [
                        -82.30833333301689,
                        37.91249999987514
                    ],
                    [
                        -81.0499999998001,
                        38.14166666638357
                    ],
                    [
                        -80.7166666665334,
                        38.04166666658347
                    ],
                    [
                        -80.64472222215278,
                        38.38250000037476
                    ],
                    [
                        -80.61249999977514,
                        38.569444444705425
                    ],
                    [
                        -80.56666666683316,
                        38.76666666693319
                    ],
                    [
                        -80.51055555599407,
                        38.90361111136377
                    ],
                    [
                        -82.72138888928578,
                        38.830277777397555
                    ],
                    [
                        -83.03333333336661,
                        38.86333333316685
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 475,
            "artcc": "zid",
            "sector": "95",
            "sectype": "superhigh",
            "Shape_Length": 6.860196189450895,
            "Shape_Area": 2.117433950756815
        }
    },
    {
        "type": "Feature",
        "id": 476,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -83.50833333331661,
                        37.41388888866135
                    ],
                    [
                        -83.40833333351651,
                        37.26666666633366
                    ],
                    [
                        -83.5583333332167,
                        36.6499999997003
                    ],
                    [
                        -83.31944444410578,
                        36.711111110889135
                    ],
                    [
                        -83.12916666710794,
                        36.76111111078916
                    ],
                    [
                        -83.11194444398086,
                        36.76472222245263
                    ],
                    [
                        -82.9750000004496,
                        36.794444444655426
                    ],
                    [
                        -82.75833333301688,
                        36.843055555869284
                    ],
                    [
                        -82.25555555574425,
                        36.95972222260252
                    ],
                    [
                        -81.933333332867,
                        37.03333333286707
                    ],
                    [
                        -81.16249999957529,
                        37.211111110789204
                    ],
                    [
                        -80.90000000009991,
                        37.27083333329176
                    ],
                    [
                        -80.74583333344157,
                        37.30416666625871
                    ],
                    [
                        -80.82222222197794,
                        37.43333333296698
                    ],
                    [
                        -80.84166666628357,
                        37.46666666683325
                    ],
                    [
                        -80.82500000024976,
                        37.52916666670836
                    ],
                    [
                        -80.78944444430562,
                        37.708333333316716
                    ],
                    [
                        -80.7166666665334,
                        38.04166666658347
                    ],
                    [
                        -81.0499999998001,
                        38.14166666638357
                    ],
                    [
                        -82.30833333301689,
                        37.91249999987514
                    ],
                    [
                        -82.716666667033,
                        37.838888888711324
                    ],
                    [
                        -83.02916666640851,
                        37.77777777752249
                    ],
                    [
                        -83.50833333331661,
                        37.41388888866135
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 476,
            "artcc": "zid",
            "sector": "96",
            "sectype": "superhigh",
            "Shape_Length": 7.439625309243766,
            "Shape_Area": 2.503616319201141
        }
    },
    {
        "type": "Feature",
        "id": 477,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -82.91666666663332,
                        40.5666666669332
                    ],
                    [
                        -83.57472222205286,
                        39.8916666664835
                    ],
                    [
                        -83.62388888856134,
                        39.61083333329174
                    ],
                    [
                        -81.5744444443556,
                        39.88750000042472
                    ],
                    [
                        -81.63333333346651,
                        40.21666666673332
                    ],
                    [
                        -81.69972222220275,
                        40.38722222222782
                    ],
                    [
                        -81.75444444435556,
                        40.527222222127875
                    ],
                    [
                        -81.78333333316675,
                        40.59999999990015
                    ],
                    [
                        -81.89166666688311,
                        40.59999999990015
                    ],
                    [
                        -82.16666666633353,
                        40.587499999925114
                    ],
                    [
                        -82.34444444425566,
                        40.5736111112638
                    ],
                    [
                        -82.5916666663835,
                        40.55416666695817
                    ],
                    [
                        -82.91666666663332,
                        40.5666666669332
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 477,
            "artcc": "zid",
            "sector": "97",
            "sectype": "superhigh",
            "Shape_Length": 5.177021278016243,
            "Shape_Area": 1.370090778991921
        }
    },
    {
        "type": "Feature",
        "id": 478,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -85.23333333346653,
                        40.46666666713304
                    ],
                    [
                        -85.33027777779716,
                        40.06138888858641
                    ],
                    [
                        -85.2922222225775,
                        39.65222222207797
                    ],
                    [
                        -84.53249999967522,
                        39.68444444445561
                    ],
                    [
                        -83.62388888856134,
                        39.61083333329174
                    ],
                    [
                        -83.57472222205286,
                        39.8916666664835
                    ],
                    [
                        -82.91666666663332,
                        40.5666666669332
                    ],
                    [
                        -83.06666666633356,
                        40.57083333299198
                    ],
                    [
                        -83.45555555604403,
                        40.70555555534463
                    ],
                    [
                        -83.64999999999998,
                        40.737499999625356
                    ],
                    [
                        -83.7458333337413,
                        40.7833333334666
                    ],
                    [
                        -84.34722222212781,
                        40.863055555569474
                    ],
                    [
                        -84.6999999997002,
                        40.90833333321683
                    ],
                    [
                        -84.68777777782213,
                        40.744444443955956
                    ],
                    [
                        -84.68333333366638,
                        40.66666666673336
                    ],
                    [
                        -84.78888888911092,
                        40.633333332867096
                    ],
                    [
                        -84.9583333331168,
                        40.5666666669332
                    ],
                    [
                        -85.23333333346653,
                        40.46666666713304
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 478,
            "artcc": "zid",
            "sector": "98",
            "sectype": "superhigh",
            "Shape_Length": 6.382301618629825,
            "Shape_Area": 2.1179128088028136
        }
    },
    {
        "type": "Feature",
        "id": 479,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -87.48333333346653,
                        38.98333333256727
                    ],
                    [
                        -86.50000000059953,
                        38.9999999995004
                    ],
                    [
                        -86.34166666698303,
                        38.9999999995004
                    ],
                    [
                        -85.45833333391619,
                        39.51666666633366
                    ],
                    [
                        -85.2922222225775,
                        39.65222222207797
                    ],
                    [
                        -85.33027777779716,
                        40.06138888858641
                    ],
                    [
                        -85.23333333346653,
                        40.46666666623372
                    ],
                    [
                        -85.31666666723288,
                        40.43333333326677
                    ],
                    [
                        -85.52499999985008,
                        40.354166666458525
                    ],
                    [
                        -85.93333333386624,
                        40.10000000000008
                    ],
                    [
                        -86.10000000049956,
                        39.9999999993006
                    ],
                    [
                        -86.31666666703302,
                        39.9999999993006
                    ],
                    [
                        -86.41666666683318,
                        39.9999999993006
                    ],
                    [
                        -86.95000000059952,
                        39.9999999993006
                    ],
                    [
                        -87.2686111118133,
                        39.9999999993006
                    ],
                    [
                        -88.16666666693311,
                        39.9999999993006
                    ],
                    [
                        -88.25000000069946,
                        39.9999999993006
                    ],
                    [
                        -88.18333333386624,
                        39.904166666458536
                    ],
                    [
                        -88.14805555601907,
                        39.86833333331674
                    ],
                    [
                        -88.12916666700806,
                        39.386111110939055
                    ],
                    [
                        -88.110277777997,
                        38.899166666308645
                    ],
                    [
                        -87.48333333346653,
                        38.98333333256727
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 479,
            "artcc": "zid",
            "sector": "99",
            "sectype": "superhigh",
            "Shape_Length": 8.114650442897085,
            "Shape_Area": 2.757793981468378
        }
    },
    {
        "type": "Feature",
        "id": 509,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -79.72805555611899,
                        34.71333333336668
                    ],
                    [
                        -80.31250000037465,
                        33.733333333166854
                    ],
                    [
                        -80.86666666713296,
                        32.783333333266796
                    ],
                    [
                        -80.23222222207784,
                        32.40249999937555
                    ],
                    [
                        -79.29388888876116,
                        32.00000000000006
                    ],
                    [
                        -79.1477777779221,
                        32.50694444423078
                    ],
                    [
                        -79.55666666723289,
                        32.62527777774727
                    ],
                    [
                        -79.28305555556938,
                        33.00027777789717
                    ],
                    [
                        -79.18583333314177,
                        33.09333333336667
                    ],
                    [
                        -79.09138888898599,
                        33.18361111056436
                    ],
                    [
                        -79.07055555599408,
                        33.28361111036452
                    ],
                    [
                        -79.03583333344153,
                        33.32805555551954
                    ],
                    [
                        -79.00194444428064,
                        33.36777777752246
                    ],
                    [
                        -78.92194444498011,
                        33.46138888828659
                    ],
                    [
                        -78.67055555589417,
                        33.66972222180317
                    ],
                    [
                        -78.5586111114136,
                        33.73999999940048
                    ],
                    [
                        -78.39555555554443,
                        33.841944444080866
                    ],
                    [
                        -78.14972222210281,
                        33.841944444080866
                    ],
                    [
                        -78.11916666740774,
                        33.98027777719773
                    ],
                    [
                        -78.35833333371636,
                        34.157222221728205
                    ],
                    [
                        -78.68499999985005,
                        34.39694444423077
                    ],
                    [
                        -78.74999999990001,
                        34.444444443956
                    ],
                    [
                        -79.06666666713295,
                        34.43416666605884
                    ],
                    [
                        -79.26333333316677,
                        34.42749999982516
                    ],
                    [
                        -79.33333333356643,
                        34.42499999965031
                    ],
                    [
                        -79.72805555611899,
                        34.71333333336668
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 509,
            "artcc": "zjx",
            "sector": "48",
            "sectype": "superhigh",
            "Shape_Length": 8.91257965927641,
            "Shape_Area": 3.407378819922464
        }
    },
    {
        "type": "Feature",
        "id": 510,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -83.1125000001748,
                        31.68333333366644
                    ],
                    [
                        -82.78333333296689,
                        30.933333333366704
                    ],
                    [
                        -82.65000000019984,
                        30.749999999800195
                    ],
                    [
                        -82.41944444410575,
                        30.577777777522442
                    ],
                    [
                        -82.10000000039969,
                        30.35472222195301
                    ],
                    [
                        -82.05416666655839,
                        30.205555555644423
                    ],
                    [
                        -81.98749999972517,
                        30.36666666663342
                    ],
                    [
                        -81.94166666678319,
                        30.47083333339168
                    ],
                    [
                        -81.55833333361642,
                        30.829166666608444
                    ],
                    [
                        -81.54999999970016,
                        31.07222222177819
                    ],
                    [
                        -81.58749999962527,
                        31.28333333356653
                    ],
                    [
                        -81.49999999980014,
                        31.483333333166854
                    ],
                    [
                        -81.49999999980014,
                        32.033333332967004
                    ],
                    [
                        -81.41666666693311,
                        32.38333333316683
                    ],
                    [
                        -81.89999999990005,
                        32.67777777782226
                    ],
                    [
                        -81.933333332867,
                        32.56249999977524
                    ],
                    [
                        -82.06666666653342,
                        32.43333333306691
                    ],
                    [
                        -82.2000000001998,
                        32.291666666383605
                    ],
                    [
                        -82.3333333329669,
                        32.16666666663343
                    ],
                    [
                        -82.74166666698306,
                        31.91388888886121
                    ],
                    [
                        -83.1125000001748,
                        31.68333333366644
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 510,
            "artcc": "zjx",
            "sector": "49",
            "sectype": "superhigh",
            "Shape_Length": 6.443596228224096,
            "Shape_Area": 2.3418314044230057
        }
    },
    {
        "type": "Feature",
        "id": 512,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -76.77944444440556,
                        32.967777777422555
                    ],
                    [
                        -77.19833333351647,
                        33.288611110714214
                    ],
                    [
                        -77.51166666718291,
                        33.52555555494496
                    ],
                    [
                        -77.75083333349153,
                        33.705555554944965
                    ],
                    [
                        -77.93277777837176,
                        33.841666665983894
                    ],
                    [
                        -78.11916666740774,
                        33.98027777719773
                    ],
                    [
                        -78.14972222210281,
                        33.841944444080866
                    ],
                    [
                        -78.46083333369137,
                        32.78777777742255
                    ],
                    [
                        -78.59972222210286,
                        32.3336111104644
                    ],
                    [
                        -79.1477777779221,
                        32.50694444423078
                    ],
                    [
                        -79.29388888876116,
                        32.00000000000006
                    ],
                    [
                        -79.46416666705801,
                        31.399999999400507
                    ],
                    [
                        -79.60861111111387,
                        30.884722222152902
                    ],
                    [
                        -79.69277777827182,
                        30.581388888286597
                    ],
                    [
                        -79.76583333324169,
                        30.31666666673334
                    ],
                    [
                        -79.90083333369137,
                        29.82222222157833
                    ],
                    [
                        -79.28666666723285,
                        30.200277777197698
                    ],
                    [
                        -78.66916666720789,
                        30.144166666358615
                    ],
                    [
                        -78.57027777799703,
                        30.13472222185311
                    ],
                    [
                        -77.9244444444555,
                        30.072222221977995
                    ],
                    [
                        -77.85666666703304,
                        30.065555555744368
                    ],
                    [
                        -77.50000000059953,
                        30.029444444505543
                    ],
                    [
                        -77.21666666723286,
                        29.999999999500403
                    ],
                    [
                        -77.0333333336664,
                        29.999999999500403
                    ],
                    [
                        -77.00000000069946,
                        29.999999999500403
                    ],
                    [
                        -77.00000000069946,
                        31.355555555144804
                    ],
                    [
                        -77.00000000069946,
                        31.583333332967015
                    ],
                    [
                        -77.00000000069946,
                        31.666666666733363
                    ],
                    [
                        -77.00000000069946,
                        32.00000000000006
                    ],
                    [
                        -77.00000000069946,
                        32.262222222277785
                    ],
                    [
                        -76.77944444440556,
                        32.967777777422555
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 512,
            "artcc": "zjx",
            "sector": "51",
            "sectype": "superhigh",
            "Shape_Length": 12.77720540463052,
            "Shape_Area": 7.582291664756279
        }
    },
    {
        "type": "Feature",
        "id": 520,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -80.14722222242756,
                        34.855555555344665
                    ],
                    [
                        -80.46888888911093,
                        34.74749999972528
                    ],
                    [
                        -80.51777777752238,
                        34.727777778221935
                    ],
                    [
                        -80.57833333341654,
                        34.70333333356655
                    ],
                    [
                        -80.724166667058,
                        34.644166666358615
                    ],
                    [
                        -80.85166666698308,
                        34.59222222247763
                    ],
                    [
                        -81.24166666638354,
                        34.43222222207794
                    ],
                    [
                        -81.33333333316676,
                        34.39444444405592
                    ],
                    [
                        -81.5744444443556,
                        34.293611110864106
                    ],
                    [
                        -81.80444444425564,
                        33.985277777547424
                    ],
                    [
                        -81.84999999999997,
                        33.888888888511474
                    ],
                    [
                        -81.84999999999997,
                        33.68333333326677
                    ],
                    [
                        -81.84999999999997,
                        33.55555555524472
                    ],
                    [
                        -81.84666666643346,
                        33.51694444473037
                    ],
                    [
                        -81.84138888888606,
                        32.900277778097006
                    ],
                    [
                        -81.84361111096393,
                        32.891666666983156
                    ],
                    [
                        -81.89999999990005,
                        32.67777777782226
                    ],
                    [
                        -81.41666666693311,
                        32.38333333316683
                    ],
                    [
                        -80.86666666713296,
                        32.783333333266796
                    ],
                    [
                        -80.31250000037465,
                        33.733333333166854
                    ],
                    [
                        -79.72805555521967,
                        34.71333333336668
                    ],
                    [
                        -79.91666666633353,
                        34.84999999970029
                    ],
                    [
                        -80.14722222242756,
                        34.855555555344665
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 520,
            "artcc": "zjx",
            "sector": "65",
            "sectype": "superhigh",
            "Shape_Length": 7.19502436665941,
            "Shape_Area": 2.9127673604068165
        }
    },
    {
        "type": "Feature",
        "id": 522,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -80.96333333336662,
                        30.581388888286597
                    ],
                    [
                        -79.69277777827182,
                        30.581388888286597
                    ],
                    [
                        -79.60861111111387,
                        30.884722222152902
                    ],
                    [
                        -79.46416666705801,
                        31.399999999400507
                    ],
                    [
                        -79.29388888876116,
                        32.00000000000006
                    ],
                    [
                        -80.23222222207784,
                        32.40249999937555
                    ],
                    [
                        -80.86666666713296,
                        32.783333333266796
                    ],
                    [
                        -81.41666666693311,
                        32.38333333316683
                    ],
                    [
                        -81.50000000069946,
                        32.033333332967004
                    ],
                    [
                        -81.50000000069946,
                        31.483333333166854
                    ],
                    [
                        -81.58750000052459,
                        31.283333332667212
                    ],
                    [
                        -81.55000000059948,
                        31.07222222177819
                    ],
                    [
                        -81.55833333361642,
                        30.829166666608444
                    ],
                    [
                        -81.34000000029971,
                        30.56444444415581
                    ],
                    [
                        -80.96333333336662,
                        30.581388888286597
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 522,
            "artcc": "zjx",
            "sector": "67",
            "sectype": "superhigh",
            "Shape_Length": 7.491135499923539,
            "Shape_Area": 3.7774262359106836
        }
    },
    {
        "type": "Feature",
        "id": 529,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -81.55833333361642,
                        30.829166666608444
                    ],
                    [
                        -81.94166666678319,
                        30.47083333339168
                    ],
                    [
                        -81.9875000006245,
                        30.36666666663342
                    ],
                    [
                        -82.05416666655839,
                        30.205555555644423
                    ],
                    [
                        -81.96666666673326,
                        29.93055555529469
                    ],
                    [
                        -81.86722222222772,
                        29.605833333141845
                    ],
                    [
                        -81.84166666698303,
                        29.522222222177845
                    ],
                    [
                        -81.80694444443054,
                        29.38361111096407
                    ],
                    [
                        -81.78944444500507,
                        29.3161111107392
                    ],
                    [
                        -81.78416666655835,
                        29.295277777747287
                    ],
                    [
                        -81.70833333331666,
                        28.999999999700265
                    ],
                    [
                        -81.73416666665833,
                        28.864166666758308
                    ],
                    [
                        -81.5947222220529,
                        28.55611111063928
                    ],
                    [
                        -81.50000000069946,
                        28.34638888843648
                    ],
                    [
                        -80.98611111123876,
                        28.727777777622407
                    ],
                    [
                        -80.84805555621892,
                        28.82194444368116
                    ],
                    [
                        -80.7941666665584,
                        28.85861111111393
                    ],
                    [
                        -80.73333333346653,
                        28.899999999900103
                    ],
                    [
                        -80.54083333349149,
                        28.9391666666084
                    ],
                    [
                        -80.20166666738277,
                        29.00777777742252
                    ],
                    [
                        -80.11555555624386,
                        29.024999999650333
                    ],
                    [
                        -79.90083333369137,
                        29.82222222157833
                    ],
                    [
                        -79.76583333324169,
                        30.31666666673334
                    ],
                    [
                        -79.69277777827182,
                        30.581388888286597
                    ],
                    [
                        -80.96333333336662,
                        30.581388888286597
                    ],
                    [
                        -81.34000000029971,
                        30.56444444415581
                    ],
                    [
                        -81.55833333361642,
                        30.829166666608444
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 529,
            "artcc": "zjx",
            "sector": "76",
            "sectype": "superhigh",
            "Shape_Length": 7.953694902996862,
            "Shape_Area": 3.5939597212591963
        }
    },
    {
        "type": "Feature",
        "id": 533,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -84.90611111113884,
                        30.447777777422516
                    ],
                    [
                        -84.78333333346654,
                        30.31666666673334
                    ],
                    [
                        -84.52500000004994,
                        30.020833333391693
                    ],
                    [
                        -84.40833333331665,
                        29.85027777789719
                    ],
                    [
                        -84.33361111066421,
                        29.708888889310856
                    ],
                    [
                        -84.0000000001998,
                        29.708333333116855
                    ],
                    [
                        -83.75666666693309,
                        29.319722222402675
                    ],
                    [
                        -83.71305555516972,
                        29.250000000099988
                    ],
                    [
                        -83.51666666633355,
                        28.93333333286705
                    ],
                    [
                        -82.89999999970019,
                        29.183333333266773
                    ],
                    [
                        -82.86666666673324,
                        29.550000000399734
                    ],
                    [
                        -82.89999999970019,
                        29.66666666713303
                    ],
                    [
                        -83.2208333329919,
                        30.404166666558467
                    ],
                    [
                        -83.57277777807195,
                        30.370555555494548
                    ],
                    [
                        -84.38249999997498,
                        30.29027777809705
                    ],
                    [
                        -84.90611111113884,
                        30.447777777422516
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 533,
            "artcc": "zjx",
            "sector": "85",
            "sectype": "superhigh",
            "Shape_Length": 5.859129995846599,
            "Shape_Area": 1.5557199851967913
        }
    },
    {
        "type": "Feature",
        "id": 534,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -83.51666666633355,
                        28.93333333286705
                    ],
                    [
                        -83.51666666633355,
                        28.400000000000034
                    ],
                    [
                        -83.20833333301687,
                        28.158333333516566
                    ],
                    [
                        -82.94166666658333,
                        27.950000000000045
                    ],
                    [
                        -82.65749999982512,
                        27.950000000000045
                    ],
                    [
                        -82.38333333286698,
                        27.950000000000045
                    ],
                    [
                        -82.20694444453045,
                        28.03000000019989
                    ],
                    [
                        -81.9119444445804,
                        28.162499999575346
                    ],
                    [
                        -81.86222222187797,
                        28.185000000249886
                    ],
                    [
                        -81.78611111143863,
                        28.589722222602518
                    ],
                    [
                        -81.73416666665833,
                        28.864166666758308
                    ],
                    [
                        -81.70833333331666,
                        28.999999999700265
                    ],
                    [
                        -81.78416666655835,
                        29.295277777747287
                    ],
                    [
                        -81.78944444410575,
                        29.3161111107392
                    ],
                    [
                        -81.80694444443054,
                        29.38361111096407
                    ],
                    [
                        -81.84166666698303,
                        29.522222222177845
                    ],
                    [
                        -81.8666666669331,
                        29.358333332917027
                    ],
                    [
                        -82.49999999960028,
                        29.283333333066935
                    ],
                    [
                        -82.86666666673324,
                        29.550000000399734
                    ],
                    [
                        -82.89999999970019,
                        29.183333333266773
                    ],
                    [
                        -83.51666666633355,
                        28.93333333286705
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 534,
            "artcc": "zjx",
            "sector": "86",
            "sectype": "superhigh",
            "Shape_Length": 6.052368856540036,
            "Shape_Area": 2.0589698294485075
        }
    },
    {
        "type": "Feature",
        "id": 535,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -83.2208333329919,
                        30.404166666558467
                    ],
                    [
                        -82.89999999970019,
                        29.66666666713303
                    ],
                    [
                        -82.86666666673324,
                        29.550000000399734
                    ],
                    [
                        -82.49999999960028,
                        29.283333333066935
                    ],
                    [
                        -81.8666666669331,
                        29.358333332917027
                    ],
                    [
                        -81.84166666698303,
                        29.522222222177845
                    ],
                    [
                        -81.86722222222772,
                        29.605833333141845
                    ],
                    [
                        -81.96666666673326,
                        29.93055555529469
                    ],
                    [
                        -82.05416666655839,
                        30.205555555644423
                    ],
                    [
                        -82.10000000039969,
                        30.35472222195301
                    ],
                    [
                        -82.41944444410575,
                        30.577777777522442
                    ],
                    [
                        -82.65000000019984,
                        30.749999999800195
                    ],
                    [
                        -82.78333333296689,
                        30.933333333366704
                    ],
                    [
                        -83.03416666675821,
                        30.46333333286708
                    ],
                    [
                        -83.15833333311679,
                        30.411111110889124
                    ],
                    [
                        -83.2208333329919,
                        30.404166666558467
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 535,
            "artcc": "zjx",
            "sector": "87",
            "sectype": "superhigh",
            "Shape_Length": 4.688610667882218,
            "Shape_Area": 1.3134724145294394
        }
    },
    {
        "type": "Feature",
        "id": 557,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -97.44166666638353,
                        38.5249999995504
                    ],
                    [
                        -98.04999999999995,
                        37.85416666695818
                    ],
                    [
                        -97.73388888896102,
                        37.50388888866132
                    ],
                    [
                        -97.73722222252752,
                        37.13388888886118
                    ],
                    [
                        -94.88333333306684,
                        37.40833333301697
                    ],
                    [
                        -94.52916666680818,
                        38.0583333335166
                    ],
                    [
                        -94.58611111103892,
                        38.23277777787223
                    ],
                    [
                        -94.7333333333666,
                        38.84444444415584
                    ],
                    [
                        -96.38749999972515,
                        38.73333333306692
                    ],
                    [
                        -97.44166666638353,
                        38.5249999995504
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 557,
            "artcc": "zkc",
            "sector": "03",
            "sectype": "superhigh",
            "Shape_Length": 8.899796826338157,
            "Shape_Area": 4.444919058212148
        }
    },
    {
        "type": "Feature",
        "id": 559,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -97.44166666728285,
                        38.5249999995504
                    ],
                    [
                        -98.66666666663332,
                        38.87499999975023
                    ],
                    [
                        -99.63055555609401,
                        39.05833333331674
                    ],
                    [
                        -100.23388888936074,
                        38.74416666625871
                    ],
                    [
                        -100.35284444491515,
                        38.68238888853642
                    ],
                    [
                        -101.1583333340161,
                        38.258333333116866
                    ],
                    [
                        -100.6333333332667,
                        38.30000000000007
                    ],
                    [
                        -99.43333333386624,
                        37.93888888851143
                    ],
                    [
                        -99.95833333371633,
                        37.52916666670836
                    ],
                    [
                        -99.92499999985006,
                        36.7499999995004
                    ],
                    [
                        -99.77083333319172,
                        36.54166666598388
                    ],
                    [
                        -99.22777777772222,
                        35.81666666653348
                    ],
                    [
                        -98.80000000029975,
                        35.81666666653348
                    ],
                    [
                        -98.49999999999994,
                        35.81666666653348
                    ],
                    [
                        -98.466666667033,
                        35.81666666653348
                    ],
                    [
                        -98.14833333391618,
                        35.86249999947546
                    ],
                    [
                        -98.01666666703301,
                        35.88333333336669
                    ],
                    [
                        -97.88333333336658,
                        35.92499999935052
                    ],
                    [
                        -97.74722222232765,
                        35.974444443955974
                    ],
                    [
                        -97.7386111112138,
                        36.977777777322615
                    ],
                    [
                        -97.73722222252752,
                        37.13388888886118
                    ],
                    [
                        -97.73388888896102,
                        37.50388888866132
                    ],
                    [
                        -98.04999999999995,
                        37.85416666605886
                    ],
                    [
                        -97.44166666728285,
                        38.5249999995504
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 559,
            "artcc": "zkc",
            "sector": "07",
            "sectype": "superhigh",
            "Shape_Length": 12.777854219908361,
            "Shape_Area": 6.5814419970844416
        }
    },
    {
        "type": "Feature",
        "id": 563,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -99.77083333319172,
                        36.54166666598388
                    ],
                    [
                        -99.92499999985006,
                        36.7499999995004
                    ],
                    [
                        -99.95833333371633,
                        37.52916666670836
                    ],
                    [
                        -99.43333333386624,
                        37.93888888851143
                    ],
                    [
                        -100.6333333332667,
                        38.30000000000007
                    ],
                    [
                        -101.1583333340161,
                        38.258333333116866
                    ],
                    [
                        -101.23333333386626,
                        38.218611111113944
                    ],
                    [
                        -102.54999999999995,
                        37.499999999800195
                    ],
                    [
                        -102.18333333376631,
                        37.04166666678327
                    ],
                    [
                        -101.75000000069946,
                        36.50000000000006
                    ],
                    [
                        -101.47222222207785,
                        36.397222221928075
                    ],
                    [
                        -100.00000000059953,
                        35.82916666650851
                    ],
                    [
                        -99.38361111116382,
                        35.83333333256729
                    ],
                    [
                        -99.22777777772222,
                        35.81666666653348
                    ],
                    [
                        -99.77083333319172,
                        36.54166666598388
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 563,
            "artcc": "zkc",
            "sector": "21",
            "sectype": "superhigh",
            "Shape_Length": 9.903467122354506,
            "Shape_Area": 4.720376736706665
        }
    },
    {
        "type": "Feature",
        "id": 572,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -93.7869444442307,
                        36.61833333261728
                    ],
                    [
                        -93.7500000004996,
                        36.62499999975023
                    ],
                    [
                        -93.53333333396614,
                        36.66666666663343
                    ],
                    [
                        -93.25000000059953,
                        36.72499999955039
                    ],
                    [
                        -93.13749999992501,
                        36.74416666665837
                    ],
                    [
                        -92.86583333314178,
                        36.789999999600354
                    ],
                    [
                        -92.64388888906092,
                        36.826666666133804
                    ],
                    [
                        -92.51488055609275,
                        36.8478777773376
                    ],
                    [
                        -90.88277777767229,
                        37.10333333326679
                    ],
                    [
                        -90.72805555571927,
                        38.01222222157833
                    ],
                    [
                        -90.50833333371634,
                        38.5249999995504
                    ],
                    [
                        -91.29999999999995,
                        38.5249999995504
                    ],
                    [
                        -91.88333333366637,
                        38.24166666618373
                    ],
                    [
                        -94.52916666680818,
                        38.05833333261728
                    ],
                    [
                        -94.88333333396616,
                        37.40833333301697
                    ],
                    [
                        -94.95638888893603,
                        37.274166665958944
                    ],
                    [
                        -94.4083333340161,
                        36.4916666660838
                    ],
                    [
                        -93.7869444442307,
                        36.61833333261728
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 572,
            "artcc": "zkc",
            "sector": "31",
            "sectype": "superhigh",
            "Shape_Length": 10.999427106160207,
            "Shape_Area": 5.690616314446135
        }
    },
    {
        "type": "Feature",
        "id": 574,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -93.89166666628358,
                        39.31111111108896
                    ],
                    [
                        -94.7333333333666,
                        38.84444444415584
                    ],
                    [
                        -94.58611111103892,
                        38.23277777787223
                    ],
                    [
                        -94.52916666680818,
                        38.0583333335166
                    ],
                    [
                        -91.88333333366637,
                        38.24166666708305
                    ],
                    [
                        -91.29999999999995,
                        38.5249999995504
                    ],
                    [
                        -91.3333333329669,
                        39.03333333336667
                    ],
                    [
                        -92.24999999990001,
                        39.29999999980021
                    ],
                    [
                        -93.89166666628358,
                        39.31111111108896
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 574,
            "artcc": "zkc",
            "sector": "33",
            "sectype": "superhigh",
            "Shape_Length": 8.18149717196438,
            "Shape_Area": 3.437763116090412
        }
    },
    {
        "type": "Feature",
        "id": 577,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -99.63055555519469,
                        39.05833333331674
                    ],
                    [
                        -98.66666666663332,
                        38.87499999975023
                    ],
                    [
                        -97.44166666638353,
                        38.5249999995504
                    ],
                    [
                        -96.38749999972515,
                        38.73333333306692
                    ],
                    [
                        -96.08333333336662,
                        40.0500000001
                    ],
                    [
                        -96.96666666643347,
                        39.86666666653349
                    ],
                    [
                        -97.88333333336658,
                        39.66666666693317
                    ],
                    [
                        -98.80000000029975,
                        39.46666666643358
                    ],
                    [
                        -99.21666666643347,
                        39.26666666683326
                    ],
                    [
                        -99.63055555519469,
                        39.05833333331674
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 577,
            "artcc": "zkc",
            "sector": "41",
            "sectype": "superhigh",
            "Shape_Length": 8.385253138788695,
            "Shape_Area": 2.960202547400074
        }
    },
    {
        "type": "Feature",
        "id": 583,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -93.90833333321672,
                        40.458333333216785
                    ],
                    [
                        -94.07249999967519,
                        40.427777777622396
                    ],
                    [
                        -94.49999999990001,
                        40.35055555569437
                    ],
                    [
                        -95.54999999960029,
                        40.1499999999001
                    ],
                    [
                        -95.91666666673325,
                        40.07916666700811
                    ],
                    [
                        -96.08333333336662,
                        40.0500000001
                    ],
                    [
                        -96.38749999972515,
                        38.73333333306692
                    ],
                    [
                        -94.7333333333666,
                        38.84444444415584
                    ],
                    [
                        -93.89166666628358,
                        39.31111111108896
                    ],
                    [
                        -93.90833333321672,
                        40.458333333216785
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 583,
            "artcc": "zkc",
            "sector": "47",
            "sectype": "superhigh",
            "Shape_Length": 7.331995402361128,
            "Shape_Area": 3.1473004637410122
        }
    },
    {
        "type": "Feature",
        "id": 609,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -90.50833333371634,
                        38.5249999995504
                    ],
                    [
                        -90.72805555571927,
                        38.01222222247765
                    ],
                    [
                        -90.88277777767229,
                        37.10333333326679
                    ],
                    [
                        -90.5666666666333,
                        37.14999999960037
                    ],
                    [
                        -90.41694444413076,
                        37.18416666685823
                    ],
                    [
                        -90.38194444438057,
                        37.19222222177814
                    ],
                    [
                        -90.33555555524464,
                        37.20305555586924
                    ],
                    [
                        -90.03444444435559,
                        37.27083333329176
                    ],
                    [
                        -89.32638888903597,
                        37.42722222202798
                    ],
                    [
                        -89.18333333366638,
                        37.45833333291705
                    ],
                    [
                        -89.04999999999995,
                        37.48333333286706
                    ],
                    [
                        -88.83333333346656,
                        37.53333333366646
                    ],
                    [
                        -88.3166666666333,
                        37.72500000024985
                    ],
                    [
                        -88.27000000029972,
                        37.96277777787219
                    ],
                    [
                        -88.233333332867,
                        38.150000000299826
                    ],
                    [
                        -88.09444444445552,
                        38.485277777547424
                    ],
                    [
                        -88.1008333334915,
                        38.651388888886174
                    ],
                    [
                        -88.1027777774724,
                        38.70361111086413
                    ],
                    [
                        -89.10833333291697,
                        38.736111111338744
                    ],
                    [
                        -89.49722222262744,
                        38.6972222218281
                    ],
                    [
                        -89.78333333336661,
                        38.71944444440561
                    ],
                    [
                        -90.16611111123876,
                        38.569444444705425
                    ],
                    [
                        -90.50833333371634,
                        38.5249999995504
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 609,
            "artcc": "zkc",
            "sector": "90",
            "sectype": "superhigh",
            "Shape_Length": 7.58033902181467,
            "Shape_Area": 3.201318017203176
        }
    },
    {
        "type": "Feature",
        "id": 611,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -90.72416666685814,
                        40.40777777802208
                    ],
                    [
                        -92.19999999999999,
                        39.6333333330669
                    ],
                    [
                        -92.24999999990001,
                        39.29999999980021
                    ],
                    [
                        -91.3333333329669,
                        39.03333333336667
                    ],
                    [
                        -91.29999999999995,
                        38.5249999995504
                    ],
                    [
                        -90.50833333371634,
                        38.5249999995504
                    ],
                    [
                        -90.16611111123876,
                        38.569444444705425
                    ],
                    [
                        -89.78333333336661,
                        38.71944444440561
                    ],
                    [
                        -89.7625000003747,
                        38.9041666666584
                    ],
                    [
                        -90.13333333356644,
                        39.4166666665335
                    ],
                    [
                        -89.75000000039967,
                        40.00000000019992
                    ],
                    [
                        -89.8758333335415,
                        40.05250000027485
                    ],
                    [
                        -90.33333333316676,
                        40.241666666683386
                    ],
                    [
                        -90.56805555531957,
                        40.33888888911099
                    ],
                    [
                        -90.72416666685814,
                        40.40777777802208
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 611,
            "artcc": "zkc",
            "sector": "94",
            "sectype": "superhigh",
            "Shape_Length": 7.588330846365405,
            "Shape_Area": 3.0142349537290487
        }
    },
    {
        "type": "Feature",
        "id": 612,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -97.73722222252752,
                        37.13388888886118
                    ],
                    [
                        -97.7386111112138,
                        36.977777778221935
                    ],
                    [
                        -97.74722222232765,
                        35.974444443955974
                    ],
                    [
                        -97.88333333336658,
                        35.92500000024984
                    ],
                    [
                        -97.58333333306683,
                        35.49166666628366
                    ],
                    [
                        -97.18805555521965,
                        35.466388889136
                    ],
                    [
                        -97.08333333316676,
                        35.458333333316716
                    ],
                    [
                        -96.83916666650839,
                        35.52777777752249
                    ],
                    [
                        -96.40416666665828,
                        35.6499999999001
                    ],
                    [
                        -96.23333333306681,
                        35.749999999700265
                    ],
                    [
                        -96.05000000039968,
                        35.87083333339166
                    ],
                    [
                        -95.98333333356646,
                        35.88333333336669
                    ],
                    [
                        -95.84166666688316,
                        35.94166666628365
                    ],
                    [
                        -95.61250000037467,
                        36.01666666703312
                    ],
                    [
                        -95.3666666669331,
                        35.87083333339166
                    ],
                    [
                        -94.68333333346652,
                        36.43333333316684
                    ],
                    [
                        -94.40833333311679,
                        36.49166666698312
                    ],
                    [
                        -94.95638888893603,
                        37.274166666858264
                    ],
                    [
                        -94.88333333306684,
                        37.40833333301697
                    ],
                    [
                        -97.73722222252752,
                        37.13388888886118
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 612,
            "artcc": "zkc",
            "sector": "97",
            "sectype": "superhigh",
            "Shape_Length": 9.345026572311225,
            "Shape_Area": 4.5586667051633185
        }
    },
    {
        "type": "Feature",
        "id": 613,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -88.24999999980014,
                        40.00000000019992
                    ],
                    [
                        -88.9500000001998,
                        40.00000000019992
                    ],
                    [
                        -89.18472222235266,
                        40.00000000019992
                    ],
                    [
                        -89.75000000039967,
                        40.00000000019992
                    ],
                    [
                        -90.13333333356644,
                        39.4166666665335
                    ],
                    [
                        -89.7625000003747,
                        38.9041666666584
                    ],
                    [
                        -89.78333333336661,
                        38.71944444440561
                    ],
                    [
                        -89.49722222262744,
                        38.6972222218281
                    ],
                    [
                        -89.10833333291697,
                        38.736111111338744
                    ],
                    [
                        -88.1027777774724,
                        38.70361111086413
                    ],
                    [
                        -88.10666666633352,
                        38.80805555571936
                    ],
                    [
                        -88.110277777997,
                        38.899166666308645
                    ],
                    [
                        -88.12916666700806,
                        39.386111110939055
                    ],
                    [
                        -88.14805555601907,
                        39.86833333331674
                    ],
                    [
                        -88.18333333296692,
                        39.904166666458536
                    ],
                    [
                        -88.24999999980014,
                        40.00000000019992
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 613,
            "artcc": "zkc",
            "sector": "98",
            "sectype": "superhigh",
            "Shape_Length": 6.033008960858505,
            "Shape_Area": 2.2912494989924066
        }
    },
    {
        "type": "Feature",
        "id": 651,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -111.53555555604402,
                        36.51500000014994
                    ],
                    [
                        -113.71194444398088,
                        36.1016666666834
                    ],
                    [
                        -113.83888055549323,
                        36.077472221865605
                    ],
                    [
                        -114.2803999998601,
                        35.80287499998133
                    ],
                    [
                        -112.92777777822187,
                        35.25555555544457
                    ],
                    [
                        -112.66666666653339,
                        35.383333333466624
                    ],
                    [
                        -112.15305555516971,
                        35.396666666833255
                    ],
                    [
                        -111.99999999999994,
                        35.40000000039976
                    ],
                    [
                        -111.8416666663835,
                        35.7666666666334
                    ],
                    [
                        -111.50416666705803,
                        36.42083333319181
                    ],
                    [
                        -111.53555555604402,
                        36.51500000014994
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 651,
            "artcc": "zla",
            "sector": "36",
            "sectype": "superhigh",
            "Shape_Length": 6.515942187103363,
            "Shape_Area": 1.924913003392797
        }
    },
    {
        "type": "Feature",
        "id": 656,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -114.2803999998601,
                        35.80287499998133
                    ],
                    [
                        -114.31555555534459,
                        35.783888888861156
                    ],
                    [
                        -114.96666666643347,
                        35.433333333366704
                    ],
                    [
                        -114.89166666658338,
                        35.37499999955037
                    ],
                    [
                        -114.86249999967521,
                        35.066666667133006
                    ],
                    [
                        -115.00555555594411,
                        34.70555555564442
                    ],
                    [
                        -115.08333333316676,
                        34.6583333331169
                    ],
                    [
                        -113.99999999960028,
                        34.61666666713302
                    ],
                    [
                        -113.99999999960028,
                        34.6666666670331
                    ],
                    [
                        -113.7000000001998,
                        34.86666666663342
                    ],
                    [
                        -113.6166666664335,
                        34.9166666665335
                    ],
                    [
                        -112.92777777822187,
                        35.25555555544457
                    ],
                    [
                        -114.2803999998601,
                        35.80287499998133
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 656,
            "artcc": "zla",
            "sector": "53",
            "sectype": "superhigh",
            "Shape_Length": 5.482331573712844,
            "Shape_Area": 1.4746251431611088
        }
    },
    {
        "type": "Feature",
        "id": 658,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -113.78333333306682,
                        37.71666666633365
                    ],
                    [
                        -113.98555555564434,
                        37.472222222477626
                    ],
                    [
                        -114.39555555554443,
                        36.97083333299196
                    ],
                    [
                        -114.60055555549445,
                        36.71666666653351
                    ],
                    [
                        -114.0505555556943,
                        36.39972222210292
                    ],
                    [
                        -113.83888055549323,
                        36.077472221865605
                    ],
                    [
                        -113.71194444398088,
                        36.1016666666834
                    ],
                    [
                        -111.53555555604402,
                        36.51500000014994
                    ],
                    [
                        -111.60833333291697,
                        36.733333333466646
                    ],
                    [
                        -111.71844444465535,
                        37.00500000024988
                    ],
                    [
                        -111.87916666630855,
                        37.41249999997507
                    ],
                    [
                        -111.89583333324168,
                        37.41388888866135
                    ],
                    [
                        -112.42182777822933,
                        37.456174999576604
                    ],
                    [
                        -112.99999999980014,
                        37.499999999800195
                    ],
                    [
                        -113.78333333306682,
                        37.71666666633365
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 658,
            "artcc": "zla",
            "sector": "55",
            "sectype": "superhigh",
            "Shape_Length": 7.554637247189246,
            "Shape_Area": 3.1845180612037605
        }
    },
    {
        "type": "Feature",
        "id": 710,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -112.99999999980014,
                        37.499999999800195
                    ],
                    [
                        -112.88333333306684,
                        37.76666666713305
                    ],
                    [
                        -111.85000000029976,
                        38.43333333366644
                    ],
                    [
                        -111.68333333366638,
                        39.333333333666474
                    ],
                    [
                        -112.89583333304188,
                        39.10527777774729
                    ],
                    [
                        -113.31666666703302,
                        39.019444444705414
                    ],
                    [
                        -113.71250000017483,
                        38.5758333337414
                    ],
                    [
                        -114.20083333349152,
                        38.0166666666334
                    ],
                    [
                        -113.70083333359145,
                        37.89555555574435
                    ],
                    [
                        -113.7002777773975,
                        37.82361111136373
                    ],
                    [
                        -113.78333333306682,
                        37.71666666633365
                    ],
                    [
                        -112.99999999980014,
                        37.499999999800195
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 710,
            "artcc": "zlc",
            "sector": "33",
            "sectype": "superhigh",
            "Shape_Length": 6.970838042145488,
            "Shape_Area": 2.4878447531476606
        }
    },
    {
        "type": "Feature",
        "id": 711,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -109.98333333346653,
                        38.94555555544457
                    ],
                    [
                        -109.98333333346653,
                        39.21666666693318
                    ],
                    [
                        -110.2999999998001,
                        39.58333333316682
                    ],
                    [
                        -111.68333333366638,
                        39.333333333666474
                    ],
                    [
                        -111.85000000029976,
                        38.43333333366644
                    ],
                    [
                        -112.88333333306684,
                        37.76666666713305
                    ],
                    [
                        -112.99999999980014,
                        37.499999999800195
                    ],
                    [
                        -112.42182777822933,
                        37.456174999576604
                    ],
                    [
                        -111.89583333324168,
                        37.41388888866135
                    ],
                    [
                        -111.87916666630855,
                        37.41249999997507
                    ],
                    [
                        -111.31388888916086,
                        37.65000000039976
                    ],
                    [
                        -109.98333333346653,
                        38.20000000019991
                    ],
                    [
                        -109.98333333346653,
                        38.52777777782222
                    ],
                    [
                        -109.98333333346653,
                        38.933333333566566
                    ],
                    [
                        -109.98333333346653,
                        38.94555555544457
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 711,
            "artcc": "zlc",
            "sector": "34",
            "sectype": "superhigh",
            "Shape_Length": 8.520127252077105,
            "Shape_Area": 3.6419433087587163
        }
    },
    {
        "type": "Feature",
        "id": 712,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -103.16666666663332,
                        46.449999999900115
                    ],
                    [
                        -103.16666666663332,
                        47.25000000009999
                    ],
                    [
                        -103.16666666663332,
                        47.84611111093909
                    ],
                    [
                        -103.16666666663332,
                        49.00000000019992
                    ],
                    [
                        -103.67500000044964,
                        49.00000000019992
                    ],
                    [
                        -104.49999999970021,
                        49.00000000019992
                    ],
                    [
                        -104.99999999960028,
                        49.00000000019992
                    ],
                    [
                        -106.5000000001998,
                        49.00000000019992
                    ],
                    [
                        -107.99999999990001,
                        49.00000000019992
                    ],
                    [
                        -109.18333333326666,
                        49.00000000019992
                    ],
                    [
                        -108.86111111128872,
                        47.16666666633364
                    ],
                    [
                        -108.84143888849388,
                        47.032811110744206
                    ],
                    [
                        -108.78333333316675,
                        46.633333333466624
                    ],
                    [
                        -108.75749722181928,
                        46.462094444303204
                    ],
                    [
                        -108.52888888861128,
                        45.791666666383605
                    ],
                    [
                        -107.62394166677444,
                        45.629288889071006
                    ],
                    [
                        -106.00000000029974,
                        45.237499999625356
                    ],
                    [
                        -104.76944444440556,
                        45.14999999980023
                    ],
                    [
                        -104.2500000001998,
                        45.11666666683328
                    ],
                    [
                        -103.16666666663332,
                        44.958333333216785
                    ],
                    [
                        -103.16666666663332,
                        46.449999999900115
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 712,
            "artcc": "zlc",
            "sector": "39",
            "sectype": "superhigh",
            "Shape_Length": 18.779204620877607,
            "Shape_Area": 20.932672160314464
        }
    },
    {
        "type": "Feature",
        "id": 717,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -119.10000000019983,
                        42.333333333066946
                    ],
                    [
                        -119.50000000029974,
                        41.00000000000006
                    ],
                    [
                        -117.73222222267742,
                        40.121111111088965
                    ],
                    [
                        -117.36666666703303,
                        39.933333333366704
                    ],
                    [
                        -117.33333333316676,
                        39.4166666665335
                    ],
                    [
                        -117.32500000014983,
                        39.2069444443307
                    ],
                    [
                        -117.30750000072442,
                        38.96888888861139
                    ],
                    [
                        -117.29166666718294,
                        38.777777777322626
                    ],
                    [
                        -114.86916666680821,
                        39.44138888838654
                    ],
                    [
                        -114.45000000049959,
                        39.54999999930055
                    ],
                    [
                        -114.45000000049959,
                        40.299999999600345
                    ],
                    [
                        -114.45083333389124,
                        40.59999999990015
                    ],
                    [
                        -114.4341666669581,
                        40.899999999300576
                    ],
                    [
                        -114.45833333351652,
                        41.15027777779727
                    ],
                    [
                        -114.50111111098897,
                        41.6666666665335
                    ],
                    [
                        -114.5833333332667,
                        41.999999999800195
                    ],
                    [
                        -115.00000000029974,
                        41.999999999800195
                    ],
                    [
                        -116.61666666673324,
                        41.999999999800195
                    ],
                    [
                        -116.99999999990007,
                        41.999999999800195
                    ],
                    [
                        -119.10000000019983,
                        42.333333333066946
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 717,
            "artcc": "zlc",
            "sector": "42",
            "sectype": "superhigh",
            "Shape_Length": 14.88640545855266,
            "Shape_Area": 11.691543441795808
        }
    },
    {
        "type": "Feature",
        "id": 722,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -116.91750000042464,
                        37.88333333296697
                    ],
                    [
                        -116.8333333332667,
                        37.88333333296697
                    ],
                    [
                        -116.43416666655838,
                        37.88333333296697
                    ],
                    [
                        -116.18416666705798,
                        37.88333333296697
                    ],
                    [
                        -114.70166666678324,
                        37.99972222160329
                    ],
                    [
                        -114.50083333379126,
                        38.0166666666334
                    ],
                    [
                        -114.20083333349152,
                        38.0166666666334
                    ],
                    [
                        -113.71250000017483,
                        38.57583333284208
                    ],
                    [
                        -113.31666666703302,
                        39.019444443806094
                    ],
                    [
                        -113.31750000042467,
                        39.064999999550366
                    ],
                    [
                        -113.31750000042467,
                        39.383333332667235
                    ],
                    [
                        -113.15833333341658,
                        39.54999999930055
                    ],
                    [
                        -114.45000000049959,
                        39.54999999930055
                    ],
                    [
                        -114.86916666680821,
                        39.44138888838654
                    ],
                    [
                        -117.29166666718294,
                        38.777777777322626
                    ],
                    [
                        -117.28138888928578,
                        38.41305555506989
                    ],
                    [
                        -117.27805555571928,
                        38.29583333304191
                    ],
                    [
                        -117.26666666723287,
                        38.08333333256729
                    ],
                    [
                        -117.09472222215277,
                        37.88333333296697
                    ],
                    [
                        -116.91750000042464,
                        37.88333333296697
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 722,
            "artcc": "zlc",
            "sector": "45",
            "sectype": "superhigh",
            "Shape_Length": 10.025562782077227,
            "Shape_Area": 4.803693865271023
        }
    },
    {
        "type": "Feature",
        "id": 745,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -80.98611111123876,
                        28.727777777622407
                    ],
                    [
                        -80.28888888911092,
                        26.940555555694402
                    ],
                    [
                        -79.53972222220273,
                        26.56194444478035
                    ],
                    [
                        -79.38194444478029,
                        26.83305555536964
                    ],
                    [
                        -79.13305555586919,
                        27.291111111188854
                    ],
                    [
                        -79.11666666703303,
                        27.475000000049988
                    ],
                    [
                        -79.03333333326668,
                        27.999999999900126
                    ],
                    [
                        -78.66916666630857,
                        30.144166666358615
                    ],
                    [
                        -79.28666666633353,
                        30.200277778097018
                    ],
                    [
                        -79.90083333369137,
                        29.82222222247765
                    ],
                    [
                        -80.11555555534454,
                        29.024999999650333
                    ],
                    [
                        -80.20166666648345,
                        29.00777777742252
                    ],
                    [
                        -80.54083333349149,
                        28.9391666666084
                    ],
                    [
                        -80.73333333346653,
                        28.899999999900103
                    ],
                    [
                        -80.7941666665584,
                        28.85861111111393
                    ],
                    [
                        -80.8480555553196,
                        28.82194444458048
                    ],
                    [
                        -80.98611111123876,
                        28.727777777622407
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 745,
            "artcc": "zma",
            "sector": "17",
            "sectype": "superhigh",
            "Shape_Length": 9.586900892459402,
            "Shape_Area": 4.8143851471099754
        }
    },
    {
        "type": "Feature",
        "id": 781,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -81.5947222220529,
                        28.55611111063928
                    ],
                    [
                        -81.73416666665833,
                        28.864166666758308
                    ],
                    [
                        -81.78611111143863,
                        28.589722221703198
                    ],
                    [
                        -81.86222222277735,
                        28.184999999350566
                    ],
                    [
                        -81.9119444445804,
                        28.162499999575346
                    ],
                    [
                        -82.20694444453045,
                        28.02999999930057
                    ],
                    [
                        -82.00916666700806,
                        27.531111110889128
                    ],
                    [
                        -81.791666667083,
                        26.983333333166854
                    ],
                    [
                        -81.57916666660833,
                        26.804166666558444
                    ],
                    [
                        -81.40833333391618,
                        26.649999999900103
                    ],
                    [
                        -81.08750000062452,
                        26.37083333259227
                    ],
                    [
                        -80.8902777783967,
                        26.18055555559448
                    ],
                    [
                        -80.82388888876119,
                        26.116666666133767
                    ],
                    [
                        -80.82388888876119,
                        26.074999999250622
                    ],
                    [
                        -80.44166666708298,
                        26.10416666615879
                    ],
                    [
                        -80.05416666695805,
                        26.216666666833248
                    ],
                    [
                        -80.15000000069944,
                        26.383333332567304
                    ],
                    [
                        -80.1333333337663,
                        26.5500000001
                    ],
                    [
                        -80.28888888911092,
                        26.940555555694402
                    ],
                    [
                        -80.98611111123876,
                        28.727777777622407
                    ],
                    [
                        -81.50000000069946,
                        28.34638888843648
                    ],
                    [
                        -81.5947222220529,
                        28.55611111063928
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 781,
            "artcc": "zma",
            "sector": "68",
            "sectype": "superhigh",
            "Shape_Length": 8.229947436408173,
            "Shape_Area": 3.115306250619292
        }
    },
    {
        "type": "Feature",
        "id": 851,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -90.76527777754734,
                        43.68888888871129
                    ],
                    [
                        -90.64166666648345,
                        43.81666666673334
                    ],
                    [
                        -90.54999999970016,
                        43.89999999960037
                    ],
                    [
                        -90.42222222257749,
                        44.038888888911174
                    ],
                    [
                        -90.37027777779718,
                        44.085833333341725
                    ],
                    [
                        -91.15722222182802,
                        44.385000000249875
                    ],
                    [
                        -91.77361111126373,
                        44.61249999997506
                    ],
                    [
                        -92.32805555521963,
                        44.8111111108891
                    ],
                    [
                        -92.36638888853633,
                        44.824999999550414
                    ],
                    [
                        -93.14388888896104,
                        45.052500000174916
                    ],
                    [
                        -93.18999999999994,
                        44.96777777772229
                    ],
                    [
                        -93.2319444440808,
                        44.89027777769735
                    ],
                    [
                        -92.873888888961,
                        44.48361111136376
                    ],
                    [
                        -92.75249999997499,
                        44.345555555444605
                    ],
                    [
                        -92.27916666680818,
                        44.10833333311689
                    ],
                    [
                        -91.98416666685813,
                        43.92972222180316
                    ],
                    [
                        -91.78333333296689,
                        43.808333333716405
                    ],
                    [
                        -91.08333333346656,
                        43.36666666673335
                    ],
                    [
                        -90.85416666695806,
                        43.595833333241785
                    ],
                    [
                        -90.82916666700805,
                        43.618055555819296
                    ],
                    [
                        -90.76527777754734,
                        43.68888888871129
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 851,
            "artcc": "zmp",
            "sector": "15",
            "sectype": "superhigh",
            "Shape_Length": 6.79898837245485,
            "Shape_Area": 1.8349098765743899
        }
    },
    {
        "type": "Feature",
        "id": 853,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -91.77361111126373,
                        44.61249999997506
                    ],
                    [
                        -91.15722222182802,
                        44.385000000249875
                    ],
                    [
                        -90.37027777779718,
                        44.085833333341725
                    ],
                    [
                        -90.29166666628356,
                        44.15416666695819
                    ],
                    [
                        -90.08333333366636,
                        44.15416666695819
                    ],
                    [
                        -89.97777777822188,
                        44.15416666695819
                    ],
                    [
                        -89.62916666670827,
                        44.15416666695819
                    ],
                    [
                        -89.58749999982507,
                        44.15416666695819
                    ],
                    [
                        -90.59999999960024,
                        45.141666666783294
                    ],
                    [
                        -90.80222222217776,
                        45.33333333336668
                    ],
                    [
                        -90.93166666698306,
                        45.375000000249884
                    ],
                    [
                        -91.53333333346654,
                        45.56666666683327
                    ],
                    [
                        -92.18333333306686,
                        45.76666666643354
                    ],
                    [
                        -92.37416666625859,
                        45.66916666680828
                    ],
                    [
                        -92.7705555555944,
                        45.464722222152886
                    ],
                    [
                        -93.00138888888608,
                        45.30833333341667
                    ],
                    [
                        -93.08583333324168,
                        45.1572222222278
                    ],
                    [
                        -93.14388888896104,
                        45.052500000174916
                    ],
                    [
                        -92.36638888853633,
                        44.824999999550414
                    ],
                    [
                        -92.32805555521963,
                        44.8111111108891
                    ],
                    [
                        -91.77361111126373,
                        44.61249999997506
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 853,
            "artcc": "zmp",
            "sector": "16",
            "sectype": "superhigh",
            "Shape_Length": 8.11949776545491,
            "Shape_Area": 2.720526504878658
        }
    },
    {
        "type": "Feature",
        "id": 855,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -91.08333333346656,
                        43.36666666673335
                    ],
                    [
                        -91.78333333296689,
                        43.808333333716405
                    ],
                    [
                        -91.98416666685813,
                        43.92972222180316
                    ],
                    [
                        -92.27916666680818,
                        44.10833333311689
                    ],
                    [
                        -92.75249999997499,
                        44.345555555444605
                    ],
                    [
                        -92.873888888961,
                        44.48361111136376
                    ],
                    [
                        -93.55416666695805,
                        44.3155555560441
                    ],
                    [
                        -93.78611111083904,
                        43.75833333291706
                    ],
                    [
                        -93.85833333331664,
                        43.45833333351658
                    ],
                    [
                        -94.12583333314177,
                        42.94055555519475
                    ],
                    [
                        -93.6999999997002,
                        42.8166666669332
                    ],
                    [
                        -93.24166666668327,
                        42.78888888871131
                    ],
                    [
                        -93.0000000001998,
                        42.783333333066935
                    ],
                    [
                        -92.46666666643347,
                        42.756944444430644
                    ],
                    [
                        -91.72500000004993,
                        42.72500000014992
                    ],
                    [
                        -91.66666666713297,
                        42.783333333066935
                    ],
                    [
                        -91.22083333319176,
                        43.23333333306692
                    ],
                    [
                        -91.11861111131373,
                        43.333333332867085
                    ],
                    [
                        -91.08333333346656,
                        43.36666666673335
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 855,
            "artcc": "zmp",
            "sector": "17",
            "sectype": "superhigh",
            "Shape_Length": 7.644385615918457,
            "Shape_Area": 3.406561304296196
        }
    },
    {
        "type": "Feature",
        "id": 857,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -97.04611111133869,
                        43.327500000025054
                    ],
                    [
                        -96.18333333316673,
                        43.12222222197801
                    ],
                    [
                        -94.86666666703303,
                        43.11666666633363
                    ],
                    [
                        -94.12583333314177,
                        42.94055555519475
                    ],
                    [
                        -93.85833333331664,
                        43.45833333351658
                    ],
                    [
                        -93.78611111083904,
                        43.75833333291706
                    ],
                    [
                        -93.55416666695805,
                        44.3155555560441
                    ],
                    [
                        -92.873888888961,
                        44.48361111136376
                    ],
                    [
                        -93.2319444440808,
                        44.89027777769735
                    ],
                    [
                        -93.7169444447303,
                        44.88611111073925
                    ],
                    [
                        -94.11638888863627,
                        44.88638888883622
                    ],
                    [
                        -94.13000000009987,
                        44.69111111148868
                    ],
                    [
                        -94.01944444430563,
                        44.60083333339168
                    ],
                    [
                        -94.09527777754738,
                        44.57194444458048
                    ],
                    [
                        -95.59777777742244,
                        44.025833333641515
                    ],
                    [
                        -97.04611111133869,
                        43.327500000025054
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 857,
            "artcc": "zmp",
            "sector": "18",
            "sectype": "superhigh",
            "Shape_Length": 10.213177981910885,
            "Shape_Area": 3.505167053285341
        }
    },
    {
        "type": "Feature",
        "id": 869,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -93.35555555604407,
                        41.947222221628294
                    ],
                    [
                        -93.26666666663334,
                        42.158333333416635
                    ],
                    [
                        -93.2375000006245,
                        42.22916666630863
                    ],
                    [
                        -93.05000000009989,
                        42.66666666633364
                    ],
                    [
                        -93.0000000001998,
                        42.783333333066935
                    ],
                    [
                        -93.24166666668327,
                        42.78888888871131
                    ],
                    [
                        -93.70000000059952,
                        42.81666666603388
                    ],
                    [
                        -94.12583333314177,
                        42.94055555519475
                    ],
                    [
                        -94.86666666703303,
                        43.11666666633363
                    ],
                    [
                        -96.18333333316673,
                        43.12222222197801
                    ],
                    [
                        -97.04611111133869,
                        43.327500000025054
                    ],
                    [
                        -98.15000000069944,
                        43.58333333326675
                    ],
                    [
                        -98.44166666708298,
                        43.599999999300564
                    ],
                    [
                        -100.10000000039969,
                        43.28888888861138
                    ],
                    [
                        -99.96666666673326,
                        43.13333333326676
                    ],
                    [
                        -99.01666666683315,
                        41.999999999800195
                    ],
                    [
                        -99.02499999985008,
                        41.71666666643358
                    ],
                    [
                        -96.36666666673324,
                        41.85000000009995
                    ],
                    [
                        -96.07027777809697,
                        41.61777777722267
                    ],
                    [
                        -93.35555555604407,
                        41.947222221628294
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 869,
            "artcc": "zmp",
            "sector": "28",
            "sectype": "superhigh",
            "Shape_Length": 15.870554722149475,
            "Shape_Area": 9.104755400951083
        }
    },
    {
        "type": "Feature",
        "id": 872,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -93.23194444498012,
                        44.89027777769735
                    ],
                    [
                        -93.7169444447303,
                        44.88611111073925
                    ],
                    [
                        -94.11638888953559,
                        44.88638888883622
                    ],
                    [
                        -94.41472222215282,
                        44.88333333336669
                    ],
                    [
                        -96.12500000024977,
                        44.849999999500426
                    ],
                    [
                        -97.67166666718293,
                        44.86666666643356
                    ],
                    [
                        -98.44166666708298,
                        43.599999999300564
                    ],
                    [
                        -98.15000000069944,
                        43.58333333326675
                    ],
                    [
                        -97.04611111133869,
                        43.327500000025054
                    ],
                    [
                        -96.18333333316673,
                        43.12222222197801
                    ],
                    [
                        -94.86666666703303,
                        43.11666666633363
                    ],
                    [
                        -94.12583333314177,
                        42.94055555519475
                    ],
                    [
                        -93.70000000059952,
                        42.81666666603388
                    ],
                    [
                        -93.24166666668327,
                        42.78888888871131
                    ],
                    [
                        -93.0000000001998,
                        42.783333333066935
                    ],
                    [
                        -92.46666666733279,
                        42.756944444430644
                    ],
                    [
                        -91.72500000004993,
                        42.7249999992506
                    ],
                    [
                        -91.66666666713297,
                        42.783333333066935
                    ],
                    [
                        -91.22083333319176,
                        43.23333333306692
                    ],
                    [
                        -91.11861111131373,
                        43.333333332867085
                    ],
                    [
                        -91.08333333346656,
                        43.36666666673335
                    ],
                    [
                        -91.78333333386621,
                        43.808333332817085
                    ],
                    [
                        -91.98416666685813,
                        43.92972222180316
                    ],
                    [
                        -92.27916666680818,
                        44.10833333311689
                    ],
                    [
                        -92.75249999997499,
                        44.345555555444605
                    ],
                    [
                        -92.873888888961,
                        44.48361111046444
                    ],
                    [
                        -93.23194444498012,
                        44.89027777769735
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 872,
            "artcc": "zmp",
            "sector": "32",
            "sectype": "superhigh",
            "Shape_Length": 16.30337690789207,
            "Shape_Area": 10.920857716135707
        }
    },
    {
        "type": "Feature",
        "id": 874,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -89.41222222207784,
                        47.79833333311689
                    ],
                    [
                        -89.82500000024976,
                        47.89166666668336
                    ],
                    [
                        -91.08694444423065,
                        47.1872222221279
                    ],
                    [
                        -91.36666666683317,
                        47.02499999965033
                    ],
                    [
                        -91.41666666673325,
                        46.999999999700265
                    ],
                    [
                        -91.86777777822186,
                        46.73694444403094
                    ],
                    [
                        -91.896666667033,
                        46.72222222197803
                    ],
                    [
                        -92.16666666703304,
                        46.549999999700276
                    ],
                    [
                        -92.1738888885613,
                        46.21888888851146
                    ],
                    [
                        -92.17527777814689,
                        46.149166667108034
                    ],
                    [
                        -92.18333333306686,
                        45.76666666643354
                    ],
                    [
                        -91.53333333346654,
                        45.56666666683327
                    ],
                    [
                        -90.93166666698306,
                        45.375000000249884
                    ],
                    [
                        -90.80222222217776,
                        45.33333333336668
                    ],
                    [
                        -90.59999999960024,
                        45.141666666783294
                    ],
                    [
                        -89.58749999982507,
                        44.15416666695819
                    ],
                    [
                        -89.37083333329167,
                        44.18333333296698
                    ],
                    [
                        -89.07611111143859,
                        44.22499999985018
                    ],
                    [
                        -88.7541666666583,
                        44.15944444450554
                    ],
                    [
                        -88.46666666633354,
                        44.10000000009995
                    ],
                    [
                        -87.54166666638349,
                        43.96666666643358
                    ],
                    [
                        -87.20000000009986,
                        44.533333333166865
                    ],
                    [
                        -87.11666666633357,
                        44.66666666683324
                    ],
                    [
                        -86.99999999960028,
                        44.86666666643356
                    ],
                    [
                        -86.93916666650841,
                        44.94258333355407
                    ],
                    [
                        -86.7025000003747,
                        45.235277777547424
                    ],
                    [
                        -86.49999999970021,
                        45.48333333306692
                    ],
                    [
                        -86.50249999987506,
                        45.99361111086415
                    ],
                    [
                        -86.4988888891109,
                        46.95833333371644
                    ],
                    [
                        -86.99999999960028,
                        47.08333333346661
                    ],
                    [
                        -87.50000000039967,
                        47.32083333299198
                    ],
                    [
                        -88.77499999965022,
                        47.9083333336165
                    ],
                    [
                        -89.10833333291697,
                        47.79999999990014
                    ],
                    [
                        -89.31666666643349,
                        47.79888888931083
                    ],
                    [
                        -89.41222222207784,
                        47.79833333311689
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 874,
            "artcc": "zmp",
            "sector": "34",
            "sectype": "superhigh",
            "Shape_Length": 15.567158691185034,
            "Shape_Area": 15.26242876887965
        }
    },
    {
        "type": "Feature",
        "id": 879,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -93.46666666713293,
                        41.6666666665335
                    ],
                    [
                        -93.35555555604407,
                        41.947222221628294
                    ],
                    [
                        -96.07027777809697,
                        41.61777777722267
                    ],
                    [
                        -96.36666666673324,
                        41.85000000009995
                    ],
                    [
                        -99.02499999985008,
                        41.71666666643358
                    ],
                    [
                        -99.03333333376628,
                        41.07499999985015
                    ],
                    [
                        -99.04722222242759,
                        40.88333333326676
                    ],
                    [
                        -99.05416666675825,
                        40.349999999500426
                    ],
                    [
                        -99.05555555544447,
                        40.23055555539463
                    ],
                    [
                        -99.05833333371635,
                        39.983333333266785
                    ],
                    [
                        -98.80000000029975,
                        39.46666666643358
                    ],
                    [
                        -97.88333333336658,
                        39.66666666603385
                    ],
                    [
                        -96.96666666733279,
                        39.86666666653349
                    ],
                    [
                        -96.08333333336662,
                        40.0500000001
                    ],
                    [
                        -95.91666666673325,
                        40.07916666610879
                    ],
                    [
                        -95.55000000049961,
                        40.1499999999001
                    ],
                    [
                        -94.49999999990001,
                        40.35055555569437
                    ],
                    [
                        -94.07250000057451,
                        40.427777777622396
                    ],
                    [
                        -93.90833333321672,
                        40.458333333216785
                    ],
                    [
                        -93.491666667083,
                        40.52500000005
                    ],
                    [
                        -93.55694444433061,
                        40.91749999962536
                    ],
                    [
                        -93.56249999997499,
                        40.9444444444556
                    ],
                    [
                        -93.58333333386622,
                        41.06666666683327
                    ],
                    [
                        -93.59999999990004,
                        41.16666666663343
                    ],
                    [
                        -93.50833333401613,
                        41.43333333306691
                    ],
                    [
                        -93.48499999994999,
                        41.566111110539396
                    ],
                    [
                        -93.46666666713293,
                        41.6666666665335
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 879,
            "artcc": "zmp",
            "sector": "40",
            "sectype": "superhigh",
            "Shape_Length": 14.969601888206029,
            "Shape_Area": 9.790478664228328
        }
    },
    {
        "type": "Feature",
        "id": 880,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -99.02499999985008,
                        41.71666666643358
                    ],
                    [
                        -99.03333333286696,
                        41.07499999985015
                    ],
                    [
                        -99.04722222242759,
                        40.88333333326676
                    ],
                    [
                        -99.05416666675825,
                        40.350000000399746
                    ],
                    [
                        -99.05555555544447,
                        40.23055555539463
                    ],
                    [
                        -99.05833333371635,
                        39.983333333266785
                    ],
                    [
                        -98.80000000029975,
                        39.46666666643358
                    ],
                    [
                        -97.88333333336658,
                        39.66666666693317
                    ],
                    [
                        -96.96666666643347,
                        39.86666666653349
                    ],
                    [
                        -96.08333333336662,
                        40.0500000001
                    ],
                    [
                        -95.91666666673325,
                        40.07916666700811
                    ],
                    [
                        -95.54999999960029,
                        40.1499999999001
                    ],
                    [
                        -95.75000000009987,
                        40.35833333341668
                    ],
                    [
                        -95.77500000004994,
                        40.483333333166854
                    ],
                    [
                        -95.89166666678324,
                        41.16666666663343
                    ],
                    [
                        -96.03333333346654,
                        41.54166666678327
                    ],
                    [
                        -96.05833333341656,
                        41.608333333616486
                    ],
                    [
                        -96.07027777809697,
                        41.61777777812199
                    ],
                    [
                        -96.36666666673324,
                        41.85000000009995
                    ],
                    [
                        -99.02499999985008,
                        41.71666666643358
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 880,
            "artcc": "zmp",
            "sector": "42",
            "sectype": "superhigh",
            "Shape_Length": 10.267865086364667,
            "Shape_Area": 6.359081018240785
        }
    },
    {
        "type": "Feature",
        "id": 881,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -93.35555555604407,
                        41.947222222527614
                    ],
                    [
                        -96.07027777809697,
                        41.61777777812199
                    ],
                    [
                        -96.05833333341656,
                        41.608333333616486
                    ],
                    [
                        -96.03333333346654,
                        41.54166666678327
                    ],
                    [
                        -95.89166666678324,
                        41.16666666663343
                    ],
                    [
                        -95.77500000004994,
                        40.483333333166854
                    ],
                    [
                        -95.75000000009987,
                        40.35833333341668
                    ],
                    [
                        -95.54999999960029,
                        40.1499999999001
                    ],
                    [
                        -94.49999999990001,
                        40.35055555569437
                    ],
                    [
                        -94.07249999967519,
                        40.427777777622396
                    ],
                    [
                        -93.90833333321672,
                        40.458333333216785
                    ],
                    [
                        -93.491666667083,
                        40.52500000005
                    ],
                    [
                        -93.55694444433061,
                        40.91749999962536
                    ],
                    [
                        -93.56249999997499,
                        40.9444444444556
                    ],
                    [
                        -93.5833333329669,
                        41.06666666683327
                    ],
                    [
                        -93.59999999990004,
                        41.16666666663343
                    ],
                    [
                        -93.50833333311681,
                        41.43333333306691
                    ],
                    [
                        -93.48499999994999,
                        41.566111111438715
                    ],
                    [
                        -93.46666666713293,
                        41.6666666665335
                    ],
                    [
                        -93.35555555604407,
                        41.947222222527614
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 881,
            "artcc": "zmp",
            "sector": "43",
            "sectype": "superhigh",
            "Shape_Length": 7.8953095227150065,
            "Shape_Area": 3.4313976476807633
        }
    },
    {
        "type": "Feature",
        "id": 882,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -101.4833333333666,
                        44.699999999800184
                    ],
                    [
                        -101.40833333351651,
                        43.70833333301698
                    ],
                    [
                        -100.10000000039969,
                        43.28888888861138
                    ],
                    [
                        -98.44166666708298,
                        43.600000000199884
                    ],
                    [
                        -97.67166666628361,
                        44.86666666643356
                    ],
                    [
                        -97.60833333301684,
                        44.96666666713304
                    ],
                    [
                        -97.63333333296691,
                        45.85833333321682
                    ],
                    [
                        -98.00000000009987,
                        45.9166666670331
                    ],
                    [
                        -98.99999999990001,
                        45.9166666670331
                    ],
                    [
                        -100.38333333286698,
                        45.999999999900126
                    ],
                    [
                        -101.82916666660833,
                        46.23888888901109
                    ],
                    [
                        -103.16666666663332,
                        46.449999999900115
                    ],
                    [
                        -103.16666666663332,
                        44.958333333216785
                    ],
                    [
                        -101.4833333333666,
                        44.699999999800184
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 882,
            "artcc": "zmp",
            "sector": "44",
            "sectype": "superhigh",
            "Shape_Length": 15.31973779481365,
            "Shape_Area": 11.481629631480535
        }
    },
    {
        "type": "Feature",
        "id": 883,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -82.34999999990004,
                        44.66666666683324
                    ],
                    [
                        -82.42138888898597,
                        44.915555555744334
                    ],
                    [
                        -82.49999999960028,
                        45.33333333336668
                    ],
                    [
                        -83.50000000029974,
                        45.78333333336667
                    ],
                    [
                        -83.58333333316676,
                        45.81666666633362
                    ],
                    [
                        -84.35833333341657,
                        46.25000000029985
                    ],
                    [
                        -84.53333333306682,
                        46.3833333330669
                    ],
                    [
                        -84.99999999999994,
                        46.53333333366646
                    ],
                    [
                        -85.63333333356644,
                        46.75000000019992
                    ],
                    [
                        -85.88333333306684,
                        46.8000000001
                    ],
                    [
                        -86.4988888891109,
                        46.95833333371644
                    ],
                    [
                        -86.50249999987506,
                        45.99361111086415
                    ],
                    [
                        -86.49999999970021,
                        45.48333333306692
                    ],
                    [
                        -86.7025000003747,
                        45.235277777547424
                    ],
                    [
                        -86.93916666650841,
                        44.94258333355407
                    ],
                    [
                        -86.99999999960028,
                        44.86666666643356
                    ],
                    [
                        -87.11666666633357,
                        44.66666666683324
                    ],
                    [
                        -87.20000000009986,
                        44.533333333166865
                    ],
                    [
                        -87.54166666638349,
                        43.96666666643358
                    ],
                    [
                        -87.16944444450547,
                        43.69166666698317
                    ],
                    [
                        -86.99999999960028,
                        43.68499999985016
                    ],
                    [
                        -86.21666666633354,
                        43.65111111068927
                    ],
                    [
                        -85.74166666638354,
                        43.628055555619426
                    ],
                    [
                        -84.9636111106642,
                        43.58666666683325
                    ],
                    [
                        -84.52249999987504,
                        43.63388888846151
                    ],
                    [
                        -84.16055555589418,
                        43.67138888928588
                    ],
                    [
                        -84.03722222202788,
                        43.683611111163884
                    ],
                    [
                        -83.29333333356647,
                        43.755833333641476
                    ],
                    [
                        -82.1694444446054,
                        43.85527777814701
                    ],
                    [
                        -82.30388888886108,
                        44.382222221978
                    ],
                    [
                        -82.34999999990004,
                        44.66666666683324
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 883,
            "artcc": "zmp",
            "sector": "46",
            "sectype": "superhigh",
            "Shape_Length": 14.658031270659176,
            "Shape_Area": 11.595931531467352
        }
    },
    {
        "type": "Feature",
        "id": 898,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -76.58611111103892,
                        40.42083333329174
                    ],
                    [
                        -76.85388888896102,
                        40.439166667008124
                    ],
                    [
                        -77.07472222245258,
                        40.457222222627536
                    ],
                    [
                        -77.30000000009989,
                        40.473055555269696
                    ],
                    [
                        -77.6722222219779,
                        40.50277777747249
                    ],
                    [
                        -77.94138888858629,
                        40.5216666664835
                    ],
                    [
                        -77.90583333354147,
                        40.395555555244755
                    ],
                    [
                        -77.86416666665832,
                        40.24666666703308
                    ],
                    [
                        -77.86249999987507,
                        40.199999999800184
                    ],
                    [
                        -77.85833333291697,
                        40.15694444423076
                    ],
                    [
                        -77.84027777819688,
                        39.855555555244734
                    ],
                    [
                        -77.61722222262745,
                        39.77222222237771
                    ],
                    [
                        -77.3974944446129,
                        39.78018888920593
                    ],
                    [
                        -77.35666666713297,
                        39.78166666688321
                    ],
                    [
                        -77.35694444433062,
                        39.74638888903604
                    ],
                    [
                        -77.35694444433062,
                        39.722222222477626
                    ],
                    [
                        -77.3572222224276,
                        39.69805555591921
                    ],
                    [
                        -77.24027777759733,
                        39.581111111088944
                    ],
                    [
                        -77.2269444442307,
                        39.56888888921094
                    ],
                    [
                        -77.20666666653341,
                        39.54805555531965
                    ],
                    [
                        -77.18361111146356,
                        39.52083333329176
                    ],
                    [
                        -77.07472222245258,
                        39.57916666710804
                    ],
                    [
                        -77.00833333371634,
                        39.610555555194765
                    ],
                    [
                        -76.82500000014983,
                        39.71388888856143
                    ],
                    [
                        -76.62361111096396,
                        39.81805555531969
                    ],
                    [
                        -76.59999999970017,
                        39.830555555294666
                    ],
                    [
                        -76.25000000039967,
                        40.01611111093905
                    ],
                    [
                        -76.20527777804699,
                        40.03666666673331
                    ],
                    [
                        -76.18416666695805,
                        40.04888888861137
                    ],
                    [
                        -76.15750000022479,
                        40.061111111388755
                    ],
                    [
                        -75.9583333331168,
                        40.16111111118886
                    ],
                    [
                        -75.80000000039968,
                        40.24444444405589
                    ],
                    [
                        -75.64166666678318,
                        40.33333333346661
                    ],
                    [
                        -76.24166666648341,
                        40.42972222250262
                    ],
                    [
                        -76.40138888878613,
                        40.457222222627536
                    ],
                    [
                        -76.51722222212783,
                        40.408888888611386
                    ],
                    [
                        -76.58611111103892,
                        40.42083333329174
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 898,
            "artcc": "zny",
            "sector": "08",
            "sectype": "superhigh",
            "Shape_Length": 5.575751701359769,
            "Shape_Area": 1.2653643844632305
        }
    },
    {
        "type": "Feature",
        "id": 900,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -77.12333333366638,
                        39.4652777777473
                    ],
                    [
                        -77.10555555524462,
                        39.446388888736294
                    ],
                    [
                        -76.94277777747243,
                        39.406666666733315
                    ],
                    [
                        -76.85555555574427,
                        39.385000000349805
                    ],
                    [
                        -76.81527777754735,
                        39.38555555564443
                    ],
                    [
                        -76.72138888868625,
                        39.38694444433071
                    ],
                    [
                        -76.34722222192795,
                        39.39166666658343
                    ],
                    [
                        -76.04027777819687,
                        39.59361111106398
                    ],
                    [
                        -75.96388888876118,
                        39.64388888906103
                    ],
                    [
                        -75.89472222175311,
                        39.68916666670833
                    ],
                    [
                        -75.77333333366636,
                        39.77083333369143
                    ],
                    [
                        -75.67000000029975,
                        39.835000000349794
                    ],
                    [
                        -75.64999999980012,
                        39.84999999960036
                    ],
                    [
                        -75.46944444450548,
                        39.97083333329175
                    ],
                    [
                        -75.34166666648343,
                        40.06666666703313
                    ],
                    [
                        -75.02777777752237,
                        40.09305555566942
                    ],
                    [
                        -75.03750000012485,
                        40.22499999975025
                    ],
                    [
                        -75.64166666678318,
                        40.33333333346661
                    ],
                    [
                        -75.80000000039968,
                        40.24444444405589
                    ],
                    [
                        -75.9583333331168,
                        40.16111111118886
                    ],
                    [
                        -76.15750000022479,
                        40.061111111388755
                    ],
                    [
                        -76.18416666695805,
                        40.04888888861137
                    ],
                    [
                        -76.20527777804699,
                        40.03666666673331
                    ],
                    [
                        -76.25000000039967,
                        40.01611111093905
                    ],
                    [
                        -76.59999999970017,
                        39.830555555294666
                    ],
                    [
                        -76.62361111096396,
                        39.81805555531969
                    ],
                    [
                        -76.82500000014983,
                        39.71388888856143
                    ],
                    [
                        -77.00833333371634,
                        39.610555555194765
                    ],
                    [
                        -77.07472222245258,
                        39.57916666710804
                    ],
                    [
                        -77.18361111146356,
                        39.52083333329176
                    ],
                    [
                        -77.12333333366638,
                        39.4652777777473
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 900,
            "artcc": "zny",
            "sector": "09",
            "sectype": "superhigh",
            "Shape_Length": 4.889596495034707,
            "Shape_Area": 0.8078852621379276
        }
    },
    {
        "type": "Feature",
        "id": 918,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -77.9499999997002,
                        41.11944444410585
                    ],
                    [
                        -78.12777777762233,
                        40.85833333331675
                    ],
                    [
                        -78.09722222202788,
                        40.81666666643355
                    ],
                    [
                        -77.98500000034971,
                        40.673888889160935
                    ],
                    [
                        -77.9669444447303,
                        40.61138888928588
                    ],
                    [
                        -77.94138888858629,
                        40.5216666664835
                    ],
                    [
                        -77.6722222219779,
                        40.50277777747249
                    ],
                    [
                        -77.30000000009989,
                        40.473055555269696
                    ],
                    [
                        -77.07472222245258,
                        40.457222222627536
                    ],
                    [
                        -76.85388888896102,
                        40.439166667008124
                    ],
                    [
                        -76.58611111103892,
                        40.42083333329174
                    ],
                    [
                        -76.51722222212783,
                        40.408888888611386
                    ],
                    [
                        -76.40138888878613,
                        40.457222222627536
                    ],
                    [
                        -76.00722222242757,
                        40.59444444425577
                    ],
                    [
                        -75.61500000004992,
                        40.74249999997505
                    ],
                    [
                        -75.59333333366635,
                        40.8149999996503
                    ],
                    [
                        -75.62861111151352,
                        40.88027777779723
                    ],
                    [
                        -76.15055555589413,
                        40.74249999997505
                    ],
                    [
                        -77.16472222245255,
                        40.88722222212789
                    ],
                    [
                        -77.90277777807194,
                        40.99166666698312
                    ],
                    [
                        -77.9499999997002,
                        41.11944444410585
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 918,
            "artcc": "zny",
            "sector": "72",
            "sectype": "superhigh",
            "Shape_Length": 5.694348914271784,
            "Shape_Area": 0.8600470292268315
        }
    },
    {
        "type": "Feature",
        "id": 922,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -77.63333333336658,
                        41.555555555444585
                    ],
                    [
                        -77.64166666638351,
                        41.54166666678327
                    ],
                    [
                        -77.72500000014986,
                        41.4166666670331
                    ],
                    [
                        -77.80833333301689,
                        41.30833333331674
                    ],
                    [
                        -77.9499999997002,
                        41.11944444410585
                    ],
                    [
                        -77.90277777807194,
                        40.99166666698312
                    ],
                    [
                        -77.16472222245255,
                        40.88722222212789
                    ],
                    [
                        -76.15055555589413,
                        40.74249999997505
                    ],
                    [
                        -75.62861111151352,
                        40.88027777779723
                    ],
                    [
                        -75.46638888903595,
                        40.920277777897184
                    ],
                    [
                        -75.44444444455542,
                        40.958333333116855
                    ],
                    [
                        -75.69444444405582,
                        41.041944444080855
                    ],
                    [
                        -75.74555555544447,
                        41.06833333361652
                    ],
                    [
                        -75.78222222197792,
                        41.092222222077964
                    ],
                    [
                        -75.81749999982509,
                        41.120000000299854
                    ],
                    [
                        -75.85111111088901,
                        41.15166666648355
                    ],
                    [
                        -75.88055555589415,
                        41.18805555581929
                    ],
                    [
                        -75.90499999965022,
                        41.22805555591924
                    ],
                    [
                        -75.91527777754737,
                        41.25277777777228
                    ],
                    [
                        -76.45833333301687,
                        41.395833333141866
                    ],
                    [
                        -77.24944444400586,
                        41.584999999550405
                    ],
                    [
                        -77.63333333336658,
                        41.555555555444585
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 922,
            "artcc": "zny",
            "sector": "75",
            "sectype": "superhigh",
            "Shape_Length": 5.532570597322934,
            "Shape_Area": 1.2604736106880396
        }
    },
    {
        "type": "Feature",
        "id": 989,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -82.35555555554441,
                        43.13333333326676
                    ],
                    [
                        -82.11666666643345,
                        43.600000000199884
                    ],
                    [
                        -82.1250000003497,
                        43.68888888871129
                    ],
                    [
                        -82.1694444446054,
                        43.85527777814701
                    ],
                    [
                        -83.29333333356647,
                        43.755833333641476
                    ],
                    [
                        -84.03722222202788,
                        43.683611111163884
                    ],
                    [
                        -84.16055555589418,
                        43.67138888928588
                    ],
                    [
                        -84.52249999987504,
                        43.63388888846151
                    ],
                    [
                        -84.9636111106642,
                        43.58666666683325
                    ],
                    [
                        -84.99999999999994,
                        43.50000000039978
                    ],
                    [
                        -84.99999999999994,
                        43.177777777522465
                    ],
                    [
                        -84.99999999999994,
                        43.03611111083916
                    ],
                    [
                        -84.99999999999994,
                        42.96666666663339
                    ],
                    [
                        -84.99999999999994,
                        42.743333332966984
                    ],
                    [
                        -83.48583333354145,
                        42.700833333591504
                    ],
                    [
                        -82.46666666663333,
                        42.87083333289206
                    ],
                    [
                        -82.46111111098895,
                        42.911111111088985
                    ],
                    [
                        -82.40416666675821,
                        42.95416666665841
                    ],
                    [
                        -82.41666666673325,
                        43.01138888898612
                    ],
                    [
                        -82.35555555554441,
                        43.13333333326676
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 989,
            "artcc": "zob",
            "sector": "19",
            "sectype": "superhigh",
            "Shape_Length": 7.29856946069806,
            "Shape_Area": 2.640477739840626
        }
    },
    {
        "type": "Feature",
        "id": 992,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -83.48583333354145,
                        42.700833333591504
                    ],
                    [
                        -82.83166666698304,
                        41.859444444605515
                    ],
                    [
                        -81.2375000003247,
                        41.7586111114137
                    ],
                    [
                        -81.21111111078909,
                        41.999999999800195
                    ],
                    [
                        -81.0638888884614,
                        42.205555555944215
                    ],
                    [
                        -80.94861111131371,
                        42.39111111068928
                    ],
                    [
                        -80.44166666708298,
                        42.755555555744365
                    ],
                    [
                        -80.26666666653341,
                        42.813888888661324
                    ],
                    [
                        -80.89583333314175,
                        42.779166667008155
                    ],
                    [
                        -81.22083333339157,
                        42.74166666708305
                    ],
                    [
                        -81.82777777742245,
                        42.80833333301695
                    ],
                    [
                        -81.84722222262741,
                        42.811111111288824
                    ],
                    [
                        -81.97083333369136,
                        42.82916666690818
                    ],
                    [
                        -82.27222222177807,
                        42.853333333466594
                    ],
                    [
                        -82.40833333371631,
                        42.86666666683328
                    ],
                    [
                        -82.46666666663333,
                        42.87083333289206
                    ],
                    [
                        -83.48583333354145,
                        42.700833333591504
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 992,
            "artcc": "zob",
            "sector": "26",
            "sectype": "superhigh",
            "Shape_Length": 7.429342712175668,
            "Shape_Area": 2.2048322530581856
        }
    },
    {
        "type": "Feature",
        "id": 995,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -84.99999999999994,
                        42.743333332966984
                    ],
                    [
                        -84.99998333286447,
                        42.68865833331546
                    ],
                    [
                        -84.9999916668819,
                        42.599116666501004
                    ],
                    [
                        -84.99999999999994,
                        42.523611110964055
                    ],
                    [
                        -84.99999999999994,
                        42.373888888461465
                    ],
                    [
                        -84.99999999999994,
                        42.16666666643357
                    ],
                    [
                        -84.99921388910968,
                        42.132474999821454
                    ],
                    [
                        -84.99999999999994,
                        42.0416666666834
                    ],
                    [
                        -84.99999999999994,
                        41.999999999800195
                    ],
                    [
                        -84.76666666653341,
                        41.85833333311689
                    ],
                    [
                        -82.83166666698304,
                        41.859444444605515
                    ],
                    [
                        -83.48583333354145,
                        42.700833333591504
                    ],
                    [
                        -84.99999999999994,
                        42.743333332966984
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 995,
            "artcc": "zob",
            "sector": "29",
            "sectype": "superhigh",
            "Shape_Length": 5.531853102844559,
            "Shape_Area": 1.5661387932886248
        }
    },
    {
        "type": "Feature",
        "id": 1003,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -82.83166666698304,
                        41.859444444605515
                    ],
                    [
                        -82.70166666688311,
                        41.162499999675276
                    ],
                    [
                        -83.45555555604403,
                        40.70555555534463
                    ],
                    [
                        -83.06666666633356,
                        40.57083333299198
                    ],
                    [
                        -82.91666666663332,
                        40.5666666669332
                    ],
                    [
                        -82.5916666663835,
                        40.55416666695817
                    ],
                    [
                        -82.34444444425566,
                        40.5736111112638
                    ],
                    [
                        -82.16666666633353,
                        40.587499999925114
                    ],
                    [
                        -81.89166666688311,
                        40.59999999990015
                    ],
                    [
                        -81.78333333316675,
                        40.59999999990015
                    ],
                    [
                        -80.89166666708297,
                        40.64999999980023
                    ],
                    [
                        -80.35833333331664,
                        40.6819444440809
                    ],
                    [
                        -80.87555555544446,
                        41.01194444468041
                    ],
                    [
                        -80.89361111106388,
                        41.50333333346663
                    ],
                    [
                        -80.61666666673324,
                        41.633333333566554
                    ],
                    [
                        -81.2375000003247,
                        41.7586111114137
                    ],
                    [
                        -82.83166666698304,
                        41.859444444605515
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 1003,
            "artcc": "zob",
            "sector": "45",
            "sectype": "superhigh",
            "Shape_Length": 8.356553323194237,
            "Shape_Area": 2.5719508110812046
        }
    },
    {
        "type": "Feature",
        "id": 1004,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -84.76666666653341,
                        41.85833333311689
                    ],
                    [
                        -84.76666666653341,
                        41.80000000019993
                    ],
                    [
                        -84.74999999960028,
                        41.583333333666474
                    ],
                    [
                        -84.71666666663333,
                        41.28333333336667
                    ],
                    [
                        -84.70916388900224,
                        41.15869999980521
                    ],
                    [
                        -84.6999999997002,
                        40.90833333321683
                    ],
                    [
                        -84.34722222212781,
                        40.863055555569474
                    ],
                    [
                        -83.7458333337413,
                        40.7833333334666
                    ],
                    [
                        -83.64999999999998,
                        40.737499999625356
                    ],
                    [
                        -83.45555555604403,
                        40.70555555534463
                    ],
                    [
                        -82.70166666688311,
                        41.162499999675276
                    ],
                    [
                        -82.83166666698304,
                        41.859444444605515
                    ],
                    [
                        -84.76666666653341,
                        41.85833333311689
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 1004,
            "artcc": "zob",
            "sector": "46",
            "sectype": "superhigh",
            "Shape_Length": 5.744006302681251,
            "Shape_Area": 1.9739353121967842
        }
    },
    {
        "type": "Feature",
        "id": 1013,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -80.61666666673324,
                        41.633333333566554
                    ],
                    [
                        -80.89361111106388,
                        41.50333333346663
                    ],
                    [
                        -80.87555555544446,
                        41.01194444468041
                    ],
                    [
                        -80.35833333331664,
                        40.6819444440809
                    ],
                    [
                        -79.77222222227772,
                        40.638888888511474
                    ],
                    [
                        -77.94138888858629,
                        40.5216666664835
                    ],
                    [
                        -77.9669444447303,
                        40.61138888928588
                    ],
                    [
                        -77.98500000034971,
                        40.673888889160935
                    ],
                    [
                        -78.09722222202788,
                        40.81666666643355
                    ],
                    [
                        -78.12777777762233,
                        40.85833333331675
                    ],
                    [
                        -77.9499999997002,
                        41.11944444410585
                    ],
                    [
                        -77.80833333301689,
                        41.30833333331674
                    ],
                    [
                        -77.89972222260246,
                        41.319444444605494
                    ],
                    [
                        -78.38888888931075,
                        41.37916666710805
                    ],
                    [
                        -78.74583333294191,
                        41.419444444405656
                    ],
                    [
                        -78.8955555554445,
                        41.43861111151364
                    ],
                    [
                        -79.16666666693311,
                        41.47083333299196
                    ],
                    [
                        -79.52027777789709,
                        41.51583333344166
                    ],
                    [
                        -79.77083333359144,
                        41.53888888851145
                    ],
                    [
                        -79.9222222219779,
                        41.56111111108896
                    ],
                    [
                        -80.61666666673324,
                        41.633333333566554
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 1013,
            "artcc": "zob",
            "sector": "58",
            "sectype": "superhigh",
            "Shape_Length": 7.604388397219769,
            "Shape_Area": 2.454159993380963
        }
    },
    {
        "type": "Feature",
        "id": 1015,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -80.40000000019984,
                        39.16666666613378
                    ],
                    [
                        -79.89444444465533,
                        39.173611110464435
                    ],
                    [
                        -79.6983333339162,
                        39.283611110964046
                    ],
                    [
                        -79.54305555576923,
                        39.3708333326922
                    ],
                    [
                        -79.3180555562189,
                        39.44111111118889
                    ],
                    [
                        -79.23333333376632,
                        39.46666666643358
                    ],
                    [
                        -79.01388888896099,
                        39.533333333266796
                    ],
                    [
                        -78.62472222205287,
                        39.64777777792216
                    ],
                    [
                        -78.55833333331663,
                        39.6716666663836
                    ],
                    [
                        -78.44444444485521,
                        39.70555555554449
                    ],
                    [
                        -78.20000000009986,
                        39.77222222147839
                    ],
                    [
                        -77.93333333366638,
                        39.84999999960036
                    ],
                    [
                        -77.84027777819688,
                        39.855555555244734
                    ],
                    [
                        -77.85833333381629,
                        40.15694444423076
                    ],
                    [
                        -77.86249999987507,
                        40.199999999800184
                    ],
                    [
                        -77.86416666665832,
                        40.24666666613376
                    ],
                    [
                        -77.90583333354147,
                        40.395555555244755
                    ],
                    [
                        -77.94138888948561,
                        40.5216666664835
                    ],
                    [
                        -79.77222222227772,
                        40.638888888511474
                    ],
                    [
                        -80.35833333331664,
                        40.6819444440809
                    ],
                    [
                        -80.89166666708297,
                        40.64999999980023
                    ],
                    [
                        -81.78333333316675,
                        40.59999999990015
                    ],
                    [
                        -81.75444444435556,
                        40.527222222127875
                    ],
                    [
                        -81.69972222220275,
                        40.38722222222782
                    ],
                    [
                        -81.63333333346651,
                        40.21666666673332
                    ],
                    [
                        -81.5744444443556,
                        39.8874999995254
                    ],
                    [
                        -81.55000000059948,
                        39.749999999800195
                    ],
                    [
                        -81.42611111143862,
                        39.68222222147841
                    ],
                    [
                        -81.19166666738278,
                        39.54999999930055
                    ],
                    [
                        -81.10277777797205,
                        39.50416666635863
                    ],
                    [
                        -80.77777777772224,
                        39.31666666673334
                    ],
                    [
                        -80.49999999999994,
                        39.16666666613378
                    ],
                    [
                        -80.40000000019984,
                        39.16666666613378
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 1015,
            "artcc": "zob",
            "sector": "64",
            "sectype": "superhigh",
            "Shape_Length": 9.395632776110382,
            "Shape_Area": 4.295906944430963
        }
    },
    {
        "type": "Feature",
        "id": 1018,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -77.61722222262745,
                        39.77222222147839
                    ],
                    [
                        -77.84027777819688,
                        39.855555555244734
                    ],
                    [
                        -77.85833333381629,
                        40.15694444423076
                    ],
                    [
                        -77.86249999987507,
                        40.199999999800184
                    ],
                    [
                        -77.86416666665832,
                        40.24666666613376
                    ],
                    [
                        -77.90583333354147,
                        40.395555555244755
                    ],
                    [
                        -77.94138888948561,
                        40.5216666664835
                    ],
                    [
                        -79.77222222227772,
                        40.638888888511474
                    ],
                    [
                        -80.35833333331664,
                        40.6819444440809
                    ],
                    [
                        -80.89166666708297,
                        40.64999999980023
                    ],
                    [
                        -79.44339722280353,
                        39.918344444340676
                    ],
                    [
                        -78.96999999999997,
                        39.73916666660841
                    ],
                    [
                        -78.62472222205287,
                        39.64777777792216
                    ],
                    [
                        -78.55833333331663,
                        39.6716666663836
                    ],
                    [
                        -78.52222222207786,
                        39.591666666183755
                    ],
                    [
                        -78.47305555556937,
                        39.48249999997506
                    ],
                    [
                        -77.61722222262745,
                        39.77222222147839
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 1018,
            "artcc": "zob",
            "sector": "68",
            "sectype": "superhigh",
            "Shape_Length": 7.53972477277459,
            "Shape_Area": 1.9469703873679947
        }
    },
    {
        "type": "Feature",
        "id": 1019,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -81.78333333316675,
                        40.59999999990015
                    ],
                    [
                        -81.75444444435556,
                        40.527222222127875
                    ],
                    [
                        -81.69972222220275,
                        40.38722222222782
                    ],
                    [
                        -81.63333333346651,
                        40.21666666673332
                    ],
                    [
                        -81.5744444443556,
                        39.88750000042472
                    ],
                    [
                        -81.54999999970016,
                        39.749999999800195
                    ],
                    [
                        -81.42611111143862,
                        39.68222222237773
                    ],
                    [
                        -81.19166666648346,
                        39.55000000019993
                    ],
                    [
                        -81.10277777797205,
                        39.50416666635863
                    ],
                    [
                        -80.77777777772224,
                        39.31666666673334
                    ],
                    [
                        -80.49999999999994,
                        39.1666666670331
                    ],
                    [
                        -80.40000000019984,
                        39.1666666670331
                    ],
                    [
                        -79.89444444465533,
                        39.173611111363755
                    ],
                    [
                        -79.69833333301688,
                        39.283611110964046
                    ],
                    [
                        -79.54305555576923,
                        39.37083333359152
                    ],
                    [
                        -79.31805555531957,
                        39.44111111118889
                    ],
                    [
                        -79.233333332867,
                        39.46666666643358
                    ],
                    [
                        -79.01388888896099,
                        39.533333333266796
                    ],
                    [
                        -78.62472222205287,
                        39.64777777792216
                    ],
                    [
                        -78.96999999999997,
                        39.73916666660841
                    ],
                    [
                        -79.4433972219042,
                        39.918344444340676
                    ],
                    [
                        -80.89166666708297,
                        40.64999999980023
                    ],
                    [
                        -81.78333333316675,
                        40.59999999990015
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 1019,
            "artcc": "zob",
            "sector": "69",
            "sectype": "superhigh",
            "Shape_Length": 7.433731455506929,
            "Shape_Area": 2.4990401054605846
        }
    },
    {
        "type": "Feature",
        "id": 1024,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -80.94861111131371,
                        42.39111111068928
                    ],
                    [
                        -81.0638888884614,
                        42.205555555944215
                    ],
                    [
                        -81.21111111078909,
                        41.999999999800195
                    ],
                    [
                        -81.2375000003247,
                        41.7586111114137
                    ],
                    [
                        -80.61666666673324,
                        41.633333333566554
                    ],
                    [
                        -79.9222222219779,
                        41.56111111108896
                    ],
                    [
                        -79.77083333359144,
                        41.53888888851145
                    ],
                    [
                        -79.52027777789709,
                        41.51583333344166
                    ],
                    [
                        -79.16666666693311,
                        41.47083333299196
                    ],
                    [
                        -78.8955555554445,
                        41.43861111151364
                    ],
                    [
                        -78.74583333294191,
                        41.419444444405656
                    ],
                    [
                        -78.38888888931075,
                        41.37916666710805
                    ],
                    [
                        -77.89972222260246,
                        41.319444444605494
                    ],
                    [
                        -77.80833333301689,
                        41.30833333331674
                    ],
                    [
                        -77.72500000014986,
                        41.4166666670331
                    ],
                    [
                        -77.64166666638351,
                        41.54166666678327
                    ],
                    [
                        -77.63333333336658,
                        41.555555555444585
                    ],
                    [
                        -77.56666666653342,
                        41.637499999625334
                    ],
                    [
                        -77.4555555554445,
                        41.82083333319184
                    ],
                    [
                        -77.41666666683318,
                        41.87500000005002
                    ],
                    [
                        -77.99999999960028,
                        42.01666666673333
                    ],
                    [
                        -78.8094444443056,
                        42.234444444755354
                    ],
                    [
                        -79.21666666683319,
                        42.341666666983144
                    ],
                    [
                        -80.26972222200288,
                        42.398888889310854
                    ],
                    [
                        -80.94861111131371,
                        42.39111111068928
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 1024,
            "artcc": "zob",
            "sector": "79",
            "sectype": "superhigh",
            "Shape_Length": 8.457735189132844,
            "Shape_Area": 2.7178528939269677
        }
    },
    {
        "type": "Feature",
        "id": 1083,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -86.48888888931077,
                        33.02222222147839
                    ],
                    [
                        -86.14888888891107,
                        33.11055555559449
                    ],
                    [
                        -85.2250000004496,
                        33.345833333041924
                    ],
                    [
                        -84.99999999999994,
                        33.36666666603384
                    ],
                    [
                        -84.99999999999994,
                        33.999999999600334
                    ],
                    [
                        -84.7033333332667,
                        34.01833333331672
                    ],
                    [
                        -85.28333333336661,
                        34.883333332667235
                    ],
                    [
                        -85.41658888916584,
                        35.00216111074673
                    ],
                    [
                        -86.00000000069946,
                        35.51666666623373
                    ],
                    [
                        -86.94194444478029,
                        35.343888888661354
                    ],
                    [
                        -87.0000000004996,
                        35.33333333266722
                    ],
                    [
                        -87.0000000004996,
                        35.10833333311689
                    ],
                    [
                        -87.0000000004996,
                        34.68333333306691
                    ],
                    [
                        -87.0000000004996,
                        34.36666666673335
                    ],
                    [
                        -87.12222222287727,
                        34.2416666660838
                    ],
                    [
                        -87.24999999999994,
                        34.099999999400495
                    ],
                    [
                        -87.55000000029975,
                        34.01666666653347
                    ],
                    [
                        -87.56666666723288,
                        33.890277777197696
                    ],
                    [
                        -87.59166666718289,
                        33.67083333329174
                    ],
                    [
                        -87.61388888886108,
                        33.5124999996753
                    ],
                    [
                        -87.61666666713296,
                        33.445833332842085
                    ],
                    [
                        -87.63333333316677,
                        33.31666666613381
                    ],
                    [
                        -87.67416666665832,
                        33.286111110539366
                    ],
                    [
                        -87.72499999995,
                        33.24166666628366
                    ],
                    [
                        -87.80833333371635,
                        33.183333333366704
                    ],
                    [
                        -87.98611111163848,
                        33.04305555536962
                    ],
                    [
                        -87.8500000005995,
                        32.69722222212789
                    ],
                    [
                        -87.94583333344156,
                        32.62777777792218
                    ],
                    [
                        -86.48888888931077,
                        33.02222222147839
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 1083,
            "artcc": "ztl",
            "sector": "02",
            "sectype": "superhigh",
            "Shape_Length": 10.270647606450165,
            "Shape_Area": 5.217140463471786
        }
    },
    {
        "type": "Feature",
        "id": 1088,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -84.57916666690812,
                        31.952777777472477
                    ],
                    [
                        -84.71666666663333,
                        33.00833333281713
                    ],
                    [
                        -84.99999999999994,
                        33.36666666603384
                    ],
                    [
                        -85.2250000004496,
                        33.345833333041924
                    ],
                    [
                        -86.14888888891107,
                        33.11055555559449
                    ],
                    [
                        -86.48888888931077,
                        33.02222222147839
                    ],
                    [
                        -87.94583333344156,
                        32.62777777792218
                    ],
                    [
                        -87.99166666728286,
                        32.59166666668335
                    ],
                    [
                        -88.11111111138865,
                        32.508333332917005
                    ],
                    [
                        -88.34722222222774,
                        32.33055555499493
                    ],
                    [
                        -88.34444444485518,
                        32.272222222077914
                    ],
                    [
                        -88.34722222222774,
                        32.06944444420577
                    ],
                    [
                        -88.32500000054955,
                        31.5166666661338
                    ],
                    [
                        -87.81666666673323,
                        31.5208333330919
                    ],
                    [
                        -87.55000000029975,
                        31.35833333341668
                    ],
                    [
                        -87.4000000005995,
                        31.28055555529471
                    ],
                    [
                        -86.95000000059952,
                        31.491666666183733
                    ],
                    [
                        -86.74583333314177,
                        31.52916666610878
                    ],
                    [
                        -86.5083333336164,
                        31.57916666600886
                    ],
                    [
                        -85.92499999994999,
                        31.70833333271719
                    ],
                    [
                        -85.89166666698304,
                        31.70694444403091
                    ],
                    [
                        -85.58333333366636,
                        31.76249999957537
                    ],
                    [
                        -85.30833333331663,
                        31.533333333066935
                    ],
                    [
                        -84.53750000002492,
                        31.627777777222718
                    ],
                    [
                        -84.57916666690812,
                        31.952777777472477
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 1088,
            "artcc": "ztl",
            "sector": "08",
            "sectype": "superhigh",
            "Shape_Length": 10.218570016800822,
            "Shape_Area": 5.292882523836425
        }
    },
    {
        "type": "Feature",
        "id": 1095,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -80.74583333344157,
                        37.30416666625871
                    ],
                    [
                        -80.90000000009991,
                        37.27083333329176
                    ],
                    [
                        -81.16249999957529,
                        37.211111110789204
                    ],
                    [
                        -81.933333332867,
                        37.03333333286707
                    ],
                    [
                        -82.25555555574425,
                        36.95972222260252
                    ],
                    [
                        -82.75833333301688,
                        36.843055555869284
                    ],
                    [
                        -82.9750000004496,
                        36.794444444655426
                    ],
                    [
                        -83.11194444398086,
                        36.76472222245263
                    ],
                    [
                        -83.24944444460539,
                        36.57166666628365
                    ],
                    [
                        -83.38333333356644,
                        35.749999999700265
                    ],
                    [
                        -83.36666666663331,
                        35.483333333266785
                    ],
                    [
                        -82.76583333354148,
                        34.68277777777229
                    ],
                    [
                        -82.10000000039969,
                        35.08333333316682
                    ],
                    [
                        -82.04999999960029,
                        35.23333333286706
                    ],
                    [
                        -82.07083333349152,
                        35.358333333516555
                    ],
                    [
                        -81.55416666665832,
                        35.58611111133877
                    ],
                    [
                        -81.43611111123874,
                        35.62916666690819
                    ],
                    [
                        -81.31111111148857,
                        35.67499999985017
                    ],
                    [
                        -80.63361111086402,
                        36.31138888888614
                    ],
                    [
                        -80.25944444410578,
                        36.68472222225279
                    ],
                    [
                        -80.32361111076409,
                        36.80833333331674
                    ],
                    [
                        -80.50194444398085,
                        37.11833333341667
                    ],
                    [
                        -80.5811111107891,
                        37.25555555594423
                    ],
                    [
                        -80.63333333366637,
                        37.3319444444806
                    ],
                    [
                        -80.74583333344157,
                        37.30416666625871
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 1095,
            "artcc": "ztl",
            "sector": "15",
            "sectype": "superhigh",
            "Shape_Length": 8.971638492502942,
            "Shape_Area": 4.599847453812902
        }
    },
    {
        "type": "Feature",
        "id": 1102,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -83.83361111076414,
                        33.542499999975064
                    ],
                    [
                        -83.84999999960024,
                        33.43333333286705
                    ],
                    [
                        -83.89444444475527,
                        33.129166666508524
                    ],
                    [
                        -83.56111111148857,
                        32.733333333366716
                    ],
                    [
                        -83.10000000019983,
                        32.27777777772229
                    ],
                    [
                        -82.74166666698306,
                        31.91388888886121
                    ],
                    [
                        -82.3333333329669,
                        32.16666666663343
                    ],
                    [
                        -82.2000000001998,
                        32.291666666383605
                    ],
                    [
                        -82.06666666653342,
                        32.43333333306691
                    ],
                    [
                        -81.933333332867,
                        32.56249999977524
                    ],
                    [
                        -81.89999999990005,
                        32.67777777782226
                    ],
                    [
                        -81.84361111096393,
                        32.891666666983156
                    ],
                    [
                        -81.84138888888606,
                        32.900277778097006
                    ],
                    [
                        -81.84666666643346,
                        33.51694444473037
                    ],
                    [
                        -81.84999999999997,
                        33.55555555524472
                    ],
                    [
                        -81.84999999999997,
                        33.68333333326677
                    ],
                    [
                        -81.84999999999997,
                        33.888888888511474
                    ],
                    [
                        -82.44055555519469,
                        33.798055556019165
                    ],
                    [
                        -82.98972222250251,
                        34.54722222202798
                    ],
                    [
                        -83.75416666675824,
                        34.076388889036025
                    ],
                    [
                        -83.83361111076414,
                        33.542499999975064
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 1102,
            "artcc": "ztl",
            "sector": "23",
            "sectype": "superhigh",
            "Shape_Length": 7.440194108890896,
            "Shape_Area": 3.479129476042639
        }
    },
    {
        "type": "Feature",
        "id": 1105,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -84.99999999999994,
                        33.999999999600334
                    ],
                    [
                        -84.99999999999994,
                        33.366666666933156
                    ],
                    [
                        -84.71666666663333,
                        33.00833333371645
                    ],
                    [
                        -84.57916666690812,
                        31.952777777472477
                    ],
                    [
                        -84.53750000002492,
                        31.627777778122038
                    ],
                    [
                        -84.16249999987508,
                        31.67499999975024
                    ],
                    [
                        -84.09999999999997,
                        31.68333333366644
                    ],
                    [
                        -83.1125000001748,
                        31.68333333366644
                    ],
                    [
                        -82.74166666698306,
                        31.91388888886121
                    ],
                    [
                        -83.10000000019983,
                        32.27777777772229
                    ],
                    [
                        -83.56111111148857,
                        32.733333333366716
                    ],
                    [
                        -83.89444444475527,
                        33.129166666508524
                    ],
                    [
                        -83.84999999960024,
                        33.43333333286705
                    ],
                    [
                        -83.83361111076414,
                        33.542499999975064
                    ],
                    [
                        -83.75416666675824,
                        34.076388889036025
                    ],
                    [
                        -84.19166666678319,
                        34.050000000399734
                    ],
                    [
                        -84.43333333326666,
                        34.03500000024985
                    ],
                    [
                        -84.7033333332667,
                        34.01833333331672
                    ],
                    [
                        -84.99999999999994,
                        33.999999999600334
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 1105,
            "artcc": "ztl",
            "sector": "27",
            "sectype": "superhigh",
            "Shape_Length": 8.229571710868338,
            "Shape_Area": 3.0366736877632188
        }
    },
    {
        "type": "Feature",
        "id": 1106,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -81.84999999999997,
                        33.888888888511474
                    ],
                    [
                        -81.80444444425564,
                        33.985277777547424
                    ],
                    [
                        -81.5744444443556,
                        34.293611110864106
                    ],
                    [
                        -81.33333333316676,
                        34.39444444405592
                    ],
                    [
                        -81.24166666728286,
                        34.43222222207794
                    ],
                    [
                        -80.85166666698308,
                        34.59222222157831
                    ],
                    [
                        -80.724166667058,
                        34.644166666358615
                    ],
                    [
                        -80.57833333341654,
                        34.70333333266723
                    ],
                    [
                        -80.5177777784217,
                        34.727777777322615
                    ],
                    [
                        -80.46888888911093,
                        34.74749999972528
                    ],
                    [
                        -80.14722222242756,
                        34.855555555344665
                    ],
                    [
                        -80.00000000009987,
                        35.10833333311689
                    ],
                    [
                        -79.99416666725784,
                        35.11666666613377
                    ],
                    [
                        -79.80416666655839,
                        35.383333332567304
                    ],
                    [
                        -79.78333333356647,
                        35.628611110714246
                    ],
                    [
                        -79.78333333356647,
                        35.6499999999001
                    ],
                    [
                        -79.76002777775972,
                        35.913011111073956
                    ],
                    [
                        -79.75638888873618,
                        35.94999999930059
                    ],
                    [
                        -79.74444444495515,
                        36.08888888861139
                    ],
                    [
                        -79.86666666733277,
                        36.133333332867096
                    ],
                    [
                        -79.9500000001998,
                        36.15416666675833
                    ],
                    [
                        -79.97944444430561,
                        36.204722221953034
                    ],
                    [
                        -80.11944444510505,
                        36.443611111064
                    ],
                    [
                        -80.2500000004996,
                        36.66666666663343
                    ],
                    [
                        -80.2594444450051,
                        36.68472222225279
                    ],
                    [
                        -80.63361111176334,
                        36.31138888888614
                    ],
                    [
                        -81.31111111148857,
                        35.67499999985017
                    ],
                    [
                        -81.43611111123874,
                        35.62916666600887
                    ],
                    [
                        -81.55416666665832,
                        35.58611111043945
                    ],
                    [
                        -82.07083333349152,
                        35.358333332617235
                    ],
                    [
                        -82.05000000049961,
                        35.23333333286706
                    ],
                    [
                        -82.10000000039969,
                        35.08333333316682
                    ],
                    [
                        -82.76583333354148,
                        34.68277777777229
                    ],
                    [
                        -82.98972222250251,
                        34.54722222202798
                    ],
                    [
                        -82.44055555609401,
                        33.798055555119845
                    ],
                    [
                        -81.84999999999997,
                        33.888888888511474
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 1106,
            "artcc": "ztl",
            "sector": "28",
            "sectype": "superhigh",
            "Shape_Length": 9.325698737702982,
            "Shape_Area": 4.024622320537905
        }
    },
    {
        "type": "Feature",
        "id": 1114,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -85.40000000009991,
                        36.18333333366644
                    ],
                    [
                        -85.32083333329166,
                        35.824999999550414
                    ],
                    [
                        -85.28333333336661,
                        35.6499999999001
                    ],
                    [
                        -85.99999999980014,
                        35.51666666713305
                    ],
                    [
                        -85.41658888916584,
                        35.00216111074673
                    ],
                    [
                        -85.28333333336661,
                        34.883333333566554
                    ],
                    [
                        -84.7033333332667,
                        34.01833333331672
                    ],
                    [
                        -84.43333333326666,
                        34.03500000024985
                    ],
                    [
                        -84.5833333329669,
                        36.3958333332418
                    ],
                    [
                        -85.40000000009991,
                        36.18333333366644
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 1114,
            "artcc": "ztl",
            "sector": "36",
            "sectype": "superhigh",
            "Shape_Length": 6.752746256456636,
            "Shape_Area": 1.833975972870125
        }
    },
    {
        "type": "Feature",
        "id": 1118,
        "geometry": {
            "type": "Polygon",
            "coordinates": [
                [
                    [
                        -83.38333333356644,
                        35.749999999700265
                    ],
                    [
                        -83.24944444460539,
                        36.57166666628365
                    ],
                    [
                        -83.11194444398086,
                        36.76472222245263
                    ],
                    [
                        -83.12916666710794,
                        36.76111111078916
                    ],
                    [
                        -83.31944444410578,
                        36.711111110889135
                    ],
                    [
                        -83.5583333332167,
                        36.6499999997003
                    ],
                    [
                        -84.11111111128872,
                        36.51249999997509
                    ],
                    [
                        -84.5833333329669,
                        36.3958333332418
                    ],
                    [
                        -84.43333333326666,
                        34.03500000024985
                    ],
                    [
                        -84.19166666678319,
                        34.050000000399734
                    ],
                    [
                        -83.75416666675824,
                        34.076388889036025
                    ],
                    [
                        -82.98972222250251,
                        34.54722222202798
                    ],
                    [
                        -82.76583333354148,
                        34.68277777777229
                    ],
                    [
                        -83.36666666663331,
                        35.483333333266785
                    ],
                    [
                        -83.38333333356644,
                        35.749999999700265
                    ]
                ]
            ]
        },
        "properties": {
            "OBJECTID": 1118,
            "artcc": "ztl",
            "sector": "40",
            "sectype": "superhigh",
            "Shape_Length": 8.06016670113962,
            "Shape_Area": 3.2195407394880613
        }
    }

	]