import React, { useEffect, useState } from 'react';
import { Form, Button, Segment, Modal, Container, Header, Table } from 'semantic-ui-react';
import _ from 'lodash';

import { useMETAR, useTAF, useRVR } from './api/weather';

function WeatherForm() {

    const [metar, setMetar] = useState(null);
    const [taf, setTaf] = useState(null);
    const [keyword, setKeyword] = useState(undefined);

    const { refetch: fetchMETAR, data: metarResult } = useMETAR(keyword);
    const { refetch: fetchTAF, data: tafResult } = useTAF(keyword);

    useEffect(() => {

        if (metarResult) setMetar(metarResult.raw_text);
        if (tafResult) setTaf(Object.values(tafResult)[0].line_by_line);

    }, [metarResult, tafResult]);

    async function search() {

        if (keyword.length === 4) {

            setMetar(null);
            setTaf(null);

            await fetchMETAR();
            await fetchTAF();

        }

    }

    return (

        <>
            <Form>
                <Container className='lightbordered'>
                    <Header as='h4' content='Weather Request' />
                    <Form.Group>
                        <Form.Input className='uppercase' inline label='ICAO' onChange={(_, { value }) => setKeyword(value)} />
                        <Button content='Search' onClick={() => search()} />
                    </Form.Group>
                </Container>
            </Form>

            {
                metar || taf ?
                    <Segment>
                        {
                            metar ?
                                <ul style={{ listStyleType: 'none' }}>
                                    <li>{metar}</li>
                                </ul>
                                :
                                null
                        }
                        {
                            taf ?
                                <ul style={{ listStyleType: 'none' }}>
                                    {
                                        _.map(taf, l => { return <li key={l}>{l} </li> })
                                    }
                                </ul>
                                :
                                null
                        }
                    </Segment>
                    : null
            }
        </>

    )

}

export function RVRForm() {

    const [rvr, setRVR] = useState(null);
    const [keyword, setKeyword] = useState(null);

    const { refetch: fetchRVR, data: rvrResult } = useRVR(keyword);

    useEffect(() => {

        if (rvrResult) setRVR(rvrResult);

    }, [rvrResult]);

    async function search() {

        if (keyword.length === 3) {

            setRVR(null);
            await fetchRVR();

        }

    }

    return (

        <>
            <Form>
                <Container className='lightbordered'>
                    <Form.Group>
                        <Form.Input className='uppercase' inline label='IATA' onChange={(_, { value }) => setKeyword(value)} />
                        <Button content='Search' onClick={() => search()} />
                    </Form.Group>
                </Container>
            </Form>

            {
                (!_.isNil(rvr) && !_.isNil(rvr.lastUpdated?.time) && _.isArray(rvr?.rvrValues)) ?
                    <Segment>
                        <Table unstackable compact>

                            <Table.Header>

                                <Table.Row style={{ borderWidth: 0 }}>
                                    <Table.HeaderCell style={{ borderWidth: 0 }}>{`${rvr.airportId}`}</Table.HeaderCell>
                                    <Table.HeaderCell style={{ borderWidth: 0 }} colspan='2'>{`${rvr.lastUpdated.time}`}</Table.HeaderCell>
                                    <Table.HeaderCell style={{ borderWidth: 0 }} colspan='4'>{`${rvr.lastUpdated.date}`}</Table.HeaderCell>
                                </Table.Row>

                                <Table.Row style={{ borderWidth: 0 }}>
                                    <Table.HeaderCell style={{ borderWidth: 0 }} />
                                    <Table.HeaderCell style={{ borderWidth: 0 }}>RWY</Table.HeaderCell>
                                    <Table.HeaderCell style={{ borderWidth: 0 }}>TD</Table.HeaderCell>
                                    <Table.HeaderCell style={{ borderWidth: 0 }}>MP</Table.HeaderCell>
                                    <Table.HeaderCell style={{ borderWidth: 0 }}>RO</Table.HeaderCell>
                                    <Table.HeaderCell style={{ borderWidth: 0 }}>E</Table.HeaderCell>
                                    <Table.HeaderCell style={{ borderWidth: 0 }}>C</Table.HeaderCell>
                                </Table.Row>

                            </Table.Header>

                            <Table.Body>
                                {
                                    _.map(rvr.rvrValues, value => {

                                        return <Table.Row key={value.RWY} style={{ borderWidth: 0 }}>
                                            <Table.Cell style={{ borderWidth: 0 }}/>
                                            <Table.Cell style={{ borderWidth: 0 }}>{value.RWY}</Table.Cell>
                                            <Table.Cell style={{ borderWidth: 0 }}>{value.TD}</Table.Cell>
                                            <Table.Cell style={{ borderWidth: 0 }}>{value.MP}</Table.Cell>
                                            <Table.Cell style={{ borderWidth: 0 }}>{value.RO}</Table.Cell>
                                            <Table.Cell style={{ borderWidth: 0 }}>{value.E}</Table.Cell>
                                            <Table.Cell style={{ borderWidth: 0 }}>{value.C}</Table.Cell>
                                        </Table.Row>

                                    })
                                }
                            </Table.Body>

                        </Table>
                    </Segment>
                    : null
            }
        </>

    )

}

export function WeatherModal({ onClose, showModal }) {

    return (
        <Modal size='tiny' closeIcon={true} onClose={() => onClose()} open={showModal}>

            <Modal.Header>Weather Request</Modal.Header>

            <Modal.Content>
                <WeatherForm />
            </Modal.Content>

            <Modal.Actions>
                <Button content='Close' onClick={() => onClose()} />
            </Modal.Actions>

        </Modal>
    )

}
