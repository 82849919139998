import React, { useEffect, useState } from 'react';
import { Form } from 'semantic-ui-react';
import { Input } from 'formik-semantic-ui-react';
import Slider from 'react-rangeslider';
import { default as SUIRNumberInput } from 'semantic-ui-react-numberinput';
import _ from 'lodash';
import { DateTime } from 'luxon';

export const DistanceSlider = ({ label, form, field }) => {

    const [min, setMin] = useState(undefined);
    const [max, setMax] = useState(undefined);
    const [horizontalLabels, setLabels] = useState(undefined);

    const [value, setValue] = useState(undefined);

    useEffect(() => {

        let arr = [];
        for (let i = 0; i <= 2600; i += 50) {

            if (i === 0 || i % 200 === 0) arr.push([i, i]);
            else arr.push([i, '']);

        }
        let pairs = _.fromPairs(arr);

        setMin(arr[0][0]);
        setMax(arr[arr.length - 1][0]);
        setLabels(pairs);
        setValue(0);

    }, []);

    function handleChange(value) {
        setValue(value);
        form.setFieldValue(field.name, value);
    }

    return (

        <Form.Group inline>
            <Input inline type='text' name={field.name} label={label} width={4} value={value} onChange={(_, { value }) => setValue(value)} />
            <div className='slider'>
                <Slider
                    min={min}
                    max={max}
                    step={25}
                    value={value}
                    tooltip={false}
                    labels={horizontalLabels}
                    onChange={(value) => handleChange(value)}
                />
            </div>
        </Form.Group>

    )

}

export const TimeSlider = ({ label, endTime, showLabels = true, form, field }) => {

    const [sliderMin, setSliderMin] = useState(undefined);
    const [sliderMax, setSliderMax] = useState(undefined);
    const [labels, setLabels] = useState(undefined);

    const [sliderValue, setSliderValue] = useState(DateTime.utc().toMillis());
    const [inputValue, setInputValue] = useState(DateTime.utc().toFormat('ddHHmm'));

    useEffect(() => {

        const s = DateTime.utc().minus({ hour: 1 }).startOf('hour');
        const e = s.plus({ hours: 24 }).startOf('hour');

        let arr = [];
        let i = s;
        while (i < e) {

            if (showLabels && i.minute === 0) arr.push([i.toMillis(), `${i.toFormat('HH')}`]);
            else arr.push([i.toMillis(), '']);

            i = i.plus({ minutes: 15 });

        }

        setSliderMin(arr[0][0]);
        setSliderMax(arr[arr.length - 1][0]);
        setLabels(_.fromPairs(arr));

    }, [showLabels]);

    function handleInputChange(value) {

        if (!value) return;

        setInputValue(value);
        setSliderValue(DateTime.fromFormat(value, 'ddHHmm', { zone: 'utc' }).toMillis());
        form.setFieldValue(field.name, value);

    }

    function handleSliderChange(value) {

        if (!value) return;

        let t = DateTime.fromMillis(value, { zone: 'utc' });
        if (endTime && t.minute % 15 === 0) t = t.minus({ minutes: 1});

        setInputValue(t.toFormat('ddHHmm'));
        setSliderValue(value);
        form.setFieldValue(field.name, t.toFormat('ddHHmm'));

    }

    return (

        <Form>
            <Form.Group inline>
                <Input className='timeInput' inline type='text' name={field.name} label={label} value={inputValue} onChange={(_, { value }) => handleInputChange(value)} />
                <div className='slider'>
                    <Slider
                        min={sliderMin}
                        max={sliderMax}
                        step={15 * 60 * 1000}
                        value={sliderValue}
                        tooltip={false}
                        labels={labels}
                        onChange={(value) => handleSliderChange(value)}
                    />
                </div>
            </Form.Group>
        </Form>

    )

}

export const NumberInput = ({ label, form, field, ...rest }) => {

    const [value, setValue] = useState(0);

    function handleChange(value) {
        setValue(value);
        form.setFieldValue(field.name, value);
    }

    useEffect(() => {

        if (!field.value) return;

        setValue(String(field.value));

    }, [field.value]);

    return (

        <Form.Field inline>
            {label ? <label>{label}</label> : null}
            <SUIRNumberInput
                className='inline numberInput'
                buttonPlacement='right'
                minValue={0}
                {...field}
                value={String(value)}
                onChange={(value) => handleChange(value)}
                {...rest}
            />
        </Form.Field>

    )

}

export const DisplayRangeSlider = ({ value }) => {

    const [horizontalLabels, setLabels] = useState(undefined);

    useEffect(() => {

        let arr = [];
        for (let i = 0; i <= 6; i += 0.25) {

            if (i === 0 || i % 1 === 0) arr.push([i, i]);
            else arr.push([i, '']);

        }
        let pairs = _.fromPairs(arr);

        setLabels(pairs);

    }, []);

    return (
        <Form>
            <Form.Field inline>
                <div style={{display: 'inline-block', fontWeight: 'bold', paddingLeft: '3px'}}>
                    Display Range: <br />
                    {value} hours
                </div>
                <div style={{display: 'inline-block', width: '120px'}}>
                    <Slider
                        min={0}
                        max={6}
                        step={0.25}
                        value={value}
                        // orientation='horizontal'
                        tooltip={false}
                        labels={horizontalLabels}
                    />
                </div>
            </Form.Field>
        </Form>
    )

}